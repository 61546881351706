import { action, makeObservable, observable } from 'mobx';

export default class ClientsStore {
  constructor({ API, toastsStore }) {
    makeObservable(this);
    this.toastsStore = toastsStore;
    this.API = API;
  }

  @observable isLoadingClients = false;

  @observable isLoadingClientLeads = false;

  @observable clients = [];

  @observable clientLeads = [];

  @action getClients = async query => {
    try {
      this.isLoadingClients = true;
      const { data } = await this.API.getAllClients(!query ? '' : query);
      this.clients = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingClients = false;
    }
  };

  @action getClientLeads = async query => {
    try {
      this.isLoadingClientLeads = true;
      const { data } = await this.API.getAllCLs(!query ? '' : query);
      this.clientLeads = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingClientLeads = false;
    }
  };
}
