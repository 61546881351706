import { Box, Flex, Spinner } from '@chakra-ui/react';
import DeazyAllocationTable from '@components/DeazyAllocationTable';
import ActualsWeekPicker from '@components/forms/ActualsWeekPicker';
import { ErrorText } from '@components/forms/FormError';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { first, get, isEmpty, map, pick } from 'lodash-es';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form, FormSpy } from 'react-final-form';
import { MAIN_NAMES_QA } from '@app/constants';
import ProjectFormListener from '../../ProjectsCreate/ProjectFormListener';
import SeeAllButton from '../SeeAllButton';

const DeazyActuals = ({
  projectId,
  usersStore: { isDL, isAdmin },
  projectsStore: { project, prepareAllocation },
  allocationsStore: {
    deazyActuals: {
      status: { data: status = {}, isLoading: isLoadingStatus },
    },
    deazyAllocations: { current },
    fetchLastDeazyActuals,
    fetchDeazyActualsStatus,
    createActuals,
  },
}) => {
  const [showForm, setShowForm] = React.useState(false);

  React.useEffect(() => {
    fetchLastDeazyActuals(projectId);
    fetchDeazyActualsStatus(projectId);
  }, []);

  const { missingActualsData } = status;

  const initialValues = {
    projectId,
    ...pick(project, [
      'currency',
      'overriddenCurrency',
      'deazyMarginPercent',
      'supplierCurrencyRate',
    ]),
    deazyAllocations: map(
      prepareAllocation(toJS(get(current, 'data[0].entries'))),
      entry => ({ ...entry, manager: get(entry, 'resourceName') }),
    ),
    year: get(first(missingActualsData), 'year'),
    month: get(first(missingActualsData), 'month'),
    week: get(first(missingActualsData), 'week'),
  };

  return (
    <>
      {showForm && (
        <Form
          initialValues={initialValues}
          onSubmit={values =>
            createActuals(values, () => setShowForm(false), true)
          }
          subscription={{
            submitting: true,
            submitError: true,
            submitErrors: true,
          }}
          render={({ handleSubmit, submitting, form, submitError }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => (
                    <ProjectFormListener formApi={form} values={values} />
                  )}
                </FormSpy>
                <Box px="24px" w="100%" mx="auto" pb="60px" pt="40px">
                  <Box mb="40px">
                    <ActualsWeekPicker
                      missingActualsData={missingActualsData}
                      project={project}
                    />
                  </Box>
                  <DeazyAllocationTable
                    qaTag={MAIN_NAMES_QA.PROJECT_ALLOCATIONS}
                    initialAllocations={initialValues.deazyAllocations}
                    disabledFields={['manager', 'rate']}
                    disallowRowChange
                    isActualsCreating
                  />
                  {submitError && <ErrorText>{submitError}</ErrorText>}

                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mt="40px"
                  >
                    <SeeAllButton
                      to="all-deazy-actuals"
                      data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--seeAllActualsButton`}
                    >
                      See all deazy actuals
                    </SeeAllButton>
                    <Flex alignItems="center">
                      <Button
                        size="lg"
                        isLoading={submitting}
                        type="submit"
                        mr="20px"
                        data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--sendDeazyActualsButton`}
                      >
                        Send actuals
                      </Button>
                      <Button
                        size="lg"
                        variant="outline"
                        isDisabled={submitting}
                        onClick={() => setShowForm(false)}
                        data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--cancelDeazyActualsButton`}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              </form>
            );
          }}
        />
      )}
      {!showForm && (
        <Flex justifyContent="space-between" alignItems="center" mt="40px">
          <SeeAllButton
            to="all-deazy-actuals"
            data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--seeAllDeazyActualsButton`}
          >
            See all deazy actuals
          </SeeAllButton>
          {isLoadingStatus ? (
            <Spinner mr="12px" />
          ) : (
            <>
              {!isEmpty(missingActualsData) &&
                isEmpty(initialValues.deazyAllocations) &&
                isDL && (
                  <Typography variant="title">
                    To post actuals fulfill allocations first
                  </Typography>
                )}
              {!isEmpty(missingActualsData) &&
                !isEmpty(initialValues.deazyAllocations) &&
                (isDL || isAdmin) && (
                  <Button
                    ml="auto"
                    size="lg"
                    onClick={() => setShowForm(true)}
                    type="submit"
                    data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--postNewDeazyActualsButton`}
                  >
                    Post new actuals
                  </Button>
                )}
            </>
          )}
        </Flex>
      )}
    </>
  );
};

export default inject(
  'usersStore',
  'projectsStore',
  'allocationsStore',
)(observer(DeazyActuals));
