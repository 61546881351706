import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { Field, Form } from 'react-final-form';
import Typography from '@styles/Typography';
import { some, includes, keys } from 'lodash-es';
import Switcher from '@components/Switcher';
import Button from '@styles/Button';
import { ErrorText } from '@components/forms/FormError';
import { required } from '@utils/formValidators';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import SettingsStickyFooter from './SettingsStickyFooter';
import ExchangeRatesForm from './ExchangeRatesForm';

const SettingsForm = ({
  initialValues,
  forceJobs,
  isForcing,
  nukePayments,
  isNuking,
  disconnectXero,
  isDisconnecting,
  isXeroAuthorized,
  updateSettings,
  updateExchangeRates,
  isAdmin,
  isDL,
}) => {
  const onSubmit = async (values, formApi) => {
    const { dirtyFields } = formApi.getState();

    const forRates = some(keys(dirtyFields), df => {
      return includes(df, 'rate');
    });

    if (forRates) {
      await updateExchangeRates(values);
    }
    return updateSettings(values);
  };

  return (
    <Box p="40px">
      <Form
        subscription={{
          submitting: true,
          submitError: true,
          dirty: true,
          values: true,
          errors: true,
        }}
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({
          handleSubmit,
          submitting,
          dirty: isDirty,
          submitError,
          values,
          errors,
        }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              {(isAdmin || isDL) && (
                <ExchangeRatesForm values={values} ratesError={errors.rates} />
              )}
              {isAdmin && (
                <>
                  <Box mb="58px">
                    <Typography variant="h3" mb="24px">
                      Project
                    </Typography>
                    <Field name="canDeleteStartedProjects">
                      {props => (
                        <Switcher
                          id={props.input.name}
                          rightLabel="Able to delete started project"
                          onChange={e => {
                            props.input.onChange(e);
                          }}
                          defaultChecked={values.canDeleteStartedProjects}
                          fontSize={18}
                        />
                      )}
                    </Field>
                  </Box>

                  <Box width="50%" mb="58px">
                    <Typography variant="h3" mb="24px">
                      Financial
                    </Typography>

                    <Flex mt="24px" mb="28px">
                      <Field
                        name="defaultPmAmount"
                        component={MaskedTextInput}
                        decimalScale={2}
                        fixedDecimalScale
                        inputBoxAutoHeight
                        label="Default Deazy Lead amount"
                        validate={required}
                        containerProps={{ w: '45%', maxW: '287px', mr: '24px' }}
                      />
                      <Field
                        name="defaultProjectMarginPercent"
                        component={MaskedTextInput}
                        decimalScale={2}
                        fixedDecimalScale
                        inputBoxAutoHeight
                        suffix="%"
                        label="Default project margin percent"
                        validate={required}
                        containerProps={{ w: '45%', maxW: '287px' }}
                      />
                    </Flex>
                  </Box>
                  <Box>
                    <Typography variant="h3" mb="24px">
                      Other
                    </Typography>
                    <Flex alignItems="center">
                      <Field name="defaultSyncEnabled">
                        {props => (
                          <Switcher
                            id={props.input.name}
                            rightLabel="Default sync enabled"
                            onChange={e => {
                              props.input.onChange(e);
                            }}
                            defaultChecked={props.input.value}
                            fontSize={18}
                          />
                        )}
                      </Field>
                      <Button
                        ml="auto"
                        onClick={forceJobs}
                        isLoading={isForcing}
                        variant="outline"
                        mr="16px"
                      >
                        Force jobs execution
                      </Button>
                      {isXeroAuthorized && (
                        <Button
                          onClick={disconnectXero}
                          isLoading={isDisconnecting}
                          variant="outline"
                          mr="16px"
                        >
                          Disconnect from xero
                        </Button>
                      )}
                      <Button
                        mr={0}
                        onClick={nukePayments}
                        isLoading={isNuking}
                        variant="outline"
                      >
                        Nuke invoices
                      </Button>
                    </Flex>
                  </Box>

                  <Box mt="56px">
                    {submitError && (
                      <ErrorText my="12px">{submitError}</ErrorText>
                    )}
                  </Box>

                  <SettingsStickyFooter
                    submitting={submitting}
                    isDirty={isDirty}
                  />
                </>
              )}
            </form>
          );
        }}
      />
    </Box>
  );
};

export default SettingsForm;
