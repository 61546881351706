import { Avatar, Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import useMediaQuery from '@hooks/useMediaQuery';
import qs from 'query-string';
import { APP_ROUTES } from '@routes';
import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import Button from '@styles/Button';
import Badge from '@styles/Badge';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { createSupplierNamesLabel } from '@utils/projectUtils';
import { first, get } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { BRIEF_STATUSES } from '@app/constants';
import { Link, useRouteMatch, withRouter } from 'react-router-dom';
import JsxParser from 'react-jsx-parser';
import TextEllipsisWithTooltip from '../TextEllipsisWithTooltip';
import ProjectTypeBadge from '../ProjectTypeBadge';

const prepareStatusBadgeJsx = ({ brief, project } = {}) => {
  let bg = null;
  let label = brief?.status;
  if (project) {
    label = 'Active';
    if (project?.pending) {
      label = 'Pending';
    }
    if (project?.archived) {
      label = 'Archived';
    }
  }
  if (brief?.status === BRIEF_STATUSES.ON_HOLD || project?.pending) {
    bg = 'aqua';
  }
  if (brief?.status === BRIEF_STATUSES.CLOSED || project?.archived) {
    bg = 'dark';
  }
  if (
    brief?.status === BRIEF_STATUSES.OPEN ||
    (!project?.pending && !project?.archived && !brief)
  ) {
    bg = 'green';
  }
  if (!label) {
    return '';
  }
  return `
    <Badge as="span" color="dark.solid" bg="${bg}" borderColor="${bg}.mid"  
    h="18px" mr="8px" py="0px" px="4px" fontSize="10px" alignItems="center" borderRadius="4px" fontWeight="600">
      ${label}
    </Badge>
  `;
};

const prepareHeaderDetailsJsx = project => {
  let linkedBriefJsx = '';
  if (project?.brief?.id || project?.ppBrief?.id) {
    const briefLink = project?.brief?.id
      ? `/briefs/${project.brief.id}/overview`
      : `/team-augmentation-briefs/${project?.ppBrief?.id}/overview`;
    linkedBriefJsx = `<Icon name="briefs" height="13px" mr="4px" mb="4px" /><Link to="${briefLink}"
      style={{ textDecoration: 'underline' }}>Brief</Link> | `;
  }
  const duration = `${moment
    .utc(project.startDate)
    .format('DD.MM.YY')} - ${moment.utc(project.endDate).format('DD.MM.YY')}`;

  return `${prepareStatusBadgeJsx({
    project,
  })}${linkedBriefJsx}DP${
    project?.projectToSuppliers?.length > 1 ? 's' : ''
  }: ${createSupplierNamesLabel(project)} | Client: ${get(
    project,
    'client.name',
    '-',
  )} | Duration: ${duration}`;
};

const arrowButtonProps = {
  variant: 'ghost',
  iconButton: { name: 'arrowLeft', fontSize: 24 },
  minWidth: '24px',
  mr: '8px',
  color: 'gray.500',
  padding: 0,
  _hover: {
    color: 'gray.400',
  },
  as: Link,
};

const TopbarHeader = ({
  routerStore: {
    history: {
      location: { search, pathname },
    },
    getLastDpListingUrl,
    prevProjectsListUrl,
    prevPnPBriefsListUrl,
    prevBriefsListUrl,
  },
  briefsStore: { brief, isLoading: isLoadingBrief },
  projectsStore: { project, isLoading: isLoadingProject },
  teamsStore: { supplier },
  usersStore: { isTeamAdmin, profile, getFullRoleName },
  // appStore: {
  // featureFlags: { 'announce-kit': announceKit },
  // },
  proposalsStore: { pnpPersistedProposalFormData, setSelectedFeatureId },
  title,
  isBrief,
  isDp,
  isProject,
  isLoadingTitle,
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');
  const topHeaderTitleWidth = isSmallerScreen
    ? 'calc(100% - 52px)'
    : 'calc(100% - 200px)';

  const isDeazyLayerBriefPage = useRouteMatch(
    '/briefs/:briefId/proposals/:proposalId/:type',
  );
  const isDeazyLayerPPBriefPage = useRouteMatch(
    '/team-augmentation-briefs/:briefId/proposals/:proposalId/:type',
  );

  const isMobileSingleFeaturePageForTeamAdmin = useRouteMatch(
    '/briefs/:briefId/proposal/features/:featureId',
  );

  const isMobileSingleFeaturePageForClient = useRouteMatch(
    '/briefs/:briefId/proposals/:proposalId/features/:featureId',
  );

  const isMobileSingleFeaturePageForAdminOrDL = useRouteMatch(
    '/briefs/:briefId/proposals/:proposalId/delivery-partner/features/:featureId',
  );

  const isDeazyLayerPage = isDeazyLayerBriefPage || isDeazyLayerPPBriefPage;

  const isOldInvoicePage = useRouteMatch(
    `/clients/:clientSlug/projects/:projectSlug/invoices/:id`,
  );
  const isInvoicePage = useRouteMatch(
    `/clients/:clientSlug/projects/:projectSlug/payments/invoices/:id`,
  );
  const isAllChangeRequests = useRouteMatch(
    `/clients/:clientSlug/projects/:projectSlug/change-requests/all`,
  );

  const isNewDeliveryPartnerPage = useRouteMatch(
    `/clients/:clientSlug/projects/:projectSlug/new-delivery-partner`,
  );

  const isDevProfilesPage = useRouteMatch(
    '/team-augmentation-briefs/:briefId/dev-profiles',
  );

  const isPnPCreate = useRouteMatch(`/team-augmentation-briefs/create`);

  const isProjectBriefCreate = useRouteMatch(`/briefs/create`);

  const isMobileProposalFeaturePage = React.useMemo(() => {
    if (
      isMobileSingleFeaturePageForTeamAdmin ||
      isMobileSingleFeaturePageForClient ||
      isMobileSingleFeaturePageForAdminOrDL
    ) {
      return true;
    }
    return false;
  }, [
    isMobileSingleFeaturePageForTeamAdmin,
    isMobileSingleFeaturePageForClient,
    isMobileSingleFeaturePageForAdminOrDL,
  ]);

  const isWithoutDetails =
    isNewDeliveryPartnerPage ||
    isMobileProposalFeaturePage ||
    isDevProfilesPage;

  const backLinkForBriefMergere = React.useMemo(() => {
    const { ppBriefId } = qs.parse(search);
    if (pathname === APP_ROUTES.projects && ppBriefId) {
      return APP_ROUTES.briefPlugAndPlayById(ppBriefId);
    }
  }, [search, pathname]);

  const {
    bottomTextJsx,
    backButtonLink,
    clientName,
    logo,
    mainText,
  } = React.useMemo(() => {
    if (isDp) {
      return {
        logo: supplier?.logo,
        clientName: supplier?.name,
        mainText: supplier?.name,
        backButtonLink:
          !isTeamAdmin && (getLastDpListingUrl() || APP_ROUTES.teams.suppliers),
      };
    }

    const briefLink = brief.isPlugAndPlay
      ? prevPnPBriefsListUrl.url
      : prevBriefsListUrl.url;

    if (isBrief) {
      let boldText = brief.name;
      let link = isDeazyLayerPage
        ? `${briefLink}/${brief.id}/proposals`
        : prevBriefsListUrl.url;
      if (isMobileSingleFeaturePageForTeamAdmin) {
        boldText = 'Feature detail';
        link = `${briefLink}/${brief.id}/proposal`;
      }

      if (isDevProfilesPage) {
        boldText = `Add a ${pnpPersistedProposalFormData?.currentResourceName ||
          ''} candidate to ${brief.name}`;
        link = `${briefLink}/${brief.id}/proposal`;
      }

      if (isMobileSingleFeaturePageForClient) {
        const {
          params: { proposalId },
        } = isMobileSingleFeaturePageForClient;
        boldText = 'Feature detail';
        link = `${briefLink}/${brief.id}/proposals/${proposalId}`;
      }

      if (isMobileSingleFeaturePageForAdminOrDL) {
        const {
          params: { proposalId },
        } = isMobileSingleFeaturePageForAdminOrDL;
        boldText = 'Feature detail';
        link = `${briefLink}/${brief.id}/proposals/${proposalId}/delivery-partner`;
      }

      return {
        logo: get(brief, 'customer.logo'),
        clientName: get(brief, 'customer.name', 'Deazy'),
        mainText: boldText,
        backButtonLink: link,
        bottomTextJsx: `${prepareStatusBadgeJsx({ brief })}Client lead: ${get(
          brief,
          'clientLead.fullname',
          '-',
        )}  |  Client: ${get(
          brief,
          'customer.name',
          '-',
        )} | Created at: ${moment.utc(brief.createdAt).format('DD.MM.YY')}`,
      };
    }
    if (isProject) {
      let link = prevProjectsListUrl.url;
      let boldText = project.name;

      if (isOldInvoicePage) {
        link = APP_ROUTES.projectTabWithSlugs(
          project?.client?.slug,
          project?.slug,
          'invoices',
        );
      }
      if (isInvoicePage) {
        link = APP_ROUTES.projectTabWithSlugs(
          project?.client?.slug,
          project?.slug,
          'payments',
        );
      }
      if (isAllChangeRequests) {
        link = APP_ROUTES.projectTabWithSlugs(
          project?.client?.slug,
          project?.slug,
          `change-requests`,
        );
      }

      if (isNewDeliveryPartnerPage) {
        link = APP_ROUTES.projectTabWithSlugs(
          project?.client?.slug,
          project?.slug,
          `allocations`,
        );
        boldText = 'Setup allocation for new Delivery Partner';
      }

      return {
        logo: get(project, 'client.logo'),
        clientName: get(project, 'client.name', 'Deazy'),
        backButtonLink: link,
        bottomTextJsx: prepareHeaderDetailsJsx(project),
        mainText: `${boldText} <ProjectTypeBadge type="${project?.engagementType}" />`,
      };
    }
    return {};
  }, [
    brief,
    project,
    project.pending,
    project.endDate,
    isDeazyLayerPage,
    isOldInvoicePage,
    isAllChangeRequests,
    isNewDeliveryPartnerPage,
    isMobileSingleFeaturePageForTeamAdmin,
    isMobileSingleFeaturePageForClient,
    isMobileSingleFeaturePageForAdminOrDL,
    supplier,
    isDp,
    getLastDpListingUrl,
    isDevProfilesPage,
  ]);

  // eslint-disable-next-line no-unused-vars
  const userType = React.useMemo(() => {
    const role = first(profile?.roles)?.name;
    return getFullRoleName(role);
  }, [profile]);

  const StyledTextEllipsisWithTooltip = styled(TextEllipsisWithTooltip)`
    max-width: 700px;
    @media (${breakpoint.xl}) {
      max-width: 500px;
    }
    @media (${breakpoint.md}) {
      max-width: 350px;
    }
    @media (${breakpoint.sm}) {
      max-width: 200px;
    }
  `;

  if (!isBrief && !isProject && !isDp) {
    if (isLoadingTitle) {
      return <Spinner variant="small" m="0px" />;
    }

    return (
      <Flex
        alignItems="center"
        pr="16px"
        w={topHeaderTitleWidth}
        overflow="hidden"
      >
        {(isPnPCreate || isProjectBriefCreate) && (
          <Button {...arrowButtonProps} to={APP_ROUTES.briefsStartCreate} />
        )}
        {backLinkForBriefMergere && (
          <Button {...arrowButtonProps} to={backLinkForBriefMergere} />
        )}
        <Typography variant="h4" mr="12px">
          {title}
        </Typography>
        {/* {announceKit && (
          <AnnounceKit
            widget="https://announcekit.co/widgets/v2/2FTahy"
            data={{ user_type: userType }}
          />
        )} */}
      </Flex>
    );
  }

  return (
    <Flex
      alignItems="center"
      pr="12px"
      w={topHeaderTitleWidth}
      overflow="hidden"
    >
      {backButtonLink && (
        <Button
          {...arrowButtonProps}
          to={backButtonLink}
          onClick={() => {
            if (isMobileProposalFeaturePage) {
              setSelectedFeatureId(null);
            }
          }}
        />
      )}
      {isLoadingBrief || isLoadingProject || isLoadingTitle ? (
        <Spinner variant="small" m="0px" />
      ) : (
        <>
          {!isWithoutDetails && (
            <Avatar
              name={clientName}
              mr="7px"
              src={logo}
              backgroundColor={logo && 'transparent'}
            />
          )}
          <Flex mt="5px" flexDir="column" w="calc(100% - 85px)">
            <StyledTextEllipsisWithTooltip mb="5px">
              <JsxParser
                renderInWrapper={false}
                jsx={mainText}
                components={{
                  ProjectTypeBadge,
                }}
              />
            </StyledTextEllipsisWithTooltip>
            {!isWithoutDetails && bottomTextJsx && (
              <JsxParser
                jsx={`<StyledTextEllipsisWithTooltip variant="text2">${bottomTextJsx}</StyledTextEllipsisWithTooltip>`}
                components={{
                  Link,
                  Typography,
                  Icon,
                  Badge,
                  StyledTextEllipsisWithTooltip,
                }}
              />
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default withRouter(
  inject(
    'usersStore',
    'teamsStore',
    'briefsStore',
    'projectsStore',
    'routerStore',
    'proposalsStore',
    'appStore',
  )(observer(TopbarHeader)),
);
