import { StickyFooter } from '@components/StickyFooterCostBox';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import Button from '@styles/Button';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormSpy } from 'react-final-form';

const buttonStyles = isSmallerScreen => ({
  size: 'lg',
  ...(isSmallerScreen && { minWidth: '167px' }),
});

const WizardFooter = ({
  onPrevClick,
  isLast = false,
  submitting = false,
  disableSubmit,
  briefsStore: { setFlag },
  usersStore: { isClient },
  mainNameQA,
}) => {
  const setThisFlag = () => {
    if (!isClient) {
      setFlag();
    }
  };
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  return (
    <>
      <StickyFooter
        display="flex"
        justifyContent="center"
        alignItems="center"
        h="auto"
        {...(isSmallerScreen && {
          w: '100%',
          left: 0,
          bottom: '88px',
          p: '16px',
        })}
      >
        {onPrevClick && (
          <FormSpy subscription={{ values: true }}>
            {({ values, form: formApi }) => (
              <Button
                variant="outline"
                onClick={() => onPrevClick(values, formApi)}
                data-test-id={`${mainNameQA}--previousStep`}
                mr="auto"
                {...buttonStyles(isSmallerScreen)}
              >
                Previous Step
              </Button>
            )}
          </FormSpy>
        )}
        {isLast ? (
          <Button
            type="submit"
            isLoading={submitting}
            isDisabled={submitting || disableSubmit}
            data-test-id={`${mainNameQA}--finish`}
            ml="auto"
            {...buttonStyles(isSmallerScreen)}
          >
            Finish
          </Button>
        ) : (
          <Button
            type="submit"
            onClick={setThisFlag}
            isLoading={submitting}
            data-test-id={`${mainNameQA}--nextStep`}
            ml="auto"
            {...buttonStyles(isSmallerScreen)}
          >
            Next Step
          </Button>
        )}
      </StickyFooter>
    </>
  );
};

export default inject('briefsStore', 'usersStore')(observer(WizardFooter));
