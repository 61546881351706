import { Box, Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { formatNumber, roundTheseHours } from '@utils';
import React from 'react';

const TableHead = () => (
  <Flex w="100%" mb="12px" mt="24px" color="dark.solid">
    <Typography pl="8px" flexGrow={1} variant="caption">
      Resource name
    </Typography>
    <Typography pl="8px" variant="caption" w="116px">
      % of time
    </Typography>
    <Typography pl="8px" variant="caption" w="116px">
      No of hours
    </Typography>
    <Typography pl="8px" variant="caption" w="108px">
      Cost
    </Typography>
  </Flex>
);

const ResourceRowDesktop = ({
  id,
  name,
  cost,
  hours,
  percentOfTime,
  mainNameQA,
}) => {
  return (
    <Flex
      key={`resource-row-${id}`}
      borderRadius="8px"
      w="100%"
      h="72px"
      mb="12px"
      bg="#fff"
      border="1px solid"
      borderColor="dark.mid"
      borderRight="none"
      borderTopLeftRadius="8px"
      borderBottomLeftRadius="8px"
    >
      <Box
        alignSelf="center"
        flexGrow="1"
        display="flex"
        h="70px"
        borderRight="1px solid"
        borderColor="dark.mid"
        borderTopLeftRadius="8px"
        borderBottomLeftRadius="8px"
      >
        <Typography
          alignSelf="center"
          ml="20px"
          data-test-id={mainNameQA && `${mainNameQA}--resourceBreakdownName`}
        >
          {name}
        </Typography>
      </Box>
      <Box
        alignSelf="center"
        display="flex"
        w="116px"
        h="70px"
        borderRight="1px solid"
        borderColor="dark.mid"
      >
        <Typography
          alignSelf="center"
          ml="12px"
          data-test-id={
            mainNameQA && `${mainNameQA}--resourceBreakdownPercentOfTime`
          }
        >
          {formatNumber(percentOfTime)}
        </Typography>
      </Box>
      <Box
        alignSelf="center"
        display="flex"
        w="116px"
        h="70px"
        borderRight="1px solid"
        borderColor="dark.mid"
      >
        <Typography
          alignSelf="center"
          ml="12px"
          data-test-id={mainNameQA && `${mainNameQA}--resourceBreakdownHours`}
        >
          {roundTheseHours(hours)}
        </Typography>
      </Box>
      <Box
        alignSelf="center"
        display="flex"
        w="108px"
        h="70px"
        borderRight="1px solid"
        borderColor="dark.mid"
        borderTopRightRadius="8px"
        borderBottomRightRadius="8px"
      >
        <Typography
          alignSelf="center"
          ml="12px"
          data-test-id={mainNameQA && `${mainNameQA}--resourceBreakdownCost`}
        >
          {cost}
        </Typography>
      </Box>
    </Flex>
  );
};

const ResourceRowMobile = ({
  id,
  name,
  cost,
  hours,
  percentOfTime,
  mainNameQA,
}) => {
  return (
    <Box
      key={id}
      mb="16px"
      bgColor="primary.100"
      borderBottomLeftRadius="8px"
      borderBottomRightRadius="8px"
    >
      <Box
        backgroundColor="primary.200"
        px="12px"
        py="8px"
        borderTopLeftRadius="8px"
        borderTopRightRadius="8px"
      >
        <Typography variant="h5" mt="8px">
          {name}
        </Typography>
      </Box>

      <Box p="12px">
        <Flex mb="12px">
          <Box width="100%" mr="4px">
            <Typography mb="8px">% of time</Typography>
            <Flex
              h="48px"
              border="1px solid"
              borderColor="dark.mid"
              borderRadius="8px"
              bgColor="#fff"
              px="12px"
              py="10px"
            >
              <Typography
                alignSelf="center"
                data-test-id={
                  mainNameQA && `${mainNameQA}--resourceBreakdownPercentOfTime`
                }
              >
                {formatNumber(percentOfTime)}
              </Typography>
            </Flex>
          </Box>

          <Box width="100%" ml="4px">
            <Typography mb="8px">No of hours</Typography>
            <Flex
              h="48px"
              px="12px"
              py="10px"
              border="1px solid"
              borderColor="dark.mid"
              borderRadius="8px"
              bgColor="#fff"
            >
              <Typography
                alignSelf="center"
                data-test-id={
                  mainNameQA && `${mainNameQA}--resourceBreakdownHours`
                }
              >
                {roundTheseHours(hours)}
              </Typography>
            </Flex>
          </Box>
        </Flex>
        <Box>
          <Typography mb="8px">Cost</Typography>
          <Flex
            h="48px"
            border="1px solid"
            borderColor="dark.mid"
            borderRadius="8px"
            bgColor="#fff"
            px="12px"
            py="10px"
          >
            <Typography
              alignSelf="center"
              data-test-id={
                mainNameQA && `${mainNameQA}--resourceBreakdownCost`
              }
            >
              {cost}
            </Typography>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

const ResourcesBreakdownTable = ({
  resources,
  mainNameQA,
  isSmallerScreen,
}) => (
  <>
    {!isSmallerScreen && <TableHead />}
    {resources.map(r =>
      isSmallerScreen ? (
        <ResourceRowMobile mainNameQA={mainNameQA} {...r} />
      ) : (
        <ResourceRowDesktop mainNameQA={mainNameQA} {...r} />
      ),
    )}
  </>
);
export default ResourcesBreakdownTable;
