import {
  INVOICE_NUMBERING_TIMESPAN_OPTIONS,
  LOCATION_OPTIONS,
} from '@app/constants';
import Button from '@styles/Button';
import { Box, SimpleGrid, Stack } from '@chakra-ui/react';
import Checkbox from '@components/forms/_common/Checkbox';
import Select from '@components/forms/_common/Select';
import Textarea from '@components/forms/_common/Textarea';
import { forEach, isEmpty, join } from 'lodash-es';
import TextInput from '@components/forms/_common/TextInput';
import { mustBeCommaSeparatedEmails, required } from '@utils/formValidators';
import React from 'react';
import FormError from '@components/forms/_common/FormError';
import { Field, Form } from 'react-final-form';
import WhiteCard from '@components/WhiteCard';
import FileUpload from './FileUpload';
import { CurrencyFormFields } from './CurrencyForm';

const validate = ({ paymentDetails }) => {
  const errors = {};
  if (isEmpty(paymentDetails)) {
    errors.paymentError = 'At least one currency must be added.';
  } else {
    forEach(
      paymentDetails,
      ({ accountNumber, IBAN, BIC, sortCode, currency }, idx) => {
        if (currency) {
          if (accountNumber) {
            if (!(BIC || sortCode)) {
              errors[
                `currencyError${idx}`
              ] = `Finance data incorrect! ${currency} requires BIC or sort code with account number`;
            }
          } else if (IBAN) {
            if (!BIC) {
              errors[
                `currencyError${idx}`
              ] = `Finance data incorrect! ${currency} requires BIC with IBAN`;
            }
          } else {
            errors[
              `currencyError${idx}`
            ] = `Finance data incorrect! ${currency} needs either IBAN or account number`;
          }
        }
      },
    );
  }
  return errors;
};

const SupplierCreateForm = ({
  onSubmit,
  supplier,
  initialValues,
  removeCurrencyFromSupplier,
  setCurrencyAsPrimaryForSupplier,
}) => (
  <Form
    initialValues={{
      ...initialValues,
      ...supplier,
      financeEmails: join(supplier.financeEmails, ', '),
    }}
    onSubmit={onSubmit}
    validate={validate}
    render={({ handleSubmit, values, submitting, form }) => {
      return (
        <form onSubmit={handleSubmit} autoComplete="off">
          <WhiteCard mb="24px">
            <Box mt="32px" mb="45px">
              <Field
                name="logo"
                component={({ input }) => (
                  <FileUpload
                    value={input.value}
                    onUploaded={val => input.onChange(val)}
                    label="Upload image"
                    labelToChangeFile="Change profile photo"
                    allowedExtensions={['.png', '.jpg', '.jpeg']}
                  />
                )}
              />
            </Box>
            <SimpleGrid columns={2} spacing="24px">
              <Box>
                <Field
                  name="name"
                  component={TextInput}
                  placeholder="Enter name"
                  label="Name"
                  validate={required}
                  containerProps={{ mb: '24px' }}
                />
              </Box>
              <Box>
                <Field
                  name="invoiceName"
                  component={TextInput}
                  placeholder="Enter invoice name"
                  label="Invoice Name"
                  containerProps={{ mb: '24px' }}
                />
              </Box>
              <Box>
                <Field
                  name="location"
                  component={Select}
                  options={LOCATION_OPTIONS}
                  label="Location"
                  styleProps={{ mb: '24px' }}
                />
              </Box>
              <Box>
                <Field
                  name="invoiceNumberingTimespan"
                  component={Select}
                  options={INVOICE_NUMBERING_TIMESPAN_OPTIONS}
                  label="Invoice Numbering Timespan"
                  styleProps={{ mb: '24px' }}
                />
              </Box>
              <Box>
                <Field
                  name="noVat"
                  variant="secondary"
                  component={Checkbox}
                  type="checkbox"
                  checked={values.noVat}
                  id="noVat"
                  bg="black"
                  label="Non VAT registered company"
                  labelProps={{ fontSize: '14px' }}
                  containerStyles={{ mr: '16px' }}
                  onAfterChange={() => {
                    form.change('vatNumber', '');
                  }}
                />
              </Box>
              <Box />
              {!values.noVat && (
                <Box>
                  <Field
                    name="vatNumber"
                    label="Vat Number"
                    validate={required}
                    placeholder="Vat Number"
                    component={TextInput}
                    containerProps={{ mb: '24px' }}
                    parse={value => (value === '' ? '' : value)}
                  />
                </Box>
              )}
              <Box>
                <Field
                  name="financeEmails"
                  label="Finance Email(s)"
                  placeholder="Finance Email(s)"
                  component={TextInput}
                  validate={mustBeCommaSeparatedEmails}
                  containerProps={{ mb: '24px' }}
                />
              </Box>
              <Box>
                <Field
                  name="paymentInfo"
                  label="Invoice Info"
                  placeholder="Invoice Info"
                  component={Textarea}
                  validate={required}
                  mb="24px"
                />
              </Box>
              <Box>
                <Field
                  name="invoiceNumberingPattern"
                  label="Invoice Numbering Pattern"
                  placeholder="[D]/#NO#/M/YYYY"
                  component={TextInput}
                  parse={value => (value === '' ? '' : value)}
                  containerProps={{ mb: '24px' }}
                />
              </Box>
            </SimpleGrid>
          </WhiteCard>
          <Field name="paymentError">
            {({ meta }) => <FormError mt="48px" meta={meta} />}
          </Field>
          <CurrencyFormFields
            values={values}
            form={form}
            removeCurrencyFromSupplier={removeCurrencyFromSupplier}
            setCurrencyAsPrimaryForSupplier={setCurrencyAsPrimaryForSupplier}
          />

          <Stack mt="40px" spacing={3} width="100%" mb="80px">
            <Button isLoading={submitting} type="submit">
              {`${supplier.id ? 'Update' : 'Add'} Delivery Partner`}
            </Button>
          </Stack>
        </form>
      );
    }}
  />
);

export default SupplierCreateForm;
