import { last, split } from 'lodash-es';
import React from 'react';

const useFileNameTurncate = ({
  containerRef,
  textRef,
  fileName,
  additionalOffset = 0,
}) => {
  const extension = last(split(fileName, '.')) || '';
  const splitedText = (fileName || '').slice(0, -(extension.length + 1));

  const [turncatedText, setTurncatedText] = React.useState('');

  React.useEffect(() => {
    if (fileName) {
      if (
        containerRef?.current &&
        textRef?.current &&
        !(
          containerRef.current.offsetWidth <
          textRef.current.offsetWidth + additionalOffset
        )
      ) {
        setTurncatedText(`${splitedText}.${extension}`);
      } else {
        setTurncatedText(`${splitedText}...${extension}`);
      }
    } else {
      setTurncatedText('');
    }
  }, [fileName]);

  React.useEffect(() => {
    if (
      containerRef?.current &&
      textRef?.current &&
      containerRef.current.offsetWidth <
        textRef.current.offsetWidth + additionalOffset
    ) {
      const [t = ''] = split(turncatedText, '...');
      setTurncatedText(`${t.slice(0, -2)}...${extension}`);
    }
  }, [turncatedText]);

  return turncatedText;
};

export default useFileNameTurncate;
