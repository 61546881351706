import { action, makeObservable } from 'mobx';

export default class MailingStore {
  constructor({ API, toastsStore }) {
    makeObservable(this);
    this.toastsStore = toastsStore;
    this.API = API;
  }

  @action sendMailingTemplate = async ({ email, template }, formApi) => {
    try {
      await this.API.postMailingTemplate({ email, template });
      this.toastsStore.showSuccess({
        title: 'Test mail has been sent successfully.',
      });
      setTimeout(formApi.restart);
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };
}
