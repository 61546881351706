import { Box } from '@chakra-ui/react';
import { has } from 'lodash-es';
import React from 'react';
import Icon from '@components/Icon';

const BASE_STYLES = {
  borderRadius: '8px',
  display: 'inline-flex',
  p: '4px 12px 4px 12px',
  fontSize: 12,
  textTransform: 'uppercase',
};

const VARIANTS = bg => ({
  primary: {
    bg: `${bg}.light`,
    border: '1px solid',
    borderColor: `${bg}.solid`,
    color: `${bg}.solid`,
  },
  secondary: {
    bg: `${bg}.solid`,
    color: 'white',
  },
  tertiary: {
    bg: `${bg}`,
    color: 'white',
  },
});

const Badge = React.forwardRef(
  (
    {
      variant = 'primary',
      bg = 'blue',
      children,
      onClose,
      isClosable,
      ...props
    },
    ref,
  ) => {
    const styles = has(VARIANTS(bg), variant)
      ? VARIANTS(bg)[variant]
      : VARIANTS(bg).primary;
    return (
      <Box
        position="relative"
        {...BASE_STYLES}
        {...styles}
        {...props}
        ref={ref}
      >
        {children}
        {isClosable && (
          <Box
            ml="6px"
            pr="8px"
            borderLeft="1px solid"
            borderColor="dark.mid"
            _hover={{
              opacity: 0.5,
              cursor: 'pointer',
            }}
            onClick={onClose}
          >
            <Icon name="cross" fontSize={16} position="absolute" top="3px" />
          </Box>
        )}
      </Box>
    );
  },
);

export default Badge;
