import React from 'react';
import { Field, useFormState } from 'react-final-form';
import FormError from '@components/forms/FormError';

const SubmitFailedError = ({ fieldName, ...props }) => {
  const { submitFailed } = useFormState({
    subscription: { submitFailed: true },
  });
  return (
    <Field name={fieldName}>
      {({ meta }) =>
        submitFailed ? (
          <FormError mt="24px" meta={meta} ml="39px" {...props} />
        ) : null
      }
    </Field>
  );
};

export default SubmitFailedError;
