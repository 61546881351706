import { Box, Flex } from '@chakra-ui/react';
import { formatCurrency, getDeazyMarginFactor } from '@utils';
import React from 'react';
import TextEllipsisWithTooltip from '@components/TextEllipsisWithTooltip';
import { isNil, round } from 'lodash-es';
import Typography from '@styles/Typography';
import {
  getFormattedRates,
  isAdvancedFrameworkCheck,
} from '@utils/proposalUtils';
import { inject, observer } from 'mobx-react';
import Icon from '@components/Icon';
import { Cell, CellWithLink } from './TeamRoleRow';
import ResourceRateIndicator from '../Proposal/ResourceRateIndicator';
import ChangeRateReasonExpander from './ChangeRateReasonExpander';

const PPRoleRow = ({
  idx,
  resource,
  currency = 'GBP',
  formApi,
  proposalSupplierCurrency,
  proposalExchangeRate,
  isReadOnly,
  ratesStore: { getResourceRate },
  briefsStore: { brief },
  isSmallerScreen,
  mainNameQA,
}) => {
  const costPerMonth = resource.clientRate * 160;

  const { showComment, dpTargetRate, clientRateRange } = React.useMemo(() => {
    const rates = getResourceRate(
      resource.role,
      resource.seniority,
      true,
      isAdvancedFrameworkCheck(resource?.briefResource?.technologies),
      brief?.customer?.type,
    );
    return {
      showComment: +resource.rate > +rates.rate,
      ...getFormattedRates({
        ...rates,
        currency,
        supplierCurrency: proposalSupplierCurrency,
      }),
    };
  }, [resource, currency, proposalSupplierCurrency]);

  return (
    <>
      <Flex
        flexDir="column"
        borderRadius="8px"
        data-test-id={mainNameQA && `${mainNameQA}--resourceRow`}
        {...(!isSmallerScreen
          ? {
              border: '1px solid',
              borderColor: 'dark.mid',
              h: '72px',
              w: '100%',
              mb: '12px',
              flexDir: 'row',
            }
          : { py: '24px', px: '12px', bg: '#fff', mb: '12px' })}
      >
        <Cell
          flex={0.5}
          opacity={isReadOnly && 0.5}
          asInput={isSmallerScreen}
          data-test-id={mainNameQA && `${mainNameQA}--resourceName`}
        >
          {resource.name}
        </Cell>
        <Cell
          flex={0.5}
          opacity={isReadOnly && 0.5}
          asInput={isSmallerScreen}
          data-test-id={mainNameQA && `${mainNameQA}--resourceRole`}
        >
          {resource.role}
        </Cell>
        <Cell
          flex={0.5}
          opacity={isReadOnly && 0.5}
          asInput={isSmallerScreen}
          data-test-id={mainNameQA && `${mainNameQA}--resourceSeniority`}
        >
          {resource.seniority}
        </Cell>
        <Cell
          w="145px"
          position="relative"
          asInput={isSmallerScreen}
          label="Delivery partner rate"
          data-test-id={mainNameQA && `${mainNameQA}--resourceDPRate`}
        >
          <Box as="span" opacity={isReadOnly && 0.5}>
            {formatCurrency(
              resource.rate,
              resource?.ppBriefProposal?.overriddenCurrency,
            )}
          </Box>
          <ResourceRateIndicator
            formApi={formApi}
            position="absolute"
            isDeazyView
            top="33%"
            isPnP
            right="5px"
            zIndex={30}
            prefix={`resources[${idx}]`}
          />
        </Cell>
        {isSmallerScreen && (
          <Typography variant="text4" color="gray.mid" mt="-6px" mb="16px">
            {dpTargetRate && `DP target rate: ${dpTargetRate}`}
          </Typography>
        )}
        <CellWithLink
          cellProps={{
            w: '145px',
            ...(isSmallerScreen && {
              mb: '12px',
              w: '100%',
              borderRight: 'none',
              p: 0,
            }),
          }}
          name={`resources[${idx}].clientRate`}
          data-test-id={
            mainNameQA && `${mainNameQA}--resources[${idx}].clientRate`
          }
          currency={currency}
          {...(isSmallerScreen && { label: 'Client rate' })}
          iconProps={{
            opacity: 0.5,
            ...(resource.autoMargin && { name: 'unlink' }),
            zIndex: 20,
          }}
          onAfterChange={clientRate => {
            formApi.batch(() => {
              const margin = round(
                100 * (1 - (resource.rate * proposalExchangeRate) / clientRate),
                2,
              );
              formApi.change(`resources[${idx}].margin`, margin);
              formApi.change(`resources[${idx}].autoClientRate`, false);
              formApi.change(`resources[${idx}].autoMargin`, true);
            });
          }}
          changeIfActive
          isReadOnly={isReadOnly}
          asInput={isSmallerScreen}
        />
        {isSmallerScreen && (
          <Typography
            variant="text4"
            color="gray.mid"
            mt="-6px"
            mb="16px"
            data-test-id={
              mainNameQA && `${mainNameQA}--resources[${idx}].clientRateChange`
            }
          >
            {clientRateRange && `Client rate: ${clientRateRange}`}
          </Typography>
        )}
        <CellWithLink
          {...(isSmallerScreen && { label: '% Margin' })}
          data-test-id={mainNameQA && `${mainNameQA}--resources[${idx}].margin`}
          cellProps={{
            w: '100px',
            ...(isSmallerScreen && {
              mb: '12px',
              w: '100%',
              borderRight: 'none',
              p: 0,
            }),
          }}
          name={`resources[${idx}].margin`}
          decimalScale={0}
          iconProps={{
            opacity: 0.5,
            ...(resource.autoClientRate && { name: 'unlink' }),
            zIndex: 20,
          }}
          onAfterChange={margin => {
            formApi.batch(() => {
              formApi.change(
                `resources[${idx}].clientRate`,
                resource.rate *
                  getDeazyMarginFactor(margin) *
                  proposalExchangeRate,
              );
              formApi.change(`resources[${idx}].autoClientRate`, true);
              formApi.change(`resources[${idx}].autoMargin`, false);
            });
          }}
          allowNegative
          changeIfActive
          isAllowed={({ floatValue }) => {
            return (floatValue >= 0 && floatValue <= 99) || isNil(floatValue);
          }}
          isReadOnly={isReadOnly}
          asInput={isSmallerScreen}
        />
        <Cell
          w="145px"
          borderRight="none"
          px="0px"
          pr="8px"
          data-test-id={mainNameQA && `${mainNameQA}--resourcesCost`}
          asInput={isSmallerScreen}
          {...(isSmallerScreen && {
            label: 'Cost per month',
            w: '100%',
          })}
        >
          <Flex
            pl={!isSmallerScreen && '12px'}
            w="100%"
            h="100%"
            position="relative"
            alignItems="center"
            {...(!isSmallerScreen && {
              bg: 'blue.light',
            })}
          >
            <TextEllipsisWithTooltip pr="22px" variant="text1" fontSize={15}>
              {formatCurrency(costPerMonth, currency)}
            </TextEllipsisWithTooltip>
            <Icon
              name="link"
              color="blue.solid"
              position="absolute"
              zIndex={1}
              right="1px"
              top="22px"
              p="2px"
              {...(isSmallerScreen && {
                right: '-8px',
                top: '10px',
              })}
              fontSize={24}
            />
          </Flex>
        </Cell>
        {isSmallerScreen && showComment && (
          <ChangeRateReasonExpander reason={resource.comments} />
        )}
      </Flex>
      {!isSmallerScreen && (
        <Flex w="100%" mb="24px" position="relative">
          <Box flex={1.5}>
            {showComment && (
              <ChangeRateReasonExpander
                reason={resource.comments}
                textProps={{ position: 'absolute' }}
              />
            )}
          </Box>
          <Typography w="145px" variant="text4" color="gray.mid">
            {dpTargetRate && `DP target rate: ${dpTargetRate}`}
          </Typography>
          <Typography w="145px" variant="text4" color="gray.mid">
            {clientRateRange && `Client rate: ${clientRateRange}`}
          </Typography>
          <Box w="245px" />
        </Flex>
      )}
    </>
  );
};

export default inject('ratesStore', 'briefsStore')(observer(PPRoleRow));
