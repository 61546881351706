import { Button, Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { isFixedPrice } from '@utils/projectUtils';
import { find, get, isEmpty, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AllocationCard from '../AllocationCard';
import { getAllocationsColumns } from '../constants';

const PendingTeamAllocations = ({
  projectId,
  project,
  supplierId,
  allocationsStore: {
    pendingAllocations: pAllocations,
    approveAllocations,
    rejectAllocations,
  },
  usersStore: { isTeamAdmin, isClient, isTeamMember },
}) => {
  const pendingAllocations = get(
    find(pAllocations, allocs => allocs?.supplier?.id === supplierId),
    'allocations',
    [],
  );

  if (isEmpty(pendingAllocations)) {
    return (
      <Typography textAlign="center" variant="h4" my="20px">
        No pending team allocations!
      </Typography>
    );
  }
  return (
    <>
      <Typography variant="h4" my="20px">
        Pending allocations:
      </Typography>
      {map(pendingAllocations, allocation => (
        <AllocationCard
          key={`allocation-entry-card-${allocation.id}`}
          allocation={allocation}
          columns={getAllocationsColumns({
            isTeamAdmin,
            isClient,
            isTeamMember,
            isFixedPrice: isFixedPrice(project),
          })}
          mb="20px"
        >
          {allocation.canApprove && (
            <Flex justifyContent="flex-end" mt="32px">
              <Button
                variant="outline"
                fontWeight="400"
                color="red.solid"
                borderWidth="2px"
                borderColor="red.solid"
                borderRadius="30px"
                py="10px"
                px="24px"
                h="auto"
                mr="12px"
                onClick={() =>
                  rejectAllocations(projectId, supplierId, allocation.id)
                }
                isLoading={allocation.isRejecting}
                isDisabled={allocation.isApproving}
              >
                Reject
              </Button>
              <Button
                borderWidth="2px"
                variant="outline"
                fontWeight="400"
                color="blue.solid"
                borderColor="blue.solid"
                borderRadius="30px"
                py="10px"
                px="24px"
                h="auto"
                onClick={() =>
                  approveAllocations(projectId, supplierId, allocation.id)
                }
                isDisabled={allocation.isRejecting}
                isLoading={allocation.isApproving}
              >
                Accept
              </Button>
            </Flex>
          )}
        </AllocationCard>
      ))}
    </>
  );
};

export default inject(
  'usersStore',
  'allocationsStore',
)(observer(PendingTeamAllocations));
