import React from 'react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import {
  find,
  forEach,
  get,
  isEmpty,
  isEqual,
  isUndefined,
  lowerCase,
  map,
  omit,
  omitBy,
  toNumber,
} from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { PROJECT_TYPES, ENGAGEMENT_TYPES } from '@app/constants';
import {
  getAllocationMultiplier,
  isFixedPriceProjectType,
  isProjectBuild,
} from '@utils/projectUtils';

@inject('usersStore', 'projectsStore', 'settingsStore')
@observer
class ProjectFormListener extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      formApi: { change, batch, focus, blur },
      formApi,
      values,
      projectsStore: { fetchCorrectionsTotals },
      settingsStore: { calculateCurrencyRate },
      isProjectCreate = false,
    } = this.props;
    const {
      startDate,
      endDate,
      numberOfWeeks,
      numberOfDays,
      totalBasis,
      invoiceRollup,
      supplier,
      client,
      projectType,
      engagementType,
    } = values;

    const { values: prevValues } = prevProps;

    if (!isEqual(values, prevValues)) {
      batch(() => {
        if (toNumber(numberOfWeeks) === 0 && toNumber(numberOfDays) === 0) {
          change('numberOfDays', 1);
        }

        if (
          prevValues.startDate !== startDate ||
          prevValues.endDate !== endDate
        ) {
          this.updateAllocationDatesOnProjectStartOrEndChange(
            values,
            prevValues,
            formApi,
            isProjectCreate,
          );
        }

        const isDateChanged =
          startDate &&
          endDate &&
          numberOfWeeks === prevValues.numberOfWeeks &&
          numberOfDays === prevValues.numberOfDays &&
          (prevValues.startDate !== startDate ||
            prevValues.endDate !== endDate);

        const isDaysNumberChanged =
          startDate &&
          (numberOfDays !== prevValues.numberOfDays ||
            numberOfWeeks !== prevValues.numberOfWeeks) &&
          prevValues.endDate === endDate;

        const isProjectFixedPrice = isFixedPriceProjectType(values || {});
        const isPrevProjectFixedPrice = isFixedPriceProjectType(
          prevValues || {},
        );

        forEach(values?.projectToSuppliers, (pts, idx) => {
          const hasCurrencyChanged =
            pts?.overriddenCurrency !==
              (prevValues?.projectToSuppliers &&
                prevValues?.projectToSuppliers[idx]?.overriddenCurrency) ||
            values.currency !== prevValues.currency;

          if (hasCurrencyChanged) {
            const supplierCurrencyRate = getSupplierCurrencyRate(pts);
            change(
              `projectToSuppliers[${idx}].supplierCurrencyRate`,
              supplierCurrencyRate,
            );
          }
        });

        const isClientChanged = !isEqual(values.client, prevValues.client);

        const multiplier = getAllocationMultiplier(values);

        if (isClientChanged) {
          values.clientSOWReference = '';
        }

        if (isDateChanged) {
          const duration =
            moment
              .utc(endDate)
              .endOf('day')
              .diff(moment.utc(startDate).startOf('day'), 'days') + 1;
          const weeks = Math.floor(duration / 7);
          const days = duration > 6 ? duration % (weeks * 7) : duration;
          if (
            startDate &&
            endDate &&
            moment.utc(endDate).isBefore(moment.utc(startDate))
          ) {
            change('endDate', moment.utc(startDate).endOf('day'));
          } else {
            change('numberOfWeeks', weeks);
            change('numberOfDays', days);
          }
          if (!totalBasis) {
            change('multiplier', multiplier);
          }
          // recalculateProjectEndDateOnAllocationChange(formApi);
        }

        if (isDaysNumberChanged && +numberOfWeeks >= 0 && +numberOfDays >= 0) {
          let days = (+numberOfWeeks || 0) * 7 + (+numberOfDays || 0) - 1;
          days = days < 0 ? days + 1 : days;
          change(
            'endDate',
            moment
              .utc(startDate)
              .add({ days })
              .endOf('day')
              .format(),
          );
          if (numberOfDays === 0 && numberOfWeeks === 0) {
            change('numberOfDays', 1);
          }
          if (!totalBasis) {
            change('multiplier', multiplier);
          }
        }

        if (totalBasis !== prevValues.totalBasis) {
          if (isProjectFixedPrice) {
            forEach(values?.projectToSuppliers, (_, idx) => {
              recalculateAmountOnTotalBasisChange(
                `projectToSuppliers[${idx}].allocations1`,
                'deazyAllocations1',
              );
              recalculateAmountOnTotalBasisChange(
                `projectToSuppliers[${idx}].allocations2`,
                'deazyAllocations2',
              );
              recalculateAmountOnTotalBasisChange(
                `projectToSuppliers[${idx}].allocations3`,
                'deazyAllocations3',
              );
            });
          } else {
            forEach(values?.projectToSuppliers, (_, idx) => {
              recalculateAmountOnTotalBasisChange(
                `projectToSuppliers[${idx}].allocations`,
                'deazyAllocations',
              );
            });
          }
        }

        if (invoiceRollup !== prevValues.invoiceRollup && !invoiceRollup) {
          change('clientMonthlyInvoiceDescription', '');
          change('supplierMonthlyInvoiceDescription', '');
        }

        if (!isEqual(supplier, prevValues.supplier) && !isEmpty(supplier)) {
          change('overriddenCurrency', supplier.currency);
        }
        if (
          values.deazyAsSupplier &&
          values.deazyAsSupplier !== prevValues.deazyAsSupplier
        ) {
          change('projectToSuppliers', []);
        }

        if (!isEqual(client, prevValues.client) && !isEmpty(client)) {
          change('currency', client.currency);
          if (values.deazyAsSupplier) {
            focus('supplier');
            blur('supplier');
          }
          if (values.deazyAsClient) {
            change('deazyMarginPercent', '0.00');
            resetDeazyAllocations();
          }
        }
        if (
          values.deazyAsClient !== prevValues.deazyAsClient &&
          !values.deazyAsClient &&
          values.deazyMarginPercent === '0.00'
        ) {
          change('deazyMarginPercent', '20.00');
        }

        if (
          projectType !== prevValues.projectType &&
          projectType !== PROJECT_TYPES.FIXED_PRICE
        ) {
          change(
            'projectToSuppliers',
            map(values.projectToSuppliers, s => ({
              ...omit(s, [
                'supplierCorrectionPercent',
                'supplierCorrectionAbsolute',
              ]),
            })),
          );
          change('clientCorrectionPercent', '');
          change('clientCorrectionAbsolute', '');
        }

        if (isProjectFixedPrice !== isPrevProjectFixedPrice) {
          if (isProjectFixedPrice) {
            const { deazyAllocations, projectToSuppliers } = values;
            change('deazyAllocations', []);
            change(
              'projectToSuppliers',
              map(values.projectToSuppliers, s => ({
                ...omit(s, [
                  'allocations',
                  'allocations1',
                  'allocations2',
                  'allocations3',
                ]),
              })),
            );
            setTimeout(() => {
              change('deazyAllocations1', deazyAllocations);
              change(
                'projectToSuppliers',
                map(projectToSuppliers, s => {
                  const allocations1 = find(
                    values.projectToSuppliers,
                    pts => pts.id === s.id,
                  )?.allocations;
                  return {
                    ...omit(s, [
                      'allocations',
                      'allocations1',
                      'allocations2',
                      'allocations3',
                    ]),
                    allocations1,
                    allocations2: [],
                    allocations3: [],
                  };
                }),
              );
            }, 100);
          } else {
            const { deazyAllocations1, projectToSuppliers } = values;
            change('deazyAllocations', []);
            change(
              'projectToSuppliers',
              map(values.projectToSuppliers, s => ({
                ...omit(s, [
                  'allocations',
                  'allocations1',
                  'allocations2',
                  'allocations3',
                ]),
              })),
            );
            setTimeout(() => {
              change('deazyAllocations', deazyAllocations1);
              change(
                'projectToSuppliers',
                map(projectToSuppliers, s => {
                  const allocations = find(
                    values.projectToSuppliers,
                    pts => pts.id === s.id,
                  )?.allocations1;
                  return {
                    ...omit(s, [
                      'allocations',
                      'allocations1',
                      'allocations2',
                      'allocations3',
                    ]),
                    allocations,
                  };
                }),
              );
            }, 100);
          }
        }

        if (!isEqual(client, prevValues.client)) {
          change('clientLead', {});
          setTimeout(() => {
            change('clientLead', null);
          }, 10);
        }

        const isOrWasRetainer =
          engagementType !== prevValues.engagementType &&
          (prevValues.engagementType === ENGAGEMENT_TYPES.RETAINED_TEAM ||
            engagementType === ENGAGEMENT_TYPES.RETAINED_TEAM);

        if (
          isProjectCreate &&
          projectType === PROJECT_TYPES.FIXED_PRICE &&
          (projectType !== prevValues.projectType ||
            isOrWasRetainer ||
            values.clientCorrectionPercent !==
              prevValues.clientCorrectionPercent ||
            values.clientCorrectionAbsolute !==
              prevValues.clientCorrectionAbsolute ||
            isDateChanged ||
            isDaysNumberChanged ||
            !isEqual(
              values.projectToSuppliers,
              prevValues.projectToSuppliers,
            ) ||
            !isEqual(values.deazyAllocations, prevValues.deazyAllocations) ||
            !isEqual(values.deazyAllocations1, prevValues.deazyAllocations1) ||
            !isEqual(values.deazyAllocations2, prevValues.deazyAllocations2) ||
            !isEqual(values.deazyAllocations3, prevValues.deazyAllocations3))
        ) {
          fetchCorrectionsTotals(omit(values, ['id']));
        }

        function recalculateAmountOnTotalBasisChange(
          allocationFieldName,
          deazyAllocationFieldName,
        ) {
          change('multiplier', multiplier);
          change(
            allocationFieldName,
            map(get(values, allocationFieldName), a =>
              omitBy(
                {
                  ...a,
                  amount:
                    a.amount &&
                    toNumber(a.amount) *
                      (totalBasis
                        ? prevValues.multiplier || multiplier
                        : 1 / multiplier),
                },
                isUndefined,
              ),
            ),
          );
          change(
            deazyAllocationFieldName,
            map(get(values, deazyAllocationFieldName), a =>
              omitBy(
                {
                  ...a,
                  amount:
                    a.amount &&
                    toNumber(a.amount) *
                      (totalBasis
                        ? prevValues.multiplier || multiplier
                        : 1 / multiplier),
                },
                isUndefined,
              ),
            ),
          );
        }
        function resetDeazyAllocations() {
          change('deazyAllocations', [{ id: nanoid(10) }]);
          change('deazyAllocations1', [{ id: nanoid(10) }]);
          change('deazyAllocations2', [{ id: nanoid(10) }]);
          change('deazyAllocations3', [{ id: nanoid(10) }]);
        }
        function getSupplierCurrencyRate(projectToSupplier) {
          const supplierCurrency =
            projectToSupplier?.overriddenCurrency ||
            projectToSupplier?.supplier?.currency;

          return calculateCurrencyRate(
            lowerCase(supplierCurrency),
            lowerCase(values.currency),
          );
        }
      });
    }
  }

  updateAllocationDatesOnProjectStartOrEndChange = (
    project,
    prevProject,
    formApi,
    isProjectCreate,
  ) => {
    const { startDate, endDate } = project;
    const { startDate: prevStartDate, endDate: prevEndDate } = prevProject;
    const allocationMap = allocations => {
      return map(allocations, a => {
        const isEmptyRow = isEmpty(omit(a, ['id', 'startDate', 'endDate']));
        const startDateShouldBeChanged =
          isEmptyRow ||
          (a.startDate &&
            !project.isRecalculating &&
            (moment
              .utc(a.startDate)
              .isBetween(
                moment.utc(prevStartDate).startOf('day'),
                moment.utc(prevEndDate).endOf('day'),
              ) ||
              moment.utc(a.startDate).isSame(moment.utc(prevStartDate)) ||
              moment.utc(a.startDate).isSameOrBefore(startDate)));

        const endDateShouldBeChanged =
          isEmptyRow ||
          (a.endDate &&
            !project.isRecalculating &&
            (moment
              .utc(a.endDate)
              .isBetween(
                moment.utc(prevStartDate).startOf('day'),
                moment.utc(prevEndDate).endOf('day'),
              ) ||
              moment.utc(a.endDate).isSame(moment.utc(prevEndDate)) ||
              moment.utc(a.endDate).isSameOrAfter(endDate)));
        return {
          ...a,
          startDate: startDateShouldBeChanged ? startDate : a.startDate,
          endDate:
            isProjectCreate && endDateShouldBeChanged ? endDate : a.endDate,
        };
      });
    };

    if (isProjectBuild(project.engagementType)) {
      formApi.change(
        'projectToSuppliers',
        map(project.projectToSuppliers, pts => ({
          ...pts,
          allocations1: allocationMap(pts.allocations1),
          allocations2: allocationMap(pts.allocations2),
          allocations3: allocationMap(pts.allocations3),
        })),
      );
      formApi.change(
        'deazyAllocations1',
        allocationMap(project.deazyAllocations1),
      );
      formApi.change(
        'deazyAllocations2',
        allocationMap(project.deazyAllocations2),
      );
      formApi.change(
        'deazyAllocations3',
        allocationMap(project.deazyAllocations3),
      );
    } else {
      formApi.change(
        'projectToSuppliers',
        map(project.projectToSuppliers, pts => ({
          ...pts,
          allocations: allocationMap(pts.allocations),
        })),
      );
      formApi.change(
        'deazyAllocations',
        allocationMap(project.deazyAllocations),
      );
    }
  };

  render() {
    return null;
  }
}

export default ProjectFormListener;
