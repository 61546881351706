import { Tooltip as ChakraTooltip } from '@chakra-ui/react';
import React from 'react';
import { has } from 'lodash-es';

const VARIANTS = {
  primary: {
    fontSize: 12,
    fontFamily: 'WS',
    bg: 'primary.600',
    color: '#fff',
    p: '8px 12px',
    borderRadius: '8px',
    zIndex: 33000,
  },
  secondary: {
    fontSize: '16px',
    zIndex: 33000,
    borderRadius: '8px',
    lineHeight: 'normal',
    fontFamily: 'WS',
    p: '24px 12px',
  },
};

const Tooltip = ({ label, variant, children, ...props }) => {
  const styles = has(VARIANTS, variant) ? VARIANTS[variant] : VARIANTS.primary;
  return (
    <ChakraTooltip
      {...styles}
      label={label || children}
      placement={variant === 'primary' ? 'bottom' : 'top'}
      hasArrow={variant === 'secondary'}
      {...props}
    >
      {children}
    </ChakraTooltip>
  );
};

export default Tooltip;
