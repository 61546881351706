import { Box, Tooltip } from '@chakra-ui/react';
import Icon from '@components/Icon';
import React from 'react';

const PaymentProcessing = ({
  tooltipLabel = 'This payment is being processed by jobs.',
  ...props
}) => {
  return (
    <Tooltip
      zIndex={10000}
      label={tooltipLabel}
      hasArrow
      p="12px"
      placement="top"
    >
      <Box as="span">
        <Icon
          cursor="default"
          name="hourglass"
          fontSize={22}
          alignSelf="center"
          mr="100px"
          {...props}
        />
      </Box>
    </Tooltip>
  );
};

export default PaymentProcessing;
