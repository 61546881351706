import {
  find,
  get,
  isEmpty,
  isInteger,
  isNaN,
  split,
  toNumber,
  trim,
  uniq,
} from 'lodash-es';
import moment from 'moment';
import validator from 'validator';
import { API } from '@app/api';
import { sleep } from '.';

const simpleMemoize = fn => {
  let lastArg;
  let lastResult;
  return (arg, args) => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg, args);
    }
    return lastResult;
  };
};

export const projectNameRegexp = /^[a-zA-Z0-9](?:[a-zA-Z0-9 ._-]*[a-zA-Z0-9])?$/g;

let now = null;

export const validateBriefName = simpleMemoize(async (value, allValues) => {
  if (!value) {
    return undefined;
  }
  // omit validation if name is the same as editted one
  if (allValues.id && value === allValues.originalName) {
    return undefined;
  }
  if (!trim(value).match(projectNameRegexp)) {
    return `Project name must contain letters, numbers, spaces, dots, dashes or the underscore character. 
            In addition, punctuation marks cannot end the name.`;
  }

  now = moment().valueOf();
  await sleep(1000);
  if (moment().valueOf() - now > 990) {
    try {
      const { data: isValid } = await API.briefNameCheck({
        ...(allValues.deazyAsClient
          ? {
              deazyAsClient: allValues.deazyAsClient,
            }
          : {
              customerId:
                get(allValues, 'customer.id') || get(allValues, 'client.id'),
            }),
        name: value,
      });
      if (!isValid) {
        return 'Project name must be unique.';
      }
      return undefined;
    } catch (e) {
      return 'Project name must be unique.';
    }
  }
  return undefined;
});

let count = 0;
export const validateProjectName = simpleMemoize(async (value, allValues) => {
  if (!value) {
    return undefined;
  }
  // omit validation if name is the same as editted one
  if (allValues.id && value === allValues.originalName) {
    return undefined;
  }
  if (!trim(value).match(projectNameRegexp)) {
    return `Project name must contain letters, numbers, spaces, dots, dashes or the underscore character. 
            In addition, punctuation marks cannot end the name.`;
  }

  count += 1;
  const oldCount = count;
  await sleep(1000);
  if (count === oldCount) {
    try {
      const { data: isValid } = await API.projectNameCheck({
        ...(allValues.deazyAsClient
          ? {
              deazyAsClient: allValues.deazyAsClient,
            }
          : {
              customerId:
                get(allValues, 'customer.id') || get(allValues, 'client.id'),
            }),
        name: value,
      });
      if (!isValid) {
        return 'Project name must be unique.';
      }
      return undefined;
    } catch (e) {
      return 'Project name must be unique.';
    }
  }
  return undefined;
});

export const composeValidators = (...validators) => (value, values) =>
  validators.reduce(
    (error, _validator) => error || _validator(value, values),
    undefined,
  );

export const required = value => (value ? undefined : 'This field is required');

export const isDefined = value => {
  return value !== undefined ? undefined : 'This field is required';
};

export const mustBeGreaterThan = (greaterThanField, greaterThanLabel) => (
  value,
  allValues,
) =>
  toNumber(value) < toNumber(allValues[greaterThanField])
    ? `Must be greater than ${greaterThanLabel}`
    : undefined;

export const maxValue = max => value =>
  value && value > max ? `Maximum value is  ${max}` : undefined;

export const mustBeInteger = value =>
  value && !isInteger(toNumber(value)) ? 'Must be integer' : undefined;

export const mustBeValidEmail = (value = '') =>
  isEmpty(value) || validator.isEmail(value)
    ? undefined
    : 'Invalid email address';

export const mustBeNumber = value =>
  isNaN(value) ? 'Must be a number' : undefined;

export const mustBeShorterOrEqual = num => (value = '') => {
  return value && value.length > num
    ? `Must be shorter or equal ${num} characters`
    : undefined;
};

// TO DO - remove after backend fix

export const mustBeShorterOrEqualTrick = num => (value = '') => {
  return value && value.length > num
    ? `Must be shorter or equal 10000 characters`
    : undefined;
};

export const mustBeShorterOrEqualForHTML = num => (value = '') => {
  const regexpValue = value.replace(/<[^>]*>?/gm, '');
  return regexpValue && regexpValue.length > num
    ? `Must be shorter or equal ${num} characters`
    : undefined;
};

export const mustBeAtLeastCharLong = num => (value = '') =>
  value.length < num ? `Must be at least ${num} characters long` : undefined;

export const mustBeAfter = date => value =>
  moment
    .utc(date)
    .endOf('day')
    .isBefore(moment.utc(value)) || isEmpty(value)
    ? undefined
    : `Must be after ${moment.utc(date).format('DD/MM/YYYY')}`;

export const mustBePositiveNumber = (value = '') =>
  value && toNumber(value) < 0 ? 'Must be positive value' : undefined;

export const mustBe = (value = '') =>
  value && toNumber(value) < 0 ? 'Must be positive value' : undefined;

export const mustBeHigherThanZero = (value = '') =>
  value && toNumber(value) <= 0 ? 'Must be higher than zero.' : undefined;

export const passwordsMustMatch = (value, allValues) =>
  value === allValues.password || value === allValues.newPassword
    ? undefined
    : 'Password confirmation must match';

const regExp = /(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s.]?[(]?[0-9]{1,3}[)]?([-\s.]?[0-9]{3})([-\s.]?[0-9]{3,4})/g;

export const mustBeValidPhoneNumberOrEmpty = (value = '') =>
  !value || value.match(regExp) ? undefined : 'Must be a valid phone number';

export const mustBeValidUrl = (value = '') =>
  isEmpty(value) || validator.isURL(value) ? undefined : 'Invalid URL';

export const clientMustBeCompleted = value =>
  value?.setupCompleted
    ? undefined
    : 'This client is missing some details. Please complete these details before creating a project.';

export const mustBeValidIBAN = (value = '') =>
  isEmpty(value) || validator.isIBAN(value) ? undefined : 'IBAN is invalid.';

export const mustBeValidBIC = (value = '') =>
  isEmpty(value) || validator.isBIC(value)
    ? undefined
    : 'BIC number is invalid.';

export const mustBeCommaSeparatedEmails = (value = '') => {
  if (isEmpty(value)) {
    return undefined;
  }
  const emails = split(value, ', ');

  const hasInvalidEmail = !!find(
    emails,
    email => !validator.isEmail(trim(email)),
  );

  if (hasInvalidEmail) {
    return 'Incorrect email address. Emails must be separated with a comma.';
  }

  if (emails.length > uniq(emails).length) {
    return 'Emails must be uniq.';
  }
  return undefined;
};
