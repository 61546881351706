import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Button from '@styles/Button';
import { isEmpty } from 'lodash-es';
import { Box, useDisclosure } from '@chakra-ui/react';
import Spinner from '@styles/Spinner';
import { APP_ROUTES } from '@routes';
import ClientDetails from './ClientDetails';
import SupplierDetails from './SupplierDetails';
import DeleteModal from '../Project/Overview/DeleteModal';

const Team = ({
  match: {
    params: { id },
  },
  isSupplierRoute,
  isClientRoute,
  usersStore: {
    profile,
    isProfileLoaded,
    isAdminOrDL,
    createUserByRole,
    isTeamAdmin,
    isTeamAdminSideUser,
  },
  teamsStore: {
    fetchClient,
    fetchSuppliersBlacklist,
    client,
    fetchSupplier,
    clear,
    supplier,
    isLoading,
    deleteUserFromClient,
    deleteUserFromSupplier,
    addUserToClient,
    addUserToSupplier,
    checkInvoiceNumberingForSuppliers,
    supplierInvoices,
  },
  teamsStore: { deleteSupplier },
  toastsStore,
  history,
}) => {
  useEffect(() => {
    if (isSupplierRoute) {
      fetchSupplier(id);
    } else {
      fetchClient(id);
      if (isAdminOrDL) {
        fetchSuppliersBlacklist(id);
      }
    }
    return clear;
  }, []);

  const handleTeamDelete = () =>
    deleteSupplier({
      supplierId: supplier.id,
      onSuccess: () => {
        onClose();
        if (isEmpty(id)) {
          return fetchSupplier();
        }
        return history.push(APP_ROUTES.teams.suppliers);
      },
      onFailure: onClose,
    });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const showLoader =
    isLoading ||
    !isProfileLoaded ||
    (isEmpty(client) && !isSupplierRoute) ||
    (isEmpty(supplier) && isSupplierRoute);

  return (
    <Box p="32px" pb="155px" minH="calc(100vh - 80px)">
      {isOpen && (
        <DeleteModal
          onClose={e => {
            if (e) {
              e.preventDefault();
            }
            onClose();
          }}
          isOpen={isOpen}
          onDelete={handleTeamDelete}
        />
      )}
      {showLoader ? (
        <Spinner />
      ) : (
        <>
          {isSupplierRoute && (
            <>
              <SupplierDetails
                supplier={supplier}
                fetchSupplier={fetchSupplier}
                addUserToSupplier={addUserToSupplier}
                deleteUserFromSupplier={deleteUserFromSupplier}
                isSupplierRoute={isSupplierRoute}
                createUserByRole={createUserByRole}
                isAdminOrDL={isAdminOrDL}
                isTeamAdmin={isTeamAdmin}
                isTeamAdminSideUser={isTeamAdminSideUser}
                checkInvoiceNumberingForSuppliers={
                  checkInvoiceNumberingForSuppliers
                }
                supplierInvoices={supplierInvoices}
                toastsStore={toastsStore}
                profile={profile}
              />
              {isAdminOrDL && (
                <Box w="100%" maxW="1200px" mx="auto">
                  <Button
                    onClick={onOpen}
                    mt="40px"
                    variant="warning"
                    px="14px"
                  >
                    Delete Delivery Partner
                  </Button>
                </Box>
              )}
            </>
          )}
          {isClientRoute && (
            <ClientDetails
              client={client}
              fetchClient={fetchClient}
              deleteUserFromClient={deleteUserFromClient}
              addUserToClient={addUserToClient}
              isAdminOrDL={isAdminOrDL}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default inject(
  'teamsStore',
  'usersStore',
  'toastsStore',
)(observer(Team));
