import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';
import RichTextEditorInput from './RichTextEditorInput';

const ExtendableRichText = ({ placeholder = '', input = {}, inputStyle }) => {
  const [value, setValue] = React.useState('');
  const { isOpen, onClose, onOpen } = useDisclosure();
  React.useEffect(() => {
    if (input.value) {
      setValue(input.value);
    }
  }, [input.value]);

  if (!isOpen && input.value) {
    return (
      <Box
        p="12px"
        borderBottomRightRadius="8px"
        borderBottomLeftRadius="8px"
        bg="#fff"
        border="1px solid"
        borderColor="dark.mid"
        {...inputStyle}
      >
        <Box
          onClick={onOpen}
          className="displayDescriptionAsHTML"
          style={{
            width: '100%',
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': '5',
            '-webkit-box-orient': 'vertical',
          }}
          dangerouslySetInnerHTML={{
            __html: input.value || placeholder,
          }}
          cursor="pointer"
        />
      </Box>
    );
  }

  if (!isOpen && !input.value) {
    return (
      <Typography
        w="100%"
        h="50px"
        onClick={onOpen}
        cursor="text"
        display="flex"
        alignItems="center"
        px="12px"
        minH="50px"
        border="1px solid"
        borderColor="dark.mid"
        borderBottomRightRadius="8px"
        borderBottomLeftRadius="8px"
        bg="#fff"
        color="rgba(0, 0, 0, .2)"
        fontSize="15px"
        {...inputStyle}
      >
        {placeholder}
      </Typography>
    );
  }

  if (isOpen) {
    return (
      <>
        <RichTextEditorInput
          isBlockDisplay
          input={{
            onChange: setValue,
          }}
          initialValue={input.value}
          bodyPadding="0px 12px"
          initProps={{
            plugins: ['lists', 'paste'],
            paste_as_text: true,
            toolbar:
              'undo redo | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
            table_toolbar: '',
            contextmenu: 'lists',
          }}
          w="100%"
        />
        <Flex mt="22px" position="relative" zIndex={100}>
          <Button
            mr="12px"
            onClick={() => {
              input.onChange(value);
              onClose();
            }}
          >
            Save
          </Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </Flex>
      </>
    );
  }

  return null;
};

export default ExtendableRichText;
