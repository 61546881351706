import { ENGAGEMENT_TYPES, SOW_STATUSES } from '@app/constants';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import RichTextEditorInput from '@components/forms/RichTextEditorInput';
import Select from '@components/forms/_common/Select';
import qs from 'query-string';
import InfoAlert from '@components/InfoAlert';
import StatusDropdownTrigger from '@components/StatusDropdownTrigger';
import useDownload from '@hooks/useDownload';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { filter, find, first, isEmpty, map, toNumber } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import CompleteButton from './CompleteButton';
import CompleteSowModal from './CompleteSowModal';
import ExportToPDFButton from './ExportToPDFButton';
import PaymentScheduleForm from './PaymentScheduleForm';
import SowHistoryList from './SowHistoryList';
import SowInstructions from './SowInstructions';
import SOWTabs from './SOWTabs';
import LinkedDocuments from './LinkedDocuments';

const { REACT_APP_API_URL: API_URL } = process.env;

const StatementOfWorks = ({
  projectsStore: {
    project: {
      deazyAsClient,
      deazyAsSupplier,
      id: projectId,
      finalisedDateClientSOW,
      clientSOWSignedOutsideOfPlatform,
      projectToSuppliers,
    },
    project,
    newDeliveryPartnerInProject,
    clearNewDeliveryPartnerInProject,
  },
  sowsStore: {
    _fetchSow: fetchSow,
    prepareClientSow,
    prepareSupplierSow,
    historyFirstRequest,
    fetchSOW,
    completeSOWaction,
    isLoading,
    sowHistory,
    fetchSowHistory,
    currentProjectToSupplier,
    setCurrentProjectToSupplier,
    clearCurrentProjectToSupplier,
    prepopulatedProjectTable,
    prepopulatedPandPTable,
    prepopulatedRetainerTable,
    fetchLinkedDocuments,
    addLinkedDocument,
    linkedDocuments,
    isLoadingLinkedDocuments,
    deleteLinkedDocument,
  },
  usersStore: { isAdminOrDL, isTeamAdmin, isClient },
  match: {
    params: { type },
  },
  sowPaymentScheduleStore: {
    clearPaymentInitialData,
    getPaymentInitialData,
    paymentInitialData,
    isLoading: isLoadingPayment,
  },
  history,
  location: { search },
}) => {
  const [initialSow, setInitialSow] = React.useState('');
  const [isSowInitialized, setSowInitialized] = React.useState(false);

  const isClientTab = type === 'client';
  const isDeazy = deazyAsClient || deazyAsSupplier;

  const isNewDeliveryPartnerInProject = !!newDeliveryPartnerInProject;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isPlugAndPlay =
    project.engagementType === ENGAGEMENT_TYPES.TEAM_AUGMENTATION;
  const isRetainer = project.engagementType === ENGAGEMENT_TYPES.RETAINED_TEAM;

  const redirects = () => {
    if (isClient && finalisedDateClientSOW) {
      return history.push('client');
    }
    if (isTeamAdmin && first(projectToSuppliers).finalisedDateSupplierSOW) {
      return history.push('delivery-partner');
    }
    if (!(type === 'client' || type === 'delivery-partner') && isAdminOrDL) {
      if (!deazyAsClient) {
        return history.push('delivery-partner');
      }
      return history.push('client');
    }
    if (!isAdminOrDL) {
      return history.push('/projects');
    }
    return undefined;
  };

  const { projectToSupplierId: ptosIdFromURL } = qs.parse(search);

  const ptosFromUrl = find(
    project.projectToSuppliers,
    pts => pts.id === toNumber(ptosIdFromURL),
  );

  React.useEffect(() => {
    setCurrentProjectToSupplier(ptosFromUrl);
    redirects();
    return () => {
      clearCurrentProjectToSupplier();
      clearPaymentInitialData();
    };
  }, []);

  const downloadLink = isClientTab
    ? `${API_URL}/projects/${projectId}/clientSOW/pdf`
    : `${API_URL}/projects/${projectId}/supplierSOW/pdf/${currentProjectToSupplier?.supplier?.id}`;

  const { onDownload, isLoading: isLoadingDownload } = useDownload({
    url: downloadLink,
  });

  const SOWstatus = () => {
    if (isClientTab) {
      return finalisedDateClientSOW ? SOW_STATUSES.SIGNED : SOW_STATUSES.DRAFT;
    }
    return currentProjectToSupplier.finalisedDateSupplierSOW
      ? SOW_STATUSES.SIGNED
      : SOW_STATUSES.DRAFT;
  };

  const isSowFinalised = SOWstatus() === SOW_STATUSES.SIGNED;

  React.useEffect(() => {
    if (isClientTab && isAdminOrDL) {
      getPaymentInitialData(projectId);
      if (isSowFinalised) {
        fetchLinkedDocuments();
      }
    }
  }, [isClientTab, isSowFinalised]);

  React.useEffect(() => {
    if (!isClientTab && currentProjectToSupplier?.id && isAdminOrDL) {
      getPaymentInitialData(projectId, currentProjectToSupplier?.id);
      if (isSowFinalised) {
        fetchLinkedDocuments(currentProjectToSupplier?.id);
      }
    }
  }, [isClientTab, currentProjectToSupplier?.id, isSowFinalised]);

  React.useEffect(() => {
    if (project.id) {
      setSowInitialized(false);
      if (!historyFirstRequest) {
        setTimeout(() => {
          setSowInitialized(true);
        }, 500);
      }
    }
  }, [project.id, historyFirstRequest]);

  const firstSow = first(sowHistory) || {};

  React.useEffect(() => {
    const paymentInitialDataCondition = () => {
      if (isAdminOrDL && !isEmpty(paymentInitialData)) {
        return true;
      }
      if (isClient || isTeamAdmin) {
        return true;
      }
      return false;
    };
    if (project.id && paymentInitialDataCondition()) {
      if (!historyFirstRequest) {
        if (isClientTab) {
          setInitialSow(
            isEmpty(firstSow.body) ? prepareClientSow() : firstSow.body,
          );
        } else {
          setInitialSow(
            isEmpty(firstSow.body) ? prepareSupplierSow() : firstSow.body,
          );
        }
      }
    }
  }, [project.id, isClientTab, historyFirstRequest, paymentInitialData]);

  const returnTable = (isTableForClient = true) => {
    if (isPlugAndPlay) {
      return prepopulatedPandPTable(isTableForClient);
    }
    if (isRetainer) {
      return prepopulatedRetainerTable(isTableForClient);
    }
    return prepopulatedProjectTable(isTableForClient);
  };

  const updateScheduleTable = () => {
    const regexp = /<div id="prepopulated">(.*)<\/div>/s;
    const sowToUpdate = firstSow.body || initialSow;
    const typeOfTable = returnTable(isClientTab);
    return sowToUpdate?.replace(regexp, `${typeOfTable}`);
  };

  const updateSowTemplate = () => {
    if (!isEmpty(paymentInitialData) && initialSow) {
      setTimeout(() => setInitialSow(updateScheduleTable()), 500);
    }
  };

  const onTabChange = tab => {
    history.push(tab);
  };

  const isCompleted = isClientTab
    ? !!finalisedDateClientSOW
    : !!currentProjectToSupplier.finalisedDateSupplierSOW;

  const download = async () => {
    const isBodyMissing = isEmpty(sowHistory) || !first(sowHistory)?.body;
    if (isBodyMissing && isClientTab) {
      await fetchSow(isClientTab, projectId, undefined, prepareClientSow());
    }
    if (isBodyMissing && !isClientTab) {
      await fetchSow(isClientTab, projectId, undefined, prepareSupplierSow());
    }
    await onDownload(() => fetchSowHistory(isClientTab));
  };

  const sowsSignedOutsideOfPlatform =
    clientSOWSignedOutsideOfPlatform ||
    !find(projectToSuppliers, pts => !pts.supplierSOWSignedOutsideOfPlatform);

  const filteredProjectToSuppliers = filter(
    projectToSuppliers,
    pts => !pts.supplierSOWSignedOutsideOfPlatform,
  );

  const handleCompleteButtonClick = () => {
    onOpen();
  };

  return (
    <>
      {(isAdminOrDL ||
        (isTeamAdmin && isCompleted) ||
        (isClient && isCompleted)) && (
        <>
          {!isDeazy && isAdminOrDL && !sowsSignedOutsideOfPlatform && (
            <SOWTabs activeTab={type} onTabChange={onTabChange} />
          )}
          <Box maxW="1280px" px="40px" w="100%" mx="auto" pb="60px" mt="32px">
            {isNewDeliveryPartnerInProject && (
              <InfoAlert
                bg="white"
                border="1px solid"
                height="56px"
                mb="12px"
                mt="-16px"
                alignItems="flex-start"
                borderColor="primary.400"
                withCloseButton
                label="A new delivery partner has been added to the project. Please set up a Statment of Work for them"
              />
            )}
            <Flex
              justifyContent="space-between"
              bg="#fff"
              p="32px"
              borderRadius="8px"
              mb="32px"
            >
              <Flex alignItems="center">
                <Typography variant="h2" mr="24px">
                  {!isClientTab
                    ? currentProjectToSupplier?.supplier?.name
                    : 'Client SoW'}
                </Typography>
                <StatusDropdownTrigger isReadOnly status={SOWstatus()} isSOW />
              </Flex>
              {!isClientTab && filteredProjectToSuppliers.length > 1 && (
                <Flex alignItems="center">
                  <Typography mr="24px" width="144px">
                    <Box
                      as="span"
                      fontWeight="bold"
                    >{`(${filteredProjectToSuppliers.length})`}</Box>
                    {` `}
                    Delivery Partners
                  </Typography>
                  <Select
                    styleProps={{ width: '244px' }}
                    options={map(filteredProjectToSuppliers, pts => ({
                      value: pts.supplier.name,
                      label: pts.supplier.name,
                      ...pts,
                    }))}
                    defaultValue={currentProjectToSupplier?.supplier?.name}
                    onChange={ptsObj => {
                      setCurrentProjectToSupplier(ptsObj);
                    }}
                  />
                </Flex>
              )}
            </Flex>

            {isAdminOrDL && (
              <>
                <Box bg="#fff" p="40px 32px" borderRadius="8px">
                  <Typography variant="h3" mb="24px">
                    Instructions
                  </Typography>
                  <SowInstructions isClientTab={isClientTab} />
                </Box>
                {!isLoadingPayment ? (
                  <PaymentScheduleForm
                    project={project}
                    projectToSupplier={find(
                      project.projectToSuppliers,
                      pts => pts.id === currentProjectToSupplier?.id,
                    )}
                    isSowFinalised={isSowFinalised}
                    isClientTab={isClientTab}
                    currency={
                      isClientTab
                        ? project.currency
                        : currentProjectToSupplier.overriddenCurrency
                    }
                    initialValues={paymentInitialData}
                    updateSowTemplate={updateSowTemplate}
                  />
                ) : (
                  <Spinner variant="large" my="40px" />
                )}
              </>
            )}

            {isClientTab && !isEmpty(initialSow) && (
              <>
                <RichTextEditorInput
                  containerProps={{ mt: '54px' }}
                  disabled={isCompleted}
                  input={{
                    onChange: value => {
                      if (isSowInitialized && !isCompleted) {
                        fetchSOW(
                          isClientTab,
                          project.id,
                          currentProjectToSupplier?.supplier?.id,
                          value,
                        );
                      }
                    },
                  }}
                  initialValue={initialSow}
                  height={1000}
                  isBlockDisplay
                />
                <Flex justify="space-between">
                  {!isCompleted && (
                    <CompleteButton
                      isDisabled={historyFirstRequest}
                      completeSOW={handleCompleteButtonClick}
                      isLoading={isLoading}
                    />
                  )}
                  {!isCompleted && (
                    <ExportToPDFButton
                      label="Export new version"
                      isLoadingDownload={isLoadingDownload}
                      onDownload={download}
                    />
                  )}
                </Flex>
              </>
            )}
            {!isClientTab && !isEmpty(initialSow) && (
              <>
                <RichTextEditorInput
                  containerProps={{ mt: '54px' }}
                  disabled={isCompleted}
                  input={{
                    onChange: value => {
                      if (isSowInitialized && !isCompleted) {
                        fetchSOW(
                          isClientTab,
                          project.id,
                          currentProjectToSupplier?.supplier?.id,
                          value,
                        );
                      }
                    },
                  }}
                  initialValue={initialSow}
                  height={1000}
                  isBlockDisplay
                />

                <Flex justify="space-between">
                  {!isCompleted && (
                    <CompleteButton
                      isDisabled={historyFirstRequest}
                      completeSOW={handleCompleteButtonClick}
                      isLoading={isLoading}
                    />
                  )}
                  {!isCompleted && (
                    <ExportToPDFButton
                      label="Export new version"
                      onDownload={download}
                      isLoadingDownload={isLoadingDownload}
                    />
                  )}
                </Flex>
              </>
            )}
            {isSowFinalised && (
              <LinkedDocuments
                isLoadingLinkedDocuments={isLoadingLinkedDocuments}
                linkedDocuments={linkedDocuments}
                isLoading={isLoading}
                onSubmit={(values, formApi) =>
                  addLinkedDocument(
                    {
                      ptsId: !isClientTab && currentProjectToSupplier?.id,
                      ...values,
                    },
                    () => setTimeout(() => formApi.restart(), 0),
                  )
                }
                deleteLinkedDocument={deleteLinkedDocument}
                ptsId={!isClientTab && currentProjectToSupplier?.id}
              />
            )}
            <SowHistoryList
              isClientTab={isClientTab}
              supplierId={currentProjectToSupplier?.supplier?.id}
            />
            <CompleteSowModal
              isOpen={isOpen}
              onClose={onClose}
              onComplete={({ date }) =>
                completeSOWaction(project.id, isClientTab, date, () => {
                  onClose();
                  clearNewDeliveryPartnerInProject();
                })
              }
            />
          </Box>
        </>
      )}
    </>
  );
};

export default inject(
  'projectsStore',
  'sowsStore',
  'usersStore',
  'sowPaymentScheduleStore',
  'toastsStore',
)(observer(StatementOfWorks));
