import { Box, Divider, Flex } from '@chakra-ui/react';
import { map } from 'lodash-es';
import Typography from '@styles/Typography';
import Spinner from '@styles/Spinner';
import React from 'react';
import moment from 'moment';

const AllocationHistoryItem = ({ user, actionDate, content }) => {
  return (
    <Box mb="12px" ml="8px">
      {content && (
        <Box>
          <Typography variant="text2" lineHeight="15px" mb="4px">
            {content}
          </Typography>
        </Box>
      )}

      <Flex alignItems="center" color="gray.300">
        <Typography variant="text3" lineHeight="13px">
          {user?.fullname || 'Deazy Platform'}
        </Typography>

        <Box
          height="10px"
          borderLeft="1px solid"
          borderColor="gray.300"
          borderWidth="0.5px"
          mb="1px"
          bg="gray.300"
          mx="6px"
        />

        <Typography variant="text3" lineHeight="13px">
          {moment(actionDate).format('DD MMM YYYY, h:mmA')}
        </Typography>
      </Flex>
    </Box>
  );
};

const AllocationHistory = ({ auditNotesLoading, auditNotes }) => {
  return (
    <Box mt="16px">
      <Divider mb="16px" color="gray.200" />

      <Typography variant="h5" mb="12px" ml="8px">
        Allocation history
      </Typography>

      {auditNotesLoading ? (
        <Spinner variant="medium" mt="64px" />
      ) : (
        <>
          {map(auditNotes, note => (
            <AllocationHistoryItem
              content={note.comment}
              actionDate={note.createdAt}
              user={note.user}
            />
          ))}
        </>
      )}
    </Box>
  );
};

export default AllocationHistory;
