import React from 'react';
import { Box } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { formatNumber } from '@utils';

const DiscountDisclaimer = ({ clientProposalData }) => (
  <>
    {+clientProposalData.discountPercentage > 0 && (
      <Box
        display="inline-flex"
        bg="green.light"
        p="18px"
        mt="16px"
        borderRadius="8px"
        alignItems="center"
      >
        <Typography variant="h3" color="green.solid" mr="12px">
          %
        </Typography>
        <Typography lineHeight="1.4">
          You currently have a discount applied to your total cost.
          {clientProposalData.discountReason && (
            <>
              {' Description:'}
              <Typography as="span" fontWeight="700" ml="8px">
                {clientProposalData.discountReason}
              </Typography>
              .
            </>
          )}
          {' Amount:'}
          <Typography as="span" fontWeight="700" ml="8px">
            {`${formatNumber(clientProposalData.discountPercentage)}%`}
          </Typography>
        </Typography>
      </Box>
    )}
  </>
);

export default DiscountDisclaimer;
