import { Flex } from '@chakra-ui/react';
import { ErrorText } from '@components/forms/FormError';
import Icon from '@components/Icon';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { isEmpty, join, last, map, reject, split } from 'lodash-es';
import { nanoid } from 'nanoid';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { formatBytes } from '@utils/appUtils';

const FileItem = ({ name, fileSize, onDeleteFile, ...props }) => {
  const iconName = last(split(name, '.'));
  return (
    <Flex
      border="1px solid"
      borderRadius="8px"
      borderColor="dark.mid"
      px="18px"
      py="12px"
      mb="12px"
      alignItems="center"
      {...props}
    >
      <Icon mr="12px" fontSize={24} name={iconName} />
      <Flex w="80%" mr="auto" flexDir="column">
        <Typography
          variant="h5"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {name}
        </Typography>
        {fileSize && (
          <Typography mt="2px" color="gray.mid" variant="caption">
            {formatBytes(fileSize)}
          </Typography>
        )}
      </Flex>
      <Button
        iconButton={{ name: 'cross', fontSize: 26 }}
        variant="ghost"
        _hover={{ color: 'dark.mid' }}
        color="dark.solid"
        w="48px"
        h="48px"
        onClick={onDeleteFile}
      />
    </Flex>
  );
};

const Dropzone = ({
  input = {},
  meta,
  dropzoneOpts = {},
  errors = {},
  fileProps,
  isMultiple = true,
  supportedExtensions = [
    '.pdf',
    '.csv',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.png',
    '.jpg',
    '.zip',
  ],
  ...props
}) => {
  const { getRootProps, getInputProps, acceptedFiles, open } = useDropzone({
    accept: supportedExtensions,
    noClick: true,
    noKeyboard: true,
    multiple: isMultiple,
    ...dropzoneOpts,
  });
  const { value: files, onChange } = input;
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  const onDeleteFile = uid => {
    onChange(reject(files, { uid }));
  };

  useEffect(() => {
    if (!isEmpty(acceptedFiles)) {
      onChange([
        ...(isMultiple ? files : []),
        ...map(acceptedFiles, file => ({ file, uid: nanoid(16) })),
      ]);
    }
  }, [acceptedFiles]);

  return (
    <>
      <Flex
        align="center"
        direction="column"
        justify="center"
        w="580px"
        bg="#fff"
        borderColor="dark.mid"
        borderStyle="dashed"
        borderRadius="8px"
        borderWidth="2px"
        {...getRootProps({ className: 'dropzone' })}
        {...props}
        {...(isSmallerScreen && { p: '24px' })}
      >
        <input {...getInputProps()} />
        {!isSmallerScreen && (
          <>
            <Typography variant="h4" mt="55px">
              Drag & Drop Files here
            </Typography>
            <Typography mt="12px" mb="16px" variant="text2">
              or
            </Typography>
          </>
        )}
        <Button
          leftIcon={{
            name: 'download',
            transform: 'rotate(180deg)',
            fontSize: 22,
          }}
          variant="outline"
          onClick={open}
        >
          Select file from {isSmallerScreen ? 'device' : 'computer'}
        </Button>
        <Typography variant="text2" align="center" mt="16px" color="gray.mid">
          Supported extensions:
          {join(supportedExtensions, ', ')}
        </Typography>
        <Typography
          variant="text2"
          mt="8px"
          color="gray.mid"
          mb={!isSmallerScreen && '55px'}
        >
          Max file size is 10MB
        </Typography>
      </Flex>
      <Flex flexWrap="wrap" w="100%" justifyContent="space-between" mt="24px">
        {map(input.value, (file, idx) => (
          <Flex
            w={isSmallerScreen ? '100%' : 'calc(50% - 12px)'}
            key={`file-key-${file?.uid}`}
            flexDir="column"
          >
            <FileItem
              w="100%"
              onDeleteFile={() => onDeleteFile(file?.uid)}
              name={file?.name || file?.file?.name}
              fileSize={file?.file?.size}
              {...fileProps}
            />
            {errors[idx] && <ErrorText mt="0px">{errors[idx]}</ErrorText>}
          </Flex>
        ))}
      </Flex>
    </>
  );
};

export default Dropzone;
