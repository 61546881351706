import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import { formatCurrency } from '@utils';
import { toNumber } from 'lodash-es';
import React from 'react';

const AutoCalcCell = ({ rate, currency, amount, ...props }) => {
  const areRateAndHoursSet = !!(rate && amount);
  const text = areRateAndHoursSet
    ? formatCurrency(toNumber(rate) * toNumber(amount), currency)
    : 'Auto calc';
  const bgColor = areRateAndHoursSet ? 'blue.light' : 'dark.light';
  const textColor = areRateAndHoursSet ? 'dark.solid' : 'dark.mid';

  return (
    <Popover usePortal trigger="hover" placement="top">
      <PopoverTrigger>
        <Flex
          px="16px"
          alignItems="center"
          w="25%"
          h="100%"
          bg={bgColor}
          borderLeft="1px solid"
          borderRight="1px solid"
          borderColor="dark.mid"
          {...props}
        >
          <Typography color={textColor} mr="auto">
            {text}
          </Typography>
          {areRateAndHoursSet && (
            <Icon name="link" color="blue.solid" fontSize={24} mb="2px" />
          )}
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        zIndex={1000}
        bg="dark.solid"
        color="#fff"
        borderRadius="8px"
      >
        <PopoverArrow bg="dark.solid" boxShadow="none" />
        <PopoverBody px="16px" pt="16px" pb="32px" textAlign="center">
          Cell value is automatically calculated based on rates
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default AutoCalcCell;
