import React from 'react';
import { Button, Flex, useDisclosure, Grid } from '@chakra-ui/react';
import { isEmpty, map } from 'lodash-es';
import StyledButton from '@styles/Button';
import { inject, observer } from 'mobx-react';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import WhiteCard from '@components/WhiteCard';
import SectionTitle from '@components/SectionTitle';
import { toJS } from 'mobx';
import AddToGroupModal from './AddToGroupModal';

const UserGroupCard = ({
  userId,
  groups,
  usersStore: {
    fetchUserGroups,
    userGroups,
    isAdminOrDL,
    deleteUserFromGroup,
    addUserToGroup,
  },
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <>
      <WhiteCard>
        <SectionTitle mt="0px">Group membership</SectionTitle>
        <Button
          leftIcon={<Icon name="add" mr="17px" w="14px" />}
          fontWeight="400"
          fontSize={18}
          bg="aqua.solid"
          color="#fff"
          borderRadius="30px"
          right="24px"
          position="absolute"
          top="12px"
          _hover={{ bg: '#3E898C' }}
          _active={{ bg: '#3E898C' }}
          onClick={onOpen}
          isDisabled={!isAdminOrDL}
        >
          Add to group
        </Button>
        <Grid templateColumns="1fr 1fr" gap="24px">
          {!isEmpty(groups) ? (
            map(groups, group => {
              return (
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  mb="16px"
                  key={`role-${group.id}`}
                >
                  <Typography key={`user-gr-${group.id}`}>
                    {group.displayName}
                  </Typography>

                  <StyledButton
                    iconButton={{ name: 'trash', fontSize: 18 }}
                    onClick={() => deleteUserFromGroup(userId, group.id)}
                    color="#fff"
                    isLoading={group.isDeleting}
                    bg="red.solid"
                    _hover={{ bg: 'red.mid' }}
                    borderRadius="50%"
                    w="32px"
                    h="32px"
                    minW="32px"
                  />
                </Flex>
              );
            })
          ) : (
            <Typography>User does not belong to any group</Typography>
          )}
        </Grid>
      </WhiteCard>
      {isOpen && (
        <AddToGroupModal
          fetchUserGroups={fetchUserGroups}
          userGroups={toJS(userGroups)}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={({ groupId }) => addUserToGroup(userId, groupId, onClose)}
        />
      )}
    </>
  );
};
export default inject('usersStore')(observer(UserGroupCard));
