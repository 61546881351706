import React from 'react';
import { sleep } from '@utils';

export default (delay = 0) => Component => {
  return ({ children, ...props }) => {
    const [show, setIsShown] = React.useState(false);

    const asyncFn = async () => {
      await sleep(delay);
      setIsShown(true);
    };
    React.useEffect(() => {
      asyncFn();
    }, []);
    if (show) {
      return <Component {...props}>{children}</Component>;
    }
    return null;
  };
};
