/* eslint-disable no-plusplus */
import {
  isArray,
  isNaN,
  isNil,
  replace,
  toNumber,
  trim,
  reject,
  last,
  first,
  forEach,
  split,
  isEmpty,
  isString,
  toLower,
  fromPairs,
  map,
  entries,
} from 'lodash-es';
import moment from 'moment';

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const trimObj = obj => {
  if (
    obj === null ||
    (!Array.isArray(obj) && typeof obj !== 'object') ||
    obj instanceof moment ||
    obj instanceof Date ||
    obj instanceof FormData
  ) {
    return obj;
  }
  return Object.keys(obj).reduce(
    (acc, key) => {
      acc[trim(key)] =
        typeof obj[key] === 'string' ? trim(obj[key]) : trimObj(obj[key]);
      return acc;
    },
    Array.isArray(obj) ? [] : {},
  );
};

export const stripObj = obj => {
  if (
    obj === null ||
    obj instanceof Blob ||
    (!Array.isArray(obj) && typeof obj !== 'object') ||
    obj instanceof moment ||
    obj instanceof Date ||
    obj instanceof FormData
  ) {
    return obj;
  }
  if (isArray(obj)) {
    return reject(obj, isNil);
  }
  return Object.keys(obj).reduce(
    (acc, key) => {
      acc[key] = isArray(obj[key])
        ? reject(obj[key], isNil)
        : stripObj(obj[key]);
      return acc;
    },
    Array.isArray(obj) ? [] : {},
  );
};

export const formatNumber = (value, dp = 2) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: dp,
    maximumFractionDigits: dp,
  }).format(!isNaN(toNumber(value)) ? toNumber(value) : 0);
};

export const safeFormatCurrencyForSOWs = (value, currency) => {
  if (!currency) {
    return formatNumber(value);
  }

  const formattedVal = !isNaN(toNumber(value)) ? toNumber(value) : 0;

  if (currency === 'USD') {
    return `##USD##${formatNumber(formattedVal)}`;
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(formattedVal);
};

export const formatCurrency = (value, currency) => {
  if (!currency) {
    return formatNumber(value);
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(!isNaN(toNumber(value)) ? toNumber(value) : 0);
};

export const getDeazyMarginFactor = deazyMargin => {
  return 100 / (100 - toNumber(deazyMargin));
};

export const kebabCase = str => replace(str, / /g, '-');

export const roundTheseHours = (value, formated = true) => {
  const formatedValue = formated ? formatNumber(value) : value;
  const afterDot = last(split(formatedValue, '.'));
  const beforeDot = first(split(formatedValue, '.')).replace(/,/g, '');
  const numberedDot = toNumber(beforeDot);
  const numberedAfter = toNumber(afterDot);
  if (numberedAfter > 50) {
    const numberedDotPlusOne = toNumber(beforeDot) + 1;
    return `${numberedDotPlusOne}.00`;
  }
  if (numberedAfter <= 50 && numberedAfter > 0) {
    return `${numberedDot}.50`;
  }
  return `${numberedDot}.00`;
};

export const roundToFullValue = (value, formated = true) => {
  const formatedValue = formated ? formatNumber(value) : value;
  const afterDot = last(split(formatedValue, '.'));
  const beforeDot = first(split(formatedValue, '.')).replace(/,/g, '');
  const numberedDot = toNumber(beforeDot);
  const numberedAfter = toNumber(afterDot);
  if (numberedAfter > 0) {
    const numberedDotPlusOne = toNumber(beforeDot) + 1;
    return `${numberedDotPlusOne}.00`;
  }
  return `${numberedDot}.00`;
};

export const getNumberOfWeekdays = (startDate, endDate) => {
  const lastDay = moment.utc(endDate);
  const firstDay = moment.utc(startDate);

  let calcBusinessDays =
    1 +
    (lastDay.diff(firstDay, 'days') * 5 -
      (firstDay.day() - lastDay.day()) * 2) /
      7;

  if (lastDay.day() === 6) calcBusinessDays--; // SAT
  if (firstDay.day() === 0) calcBusinessDays--; // SUN

  return calcBusinessDays;
};

export const transformToArray = value => {
  if (!isEmpty(value)) {
    if (isString(value)) {
      return [value];
    }
    return [...value];
  }
  return value;
};

export const fileSizeValidation = (values, sizeInMb = 10) => {
  const errors = {};
  forEach(values.files, ({ name, file }, idx) => {
    if (!name && file.size / 1024 / 1024 > sizeInMb) {
      if (!errors.files) {
        errors.files = [];
      }
      errors.files[idx] = `The file size cannot exceed ${sizeInMb}MB.`;
    }
  });
  return errors;
};

export const setApiCommonHeaders = axiosInstance => {
  axiosInstance.defaults.headers.common = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  };
};

export const axiosCommonHeaders = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
};

export const successRequestInterceptor = axiosConfig => {
  if (axiosConfig.data) {
    return {
      ...axiosConfig,
      data: trimObj(axiosConfig.data),
    };
  }
  return axiosConfig;
};

export const errorRequestInterceptor = () => error => Promise.reject(error);

export const objectValueToLower = array =>
  fromPairs(map(entries(array), ([key, value]) => [key, toLower(value)]));
