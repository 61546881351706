import { Box, Stack } from '@chakra-ui/react';
import Select from '@components/forms/_common/Select';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { required } from '@utils/formValidators';
import { map } from 'lodash-es';
import React from 'react';
import { Field, Form } from 'react-final-form';

const AddToGroupModal = ({
  fetchUserGroups,
  userGroups: { isLoading, data: groups },
  isOpen,
  onClose,
  onSubmit = () => {},
}) => {
  React.useEffect(() => {
    fetchUserGroups();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} styleProps={{ p: '32px' }}>
      <Typography variant="h2" mb="32px">
        Add user to group
      </Typography>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Box>
                <Field
                  label="Group name"
                  name="groupId"
                  placeholder="Select user group"
                  component={Select}
                  validate={required}
                  options={map(groups, g => ({
                    value: g.id,
                    label: g.displayName,
                  }))}
                  isDisabled={isLoading}
                  styleProps={{ mb: '32px' }}
                />
              </Box>
              <Stack mt="40px" spacing={3}>
                <Button isLoading={submitting} type="submit">
                  Add to group
                </Button>
                <Button
                  variant="outline"
                  isDisabled={submitting}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Stack>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default AddToGroupModal;
