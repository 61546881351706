import { Flex, Box, Spinner } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import React from 'react';
import { Field } from 'react-final-form';

const WIDTHS = ['50%', '10%', '10%', '15%', '15%'];

export const ProjectSummaryHeadings = () => {
  return (
    <Flex gap="24px">
      <Typography variant="text2" w={WIDTHS[0]}>
        NAME
      </Typography>
      <Typography variant="text2" w={WIDTHS[1]}>
        SUB TOTAL
      </Typography>
      <Typography variant="text2" w={WIDTHS[2]}>
        CORRECTION %
      </Typography>
      <Typography variant="text2" w={WIDTHS[3]}>
        CORRECTION AMOUNT
      </Typography>
      <Typography variant="text2" w={WIDTHS[4]}>
        TOTAL
      </Typography>
    </Flex>
  );
};

const ProjectSummaryRow = ({
  name,
  subtotal,
  total,
  correctionTotalFieldName,
  correctionPercentFieldName,
  isLoadingTotals,
  isClient,
  mainNameQA,
}) => {
  return (
    <Flex alignItems="center" gap="24px" mb="16px">
      <Box w={WIDTHS[0]} data-test-id={`${mainNameQA}--name`}>
        {name}
      </Box>
      <Box w={WIDTHS[1]} data-test-id={`${mainNameQA}--subtotal`}>
        {subtotal}
      </Box>
      <Box w={WIDTHS[2]}>
        <Field
          component={MaskedTextInput}
          allowNegative
          type="number"
          placeholder="0"
          suffix="%"
          decimalScale={2}
          fixedDecimalScale
          name={correctionPercentFieldName}
          data-test-id={`${mainNameQA}--${correctionPercentFieldName}`}
        />
      </Box>
      <Box w={WIDTHS[3]}>
        <Field
          component={MaskedTextInput}
          allowNegative
          placeholder="0"
          decimalScale={2}
          fixedDecimalScale
          type="number"
          name={correctionTotalFieldName}
          data-test-id={`${mainNameQA}--${correctionTotalFieldName}`}
        />
      </Box>
      <Box
        data-test-id={`${mainNameQA}--total`}
        fontWeight={isClient ? 600 : 400}
        w={WIDTHS[4]}
      >
        {isLoadingTotals ? <Spinner /> : total}
      </Box>
    </Flex>
  );
};

export default ProjectSummaryRow;
