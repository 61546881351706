import { CONFIDENCE_LEVEL } from '@app/constants';
import { Box, Stack, Flex } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import { find, values } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';

const ConfidenceBox = ({
  label,
  description,
  onSelect,
  isSelected,
  isReadOnly,
  isSmallerScreen,
  ...props
}) => {
  const borderColor = () => {
    if (isSmallerScreen && isSelected) {
      return 'blue.solid';
    }
    if (!isSmallerScreen && isSelected) {
      return 'green.solid';
    }
    return 'dark.mid';
  };
  return (
    <Box
      border="2px solid"
      borderRadius="8px"
      p="22px"
      borderColor={borderColor}
      onClick={onSelect}
      cursor={isReadOnly ? 'default' : 'pointer'}
      mb={isSmallerScreen && '24px'}
      {...props}
      _hover={{
        ...(!isSelected && !isReadOnly && { borderColor: 'green.mid' }),
      }}
    >
      <Typography variant="title" mb="8px">
        {label}
      </Typography>
      <Typography variant="text2">{description}</Typography>
    </Box>
  );
};

const ConfidenceLevel = ({
  proposalsStore: { updateFeatureConfidenceLevel },
  isReadOnly,
  currentFeature,
  mainNameQA,
  isSmallerScreen,
}) => {
  const [selected, setSelected] = React.useState(CONFIDENCE_LEVEL.HIGH);

  React.useEffect(() => {
    setSelected(
      find(
        values(CONFIDENCE_LEVEL),
        l => l === currentFeature?.confidenceLevel,
      ) || CONFIDENCE_LEVEL.HIGH,
    );
  }, [currentFeature]);

  const onSelect = async level => {
    if (!isReadOnly) {
      const prevLevel = selected;
      setSelected(level);
      try {
        await updateFeatureConfidenceLevel({
          ...currentFeature,
          confidenceLevel: level,
        });
      } catch {
        setSelected(prevLevel);
      }
    }
  };

  return (
    <WhiteCard
      mt="0"
      boxShadow="0 0 0 0 rgba(0, 0, 0, 0)"
      border="solid 1px"
      borderColor="dark.mid"
      mb="24px"
    >
      <Typography variant="h2" mb="16px">
        Confidence Level
      </Typography>
      <Typography mb="32px">
        This will be used to generate a range for your proposal that accounts
        for any uncertainties
      </Typography>
      <Box
        as={isSmallerScreen ? Flex : Stack}
        isInline
        flexDirection={isSmallerScreen && 'column'}
      >
        <ConfidenceBox
          label={CONFIDENCE_LEVEL.HIGH}
          description="Estimate - 5% to + 5%"
          isSelected={selected === CONFIDENCE_LEVEL.HIGH}
          onSelect={() => onSelect(CONFIDENCE_LEVEL.HIGH)}
          isReadOnly={isReadOnly}
          data-test-id={`${mainNameQA}--feature[${currentFeature?.id}]-confidance-${CONFIDENCE_LEVEL.HIGH}`}
          isSmallerScreen={isSmallerScreen}
        />
        <ConfidenceBox
          label={CONFIDENCE_LEVEL.MEDIUM}
          description="Estimate - 10% to + 10%"
          isSelected={selected === CONFIDENCE_LEVEL.MEDIUM}
          onSelect={() => onSelect(CONFIDENCE_LEVEL.MEDIUM)}
          isReadOnly={isReadOnly}
          data-test-id={`${mainNameQA}--feature[${currentFeature?.id}]-confidance-${CONFIDENCE_LEVEL.MEDIUM}`}
          isSmallerScreen={isSmallerScreen}
          ml={!isSmallerScreen && '24px'}
        />
        <ConfidenceBox
          label={CONFIDENCE_LEVEL.LOW}
          description="Estimate - 20% to + 20%"
          isSelected={selected === CONFIDENCE_LEVEL.LOW}
          onSelect={() => onSelect(CONFIDENCE_LEVEL.LOW)}
          data-test-id={`${mainNameQA}--feature[${currentFeature?.id}]-confidance-${CONFIDENCE_LEVEL.LOW}`}
          isSmallerScreen={isSmallerScreen}
          isReadOnly={isReadOnly}
          ml={!isSmallerScreen && '24px'}
        />
      </Box>
    </WhiteCard>
  );
};

export default inject('proposalsStore')(observer(ConfidenceLevel));
