import { map } from 'lodash-es';

const { REACT_APP_ENV } = process.env;

export const IS_STAGING = REACT_APP_ENV === 'staging';
export const IS_DEV = REACT_APP_ENV === 'development';
export const IS_PROD = REACT_APP_ENV === 'production';
export const IS_PREPROD = REACT_APP_ENV === 'preprod';
export const IS_LOCALHOST = window.location.hostname === 'localhost';

export const PROJECT_TYPES = {
  FIXED_PRICE: 'Fixed Price',
  T_AND_M: 'T&M',
};
export const GROUP_TYPES = {
  CLIENT: 'Client',
  ROLE: 'Role',
  SUPPLIER: 'Delivery partner',
};
export const PROJECT_AVAILABLE_CURRENCIES = ['GBP', 'EUR', 'USD'];

export const RESOURCE_AVAILABLE_CURRENCIES = {
  GBP: 'GBP',
  EUR: 'EUR',
  USD: 'USD',
};

export const CHANGE_REQUEST_TYPE = {
  After: 'After the project',
  During: 'During the project',
};

export const CHANGE_REQUEST_STATUS = {
  New: 'New',
  Approved: 'Approved',
  Rejected: 'Rejected',
  Deleted: 'Deleted',
  Ready: 'Ready',
  Completed: 'Completed',
};

export const GROUP_AVAILABLE_CURRENCIES = {
  GBP: 'GBP',
  EUR: 'EUR',
  USD: 'USD',
};

export const ROLES = {
  PROJECT_MANAGER: 'Deazy Leads',
  ADMIN: 'Admins',
  DEVELOPERS: 'Team Members',
  CLIENT_LEADS: 'Client Leads',
};

export const INVOICE_TYPES = {
  DEPOSIT: 'Deposit',
  MONTHLY: 'Monthly payment',
  DOWNPAYMENT: 'Downpayment',
  FINAL: 'Final payment',
  CR: 'Change request payment',
  FINAL_MONTH: 'Final monthly payment',
  INTERIM: 'Interim payment',
};

export const INVOICE_STATUSES = {
  SCHEDULED: 'Scheduled', // XERO DRAFT
  ACTUALS_MISSING: 'Actuals input required', // XERO DRAFT
  PENDING_APPROVAL: 'Actuals approval required', // XERO DRAFT
  APPROVED: 'Approved',
  CLIENT_SENT: 'Client sent',
  CLIENT_PAID: 'Client paid',
  SUPPLIER_PAID: 'Supplier paid',
  SUPPLIER_COMPLETED: 'Supplier Completed',
  SUPPLIER_APPROVED: 'Supplier approved',
  READY: 'Ready for approval',
  READY_XERO: 'Ready for xero approval',
};

export const NEW_INVOICE_STATUSES = {
  SCHEDULED: 'Scheduled',
  PENDING: 'Pending',
  READY_FOR_PRE_APPROVE: 'Ready for pre-approval',
  READY: 'Ready for approval',
  APPROVED: 'Approved',
  WAITING_TO_BE_SENT: 'Waiting to be sent',
  SENT: 'Sent',
  PAID: 'Paid',
};

export const GROUPS = {
  PROJECT_MANAGERS: 'Deazy Leads',
  CLIENT_LEADS: 'Client Leads',
  CLIENTS: 'Clients',
  SUPPLIERS: 'Delivery Partners',
  DEVELOPERS: 'Team Members',
};

export const ALLOCATION_STATUS = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  NEW: 'New',
};

export const ACTUALS_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};

export const ALLOCATION_RESOURCE_TYPE = {
  'UX Designer': 'UX Designer',
  'Frontend Developer': 'Frontend Developer',
  'Backend Developer': 'Backend Developer',
  'Mobile Developer': 'Mobile Developer',
  'Project Manager': 'Deazy Lead',
};

export const SENIORITY_TYPES = {
  TechLead: 'Tech Lead',
  Architect: 'Architect',
  Senior: 'Senior',
  Mid: 'Mid',
};

export const SENIORITY_OPTIONS = map(SENIORITY_TYPES, type => ({
  value: type,
  label: type,
}));

export const ROLE_TYPES = {
  DEV: 'Dev',
  PM: 'PM',
  MANUAL_QA: 'Manual QA',
  AUTOMATED_QA: 'Automated QA',
};

export const ROLE_TYPES_OPTIONS = map(ROLE_TYPES, type => ({
  value: type,
  label: type,
}));

export const RESOURCE_LOCATION_TYPES = {
  UK: 'UK',
  EU: 'EU',
  'Rest of the World': 'Rest of the World',
};

export const GROUP_LOCATION_TYPES = {
  UK: 'UK',
  EU: 'EU',
  'Rest of the World': 'Rest of the World',
};

export const ERROR_MESSAGES = {
  UNKNOWN: 'Unknown error occurred',
  INVALID_CREDS: 'Invalid Credentials',
};

export const DEAZY_DETAILS = {
  fullName: 'DEAZY LIMITED',
  noteToTheName: 'Runway East Bristol Bridge',
  addressLine1: '1 Victoria Street',
  addressLine2: 'Redcliffe',
  cityAndRegion: 'Bristol',
  postalCode: 'BS1 6AA',
  countryCode: 'United Kingdom',
  vatNumber: 'GB248572575',
};

export const BANKING_DETAILS = {
  bankName: 'Revolut Ltd',
  sortCode: 'Sort code: 04-00-75',
  accountNumber: 'Account number: 06377777',
};

export const DOCUMENT_PARTY_TYPE = {
  CLIENT: 'Client',
  SUPPLIER: 'Supplier',
};

export const CREDIT_NOTE_STATUS = {
  PLATFORM_DRAFT: 'Draft Platform',
  XERO_DRAFT: 'Draft Xero',
  XERO_APPROVED: 'Approved',
  ALLOCATED: 'Credit fully allocated',
  APPROVED: 'Credit allocation approved',
};

export const CREDIT_NOTE_TYPE = {
  RETURN: 'Deposit return',
  CORRECTION: 'Settlement correction',
};

export const CREDIT_ALLOCATION_STATUS = {
  ALLOCATED: 'Allocated',
  SCHEDULED: 'Scheduled',
};

export const ALLOCATION_TYPES = {
  DEAZY: 'DEAZY',
  TEAM: 'TEAM',
};

export const NEW_ROLES = {
  ADMIN: 'Admin',
  DEAZY_LEAD: 'DL',
  CLIENT_LEAD: 'CL',
  TEAM_MEMBER: 'TM',
  TEAM_ADMIN: 'TA',
};

export const INVOICE_NUMBERING_TIMESPAN = {
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
  GLOBAL: 'Global',
};

export const PROJECT_CATEGORIES = {
  TOOL: 'Tool (eg Figma)',
  E_COMMERCE: 'Ecommerce (eg Amazon)',
  MEMBER_SERVICES: 'Member services (eg Pure Gym)',
  SOCIAL_NETWORK: 'Social network (eg Facebook)',
  MARKETPLACE: 'Marketplace (eg eBay)',
  MEDIA_PLATFORM: 'Media platform (eg Spotify)',
  GAME: 'Game (eg Pokemon Go)',
  BANKING: 'Banking/finance (eg Xero)',
  MARKETING: 'Marketing site (eg deazy.com)',
  OTHER: 'Other',
};

export const PROJECT_TECHNOLOGY = {
  React: 'React',
  NODE_JS: 'Node.js',
  HTML5: 'HTML5',
  PHP: 'PHP',
  JAVA: 'Java',
};

export const PRODUCT_FEATURES = [
  'Sign Up',
  'Dashboard',
  'Activity Feed',
  'Tagging',
  'A/V Processing',
  'Search',
  'Calendar',
  'Map',
  'Bookings',
  'Inventory',
  'Messaging',
  'Subscription',
  'Payments',
  'Shopping Cart',
  'CMS',
  'Moderation',
  'User Profiles',
];

export const E_COMMERCE_FEATURES = [
  'Home page',
  'Header',
  'Footer',
  '404 page',
  'Contact us',
  'Blog',
  'FAQ',
  'Search',
  'Product page',
  'Product list',
  'Curated collections',
  'Customer account',
  'Shopping Cart',
  'Checkout',
  'Gift card',
  'Wishlist',
  'Stockists page',
];

export const CURRENCIES_SYMBOLS = {
  GBP: '£',
  EUR: '€',
  USD: '$',
};

export const CURRENCY_OPTIONS = map(PROJECT_AVAILABLE_CURRENCIES, c => ({
  value: c,
  label: c,
}));

export const LOCATION_OPTIONS = map(RESOURCE_LOCATION_TYPES, lock => ({
  value: lock,
  label: lock,
}));

export const INVOICE_NUMBERING_TIMESPAN_OPTIONS = map(
  INVOICE_NUMBERING_TIMESPAN,
  option => ({
    value: option,
    label: option,
  }),
);

export const BRIEF_STATUSES = {
  OPEN: 'Open',
  ON_HOLD: 'On Hold',
  CLOSED: 'Closed',
};

export const BRIEF_TYPES = [
  {
    label: 'All',
    value: 'all-briefs=true',
  },
  {
    label: 'Team Augmentation',
    value: 'onlyPlugPlay=true',
  },
  {
    label: 'Project',
    value: 'onlyProject=true',
  },
];

export const PROPOSAL_STATUSES = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
};

export const ACTION_TYPES = {
  Payment: 'Payment required',
  ActualsInput: 'Actuals input required',
  TeamActualsApproval: 'Team actuals approval required',
  DeazyActualsInput: 'Deazy actuals input required',
  ClientInvoiceApproval: 'Client invoice approval required',
  DeliveryPartnerInvoiceApproval: 'Delivery partner invoice approval required',
  FixInvoiceNumbering: 'Fix numbering for invoices',
  Unsynced: 'Sync required',
};

export const PROJECT_PAYMENT_TYPES = {
  FIXED_PAYMENT: 'Fixed Payment',
  CR_PAYMENT: 'Change Request Payment',
  T_AND_M_PAYMENT: 'TM Payment',
  DEPOSIT: 'Deposit Payment',
};

export const PROJECT_PAYMENT_TYPES_LABEL = {
  FIXED_PAYMENT: 'Standard',
  CR_PAYMENT: 'Change Request',
  T_AND_M_PAYMENT: 'TM Invoice',
  DEPOSIT: 'Deposit Invoice',
};

export const INVOICE_AMOUNT_TYPES = {
  // for T&M projects
  FORECASTED: 'Forecasted from allocation',
  SET_AMOUNT: 'A set amount',
  // for deposit payment type
  FIXED_AMOUNT: 'Fixed amount',
  TIME_AMOUNT: 'Time amount',
};

export const XERO_INVOICE_URL = {
  XERO_BASE_URL_SUPPLIER: '',
  XERO_BASE_URL_CLIENT: '',
};

export const XERO_CREDIT_NOTE_URL = {
  SUPPLIER: '',
  CLIENT: '',
};

export const DEAZY_HELPER_OPTION = {
  value: {
    id: 'deazy',
    setupCompleted: true,
    currency: 'GBP',
    name: 'DEAZY',
  },
  label: 'DEAZY',
};

export const MILESTONE_STAGE = {
  UPFRONT: 'Upfront Project Setup',
  DELIVERY: 'Delivery',
  UAT: 'UAT & Go Live',
};

export const MILESTONE_STATUS = {
  ON_TRACK: 'On track',
  COMPLETE: 'Complete',
  DELAYED: 'Delayed',
};

export const RAG_STATUSES = {
  GREEN: 'Green',
  AMBER: 'Amber',
  RED: 'Red',
};

export const ragStatusesOptions = [
  {
    label: RAG_STATUSES.RED,
    value: RAG_STATUSES.RED,
  },
  {
    label: RAG_STATUSES.AMBER,
    value: RAG_STATUSES.AMBER,
  },
  {
    label: RAG_STATUSES.GREEN,
    value: RAG_STATUSES.GREEN,
  },
];

const ragMap = {
  [MILESTONE_STATUS.COMPLETE]: RAG_STATUSES.GREEN,
  [MILESTONE_STATUS.ON_TRACK]: RAG_STATUSES.AMBER,
  [MILESTONE_STATUS.DELAYED]: RAG_STATUSES.RED,
};

export const MILESTONE_STATUS_OPTIONS = map(MILESTONE_STATUS, option => ({
  value: ragMap[option],
  label: option,
}));

export const ENGAGEMENT_TYPES = {
  PROJECT_BUILD: 'Project Build',
  TEAM_AUGMENTATION: 'Team Augmentation',
  SUPPORT: 'Support',
  RETAINED_TEAM: 'Retained Team',
};

export const PROJECT_TYPE_OPTIONS = map(PROJECT_TYPES, projectType => ({
  value: projectType,
  label: projectType,
}));

export const ENGAGEMENT_TYPES_OPTIONS = map(
  ENGAGEMENT_TYPES,
  engagementType => ({
    value: engagementType,
    label: engagementType,
  }),
);

export const NEW_PROPOSAL_STATUSES = {
  WITH_DP: 'With Delivery Partner',
  WITH_DEAZY: 'With Deazy',
  WITH_CLIENT: 'With Client',
  WON: 'Won',
  LOST: 'Lost',
};

export const SOW_STATUSES = {
  DRAFT: 'Draft',
  SIGNED: 'Signed',
};

export const DEFAULT_NEW_PROPOSAL_STATUS_MESSAGE = `This proposal is currently with you to complete - nobody else can see it. Please
fill in all the sections and then submit the proposal for Deazy to review`;

export const CONFIDENCE_LEVEL = {
  HIGH: 'High', // 5%
  MEDIUM: 'Medium', // 10%
  LOW: 'Low', // 20%
};

export const FIXED_PRICE_ALLOCATION_STAGES = {
  DEVELOPMENT_READINESS: 'Development Readiness',
  DELIVERY: 'Delivery',
  UAT_AND_DEPLOYMENT: 'UAT & Deployment',
};

export const PROPOSAL_CLIENT_STATUS = {
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
};

export const PROJECT_STATUSES = [
  {
    label: 'All',
    value: 'all-projects',
  },
  { label: 'Active', value: 'active' },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
];

export const TOAST_STYLE_TYPES = {
  DEFAULT: 'Default', // with icon, close button and border
  WITH_ICON: 'With Icon',
  BASIC: 'Basic',
};

export const FP_PROJECT_PAYMENT_OPTIONS = [
  {
    value: PROJECT_PAYMENT_TYPES.FIXED_PAYMENT,
    label: PROJECT_PAYMENT_TYPES_LABEL.FIXED_PAYMENT,
  },
  {
    value: PROJECT_PAYMENT_TYPES.CR_PAYMENT,
    label: PROJECT_PAYMENT_TYPES_LABEL.CR_PAYMENT,
  },
];
export const TM_PROJECT_PAYMENT_OPTIONS = [
  {
    value: PROJECT_PAYMENT_TYPES.T_AND_M_PAYMENT,
    label: PROJECT_PAYMENT_TYPES_LABEL.T_AND_M_PAYMENT,
  },
];

export const NOTIFICATION_TYPES = {
  NEW_PROPOSAL_SUBMITED: 'New Proposal Submitted',
  TA_PROPOSAL_HAS_BEEN_UPDATED: 'Proposal Has Been Updated',
  NEW_BRIEF_CREATED: 'New Brief Created',
  PRE_APPROVE_INVOICE: 'Invoice Ready For Pre Approve',
  APPROVE_INVOICE: 'Invoice Ready For Approval',
  ALL_DPS_DECLINED_BRIEF: 'All DPs Declined Brief',
  NEW_QUESTION: 'New question on your brief',
  BRIEF_IS_DUE_TO_CLOSE_TODAY: 'Brief is due to close today',
  APPROVE_CREDIT_NOTE: 'Approve Credit Note',
  FINALISE_SOW_FOR_NEW_DP: 'Finalise SOW For New DP',
  NEW_PUBLIC_PROFILE_CREATED: 'New Public Profile Created',
  PUBLIC_PROFILE_ACCEPTED: 'Public Profile Accepted',
  PUBLIC_PROFILE_REJECTED: 'Public Profile Rejected',
  PROJECT_SURVEY_TO_COMPLETE: 'Project Survey To Complete',
  PROJECT_IS_DUE_TO_END_IN_30_DAYS: 'Project is due to end in 30 days',
};

export const TOP_PROJECT_PRIORITY = {
  PRICE: 'Price',
  TIME: 'Time',
  QUALITY: 'Quality',
};

export const VISIBLE_COSTS = {
  ACTUAL_ESTIMATE: 'Actual estimate',
  HIGH_COST_ONLY: 'High cost only',
  FULL_RANGE: 'Full range',
};

export const FEATURES_BREAKDOWN = {
  HIDDEN_COSTS: 'Hidden costs',
  COSTS_PER_FESTURE: 'Costs per feature',
  FULL_BREAKDOWN: 'Full breakdown',
};

export const CANDIDATE_STATUSES = {
  PROPOSED: 'Proposed',
  QUERIED: 'Queried',
  SHORTLISTED: 'Shortlisted',
  WON: 'Won',
  LOST: 'Lost',
};

export const ASSIGNMENT_STATUSES = {
  EXCLUSIVE: 'Exclusive',
  ASSIGNMENT_PENDING: 'Assignment pending',
  AWAITING_RESPONSE: 'Awaiting response',
  PROPOSAL_SHARED: 'Proposal shared',
  CANDIDATES_SHARED: 'Candidates shared',
  CANCELLED: 'Cancelled',
  SCHEDULED: 'Scheduled',
  SHOWN_INTEREST: 'Shown interest',
  NOT_INTERESTED: 'Not interested',
};
export const SUPPLIER_ESTIMATE_STATUSES = {
  LEGENDS: 'Legends - even w/o detail (FS ready)',
  TRIED_AND_TESTED: 'Tried & Tested',
  UNKNOWN: 'Unknown',
  MIXED: 'Mixed',
  TM_ONLY: 'T&M only - not good at estimating FP',
  NA: 'N/A',
  NEW: 'New',
};

export const SUPPLIER_STATUSES = {
  TRUSTED: 'Trusted',
  FOUNDING_TEAMS: 'Founding teams',
  VALIDATED: 'Validated',
  UNTESTED: 'Untested',
  DISQUALIFIED: 'Disqualified',
  NO_CONTRACT: 'No contract in place',
};

export const MAIN_NAMES_QA = {
  BRIEF_ASSIGNMENT: 'brief-assignment',
  BRIEF_QUESTIONS: 'brief-questions',
  BRIEFS_LIST: 'briefs-list',
  BRIEF_PROJECT_CREATE: 'brief-project-create',
  BRIEF_FIND_DP: 'brief-find-dp',
  PLUG_AND_PLAY_PROPOSAL_FORM: 'plug-and-play-proposal-form',
  PLUG_AND_PLAY_PROPOSAL_PROPOSAL_CANDIDATES: 'pp-proposal-candidates',
  TEAM_AUGMENTATION_BRIEF_OVERVIEW: 'team-augmentation-brief-overview',
  PROJECT_BRIEF_OVERVIEW: 'project-brief-overview',
  PROJECT_PROPOSAL: 'project-proposal',
  TEAM_AUGMENTATION_PROPOSAL: 'team-augmentation-proposal',
  PROJECT_OVERVIEW: 'project-overivew',
  PROJECT_SETTINGS_FORM: 'project-settings-form',
  PROJECT_INVOICES: 'invoices',
  PROJECTS_LIST: 'projects-list',
  PROJECT_CREATE: 'project-create',
  TASKS: 'tasks',
  PROJECT_INVOICE: 'invoice',
  PROJECT_PROPOSALS_LIST: 'project-proposals-list',
  CHANGE_REQUEST: 'change-requests',
  PROJECT_ALLOCATIONS: 'project-allocations',
  PROJECT_ACTUALS: 'project-actuals',
  DP_MATCHING: 'dp-matching',
};
