import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import Select from '@components/forms/Select';
import { Field } from 'react-final-form';
import Checkbox from '@components/forms/_common/Checkbox';
import Icon from '@components/Icon';
import Button from '@styles/Button';
import { IS_PROD, ASSIGNMENT_STATUSES } from '@app/constants';
import Typography from '@styles/Typography';
import { map } from 'lodash-es';
import moment from 'moment';
import AssignmentActionButtons from './AssignmentActionButtons';
import AssignmentStatusBadge from './AssignmentStatusBadge';

export const DpIcon = ({ status }) => {
  const iconProps = React.useMemo(() => {
    if (status === ASSIGNMENT_STATUSES.EXCLUSIVE) {
      return { name: 'bolt', color: 'yellow.solid' };
    }
    if (status === ASSIGNMENT_STATUSES.SCHEDULED) {
      return { name: 'lock2', color: 'yellow.solid' };
    }
    if (status === ASSIGNMENT_STATUSES.AWAITING_RESPONSE) {
      return { name: 'hourglass2', color: 'orange.solid' };
    }
    if (status === ASSIGNMENT_STATUSES.NOT_INTERESTED) {
      return { name: 'cross', color: 'red.solid' };
    }
    if (status === ASSIGNMENT_STATUSES.SHOWN_INTEREST) {
      return { name: 'simpleTick', color: 'green.solid' };
    }
    if (
      status === ASSIGNMENT_STATUSES.PROPOSAL_SHARED ||
      status === ASSIGNMENT_STATUSES.CANDIDATES_SHARED
    ) {
      return { name: 'fancyTick', color: 'aqua.solid' };
    }
    return { name: 'edit2', color: 'dark.mid' };
  }, [status]);

  return <Icon fontSize={20} mr="10px" {...iconProps} />;
};

const ShortlistedSupplierRow = ({
  formApi,
  assignment,
  isExclusivitySelected,
  onDelete,
  onAssign,
  onRemind,
  fieldPrefix,
  isAnyAssignmentExclusive,
  shouldShowExclusiveCheckbox,
  mainNameQA,
}) => {
  return (
    <Flex minH="76px" alignItems="center">
      <Flex
        alignItems="center"
        maxW="260px"
        w="100%"
        pr="12px"
        wordBreak="break-all"
        data-test-id={`${mainNameQA}--supplierName`}
      >
        <DpIcon status={assignment.status} />
        {assignment?.supplier?.name}
      </Flex>
      {!shouldShowExclusiveCheckbox(assignment) ? (
        <Flex>
          {assignment.status === 'Cancelled' && (
            <Typography color="dark.mid">
              Brief no longer will be shared
            </Typography>
          )}
          {assignment.dueDate && (
            <Typography
              color="dark.mid"
              data-test-id={`${mainNameQA}--assigmentDueDate`}
            >
              {moment(assignment.dueDate).format('[Due] DD MMM YYYY @ HH:mmA')}
            </Typography>
          )}
          {assignment.assignedAt &&
            !assignment.dueDate &&
            assignment.status !== 'Cancelled' && (
              <Typography
                mr="64px"
                data-test-id={`${mainNameQA}--${
                  assignment.isExclusive ? 'exclusivelyAssigned' : 'assigned'
                }`}
              >
                {moment(assignment.assignedAt).format(
                  `[${
                    assignment.isExclusive ? 'Exclusively assigned' : 'Assigned'
                  }] DD MMM YYYY`,
                )}
              </Typography>
            )}
          {assignment.lastReminderDate && !assignment.dueDate && (
            <Typography data-test-id={`${mainNameQA}--lastReminded`}>
              {moment(assignment.lastReminderDate).format(
                '[Last reminded] DD MMM YYYY',
              )}
            </Typography>
          )}
        </Flex>
      ) : (
        <Flex
          alignItems="center"
          sx={{
            '@media (max-width: 1250px)': {
              flexWrap: 'wrap',
              my: '42px',
            },
          }}
        >
          <Field
            name={`${fieldPrefix}.exclusivityEnabled`}
            data-test-id={`${mainNameQA}--${fieldPrefix}.exclusivityEnabled`}
            variant="secondary"
            isDisabled={false}
            component={Checkbox}
            id={`exclusivityEnabled${fieldPrefix}`}
            label="Give exclusivity for"
            containerStyles={{
              mb: 0,
              mr: '20px',
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              sx: {
                '@media (max-width: 1250px)': {
                  marginBottom: '20px',
                },
              },
            }}
            onAfterChange={e => {
              const { values } = formApi.getState();
              formApi.change(
                'assignments',
                map(values.assignments, a => ({
                  ...a,
                  exclusivityEnabled:
                    a.id === assignment.id ? e.target.checked : false,
                })),
              );
            }}
          />
          <Field
            name={`${fieldPrefix}.exclusivityDuration`}
            data-test-id={`${mainNameQA}--${fieldPrefix}.exclusivityDuration`}
            isDisabled={!assignment.exclusivityEnabled}
            options={[
              ...(!IS_PROD
                ? [
                    { label: '3 mins', value: 0.05 },
                    { label: '15 mins', value: 0.25 },
                    { label: '1 hours', value: 1 },
                  ]
                : []),
              { label: '2 hours', value: 2 },
              { label: '4 hours', value: 4 },
              { label: '8 hours', value: 8 },
              { label: '12 hours', value: 12 },
              { label: '24 hours', value: 24 },
            ]}
            component={Select}
            styleProps={{ minW: '160px' }}
          />
        </Flex>
      )}
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        ml="auto"
        pl="12px"
        w="100%"
        maxW="359px"
      >
        <AssignmentStatusBadge
          assignment={assignment}
          mainNameQA={mainNameQA}
        />
        <Button
          iconButton={{
            name: 'trash3',
            color: 'dark.solid',
            fontSize: 24,
            _hover: { opacity: 0.7 },
          }}
          mx="12px"
          data-test-id={`${mainNameQA}--removeSupplier`}
          isDisabled={assignment.status === ASSIGNMENT_STATUSES.EXCLUSIVE}
          variant="ghost"
          onClick={onDelete}
        />
        <Box minW="110px" textAlign="right">
          <AssignmentActionButtons
            assignment={assignment}
            isExclusivitySelected={isExclusivitySelected}
            isAnyAssignmentExclusive={isAnyAssignmentExclusive}
            onAssign={onAssign}
            onRemind={onRemind}
            mainNameQA={mainNameQA}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default ShortlistedSupplierRow;
