import { PROPOSAL_CLIENT_STATUS } from '@app/constants';
import { Flex, Stack } from '@chakra-ui/react';
import { ErrorText } from '@components/forms/_common/FormError';
import Textarea from '@components/forms/_common/Textarea';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import {
  composeValidators,
  mustBeShorterOrEqual,
  required,
} from '@utils/formValidators';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form } from 'react-final-form';

const ClientDeclineProposalModal = ({
  isOpen,
  onClose,
  proposalId,
  proposalsStore: { briefId, proceedOrDeclineClientProposal },
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2" mb="24px" textAlign="center">
        Decline proposal
      </Typography>
      <Flex w="100%" mx="auto" flexDirection="column">
        <Typography mb="32px" textAlign="center">
          If possible, let us know why you’re declining this proposal. You can
          change your decision at any time.
        </Typography>
        <Form
          initialValues={{
            briefId,
            proposalId,
            clientStatus: PROPOSAL_CLIENT_STATUS.DECLINED,
          }}
          onSubmit={values =>
            proceedOrDeclineClientProposal({ ...values, successCb: onClose })
          }
          render={({ handleSubmit, submitting, submitError }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Field
                  component={Textarea}
                  inputProps={{ rows: 5 }}
                  maxW="488px"
                  mx="auto"
                  name="clientProposalStatusMessage"
                  validate={composeValidators(
                    required,
                    mustBeShorterOrEqual(500),
                  )}
                  placeholder="eg. We don’t have enough resource at the moment."
                />
                {submitError && <ErrorText mt="12px">{submitError}</ErrorText>}
                <Stack mt="40px" spacing={3}>
                  <Button
                    variant="warning"
                    isLoading={submitting}
                    type="submit"
                  >
                    Decline proposal
                  </Button>
                  <Button variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                </Stack>
              </form>
            );
          }}
        />
      </Flex>
    </Modal>
  );
};

export default inject('proposalsStore')(observer(ClientDeclineProposalModal));
