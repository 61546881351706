import { VStack } from '@chakra-ui/react';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { join, map, split } from 'lodash-es';
import Select from '@components/forms/_common/Select';
import MultiSelect from '@components/forms/MultiSelect';
import useSearchParams from '@hooks/useSearchParams';
import { MAIN_NAMES_QA } from '@app/constants';
import useEngagementTypes from '@hooks/useEngagementTypes';
import useCountries from '@hooks/useCountries';
import AutoSave from '../Proposal/AutoSave';

export const MatchingFiltersFields = ({ values }) => {
  const { countryOptions, isLoading: isLoadingCountries } = useCountries();
  const {
    engagementOptions,
    isLoading: isLoadingEngagements,
  } = useEngagementTypes();

  return (
    <VStack gap="24px" pb="6px" pt="12px">
      <Field
        name="engagementType"
        label="ENGAGEMENT MODEL"
        key={`engagment-${values.engagementType}`}
        component={Select}
        placeholder="Engagement model type"
        isLoading={isLoadingEngagements}
        options={engagementOptions}
        data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--engagementInput`}
      />
      <Field
        name="countries"
        label="EXCLUDE REGIONS"
        key={`countries-${JSON.stringify(values.countries)}`}
        component={MultiSelect}
        menuRelative
        showAllSelectedOptions
        placeholder="Exclude these regions"
        isLoading={isLoadingCountries}
        options={countryOptions}
        iconColor="black"
        data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--countryInput`}
      />
    </VStack>
  );
};

const MatchingFiltersForm = () => {
  const { searchObj, onSearch } = useSearchParams();

  const onSubmit = ({ countries: selectedCountries, ...values }) =>
    onSearch({
      ...searchObj,
      ...values,
      countries: join(
        map(selectedCountries, c => c.value),
        ',',
      ),
    });

  const initialValues = React.useMemo(
    () => ({
      filter: searchObj.filter,
      engagementType: searchObj.engagementType,
      countries:
        searchObj.countries &&
        map(split(searchObj.countries, ','), c => ({
          label: c,
          value: c,
        })),
    }),
    [searchObj.filter, searchObj.engagementType, searchObj.countries],
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, values, form }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <AutoSave formApi={form} onSave={onSubmit} />
            <MatchingFiltersFields values={values} />
          </form>
        );
      }}
    />
  );
};

export default MatchingFiltersForm;
