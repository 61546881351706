import { Flex, Stack } from '@chakra-ui/react';
import moment from 'moment';
import { isEmpty, join, map } from 'lodash-es';
import { formatCurrency } from '@utils';
import { TOP_PROJECT_PRIORITY } from '@app/constants';
import React from 'react';
import WhiteCard from '@components/WhiteCard';
import ReadOnlyResourcesTable from './ReadOnlyResourcesTable';
import MobileResourceCard from './MobileResourceCard';
import { Document, SectionTitle, Stat } from './components';

const RESOURCES_COLUMNS = [
  {
    name: 'Description',
    w: '30%',
  },
  { name: 'Seniority', w: '20%' },
  { name: 'Start date', w: '20%' },
  {
    name: 'Months',
    w: '20%',
  },
];

const BriefDetails = ({ brief, isSmallerScreen, mainNameQA }) => {
  const isPlugAndPlay = !!brief.newOrExisting;

  const priorityLabel = () => {
    if (brief.topProjectPriority === TOP_PROJECT_PRIORITY.PRICE) {
      return 'Getting the project completed for the best price';
    }
    if (brief.topProjectPriority === TOP_PROJECT_PRIORITY.TIME) {
      return 'Getting the project completed quickly';
    }
    if (brief.topProjectPriority === TOP_PROJECT_PRIORITY.QUALITY) {
      return 'Getting the project produced to the very best quality';
    }
    return null;
  };

  return (
    <WhiteCard pt="40px" {...(isSmallerScreen && { pt: 0, mt: '12px', px: 0 })}>
      <SectionTitle mt="0px">
        {isPlugAndPlay ? 'Requirements' : 'Basic details'}
      </SectionTitle>
      <Stack isInline spacing="16px" mb>
        <Stat
          w="50%"
          label="Client name "
          value={brief?.customer?.name}
          data-test-id={`${mainNameQA}--details-clientName`}
        />
        <Stat
          w="50%"
          label="Project name "
          value={brief?.name}
          data-test-id={`${mainNameQA}--details-projectName`}
        />
      </Stack>

      {isPlugAndPlay && !isEmpty(brief.industryVertical) && (
        <Stack isInline spacing="16px">
          <Stat
            w="50%"
            label="Industry vertical"
            value={brief.industryVertical}
            data-test-id={`${mainNameQA}--details-industryVertical`}
          />
          <Stat
            w="50%"
            label="Brand new product?"
            value={brief.newOrExisting === 'New' ? 'Yes' : 'No'}
            data-test-id={`${mainNameQA}--details-brandNewProduct`}
          />
        </Stack>
      )}
      <Stack isInline spacing="16px">
        <Stat
          isHTML
          w="100%"
          label={isPlugAndPlay ? 'Description' : 'Elevator pitch'}
          value={brief.description}
          data-test-id={`${mainNameQA}--details-${
            isPlugAndPlay ? 'description' : 'elevatorPitch'
          }`}
        />
      </Stack>

      {!isEmpty(brief.files) && (
        <>
          <SectionTitle>Shared documents</SectionTitle>
          <Flex
            w="100%"
            flexWrap="wrap"
            mb="56px"
            justifyContent="space-between"
          >
            {map(brief.files, file => (
              <Document
                w={isSmallerScreen ? '100%' : 'calc(50% - 12px)'}
                name={file.name}
                link={file.link}
                key={`file-${file.id}`}
                data-test-id={`${mainNameQA}--documents-${file.id}`}
              />
            ))}
          </Flex>
        </>
      )}

      {isPlugAndPlay && !isEmpty(brief.resourcesTableData) && (
        <>
          <SectionTitle>Desired resources</SectionTitle>
          {isSmallerScreen ? (
            <>
              {map(brief.resources, res => (
                <MobileResourceCard
                  key={`resource-detail-${res.id}`}
                  resource={res}
                  brief={brief}
                />
              ))}
            </>
          ) : (
            <ReadOnlyResourcesTable
              tableData={brief.resourcesTableData}
              columns={RESOURCES_COLUMNS}
              mainNameQA={mainNameQA}
            />
          )}
        </>
      )}

      {!isPlugAndPlay && (
        <>
          <SectionTitle>Tech requirements</SectionTitle>
          <Stack isInline spacing="16px">
            <Stat
              w="50%"
              label="App or website"
              value={brief.appOrWebsite}
              data-test-id={`${mainNameQA}--techRequirements-appOrWebsite`}
            />
            <Stat
              w="50%"
              label="Industry"
              value={brief.industryVertical}
              data-test-id={`${mainNameQA}--techRequirements-industryVertical`}
            />
          </Stack>
          {(brief.appOrWebsite === 'Mobile app' ||
            brief.appOrWebsite === 'Both') && (
            <Stack isInline spacing="8px">
              <Stat
                w="50%"
                label="Technical approach"
                value={brief.techApproach}
                data-test-id={`${mainNameQA}--techRequirements-techApproach`}
              />
              <Stat
                w="50%"
                label="Which platforms"
                value={brief.platforms}
                data-test-id={`${mainNameQA}--techRequirements-platforms`}
              />
            </Stack>
          )}
          <Stack isInline spacing="8px">
            <Stat
              w="100%"
              label="Required tech stacks"
              value={join(brief.technologies, ', ')}
              data-test-id={`${mainNameQA}--techRequirements-technologies`}
            />
          </Stack>
          <Stack isInline spacing="8px">
            <Stat
              w="100%"
              label="Additional technology information"
              value={brief.techRequirements}
              data-test-id={`${mainNameQA}--techRequirements-techRequirements`}
            />
          </Stack>
          <SectionTitle>Project details</SectionTitle>
          <Stack isInline spacing="16px">
            <Stat
              w="50%"
              label="Target date"
              value={
                brief.startDate &&
                moment.utc(brief.startDate).format('Do MMM YYYY')
              }
              data-test-id={`${mainNameQA}--projectDetails-startDate`}
            />
            <Stat
              w="50%"
              label="Proposal needed by"
              value={
                brief.proposalClosingDate &&
                moment.utc(brief.proposalClosingDate).format('Do MMM YYYY')
              }
              data-test-id={`${mainNameQA}--projectDetails-proposalClosingDate`}
            />
          </Stack>
          <Stack isInline spacing="16px">
            <Stat
              w="50%"
              label="Billing model"
              value={brief.preferredBillingModel}
              data-test-id={`${mainNameQA}--projectDetails-preferredBillingModel`}
            />
            <Stat
              w="50%"
              label="Top priority"
              value={priorityLabel()}
              data-test-id={`${mainNameQA}--projectDetails-priorityLabel`}
            />
          </Stack>
          <Stack isInline spacing="16px">
            <Stat
              w="50%"
              label="Budget"
              data-test-id={`${mainNameQA}--projectDetails-budget`}
              value={
                brief.hasBudget
                  ? `${formatCurrency(
                      brief.budgetFrom,
                      brief.currency,
                    )} - ${formatCurrency(brief.budgetTo, brief.currency)}`
                  : 'Not specified.'
              }
            />
          </Stack>
        </>
      )}
    </WhiteCard>
  );
};

export default BriefDetails;
