import { useMemo } from 'react';
import { API } from '@app/api';
import { map } from 'lodash-es';
import useFetch from './useFetch';

const countriesMap = countries => map(countries, c => ({ label: c, value: c }));

export default function useCountries() {
  const { data: countries, isLoading } = useFetch(API.getCountries, {
    cacheKey: 'countries',
    cacheTime: 5 * 60,
  });
  const countryOptions = useMemo(() => countriesMap(countries), [countries]);

  return {
    countryOptions,
    isLoading,
  };
}
