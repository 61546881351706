import { Box, Flex } from '@chakra-ui/react';
import { formatCurrency, formatNumber, getDeazyMarginFactor } from '@utils';
import React from 'react';
import Icon from '@components/Icon';
import { Field } from 'react-final-form';
import { isNil } from 'lodash-es';
import TextEllipsisWithTooltip from '@components/TextEllipsisWithTooltip';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import { INPUT_VARIANTS } from '@styles/theme';
import {
  getFormattedRates,
  isAdvancedFrameworkCheck,
} from '@utils/proposalUtils';
import { CURRENCIES_SYMBOLS } from '@app/constants';
import { inject, observer } from 'mobx-react';
import Typography from '@styles/Typography';
import ChangeRateReasonExpander from './ChangeRateReasonExpander';
import ResourceRateIndicator from '../Proposal/ResourceRateIndicator';

export const Cell = ({ children, asInput = false, label, ...props }) => {
  return asInput ? (
    <>
      {label && (
        <Typography
          color="dark.solid"
          fontSize={13}
          fontWeight={500}
          lineHeight="19px"
          mb="8px"
        >
          {label}
        </Typography>
      )}
      <Flex
        alignItems="center"
        mb="12px"
        px="12px"
        h="48px"
        border="1px solid"
        borderColor="dark.mid"
        borderRadius="8px"
        position="relative"
      >
        {children}
      </Flex>
    </>
  ) : (
    <Flex
      borderRight="1px solid"
      borderColor="dark.mid"
      alignItems="center"
      px="13px"
      {...props}
    >
      {children}
    </Flex>
  );
};

export const CellWithLink = ({
  currency,
  cellProps,
  w,
  iconProps,
  isReadOnly,
  asInput,
  ...props
}) => (
  <Cell w={w} position="relative" {...cellProps}>
    <Icon
      name="link"
      color="blue.solid"
      position="absolute"
      zIndex={1}
      right="3px"
      top="22px"
      bg="#fff"
      p="2px"
      {...iconProps}
      fontSize={24}
      {...(asInput && {
        right: '6px',
        top: '40px',
      })}
    />
    <Field
      {...(!asInput && {
        px: '0px',
        pt: '0px',
        pr: '12px',
      })}
      isDisabled={isReadOnly}
      component={MaskedTextInput}
      variant={!asInput && INPUT_VARIANTS.CONTAINER_FILLED}
      decimalScale={2}
      fixedDecimalScale
      prefix={CURRENCIES_SYMBOLS[currency]}
      placeholder="-"
      {...props}
    />
  </Cell>
);

const TeamRoleRow = ({
  idx,
  fieldName = 'roles',
  resource,
  currency = 'GBP',
  formApi,
  proposalSupplierCurrency,
  proposalExchangeRate,
  isReadOnly,
  ratesStore: { getResourceRate },
  briefsStore: { brief },
  mainNameQA,
  isCoreTeam,
}) => {
  const clientCost = resource.amount * resource.clientRate;

  const { dpTargetRate, clientRateRange } = React.useMemo(() => {
    return getFormattedRates({
      ...getResourceRate(
        resource.name,
        resource.seniority,
        false,
        isAdvancedFrameworkCheck(brief.technologies),
        brief?.customer?.type,
      ),
      currency,
      supplierCurrency: proposalSupplierCurrency,
    });
  }, [resource, brief.technologies, proposalSupplierCurrency, currency]);

  return (
    <>
      <Flex
        border="1px solid"
        borderColor="dark.mid"
        h="72px"
        w="100%"
        borderRadius="8px"
        mb="12px"
        data-test-id={`${mainNameQA}--${
          isCoreTeam ? 'core' : 'additional'
        }TeamRow`}
      >
        <Cell
          w="16%"
          opacity={isReadOnly && 0.5}
          data-test-id={mainNameQA && `${mainNameQA}--resourceName`}
        >
          {resource.name}
        </Cell>
        <Cell
          w="16%"
          opacity={isReadOnly && 0.5}
          data-test-id={mainNameQA && `${mainNameQA}--resourceSeniority`}
        >
          {resource.seniority}
        </Cell>
        <Cell w="12%" position="relative">
          <Box
            as="span"
            opacity={0.5}
            data-test-id={mainNameQA && `${mainNameQA}--resourceRate`}
          >
            {formatCurrency(resource.rate, proposalSupplierCurrency)}
          </Box>
          <ResourceRateIndicator
            formApi={formApi}
            position="absolute"
            isDeazyView
            top="33%"
            right="5px"
            zIndex={30}
            prefix={`${fieldName}[${idx}]`}
          />
        </Cell>
        <Cell
          w="12%"
          opacity={0.5}
          data-test-id={mainNameQA && `${mainNameQA}--resourceAmount`}
        >
          {formatNumber(resource.amount)}
        </Cell>
        <Cell
          w="12%"
          opacity={0.5}
          borderColor="dark.solid"
          data-test-id={mainNameQA && `${mainNameQA}--resourceDPCost`}
        >
          {formatCurrency(
            resource.rate * resource.amount,
            proposalSupplierCurrency,
          )}
        </Cell>
        <CellWithLink
          data-test-id={mainNameQA && `${mainNameQA}--resourceClientRate`}
          w="10%"
          name={`${fieldName}[${idx}].clientRate`}
          iconProps={{
            opacity: 0.5,
            ...(resource.autoMargin && { name: 'unlink' }),
          }}
          cellProps={{ pr: '28px' }}
          currency={currency}
          onAfterChange={clientRate => {
            if (!clientRate) {
              clientRate = 0.01;
              formApi.change(`${fieldName}[${idx}].clientRate`, 0.01);
              const el = document.querySelector(
                `input[name="${fieldName}[${idx}].clientRate"]`,
              );
              if (el) {
                setTimeout(() => {
                  el.select();
                }, 100);
              }
            }
            formApi.batch(() => {
              const margin =
                100 * (1 - (resource.rate * proposalExchangeRate) / clientRate);
              formApi.change(`${fieldName}[${idx}].margin`, margin);
              formApi.change(`${fieldName}[${idx}].autoMargin`, true);
              formApi.change(`${fieldName}[${idx}].autoClientRate`, false);
            });
          }}
          isAllowed={({ floatValue }) => {
            return floatValue >= 0.01 || isNil(floatValue);
          }}
          changeIfActive
          isReadOnly={isReadOnly}
        />
        <CellWithLink
          data-test-id={mainNameQA && `${mainNameQA}--resourceMargin`}
          w="10%"
          name={`${fieldName}[${idx}].margin`}
          cellProps={{ pr: '28px' }}
          iconProps={{
            opacity: 0.5,
            ...(resource.autoClientRate && { name: 'unlink' }),
          }}
          decimalScale={0}
          onAfterChange={margin => {
            if (!margin) {
              margin = 0;
              formApi.change(`${fieldName}[${idx}].margin`, 0);
              const el = document.querySelector(
                `input[name="${fieldName}[${idx}].margin"]`,
              );
              if (el) {
                setTimeout(() => {
                  el.select();
                }, 100);
              }
            }

            formApi.batch(() => {
              formApi.change(
                `${fieldName}[${idx}].clientRate`,
                resource.rate *
                  getDeazyMarginFactor(margin) *
                  proposalExchangeRate,
              );
              formApi.change(`${fieldName}[${idx}].autoClientRate`, true);
              formApi.change(`${fieldName}[${idx}].autoMargin`, false);
            });
          }}
          changeIfActive
          isAllowed={({ floatValue }) => {
            return (floatValue >= 0 && floatValue <= 99) || isNil(floatValue);
          }}
          isReadOnly={isReadOnly}
        />
        <Cell w="12%" borderRight="none" px="0px" pr="0">
          <Flex
            pl="12px"
            w="100%"
            h="100%"
            bg="blue.light"
            alignItems="center"
            position="relative"
          >
            <TextEllipsisWithTooltip
              pr="22px"
              variant="text1"
              data-test-id={mainNameQA && `${mainNameQA}--resourceClientCost`}
            >
              {formatCurrency(clientCost, currency)}
            </TextEllipsisWithTooltip>
            <Icon
              name="link"
              color="blue.solid"
              position="absolute"
              zIndex={1}
              right="1px"
              top="22px"
              p="2px"
              fontSize={24}
            />
          </Flex>
        </Cell>
      </Flex>
      <Flex w="100%" mb="24px" position="relative">
        <Box w="32%">
          <ChangeRateReasonExpander
            reason={resource.comment}
            textProps={{ position: 'absolute' }}
          />
        </Box>
        <Typography w="12%" variant="text4" color="gray.mid">
          {dpTargetRate && `DP target rate: ${dpTargetRate}`}
        </Typography>
        <Box w="24%" />
        <Typography w="10%" variant="text4" color="gray.mid">
          {clientRateRange && `Client rate: ${clientRateRange}`}
        </Typography>
      </Flex>
    </>
  );
};

export default inject('ratesStore', 'briefsStore')(observer(TeamRoleRow));
