import React from 'react';
import WhiteCard from '@components/WhiteCard';
import useMediaQuery from '@hooks/useMediaQuery';
import { MAIN_NAMES_QA } from '@app/constants';
import { inject, observer } from 'mobx-react';
import AdditionalRequirementsForm from './AdditionalRequirementsForm';
import SupplierAssignmentForm from './SupplierAssignmentForm';
import SupplierList from './SupplierList';

const Assignment = ({
  briefAssignmentsStore: {
    clearRecommendedSuppliers,
    fetchAssignmentList,
    clearAssignmentList,
  },
  appStore: { featureFlags },
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  React.useEffect(() => {
    fetchAssignmentList();
    return () => {
      clearAssignmentList();
      clearRecommendedSuppliers();
    };
  }, []);

  const mainNameQA = MAIN_NAMES_QA.BRIEF_ASSIGNMENT;
  const deliveryPartnerMatchingEnabled =
    featureFlags['delivery-partner-matching'];

  return (
    <>
      <WhiteCard
        title={
          isSmallerScreen
            ? 'Assign delivery partners'
            : 'Manage delivery partners on this brief'
        }
        mt="0px"
        {...(isSmallerScreen && { px: 0, pt: 0 })}
      >
        <SupplierAssignmentForm
          mainNameQA={mainNameQA}
          isMobile={isSmallerScreen}
        />
        <SupplierList mainNameQA={mainNameQA} isMobile={isSmallerScreen} />
      </WhiteCard>
      {!deliveryPartnerMatchingEnabled && (
        <AdditionalRequirementsForm mainNameQA={mainNameQA} />
      )}
    </>
  );
};

export default inject(
  'briefAssignmentsStore',
  'usersStore',
  'appStore',
)(observer(Assignment));
