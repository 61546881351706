import React from 'react';
import Icon from '@components/Icon';
import { Field, FormSpy } from 'react-final-form';
import { isRetainerOrTAForFixedPrice } from '@utils/projectUtils';
import {
  CURRENCIES_SYMBOLS,
  PROJECT_PAYMENT_TYPES,
  PROJECT_TYPES,
} from '@app/constants';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import TextInput from '@components/forms/_common/TextInput';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { find, get, isEmpty, map, reduce, reject, toNumber } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import InvoiceTotalValueLabel from './InvoiceTotalValueLabel';

const AutoCalculatedInput = ({
  input = {},
  customInputBoxProps,
  showTooltip,
  ...props
}) => (
  <Box position="relative" {...customInputBoxProps}>
    {showTooltip && (
      <Tooltip
        zIndex={10000}
        label="This value is autocalculated"
        hasArrow
        p="12px"
        placement="top"
      >
        <Box as="span" right="12px" top="38px" zIndex="30" position="absolute">
          <Icon name="link" color="blue.solid" fontSize={24} mb="2px" />
        </Box>
      </Tooltip>
    )}
    <TextInput
      input={input}
      {...props}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
    />
  </Box>
);

const PercentageAndAbsoluteFields = ({
  requiredValidation,
  fieldPrefix,
  selectedSupplier,
  projectsStore: { project },
  paymentsStore: { maxPaymentValues },
  mainNameQA,
}) => {
  const { maxClientValue, maxSuppliersValue } = maxPaymentValues;
  const { clientTotalValue, projectType, projectToSuppliers } = project;
  const currentSupplier = find(
    projectToSuppliers,
    ptos => ptos?.supplier?.id === selectedSupplier?.supplier?.id,
  );

  const isTeamAdmin = fieldPrefix !== 'client';

  const isFixedPrice = projectType === PROJECT_TYPES.FIXED_PRICE;

  const maxValue = isTeamAdmin
    ? get(
        find(
          maxSuppliersValue,
          ({ supplier }) => supplier?.id === selectedSupplier?.supplier?.id,
        ),
        'maxSupplierValue',
      )
    : maxClientValue;

  const total = isTeamAdmin
    ? currentSupplier?.supplierTotalValue
    : clientTotalValue;

  const currency = isTeamAdmin
    ? currentSupplier?.overriddenCurrency || currentSupplier?.supplier?.currency
    : project.currency;

  return (
    <FormSpy subscription={{ submitting: true, values: true }}>
      {({ form, values }) => {
        const isFixedPayment = values.paymentType === 'Fixed Payment';
        React.useEffect(() => {
          if (
            isEmpty(get(values, `[${fieldPrefix}].lineItems`)) &&
            isFixedPayment
          ) {
            form.change(`${fieldPrefix}.lineItems`, [
              { purchaseOrder: project.purchaseOrder || '' },
            ]);
          }
        }, [isFixedPayment, selectedSupplier?.supplier?.id]);

        const onAddRow = () => {
          form.change(`${fieldPrefix}.lineItems`, [
            ...(get(values, `${fieldPrefix}.lineItems`) || []),
            { purchaseOrder: project.purchaseOrder || '' },
          ]);
        };
        const onDeleteRow = idx => {
          form.change(
            `${fieldPrefix}.lineItems`,
            reject(
              get(values, `${fieldPrefix}.lineItems`),
              (item, i) => idx === i,
            ),
          );
        };

        const doesInvoiceLineItemExceedTotal = idx => {
          if (
            !get(values, `${fieldPrefix}.lineItems[${idx}].paymentPercent`) &&
            !get(values, `${fieldPrefix}.lineItems[${idx}].paymentAmount`)
          ) {
            return false;
          }
          if (isFixedPrice) {
            const totalValue = reduce(
              get(values, `${fieldPrefix}.lineItems`),
              (sum, item) => {
                return sum + (+item.paymentAmount || 0);
              },
              0,
            );
            return totalValue > maxValue;
          }
          return false;
        };
        return (
          <>
            {map(
              get(values, `${fieldPrefix}.lineItems`, [{}]),
              (item, itemIdx) => (
                <Flex
                  justifyContent="flex-start"
                  mb={isFixedPayment ? '32px' : '0px'}
                  w="100%"
                  key={`line-item-key-${itemIdx}`}
                >
                  {!isRetainerOrTAForFixedPrice(project) && (
                    <>
                      <Field
                        label={
                          <InvoiceTotalValueLabel
                            text="Total project value %"
                            exceedsTotal={doesInvoiceLineItemExceedTotal(
                              itemIdx,
                            )}
                          />
                        }
                        name={`${fieldPrefix}.lineItems[${itemIdx}].paymentPercent`}
                        data-test-id={`${mainNameQA}-${fieldPrefix}.lineItems[${itemIdx}].paymentPercent`}
                        component={MaskedTextInput}
                        decimalScale={2}
                        fixedDecimalScale
                        placeholder="0.00"
                        suffix="%"
                        customInput={AutoCalculatedInput}
                        changeIfActive
                        isErrorAbsolute
                        validate={requiredValidation}
                        isAllowed={({ floatValue }) =>
                          floatValue > 0 || !floatValue
                        }
                        onAfterChange={value => {
                          form.batch(() => {
                            form.change(
                              `${fieldPrefix}.lineItems[${itemIdx}].paymentAmount`,
                              (toNumber(value) / 100) * total,
                            );
                            form.change(
                              `${fieldPrefix}.lineItems[${itemIdx}].autoPercent`,
                              false,
                            );
                            form.change(
                              `${fieldPrefix}.lineItems[${itemIdx}].autoAmount`,
                              true,
                            );
                          });
                        }}
                        showTooltip={
                          !!get(
                            form.getState().values,
                            `${fieldPrefix}.lineItems[${itemIdx}].autoPercent`,
                          )
                        }
                        containerProps={{ w: '100%' }}
                        customInputBoxProps={{
                          w: 'auto',
                        }}
                      />
                      <Typography
                        mt="42px"
                        variant="text3"
                        w="50px"
                        textAlign="center"
                      >
                        OR
                      </Typography>
                    </>
                  )}
                  <Field
                    label={
                      <InvoiceTotalValueLabel
                        text="Absolute amount"
                        exceedsTotal={doesInvoiceLineItemExceedTotal(itemIdx)}
                      />
                    }
                    changeIfActive={false}
                    name={`${fieldPrefix}.lineItems[${itemIdx}].paymentAmount`}
                    data-test-id={`${mainNameQA}-${fieldPrefix}.lineItems[${itemIdx}].paymentAmount`}
                    component={MaskedTextInput}
                    prefix={CURRENCIES_SYMBOLS[currency]}
                    customInput={AutoCalculatedInput}
                    decimalScale={2}
                    validate={requiredValidation}
                    isErrorAbsolute
                    showTooltip={
                      !!get(
                        form.getState().values,
                        `${fieldPrefix}.lineItems[${itemIdx}].autoAmount`,
                      )
                    }
                    isAllowed={({ floatValue }) =>
                      floatValue > 0 || !floatValue
                    }
                    onAfterChange={value => {
                      form.batch(() => {
                        form.change(
                          `${fieldPrefix}.lineItems[${itemIdx}].paymentPercent`,
                          (toNumber(value) * 100) / total,
                        );
                        form.change(
                          `${fieldPrefix}.lineItems[${itemIdx}].autoPercent`,
                          true,
                        );
                        form.change(
                          `${fieldPrefix}.lineItems[${itemIdx}].autoAmount`,
                          false,
                        );
                      });
                    }}
                    fixedDecimalScale
                    placeholder="0.00"
                    containerProps={{ w: '100%' }}
                    customInputBoxProps={{
                      w: !isRetainerOrTAForFixedPrice(project)
                        ? 'auto'
                        : 'calc(50% - 12px)',
                    }}
                  />
                  <Field
                    name={
                      values.isDuplicated &&
                      values.paymentType !==
                        PROJECT_PAYMENT_TYPES.T_AND_M_PAYMENT
                        ? `${fieldPrefix}.lineItems[${itemIdx}].name`
                        : `${fieldPrefix}.lineItems[${itemIdx}].purchaseOrder`
                    }
                    data-test-id={
                      values.isDuplicated &&
                      values.paymentType !==
                        PROJECT_PAYMENT_TYPES.T_AND_M_PAYMENT
                        ? `${mainNameQA}-${fieldPrefix}.lineItems[${itemIdx}].name`
                        : `${mainNameQA}-${fieldPrefix}.lineItems[${itemIdx}].purchaseOrder`
                    }
                    component={TextInput}
                    label={
                      values.isDuplicated &&
                      values.paymentType !==
                        PROJECT_PAYMENT_TYPES.T_AND_M_PAYMENT
                        ? 'Description'
                        : 'PO number'
                    }
                    containerProps={{
                      w: !isRetainerOrTAForFixedPrice(project)
                        ? 'auto'
                        : 'calc(50% - 12px)',
                      ml: '24px',
                    }}
                  />

                  {itemIdx !== 0 && (
                    <Button
                      alignSelf="center"
                      iconButton={{ name: 'trash' }}
                      color="red.solid"
                      mt="22px"
                      ml="auto"
                      variant="ghost"
                      borderRadius="100%"
                      fontSize={24}
                      width="32px"
                      height="32px"
                      minW="32px"
                      onClick={() => {
                        onDeleteRow(itemIdx);
                      }}
                      data-test-id={`${mainNameQA}-deleteRow`}
                    />
                  )}
                </Flex>
              ),
            )}
            {isFixedPayment && (
              <Flex justifyContent="center">
                <Box
                  alignItems="center"
                  display="inline-flex"
                  color="blue.solid"
                  _hover={{
                    cursor: 'pointer',
                    bg: 'dark.light',
                  }}
                  px="16px"
                  borderRadius="8px"
                  py="8px"
                  mx="auto"
                  onClick={onAddRow}
                  data-test-id={`${mainNameQA}-addNewAmountButton`}
                >
                  <Typography variant="text2" mt="3px">
                    Add new amount
                  </Typography>
                  <Icon name="add" fontSize={14} ml="12px" />
                </Box>
              </Flex>
            )}
          </>
        );
      }}
    </FormSpy>
  );
};
export default inject(
  'paymentsStore',
  'projectsStore',
)(observer(PercentageAndAbsoluteFields));
