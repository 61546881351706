import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import Button from '@styles/Button';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import React from 'react';
import { MAIN_NAMES_QA } from '@app/constants';
import { API } from '@app/api';
import useQuery from '@hooks/useQuery';
import { Field, Form, FormSpy } from 'react-final-form';
import { isFixedPrice, isRetainerOrTAForFixedPrice } from '@utils/projectUtils';
import DeazyAllocationTable from '@components/DeazyAllocationTable';
import DatePicker from '@components/forms/DatePicker';
import { getEarliestStartDateForAllocationRateOrHourChange } from '@utils/allocationUtils';
import { required } from '@utils/formValidators';
import { pick, get, isEmpty } from 'lodash-es';
import { ErrorText } from '@components/forms/FormError';
import FormSpyFieldValues from '@components/forms/FormSpyFieldValues';
import Typography from '@styles/Typography';
import withDelay from '../../../hoc/withDelay';
import ProjectFormListener from '../../ProjectsCreate/ProjectFormListener';
import SeeAllButton from '../SeeAllButton';
import ConfirmationModal from './ConfirmationModal';
import AllocationHistory from './AllocationHistory';

const DelayedDeazyAllocations = withDelay(100)(DeazyAllocationTable);

const DeazyAllocations = ({
  projectId,
  projectsStore: { project, prepareAllocation },
  resourceAsText,
  allocationsStore: {
    updateAllocations,
    fetchCurrentDeazyAllocations,
    fetchAllocationBorderDate,
    canEditAllocations,
    allocationBorderDates,
    deazyAllocations: { current },
  },
}) => {
  React.useEffect(() => {
    fetchCurrentDeazyAllocations(projectId);
  }, []);

  const { data: auditNotes, isLoading: auditNotesLoading, refetch } = useQuery(
    () =>
      isRetainerOrTAForFixedPrice(project)
        ? API.getAuditNotesForDeazyAllocation(project.id)
        : {},
    {},
    true,
  );

  const isFixedPriceProject = project.projectType === 'Fixed Price';

  const isTodayInsideProjectRange = moment
    .utc()
    .isBetween(project.startDate, project.endDate);

  const initialValues = {
    projectId,
    ...pick(project, [
      'startDate',
      'endDate',
      'currency',
      'overriddenCurrency',
      'deazyMarginPercent',
      'supplierCurrencyRate',
      'projectType',
      'engagementType',
    ]),
    deazyAllocations: prepareAllocation(toJS(get(current, 'data[0].entries'))),
    applicableFrom: isTodayInsideProjectRange ? moment.utc().format() : null,
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={values => updateAllocations(values, true, refetch)}
      subscription={{
        submitting: true,
        dirty: true,
        submitError: true,
        submitErrors: true,
        modified: true,
        submitSucceeded: true,
      }}
      render={({
        handleSubmit,
        submitting,
        dirty,
        form,
        submitError,
        submitSucceeded,
      }) => {
        const [isEditing, setIsEditing] = React.useState(false);
        const { isOpen, onOpen, onClose } = useDisclosure();
        const [isHistoryOpened, setIsHistoryOpened] = React.useState(false);

        const [earliestStartDate, setEarlinestStartDate] = React.useState(
          false,
        );

        React.useEffect(() => {
          if (submitSucceeded) {
            setIsEditing(false);
          }
        }, [submitSucceeded]);

        React.useEffect(() => {
          if (isEditing && isFixedPrice(project)) {
            fetchAllocationBorderDate(project.id, () => setIsEditing(false));
          }
          if (!isEditing) {
            form.reset();
          }
        }, [isEditing]);

        return (
          <form onSubmit={handleSubmit}>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                const allocationEarliestStartDate = getEarliestStartDateForAllocationRateOrHourChange(
                  initialValues?.deazyAllocations,
                  values?.deazyAllocations,
                );
                setEarlinestStartDate(allocationEarliestStartDate);
                return <ProjectFormListener formApi={form} values={values} />;
              }}
            </FormSpy>
            <Box>
              {!isFixedPriceProject && (
                <Typography variant="h4" my="20px">
                  Current deazy allocations
                </Typography>
              )}
              {earliestStartDate && isFixedPriceProject && (
                <FormSpyFieldValues fieldNames={['startDate']}>
                  {({ startDate }) => {
                    const borderDate = allocationBorderDates[project.id];
                    const disabledFn = day =>
                      moment
                        .utc(day)
                        .endOf('day')
                        .isBefore(moment.utc(borderDate || startDate)) ||
                      moment
                        .utc(day)
                        .endOf('day')
                        .isBefore(
                          moment.utc(earliestStartDate).add({ days: 1 }),
                        ) ||
                      moment.utc(day).isAfter(moment.utc(project.endDate));
                    return (
                      <Box maxW="340px" mb="12px">
                        <Field
                          disabledDaysFn={disabledFn}
                          dayPickerProps={{
                            ...((earliestStartDate ||
                              borderDate ||
                              startDate) && {
                              month: moment
                                .utc(
                                  earliestStartDate || borderDate || startDate,
                                )
                                .toDate(),
                            }),
                          }}
                          name="updateStartsFrom"
                          label="Rate/hour changes take effect from:"
                          validate={required}
                        >
                          {props => {
                            React.useEffect(() => {
                              if (
                                moment
                                  .utc(earliestStartDate)
                                  .add({ days: 1 })
                                  .isAfter(moment.utc(props.input.value))
                              ) {
                                form.change('updateStartsFrom', null);
                              }
                            }, [earliestStartDate, props.input.value]);
                            return <DatePicker {...props} />;
                          }}
                        </Field>
                      </Box>
                    );
                  }}
                </FormSpyFieldValues>
              )}
              <DelayedDeazyAllocations
                initialAllocations={initialValues.deazyAllocations}
                resourceAsText={resourceAsText}
                isManualAddingRow
                isEditing={isEditing}
              />
              {submitError && <ErrorText>{submitError}</ErrorText>}
              <Flex alignItems="flex-end" mt="40px">
                {!isEmpty(auditNotes) && (
                  <Button
                    iconButton={{
                      name: 'chevronDown',
                      fontSize: 12,
                      color: 'primary.400',
                    }}
                    bg="primary.100"
                    width="48px"
                    h="48px"
                    border="2px solid"
                    mr="16px"
                    transform={
                      isHistoryOpened ? 'rotate(-180deg)' : 'rotate(0deg)'
                    }
                    data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--deazyAllocationsHistoryButton`}
                    onClick={() => setIsHistoryOpened(!isHistoryOpened)}
                  />
                )}
                {isEditing ? (
                  <Flex>
                    <Button
                      leftIcon={{ name: 'check' }}
                      mr="12px"
                      onClick={handleSubmit}
                      isLoading={submitting}
                      h="48px"
                      data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--confirmDeazyAllocationsButton`}
                    >
                      Confirm changes
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => setIsEditing(false)}
                      h="48px"
                      data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--cancelDeazyAllocationsButton`}
                    >
                      Cancel
                    </Button>
                  </Flex>
                ) : (
                  <Flex>
                    {canEditAllocations && (
                      <Button
                        leftIcon={{ name: 'edit' }}
                        mr="12px"
                        h="48px"
                        data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--editDeazyAllocationsButton`}
                        onClick={() => {
                          if (isFixedPriceProject) {
                            return onOpen();
                          }
                          return setIsEditing(true);
                        }}
                      >
                        Edit allocations
                      </Button>
                    )}

                    <SeeAllButton
                      to="all-deazy-allocations"
                      data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--seeAllDeazyAllocationsButton`}
                    >
                      See all team allocations
                    </SeeAllButton>
                  </Flex>
                )}

                {dirty && !isFixedPriceProject && (
                  <Flex alignItems="flex-end">
                    <Box maxW="300px" mr="32px">
                      <Field
                        label="Starting from"
                        component={DatePicker}
                        disabledDaysFn={day =>
                          !moment
                            .utc(day)
                            .isBetween(
                              moment.utc(project.startDate).startOf('day'),
                              moment.utc(project.endDate).endOf('day'),
                            )
                        }
                        name="applicableFrom"
                      />
                    </Box>
                  </Flex>
                )}
              </Flex>
              {isHistoryOpened && (
                <AllocationHistory
                  auditNotes={auditNotes}
                  isLoading={auditNotesLoading}
                />
              )}
            </Box>
            <ConfirmationModal
              onClose={onClose}
              isOpen={isOpen}
              onConfirm={() => {
                setIsEditing(true);
                return onClose();
              }}
            />
          </form>
        );
      }}
    />
  );
};
export default inject(
  'projectsStore',
  'allocationsStore',
)(observer(DeazyAllocations));
