import { useEffect, useState } from 'react';

const useQuery = (apiFn, triggerParams, fetchOnce = false) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [firstRequest, setFirstRequest] = useState(true);

  const fetch = async () => {
    if (isLoading) {
      return;
    }
    try {
      setFirstRequest(false);
      setData({});
      setIsLoading(true);
      const { data: _data } = await apiFn();
      setData(_data);
    } catch (e) {
      setError(e);
      setData({});
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if ((triggerParams && !fetchOnce) || (fetchOnce && firstRequest)) {
      fetch();
    }
  }, [JSON.stringify(triggerParams), fetchOnce, firstRequest]);

  return {
    refetch: fetch,
    data,
    isLoading,
    error,
  };
};

export default useQuery;
