import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const RoleShortlistedWarningModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2" mb="24px" textAlign="center">
        Role already shortlisted
      </Typography>
      <Typography mb="12px" textAlign="center">
        This role has already been approved. By editing the role will be removed
        from shortlist and will returned to ‘proposed candidate’ status.
      </Typography>
      <Typography mb="40px" textAlign="center">
        Are you sure you’d like to edit?
      </Typography>
      <Stack spacing={3}>
        <Button h="44px" onClick={onConfirm}>
          Yes, continue to edit
        </Button>
        <Button h="44px" onClick={onClose} variant="outline">
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};

export default RoleShortlistedWarningModal;
