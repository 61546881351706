import { APP_ROUTES } from '@routes';
import { includes } from 'lodash-es';
import { action, makeObservable, observable } from 'mobx';
import {
  setIsAuthorized,
  setIsAuthorizedFrontegg,
  destroyIsAuthorized,
  isAuthorized,
} from '../app/api';

export default class AuthStore {
  constructor({ API, history, appStore, toastsStore, routerStore }) {
    makeObservable(this);
    this.API = API;
    this.history = history;
    this.appStore = appStore;
    this.toastsStore = toastsStore;
    this.routerStore = routerStore;

    this._setInitialLocation();
  }

  @observable isLoading = false;

  @observable isAuthenticated = isAuthorized();

  @observable isForbidden = false;

  @observable lastLocation;

  _setInitialLocation = () => {
    const { pathname, search } = this.routerStore.location;
    if (
      !['/sign-in', '/login'].includes(pathname) &&
      pathname !== APP_ROUTES.auth.forgottenPassword &&
      !includes(pathname, 'magic-link')
    ) {
      this.lastLocation = `${pathname}${search}`;
    }
  };

  @action setIsForbidden = isForbidden => {
    this.isForbidden = isForbidden;
  };

  @action
  signIn = async values => {
    this.isLoading = true;
    try {
      const { data: { token } = {} } = await this.API.signIn(values);
      setIsAuthorized(token);
      this.isAuthenticated = true;
    } catch (e) {
      return {
        username: ' ',
        password: 'We could not find a match for these details.',
      };
    } finally {
      this.isLoading = false;
    }
    return undefined;
  };

  @action
  magicLinkSignIn = async values => {
    this.isLoading = true;
    try {
      const {
        data: { jwtToken, redirectUrl } = {},
      } = await this.API.magicLinkSignIn(values);
      setIsAuthorized(jwtToken);
      this.isAuthenticated = true;
      this.routerStore.push(redirectUrl);
    } catch {
      this.toastsStore.showError({
        title: 'Your token is not valid!',
      });
      setTimeout(() => {
        this.routerStore.push(APP_ROUTES.auth.signIn);
      }, 1000);
    } finally {
      this.isLoading = false;
    }
  };

  @action forgotPassword = async ({ email }) => {
    try {
      await this.API.recovery({ email });
    } catch (e) {
      return {
        email: 'We could not find a match for these details.',
      };
    }
    return undefined;
  };

  @action
  signOut = async (setLasLocation = true) => {
    const {
      location: { search, pathname },
    } = this.routerStore;
    const lastLocation = `${pathname}${search}`;
    if (
      !includes(lastLocation, APP_ROUTES.auth.signIn) &&
      !includes(lastLocation, APP_ROUTES.auth.forgottenPassword) &&
      setLasLocation
    ) {
      this.lastLocation = lastLocation;
    }
    if (pathname === APP_ROUTES.projects && setLasLocation) {
      this.lastLocation = APP_ROUTES.projects;
    }
    this.isAuthenticated = false;
    destroyIsAuthorized();
  };

  @action
  fronteggSignIn = token => {
    this.isLoading = true;
    try {
      setIsAuthorizedFrontegg(token);
      this.isAuthenticated = true;
    } catch (e) {
      return undefined;
    } finally {
      this.isLoading = false;
    }
    return undefined;
  };

  @action
  fronteggSignOut = async () => {
    const {
      location: { search, pathname },
    } = this.routerStore;
    const lastLocation = `${pathname}${search}`;
    this.lastLocation = lastLocation;

    localStorage.setItem('fgLastLocation', lastLocation);
    localStorage.setItem('redirectToLastLocation', true);

    if (pathname === APP_ROUTES.projects) {
      this.lastLocation = APP_ROUTES.projects;
    }

    this.isAuthenticated = false;
    destroyIsAuthorized();
    this.history.replace('/logout');
  };
}
