import { BANKING_DETAILS, PROJECT_PAYMENT_TYPES } from '@app/constants';
import { Box, Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { get, join, values } from 'lodash-es';
import moment from 'moment';
import React from 'react';
import { Stat } from './InvoiceHeader';

const BANK_DETAILS_STR = join(values(BANKING_DETAILS), '\n');

const InvoiceFooter = ({ invoice, mainNameQA }) => {
  const isClient = invoice.contactType === 'Client';
  const isDepositType =
    invoice.projectPayment?.paymentType === PROJECT_PAYMENT_TYPES.DEPOSIT;
  const bankDetails = isClient
    ? BANK_DETAILS_STR
    : get(invoice, 'invoiceDetailsInfo.paymentInfo');

  return (
    <Flex borderTop="1px solid" borderColor="dark.mid" pt="12px">
      <Stat
        label="Due date"
        value={moment.utc(invoice.dueDate).format('Do MMMM YYYY')}
        minW="120px"
        mt="8px"
        data-test-id={`${mainNameQA}--dueDate`}
      />
      {bankDetails && (
        <Box
          borderLeft="1px solid"
          borderColor="dark.mid"
          pb="18px"
          pt="6px"
          pl="12px"
        >
          {isDepositType && (
            <Box mb="16px">
              <Typography fontFamily="WM" mb="8px">
                Security Deposit:
              </Typography>
              <Typography mb="8px">
                This is not a VAT invoice, this amount will be refunded or
                allocated against any amount owed at the end of the project.
              </Typography>
            </Box>
          )}
          {isClient ? (
            <Typography mb="8px">
              Please make payment via BACS transfer using the following bank
              details:
            </Typography>
          ) : (
            <Typography mb="8px">Invoice details:</Typography>
          )}
          <Typography
            fontFamily="WM"
            whiteSpace="pre-line"
            mb="8px"
            data-test-id={`${mainNameQA}--bankDetails`}
          >
            {bankDetails}
          </Typography>
          {isClient && (
            <>
              <Typography fontFamily="WM" mb="8px">
                Beneficiary: Deazy Limited
              </Typography>
              <Typography fontSize={14} mb="8px">
                ** PLEASE NOTE NEW BANK DETAILS **
              </Typography>
            </>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default InvoiceFooter;
