import React from 'react';
import Select from '@components/forms/Select';
import { INVOICE_AMOUNT_TYPES } from '@app/constants';
import { Field } from 'react-final-form';
import { Alert, AlertIcon, Flex } from '@chakra-ui/react';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import PercentageAndAbsoluteFields from './PercentageAndAbsoluteFields';
import ForecastBox from './ForecastBox';

const DEPOSIT_AMOUNT_TYPE_OPTIONS = [
  {
    label: 'Weeks',
    value: INVOICE_AMOUNT_TYPES.TIME_AMOUNT,
  },
  {
    label: INVOICE_AMOUNT_TYPES.FIXED_AMOUNT,
    value: INVOICE_AMOUNT_TYPES.FIXED_AMOUNT,
  },
];

const T_AND_M_AMOUNT_TYPE_OPTIONS = [
  {
    label: INVOICE_AMOUNT_TYPES.FORECASTED,
    value: INVOICE_AMOUNT_TYPES.FORECASTED,
  },
  {
    label: INVOICE_AMOUNT_TYPES.SET_AMOUNT,
    value: INVOICE_AMOUNT_TYPES.SET_AMOUNT,
  },
];

const TimeAmountValueBox = ({
  fieldPrefix,
  requiredValidation,
  mainNameQA,
}) => (
  <>
    <Field
      name={`${fieldPrefix}.weeksWorth`}
      validate={requiredValidation}
      data-test-id={mainNameQA && `${mainNameQA}-${fieldPrefix}--weeksWorth`}
      isErrorAbsolute
    >
      {({ input, meta }) => (
        <Flex alignItems="flex-end" mb="8px">
          <MaskedTextInput label="Weeks of work" input={input} meta={meta} />
          <ForecastBox fieldPrefix={fieldPrefix} isWeekly />
        </Flex>
      )}
    </Field>
    <Alert status="warning" borderRadius="8px" mb="24px">
      <AlertIcon />
      WARNING: a value between 1 and 4 weeks worth of work is recommended
    </Alert>
  </>
);

const InvoiceAmountTypeFields = ({
  requiredValidation,
  fieldPrefix,
  isDeposit,
  selectedSupplier,
  mainNameQA,
}) => {
  return (
    <Field
      name={`${fieldPrefix}.invoiceAmountType`}
      validate={requiredValidation}
      data-test-id={`${mainNameQA}--${fieldPrefix}.invoiceAmountType`}
    >
      {({ input, meta }) => {
        const isForecasted = input.value === INVOICE_AMOUNT_TYPES.FORECASTED;
        return (
          <>
            <Flex alignItems="flex-end" mb="32px">
              <Select
                input={input}
                meta={meta}
                options={
                  isDeposit
                    ? DEPOSIT_AMOUNT_TYPE_OPTIONS
                    : T_AND_M_AMOUNT_TYPE_OPTIONS
                }
                label="Invoice amount type"
                styleProps={{ maxW: isForecasted ? '70%' : '100%' }}
              />
              {input.value === INVOICE_AMOUNT_TYPES.FORECASTED && (
                <ForecastBox
                  fieldPrefix={fieldPrefix}
                  selectedSupplier={selectedSupplier}
                  mainNameQA={mainNameQA}
                />
              )}
            </Flex>
            {input.value === INVOICE_AMOUNT_TYPES.TIME_AMOUNT && (
              <TimeAmountValueBox
                selectedSupplier={selectedSupplier}
                fieldPrefix={fieldPrefix}
                requiredValidation={requiredValidation}
                mainNameQA={mainNameQA}
              />
            )}
            {(input.value === INVOICE_AMOUNT_TYPES.SET_AMOUNT ||
              input.value === INVOICE_AMOUNT_TYPES.FIXED_AMOUNT) && (
              <PercentageAndAbsoluteFields
                fieldPrefix={fieldPrefix}
                requiredValidation={requiredValidation}
                selectedSupplier={selectedSupplier}
                mainNameQA={mainNameQA}
              />
            )}
          </>
        );
      }}
    </Field>
  );
};

export default InvoiceAmountTypeFields;
