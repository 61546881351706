import { Flex } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import useMediaQuery from '@hooks/useMediaQuery';
import { COLORS } from '@styles/theme';
import Typography from '@styles/Typography';
import { formatCurrency } from '@utils';
import { map } from 'lodash-es';
import React from 'react';
import {
  CellText,
  TableHeadings,
} from '../ProposalDeazyLayer/PnPCandidatesList';
import { Cell } from '../ProposalDeazyLayer/TeamRoleRow';

const TableRow = ({ res, isSmallerScreen, mainNameQA }) => {
  if (isSmallerScreen) {
    return (
      <WhiteCard p="12px" mb="12px">
        <Typography variant="h4" mb="12px">
          {res.name}
        </Typography>
        <Cell asInput mb="12px">
          {res.role}
        </Cell>
        <Cell asInput label="Cost per month">
          {formatCurrency(res.costPerMonth, 'GBP')}
        </Cell>
      </WhiteCard>
    );
  }
  return (
    <Flex
      border="1px solid"
      borderColor="dark.mid"
      borderRadius="8px"
      bg="#fff"
      position="relative"
      h="72px"
      mb="12px"
      w="100%"
      data-test-id={mainNameQA && `${mainNameQA}--clientTabRoleRow`}
    >
      <Flex alignItems="center" px="12px" w="40%">
        <CellText data-test-id={mainNameQA && `${mainNameQA}--clientTabName`}>
          {res.name}
        </CellText>
      </Flex>
      <Flex
        alignItems="center"
        px="12px"
        borderLeft="1px solid"
        borderRight="1px solid"
        borderColor="dark.mid"
        w="40%"
      >
        <CellText data-test-id={mainNameQA && `${mainNameQA}--clientTabRole`}>
          {res.role}
        </CellText>
      </Flex>
      <Flex alignItems="center" px="12px" w="20%">
        <CellText
          data-test-id={mainNameQA && `${mainNameQA}--clientTabCostPerMonth`}
        >
          {formatCurrency(res.costPerMonth, 'GBP')}
        </CellText>
      </Flex>
    </Flex>
  );
};

const ClientPandPProposalTable = ({ proposalData, mainNameQA }) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  return (
    <WhiteCard
      border={['none', 'none', 'none', `1px solid ${COLORS.dark.mid}`]}
      p={[0, 0, 0, '32px 24px']}
      bg={isSmallerScreen ? 'primary.100' : '#fff'}
    >
      <Typography variant="h2" mb="24px">
        Candidates for required roles
      </Typography>
      {!isSmallerScreen && (
        <TableHeadings
          mainWidth="100%"
          lastCellWidth="10%"
          lastCellLabel="Cost per month"
        />
      )}
      {map(proposalData.resources, res => {
        return (
          <TableRow
            res={res}
            key={`candidate-data-row-${res.id}`}
            isSmallerScreen={isSmallerScreen}
            mainNameQA={mainNameQA}
          />
        );
      })}
    </WhiteCard>
  );
};

export default ClientPandPProposalTable;
