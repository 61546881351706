import { Flex } from '@chakra-ui/react';
import React from 'react';

const tabProps = isActive => ({
  fontFamily: 'WM',
  color: isActive ? '#fff' : 'dark.solid',
  bg: isActive ? 'blue.solid' : 'blue.light',
  alignItems: 'center',
  justifyContent: 'center',
  minW: '180px',
  cursor: 'pointer',
  fontSize: 12,
  letterSpacing: '0.5px',
  border: '1px solid',
  borderColor: 'blue.solid',
  textTransform: 'uppercase',
});

const SOWTabs = ({ activeTab, onTabChange }) => {
  return (
    <Flex h="40px" maxW="360px" mx="auto" mt="48px" mb="40px">
      <Flex
        onClick={() => onTabChange('client')}
        {...tabProps(activeTab === 'client')}
        borderTopLeftRadius="8px"
        borderBottomLeftRadius="8px"
      >
        Client
      </Flex>
      <Flex
        onClick={() => onTabChange('delivery-partner')}
        {...tabProps(activeTab === 'delivery-partner')}
        borderTopRightRadius="8px"
        borderBottomRightRadius="8px"
      >
        Delivery Partner
      </Flex>
    </Flex>
  );
};

export default SOWTabs;
