export const APP_ROUTES = {
  root: '/',
  auth: {
    signIn: '/sign-in',
    forgottenPassword: '/forgotten-password',
    resetPassword: '/reset-password',
  },
  dashboard: '/dashboard',
  profile: '/profile',
  projects: '/projects',
  projectsCreate: '/projects/create',
  projectById: id => `/projects/${id || ':id'}`,
  projectTab: (id, tab) => `/projects/${id || ':id'}/${tab || ':tab'}`,

  projectByClientAndSlug: (clientSlug, projectSlug) =>
    `/clients/${clientSlug || ':clientSlug'}/projects/${projectSlug ||
      ':projectSlug'}`,

  projectTabWithSlugs: (clientSlug, projectSlug, tab) =>
    `/clients/${clientSlug || ':clientSlug'}/projects/${projectSlug ||
      ':projectSlug'}/${tab || ':tab'}`,

  changeRequestAction: '/changeRequestAction',
  changeRequestGroupAction: '/changeRequestGroupAction',

  briefsStartCreate: '/briefs-start-create',

  briefs: '/briefs',
  briefById: id => `/briefs/${id || ':id'}`,
  briefPlugAndPlayById: id => `/team-augmentation-briefs/${id || ':id'}`,
  briefTab: (id, tab) => `/briefs/${id || ':id'}/${tab || ':tab'}`,
  briefPlugAndPlayTab: (id, tab) =>
    `/team-augmentation-briefs/${id || ':id'}/${tab || ':tab'}`,
  briefsCreate: '/briefs/create',
  briefsEdit: briefId => `/briefs/${briefId || ':id'}/edit`,
  briefsCreateBasics: '/briefs/create/basics',
  briefsEditBasics: briefId => `/briefs/${briefId || ':id'}/edit/basics`,
  briefsPlugAndPlay: '/team-augmentation-briefs',
  briefsClosed: '/closed-briefs',
  briefsCreatePlugAndPlay: '/team-augmentation-briefs/create',
  briefsEditPlugAndPlay: briefId =>
    `/team-augmentation-briefs/${briefId || ':id'}/edit`,
  briefsCreateTechStack: '/briefs/create/tech-stack',
  briefsEditTechStack: briefId => `/briefs/${briefId || ':id'}/edit/tech-stack`,
  briefsCreateFeatures: '/briefs/create/features',
  briefsEditFeatures: briefId => `/briefs/${briefId || ':id'}/edit/features`,
  briefsCreateFinish: '/briefs/create/finish',
  briefsEditFinish: briefId => `/briefs/${briefId || ':id'}/edit/finish`,
  invoiceById: (clientSlug, projectSlug, id) =>
    `/clients/${clientSlug || ':clientSlug'}/projects/${projectSlug ||
      ':projectSlug'}/payments/invoices/${id || ':id'}`,
  supplierOldInvoiceById: (clientSlug, projectSlug, id) =>
    `/clients/${clientSlug || ':clientSlug'}/projects/${projectSlug ||
      ':projectSlug'}/invoices/${id || ':id'}/Supplier`,
  invoices: '/invoices',

  inbox: '/inbox',

  teams: {
    all: '/teams',
    clients: '/clients',
    clientCreate: '/clients/create',
    suppliers: '/delivery-partners',
    supplierCreate: '/delivery-partners/create',
    supplierById: id => `/delivery-partners/${id || ':id'}`,
    clientById: id => `/clients/${id || ':id'}`,
    supplierByIdEdit: (id = ':id') => `/delivery-partners/${id}/edit`,
    clientByIdEdit: (id = ':id') => `/clients/${id}/edit`,
  },
  users: {
    all: '/users',
    admins: '/admins',
    clientLeads: '/client-leads',
    projectManagers: '/deazy-leads',
    teamMembers: '/team-members',
    teamAdmins: '/team-admins',
    developerCreate: '/users/developer-create',
    userById: id => `/users/${id || ':id'}`,
    create: '/users/create',
    userByIdEdit: (id = ':id') => `/users/${id}/edit`,
    userByIdEditProfile: (id = ':id') => `/users/${id}/edit-profile`,
  },
  profiles: '/profiles',
  profileById: id => `/profile/${id || ':id'}`,
  editProfileById: id => `/profile/${id || ':id'}/edit`,
  partners: '/partners',
  partnerById: id => `/partner/${id || ':id'}`,
  settings: '/settings',
  tasks: '/tasks',
  resources: '/resources-and-guides',
  mailing: '/mailing',
  nationalHolidays: (projectId = ':id') =>
    `/projects/${projectId}/national-holidays`,
  magicLink: token => `/magic-link/${token || ':token'}`,
  myProfile: '/my-profile',
  editMyProfile: '/my-profile/edit',
  clientsIndividuals: '/individuals',
  clientById: id => `/client/${id || ':id'}`,
  individualClientById: id => `/individual-client/${id || ':id'}`,
  deazyTeam: '/deazy-team',
  deazyTeamById: id => `/deazy-team/${id || ':id'}`,
};
