import { Box } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import { forEach, isEmpty, isEqual, last, map, omit } from 'lodash-es';
import { nanoid } from 'nanoid';
import Button from '@styles/Button';
import React from 'react';
import { Form } from 'react-final-form';
import AdditionalTeamRolesTable from './AdditionalTeamRolesTable';
import AutoSave from './AutoSave';

const validate = ({ additionalRoles }) => {
  const errors = { additionalRoles: [] };
  forEach(additionalRoles, (r, idx) => {
    errors.additionalRoles[idx] = {};
    if (additionalRoles.length - 1 !== idx) {
      if (!r.name) {
        errors.additionalRoles[idx].name = 'Role name is required.';
      } else if (r.name.length > 255) {
        errors.additionalRoles[idx].name =
          'Role name must be shorter or equal 255 characters.';
      }
      if (!r.rate) {
        errors.additionalRoles[idx].rate = 'Rate is required.';
      }
    }
    if (isEmpty(errors.additionalRoles[idx])) {
      delete errors.additionalRoles[idx];
    }
  });
  return errors.additionalRoles;
};

const AdditionalTeamRolesForm = ({
  initialValues,
  onSubmit,
  isReadOnly,
  currency,
  totalHours,
  isSmallerScreen,
  mainNameQA,
}) => {
  const additionalTeam = React.useMemo(() => {
    const team = map(initialValues.additionalTeam, m => ({
      ...m,
      ...((m.timeHours || m.timePercent) && {
        timeHours: !m.timePercent ? m.timeHours : null,
      }),
      rowId: m.rowId || nanoid(10),
    }));
    if (
      !isSmallerScreen &&
      !isEmpty(omit(last(team), 'rowId')) &&
      !isReadOnly
    ) {
      team.push({ rowId: nanoid(10) });
    }
    return team;
  }, [initialValues.additionalTeam, isSmallerScreen]);

  return (
    <WhiteCard
      mt={0}
      mb="24px"
      pb={isSmallerScreen && !isReadOnly ? '16px' : 'inherit'}
      boxShadow="0 0 0 0 rgba(0, 0, 0, 0)"
      border="solid 1px"
      borderColor="dark.mid"
      pt={isSmallerScreen ? '16px' : 'inherit'}
      px={isSmallerScreen ? '12px' : '32px'}
    >
      <Typography variant="h2" mb="16px">
        Additional Team Roles
      </Typography>
      <Typography
        mb={isSmallerScreen ? '24px' : '40px'}
        maxW={isSmallerScreen ? '100%' : '632px'}
        lineHeight="22px"
      >
        These are the team members who help to oversee activities. You can add
        there time as absolute values, or % of the core teams time.
      </Typography>

      <Box>
        <Form
          onSubmit={onSubmit}
          initialValues={{ additionalTeam }}
          validate={validate}
          keepDirtyOnReinitialize
          render={({ form, handleSubmit, values }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <>
                  <AutoSave
                    formName="additionalTeam"
                    formApi={form}
                    onSave={handleSubmit}
                  />
                  <AdditionalTeamRolesTable
                    currency={currency}
                    isReadOnly={isReadOnly}
                    totalHours={totalHours}
                    mainNameQA={mainNameQA}
                  />
                  {isSmallerScreen && !isReadOnly && (
                    <Button
                      onClick={() => {
                        form.change('additionalTeam', [
                          ...values.additionalTeam,
                          { rowId: nanoid(10) },
                        ]);
                      }}
                      variant="outline"
                      leftIcon={{ name: 'add' }}
                      size="lg"
                      width="100%"
                    >
                      Add another additional role
                    </Button>
                  )}
                </>
              </form>
            );
          }}
        />
      </Box>
    </WhiteCard>
  );
};

export default React.memo(AdditionalTeamRolesForm, (prevProps, nextProps) => {
  if (isEqual(omit(prevProps, 'onSubmit'), omit(nextProps, 'onSubmit'))) {
    return true;
  }
  return false;
});
