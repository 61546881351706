import { Box, Tooltip } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import React from 'react';

const InvoiceTotalValueLabel = ({
  text = 'Total project value %',
  exceedsTotal = false,
}) => {
  return (
    <Typography variant="text2">
      {text}
      {exceedsTotal && (
        <Tooltip
          zIndex={10000}
          label="This value results in sum invoice value exceeding project total"
          hasArrow
          p="12px"
          placement="top"
        >
          <Box as="span" ml="8px">
            <Icon mb="3px" name="warning" color="red.solid" fontSize={16} />
          </Box>
        </Tooltip>
      )}
    </Typography>
  );
};

export default InvoiceTotalValueLabel;
