import { Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { get } from 'lodash-es';
import React from 'react';
import CreditAllocationsTableTooltip from './CreditAllocationsTableTooltip';

const InvoiceTotals = ({ invoice, isPreview = true, mainNameQA, ...props }) => {
  const showCreditAllocationTotals =
    (get(invoice, 'creditAllocations[0].creditNote.status') === 'Approved' ||
      get(invoice, 'creditAllocations[0].creditNote.status') === 'Finalised') &&
    !!invoice.creditAllocationsTotal &&
    !isPreview;
  return (
    <Flex
      bg="#fff"
      border="1px solid"
      borderColor="dark.mid"
      borderRadius="8px"
      flexDirection="column"
      p="12px"
      maxW="306px"
      w="100%"
      ml="auto"
      mb="72px"
      {...props}
    >
      <Flex alignItems="center">
        <Typography variant="text3" textAlign="right" w="45%" mr="16px">
          Subtotal
        </Typography>
        <Typography
          fontFamily="WM"
          mb="2px"
          data-test-id={`${mainNameQA}--formatedTotal`}
        >
          {invoice.formatedTotal}
        </Typography>
      </Flex>
      <Flex alignItems="center">
        <Typography variant="text3" textAlign="right" w="45%" mr="16px">
          VAT amount
        </Typography>
        <Typography
          fontFamily="WM"
          mb="2px"
          data-test-id={`${mainNameQA}--formatedTotalVat`}
        >
          {invoice.formatedTotalVat}
        </Typography>
      </Flex>
      <Flex alignItems="center">
        <Typography variant="text3" textAlign="right" w="45%" mr="16px">
          Total
        </Typography>
        <Typography mb="2px" data-test-id={`${mainNameQA}--formatedAmountDue`}>
          {invoice.formatedAmountDue}
        </Typography>
      </Flex>
      {showCreditAllocationTotals && (
        <>
          <Flex alignItems="center">
            <Typography
              variant="text3"
              textAlign="right"
              w="45%"
              mr="16px"
              display="inline"
            >
              <CreditAllocationsTableTooltip
                data={invoice.creditAllocationsTableData}
              />
              Less credit note
            </Typography>
            <Typography
              fontFamily="WM"
              mb="2px"
              data-test-id={`${mainNameQA}--formatedCreditAllocationsTotalWithVat`}
            >
              {invoice.formatedCreditAllocationsTotalWithVat}
            </Typography>
          </Flex>
          <Flex alignItems="center">
            <Typography
              variant="text3"
              textAlign="right"
              w="45%"
              mr="16px"
              mb="4px"
            >
              Amount due
            </Typography>
            <Typography
              fontFamily="WM"
              mb="2px"
              data-test-id={`${mainNameQA}--formatedTotalWithCredits`}
            >
              {invoice.formatedTotalWithCredits}
            </Typography>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default InvoiceTotals;
