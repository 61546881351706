import { Stack } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';

const PageResults = ({
  totalPages = 1,
  currentPage = 1,
  totals = 0,
  mainNameQA = '',
}) => (
  <>
    {totals !== 0 && (
      <Stack spacing={1}>
        <Typography
          data-test-id={`${mainNameQA}--pageResults`}
          variant="h3"
        >{`${totals} results`}</Typography>
        <Typography
          variant="text2"
          color="dark.solid"
        >{`Page ${currentPage} of ${totalPages}`}</Typography>
      </Stack>
    )}
  </>
);

export default PageResults;
