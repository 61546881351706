import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Icon from '@components/Icon';
import { css } from '@emotion/react';
import { COLORS, FONTS } from '@styles/theme';
import { iconColor } from '@utils/appUtils';
import { find } from 'lodash-es';
import React from 'react';
import Select from 'react-select';

const DEFAULT_OPTIONS = [
  {
    label: 'Creation date (New to Old)',
    value: 'orderDirection=DESC&orderBy=createdAt',
  },
  {
    label: 'Creation date (Old to New)',
    value: 'orderDirection=ASC&orderBy=createdAt',
  },
  {
    label: 'Client name (A-Z)',
    value: 'orderDirection=ASC&orderBy=name',
  },
  {
    label: 'Client name (Z-A)',
    value: 'orderDirection=DESC&orderBy=name',
  },
];

const Styles = styled(({ regularStyles, ...props }) => <Box {...props} />)`
  .select__control {
    ${({ regularStyles, minW, maxW, marginLeft, marginTop }) =>
      regularStyles
        ? css`
            min-width: ${minW || '128px'};
          `
        : css`
            width: ${maxW || '291px'};
            margin-left: ${marginLeft || '16px'};
            margin-top: ${marginTop};
          `};
    padding: 9px 16px;
    border-radius: 8px;
    border: 1px solid ${COLORS.gray[200]};
    color: ${COLORS.primary[400]};
    max-height: 40px;
    :before {
      ${({ regularStyles }) =>
        regularStyles
          ? css`
              content: none;
            `
          : null};
    }
    :hover {
      border-color: ${COLORS.dark.solid};
    }
    box-shadow: none;
    cursor: pointer;
  }
  .select__placeholder {
    left: 47px;
    font-size: 14px;
  }
  .select__control--menu-is-open {
    .arrow-down {
      transform: rotate(180deg);
    }
  }
  .select__control--is-focused {
    border-color: ${COLORS.dark.solid};
  }
  .select__single-value {
    font-size: 16px;
    font-family: ${FONTS.WS};
    margin-left: 0;
    max-width: calc(100% - 47px);
    ${({ regularStyles }) =>
      regularStyles
        ? null
        : css`
            left: 47px;
          `};
  }
  .select__value-container {
    margin-right: 5px;
    padding-left: 0;
  }
  .select__menu {
    width: calc(100% + 16px);
  }
  .select-menu {
    border-radius: 8px;
    border: solid 1px #d9dada;
  }
  .select__option {
    color: ${COLORS.dark.solid};
    padding: 8px 9px;
    border-radius: 8px;
    :active {
      background: ${COLORS.primary[200]};
    }
  }
  .select__option--is-focused {
    background: ${COLORS.primary[200]};
    cursor: pointer;
  }
  .select__option--is-selected {
    background: ${COLORS.primary[200]};
  }
  .select__menu {
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
  }
  .select__menu-list {
    padding: 9px 8px;
  }
`;

const SortAndOrderSelect = ({
  placeholder = 'Select...',
  options = DEFAULT_OPTIONS,
  defaultValue,
  isRegular,
  onChange = () => {},
  minW,
  maxW,
  checkIsSorting,
  marginLeft,
  marginTop,
  ...rest
}) => {
  return (
    <Styles
      minW={minW || '128px'}
      maxW={maxW || '291px'}
      marginLeft={marginLeft}
      marginTop={marginTop}
      regularStyles={isRegular ? 1 : 0}
      data-test-id={rest['data-test-id']}
    >
      <Select
        placeholder={placeholder}
        classNamePrefix="select"
        components={{
          ValueContainer: ({ children }) => (
            <>
              {!isRegular && (
                <Icon
                  ml="3px"
                  mb="1px"
                  color={iconColor(checkIsSorting)}
                  name="sortAndSelect"
                  fontSize="18px"
                />
              )}
              {children}
            </>
          ),
          IndicatorSeparator: () => null,
          IndicatorsContainer: () => (
            <Icon
              position="absolute"
              right="12px"
              className="arrow-down"
              name="chevronDown"
              fontSize={12}
            />
          ),
        }}
        onChange={onChange}
        defaultValue={find(options, { value: defaultValue })}
        isClearable={false}
        isSearchable={false}
        name="color"
        options={options}
        {...rest}
      />
    </Styles>
  );
};
export default SortAndOrderSelect;
