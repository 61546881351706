import React, { useState } from 'react';
import {
  Box,
  Flex,
  Stack,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  Textarea,
} from '@chakra-ui/react';
import Icon from '@components/Icon';
import { map, join } from 'lodash-es';
import Typography from '@styles/Typography';

const DropdownOption = ({ children, ...props }) => (
  <Box
    _hover={{ bg: 'primary.100', textDecoration: 'none' }}
    py="9px"
    px="12px"
    borderRadius="8px"
    cursor="pointer"
    zIndex="100"
    {...props}
  >
    {children}
  </Box>
);

const FileUpload = ({
  onUploaded,
  value: initialFile,
  allowedExtensions = [],
  label = 'Upload file',
  labelToChangeFile = 'Change file',
}) => {
  const [file, setFile] = useState(initialFile || null);
  const hiddenFileInput = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const allowedExtensionsToRegExp = join(
    map(allowedExtensions, ext => ext.slice(1)),
    '|',
  );

  const isJSONtype = allowedExtensions.includes('.json');

  const handleChanged = e => {
    const targetFile = e.target.files[0];
    const reader = new FileReader();

    const checkFileExtension = checkingFile => {
      return checkingFile.match(
        new RegExp(`.(${allowedExtensionsToRegExp})$`, 'i'),
      );
    };
    if (checkFileExtension(targetFile.name)) {
      reader.onload = () => {
        const { result } = reader;
        onUploaded(result);
        setFile(result);
      };
      if (isJSONtype) {
        reader.readAsText(targetFile);
        return onClose();
      }
      reader.readAsDataURL(targetFile);
      return onClose();
    }
    return undefined;
  };

  const handleDelete = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.value = null;
    }
    setFile(null);
    onUploaded(null);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const hoverProps = {
    opacity: '0.5',
    cursor: 'pointer',
    img: {
      border: '4px solid',
      borderColor: 'dark.mid',
    },
  };

  const input = (
    <input
      ref={hiddenFileInput}
      type="file"
      id="file-upload"
      accept={allowedExtensions}
      onChange={handleChanged}
    />
  );

  if (file) {
    return (
      <Box position="relative" textAlign="center">
        {isJSONtype && (
          <Textarea w="100%" height="400px" isDisabled opacity="0.4">
            {JSON.stringify(JSON.parse(file), 0, 2)}
          </Textarea>
        )}
        <Popover usePortal isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
          {!isJSONtype ? (
            <PopoverTrigger>
              <Flex
                w="160px"
                h="160px"
                position="relative"
                justifyContent="center"
                mx="auto"
                _hover={hoverProps}
                {...(isOpen && { ...hoverProps })}
              >
                <Image
                  border="4px solid"
                  borderColor="transparent"
                  src={file}
                  w="160px"
                  objectFit="cover"
                  h="160px"
                  borderRadius="50%"
                  {...(isOpen && {
                    border: '4px solid',
                    borderColor: 'dark.mid',
                  })}
                />
                <Icon
                  name="camera"
                  fontSize={32}
                  position="absolute"
                  top="65px"
                  right="63px"
                  color="#fff"
                />
              </Flex>
            </PopoverTrigger>
          ) : (
            <PopoverTrigger>
              <Flex
                justifyContent="center"
                mx="auto"
                _hover={hoverProps}
                bottom="-72px"
                position="absolute"
                right="0"
                height="40px"
                width="40px"
                border="1px solid"
                borderRadius="8px"
                {...(isOpen && { ...hoverProps })}
              >
                <Icon
                  name="dotsVertical"
                  color="black"
                  fontSize="20px"
                  mt="8px"
                />
              </Flex>
            </PopoverTrigger>
          )}
          <PopoverContent
            zIndex={40000}
            w="190px"
            border="none"
            top={!isJSONtype ? '-60px' : '0'}
          >
            <PopoverBody
              boxShadow="0px 0px 24px rgba(0, 0, 0, 0.15)"
              px="4px"
              py="8px"
              borderRadius="8px"
            >
              <Stack spacing="0px">
                <DropdownOption onClick={handleClick}>
                  {labelToChangeFile}
                </DropdownOption>
                <DropdownOption onClick={handleDelete}>Delete</DropdownOption>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        {input}
      </Box>
    );
  }

  return (
    <Flex
      w="160px"
      h="160px"
      border="1px dashed"
      borderColor="dark.mid"
      borderRadius="50%"
      justifyContent="center"
      mx="auto"
      alignItems="center"
      cursor="pointer"
      onClick={handleClick}
    >
      <Flex flexDir="column" alignItems="center" justifyContent="center">
        <Icon
          name="download"
          transform="rotate(180deg)"
          color="blue.solid"
          mb="16px"
          fontSize={24}
        />
        <Typography color="blue.solid">{label}</Typography>
      </Flex>
      {input}
    </Flex>
  );
};

export default FileUpload;
