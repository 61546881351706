import { APP_ROUTES } from '@routes';
import { getDeazyMarginFactor, getNumberOfWeekdays } from '@utils';
import {
  filter,
  get,
  isEmpty,
  last,
  map,
  omit,
  orderBy,
  reject,
  sortBy,
  round,
  toNumber,
  find,
  isEqual,
  includes,
  first,
} from 'lodash-es';
import { action, computed, makeObservable, observable, when, toJS } from 'mobx';
import moment from 'moment';
import {
  getAllocationMultiplier,
  floorEntriesValuesTo2Values,
  isFixedPriceProjectType,
  isRetainerOrTAForFixedPrice,
} from '@utils/projectUtils';
import {
  DEAZY_HELPER_OPTION,
  FIXED_PRICE_ALLOCATION_STAGES,
  ENGAGEMENT_TYPES,
  PROJECT_TYPES,
} from '@app/constants';
import { nanoid } from 'nanoid';

export default class ProjectsStore {
  constructor({ API, toastsStore, routerStore, usersStore, settingsStore }) {
    makeObservable(this);
    this.API = API;
    this.toastsStore = toastsStore;
    this.routerStore = routerStore;
    this.usersStore = usersStore;
    this.settingsStore = settingsStore;
  }

  @observable isLoading = false;

  @observable isArchieving = false;

  @observable isDeleting = false;

  @observable project = {};

  @observable allocationRoles = [];

  @observable projectTotals = { isLoading: false, data: {} };

  rolesFirstRequest = false;

  @observable projectsReadyToArchive = [];

  @action clearProject = () => {
    this.project = {};
  };

  cloneProject = async (projectId, successCb) => {
    try {
      await this.API.cloneProject(projectId);
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e?.message || 'Could not clone project. Please try again later.',
      });
    }
  };

  @action fetchProject = async ({ projectId, clientSlug, projectSlug }) => {
    this.project = {};
    if (!projectId && !clientSlug && !projectSlug) {
      return;
    }
    this.isLoading = true;
    try {
      const apiCall = () => {
        if (clientSlug && projectSlug) {
          return this.API.getProjectBySlugAndClient({
            clientSlug,
            projectSlug,
          });
        }
        return this.API.getProjectById(projectId);
      };
      const { data } = await apiCall();
      this.project = await this.decorateInitialProjectData(data, !!projectId);
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoading = false;
    }
  };

  @action createProject = async project => {
    try {
      this.clearNewDeliveryPartnerInProject();
      const { data } = await this.API.createProject(
        this.decorateProjectBeforeSubmit(project),
      );
      this.toastsStore.showSuccess({
        title: 'Project has been successfully created.',
      });
      if (
        data.projectType === PROJECT_TYPES.FIXED_PRICE &&
        includes(
          [
            ENGAGEMENT_TYPES.PROJECT_BUILD,
            ENGAGEMENT_TYPES.TEAM_AUGMENTATION,
            ENGAGEMENT_TYPES.RETAINED_TEAM,
          ],
          data.engagementType,
        )
      ) {
        return this.routerStore.push(
          APP_ROUTES.projectTabWithSlugs(
            data.client?.slug || 'deazy',
            data?.slug,
            'statement-of-work',
          ),
        );
      }
      this.routerStore.push(APP_ROUTES.projects);
    } catch (e) {
      if (e.errors) {
        return e.errors;
      }
      this.toastsStore.showError({
        title: typeof e === 'string' ? e : JSON.stringify(e),
      });
    }
    return undefined;
  };

  @action updateProject = async project => {
    try {
      const { data } = await this.API.updateProject(
        this.decorateProjectBeforeSubmit(project),
      );
      this.project = await this.decorateInitialProjectData(data);
      this.toastsStore.showSuccess({
        title: 'Project has been successfully updated.',
      });
    } catch (e) {
      if (e.errors) {
        return e.errors;
      }
      this.toastsStore.showError({
        title: typeof e === 'string' ? e : JSON.stringify(e),
      });
    }
    return undefined;
  };

  @action archiveOrUnarchiveProject = async (project, successCb) => {
    const { archived: isArchived } = project;
    try {
      this.isArchieving = true;
      await this.API[isArchived ? 'unarchiveProject' : 'archiveProject'](
        project.id,
      );
      this.project.archived = !this.project.archived;
      this.toastsStore.showSuccess({
        title: `Project has been successfully ${
          isArchived ? 'un' : ''
        }archived.`,
      });
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: typeof e === 'string' ? e : JSON.stringify(e),
      });
    } finally {
      this.isArchieving = false;
    }
  };

  @action deleteProject = async (projectId, successCb) => {
    try {
      this.isDeleting = true;
      await this.API.deleteProject(projectId);

      if (successCb) {
        await successCb();
      }
      this.project = {};
      this.toastsStore.showSuccess({
        title: 'Project has been successfully deleted.',
      });
    } catch (e) {
      this.toastsStore.showError({
        title: typeof e === 'string' ? e : JSON.stringify(e),
      });
    } finally {
      this.isDeleting = false;
    }
  };

  preparePaylodToNewDP = (values, ppBriefId) => {
    const { supplierLead, overriddenCurrency: currency, allocations } = values;
    const filterAllocationFn = a =>
      reject(a, r => isEmpty(omit(r, ['id', 'startDate', 'endDate'])));
    return {
      supplierLead,
      currency,
      allocation: {
        entries: map(filterAllocationFn(allocations), allocation => ({
          ...omit(allocation, ['id']),
        })),
      },
      mergedBriefId: ppBriefId,
    };
  };

  @observable newDeliveryPartnerInProject = null;

  @action clearNewDeliveryPartnerInProject = () => {
    this.newDeliveryPartnerInProject = null;
  };

  @action addNewDeliveryPartnerToProjectWithAllocations = async values => {
    const { id: projectId } = this.project;
    try {
      const { projectToSuppliers, ppBriefId } = values;
      const responses = await Promise.all(
        map(projectToSuppliers, async pts => {
          try {
            const { data } = await this.API.addNewDeliveryPartner(
              projectId,
              pts?.supplier?.id,
              this.preparePaylodToNewDP(pts, ppBriefId),
            );
            this.toastsStore.showSuccess({
              title: `${pts?.supplier?.name} has been successfully added.`,
            });
            return data;
          } catch (e) {
            this.toastsStore.showError({
              title: `${pts?.supplier?.name} could not be added. ${e.message ||
                ''}`,
            });
            return {};
          }
        }),
      );
      const newDp = find(responses, r => !isEmpty(r));
      this.newDeliveryPartnerInProject = newDp;

      if (newDp) {
        await this.fetchProject({
          clientSlug: this.project.client.slug || 'deazy',
          projectSlug: this.project.slug,
        });
        this.routerStore.push(
          APP_ROUTES.projectTabWithSlugs(
            this.project.client?.slug || 'deazy',
            this.project?.slug,
            'statement-of-work/delivery-partner',
          ),
        );
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Network error.',
      });
    }
  };

  @action fetchAllocationRoles = async () => {
    if (!isEmpty(this.allocationRoles) || this.rolesFirstRequest) return;
    this.rolesFirstRequest = true;
    try {
      const { data } = await this.API.searchAllocationRoles();
      this.allocationRoles = data;
    } catch (e) {
      this.allocationRoles = [];
    }
  };

  correctionsTO = null;

  @action fetchCorrectionsTotals = async data => {
    clearTimeout(this.correctionsTO);
    this.projectTotals.isLoading = true;
    const {
      allocations,
      deazyAllocations,
      ...project
    } = this.decorateProjectBeforeSubmit(data);
    this.correctionsTO = setTimeout(async () => {
      try {
        const { data: totals } = await this.API.getProjectTotals({
          deazyAllocations,
          projectToSuppliers: project.projectToSuppliers,
          project,
        });
        this.projectTotals.data = totals;
      } catch (e) {
        this.toastsStore.showError({
          title:
            e.message ||
            'Something went wrong while calculating corrections. Please, try again later.',
        });
      } finally {
        this.projectTotals.isLoading = false;
      }
    }, 700);
  };

  @action clearCorrectionsTotals = () => {
    clearTimeout(this.correctionsTO);
    this.projectTotals.isLoading = false;
    this.projectTotals.data = {};
  };

  @computed
  get allocationRolesOptions() {
    return map(sortBy(this.allocationRoles), role => ({
      label: role,
      value: role,
    }));
  }

  @computed get projectMutlipliers() {
    const multiplier = getAllocationMultiplier(this.project);
    const deazyMarginPercent =
      this.project.deazyMarginPercent ||
      this.settingsStore.settings.defaultProjectMarginPercent;
    return {
      multiplier,
      deazyMarginFactor:
        getDeazyMarginFactor(deazyMarginPercent) *
        (this.project.supplierCurrencyRate || 1),
    };
  }

  @computed get projectCurrency() {
    const { currency, overriddenCurrency, supplier = {} } = this.project;
    const supplierCurrency = overriddenCurrency || get(supplier, 'currency');
    return { supplierCurrency, currency };
  }

  decorateInitialProjectData = async (project, isDuplicate = false) => {
    const duration =
      moment.utc(project.endDate).diff(moment.utc(project.startDate), 'days') +
      1;
    const numberOfWeeks = Math.floor(duration / 7);
    const numberOfDays = duration % (numberOfWeeks * 7);
    const multiplier = getAllocationMultiplier(project);
    const { totalBasis } = project;

    await when(() => !isEmpty(this.usersStore.profile));

    if (
      !project.deazyMarginPercent &&
      !this.settingsStore.settings.defaultProjectMarginPercent &&
      this.usersStore.isAdminOrDL
    ) {
      await when(
        () => !!this.settingsStore.settings.defaultProjectMarginPercent,
      );
    }
    const deazyMarginPercent =
      project.deazyMarginPercent ||
      this.settingsStore.settings.defaultProjectMarginPercent;

    const sortedDeazyAllocations = orderBy(
      project.deazyAllocations,
      ['number'],
      ['desc'],
    );

    const decoratedProjectToSuppliers = map(project.projectToSuppliers, pts => {
      const orderedAllocations = orderBy(pts.allocations, ['number'], ['desc']);
      return {
        ...pts,
        allocations: map(orderedAllocations, allocation => ({
          ...allocation,
          entries: this.prepareAllocation(
            allocation.entries,
            totalBasis ? multiplier : 1,
            pts.supplierCurrencyRate || 1,
          ),
        })),
      };
    });

    const toPrepareAllocations = () => {
      if (project.deazyAsClient)
        return [
          {
            manager: project.pm,
            amount: this.settingsStore.settings.defaultPmAmount,
            rate: project.pm.rate,
            id: nanoid(10),
          },
        ];
      return project.deazyAllocations;
    };

    return {
      ...project,
      primaryPTOS: find(project.projectToSuppliers, pts => pts?.isPrimary),
      client: project.deazyAsClient
        ? DEAZY_HELPER_OPTION.value
        : project.client,
      supplier: project.deazyAsSupplier
        ? DEAZY_HELPER_OPTION.value
        : project.supplier,
      deazyMarginPercent,
      numberOfWeeks,
      numberOfDays,
      multiplier,
      projectToSuppliers: decoratedProjectToSuppliers,

      deazyAllocations: this.prepareAllocation(
        toPrepareAllocations(),
        totalBasis ? multiplier : 1,
      ),

      sortedDeazyAllocations,
      overriddenCurrency: !project.deazyAsSupplier
        ? project.overriddenCurrency
        : 'GBP',
      clientSOWReference: isDuplicate ? undefined : project.clientSOWReference,
    };
  };

  decorateProjectBeforeSubmit = p => {
    const project = { ...p };
    const applicableFrom = moment.utc(project.startDate).format('YYYY-MM-DD');
    const allocationMap = allocs =>
      reject(
        map(allocs, ({ id, ...a }) => this.decorateTeamEntry(a)),
        ({ startDate, endDate, ...rest }) => isEmpty(rest),
      );
    const deazyAllocationMap = allocs =>
      reject(
        map(allocs, ({ id, ...a }) => ({ ...a })),
        ({ startDate, endDate, ...rest }) => isEmpty(rest),
      );

    let deazyAllocations = [];

    const projectToSuppliers = map(project.projectToSuppliers, s => {
      let allocations = [];
      if (isFixedPriceProjectType(project)) {
        allocations = [
          {
            stage: FIXED_PRICE_ALLOCATION_STAGES.DEVELOPMENT_READINESS,
            applicableFrom,
            entries: allocationMap(s.allocations1),
          },
          {
            stage: FIXED_PRICE_ALLOCATION_STAGES.DELIVERY,
            applicableFrom,
            entries: allocationMap(s.allocations2),
          },
          {
            stage: FIXED_PRICE_ALLOCATION_STAGES.UAT_AND_DEPLOYMENT,
            applicableFrom,
            entries: allocationMap(s.allocations3),
          },
        ];
      } else {
        allocations = [
          {
            applicableFrom,
            entries: allocationMap(s.allocations),
          },
        ];
      }
      return {
        ...omit(s, [
          'allocations1',
          'allocations2',
          'allocations3',
          'deazyAllocations1',
          'deazyAllocations2',
          'deazyAllocations3',
        ]),
        allocations,
      };
    });
    if (isFixedPriceProjectType(project)) {
      deazyAllocations = project.deazyAsClient
        ? null
        : [
            {
              stage: FIXED_PRICE_ALLOCATION_STAGES.DEVELOPMENT_READINESS,
              applicableFrom,
              entries: deazyAllocationMap(project.deazyAllocations1),
            },
            {
              stage: FIXED_PRICE_ALLOCATION_STAGES.DELIVERY,
              applicableFrom,
              entries: deazyAllocationMap(project.deazyAllocations2),
            },
            {
              stage: FIXED_PRICE_ALLOCATION_STAGES.UAT_AND_DEPLOYMENT,
              applicableFrom,
              entries: deazyAllocationMap(project.deazyAllocations3),
            },
          ];
    } else {
      deazyAllocations = project.deazyAsClient
        ? null
        : [
            {
              applicableFrom,
              entries: deazyAllocationMap(project.deazyAllocations),
            },
          ];
    }

    const primaryPTOS =
      projectToSuppliers.length === 1
        ? first(projectToSuppliers)
        : project.primaryPTOS;

    const primaryDP =
      projectToSuppliers.length === 1
        ? first(projectToSuppliers).supplier
        : project.primaryDP;

    const isProjectToUpdate = !isEmpty(project.primaryPTOS);

    return {
      ...project,
      deazyAllocations,
      startDate: moment.utc(project.startDate).format('YYYY-MM-DD'),
      endDate: moment.utc(project.endDate).format('YYYY-MM-DD'),
      projectToSuppliers: map(projectToSuppliers, pts => ({
        ...pts,
        isPrimary: isProjectToUpdate
          ? isEqual(primaryPTOS.id, pts?.id)
          : isEqual(primaryDP, pts?.supplier),
      })),
    };
  };

  decorateTeamEntry = (entry = {}, isTeamAdmin) => {
    const decoratedEntry = { ...entry };
    if (isTeamAdmin) {
      delete decoratedEntry.clientRate;
      delete decoratedEntry.rate;
    }
    if (!entry.amount) {
      return {}; // fix sending empty entries with just clientRate and rate set to null
    }
    return decoratedEntry;
  };

  @action toggleProjectCRSendout = async () => {
    try {
      this.project.sendClientCrEmails = !this.project.sendClientCrEmails;
      await this.API.toggleProjectCRSendout(this.project.id);
    } catch (e) {
      this.project.sendClientCrEmails = !this.project.sendClientCrEmails;
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    }
  };

  prepareAllocation = (entries, multiplier = 1, clientRateFactor) => {
    return map(entries, entry => {
      const clientRate = entry.clientRate === 0 ? '0.00' : entry.clientRate;
      const rate = entry.rate === 0 ? '0.00' : entry.rate;
      return {
        ...entry,
        amount: (toNumber(entry.amount || 0) * multiplier).toFixed(2),
        clientRate: round(clientRate || rate * clientRateFactor, 2),
        rate: rate || clientRate / clientRateFactor,
      };
    });
  };

  @computed get projectDaysDuration() {
    return (
      moment
        .utc(this.project.endDate)
        .diff(moment.utc(this.project.startDate), 'days') + 1
    );
  }

  @computed get projectDaysDurationWithoutWeekends() {
    let { startDate, endDate } = this.project;
    if (
      !startDate ||
      !endDate ||
      !moment(startDate).isValid() ||
      !moment(endDate).isValid()
    ) {
      return 0;
    }
    startDate = moment.utc(startDate);
    endDate = moment.utc(endDate);
    let days = 0;
    while (startDate.isBefore(endDate)) {
      if (startDate.isoWeekday() !== 6 && startDate.isoWeekday() !== 7) {
        days += 1;
      }
      startDate.add({ days: 1 });
    }
    return days;
  }

  getStagedAllocations = allocs => {
    return [
      filter(
        allocs,
        a => a.stage === FIXED_PRICE_ALLOCATION_STAGES.DEVELOPMENT_READINESS,
      ),
      filter(allocs, a => a.stage === FIXED_PRICE_ALLOCATION_STAGES.DELIVERY),
      filter(
        allocs,
        a => a.stage === FIXED_PRICE_ALLOCATION_STAGES.UAT_AND_DEPLOYMENT,
      ),
    ];
  };

  @computed get projectInitialValues() {
    const p = { ...toJS(this.project) };
    const deazyAllocs = {};

    const primaryDP = find(p.projectToSuppliers, pts => pts.isPrimary)
      ?.supplier;

    p.totalBasis = p.engagementType === ENGAGEMENT_TYPES.PROJECT_BUILD;

    if (isFixedPriceProjectType(p)) {
      const [
        deazyAllocs1,
        deazyAllocs2,
        deazyAllocs3,
      ] = this.getStagedAllocations(p.deazyAllocations);
      const {
        deazyAllocations: deazyAllocations1,
      } = this.prepareInitialProjectAllocations([], deazyAllocs1);
      const {
        deazyAllocations: deazyAllocations2,
      } = this.prepareInitialProjectAllocations([], deazyAllocs2);
      const {
        deazyAllocations: deazyAllocations3,
      } = this.prepareInitialProjectAllocations([], deazyAllocs3);
      deazyAllocs.deazyAllocations1 = deazyAllocations1;
      deazyAllocs.deazyAllocations2 = deazyAllocations2;
      deazyAllocs.deazyAllocations3 = deazyAllocations3;
      deazyAllocs.deazyAllocations = [];
    } else {
      const { deazyAllocations } = this.prepareInitialProjectAllocations(
        [],
        p.deazyAllocations,
      );
      deazyAllocs.deazyAllocations = deazyAllocations;
    }

    const projectToSuppliers = map(this.project.projectToSuppliers, s => {
      if (isFixedPriceProjectType(p)) {
        const [allocs1, allocs2, allocs3] = this.getStagedAllocations(
          s.allocations,
        );

        const {
          allocations: allocations1,
        } = this.prepareInitialProjectAllocations(allocs1);
        const {
          allocations: allocations2,
        } = this.prepareInitialProjectAllocations(allocs2);
        const {
          allocations: allocations3,
        } = this.prepareInitialProjectAllocations(allocs3);

        return {
          ...s,
          allocations: [],
          allocations1,
          allocations2,
          allocations3,
        };
      }
      return {
        ...s,
        ...this.prepareInitialProjectAllocations(s.allocations),
      };
    });
    return {
      ...p,
      projectToSuppliers,
      primaryDP,
      ...deazyAllocs,
    };
  }

  prepareInitialProjectAllocations = (allocs = [], deazyAllocs = []) => {
    const { totalBasis, deazyAsClient } = this.project;
    const multiplier =
      getNumberOfWeekdays(this.project.startDate, this.project.endDate) / 5;
    const sortedAllocs = this.sortAllocationEntriesByNumber(allocs);
    const sortedDeazyAllocs = this.sortAllocationEntriesByNumber(deazyAllocs);

    const allocationMap = alloc => ({
      ...alloc,
      amount: this.project.totalBasis
        ? alloc.amount * multiplier
        : alloc.amount,
    });
    const values = {};
    if (allocs.length > 0) {
      const baseAllocation = isRetainerOrTAForFixedPrice(this.project)
        ? first(sortedAllocs)
        : last(sortedAllocs);
      values.allocations = floorEntriesValuesTo2Values(
        this.prepareAllocation(
          get(baseAllocation, 'entries'),
          totalBasis && get(baseAllocation, 'number') !== -1 ? multiplier : 1,
        ),
      );
      if (get(baseAllocation, 'number') !== -1) {
        values.allocations = map(values.allocations, allocationMap);
      }
    }
    if (deazyAllocs.length > 0) {
      const baseAllocation = isRetainerOrTAForFixedPrice(this.project)
        ? first(sortedDeazyAllocs)
        : last(sortedDeazyAllocs);
      values.deazyAllocations = floorEntriesValuesTo2Values(
        this.prepareAllocation(
          deazyAsClient
            ? [
                {
                  manager: this.project.pm,
                  amount: this.settingsStore.settings.defaultPmAmount,
                  rate: this.project.pm.rate,
                  id: nanoid(10),
                },
              ]
            : get(baseAllocation, 'entries'),
          totalBasis && get(baseAllocation, 'number') !== -1 ? multiplier : 1,
        ),
      );
    }
    return {
      allocations: toJS(values.allocations || []),
      deazyAllocations: toJS(values.deazyAllocations || []),
    };
  };

  sortAllocationEntriesByNumber = (allocs, order = 'desc') =>
    orderBy(toJS(allocs), ['number'], [order]);

  @computed get projectDurationInWeeks() {
    return Math.ceil(
      getNumberOfWeekdays(this.project.startDate, this.project.endDate) / 5,
    );
  }

  @computed get isPrimaryDP() {
    const { isTeamAdminSideUser, profile } = this.usersStore;
    return (
      isTeamAdminSideUser &&
      get(
        find(this.project.projectToSuppliers, pts =>
          isEqual(pts?.supplier, profile?.supplier),
        ),
        'isPrimary',
      )
    );
  }

  @action setProjectRiskProfile = async (projectId, riskLevel, failCb) => {
    try {
      await this.API.overrideProjectRiskLevel(projectId, { riskLevel });
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
      if (failCb) {
        failCb();
      }
    }
  };

  @action importProject = async (values, successCb) => {
    try {
      await this.API.importProject(values);
      this.toastsStore.showSuccess({
        title: 'Project has been successfully imported.',
      });
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    }
  };

  @observable isLoadingBriefResourcesToMerge = false;

  @observable briefResourcesToMerge = [];

  clearBriefResources = () => {
    this.briefResourcesToMerge = [];
  };

  @action fetchBriefResourcesToMergeToExistingProject = async ({
    ppBriefId,
  }) => {
    try {
      this.isLoadingBriefResourcesToMerge = true;
      const { data } = await this.API.getWonBriefToSuppliers(ppBriefId);
      this.briefResourcesToMerge = data;
    } catch {
      //
    } finally {
      this.isLoadingBriefResourcesToMerge = false;
    }
  };

  @action getProjectsArchiveSource = async params => {
    try {
      const { data } = await this.API.getProjectsArchiveSource(params);
      this.projectsReadyToArchive = data;
    } catch (e) {
      this.toastsStore.showError({ title: e?.message || 'Network error!' });
    }
  };

  @action createProjectSurvey = async (projectId, payload) => {
    try {
      await this.API.createProjectSurvey(projectId, payload);
      this.project = {
        ...this.project,
        projectSurveySubmitDate: moment.utc().format(),
        projectToSuppliers: map(this.project.projectToSuppliers, pts => {
          const survey =
            find(payload, sur => sur?.supplierId === pts?.supplier?.id) || {};
          return {
            ...pts,
            ...survey,
            projectSurveyComment: survey.comment,
          };
        }),
      };
    } catch (e) {
      this.toastsStore.showError({ title: e?.message || 'Network error!' });
    }
  };
}
