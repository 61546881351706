import Modal from '@components/Modal';
import Typography from '@styles/Typography';
import React from 'react';
import AddToRoleForm from './AddToRoleForm';

const AddToRoleModal = ({
  isOpen,
  onClose,
  fetchUser,
  addUserToGroupByRole,
  userId,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2" mb="32px">
        Add user to role
      </Typography>
      <AddToRoleForm
        onClose={onClose}
        onSubmit={values => {
          if (values.supplier) {
            return addUserToGroupByRole({
              memberId: userId,
              role: values.role,
              supplier: values.supplier,
              onSuccess: () => {
                fetchUser(userId);
                onClose();
              },
            });
          }
          if (values.client) {
            return addUserToGroupByRole({
              memberId: userId,
              role: values.role,
              client: values.client,
              onSuccess: () => {
                fetchUser(userId);
                onClose();
              },
            });
          }
          if (values.rate) {
            return addUserToGroupByRole({
              memberId: userId,
              role: values.role,
              rate: values.rate,
              onSuccess: () => {
                fetchUser(userId);
                onClose();
              },
            });
          }
          return addUserToGroupByRole({
            memberId: userId,
            role: values.role,
            onSuccess: () => {
              fetchUser(userId);
              onClose();
            },
          });
        }}
      />
    </Modal>
  );
};

export default AddToRoleModal;
