import { Avatar, Box, Flex, Link, Grid } from '@chakra-ui/react';
import CountBadge from '@components/CountBadge';
import StatusDropdownTrigger from '@components/StatusDropdownTrigger';
import useMediaQuery from '@hooks/useMediaQuery';
import { APP_ROUTES } from '@routes';
import Card from '@styles/Card';
import { COLORS } from '@styles/theme';
import Typography from '@styles/Typography';
import { first, get, isEmpty, map, last } from 'lodash-es';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import BriefCardBadge from './BriefCardBadge';

const statsWrapperProps = {
  height: 'fit-content',
  borderLeft: `1px solid ${COLORS.dark.mid}`,
  paddingLeft: '8px',
  marginLeft: '8px',
  flexDir: 'column',
};

const Stat = ({ label, value, qaTag, ...props }) => (
  <Box maxH="43px" pl="13px" mb="18px" _last={{ marginBottom: 0 }} {...props}>
    <Typography color="dark.solid" mb="8px" variant="text3">
      {label}
    </Typography>
    <Typography variant="text2" mb="8px" data-test-id={qaTag}>
      {value || '-'}
    </Typography>
  </Box>
);

const BriefCard = ({
  brief = {},
  isAdminOrDL,
  isTeamAdmin,
  refetch,
  mainNameQA,
  unreadMessagesCount,
  showChatIcon,
}) => {
  const isPlugAndPlay = !!brief.newOrExisting;
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const startDate =
    !isEmpty(brief.resources) &&
    moment.min(map(brief.resources, r => moment.utc(r.startDate)));

  const clientLead = get(
    brief,
    'clientLead.fullname',
    get(brief, 'clientLead.email', ''),
  );

  const proposal = useMemo(() => {
    if (brief.briefToSuppliers) {
      return get(first(brief.briefToSuppliers), 'proposal') || {};
    }
    if (brief.ppBriefToSuppliers) {
      const proposals = get(first(brief.ppBriefToSuppliers), 'proposals') || [];
      return last(proposals) || {};
    }

    return {};
  }, [brief]);

  const chatIconStyles = () => {
    if (isSmallerScreen && showChatIcon) {
      return {
        maxW: '210px',
        pr: '44px',
      };
    }
    if (isSmallerScreen && !showChatIcon) {
      return {
        maxW: '250px',
        pr: '20px',
      };
    }
    if (!isSmallerScreen && showChatIcon) {
      return { pr: '130px' };
    }
    return {
      pr: '88px',
    };
  };

  return (
    <Card data-test-id={`${mainNameQA}--card`}>
      <Link
        as={RouterLink}
        to={
          !isPlugAndPlay
            ? APP_ROUTES.briefTab(brief.id, 'overview')
            : APP_ROUTES.briefPlugAndPlayTab(brief.id, 'overview')
        }
        _hover={{ cursor: 'pointer', textDecoration: 'none' }}
      >
        <Box
          pb="12px"
          display="flex"
          alignItems="flex-start"
          borderBottom="1px solid"
          borderColor="dark.mid"
          w="100%"
        >
          <Flex pt="12px" pl="11px" align="center" mr="auto">
            <Avatar
              name={get(brief, 'customer.name', 'Deazy')}
              mr="7px"
              pt={get(brief, 'customer.logo') ? '0px' : '3px'}
              src={get(brief, 'customer.logo')}
            />
            <Box>
              <Typography
                variant="h4"
                mb="5px"
                {...chatIconStyles()}
                data-test-id={`${mainNameQA}--briefName`}
              >
                {brief.name}
              </Typography>
              <Typography
                data-test-id={`${mainNameQA}--customerName`}
                variant="text2"
              >{`${get(brief, 'customer.name', 'Deazy')}${
                clientLead ? ` | ${clientLead}` : ''
              }`}</Typography>
            </Box>
          </Flex>
        </Box>
        <Grid
          templateColumns={
            isSmallerScreen ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'
          }
          gap="12px"
          p="16px"
          w="100%"
          {...(isAdminOrDL && {
            borderBottom: '1px solid',
            borderColor: 'dark.mid',
          })}
        >
          <Flex flexDir="column">
            <Stat
              borderLeft="none"
              qaTag={`${mainNameQA}--briefType`}
              label="Type"
              pl={isSmallerScreen && 0}
              value={isPlugAndPlay ? 'Team Augmentation' : 'Project'}
            />
          </Flex>
          <Flex {...statsWrapperProps}>
            <Stat
              pl={isSmallerScreen && 0}
              label="Deazy lead"
              qaTag={`${mainNameQA}--deazyLead`}
              value={get(brief, 'pm.fullname') || get(brief, 'pm.email')}
            />
          </Flex>
          <Flex
            {...statsWrapperProps}
            {...(isSmallerScreen && {
              borderLeft: 'none',
              pl: 0,
              ml: 0,
            })}
          >
            <Stat
              label="Created"
              qaTag={`${mainNameQA}--createdDate`}
              pl={isSmallerScreen && 0}
              value={
                brief.createdAt
                  ? moment.utc(brief.createdAt).format('DD/MM/YYYY')
                  : '-'
              }
            />
          </Flex>
          <Flex {...statsWrapperProps}>
            {isPlugAndPlay ? (
              <Stat
                pl={isSmallerScreen && 0}
                label="Start date"
                qaTag={`${mainNameQA}--startDate`}
                value={
                  startDate ? moment.utc(startDate).format('DD/MM/YYYY') : '-'
                }
              />
            ) : (
              <Stat
                label="Closing date"
                data-test-id={`${mainNameQA}--closingDate`}
                pl={isSmallerScreen && 0}
                value={
                  brief.proposalClosingDate
                    ? moment.utc(brief.proposalClosingDate).format('DD/MM/YYYY')
                    : '-'
                }
              />
            )}
          </Flex>
          {proposal.status && isTeamAdmin && (
            <Flex flexDir="column">
              <Box maxH="43px">
                <Typography color="dark.solid" mb="8px" variant="text3">
                  Proposal Status
                </Typography>
                <StatusDropdownTrigger
                  isReadOnly
                  status={proposal.status}
                  mainNameQA={mainNameQA}
                  qaValue="proposalStatus"
                />
              </Box>
            </Flex>
          )}
        </Grid>
        {isAdminOrDL && <Box pb="60px" />}
      </Link>
      {isAdminOrDL && (
        <Box
          px={[0, 0, '16px', '16px']}
          as={Flex}
          pb="14px"
          alignItems="center"
          position="absolute"
          bottom="0"
          right="0"
          maxW={isSmallerScreen && '366px'}
        >
          <CountBadge
            label="Assigned"
            count={brief.assignedCount}
            iconName="teams"
            data-test-id={`${mainNameQA}--assignedCounter`}
            iconProps={{ color: 'dark.solid', fontSize: 22 }}
          />
          <CountBadge
            label="Accepted"
            count={brief.acceptedCount}
            data-test-id={`${mainNameQA}--acceptedCounter`}
            iconName="check"
            iconProps={{ color: 'green.solid', fontSize: 22 }}
          />
          <CountBadge
            label="Pending"
            count={brief.pendingCount}
            iconName="pending"
            data-test-id={`${mainNameQA}--pendingCounter`}
            iconProps={{ fontSize: 22, color: 'orange.solid' }}
          />
          <CountBadge
            label="Declined"
            count={brief.declinedCount}
            data-test-id={`${mainNameQA}--declinedCounter`}
            iconName="cross"
            iconProps={{ color: 'red.solid', fontSize: 22 }}
          />
          <CountBadge
            label="Proposals"
            data-test-id={`${mainNameQA}--proposalsCounter`}
            count={brief.proposalsCount}
            iconName="proposal2"
            iconProps={{ color: 'blue.solid', fontSize: 14 }}
          />
        </Box>
      )}
      <BriefCardBadge
        brief={brief}
        isAdminOrDL={isAdminOrDL}
        isTeamAdmin={isTeamAdmin}
        refetch={refetch}
        mainNameQA={mainNameQA}
        showChatIcon={showChatIcon}
        unreadMessagesCount={unreadMessagesCount}
      />
    </Card>
  );
};

export default BriefCard;
