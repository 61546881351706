import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Field, FormSpy } from 'react-final-form';
import { useLocation } from 'react-router';
import { INPUT_VARIANTS } from '@styles/theme';
import useMediaQuery from '@hooks/useMediaQuery';
import { find, first, get, round, split, isNumber } from 'lodash-es';
import { CURRENCIES_SYMBOLS } from '@app/constants';
import { isProjectCloning } from '@utils/projectUtils';
import AutoCalcCell from './AutoCalcCell';
import ActionButton from './ActionButton';
import TextInput from '../forms/TextInput';
import Select from '../forms/Select';
import MaskedTextInput from '../forms/MaskedTextInput';
import { cellStyle } from './allocationsHelpers';
import AllocationEntryDatesFields from './AllocationEntryDatesFields';

const AllocationRow = ({
  allocationRolesOptions,
  allocationIdx,
  allocationId,
  fieldName = 'allocations',
  canBeDeleted,
  deleteRow,
  addRow,
  disabledFields = [],
  disallowRowChange,
  isTeamAdmin,
  isTeamMember,
  mainNameQA,
  isManualAddingRow,
  isEditing,
  borderDateForAllocation,
  isOnlyFirstRow,
  isFixedPrice,
  isRetainerOrTAForFixedPrice,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const location = useLocation();

  const disableEditing = (!isEditing && isManualAddingRow) || isOpen;

  const isOldRow = isNumber(allocationId);

  const cannotRemoveExistingRow =
    isOldRow && isFixedPrice && !isProjectCloning(location);

  const isDesktop = useMediaQuery('(min-width: 1620px)');

  return (
    <FormSpy subscription={{ submitting: true }}>
      {() => (
        <>
          <Box
            w="100%"
            position="relative"
            data-test-id={`${mainNameQA}--${fieldName}-row`}
          >
            <ActionButton
              deleteRow={deleteRow(allocationId)}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              canBeDeleted={canBeDeleted}
              addRow={addRow}
              disallowRowChange={disallowRowChange}
              isManualAddingRow={isManualAddingRow}
              isOnlyFirstRow={isOnlyFirstRow}
              cannotRemoveExistingRow={cannotRemoveExistingRow}
            />

            <Flex
              borderRadius="8px"
              w="100%"
              h="72px"
              mb="12px"
              bg="#fff"
              opacity={isOpen ? 0.5 : 1}
            >
              <Flex
                pl="40px"
                flex={1}
                border="1px solid"
                borderColor="dark.mid"
                borderRight="none"
                borderTopLeftRadius="8px"
                borderBottomLeftRadius="8px"
              >
                <Box
                  alignSelf="center"
                  display="flex"
                  minW="100px"
                  flex={1}
                  sx={cellStyle(150)}
                  h="70px"
                  borderRight="1px solid"
                  borderColor="dark.mid"
                >
                  <Field
                    name={`${fieldName}[${allocationIdx}].name`}
                    variant={INPUT_VARIANTS.CONTAINER_FILLED}
                    data-test-id={`${mainNameQA}--${fieldName}[${allocationIdx}].name`}
                    component={TextInput}
                    disabled={
                      find(disabledFields, field => field === 'name') ||
                      disableEditing
                    }
                    placeholder="Add Name"
                  />
                </Box>
                <Box
                  alignSelf="center"
                  sx={cellStyle(160)}
                  borderRight="1px solid"
                  borderColor="dark.mid"
                  h="70px"
                >
                  <Field
                    name={`${fieldName}[${allocationIdx}].resourceType`}
                    data-test-id={`${mainNameQA}--${fieldName}[${allocationIdx}].resourceType`}
                    variant={INPUT_VARIANTS.CONTAINER_FILLED}
                    {...(find(disabledFields, field => field === 'name')
                      ? { disabled: true, component: TextInput }
                      : {
                          component: Select,
                          options: allocationRolesOptions,
                          isDisabled: disableEditing || cannotRemoveExistingRow,
                        })}
                    placeholder={isDesktop ? 'Select Role' : 'Select'}
                  />
                </Box>
                <Box
                  alignSelf="center"
                  sx={cellStyle(140)}
                  borderRight="1px solid"
                  borderColor="dark.mid"
                  h="70px"
                >
                  <Field
                    name={`${fieldName}[${allocationIdx}].amount`}
                    data-test-id={`${mainNameQA}--${fieldName}[${allocationIdx}].amount`}
                    variant={INPUT_VARIANTS.CONTAINER_FILLED}
                    component={MaskedTextInput}
                    decimalScale={2}
                    fixedDecimalScale
                    placeholder="0.00"
                    disabled={
                      (disableEditing || cannotRemoveExistingRow) &&
                      !isRetainerOrTAForFixedPrice
                    }
                    containerProps={{ maxW: '140px' }}
                  />
                </Box>
                <AllocationEntryDatesFields
                  isStartDateDisabled={find(
                    disabledFields,
                    field => field === 'startDate',
                  )}
                  isEndDateDisabled={find(
                    disabledFields,
                    field => field === 'endDate',
                  )}
                  isNotCloning={!isProjectCloning(location)}
                  endDateFieldName={`${fieldName}[${allocationIdx}].endDate`}
                  startDateFieldName={`${fieldName}[${allocationIdx}].startDate`}
                  mainNameQA={mainNameQA}
                  borderDateForAllocation={borderDateForAllocation}
                  isOldRow={isOldRow}
                />
              </Flex>
              {!isTeamMember && (
                <Flex
                  sx={cellStyle(600)}
                  w="100%"
                  border="1px solid"
                  borderColor="blue.solid"
                  h="100%"
                >
                  <Box
                    alignSelf="center"
                    h="100%"
                    position="relative"
                    w={isTeamAdmin ? '50%' : '25%'}
                  >
                    {/* need refactor */}
                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => {
                        return (
                          <Field
                            name={`${first(
                              split(fieldName, '.'),
                            )}.overriddenCurrency`}
                            subscription={{ value: true }}
                          >
                            {({ input: { value: currency } }) => (
                              <Field
                                name={`${fieldName}[${allocationIdx}].rate`}
                                data-test-id={`${mainNameQA}--${fieldName}[${allocationIdx}].rate`}
                                disabled={
                                  !isRetainerOrTAForFixedPrice &&
                                  (find(
                                    disabledFields,
                                    field => field === 'rate',
                                  ) ||
                                    disableEditing ||
                                    cannotRemoveExistingRow)
                                }
                              >
                                {({ ...inputProps }) => {
                                  return (
                                    <MaskedTextInput
                                      variant={INPUT_VARIANTS.CONTAINER_FILLED}
                                      decimalScale={2}
                                      fixedDecimalScale
                                      prefix={
                                        CURRENCIES_SYMBOLS[
                                          values.isNewProject
                                            ? currency
                                            : values.deliveryPartnerCurrency ||
                                              currency
                                        ]
                                      }
                                      placeholder="-"
                                      {...inputProps}
                                      changeIfActive
                                    />
                                  );
                                }}
                              </Field>
                            )}
                          </Field>
                        );
                      }}
                    </FormSpy>
                  </Box>
                  <FormSpy subscription={{ values: true }}>
                    {({ values }) => {
                      const { rate, amount } = get(
                        values,
                        `${fieldName}[${allocationIdx}]`,
                        {},
                      );
                      const { overriddenCurrency } = get(
                        values,
                        first(split(fieldName, '.')),
                        {},
                      );

                      return (
                        <AutoCalcCell
                          w={isTeamAdmin ? '50%' : '25%'}
                          currency={
                            values.isNewProject
                              ? overriddenCurrency
                              : values.deliveryPartnerCurrency ||
                                overriddenCurrency
                          }
                          rate={rate}
                          amount={amount}
                          data-test-id={`${mainNameQA}--${fieldName}[${allocationIdx}].dp-cost`}
                          {...(isTeamAdmin && {
                            borderTopRightRadius: '8px',
                            borderBottomRightRadius: '8px',
                          })}
                        />
                      );
                    }}
                  </FormSpy>
                  {!isTeamAdmin && (
                    <Box
                      alignSelf="center"
                      w="25%"
                      h="100%"
                      position="relative"
                    >
                      <Field name="currency" subscription={{ value: true }}>
                        {({ input: { value: currency } }) => (
                          <Field
                            name={`${fieldName}[${allocationIdx}].clientRate`}
                            data-test-id={`${mainNameQA}--${fieldName}[${allocationIdx}].clientRate`}
                            disabled={
                              !isRetainerOrTAForFixedPrice &&
                              (find(
                                disabledFields,
                                field => field === 'clientRate',
                              ) ||
                                disableEditing ||
                                cannotRemoveExistingRow)
                            }
                          >
                            {inputProps => {
                              return (
                                <MaskedTextInput
                                  variant={INPUT_VARIANTS.CONTAINER_FILLED}
                                  decimalScale={2}
                                  fixedDecimalScale
                                  placeholder="-"
                                  prefix={CURRENCIES_SYMBOLS[currency]}
                                  {...inputProps}
                                  component={MaskedTextInput}
                                  changeIfActive
                                />
                              );
                            }}
                          </Field>
                        )}
                      </Field>
                    </Box>
                  )}
                  {!isTeamAdmin && (
                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => {
                        const { clientRate, amount } = get(
                          values,
                          `${fieldName}[${allocationIdx}]`,
                          {},
                        );
                        return (
                          <AutoCalcCell
                            w={isTeamAdmin ? '50%' : '25%'}
                            currency={values.currency}
                            borderRight="none"
                            data-test-id={`${mainNameQA}--${fieldName}[${allocationIdx}].client-cost`}
                            rate={round(clientRate, 2)}
                            amount={round(amount, 2)}
                          />
                        );
                      }}
                    </FormSpy>
                  )}
                </Flex>
              )}
            </Flex>
          </Box>
        </>
      )}
    </FormSpy>
  );
};
export default AllocationRow;
