import { find, get, toNumber } from 'lodash-es';
import { action, makeObservable, observable } from 'mobx';

export default class RatesStore {
  constructor({ API, toastsStore, usersStore }) {
    makeObservable(this);
    this.API = API;
    this.toastsStore = toastsStore;
    this.usersStore = usersStore;
  }

  @observable resourceRates = {
    data: {},
    isFetched: false,
    isLoading: false,
  };

  @observable clientRates = {
    data: {},
    isLoading: false,
  };

  @action getResourceRate = (
    resourceName,
    seniority,
    isPnP,
    isAdvancedFramework,
    clientType,
  ) => {
    const getRate = (resources, checkClientType = false) =>
      find(
        resources[
          isAdvancedFramework ? 'advancedFrameworkRates' : 'regularRates'
        ],
        r => {
          if (isAdvancedFramework) {
            if (checkClientType) {
              return r.seniority === seniority && clientType === r.clientType;
            }
            return r.seniority === seniority;
          }
          if (checkClientType) {
            return (
              r.role === resourceName &&
              r.seniority === seniority &&
              r.clientType === clientType
            );
          }
          return r.role === resourceName && r.seniority === seniority;
        },
      ) || {};
    const supplierResource = getRate(this.resourceRates.data);
    const clientResource = getRate(this.clientRates.data, true);

    if (isPnP) {
      return {
        rate: toNumber(supplierResource.pnpTargetRate) || null,
        highRate: toNumber(supplierResource.pnpHighRate) || null,
        clientLowRate: toNumber(clientResource.lowRate) || null,
        clientHighRate: toNumber(clientResource.highRate) || null,
        clientTargetRate: toNumber(clientResource.targetRate) || null,
      };
    }
    return {
      rate: toNumber(supplierResource.projectRate) || null,
      clientLowRate: toNumber(clientResource.lowRate) || null,
      clientHighRate: toNumber(clientResource.highRate) || null,
      clientTargetRate: toNumber(clientResource.targetRate) || null,
    };
  };

  @action fetchResourceRates = async sId => {
    const supplierId = sId || get(this.usersStore.profile, 'supplier.id');
    if (!supplierId) {
      return;
    }
    try {
      this.resourceRates.isLoading = true;
      const { data } = await this.API.getResourceRates(supplierId);
      this.resourceRates.data = data;
      this.resourceRates.isFetched = true;
    } catch {
      this.toastsStore.showError({
        title: 'Resource rates could not be fetched',
      });
    } finally {
      this.resourceRates.isLoading = false;
    }
  };

  @action fetchClientResourceRates = async cId => {
    if (!cId) {
      return;
    }
    try {
      this.clientRates.isLoading = true;
      const { data } = await this.API.getClientRates(cId);
      this.clientRates.data = data;
    } catch {
      this.toastsStore.showError({
        title: 'Resource client rates could not be fetched',
      });
    } finally {
      this.clientRates.isLoading = false;
    }
  };
}
