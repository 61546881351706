import { Box, Flex } from '@chakra-ui/react';
import DescriptionWithIconBox from '@components/DescriptionWithIconBox';
import FormError from '@components/forms/FormError';
import { map } from 'lodash-es';
import React from 'react';

const withOnlyLabelStyleProps = {
  flexDir: 'row',
  h: 'auto',
};

const FormRadio = ({
  containerProps,
  choices,
  meta = {},
  input = {},
  keyPrefix,
  responsive,
  ...props
}) => {
  const hasError =
    (meta.touched || meta.submitFailed) && (meta.error || meta.submitError);

  return (
    <Flex flexDir="column" width="100%">
      <Box position="relative" {...containerProps}>
        {hasError && (
          <Box
            w="2px"
            height="100%"
            position="absolute"
            bg="red.solid"
            left="-12px"
          />
        )}
        <Flex gap={responsive ? 3 : 6} wrap={responsive && 'wrap'}>
          {map(
            choices,
            (
              { label, leftIcon, value, description, dataTestForRadio },
              idx,
            ) => {
              const iconWithoutDescriptionStyle = !description && {
                fontSize: 21,
                mr: '11px',
                mb: '0px',
              };

              return (
                <DescriptionWithIconBox
                  {...props}
                  key={`${keyPrefix}-${idx}`}
                  label={label}
                  description={description}
                  data-test-id={`${dataTestForRadio}-${idx}`}
                  {...(!description && withOnlyLabelStyleProps)}
                  icon={{
                    name: leftIcon,
                    fontSize: 21,
                    color: leftIcon === 'check' && 'green.solid',
                    ...iconWithoutDescriptionStyle,
                  }}
                  isActive={input.value === value}
                  onClick={() => {
                    input.onChange(value);
                  }}
                />
              );
            },
          )}
        </Flex>
      </Box>
      <FormError meta={meta} ml="0px" />
    </Flex>
  );
};

export default FormRadio;
