import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import ReactSelect, { components } from 'react-select';
import Spinner from '@styles/Spinner';
import theme, { COLORS, FONTS, INPUT_VARIANTS } from '@styles/theme';
import { findIndex, isEqual } from 'lodash-es';
import React from 'react';
import FormError from './FormError';
import FormLabel from './FormLabel';

export const SimpleStyles = styled(({ regularStyles, hasError, ...props }) => (
  <Box {...props} />
))`
  .select__control {
    padding: 0px 12px;
    min-height: 48px;
    box-shadow: none;
    border-radius: 8px;
    border-color: ${theme.colors.dark.mid};
    border: 1px solid ${theme.colors.dark.mid};
    border-style: solid;
    max-width: 219px;
    min-width: 100%;
    :hover {
      border: 1px solid ${theme.colors.dark.mid};
    }
  }
  .select__value-container {
    padding: 0px;
    position: relative;
    padding-right: 20px;
    max-width: 333px;
    overflow: hidden;
    flex-wrap: nowrap;
  }
  .select__multi-value {
    font-size: 16px;
    background-color: transparent;
    min-height: 24px;
    margin: 0;
    margin-right: 8px;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 8px;
    font-family: ${theme.fonts.WS};
    min-width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    &:nth-last-of-type(2) {
      .select__multi-value__label {
        &::after {
          content: ' ';
        }
      }
    }
  }

  .select__multi-value__label {
    font-weight: 400;
    align-self: center;
    padding-right: 0;
    padding-left: 0;
    text-overflow: inherit;
    overflow: visible;

    &::after {
      content: ',';
      font-size: 16px;
      font-family: ${theme.fonts.WS};
    }
  }

  .select__indicator-separator {
    background-color: #d9dada;
    margin-top: 11px;
    margin-bottom: 11px;
  }
  .select__dropdown-indicator {
    padding: 8px 0px 8px 8px;
    cursor: pointer;
    color: #2b231d;
  }

  .select__menu-list {
    margin: 16px;
    border-top: 1px solid ${COLORS.dark.mid};
  }

  .select__menu {
    border: 1px solid ${theme.colors.dark.mid};
    border-top: 1px solid transparent;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-top: -8px;
    z-index: 100;
    box-shadow: none;
  }
  .select__option {
    font-size: 15px;
    font-family: ${FONTS.WS};
    padding: 9px 8px;
    padding-left: 30px;
    position: relative;
    cursor: pointer;
    border-radius: 8px;
    :not(:last-of-type) {
      margin-bottom: 0px;
    }
    :active {
      background: transparent;
    }
    :before {
      content: '';
      background-image: url('/images/icons/ui-element-checkbox-unselected.svg');
      width: 16px;
      height: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: 4px;
      top: 8px;
    }
    &--is-selected:before {
      content: '';
      background-image: url('/images/icons/ui-element-checkbox-selected.svg');
      width: 16px;
      height: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: 4px;
      top: 8px;
    }
  }
  .select__option--is-focused {
    background: ${COLORS.aqua.light};
  }
  .select__option--is-selected {
    background: transparent;
    color: #000;
  }
  .select__menu-list {
    margin: 8px 14px;
  }
  .select__control--menu-is-open {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .select__placeholder {
    color: hsl(0, 0%, 70%);
    font-size: 16px;
    opacity: 0.6;
  }
  select__multi-value__remove {
    display: none;
  }
`;

const MultiValueContainer = ({ selectProps, ...props }) => {
  const { howManyItemsInsideInput } = selectProps;
  const index = findIndex(selectProps.value, option =>
    isEqual(option, props.data),
  );

  // This needs to be refactored to be more customizable
  if (index < howManyItemsInsideInput) {
    return (
      <components.MultiValueContainer {...props} selectProps={selectProps} />
    );
  }
  return null;
};

const OptionsCounter = ({ amount }) => (
  <Flex
    w="21px"
    h="20px"
    borderRadius="5px"
    alignItems="center"
    justifyContent="center"
    bg={amount ? 'blue.solid' : '#D9DFFF'}
  >
    <Text color="white" fontSize="14px" lineHeight="16px">
      {amount}
    </Text>
  </Flex>
);

const MultiSelectSimple = ({
  placeholder = 'Select multiple options...',
  options = [],
  defaultValue,
  styleProps = {},
  isLoading,
  input = {},
  meta = {},
  label,
  labelProps,
  isRegular,
  howManyItemsInsideInput = 5,
  isWithoutChipsInsideInput,
  notRemoveFromInput,
  onAfterChange,
  menuPosition = 'absolute',
  variant = INPUT_VARIANTS.NORMAL,
  showCounter = false,
  ...rest
}) => {
  const hasError =
    (meta.touched || meta.submitFailed) && (meta.error || meta.submitError);

  const notRemoveFromInputStyles = {
    multiValueRemove: base => ({
      ...base,
      display: 'none',
    }),
    menu: base => ({ ...base, position: menuPosition }),
  };

  return (
    <SimpleStyles
      variant={variant}
      w="100%"
      cursor="pointer"
      {...styleProps}
      regularStyles={isRegular ? 1 : 0}
      hasError={hasError}
      data-test-id={rest['data-test-id']}
    >
      <HStack
        w="100%"
        mb={showCounter ? '11px' : '14px'}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <FormLabel name={input.name} label={label} {...labelProps} mb={0} />
        {!isLoading && showCounter && (
          <OptionsCounter amount={(input.value || []).length} />
        )}
      </HStack>
      {isLoading ? (
        <Spinner variant="small" />
      ) : (
        <ReactSelect
          closeMenuOnSelect={false}
          isMulti
          howManyItemsInsideInput={howManyItemsInsideInput}
          components={{ MultiValueContainer }}
          placeholder={placeholder}
          className="form-select"
          classNamePrefix="select"
          hideSelectedOptions={false}
          defaultValue={input.value || []}
          options={options}
          styles={notRemoveFromInput && notRemoveFromInputStyles}
          {...input}
          onChange={v => {
            input.onChange(v);
            if (onAfterChange) {
              onAfterChange(v);
            }
          }}
          {...rest}
        />
      )}
      <FormError meta={meta} />
    </SimpleStyles>
  );
};

export default MultiSelectSimple;
