import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import {
  composeValidators,
  mustBeShorterOrEqual,
  required,
} from '@utils/formValidators';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form } from 'react-final-form';
import Textarea from './forms/_common/Textarea';

const QueryProposalModal = ({
  isOpen,
  onClose,
  proposalQueriesStore: { queryProposal },
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={values => queryProposal(values, onClose)}
        subscription={{ submitError: true, submitting: true }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Typography variant="h2" mb="24px" textAlign="center">
                Query proposal
              </Typography>
              <Typography mb="40px" textAlign="center" maxW="488px" mx="auto">
                Let us know what concerns you have with this proposal and we’ll
                get back to you
              </Typography>
              <Field
                component={Textarea}
                name="message"
                rows={5}
                counter={150}
                validate={composeValidators(
                  required,
                  mustBeShorterOrEqual(150),
                )}
                placeholder="e.g. Something is missing"
                maxW="488px"
                mb="56px"
                mx="auto"
              />
              <Stack spacing={3} mt="40px">
                <Button isLoading={submitting} type="submit">
                  Submit
                </Button>
                <Button
                  onClick={onClose}
                  variant="outline"
                  isDisabled={submitting}
                >
                  Cancel
                </Button>
              </Stack>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default inject('proposalQueriesStore')(observer(QueryProposalModal));
