/* eslint-disable */
import React from 'react';
import { Router, Switch } from 'react-router-dom';
import GlobalStyles from '@styles/globalStyles';
import AppRoute from '@components/app/AppRoute';
import ScrollToTop from '@components/app/ScrollToTop';
import theme from '@styles/theme';
import { observer, inject } from 'mobx-react';
import { CSSReset, Flex, ChakraProvider } from '@chakra-ui/react';
import useMediaQuery from '@hooks/useMediaQuery';
import { map } from 'lodash-es';
import smoothscroll from 'smoothscroll-polyfill';
import routes from '@app/routesConfig';
import history from '@app/history';
import ToastsContainer from '@components/ToastsContainer';
// import ErrorReportButton from '@components/ErrorReportButton';
// import XeroButton from '@components/XeroButton';
// import MarketplaceButton from '@components/MarketplaceButton';

smoothscroll.polyfill();

const App = ({ usersStore: { profile } }) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');
  return (
    <>
      <ChakraProvider theme={theme}>
        <CSSReset />
        <GlobalStyles />
        <Flex
          position="fixed"
          bottom="15px"
          left="50%"
          transform="translateX(-50%)"
          zIndex={1300}
        >
          {/* {!isSmallerScreen && (
            <>
              <XeroButton />
              <ErrorReportButton />
              <MarketplaceButton />
            </>
          )} */}
        </Flex>
        <ToastsContainer />
        <Router history={history}>
          <ScrollToTop>
            <Switch>
              {map(routes, (route, idx) => (
                <AppRoute key={`app-route-${idx}`} {...route} />
              ))}
            </Switch>
          </ScrollToTop>
        </Router>
      </ChakraProvider>
    </>
  );
};

export default inject('usersStore')(observer(App));
