import { Box } from '@chakra-ui/react';
import { COLORS } from '@styles/theme';
import React from 'react';

const styles = {
  borderRadius: '8px',
  border: `solid 1px ${COLORS.dark.mid}`,
  backgroundColor: 'white',
  width: '100%',
  position: 'relative',
  marginBottom: '16px',
  _hover: {
    boxShadow: `0px 0px 8px rgba(0, 0, 0, 0.1)`,
    cursor: 'pointer',
  },
};

const Card = ({ children, ...props }) => (
  <Box {...styles} {...props}>
    {children}
  </Box>
);

export default Card;
