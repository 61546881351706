import { inject, observer } from 'mobx-react';
import React from 'react';
import { Box } from '@chakra-ui/react';
import SubSidebarTabs from '@components/SubSidebarTabs';
import useMediaQuery from '@hooks/useMediaQuery';
import useSubSidebarToggle from '@hooks/useSubSidebarToggle';
import { APP_ROUTES } from '@routes';
import useBriefGuard from '@hooks/useBriefGuard';
import { breakpoint } from '@styles/breakpoints';
import Spinner from '@styles/Spinner';
import { Route, Switch } from 'react-router';
import Assignment from './Assignment';
import AllProposals from './AllProposals';
import DPFeatureSection from './Proposal/DPFeatureSection';
import Overview from './Overview';
import PlugAndPlayProposal from './PlugAndPlayProposal';
import Proposal from './Proposal';
import ProposalDeazyLayer from './ProposalDeazyLayer';
import BriefChat from './BriefChat';
import Questions from './Questions';
import AllCandidates from './AllCandidates';
import FindDPs from './FindDPs';
import DevProfiles from './DevProfiles';

const Brief = ({
  match: {
    params: { id },
  },
  briefsStore: {
    clearBrief,
    isLoading,
    fetchBrief,
    brief,
    setCurrentChannelUrl,
    currentChannelUrl,
  },
  briefsStore,
  usersStore: { isProfileLoaded, isAdminOrDL, isTeamAdmin, isClient },
  usersStore,
  proposalsStore: {
    proposalData,
    allProposals: { data: proposals, isLoading: isLoadingProposals },
    clearAllProposals,
    fetchAllProposals,
  },
  proposalsStore,
  isPlugAndPlay,
  appStore: {
    toggleSubSidebar,
    isSubSidebarOpened,
    isBriefChatOpened,
    toggleBriefChat,
  },
  appStore,
}) => {
  useSubSidebarToggle(() =>
    toggleSubSidebar(isAdminOrDL || isTeamAdmin || isClient),
  );
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  React.useEffect(() => {
    if (id) {
      fetchBrief(id, isPlugAndPlay);
    }
    return () => {
      clearAllProposals();
      clearBrief();
    };
  }, [id]);

  React.useEffect(() => {
    if (brief.id && !brief.isPlugAndPlay) {
      fetchAllProposals(brief.id, brief.isPlugAndPlay);
    }
  }, [brief.id, proposalData]);

  const briefLinkBase = isPlugAndPlay ? 'team-augmentation-briefs' : 'briefs';

  const tabs = [
    {
      label: 'Overview',
      value: 'overview',
      link: `/${briefLinkBase}/${id}/overview`,
    },
    {
      label: 'Find Delivery Partners',
      value: 'find-delivery-partners',
      link: `/${briefLinkBase}/${id}/find-delivery-partners`,
    },
    {
      label: 'Assignment',
      value: 'assignment',
      link: `/${briefLinkBase}/${id}/assignment`,
    },
    {
      label: 'Questions',
      value: 'questions',
      link: `/${briefLinkBase}/${id}/questions`,
    },
    {
      label: 'Your proposal',
      value: 'proposal',
      link: `/${briefLinkBase}/${id}/proposal`,
    },
    {
      label: 'Candidates',
      value: 'candidates',
      link: `/team-augmentation-briefs/${id}/candidates`,
    },
    {
      label: 'Proposal',
      value: 'deazy-proposal',
      link: `/team-augmentation-briefs/${id}/proposal/deazy`,
    },
    {
      label: 'Proposal',
      value: 'client',
      link: `/team-augmentation-briefs/${id}/proposals/client`,
    },
    {
      label: 'Proposals',
      value: 'proposals',
      link: `/${briefLinkBase}/${id}/proposals`,
    },
  ];

  const { tabs: strippedTabs, activeTab } = useBriefGuard({
    usersStore,
    briefsStore,
    proposalsStore,
    appStore,
    tabs,
  });

  const memoForBackground = React.useMemo(() => {
    const isProposalPageMobile =
      activeTab === 'deazy-proposal' ||
      activeTab === 'proposal' ||
      activeTab === 'proposals' ||
      activeTab === 'client';

    const isCandidatesPageMobile = activeTab === 'candidates';

    if ((isProposalPageMobile || isCandidatesPageMobile) && isSmallerScreen) {
      return 'primary.100';
    }
    if (isSmallerScreen) {
      return '#fff';
    }
    return 'primary.100';
  }, [isPlugAndPlay, activeTab, isSmallerScreen]);

  const paddingForBriefPage = React.useMemo(() => {
    if (isSmallerScreen && activeTab === 'dev-profiles') {
      return '24px 0 217px';
    }
    if (isSmallerScreen && !activeTab === 'dev-profiles') {
      return '24px 16px 217px';
    }
    return '40px 40px 155px';
  }, [isSmallerScreen, activeTab]);

  return (
    <>
      {activeTab !== 'dev-profiles' && (
        <SubSidebarTabs
          value={activeTab}
          tabs={strippedTabs}
          subsidebarProps={{
            width: isSubSidebarOpened ? '172px' : '0',
          }}
        />
      )}
      {brief.id && (
        <BriefChat
          brief={brief}
          isBriefChatOpened={isBriefChatOpened}
          toggleBriefChat={toggleBriefChat}
          briefName={brief.name}
          isAdminOrDL={isAdminOrDL}
          setCurrentChannelUrl={setCurrentChannelUrl}
          currentChannelUrl={currentChannelUrl}
        />
      )}
      <Box
        p={paddingForBriefPage}
        minH="calc(100vh - 80px)"
        bg={memoForBackground}
      >
        <Box w="100%" maxW="1336px" mx="auto">
          {isLoading || !isProfileLoaded || !brief.id ? (
            <Spinner />
          ) : (
            <Switch>
              <Route
                path={APP_ROUTES.briefTab(id, 'overview')}
                exact
                render={props => <Overview {...props} />}
              />
              <Route
                path={APP_ROUTES.briefPlugAndPlayTab(id, 'overview')}
                exact
                render={props => <Overview {...props} />}
              />
              <Route
                path={APP_ROUTES.briefTab(id, 'assignment')}
                exact
                render={props => <Assignment {...props} />}
                key="brief-exclusive-assignment"
              />
              <Route
                path={APP_ROUTES.briefPlugAndPlayTab(id, 'assignment')}
                exact
                key="pnp-brief-exclusive-assignment"
                render={props => <Assignment {...props} />}
              />
              <Route
                path={APP_ROUTES.briefPlugAndPlayTab(id, 'questions')}
                exact
                render={props => <Questions {...props} />}
              />
              <Route
                path={APP_ROUTES.briefTab(id, 'questions')}
                exact
                render={props => <Questions {...props} />}
              />
              <Route
                path={APP_ROUTES.briefPlugAndPlayTab(id, 'proposal')}
                exact
                render={props => <PlugAndPlayProposal {...props} />}
              />
              <Route
                path={APP_ROUTES.briefTab(id, 'proposal')}
                exact
                render={props => <Proposal {...props} />}
              />
              <Route
                path={APP_ROUTES.briefPlugAndPlayTab(
                  id,
                  'proposals/:proposalId',
                )}
                exact
                render={props => <PlugAndPlayProposal {...props} />}
              />
              <Route
                path={APP_ROUTES.briefTab(id, 'proposals/:proposalId')}
                exact
                render={props => <Proposal {...props} />}
              />
              <Route
                path={APP_ROUTES.briefPlugAndPlayTab(id, 'proposals')}
                exact
                render={props => (
                  <AllProposals
                    proposals={proposals}
                    isLoading={isLoadingProposals}
                    {...props}
                  />
                )}
              />
              <Route
                path={APP_ROUTES.briefPlugAndPlayTab(id, 'candidates')}
                exact
                render={props => <AllCandidates {...props} />}
              />
              <Route
                path={APP_ROUTES.briefPlugAndPlayTab(null, 'proposal/:type')}
                exact
                render={props => <ProposalDeazyLayer {...props} />}
              />
              <Route
                path={APP_ROUTES.briefPlugAndPlayTab(id, 'dev-profiles')}
                exact
                render={props => <DevProfiles {...props} />}
              />
              <Route
                path={APP_ROUTES.briefPlugAndPlayTab(
                  id,
                  'find-delivery-partners',
                )}
                exact
                render={props => <FindDPs {...props} />}
              />
              <Route
                path={APP_ROUTES.briefTab(id, 'find-delivery-partners')}
                exact
                render={props => <FindDPs {...props} />}
              />
              <Route
                path={APP_ROUTES.briefPlugAndPlayTab(
                  null,
                  'proposals/:proposalId/:type',
                )}
                exact
                render={props => <ProposalDeazyLayer {...props} />}
              />
              <Route
                path={APP_ROUTES.briefTab(null, 'proposals/:proposalId/:type')}
                exact
                render={props => <ProposalDeazyLayer {...props} />}
              />
              <Route
                path={APP_ROUTES.briefTab(id, 'proposals')}
                exact
                render={props => (
                  <AllProposals
                    proposals={proposals}
                    isLoading={isLoadingProposals}
                    {...props}
                  />
                )}
              />
              <Route
                path={APP_ROUTES.briefTab(id, 'proposal/features/:featureId')}
                exact
                render={props => <DPFeatureSection {...props} />}
              />
              <Route
                path={APP_ROUTES.briefTab(
                  id,
                  'proposals/:proposalId/features/:featureId',
                )}
                exact
                render={props => <DPFeatureSection {...props} />}
              />
              <Route
                path={APP_ROUTES.briefTab(
                  id,
                  'proposals/:proposalId/:type/features/:featureId',
                )}
                exact
                render={props => <DPFeatureSection {...props} />}
              />
            </Switch>
          )}
        </Box>
      </Box>
    </>
  );
};

export default inject(
  'briefsStore',
  'usersStore',
  'proposalsStore',
  'appStore',
)(observer(Brief));
