import { Tooltip } from '@chakra-ui/react';
import useCopy from '@hooks/useCopy';
import Button from '@styles/Button';
import React from 'react';

const CopyPaymentLink = ({ link, ...props }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { clearCopy, onCopy, isCopied } = useCopy();

  return (
    <Tooltip
      zIndex={10000}
      label={isCopied ? 'Copied to clipboard!' : 'Copy link to this invoice'}
      hasArrow
      p="12px"
      placement="top"
      isOpen={isOpen}
      hideDelay={500}
    >
      <Button
        iconButton={{
          name: 'copy',
          fontSize: 22,
        }}
        onMouseLeave={() => {
          clearCopy();
          setIsOpen(false);
        }}
        onMouseEnter={() => setIsOpen(true)}
        onClick={e => {
          e.stopPropagation();
          onCopy(link);
        }}
        {...props}
      />
    </Tooltip>
  );
};

export default CopyPaymentLink;
