import { Box, Flex, Stack } from '@chakra-ui/react';
import { ErrorText } from '@components/forms/_common/FormError';
import Textarea from '@components/forms/_common/Textarea';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import {
  composeValidators,
  mustBeShorterOrEqual,
  required,
} from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';

const BriefDeclineModal = ({
  onSubmit,
  projectName,
  clientName,
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Flex flexDir="column" mb="24px">
        <Typography variant="h2" mb="24px" textAlign="center">
          Decline this brief
        </Typography>
        <Flex justify="space-between" bg="dark.light" p="16px">
          <Box minW="50%" textAlign="center">
            <Typography>
              Project:{' '}
              <Typography as="span" variant="title">
                {projectName}
              </Typography>
            </Typography>
          </Box>
          <Box minW="50%" textAlign="center">
            <Typography>
              Client:{' '}
              <Typography as="span" variant="title">
                {clientName}
              </Typography>
            </Typography>
          </Box>
        </Flex>
      </Flex>

      <Flex w="100%" mx="auto" flexDirection="column">
        <Typography variant="h4" mb="32px" textAlign="center">
          If possible, let us know why you’re declining this brief
        </Typography>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, submitError }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Field
                  component={Textarea}
                  inputProps={{ rows: 5 }}
                  maxW="488px"
                  mx="auto"
                  name="changeStatusReason"
                  validate={composeValidators(
                    required,
                    mustBeShorterOrEqual(10000),
                  )}
                  placeholder="eg. We don’t have enough resource at the moment."
                />
                {submitError && <ErrorText mt="12px">{submitError}</ErrorText>}
                <Stack spacing={3} mt="40px">
                  <Button
                    variant="warning"
                    isLoading={submitting}
                    type="submit"
                  >
                    Decline brief
                  </Button>
                  <Button variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                </Stack>
              </form>
            );
          }}
        />
      </Flex>
    </Modal>
  );
};

export default BriefDeclineModal;
