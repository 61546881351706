import { isEmpty } from 'lodash-es';
import { action, makeObservable, observable, reaction } from 'mobx';
import { TOAST_STYLE_TYPES } from '@app/constants';

export default class ToastStore {
  constructor() {
    makeObservable(this);
    this._clearToast();
  }

  @observable toast = null;

  @observable requestError = null;

  @action _clearToast = () => {
    reaction(
      () => this.toast,
      async toast => {
        if (!isEmpty(toast)) {
          this.toast = null;
        }
      },
      { delay: 250 },
    );
    reaction(
      () => this.requestError,
      async requestError => {
        if (!isEmpty(requestError)) {
          this.requestError = null;
        }
      },
      { delay: 250 },
    );
  };

  @action showToast = toast => {
    this.toast = toast;
  };

  @action showError = toast =>
    this.showToast({
      status: 'error',
      toastStyleType: TOAST_STYLE_TYPES.DEFAULT,
      ...toast,
    });

  @action showInfo = toast =>
    this.showToast({
      status: 'info',
      toastStyleType: TOAST_STYLE_TYPES.DEFAULT,
      ...toast,
    });

  @action showSuccess = toast =>
    this.showToast({
      status: 'success',
      toastStyleType: TOAST_STYLE_TYPES.DEFAULT,
      ...toast,
    });

  @action showWarning = toast =>
    this.showToast({
      status: 'warning',
      toastStyleType: TOAST_STYLE_TYPES.DEFAULT,
      ...toast,
    });

  @action setRequestError = errorObj => {
    this.requestError = errorObj;
  };
}
