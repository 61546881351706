import { IS_DEV, IS_STAGING } from '@app/constants';
import { Box, useDisclosure } from '@chakra-ui/react';
import useMediaQuery from '@hooks/useMediaQuery';
import { some, noop } from 'lodash-es';
import { APP_ROUTES } from '@routes';
import Button from '@styles/Button';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import Popover, { PopoverList } from './Popover';

const CreateButton = ({
  appStore: {
    featureFlags: {
      'uo-create-user': uoCreateUser,
      'uo-frontegg-auth': uoFronteggAuth,
    },
  },
  usersStore: { isAdmin, isAdminOrDL, isClient, isTeamAdmin, isTeamMember },
  onImport,
  onCreateUser,
  label = 'New',
  popoverProps,
  buttonProps,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');
  const iconProps = {
    w: '21px',
    h: '21px',
    mb: '5px',
  };

  const popoverOptions = [
    {
      label: 'Brief',
      dataTestId: 'brief',
      visible: !isTeamAdmin && !isTeamMember,
      as: NavLink,
      to: APP_ROUTES.briefsStartCreate,
      icon: 'briefs',
      ...(isSmallerScreen && { iconProps }),
    },
    {
      label: 'Project',
      dataTestId: 'project',
      visible: isAdminOrDL,
      as: NavLink,
      to: APP_ROUTES.projectsCreate,
      icon: 'projects',
      ...(isSmallerScreen && { iconProps }),
    },
    {
      label: 'Project from file',
      dataTestId: 'projectFromFile',
      visible: isAdmin && (IS_DEV || IS_STAGING),
      icon: 'download',
      iconProps: {
        transform: 'rotate(180deg)',
        mb: '5px',
        ...(isSmallerScreen && iconProps),
      },
      onClick: () => {
        onImport();
        onClose();
      },
    },
    {
      label: 'User',
      dataTestId: 'user',
      visible: isAdminOrDL || isClient,
      as: uoCreateUser && uoFronteggAuth ? 'button' : NavLink,
      to: uoCreateUser && uoFronteggAuth ? null : APP_ROUTES.users.create,
      onClick:
        uoCreateUser && uoFronteggAuth
          ? () => {
              onCreateUser();
              onClose();
            }
          : noop,
      display: uoCreateUser ? 'flex' : 'block',
      icon: 'users',
      ...(isSmallerScreen && { iconProps }),
    },
    {
      label: 'Client',
      dataTestId: 'client',
      visible: isAdminOrDL && !(uoCreateUser && uoFronteggAuth),
      as: NavLink,
      to: APP_ROUTES.teams.clientCreate,
      icon: 'teams',
      ...(isSmallerScreen && { iconProps }),
    },
    {
      label: 'Delivery Partner',
      dataTestId: 'deliveryPartner',
      visible: isAdminOrDL && !(uoCreateUser && uoFronteggAuth),
      as: NavLink,
      to: APP_ROUTES.teams.supplierCreate,
      icon: 'teams',
      ...(isSmallerScreen && { iconProps }),
    },
    {
      label: 'Team Member',
      dataTestId: 'teamMember',
      visible: isTeamAdmin && !(uoCreateUser && uoFronteggAuth),
      as: NavLink,
      to: APP_ROUTES.users.developerCreate,
      icon: 'teams',
      ...(isSmallerScreen && { iconProps }),
    },
  ];

  return some(popoverOptions, 'visible') ? (
    <Popover
      trigger={
        <Box py="4px">
          {isSmallerScreen ? (
            <Button
              width="56px"
              height="56px"
              iconButton={{ name: 'add' }}
              data-test-id="createButton"
              {...buttonProps}
            />
          ) : (
            <Button
              data-test-id="createButton"
              leftIcon={{ name: 'add' }}
              {...buttonProps}
            >
              {label}
            </Button>
          )}
        </Box>
      }
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      {...popoverProps}
    >
      <PopoverList options={popoverOptions} />
    </Popover>
  ) : null;
};

export default inject('appStore', 'usersStore')(observer(CreateButton));
