/* eslint-disable max-len */
import { Flex, Image } from '@chakra-ui/react';
import { APP_ROUTES } from '@routes';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { inject } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router';

const Typo404 = (
  <svg
    width="284"
    height="122"
    viewBox="0 0 284 122"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.0134 95.768H0.337402V75.104L47.7334 2.94402H74.6294V75.104H89.5534V95.768H74.6294V119.056H51.0134V95.768ZM51.0134 35.088H50.6854L24.9374 75.104H51.0134V35.088Z"
      fill="#F7CDCD"
    />
    <path
      d="M98.8681 61C98.8681 54.112 99.4694 47.1147 100.672 40.008C101.984 32.9013 104.225 26.4507 107.396 20.656C110.676 14.8613 115.104 10.16 120.68 6.55201C126.256 2.83468 133.363 0.976013 142 0.976013C150.637 0.976013 157.744 2.83468 163.32 6.55201C168.896 10.16 173.269 14.8613 176.44 20.656C179.72 26.4507 181.961 32.9013 183.164 40.008C184.476 47.1147 185.132 54.112 185.132 61C185.132 67.888 184.476 74.8854 183.164 81.992C181.961 89.0987 179.72 95.5493 176.44 101.344C173.269 107.139 168.896 111.895 163.32 115.612C157.744 119.22 150.637 121.024 142 121.024C133.363 121.024 126.256 119.22 120.68 115.612C115.104 111.895 110.676 107.139 107.396 101.344C104.225 95.5493 101.984 89.0987 100.672 81.992C99.4694 74.8854 98.8681 67.888 98.8681 61ZM123.468 61C123.468 64.0614 123.577 67.724 123.796 71.988C124.124 76.252 124.889 80.352 126.092 84.288C127.295 88.224 129.099 91.5587 131.504 94.292C134.019 97.0253 137.517 98.392 142 98.392C146.373 98.392 149.817 97.0253 152.332 94.292C154.847 91.5587 156.705 88.224 157.908 84.288C159.111 80.352 159.821 76.252 160.04 71.988C160.368 67.724 160.532 64.0614 160.532 61C160.532 57.9387 160.368 54.276 160.04 50.012C159.821 45.748 159.111 41.648 157.908 37.712C156.705 33.776 154.847 30.4413 152.332 27.708C149.817 24.9747 146.373 23.608 142 23.608C137.517 23.608 134.019 24.9747 131.504 27.708C129.099 30.4413 127.295 33.776 126.092 37.712C124.889 41.648 124.124 45.748 123.796 50.012C123.577 54.276 123.468 57.9387 123.468 61Z"
      fill="#F7CDCD"
    />
    <path
      d="M245.123 95.768H194.447V75.104L241.843 2.94402H268.739V75.104H283.663V95.768H268.739V119.056H245.123V95.768ZM245.123 35.088H244.795L219.047 75.104H245.123V35.088Z"
      fill="#F7CDCD"
    />
  </svg>
);
const Cloud = props => (
  <Image
    h="auto"
    src="/images/cloud.svg"
    alt="..."
    position="fixed"
    ignoreFallback
    w={['120px', '250px', '320px', '450px', '770px']}
    {...props}
  />
);

const Page404 = ({ appStore: { setIs404 } }) => {
  const history = useHistory();
  return (
    <Flex bg="#fff" w="100vw" minH="100vh">
      <Cloud left="35%" />
      <Cloud transform="rotate(180deg)" bottom="0px" right="-240px" />
      <Cloud transform="rotate(180deg)" bottom="0px" left="-270px" />
      <Flex
        w="50%"
        minH="100vh"
        p={['30px', '40px', '50px', '60px', '117px 20px 48px 128px']}
        flexDir="column"
        zIndex={10}
      >
        <Image
          maxW="312px"
          ml="16px"
          w="100%"
          h="auto"
          src="/images/deazy-wordmark-black.svg"
          alt="DEAZY"
          mb="120px"
          ignoreFallback
        />
        <Image
          maxW="820px"
          w="100%"
          h="auto"
          src="/images/404icon.svg"
          alt="Looking..."
          ignoreFallback
        />
      </Flex>
      <Flex w="50%" justifyContent="center" zIndex={10} p="16px">
        <Flex
          flexDir="column"
          maxW="474px"
          w="100%"
          mt="40%"
          alignItems="center"
        >
          {Typo404}
          <Typography variant="h2" mb="24px" pt="32px">
            Ooops! Something went wrong!
          </Typography>
          <Button
            w="100%"
            onClick={() => {
              history.push(APP_ROUTES.projects);
              setIs404(false);
            }}
          >
            Back to home
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default inject('appStore')(Page404);
