import { API } from '@app/api';
import { Box, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { isEmpty, map, find } from 'lodash-es';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { inject, observer } from 'mobx-react';
import Menu from '@components/Menu';
import { ASSIGNMENT_STATUSES } from '@app/constants';
import JsxParser from 'react-jsx-parser';
import useQuery from '@hooks/useQuery';
import MultiSelect from '@components/forms/MultiSelect';
import SupplierNoteModal from './SupplierNoteModal';
import ReminderConfirmationModal from './ReminderConfirmationModal';

const SupplierAssignmentForm = ({
  isMobile,
  briefAssignmentsStore: {
    assignAllSuppliers,
    remindSuppliers,
    shortlistSuppliers,
    decoratedAssignments: assignments,
    assignments: { isFetched, isLoading: isLoadingAssignments },
    reminders,
  },
  briefsStore: { brief },
  mainNameQA,
}) => {
  const { data, isLoading } = useQuery(API.searchSuppliers, {}, true);
  const options = React.useMemo(
    () =>
      map(data, item => ({
        supplier: item,
        value: item.id,
        label: item.name,
      })),
    [data],
  );

  const isNotAbleToRemindAll = React.useMemo(() => {
    return (
      isEmpty(assignments) ||
      Object.keys(reminders).length === assignments.length ||
      !find(
        assignments,
        a =>
          a.status !== ASSIGNMENT_STATUSES.AWAITING_RESPONSE ||
          a.status !== ASSIGNMENT_STATUSES.SHOWN_INTEREST,
      ) ||
      !!find(assignments, a => a.status === ASSIGNMENT_STATUSES.EXCLUSIVE)
    );
  }, [assignments, reminders]);

  const isReadOnly =
    brief.isClosed || !(isFetched && !isLoadingAssignments) || !isFetched;

  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  const [assignAllValues, setAssignAllValues] = React.useState({});

  const {
    isOpen: isReminderOpen,
    onClose: onReminderClose,
    onOpen: onReminderOpen,
  } = useDisclosure();

  const onSubmitAll = values =>
    assignAllSuppliers({
      briefId: brief.id,
      values: values.message,
      successCb: () => setAssignAllValues({}),
    });

  const menuItems = [
    {
      iconColor: 'blue.solid',
      label: 'Remind all delivery partners',
      icon: 'clock3',
      isDisabled: isNotAbleToRemindAll,

      onClick: onReminderOpen,
    },
    {
      iconColor: 'blue.solid',
      label: (
        <JsxParser
          jsx={`Assign <Typography as="span" fontWeight="600">all</Typography> delivery partners`}
          renderInWrapper={false}
          components={{
            Typography,
          }}
        />
      ),
      icon: 'target',
      onClick: () => setAssignAllValues({ all: true }),
    },
  ];

  return (
    <>
      <Form
        onSubmit={shortlistSuppliers}
        render={({
          handleSubmit,
          submitSucceeded,
          values: formValues,
          submitting,
        }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Box w="100%">
                <Stack
                  w="100%"
                  display="flex"
                  alignItems="center"
                  spacing={isSmallerScreen ? 3 : 6}
                  direction={isSmallerScreen ? 'column' : 'row'}
                >
                  <Field
                    key={submitSucceeded ? 'succeded' : 'not-succeded'}
                    component={MultiSelect}
                    styleProps={{ maxW: isSmallerScreen ? '100%' : '600px' }}
                    name="suppliers"
                    showAllSelectedOptions
                    data-test-id={`${mainNameQA}--supplier`}
                    isDisabled={isReadOnly || isLoading}
                    placeholder={
                      isMobile
                        ? 'Select delivery partners'
                        : 'Select delivery partners you’d like to shortlist'
                    }
                    options={options}
                  />
                  <Flex w="100%" alignItems="center">
                    <Button
                      {...(isMobile && {
                        w: 'calc(100% - 64px)',
                      })}
                      leftIcon={{
                        name: 'add',
                        mr: '0',
                        fontSize: 10,
                      }}
                      size="lg"
                      pr="24px"
                      pl="32px"
                      mr="auto !important"
                      isDisabled={isEmpty(formValues.suppliers)}
                      type="submit"
                      isLoading={submitting}
                    >
                      Shortlist
                    </Button>
                    <Menu menuItems={menuItems} />
                  </Flex>
                </Stack>
              </Box>
            </form>
          );
        }}
      />
      <SupplierNoteModal
        onSubmit={onSubmitAll}
        initialValues={assignAllValues}
        isSingleSupplier={false}
        isOpen={!isEmpty(assignAllValues)}
        onClose={() => setAssignAllValues({})}
        mainNameQA={mainNameQA}
      />
      <ReminderConfirmationModal
        isLoading={brief.remindingSupplier && isEmpty(brief.remindingSupplier)}
        onRemind={() => remindSuppliers({}, onReminderClose)}
        onClose={onReminderClose}
        isOpen={isReminderOpen}
      />
    </>
  );
};

export default inject(
  'briefsStore',
  'briefAssignmentsStore',
)(observer(SupplierAssignmentForm));
