import { Box, Stack } from '@chakra-ui/react';
import AsyncAutocomplete from '@components/forms/_common/AsyncAutocomplete';
import Textarea from '@components/forms/_common/Textarea';
import Button from '@styles/Button';
import { mustBeShorterOrEqual, required } from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { API } from '../../app/api';

const AddSupplierToBlacklistForm = ({ onClose, onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting }) => {
      return (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Box>
            <Field
              name="supplier"
              label="Choose a Delivery Partner"
              placeholder="Choose a Delivery Partner"
              component={AsyncAutocomplete}
              styleProps={{ mb: '38px' }}
              getOptionsFn={API.searchSuppliers}
              customOptionMap={option => ({
                value: option,
                label: option.name,
              })}
              validate={required}
            />
          </Box>
          <Box>
            <Field
              component={Textarea}
              rows={6}
              name="reason"
              parse={value => (value === null ? '' : value)}
              placeholder="Write reason here"
              validate={mustBeShorterOrEqual(500)}
            />
          </Box>
          <Stack mt="40px" spacing={3}>
            <Button isLoading={submitting} type="submit">
              Add
            </Button>
            <Button variant="outline" isDisabled={submitting} onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </form>
      );
    }}
  />
);

export default AddSupplierToBlacklistForm;
