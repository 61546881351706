import React from 'react';
import { RICH_TEXT_STYLES } from '@styles/globalStyles';
import { FONTS } from '@styles/theme';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/plugins/link';
import 'tinymce/themes/silver';
import 'tinymce/plugins/image';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/table';
import 'tinymce/plugins/noneditable';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/nonbreaking';
import { Editor } from '@tinymce/tinymce-react';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import styled from '@emotion/styled';
import FormLabel from '@components/forms/FormLabel';
import { Flex, FormControl } from '@chakra-ui/react';
import FormError from './_common/FormError';

const StyledEditor = styled(({ isResponsive, ...props }) => (
  <Flex {...props} />
))`
  ${({ isResponsive }) =>
    isResponsive &&
    `
  .tox-tinymce {
    max-width: 1268px;
    @media (max-width: 1366px) {
      width: 75vw !important;
    }
    @media (max-width: 1199px) {
      width: 70vw !important;
    }
  }
  `}
`;

function RichTextEditorInput({
  containerProps,
  meta,
  label,
  isFormField,
  height = 500,
  width = 'auto',
  isBlockDisplay,
  isErrorAbsolute,
  errorStyle,
  isEditorForUser,
  input = {},
  disabled = false,
  initProps,
  isResponsive,
  bodyPadding = '40px',
  initialValue = '',
  ...props
}) {
  const hasError = isFormField
    ? (meta.touched || meta.submitFailed) && (meta.error || meta.submitError)
    : undefined;
  const mainToolbar =
    'undo redo | formatselect | bold italic backcolor | ' +
    'alignleft aligncenter alignright alignjustify | ' +
    'bullist numlist outdent indent | removeformat | table';

  const toolbarForUser =
    'undo redo | formatselect | bold italic backcolor | ' +
    'alignleft aligncenter alignright alignjustify | ' +
    'bullist numlist outdent indent | removeformat';

  return (
    <FormControl
      w="100%"
      {...containerProps}
      position={isErrorAbsolute && 'relative'}
    >
      <FormLabel label={label} name={input.name} />
      <StyledEditor
        justify="center"
        background="transparent"
        border="1px solid"
        width="fit-content"
        mx="auto"
        isResponsive={isResponsive}
        borderColor={hasError ? 'red.solid' : 'dark.mid'}
        {...(isBlockDisplay && {
          display: 'block',
          width: 'auto',
        })}
        {...props}
      >
        <Editor
          onEditorChange={value => {
            input.onChange(value);
          }}
          onFocus={e => input.onFocus && input.onFocus(e)}
          onBlur={e => input.onBlur && input.onBlur(e)}
          disabled={disabled}
          initialValue={initialValue}
          init={{
            skin: false,
            branding: false,
            height,
            width,
            menubar: false,
            contextmenu: 'link image imagetools lists table',
            plugins: [
              'link image lists',
              'table paste nonbreaking',
              'noneditable',
            ],
            toolbar: isEditorForUser ? toolbarForUser : mainToolbar,
            table_toolbar:
              'tableprops tabledelete | tableinsertrowbefore ' +
              'tableinsertrowafter tabledeleterow | tableinsertcolbefore ' +
              'tableinsertcolafter tabledeletecol',
            nonbreaking_force_tab: true,
            content_style: `
        body {
            padding: ${bodyPadding};
            font-family: ${FONTS.WS};
          }
        li {
            margin-bottom: 10px;
            margin-top: 10px;
          }

        ol li {
            display: block;
        }

        ol.start {
          counter-reset: mainCounter;
        }
        ol.start li, ol.continue li {
            list-style: none;
        }
        ol.start li:before, ol.continue li:before {
            content: counter(mainCounter) ". ";
            counter-increment: mainCounter;
        }

        ol.nested-1 {
          counter-reset: nestedCounter-1;
        }

        ol.nested-1 li:before {
          counter-increment: nestedCounter-1;
          content: counter(mainCounter) "." counter(nestedCounter-1) " ";
        }

        ol.nested-2 {
          counter-reset: nestedCounter-2;
        }

        ol.nested-2 li:before {
          counter-increment: nestedCounter-2;
          content:  counter(mainCounter) "."
                    counter(nestedCounter-1) "."
                    counter(nestedCounter-2) " ";
        }

        ol.nested-3 {
          counter-reset: nestedCounter-3;
        }

        ol.nested-3 li:before {
          counter-increment: nestedCounter-3;
          content:  counter(mainCounter) "."
                    counter(nestedCounter-1) "."
                    counter(nestedCounter-2) "."
                    counter(nestedCounter-3);
        }

          ul.resetDisplayList li {list-style: disc; display: list-item;}
          ul.resetDisplayList li::before {content: none;}


          ${RICH_TEXT_STYLES}
        `,
            ...initProps,
          }}
          {...props}
        />
      </StyledEditor>
      {isFormField && (
        <FormError
          meta={meta}
          isErrorAbsolute={isErrorAbsolute}
          style={errorStyle}
        />
      )}
    </FormControl>
  );
}

export default RichTextEditorInput;
