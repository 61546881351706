import { NEW_ROLES } from '@app/constants';

export const addFullDescription = value => {
  if (value === NEW_ROLES.ADMIN) {
    return 'Administrator';
  }
  if (value === NEW_ROLES.TEAM_MEMBER) {
    return 'Team Member';
  }
  if (value === NEW_ROLES.DEAZY_LEAD) {
    return 'Deazy Lead';
  }
  if (value === NEW_ROLES.CLIENT_LEAD) {
    return 'Client Lead';
  }
  if (value === NEW_ROLES.TEAM_ADMIN) {
    return 'Team Admin';
  }
  return '-';
};

export const addToCurrentGroup = value => value.substring(1).replace(/-/g, ' ');
