import { Flex } from '@chakra-ui/react';
import DatePicker from '@components/forms/DatePicker';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import { required } from '@utils/formValidators';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Field, Form } from 'react-final-form';
import AutoSave from './AutoSave';

const ProposalTimings = ({
  initialValues,
  isReadOnly,
  onSubmit = () => {},
  setSubmit = () => {},
  mainNameQA,
  isSmallerScreen,
}) => {
  return (
    <WhiteCard
      mt={0}
      mb="24px"
      py={isSmallerScreen ? '16px' : '40px'}
      px={isSmallerScreen ? '12px' : '32px'}
      boxShadow="0 0 0 0 rgba(0, 0, 0, 0)"
      border="solid 1px"
      borderColor="dark.mid"
    >
      <Typography variant="h2" mb={isSmallerScreen ? '12px' : '32px'}>
        Date & Time
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form }) => {
          setSubmit(handleSubmit);
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <AutoSave ignoreErrors onSave={onSubmit} formApi={form} />
              <Flex flexWrap="wrap" flexFlow={isSmallerScreen && 'column'}>
                <Field
                  component={DatePicker}
                  containerProps={{
                    w: '100%',
                    maxW: !isSmallerScreen && '288px',
                    mr: '24px',
                    mb: '24px',
                    zIndex: 10,
                  }}
                  name="earliestStartDate"
                  label="Earliest start date"
                  data-test-id={`${mainNameQA}--earliestStartDate`}
                  placeholder="Select start date"
                  isDisabled={isReadOnly}
                  validate={required}
                  disabledDaysFn={day => moment.utc(day).isBefore(moment.utc())}
                />
                <Field
                  decimalScale={0}
                  fixedDecimalScale
                  name="deliveryTime"
                  data-test-id={`${mainNameQA}--deliveryTime`}
                  label="Total delivery time in weeks"
                  placeholder="Type total weeks"
                  isDisabled={isReadOnly}
                  containerProps={{
                    mr: '24px',
                    maxW: !isSmallerScreen && '288px',
                    mb: '24px',
                  }}
                  maxW={!isSmallerScreen && '288px'}
                  inputBoxAutoHeight
                  component={MaskedTextInput}
                  validate={required}
                  isAllowed={({ floatValue }) =>
                    ((floatValue <= 10000000 && floatValue > 0) ||
                      !floatValue) &&
                    floatValue !== 0
                  }
                />
                <Field
                  component={DatePicker}
                  containerProps={{
                    w: '100%',
                    maxW: !isSmallerScreen && '288px',
                    mb: '24px',
                  }}
                  calendarColor="dark.mid"
                  name="estimatedDeliveryDate"
                  data-test-id={`${mainNameQA}--estimatedDeliveryDate`}
                  bg="#F9F9F9"
                  label="Estimated delivery date"
                  placeholder="Auto calc"
                  isDisabled
                />
              </Flex>
            </form>
          );
        }}
      />
    </WhiteCard>
  );
};

export default inject('proposalsStore')(observer(ProposalTimings));
