import useAutoTableRows from '@hooks/useAutoTableRows';
import { map } from 'lodash-es';
import { ENGAGEMENT_TYPES } from '@app/constants';
import React from 'react';
import PaymentRow from './PaymentScheduleTableRow';
import { TableHead } from './components';

const PaymentScheduleTable = ({
  paymentScheduleItems,
  maxValue,
  engagementType,
  currency,
  formApi,
  isReadOnly,
}) => {
  const { onDelete, onAdd } = useAutoTableRows(
    'paymentScheduleItems',
    null,
    { paymentScheduleItems },
    formApi,
  );

  const isProjectType = engagementType === ENGAGEMENT_TYPES.PROJECT_BUILD;

  return (
    <>
      <TableHead isProjectType={isProjectType} />
      {map(paymentScheduleItems, (p, idx) => {
        if (
          (idx + 1 === paymentScheduleItems.length && !isProjectType) ||
          (isReadOnly && idx + 1 === paymentScheduleItems.length)
        ) {
          return null;
        }
        return (
          <PaymentRow
            isReadOnly={isReadOnly}
            maxValue={maxValue}
            key={`payment-split-${p.rowId}`}
            canBeDeleted={idx + 1 < (paymentScheduleItems || []).length}
            onDelete={() => onDelete(p.rowId)}
            onAdd={onAdd}
            formApi={formApi}
            payment={p}
            idx={idx}
            engagementType={engagementType}
            currency={currency}
          />
        );
      })}
    </>
  );
};

export default PaymentScheduleTable;
