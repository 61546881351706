import {
  ROLE_TYPES,
  ROLE_TYPES_OPTIONS,
  SENIORITY_OPTIONS,
  SENIORITY_TYPES,
} from '@app/constants';
import { Box, Flex, List, ListItem } from '@chakra-ui/react';
import ActionButton from '@components/AllocationTable/ActionButton';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import Select from '@components/forms/Select';
import TextInput from '@components/forms/TextInput';
import Button from '@styles/Button';
import Icon from '@components/Icon';
import { INPUT_VARIANTS } from '@styles/theme';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import Typography from '@styles/Typography';
import {
  filter,
  isBoolean,
  isEmpty,
  keys,
  last,
  map,
  omit,
  reject,
} from 'lodash-es';
import { nanoid } from 'nanoid';
import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import ResourceRateIndicator from './ResourceRateIndicator';

const TableHead = () => (
  <Flex w="100%" mb="12px" mt="24px" color="dark.solid">
    <Typography ml="40px" w="50%" variant="caption">
      Role name
    </Typography>
    <Typography ml="40px" w="50%" variant="caption">
      Seniority
    </Typography>
    <Typography w="108px" variant="caption">
      Rate
    </Typography>
  </Flex>
);

const RoleRowDesktop = ({
  idx,
  isOpen,
  setIsOpen,
  onDeleteRow,
  isReadOnly,
  onAddRow,
  canBeDeleted,
  role,
  formApi,
  mainNameQA,
  ...props
}) => {
  const { name, isRateOk } = role;
  const showCommentBox = isRateOk === false;

  return (
    <>
      <Box w="100%" position="relative" {...props}>
        <ActionButton
          deleteRow={onDeleteRow}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          disallowRowChange={isReadOnly}
          canBeDeleted={canBeDeleted}
          addRow={onAddRow}
          mainNameQA={mainNameQA}
        />
        <Flex
          w="100%"
          h="72px"
          mb="12px"
          bg="#fff"
          pl="40px"
          opacity={isOpen ? 0.5 : 1}
          border="1px solid"
          borderColor="dark.mid"
          borderRight="none"
          borderTopLeftRadius="8px"
          borderTopRightRadius="8px"
          borderBottomLeftRadius="8px"
          borderBottomRightRadius={showCommentBox ? '0px' : '8px'}
        >
          <Box
            alignSelf="center"
            w="50%"
            h="70px"
            borderRight="1px solid"
            borderColor="dark.mid"
          >
            <Field
              name={`roles[${idx}].name`}
              data-test-id={`${mainNameQA}--roles[${idx}].name`}
              variant={INPUT_VARIANTS.CONTAINER_FILLED}
              placeholder="Select Role"
              isDisabled={isReadOnly}
              options={ROLE_TYPES_OPTIONS}
              component={Select}
            />
          </Box>
          <Box
            alignSelf="center"
            w="50%"
            h="70px"
            borderRight="1px solid"
            borderColor="dark.mid"
          >
            <Field
              name={`roles[${idx}].seniority`}
              data-test-id={`${mainNameQA}--roles[${idx}].seniority`}
              variant={INPUT_VARIANTS.CONTAINER_FILLED}
              placeholder="Select Seniority"
              isDisabled={isReadOnly}
              key={`key=by-${name}`}
              options={
                name !== ROLE_TYPES.DEV
                  ? filter(
                      SENIORITY_OPTIONS,
                      opt =>
                        opt.value !== SENIORITY_TYPES.TechLead &&
                        opt.value !== SENIORITY_TYPES.Architect,
                    )
                  : SENIORITY_OPTIONS
              }
              component={Select}
            />
          </Box>
          <Box
            alignSelf="center"
            w="108px"
            h="70px"
            borderRight="1px solid"
            borderColor="dark.mid"
            borderTopRightRadius="8px"
            borderBottomRightRadius={showCommentBox ? '0px' : '8px'}
            position="relative"
          >
            <Field
              name={`roles[${idx}].rate`}
              isDisabled={isReadOnly}
              data-test-id={`${mainNameQA}--roles[${idx}].rate`}
              variant={INPUT_VARIANTS.CONTAINER_FILLED}
              component={MaskedTextInput}
              decimalScale={0}
              fixedDecimalScale
              borderTopRightRadius="8px"
              borderBottomRightRadius="8px"
              pr="32px"
              placeholder="0"
            />
            <ResourceRateIndicator
              formApi={formApi}
              position="absolute"
              zIndex={30}
              top="34%"
              right="6px"
              prefix={`roles[${idx}]`}
            />
          </Box>
        </Flex>
      </Box>
      {showCommentBox && (
        <Box
          w="calc(100% - 39px)"
          mt="-12px"
          mb="12px"
          h="48px"
          border="1px solid #d9dada"
          borderTop="none"
          borderBottomLeftRadius="8px"
          position="relative"
          left="39px"
          borderBottomRightRadius="8px"
        >
          <Field
            name={`roles[${idx}].comment`}
            data-test-id={`${mainNameQA}--roles[${idx}].comment`}
            isDisabled={isReadOnly}
            variant={INPUT_VARIANTS.CONTAINER_FILLED}
            component={TextInput}
            pt="12px"
            pb="12px"
            placeholder="Add a comment if required. Include why you have exceeded your agreed high rate."
            borderBottomRightRadius="8px"
            borderBottomLeftRadius="8px"
          />
        </Box>
      )}
    </>
  );
};

const RoleRowMobile = ({
  mainNameQA,
  idx,
  isReadOnly,
  onDeleteRow,
  formApi,
  role,
  ...rest
}) => {
  const { name } = role;
  return (
    <Box w="100%" position="relative" mb="24px" {...rest}>
      <Flex
        backgroundColor="primary.200"
        justifyContent="space-between"
        px="12px"
        py="8px"
        borderTopLeftRadius="8px"
        borderTopRightRadius="8px"
      >
        <Typography variant="h5" mt="8px">
          Role
        </Typography>
        {!isReadOnly && (
          <Button
            onClick={onDeleteRow}
            background="#fff"
            color="black"
            width="40px"
            iconButton={{
              name: 'trashNew',
            }}
          />
        )}
      </Flex>
      <Flex
        bgColor="primary.100"
        flexDirection="column"
        p="12px"
        borderBottomLeftRadius="8px"
        borderBottomRightRadius="8px"
      >
        <Box mb="12px">
          <Field
            name={`roles[${idx}].name`}
            data-test-id={`${mainNameQA}--roles[${idx}].name`}
            placeholder="Select Role"
            isDisabled={isReadOnly}
            options={ROLE_TYPES_OPTIONS}
            component={Select}
          />
        </Box>
        <Box mb="12px">
          <Field
            name={`roles[${idx}].seniority`}
            data-test-id={`${mainNameQA}--roles[${idx}].seniority`}
            placeholder="Select Seniority"
            isDisabled={isReadOnly}
            key={`key=by-${name}`}
            options={
              name !== ROLE_TYPES.DEV
                ? filter(
                    SENIORITY_OPTIONS,
                    opt =>
                      opt.value !== SENIORITY_TYPES.TechLead &&
                      opt.value !== SENIORITY_TYPES.Architect,
                  )
                : SENIORITY_OPTIONS
            }
            component={Select}
          />
        </Box>
        <Box
          w="100%"
          h="48px"
          position="relative"
          border="1px solid"
          borderRadius="8px"
          borderColor="dark.mid"
          mb="12px"
        >
          <Field
            name={`roles[${idx}].rate`}
            isDisabled={isReadOnly}
            data-test-id={`${mainNameQA}--roles[${idx}].rate`}
            variant={INPUT_VARIANTS.CONTAINER_FILLED}
            component={MaskedTextInput}
            decimalScale={0}
            borderRadius="8px"
            placeholder="Rate"
            fixedDecimalScale
            borderTopRightRadius="8px"
            borderBottomRightRadius="8px"
          />
          <ResourceRateIndicator
            formApi={formApi}
            position="absolute"
            top="34%"
            right="6px"
            prefix={`roles[${idx}]`}
          />
        </Box>
      </Flex>
    </Box>
  );
};

const CoreTeamRolesTable = ({ deleteRole, isReadOnly, mainNameQA }) => {
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  const isSmallerScreenRef = React.useRef(isSmallerScreen);

  React.useEffect(() => {
    if (
      isSmallerScreenRef.current !== isSmallerScreen &&
      isBoolean(isSmallerScreenRef.current)
    ) {
      window.location.reload();
    }
    isSmallerScreenRef.current = isSmallerScreen;
  }, [isSmallerScreen]);

  return (
    <FormSpy subscription={{ values: true, errors: true, touched: true }}>
      {({ values: { roles = [] }, errors = {}, touched, form }) => {
        if (!isReadOnly && isBoolean(isSmallerScreen)) {
          if (isEmpty(roles)) {
            form.change('roles', [{ rowId: nanoid(10) }]);
          } else if (!isSmallerScreen && !isEmpty(omit(last(roles), 'rowId'))) {
            form.change('roles', [...roles, { rowId: nanoid(10) }]);
          }
        }
        const onDeleteRow = (id, rowId) => {
          const oldRoles = roles;
          form.change('roles', reject(roles, { rowId }));
          if (id) {
            deleteRole(id, roles, () => {
              form.change('roles', oldRoles);
            });
          }
        };
        const onAddRow = () => {
          form.change('roles', [...roles, { rowId: nanoid(10) }]);
        };

        return (
          <>
            {!isEmpty(roles) && !isSmallerScreen && <TableHead />}
            {map(roles, (role, idx) => (
              <React.Fragment key={`core-role-row-${role.rowId}`}>
                {isSmallerScreen ? (
                  <RoleRowMobile
                    mainNameQA={mainNameQA}
                    data-test-id={`${mainNameQA}--coreRoleRow`}
                    idx={idx}
                    role={role}
                    formApi={form}
                    isReadOnly={isReadOnly}
                    onDeleteRow={() =>
                      !isReadOnly && onDeleteRow(role.id, role.rowId)
                    }
                  />
                ) : (
                  <RoleRowDesktop
                    isOpen={role.isOpen}
                    setIsOpen={isOpen =>
                      form.change(`roles[${idx}].isOpen`, isOpen)
                    }
                    role={role}
                    isReadOnly={isReadOnly}
                    idx={idx}
                    onDeleteRow={() =>
                      !isReadOnly && onDeleteRow(role.id, role.rowId)
                    }
                    onAddRow={!isReadOnly && onAddRow}
                    canBeDeleted={idx !== roles.length - 1 && !isReadOnly}
                    formApi={form}
                    mainNameQA={mainNameQA}
                    data-test-id={`${mainNameQA}--coreRoleRow`}
                  />
                )}
                {!isEmpty(errors.coreRoles && errors.coreRoles[idx]) && (
                  <List ml="24px">
                    {map(keys(errors.coreRoles[idx]), (key, i) =>
                      touched[`roles[${idx}].${key}`] ? (
                        <ListItem
                          className="final-form-error"
                          color="red.500"
                          mb="12px"
                          key={`core-role-error-${idx}-${i}`}
                        >
                          <Icon
                            name="warning"
                            color="red.500"
                            mb="2px"
                            mr="5px"
                          />
                          {errors.coreRoles[idx][key]}
                        </ListItem>
                      ) : null,
                    )}
                  </List>
                )}
              </React.Fragment>
            ))}
          </>
        );
      }}
    </FormSpy>
  );
};

export default CoreTeamRolesTable;
