import FiltersFormSidebar from '@components/FiltersFormSidebar';
import React from 'react';

const SORT_OPTIONS = [
  {
    label: 'Creation date (new to old)',
    value: 'orderDirection=desc&orderBy=createdAt',
  },
  {
    label: 'Creation date (old to new)',
    value: 'orderDirection=asc&orderBy=createdAt',
  },
  {
    label: 'Order by client name',
    value: 'orderDirection=asc&orderBy=clientName',
  },
  {
    label: 'Due date',
    value: 'orderDirection=asc&orderBy=dueDate',
  },
];

const STATUSES = [
  {
    label: 'Open',
    value: 'Open',
  },
  {
    label: 'Re-assigned',
    value: 'Reassigned',
  },
  {
    label: 'Completed',
    value: 'Completed',
  },
];

const TasksFilters = ({ onSubmit, initialValues, isLoading }) => {
  return (
    <FiltersFormSidebar
      onSubmit={onSubmit}
      initialValues={initialValues}
      sortOptions={SORT_OPTIONS}
      statusOptions={STATUSES}
      fieldsToShow={['taskCategory']}
      loading={isLoading}
    />
  );
};

export default TasksFilters;
