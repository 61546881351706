import { Box } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { isEmpty, map, times } from 'lodash-es';
import React from 'react';
import { Facebook as TeamLoader } from 'react-content-loader';
import TeamCard from './TeamCard';
import TeamCardContent from './TeamCardContent';

const TeamsList = ({ teams, isLoading, isSupplierRoute, fetchTeamsFn }) => {
  if (isLoading) {
    return map(times(8), (t, idx) => (
      <Box mb="16px" w="100%" key={`loader-${idx}`} maxW="720px">
        <TeamLoader height="240px" width="100%" />
      </Box>
    ));
  }
  if (isEmpty(teams)) {
    return (
      <Typography variant="h2" my="40px">
        {`${!isSupplierRoute ? 'Client' : 'Delivery Partner'} list is empty.`}
      </Typography>
    );
  }
  return map(teams, team => (
    <TeamCard
      key={`team-card-${team.id}`}
      isSupplierRoute={isSupplierRoute}
      teamId={team.id}
    >
      <TeamCardContent
        team={team}
        isStatic
        isSupplierRoute={isSupplierRoute}
        fetchTeamsFn={fetchTeamsFn}
      />
    </TeamCard>
  ));
};

export default TeamsList;
