import {
  filter,
  find,
  isEmpty,
  isEqual,
  map,
  reject,
  toNumber,
  pick,
  omit,
  cloneDeep,
} from 'lodash-es';
import moment from 'moment';
import { action, makeObservable, observable } from 'mobx';
import { MILESTONE_STAGE } from '@app/constants';
import { nanoid } from 'nanoid';

export default class MilestonesStore {
  constructor({ API, toastsStore, routerStore, briefsStore, usersStore }) {
    makeObservable(this);
    this.API = API;
    this.toastsStore = toastsStore;
    this.routerStore = routerStore;
    this.briefsStore = briefsStore;
    this.usersStore = usersStore;
  }

  @observable upfrontMilestones = [];

  @observable deliveryMilestones = [];

  @observable uatMilestones = [];

  @observable isLoadingMilestones = false;

  @observable fetchedMilestones = false;

  @observable updatedMilestone = {};

  @observable reportStatus = null;

  @observable riskLevel = null;

  @observable lastModifiedDate = null;

  @observable projectStatusFromReport = null;

  @action fetchReport = async projectId => {
    this.isLoadingMilestones = true;
    this.fetchedMilestones = false;

    try {
      const {
        data: { status, milestones, riskLevel, project },
      } = await this.API.getMilestoneReport(projectId);
      this.reportStatus = status;
      this.riskLevel = riskLevel;

      this.projectStatusFromReport =
        project.overriddenProjectStatus || project.calculatedProjectStatus;

      const milestonesDecoratedWithRowId = map(milestones, m => {
        return { ...m, rowId: nanoid(10) };
      });

      // initialize with empty row so the view doesnt blink
      this.upfrontMilestones = [
        ...milestonesDecoratedWithRowId.filter(
          m => m.stage === MILESTONE_STAGE.UPFRONT,
        ),
        { rowId: nanoid(10), stage: MILESTONE_STAGE.UPFRONT },
      ];

      this.deliveryMilestones = [
        ...milestonesDecoratedWithRowId.filter(
          m => m.stage === MILESTONE_STAGE.DELIVERY,
        ),
        { rowId: nanoid(10), stage: MILESTONE_STAGE.DELIVERY },
      ];

      this.uatMilestones = [
        ...milestonesDecoratedWithRowId.filter(
          m => m.stage === MILESTONE_STAGE.UAT,
        ),
        { rowId: nanoid(10), stage: MILESTONE_STAGE.UAT },
      ];
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      this.isLoadingMilestones = false;
      this.fetchedMilestones = true;
    }
  };

  @action submitMilestoneReport = async ({ projectId, successCb }) => {
    const filterWithId = m => m.id;
    if (
      isEmpty(filter(this.upfrontMilestones, filterWithId)) ||
      isEmpty(filter(this.deliveryMilestones, filterWithId)) ||
      isEmpty(filter(this.uatMilestones, filterWithId))
    ) {
      return this.toastsStore.showError({
        title:
          'You must complete at least one milestone per section to proceed.',
      });
    }
    try {
      await this.API.submitMilestoneReport(projectId);
      successCb();
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    }
    return undefined;
  };

  @action approveMilestoneReport = async ({ projectId, successCb }) => {
    const filterWithId = m => m.id;
    if (
      isEmpty(filter(this.upfrontMilestones, filterWithId)) ||
      isEmpty(filter(this.deliveryMilestones, filterWithId)) ||
      isEmpty(filter(this.uatMilestones, filterWithId))
    ) {
      return this.toastsStore.showError({
        title:
          'You must complete at least one milestone per section to proceed.',
      });
    }
    try {
      await this.API.approveMilestoneReport(projectId);
      successCb();
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    }
    return undefined;
  };

  @action overrideProjectStatus = async (projectId, values, successCb) => {
    try {
      this.clearUpdatedMilestone();
      await this.API.overrideProjectStatus(projectId, values);
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    }
  };

  @action clearMilestones = () => {
    this.upfrontMilestones = [];
    this.deliveryMilestones = [];
    this.uatMilestones = [];
  };

  @action clearUpdatedMilestone = () => {
    this.updatedMilestone = {};
  };

  @observable isSubmittingMilestones = false;

  @action saveMilestonesData = async (projectId, milestonesData, stage) => {
    if (
      isEmpty(
        reject(
          map(milestonesData, m => omit(m, ['stage', 'rowId'])),
          isEmpty,
        ),
      )
    ) {
      return;
    }
    let observableKey = 'deliveryMilestones';
    if (stage === MILESTONE_STAGE.UPFRONT) {
      observableKey = 'upfrontMilestones';
    }
    if (stage === MILESTONE_STAGE.UAT) {
      observableKey = 'uatMilestones';
    }

    const fieldsToWatch = [
      'name',
      'baselineDate',
      'stage',
      'ragStatus',
      'comment',
      'plannedCompletionDate',
      'actualDate',
      'percentComplete',
    ];
    const convertToSameType = obj => ({
      ...obj,
      percentComplete: toNumber(obj.percentComplete),
    });
    const milestoneDataClone = cloneDeep(milestonesData);
    const milestonesToBeCreatedOrUpdated = filter(
      milestonesData,
      m =>
        m.name &&
        m.stage &&
        !isEqual(
          pick(convertToSameType(m), fieldsToWatch),
          pick(
            convertToSameType(
              find(this[observableKey], milestone => milestone.id === m.id) ||
                {},
            ),
            fieldsToWatch,
          ),
        ),
    );
    const checkMilestoneShouldBeDeleted = m => m.id && m.toBeDeleted;
    const checkMilestoneShouldBeDiscarded = m => !m.id && m.toBeDeleted;
    const milestonesToBeDeleted = milestoneDataClone.filter(
      checkMilestoneShouldBeDeleted,
    );

    if (
      isEmpty(milestonesToBeDeleted) &&
      isEmpty(milestonesToBeCreatedOrUpdated)
    ) {
      return;
    }

    try {
      const createOrUpdatePromises = map(
        milestonesToBeCreatedOrUpdated,
        async m => {
          const { data } = await this.API.createOrUpdateMilestone(projectId, {
            id: m.id,
            name: m.name,
            baselineDate:
              this.reportStatus === 'Accepted' && !m.id ? null : m.baselineDate,
            stage: m.stage,
            ragStatus: m.ragStatus,
            comment: m.comment,
            plannedCompletionDate: m.plannedCompletionDate,
            actualDate: m.actualDate,
            percentComplete: m.percentComplete,
          });
          this.updatedMilestone = data;
          return Promise.resolve({ ...data, rowId: m.rowId });
        },
      );

      const createOrUpdateResponses = await Promise.all(createOrUpdatePromises);

      const deletePromises = map(milestonesToBeDeleted, m =>
        this.API.deleteMilestone(projectId, m.id),
      );
      await Promise.all(deletePromises);
      let updatedMilestonesData = milestoneDataClone
        .filter(m => !checkMilestoneShouldBeDeleted(m))
        .filter(m => !checkMilestoneShouldBeDiscarded(m));

      updatedMilestonesData = map(
        updatedMilestonesData,
        ({ toBeUpdated, ...m }) => ({
          ...m,
          ...(find(
            createOrUpdateResponses,
            milestone => milestone.rowId === m.rowId,
          ) || {}),
        }),
      );
      this[observableKey] = updatedMilestonesData;

      this.lastModifiedDate = moment().format();
    } catch (e) {
      this.toastsStore.showError({
        title:
          e?.message || 'Network error while saving the project milestones',
      });
      await this.fetchReport(projectId);
    }
  };

  @action clearLastModifiedDate = () => {
    this.lastModifiedDate = null;
  };
}
