import { Flex, useDisclosure } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import ZoneContainer from '@components/ZoneContainer';
import Button from '@styles/Button';
import { inject, observer } from 'mobx-react';
import Typography from '@styles/Typography';
import { cloneDeep, compact, flatten, map } from 'lodash-es';
import qs from 'query-string';
import moment from 'moment';
import React from 'react';
import ConditionalWrapper from '@components/ConditionalWrapper';
import { useHistory } from 'react-router-dom';
import SingleCandidate from './SingleCandidate';

const RoleRow = ({
  idx,
  technology,
  role,
  onAddRole,
  candidates,
  startDate,
  isReadOnly,
  formApi,
  onSaveComment,
  onDeleteRole,
  candidateErrors,
  shouldShowCandidateError,
  isSmallerScreen,
  isEditingMode,
  mainNameQA,
  id: resourceId,
  proposalsStore: {
    initializePnpPersistedProposalFormData,
    pnpPersistedProposalFormData,
  },
  appStore: {
    featureFlags: { 'dev-profiles-in-ta-proposal': devProfilesInTAProposal },
  },
  usersStore: { profile },
}) => {
  const history = useHistory();
  const numberOfCandidates = (candidates || []).length;

  const { isOpen, onClose, onOpen } = useDisclosure(isReadOnly);

  const disabledDaysFn = day =>
    moment
      .utc(day)
      .isBefore(moment.max([startDate, moment.utc().startOf('day')]));

  const onProfileRedirect = path => {
    const formState = formApi.getState();
    initializePnpPersistedProposalFormData({
      currentResourceId: resourceId,
      currentResourceName: technology,
      ...cloneDeep(formState.values),
    });
    history.push(path);
  };

  const excludeProfileIds = compact(
    flatten(
      map(pnpPersistedProposalFormData.resources, item =>
        map(item.candidates || [], candidate => candidate?.devProfile?.id),
      ),
    ),
  );

  return (
    <WhiteCard>
      <Flex justifyContent="space-between" alignItems="center">
        <Typography variant="h4">{role || technology}</Typography>
        <Flex alignItems="center">
          {!isSmallerScreen && (
            <Typography
              {...(numberOfCandidates === 0 && { color: 'gray.mid' })}
              mr="24px"
            >{`Added ${numberOfCandidates} Candidate${
              numberOfCandidates === 1 ? '' : 's'
            }`}</Typography>
          )}

          <Button
            color="black"
            bg="primary.100"
            width="40px"
            iconButton={{ name: 'arrowLeft', fontSize: 20 }}
            transform={isOpen ? 'rotate(90deg)' : 'rotate(-90deg)'}
            onClick={!isOpen ? onOpen : onClose}
            isDisabled={isReadOnly && numberOfCandidates === 0}
            data-test-id={`${mainNameQA}--accordionButton--${idx}`}
          />
        </Flex>
      </Flex>
      {isOpen && (
        <>
          {map(candidates, ({ rowId, ...candidate }, candidateIdx) => (
            <SingleCandidate
              isSmallerScreen={isSmallerScreen}
              formApi={formApi}
              isEditingMode={isEditingMode}
              onSaveComment={onSaveComment}
              onDeleteRole={onDeleteRole}
              candidateErrors={candidateErrors}
              shouldShowCandidateError={shouldShowCandidateError}
              candidate={candidate}
              resourceIdx={idx}
              rowId={rowId}
              isReadOnly={isReadOnly}
              candidateIdx={candidateIdx}
              disabledDaysFn={disabledDaysFn}
              key={`candiadate-card-${rowId}`}
              mainNameQA={mainNameQA}
              onProfileRedirect={onProfileRedirect}
              supplierName={profile.supplier.name}
            />
          ))}
          {!isReadOnly && (
            <ConditionalWrapper
              wrapper={children => (
                <ZoneContainer
                  mt="24px"
                  alignItems="center"
                  borderColor="dark.mid"
                  justifyContent="center"
                  {...(devProfilesInTAProposal
                    ? { pt: '40px', pb: '20px' }
                    : { py: '34px' })}
                >
                  {children}
                </ZoneContainer>
              )}
              condition={!isSmallerScreen}
            >
              {devProfilesInTAProposal ? (
                <>
                  <Button
                    leftIcon={{ name: 'candidate', fontSize: 20 }}
                    w={isSmallerScreen && '100%'}
                    mt={['24px', '24px', '24px', 0]}
                    onClick={() =>
                      onProfileRedirect(
                        `dev-profiles?${qs.stringify({ excludeProfileIds })}`,
                      )
                    }
                    data-test-id={`${mainNameQA}--selectCandidateProfileButton`}
                  >
                    Select a candidate profile
                  </Button>
                  <Button
                    variant="ghost"
                    w={isSmallerScreen && '100%'}
                    mt="12px"
                    onClick={() => onAddRole(idx)}
                    data-test-id={`${mainNameQA}--addCandidateButton`}
                  >
                    Add candidate manually
                  </Button>
                </>
              ) : (
                <Button
                  variant="outline"
                  leftIcon={{ name: 'add', mr: '12px', fontSize: 14 }}
                  w={isSmallerScreen && '100%'}
                  mt={['24px', '24px', '24px', 0]}
                  onClick={() => onAddRole(idx)}
                  data-test-id={`${mainNameQA}--addCandidateButton`}
                >
                  Add a candidate
                </Button>
              )}
            </ConditionalWrapper>
          )}
        </>
      )}
    </WhiteCard>
  );
};

export default inject(
  'proposalsStore',
  'appStore',
  'usersStore',
)(observer(RoleRow));
