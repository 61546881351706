import React from 'react';
import { Field } from 'react-final-form';

const FormSpyFieldValues = ({ children, fieldNames }) =>
  fieldNames.reduce(
    (acc, fieldName) => values => (
      <Field name={fieldName} subscription={{ value: true }}>
        {({ input: { value } }) => acc({ ...values, [fieldName]: value })}
      </Field>
    ),
    children,
  )({});

export default FormSpyFieldValues;
