import { Box, Flex, Image } from '@chakra-ui/react';
import useDownload from '@hooks/useDownload';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { get } from 'lodash-es';
import moment from 'moment';
import React from 'react';

export const Stat = ({ label, value, ...props }) => (
  <Box pr="32px" {...props}>
    <Typography variant="text3" mb="8px">
      {label}
    </Typography>
    <Typography variant="text2">{value}</Typography>
  </Box>
);

const InvoiceHeader = ({
  isDownloadable,
  invoice,
  downloadLink,
  mainNameQA,
}) => {
  const { onDownload, isLoading } = useDownload({
    url: downloadLink,
  });

  const isClient = invoice.contactType === 'Client';

  const invoiceNumberingPattern = get(
    invoice,
    'supplier.invoiceNumberingPattern',
  );

  const invoiceNumber = isClient
    ? invoice.xeroNumber
    : invoice.supplierAccountingNo ||
      (invoiceNumberingPattern && invoice.periodOrdinal
        ? moment(invoice.invoiceDate)
            .utc()
            .format(
              invoiceNumberingPattern.replace(
                '#NO#',
                `[${invoice.periodOrdinal}]`,
              ),
            )
        : invoice.xeroNumber);

  const logoSrc = isClient
    ? '/images/deazy-wordmark-black.svg'
    : invoice?.supplier?.logo;

  return (
    <Flex pb="13px" borderBottom="1px solid" borderColor="dark.mid">
      {logoSrc && (
        <Image
          src={logoSrc}
          maxH="70px"
          pt="12px"
          pb="16px"
          alignSelf="center"
          pr="24px"
          data-test-id={`${mainNameQA}--logo`}
        />
      )}
      <Flex
        py="12px"
        alignItems="flex-start"
        {...(logoSrc && {
          borderLeft: '1px solid',
          borderColor: 'dark.mid',
        })}
        pl="14px"
      >
        <Stat
          label="Invoice date"
          value={moment.utc(invoice.invoiceDate).format('Do MMMM YYYY')}
          minW="120px"
          data-test-id={`${mainNameQA}--invoiceDate`}
        />
        {invoiceNumber && (
          <Stat
            label="Invoice number"
            value={invoiceNumber}
            minW="120px"
            data-test-id={`${mainNameQA}--invoiceNumber`}
          />
        )}
        <Stat
          label="Reference"
          value={invoice.reference}
          minW="120px"
          data-test-id={`${mainNameQA}--invoiceReference`}
        />
      </Flex>
      {isDownloadable && (
        <Button
          borderRadius="100%"
          ml="auto"
          width="40px"
          color="#fff"
          bg="dark.solid"
          iconButton={{ name: 'download', fontSize: 22 }}
          data-test-id={`${mainNameQA}--downloadButton`}
          cursor="pointer"
          isLoading={isLoading}
          onClick={onDownload}
        />
      )}
    </Flex>
  );
};

export default InvoiceHeader;
