import { Stack } from '@chakra-ui/react';
import TextInput from '@components/forms/TextInput';
import Button from '@styles/Button';
import { INPUT_VARIANTS } from '@styles/theme';
import {
  composeValidators,
  mustBeValidEmail,
  required,
} from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';

const ClientLeadCreateForm = ({
  showIncompleteForm = false,
  onClose,
  onSubmit,
  initialValues,
}) => (
  <Form
    onSubmit={onSubmit}
    initialValues={{ role: 'CL', ...initialValues }}
    render={({ handleSubmit, submitting }) => {
      return (
        <form onSubmit={onSubmit} autoComplete="off">
          <Stack direction="column" spacing={6}>
            {!showIncompleteForm && (
              <Field
                label="Username"
                name="username"
                placeholder="Enter your username"
                type="text"
                component={TextInput}
                variant={INPUT_VARIANTS.NORMAL}
                validate={required}
                isBordered
              />
            )}
            <Field
              label="Full name"
              name="fullname"
              placeholder="Full name"
              type="text"
              component={TextInput}
              variant={INPUT_VARIANTS.NORMAL}
              validate={required}
              isBordered
            />
            <Field
              label="Email"
              name="email"
              placeholder="Email"
              type="text"
              component={TextInput}
              variant={INPUT_VARIANTS.NORMAL}
              validate={composeValidators(required, mustBeValidEmail)}
              isBordered
            />
          </Stack>
          <Stack mt="40px" spacing={3}>
            <Button isLoading={submitting} onClick={handleSubmit}>
              Add client
            </Button>
            <Button variant="outline" isDisabled={submitting} onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </form>
      );
    }}
  />
);

export default ClientLeadCreateForm;
