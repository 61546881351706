import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Icon from '@components/Icon';
import React from 'react';
import Typography from '@styles/Typography';

const getBadgeProps = team => {
  if (!team.vatNumber) {
    return {
      label: 'no VAT number',
      icon: 'close',
      color: '#fff',
      bg: 'blue.solid',
    };
  }
  return {
    label: 'VAT taxpayer',
    icon: 'check',
    bg: 'yellow.solid',
    color: 'black',
  };
};

const Wrapper = styled(Flex)`
  position: relative;
  top: -1px;
  right: -1px;
`;

const TeamCardBadge = ({ team }) => {
  const { label, icon, bg, color = 'white' } = getBadgeProps(team);
  return (
    <Wrapper
      borderTopRightRadius="8px"
      borderBottomLeftRadius="8px"
      bg={bg}
      pr="16px"
      pl="6px"
      py="10px"
      align="center"
      justify="center"
    >
      <Icon name={icon} mr="6px" width="16px" color={color} />
      <Typography variant="text3" color={color}>
        {label}
      </Typography>
    </Wrapper>
  );
};

export default TeamCardBadge;
