import axios from 'axios';
import Cookies from 'js-cookie';
import {
  axiosCommonHeaders,
  successRequestInterceptor,
  errorRequestInterceptor,
} from '@utils';
import apiMock from './apiMock';

const AUTH_COOKIE_KEY = 'deazy-auth-token';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const apiMarketplace = axios.create({
  baseURL: process.env.REACT_APP_MARKETPLACE_API_URL,
});

api.defaults.headers.common = { ...axiosCommonHeaders };
apiMarketplace.defaults.headers.common = { ...axiosCommonHeaders };

api.interceptors.request.use(
  successRequestInterceptor,
  errorRequestInterceptor,
);
apiMarketplace.interceptors.request.use(
  successRequestInterceptor,
  errorRequestInterceptor,
);

const isAuthorized = () => {
  const token = Cookies.get(AUTH_COOKIE_KEY);
  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    apiMarketplace.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
  return !!token;
};

const setIsAuthorized = token => {
  Cookies.set(AUTH_COOKIE_KEY, token);
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  apiMarketplace.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const setIsAuthorizedFrontegg = token => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  apiMarketplace.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const destroyIsAuthorized = () => {
  Cookies.remove(AUTH_COOKIE_KEY);
  delete api.defaults.headers.common.Authorization;
  delete apiMarketplace.defaults.headers.common.Authorization;
};

apiMock(api);

const plugAndPlayBriefReplaceUrl = (isPlugAndPlay, url) =>
  isPlugAndPlay ? url.replace('/briefs', '/plugPlayBriefs') : url;

const routeBriefReplaceUrl = (route, url) => {
  if (route === '/plug-and-play-briefs') {
    return url.replace('/briefs', '/plugPlayBriefs');
  }
  if (route === '/closed-briefs') {
    return url.replace('/briefs', '/closedBriefs');
  }
  return url;
};

const API = {
  magicLinkSignIn: params => api.get('/auth/magicLink/signIn', { params }),

  signIn: data => api.post('/auth/signIn', data),

  recovery: data => api.post('/auth/recovery', data),

  getProfile: () => api.get('/profile'),

  getSendbirdToken: () => api.get('/profile/sendbirdToken'),

  updateProfile: data => api.put('/profile', data),

  updatePassword: data => api.post('/profile', data),

  getSettings: () => api.get('/settings'),

  getAllBriefs: opts => api.get('/allBriefs', opts),

  getBriefs: route => opts =>
    api.get(routeBriefReplaceUrl(route, '/briefs'), opts),

  getBriefById: (id, isPlugAndPlay) =>
    api.get(plugAndPlayBriefReplaceUrl(isPlugAndPlay, `/briefs/${id}`)),

  createBrief: data => api.post('/briefs', data),

  createPlugAndPlayBrief: data => api.post('/plugPlayBriefs', data),

  updatePlugAndPlayBrief: (id, data) => api.post(`/plugPlayBriefs/${id}`, data),

  cloneProject: projectId => api.post('/projects/clone', { projectId }),

  createProject: data => api.post('/projects', data),

  updateProject: data => api.put(`/projects/${data.id}`, data),

  deleteProject: projectId => api.delete(`/projects/${projectId}`),

  archiveProject: projectId => api.post(`/projects/${projectId}/archive`),

  unarchiveProject: projectId => api.post(`/projects/${projectId}/unarchive`),

  uploadBriefFiles: files =>
    api.post('/briefs/uploadFiles', files, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),

  uploadProposals: ({ briefId, isPlugAndPlay }, files) =>
    api.post(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/proposals/uploadCvs`,
      ),
      files,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    ),

  uploadPlugAndPlayBriefFiles: files =>
    api.post('/plugPlayBriefs/uploadFiles', files, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),

  getProjects: opts => api.get('/projects', opts),

  getProjectsArchiveSource: opts =>
    api.get(`/projects/archivalReadiness`, opts),

  getProjectsForBriefMerge: briefId => opts =>
    api.get(`/projects/getProjectsForBriefMerge/${briefId}`, opts),

  getProjectTotals: data => api.post('/projects/totals', data),

  getProjectById: id => api.get(`/projects/${id}`),

  getProjectBySlugAndClient: ({ clientSlug, projectSlug }) =>
    api.get(`/projects/${projectSlug}/clients/${clientSlug}`),

  getInvoices: opts => api.get('/invoices', opts),

  checkInvoiceNumberingForSuppliers: (supplierId, data) =>
    api.put(`/suppliers/${supplierId}/checkInvoiceNumbering`, data),

  getProjectInvoices: projectId =>
    api.get(`/projects/${projectId}/invoices?pageSize=25`),

  getScheduleInvoice: (projectId, invoiceId) =>
    api.get(`/projects/${projectId}/scheduleInvoices/${invoiceId}`),

  approveScheduleInvoice: (projectId, invoiceId) =>
    api.post(`/projects/${projectId}/scheduleInvoices/approve`, {
      id: invoiceId,
    }),

  preapproveScheduleInvoice: (projectId, invoiceId) =>
    api.post(`/projects/${projectId}/scheduleInvoices/preApprove`, {
      id: invoiceId,
    }),

  markAsSentScheduleInvoice: (projectId, invoiceId) =>
    api.post(`/projects/${projectId}/scheduleInvoices/sent`, {
      id: invoiceId,
    }),

  updateScheduleInvoice: (projectId, invoiceId, data) =>
    api.put(`/projects/${projectId}/scheduleInvoices/${invoiceId}`, data),

  updateInvoice: (id, data) => api.put(`/invoices/${id}`, data),

  getOldInvoice: id => api.get(`/invoices/${id}`),

  projectSync: (projectId, { enabled }) =>
    api.post(`/projects/${projectId}/syncStatus`, { enabled }),

  xeroSync: projectId => api.post(`/projects/${projectId}/syncNow`),

  getXeroStatusLimits: () => api.get('/xero/statusLimits'),

  getXeroAuthorize: () => api.get('/xero/authorize'),

  getAllClients: opts => api.get('/clients', opts),

  createClient: data => api.post('/clients', data),

  deleteClientById: clientId => api.delete(`clients/${clientId}`),

  updateClientById: (clientId, data) => api.put(`clients/${clientId}`, data),

  deleteUserFromClient: (clientId, userId) =>
    api.delete(`/clients/${clientId}/members/${userId}`),

  addUserToClient: (clientId, data) =>
    api.post(`/clients/${clientId}/members`, data),

  getSuppliersBlacklist: clientId =>
    api.get(`/clients/${clientId}/blacklistSuppliers`),

  addSupplierToBlacklist: (clientId, supplierId, data) =>
    api.post(`/clients/${clientId}/blacklistSuppliers/${supplierId}`, data),

  removeSupplierFromBlacklist: (clientId, supplierId) =>
    api.delete(`/clients/${clientId}/blacklistSuppliers/${supplierId}`),

  addDeazyLeadToClient: (clientId, data) =>
    api.post(`clients/${clientId}/addDeazyLead`, data),

  removeDeazyLeadToClient: clientId =>
    api.delete(`clients/${clientId}/removeDeazyLead`),

  getAllSuppliers: opts => api.get('/suppliers', opts),

  getSupplierById: supplierId => api.get(`/suppliers/${supplierId || ''}`),

  createSupplier: data => api.post('/suppliers', data),

  deleteSupplierById: supplierId => api.delete(`suppliers/${supplierId}`),

  updateSupplierById: (supplierId, data) =>
    api.put(`suppliers/${supplierId}`, data),

  removeCurrencyFromSupplier: (supplierId, paymentDetailsId) =>
    api.delete(`suppliers/${supplierId}/paymentDetails/${paymentDetailsId}`),

  setCurrencyAsPrimaryForSupplier: (supplierId, paymentDetailsId) =>
    api.post(
      `suppliers/${supplierId}/paymentDetails/${paymentDetailsId}/setAsPrimary`,
    ),

  addUserToSupplier: (supplierId, data) =>
    api.post(`/suppliers/${supplierId}/members`, data),

  deleteUserFromSupplier: (supplierId, userId) =>
    api.delete(`/suppliers/${supplierId}/members/${userId}`),

  searchClients: (clientPhrase = '') =>
    api.get(`/clients/search/${clientPhrase}`),

  searchClientLeads: clientId => (phrase = '') =>
    api.get(`/users/searchClientLeads/${clientId}/${phrase}`),

  searchClientContactUsers: clientId => (phrase = '') =>
    api.get(`/users/searchClientContactUsers/${clientId}/${phrase}`),

  searchSupplierUsers: supplierId => (phrase = '') =>
    api.get(`/users/searchSupplierUsers/${supplierId}/${phrase}`),

  searchSupplierAdmins: supplierId => (phrase = '') =>
    api.get(`/users/searchSupplierAdmins/${supplierId}/${phrase}`),

  searchAllocationRoles: (phrase = '') =>
    api.get(`projects/autosuggest/allocations/types/${phrase}`),

  getClients: clientId => api.get(`/clients/${clientId || ''}`),

  briefNameCheck: params => api.get('/briefs/namecheck', { params }),

  projectNameCheck: params => api.get('/briefs/projectNamecheck', { params }),

  getBriefTechStack: (briefId, phrase = '') =>
    api.get(`/briefs/${briefId}/techStackAutosuggest/${phrase}`),

  // should not have briefId param
  getIndustries: (briefId = 1) =>
    api.get(`/briefs/${briefId}/industryVerticals`),

  searchPMs: (phrase = '') => api.get(`/users/searchProjectManagers/${phrase}`),

  searchDLsAndAdmins: (phrase = '') =>
    api.get(`/users/searchDeazyLeadsAndAdmins/${phrase}`),

  searchSuppliers: (phrase = '') => api.get(`/suppliers/search/${phrase}`),

  searchUsers: (phrase = '') => api.get(`/users/searchUsers/${phrase}`),

  salesCommision: (phrase = '') =>
    api.get(`/projects/autosuggest/trackingCategories/commission/${phrase}`),

  trackingCategories: (phrase = '') =>
    api.get(`/projects/autosuggest/trackingCategories/projects/${phrase}`),

  assignAllSuppliersToBrief: isPlugAndPlay => (briefId, data) =>
    api.post(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/assignSupplier/all`,
      ),
      data,
    ),

  unassignSupplierFromBrief: isPlugAndPlay => (briefId, supplierId) =>
    api.post(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/unassignSupplier/${supplierId}`,
      ),
    ),

  createBriefNote: isPlugAndPlay => (briefId, data) =>
    api.post(
      plugAndPlayBriefReplaceUrl(isPlugAndPlay, `/briefs/${briefId}/notes`),
      data,
    ),

  updateBrief: isPlugAndPlay => (briefId, data) =>
    api.patch(
      plugAndPlayBriefReplaceUrl(isPlugAndPlay, `/briefs/${briefId}`),
      data,
    ),

  updateBasicBrief: (id, data) => api.post(`/briefs/${id}`, data),

  updateBriefStatus: isPlugAndPlay => (briefId, { status }) =>
    api.post(
      plugAndPlayBriefReplaceUrl(isPlugAndPlay, `/briefs/${briefId}/status`),
      { status },
    ),

  updateAllocations: ({ projectId, supplierId }, data) =>
    api.post(`/projects/${projectId}/allocations/${supplierId}`, data),

  updateFixedPriceAllocations: ({ projectId, supplierId }, data) =>
    api.post(
      `/projects/${projectId}/allocations/${supplierId}/fixedPrice`,
      data,
    ),

  updateDeazyAllocations: ({ projectId }, data) =>
    api.post(`/projects/${projectId}/deazyAllocations`, data),

  updateFixedPriceForTAandRetainerDeazyAllocations: ({ projectId }, data) =>
    api.post(`/projects/${projectId}/deazyAllocations/fixedPrice`, data),

  createActuals: ({ projectId, supplierId }, data) =>
    api.post(`/projects/${projectId}/actuals/${supplierId}`, data),

  createDeazyActuals: ({ projectId }, data) =>
    api.post(`/projects/${projectId}/deazyActualsNew`, data),

  getAllAllocations: ({ projectId, supplierId }) =>
    api.get(`/projects/${projectId}/allocations/all/${supplierId}`),

  getPendingAllocations: ({ projectId, supplierId }) =>
    api.get(`/projects/${projectId}/allocations/pending/${supplierId}`),

  getCurrentAllocations: ({ projectId, supplierId }) =>
    api.get(`/projects/${projectId}/allocations/current/${supplierId}`),

  getAllActuals: ({ projectId, supplierId }) =>
    api.get(`/projects/${projectId}/actuals/all/${supplierId}`),

  getLastActuals: ({ projectId, supplierId }) =>
    api.get(`/projects/${projectId}/actuals/last/${supplierId}`),

  getPendingActuals: ({ projectId, supplierId }) =>
    api.get(`/projects/${projectId}/actuals/pending/${supplierId}`),

  getActualsStatus: ({ projectId, supplierId }) =>
    api.get(`/projects/${projectId}/actuals/status/${supplierId}`),

  getLastDeazyActuals: projectId =>
    api.get(`/projects/${projectId}/deazyActuals/last`),

  getAllDeazyActuals: projectId =>
    api.get(`/projects/${projectId}/deazyActuals/all`),

  getAllUsers: opts => api.get('/users', opts),

  getUserById: id => api.get(`/users/${id}`),

  getAllAdmins: opts => api.get(`/roles/Admin/members`, opts),

  getAllCLs: opts => api.get(`/roles/CL/members`, opts),

  getAllDevelopers: opts => api.get(`/roles/TM/members`, opts),

  getAllSupplierAdmins: opts => api.get(`/roles/TA/members`, opts),

  getAllPMs: opts => api.get(`/roles/DL/members`, opts),

  getAllUsersByRole: (role, opts) => api.get(`/roles/${role}/members`, opts),

  createUser: data => api.post('/users', data),

  createUserByRole: (role, data) => api.post(`roles/${role}/createUser`, data),

  deleteUserByRole: (role, userId) =>
    api.delete(`roles/${role}/members/${userId}`),

  updateUserByRole: (role, data) => api.post(`roles/${role}/members`, data),

  updateUserById: (userId, data) => api.put(`users/${userId}`, data),

  setUserActivity: (userId, data) =>
    api.post(`users/${userId}/setActive`, data),

  setUserAccessibility: userId => api.post('users/convert', userId),

  getDeazyActualsStatus: projectId =>
    api.get(`/projects/${projectId}/deazyActuals/status`),

  getCurrentDeazyAllocations: projectId =>
    api.get(`/projects/${projectId}/deazyAllocations/current`),

  getAllDeazyAllocations: projectId =>
    api.get(`/projects/${projectId}/deazyAllocations/all`),

  approveActuals: (projectId, supplierId, actualsId) =>
    api.post(`/projects/${projectId}/actuals/approve/${supplierId}`, {
      actualsId,
    }),

  rejectActuals: (projectId, supplierId, actualsId) =>
    api.post(`/projects/${projectId}/actuals/reject/${supplierId}`, {
      actualsId,
    }),

  approveAllocations: (projectId, supplierId, allocationId) =>
    api.post(`/projects/${projectId}/allocations/approve/${supplierId}`, {
      allocationId,
    }),

  rejectAllocations: (projectId, supplierId, allocationId) =>
    api.post(`/projects/${projectId}/allocations/reject/${supplierId}`, {
      allocationId,
    }),

  deletePayment: (projectId, paymentId) =>
    api.delete(`/projects/${projectId}/projectPayments/${paymentId}`),

  getPayments: projectId => api.get(`/projects/${projectId}/projectPayments`),

  getCRAvailablePayments: (projectId, crId) =>
    api.get(`/projects/${projectId}/changeRequests/${crId}/availablePayments`),

  createPayment: (projectId, data) =>
    api.post(`/projects/${projectId}/projectPayments`, data),

  getClientTaxInfo: (projectId, isDeposit = false) =>
    api.get(`/projects/${projectId}/taxInfo/${isDeposit}`),

  getSupplierTaxInfo: (projectId, supplierId, isDeposit = false) =>
    api.get(`/projects/${projectId}/taxInfo/${isDeposit}/${supplierId}`),

  updateSettings: data => api.put('/settings', data),

  updateInvoiceLineItem: (projectId, entryId, data) =>
    api.put(`/projects/${projectId}/allocationEntries/${entryId}`, data),

  updateSchedulueInvoiceLineItem: (
    { projectId, invoiceId, lineItemId },
    data,
  ) =>
    api.put(
      `/projects/${projectId}/scheduleInvoices/${invoiceId}/lineItems/${lineItemId}`,
      data,
    ),

  forceJobs: () => api.post('/settings/forceJobs'),

  forceJobsByProject: data => api.post('/settings/forceJobs', data),

  disconnectXero: () => api.delete('/settings/xeroAuthentication'),

  nukePayments: () => api.post('/settings/nukePayments'),

  getDepositWeeksWorthValue: (projectId, numberOfWeeks, isClient) =>
    api.get(
      `/projects/${projectId}/projectPayments/weeksWorth/${numberOfWeeks}/${isClient}`,
    ),

  getPaymentForecast: (projectId, params) =>
    api.get(`/projects/${projectId}/scheduleInvoices/forecastAmount`, {
      params,
    }),

  getAllChangeRequests: projectId =>
    api.get(`/projects/${projectId}/changeRequests/all`),

  getPendingChangeRequests: projectId =>
    api.get(`/projects/${projectId}/changeRequests/pending`),

  getSingleChangeRequest: (projectId, crId) =>
    api.get(`/projects/${projectId}/changeRequests/${crId}`),

  assignChangeRequestToPayment: (projectId, paymentId, crId) =>
    api.post(
      `/projects/${projectId}/projectPayments/${paymentId}/changeRequests/${crId}`,
    ),

  readyChangeRequests: (projectId, changeRequestId) =>
    api.post(`/projects/${projectId}/changeRequests/ready`, {
      changeRequestId,
      projectId,
    }),

  approveCR: (projectId, changeRequestId) =>
    api.post(`/projects/${projectId}/changeRequests/approve`, {
      changeRequestId,
      projectId,
    }),

  rejectCR: (projectId, changeRequestId) =>
    api.post(`/projects/${projectId}/changeRequests/reject`, {
      changeRequestId,
      projectId,
    }),

  deleteCR: (projectId, changeRequestId) =>
    api.post(`/projects/${projectId}/changeRequests/delete`, {
      changeRequestId,
      projectId,
    }),

  createChangeRequest: (projectId, data) =>
    api.post(`/projects/${projectId}/changeRequests`, data),

  updateChangeRequest: (projectId, { id, ...data }) =>
    api.put(`/projects/${projectId}/changeRequests/${id}`, data),

  createBriefQuestion: isPlugAndPlay => (briefId, data) =>
    api.post(
      plugAndPlayBriefReplaceUrl(isPlugAndPlay, `/briefs/${briefId}/questions`),
      data,
    ),

  createBriefQuestionReply: isPlugAndPlay => (briefId, questionId, data) =>
    api.post(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/questions/${questionId}/reply`,
      ),
      data,
    ),

  deleteBriefQuestion: isPlugAndPlay => (briefId, questionId) =>
    api.delete(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/questions/${questionId}`,
      ),
    ),

  deleteBriefQuestionReply: isPlugAndPlay => (briefId, questionId, replyId) =>
    api.delete(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/questions/${questionId}/reply/${replyId}`,
      ),
    ),

  approveOldInvoice: (invoiceId, data) =>
    api.post(`/invoices/${invoiceId}/approve`, data),

  getPaymentOverview: projectId =>
    api.get(`/projects/${projectId}/paymentSummary`),

  getEarliestStartDate: projectId =>
    api.get(`projects/${projectId}/earliestStartDate`),

  getBorderDateForAllocation: projectId =>
    api.get(`projects/${projectId}/approvedPeriodEnd`),

  getResources: opts => api.get('/resources', opts),

  getProjectDocuments: projectId => api.get(`/projects/${projectId}/documents`),

  createProjectDocument: (projectId, data) =>
    api.post(`/projects/${projectId}/documents`, data),

  acceptBrief: isPlugAndPlay => ({ briefId, supplierId }) =>
    api.post(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/supplierAccept/${supplierId}`,
      ),
    ),

  createProposal: isPlugAndPlay => (briefId, data) =>
    api.post(
      plugAndPlayBriefReplaceUrl(isPlugAndPlay, `/briefs/${briefId}/proposals`),
      data,
    ),

  getBriefProposals: isPlugAndPlay => briefId =>
    api.get(
      plugAndPlayBriefReplaceUrl(isPlugAndPlay, `/briefs/${briefId}/proposals`),
    ),

  getProposalsForClient: isPlugAndPlay => briefId =>
    api.get(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/clientProposals`,
      ),
    ),

  getProposalClientView: (briefId, proposalId) =>
    api.get(`/briefs/${briefId}/proposals/${proposalId}/clientView`),

  updateProposal: (briefId, proposalId, data) =>
    api.put(`/briefs/${briefId}/proposals/${proposalId}`, data),

  updatePnpProposal: (briefId, data) =>
    api.put(`/plugPlayBriefs/${briefId}/deazyProposal`, data),

  updateProposalStatus: (briefId, proposalId, data) =>
    api.patch(`/briefs/${briefId}/proposals/${proposalId}`, data),

  updatePnpProposalStatus: (briefId, data) =>
    api.patch(`/plugPlayBriefs/${briefId}/deazyProposal`, data),

  createProposalCoreTeamRoles: (briefId, proposalId, data) =>
    api.post(`/briefs/${briefId}/proposals/${proposalId}/coreTeams`, data),

  updateProposalAllRolesAsDeazy: (briefId, proposalId, data) =>
    api.put(`/briefs/${briefId}/proposals/${proposalId}/allResources`, data),

  deleteProposalCoreTeamRole: (briefId, proposalId, resourceId) =>
    api.delete(
      `/briefs/${briefId}/proposals/${proposalId}/coreTeams/${resourceId}`,
    ),

  createFeatureTask: ({ briefId, proposalId, featureId }, data) =>
    api.post(
      `briefs/${briefId}/proposals/${proposalId}/features/${featureId}/tasks`,
      data,
    ),

  updateFeatureTask: ({ briefId, proposalId, featureId, taskId }, data) =>
    api.put(
      `briefs/${briefId}/proposals/${proposalId}/features/${featureId}/tasks/${taskId}`,
      data,
    ),

  deleteFeatureTask: ({ briefId, proposalId, featureId, taskId }) =>
    api.delete(
      `briefs/${briefId}/proposals/${proposalId}/features/${featureId}/tasks/${taskId}`,
    ),

  submitProposal: ({ briefId, proposalId }, data) =>
    api.put(`/briefs/${briefId}/proposals/${proposalId}/submit`, data),

  updateProposalFeature: ({ briefId, proposalId, featureId }, data) =>
    api.put(
      `briefs/${briefId}/proposals/${proposalId}/features/${featureId}`,
      data,
    ),

  createProposalFeature: ({ briefId, proposalId }, data) =>
    api.post(`briefs/${briefId}/proposals/${proposalId}/features`, data),

  deleteProposalFeature: ({ briefId, proposalId, featureId }) =>
    api.delete(
      `briefs/${briefId}/proposals/${proposalId}/features/${featureId}`,
    ),

  createProposalTeamResources: ({ briefId, proposalId }, data) =>
    api.post(`briefs/${briefId}/proposals/${proposalId}/additionalTeams`, data),

  createProposalSingleResource: ({ briefId, proposalId }, data) =>
    api.post(
      `briefs/${briefId}/proposals/${proposalId}/additionalTeams/single`,
      data,
    ),

  createProposalDeazySingleResource: ({ briefId, proposalId }, data) =>
    api.post(
      `briefs/${briefId}/proposals/${proposalId}/deazyTeams/single`,
      data,
    ),

  updateProposalDeazyTeamResources: ({ briefId, proposalId }, data) =>
    api.put(`briefs/${briefId}/proposals/${proposalId}/deazyTeams`, data),

  deleteProposalDeazyTeamResource: ({ briefId, proposalId, resourceId }) =>
    api.delete(
      `briefs/${briefId}/proposals/${proposalId}/deazyTeams/${resourceId}`,
    ),

  updateProposalTeamResources: (briefId, proposalId, data) =>
    api.put(`briefs/${briefId}/proposals/${proposalId}/additionalTeams`, data),

  updateSupplierContingency: (
    briefId,
    proposalId,
    { supplierContingencyMargin },
  ) =>
    api.post(
      `briefs/${briefId}/proposals/${proposalId}/updateSupplierContingency`,
      { supplierContingencyMargin },
    ),

  updateDeazyContingency: (
    briefId,
    proposalId,
    { deazyContingencyPercent, deazyContingencyValue },
  ) =>
    api.post(
      `briefs/${briefId}/proposals/${proposalId}/updateDeazyContingency`,
      { deazyContingencyPercent, deazyContingencyValue },
    ),

  deleteProposalTeamResource: ({ briefId, proposalId, resourceId }) =>
    api.delete(
      `briefs/${briefId}/proposals/${proposalId}/additionalTeams/${resourceId}`,
    ),

  getBriefProposal: isPlugAndPlay => (briefId, proposalId) =>
    api.get(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/proposals/${proposalId}`,
      ),
    ),

  getPnpDeazyProposal: briefId =>
    api.get(`plugPlayBriefs/${briefId}/deazyProposal`),

  updatePnpDeazyProposal: briefId =>
    api.put(`plugPlayBriefs/${briefId}/deazyProposal`),

  getBriefProposalResourcesBreakDown: (briefId, proposalId) =>
    api.get(`/briefs/${briefId}/proposals/${proposalId}/resourcesBreakdown`),

  declineBrief: isPlugAndPlay => ({ briefId, supplierId }, data) =>
    api.post(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/supplierReject/${supplierId}`,
      ),
      data,
    ),

  checkToken: token => api.get(`changeRequests/checkToken?token=${token}`),

  useTokenAction: data => api.post('changeRequests/useTokenAction/', data),

  toggleProjectCRSendout: projectId =>
    api.post(`/projects/${projectId}/toggleSendClientCrEmails`),

  getCostBreakdown: (briefId, propsalId, opts) =>
    api.get(`briefs/${briefId}/proposals/${propsalId}/costs`, opts),

  getDeazyCostBreakdown: (briefId, propsalId) =>
    api.get(`/briefs/${briefId}/proposals/${propsalId}/deazyCosts`),

  getPnpDeazyCostBreakdown: briefId =>
    api.get(`/plugPlayBriefs/${briefId}/deazyProposal/deazyCosts`),

  getExchangeRates: () => api.get('/settings/rates'),

  updateExchangeRate: data => api.post(`/settings/rates`, data),

  submitMilestoneReport: projectId =>
    api.post(`projects/${projectId}/report/submit`),

  approveMilestoneReport: projectId =>
    api.post(`projects/${projectId}/report/approve`),

  getMilestoneReport: projectId => api.get(`projects/${projectId}/report`),

  createOrUpdateMilestone: (projectId, milestoneData) =>
    api.post(`projects/${projectId}/milestones`, milestoneData),

  deleteMilestone: (projectId, milestoneId) =>
    api.delete(`projects/${projectId}/milestones/${milestoneId}`),

  overrideProjectStatus: (projectId, data) =>
    api.post(`projects/${projectId}/report/overrideStatus`, data),

  overrideProjectRiskLevel: (projectId, data) =>
    api.post(`projects/${projectId}/setRiskLevel`, data),

  getRecomendedSuppliersForBrief: isPlugAndPlay => (briefId, data) =>
    api.post(
      `/suppliers/${
        isPlugAndPlay ? 'getRecomendedForPPBrief' : 'getRecomendedForBrief'
      }/${briefId}`,
      data,
    ),

  getCountries: () => api.get(`/briefs/1/countries`),

  getEngagementTypes: () => api.get(`/briefs/1/engagementTypes`),

  postClientSOW: (projectId, data) =>
    api.post(`/projects/${projectId}/clientSow`, data),

  postSupplierSOW: (projectId, supplierId, data) =>
    api.post(`/projects/${projectId}/supplierSow/${supplierId}`, data),

  postApproveClientSOW: (projectId, date) =>
    api.post(`projects/${projectId}/approveSow/client`, { date }),

  postApproveSupplierSOW: (projectId, supplierId, date) =>
    api.post(`projects/${projectId}/approveSow/supplier/${supplierId}`, {
      date,
    }),

  getActualsWeekInfo: ({ projectId, year, month, week }) =>
    projectId &&
    year &&
    month &&
    week &&
    api.get(`/projects/${projectId}/actuals/weekInfo`, {
      params: {
        year,
        month,
        week,
      },
    }),

  updatePPResource: ({ briefId, proposalId, resourceId }, data) =>
    api.put(
      `/plugPlayBriefs/${briefId}/proposals/${proposalId}/resources/${resourceId}`,
      data,
    ),

  resubmitPnPProposal: (briefId, proposalId, data) =>
    api.post(
      `/plugPlayBriefs/${briefId}/proposals/${proposalId}/resubmit`,
      data,
    ),

  submitNewPnPProposal: (briefId, proposalId, data) =>
    api.post(
      `/plugPlayBriefs/${briefId}/proposals/${proposalId}/submitNewVersion`,
      data,
    ),

  getProjectDataFromBrief: isPlugAndPlay => briefId =>
    api.get(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/getDataForProject`,
      ),
    ),
  getMailing: () => api.get(`/mailing/`),

  postMailingTemplate: ({ email, template }) =>
    api.get(`/mailing/test?email=${email}&template=${template}`),

  restoreBrief: isPlugAndPlay => briefId =>
    api.post(
      plugAndPlayBriefReplaceUrl(isPlugAndPlay, `/briefs/${briefId}/restore`),
    ),

  getClientSowHistory: projectId =>
    api.get(`/projects/${projectId}/clientSows`),

  getSupplierSowHistory: (projectId, supplierId) =>
    api.get(`/projects/${projectId}/supplierSows/${supplierId}`),

  getClientProposal: (briefId, proposalId) =>
    api.get(`/briefs/${briefId}/clientProposals/${proposalId}`),

  getPnpClientProposal: briefId =>
    api.get(`/plugPlayBriefs/${briefId}/clientProposal`),

  updateClientProposalStatus: (briefId, proposalId, values) =>
    api.post(
      `/briefs/${briefId}/clientProposals/${proposalId}/changeClientStatus`,
      values,
    ),

  updatePnpClientProposalStatus: (briefId, values) =>
    api.post(
      `/plugPlayBriefs/${briefId}/clientProposal/changeClientStatus`,
      values,
    ),

  remindSupplierAssignment: isPlugAndPlay => (briefId, supplierId) =>
    api.post(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/supplierReminders/${supplierId || ''}`,
      ),
    ),

  getAssignmentList: isPlugAndPlay => briefId =>
    api.get(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/assignmentList`,
      ),
    ),

  getResourceRates: supplierId =>
    api.get(`/suppliers/${supplierId}/proposalRates`),

  getClientRates: clientId => api.get(`/clients/${clientId}/proposalRates`),

  getTasks: opts => api.get('/actionNotifications', opts),

  taskCompleteAction: (actionNotificationId, data) =>
    api.post(`actionNotifications/${actionNotificationId}/complete`, data),

  queryProposal: (briefId, proposalId, data) =>
    api.post(`/briefs/${briefId}/clientProposals/${proposalId}/queries`, data),

  queryPnpProposal: (briefId, data) =>
    api.post(`/briefs/${briefId}/clientProposal/queries`, data),

  getProposalQueries: (briefId, proposalId) =>
    api.get(`/briefs/${briefId}/clientProposals/${proposalId}/queries`),

  getUpcomingHolidays: limited => projectId =>
    api.get(
      limited
        ? `/projects/${projectId}/upcomingHolidays/limited`
        : `/projects/${projectId}/upcomingHolidays`,
    ),

  getPastHolidays: projectId => api.get(`/projects/${projectId}/pastHolidays`),

  getPaymentScheduleSettingsForClient: projectId =>
    api.get(`projects/${projectId}/paymentScheduleSettings/client`),

  getPaymentScheduleSettingsForSupplier: (projectId, projectToSupplierId) =>
    api.get(
      `/projects/${projectId}/paymentScheduleSettings/supplier/${projectToSupplierId}`,
    ),

  postPaymentScheduleSettings: (projectId, data) =>
    api.post(`/projects/${projectId}/paymentScheduleSettings`, data),

  updatePaymentScheduleSettings: (projectId, data) =>
    api.put(`/projects/${projectId}/paymentScheduleSettings/${data.id}`, data),

  updateClientManulaTable: ({ manualTable, ...data }) =>
    api.post(
      `/projects/${data.projectId}/paymentScheduleSettings/client/manual/${manualTable}`,
    ),

  updateSupplierManulaTable: ({ manualTable, ...data }) =>
    api.post(
      `/projects/${data.projectId}/paymentScheduleSettings/supplier/${data.projectToSupplierId}/manual/${manualTable}`,
    ),

  getSetupEnums: () => api.get('/setup/enums'),

  getTechSkills: () => apiMarketplace.get('/technologies/all'),

  getLanguages: () => apiMarketplace.get('/language-skill/languages'),

  getLanguageLevels: () => apiMarketplace.get('/language-skill/levels'),

  getStaffProfile: userId =>
    apiMarketplace.get(`/user/${userId}/staff-profile`),

  createStaffProfile: data => apiMarketplace.post(`/staff-profile`, data),

  updateStaffProfile: (staffProfileId, data) =>
    apiMarketplace.patch(`/staff-profile/${staffProfileId}`, data),

  getLanguageSkills: userId =>
    apiMarketplace.get(`/user/${userId}/language-skill`),

  createLanguageSkill: data => apiMarketplace.post(`/language-skill`, data),

  updateLanguageSkill: (languageEntryId, data) =>
    apiMarketplace.patch(`/language-skill/${languageEntryId}`, data),

  removeLanguageSkill: languageEntryId =>
    apiMarketplace.delete(`/language-skill/${languageEntryId}`),

  getLocationCities: keyword =>
    apiMarketplace.get(`/location/cities?keyword=${keyword}`),

  getCitiesByStateId: stateId =>
    apiMarketplace.get(`/location/state/${stateId}/cities`),

  getMediaFiles: staffProfileId =>
    apiMarketplace.get(`/staff-profile/${staffProfileId}/media`),

  getMediaURL: publicHash =>
    apiMarketplace.get(`/media/public/${publicHash}/signed-url`),

  getPrivateMediaURL: id => apiMarketplace.get(`/media/${id}/signed-url`),

  uploadMediaFile: (staffProfileId, files) =>
    apiMarketplace.post(`/staff-profile/${staffProfileId}/media`, files, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),

  removeMediaFile: (staffProfileId, mediaId) =>
    apiMarketplace.delete(`/staff-profile/${staffProfileId}/media/${mediaId}`),

  getUserGroups: () => api.get(`/groups`),

  addUserToGroup: (userId, groupId) =>
    api.post(`/groups/${groupId}/addMember/${userId}`),

  removeUserFromGroup: (userId, groupId) =>
    api.post(`/groups/${groupId}/removeMember/${userId}`),

  reassignTask: (userId, actionNotificationIds) =>
    api.post(`/actionNotifications/reassign/${userId}`, {
      actionNotificationIds,
    }),

  getTasksCount: () => api.get('/actionNotifications/count'),

  importProject: data => api.post('projects/import', data),

  uploadCSVTemplate: files =>
    api.post('/settings/uploadCsvTemplate', files, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),

  deleteCSVTemplate: fileId =>
    api.delete(`settings/deleteCsvTemplate/${fileId}`),

  getAuditNotes: (projectId, invoiceId) =>
    api.get(
      `projects/${projectId}/scheduleInvoices/${invoiceId}/getAuditNotes`,
    ),

  addCommentToInvoice: (projectId, data) =>
    api.post(`projects/${projectId}/scheduleInvoices/addComment`, data),

  rejectInvoice: (projectId, data) =>
    api.post(`projects/${projectId}/scheduleInvoices/reject`, data),

  createCreditNote: (projectId, invoiceId, data) =>
    api.post(
      `projects/${projectId}/scheduleInvoices/${invoiceId}/creditNotes`,
      data,
    ),

  deleteCreditNote: (projectId, invoiceId, creditNoteId) =>
    api.delete(
      `projects/${projectId}/scheduleInvoices/${invoiceId}/creditNotes/${creditNoteId}`,
    ),

  checkExistingEmail: email =>
    apiMarketplace.get(`/user/check-email-exists?email=${email}`),

  getTechSkill: keyword =>
    apiMarketplace.get(`/technologies?keyword=${keyword}`),

  preApproveCreditNote: (projectId, invoiceId, creditNoteId) =>
    api.post(
      `/projects/${projectId}/scheduleInvoices/${invoiceId}/creditNotes/${creditNoteId}/preApprove`,
    ),

  approveCreditNote: (projectId, invoiceId, creditNoteId) =>
    api.post(
      `/projects/${projectId}/scheduleInvoices/${invoiceId}/creditNotes/${creditNoteId}/approve`,
    ),

  rejectCreditNote: (projectId, invoiceId, creditNoteId) =>
    api.post(
      `/projects/${projectId}/scheduleInvoices/${invoiceId}/creditNotes/${creditNoteId}/reject`,
    ),

  addNewDeliveryPartner: (projectId, supplierId, data) =>
    api.post(
      `/projects/${projectId}/addNewDeliveryPartner/${supplierId}`,
      data,
    ),

  getWonBriefToSuppliers: briefId =>
    api.get(`/plugPlayBriefs/${briefId}/getWonBriefToSuppliers`),

  createPublicLink: data => apiMarketplace.post('/public-group', data),

  getAuditNotesForAllocation: (projectId, supplierId) =>
    api.get(
      `/projects/${projectId}/allocations/current/${supplierId}/getAuditNotes`,
    ),

  getAuditNotesForDeazyAllocation: projectId =>
    api.get(`/projects/${projectId}/deazyAllocations/current/getAuditNotes`),

  getAllAssessments: () => apiMarketplace.get('/assessments/tests'),

  getAssessmentServiceStatus: service =>
    apiMarketplace.get(`/assessments/${service}/status`),

  getProfileAssessments: staffProfileId =>
    apiMarketplace.get(`/staff-profile/${staffProfileId}/assessments`),

  createProfileAssessment: (staffProfileId, assessmentData) =>
    apiMarketplace.post(
      `/staff-profile/${staffProfileId}/assessments`,
      assessmentData,
    ),

  getBriefCandidates: (briefId, phrase, opts) =>
    api.get(
      `plugPlayBriefs/${briefId}/proposals/allResources?groupBy=${phrase}`,
      opts,
    ),

  getBriefProposalChanges: briefId =>
    api.get(`plugPlayBriefs/${briefId}/proposalChanges`),

  toggleCandidateShortlist: (briefId, proposalId, resourceId) =>
    api.post(
      `plugPlayBriefs/${briefId}/proposals/${proposalId}/resources/${resourceId}/shortlisted`,
    ),

  getUserStaffProfiles: query => apiMarketplace.get(`/staff-profile${query}`),

  getCitiesByIds: query =>
    apiMarketplace.get(`/location/cities-filtered-staff-profile${query}`),

  getClientLinkedDocuments: projectId =>
    api.get(`/projects/${projectId}/externalDocuments`),

  getSupplierLinkedDocuments: (projectId, supplierId) =>
    api.get(
      `/projects/${projectId}/projectToSupplier/${supplierId}/externalDocuments`,
    ),

  deleteClientLinkedDocuments: (projectId, documentId) =>
    api.delete(`/projects/${projectId}/externalDocuments/${documentId}`),

  deleteSupplierLinkedDocuments: (projectId, documentId) =>
    api.delete(`/projects/${projectId}/externalDocuments/${documentId}`),

  createClientLinkedDocuments: ({ projectId }, data) =>
    api.post(`/projects/${projectId}/externalDocuments`, data),

  createSupplierLinkedDocuments: ({ projectId, ptsId }, data) =>
    api.post(
      `/projects/${projectId}/projectToSupplier/${ptsId}/externalDocuments`,
      data,
    ),

  queryCandidate: (briefId, proposalId, resourceId, data) =>
    api.post(
      `/plugPlayBriefs/${briefId}/proposals/${proposalId}/resources/${resourceId}/query`,
      data,
    ),

  getAllLanguageSkillAssessments: () =>
    apiMarketplace.get('/assessments/test-gorilla/tests'),

  getStaffProfileLanguageSkillAssessments: staffProfileId =>
    apiMarketplace.get(
      `/staff-profile/${staffProfileId}/assessments/test-gorilla/language`,
    ),

  createStaffProfileLanguageSkillAssessment: (staffProfileId, assessmentData) =>
    apiMarketplace.post(
      `/staff-profile/${staffProfileId}/assessments/test-gorilla/language`,
      assessmentData,
    ),

  getLastAllocationEntryEndDate: projectId =>
    api.get(`/projects/${projectId}/lastAllocationEntryEndDate`),

  getDeliveryPartners: query => apiMarketplace.get(`/delivery-partner${query}`),

  getDeliveryPartner: partnerId =>
    apiMarketplace.get(`/delivery-partner/${partnerId}`),

  getCompleteProfile: userId =>
    apiMarketplace.get(`/staff-profile/${userId}/complete`),

  getAllCountries: () => apiMarketplace.get(`/location/countries`),

  shortlistSuppliers: isPlugAndPlay => (briefId, { supplierIds }) =>
    api.post(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/bts/shortlist`,
      ),
      {
        supplierIds,
      },
    ),

  scheduleSupplier: isPlugAndPlay => ({ briefId, btsId }, data) =>
    api.post(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/bts/schedule/${btsId}`,
      ),
      data,
    ),
  exclusiveSupplier: isPlugAndPlay => ({ briefId, btsId }, data) =>
    api.post(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/bts/exclusive/${btsId}`,
      ),
      data,
    ),
  assignSupplier: isPlugAndPlay => ({ btsId, briefId }, data) =>
    api.post(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/bts/assign/${btsId}`,
      ),
      data,
    ),

  assignmentJobTrigger: isPlugAndPlay => briefId =>
    api.post(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/unexclusive`,
      ),
    ),

  createVideoInterview: profileId =>
    apiMarketplace.post(`/staff-profile/${profileId}/willo/request-video`),

  getInterviews: profileId =>
    apiMarketplace.get(`/staff-profile/${profileId}/willo/interviews`),

  addQuestionInterview: (profileId, data) =>
    apiMarketplace.post(`/staff-profile/${profileId}/willo/request-qa`, data),

  addProfileImage: (profileId, data) =>
    apiMarketplace.post(`/staff-profile/${profileId}/profile-image`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),

  getProfileImageURLById: id =>
    apiMarketplace.get(`/media/profile-image/${id}/signed-url`),

  getProfileImageURLByHash: publicHash =>
    apiMarketplace.get(`/media/public/profile-image/${publicHash}/signed-url`),

  deleteProfileImage: id =>
    apiMarketplace.delete(`/staff-profile/${id}/profile-image`),

  getMeComplete: () => apiMarketplace.get('/staff-profile/me/complete'),

  getRateLimits: () => apiMarketplace.get('/staff-profile/rate-limits'),

  updateTMProfile: (staffProfileId, data) =>
    apiMarketplace.patch(`/staff-profile/${staffProfileId}/team-member`, data),

  getEducationList: profileId =>
    apiMarketplace.get(`/staff-profile/${profileId}/education`),

  createEducation: (profileId, data) =>
    apiMarketplace.post(`/staff-profile/${profileId}/education`, data),

  deleteEducation: (profileId, educationId) =>
    apiMarketplace.delete(
      `/staff-profile/${profileId}/education/${educationId}`,
    ),

  getWorkHighlights: profileId =>
    apiMarketplace.get(`/staff-profile/${profileId}/work-highlights`),

  updateWorkHighlights: (profileId, data) =>
    apiMarketplace.put(`/staff-profile/${profileId}/work-highlights`, data),

  getWorkProjects: profileId =>
    apiMarketplace.get(`/staff-profile/${profileId}/work-projects`),

  updateWorkProjects: (profileId, data) =>
    apiMarketplace.post(`/staff-profile/${profileId}/work-projects`, data),

  deleteWorkProject: (profileId, projectId) =>
    apiMarketplace.delete(
      `/staff-profile/${profileId}/work-projects/${projectId}`,
    ),

  getSendbirdChannelsIdsForBrief: isPlugAndPlay => briefId =>
    api.get(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/sendbirdChannelIds`,
      ),
    ),

  getBriefsByChannelIds: channelIds =>
    api.post('/chat/getBriefsByChannelIds', { channelIds }),

  addToChannels: isPlugAndPlay => ({ briefId }) =>
    api.post(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/addToChannels/`,
      ),
    ),

  sendMessageToSlack: data => api.post('/slack/sendMessage', data),

  updateMatchingQuestionnaire: isPlugAndPlay => (briefId, data) =>
    api.put(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/answersToQuestions/`,
      ),
      data,
    ),

  createMatchingQuestionnaire: isPlugAndPlay => (briefId, data) =>
    api.post(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/answersToQuestions/`,
      ),
      data,
    ),

  getMatchedDPs: isPlugAndPlay => (briefId, params) =>
    api.get(
      plugAndPlayBriefReplaceUrl(
        isPlugAndPlay,
        `/briefs/${briefId}/matchingSuppliers/`,
      ),
      { params },
    ),

  bulkUpdatedAvailability: (deliveryPartnerId, data) =>
    apiMarketplace.patch(
      `/staff-profile/${deliveryPartnerId}/availabilities`,
      data,
    ),

  getPandadocSowClientTemplate: projectId =>
    api.get(`/pandadoc/${projectId}/clientDocument`),

  getPandadocSowSupplierTemplate: (projectId, supplierId) =>
    api.get(`/pandadoc/${projectId}/supplierDocument/${supplierId}`),

  getTemplateFiles: () => apiMarketplace.get(`/media/template-files`),

  getClientLeads: opts => api.get(`/roles/CL/members${opts || ''}`),

  createProjectSurvey: (projectId, data) =>
    api.post(`/projects/${projectId}/projectSurvey`, data),

  createUserFrontegg: data => apiMarketplace.post('/user', data),

  activateUserFrontegg: data => apiMarketplace.put('/user/activate', data),

  deactivateUserFrontegg: data => apiMarketplace.put('/user/deactivate', data),

  editUserPassword: data => apiMarketplace.post('/user/password', data),

  getUserRoles: () => apiMarketplace.get('/role'),

  getSuppliers: query => apiMarketplace.get(`/delivery-partner${query}`),

  getSupplierFilters: () => apiMarketplace.get('/delivery-partner/filters'),

  getSupplierNameLogo: () =>
    apiMarketplace.get('/delivery-partner/names-and-logos'),

  getSupplierNameLogoById: id =>
    apiMarketplace.get(`/delivery-partner/names-and-logos/${id}`),

  getCustomers: () => apiMarketplace.get('/customer/names-and-logos'),

  getCustomerById: id => apiMarketplace.get(`/customer/names-and-logos/${id}`),

  getTimezones: () => apiMarketplace.get('/settings/timezones'),

  apiInstance: api,

  apiMarketplaceInstance: apiMarketplace,
};

export {
  API,
  isAuthorized,
  setIsAuthorized,
  setIsAuthorizedFrontegg,
  destroyIsAuthorized,
};
