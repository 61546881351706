import { NEW_ROLES } from '@app/constants';
import { Button, Flex, Grid, useDisclosure } from '@chakra-ui/react';
import Icon from '@components/Icon';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import { addFullDescription } from '@utils/usersUtils';
import { find, isEmpty, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AddToRoleModal from './AddToRoleModal';

const RolesDetails = ({
  roles,
  deleteUserByRole,
  userId,
  fetchUser,
  addUserToGroupByRole,
  usersStore: { isAdmin, isDL },
}) => {
  const handleDelete = role => {
    return deleteUserByRole({
      userId,
      role,
      onSuccess: () => fetchUser(userId),
    });
  };

  const mappedRoles = React.useMemo(() => {
    return map(roles, el => ({
      ...el,
      fullDescription: addFullDescription(el.name),
    }));
  }, [roles]);

  const isUserAdmin = !!find(roles, role => role.name === NEW_ROLES.ADMIN);

  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <>
      <WhiteCard
        p={[
          '32px 40px 80px 40px',
          '32px 40px 80px 40px',
          '32px 40px 32px 40px',
          '32px 40px 32px 40px',
          '32px 40px 32px 40px',
        ]}
      >
        <Typography variant="h4" pb="10px" mb="16px">
          Roles details
        </Typography>
        <Button
          leftIcon={<Icon name="add" mr="5px" w="14px" />}
          fontWeight="400"
          fontSize={18}
          bg="primary.400"
          color="#fff"
          borderRadius="8px"
          position="absolute"
          transform={[
            'translateX(50%)',
            'translateX(50%)',
            'initial',
            'initial',
            'initial',
          ]}
          right={['50%', '50%', '40px', '40px', '40px']}
          top={['initial', 'initial', '40px', '40px', '40px']}
          bottom={['24px', '24px', 'initial', 'initial', 'initial']}
          _hover={{ bg: 'primary.300' }}
          _active={{ bg: 'primary.500' }}
          onClick={onOpen}
          isDisabled={isUserAdmin && isDL}
        >
          Add role
        </Button>
        <Grid templateColumns="1fr 1fr" gap="24px">
          {!isEmpty(roles) ? (
            mappedRoles.map(item => {
              return (
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  mb="16px"
                  key={`role-${item.id}`}
                >
                  <Typography key={item.id}>{item.fullDescription}</Typography>
                  {((isAdmin && item.name === NEW_ROLES.ADMIN) ||
                    item.name !== NEW_ROLES.ADMIN) && (
                    <Button
                      bg="red.solid"
                      color="#fff"
                      variant="solid"
                      borderRadius="8px"
                      fontWeight="initial"
                      size="sm"
                      mr="8px"
                      px="14px"
                      _hover={{ bg: 'red.mid' }}
                      _active={{ bg: 'red.light' }}
                      onClick={() => handleDelete(item.name)}
                    >
                      Leave
                    </Button>
                  )}
                </Flex>
              );
            })
          ) : (
            <Typography>User has no role</Typography>
          )}
        </Grid>
      </WhiteCard>
      {isOpen && (
        <AddToRoleModal
          userId={userId}
          addUserToGroupByRole={addUserToGroupByRole}
          fetchUser={fetchUser}
          isOpen={isOpen}
          onClose={onClose}
          groupTitle="group"
        />
      )}
    </>
  );
};
export default inject('usersStore')(observer(RolesDetails));
