/* eslint-disable no-throw-literal */
import { action, makeObservable, observable, toJS, computed } from 'mobx';
import moment from 'moment';
import {
  map,
  reject,
  isEmpty,
  get,
  find,
  omit,
  first,
  set,
  orderBy,
  lowerCase,
  filter,
  isString,
  groupBy,
  values as _values,
} from 'lodash-es';
import { sleep } from '@utils';
import { APP_ROUTES } from '@routes';
import { nanoid } from 'nanoid';
import { NEW_PROPOSAL_STATUSES } from '@app/constants';
import {
  decorateCoreTeamWithHours,
  calculateMarginAndClientRate,
} from '@utils/projectUtils';
import { getPnpResourceCurrencyRate } from '@utils/briefUtils';

let isSavingBrief = false;
export default class BriefsStore {
  constructor({ API, toastsStore, routerStore, usersStore }) {
    makeObservable(this);
    this.API = API;
    this.toastsStore = toastsStore;
    this.routerStore = routerStore;
    this.usersStore = usersStore;
  }

  @observable draft = {};

  @observable _brief = {};

  @observable ppBriefFetchDate = null;

  @observable deleteId = null;

  @observable isLoading = false;

  @computed get stepProgress() {
    return this.draft.step || 0;
  }

  @action onStatusChange = async ({
    v,
    proposalId,
    targetStatus,
    successCb,
  }) => {
    try {
      const apiFn = !proposalId
        ? () =>
            this.API.updatePnpProposalStatus(this.brief.id, {
              ...v,
              status: targetStatus,
            })
        : () =>
            this.API.updateProposalStatus(this.brief.id, proposalId, {
              ...v,
              status: targetStatus,
            });
      await apiFn();

      if (successCb) {
        successCb();
      }

      const key = this.brief.isPlugAndPlay
        ? 'ppBriefToSuppliers'
        : 'briefToSuppliers';
      this._brief[key] = map(this._brief[key], bts =>
        bts.proposal?.id === proposalId
          ? { ...bts, proposal: { ...bts.proposal, status: targetStatus } }
          : bts,
      );
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Could not change proposal status. Please try again later.',
      });
    }
  };

  @computed get brief() {
    const { profile, isAdminOrDL } = this.usersStore;
    const userSupplierId = get(profile, 'supplier.id', undefined);
    const briefSupplierConnections = filter(
      this._brief.briefToSuppliers || this._brief.ppBriefToSuppliers,
      bts => {
        if (isAdminOrDL) {
          return true;
        }
        if (userSupplierId) {
          return bts?.supplier?.id === userSupplierId;
        }

        return false;
      },
    );

    const filteredProposals = filter(
      this._brief.briefToSuppliers || this._brief.ppBriefToSuppliers,
      p => p.proposal !== null,
    );

    const proposals = orderBy(
      briefSupplierConnections,
      item => lowerCase(item?.supplier?.name),
      ['asc'],
    );

    const assignedSuppliers = map(briefSupplierConnections, s => {
      return { supplier: s.supplier };
    });

    const isPlugAndPlay = !!this._brief.newOrExisting;

    const supplierProposal = first(
      this._brief.briefToSuppliers || this._brief.ppBriefToSuppliers,
    );

    return {
      ...this._brief,
      proposals,
      isClosed: this._brief.status === 'Closed',
      filteredProposals,
      assignedSuppliers,
      isPlugAndPlay,
      isAssigned: !isEmpty(
        this._brief.briefToSuppliers || this._brief.ppBriefToSuppliers,
      ),
      ...((this.usersStore.isTeamAdmin || this.usersStore.isClient) && {
        supplierProposal: {
          ...supplierProposal,
          isAccepted: supplierProposal?.status === 'Accepted',
          isDeclined: supplierProposal?.status === 'Declined',
          primarySupplierCurrency: find(
            supplierProposal?.supplier?.paymentDetails,
            c => c.isPrimary,
          )?.currency,
        },
      }),
      features: map(
        reject(this._brief.features, { wanted: false }),
        feature => feature.name,
      ),
      wantedFeatures: reject(this._brief.features, { wanted: false }),
      unwantedFeatures: map(
        reject(this._brief.features, { wanted: true }),
        feature => feature.name,
      ),
      allWantedFeatures: reject(
        [
          ...(this._brief.features || []),
          ...(map(this._brief.additionalFeatures, f => ({
            ...f,
            isAdditional: true,
          })) || []),
        ],
        { wanted: false },
      ),
      additionalFeatures: map(
        this._brief.additionalFeatures,
        feature => feature.name,
      ),

      resourcesTableData: map(this._brief.resources, r => [
        r.technology,
        r.seniority,
        moment.utc(r.startDate).format('DD MMM YYYY'),
        r.numberOfMonths,
        r.technologies,
        r.comment,
      ]),
    };
  }

  uploadBriefFiles = async brief => {
    if (!isEmpty(brief.files)) {
      const isPlugAndPlay = !!brief.newOrExisting;

      const promises = [];

      const rejectedFiles = reject(brief.files, 'name');

      rejectedFiles.forEach(f => {
        const fd = new FormData();
        fd.append(f.uid, f.file, f.file.name);
        promises.push(
          this.API[
            isPlugAndPlay ? 'uploadPlugAndPlayBriefFiles' : 'uploadBriefFiles'
          ](fd),
        );
      });
      await Promise.all(promises);
    }
  };

  @observable flag = false;

  @action setFlag = async () => {
    this.flag = true;
    await sleep(100);
    this.flag = false;
  };

  @action saveBrief = async (brief, formApi, successCb) => {
    if (isSavingBrief) {
      return;
    }
    isSavingBrief = true;

    const { step } = brief;
    const { errors } = formApi.getState();
    this.draft = { ...brief, [`isStep${step}Completed`]: isEmpty(errors) };

    if (typeof successCb === 'function') {
      try {
        const briefObj = toJS({
          ...brief,
          technologies: map(brief.technologies, t => t.value),
          customerId: get(brief, 'customer.id'),
        });

        await this.uploadBriefFiles(briefObj);
        if (!brief.id) {
          const { data } = await this.API.createBrief(briefObj);
          this._brief = data;
        } else {
          await this.API.updateBasicBrief(brief.id, briefObj);
        }

        successCb();
      } catch (e) {
        this.toastsStore.showError({
          title:
            'Ooops! Something unexpected happened. Please try again later.',
        });
        if (e.errors) {
          return e.errors;
        }
      } finally {
        isSavingBrief = false;
      }
    }
    isSavingBrief = false;
    return undefined;
  };

  @action fetchBrief = async (briefId, isPlugAndPlay) => {
    this._brief = {};
    if (!briefId) {
      return;
    }
    this.isLoading = true;
    try {
      if (isPlugAndPlay) {
        this.ppBriefFetchDate = new Date();
      }
      const { data } = await this.API.getBriefById(briefId, isPlugAndPlay);
      this._brief = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoading = false;
    }
  };

  @computed get supplierNotes() {
    const notesArray = filter(
      this.brief.briefToSuppliers || this.brief.ppBriefToSuppliers,
      n => n.note !== null,
    );
    return map(notesArray, n => {
      return { description: n.note, createdAt: n.assignedOn };
    });
  }

  @action addNote = async (note, formApi) => {
    try {
      const { data } = await this.API.createBriefNote(
        !!this.brief.newOrExisting,
      )(this.brief.id, note);
      setTimeout(formApi.restart);
      this._brief = {
        ...this._brief,
        notes: [...(this._brief.notes || []), data],
      };
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    }
  };

  @action addTargetValue = async (
    { targetValue, pm, clientLead, proposalClosingDate },
    formApi,
  ) => {
    if (targetValue > 2000000000) {
      setTimeout(formApi.restart);
      return this.toastsStore.showError({
        title:
          'Provided target value is out of range! Must be no more than 2,000,000,000.',
      });
    }
    try {
      await this.API.updateBrief(!!this.brief.newOrExisting)(this.brief.id, {
        targetValue,
        pm,
        clientLead,
        proposalClosingDate,
      });
      this._brief = {
        ...this._brief,
        targetValue,
        pm,
        proposalClosingDate,
        clientLead,
      };
    } catch (e) {
      setTimeout(formApi.restart);
      this.toastsStore.showError({
        title: 'Ooops! Something unexpected happened. Please try again later.',
      });
    }
    return undefined;
  };

  @action clearDraft = async () => {
    this.draft = {};
  };

  @action clearBrief = async () => {
    this._brief = {};
    this.reminders = {};
    this.isCreatingProjectFromBrief = false;
  };

  @action assignBriefToDraft = async () => {
    this.draft = {
      ...this._brief,
      isStep1Completed: true,
      isStep2Completed: true,
      isStep3Completed: true,
      technologies: map(this._brief?.technologies, t => ({
        label: t,
        value: t,
      })),
    };
  };

  @action acceptBrief = async (
    { briefId, isPlugAndPlay, supplierId },
    successCb,
  ) => {
    try {
      this._brief.isAccepting = true;
      await this.API.acceptBrief(isPlugAndPlay)({
        briefId,
        supplierId,
      });
      set(
        this._brief,
        `${isPlugAndPlay ? 'ppBriefToSuppliers' : 'briefToSuppliers'}[0]`,
        {
          ...get(
            this._brief,
            `${isPlugAndPlay ? 'ppBriefToSuppliers' : 'briefToSuppliers'}[0]`,
          ),
          status: 'Accepted',
        },
      );
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: 'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      this._brief.isAccepting = false;
    }
  };

  @action declineBrief = async (
    { briefId, isPlugAndPlay, changeStatusReason, supplierId },
    successCb,
  ) => {
    try {
      this._brief.isDeclining = true;
      await this.API.declineBrief(isPlugAndPlay)(
        { briefId, supplierId },
        {
          changeStatusReason,
        },
      );
      set(
        this._brief,
        `${isPlugAndPlay ? 'ppBriefToSuppliers' : 'briefToSuppliers'}[0]`,
        {
          ...get(
            this._brief,
            `${isPlugAndPlay ? 'ppBriefToSuppliers' : 'briefToSuppliers'}[0]`,
          ),
          status: 'Declined',
          changeStatusReason,
        },
      );
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: 'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      this._brief.isDeclining = false;
    }
  };

  @action createPlugAndPlayBrief = async values => {
    try {
      const payload = this.preparePlugAndPlayPayload(values);
      await this.uploadBriefFiles(payload);
      const { data: brief } = await this.API.createPlugAndPlayBrief(payload);
      this.toastsStore.showSuccess({
        title: 'Brief submitted successfully!',
      });
      this.routerStore.push(APP_ROUTES.briefPlugAndPlayById(brief.id));
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    }
  };

  @action updatePlugAndPlayBrief = async (id, values) => {
    try {
      const payload = this.preparePlugAndPlayPayload(values);
      await this.uploadBriefFiles(payload);
      await this.API.updatePlugAndPlayBrief(id, payload);
      this.toastsStore.showSuccess({
        title: 'Brief updated successfully!',
      });
      this.routerStore.push(APP_ROUTES.briefs);
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    }
  };

  preparePlugAndPlayPayload = ({ resources, ...data }) => {
    return {
      ...data,
      customerId: get(data, 'customer.id'),
      resources: map(
        reject(resources, r => isEmpty(omit(r, ['id', 'numberOfMonths']))),
        ({ id, ...resource }) =>
          isString(id) ? resource : { ...resource, id },
      ),
    };
  };

  decoratePnPResource = (res, exchangeRate) => ({
    name: res.name || res.role,
    rate: res.rate,
    clientRate: get(
      calculateMarginAndClientRate(res, exchangeRate),
      'clientRate',
    ),
    id: nanoid(10),
    startDate: res?.startDate,
    endDate: res?.endDate,
    amount: 40,
  });

  @computed get projectInitialValuesFromBrief() {
    const initialValues = {};
    const brief = this.brief?.brief ? this._brief.brief : this.brief;
    const isPlugAndPlay = !!brief.newOrExisting;

    if (!brief.warningMessage) {
      const { proposal = {}, supplier } =
        first(
          filter(
            brief.filteredProposals,
            bToS => bToS?.proposal?.status === NEW_PROPOSAL_STATUSES.WON,
          ),
        ) || {};

      if (proposal.earliestStartDate && proposal.estimatedDeliveryDate) {
        initialValues.startDate = proposal.earliestStartDate;
        initialValues.endDate = proposal.estimatedDeliveryDate;
      }

      const decorateResource = (res, exchangeRate) => ({
        name: res.name || res.role,
        rate: res.rate,
        clientRate: get(
          calculateMarginAndClientRate(res, exchangeRate),
          'clientRate',
        ),
        id: nanoid(10),
        amount: res.amount,
        ...(!isPlugAndPlay
          ? {
              startDate: initialValues.startDate || brief?.startDate,
              endDate: initialValues.endDate || brief?.proposalClosingDate,
            }
          : {
              startDate: res?.startDate,
              endDate: res?.endDate,
            }),
        ...(isPlugAndPlay && { amount: 40 }),
      });
      if (proposal.currency) {
        initialValues.overriddenCurrency = proposal.currency;
      }
      if (proposal.currencyRate) {
        initialValues.supplierCurrencyRate = proposal.currencyRate;
      }

      if (isPlugAndPlay) {
        const wonResources = filter(this._brief?.resources, {
          status: 'Won',
        });
        let resourcesOrderedByDate = orderBy(wonResources, 'startDate', 'asc');
        resourcesOrderedByDate = map(resourcesOrderedByDate, res => ({
          ...res,
          endDate: moment
            .utc(res.startDate)
            .add({ months: +res.briefResource.numberOfMonths })
            .format(),
        }));
        initialValues.startDate = first(resourcesOrderedByDate)?.startDate;
        initialValues.endDate = first(
          orderBy(resourcesOrderedByDate, 'endDate', 'desc'),
        )?.endDate;

        const resourcesGroupedBySupplier = groupBy(
          resourcesOrderedByDate,
          'ppBriefProposal.ppBriefToSupplier.supplier.id',
        );
        const { currencyRateEur, currencyRateUsd } = this._brief || {};
        const projectToSuppliers = map(
          _values(resourcesGroupedBySupplier),
          supplierResources => {
            const dp = first(supplierResources)?.ppBriefProposal
              ?.ppBriefToSupplier?.supplier;

            return {
              supplier: dp,
              allocations: map(supplierResources, resource => {
                const exchangeRate = getPnpResourceCurrencyRate(resource, {
                  deazyProposalInfo: { currencyRateEur, currencyRateUsd },
                });
                return decorateResource(resource, exchangeRate);
              }),
              overriddenCurrency: find(dp?.paymentDetails, c => c.isPrimary)
                ?.currency,
            };
          },
        );
        initialValues.projectToSuppliers = projectToSuppliers;
        initialValues.totalBasis = false;
      } else {
        const allocations = map(
          [
            ...decorateCoreTeamWithHours(
              proposal.coreTeam || [],
              proposal.features,
              proposal.currencyRate,
            ),
            ...map(proposal.additionalTeam || [], a => ({
              ...a,
              amount: a.timeHours,
            })),
          ],
          r => decorateResource(r, proposal.currencyRate),
        );
        initialValues.projectToSuppliers = [
          {
            supplier,
            allocations,
            overriddenCurrency:
              proposal?.overriddenCurrency ||
              find(supplier?.paymentDetails, c => c.isPrimary)?.currency,
          },
        ];
        initialValues.deazyAllocations = map(proposal.deazyTeam, alloc => ({
          id: nanoid(10),
          amount: alloc.timeHours,
          rate: alloc.clientRate,
          resourceName: alloc.deazyName,
          startDate: initialValues.startDate || brief?.startDate,
          endDate: initialValues.endDate || brief?.proposalClosingDate,
        }));
      }
    }
    const duration =
      moment
        .utc(initialValues.endDate)
        .endOf('day')
        .diff(moment.utc(initialValues.startDate).startOf('day'), 'days') + 1;
    initialValues.numberOfWeeks = Math.floor(duration / 7);
    initialValues.numberOfDays =
      duration > 6 ? duration % (initialValues.numberOfWeeks * 7) : duration;

    return {
      name: brief?.name,
      clientLead: brief?.clientLead,
      ...(brief?.customer && {
        currency: brief?.customer.currency,
        client: brief?.customer,
      }),
      pm: brief?.pm,
      ...(isPlugAndPlay && {
        engagementType: 'Team Augmentation',
      }),
      ...(brief.startDate && {
        startDate: moment.utc(brief.startDate).format(),
        endDate: moment
          .utc(brief.startDate)
          .add({ days: 6 })
          .format(),
      }),
      ...initialValues,
      [!isPlugAndPlay ? 'briefId' : 'ppBriefId']: brief.id,
    };
  }

  @observable isCreatingProjectFromBrief = false;

  @action toggleIsCreatingProjectFromBrief = isCreating => {
    this.isCreatingProjectFromBrief = isCreating;
  };

  @action initializeProjectDataFromBrief = async (briefId, isPlugAndPlay) => {
    this._brief = {};
    if (!briefId) {
      return;
    }
    this.isLoading = true;
    try {
      const { data } = await this.API.getProjectDataFromBrief(isPlugAndPlay)(
        briefId,
      );
      this._brief = {
        ...data,
      };
      this.toggleIsCreatingProjectFromBrief(true);
    } catch (e) {
      this.toastsStore.showError({ title: e?.message || 'Network error' });
    } finally {
      this.isLoading = false;
    }
  };

  @action addToChannels = async (isPlugAndPlay, briefId) => {
    try {
      await this.API.addToChannels(isPlugAndPlay)({ briefId });
    } catch (e) {
      this.toastsStore.showError({ title: e?.message || 'Network error' });
    }
  };

  @observable currentChannelUrl = '';

  @action setCurrentChannelUrl = url => {
    this.currentChannelUrl = url;
  };

  @action sendMessageToSlack = async values => {
    try {
      await this.API.sendMessageToSlack({
        data: {
          id: this.brief.id,
          isPPBrief: this.brief.isPlugAndPlay,
          ...values,
        },
      });
    } catch (e) {
      this.toastsStore.showError({ title: e?.message || 'Network error' });
    }
  };
}
