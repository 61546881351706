import Modal from '@components/Modal';
import Typography from '@styles/Typography';
import { isInvoiceEditable } from '@utils/invoiceUtils';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useRouteMatch } from 'react-router';
import EditNewInvoiceForm from './EditNewInvoiceForm';

const EditInvoiceIntegrationModal = ({
  isOpen,
  onClose,
  selectedInvoice,
  paymentsStore: { updateInvoice },
  teamsStore: { reCheckInvoiceNumberingForSuppliers },
}) => {
  const routeMatch = useRouteMatch('/delivery-partners/:supplierId');

  const supplierId = routeMatch?.params?.supplierId;

  const isEditable = isInvoiceEditable(selectedInvoice?.status);

  const projectId = selectedInvoice?.projectPayment?.project.id;

  const noRefetchPayments = false;

  return (
    <Modal isOpen={isOpen} onClose={onClose} styleProps={{ minW: '620px' }}>
      <Typography variant="h2" mb="24px">
        Edit invoice integration
      </Typography>
      <EditNewInvoiceForm
        invoice={selectedInvoice}
        isEditable={isEditable}
        initialValues={selectedInvoice}
        onClose={onClose}
        onSubmit={values => {
          updateInvoice(
            values,
            projectId,
            () => {
              reCheckInvoiceNumberingForSuppliers({ supplierId });
              onClose();
            },
            noRefetchPayments,
          );
        }}
      />
    </Modal>
  );
};

export default inject(
  'teamsStore',
  'paymentsStore',
)(observer(EditInvoiceIntegrationModal));
