import React from 'react';
import { Field } from 'react-final-form';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import TextInput from '@components/forms/TextInput';
import Icon from '@components/Icon';
import Select from '@components/forms/Select';
import DatePicker from '@components/forms/DatePicker';
import { isNil, toNumber } from 'lodash-es';
import ActionButton from '@components/AllocationTable/ActionButton';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import { INPUT_VARIANTS } from '@styles/theme';
import { MILESTONE_STAGE, MILESTONE_STATUS_OPTIONS } from '@app/constants';
import CommentModal from '../../../components/CommentModal';

const getStatusProps = ({ percentComplete, ragStatus }) => {
  if (toNumber(percentComplete) >= 90 || ragStatus === 'Green') {
    return { color: 'green.solid' };
  }
  if (toNumber(percentComplete) >= 75 || ragStatus === 'Amber') {
    return { color: 'orange.solid', icon: 'pending' };
  }
  return { color: 'red.solid', icon: 'pending' };
};

const MilestoneUpdateRow = ({
  idx,
  onAddRow,
  onDeleteRow,
  canBeDeleted,
  onChangeRow,
  milestoneRowId,
  isLast,
  milestone,
  stage,
  reportStatus,
  isAdminOrDL,
  isTeamAdminSideUser,
  isPlugPlay,
  isPrimaryDP,
  mainNameQA,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  let stageIdentifier = '';
  if (stage === MILESTONE_STAGE.UAT) {
    stageIdentifier = 'uat';
  } else if (stage === MILESTONE_STAGE.DELIVERY) {
    stageIdentifier = 'delivery';
  } else if (stage === MILESTONE_STAGE.UPFRONT) {
    stageIdentifier = 'upfront';
  }

  const { color, icon } = getStatusProps(milestone);
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const percentCompleteOrRagStatus =
    stageIdentifier === 'delivery' && !isPlugPlay
      ? `milestones[${idx}].percentComplete`
      : `milestones[${idx}].ragStatus`;

  return (
    <Box w="100%" position="relative">
      <ActionButton
        deleteRow={() => onDeleteRow(milestoneRowId)}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        canBeDeleted={canBeDeleted}
        addRow={onAddRow}
        disallowRowChange={
          reportStatus === 'Submitted' ||
          !isLast ||
          (isTeamAdminSideUser && !isPrimaryDP)
        }
        actionColor={!isLast ? color : 'blue.solid'}
        customIcon={!isLast && icon}
      />
      <Flex
        borderRadius="8px"
        w="100%"
        h="72px"
        mb="12px"
        bg="#fff"
        opacity={isOpen ? 0.5 : 1}
      >
        <Flex
          pl="40px"
          w="30%"
          border="1px solid"
          borderColor="dark.mid"
          borderRight="none"
          borderTopLeftRadius="8px"
          borderBottomLeftRadius="8px"
        >
          <Box
            alignSelf="center"
            w="100%"
            h="70px"
            borderRight="1px solid"
            borderColor="dark.mid"
          >
            <Field
              id={`${stageIdentifier}Milestones[${idx}].name`}
              name={`milestones[${idx}].name`}
              data-test-id={`${mainNameQA}--milestones[${idx}].name`}
              isDisabled={
                !isNil(milestone.baselineDate) ||
                (!isAdminOrDL && !isTeamAdminSideUser) ||
                (isTeamAdminSideUser && !isPrimaryDP)
              }
              variant={INPUT_VARIANTS.CONTAINER_FILLED}
              placeholder="Give this milestone a name"
              render={props => (
                <TextInput
                  {...props}
                  input={{
                    ...props.input,
                    onChange: val => {
                      props.input.onChange(val);
                      const inputName = props.input.name?.split('.')[1];
                      onChangeRow(val, inputName, milestoneRowId);
                    },
                  }}
                />
              )}
            />
          </Box>
        </Flex>
        <Flex width="70%">
          <Flex
            width="20%"
            border="1px solid"
            position="relative"
            borderColor="dark.mid"
            borderLeft="none"
          >
            <Field
              name={percentCompleteOrRagStatus}
              data-test-id={`${mainNameQA}--${percentCompleteOrRagStatus}`}
              variant={INPUT_VARIANTS.CONTAINER_FILLED}
              isDisabled={
                (!isAdminOrDL && !isTeamAdminSideUser) ||
                (isTeamAdminSideUser && !isPrimaryDP)
              }
              placeholder="Status"
              render={props =>
                stageIdentifier === 'delivery' && !isPlugPlay ? (
                  <>
                    <MaskedTextInput
                      variant={INPUT_VARIANTS.CONTAINER_FILLED}
                      decimalScale={0}
                      fixedDecimalScale
                      suffix="%"
                      isAllowed={({ floatValue }) =>
                        (floatValue >= 0 && floatValue <= 100) ||
                        isNil(floatValue)
                      }
                      placeholder="-"
                      {...props}
                    />
                    {!!milestone.id && (
                      <Box
                        w="24px"
                        h="24px"
                        position="absolute"
                        borderRadius="50%"
                        bg={color}
                        top="23px"
                        right="13px"
                      />
                    )}
                  </>
                ) : (
                  <Select
                    {...props}
                    options={MILESTONE_STATUS_OPTIONS}
                    input={{
                      ...props.input,
                      onChange: val => {
                        props.input.onChange(val);
                        const inputName = props.input.name?.split('.')[1];
                        onChangeRow(val, inputName, milestoneRowId);
                      },
                    }}
                  />
                )
              }
            />
          </Flex>
          <Flex
            width="20%"
            border="1px solid"
            borderColor="dark.mid"
            borderLeft="none"
          >
            <Field
              id={`${stageIdentifier}Milestones[${idx}].baselineDate`}
              name={`milestones[${idx}].baselineDate`}
              data-test-id={`${mainNameQA}--milestones[${idx}].baselineDate`}
              h="70px"
              variant={INPUT_VARIANTS.CONTAINER_FILLED}
              placeholder="Select a date"
              calendarProps={{ top: '13px' }}
              calendarColor="dark.mid"
              isDisabled={
                (!isAdminOrDL && !isTeamAdminSideUser) ||
                (isTeamAdminSideUser && !isPrimaryDP)
              }
              px="8px"
              render={props => (
                <DatePicker
                  {...props}
                  input={{
                    ...props.input,

                    onChange: val => {
                      props.input.onChange(val);
                      const inputName = props.input.name?.split('.')[1];
                      onChangeRow(val, inputName, milestoneRowId);
                    },
                  }}
                  minW="auto"
                  dateFormat="DD/MM/YYYY"
                  isDisabled
                />
              )}
            />
          </Flex>
          <Flex
            width="20%"
            border="1px solid"
            borderColor="dark.mid"
            borderLeft="none"
          >
            <Field
              id={`${stageIdentifier}Milestones[${idx}].plannedCompletionDate`}
              name={`milestones[${idx}].plannedCompletionDate`}
              data-test-id={`${mainNameQA}--milestones[${idx}].plannedCompletionDate`}
              isDisabled={
                (!isAdminOrDL && !isTeamAdminSideUser) ||
                (isTeamAdminSideUser && !isPrimaryDP)
              }
              borderTopRightRadius="8px"
              borderBottomRightRadius="8px"
              h="70px"
              variant={INPUT_VARIANTS.CONTAINER_FILLED}
              placeholder="Select a date"
              calendarProps={{ top: '13px' }}
              calendarColor={
                !milestone.plannedCompletionDate ? 'blue.solid' : 'dark.mid'
              }
              px="8px"
              render={props => (
                <DatePicker
                  {...props}
                  minW="auto"
                  dateFormat="DD/MM/YYYY"
                  input={{
                    ...props.input,
                    onChange: val => {
                      props.input.onChange(val);
                      const inputName = props.input.name?.split('.')[1];
                      onChangeRow(val, inputName, milestoneRowId);
                    },
                  }}
                />
              )}
            />
          </Flex>
          <Flex
            width="20%"
            border="1px solid"
            borderColor="dark.mid"
            borderLeft="none"
          >
            <Field
              id={`${stageIdentifier}Milestones[${idx}].actualDate`}
              name={`milestones[${idx}].actualDate`}
              data-test-id={`${mainNameQA}--milestones[${idx}].actualDate`}
              borderTopRightRadius="8px"
              isDisabled={
                (!isAdminOrDL && !isTeamAdminSideUser) ||
                (isTeamAdminSideUser && !isPrimaryDP)
              }
              borderBottomRightRadius="8px"
              h="70px"
              calendarProps={{ top: '13px' }}
              calendarColor={!milestone.actualDate ? 'blue.solid' : 'dark.mid'}
              variant={INPUT_VARIANTS.CONTAINER_FILLED}
              placeholder="Select a date"
              px="8px"
              render={props => (
                <DatePicker
                  minW="auto"
                  dateFormat="DD/MM/YYYY"
                  {...props}
                  input={{
                    ...props.input,
                    onChange: val => {
                      props.input.onChange(val);
                      const inputName = props.input.name?.split('.')[1];
                      onChangeRow(val, inputName, milestoneRowId);
                    },
                  }}
                />
              )}
            />
          </Flex>
          <Flex
            width="25%"
            border="1px solid"
            borderColor="dark.mid"
            borderLeft="none"
            borderTopRightRadius="8px"
            borderBottomRightRadius="8px"
          >
            <Field
              id={`${stageIdentifier}Milestones[${idx}].comment`}
              name={`milestones[${idx}].comment`}
              data-test-id={`${mainNameQA}--milestones[${idx}].comment`}
              borderTopRightRadius="8px"
              borderBottomRightRadius="8px"
              cursor="pointer"
              h="70px"
              variant={INPUT_VARIANTS.CONTAINER_FILLED}
              placeholder="Add a comment here"
              render={props => {
                return (
                  <>
                    <Box position="relative" width="100%">
                      <TextInput
                        disabled={isOpenModal}
                        width="80%"
                        onClick={e => {
                          if (e) {
                            e.stopPropagation();
                            onOpenModal();
                          }
                        }}
                        {...props}
                      />
                      <Icon
                        name="commentRegular"
                        position="absolute"
                        right="10px"
                        fontSize={22}
                        top="36%"
                        color={!milestone.comment ? 'blue.solid' : 'dark.mid'}
                      />
                    </Box>

                    {isOpenModal && (
                      <CommentModal
                        isOpen={isOpenModal}
                        onOpen={onOpenModal}
                        onClose={onCloseModal}
                        defaultValue={milestone.comment}
                        isReadOnly={
                          (!isAdminOrDL && !isTeamAdminSideUser) ||
                          (isTeamAdminSideUser && !isPrimaryDP)
                        }
                        saveComment={value => props.input.onChange(value)}
                      />
                    )}
                  </>
                );
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default MilestoneUpdateRow;
