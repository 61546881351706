import React from 'react';
import ProjectBasicsForm from './ProjectBasicsForm';

const BasicsStep = ({
  initialValues,
  isProfileLoaded,
  customerId,
  briefId,
  onSubmit,
  submit,
  mainNameQA,
}) => {
  const getInitialValues = () => {
    const values = { ...initialValues, step: 1 };
    if (customerId) {
      values.customer = { id: customerId };
    }
    return values;
  };

  return (
    <>
      <ProjectBasicsForm
        submit={submit}
        onSubmit={(v, f) => onSubmit(v, f)}
        initialValues={getInitialValues()}
        isProfileLoaded={isProfileLoaded}
        customerId={customerId}
        briefId={briefId}
        mainNameQA={mainNameQA}
      />
    </>
  );
};

export default BasicsStep;
