import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import Button from '@styles/Button';

const Notification = React.forwardRef(
  (
    {
      iconName,
      onClick,
      messagesCount = 0,
      buttonPoitnerEvent,
      isDisabled,
      color = 'black',
      ...props
    },
    ref,
  ) => {
    return (
      <Box position="relative" ref={ref} {...props} h="40px" mt="8px">
        {messagesCount > 0 && (
          <Flex
            minW="16px"
            h="16px"
            zIndex="1"
            position="absolute"
            top="-1px"
            left="20px"
            bg="red.solid"
            color="#fff"
            borderRadius="4px"
            fontFamily="WB"
            alignItems="center"
            justifyContent="center"
            fontSize={12}
            px="3px"
          >
            {messagesCount > 99 ? '99+' : messagesCount}
          </Flex>
        )}
        <Button
          width="18px"
          h="18px"
          bg="white"
          _disabled={{
            opacity: '0.4',
            bg: 'white',
          }}
          iconButton={{
            name: iconName,
            fontSize: 18,
            color,
          }}
          isDisabled={isDisabled}
          onClick={onClick}
          pointerEvents={buttonPoitnerEvent}
          _focus={{ bg: 'white' }}
          _hover={{ bg: 'white' }}
        />
      </Box>
    );
  },
);

export default Notification;
