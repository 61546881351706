import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import { formatCurrency } from '@utils';
import { toNumber } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';

const AutoCalcCell = ({
  proposalsStore: { totalHours },
  rate,
  currency,
  timePercent: percentOfTime,
  timeHours: numberOfHours,
  placeholder = '-',
  isMobile,
  ...props
}) => {
  const percentAndHours =
    Math.round(percentOfTime >= 0 && (percentOfTime / 100) * totalHours * 2) /
    2;
  const areRateAndHoursSet = !!(rate && (percentAndHours || numberOfHours));
  const text = areRateAndHoursSet
    ? formatCurrency(
        toNumber(rate) * toNumber(percentAndHours || numberOfHours),
        currency,
      )
    : placeholder;
  const bgColor = areRateAndHoursSet ? 'blue.light' : 'dark.light';
  const textColor = areRateAndHoursSet ? 'dark.solid' : 'dark.mid';

  return (
    <Popover usePortal trigger="hover" placement="top">
      <PopoverTrigger>
        <Flex
          px="16px"
          alignItems="center"
          w="25%"
          h="100%"
          bg={bgColor}
          borderTop={isMobile && '1px solid'}
          borderBottom={isMobile && '1px solid'}
          borderLeft="1px solid"
          borderRight="1px solid"
          borderColor="dark.mid"
          borderTopRightRadius="8px"
          borderBottomRightRadius="8px"
          {...props}
        >
          <Typography
            color={textColor}
            mr="auto"
            fontSize={isMobile && '15px'}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {text}
          </Typography>
          {areRateAndHoursSet && (
            <Icon name="link" color="blue.solid" fontSize={24} mb="2px" />
          )}
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        zIndex={1000}
        bg="dark.solid"
        lineHeight={1.4}
        color="#fff"
        borderRadius="8px"
      >
        <PopoverArrow bg="dark.solid" boxShadow="none" />
        <PopoverBody px="16px" pt="16px" pb="32px" textAlign="center">
          <Typography mb="10px" variant="title">
            Cost: {text}
          </Typography>
          Cell value is automatically calculated based on rates
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default inject('proposalsStore')(observer(AutoCalcCell));
