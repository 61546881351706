import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useMediaQuery from '@hooks/useMediaQuery';
import { inject, observer } from 'mobx-react';
import { useDisclosure, Box, Flex } from '@chakra-ui/react';
import Button from '@styles/Button';
import BriefActions from '@components/BriefActions';
import { NEW_PROPOSAL_STATUSES, MAIN_NAMES_QA } from '@app/constants';
import qs from 'query-string';
import { APP_ROUTES } from '@routes';
import { find, isEmpty } from 'lodash-es';
import Typography from '@styles/Typography';
import ConvertBriefWarningModal from '@pages/ProjectsCreate/ConvertBriefWarningModal';
import Badge from '@styles/Badge';
import NotesForm from './NotesForm';
import TargetValueForm from './TargetValueForm';
import BriefAcceptedModal from '../BriefAcceptedModal';
import BriefDeclineModal from '../BriefDeclineModal';
import NotesList from './NotesList';
import BriefDetails from './BriefDetails';
import ExclusivityDpBanner from './ExclusivityDpBanner';

const Overview = ({
  briefsStore: {
    brief,
    addNote,
    addTargetValue,
    acceptBrief,
    declineBrief,
    supplierNotes,
  },
  usersStore: { isAdminOrDL, isClient, isTeamAdmin },
  location: { search },
  routerStore: { history },
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const isPlugAndPlay = !!brief.newOrExisting;
  const isReadOnly = brief.isClosed;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConvertWarningOpen,
    onOpen: onConvertWarningOpen,
    onClose: onConvertWarningClose,
  } = useDisclosure();
  const {
    isOpen: isDeclineOpen,
    onOpen: onDeclineOpen,
    onClose: onDeclineClose,
  } = useDisclosure();

  React.useEffect(() => {
    const { actionType } = qs.parse(search);
    if (actionType === 'reject' && isTeamAdmin) {
      onDeclineOpen();
    }
  }, [search, isTeamAdmin]);

  const mainNameQA = isPlugAndPlay
    ? MAIN_NAMES_QA.TEAM_AUGMENTATION_BRIEF_OVERVIEW
    : MAIN_NAMES_QA.PROJECT_BRIEF_OVERVIEW;

  const {
    projectCreateLink,
    briefEditLink,
    birefMergeToProjectLink,
  } = React.useMemo(() => {
    return {
      projectCreateLink: !isPlugAndPlay
        ? `${APP_ROUTES.projectsCreate}?briefId=${brief.id}`
        : `${APP_ROUTES.projectsCreate}?ppBriefId=${brief.id}`,
      briefEditLink: !isPlugAndPlay
        ? APP_ROUTES.briefsEdit(brief.id)
        : APP_ROUTES.briefsEditPlugAndPlay(brief.id),
      birefMergeToProjectLink: !isPlugAndPlay
        ? `${APP_ROUTES.projects}?briefId=${brief.id}&status=active`
        : `${APP_ROUTES.projects}?ppBriefId=${brief.id}&status=active`,
    };
  }, [brief.id, isPlugAndPlay]);

  return (
    <>
      <ConvertBriefWarningModal
        isOpen={isConvertWarningOpen}
        onClose={onConvertWarningClose}
        onContinue={() => history.push(birefMergeToProjectLink)}
      />
      <BriefDeclineModal
        isOpen={isDeclineOpen}
        onClose={onDeclineClose}
        projectName={brief?.name}
        onSubmit={({ changeStatusReason }) =>
          declineBrief(
            {
              briefId: brief.id,
              changeStatusReason,
              isPlugAndPlay,
              supplierId: brief?.supplierProposal?.supplier?.id,
            },
            onDeclineClose,
          )
        }
        clientName={brief?.customer?.name}
      />
      <BriefAcceptedModal
        isOpen={isOpen}
        onClose={onClose}
        projectName={brief?.name}
        clientName={brief?.customer?.name}
      />
      {isTeamAdmin && <ExclusivityDpBanner />}
      <BriefActions
        brief={brief}
        showBriefActions={isTeamAdmin}
        acceptBrief={() =>
          acceptBrief(
            {
              briefId: brief.id,
              isPlugAndPlay,
              supplierId: brief?.supplierProposal?.supplier?.id,
            },
            onOpen,
          )
        }
        declineBrief={onDeclineOpen}
      />
      {isTeamAdmin && !isEmpty(supplierNotes) && (
        <Box borderBottom="1px solid" mb="12px">
          <NotesList notes={supplierNotes} isSupplierView />
        </Box>
      )}
      <Flex
        justifyContent="flex-end"
        alignItems="flex-end"
        {...(isSmallerScreen && {
          flexWrap: 'wrap',
          mt: isAdminOrDL ? '-24px' : 0,
        })}
      >
        {brief.isAssigned && (
          <Flex
            mr="auto"
            alignItems="center"
            {...(isSmallerScreen && {
              bg: 'green.mid',
              p: '12px',
              borderRadius: '8px',
              w: '100%',
              justifyContent: 'center',
            })}
          >
            <Typography variant="text4">Brief status</Typography>
            <Badge ml="8px" bg="green" variant={isSmallerScreen && 'secondary'}>
              ASSIGNED
            </Badge>
          </Flex>
        )}
        <Flex
          {...(isSmallerScreen && {
            flexWrap: 'wrap',
            w: '100%',
            mt: '12px',
            flexDir: 'column-reverse',
          })}
        >
          {brief.isEditable && (
            <Button
              variant="outline"
              leftIcon={{ name: 'edit' }}
              {...(!isReadOnly && {
                as: RouterLink,
                to: briefEditLink,
              })}
              isDisabled={isReadOnly}
              {...(isSmallerScreen && { w: '100%', mt: '12px' })}
              data-test-id={`${mainNameQA}--editBrief`}
            >
              Edit
            </Button>
          )}
          {isAdminOrDL && (
            <>
              {isPlugAndPlay && (
                <Button
                  leftIcon={{ name: 'merge' }}
                  variant="outline"
                  ml="20px"
                  data-test-id={`${mainNameQA}--mergeBriefToProject`}
                  onClick={() => {
                    if (
                      brief.id &&
                      !find(
                        brief?.ppBriefToSuppliers,
                        bts =>
                          bts?.proposal?.status === NEW_PROPOSAL_STATUSES.WON,
                      )
                    ) {
                      onConvertWarningOpen();
                    } else {
                      history.push(birefMergeToProjectLink);
                    }
                  }}
                  {...(isSmallerScreen && { w: '100%', mt: '12px', ml: 0 })}
                  isDisabled={isReadOnly}
                >
                  Merge to existing project
                </Button>
              )}
              <Button
                leftIcon={{ name: 'add' }}
                ml="20px"
                {...(!isReadOnly && {
                  as: RouterLink,
                  to: projectCreateLink,
                })}
                {...(isSmallerScreen && { w: '100%', mt: '12px', ml: 0 })}
                isDisabled={isReadOnly}
                data-test-id={`${mainNameQA}--createProjectFromBrief`}
              >
                Create project from brief
              </Button>
            </>
          )}
        </Flex>
      </Flex>

      <TargetValueForm
        isSmallerScreen={isSmallerScreen}
        isAdminOrDL={isAdminOrDL}
        isTeamAdmin={isTeamAdmin}
        isReadOnly={isReadOnly}
        brief={brief}
        initialValues={{
          clientLead: brief.clientLead,
          targetValue: brief.targetValue,
          proposalClosingDate: brief.proposalClosingDate,
          pm: brief.pm,
        }}
        onSubmit={addTargetValue}
        mainNameQA={mainNameQA}
      />
      <BriefDetails
        isSmallerScreen={isSmallerScreen}
        brief={brief}
        isAdminOrDL={isAdminOrDL}
        isClient={isClient}
        isReadOnly={isReadOnly}
        mainNameQA={mainNameQA}
      />

      {(isAdminOrDL || isTeamAdmin) && !isPlugAndPlay && (
        <NotesForm
          isSmallerScreen={isSmallerScreen}
          isAdminOrDL={isAdminOrDL}
          onSubmit={addNote}
          notes={brief.notes}
          isReadOnly={isReadOnly}
          mainNameQA={mainNameQA}
        />
      )}
    </>
  );
};

export default inject(
  'routerStore',
  'briefsStore',
  'usersStore',
)(observer(Overview));
