import { Box, Text } from '@chakra-ui/react';
import TextInput from '@components/forms/_common/TextInput';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import { first, round, split, toNumber } from 'lodash-es';
import React from 'react';
import NumberFormat from 'react-number-format';

const CustomInput = ({ onFocus, input = {}, ...props }) => (
  <TextInput
    input={input}
    {...props}
    onClick={e => {
      if (e && e.target && e.target.select) {
        e.target.select();
      }
    }}
    onBlur={input.onBlur}
    onFocus={input.onFocus}
  />
);

export const PercentInput = ({ onFocus, input = {}, ...props }) => (
  <Box position="relative">
    <Typography
      top="34px"
      zIndex="30"
      left="11px"
      position="absolute"
      fontSize={24}
      fontFamily="WB"
    >
      %
    </Typography>
    <TextInput
      input={input}
      {...props}
      onClick={e => {
        if (e && e.target && e.target.select) {
          e.target.select();
        }
      }}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      pl="40px"
    />
  </Box>
);

const MaskedTextInput = ({
  input = {},
  meta,
  thousandSeparator = true,
  prefix = '',
  decimalScale = 2,
  suffix = '',
  onAfterChange,
  changeIfActive = false,
  allowNegative = false,
  customInput = CustomInput,
  isAllowed = () => true,
  ...props
}) => {
  return (
    <>
      <NumberFormat
        customInput={customInput}
        thousandSeparator={thousandSeparator}
        prefix={prefix}
        suffix={suffix}
        meta={meta}
        input={input}
        {...props}
        value={
          decimalScale > 0 && input.value
            ? round(input.value, decimalScale)
            : input.value
        }
        decimalScale={decimalScale}
        name={input.name}
        isAllowed={arg => {
          if (
            arg.value &&
            toNumber(first(split(arg.value, '.'))).toString() !==
              first(split(arg.value, '.'))
          ) {
            return false;
          }
          return isAllowed(arg);
        }}
        allowNegative={allowNegative}
        onValueChange={values => {
          const { value } = values;
          input.onChange(value);

          if (typeof onAfterChange === 'function') {
            if (changeIfActive && meta.active) {
              onAfterChange(value);
            }
            if (!changeIfActive) {
              onAfterChange(value);
            }
          }
        }}
      />
    </>
  );
};

export const RightIconInput = ({
  rightPlacement = true,
  iconName,
  input = {},
  iconProps,
  iconTagQA,
  wrapperProps,
  ...props
}) => (
  <Box position="relative" {...wrapperProps}>
    {iconName === 'percent' ? (
      <Text
        top="37px"
        zIndex="30"
        right="11px"
        position="absolute"
        fontSize={24}
        fontFamily="WB"
      >
        %
      </Text>
    ) : (
      <Icon
        top="37px"
        zIndex="30"
        {...(rightPlacement ? { right: '9px' } : { left: '9px' })}
        position="absolute"
        fontSize={24}
        {...iconProps}
        data-test-id={iconTagQA}
        name={iconName}
      />
    )}
    <MaskedTextInput
      input={input}
      {...props}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      pr="40px"
    />
  </Box>
);

export default MaskedTextInput;
