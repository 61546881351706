import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2" mb="24px" textAlign="center">
        Edit allocations?
      </Typography>

      <Typography mb="40px" textAlign="center" fontSize="15px">
        Editing the allocations will change the invoices associated with the
        project. Are you sure you want to continue?
      </Typography>

      <Stack spacing={3}>
        <Button h="44px" onClick={onConfirm}>
          Continue to edit
        </Button>
        <Button h="44px" onClick={onClose} variant="outline">
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};

export default ConfirmationModal;
