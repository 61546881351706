import { Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import useMediaQuery from '@hooks/useMediaQuery';
import { map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form } from 'react-final-form';
import { getPnpResourceCurrencyRate } from '@utils/briefUtils';
import AutoSave from '../Proposal/AutoSave';
import PPRoleRow from './PPRoleRow';

const TableHeadings = () => {
  return (
    <Flex w="100%" mb="16px">
      <Typography flex={0.5} pl="20px" variant="caption">
        Candidate name
      </Typography>
      <Typography flex={0.5} variant="caption">
        Role
      </Typography>
      <Typography flex={0.5} variant="caption">
        Seniority
      </Typography>
      <Typography w="145px" variant="caption">
        Delivery Partner Rate
      </Typography>
      <Typography w="145px" variant="caption">
        Client rate
      </Typography>
      <Typography w="100px" variant="caption">
        % Margin
      </Typography>
      <Typography w="145px" variant="caption">
        Cost per month
      </Typography>
    </Flex>
  );
};

const PlugPlayResourceTable = ({
  initialValues,
  isReadOnly,
  proposalsStore: { updatePPResource },
  briefsStore: { brief },
  mainNameQA,
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  return (
    <Form
      initialValues={initialValues}
      onSubmit={updatePPResource}
      render={({ handleSubmit, values, form }) => {
        return (
          <form onSubmit={handleSubmit}>
            {!isSmallerScreen && (
              <Typography variant="h4" mb="30px">
                Roles
              </Typography>
            )}
            <AutoSave
              formName="deazyLayerPPResources"
              formApi={form}
              onSave={updatePPResource}
            />
            {!isSmallerScreen && <TableHeadings />}
            {map(values.resources, (r, idx) =>
              r?.toBeDeleted ? null : (
                <PPRoleRow
                  isSmallerScreen={isSmallerScreen}
                  isReadOnly={isReadOnly || r.isReadOnly}
                  idx={idx}
                  key={`pp-resource-core-team-${r.id}`}
                  resource={r}
                  formApi={form}
                  proposalSupplierCurrency={
                    r?.ppBriefProposal?.overriddenCurrency
                  }
                  proposalExchangeRate={getPnpResourceCurrencyRate(r, brief)}
                  mainNameQA={mainNameQA}
                />
              ),
            )}
          </form>
        );
      }}
    />
  );
};

export default inject(
  'proposalsStore',
  'briefsStore',
)(observer(PlugPlayResourceTable));
