import { Flex, Spinner } from '@chakra-ui/react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { min, map } from 'lodash-es';
import React from 'react';
import { FormSpy } from 'react-final-form';
import Typography from '@styles/Typography';

const ForecastedValueBox = inject('paymentsStore')(
  observer(
    ({
      formValues = {},
      fieldPrefix,
      isWeekly,
      selectedSupplier,
      submitting,
      paymentsStore: { fetchForecastedValue, forecast, forecastDeposit },
      mainNameQA,
    }) => {
      const isClient = fieldPrefix === 'client';
      const { client = {}, suppliers = [{}] } = formValues;

      const supplierDates = map(suppliers, sup => new Date(sup.invoiceDate));

      const minInvoiceDate = React.useMemo(() => {
        return min([...supplierDates, new Date(client.invoiceDate)]);
      }, [client.invoiceDate, JSON.stringify(supplierDates)]);

      React.useEffect(() => {
        if (!isWeekly && isClient && !submitting) {
          fetchForecastedValue({
            isClient,
            minInvoiceDate,
          });
        }
      }, [isWeekly, submitting, minInvoiceDate]);

      React.useEffect(() => {
        if (!isWeekly && !isClient && !submitting) {
          fetchForecastedValue({
            isClient,
            minInvoiceDate,
            projectToSupplierId: selectedSupplier?.pts?.id,
          });
        }
      }, [isWeekly, minInvoiceDate]);

      const value = isWeekly
        ? { ...toJS(forecastDeposit) }
        : { ...toJS(forecast) };

      return (
        <Flex
          alignItems="center"
          justifyContent="center"
          border="1px solid"
          h="48px"
          px="12px"
          ml="40px"
          maxW="136px"
          w="100%"
          borderColor="dark.mid"
          borderRadius="8px"
        >
          {value[isClient ? 'client' : 'supplier'].isLoading ? (
            <Spinner />
          ) : (
            <Typography
              w="100%"
              data-test-id={`${mainNameQA}--${fieldPrefix}.${
                value[isClient ? 'client' : 'supplier'].value
              }`}
            >
              {value[isClient ? 'client' : 'supplier'].value}
            </Typography>
          )}
        </Flex>
      );
    },
  ),
);

const ForecastBox = ({ mainNameQA, ...props }) => {
  return (
    <FormSpy>
      {({ values, submitting }) => (
        <ForecastedValueBox
          {...props}
          formValues={values}
          submitting={submitting}
          mainNameQA={mainNameQA}
        />
      )}
    </FormSpy>
  );
};

export default ForecastBox;
