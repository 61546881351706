import { Box } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Tooltip from '@styles/Tooltip';
import Typography from '@styles/Typography';
import React from 'react';

const PurchaseOrderStat = ({ isInheritedFromProject, purchaseOrder }) => {
  return (
    <Typography
      variant="text2"
      color={isInheritedFromProject && 'rgba(43, 35, 29, 0.5)'}
      textDecoration={isInheritedFromProject && 'underline'}
      as="div"
    >
      {purchaseOrder || 'N/A'}
      {isInheritedFromProject && purchaseOrder && (
        <Tooltip label="Purchase order inherited from project" hasArrow>
          <Box as="span" ml="8px" mr="15px">
            <Icon mb="3px" name="help" fontSize={18} />
          </Box>
        </Tooltip>
      )}
    </Typography>
  );
};

export default PurchaseOrderStat;
