import { Stack } from '@chakra-ui/react';
import DatePicker from '@components/forms/DatePicker';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { required } from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';

const CompleteSowModal = ({ isOpen, onClose, onComplete }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2" mb="20px">
        SOW signed date
      </Typography>
      <Form
        onSubmit={onComplete}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Field
                component={DatePicker}
                isOverlayFixed
                isErrorAbsolute
                name="date"
                validate={required}
              />
              <Stack spacing={3} mt="40px">
                <Button isLoading={submitting} type="submit">
                  Complete SOW
                </Button>
                <Button
                  variant="outline"
                  onClick={onClose}
                  isDisabled={submitting}
                >
                  Cancel
                </Button>
              </Stack>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default CompleteSowModal;
