import React from 'react';
import AllocationTable from '@components/AllocationTable';
import Typography from '@styles/Typography';
import { AllocationTableWrapper } from './FixedPriceAllocations';

const ProjectAllocations = ({
  fieldName,
  mainNameQA,
  label = 'Allocation',
  ...props
}) => (
  <>
    <Typography variant="h3" mb="32px">
      {label}
    </Typography>
    <AllocationTableWrapper p="40px" {...props}>
      <AllocationTable fieldName={fieldName} mainNameQA={mainNameQA} />
    </AllocationTableWrapper>
  </>
);

export default ProjectAllocations;
