import { Box, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { addFullDescription } from '@utils/usersUtils';
import { get, isEmpty, map } from 'lodash-es';
import moment from 'moment';
import React from 'react';
import DeleteQuestionModal from './DeleteQuestionModal';
import QuestionReplyForm from './QuestionReplyForm';

const buttonProps = {
  color: '#000',
  bg: 'primary.100',
  width: '40px',
};

const authorTextProps = {
  color: 'gray.400',
};

const QuestionListItem = ({
  briefId,
  isAdminOrDL,
  createQuestionReply,
  question = {},
  deleteQuestion,
  toggleQuestion,
  author,
  isReadOnly,
  mainNameQA,
}) => {
  const [deleteValues, setDeleteValues] = React.useState({});
  const { isOpen: isReplyOpen, onOpen, onClose } = useDisclosure();
  const onReplyOpen = React.useCallback(() => {
    onOpen();
    if (!question.isOpened) {
      toggleQuestion();
    }
  }, []);
  return (
    <Flex
      flexDirection="column"
      border="1px solid"
      borderColor="dark.mid"
      bg="#fff"
      borderRadius="8px"
      mb="12px"
      data-test-id={`${mainNameQA}--singleQuestion`}
    >
      <Flex
        w="100%"
        justifyContent="space-between"
        py="24px"
        px="16px"
        alignItems="center"
      >
        <Box>
          <Typography
            variant="h4"
            mb="12px"
            data-test-id={`${mainNameQA}--singleQuestion-title`}
          >
            {question.title}
          </Typography>
          <Stack isInline>
            <Typography
              variant="caption"
              color="blue.solid"
              data-test-id={`${mainNameQA}--singleQuestion-repliesCount`}
            >
              {`(${question.repliesCount})`}
            </Typography>
            <Typography
              variant="caption"
              data-test-id={`${mainNameQA}--singleQuestion-description`}
            >{`${addFullDescription(
              get(question, 'author.roles[0].name', '-'),
            )} | ${moment(question.createdAt).format(
              'DD MMM YYYY',
            )} at ${moment(question.createdAt).format('hh:mmA')}`}</Typography>
          </Stack>
        </Box>
        <Flex>
          {isAdminOrDL && (
            <Button
              onClick={() =>
                setDeleteValues({ questionId: question.id, briefId })
              }
              iconButton={{ name: 'trashNew', fontSize: 18 }}
              isDisabled={isReadOnly}
              mr="12px"
              data-test-id={`${mainNameQA}--singleQuestion-delete`}
              {...buttonProps}
            />
          )}
          <Button
            iconButton={{ name: 'undo', fontSize: 18 }}
            mr="12px"
            data-test-id={`${mainNameQA}--singleQuestion-udno`}
            isDisabled={isReadOnly}
            onClick={onReplyOpen}
            {...buttonProps}
          />
          <Button
            iconButton={{ name: 'arrowLeft', fontSize: 24 }}
            data-test-id={`${mainNameQA}--singleQuestion-open`}
            transform={question.isOpened ? 'rotate(90deg)' : 'rotate(-90deg)'}
            onClick={() => {
              if (question.isOpened) {
                onClose();
              }
              toggleQuestion();
            }}
            {...buttonProps}
          />
        </Flex>
      </Flex>
      {question.isOpened && (
        <Box py="40px" px="32px" borderTop="1px solid" borderColor="dark.mid">
          <Typography
            variant="title"
            mb="24px"
            data-test-id={`${mainNameQA}--singleQuestion-name`}
          >
            {question?.feature?.name || 'General Question'}
          </Typography>
          <Typography
            whiteSpace="pre-wrap"
            data-test-id={`${mainNameQA}--singleQuestion-question`}
          >
            {question.question}
          </Typography>
          {map(question.replies, reply => (
            <Box
              key={`question-reply-${reply.id}`}
              borderTop="1px solid"
              borderColor="dark.mid"
              pt="46px"
              mt="24px"
              data-test-id={`${mainNameQA}--singleQuestion-replay`}
            >
              <Stack isInline mb="22px">
                <Icon name="undo" color="gray.400" />
                <Typography
                  variant="caption"
                  {...authorTextProps}
                  mb="16px"
                  mr="12px"
                  data-test-id={`${mainNameQA}--singleQuestion-replyDescription`}
                >
                  {`${addFullDescription(
                    get(reply, 'author.roles[0].name', '-'),
                  )} | ${moment(reply.createdAt).format(
                    'DD MMM YYYY',
                  )} at ${moment(reply.createdAt).format('hh:mmA')}`}
                </Typography>
              </Stack>
              <Typography
                whiteSpace="pre-wrap"
                data-test-id={`${mainNameQA}--singleQuestion-replayText`}
              >
                {reply.text}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      {isReplyOpen && (
        <QuestionReplyForm
          onSubmit={values => createQuestionReply(values, onClose)}
          initialValues={{ briefId, questionId: question.id }}
          onClose={onClose}
          author={author}
          mainNameQA={mainNameQA}
        />
      )}
      <DeleteQuestionModal
        isLoading={question.isDeleting}
        isOpen={!isEmpty(deleteValues)}
        onClose={() => setDeleteValues({})}
        onDelete={() => deleteQuestion(deleteValues, () => setDeleteValues({}))}
      />
    </Flex>
  );
};

export default QuestionListItem;
