import { get } from 'lodash-es';
import React from 'react';
import useDownload from '@hooks/useDownload';
import useFileNameTurncate from '@hooks/useFileNameTurncate';
import Typography from '@styles/Typography';
import { Flex } from '@chakra-ui/react';
import Button from '@styles/Button';

const CvDownloadSection = ({ candidate }) => {
  const plugPlayProposalCv = get(candidate, 'plugPlayProposalCv') || {};
  const { onDownload, isLoading } = useDownload({
    url: plugPlayProposalCv.link,
    fileName: plugPlayProposalCv.name,
  });

  const containerRef = React.useRef();
  const textRef = React.useRef();

  const turncatedText = useFileNameTurncate({
    containerRef,
    textRef,
    fileName: plugPlayProposalCv.name || '',
    additionalOffset: 45,
  });

  return (
    <Flex w="100%" flexDir="column">
      <Typography pl="10px" variant="text2">
        Curriculum vitae
      </Typography>
      <Flex
        alignItems="center"
        onClick={plugPlayProposalCv.link && !isLoading ? onDownload : () => {}}
        cursor="pointer"
        pt="14px"
        ref={containerRef}
        overflow="hidden"
      >
        {plugPlayProposalCv.link ? (
          <>
            <Button
              mr="4px"
              fontSize={24}
              p="0px"
              variant="ghost"
              borderRadius="50%"
              iconButton={{ name: 'download', fontSize: 24 }}
              isLoading={isLoading}
            />
            <Typography
              transition="all 0.5s"
              whiteSpace="nowrap"
              color="blue.solid"
              mb="3px"
              ref={textRef}
            >
              {turncatedText}
            </Typography>
          </>
        ) : (
          <Flex alignItems="center" px="16px" h="100%">
            No file here
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default CvDownloadSection;
