import { APP_ROUTES } from '@routes';
import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { inject, observer } from 'mobx-react';
import FinishStepForm from './FinishStepForm';
import SuccessModal from '../SuccessModal';

const FinishStep = ({
  initialValues,
  saveBrief,
  onSubmit,
  submit,
  history,
  briefId,
  mainNameQA,
  briefsStore: { brief = {} },
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onPrevClick = async (values, formApi) => {
    await saveBrief(values, formApi);
    if (briefId) {
      history.push(APP_ROUTES.briefsEditTechStack(briefId));
    } else {
      history.push(APP_ROUTES.briefsCreateTechStack);
    }
  };

  const onSuccessClose = () => {
    onClose();
    if (brief.id) {
      history.push(APP_ROUTES.briefById(brief.id));
    } else {
      history.push(APP_ROUTES.briefs);
    }
  };

  return (
    <>
      <FinishStepForm
        onPrevClick={onPrevClick}
        onSubmit={(values, formApi) => onSubmit(values, formApi, onOpen)}
        initialValues={{ ...initialValues, step: 3 }}
        submit={submit}
        mainNameQA={mainNameQA}
      />
      <SuccessModal isOpen={isOpen} onClose={onSuccessClose} />
    </>
  );
};

export default inject('briefsStore')(observer(FinishStep));
