import { transformToArray } from '@utils';
import { findIndex, flatten, isEmpty, map, orderBy, reject } from 'lodash-es';
import { CloseButton, Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { inject, observer } from 'mobx-react';
import qs from 'query-string';
import React from 'react';
import { withRouter } from 'react-router';

const FilterChip = ({ label, onClick }) => {
  return (
    <Flex
      alignItems="center"
      borderRadius="32px"
      bg="dark.mid"
      py="4px"
      pl="16px"
      pr="8px"
      h="24px"
      ml="8px"
      mb="10px"
    >
      <Typography variant="caption">{label}</Typography>
      <CloseButton
        onClick={onClick}
        height="24px"
        fontSize="8px"
        _focus={{ bg: 'transparent' }}
      />
    </Flex>
  );
};

const PageChips = ({ history, usersStore: { isAdminOrDL } }) => {
  const params = qs.parse(history.location.search);
  const {
    actionType,
    ragStatus,
    supplierNames,
    deazyLeadNames,
    techStack,
  } = params;

  const chips = orderBy(
    flatten([
      map(transformToArray(actionType), value => ({
        groupType: 'actionType',
        value,
      })),
      map(transformToArray(ragStatus), value => ({
        groupType: 'ragStatus',
        value,
      })),
      map(transformToArray(supplierNames), value => ({
        groupType: 'supplierNames',
        value,
      })),
      map(transformToArray(deazyLeadNames), value => ({
        groupType: 'deazyLeadNames',
        value,
      })),
      map(transformToArray(techStack), value => ({
        groupType: 'techStack',
        value,
      })),
    ]),
    'value',
    'desc',
  );

  const onRemoveChip = ({ groupType, value }) => {
    const paramsToRemove = { ...params };
    if (groupType === 'supplierNames') {
      const idx = findIndex(
        transformToArray(paramsToRemove[groupType]),
        param => param === value,
      );
      paramsToRemove.supplierIds = reject(
        transformToArray(paramsToRemove.supplierIds),
        (_, i) => idx === i,
      );
    }
    if (groupType === 'deazyLeadNames') {
      const idx = findIndex(
        transformToArray(paramsToRemove[groupType]),
        param => param === value,
      );
      paramsToRemove.deazyLeadIds = reject(
        transformToArray(paramsToRemove.deazyLeadIds),
        (_, i) => idx === i,
      );
    }
    paramsToRemove[groupType] = reject(
      transformToArray(paramsToRemove[groupType]),
      param => param === value,
    );
    return history.push(
      `${history.location.pathname}?${qs.stringify(paramsToRemove)}`,
    );
  };
  if (!isAdminOrDL) {
    return null;
  }

  return (
    !isEmpty(chips) && (
      <Flex marginBottom="20px" wrap="wrap" direction="row-reverse">
        {map(chips, chip => (
          <FilterChip
            key={`page-chip-${chip.value}-${chip.groupType}`}
            label={chip.value}
            onClick={() => onRemoveChip(chip)}
          />
        ))}
      </Flex>
    )
  );
};

export default withRouter(inject('usersStore')(observer(PageChips)));
