import { Box, CloseButton, useDisclosure } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import TeamCard from '@pages/Teams/TeamCard';
import Typography from '@styles/Typography';
import { isEmpty } from 'lodash-es';
import React from 'react';
import CheckInvoiceNumbering from './CheckInvoiceNumbering';
import InvoicesListTable from './InvoicesListTable';
import SupplierGeneralInfo from './SupplierGeneralInfo';
import UsersDetails from './UsersDetails';

const SupplierDetails = ({
  supplier,
  addUserToSupplier,
  deleteUserFromSupplier,
  fetchSupplier,
  isSupplierRoute,
  checkInvoiceNumberingForSuppliers,
  supplierInvoices = [],
  isAdminOrDL,
  isTeamAdmin,
  isTeamAdminSideUser,
  createUserByRole,
  toastsStore,
  profile,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleAddUser = (supplierId, userId) => {
    return addUserToSupplier({
      supplierId,
      memberId: userId,
      onSuccess: () => fetchSupplier(supplierId),
    });
  };

  const handleRemoveUser = (userId, supplierId) => {
    return deleteUserFromSupplier({
      userId,
      supplierId,
      onSuccess: () => fetchSupplier(supplierId),
    });
  };

  return (
    <Box w="100%" maxW="1200px" mx="auto">
      <Box w="100%" maxW="1200px" mx="auto">
        <TeamCard isStatic>
          <SupplierGeneralInfo team={supplier} />
        </TeamCard>
      </Box>
      <UsersDetails
        users={supplier.users}
        teamId={supplier.id}
        handleAddUser={handleAddUser}
        handleRemoveUser={handleRemoveUser}
        isAdminOrDL={isAdminOrDL}
        createUserByRole={createUserByRole}
        supplier={supplier}
        isTeamAdmin={isTeamAdmin}
        profile={profile}
        isSupplierRoute={isSupplierRoute}
        fetchSupplier={fetchSupplier}
        toastsStore={toastsStore}
      />
      <CheckInvoiceNumbering
        supplierId={supplier.id}
        checkInvoiceNumberingForSuppliers={checkInvoiceNumberingForSuppliers}
        onOpen={onOpen}
      />

      {isOpen && (
        <WhiteCard p="40px">
          <CloseButton
            onClick={onClose}
            size="lg"
            position="absolute"
            right="10px"
            top="10px"
          />
          {!isEmpty(supplierInvoices) ? (
            <InvoicesListTable
              isAdminOrDL={isAdminOrDL}
              tableData={supplierInvoices}
              isTeamAdminSideUser={isTeamAdminSideUser}
              isTeamAdmin={isTeamAdmin}
            />
          ) : (
            <Typography>
              This Delivery Partner does not have invoices for a selected
              period.
            </Typography>
          )}
        </WhiteCard>
      )}
    </Box>
  );
};

export default SupplierDetails;
