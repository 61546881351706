import { computed, action, observable } from 'mobx';
import { RouterStore as MobxRouterStore } from 'mobx-react-router';
import { APP_ROUTES } from '@routes';
import { find, isEmpty, includes, replace } from 'lodash-es';

class RouterStore extends MobxRouterStore {
  @action redirectFromOldPlugAndPlayRoute = () => {
    const {
      location: { pathname, search },
    } = this.history;
    if (includes(pathname, 'plug-and-play-briefs')) {
      this.history.push(
        replace(
          `${pathname}${search}`,
          'plug-and-play-briefs',
          'team-augmentation-briefs',
        ),
      );
    }
  };

  @action watchPrevLocation = () => {
    this.pageMeta = this.preparePageMeta(this.history.location);
    this.history.listen(location => {
      this.pageMeta = this.preparePageMeta(location);
    });
  };

  @observable pageMeta = {
    fullHistory: [],
  };

  preparePageMeta = location => {
    const prevPageYOffset = window.pageYOffset;
    const fullHistory = [...this.pageMeta.fullHistory];
    if (fullHistory[0]) {
      fullHistory[0].pageYOffset = prevPageYOffset;
    }
    return {
      fullHistory: [
        {
          search: location.search,
          pathname: location.pathname,
        },
        ...fullHistory,
      ],
    };
  };

  @action getLastDpListingUrl = () => {
    const { fullHistory } = this.pageMeta;
    const location = find(
      fullHistory,
      item => item.pathname === APP_ROUTES.teams.suppliers,
    );
    return location ? `${location.pathname}${location.search}` : null;
  };

  @action prepareUrl = pathToSearch => {
    const fullHistory = [...this.pageMeta.fullHistory];
    if (!isEmpty(fullHistory)) {
      fullHistory.shift();
    }
    const [currentItem = {}, prevItem = {}] = this.pageMeta.fullHistory;
    const history = find(fullHistory, h => h.pathname === pathToSearch) || {};

    if (history?.pathname) {
      return {
        url: `${history.pathname}${history.search || ''}`,
        pageYOffset:
          prevItem.pathname && currentItem.pathname === prevItem.pathname
            ? 0
            : history.pageYOffset,
      };
    }
    return { url: pathToSearch, pageYOffset: 0 };
  };

  @computed get prevProjectsListUrl() {
    return this.prepareUrl(APP_ROUTES.projects);
  }

  @computed get prevBriefsListUrl() {
    return this.prepareUrl(APP_ROUTES.briefs);
  }

  @computed get prevPnPBriefsListUrl() {
    return this.prepareUrl(APP_ROUTES.briefsPlugAndPlay);
  }
}

export default new RouterStore();
