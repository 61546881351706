import { useCallback, useEffect, useState } from 'react';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { isEmpty, isEqual, isNil, omit, omitBy } from 'lodash-es';

const initialState = {
  items: [],
  totalPages: '...',
  totals: '...',
};

const useListing = (fetchFn, defaultParams = {}, customMap) => {
  const {
    push,
    location: { search },
  } = useHistory();

  const [data, setData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const pageParams = omit(qs.parse(search), 'editInvoiceId');
  const paramsToWatch = qs.stringify(pageParams);

  const onFilterChange = obj => {
    push(
      `?${qs.stringify(
        omitBy(
          {
            ...pageParams,
            page: 1,
            ...obj,
          },
          isNil,
        ),
      )}`,
    );
  };

  const fetch = useCallback(
    async (p = {}) => {
      const params = qs.parse(search);
      if (!isEmpty(p) && !isEqual({ ...p, ...params }, params)) {
        return push(`?${qs.stringify({ ...params, ...p })}`);
      }
      try {
        setIsLoading(true);
        const {
          data: {
            items,
            paging: { totalPages, totalObjects: totals },
          },
        } = await fetchFn({
          params: {
            ...defaultParams,
            ...params,
            ...p,
            pageSize: params?.pageSize || 10,
          },
        });

        setData({
          items: customMap ? customMap(items) : items,
          totalPages,
          totals,
        });
      } catch (e) {
        setError(e.message);
        setData({ items: [], totalPages: 0, totals: 0 });
      } finally {
        setIsLoading(false);
      }
      return undefined;
    },
    [search],
  );

  useEffect(() => {
    fetch();
  }, [paramsToWatch]);

  return {
    ...data,
    isLoading,
    error,
    onFilterChange,
    fetch,
  };
};

export default useListing;
