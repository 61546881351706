import { first, orderBy, filter, isEmpty, omit } from 'lodash-es';
import { getFlattenedAllProjectAllocations } from '@utils/allocationUtils';
import { isFixedPrice } from '@utils/projectUtils';

export const cellStyle = (width = 140) => ({
  '@media (min-width: 400px)': {
    w: `${width - 40}px`,
  },
  '@media (min-width: 1250px)': {
    w: `${width - 35}px`,
  },
  '@media (min-width: 1350px)': {
    w: `${width - 30}px`,
  },
  '@media (min-width: 1450px)': {
    w: `${width - 25}px`,
  },
  '@media (min-width: 1550px)': {
    w: `${width - 20}px`,
  },
  '@media (min-width: 1650px)': {
    w: `${width}px`,
  },
});

export const recalculateProjectEndDateOnAllocationChange = formApi => {
  const { values: project } = formApi.getState();
  if (isFixedPrice(project)) {
    const allocations = filter(
      getFlattenedAllProjectAllocations(project),
      allocation => !isEmpty(omit(allocation, ['startDate', 'endDate', 'id'])),
    );
    const latestEndDate = first(
      orderBy(
        filter(allocations, a => a?.endDate),
        'endDate',
        'desc',
      ),
    )?.endDate;
    const earliestStartDate = first(
      orderBy(
        filter(allocations, a => a?.startDate),
        'startDate',
        'asc',
      ),
    )?.startDate;
    formApi.change('isRecalculating', true);
    if (latestEndDate) {
      formApi.change('endDate', latestEndDate);
    }
    if (earliestStartDate) {
      formApi.change('startDate', earliestStartDate);
    }
    setTimeout(() => {
      formApi.change('isRecalculating', false);
    }, 100);
  }
};
