import Badge from '@styles/Badge';
import moment from 'moment';
import React from 'react';

const DueBadge = ({ dueDate, ...props }) => {
  const { label, ...badgeProps } = React.useMemo(() => {
    const formattedDueDate = moment.utc(dueDate).format('MMM DD');
    if (moment.utc(dueDate).isBefore(moment.utc().startOf('day'))) {
      return {
        label: `Overdue: ${formattedDueDate}`,
        bg: 'red',
      };
    }
    if (
      moment.utc(dueDate).isSameOrAfter(moment.utc().startOf('day')) &&
      moment.utc(dueDate).isSameOrBefore(
        moment
          .utc()
          .add({ days: 1 })
          .endOf('day'),
      )
    ) {
      return {
        label: `Due soon: ${formattedDueDate}`,
        bg: 'orange',
      };
    }
    return {
      label: `Due: ${formattedDueDate}`,
      variant: 'tertiary',
      bg: 'dark.light',
      color: 'gray.mid',
    };
  }, [dueDate]);

  return (
    <Badge
      border="none"
      fontFamily="WM"
      p="4px 16px"
      {...badgeProps}
      {...props}
    >
      {label}
    </Badge>
  );
};

export default DueBadge;
