import { Box, Flex, Select } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React, { Fragment } from 'react';
import { usePagination, useTable } from 'react-table';

const StyledPagination = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const SupplierInvoiceTable = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination,
  );

  return (
    <>
      <Flex {...getTableProps()} direction="column" width="100%">
        {headerGroups.map(headerGroup => (
          <Flex
            {...headerGroup.getHeaderGroupProps()}
            width="100%"
            mb="12px"
            mt="24px"
            fontSize="12px"
            color="#4e4e4e"
            textTransform="uppercase"
          >
            {headerGroup.headers.map(column => (
              <Flex
                width={column.width}
                fontFamily="WM"
                px="10px"
                key={`keyColumnId=${column.id}`}
              >
                {column.render('Header', 'width')}
              </Flex>
            ))}
          </Flex>
        ))}

        <Flex {...getTableBodyProps()} direction="column" width="100%">
          {page.map(row => {
            prepareRow(row);
            return (
              <Flex
                {...row.getRowProps()}
                border="1px solid"
                borderColor="#d9dada"
                borderRadius="8px"
                position="relative"
                mb="12px"
                width="100%"
              >
                {row.cells.map(cell => {
                  return (
                    <Fragment key={`keyCellId=${cell.column.getCellProps.id}`}>
                      {cell.render('Cell')}
                    </Fragment>
                  );
                })}
              </Flex>
            );
          })}
        </Flex>
      </Flex>

      <StyledPagination>
        {pageOptions.length > 1 && (
          <Flex alignItems="center" width="20%" justifyContent="space-between">
            <Button
              cursor="pointer"
              color="#fff"
              opacity="1"
              mr="5px"
              iconButton={{ name: 'arrowLeft' }}
              fontSize={18}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />

            <Typography>{`Page ${pageIndex + 1} of ${
              pageOptions.length
            }`}</Typography>
            <Button
              ml="5px"
              cursor="pointer"
              opacity="1"
              color="#fff"
              iconButton={{ name: 'arrowLeft', transform: 'rotate(180deg)' }}
              fontSize={18}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
          </Flex>
        )}
        <Box position="absolute" right="0">
          <Select
            border="1px solid"
            borderColor="#d9dada"
            borderRadius="8px"
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 15].map(ps => (
              <option key={`key="pageSize=${ps}`} value={ps}>
                Show {ps}
              </option>
            ))}
          </Select>
        </Box>
      </StyledPagination>
    </>
  );
};

export default SupplierInvoiceTable;
