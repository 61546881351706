import React, { Component } from 'react';
import { APP_ROUTES } from '@routes';
import Typography from '@styles/Typography';
import { Redirect } from 'react-router';
import { inject, observer } from 'mobx-react';
import { isUndefined } from 'lodash-es';
import PasswordResetForm from './PasswordResetForm';
import SignInLayout from '../SignIn/SignInLayout';

@inject('appStore', 'authStore', 'usersStore')
@observer
class PasswordReset extends Component {
  render() {
    const {
      appStore: {
        featureFlags: { 'uo-frontegg-auth': isUoFronteggAuth },
      },
      usersStore: { updateProfileAndPassword, isProfileLoaded, profile },
      authStore: { signOut },
    } = this.props;

    const redirectToProjects =
      !isUndefined(isUoFronteggAuth) && isUoFronteggAuth
        ? true
        : !profile.passwordResetRequired;

    if (isProfileLoaded && redirectToProjects) {
      return <Redirect to={APP_ROUTES.projects} />;
    }

    return (
      <SignInLayout>
        <Typography variant="h2" mb="16px">
          Password reset
        </Typography>
        <Typography mb="24px">
          You have to reset your password to start using the platform
        </Typography>
        <PasswordResetForm
          onSubmit={updateProfileAndPassword}
          onCancel={() => signOut(false)}
        />
      </SignInLayout>
    );
  }
}

export default PasswordReset;
