import React from 'react';
import Modal from '@components/Modal';
import { Field, Form } from 'react-final-form';
import FileUpload from '@pages/UserCreate/FileUpload';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { isEmpty } from 'lodash-es';
import { Stack } from '@chakra-ui/react';

const ImportProjectModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} styleProps={{ minW: '720px' }}>
      <Typography variant="h2" mb="52px" textAlign="center">
        Import project from JSON file
      </Typography>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Field
                component={({ input }) => (
                  <FileUpload
                    value={input.value}
                    onUploaded={val => input.onChange(val)}
                    allowedExtensions={['.json']}
                  />
                )}
                name="project"
                width="100%"
              />
              <Stack mt="86px" spacing={3}>
                <Button
                  type="submit"
                  isDisabled={isEmpty(values) || submitting}
                >
                  Import
                </Button>
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>
              </Stack>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default ImportProjectModal;
