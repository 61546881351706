import Modal from '@components/Modal';
import Typography from '@styles/Typography';
import React from 'react';
import AddSupplierToBlacklistForm from './AddSupplierToBlacklistForm';

const AddSupplierToBlacklistModal = ({
  isOpen,
  onClose,
  handleAddSupplierToBlacklist,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <Typography variant="h2" mb="30px" textAlign="center">
      Add Delivery Partner to Blacklist
    </Typography>
    <AddSupplierToBlacklistForm
      onClose={onClose}
      onSubmit={values =>
        handleAddSupplierToBlacklist(values?.supplier?.id, {
          reason: values.reason,
        })
      }
    />
  </Modal>
);

export default AddSupplierToBlacklistModal;
