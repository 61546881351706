import { Box, Button } from '@chakra-ui/react';
import Icon from '@components/Icon';
import { APP_ROUTES } from '@routes';
import Typography from '@styles/Typography';
import { map } from 'lodash-es';
import React from 'react';
import { Link } from 'react-router-dom';

const Arrow = () => (
  <Icon
    name="arrowLeft"
    color="aqua.solid"
    fontSize={24}
    transform="rotate(180deg)"
    mr="15px"
  />
);

const getActiveListReasons = ({ isClient, isTeamAdmin }) => {
  if (isClient) {
    return [
      'Project name and basic concept',
      'Any technical requirements you may have',
      'What features you’d like',
      'Finally, what your deadlines are',
    ];
  }
  if (isTeamAdmin) {
    return [
      'Briefs to review',
      'Briefs waiting for an estimate',
      'Briefs waiting for client approval',
    ];
  }
  return [
    'New briefs from clients',
    'Briefs waiting for delivery partner input',
    'Briefs containing delivery partner quotes',
  ];
};

const BriefsEmpty = ({ isClient, isTeamAdmin, isAdminOrDL }) => {
  return (
    <Box py="44px" maxW="671px" w="100%">
      <Typography variant="h1" mb="40px" maxW={isClient ? '480px' : '671px'}>
        {isClient ? (
          <>
            {'You don’t have any briefs here yet. '}
            <span role="img" aria-label="cry">
              😥
            </span>
          </>
        ) : (
          'There are no briefs here yet.'
        )}
      </Typography>
      <Box
        maxW="671px"
        w="100%"
        padding="32px 40px 40px 40px"
        borderRadius="8px"
        borderColor="dark.mid"
        borderStyle="dashed"
        borderWidth="2px"
        bg="#fff"
      >
        <Box maxW="480px">
          <Typography variant="h3" mb="16px">
            {isClient
              ? 'Create a brief is the first step in setting up a project'
              : 'A brief summary'}
          </Typography>
          {isClient && (
            <Typography mb="24px">
              To get a quote use our simple 4 step wizard. It takes less than 2
              minutes to complete!
            </Typography>
          )}
          <Typography mb="24px">
            {isClient
              ? 'We’ll ask about:'
              : 'Here you will find all active or ‘Live’ briefs: These include:'}
          </Typography>

          <Box as="ul">
            {map(getActiveListReasons({ isClient, isTeamAdmin }), item => (
              <Typography as="li" mb="8px" key={item}>
                <Arrow />
                {item}
              </Typography>
            ))}
          </Box>
          {isAdminOrDL && (
            <Typography mt="30px">
              You can also create briefs on behalf of a client.
            </Typography>
          )}
          {!isTeamAdmin && (
            <Button
              mt="30px"
              leftIcon={<Icon name="add" mb="2px" mr="13px" fontSize={14} />}
              bg="aqua.solid"
              fontWeight="400"
              color="#fff"
              py="18px"
              px="20px"
              variant="solid"
              fontSize={18}
              h="auto"
              borderRadius="30px"
              as={Link}
              to={APP_ROUTES.briefsStartCreate}
            >
              {isClient ? 'Add a new brief' : 'Create a new brief'}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BriefsEmpty;
