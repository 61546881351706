export const size = {
  xs: '320px',
  sm: '450px',
  md: '769px',
  lg: '1024px',
  xl: '1200px',
};

export const breakpoint = {
  xs: `(max-width: ${size.xs})`,
  sm: `(max-width: ${size.sm})`,
  md: `(max-width: ${size.md})`,
  lg: `(max-width: ${size.lg})`,
  xl: `(max-width: ${size.xl})`,
};
