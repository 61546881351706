import {
  Box,
  Menu as MenuRaw,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import { map, noop } from 'lodash-es';
import React from 'react';
import { Link } from 'react-router-dom';

const Menu = ({ menuItems }) => (
  <MenuRaw>
    <MenuButton
      type="button"
      height="40px"
      width="40px"
      borderRadius="8px"
      marginLeft="24px"
      aria-label="Menu"
      bg="primary.100"
      _hover={{ bg: 'primary.200' }}
      _expanded={{ bg: 'primary.200' }}
    >
      <Icon name="dotsVertical" />
    </MenuButton>
    <MenuList
      placement="bottom-end"
      boxShadow="0px 0px 24px rgba(0, 0, 0, 0.15)"
      borderRadius="8px"
      p="8px"
      minW="133px"
    >
      {map(
        menuItems,
        ({
          isLink = false,
          to,
          onClick,
          iconColor,
          icon,
          label,
          isDisabled,
          isVisible = true,
        }) => (
          <React.Fragment key={`key-${label}`}>
            {isVisible && (
              <MenuItem
                p="8px"
                isDisabled={isDisabled}
                as={isLink ? Link : Box}
                to={to}
                onClick={onClick || noop}
                cursor="pointer"
              >
                <Icon
                  name={icon}
                  {...(iconColor && { color: iconColor })}
                  mr="10px"
                  fontSize={18}
                  mb="3px"
                />
                <Typography lineHeight="22px">{label}</Typography>
              </MenuItem>
            )}
          </React.Fragment>
        ),
      )}
    </MenuList>
  </MenuRaw>
);

export default Menu;
