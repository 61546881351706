import { Box, Flex } from '@chakra-ui/react';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import Icon from '@components/Icon';
import Spinner from '@styles/Spinner';
import { INPUT_VARIANTS } from '@styles/theme';
import Typography from '@styles/Typography';
import { lowerCase, upperCase, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field } from 'react-final-form';
import { required } from '@utils/formValidators';

const RateRow = ({
  isDL,
  isAdmin,
  sourceCurrency,
  destCurrency,
  rateName,
  isError,
  ...props
}) => (
  <Flex h="48px" display="inline-flex" borderRadius="8px" {...props}>
    <Flex w="168px" alignItems="center" bgColor="#F9F9F9" px="20px">
      <Typography color="#4E4E4E" fontSize={13}>
        {upperCase(sourceCurrency)}
      </Typography>
      <Icon mx="24px" name="arrowUp" fontSize={24} transform="rotate(90deg)" />
      <Typography color="#4E4E4E" fontSize={13}>
        {upperCase(destCurrency)}
      </Typography>
    </Flex>
    <Box maxW="88px">
      <Field
        ml="24px"
        p="13px 12px"
        border="1px"
        pt="13px"
        borderColor={isError ? 'red' : '#D9DADA'}
        borderRadius="8px"
        borderStyle="solid"
        name={rateName}
        component={MaskedTextInput}
        variant={INPUT_VARIANTS.CONTAINER_FILLED}
        placeholder="0.00"
        isDisabled={!isAdmin && isDL}
        decimalScale={2}
        fixedDecimalScale
        fontSize={15}
        validate={required}
        isErrorAbsolute
        errorStyle={{ width: '200px', bottom: '15px', left: '128px' }}
      />
    </Box>
  </Flex>
);

const ExchangeRatesForm = ({
  settingsStore: {
    exchangeRates: { isLoading },
  },
  values,
  usersStore: { isDL, isAdmin },
  ratesError,
}) => {
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Box mb="46px">
      <Typography variant="h3" mb="24px">
        Exchange rates
      </Typography>

      <Flex flexDir="column" alignItems="flex-start">
        {map(values.rates, (r, idx) => (
          <RateRow
            key={`ex-rate-${idx}`}
            destCurrency={lowerCase(r.sourceCurrency)}
            sourceCurrency={lowerCase(r.destCurrency)}
            rateName={`rates[${idx}].rate`}
            mb="20px"
            isDL={isDL}
            isAdmin={isAdmin}
            isError={ratesError && ratesError[idx]}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default inject(
  'usersStore',
  'settingsStore',
)(observer(ExchangeRatesForm));
