import { NEW_ROLES } from '@app/constants';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { ErrorText } from '@components/forms/_common/FormError';
import Select from '@components/forms/_common/Select';
import TextInput from '@components/forms/_common/TextInput';
import Button from '@styles/Button';
import {
  composeValidators,
  mustBeAtLeastCharLong,
  mustBeValidEmail,
  required,
} from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';

const ROLE_TYPE_OPTIONS_FOR_SUPPLIER_VIEW = [
  { value: NEW_ROLES.TEAM_MEMBER, label: 'Team Member' },
  { value: NEW_ROLES.TEAM_ADMIN, label: 'Team Admin' },
];

const DeveloperCreateForm = ({
  onSubmit,
  isAdminOrDL,
  supplier,
  onClose,
  history,
  isDeveloperCreateRoute,
}) => {
  return (
    <Box maxW="1201px" h="100%" w="100%" mx="auto">
      <Flex h="100%" direction="column" align="flex-start">
        <Form
          initialValues={{
            supplier,
            role: NEW_ROLES.TEAM_MEMBER,
          }}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitError, submitting, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Flex flexDirection="column">
                  {!values.id && isAdminOrDL && (
                    <Box mb="32px">
                      <Field
                        label="User type"
                        name="role"
                        placeholder="Choose a user role"
                        component={Select}
                        validate={required}
                        options={ROLE_TYPE_OPTIONS_FOR_SUPPLIER_VIEW}
                      />
                    </Box>
                  )}
                  <Field
                    label="Username"
                    name="username"
                    placeholder="Enter your username"
                    type="text"
                    component={TextInput}
                    validate={composeValidators(
                      required,
                      mustBeAtLeastCharLong(5),
                    )}
                    containerProps={{ mb: '32px' }}
                  />
                  <Field
                    label="Email"
                    name="email"
                    placeholder="Email"
                    type="text"
                    component={TextInput}
                    validate={composeValidators(required, mustBeValidEmail)}
                    containerProps={{ mb: '32px' }}
                  />
                  <Field
                    label="Password"
                    name="password"
                    placeholder="Password"
                    autoComplete="new-password"
                    type="password"
                    component={TextInput}
                    validate={composeValidators(required)}
                    containerProps={{ mb: '32px' }}
                  />
                  <Field
                    label="Full name"
                    name="fullname"
                    placeholder="Full name"
                    type="text"
                    component={TextInput}
                    validate={required}
                  />
                  {submitError && <ErrorText>{submitError}</ErrorText>}
                  <Stack mt="40px">
                    <Button
                      isLoading={submitting}
                      isDisabled={submitting}
                      type="submit"
                    >
                      Create
                    </Button>
                    <Button
                      variant="outline"
                      isDisabled={submitting}
                      onClick={() =>
                        isDeveloperCreateRoute ? history.goBack() : onClose()
                      }
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Flex>
              </form>
            );
          }}
        />
      </Flex>
    </Box>
  );
};

export default DeveloperCreateForm;
