import React from 'react';
import { inject, observer } from 'mobx-react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import qs from 'query-string';
import { APP_ROUTES } from '@routes';
import { Helmet } from 'react-helmet';
import { IS_DEV, IS_STAGING, IS_PREPROD } from '@app/constants';
import {
  includes,
  isUndefined,
  isEmpty,
  find,
  lowerCase,
  split,
  omit,
} from 'lodash-es';
import Page403 from '@pages/403Page';
import Page500 from '@pages/500Page';
import Page404 from '@pages/404Page';
import LoadingPage from '@pages/LoadingPage';
// import FronteggAuth from './FronteggAuth';
import AuthedLayout from './AuthedLayout';

@withRouter
@inject(
  'appStore',
  'authStore',
  'usersStore',
  'teamsStore',
  'tasksStore',
  'briefsStore',
  'projectsStore',
)
@observer
class AppRoute extends React.Component {
  isUserPermitted = () => {
    const {
      permittedRoles,
      usersStore: { profile, isProfileLoaded },
    } = this.props;
    if (!isEmpty(permittedRoles) && isProfileLoaded) {
      return !!find(profile.roles, ({ name: role }) =>
        includes(permittedRoles, role),
      );
    }
    return true;
  };

  getLayoutTitleProps = () => {
    const {
      title,
      path,
      computedMatch: {
        params: { clientSlug, projectSlug },
      },
      usersStore: { user, isLoading: isLoadingUser },
      teamsStore: { client, supplier, isLoading: isLoadingTeam },
      briefsStore: { brief },
      projectsStore: { project },
      tasksStore: { tasks },
      location: { search },
    } = this.props;

    const { status = 'Open', ppBriefId } = qs.parse(search);

    if (path === APP_ROUTES.projects && ppBriefId) {
      return {
        title: 'Select a project',
      };
    }
    if (path === APP_ROUTES.tasks) {
      return {
        title: `${tasks.paging.totalObjects} ${lowerCase(status)} ${
          tasks.paging.totalObjects === 1 ? 'task' : 'tasks'
        }`,
        isLoadingTitle: tasks.isLoading,
      };
    }

    if (
      path === APP_ROUTES.briefById() ||
      path === APP_ROUTES.briefPlugAndPlayById()
    ) {
      return { isBrief: true, isLoadingTitle: !brief.id };
    }

    if (
      path === APP_ROUTES.briefsEdit() ||
      path === APP_ROUTES.briefsEditPlugAndPlay()
    ) {
      return { isBrief: true };
    }

    if (path === APP_ROUTES.users.userById()) {
      return { title: user.fullname, isLoadingTitle: isLoadingUser };
    }

    if (path === APP_ROUTES.users.userByIdEdit()) {
      return {
        title: `Edit ${user.fullname}`,
        isLoadingTitle: isLoadingUser,
      };
    }

    if (path === APP_ROUTES.teams.supplierById()) {
      return {
        title: supplier.name,
        isDp: true,
        isLoadingTitle: isLoadingTeam,
      };
    }

    if (path === APP_ROUTES.teams.clientById()) {
      return { title: client.name, isLoadingTitle: isLoadingTeam };
    }

    if (!!clientSlug && !!projectSlug) {
      return { isProject: true, isLoadingTitle: !project.id };
    }
    return { title };
  };

  strippedLastLocation = () => {
    const {
      usersStore: { isAdminOrDL },
      authStore: { lastLocation },
      appStore: {
        featureFlags: { 'uo-frontegg-auth': isUoFronteggAuth },
      },
    } = this.props;
    const fgLastLocation = localStorage.getItem('fgLastLocation');
    const stripLocation = isUoFronteggAuth ? fgLastLocation : lastLocation;

    const [path, search] = split(stripLocation, '?');
    if (!isAdminOrDL && path === APP_ROUTES.briefs) {
      const params = qs.parse(search);
      if (search) {
        return `${path}?${qs.stringify(
          omit(params, [
            'deazyLeadNames',
            'supplierNames',
            'deazyLeadIds',
            'techStack',
            'supplierIds',
          ]),
        )}`;
      }
    }
    return stripLocation;
  };

  getHeadTitle = () => {
    const {
      path,
      headTitle = 'Deazy Platform',
      projectsStore: { project },
      briefsStore: { brief },
    } = this.props;

    const getDecoratedTitle = title => {
      if (IS_STAGING) {
        return `STAGING | ${title}`;
      }
      if (IS_PREPROD) {
        return `PREPROD | ${title}`;
      }
      if (IS_DEV) {
        return `LOCAL | ${title}`;
      }
      return title;
    };

    if (
      includes(path, APP_ROUTES.briefById()) ||
      includes(path, APP_ROUTES.briefPlugAndPlayById())
    ) {
      return getDecoratedTitle(`${brief.name || 'Brief'} - Deazy Platform`);
    }
    if (includes(path, APP_ROUTES.projectByClientAndSlug())) {
      return getDecoratedTitle(`${project.name || 'Project'} - Deazy Platform`);
    }
    return getDecoratedTitle(headTitle);
  };

  render() {
    const {
      layout: Layout = AuthedLayout,
      isPrivate = true,
      redirectIfAuthenticated = false,
      path,
      component: Component,
      searchBar,
      authStore,
      subSidebarWidth,
      history,
      usersStore: { isProfileLoaded, isAdminOrDL },
      appStore,
      pageProps = {},
      hideTopbar,
      hideSidebar,
      ...routeProps
    } = this.props;
    const { isAuthenticated, setIsForbidden, isForbidden } = authStore;
    const {
      is500,
      is404,
      featureFlags: { 'uo-frontegg-auth': isUoFronteggAuth },
    } = appStore;
    const isUserPermitted = this.isUserPermitted();
    const titleProps = this.getLayoutTitleProps();
    const strippedLastLocation = this.strippedLastLocation();

    return (
      <Route
        {...routeProps}
        render={props => {
          if (
            (!isProfileLoaded && isAuthenticated) ||
            isUndefined(isUoFronteggAuth)
          ) {
            return <LoadingPage />;
          }

          if (redirectIfAuthenticated && isAuthenticated) {
            return (
              <Redirect
                to={
                  strippedLastLocation ||
                  (isAdminOrDL ? APP_ROUTES.tasks : APP_ROUTES.projects)
                }
              />
            );
          }

          // if (
          //   isUoFronteggAuth !== undefined &&
          //   isUoFronteggAuth === true &&
          //   isPrivate &&
          //   !isAuthenticated
          // ) {
          //   return <FronteggAuth />;
          // }

          if (
            isUoFronteggAuth !== undefined &&
            isUoFronteggAuth === true &&
            isPrivate &&
            isAuthenticated
          ) {
            const shouldRedirect =
              localStorage.getItem('redirectToLastLocation') === 'true';
            localStorage.removeItem('redirectToLastLocation');

            if (shouldRedirect) {
              return (
                <Redirect
                  to={
                    strippedLastLocation ||
                    (isAdminOrDL ? APP_ROUTES.tasks : APP_ROUTES.projects)
                  }
                />
              );
            }
          }

          if (
            isUoFronteggAuth !== undefined &&
            isUoFronteggAuth === false &&
            isPrivate &&
            !isAuthenticated
          ) {
            return (
              <Redirect
                to={{
                  pathname: APP_ROUTES.auth.signIn,
                }}
              />
            );
          }

          if (is404 || path === '*') {
            return <Page404 />;
          }

          if (is500) {
            return <Page500 />;
          }

          if (isForbidden || !isUserPermitted) {
            return (
              <Page403
                goBack={() => {
                  history.push(APP_ROUTES.projects);
                  setIsForbidden(false);
                }}
              />
            );
          }

          return (
            <Layout
              searchBar={searchBar}
              hideTopbar={hideTopbar}
              hideSidebar={hideSidebar}
              {...titleProps}
              subSidebarWidth={pageProps.isTasksRoute ? '376px' : '284px'}
            >
              <Helmet defer={false}>
                <title>{this.getHeadTitle()}</title>
              </Helmet>
              <Component {...pageProps} {...props} />
            </Layout>
          );
        }}
      />
    );
  }
}

export default AppRoute;
