import { Link } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import { APP_ROUTES } from '@routes';
import Card from '@styles/Card';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const TeamCard = ({ teamId, isSupplierRoute, children, isStatic }) => {
  return (
    <>
      {!isStatic ? (
        <Card>
          <Link
            as={RouterLink}
            to={
              isSupplierRoute
                ? APP_ROUTES.teams.supplierById(teamId)
                : APP_ROUTES.teams.clientById(teamId)
            }
            _hover={{ cursor: 'pointer', textDecoration: 'none' }}
          >
            {children}
          </Link>
        </Card>
      ) : (
        <WhiteCard px="0" py="0">
          {children}
        </WhiteCard>
      )}
    </>
  );
};

export default TeamCard;
