import { Box, Flex } from '@chakra-ui/react';
import Textarea from '@components/forms/_common/Textarea';
import TextInput from '@components/forms/_common/TextInput';
import Button from '@styles/Button';
import {
  composeValidators,
  mustBeShorterOrEqual,
  mustBeValidUrl,
  required,
} from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';

const ResourceForm = ({ initialValues, onClose, onSubmit }) => {
  return (
    <Box
      my="32px"
      border="1px solid"
      borderColor="dark.mid"
      bg="#fff"
      boxShadow="0 0 24px 0 rgba(0, 0, 0, 0.15)"
      borderRadius="8px"
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Box py="34px" px="24px">
              <Field
                name="title"
                label="Title of document"
                component={TextInput}
                validate={composeValidators(
                  required,
                  mustBeShorterOrEqual(200),
                )}
                placeholder="Please add a title"
              />
              <Field
                name="description"
                label="Description"
                placeholder="Add a description here"
                component={Textarea}
                validate={composeValidators(
                  required,
                  mustBeShorterOrEqual(1000),
                )}
                mt="30px"
              />
              <Field
                name="link"
                label="Link to resource"
                component={TextInput}
                placeholder="Paste url here"
                validate={composeValidators(required, mustBeValidUrl)}
                containerProps={{ mt: '12px' }}
              />
            </Box>
            <Flex
              borderTop="1px solid"
              borderColor="dark.mid"
              py="24px"
              mt="4px"
              px="24px"
            >
              <Button mr="12px" isLoading={submitting} type="submit">
                Save
              </Button>
              <Button
                variant="outline"
                onClick={onClose}
                isDisabled={submitting}
              >
                Cancel
              </Button>
            </Flex>
          </form>
        )}
      />
    </Box>
  );
};

export default ResourceForm;
