import { Flex } from '@chakra-ui/react';
import { map } from 'lodash-es';
import React from 'react';
import { Field } from 'react-final-form';
import RadioField from '@components/forms/RadioField';

const CHOICES = [
  { label: 'No', value: false },
  { label: 'Yes', value: true },
];

const parseFn = value => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
};
const BlueRadioGroup = ({ isDisabled, choices = CHOICES, name }) => {
  return (
    <Flex
      borderRadius="8px"
      px="14px"
      py="18px"
      border="1px solid #D9DFFF"
      bg="#F9FAFF"
      opacity={isDisabled ? 0.7 : 1}
      maxW="239px"
    >
      <Field name={name} parse={value => parseFn(value)}>
        {({ input }) =>
          map(choices, ({ label, value }, idx) => {
            const isChecked = value === input.value;
            return (
              <RadioField
                wrapperProps={{
                  mb: 0,
                  mr: idx + 1 === choices.length ? 0 : '18px',
                  alignItems: 'center',
                }}
                disabled={isDisabled}
                key={`radio-group-${value}`}
                label={label}
                checked={isChecked}
                value={value}
                onChange={input.onChange}
                name={input.name}
                isVertical={false}
              />
            );
          })
        }
      </Field>
    </Flex>
  );
};

export default BlueRadioGroup;
