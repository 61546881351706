/* eslint-disable no-restricted-syntax */
import { useDisclosure } from '@chakra-ui/react';
import { includes } from 'lodash-es';
import { inject } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router';
import DiscardBriefModal from './DiscardBriefModal';

const WizardLeavingGuard = ({ briefsStore: { clearDraft }, isFormDirty }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const [link, setLink] = React.useState({});

  const confirm = e => {
    e.preventDefault();
    const { origin, href } = e.currentTarget;
    const isRouterLink = !includes(e.currentTarget.className, 'external-link');
    if (isRouterLink) {
      setLink({ to: href.replace(origin, '') });
    } else {
      setLink({ href });
    }
    onOpen();
  };

  const detachEvent = links => {
    for (const l of links) {
      l.removeEventListener('click', confirm, false);
    }
  };

  const attachEvent = links => {
    for (const l of links) {
      l.addEventListener('click', confirm, false);
    }
  };

  React.useEffect(() => {
    const links = document.getElementsByTagName('a');
    if (isFormDirty) {
      attachEvent(links);
    } else {
      detachEvent(links);
    }
    return () => {
      detachEvent(links);
    };
  }, [isFormDirty, isOpen]);

  return (
    <DiscardBriefModal
      isOpen={isOpen}
      onClose={onClose}
      onDiscardBrief={() => {
        clearDraft();
        if (link.to) {
          history.push(link.to);
        }
        if (link.href) {
          window.location.replace(link.href);
        }
      }}
    />
  );
};

export default inject('briefsStore')(WizardLeavingGuard);
