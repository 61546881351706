import { Stack } from '@chakra-ui/react';
import Textarea from '@components/forms/_common/Textarea';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { mustBeShorterOrEqual } from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';

const SupplierNoteModal = ({
  onSubmit,
  initialValues,
  isOpen,
  onClose,
  isSingleSupplier,
  mainNameQA,
}) => {
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      display="none"
      styleProps={
        isSmallerScreen && { p: '24px 16px 48px', margin: 'auto 0px 0px' }
      }
    >
      <Typography variant="h2" mb="32px" textAlign="center">
        {`Assign Delivery Partner${isSingleSupplier ? '' : 's'}`}
      </Typography>
      <Typography mb="32px" textAlign="center">
        {isSingleSupplier
          ? 'Tell the delivery partner why they have been individually selected for the brief.'
          : 'You are about to add all delivery partners to this brief. Everyone will see this note.'}
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Field
                name="message"
                component={Textarea}
                mb="16px"
                validate={mustBeShorterOrEqual(10000)}
                placeholder="Write note here"
                mt="24px"
                maxW="480px"
                {...(isSmallerScreen && {
                  mx: 'auto',
                })}
              />
              <Typography textAlign={isSmallerScreen && 'center'}>
                Leave this blank if you want to continue assigning without
                sending a message
              </Typography>
              <Stack spacing={3} mt="40px">
                <Button
                  type="submit"
                  isLoading={submitting}
                  data-test-id={mainNameQA && `${mainNameQA}--assignButton`}
                >
                  {`Assign ${isSingleSupplier ? '' : 'all'}`}
                </Button>
                <Button
                  variant="outline"
                  isDisabled={submitting}
                  onClick={onClose}
                  data-test-id={mainNameQA && `${mainNameQA}--cancelButton`}
                >
                  Cancel
                </Button>
              </Stack>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default SupplierNoteModal;
