import { Box, Button } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import { includes } from 'lodash-es';
import React from 'react';

const ResourceCard = ({ resource = {} }) => {
  return (
    <Box
      border="1px solid"
      borderColor="dark.mid"
      borderRadius="8px"
      bg="#fff"
      py="32px"
      px="24px"
      mb="16px"
    >
      <Typography variant="h2" mb="12px">
        {resource.title}
      </Typography>
      <Typography mb="40px">{resource.description}</Typography>
      <Button
        isDisabled={!resource.link}
        fontWeight="400"
        fontSize={18}
        bg="aqua.solid"
        color="#fff"
        borderRadius="30px"
        _hover={{ bg: '#3E898C' }}
        _active={{ bg: '#3E898C' }}
        minW="228px"
        h="56px"
        rightIcon={<Icon name="arrowLongTail" fontSize={15} ml="24px" />}
        as="a"
        target="_blank"
        href={
          includes(resource.link, 'http') ? resource.link : `//${resource.link}`
        }
      >
        View this resource
      </Button>
    </Box>
  );
};

export default ResourceCard;
