import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import Button from '@styles/Button';
import { APP_ROUTES } from '@routes';
import Typography from '@styles/Typography';
import { join, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Stat } from '../Teams/TeamCardContent';

const SupplierGeneralInfo = ({
  team,
  isStatic,
  usersStore: { isAdminOrDL },
}) => {
  const history = useHistory();

  const handleTeamEdit = () => {
    history.push(APP_ROUTES.teams.supplierByIdEdit(team.id));
  };

  return (
    <>
      <Box p="40px">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          w="100%"
          mb="24px"
        >
          <Typography variant="h3">General Info</Typography>
          {isAdminOrDL && (
            <Flex>
              {!isStatic && (
                <Button
                  leftIcon={{ name: 'edit', fontSize: 20 }}
                  onClick={handleTeamEdit}
                  mr="8px"
                >
                  Edit
                </Button>
              )}
            </Flex>
          )}
        </Flex>
        <SimpleGrid columns={3} spacing="24px">
          <Stat label="Name" value={team.name} borderLeft="none" pl="0px" />
          <Stat
            label="Invoice name"
            value={team.invoiceName}
            borderLeft="none"
            pl="0px"
          />
          <Stat
            label="Location"
            value={team.location}
            borderLeft="none"
            pl="0px"
          />
          <Stat
            label="VAT registered"
            value={team.noVat ? 'No' : 'Yes'}
            borderLeft="none"
            pl="0px"
          />
          <Stat
            label="VAT number"
            value={team.vatNumber}
            borderLeft="none"
            pl="0px"
          />
          <Stat
            label="Finance email(s)"
            value={join(team.financeEmails, ', ')}
            borderLeft="none"
            pl="0px"
          />
        </SimpleGrid>
        {map(team.paymentDetails, pd => (
          <React.Fragment key={`supplier-pd-${pd.id}`}>
            <Box
              borderBottom="1px solid"
              borderColor="dark.mid"
              w="100%"
              mb="24px"
            />
            <SimpleGrid columns={3} spacing="24px">
              <Stat
                label="Currency"
                value={pd.currency}
                borderLeft="none"
                pl="0px"
              />
              <Stat
                label="Account number"
                value={pd.accountNumber}
                borderLeft="none"
                pl="0px"
              />
              <Stat
                label="Sort code"
                value={pd.sortCode}
                borderLeft="none"
                pl="0px"
              />
              <Stat label="IBAN" value={pd.IBAN} borderLeft="none" pl="0px" />
              <Stat label="BIC" value={pd.BIC} borderLeft="none" pl="0px" />
              <Stat
                label="Address"
                value={`${pd.addressLine1 || ''} ${pd.addressLine2 || ''} ${
                  pd.city
                } ${pd.postalCode}`}
                borderLeft="none"
                pl="0px"
                whiteSpace="pre-wrap"
              />
            </SimpleGrid>
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

export default inject(
  'usersStore',
  'teamsStore',
)(observer(SupplierGeneralInfo));
