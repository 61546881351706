import WhiteCard from '@components/WhiteCard';
import useMediaQuery from '@hooks/useMediaQuery';
import Typography from '@styles/Typography';
import { formatNumber, roundTheseHours } from '@utils';
import { getMarginborderColor } from '@utils/briefUtils';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form } from 'react-final-form';
import { useRouteMatch } from 'react-router';
import CostRow from '../CostRow';
import AutoSave from '../Proposal/AutoSave';
import ContigencySection from '../Proposal/ContingencySection';
import DiscountSection from './DiscountSection';

const CostBreakdownCard = ({
  briefsStore: {
    brief: { isPlugAndPlay },
  },
  proposalsStore: {
    fetchDeazyCostBreakdown,
    deazyCostBreakdown,
    updateDiscount,
    updateDeazyContingency,
    proposalData,
  },
  isReadOnly,
  mainNameQA,
}) => {
  const {
    supplierContingencyWithMargin,
    supplierContingencyMargin,
    supplierAvgMarginPercent,
    finalContingencyValue,
    proposalCost,
    proposalDeazyCostAvgMargin,
    proposalDeazyCostWithMargin,
    proposalDeazyHours,
    proposalHours,
    proposalClientCostSupplierResources,
    proposalSupplierHours,
    subTotalCost,
    subTotalHours,
    subTotalAvgMargin,
    totalAvgMargin,
  } = deazyCostBreakdown?.data || {};
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const {
    params: { briefId, proposalId },
  } = useRouteMatch('/briefs/:briefId/proposals/:proposalId/:type') || {
    params: {},
  };

  const {
    params: { ppBriefId, ppProposalId },
  } = useRouteMatch('/team-augmentation-briefs/:ppBriefId/proposal/:type') || {
    params: {},
  };

  React.useEffect(() => {
    if (
      (briefId || ppBriefId) &&
      (proposalId || ppProposalId || isPlugAndPlay)
    ) {
      fetchDeazyCostBreakdown(briefId || ppBriefId, proposalId || ppProposalId);
    }
  }, [
    isPlugAndPlay,
    proposalData.coreTeam,
    proposalData.additionalTeam,
    proposalData.deazyTeam,
    proposalData.deazyContingencyPercent,
    proposalData.deazyContingencyValue,
    proposalData.resources,
    proposalData.supplierContingencyMargin,
    proposalData.discountPercentage,
  ]);

  return (
    <WhiteCard
      border="1px solid"
      borderColor="dark.mid"
      {...(isSmallerScreen && { p: 0, bg: 'primary.100', border: 'none' })}
    >
      <Typography
        variant="h2"
        mb={['16px', '16px', '16px', '28px']}
        mt={['32px', '32px', '32px', 0]}
      >
        {isPlugAndPlay ? 'Total monthly cost' : 'Cost breakdown'}
      </Typography>
      {deazyCostBreakdown.error ? (
        <Typography
          my="20px"
          variant="h4"
          borderLeft="3px solid"
          borderColor="red.solid"
          pl="20px"
        >
          {deazyCostBreakdown.error}
        </Typography>
      ) : (
        <>
          {!isPlugAndPlay && (
            <>
              <Typography mb="32px">
                A breakdown by feature gives clear visibility to where the
                complexity lies. Add contingency if necessary.
              </Typography>
              <CostRow
                title="Delivery partner resource total"
                currency="gbp"
                hours={
                  !deazyCostBreakdown.isLoading &&
                  roundTheseHours(proposalSupplierHours)
                }
                margin={supplierAvgMarginPercent}
                cost={
                  !deazyCostBreakdown.isLoading &&
                  formatNumber(proposalClientCostSupplierResources)
                }
                data-test-id={
                  mainNameQA && `${mainNameQA}--resourceBreakdown-resourceTotal`
                }
                mainNameQA={mainNameQA}
                qaValue="resourceTotal"
              />
              <CostRow
                title="Delivery partner contingency"
                currency="gbp"
                data-test-id={
                  mainNameQA && `${mainNameQA}--resourceBreakdown-dpContingency`
                }
                showHours={false}
                margin={supplierContingencyMargin}
                cost={
                  !deazyCostBreakdown.isLoading &&
                  formatNumber(supplierContingencyWithMargin)
                }
                mainNameQA={mainNameQA}
                qaValue="dpContingency"
              />
              <CostRow
                title="Deazy resource total"
                currency="gbp"
                hours={
                  !deazyCostBreakdown.isLoading &&
                  roundTheseHours(proposalDeazyHours)
                }
                data-test-id={
                  mainNameQA &&
                  `${mainNameQA}--resourceBreakdown-deazyResourceTotal`
                }
                margin={proposalDeazyCostAvgMargin}
                cost={
                  !deazyCostBreakdown.isLoading &&
                  formatNumber(proposalDeazyCostWithMargin)
                }
                mainNameQA={mainNameQA}
                qaValue="deazyResourceTotal"
              />
              <CostRow
                title="Sub total"
                currency="gbp"
                data-test-id={
                  mainNameQA && `${mainNameQA}--resourceBreakdown-subTotal`
                }
                hours={
                  !deazyCostBreakdown.isLoading &&
                  roundTheseHours(subTotalHours)
                }
                margin={subTotalAvgMargin}
                cost={
                  !deazyCostBreakdown.isLoading && formatNumber(subTotalCost)
                }
                mb="24px"
                border="2px solid"
                borderColor={getMarginborderColor(subTotalAvgMargin)}
                mainNameQA={mainNameQA}
                qaValue="subTotal"
              />

              <Form
                onSubmit={updateDeazyContingency}
                initialValues={{
                  deazyContingencyPercent: proposalData.deazyContingencyPercent,
                  deazyContingencyValue: proposalData.deazyContingencyValue,
                }}
                keepDirtyOnReinitialize
                render={({ handleSubmit, form }) => {
                  return (
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <AutoSave
                        formApi={form}
                        onSave={updateDeazyContingency}
                      />
                      <ContigencySection
                        isReadOnly={isReadOnly}
                        finalContingencyValue={
                          !deazyCostBreakdown.isLoading &&
                          formatNumber(finalContingencyValue)
                        }
                        form={form}
                        currency="gbp"
                        isDeazy
                        isSmallerScreen={isSmallerScreen}
                        mainNameQA={mainNameQA}
                      />
                    </form>
                  );
                }}
              />
            </>
          )}
          <DiscountSection
            isSmallerScreen={isSmallerScreen}
            initialValues={{
              discountPercentage: proposalData.discountPercentage,
              discountReason: proposalData.discountReason,
            }}
            onSubmit={updateDiscount}
            isReadOnly={isReadOnly}
          />
          <CostRow
            mt={['36px', '36px', '36px', '24px']}
            bg="#fff"
            subText={
              +proposalData.discountPercentage > 0 &&
              `${formatNumber(
                proposalData.discountPercentage,
              )}% discount applied`
            }
            title="Deazy proposal grand total"
            data-test-id={
              mainNameQA && `${mainNameQA}--resourceBreakdown-grandTotal`
            }
            mainNameQA={mainNameQA}
            qaValue="grandTotal"
            currency="gbp"
            showHours={!isPlugAndPlay}
            hours={
              !deazyCostBreakdown.isLoading && roundTheseHours(proposalHours)
            }
            margin={totalAvgMargin}
            cost={!deazyCostBreakdown.isLoading && formatNumber(proposalCost)}
            border="2px solid"
            borderColor={getMarginborderColor(totalAvgMargin)}
          />
        </>
      )}
    </WhiteCard>
  );
};

export default inject(
  'proposalsStore',
  'briefsStore',
)(observer(CostBreakdownCard));
