import Typography from '@styles/Typography';
import { inject, observer } from 'mobx-react';
import { Box } from '@chakra-ui/react';
import Spinner from '@styles/Spinner';
import React from 'react';
import HolidaysTable from './HolidaysTable';

const NationalHolidays = ({
  projectId,
  nationalHolidaysStore: {
    getUpcomingHolidays,
    getPastHolidays,
    docoratedUpcomingHolidays,
    docoratedPastHolidays,
    isLoading,
    clearHolidays,
    firstRequest,
  },
}) => {
  React.useEffect(() => {
    getUpcomingHolidays(projectId);
    getPastHolidays(projectId);
    return () => clearHolidays();
  }, []);

  if (isLoading || firstRequest) {
    return <Spinner />;
  }

  return (
    <Box maxW="1280px" w="100%" mx="auto" px="40px" pb="100px">
      <Box my="40px">
        <Typography variant="h2" mb="16px">
          National Holidays
        </Typography>
        <Typography>
          This is list of the national holidays for the delivery partners
          working on this project.
        </Typography>
      </Box>
      <HolidaysTable
        holidays={docoratedUpcomingHolidays}
        label="Upcoming National Holidays"
      />
      <HolidaysTable
        holidays={docoratedPastHolidays}
        label="Past National Holidays"
      />
    </Box>
  );
};

export default inject('nationalHolidaysStore')(observer(NationalHolidays));
