import React, { useEffect, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import useSearchParams from '@hooks/useSearchParams';
import Spinner from '@styles/Spinner';
import { inject, observer } from 'mobx-react';
import SyncInfiniteScroller from '@components/SyncInifinteScroller';
import { withRouter } from 'react-router';
import { uniq, flatMap, lowerCase, find, isEmpty, map } from 'lodash-es';
import ShortlistedDPsHeader from './ShortlistedDPsHeader';
import MatchedCompanyCard from './MatchedCompanyCard';
import EmptyMatches from './EmptyMatches';

const DiscoverDPs = ({
  briefAssignmentsStore: {
    fetchMatchedDPs,
    matchedDPs,
    isLoadingMatchedDPs,
    shortlistSingleSupplier,
    unassignSupplier,
    fetchAssignmentList,
    clearAssignmentList,
    decoratedAssignments: assignments,
  },
  briefsStore: { brief },
  mainNameQA,
}) => {
  const {
    searchObj: { countries, engagementType, filter = 'best' },
  } = useSearchParams();

  useEffect(() => {
    fetchMatchedDPs({ countries, engagementType, filter });
  }, [countries, engagementType]);

  const currentMatchedDPs = useMemo(() => {
    const briefTechTags = brief.isPlugAndPlay
      ? uniq(flatMap(brief.resources, r => r.technologies))
      : brief.technologies;
    return map(matchedDPs[filter], dp => ({
      ...dp,
      ...dp?.supplier,
      isChosen: !!find(assignments, a => a.supplier.id === dp?.supplier?.id),
      technologies: map(uniq(dp?.supplier?.technologies), dpTech => ({
        name: dpTech,
        isBriefMatched: !!find(
          briefTechTags,
          t => lowerCase(t) === lowerCase(dpTech),
        ),
      })),
      techStrengths: map(uniq(dp?.supplier?.techStrengths), strength => ({
        name: strength,
        isBriefMatched: !!find(
          briefTechTags,
          t => lowerCase(t) === lowerCase(strength),
        ),
      })),
    }));
  }, [matchedDPs, filter, brief.id, assignments]);

  return (
    <Flex flexDir="column" w="100%">
      <ShortlistedDPsHeader
        counts={{
          new: matchedDPs?.new?.length || 0,
          partial: matchedDPs?.partial?.length || 0,
          best: matchedDPs?.best?.length || 0,
        }}
        fetchAssignmentList={fetchAssignmentList}
        clearAssignmentList={clearAssignmentList}
        assignments={assignments}
        unassignSupplier={unassignSupplier}
      />
      {isEmpty(currentMatchedDPs) && !isLoadingMatchedDPs && (
        <EmptyMatches filter={filter} />
      )}
      {isLoadingMatchedDPs && <Spinner />}
      <SyncInfiniteScroller
        items={currentMatchedDPs}
        renderItem={item => (
          <MatchedCompanyCard
            key={`matched-company-card-${item.id}`}
            mainNameQA={mainNameQA}
            shortlistSingleSupplier={shortlistSingleSupplier}
            unassignSupplier={unassignSupplier}
            deliveryPartner={item}
          />
        )}
      />
    </Flex>
  );
};

// will not rerender without withRouter
export default withRouter(
  inject('briefAssignmentsStore', 'briefsStore')(observer(DiscoverDPs)),
);
