import { Box, Divider, Flex } from '@chakra-ui/react';
import Tooltip from '@styles/Tooltip';
import Icon from '@components/Icon';
import { orderBy, map } from 'lodash-es';
import Typography from '@styles/Typography';
import Button from '@styles/Button';
import Spinner from '@styles/Spinner';
import React from 'react';
import moment from 'moment';
import PaymentNoteForm from '../PaymentNoteForm';

const InvoiceHistoryItem = ({ author, actionDate, content, mainNameQA }) => {
  return (
    <Box mb="12px" ml="8px">
      {content && (
        <Box>
          <Typography
            variant="text2"
            lineHeight="15px"
            mb="4px"
            data-test-id={`${mainNameQA}--historyItemContent`}
          >
            {content}
          </Typography>
        </Box>
      )}
      <Flex alignItems="center" color="gray.300">
        <Typography
          variant="text3"
          lineHeight="13px"
          data-test-id={`${mainNameQA}--historyItemAuthor`}
        >
          {author?.fullname || 'Deazy Platform'}
        </Typography>
        <Box
          height="10px"
          borderLeft="1px solid"
          borderColor="gray.300"
          borderWidth="0.5px"
          mb="1px"
          bg="gray.300"
          mx="6px"
        />
        <Typography
          variant="text3"
          lineHeight="13px"
          data-test-id={`${mainNameQA}--historyItemDate`}
        >
          {moment(actionDate).format('DD MMM YYYY, h:mmA')}
        </Typography>
      </Flex>
    </Box>
  );
};

const InvoiceHistory = ({
  invoice,
  addNewPaymentNote,
  rejectInvoice,
  isNoteFormOpened,
  setIsNoteFormOpened,
  setIsRejectInvoiceOpened,
  isRejectInvoiceOpened,
  projectId,
  auditNotesLoading,
  auditNotes,
  refetch,
  mainNameQA,
}) => {
  const orderedAuditNotes = React.useMemo(() => {
    return orderBy(auditNotes, 'createdAt', 'desc');
  }, [auditNotes]);
  return (
    <Box mt="16px">
      <Divider mb="16px" color="gray.200" />
      <Typography variant="h5" mb="20px" ml="8px">
        Invoice history & notes
        <Tooltip label="Details only seen by Deazy users" placement="top">
          <Box as="span">
            <Icon name="infoFilled" color="gray.300" mb="4px" ml="5px" />
          </Box>
        </Tooltip>
      </Typography>
      {!isNoteFormOpened && (
        <Button
          leftIcon={{ name: 'add', fontSize: 12, mr: '9px' }}
          onClick={() => setIsNoteFormOpened(!isNoteFormOpened)}
          mb="24px"
          data-test-id={`${mainNameQA}--addANote`}
        >
          Add a note
        </Button>
      )}
      {isNoteFormOpened && (
        <PaymentNoteForm
          onSubmit={({ reason, note }) => {
            if (isRejectInvoiceOpened) {
              return rejectInvoice(
                projectId,
                { id: invoice.id, reason },
                () => {
                  setIsNoteFormOpened(false);
                  setIsRejectInvoiceOpened(false);
                  refetch();
                },
              );
            }
            return addNewPaymentNote(
              projectId,
              { id: invoice.id, note },
              () => {
                setIsNoteFormOpened(false);
                refetch();
              },
            );
          }}
          onClose={() => {
            if (isRejectInvoiceOpened) {
              setIsRejectInvoiceOpened(false);
            }
            setIsNoteFormOpened(false);
          }}
          isRejectInvoiceOpened={isRejectInvoiceOpened}
          mainNameQA={mainNameQA}
        />
      )}
      {auditNotesLoading ? (
        <Spinner variant="medium" mt="64px" />
      ) : (
        <>
          {map(orderedAuditNotes, note => (
            <InvoiceHistoryItem
              content={note.comment}
              actionDate={note.createdAt}
              author={note.user}
              mainNameQA={mainNameQA}
            />
          ))}
        </>
      )}
    </Box>
  );
};

export default InvoiceHistory;
