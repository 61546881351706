import { Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';

const TableHeadings = () => {
  return (
    <Flex w="100%" mb="16px">
      <Typography w="16%" variant="caption">
        Role name
      </Typography>
      <Typography w="16%" variant="caption">
        Seniority
      </Typography>
      <Typography pr="2%" w="12%" variant="caption">
        Delivery Partner rate
      </Typography>
      <Typography pr="2%" w="12%" variant="caption">
        Delivery Partner hours
      </Typography>
      <Typography pr="2%" w="12%" variant="caption">
        Delivery Partner cost
      </Typography>
      <Typography w="10%" variant="caption">
        Client rate
      </Typography>
      <Typography w="10%" variant="caption">
        % Margin
      </Typography>
      <Typography w="12%" variant="caption">
        Client cost
      </Typography>
    </Flex>
  );
};

export default TableHeadings;
