import React from 'react';
import { Button, Flex, Grid } from '@chakra-ui/react';
import { Stat } from '@pages/Teams/TeamCardContent';
import Icon from '@components/Icon';

function SupplierBlacklistItem({
  canDelete,
  name,
  reason,
  onDelete,
  supplierId,
}) {
  return (
    <li>
      <Flex alignItems="center" justifyContent="space-between" mb="24px">
        <Grid
          templateColumns="repeat(3, 1fr)"
          width={canDelete ? '90%' : '100%'}
        >
          <Stat
            label="Delivery Partner"
            value={name}
            mb="0"
            borderLeft="none"
            ml="0"
            pl="0"
          />
          <Stat label="Reason" value={reason} mb="0" />
        </Grid>
        {canDelete && (
          <Button
            pt="2px"
            bg="red.solid"
            color="#fff"
            variant="solid"
            borderRadius="32px"
            fontWeight="initial"
            size="sm"
            mr="8px"
            px="14px"
            onClick={() => onDelete(supplierId)}
            leftIcon={<Icon fontSize={16} mr="5px" name="trash" />}
          >
            Delete
          </Button>
        )}
      </Flex>
    </li>
  );
}

export default SupplierBlacklistItem;
