/* eslint-disable no-unused-vars */
import { action, computed, makeObservable, observable } from 'mobx';

export default class XeroStore {
  constructor({ API, toastsStore }) {
    makeObservable(this);
    this.API = API;
    this.toastsStore = toastsStore;
  }

  @observable xero = {};

  @observable isLoadingXeroLink = false;

  @observable xeroLink = '';

  @computed get isAuthorized() {
    return this.xero.authorized;
  }

  @action getXeroAuth = async (redirect = false) => {
    try {
      // this.isLoadingXeroLink = true;
      // const { data } = await this.API.getXeroAuthorize();
      // this.xeroLink = data;
      // if (redirect) {
      //   window.location.replace(data);
      // }
    } catch (e) {
      this.xeroLink = '';
      if (redirect) {
        this.toastsStore.showError({ title: 'Could not fetch xero link' });
      }
    } finally {
      this.isLoadingXeroLink = false;
    }
  };

  @action getXeroStatusLimits = async () => {
    try {
      // const { data } = await this.API.getXeroStatusLimits();
      // this.xero = data;
      // if (!data.authorized) {
      //   toastsStore.showWarning({ title: "Your Xero integration's expired." });
      // }
    } catch (e) {
      this.xero = {};
    }
  };

  @action xeroSync = async (projectId, successCb) => {
    try {
      // await this.API.xeroSync(projectId);
      // this.toastsStore.showSuccess({
      //   title: 'Your project has been successfully updated.',
      // });
      // if (successCb) {
      //   successCb();
      // }
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };
}
