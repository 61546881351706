export const scrollToElementStart = (value, additionalOffset = -120) => {
  const el = document.querySelector(value);
  if (el) {
    const { top } = el.getBoundingClientRect();
    window.scrollTo({
      top: top + window.scrollY + additionalOffset,
      behavior: 'smooth',
    });
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

export const buttonBorder = (isOpen, isOneSelected) => {
  if (isOneSelected) {
    return 'primary.400';
  }
  if (isOpen) {
    return 'primary.400';
  }
  return 'dark.mid';
};

export const iconColor = isOneSelected => {
  if (isOneSelected) {
    return 'primary.400';
  }
  return 'currentColor';
};

export const stripOptionalLabel = (str = '', isReadOnlyView) =>
  isReadOnlyView ? str.replace('(optional)', '') : str;

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${sizes[i]}`;
};

export function stopEventPropagation(e) {
  e.stopPropagation();
}
