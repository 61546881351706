/* eslint-disable */
/* retainer */
export default `
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Schedule 1 Statement of Work&nbsp;</strong></p>
<p><strong>Dated:&nbsp;</strong></p>
<p><strong>Between:</strong></p>
<p><strong>(1) {{DEAZY_NAME}}</strong><span style="font-weight: 400;"> (trading as Deazy) of {{DEAZY_DETAILS}} (Company No. 10305788) (&ldquo;</span><strong>Deazy</strong><span style="font-weight: 400;">&rdquo;); and</span></p>
<p><span style="background-color: #feff00;"><strong>(2) {{CLIENT_NAME}} </strong><span style="font-weight: 400;"> of {{ADDRESS}} (the "</span><strong>Client</strong><span style="font-weight: 400;">")&nbsp;</span></span></p>
<p style="padding-left: 40px;">&nbsp;</p>
<p><strong>Background</strong></p>
<p><span style="font-weight: 400;">(A) Pursuant to Deazy’s standard terms and conditions (the “Terms”), accepted by the Client on <span style="background-color: #feff00;">{{DATE}}</span>, the Client has appointed Deazy to provide <span style="background-color: #feff00;">[INCLUDE AS APPROPRIATE product management, project management, design and software development services]</span>, when required, during the Term. </span></p>
<p><span style="font-weight: 400;">(B) Pursuant to the Terms, the Client is able to request these services (as defined in the Terms) and Deazy has agreed to provide such Services in accordance with this statement of work (&ldquo;<strong>SOW</strong>&rdquo;).</span></p>
<p>&nbsp;</p>
<p><strong>Term</strong></p>
<ol class="start">
<li><span style="font-weight: 400;">This SOW shall come into force on the date it is executed by its parties OR on </span><span style="font-weight: 400; background-color: #feff00;">{{TERM_DATE}}</span><span style="font-weight: 400;"> (the &ldquo;</span><strong>Commencement Date</strong><span style="font-weight: 400;">&rdquo;</span><strong>)</strong></li>
<li><span style="font-weight: 400;">This SOW shall take effect from the Commencement Date and will continue in effect for the initial term of </span><span style="font-weight: 400;"><span style="background-color: #feff00;">{{DURATION}}</span>, </span><span style="font-weight: 400;">(“<strong>Initial Term”</strong>) then continue thereafter, unless terminated earlier, on a <span style="background-color: #feff00;">[monthly]</span></span> rolling basis. </span></li>
<li><span style="font-weight: 400;">Following the Initial Term, either party may terminate this SOW on giving no less than <span style="background-color: #feff00;">[thirty (30) days]</span> notice. In both cases, such notice must expire on or after the Initial Term</span></li>
</ol>
<p><span style="font-weight: 400;"><strong>Conflict</strong></span></p>
<ol class="continue">
<li><span style="font-weight: 400;">If there is an inconsistency between any of the provisions of this SOW and the provisions of the Terms, the provisions of this SOW shall prevail.</span></li>
<li><span style="font-weight: 400;">If a term is capitalised in this SOW but not defined therein the defined terms of the Terms shall be applicable to said term.</span></li>
</ol>
<p><span style="font-weight: 400;"><strong>Services</strong></span></p>
<p><span style="font-weight: 400;"><strong>Scope of work and approach</strong></span></p>
<ol class="continue">
<li><span style="font-weight: 400;"><strong>Context:</strong> The Client wishes to have <span style="background-color: #feff00;">[new or ongoing]</span> product development including, new feature development, bug fixing and minor updates on the <span style="background-color: #feff00;">[PLATFORM DETAILS]</span> (the “<strong>engagement</strong>”).</span></li>
<li><span style="font-weight: 400;"><strong>Approach:</strong> The Services in this SOW are provided on a retainer basis, with a fixed resource allocation detailed in clause 8. Any additional hours outside of the retainer will be delivered on a time & material basis. <span style="background-color: #feff00;">[Hours can be ramped up or down with 30 days notice, but cannot be reduced below 160 hours total per month]</span> </span></li>
</ol>
<ol class="continue">
<li><strong>Scope of Services:</strong>
<ol class="nested-1">
<li><span style="font-weight: 400;">Deazy shall provide (either directly or with the assistance of a sub-contractor): </span><br />
<ol class="nested-2">
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">Cross functional development team to work on;</span>
<ol class="nested-3">
  <li>New features, as prioritized by the Deazy Product Manager and the Client.</li>
  <li>Minor updates to existing features, as prioritized by the Deazy Product Manager and the Client.</li>
  <li>General  and Support</li>
  <li>Bug fixes, as prioritized by the Deazy Product Manager and the Client.</li>
</ol>
</li>
<li style="font-weight: 400;" aria-level="3"><span style="background-color: #feff00;">[Product management;</span>
<ol class="nested-2">
  <li style="background-color: #feff00; display:table;"><span style="font-weight: 400;">Product oversight for the existing product and future roadmap.</span></li>
  <li style="background-color: #feff00; display:table;"><span style="font-weight: 400;">Feature prioritization that will enable value for money from the development budget</span> </li>
  <li style="background-color: #feff00; display:table;"><span style="font-weight: 400;">Provide product ideas and improvements</span></li>
  <li style="background-color: #feff00; display:table;"><span style="font-weight: 400;">Plan and manage development team throughout project]</span></li>
</ol>
</li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">Monthly retainer team breakdown: </span>
<br />
<br />
<table style="border-collapse: collapse; width: 91.9273%; height: 72px;" border="1">
<tbody>
<tr style="height: 18px;">
<td style="width: 49.6158%; height: 18px;">
<p><strong>Resource</strong></p>
</td>
<td style="width: 49.6158%; height: 18px; font-weight: bold;">[<span style="background-color: #feff00;">Days</span>] per month (4 week period)</td>
</tr>
{{RESOURCES}}
<tr style="height: 18px;">
<td style="width: 49.6158%; height: 18px;">
<p><span style="font-weight: bold;">Total Monthly [<span style="background-color: #feff00;">Days</span>]</p>
</td>
<td style="width: 49.6158%; height: 18px;">{{TOTAL_RESOURCE_HOURS}}</td>
</tr>
</tbody>
</table>
</ol>
</li>
</ol>
</li>
</ol>
<br />
<ol class="continue">
<li><strong> Service Level Agreements</strong>
<ol class="nested-1">
<li><span style="font-weight: 400;">Deazy (or a third party retained by Deazy) will respond to new support tickets (bugs and ad-hoc technical support only) according to the following Service Level Agreements (SLAs).  </span><br />
<br />

<table style="border-collapse: collapse; width: 105%; height: 72px; margin-left: -30px" border="1" cellpadding="10">
  <tbody>
    <tr style="height: 18px;">
      <td style="height: 18px;">Severity</td>
      <td style="height: 18px;">Description</td>
      <td style="height: 18px;">Response time</td>
      <td style="height: 18px;">Target Resolution time</td>
    </tr>
    <tr style="height: 18px;">
      <td style="height: 18px;">Critical</td>
      <td style="height: 18px;">
        <p>Any error that either;</p>
        <ul class="resetDisplayList">
          <li>makes the system completely unusable</li>
          <li>impedes the business at large</li>
          <li>stops the client from working during business as usual</li>
        </ul>
        <p>Example: fatal application crash where the OS aborts the application</p>
      </td>
      <td style="height: 18px;">4 Business Hours</td>
      <td style="height: 18px;">8 Business Hours from reporting time</td>
    </tr>
    <tr style="height: 18px;">
      <td style="height: 18px;"><p>Normal</p></td>
      <td style="height: 18px;">
        <p>Any error related to critical user flows in the products.</p>
        <p>Example: login, registration, scanning, payment etc</p>
      </td>
      <td style="height: 18px;"><p>4 Business Hours</p></td>
      <td style="height: 18px;"><p>16 Business Hours from reporting time</p></td>
    </tr>
    <tr style="height: 18px;">
      <td style="height: 18px;"><p>Low</p></td>
      <td style="height: 18px;">
        <p>User interface glitches, typos, low impact errors etc.</p>
      </td>
      <td style="height: 18px;"><p>4 Business Hours</p></td>
      <td style="height: 18px;"><p>40 Business Hours from reporting time</p></td>
    </tr>
    <tr style="height: 18px;">
      <td style="height: 18px;"><p>Client Feedback</p></td>
      <td style="height: 18px;" colspan="3">
        <p>The Client shall confirm that the issue has been solved within 4 Business Days of the time we mark the issue as resolved. We shall consider Your lack of feedback as confirmation that the issue has been resolved and accepted as resolved</p>
      </td>
    </tr>
  </tbody>
</table>
<p>&nbsp;</p>
<li><span style="font-weight: 400;"><strong>Business Hours: </strong>Business Hours are defined as Monday to Friday 9am to 5pm UK time, excluding bank holidays</span></li>
<li><span style="font-weight: 400;"><strong>Response Time: </strong>Response Time is defined as the time elapsed between the initial submission of the issue as a Support ticket and the first response acknowledging the issue, confirming the severity and communicating that a resource is investigating it.</span></li>
<li><span style="font-weight: 400;"><strong>Resolution Time: </strong>Resolution Time is defined as the time elapsed between the initial submission of the issue as a Support ticket and confirmation of the resolution of the issue, which will be either:</span>

<ol class="nested-2">
<li><span style="font-weight: 400;">An explanation that the issue is not caused by the <span style="background-color: #feff00;">[Product details]</span> (e.g. the issue is caused by an external API)</span></li>
<li><span style="font-weight: 400;">A new version of the <span style="background-color: #feff00;">[Product details]</span> that contains the fixed codebase.  The issue will be QA tested internally by the development team’s test resource.  The <span style="background-color: #feff00;">[Product details]</span> will be sanity checked to ensure that the application is still functional by following the major user flows.  The <span style="background-color: #feff00;">[Product details]</span> will be distributed as outlined in clause 8.6.</span></li>
</ol>
</li>

<li><strong>Target Resolution Time: </strong><span style="font-weight: 400;">Target Resolution Time is defined as the target elapsed time between the initial submission of the issue as a Support ticket and confirmation of the resolution of the issue.</span>
<ol class="nested-2"><li><span style="font-weight: 400;">Deazy shall use all reasonable endeavours to meet the Target Resolution Time as set out in the table above, however there may be scenarios where the resolution is not only dependent on the modification of the <span style="background-color: #feff00;">[Product details]</span> codebase, for example, if there are delays due to changes in implementation required from the Client or the Client’s third party suppliers</span> </li></ol>
</li>

<li><strong>Application Deployment:</strong>
<ol class="nested-2"><li><span style="font-weight: 400;">The <span style="background-color: #feff00;">[Product details]</span> is distributed <span style="background-color: #feff00;">[INCLUDE details of distribution to app store / production environments, including the role Deazy and the client play]</span>.
</span>
</li></ol>
</li>

</ol>
</ol>
<p>clauses 7-8 together the "<strong>Services</strong>".</p>

<ol class="continue">
<li><strong>Production / Client Data</strong>
  <ol class="nested-1"><li>
  <span style="font-weight: 400;">
  During the triage and issue resolution process, Deazy will never view, transfer or copy data which is related to customers of the Client, internal staff or other production aspects.  In the case where the issue at hand is related to customers of the Client or internal staff, the Client will either;</span>
  <ol class="nested-2"><li><span style="font-weight: 400;">Provide Deazy with a UAT (test) environment where it is possible to replicate the issue and test the solution</span></li></ol>
  </li></ol>
</li>
</ol>

<ol class="continue">
  <li>
    <strong>Location of services provided</strong>
    <p>Deazy shall work remotely. Any travel required to deliver the Services will be chargeable to the Client but all expenses must be approved in advance by the Client, in both cases in writing.</p>
  </li>
</ol>

<ol class="continue">
  <li>
    <strong>Use of subcontractors</strong>
    <p>The parties acknowledge and agree that Deazy may retain the services of third parties to provide the Services.
    </p>
  </li>
</ol>

<ol class="continue">
  <li>
    <strong>Non-solicitation</strong>
    <p>During the term of this SOW and for a period of twelve (12) months after any termination or expiry of this SOW, Client will not solicit, induce, recruit or encourage any of Deazy’s or its subcontractors’ officers, employees, agents, advisers and other representatives to terminate their relationship with Deazy or the subcontractor (as applicable).</p>
    <p>Client shall provide Deazy with notice as soon as reasonably possible if any of the events outlined in clause 12 above occurs.</p>
  </li>
</ol>

<ol class="continue">
  <li>
    <strong>Charges</strong>
    <p>This SOW will be delivered on a retainer basis, with a fixed resource allocation detailed in clause 8. Any additional hours outside of the retainer will be delivered on a Time & Material basis. The Charges are outlined in the table that follows:</p>
    <table style="border-collapse: collapse; width: 91.9273%; height: 72px;" border="1" cellpadding="10">
    <tbody>
      <tr style="height: 18px;">
        <td style="height: 18px; font-weight:bold;">Month</td>
        <td style="height: 18px; font-weight:bold;">Activity</td>
        <td style="height: 18px; font-weight:bold;">Total (Ex VAT)</td>
      </tr>

      {{CHARGES_PER_PERIODS_ROWS}}
      {{CHARGES_PER_PERIODS_ROWS_TOTAL}}
  
    </tbody>
  </table>
  <p>(the <strong>"Charges"</strong>)</p>
  </li>
</ol>
<p><strong>Payment Schedule</strong></p>
<ol class="continue"><li><span style="font-weight: 400;"> Deazy will invoice monthly in <span style="background-color: #feff00;">[advance/arrears]</span> for all invoices.</span> </li></ol>
<ol class="continue"><li><span style="font-weight: 400;"> Invoices due during the initial term of the SOW are set out in the table below:</span>
<br />
<br />

  {{PREPOPULATED_TABLE}}

</li>
</ol>
<ol class="continue"><li><span style="font-weight: 400;"> All payments shall be due within [<span style="background-color: #feff00;">DELETE AS APPROPRIATE 14 or 30 or 60 days</span>] of the invoice date, in accordance with the Terms. </span></li></ol>


<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<table style="border-collapse: collapse; width: 93.2318%; height: 136px;" border="0">
<tbody>
<tr style="height: 118px;">
<td style="width: 49.6824%; height: 118px;">
<p><strong>Signed</strong><span style="font-weight: 400;"> by </span><span style="background-color: #feff00;"><strong>[A MEMBER OF THE DEAZY LEADERSHIP TEAM]</strong></span></p>
<p><span style="font-weight: 400;">duly authorised for and on behalf of </span><strong>Deazy Limited</strong></p>
</td>
<td style="width: 49.6824%; height: 118px;">
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-weight: 400;">..................................................................................................</span></p>
<p style="text-align: center;"><span style="font-weight: 400;">[Director]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; [Secretary]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;[Authorised Signatory]</span></p>
</td>
</tr>
<tr style="height: 18px;">
<td style="width: 49.6824%; height: 18px;">
<p style="text-align: left;"><strong>Signed<span style="font-weight: 400;"> by </span><span style="background-color: #feff00;">[AUTHORISED SIGNATORY]</span></strong></p>
<p><strong><span style="font-weight: 400;">duly authorised for and on behalf of </span><span style="background-color: #feff00;">{{CLIENT_NAME}}</span></strong></p>
</td>
<td style="width: 49.6824%; height: 18px;">
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-weight: 400;">..................................................................................................</span></p>
<p style="text-align: center;"><span style="font-weight: 400;">[Director]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; [Secretary]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;[Authorised Signatory]</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p>&nbsp;</p>

`;
