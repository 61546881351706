import { omit, get, reject, pick, isEmpty, last } from 'lodash-es';
import { nanoid } from 'nanoid';
import React from 'react';

const useAutoTableRows = (fieldName, fieldsToWatch, formValues, formApi) => {
  const collection = get(formValues, fieldName) || [];

  const onDelete = rowId => {
    formApi.change(fieldName, reject(collection, { rowId }));
  };

  const onAdd = () => {
    formApi.change(fieldName, [...collection, { rowId: nanoid(10) }]);
  };

  React.useEffect(() => {
    if (isEmpty(last(collection))) {
      onAdd();
    }
    if (
      !isEmpty(fieldsToWatch) &&
      !isEmpty(pick(last(collection), fieldsToWatch))
    ) {
      onAdd();
    } else if (!isEmpty(omit(last(collection), 'rowId'))) {
      onAdd();
    }
  }, [last(collection)]);

  return {
    onDelete,
    onAdd,
  };
};

export default useAutoTableRows;
