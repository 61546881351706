import { action, makeObservable, observable } from 'mobx';
import { noop } from 'lodash-es';

export default class StaffProfileStore {
  constructor({ API, toastsStore }) {
    makeObservable(this);
    this.toastsStore = toastsStore;
    this.API = API;
  }

  @observable languages = [];

  @observable languageLevels = [];

  @observable languageSkills = [];

  @observable locationCities = [];

  @observable locationCitiesByState = [];

  @observable mediaFiles = [];

  @observable techSkills = {};

  @observable staffProfile = {};

  @observable allAssessments = [];

  @observable profileAssessments = [];

  @observable userStaffProfiles = [];

  @observable allLanguageSkillAssessments = [];

  @observable staffProfileLanguageSkillAssessments = [];

  @observable citiesByIds = [];

  @observable deliveryPartners = [];

  @observable deliveryPartner = {};

  @observable deliveryPartnersEnums = [];

  @observable completeProfile = {};

  @observable allCountries = [];

  @observable interviews = [];

  @observable myStaffProfile = {};

  @observable profilesRateLimits = {};

  @observable templateFiles = {};

  @observable educationList = [];

  @observable highlights = '';

  @observable workProjects = [];

  @observable isLoadingTechSkills = false;

  @observable isLoadingLanguages = false;

  @observable isLoadingLanguageLevels = false;

  @observable isLoadingLanguageSkill = false;

  @observable isLoadingLanguageSkillCreate = false;

  @observable isLoadingLanguageSkillUpdate = false;

  @observable isLoadingLanguageSkillRemove = false;

  @observable isLoadingLocationCities = false;

  @observable isLoadingCitiesByStateId = false;

  @observable isLoadingMediaGet = false;

  @observable isLoadingMediaUpload = false;

  @observable isLoading = false;

  @observable isLoadingUpdate = false;

  @observable isLoadingGetAllAssessments = false;

  @observable isLoadingGetProfileAssessments = false;

  @observable isLoadingProfileAssessments = false;

  @observable isLoadingGetUserStaffProfiles = false;

  @observable isCheckingGeektasticAssessmentServiceStatus = false;

  @observable isGeektasticServiceUp = false;

  @observable isLoadingAllLanguageSkillAssessments = false;

  @observable isLoadingStaffProfileLanguageSkillAssessments = false;

  @observable isCreatingStaffProfileLanguageSkillAssessments = false;

  @observable isLoadingGetCitiesByIds = false;

  @observable isLoadingGetDeliveryPartners = false;

  @observable isLoadingGetDeliveryPartner = false;

  @observable isLoadingGetDeliveryPartnersEnums = false;

  @observable isLoadingCompleteProfile = false;

  @observable isLoadingAllCountries = false;

  @observable isLoadingInterviews = false;

  @observable isLoadingMyStaffProfile = false;

  @observable isLoadingProfilesRateLimits = false;

  @observable isLoadingTemplateFiles = false;

  @observable isLoadingEducation = false;

  @observable isLoadingEducationItem = false;

  @observable isLoadingDeleteEducation = false;

  @observable isLoadingHighlights = false;

  @observable isLoadingUpdatedHighlights = false;

  @observable isLoadingWorkProjects = false;

  @observable isLoadingUpdatedProjects = false;

  @observable isLoadingDeleteProject = false;

  @action clearStaffProfileData = () => {
    this.staffProfile = {};
  };

  @action getTechSkills = async () => {
    this.techSkills = {};
    this.isLoadingTechSkills = true;
    try {
      const { data } = await this.API.getTechSkills();
      this.techSkills = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingTechSkills = false;
    }
  };

  @action getStaffProfile = async userId => {
    try {
      this.isLoading = true;
      const { data } = await this.API.getStaffProfile(userId);
      this.staffProfile = data;
    } catch (e) {
      this.staffProfile = {};
    } finally {
      this.isLoading = false;
    }
  };

  @action createStaffProfile = async (profileData, onFailure = noop) => {
    try {
      this.isLoading = true;
      const { data } = await this.API.createStaffProfile(profileData);
      this.staffProfile = data;
    } catch (e) {
      if (onFailure) {
        onFailure(e);
      } else {
        this.toastsStore.showError({ title: 'Network error' });
      }
    } finally {
      this.isLoading = false;
    }
  };

  @action updateStaffProfile = async (staffProfileId, profileData) => {
    try {
      this.isLoadingUpdate = true;
      const { data } = await this.API.updateStaffProfile(
        staffProfileId,
        profileData,
      );
      this.staffProfile = { ...this.staffProfile, ...data };
      this.toastsStore.showSuccess({ title: 'Your changes have been saved' });
    } catch (e) {
      this.toastsStore.showError({
        title: 'Your changes could not be saved, please try again!',
      });
    } finally {
      this.isLoadingUpdate = false;
    }
  };

  @action getLanguages = async () => {
    this.languages = {};
    this.isLoadingLanguages = true;
    try {
      const { data } = await this.API.getLanguages();
      this.languages = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingLanguages = false;
    }
  };

  @action getLanguageLevels = async () => {
    this.languageLevels = {};
    this.isLoadingLanguageLevels = true;
    try {
      const { data } = await this.API.getLanguageLevels();
      this.languageLevels = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingLanguageLevels = false;
    }
  };

  @action getLanguageSkills = async userId => {
    try {
      this.isLoadingLanguageSkill = true;
      const { data } = await this.API.getLanguageSkills(userId);
      this.languageSkills = data || [];
    } catch (e) {
      this.languageSkills = [];
    } finally {
      this.isLoadingLanguageSkill = false;
    }
  };

  @action createLanguageSkill = async userData => {
    try {
      this.isLoadingLanguageSkillCreate = true;
      const { data } = await this.API.createLanguageSkill(userData);

      this.languageSkills = [...(this.languageSkills || []), data];
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingLanguageSkillCreate = false;
    }
  };

  @action updateLanguageSkill = async (languageEntryId, userData) => {
    try {
      this.isLoadingLanguageSkillUpdate = true;
      const { data } = await this.API.updateLanguageSkill(
        languageEntryId,
        userData,
      );

      this.languageSkills = this.languageSkills?.map(languageSkill => {
        if (languageSkill.id === languageEntryId) {
          return {
            ...languageSkill,
            ...data,
          };
        }
        return languageSkill;
      });

      this.toastsStore.showSuccess({ title: 'Your changes have been saved' });
    } catch (e) {
      if (e.response.data.status === 400) {
        this.toastsStore.showError({
          title: Array.isArray(e.response.data.message)
            ? e.response.data.message.shift()
            : e.response.data.message,
        });
      } else {
        this.toastsStore.showError({
          title: 'Your changes could not be saved, please try again!',
        });
      }
    } finally {
      this.isLoadingLanguageSkillUpdate = false;
    }
  };

  @action removeLanguageSkill = async languageEntryId => {
    try {
      this.isLoadingLanguageSkillRemove = true;
      await this.API.removeLanguageSkill(languageEntryId);

      this.languageSkills = this.languageSkills?.filter(
        languageSkill => languageSkill.id !== languageEntryId,
      );

      this.toastsStore.showInfo({
        title: 'Language skill has been removed',
      });
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingLanguageSkillRemove = false;
    }
  };

  @action checkExistingEmail = async email => {
    try {
      return this.API.checkExistingEmail(email);
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
      return false;
    }
  };

  @action getTechSkill = async keyword => {
    try {
      return this.API.getTechSkill(keyword);
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
      return false;
    }
  };

  @action getLocationCities = async keyword => {
    try {
      this.isLoadingLocationCities = true;
      const { data } = await this.API.getLocationCities(keyword);

      this.locationCities = data;
    } catch (e) {
      this.locationCities = [];
    } finally {
      this.isLoadingLocationCities = false;
    }
  };

  @action getMediaFiles = async staffProfileId => {
    try {
      this.isLoadingMediaGet = true;
      const { data } = await this.API.getMediaFiles(staffProfileId);
      this.mediaFiles = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
      this.mediaFiles = [];
    } finally {
      this.isLoadingMediaGet = false;
    }
  };

  @action uploadMediaFile = async (staffProfileId, files) => {
    try {
      this.isLoadingMediaUpload = true;
      const { data } = await this.API.uploadMediaFile(staffProfileId, files);
      this.mediaFiles = [...this.mediaFiles, data];
    } catch (e) {
      if (
        e.response.data.statusCode === 415 ||
        e.response.data.statusCode === 413
      ) {
        this.toastsStore.showError({
          title: e.response.data.message,
        });
      } else {
        this.toastsStore.showError({
          title: 'The file could not be uploaded, please try again!',
        });
      }
    } finally {
      this.isLoadingMediaUpload = false;
    }
  };

  @action removeMediaFile = async (staffProfileId, mediaId) => {
    try {
      await this.API.removeMediaFile(staffProfileId, mediaId);
      this.mediaFiles = this.mediaFiles.filter(
        mediaFile => mediaFile.id !== mediaId,
      );
      this.toastsStore.showInfo({
        title: 'Document was removed',
      });
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    }
  };

  @action getAllAssessments = async () => {
    try {
      this.isLoadingGetAllAssessments = true;
      const { data } = await this.API.getAllAssessments();
      this.allAssessments = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingGetAllAssessments = false;
    }
  };

  @action getAssessmentServiceStatus = async assessmentService => {
    try {
      this.isCheckingGeektasticAssessmentServiceStatus = true;
      const { data } = await this.API.getAssessmentServiceStatus(
        assessmentService,
      );
      this.isGeektasticServiceUp = data?.up;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isCheckingGeektasticAssessmentServiceStatus = false;
    }
  };

  @action getProfileAssessments = async staffProfileId => {
    try {
      this.isLoadingGetProfileAssessments = true;
      const { data } = await this.API.getProfileAssessments(staffProfileId);
      this.profileAssessments = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingGetProfileAssessments = false;
    }
  };

  @action createProfileAssessment = async (staffProfileId, assessmentData) => {
    try {
      this.isLoadingProfileAssessments = true;
      return this.API.createProfileAssessment(staffProfileId, assessmentData);
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingProfileAssessments = false;
    }
  };

  @action getUserStaffProfiles = async query => {
    try {
      this.isLoadingGetUserStaffProfiles = true;
      const { data } = await this.API.getUserStaffProfiles(!query ? '' : query);
      this.userStaffProfiles = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingGetUserStaffProfiles = false;
    }
  };

  @action getCitiesByIds = async query => {
    try {
      this.isLoadingGetCitiesByIds = true;
      const { data } = await this.API.getCitiesByIds(!query ? '' : query);
      this.citiesByIds = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingGetCitiesByIds = false;
    }
  };

  @action getAllLanguageSkillAssessments = async () => {
    try {
      this.isLoadingAllLanguageSkillAssessments = true;
      const { data } = await this.API.getAllLanguageSkillAssessments();
      this.allLanguageSkillAssessments = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingAllLanguageSkillAssessments = false;
    }
  };

  @action getStaffProfileLanguageSkillAssessments = async staffProfileId => {
    try {
      this.isLoadingStaffProfileLanguageSkillAssessments = true;
      const { data } = await this.API.getStaffProfileLanguageSkillAssessments(
        staffProfileId,
      );
      this.staffProfileLanguageSkillAssessments = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingStaffProfileLanguageSkillAssessments = false;
    }
  };

  @action createStaffProfileLanguageSkillAssessment = async (
    staffProfileId,
    assessmentData,
  ) => {
    try {
      this.isCreatingStaffProfileLanguageSkillAssessments = true;

      await this.API.createStaffProfileLanguageSkillAssessment(
        staffProfileId,
        assessmentData,
      );
      // Call it without triggering the loading state
      const { data } = await this.API.getStaffProfileLanguageSkillAssessments(
        staffProfileId,
      );
      this.staffProfileLanguageSkillAssessments = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isCreatingStaffProfileLanguageSkillAssessments = false;
    }
  };

  @action getDeliveryPartners = async query => {
    try {
      this.isLoadingGetDeliveryPartners = true;
      const { data } = await this.API.getDeliveryPartners(!query ? '' : query);
      this.deliveryPartners = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingGetDeliveryPartners = false;
    }
  };

  @action getPartner = async partnerId => {
    try {
      this.isLoadingGetDeliveryPartner = true;
      const { data } = await this.API.getDeliveryPartner(partnerId);
      this.deliveryPartner = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingGetDeliveryPartner = false;
    }
  };

  @action getDeliveryPartnersEnums = async isUsersApi => {
    try {
      this.isLoadingGetDeliveryPartnersEnums = true;
      const { data } = await this.API.getSupplierFilters(isUsersApi);
      this.deliveryPartnersEnums = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingGetDeliveryPartnersEnums = false;
    }
  };

  @action getCompleteProfile = async userId => {
    try {
      this.isLoadingCompleteProfile = true;
      const { data } = await this.API.getCompleteProfile(userId);
      this.completeProfile = data;
    } catch (e) {
      this.completeProfile = {};
    } finally {
      this.isLoadingCompleteProfile = false;
    }
  };

  @action getAllCountries = async () => {
    try {
      this.isLoadingAllCountries = true;
      const { data } = await this.API.getAllCountries();
      this.allCountries = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingAllCountries = false;
    }
  };

  @action getInterviews = async userId => {
    this.isLoadingInterviews = true;
    try {
      const { data } = await this.API.getInterviews(userId);
      this.interviews = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingInterviews = false;
    }
  };

  @action getMyStaffProfile = async () => {
    try {
      this.isLoadingMyStaffProfile = true;
      const { data } = await this.API.getMeComplete();
      this.myStaffProfile = data;
    } catch (e) {
      this.myStaffProfile = {};
    } finally {
      this.isLoadingMyStaffProfile = false;
    }
  };

  @action getRateLimits = async () => {
    try {
      this.isLoadingProfilesRateLimits = true;
      const { data } = await this.API.getRateLimits();
      this.profilesRateLimits = data;
    } catch (e) {
      this.profilesRateLimits = {};
    } finally {
      this.isLoadingProfilesRateLimits = false;
    }
  };

  @action getMediaFilesTemplates = async () => {
    try {
      this.isLoadingTemplateFiles = true;
      const { data } = await this.API.getTemplateFiles();
      this.templateFiles = data;
    } catch (e) {
      this.templateFiles = {};
    } finally {
      this.isLoadingTemplateFiles = false;
    }
  };

  @action getEducationList = async userId => {
    try {
      this.isLoadingEducation = true;
      const { data } = await this.API.getEducationList(userId);
      this.educationList = data;
    } catch (e) {
      this.educationList = [];
    } finally {
      this.isLoadingEducation = false;
    }
  };

  @action createEducationItem = async (
    userId,
    educationData,
    onFailure = noop,
  ) => {
    try {
      this.isLoadingEducationItem = true;
      const { data } = await this.API.createEducation(userId, educationData);
      this.educationList = data;
    } catch (e) {
      if (onFailure) {
        onFailure(e);
      } else {
        this.toastsStore.showError({ title: 'Network error' });
      }
    } finally {
      this.isLoadingEducationItem = false;
    }
  };

  @action deleteEducation = async (userId, educationId) => {
    try {
      this.isLoadingDeleteEducation = true;
      await this.API.deleteEducation(userId, educationId);
      this.educationList = this.educationList.filter(
        education => education.id !== educationId,
      );
      this.toastsStore.showInfo({
        title: 'Education has been removed',
      });
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingDeleteEducation = false;
    }
  };

  @action getWorkHighlights = async userId => {
    try {
      this.isLoadingHighlights = true;
      const { data } = await this.API.getWorkHighlights(userId);
      this.highlights = data;
    } catch (e) {
      this.highlights = '';
    } finally {
      this.isLoadingHighlights = false;
    }
  };

  @action updateWorkHighlights = async (userId, highlightsData) => {
    try {
      this.isLoadingUpdatedHighlights = true;
      const { data } = await this.API.updateWorkHighlights(
        userId,
        highlightsData,
      );
      this.highlights = data;
    } catch (e) {
      this.highlights = '';
    } finally {
      this.isLoadingUpdatedHighlights = false;
    }
  };

  @action getWorkProjects = async userId => {
    try {
      this.isLoadingWorkProjects = true;
      const { data } = await this.API.getWorkProjects(userId);
      this.workProjects = data;
    } catch (e) {
      this.workProjects = [];
    } finally {
      this.isLoadingWorkProjects = false;
    }
  };

  @action updateWorkProjects = async (userId, projectsData) => {
    try {
      this.isLoadingUpdatedProjects = true;
      const { data } = await this.API.updateWorkProjects(userId, projectsData);
      this.workProjects = data;
    } catch (e) {
      this.workProjects = [];
    } finally {
      this.isLoadingUpdatedProjects = false;
    }
  };

  @action deleteWorkProject = async (userId, projectId) => {
    try {
      this.isLoadingDeleteProject = true;
      await this.API.deleteWorkProject(userId, projectId);
      this.workProjects = this.workProjects.filter(
        project => project.id !== projectId,
      );
      this.toastsStore.showInfo({
        title: 'Project has been removed',
      });
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingDeleteProject = false;
    }
  };
}
