import React from 'react';
import { Field, Form } from 'react-final-form';
import { Flex } from '@chakra-ui/react';
import TextInput from '@components/forms/TextInput';
import { ErrorText } from '@components/forms/FormError';
import Button from '@styles/Button';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '@routes';
import Typography from '@styles/Typography';
import yupFormValidation from '@utils/yupFormValidation';
import { emailValidation } from '@validationSchemas/userValidation';

const ForgottenPassword = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    validate={yupFormValidation(emailValidation)}
    render={({ handleSubmit, submitError, submitting, submitSucceeded }) => {
      if (submitSucceeded) {
        return (
          <Flex minH="180px" flexDir="column">
            <Typography variant="h4" mb="24px">
              Submitted successfully!
            </Typography>
            <Typography lineHeight="1.5">
              If we have a record of the email address you have provided, you
              will receive an email with reset instructions.
            </Typography>
            <Flex justifyContent="flex-end">
              <Button
                variant="ghost"
                as={Link}
                to={APP_ROUTES.auth.signIn}
                mt="24px"
                h="22px"
                p="0px 12px"
              >
                Back to login page
              </Button>
            </Flex>
          </Flex>
        );
      }
      return (
        <form onSubmit={handleSubmit}>
          <Flex alignItems="center" flexDirection="column">
            <Field
              label="Email address"
              name="email"
              placeholder="Enter email address here"
              type="text"
              component={TextInput}
            />
            {submitError && <ErrorText>{submitError}</ErrorText>}
          </Flex>
          <Flex justifyContent="flex-end">
            <Button
              variant="ghost"
              as={Link}
              to={APP_ROUTES.auth.signIn}
              mt="12px"
              mb="32px"
              h="22px"
              p="0px 12px"
            >
              Back to login page
            </Button>
          </Flex>
          <Button
            w="100%"
            size="lg"
            isLoading={submitting}
            isDisabled={submitting}
            type="submit"
          >
            Send
          </Button>
        </form>
      );
    }}
  />
);

export default ForgottenPassword;
