import React from 'react';
import StatusDropdown from '@components/StatusDropdown';
import { inject, observer } from 'mobx-react';
import {
  NEW_PROPOSAL_STATUSES,
  VISIBLE_COSTS,
  FEATURES_BREAKDOWN,
} from '@app/constants';
import { useDisclosure } from '@chakra-ui/react';
import { includes, isBoolean, isEmpty, map } from 'lodash-es';
import { NEW_PROPOSAL_STATUSES_OPTIONS } from '@components/StatusDropdownTrigger';
import { formatCurrency } from '@utils';
import ChangeStatusCommentModal from './ChangeStatusCommentModal';

const ProposalStatusDropdown = ({
  proposalsStore: {
    proposalData,
    clientProposalData,
    updateProposalStatus,
    isLoadingProposal,
    deazyCostBreakdown,
  },
  briefsStore: { brief },
  usersStore: { isAdminOrDL, isClient, isTeamAdmin },
  isPlugAndPlay = false,
  isDisabled,
  clientCurrency,
  useAltLabel,
  useOutsideBg,
  availableOptions = [],
  mainNameQA = '',
  isSmallerScreen,
  buttonProps = {
    mt: '38px',
    ml: '24px',
  },
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [targetStatus, setTargetStatus] = React.useState('');

  const optionsWithDisabled = map(NEW_PROPOSAL_STATUSES_OPTIONS, opt => {
    return {
      ...opt,
      isDisabled: !isEmpty(availableOptions)
        ? !includes(availableOptions, opt.label)
        : opt.label === proposalData.status,
    };
  });

  const status = React.useMemo(() => {
    if (isLoadingProposal) {
      return null;
    }
    const statusToDisplay = isClient
      ? clientProposalData.status
      : proposalData.status;
    return statusToDisplay || NEW_PROPOSAL_STATUSES.WITH_DP;
  }, [proposalData.status, clientProposalData.status, isLoadingProposal]);

  const fullRangeCost = `${formatCurrency(
    deazyCostBreakdown.data.proposalMinCost,
    clientCurrency,
  )} - ${formatCurrency(
    deazyCostBreakdown.data.proposalMaxCost,
    clientCurrency,
  )}`;

  const defaultValues = {
    featuresBreakdown: FEATURES_BREAKDOWN.HIDDEN_COSTS,
    visibleCosts: VISIBLE_COSTS.ACTUAL_ESTIMATE,
  };

  const shareSettingsForClientInnitialValues = {
    ...(proposalData.clientSettings || defaultValues),
    statusMessage: null,
  };

  const showLabel = React.useMemo(() => {
    if (isPlugAndPlay) {
      return true;
    }
    if (isBoolean(isSmallerScreen) && !isSmallerScreen && !isPlugAndPlay) {
      return true;
    }
    return false;
  }, [isSmallerScreen, isPlugAndPlay]);

  if (!status) {
    return null;
  }

  return (
    <>
      <StatusDropdown
        useOutsideBg={useOutsideBg}
        popoverContentProps={{ w: '200px' }}
        buttonProps={buttonProps}
        setOnClick={false}
        options={optionsWithDisabled}
        isReadOnly={!isAdminOrDL || brief.isClosed}
        onChange={value => {
          setTargetStatus(value);
          onOpen();
        }}
        currentValue={status}
        isDisabled={isDisabled}
        label={showLabel && 'Current status'}
        mainNameQA={mainNameQA}
        qaValue="proposalStatusDropdown"
        capitalizeLabel={false}
        buttonWrapperProps={{ pb: '0px' }}
        labelProps={{ mt: '0px', textTransform: 'inherit' }}
        useAltLabel={useAltLabel}
        isTeamAdmin={isTeamAdmin}
        isLoadingProposal={isLoadingProposal}
      />
      <ChangeStatusCommentModal
        onSubmit={values =>
          updateProposalStatus(
            { ...values, status: targetStatus, isPlugAndPlay },
            onClose,
          )
        }
        targetStatus={targetStatus}
        isOpen={isOpen}
        onClose={onClose}
        mainNameQA={mainNameQA}
        actualEstimateCost={formatCurrency(
          deazyCostBreakdown.data.proposalCost,
          clientCurrency,
        )}
        hightCost={formatCurrency(
          deazyCostBreakdown.data.proposalMaxCost,
          clientCurrency,
        )}
        fullRangeCost={fullRangeCost}
        initialValues={shareSettingsForClientInnitialValues}
        isPlugAndPlay={isPlugAndPlay}
      />
    </>
  );
};

export default inject(
  'proposalsStore',
  'usersStore',
  'briefsStore',
)(observer(ProposalStatusDropdown));
