import { Box, Flex } from '@chakra-ui/react';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { formatNumber } from '@utils';
import React from 'react';
import { Field, Form } from 'react-final-form';
import Tooltip from '@styles/Tooltip';
import { required } from '@utils/formValidators';
import { RightIconInput } from './forms/MaskedTextInput';
import Icon from './Icon';

const PnPExchangeRateBox = ({
  isReadOnly,
  updateProposalCurrencyRate,
  initialValues,
  globalEur,
  globalUsd,
}) => {
  const [actionOpened, setActionOpened] = React.useState();

  const onSubmit = values =>
    updateProposalCurrencyRate(values, () => setActionOpened(false));

  return (
    <>
      <Box
        maxW="320px"
        w="100%"
        p="11px 11px 8px 11px"
        borderRadius="8px"
        bg="dark.light"
      >
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, values, submitting }) => {
            const renderInput = (key, iconName) => {
              if (values[key]) {
                return (
                  <Field
                    name={key}
                    validate={required}
                    isAllowed={({ floatValue }) =>
                      (floatValue <= 10 && floatValue > 0) || !floatValue
                    }
                    component={RightIconInput}
                    maxW="100%"
                    iconName={iconName}
                    wrapperProps={{ flex: 1 }}
                    placeholder="0.00"
                    iconProps={{ top: '12px' }}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                );
              }
              return null;
            };
            const renderLabel = (currencyKey, currency) => {
              if (values[currencyKey]) {
                const differsFromGlobalRate =
                  formatNumber(currency === 'EUR' ? globalEur : globalUsd) !==
                  formatNumber(values[currencyKey]);

                return (
                  <Typography w="50%" fontWeight={600}>
                    {`${formatNumber(values[currencyKey])} ${currency}`}
                    {differsFromGlobalRate && (
                      <Tooltip
                        zIndex={33000}
                        p="12px"
                        borderRadius="8px"
                        lineHeight="normal"
                        label="The exchange rate differs from current platform default exchange rate."
                        hasArrow
                        fontFamily="WS"
                        bg="dark.solid"
                        px="24px"
                        py="12px"
                        placement="bottom"
                      >
                        <Box as="span" ml="10px">
                          <Icon name="question" color="#000" mt="-4px" />
                        </Box>
                      </Tooltip>
                    )}
                  </Typography>
                );
              }
              return null;
            };

            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Flex w="100%">
                  <Typography color="#4E4E4E" variant="text2" mr="auto">
                    1 GBP equals
                  </Typography>
                  {actionOpened ? (
                    <>
                      <Button
                        color="gray.mid"
                        variant="unstyled"
                        fontSize={15}
                        h="16px"
                        isDisabled={submitting}
                        leftIcon={{ mr: 0, fontSize: 18, name: 'cross' }}
                        p="0px 10px"
                        onClick={() => setActionOpened(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="blue.solid"
                        h="16px"
                        variant="unstyled"
                        fontSize={15}
                        leftIcon={{ mr: 0, name: 'simpleTick' }}
                        type="submit"
                        isLoading={submitting}
                        p="0px 10px"
                      >
                        Confirm
                      </Button>
                    </>
                  ) : (
                    <Button
                      color="blue.solid"
                      variant="unstyled"
                      fontSize={15}
                      h="16px"
                      isDisabled={isReadOnly}
                      leftIcon={{ mr: 0, mt: '2px', name: 'refresh' }}
                      onClick={() => setActionOpened(true)}
                    >
                      Change rate
                    </Button>
                  )}
                </Flex>
                <Flex w="100%">
                  {actionOpened ? (
                    <Flex mt="8px" gap="12px" w="100%">
                      {renderInput('currencyRateEur', 'eur')}
                      {renderInput('currencyRateUsd', 'usd')}
                    </Flex>
                  ) : (
                    <Flex mt="24px" mb="14px" w="100%">
                      {renderLabel('currencyRateEur', 'EUR')}
                      {renderLabel('currencyRateUsd', 'USD')}
                    </Flex>
                  )}
                </Flex>
              </form>
            );
          }}
        />
      </Box>
    </>
  );
};

export default PnPExchangeRateBox;
