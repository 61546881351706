import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { APP_ROUTES } from '@routes';
import { Flex } from '@chakra-ui/react';
import Button from '@styles/Button';
import TextInput from '@components/forms/TextInput';
import { ErrorText } from '@components/forms/FormError';
import yupFormValidation from '@utils/yupFormValidation';
import { signInValidation } from '@validationSchemas/userValidation';

const SignInForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    validate={yupFormValidation(signInValidation)}
    render={({ handleSubmit, submitError, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Flex flexDirection="column">
          <Field
            label="Username"
            name="username"
            placeholder="Enter your username"
            type="text"
            component={TextInput}
            containerProps={{ mb: '24px' }}
          />
          <Field
            label="Password"
            placeholder="Enter your password"
            name="password"
            type="password"
            component={TextInput}
          />
          {submitError && <ErrorText>{submitError}</ErrorText>}
          <Flex justifyContent="flex-end">
            <Button
              variant="ghost"
              as={Link}
              to={APP_ROUTES.auth.forgottenPassword}
              mt="12px"
              mb="32px"
              h="22px"
              p="0px 12px"
            >
              Forgotten Password?
            </Button>
          </Flex>
        </Flex>
        <Button
          w="100%"
          size="lg"
          isLoading={submitting}
          isDisabled={submitting}
          type="submit"
        >
          Login
        </Button>
      </form>
    )}
  />
);

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignInForm;
