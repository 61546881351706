/* eslint-disable no-nested-ternary */
import { MAIN_NAMES_QA, PROJECT_TYPES } from '@app/constants';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Divider,
  Flex,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import Button from '@styles/Button';
import { isProjectBuild } from '@utils/projectUtils';
import { COLORS } from '@styles/theme';
import Typography from '@styles/Typography';
import { find, first, get, map } from 'lodash-es';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { Fragment } from 'react';
import DeazyActuals from './DeazyActuals';

import DeazyAllocations from './DeazyAllocations';
import LastDeazyActuals from './LastDeazyActuals';
import PendingTeamActuals from './PendingTeamActuals';
import PendingTeamAllocations from './PendingTeamAllocations';
import TeamActuals from './TeamActuals';
import TeamAllocations from './TeamAllocations';
import AddNewDeliveryPartnerModal from './AddNewDeliveryPartnerModal';

const badgeProps = {
  variant: 'solid',
  variantColor: 'blue',
  fontSize: 14,
  p: '10px',
  borderRadius: '8px',
  mr: '10px',
};

const HeaderBadges = ({ isLoading, status }) => (
  <Flex justifyContent="flex-end">
    {isLoading && <Spinner mr="12px" />}
    {status.shouldReport && !isLoading && (
      <Badge
        data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--reportRequiredBadge`}
        {...badgeProps}
      >
        Report required
      </Badge>
    )}
    {status.shouldApprove && !isLoading && (
      <Badge
        {...badgeProps}
        data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--approvalRequiredBadge`}
      >
        Approval Required
      </Badge>
    )}
  </Flex>
);
const AccordionIconButton = () => (
  <Flex
    borderRadius="8px"
    width="40px"
    h="40px"
    bg="primary.100"
    alignItems="center"
    justify="center"
  >
    <AccordionIcon color="black" fontSize="22px" />
  </Flex>
);

const Allocations = ({
  projectId,
  project,
  allocationsStore: {
    fetchCurrentAllocations,
    fetchPendingAllocations,
    currentAllocations,
    fetchActualsStatus,
    fetchLastActuals,
    fetchPendingActuals,
    pendingAllocations,
    canAddNewDeliveryPartner,
    actuals: {
      status: { data: status = {}, isLoading: isLoadingStatus },
    },
    deazyActuals: {
      status: { data: deazyStatus = {}, isLoading: isLoadingDeazyStatus },
    },
  },
  usersStore: { isAdminOrDL, isClient, isTeamAdminSideUser },
}) => {
  const { deazyAsClient } = project;
  const isFixedPrice = project.projectType === PROJECT_TYPES.FIXED_PRICE;

  React.useEffect(() => {
    fetchCurrentAllocations({ projectId, pToS: project.projectToSuppliers });

    if (!isFixedPrice && projectId) {
      fetchPendingAllocations({ projectId, pToS: project.projectToSuppliers });
      fetchActualsStatus({ projectId, pToS: project.projectToSuppliers });
      fetchLastActuals({ projectId, pToS: project.projectToSuppliers });
      fetchPendingActuals({ projectId, pToS: project.projectToSuppliers });
    }
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const allocationTitle = () => {
    if (isFixedPrice) {
      if (isProjectBuild(project.engagementType)) {
        return `Total Allocations`;
      }
      return `Weekly Allocations`;
    }
    return `Allocations`;
  };

  return (
    <Box px="24px" w="100%" mx="auto" pb="60px" mt="32px">
      {map(currentAllocations, (pts, i) => {
        const actualsStatus = get(
          find(
            status,
            ({ supplier = {} }) => supplier?.id === pts?.supplier?.id,
          ),
          'actuals[0]',
          {},
        );
        const nextActualsDate = actualsStatus.nextActualsDate
          ? moment(actualsStatus.nextActualsDate).format('Do MMMM YYYY')
          : null;

        const allocationEntries = toJS(
          first(pts.allocations)?.formPreparedEntries,
        );
        return (
          <Fragment key={`current-allocation-${pts.id}`}>
            <Flex justifyContent="flex-end" mb="24px" alignItems="baseline">
              {canAddNewDeliveryPartner && i === 0 && (
                <Button
                  h="48px"
                  leftIcon={{ name: 'add' }}
                  data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--addNewDPButton`}
                  onClick={onOpen}
                >
                  Add role(s) from another Delivery Partner
                </Button>
              )}
            </Flex>
            <Accordion allowMultiple allowToggle defaultIndex={[0]}>
              <AccordionItem
                bg="#fff"
                border={`1px solid ${COLORS.dark.mid}`}
                borderRadius="8px"
                p="20px"
                mb="40px"
              >
                <AccordionButton borderRadius="8px">
                  <Typography variant="h3" flex="1" textAlign="left">
                    {`${pts?.supplier?.name || ''} - ${allocationTitle()}`}
                  </Typography>
                  <AccordionIconButton />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {(isAdminOrDL || isTeamAdminSideUser || isClient) && (
                    <>
                      {!isFixedPrice && (
                        <>
                          <PendingTeamAllocations
                            allocations={pendingAllocations[i]?.allocations}
                            project={project}
                            supplierId={pts?.supplier?.id}
                            projectId={projectId}
                          />
                          <Divider my="40px" />
                        </>
                      )}
                      <TeamAllocations
                        currentPts={pts}
                        allocations={allocationEntries}
                        supplierId={pts?.supplier?.id}
                        projectId={projectId}
                      />
                    </>
                  )}
                </AccordionPanel>
              </AccordionItem>
              {!isFixedPrice && (
                <AccordionItem
                  bg="#fff"
                  border={`1px solid ${COLORS.dark.mid}`}
                  borderRadius="8px"
                  p="20px"
                  mb="30px"
                >
                  <AccordionButton borderRadius="8px">
                    <Flex w="100%" alignItems="center">
                      <Flex w="100%" flexDir="column">
                        <Flex w="100%" alignItems="center">
                          <Typography variant="h4" flex="1" textAlign="left">
                            Actuals
                          </Typography>
                          <HeaderBadges
                            isLoading={isLoadingStatus}
                            status={actualsStatus}
                          />
                        </Flex>
                        <Typography textAlign="left" mt="12px">
                          {isTeamAdminSideUser
                            ? nextActualsDate
                              ? `On ${nextActualsDate}, you will be able to add the
    number of hours each person spent each week during the
    payment period. We'll also notify you when you need to do
    this.`
                              : ''
                            : `At the end of each payment period you will be able to see
    the number of hours each person spent on the project per
    week.`}
                        </Typography>
                      </Flex>
                      <AccordionIconButton />
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <PendingTeamActuals
                      project={project}
                      projectId={projectId}
                      supplierId={pts?.supplier?.id}
                    />
                    <Divider my="40px" />
                    <TeamActuals
                      projectId={projectId}
                      allocations={allocationEntries}
                      supplierId={pts?.supplier?.id}
                    />
                  </AccordionPanel>
                </AccordionItem>
              )}
            </Accordion>
          </Fragment>
        );
      })}
      {(isClient || isAdminOrDL) && !deazyAsClient && (
        <>
          <Accordion allowMultiple allowToggle defaultIndex={[0]}>
            <AccordionItem
              bg="#fff"
              border={`1px solid ${COLORS.dark.mid}`}
              borderRadius="8px"
              p="20px"
              mb="20px"
            >
              <AccordionButton borderRadius="8px">
                <Typography variant="h3" flex="1" textAlign="left">
                  {`Deazy - ${allocationTitle()}`}
                </Typography>
                <AccordionIconButton />
              </AccordionButton>
              <AccordionPanel pb={4}>
                {(isAdminOrDL || isClient) && (
                  <DeazyAllocations projectId={projectId} resourceAsText />
                )}
              </AccordionPanel>
            </AccordionItem>
            {!isFixedPrice && (
              <AccordionItem
                bg="#fff"
                border={`1px solid ${COLORS.dark.mid}`}
                borderRadius="8px"
                p="20px"
                mb="30px"
              >
                <AccordionButton borderRadius="8px" flexWrap="wrap">
                  <Flex w="100%" alignItems="center">
                    <Flex w="100%" flexDir="column">
                      <Flex w="100%" alignItems="center">
                        <Typography variant="h4" flex="1" textAlign="left">
                          Actuals
                        </Typography>
                        <HeaderBadges
                          isLoading={isLoadingDeazyStatus}
                          status={deazyStatus}
                        />
                      </Flex>
                      <Typography textAlign="left" mt="12px">
                        At the end of each payment period you will be able to
                        see the number of hours each person spent on the project
                        per week.
                      </Typography>
                    </Flex>
                    <AccordionIconButton />
                  </Flex>
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <LastDeazyActuals projectId={projectId} />
                  <Divider my="40px" />
                  <DeazyActuals projectId={projectId} />
                </AccordionPanel>
              </AccordionItem>
            )}
          </Accordion>
        </>
      )}
      <AddNewDeliveryPartnerModal
        isOpen={isOpen}
        onClose={onClose}
        project={project}
      />
    </Box>
  );
};

export default inject('allocationsStore', 'usersStore')(observer(Allocations));
