import { Box, Stack } from '@chakra-ui/react';
import AsyncAutocomplete from '@components/forms/_common/AsyncAutocomplete';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { required } from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { API } from '../../app/api';

const AddDeazyLeadToClientModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2" mb="30px" textAlign="center">
        Add Deazy Lead to Client
      </Typography>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Box>
                <Field
                  name="deazyLead"
                  label="Choose a Deazy Lead"
                  placeholder="Choose a Deazy Lead"
                  component={AsyncAutocomplete}
                  styleProps={{ mb: '38px' }}
                  getOptionsFn={API.searchDLsAndAdmins}
                  customOptionMap={option => ({
                    value: option,
                    label: `${option.fullname} (${option.email})`,
                  })}
                  validate={required}
                />
              </Box>

              <Stack mt="40px" spacing={3}>
                <Button isLoading={submitting} type="submit">
                  Add
                </Button>
                <Button
                  variant="outline"
                  isDisabled={submitting}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Stack>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default AddDeazyLeadToClientModal;
