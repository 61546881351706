import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const SubmitProposalConfirmationModal = ({
  isOpen,
  onSubmit,
  onClose,
  isLoading,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styleProps={{ p: '40px 50px', borderRadius: '8px' }}
      closeOnOverlayClick={!isLoading}
      closeOnEsc={!isLoading}
    >
      <Typography variant="h2" mb="24px" textAlign="center">
        Submit proposal
      </Typography>
      <Typography>
        Are you sure you want to submit the Proposal? You can not make changes
        until the admin changes the status ‘With delivery partner’.
      </Typography>
      <Stack isInline mt="40px" spacing={3}>
        <Button isLoading={isLoading} onClick={onSubmit} flex={1}>
          Submit
        </Button>
        <Button
          variant="outline"
          onClick={onClose}
          isDisabled={isLoading}
          flex={1}
        >
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};

export default SubmitProposalConfirmationModal;
