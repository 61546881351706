import { Avatar, Box, Flex } from '@chakra-ui/react';
import Checkbox from '@components/forms/_common/Checkbox';
import useMediaQuery from '@hooks/useMediaQuery';
import Badge from '@styles/Badge';
import Tooltip from '@styles/Tooltip';
import Typography from '@styles/Typography';
import { prepareTaskDescription } from '@utils/taskUtils';
import { includes, isEqual, isNil, omit } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import JsxParser from 'react-jsx-parser';
import { Link, useHistory } from 'react-router-dom';
import AssignmentDropdown from './AssignmentDropdown';
import CompleteTaskButton from './CompleteTaskButton';
import DueBadge from './DueBadge';

const TaskDescription = ({ description, ...props }) => (
  <Typography fontSize={15} {...props}>
    <JsxParser
      renderInWrapper={false}
      jsx={description}
      components={{ Typography, Link }}
    />
  </Typography>
);

const badgeProps = {
  fontSize: '10px',
  borderRadius: '4px',
  padding: '4px',
};

const CategoryBadge = ({ category, ...props }) => {
  return (
    <Badge
      bg={category.bg}
      color={category.color}
      border="none"
      variant="tertiary"
      fontFamily="WM"
      p="4px 16px"
      {...props}
    >
      {category.label}
    </Badge>
  );
};

const TaskCard = ({
  task,
  paramsStatus,
  usersStore: { profile: myUser },
  tasksStore: { toggleTaskSelection, completeTask, reassignTask },
  ...rest
}) => {
  const { description, category, link } = prepareTaskDescription(task) || {};
  const { allowCompletion, client, supplier } = task?.action || {};
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');
  const triggerRef = React.useRef(null);
  const popoverOffset = [
    0,
    -(
      triggerRef.current?.getBoundingClientRect().top +
      triggerRef.current?.offsetHeight -
      127
    ),
  ];

  if (!description || !category) {
    return null;
  }

  const isCompletedStatus = paramsStatus === 'Completed';
  const isOpenStatus = paramsStatus === 'Open';
  const isCompleted = task.isCompleted || paramsStatus === 'Completed';

  const taskHero = React.useMemo(() => {
    if (isNil(supplier)) {
      return client;
    }
    if (isNil(client)) {
      return supplier;
    }
    return undefined;
  }, [client, supplier]);

  const deazyAsClient = isNil(taskHero);

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const history = useHistory();

  return (
    <Flex
      border="1px solid"
      borderColor={task.isSelected ? 'blue.solid' : 'dark.mid'}
      w="100%"
      bg="#fff"
      onClick={e => {
        const { target } = e;
        if (includes(['DIV', 'P'], target.tagName) && !isMenuOpen) {
          history.push(link);
        }
      }}
      p="12px"
      borderRadius="8px"
      minH="74px"
      mb="8px"
      _hover={{
        boxShadow: '0 0 5px 0 rgba(51, 102, 255, 0.4)',
        cursor: 'pointer',
      }}
      data-test-id={rest['data-test-id']}
      className="taskCard"
    >
      {!isCompletedStatus && (
        <Flex
          alignItems="center"
          borderRight="1px solid"
          borderColor="dark.mid"
          pr={isSmallerScreen ? '8px' : '4px'}
          mr={isSmallerScreen ? '8px' : '12px'}
          pb="4px"
        >
          <Checkbox
            input={{ onChange: () => toggleTaskSelection(task.id) }}
            checked={task.isSelected}
            id={`task-checkbox-${task.id}`}
            size={isSmallerScreen ? 'sm' : undefined}
          />
        </Flex>
      )}
      <Box flex={1}>
        <Flex alignItems="center" mb="8px">
          <Flex alignItems="center">
            <Tooltip label={deazyAsClient ? 'Deazy' : taskHero?.name || 'N/A'}>
              <Avatar
                name={deazyAsClient ? 'Deazy' : taskHero?.name}
                src={deazyAsClient ? '/images/deazy-logo.svg' : taskHero?.logo}
                {...(deazyAsClient && {
                  bg: 'primary.600',
                  css: {
                    img: { padding: '6px', borderRadius: '0px' },
                  },
                })}
                size="xs"
                mr="8px"
              />
            </Tooltip>
            {task?.action?.dueDate && !isCompleted && (
              <DueBadge
                dueDate={task?.action?.dueDate}
                mr="8px"
                {...(isSmallerScreen && badgeProps)}
              />
            )}
            <CategoryBadge
              category={category}
              mr="8px"
              {...(isSmallerScreen && badgeProps)}
            />
          </Flex>
          {!isSmallerScreen && (
            <Typography variant="text2" fontSize={13} color="gray.300">
              {`Created: ${moment(task.createdAt).format(
                'MMM DD, YYYY h:mmA',
              )}`}
            </Typography>
          )}
          <Flex ml="auto">
            {!isCompleted && (
              <>
                <CompleteTaskButton
                  onClick={() => completeTask(task.id)}
                  isLoading={task.isLoading}
                  isDisabled={!allowCompletion || !isOpenStatus}
                  {...(isSmallerScreen && { padding: '12px' })}
                />
                <AssignmentDropdown
                  placement="bottom-start"
                  onChooseOption={option => reassignTask(option, task.id)}
                  value={
                    task.assignee
                      ? {
                          ...task?.assignee,
                          isMe: myUser.id === task?.assignee?.id,
                        }
                      : {
                          ...task.owner,
                          isMe: myUser.id === task?.owner?.id,
                        }
                  }
                  popoverStyleProps={{ offset: popoverOffset }}
                >
                  {({ currentValue, onOpen, isOpen }) => {
                    React.useEffect(() => {
                      setIsMenuOpen(isOpen);
                    }, [isOpen]);
                    return (
                      <Typography
                        as="button"
                        ml="8px"
                        h={isSmallerScreen ? '24px' : '28px'}
                        cursor="pointer"
                        variant="text4"
                        textTransform="uppercase"
                        px="6px"
                        borderRadius="8px"
                        border="1px solid"
                        borderColor="dark.mid"
                        onClick={onOpen}
                        ref={triggerRef}
                        data-test-id="task-assigment-trigger"
                      >
                        {currentValue}
                      </Typography>
                    );
                  }}
                </AssignmentDropdown>
              </>
            )}
          </Flex>
        </Flex>
        {isSmallerScreen && (
          <Typography mb={2} variant="text2" fontSize={11} color="gray.300">
            {moment(task.createdAt).format('MMM DD, YYYY h:mmA')}
          </Typography>
        )}
        <TaskDescription
          description={description}
          {...(isSmallerScreen && { fontSize: '12px', lineHeight: '16px' })}
        />
      </Box>
    </Flex>
  );
};

export default React.memo(
  inject('tasksStore', 'usersStore')(observer(TaskCard)),
  (prevProps, nextProps) => {
    if (
      isEqual(
        omit(prevProps, ['tasksStore', 'usersStore']),
        omit(nextProps, ['tasksStore', 'usersStore']),
      )
    ) {
      return true;
    }
    return false;
  },
);
