import { Box, Flex } from '@chakra-ui/react';
import Textarea from '@components/forms/_common/Textarea';
import { stripOptionalLabel } from '@utils/appUtils';
import {
  composeValidators,
  mustBeShorterOrEqual,
  required,
} from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';
import AutoSave from './AutoSave';

const proposalSummaryPlaceHolder =
  'Include an explanation of your approach as well as any additional information about why you would be well-suited to this project';

const ProposalOverview = ({
  isReadOnly = false,
  initialValues,
  setSubmit,
  onSubmit,
  mainNameQA,
  isSmallerScreen,
}) => {
  return (
    <Box>
      <Form
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        initialValues={initialValues}
        render={({ handleSubmit, form, values }) => {
          setSubmit(handleSubmit);
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <AutoSave
                formApi={form}
                values={values}
                onSave={onSubmit}
                formName="overview"
              />
              <Flex
                flexFlow={isSmallerScreen && 'column'}
                py={isSmallerScreen && '20px'}
              >
                <Box
                  w={!isSmallerScreen && '50%'}
                  mr={!isSmallerScreen && '40px'}
                >
                  <Field
                    component={Textarea}
                    mb={isSmallerScreen ? '24px' : 0}
                    label="Proposal summary"
                    inputProps={{ disabled: isReadOnly }}
                    rows={isSmallerScreen ? 10 : 24}
                    name="summary"
                    data-test-id={`${mainNameQA}--summary`}
                    validate={composeValidators(
                      required,
                      mustBeShorterOrEqual(10000),
                    )}
                    placeholder={proposalSummaryPlaceHolder}
                    isDisabled={isReadOnly}
                  />
                </Box>
                <Box w={!isSmallerScreen && '50%'}>
                  <Field
                    component={Textarea}
                    mb={isSmallerScreen ? '24px' : '30px'}
                    label={stripOptionalLabel(
                      'Assumptions (optional)',
                      isReadOnly,
                    )}
                    inputProps={{ disabled: isReadOnly }}
                    rows={isSmallerScreen ? 4 : 5}
                    name="assumptions"
                    data-test-id={`${mainNameQA}--assumptions`}
                    validate={mustBeShorterOrEqual(10000)}
                    placeholder="Enter assumptions"
                    isDisabled={isReadOnly}
                  />
                  <Field
                    component={Textarea}
                    label={stripOptionalLabel(
                      'Dependencies (optional)',
                      isReadOnly,
                    )}
                    inputProps={{ disabled: isReadOnly }}
                    rows={isSmallerScreen ? 4 : 5}
                    name="dependencies"
                    data-test-id={`${mainNameQA}--dependencies`}
                    validate={mustBeShorterOrEqual(10000)}
                    placeholder="Enter dependencies"
                    mb={isSmallerScreen ? '24px' : '30px'}
                    isDisabled={isReadOnly}
                  />
                  <Field
                    component={Textarea}
                    mb="0"
                    label={stripOptionalLabel('Risks (optional)', isReadOnly)}
                    inputProps={{ disabled: isReadOnly }}
                    rows={isSmallerScreen ? 4 : 5}
                    name="risks"
                    data-test-id={`${mainNameQA}--risks`}
                    validate={mustBeShorterOrEqual(10000)}
                    placeholder="Enter risks"
                    marginBottom="0"
                    isDisabled={isReadOnly}
                  />
                </Box>
              </Flex>
            </form>
          );
        }}
      />
    </Box>
  );
};

export default ProposalOverview;
