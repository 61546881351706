import { Box } from '@chakra-ui/react';
import { isEmpty, map, times } from 'lodash-es';
import React from 'react';
import { Facebook as TeamLoader } from 'react-content-loader';
import EmptyList from '@components/EmptyList';
import CandidateCard from './CandidateCard';
import SectionTitle from './SectionTitle';

const CandidatesList = ({
  validCandidates,
  removedCandidates,
  mainNameQA,
  isLoading,
}) => {
  const renderCandidateCard = candidate => (
    <CandidateCard
      candidate={candidate}
      key={`candidate-card-${candidate.id}`}
      mainNameQA={mainNameQA}
    />
  );

  if (isLoading) {
    return map(times(8), (t, idx) => (
      <Box mb="16px" w="100%" key={`loader-${idx}`} maxW="720px">
        <TeamLoader height="240px" width="100%" />
      </Box>
    ));
  }
  if (isEmpty(validCandidates)) {
    return (
      <EmptyList
        label="No candidates have been proposed for this brief yet"
        description="They will appear once the delivery partners you have assisgned submit their proposals"
        imagePath="/images/candidates.svg"
      />
    );
  }

  return (
    <>
      {map(validCandidates, renderCandidateCard)}
      {removedCandidates.length > 0 && (
        <>
          <SectionTitle
            mt="56px"
            mb="24px"
            label="Withdrawn candidates"
            description="These are any candidates which Delivery Partners have removed from their proposal."
          />
          {map(removedCandidates, renderCandidateCard)}
        </>
      )}
    </>
  );
};

export default CandidatesList;
