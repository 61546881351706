import React, { useEffect, useMemo } from 'react';
import { observer, inject } from 'mobx-react';
import { Box } from '@chakra-ui/react';
import Spinner from '@styles/Spinner';
import { NEW_ROLES } from '@app/constants';
import { assign, find } from 'lodash-es';
import BackButton from '@components/BackButton';
import { APP_ROUTES } from '@routes';
import UserCard from '@pages/Users/UserCard';
import UserCardContent from '@pages/Users/UserCardContent';
import * as DeazyMarketplaceFront from '../../utils/importMarketplaceUtil';
import injectMarketplaceComponent from '../../utils/injectMarketplaceComponent';
import AdditionalDetails from './AdditionalDetails';
import RolesDetails from './RolesDetails';
import UserGroupCard from './UserGroupCard';

const User = ({
  match: {
    params: { id },
  },
  usersStore: {
    fetchUser,
    user,
    isAdminOrDL,
    isTeamAdmin,
    deleteUserByRole,
    addUserToGroupByRole,
    isLoading,
    isProfileLoaded,
    setUserActivity,
    setUserAccessibility,
  },
}) => {
  useEffect(() => {
    fetchUser(id);
  }, []);

  const canSeeGroupTab = useMemo(
    () =>
      !!find(
        user.roles,
        r => r.name === NEW_ROLES.ADMIN || r.name === NEW_ROLES.DEAZY_LEAD,
      ),
    [user],
  );

  const canSeeViewStaffProfile = useMemo(
    () =>
      !!find(
        user.roles,
        r =>
          r.name === NEW_ROLES.TEAM_ADMIN || r.name === NEW_ROLES.TEAM_MEMBER,
      ),
    [user],
  );

  const memorizedSupplier = useMemo(() => {
    return assign(user.supplier, { isTeamAdmin: true });
  }, [user]);

  const ViewStaffProfile = injectMarketplaceComponent(
    DeazyMarketplaceFront.ViewStaffProfile,
  );

  return (
    <Box p="32px" pb="155px" minH="calc(100vh - 80px)">
      {isLoading || !isProfileLoaded ? (
        <Spinner />
      ) : (
        <>
          {isAdminOrDL && !canSeeViewStaffProfile && (
            <BackButton to={APP_ROUTES.users.all}>
              Go to all user list
            </BackButton>
          )}
          {isTeamAdmin && !canSeeViewStaffProfile && (
            <BackButton to={APP_ROUTES.users.teamMembers}>
              Go to all team members list
            </BackButton>
          )}
          {canSeeViewStaffProfile && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              {isAdminOrDL && (
                <BackButton to={APP_ROUTES.users.all}>
                  Go to all user list
                </BackButton>
              )}
              {isTeamAdmin && (
                <BackButton to={APP_ROUTES.users.teamMembers}>
                  Go to all team members list
                </BackButton>
              )}
              <DeazyMarketplaceFront.ShareProfileView />
            </Box>
          )}
          <Box w="100%" maxW="1200px" mx="auto">
            <UserCard isStatic>
              <UserCardContent
                user={user}
                setUserActivity={setUserActivity}
                setUserAccessibility={setUserAccessibility}
              />
            </UserCard>
            {canSeeViewStaffProfile && <ViewStaffProfile />}
            {isAdminOrDL && (
              <RolesDetails
                roles={user.roles}
                userId={user.id}
                addUserToGroupByRole={addUserToGroupByRole}
                deleteUserByRole={deleteUserByRole}
                fetchUser={fetchUser}
              />
            )}
            {isAdminOrDL && canSeeGroupTab && (
              <UserGroupCard
                userId={user.id}
                addUserToGroupByRole={addUserToGroupByRole}
                deleteUserByRole={deleteUserByRole}
                fetchUser={fetchUser}
                groups={user.groups}
              />
            )}
            {(isAdminOrDL || isTeamAdmin) && (
              <>
                {user.customer && (
                  <AdditionalDetails
                    obj={user.customer}
                    label="Customer details"
                  />
                )}
                {user.supplier && (
                  <AdditionalDetails
                    obj={memorizedSupplier}
                    label="Delivery Partner details"
                  />
                )}
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default inject('usersStore')(observer(User));
