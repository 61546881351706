import React from 'react';
import ZoneContainer from '@components/ZoneContainer';
import Button from '@styles/Button';
import { nanoid } from 'nanoid';
import { inject, observer } from 'mobx-react';
import { findIndex, isEmpty, map, reject } from 'lodash-es';
import DPCard from './DPCard';

const MultipleDPSection = ({ formApi, toastsStore, mainNameQA }) => {
  const [suppliers, setSuppliers] = React.useState([{ rowId: nanoid(10) }]);

  React.useEffect(() => {
    const {
      values: { projectToSuppliers: dps = [] },
    } = formApi.getState();
    if (!isEmpty(dps)) {
      setSuppliers(map(dps, () => ({ rowId: nanoid(10) })));
    }
  }, []);

  const onAddNewDP = () => {
    const rowId = nanoid(10);
    setSuppliers([...suppliers, { rowId }]);
  };

  const onRemoveDP = rowId => {
    const { values } = formApi.getState();
    const idx = findIndex(suppliers, s => s.rowId === rowId);
    if (values?.projectToSuppliers[idx].isPrimary) {
      toastsStore.showError({
        title: 'You cannot delete primary Delivery Partner!',
      });
    } else {
      setSuppliers(reject(suppliers, { rowId }));
      formApi.change(
        'projectToSuppliers',
        reject(values?.projectToSuppliers, (_, i) => i === idx),
      );
    }
  };

  return (
    <>
      {map(suppliers, (s, idx) => (
        <DPCard
          key={`dp-card-${s.rowId || s.id}`}
          idx={idx}
          formApi={formApi}
          fieldName={`projectToSuppliers[${idx}]`}
          mainNameQA={mainNameQA}
          onRemoveDP={suppliers.length > 1 ? () => onRemoveDP(s.rowId) : null}
        />
      ))}
      {suppliers.length < 10 && (
        <ZoneContainer bg="#fff" py="32px" borderColor="dark.mid" mt="40px">
          <Button
            leftIcon={{ name: 'add' }}
            size="lg"
            minW="274px"
            onClick={onAddNewDP}
          >
            Add a new Delivery Partner
          </Button>
        </ZoneContainer>
      )}
    </>
  );
};

export default inject('toastsStore')(observer(MultipleDPSection));
