import React from 'react';
import WhiteCard from '@components/WhiteCard';
import {
  Box,
  Flex,
  Grid,
  Avatar,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { formatCurrency } from '@utils';
import { isEmpty, get, toNumber, first } from 'lodash-es';
import useMediaQuery from '@hooks/useMediaQuery';
import { getPnpResourceCurrencyRate } from '@utils/briefUtils';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { NEW_PROPOSAL_STATUSES_OPTIONS } from '@components/StatusDropdownTrigger';
import Button from '@styles/Button';
import useDownload from '@hooks/useDownload';
import StatusDropdown from '@components/StatusDropdown';
import { CANDIDATE_STATUSES } from '@app/constants';
import Typography from '@styles/Typography';
import QueryCandidateModal from './QueryCandidateModal';
import CandidateBadge from './CandidateBadge';
import CandidateQueriesList from './CandidateQueriesList';
import CandidateCardButtons from './CandidateCardButtons';
import CandidateDiffTooltip from './CandidateDiffTooltip';

const Stat = ({ label, value, bold, ...props }) => (
  <Box {...props}>
    <Typography color="dark.solid" mb="8px" variant="text3">
      {label}
    </Typography>
    <Typography
      variant="text2"
      mb="8px"
      fontWeight={bold && 700}
      wordBreak="break-word"
    >
      {value}
    </Typography>
  </Box>
);

const arrayOfTemplateColumns = [
  'repeat(2, 1fr)',
  'repeat(2, 1fr)',
  'repeat(4, 1fr)',
  'repeat(4, 1fr)',
  'repeat(4, 1fr)',
];

const CandidateCard = ({
  proposalCandidatesStore: {
    shortlistCandidate,
    unshortlistCandidate,
    queryCandidate,
  },
  briefsStore: { brief },
  candidate,
  mainNameQA,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const plugPlayProposalCv = get(candidate, 'plugPlayProposalCv') || {};
  const { onDownload, isLoading } = useDownload({
    url: plugPlayProposalCv.link,
    fileName: plugPlayProposalCv.name,
  });
  const {
    isRemoved,
    queries,
    ppBriefProposal: { status: proposalStatus, newPpBriefToSupplier } = {},
    status = CANDIDATE_STATUSES.PROPOSED,
  } = candidate;

  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const adjustedRateToProposalCurrency = React.useMemo(() => {
    return (
      toNumber(candidate.rate) * getPnpResourceCurrencyRate(candidate, brief)
    );
  }, [candidate, brief]);

  const rateWithMargin = React.useMemo(() => {
    return (
      toNumber(adjustedRateToProposalCurrency) / ((100 - toNumber(30)) / 100)
    );
  }, [adjustedRateToProposalCurrency]);

  const onQueryCandidate = React.useCallback(
    ({ query }) =>
      queryCandidate({ briefId: brief.id, candidate, query }, onClose),
    [brief.id, candidate],
  );

  const mobileBadgeProps = {
    h: '18px',
    fontSize: '10px',
    lineHeight: '10px',
    p: '4px',
    borderRadius: '4px',
  };

  return (
    <WhiteCard
      border="1px solid #D9DADA"
      bg={isRemoved ? '#F9F9F9' : 'white'}
      p="0"
      mt="0"
      mb="24px"
      data-test-id={`${mainNameQA}--singleCandidateCard`}
    >
      <Box
        pb="12px"
        borderBottom="1px solid"
        borderColor="dark.mid"
        w="100%"
        {...(!isSmallerScreen && { display: 'flex', alignItems: 'flex-start' })}
      >
        {isSmallerScreen && !isRemoved && (
          <Flex alignItems="flex-start" mr="-4px" mt="8px" mb="2px">
            <CandidateBadge
              status={status}
              mr="12px"
              ml="auto"
              mainNameQA={mainNameQA}
              {...mobileBadgeProps}
            />
            <StatusDropdown
              mainNameQA={mainNameQA}
              options={NEW_PROPOSAL_STATUSES_OPTIONS}
              currentValue={proposalStatus}
              isReadOnly
              buttonProps={{
                ...mobileBadgeProps,
                paddingTop: '3px',
                padding: '4px 4px',
              }}
              buttonDescriptionProps={{ fontSize: '10px', lineHeight: '10px' }}
            />
          </Flex>
        )}
        <Flex pt={!isSmallerScreen && '12px'} px="11px" align="center" w="100%">
          <Avatar
            src={get(
              candidate,
              'ppBriefProposal.newPpBriefToSupplier.supplier.logo',
            )}
            name={get(
              candidate,
              'ppBriefProposal.newPpBriefToSupplier.supplier.name',
              'Deazy',
            )}
            mr="7px"
          />
          <Box>
            <Flex alignItems="center">
              <Typography
                variant="h4"
                mb="4px"
                data-test-id={`${mainNameQA}--candidateTechnology`}
              >
                {candidate?.briefResource?.technology}
              </Typography>
              {!isEmpty(candidate.diffs) && !isRemoved && (
                <CandidateDiffTooltip newDiff={first(candidate.diffs)}>
                  <Button
                    variant="ghost"
                    h="auto"
                    p={0}
                    iconButton={{ name: 'version' }}
                    data-test-id={`candidate-${candidate.id}-changelog`}
                  />
                </CandidateDiffTooltip>
              )}
            </Flex>
            <Typography
              variant="text4"
              mb="8px"
              data-test-id={`${mainNameQA}--candidateSupplierName`}
            >
              {candidate?.ppBriefProposal?.newPpBriefToSupplier?.supplier
                ?.name || '-'}
            </Typography>
          </Box>
          {!isSmallerScreen && !isRemoved && (
            <Flex alignItems="flex-start" ml="auto">
              <CandidateBadge
                status={status}
                h="24px"
                mr="12px"
                mainNameQA={mainNameQA}
              />
              <StatusDropdown
                options={NEW_PROPOSAL_STATUSES_OPTIONS}
                currentValue={proposalStatus}
                isReadOnly
                mainNameQA={mainNameQA}
              />
            </Flex>
          )}
        </Flex>
      </Box>
      <Grid
        position="relative"
        w="100%"
        p="24px 16px 32px"
        gap="16px"
        templateColumns={arrayOfTemplateColumns}
      >
        <Stack>
          <Stat
            label="Candidate name"
            value={candidate.name}
            data-test-id={`${mainNameQA}--candidateName`}
          />
        </Stack>
        <Stack>
          <Stat
            label="Delivery partner rate (hourly)"
            value={formatCurrency(
              candidate.rate,
              candidate.ppBriefProposal.overriddenCurrency,
            )}
            bold
            data-test-id={`${mainNameQA}--candidateRate`}
          />
        </Stack>
        <Stack>
          <Stat
            label="Adjusted to project currency "
            value={formatCurrency(adjustedRateToProposalCurrency, 'GBP')}
            bold
            data-test-id={`${mainNameQA}--candidateCurrency`}
          />
        </Stack>
        <Stack>
          <Stat
            label="Rate +30% margin"
            value={formatCurrency(rateWithMargin, 'GBP')}
            bold
            data-test-id={`${mainNameQA}--candidateMargin`}
          />
        </Stack>
        <Stack>
          <Stat
            label="Role"
            value={candidate.role}
            data-test-id={`${mainNameQA}--candidateRole`}
          />
        </Stack>
        <Stack>
          <Stat
            label="Seniority"
            value={candidate.seniority}
            data-test-id={`${mainNameQA}--candidateSeniority`}
          />
        </Stack>
        <Stack>
          <Stat
            label="Location"
            value={candidate.location}
            data-test-id={`${mainNameQA}--candidateLocation`}
          />
        </Stack>
        <Stack>
          <Stat
            label="Start date"
            data-test-id={`${mainNameQA}--candidateStartDate`}
            value={moment(candidate.startDate).format('DD MMMM YYYY')}
          />
        </Stack>
        {candidate.typeOfPlugPlayResource && (
          <Stack gridColumn="1 / -1">
            <Stat
              label="Candidate is"
              value={`${candidate.typeOfPlugPlayResource} ${newPpBriefToSupplier
                ?.supplier?.name || 'DP'}`}
              data-test-id={`${mainNameQA}--candidateTypeOfPlugPlayResource`}
            />
          </Stack>
        )}
        {candidate.comments && (
          <Stack gridColumn="1 / -1">
            <Stat
              label="Comment"
              value={candidate.comments}
              data-test-id={`${mainNameQA}--candidateComment`}
            />
          </Stack>
        )}
      </Grid>
      {!isRemoved && (
        <CandidateCardButtons
          shortlistCandidate={shortlistCandidate}
          isLoading={isLoading}
          onDownload={onDownload}
          onOpen={onOpen}
          unshortlistCandidate={unshortlistCandidate}
          candidate={candidate}
          status={status}
          plugPlayProposalCv={plugPlayProposalCv}
          proposalStatus={proposalStatus}
          brief={brief}
          mainNameQA={mainNameQA}
        />
      )}
      <QueryCandidateModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onQueryCandidate}
      />
      <CandidateQueriesList queries={queries} mainNameQA={mainNameQA} />
    </WhiteCard>
  );
};

export default inject(
  'proposalCandidatesStore',
  'briefsStore',
)(observer(CandidateCard));
