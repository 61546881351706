import { API } from '@app/api';
import { Grid, Flex, useDisclosure } from '@chakra-ui/react';
import ListContainer from '@components/ListContainer';
import PageResults from '@components/PageResults';
import PageSize from '@components/PageSize';
import Pagination from '@components/Pagination';
import SortAndOrderSelect from '@components/SortAndOrderSelect';
import SubSidebarTabs from '@components/SubSidebarTabs';
import useListing from '@hooks/useListing';
import useSubSidebarToggle from '@hooks/useSubSidebarToggle';
import { APP_ROUTES } from '@routes';
import Button from '@styles/Button';
import { addToCurrentGroup } from '@utils/usersUtils';
import { filter, isEmpty, last, split } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import qs from 'query-string';
import React from 'react';
import useMediaQuery from '@hooks/useMediaQuery';
import * as DeazyMarketplaceFront from '../../utils/importMarketplaceUtil';
import injectMarketplaceComponent from '../../utils/injectMarketplaceComponent';
import AddToGroupModal from './AddToGroupModal';
import UsersList from './UsersList';

const selectOptions = [
  {
    label: 'Creation date (New to Old)',
    value: 'orderDirection=DESC&orderBy=id',
  },
  {
    label: 'Creation date (Old to New)',
    value: 'orderDirection=ASC&orderBy=id',
  },
  {
    label: 'User name (A-Z)',
    value: 'orderDirection=ASC&orderBy=username',
  },
  {
    label: 'User name (Z-A)',
    value: 'orderDirection=DESC&orderBy=username',
  },
];

const Users = ({
  history,
  location: { search, pathname },
  appStore: {
    toggleSubSidebar,
    isSubSidebarOpened,
    featureFlags: { 'bulk-upload-profile': bulkUploadProfileFeatureFlag },
  },
  usersStore: {
    setUserActivity,
    isAdmin,
    setUserAccessibility,
    addUserToGroupByRole,
    isAdminOrDL,
    isTeamAdmin,
  },
  isClientLeadRoute,
  isTeamMemberRoute,
  isTeamAdminRoute,
  isProjectManagerRoute,
  isAdminRoute,
  isAllUsersRoute,
}) => {
  const {
    page = 1,
    orderDirection = 'DESC',
    orderBy = 'id',
    ...params
  } = qs.parse(search);

  const isSmallerScreen = useMediaQuery('(max-width: 1300px)');

  const onOrderChange = order => {
    history.push(
      `${APP_ROUTES.users.all}?${qs.stringify({
        ...params,
        page: 1,
      })}&${order}`,
    );
  };

  const fetchUsers = React.useMemo(() => {
    if (isAdminRoute) {
      return API.getAllAdmins;
    }
    if (isClientLeadRoute) {
      return API.getAllCLs;
    }
    if (isTeamMemberRoute) {
      return API.getAllDevelopers;
    }
    if (isTeamAdminRoute) {
      return API.getAllSupplierAdmins;
    }
    if (isProjectManagerRoute) {
      return API.getAllPMs;
    }
    return API.getAllUsers;
  }, [
    isClientLeadRoute,
    isTeamMemberRoute,
    isTeamAdminRoute,
    isProjectManagerRoute,
    isAdminRoute,
  ]);

  const {
    items: users,
    totalPages,
    isLoading,
    totals,
    onFilterChange,
    fetch,
  } = useListing(fetchUsers);

  const {
    isOpen: isAddToGroupModalOpen,
    onOpen: onAddToGroupModalOpen,
    onClose: onAddToGroupModalClose,
  } = useDisclosure();

  useSubSidebarToggle(() => toggleSubSidebar(isAdminOrDL));

  const isListEmpty = isEmpty(users) && !isLoading;
  const isFiltered = !isEmpty(params.query);

  const tabs = filter(
    [
      {
        label: 'All',
        value: 'users',
        show: isAdminOrDL,
        link: APP_ROUTES.users.all,
      },
      {
        label: 'Admins',
        value: 'admins',
        show: isAdminOrDL,
        link: APP_ROUTES.users.admins,
      },
      {
        label: 'Client Leads',
        value: 'client-leads',
        show: isAdminOrDL,
        link: APP_ROUTES.users.clientLeads,
      },
      {
        label: 'Team Admins',
        value: 'team-admins',
        show: isAdminOrDL,
        link: APP_ROUTES.users.teamAdmins,
      },
      {
        label: 'Team Members',
        value: 'team-members',
        show: isAdminOrDL,
        link: APP_ROUTES.users.teamMembers,
      },
      {
        label: 'Deazy Leads',
        value: 'deazy-leads',
        show: isAdminOrDL,
        link: APP_ROUTES.users.projectManagers,
      },
    ],
    { show: true },
  );

  const activeTab = last(split(pathname, '/'));

  const UserBulkUpload = injectMarketplaceComponent(
    DeazyMarketplaceFront.UserBulkUpload,
  );

  return (
    <>
      {isAdminOrDL && (
        <SubSidebarTabs
          value={activeTab}
          tabs={tabs}
          subsidebarProps={{
            width: isSubSidebarOpened ? '172px' : '0',
          }}
        />
      )}
      <ListContainer key={`users-page-filters-${search}`}>
        {(!isListEmpty || isFiltered) && (
          <Flex
            w="100%"
            justify="space-between"
            mb="20px"
            display="-webkit-inline-box"
          >
            <PageResults
              totalPages={totalPages}
              currentPage={page}
              totals={totals}
            />
            {isAllUsersRoute && (
              <Flex>
                <SortAndOrderSelect
                  options={selectOptions}
                  onChange={({ value: order }) => onOrderChange(order)}
                  defaultValue={`orderDirection=${orderDirection}&orderBy=${orderBy}`}
                />
              </Flex>
            )}
            {!isAllUsersRoute && (
              <Grid
                gap="16px"
                alignItems="end"
                justifyItems="end"
                templateColumns={
                  bulkUploadProfileFeatureFlag
                    ? [
                        'repeat(1, 1fr)',
                        'repeat(1, 1fr)',
                        isTeamMemberRoute && isAdminOrDL && !isAllUsersRoute
                          ? 'repeat(2, 1fr)'
                          : 'repeat(1, 1fr)',
                        isTeamMemberRoute && isAdminOrDL && !isAllUsersRoute
                          ? 'repeat(2, 1fr)'
                          : 'repeat(1, 1fr)',
                        isTeamMemberRoute && isAdminOrDL && !isAllUsersRoute
                          ? 'repeat(2, 1fr)'
                          : 'repeat(1, 1fr)',
                      ]
                    : 'repeat(1, 1fr)'
                }
              >
                {isTeamMemberRoute && bulkUploadProfileFeatureFlag && (
                  <UserBulkUpload />
                )}
                {isAdminOrDL && !isAllUsersRoute && (
                  <>
                    {(isTeamMemberRoute && isTeamAdmin) ||
                    (isAdminRoute && !isAdmin) ? null : (
                      <Button
                        leftIcon={{
                          name: 'add',
                          mr: '12px',
                          fontSize: 14,
                        }}
                        onClick={onAddToGroupModalOpen}
                        isDisabled={isAddToGroupModalOpen}
                      >
                        Add user to {addToCurrentGroup(pathname)}
                      </Button>
                    )}
                  </>
                )}
              </Grid>
            )}
          </Flex>
        )}
        <UsersList
          setUserActivity={setUserActivity}
          setUserAccessibility={setUserAccessibility}
          users={users}
          isLoading={isLoading}
        />

        <Flex
          flexDirection={isSmallerScreen && 'column'}
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          pt="24px"
          pb="72px"
        >
          {totals > 0 && (
            <PageSize
              containerProps={isSmallerScreen && { mb: '12px', minW: 'auto' }}
            />
          )}
          <Pagination
            forcePage={+page - 1}
            pageCount={totalPages}
            onPageChange={p => onFilterChange({ page: p })}
            containerProps={isSmallerScreen && { mb: '85px' }}
          />
        </Flex>
      </ListContainer>
      {isAddToGroupModalOpen && (
        <AddToGroupModal
          isOpen={isAddToGroupModalOpen}
          onClose={onAddToGroupModalClose}
          groupTitle={addToCurrentGroup(pathname)}
          fetch={fetch}
          addUserToGroupByRole={addUserToGroupByRole}
          isClientLeadRoute={isClientLeadRoute}
          isTeamMemberRoute={isTeamMemberRoute}
          isTeamAdminRoute={isTeamAdminRoute}
          isProjectManagerRoute={isProjectManagerRoute}
        />
      )}
    </>
  );
};

export default inject('usersStore', 'appStore')(observer(Users));
