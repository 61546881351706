import { MAIN_NAMES_QA, CHANGE_REQUEST_STATUS } from '@app/constants';
import { Box, Button, Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import { formatCurrency } from '@utils';
import { get, isNil } from 'lodash-es';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Stat } from '../../Payments/components';

const buttonProps = {
  fontWeight: '400',
  fontSize: 18,
  bg: 'aqua.solid',
  color: '#fff',
  fontFamily: 'WS',
  borderRadius: '30px',
  px: '20px',
  h: '50px',
};

export const HorizontalStat = ({ value, label, ...rest }) => (
  <Flex alignItems="center" display="inline-block" mb="12px" {...rest}>
    <Typography variant="text2" fontFamily="WB" mr="8px" display="inline">
      {`${label}:`}
    </Typography>
    <Typography
      variant="text2"
      fontFamily="WM"
      display="inline"
      wordBreak="break-word"
      data-test-id={rest['data-test-id']}
    >
      {value || '-'}
    </Typography>
  </Flex>
);

const prepareRate = (isDisabled, rate, currency) => {
  if (isDisabled) {
    return 'Disabled';
  }
  if (+rate > 0) {
    return formatCurrency(rate, currency);
  }
  return '-';
};

const CRCard = ({
  onEdit,
  cr = {},
  projectId,
  readyChangeRequests,
  approveCR,
  deleteCR,
  projectCurrency = {},
  primaryPTOS,
  addToPaymentLink,
  rejectCR,
  isTeamMember,
  isAdminOrDL,
  isClient,
  isTeamAdmin,
  isAllowedToApproveCRs,
  deazyAsClient,
  deazyAsSupplier,
}) => {
  return (
    <Box w="100%" border="1px solid" bg="#fff" borderColor="dark.mid" mb="32px">
      <Flex
        w="100%"
        borderBottom="1px solid"
        borderColor="dark.mid"
        alignItems="stretch"
      >
        <Stat
          bg="dark.solid"
          color="#fff"
          px="8px"
          py="16px"
          borderBottomRightRadius="8px"
          label="Status"
          labelColor="#fff"
          value={cr.status}
          data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--crStatus`}
        />
        <Stat
          px="8px"
          py="16px"
          borderRight="none"
          label="No."
          data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--number`}
          value={`#${cr.number}`}
        />
        <Stat
          px="8px"
          py="16px"
          borderRight="none"
          data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--name`}
          label="Name"
          value={cr.name}
        />
        <Stat
          px="8px"
          py="16px"
          borderRight="none"
          label="Hours"
          data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--hours`}
          value={formatCurrency(cr.amount)}
        />
        {!isClient && !isTeamMember && (
          <Stat
            px="8px"
            py="16px"
            data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--rate`}
            borderRight="none"
            label="Delivery Partner rate"
            value={prepareRate(
              cr.supplierDisabled || deazyAsSupplier,
              cr.rate,
              primaryPTOS?.overriddenCurrency ||
                primaryPTOS?.supplier?.currency,
            )}
          />
        )}
        {!isTeamAdmin && !isTeamMember && (
          <Stat
            px="8px"
            py="16px"
            borderRight="none"
            label="Client rate"
            data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--clientRate`}
            value={prepareRate(
              cr.clientDisabled || deazyAsClient,
              cr.clientRate,
              projectCurrency.currency,
            )}
          />
        )}
      </Flex>
      <Flex>
        <Flex px="24px" my="20px" flexDirection="column">
          {!isNil(cr.totalClientCost) && (
            <HorizontalStat
              data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--totalClientCost`}
              label="Total Client cost"
              value={formatCurrency(
                cr.totalClientCost,
                projectCurrency.currency,
              )}
            />
          )}
          {!isNil(cr.totalSupplierCost) && (
            <HorizontalStat
              label="Total Delivery Partner cost"
              data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--totalDPCost`}
              value={formatCurrency(
                cr.totalSupplierCost,
                primaryPTOS.overriddenCurrency ||
                  primaryPTOS?.supplier?.currency,
              )}
            />
          )}
          <HorizontalStat
            label="Description"
            value={cr.description}
            data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--description`}
          />
          {cr?.purchaseOrder && (
            <HorizontalStat
              label="Purchase order"
              value={cr?.purchaseOrder}
              data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--purchaseOrder`}
            />
          )}
          {get(cr, 'processedBy.fullname') && (
            <HorizontalStat
              data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--processedBy`}
              label="Processed by"
              value={get(cr, 'processedBy.fullname')}
            />
          )}
          {cr.dateProcessed && (
            <HorizontalStat
              data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--processedAt`}
              label="Processed at"
              value={moment(cr.dateProcessed).format('LLLL')}
            />
          )}
        </Flex>
        <Flex ml="auto" alignItems="flex-end" px="24px" my="20px">
          {cr.status === CHANGE_REQUEST_STATUS.New &&
            onEdit &&
            (isTeamAdmin || isTeamMember || isAdminOrDL) && (
              <Button
                {...buttonProps}
                bg="#fff"
                color="dark.solid"
                borderColor="aqua.solid"
                borderWidth="2px"
                leftIcon={<Icon name="edit" mr="12px" fontSize={22} />}
                isDisabled={cr.isSettingReady}
                onClick={onEdit}
                data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--crEditButton`}
              >
                Edit
              </Button>
            )}
          {isAdminOrDL &&
            cr.status === CHANGE_REQUEST_STATUS.New &&
            readyChangeRequests && (
              <Button
                {...buttonProps}
                leftIcon={<Icon name="check" mr="12px" fontSize={22} />}
                ml="12px"
                onClick={() => readyChangeRequests(projectId, cr)}
                data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--crReadyButton`}
                isLoading={cr.isSettingReady}
              >
                Ready
              </Button>
            )}
          {isAdminOrDL &&
            cr.status === CHANGE_REQUEST_STATUS.Rejected &&
            deleteCR && (
              <Button
                {...buttonProps}
                bg="#fff"
                color="red.solid"
                borderColor="red.solid"
                borderWidth="2px"
                onClick={() => deleteCR(projectId, cr.id)}
                data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--crDeleteButton`}
                isLoading={cr.isDeleting}
              >
                Delete
              </Button>
            )}

          {isAdminOrDL &&
            cr.status === CHANGE_REQUEST_STATUS.Approved &&
            addToPaymentLink && (
              <Button
                leftIcon={<Icon name="add" mr="12px" />}
                as={Link}
                to={addToPaymentLink}
                {...buttonProps}
                data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--addToInvoiceButton`}
              >
                Add to invoice
              </Button>
            )}
          {cr.status === CHANGE_REQUEST_STATUS.Ready &&
            approveCR &&
            isAllowedToApproveCRs && (
              <>
                <Button
                  {...buttonProps}
                  mr="12px"
                  onClick={() => approveCR(projectId, cr.id)}
                  isLoading={cr.isApproving}
                  data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--crAcceptButton`}
                >
                  Accept
                </Button>
                <Button
                  {...buttonProps}
                  bg="#fff"
                  color="red.solid"
                  borderColor="red.solid"
                  borderWidth="2px"
                  onClick={() => rejectCR(projectId, cr.id)}
                  isLoading={cr.isRejecting}
                  data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--crRejectButton`}
                >
                  Reject
                </Button>
              </>
            )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default CRCard;
