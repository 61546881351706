import { Flex, Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { isEmpty, map, uniqBy } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';

const ProposalIncompleteModal = ({
  proposalsStore: { incompleteFeatures },
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setIsOpen(!isEmpty(incompleteFeatures));
  }, [incompleteFeatures]);

  const uniqIncompleteFeatures = uniqBy(incompleteFeatures, 'name');

  return (
    <Modal isOpen={isOpen} styleProps={{ maxW: '864px' }}>
      <Flex
        bg="dark.light"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        py="32px"
        borderBottom="1px solid"
        borderColor="dark.mid"
      >
        <Typography variant="h2" mb="24px">
          Your proposal is not complete
        </Typography>
        <Stack isInline spacing="5px">
          <Typography>Each feature</Typography>
          <Typography fontFamily="WB">must</Typography>
          <Typography>be given a summary</Typography>
          <Typography fontFamily="WB">and</Typography>
          <Typography>contain at least one complete task</Typography>
        </Stack>
      </Flex>
      <Flex flexDir="column" alignItems="center" justifyContent="center">
        <Typography variant="h4" mb="24px" mt="32px">
          Features not completed
        </Typography>
        {map(uniqIncompleteFeatures, feature => (
          <Typography
            key={`incomplete-feaure-${feature.id}`}
            fontFamily="WM"
            color="red.solid"
            mb="2px"
          >
            {feature?.name}
          </Typography>
        ))}
        <Button
          minWidth="304px"
          mt="40px"
          variant="outline"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
};

export default inject('proposalsStore')(observer(ProposalIncompleteModal));
