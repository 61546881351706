import Badge from '@styles/Badge';
import { ENGAGEMENT_TYPES } from '@app/constants';
import React from 'react';
import Icon from './Icon';

const ProjectTypeBadge = ({ type }) => {
  const renderBadge = (label, iconName, bg) => (
    <Badge variant="tertiary" bg={bg} color="#2B231D" fontWeight="500">
      <Icon name={iconName} mr="5px" mt="1px" />
      {label}
    </Badge>
  );

  if (type === ENGAGEMENT_TYPES.PROJECT_BUILD) {
    return renderBadge(
      'PROJECT BUILD',
      'projectBuildTypeIcon',
      'rgba(0, 136, 134, 0.05)',
    );
  }
  if (type === ENGAGEMENT_TYPES.SUPPORT) {
    return renderBadge('SUPPORT', 'supportTypeIcon', 'rgba(255, 68, 0, 0.03)');
  }
  if (type === ENGAGEMENT_TYPES.RETAINED_TEAM) {
    return renderBadge(
      'RETAINED TEAM',
      'retainedTeamTypeIcon',
      'rgba(30, 144, 255, 0.04)',
    );
  }
  return renderBadge(
    'TEAM AUGMENTATION',
    'teamAugmentationTypeIcon',
    'rgba(148, 0, 211, 0.04)',
  );
};

export default ProjectTypeBadge;
