import { Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import useDownload from '@hooks/useDownload';
import Button from '@styles/Button';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { filter, isEmpty, map, slice, take } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

const ColumnTitle = props => <Typography variant="caption" {...props} />;

const DownloadButton = ({ link, fileName }) => {
  const { onDownload, isLoading } = useDownload({
    url: link,
    fileName,
  });

  return (
    <Button
      borderRadius="100%"
      fontSize={20}
      color="blue.solid"
      variant="ghost"
      iconButton={{ name: 'download' }}
      onClick={onDownload}
      isLoading={isLoading}
    />
  );
};

const SowHistoryList = ({
  isClientTab,
  supplierId,
  sowsStore: { clearSowHistory, fetchSowHistory, sowHistory, isLoadingHistory },
  projectsStore: {
    project: { finalisedDateClientSOW, projectToSuppliers },
  },
  usersStore: { isAdminOrDL, isClient },
}) => {
  const hasSupplierFinalisationDate = filter(
    projectToSuppliers,
    x => x?.supplier?.id === supplierId && x?.finalisedDateSupplierSOW,
  ).length;

  React.useEffect(() => {
    const isClientHistory = isClient || (isAdminOrDL && isClientTab);
    fetchSowHistory(isClientHistory);
    return () => clearSowHistory();
  }, [isClient, isAdminOrDL, isClientTab, supplierId]);
  const [showAll, setShowAll] = React.useState(false);

  const onlyHistory = React.useMemo(() => {
    if (
      (isClientTab && finalisedDateClientSOW) ||
      (!isClientTab && hasSupplierFinalisationDate)
    ) {
      return sowHistory;
    }
    return slice(sowHistory, 1);
  }, [
    sowHistory,
    hasSupplierFinalisationDate,
    finalisedDateClientSOW,
    isClientTab,
  ]);

  const filesToShow = React.useMemo(() => {
    if (showAll) {
      return onlyHistory;
    }
    return take(onlyHistory, 1);
  }, [showAll, onlyHistory]);

  if (isLoadingHistory) {
    return <Spinner variant="medium" />;
  }

  if (isEmpty(filesToShow)) {
    return null;
  }

  return (
    <>
      <Flex w="100%" mt="32px" mb="8px">
        <ColumnTitle pl="27px" w="65%">
          Document name
        </ColumnTitle>
        <ColumnTitle w="35%">Created date</ColumnTitle>
      </Flex>
      {map(filesToShow, (file, idx) => (
        <Flex
          w="100%"
          key={`file-row-key-${file.id}`}
          py="4px"
          borderBottom="1px solid"
          borderColor="dark.mid"
        >
          <Flex w="65%" alignItems="center">
            <Icon mr="12px" name="document" />
            <Typography
              variant="text2"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {file.name}
            </Typography>
            {idx === 0 && (
              <Typography
                ml="16px"
                color="#fff"
                variant="caption"
                bg="dark.mid"
                p="10px"
                borderRadius="32px"
                px="8px"
                py="3px"
              >
                LATEST VERSION
              </Typography>
            )}
          </Flex>
          <Flex w="35%" alignItems="center">
            <Typography variant="text2" mr="auto">
              {file.exportDate
                ? moment.utc(file.exportDate).format('DD MMM YY')
                : '-'}
            </Typography>
            <DownloadButton link={file.link} fileName={`${file.name}.pdf`} />
          </Flex>
        </Flex>
      ))}
      {setShowAll && (onlyHistory || []).length > 1 && isAdminOrDL && (
        <Button
          mt="16px"
          mb="20px"
          display="flex"
          onClick={() => setShowAll(!showAll)}
          variant="ghost"
          mx="auto"
          color="blue.solid"
          _hover={{ bg: 'transparent', textDecor: 'underline' }}
          _active={{ bg: 'transparent' }}
        >
          {showAll ? 'Hide Version History' : 'Show Version History'}
        </Button>
      )}
    </>
  );
};

export default inject(
  'sowsStore',
  'projectsStore',
  'usersStore',
)(observer(SowHistoryList));
