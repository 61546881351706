import React from 'react';
import Modal from '@components/Modal';
import { Box, ModalCloseButton } from '@chakra-ui/react';
import Typography from '@styles/Typography';

const NotMatchedModal = ({ isOpen, options, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick
      styleProps={{
        p: '24px 24px 32px',
        borderRadius: '8px',
        border: '1px solid gray.200',
        boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.15)',
      }}
    >
      <Box>
        <ModalCloseButton />
        <Typography mb="24px" variant="h4">
          Criteria not matched
        </Typography>
        {options.map((opt, idx) => (
          <Box mb="24px" key={`not-matched-opt-${idx}`}>
            <Typography variant="title" color="gray.400" mb="8px">
              {opt.title}
            </Typography>
            <Typography dangerouslySetInnerHTML={{ __html: opt.desc }} />
          </Box>
        ))}
      </Box>
    </Modal>
  );
};

export default NotMatchedModal;
