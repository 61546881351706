import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import { isEmpty, map } from 'lodash-es';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import DeazyRolesTable from './DeazyRolesTable';

const DeazyTeamRolesCard = ({
  proposalsStore: { saveProposalDeazyTeam, proposalData },
  isReadOnly,
  mainNameQA,
}) => {
  const deazyTeam = React.useMemo(() => {
    if (isEmpty(proposalData.deazyTeam)) {
      return [{ rowId: nanoid(10) }];
    }
    return map(toJS(proposalData.deazyTeam), team => ({
      rowId: nanoid(10),
      ...team,
    }));
  }, [proposalData.deazyTeam]);
  return (
    <WhiteCard border="1px solid" borderColor="dark.mid">
      <Typography variant="h2" mb="24px">
        Deazy team roles
      </Typography>
      <DeazyRolesTable
        isReadOnly={isReadOnly}
        deazyTeam={deazyTeam}
        onSubmit={saveProposalDeazyTeam}
        mainNameQA={mainNameQA}
      />
    </WhiteCard>
  );
};

export default inject('proposalsStore')(observer(DeazyTeamRolesCard));
