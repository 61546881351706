import { Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';

const EmptyResults = props => (
  <Flex flexDir="column" px="32px" mt="12px" {...props}>
    <Typography variant="h1" mb="12px">
      Nothing found
    </Typography>
    <Typography variant="h3">Please try amending your criteria</Typography>
  </Flex>
);

export default EmptyResults;
