import {
  Box,
  Modal as ChakraModal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

const Modal = ({
  isOpen,
  onClose = () => {},
  children,
  closeOnOverlayClick = false,
  styleProps,
  ...props
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <>
      <ChakraModal
        motionPreset="slideInBottom"
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        trapFocus={false}
        closeOnOverlayClick={closeOnOverlayClick}
        {...props}
      >
        <ModalOverlay bg="rgba(0,0,0,0.4)" />
        <ModalContent
          m="15px"
          bg="#fff"
          boxShadow="0 0 24px 0 rgb(0 0 0 / 15%)"
          borderRadius={0}
          maxH="95vh"
          overflowY="auto"
          px="56px"
          py="40px"
          maxW="600px"
          w="100%"
          {...styleProps}
        >
          <Box>
            {typeof children === 'function' ? children({ onClose }) : children}
          </Box>
        </ModalContent>
      </ChakraModal>
    </>
  );
};

export default Modal;
