import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const DeleteFeatureOrTaskModal = ({
  isOpen,
  onClose,
  isLoading,
  onDelete,
  isForTask = false,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={!isLoading}
      closeOnEsc={!isLoading}
    >
      <Typography
        variant="h2"
        mb="40px"
        maxW="400px"
        textAlign="center"
        mx="auto"
      >
        Are you sure you’d like to delete this {isForTask ? 'task' : 'feature'}?
      </Typography>
      <Stack spacing={3} mt="40px">
        <Button variant="warning" isLoading={isLoading} onClick={onDelete}>
          Yes, delete {isForTask ? 'task' : 'feature'}
        </Button>
        <Button variant="outline" onClick={onClose} isDisabled={isLoading}>
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};

export default DeleteFeatureOrTaskModal;
