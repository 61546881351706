import { Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';

const ProposalStatus = ({ clientProposalData, isAdminOrDL, ...props }) => (
  <>
    {isAdminOrDL && clientProposalData.clientProposalStatus && (
      <Flex
        bg="#fff"
        p="16px"
        alignItems="center"
        ml="auto"
        maxW="240px"
        borderRadius="8px"
        {...props}
      >
        <Typography mr="12px">Proposal status</Typography>
        <Typography
          variant="caption"
          color="#fff"
          bg={
            clientProposalData.clientProposalStatus === 'Declined'
              ? 'red.solid'
              : 'green.solid'
          }
          p="10px"
          borderRadius="32px"
          px="8px"
          py="3px"
        >
          {clientProposalData.clientProposalStatus}
        </Typography>
      </Flex>
    )}
  </>
);

export default ProposalStatus;
