import { Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React, { Component } from 'react';

class Inbox extends Component {
  render() {
    return (
      <Flex px="32px" pt="39px" h="100%" direction="column" align="flex-start">
        <Typography>Inbox page</Typography>
      </Flex>
    );
  }
}

export default Inbox;
