import { Box, Flex } from '@chakra-ui/react';
import ActionButton from '@components/AllocationTable/ActionButton';
import TextInput from '@components/forms/TextInput';
import { CURRENCIES_SYMBOLS, ENGAGEMENT_TYPES } from '@app/constants';
import { INPUT_VARIANTS } from '@styles/theme';
import { isNil, upperCase } from 'lodash-es';
import React from 'react';
import moment from 'moment';
import { Field } from 'react-final-form';
import DatePicker from '@components/forms/DatePicker';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import { formatCurrency } from '@utils';
import { FixedCell } from './components';

const PaymentRow = ({
  formApi,
  onDelete,
  onAdd,
  isReadOnly,
  currency,
  idx,
  canBeDeleted,
  engagementType,
  payment,
  maxValue,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const isProjectEngagementType =
    engagementType === ENGAGEMENT_TYPES.PROJECT_BUILD;

  const isPnPEngagementType =
    engagementType === ENGAGEMENT_TYPES.TEAM_AUGMENTATION;

  const isRetainer = engagementType === ENGAGEMENT_TYPES.RETAINED_TEAM;

  return (
    <Box w="100%" position="relative">
      {isProjectEngagementType && (
        <ActionButton
          alternateBg
          deleteRow={onDelete}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          canBeDeleted={canBeDeleted}
          addRow={onAdd}
          disallowRowChange={isReadOnly}
        />
      )}
      <Flex
        borderRadius="8px"
        w="100%"
        h="50px"
        mb="12px"
        bg="#fff"
        opacity={isOpen ? 0.5 : 1}
      >
        <Flex
          w="100%"
          pl={isProjectEngagementType && '40px'}
          border="1px solid"
          borderColor="dark.mid"
          borderRight="none"
          borderRadius="8px"
        >
          <Box
            alignSelf="center"
            display="flex"
            flex={1}
            w="100%"
            h="48px"
            borderRight="1px solid"
            borderColor="dark.mid"
          >
            <Field
              name={`paymentScheduleItems[${idx}].name`}
              variant={INPUT_VARIANTS.CONTAINER_FILLED}
              isDisabled={isReadOnly}
              component={TextInput}
              placeholder="Name of new invoice"
            />
          </Box>

          {isPnPEngagementType || isRetainer ? (
            <FixedCell>
              {moment.utc(payment.invoiceDate).format('DD/MM/YYYY')}
            </FixedCell>
          ) : (
            <Box
              alignSelf="center"
              w="140px"
              borderRight="1px solid"
              borderColor="dark.mid"
              h="48px"
            >
              <Field
                name={`paymentScheduleItems[${idx}].invoiceDate`}
                isDisabled={isReadOnly}
                {...(!isProjectEngagementType && {
                  bg: 'dark.light',
                })}
                variant={INPUT_VARIANTS.CONTAINER_FILLED}
                component={DatePicker}
                minW="auto"
                containerProps={{ h: '100%' }}
                h="100%"
                placeholder="Add a date"
                dateFormat="DD/MM/YYYY"
                hideCalendarIcon
              />
            </Box>
          )}
          {isPnPEngagementType || isRetainer ? (
            <Box
              w="140px"
              h="48px"
              pr="8px"
              bg="#fff"
              borderRight="1px solid"
              borderColor="dark.mid"
              borderTopRightRadius="8px"
              borderBottomRightRadius="8px"
            >
              <FixedCell w="132px" mr="8px" borderRight="none">
                {formatCurrency(payment?.value, currency)}
              </FixedCell>
            </Box>
          ) : (
            <Box
              alignSelf="center"
              w="140px"
              borderRight="1px solid"
              borderColor="dark.mid"
              {...(!isProjectEngagementType && {
                borderTopRightRadius: '8px',
                borderBottomRightRadius: '8px',
              })}
              h="48px"
              pr="8px"
            >
              <Field
                {...(!isProjectEngagementType && {
                  bg: 'dark.light',
                })}
                name={`paymentScheduleItems[${idx}].value`}
                variant={INPUT_VARIANTS.CONTAINER_FILLED}
                component={MaskedTextInput}
                decimalScale={2}
                fixedDecimalScale
                placeholder={`${CURRENCIES_SYMBOLS[upperCase(currency)]}0,000`}
                prefix={CURRENCIES_SYMBOLS[upperCase(currency)]}
                isDisabled={isReadOnly}
                changeIfActive
                onAfterChange={value => {
                  formApi.change(
                    `paymentScheduleItems[${idx}].percentage`,
                    (value / maxValue) * 100,
                  );
                }}
              />
            </Box>
          )}

          {isProjectEngagementType && (
            <Box
              alignSelf="center"
              w="140px"
              borderRight="1px solid"
              borderColor="dark.mid"
              borderTopRightRadius="8px"
              borderBottomRightRadius="8px"
              h="48px"
            >
              <Field
                name={`paymentScheduleItems[${idx}].percentage`}
                variant={INPUT_VARIANTS.CONTAINER_FILLED}
                component={MaskedTextInput}
                suffix="%"
                borderTopRightRadius="8px"
                borderBottomRightRadius="8px"
                changeIfActive
                placeholder="0%"
                isDisabled={isReadOnly}
                onAfterChange={perc => {
                  formApi.change(
                    `paymentScheduleItems[${idx}].value`,
                    (perc / 100) * maxValue,
                  );
                }}
                isAllowed={({ floatValue }) => {
                  return (
                    (floatValue >= 0 && floatValue <= 100) || isNil(floatValue)
                  );
                }}
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default PaymentRow;
