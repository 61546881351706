import { Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';

export const FixedCell = ({ children, ...props }) => (
  <Flex
    alignSelf="center"
    w="140px"
    borderRight="1px solid"
    borderColor="dark.mid"
    h="48px"
    bg="dark.light"
    alignItems="center"
    px="16px"
    {...props}
  >
    <Typography>{children}</Typography>
  </Flex>
);

export const TableHeadCell = ({ children, ...props }) => (
  <Typography variant="caption" pl="8px" {...props}>
    {children}
  </Typography>
);

export const TableHead = ({ isProjectType }) => (
  <Flex w="100%" mb="12px" mt="32px">
    <TableHeadCell flex={1} pl={isProjectType ? '48px' : '8px'}>
      {isProjectType ? 'Name of Invoice Split' : 'Name of monthly invoice'}
    </TableHeadCell>
    <TableHeadCell w="140px">Invoice date</TableHeadCell>
    <TableHeadCell w="140px">Amount</TableHeadCell>
    {isProjectType && <TableHeadCell w="140px">Percentage</TableHeadCell>}
  </Flex>
);
