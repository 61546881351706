import { API } from '@app/api';
import { Flex } from '@chakra-ui/react';
import EmptyResults from '@components/EmptyResults';
import PageChips from '@components/PageChips';
import useMediaQuery from '@hooks/useMediaQuery';
import PageResults from '@components/PageResults';
import PageSize from '@components/PageSize';
import { MAIN_NAMES_QA } from '@app/constants';
import Pagination from '@components/Pagination';
import useListing from '@hooks/useListing';
import { decorateBrief, decorateBriefForSendbridIds } from '@utils/briefUtils';
import { isEmpty, isString, join, map, toNumber } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import qs from 'query-string';
import React from 'react';
import BriefsFilters from './BriefsFilters';
import BriefsList from './BriefsList';
import BriefsEmpty from './BriefsEmpty';

const mainNameQA = MAIN_NAMES_QA.BRIEFS_LIST;

const Briefs = ({
  usersStore: { isTeamAdmin, isClient, isAdminOrDL },
  appStore: {
    toggleSubSidebar,
    featureFlags: { 'show-brief-chat': showBriefChat },
  },
  history,
  location: { search },
  routerStore: { prevBriefsListUrl },
}) => {
  const params = qs.parse(search);
  const {
    page = 1,
    orderDirection = 'DESC',
    orderBy = 'createdAt',
    onlyPlugPlay,
    onlyProject,
    supplierIds,
    deazyLeadIds,
    deazyLeadNames,
    techStack,
    supplierNames,
    status = 'Open',
    query,
  } = params;

  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const briefsTypeDV = () => {
    if (onlyPlugPlay) {
      return 'onlyPlugPlay=true';
    }
    if (onlyProject) {
      return 'onlyProject=true';
    }
    return 'all-briefs=true';
  };

  const briefStatusDV = () => {
    if (status === 'Open') {
      return 'Open';
    }
    if (status === 'Closed') {
      return 'Closed';
    }
    if (status === 'On Hold') {
      return 'On Hold';
    }
    return 'all-statuses';
  };

  const onSubmit = values => {
    const valueMapping = ({ value }) => value;
    return history.push(
      `${history.location.pathname}?${qs.stringify({
        status: values.status,
        query: values.query,
        supplierIds: map(values.suppliers, valueMapping),
        supplierNames: map(values.suppliers, ({ label }) => label),
        techStack: map(values.techStack, valueMapping),
        deazyLeadIds: map(values.deazyLeadIds, valueMapping),
        deazyLeadNames: map(values.deazyLeadIds, ({ label }) => label),
      })}&${values.sort}&${values.type}`,
    );
  };

  const customMap = items =>
    map(items, item => ({
      ...item,
      ...decorateBrief(item),
      ...decorateBriefForSendbridIds(item),
    }));

  const {
    items: briefs,
    totalPages,
    isLoading,
    totals,
    onFilterChange,
    fetch: refetch,
  } = useListing(
    ({ params: { ...paramsToListing } }) => {
      return API.getAllBriefs({
        params: {
          ...paramsToListing,
          onlyPlugPlay,
          onlyProject,
          status,
          supplierIds: isString(supplierIds)
            ? supplierIds
            : join(supplierIds, ','),
          techStack: isString(techStack) ? techStack : join(techStack, ','),
          deazyLeadIds: isString(deazyLeadIds)
            ? deazyLeadIds
            : join(deazyLeadIds, ','),
        },
      });
    },
    {},
    customMap,
  );

  const initialValues = () => ({
    status: briefStatusDV(),
    type: briefsTypeDV(),
    query,
    sort: `orderDirection=${orderDirection}&orderBy=${orderBy}`,
    suppliers: map(
      isString(supplierIds) ? [supplierIds] : supplierIds,
      (id, idx) => ({
        value: toNumber(id),
        label: isString(supplierIds) ? supplierNames : supplierNames[idx],
      }),
    ),
    techStack: map(isString(techStack) ? [techStack] : techStack, ts => ({
      value: ts,
      label: ts,
    })),
    deazyLeadIds: map(
      isString(deazyLeadIds) ? [deazyLeadIds] : deazyLeadIds,
      (id, idx) => ({
        value: toNumber(id),
        label: isString(deazyLeadIds) ? deazyLeadNames : deazyLeadNames[idx],
      }),
    ),
  });

  const isListEmpty = isEmpty(briefs) && !isLoading;
  const isFiltered = !isEmpty(params);

  React.useEffect(() => {
    if (!isEmpty(briefs)) {
      if (prevBriefsListUrl.pageYOffset > 0) {
        window.scrollTo(0, prevBriefsListUrl.pageYOffset);
      }
    }
  }, [prevBriefsListUrl]);

  return (
    <>
      <Flex>
        <BriefsFilters
          onSubmit={onSubmit}
          isAdminOrDL={isAdminOrDL}
          isLoading={isLoading}
          initialValues={initialValues()}
          mainNameQA={mainNameQA}
        />
        <Flex
          direction="column"
          px={isSmallerScreen ? '12px' : '24px'}
          pt="16px"
          flex={1}
        >
          {(!isListEmpty || isFiltered) && (
            <Flex
              display="-webkit-inline-box"
              w="100%"
              justify="space-between"
              mb="12px"
            >
              <PageResults
                totalPages={totalPages}
                currentPage={page}
                totals={totals}
                mainNameQA={mainNameQA}
              />
            </Flex>
          )}
          <PageChips />
          <BriefsList
            briefs={briefs}
            isLoading={isLoading}
            isAdminOrDL={isAdminOrDL}
            isTeamAdmin={isTeamAdmin}
            toggleSubSidebar={toggleSubSidebar}
            mainNameQA={mainNameQA}
            showBriefChat={showBriefChat}
            refetch={() => {
              refetch({
                page: (briefs || []).length === 1 && page > 1 ? page - 1 : page,
              });
            }}
          />

          {isFiltered && isListEmpty && <EmptyResults />}
          {isListEmpty && !isFiltered && (
            <BriefsEmpty
              isTeamAdmin={isTeamAdmin}
              isAdminOrDL={isAdminOrDL}
              isClient={isClient}
            />
          )}

          <Flex
            flexDirection={isSmallerScreen && 'column'}
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            pt="calc(24px - 16px)"
            pb="72px"
          >
            {totals > 0 && (
              <PageSize
                containerProps={isSmallerScreen && { mb: '12px', minW: 'auto' }}
              />
            )}
            <Pagination
              forcePage={+page - 1}
              pageCount={totalPages}
              onPageChange={p => onFilterChange({ page: p })}
              containerProps={isSmallerScreen && { mb: '85px' }}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default inject(
  'usersStore',
  'routerStore',
  'appStore',
)(observer(Briefs));
