import Typography from '@styles/Typography';
import React from 'react';

const SectionTitle = ({ children, ...props }) => (
  <Typography
    variant="h4"
    borderBottom="1px solid"
    borderColor="aqua.solid"
    pb="10px"
    mb="16px"
    mt="56px"
    {...props}
  >
    {children}
  </Typography>
);

export default SectionTitle;
