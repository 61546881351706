import Icon from '@components/Icon';
import React from 'react';
import { StickyFooter } from '@components/StickyFooterCostBox';
import { NEW_PROPOSAL_STATUSES } from '@app/constants';
import { CheckButton } from '@components/BriefActions';
import Typography from '@styles/Typography';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import Tooltip from '@styles/Tooltip';
import { camelCase } from 'lodash-es';

const Stat = ({ label, value, mainNameQA, isLoading, ...props }) => (
  <Flex mr="8px" {...props}>
    <Flex flexDir="column">
      <Typography variant="text3" mb="2px" color="#878380">
        {label}
      </Typography>

      {isLoading ? (
        <Spinner />
      ) : (
        <Typography data-test-id={`${mainNameQA}--stat-${camelCase(label)}`}>
          {value}
        </Typography>
      )}
    </Flex>
  </Flex>
);

const ProposalFooter = ({
  mainNameQA,
  showButton,
  onSubmit,
  isSubmitting,
  isDisabled,
  hours,
  cost,
  contingency,
  totalCost,
  infoTooltipLabel,
  currency,
  isLoadingStats,
  isResubmit,
  isSmallerScreen,
  status,
  isTeamAdmin,
}) => {
  const mobileBottomMarginForItems =
    isSmallerScreen && status === NEW_PROPOSAL_STATUSES.WITH_DP && isTeamAdmin;

  return (
    <StickyFooter
      display="flex"
      h="72px"
      py="0px"
      pr="40px"
      pl="240px"
      alignItems="center"
      {...(isSmallerScreen && {
        bottom: '88px',
        minH: mobileBottomMarginForItems && '117px',
        h: 'auto',
        left: '0',
        width: '100%',
        pl: '12px',
        pr: '12px',
        pb: '16px',
        pt: '12px',
      })}
    >
      <Flex
        alignItems="center"
        mx="auto"
        width="100%"
        maxWidth="1336px"
        flexWrap={isSmallerScreen && 'wrap'}
        justifyContent={isSmallerScreen && 'space-between'}
      >
        {!isSmallerScreen && (
          <Typography variant="h4" mr="auto">
            Proposal grand total
          </Typography>
        )}
        <Flex mb={mobileBottomMarginForItems && '12px'}>
          <Icon
            name="clock"
            mt="8px"
            color="blue.solid"
            fontSize={20}
            mr="5px"
          />
          <Stat
            isLoading={isLoadingStats}
            label="Total hours"
            value={`${hours} Hours`}
            mainNameQA={mainNameQA}
            mr="22px"
          />
        </Flex>
        <Flex mb={mobileBottomMarginForItems && '12px'}>
          <Icon
            name={currency?.toLowerCase() || 'gbp'}
            fontSize={20}
            mr="13px"
            mt="10px"
            color="blue.solid"
          />
          <Stat
            mainNameQA={mainNameQA}
            isLoading={isLoadingStats}
            label="Sub total"
            value={cost}
          />
        </Flex>
        <Flex mb={mobileBottomMarginForItems && '12px'}>
          <Icon
            name="cross"
            transform="rotate(45deg)"
            fontSize={15}
            mt="13px"
            color="blue.solid"
            mr="12px"
          />
          <Stat
            mainNameQA={mainNameQA}
            isLoading={isLoadingStats}
            label="Contingency"
            value={contingency}
          />
        </Flex>
        <Flex mb={mobileBottomMarginForItems && '12px'}>
          <Flex flexDir="column" mr="13px" mt="13px">
            <Box w="10px" h="1px" bg="blue.solid" mb="3px" />
            <Box w="10px" h="1px" bg="blue.solid" />
          </Flex>
          <Stat
            mainNameQA={mainNameQA}
            isLoading={isLoadingStats}
            label="Grand total"
            value={totalCost}
          />
          {infoTooltipLabel && (
            <Tooltip
              zIndex={33000}
              p="12px"
              borderRadius="8px"
              lineHeight="normal"
              hasArrow
              bg="dark.solid"
              py="12px"
              placement="top"
              fontSize={16}
              label={infoTooltipLabel}
            >
              <Box as="span" mt="12px">
                <Icon name="infoOutline" />
              </Box>
            </Tooltip>
          )}
        </Flex>

        {showButton && (
          <CheckButton
            onClick={onSubmit}
            isLoading={isSubmitting}
            isDisabled={isDisabled}
            data-test-id={`${mainNameQA}--submit`}
            ml="24px"
            mr="0px"
            {...(isSmallerScreen && {
              w: '100%',
              ml: 0,
            })}
          >
            {isResubmit ? 'Re-submit proposal' : 'Submit proposal'}
          </CheckButton>
        )}
      </Flex>
    </StickyFooter>
  );
};

export default ProposalFooter;
