import { includes } from 'lodash-es';
import React from 'react';
import { Field } from 'react-final-form';

const Condition = ({ when, is, isOneOf, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) =>
      value === is || includes(isOneOf, value) ? children : null
    }
  </Field>
);

export default Condition;
