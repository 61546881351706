import Button from '@styles/Button';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { useDisclosure } from '@chakra-ui/react';
import Icon from '@components/Icon';
import { ASSIGNMENT_STATUSES } from '@app/constants';
import { Link } from 'react-router-dom';
import Tooltip from '@styles/Tooltip';
import BriefDeclineReasonModal from './BriefDeclineReasonModal';

const AssignmentActionButtons = ({
  briefsStore: {
    brief: { isPlugAndPlay },
  },
  assignment,
  onRemind,
  onAssign,
  isExclusivitySelected,
  isAnyAssignmentExclusive,
  isMobile,
  mainNameQA = '',
}) => {
  const commonProps = {
    color: isMobile ? '#fff' : 'dark.solid',
    p: '0px',
    size: 'lg',
    ...(isMobile && {
      w: '100%',
    }),
  };
  const [isReminding, setIsReminding] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const proposalLabel = React.useMemo(() => {
    if (isPlugAndPlay) {
      return isMobile ? 'View candidates' : 'Candidates';
    }
    return isMobile ? 'View proposal' : 'Proposal';
  }, [isMobile, isPlugAndPlay]);

  if (assignment.status === ASSIGNMENT_STATUSES.NOT_INTERESTED) {
    const iconProps = {
      name: 'commentBubble',
      color:
        !assignment.exclusivityEnabled &&
        isExclusivitySelected &&
        !isAnyAssignmentExclusive
          ? 'dark.mid'
          : 'blue.solid',
      fontSize: 20,
    };
    const buttonProps = {
      as: 'span',
      _hover: {},
      onClick: isMobile ? onOpen : () => {},
    };
    const button = (
      <Button
        {...commonProps}
        {...buttonProps}
        {...(isMobile
          ? {
              leftIcon: {
                ...iconProps,
                color: '#fff',
                fontSize: 22,
                mr: 0,
              },
            }
          : {
              rightIcon: <Icon {...iconProps} />,
              variant: 'ghost',
            })}
      >
        {isMobile ? 'View reason' : 'Reason'}
      </Button>
    );

    if (isMobile) {
      return (
        <>
          <BriefDeclineReasonModal
            reason={assignment.changeStatusReason}
            onClose={onClose}
            isOpen={isOpen}
          />
          {button}
        </>
      );
    }
    return assignment.changeStatusReason ? (
      <Tooltip label={assignment.changeStatusReason}>
        <Button
          {...commonProps}
          {...buttonProps}
          {...(isMobile
            ? {
                leftIcon: {
                  ...iconProps,
                  color: '#fff',
                  fontSize: 22,
                  mr: 0,
                },
              }
            : {
                rightIcon: <Icon {...iconProps} />,
                variant: 'ghost',
              })}
        >
          {isMobile ? 'View reason' : 'Reason'}
        </Button>
      </Tooltip>
    ) : null;
  }

  if (
    assignment.status === ASSIGNMENT_STATUSES.PROPOSAL_SHARED ||
    assignment.status === ASSIGNMENT_STATUSES.CANDIDATES_SHARED
  ) {
    const iconProps = {
      name: 'binoculars',
      color:
        !assignment.exclusivityEnabled &&
        isExclusivitySelected &&
        !isAnyAssignmentExclusive
          ? 'dark.mid'
          : 'blue.solid',
      fontSize: 20,
    };
    const buttonProps = {
      as: Link,
      to: assignment.proposalLink,
    };

    return (
      <Button
        {...commonProps}
        {...buttonProps}
        {...(isMobile
          ? {
              leftIcon: {
                ...iconProps,
                color: '#fff',
                fontSize: 22,
                mr: 0,
              },
            }
          : {
              rightIcon: <Icon {...iconProps} />,
              variant: 'ghost',
            })}
      >
        {proposalLabel}
      </Button>
    );
  }

  if (assignment.status === ASSIGNMENT_STATUSES.ASSIGNMENT_PENDING) {
    const shouldBeDisabled =
      !assignment.exclusivityEnabled &&
      isAnyAssignmentExclusive &&
      isExclusivitySelected;
    const iconProps = {
      name: 'fancyArrow',
      color: shouldBeDisabled ? 'dark.mid' : 'blue.solid',
      fontSize: 20,
    };
    const buttonProps = {
      ...(shouldBeDisabled && {
        isDisabled: true,
        color: isMobile ? '#fff' : 'dark.mid',
      }),
      onClick: onAssign,
    };
    return (
      <Button
        {...commonProps}
        {...buttonProps}
        {...(isMobile
          ? {
              leftIcon: {
                ...iconProps,
                color: '#fff',
                fontSize: 22,
                mr: 0,
              },
            }
          : {
              rightIcon: <Icon {...iconProps} />,
              variant: 'ghost',
            })}
        data-test-id={`${mainNameQA}--assignSupplier`}
      >
        {isMobile ? 'Assign this delivery partner' : 'Assign'}
      </Button>
    );
  }
  if (
    assignment.status !== ASSIGNMENT_STATUSES.SCHEDULED &&
    assignment.status !== ASSIGNMENT_STATUSES.CANCELLED &&
    !isMobile
  ) {
    const iconProps = {
      name: 'clock3',
      color: 'dark.mid',
    };
    const buttonProps = {
      onClick: async () => {
        setIsReminding(true);
        await onRemind();
        setIsReminding(false);
      },
      isLoading: isReminding,
    };
    return (
      <Button
        {...commonProps}
        {...buttonProps}
        variant="ghost"
        rightIcon={<Icon {...iconProps} />}
        data-test-id={`${mainNameQA}--remindSupplier`}
      >
        Remind
      </Button>
    );
  }
  return null;
};

export default inject('briefsStore')(observer(AssignmentActionButtons));
