import React from 'react';
import { Field } from 'react-final-form';
import { required } from '@utils/formValidators';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import FormSpyFieldValues from '@components/forms/FormSpyFieldValues';
import Select from '@components/forms/Select';
import { reject, isEmpty, map, isEqual, find } from 'lodash-es';

const ProjectPrimaryDPSection = ({ formApi, mainNameQA }) => {
  return (
    <WhiteCard p="40px" boxShadow="none">
      <Typography variant="h3" mb="24px">
        Primary Delivery Partner
      </Typography>
      <Typography mb="32px">
        Only Primary Delivery Partner will be responsible for the Milestone
        plan.
      </Typography>
      <FormSpyFieldValues fieldNames={['projectToSuppliers']}>
        {({ projectToSuppliers }) => {
          const options = map(
            reject(projectToSuppliers, dp => isEmpty(dp?.supplier)),
            dp => ({
              label: dp?.supplier?.name,
              value: dp?.supplier,
            }),
          );

          return (
            <Field
              styleProps={{
                maxW: '376px',
              }}
              placeholder="Select Delivery Partner"
              name="primaryDP"
              data-test-id={`${mainNameQA}--primaryDP`}
              component={props => {
                const { input } = props;
                React.useEffect(() => {
                  if (!find(options, opt => isEqual(opt.value, input.value))) {
                    input.onChange(null);
                  }
                }, [options, input.value]);

                return (
                  <Select
                    {...props}
                    onAfterChange={supplier => {
                      const {
                        values: { projectToSuppliers: suppliers },
                      } = formApi.getState();
                      formApi.change(
                        'projectToSuppliers',
                        map(suppliers, pts => ({
                          ...pts,
                          isPrimary: isEqual(supplier, pts?.supplier),
                        })),
                      );
                    }}
                  />
                );
              }}
              options={options}
              isDisabled={isEmpty(options)}
              validate={required}
            />
          );
        }}
      </FormSpyFieldValues>
    </WhiteCard>
  );
};

export default ProjectPrimaryDPSection;
