import React from 'react';
import {
  FormControl,
  Input,
  InputRightElement,
  InputLeftElement,
  Flex,
  Box,
  InputGroup,
} from '@chakra-ui/react';
import { getInputVariantProps, INPUT_VARIANTS } from '@styles/theme';
import Tooltip from '@styles/Tooltip';
import Icon from '@components/Icon';
import FormError from './FormError';
import FormLabel from '../FormLabel';

const TextInput = React.forwardRef(
  (
    {
      placeholder = '',
      label,
      input = {},
      meta = {},
      onAfterChange,
      isBordered = false,
      onAfterBlur,
      onFocus,
      containerProps,
      isErrorAbsolute,
      errorStyle,
      variant = INPUT_VARIANTS.DEFAULT,
      labelProps = {},
      hideError,
      rightIcon,
      leftIcon,
      iconColor,
      onOnlyBlur,
      tooltip,
      inputBoxAutoHeight,
      elementProps = {},
      ...props
    },
    ref,
  ) => {
    const hasError =
      !!(meta.touched || meta.submitFailed) && (meta.error || meta.submitError);
    const {
      labelProps: lProps,
      inputProps,
      containerProps: wrapperProps,
    } = getInputVariantProps(variant);

    const elementIconBasicStyle = {
      width: 'auto',
      transform: 'translateY(-50%)',
      top: '50%',
    };
    return (
      <FormControl
        position={isErrorAbsolute && 'relative'}
        w="100%"
        {...containerProps}
        {...wrapperProps}
      >
        <Flex alignItems="flex-start">
          <FormLabel
            label={label}
            name={input.name}
            {...lProps}
            {...labelProps}
          />
          {tooltip && (
            <Tooltip label={tooltip} placement="bottom">
              <Box as="span">
                <Icon name="infoFilled" color="gray.400" mb="8px" />
              </Box>
            </Tooltip>
          )}
        </Flex>
        <Box position="relative" height={inputBoxAutoHeight ? 'auto' : '100%'}>
          {leftIcon && (
            <InputGroup position="initial">
              <InputLeftElement
                pl="14px"
                {...elementIconBasicStyle}
                {...elementProps}
                color={iconColor}
              >
                <Icon name={leftIcon} />
              </InputLeftElement>
            </InputGroup>
          )}
          <Input
            pl={leftIcon ? '44px' : '12px'}
            borderColor={hasError ? 'red.solid' : 'gray.light'}
            {...input}
            id={input.name}
            placeholder={placeholder}
            _placeholder={{ color: 'gray.200' }}
            borderStyle={isBordered || hasError ? 'solid' : 'none'}
            bg="white"
            {...inputProps}
            onChange={e => {
              input.onChange(e.target.value);
              if (typeof onAfterChange === 'function') {
                onAfterChange(e.target.value);
              }
            }}
            {...props}
            onBlur={e => {
              input.onBlur(e.target.value);
              if (typeof onAfterBlur === 'function') {
                onAfterBlur(e.target.value);
              }
            }}
            {...(typeof onOnlyBlur === 'function' && { onBlur: onOnlyBlur })}
            ref={ref}
          />
          {rightIcon && (
            <InputGroup position="initial">
              <InputRightElement
                pr="14px"
                {...elementProps}
                {...elementIconBasicStyle}
                color={iconColor}
              >
                <Icon name={rightIcon} />
              </InputRightElement>
            </InputGroup>
          )}
        </Box>

        {!hideError && (
          <FormError
            isErrorAbsolute={isErrorAbsolute}
            meta={meta}
            style={errorStyle}
          />
        )}
      </FormControl>
    );
  },
);

export default TextInput;
