import { Stack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import qs from 'query-string';
import Typography from '@styles/Typography';
import React from 'react';
import { inject, observer } from 'mobx-react';

const ConfirmMergeBriefModal = ({ isOpen, onClose, project, routerStore }) => {
  const { ppBriefId } = qs.parse(routerStore.history.location.search);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2" mb="24px" textAlign="center">
        Are you sure?
      </Typography>

      <Typography textAlign="center">
        You are about to merge your brief with
      </Typography>
      <Typography mb="40px" textAlign="center" fontWeight="600">
        {project.name}
      </Typography>
      <Stack spacing={3}>
        <Button
          h="44px"
          as={Link}
          to={`/clients/${project?.client?.slug || 'deazy'}/projects/${
            project.slug
          }/new-delivery-partner?${qs.stringify({ ppBriefId })}`}
        >
          Continue to edit
        </Button>
        <Button h="44px" onClick={onClose} variant="outline">
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};

export default inject('routerStore')(observer(ConfirmMergeBriefModal));
