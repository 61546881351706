import React from 'react';
import WhiteCard from '@components/WhiteCard';
import { inject, observer } from 'mobx-react';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { isEmpty, map, take } from 'lodash-es';
import { Box } from '@chakra-ui/react';
import moment from 'moment';
import Button from '@styles/Button';

const ProposalQueriesList = ({
  proposalId,
  proposalQueriesStore: {
    fetchProposalQueries,
    clearProposalQueries,
    queries: { isLoading, data: queries },
  },
  usersStore: { profile },
}) => {
  const [showAll, setShowAll] = React.useState(false);

  React.useEffect(() => {
    if (proposalId) {
      fetchProposalQueries(proposalId);
    }
    return () => {
      clearProposalQueries();
    };
  }, [proposalId]);

  if (isLoading) {
    return <Spinner mx="auto" my="50px" variant="medium" />;
  }
  if (isEmpty(queries)) {
    return null;
  }

  return (
    <WhiteCard>
      {map(showAll ? queries : take(queries, 1), (q, idx) => (
        <React.Fragment key={`proposal-query-${q.id}`}>
          {queries[idx - 1]?.createdBy?.user?.id !== q?.createdBy?.user?.id && (
            <Typography variant="h5" mb="8px">
              {q?.createdBy?.user?.id === profile.id
                ? 'Your Query'
                : 'Query from client'}
            </Typography>
          )}
          <Box
            p="16px"
            bg="primary.100"
            borderRadius="8px"
            _notLast={{ mb: '16px' }}
          >
            {q.createdAt && (
              <Typography variant="text4" color="gray.mid" mb="8px">
                {moment(q.createdAt).format('MMM DD, YYYY, h:mm A')}
              </Typography>
            )}
            {q.message}
          </Box>
        </React.Fragment>
      ))}
      {setShowAll && queries.length > 1 && (
        <Button
          display="flex"
          mt="32px"
          onClick={() => setShowAll(!showAll)}
          variant="ghost"
          mx="auto"
        >
          {showAll ? 'Hide previous queries' : 'Show previous queries'}
        </Button>
      )}
    </WhiteCard>
  );
};

export default inject(
  'proposalQueriesStore',
  'usersStore',
)(observer(ProposalQueriesList));
