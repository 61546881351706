import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

const ListContainer = ({ children }) => (
  <Box maxW="1201px" h="100%" w="100%" mx="auto" minH="calc(100vh - 135px)">
    <Flex h="100%" px="32px" pt="39px" direction="column" align="flex-start">
      {children}
    </Flex>
  </Box>
);
export default ListContainer;
