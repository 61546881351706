import React from 'react';
import moment from 'moment';
import { Box, Flex } from '@chakra-ui/react';
import Button from '@styles/Button';
import ReadOnlyTable from '@components/ReadOnlyTable';
import TextInput from '@components/forms/_common/TextInput';
import { Field, Form } from 'react-final-form';
import DatePicker from '@components/forms/DatePicker';
import { CURRENCIES_SYMBOLS } from '@app/constants';
import { inject, observer } from 'mobx-react';
import { formatCurrency } from '@utils';
import { get, isNil, toNumber } from 'lodash-es';
import { LineItemInput } from '../InvoiceLineItemForm';
import TaxCell from '../TaxCell';

const CreditNoteForm = ({
  project,
  invoice,
  currency,
  onCancel,
  onSubmit,
  toastsStore: { showError },
  mainNameQA,
}) => {
  const isClient = invoice.contactType === 'Client';
  const taxName = get(invoice, 'taxInfo.name', '');
  const creditNoteCurrency = isClient ? currency : invoice.overriddenCurrency;

  const { total: maxValue } = invoice;

  const COLUMNS = [
    {
      name: 'Description',
      w: '40%',
      ellipsis: false,
      component: () => <LineItemInput fieldName="description" />,
    },
    {
      name: 'Quantity',
      w: '15%',
      component: () => (
        <LineItemInput
          fieldName="quantity"
          isMaskedInput
          inputProps={{
            decimalScale: 2,
            fixedDecimalScale: true,
            isAllowed: ({ floatValue }) =>
              (floatValue >= 0 && maxValue >= floatValue) || isNil(floatValue),
          }}
          data-test-id={`${mainNameQA}--quantity`}
        />
      ),
    },
    {
      name: 'Unit price',
      w: '15%',
      component: () => (
        <LineItemInput
          fieldName="unitPrice"
          isMaskedInput
          inputProps={{
            decimalScale: 2,
            fixedDecimalScale: true,
            isAllowed: ({ floatValue }) =>
              (floatValue >= 0 && maxValue >= floatValue) || isNil(floatValue),
            prefix: CURRENCIES_SYMBOLS[creditNoteCurrency],
          }}
          data-test-id={`${mainNameQA}--creditNoteUnitPrice`}
        />
      ),
    },
    {
      name: 'Vat',
      w: '15%',
      component: () => (
        <TaxCell
          value={taxName}
          isLoading={invoice.isLoadingTaxInfo}
          bg="dark.light"
          data-test-id={`${mainNameQA}--creditNoteVAT`}
        />
      ),
      bg: 'dark.light',
    },
    {
      name: 'Amount',
      w: '15%',
      bg: 'dark.light',
      borderRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  ];

  const initialValues = React.useMemo(() => {
    return {
      projectId: project.id,
      invoiceId: invoice.id,
      date: moment().toDate(),
      reference: invoice.reference,
      description: invoice.reference,
      quantity: 1,
      unitPrice: maxValue,
    };
  }, [invoice]);

  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize
      initialValues={initialValues}
      render={({ handleSubmit, submitting, values }) => {
        const tableData = React.useMemo(() => {
          return [
            [
              '',
              '',
              '',
              '',
              formatCurrency(
                toNumber(values.unitPrice) * toNumber(values.quantity),
                creditNoteCurrency,
              ),
            ],
          ];
        }, [creditNoteCurrency, maxValue, values.unitPrice, values.quantity]);

        const isMaxValueExceeded = React.useMemo(() => {
          return (
            toNumber(values.unitPrice) >= 0 &&
            toNumber(values.quantity) >= 0 &&
            toNumber(values.unitPrice) * toNumber(values.quantity) > maxValue
          );
        }, [values.unitPrice, values.quantity, maxValue]);

        React.useEffect(() => {
          if (isMaxValueExceeded) {
            showError({
              title: 'Total amount cannot exceed project total value!',
            });
          }
        }, [isMaxValueExceeded]);
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Flex justifyContent="space-between">
              <Field
                label="Date"
                name="date"
                component={DatePicker}
                containerProps={{ flex: 0.5 }}
                bg="#fff"
                data-test-id={`${mainNameQA}--creditNoteDate`}
              />
              <Box pr="16px" />
              <Field
                label="Reference"
                name="reference"
                component={TextInput}
                containerProps={{ flex: 0.5 }}
                data-test-id={`${mainNameQA}--creditNoteReference`}
              />
            </Flex>
            <ReadOnlyTable
              columns={COLUMNS}
              tableData={tableData}
              qaTag={mainNameQA}
            />
            <Flex mt="16px">
              <Button
                mr="16px"
                type="submit"
                leftIcon={{ name: 'check', fontSize: 22 }}
                isLoading={submitting}
                data-test-id={`${mainNameQA}--creditNoteConfirm`}
                isDisabled={isMaxValueExceeded}
              >
                Confirm
              </Button>
              <Button
                mr="auto"
                variant="outline"
                isDisabled={submitting}
                onClick={onCancel}
                data-test-id={`${mainNameQA}--creditNoteCancel`}
              >
                Cancel
              </Button>
            </Flex>
          </form>
        );
      }}
    />
  );
};

export default inject('toastsStore')(observer(CreditNoteForm));
