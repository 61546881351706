import {
  ROLE_TYPES,
  ROLE_TYPES_OPTIONS,
  SENIORITY_OPTIONS,
  SENIORITY_TYPES,
  CANDIDATE_STATUSES,
} from '@app/constants';
import { Box, Flex, List, ListItem, useBoolean } from '@chakra-ui/react';
import DatePicker from '@components/forms/DatePicker';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import NativeUploadFile from '@components/forms/NativeUploadFile';
import Select from '@components/forms/_common/Select';
import TextInput from '@components/forms/_common/TextInput';
import Icon from '@components/Icon';
import Button from '@styles/Button';
import { filter, isEmpty, isNil, keys, map } from 'lodash-es';
import React from 'react';
import { Field } from 'react-final-form';
import CandidateBadge from '../AllCandidates/CandidateBadge';
import CandidateQueriesList from '../AllCandidates/CandidateQueriesList';
import ResourceRateIndicator from '../Proposal/ResourceRateIndicator';
import CommentSection from './CommentSection';
import CvDownloadSection from './CvDownloadSection';
import EmployeeTypeRadio from './EmployeeTypeRadio';
import RoleShortlistedWarningModal from './RoleShortlistedWarningModal';

const SingleCandidate = ({
  rowId,
  candidate: { status, devProfile = {}, ...c },
  candidateIdx,
  resourceIdx: idx,
  onProfileRedirect,
  formApi,
  onSaveComment,
  onDeleteRole,
  candidateErrors,
  shouldShowCandidateError,
  isReadOnly,
  disabledDaysFn,
  isSmallerScreen,
  isEditingMode,
  mainNameQA,
  supplierName,
}) => {
  const [isShortlistedModalOpen, setIsShortlistedModalOpen] = useBoolean();
  const [isEditing, setIsEditing] = useBoolean(isEditingMode || !c.id);
  const onEditCandidate = React.useCallback(() => {
    if (status === CANDIDATE_STATUSES.SHORTLISTED) {
      setIsShortlistedModalOpen.on();
    } else {
      setIsEditing.on();
    }
  }, [status]);

  React.useEffect(() => {
    if (isEditingMode) {
      setIsEditing.on();
    }
  }, [isEditingMode]);

  return (
    <Box
      mt="24px"
      bg={status === 'Won' ? 'green.light' : 'gray.100'}
      borderRadius="8px"
      border="1px solid"
      borderColor="dark.mid"
    >
      <Box p="24px">
        <Flex justifyContent="flex-end" alignItems="center">
          {devProfile?.id && (
            <Button
              variant="ghost"
              leftIcon={{ name: 'candidate', mr: 0, fontSize: 20 }}
              data-test-id={`${mainNameQA}--linedCandidateButton`}
              onClick={() => onProfileRedirect(`/profile/${devProfile.id}`)}
            >
              Linked candidate
            </Button>
          )}
          {status && <CandidateBadge status={status} />}
        </Flex>
        <Flex
          w="100%"
          flexDir={['column', 'column', 'column', 'column', 'row']}
        >
          <Flex flexDir="column" w="100%" pr="24px">
            <Flex
              mt={!isSmallerScreen && '16px'}
              mb={!isSmallerScreen && '24px'}
              flexDir={['column', 'column', 'column', 'row']}
            >
              <Box
                flex={1}
                pr={[0, 0, 0, '24px']}
                mb={['12px', '12px', '12px', 0]}
              >
                <Field
                  name={`resources[${idx}].candidates[${candidateIdx}].name`}
                  component={TextInput}
                  label="Candidate name"
                  bg="white"
                  _disabled={{ bg: 'transparent' }}
                  isDisabled={!isEditing || !!devProfile?.user?.fullname}
                  placeholder="Type candidate name"
                  data-test-id={`${mainNameQA}--resources[${idx}].candidates[${candidateIdx}].name`}
                />
              </Box>
              <Box
                flex={1}
                pr={[0, 0, 0, '24px']}
                mb={['12px', '12px', '12px', 0]}
              >
                <Field
                  name={`resources[${idx}].candidates[${candidateIdx}].role`}
                  label="Role"
                  bg="white"
                  isDisabled={!isEditing}
                  placeholder="Select Role"
                  options={ROLE_TYPES_OPTIONS}
                  component={Select}
                  data-test-id={`${mainNameQA}--resources[${idx}].candidates[${candidateIdx}].role`}
                />
              </Box>
              <Box flex={1} mb={['12px', '12px', '12px', 0]}>
                <Field
                  name={`resources[${idx}].candidates[${candidateIdx}].seniority`}
                  label="Seniority"
                  bg="white"
                  isDisabled={!isEditing || !!devProfile?.seniority}
                  placeholder="Select Seniority"
                  component={Select}
                  key={`key=by-${c.role}`}
                  data-test-id={`${mainNameQA}--resources[${idx}].candidates[${candidateIdx}].seniority`}
                  options={
                    c.role !== ROLE_TYPES.DEV
                      ? filter(
                          SENIORITY_OPTIONS,
                          opt =>
                            opt.value !== SENIORITY_TYPES.TechLead &&
                            opt.value !== SENIORITY_TYPES.Architect,
                        )
                      : SENIORITY_OPTIONS
                  }
                />
              </Box>
            </Flex>
            <Flex
              my={!isSmallerScreen && '24px'}
              flexDir={['column', 'column', 'column', 'row']}
            >
              <Box
                w={['100%', '100%', '100%', 'calc(33% + 24px)']}
                mr={[0, 0, 0, '24px']}
                position="relative"
                mb={['12px', '12px', '12px', 0]}
              >
                <ResourceRateIndicator
                  formApi={formApi}
                  position="absolute"
                  top="55%"
                  right="12px"
                  zIndex={30}
                  isPnP
                  prefix={`resources[${idx}].candidates[${candidateIdx}]`}
                />
                <Field
                  name={`resources[${idx}].candidates[${candidateIdx}].rate`}
                  data-test-id={`${mainNameQA}--resources[${idx}].candidates[${candidateIdx}].rate`}
                  component={MaskedTextInput}
                  label="Rate (hourly)"
                  isDisabled={!isEditing}
                  _disabled={{ bg: 'transparent' }}
                  decimalScale={2}
                  fixedDecimalScale
                  isAllowed={({ floatValue }) =>
                    floatValue >= 1 || isNil(floatValue)
                  }
                  placeholder="Add a rate"
                />
              </Box>
              <Box
                pr={[0, 0, 0, '24px']}
                mb={['12px', '12px', '12px', 0]}
                w={['100%', '100%', '100%', 'calc(33% + 24px)']}
              >
                <Field
                  name={`resources[${idx}].candidates[${candidateIdx}].location`}
                  data-test-id={`${mainNameQA}--resources[${idx}].candidates[${candidateIdx}].location`}
                  component={TextInput}
                  _disabled={{ bg: 'transparent' }}
                  isDisabled={!isEditing}
                  label="Location"
                  bg="white"
                  placeholder="Type location"
                />
              </Box>
              <Box
                w={['100%', '100%', '100%', 'calc(33% + 24px)']}
                mb={['12px', '12px', '12px', 0]}
              >
                <Field
                  component={DatePicker}
                  isDisabled={!isEditing}
                  bg="white"
                  minW="100px"
                  name={`resources[${idx}].candidates[${candidateIdx}].startDate`}
                  data-test-id={`${mainNameQA}--resources[${idx}].candidates[${candidateIdx}].startDate`}
                  label="Start date"
                  _disabled={{ bg: 'transparent' }}
                  disabledDaysFn={disabledDaysFn}
                  placeholder="Set a date"
                />
              </Box>
            </Flex>
          </Flex>
          <EmployeeTypeRadio
            mt={!isSmallerScreen && '16px'}
            isDisabled={!isEditing}
            fieldName={`resources[${idx}].candidates[${candidateIdx}].typeOfPlugPlayResource`}
            supplierName={supplierName}
            mb="12px"
          />
        </Flex>

        <Flex flexDir={['column', 'column', 'column', 'row']}>
          <CommentSection
            c={c}
            isReadOnly={!isEditing}
            onSaveComment={onSaveComment}
            idx={idx}
            candidateIdx={candidateIdx}
            mainNameQA={mainNameQA}
          />
          {!devProfile?.id && (
            <Flex w={['100%', '100%', '100%', '288px']} pt={[0, 0, 0, '18px']}>
              {!isEditing ? (
                <CvDownloadSection candidate={c} />
              ) : (
                <Field
                  component={NativeUploadFile}
                  title="Curriculum vitae"
                  data-test-id={`${mainNameQA}--resources[${idx}].candidates[${candidateIdx}].file`}
                  pt={0}
                  name={`resources[${idx}].candidates[${candidateIdx}].file`}
                  label="Upload a CV for this candidate"
                  nativeInputProps={{
                    accept:
                      'application/pdf, application/doc, application/docx',
                  }}
                  allowedExtensions={['.pdf', '.doc', '.docx']}
                />
              )}
            </Flex>
          )}
        </Flex>

        {!isReadOnly && (
          <Flex
            mt="24px"
            w={['100%', '100%', '100%', 'auto']}
            flexDir={[
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'row',
            ]}
          >
            <Button
              variant="outline"
              borderColor="red.solid"
              display="flex"
              ml="auto"
              _hover={{
                bg: 'red.mid',
              }}
              _active={{
                bg: 'red.mid',
              }}
              leftIcon={{
                color: 'red.solid',
                name: 'trashNew',
                mr: '0px',
                fontSize: 14,
              }}
              color="dark.solid"
              w={['100%', '100%', '100%', 'auto']}
              onClick={() => onDeleteRole(idx, candidateIdx)}
              data-test-id={`${mainNameQA}--removeCandidate-${candidateIdx}`}
            >
              Remove candidate
            </Button>
            {c.id && !isEditingMode && (
              <Button
                leftIcon={{
                  name: 'edit',
                  fontSize: 24,
                  mr: '0px',
                }}
                onClick={onEditCandidate}
                isDisabled={isEditing}
                mb={['12px', '12px', '12px', 0]}
                ml={[0, 0, 0, '8px']}
                w={['100%', '100%', '100%', 'auto']}
              >
                Edit candidate
              </Button>
            )}
          </Flex>
        )}
      </Box>

      {!isEmpty(candidateErrors[rowId]) && (
        <List spacing={3} ml="24px">
          {map(keys(candidateErrors[rowId]), key =>
            shouldShowCandidateError(idx, candidateIdx, key) ? (
              <ListItem
                className="final-form-error"
                color="red.500"
                my="12px"
                key={`role-error-${idx}-${candidateIdx}-${key}-${rowId}`}
              >
                <Icon name="warning" color="red.500" mb="2px" mr="5px" />
                {candidateErrors[rowId][key]}
              </ListItem>
            ) : null,
          )}
        </List>
      )}
      <CandidateQueriesList isDpView queries={c.queries} />
      <RoleShortlistedWarningModal
        isOpen={isShortlistedModalOpen}
        onClose={setIsShortlistedModalOpen.off}
        onConfirm={() => {
          setIsEditing.on();
          setIsShortlistedModalOpen.off();
        }}
      />
    </Box>
  );
};

export default SingleCandidate;
