import { Box } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';

const SectionTitle = ({ label, description, ...props }) => (
  <Box {...props}>
    <Typography variant="h2" mb={2}>
      {label}
    </Typography>
    <Typography>{description}</Typography>
  </Box>
);

export default SectionTitle;
