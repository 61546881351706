import { Box, Flex, Stack } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import { isEmpty } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';

const LinkedBriefBanner = ({
  briefsStore: {
    toggleIsCreatingProjectFromBrief,
    isCreatingProjectFromBrief,
    brief: briefData,
  },
}) => {
  const brief = !isEmpty(briefData?.brief) ? briefData.brief : briefData;
  if (!isCreatingProjectFromBrief) {
    return null;
  }

  return (
    <Flex py="18px" px="14px" bg="#fff" alignItems="center" mb="24px" w="100%">
      <Flex
        borderRadius="50%"
        w="44px"
        h="44px"
        bg="blue.solid"
        alignItems="center"
        mr="18px"
        justifyContent="center"
      >
        <Icon name="link" color="#fff" fontSize={28} />
      </Flex>
      <Typography variant="h4">Linked project</Typography>
      <Flex ml="auto">
        <Stack isInline mr="56px" alignSelf="center">
          <Typography mr="8px">Brief:</Typography>
          <Typography fontFamily="WB">{brief?.name}</Typography>
        </Stack>
        <Stack isInline mr="56px" alignSelf="center">
          <Typography mr="8px">Client:</Typography>
          <Typography fontFamily="WM">{brief?.customer?.name}</Typography>
        </Stack>
        <Stack
          isInline
          borderLeft="1px solid"
          borderColor="dark.mid"
          pl="10px"
          py="11px"
        >
          <Icon name="unlink" color="blue.solid" fontSize={22} />
          <Box
            fontSize={18}
            mr="8px"
            as={Typography}
            textDecor="underline"
            onClick={() => toggleIsCreatingProjectFromBrief(false)}
            _hover={{ color: 'blue.solid', cursor: 'pointer' }}
          >
            Unlink from brief
          </Box>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default inject('briefsStore')(observer(LinkedBriefBanner));
