import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import ImportProjectModal from '@components/ImportProjectModal';
import useMediaQuery from '@hooks/useMediaQuery';
// import CreateUserModal from '@components/CreateUserModal/CreateUserModal';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Sidebar from './Sidebar';
import Topbar from './Topbar';

const AuthedLayout = ({
  title,
  searchBar,
  hideTopbar,
  hideSidebar,
  children,
  appStore: { isSubSidebarOpened },
  projectsStore: { importProject },
  userOrchestrationStore: {
    // isOpenCreateUserModal,
    onCreateUserModalOpen,
    // onCloseCreateUserModal,
  },
  subSidebarWidth,
  ...props
}) => {
  const { onOpen: onImportProjectModalOpen, onClose, isOpen } = useDisclosure();
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');
  const topBarHeight = isSmallerScreen ? '63px' : '80px';
  const hideSmallerScreenSidebar = isSmallerScreen && hideSidebar;

  return (
    <Flex>
      {!hideSmallerScreenSidebar && (
        <Sidebar
          onImportProjectModalOpen={onImportProjectModalOpen}
          onCreateUserModalOpen={onCreateUserModalOpen}
        />
      )}
      {!hideTopbar && (
        <Topbar
          title={title}
          searchBar={searchBar}
          onImportProjectModalOpen={onImportProjectModalOpen}
          onCreateUserModalOpen={onCreateUserModalOpen}
          {...props}
        />
      )}
      <Box
        pl={!isSmallerScreen && (isSubSidebarOpened ? subSidebarWidth : '92px')}
        pt={hideTopbar ? '0' : topBarHeight}
        minH={`calc(100vh - ${hideTopbar ? '0' : topBarHeight})`}
        h="1px"
        w="100%"
      >
        {children}
      </Box>
      {isOpen && (
        <ImportProjectModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={values => {
            importProject(values, onClose);
          }}
        />
      )}
      {/* {isOpenCreateUserModal && (
        <CreateUserModal
          isOpen={isOpenCreateUserModal}
          onClose={onCloseCreateUserModal}
        />
      )} */}
    </Flex>
  );
};

export default inject(
  'appStore',
  'projectsStore',
  'userOrchestrationStore',
)(observer(AuthedLayout));
