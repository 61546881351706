import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  Box,
  Flex,
  useDisclosure,
  Accordion,
  AccordionItem,
} from '@chakra-ui/react';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import Typography from '@styles/Typography';
import Spinner from '@styles/Spinner';
import PageDescription from '@components/PageDescription';
import { toJS } from 'mobx';
import styled from '@emotion/styled';
import { formatCurrency, formatNumber, roundTheseHours, sleep } from '@utils';
import DiscardBriefModal from '@pages/BriefsCreate/DiscardBriefModal';
import SuccessModal from '@pages/BriefsCreate/SuccessModal';
import { APP_ROUTES } from '@routes';
import {
  NEW_PROPOSAL_STATUSES,
  VISIBLE_COSTS,
  MAIN_NAMES_QA,
} from '@app/constants';
import { isEmpty, omit, get, toNumber, pick } from 'lodash-es';
import { COLORS } from '@styles/theme';
import googleAnalytics from '@app/ga';
import BriefActions from '@components/BriefActions';
import useDownload from '@hooks/useDownload';
import ProposalOverview from './ProposalOverview';
import CoreTeamRolesForm from './CoreTeamRolesForm';
import ResourcesBreakdownSection from './ResourcesBreakdownSection';
import AdditionalTeamRolesForm from './AdditionalTeamRolesForm';
import CostBreakdownForm from './CostBreakdownForm';
import ProposalIncompleteModal from './ProposalIncompleteModal';
import ProposalEmpty from './ProposalEmpty';
import ProposalsAccordionItemBody from './ProposalAccordionItemBody';
import ProposalTimings from './ProposalTimings';
import ClientProposal from '../ClientProposal';
import ClientAcceptProposalModal from '../ClientProposal/ClientAcceptProposalModal';
import ClientDeclineProposalModal from '../ClientProposal/ClientDeclineProposalModal';
import DPFeatureSection from './DPFeatureSection';
import ExportProposalCsvSection from './ExportProposalCsvSection';
import RateConfirmationModal from './RateConfirmationModal';
import ProposalQueriesList from '../ClientProposal/ProposalQueriesList';
import CurrencyForm from './CurrencyForm';
import ProposalFooter from './ProposalFooter';
import SubmitProposalConfirmationModal from './SubmitProposalConfirmationModal';
import ProposalStatusDropdown from './ProposalStatusDropdown';
import StatusMessageBox from '../StatusMessageBox';

const { REACT_APP_API_URL: API_URL } = process.env;

const StyledAccordionItem = styled(AccordionItem)`
  .chakra-collapse {
    overflow: inherit !important;
  }
`;

const accordionItemProps = {
  bg: 'white',
  mb: '24px',
  borderRadius: '8px',
  border: `1px solid ${COLORS.dark.mid}`,
};

const mainNameQA = MAIN_NAMES_QA.PROJECT_PROPOSAL;

const MainProposalTitle = ({ isDeazyLayer, proposalData, ...props }) => {
  return (
    <Flex alignItems="center">
      <Typography variant="h1" mt="24px" mb="24px" {...props}>
        {isDeazyLayer
          ? `${proposalData?.briefToSupplier?.supplier?.name ||
              proposalData?.ppBriefToSupplier?.supplier?.name} Proposal`
          : 'Your Proposal'}
      </Typography>
    </Flex>
  );
};

const Proposal = ({
  proposalsStore: {
    submitProposal,
    clearProposalData,
    clearBreakdowns,
    saveProposalOverviewData,
    proposalData,
    initializeProposal,
    decoratedProposalData,
    saveProposalCoreTeamData,
    deleteCoreTeamRole,
    isLoadingProposal,
    fetchCostBreakdown,
    saveAdditionalTeam,
    totalHours,
    fetchProposalResources,
    proposalId,
    fetchProposalData,
    coreTeamError,
    overviewError,
    costBreakdown,
    featuresError,
    timingsError,
    isEditingRolesSection,
    isEditingFeaturesSection,
    isEditingCostSection,
    isEditingOverviewSection,
    saveProposalCostData,
    clearEditingSections,
    clearIncompleteFeatures,
    costSectionAsCompletedOnSubmit,
    fetchClientProposalData,
    clearCostSectionAsCompletedOnSubmit,
    fetchDeazyCostBreakdown,
    clientProposalData,
    validateProposalRates,
    briefToSupplierId,
    saveProposalCurrency,
    saveProposalTimings,
    isPendingRequest,
    selectedFeatureId,
    setSelectedFeatureId,
    proposalCurrency,
  },
  briefsStore: { brief },
  toastsStore,
  usersStore: { profile, isTeamAdmin, isDL, isAdminOrDL, isClient },
  history,
  isReadOnly = false,
  isDeazyLayer = false,
  readOnlyProposalId,
  match: {
    params: { proposalId: proposalIdFromURL },
  },
  ratesStore: { fetchResourceRates },
}) => {
  const isSmallerScreen = useMediaQuery(breakpoint.lg);
  const initFeatureId = get(proposalData, 'features[0].id', null);

  React.useEffect(() => {
    fetchResourceRates(proposalData?.briefToSupplier?.supplier?.id);
  }, [proposalData?.briefToSupplier?.supplier?.id]);

  React.useEffect(() => {
    if (!selectedFeatureId) {
      setSelectedFeatureId(initFeatureId);
    }
  }, [initFeatureId]);

  const [isSubmitting, setIsSubmitting] = React.useState(null);
  const disableForms =
    proposalData.status !== NEW_PROPOSAL_STATUSES.WITH_DP || brief.isClosed;

  const proposalTimingsReadOnly = React.useMemo(() => {
    if (
      isTeamAdmin &&
      proposalData.status === NEW_PROPOSAL_STATUSES.WITH_DP &&
      !brief.isClosed
    ) {
      return false;
    }
    if (
      isAdminOrDL &&
      proposalData.status === NEW_PROPOSAL_STATUSES.WITH_DEAZY &&
      !brief.isClosed
    ) {
      return false;
    }
    return true;
  }, [proposalData.status, isTeamAdmin, isAdminOrDL, brief.isClosed]);

  React.useEffect(() => {
    if (isAdminOrDL) {
      fetchDeazyCostBreakdown(brief.id, proposalIdFromURL);
    }
  }, [brief.id]);

  React.useEffect(() => {
    if (brief.id && isTeamAdmin && !isReadOnly && proposalId) {
      fetchProposalData(brief.id, proposalId);
    }
    if (brief.id && isClient && (proposalId || proposalIdFromURL)) {
      fetchClientProposalData(brief.id, proposalIdFromURL || proposalId);
    }
    return () => {
      if (!isDeazyLayer) {
        clearProposalData();
        clearBreakdowns();
        clearIncompleteFeatures();
        clearCostSectionAsCompletedOnSubmit();
      }
    };
  }, [brief.id, proposalId]);

  React.useEffect(() => {
    if (isReadOnly && brief.id && readOnlyProposalId) {
      fetchProposalData(brief.id, readOnlyProposalId, true);
    }
  }, [brief.id]);

  React.useEffect(() => {
    if (
      (proposalId || readOnlyProposalId) &&
      decoratedProposalData.isFetched &&
      !isClient
    ) {
      fetchProposalResources(brief.id, readOnlyProposalId || proposalId);
      fetchCostBreakdown(brief.id, readOnlyProposalId || proposalId);
    }
  }, [
    proposalId,
    proposalData.features,
    decoratedProposalData.isFetched,
    JSON.stringify(decoratedProposalData.additionalTeam),
    JSON.stringify(decoratedProposalData.coreTeam),
    decoratedProposalData.contingencyPercent,
    decoratedProposalData.contingencyValue,
  ]);

  let overviewSubmit;
  let teamRolesSubmit;
  let costBreakdownSubmit;
  let timingsSubmit;
  let tasksSubmit;
  let additonalTeamRolesSubmit;
  let currencySubmit;

  const checkErrors = () => {
    const error = document.querySelector('.errorInSection');
    if (error) {
      error.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
    return !!error;
  };

  const { isOpen: isSuccessOpen, onOpen: onSuccessOpen } = useDisclosure();
  const {
    isOpen: isRateConfirmationOpen,
    onOpen: onRateConfirmationOpen,
    onClose: onRateConfirmationClose,
  } = useDisclosure();

  const {
    isOpen: isOpenSubmitConfirmation,
    onOpen: onOpenSubmitConfirmation,
    onClose: onCloseSubmitConfirmation,
  } = useDisclosure();

  const {
    isOpen: isDiscardOpen,
    onOpen: onDiscardOpen,
    onClose: onDiscardClose,
  } = useDisclosure();

  const [showErrors, setShowErrors] = React.useState(false);

  const createProposal = async () => {
    setIsSubmitting(true);

    await sleep(50);
    if (overviewSubmit) {
      await sleep(10);
      await overviewSubmit();
    }
    if (timingsSubmit) {
      await sleep(10);
      await timingsSubmit();
    }
    if (teamRolesSubmit) {
      await sleep(10);
      await teamRolesSubmit();
    }
    if (costBreakdownSubmit) {
      await sleep(10);
      await costBreakdownSubmit();
    }
    if (tasksSubmit) {
      await sleep(10);
      await tasksSubmit();
    }
    if (additonalTeamRolesSubmit) {
      await sleep(10);
      await additonalTeamRolesSubmit();
    }
    if (currencySubmit) {
      await sleep(10);
      await currencySubmit();
    }

    const hasFromErrors = checkErrors();

    setShowErrors(true);

    if (hasFromErrors) {
      onCloseSubmitConfirmation();
    }
    if (!isRateConfirmationOpen && !hasFromErrors) {
      const hasError = validateProposalRates(proposalData);
      if (hasError) {
        setIsSubmitting(false);
        return onRateConfirmationOpen();
      }
    }

    await submitProposal(
      { briefId: brief.id, ...proposalData, briefToSupplierId },
      onSuccessOpen,
    );

    if (featuresError || coreTeamError || overviewError) {
      onCloseSubmitConfirmation();
      toastsStore.showError({
        title: 'Please check, some steps are not completed',
      });
    }

    setIsSubmitting(false);

    clearEditingSections();
  };

  const [startProposal, setStartProposal] = React.useState(false);

  const simplySetIcon = value => (value === false ? 'check' : 'warning');

  const {
    isOpen: isClientAcceptModalOpen,
    onClose: onClientAcceptModalClose,
    onOpen: onClientAcceptModalOpen,
  } = useDisclosure();
  const {
    isOpen: isClientDeclineModalOpen,
    onClose: onClientDeclineModalClose,
    onOpen: onClientDeclineModalOpen,
  } = useDisclosure();

  const downloadLink = `${API_URL}/briefs/${brief.id}/clientProposals/${clientProposalData.id}/pdf`;

  const { onDownload, isLoading: isDownloadLoading } = useDownload({
    url: downloadLink,
  });

  if (isLoadingProposal) {
    return <Spinner />;
  }

  if (isClient) {
    return (
      <>
        <BriefActions
          brief={{ ...brief, isCreatingProposal: isSubmitting }}
          showAssignedBadge={false}
          proposal={isEmpty(proposalData) ? clientProposalData : proposalData}
          showProposalActions={false}
          showClientProposalActions
          disableClientProposalActions={
            clientProposalData.status !== NEW_PROPOSAL_STATUSES.WITH_CLIENT
          }
          onAcceptClientProposal={onClientAcceptModalOpen}
          onDeclineClientProposal={onClientDeclineModalOpen}
          onDownload={onDownload}
          isDownloadLoading={isDownloadLoading}
          canDownloadPDF
          isDisabledDownload={
            clientProposalData.clientSettings?.visibleCosts ===
            VISIBLE_COSTS.FULL_RANGE
          }
          mainNameQA={mainNameQA}
          isSmallerScreen={isSmallerScreen}
        />
        <ClientAcceptProposalModal
          isOpen={isClientAcceptModalOpen}
          onClose={onClientAcceptModalClose}
          proposalId={proposalIdFromURL}
        />
        <ClientDeclineProposalModal
          isOpen={isClientDeclineModalOpen}
          proposalId={proposalIdFromURL}
          onClose={onClientDeclineModalClose}
        />
        <ClientProposal
          mainNameQA={mainNameQA}
          isSmallerScreen={isSmallerScreen}
        />
      </>
    );
  }
  if (!proposalData.id && !startProposal && !isLoadingProposal) {
    return (
      <ProposalEmpty
        closedBrief={brief.isClosed}
        briefId={brief.id}
        initializeProposal={initializeProposal}
        setStartProposal={setStartProposal}
        mainNameQA={mainNameQA}
        isSmallerScreen={isSmallerScreen}
      />
    );
  }
  return (
    <>
      {!isReadOnly && (
        <>
          {!isSmallerScreen && (
            <PageDescription
              mb="12px"
              mt="20px"
              descriptionProps={{ maxW: '720px' }}
              description="Add the roles that are needed on the project in the project overview,
        then go through each feature on the left hand side and add estimates per role.
        You can add any contingency or project level roles in the project overview once you’ve added estimates per task."
            />
          )}
          {isSmallerScreen && (
            <Box bg="#fff" p="16px 12px" borderRadius="8px" mb="32px">
              <ProposalStatusDropdown
                useOutsideBg
                mainNameQA={mainNameQA}
                isSmallerScreen={isSmallerScreen}
                buttonProps={{
                  mt: '0px',
                  ml: '0px',
                }}
              />
              <MainProposalTitle
                isDeazyLayer={isDeazyLayer}
                proposalData={proposalData}
                mt="16px"
                mb="16px"
              />
              <StatusMessageBox
                message={proposalData.statusMessage}
                maxW="100%"
                mb={0}
                background="#F9FAFF"
              />
            </Box>
          )}
          <BriefActions
            showAssignedBadge={false}
            proposal={proposalData}
            brief={{ ...brief, isCreatingProposal: isSubmitting }}
            createProposal={createProposal}
            isPendingRequest={isPendingRequest}
            showProposalActions={
              isEmpty(brief?.supplierProposal?.proposal) ||
              proposalData?.status === NEW_PROPOSAL_STATUSES.WITH_DP
            }
            rejectProposal={onDiscardOpen}
            infoBoxMessage={proposalData.statusMessage}
            mainNameQA={mainNameQA}
            isSmallerScreen={isSmallerScreen}
            isTeamAdmin={isTeamAdmin}
          />
        </>
      )}
      <ProposalQueriesList proposalId={proposalId || proposalIdFromURL} />
      {!isSmallerScreen && (
        <MainProposalTitle
          isDeazyLayer={isDeazyLayer}
          proposalData={proposalData}
        />
      )}
      <Accordion
        defaultIndex={proposalData.submitted ? [0, 1, 2, 3] : [0]}
        allowMultiple={!proposalData.submitted}
        allowToggle={!proposalData.submitted}
      >
        <StyledAccordionItem {...accordionItemProps}>
          <ProposalsAccordionItemBody
            stepTitle="1. Configure your team"
            showArrow={!proposalData.submitted}
            isSmallerScreen={isSmallerScreen}
            iconWhenTrySubmitting={
              showErrors &&
              !isEditingRolesSection &&
              simplySetIcon(coreTeamError)
            }
            isError={showErrors && coreTeamError && !isEditingRolesSection}
            isCompleted={
              !coreTeamError && !isEditingRolesSection && !showErrors
            }
            isEditing={isEditingRolesSection}
            mainNameQA={mainNameQA}
            stepNumber={1}
          >
            <Box mb="32px" maxW="632px">
              <Typography>
                Start by setting up all the roles that will be working on the
                project.
              </Typography>
            </Box>
            <CurrencyForm
              initialValues={{
                currencies:
                  profile?.supplier?.paymentDetails ||
                  proposalData?.briefToSupplier?.supplier?.paymentDetails,
                overriddenCurrency: proposalData.overriddenCurrency,
              }}
              onSubmit={
                !isReadOnly ? saveProposalCurrency(isSubmitting) : () => {}
              }
              setSubmit={handleSubmit => {
                currencySubmit = handleSubmit;
              }}
              label="Project currency"
              mb="32px"
              fontSize="14px"
              name="overriddenCurrency"
              isReadOnly
              mainNameQA={mainNameQA}
            />
            <CoreTeamRolesForm
              isReadOnly={isReadOnly || disableForms}
              onSubmit={
                !isReadOnly ? saveProposalCoreTeamData(isSubmitting) : () => {}
              }
              initialValues={{
                coreTeam: toJS(proposalData.coreTeam),
              }}
              deleteRole={deleteCoreTeamRole}
              setSubmit={handleSubmit => {
                teamRolesSubmit = handleSubmit;
              }}
              mainNameQA={mainNameQA}
              isSmallerScreen={isSmallerScreen}
            />

            <AdditionalTeamRolesForm
              isReadOnly={isReadOnly || disableForms}
              onSubmit={
                !isReadOnly ? saveAdditionalTeam(isSubmitting) : () => {}
              }
              initialValues={{
                additionalTeam: toJS(proposalData.additionalTeam),
              }}
              currency={proposalCurrency}
              totalHours={totalHours}
              mainNameQA={mainNameQA}
              isSmallerScreen={isSmallerScreen}
            />
          </ProposalsAccordionItemBody>
        </StyledAccordionItem>
        <AccordionItem {...accordionItemProps}>
          {({ isExpanded }) => {
            React.useEffect(() => {
              if (isExpanded) {
                googleAnalytics.onProposalExpandEstimateSectionDpView(
                  proposalId,
                );
              }
            }, [isExpanded]);
            return (
              <ProposalsAccordionItemBody
                stepTitle="2. Estimate features"
                showArrow={!proposalData.submitted}
                stepNumber={2}
                mainNameQA={mainNameQA}
                isSmallerScreen={isSmallerScreen}
                iconWhenTrySubmitting={
                  showErrors &&
                  !isEditingFeaturesSection &&
                  simplySetIcon(featuresError)
                }
                isError={
                  showErrors && featuresError && !isEditingFeaturesSection
                }
                isCompleted={
                  !featuresError && !isEditingFeaturesSection && !showErrors
                }
                isEditing={isEditingFeaturesSection}
              >
                <DPFeatureSection
                  isReadOnly={isReadOnly}
                  isSubmitting={isSubmitting}
                />
              </ProposalsAccordionItemBody>
            );
          }}
        </AccordionItem>
        <StyledAccordionItem {...accordionItemProps}>
          {({ isExpanded }) => {
            React.useEffect(() => {
              if (isExpanded) {
                googleAnalytics.onProposalExpandCostSectionDpView(proposalId);
              }
            }, [isExpanded]);
            return (
              <ProposalsAccordionItemBody
                showArrow={!proposalData.submitted}
                stepTitle="3. Cost & Resource"
                isSmallerScreen={isSmallerScreen}
                iconWhenTrySubmitting={
                  showErrors &&
                  !isEditingCostSection &&
                  simplySetIcon(timingsError)
                }
                isError={showErrors && timingsError && !isEditingCostSection}
                isCompleted={
                  (proposalData.contingencyPercent ||
                    proposalData.contingencyValue ||
                    costSectionAsCompletedOnSubmit) &&
                  !isEditingCostSection &&
                  !timingsError &&
                  !showErrors
                }
                isEditing={isEditingCostSection}
                mainNameQA={mainNameQA}
                stepNumber={3}
              >
                <CostBreakdownForm
                  isReadOnly={isReadOnly || disableForms}
                  onSubmit={
                    !isReadOnly ? saveProposalCostData(isSubmitting) : () => {}
                  }
                  initialValues={{
                    ...omit(toJS(proposalData), ['coreTeam', 'additionalTeam']),
                    contingencyValue: costBreakdown.data.contingencyValue,
                    contingencyPercent: costBreakdown.data.contingencyPercent,
                  }}
                  setSubmit={handleSubmit => {
                    costBreakdownSubmit = handleSubmit;
                  }}
                  currency={proposalCurrency}
                  mainNameQA={mainNameQA}
                  isSmallerScreen={isSmallerScreen}
                />
                <ResourcesBreakdownSection
                  currency={proposalCurrency}
                  mainNameQA={mainNameQA}
                  isSmallerScreen={isSmallerScreen}
                />
                <ProposalTimings
                  mainNameQA={mainNameQA}
                  key={`timings-${proposalTimingsReadOnly}`}
                  isReadOnly={proposalTimingsReadOnly}
                  initialValues={{
                    ...pick(toJS(proposalData), [
                      'earliestStartDate',
                      'deliveryTime',
                      'estimatedDeliveryDate',
                    ]),
                  }}
                  setSubmit={handleSubmit => {
                    timingsSubmit = handleSubmit;
                  }}
                  onSubmit={
                    !proposalTimingsReadOnly
                      ? saveProposalTimings(isSubmitting)
                      : () => {}
                  }
                  isSmallerScreen={isSmallerScreen}
                />
                {isDL && (
                  <ExportProposalCsvSection
                    briefId={brief.id}
                    proposalId={proposalId}
                  />
                )}
              </ProposalsAccordionItemBody>
            );
          }}
        </StyledAccordionItem>
        <AccordionItem {...accordionItemProps} mb={isSmallerScreen && '42px'}>
          {({ isExpanded }) => {
            React.useEffect(() => {
              if (isExpanded) {
                googleAnalytics.onProposalExpandOverivewSectionDpView(
                  proposalId,
                );
              }
            }, [isExpanded]);
            return (
              <ProposalsAccordionItemBody
                showArrow={!proposalData.submitted}
                stepTitle="4. Overview"
                iconWhenTrySubmitting={
                  showErrors &&
                  !isEditingOverviewSection &&
                  simplySetIcon(overviewError)
                }
                isError={
                  showErrors && overviewError && !isEditingOverviewSection
                }
                isCompleted={
                  !overviewError && !isEditingOverviewSection && !showErrors
                }
                isEditing={isEditingOverviewSection}
                mainNameQA={mainNameQA}
                stepNumber={4}
                isSmallerScreen={isSmallerScreen}
              >
                <ProposalOverview
                  onSubmit={
                    isReadOnly
                      ? () => {}
                      : saveProposalOverviewData(isSubmitting)
                  }
                  initialValues={{
                    ...omit(toJS(proposalData), ['coreTeam', 'additionalTeam']),
                    proposalCurrency,
                  }}
                  setSubmit={handleSubmit => {
                    overviewSubmit = handleSubmit;
                  }}
                  isReadOnly={isReadOnly || disableForms}
                  mainNameQA={mainNameQA}
                  isSmallerScreen={isSmallerScreen}
                />
              </ProposalsAccordionItemBody>
            );
          }}
        </AccordionItem>
      </Accordion>
      <SubmitProposalConfirmationModal
        isOpen={isOpenSubmitConfirmation}
        onClose={onCloseSubmitConfirmation}
        isLoading={isSubmitting}
        onSubmit={createProposal}
      />

      <ProposalFooter
        isLoadingStats={costBreakdown.isLoading}
        currency={proposalCurrency}
        isResubmit={proposalData?.submitted}
        isSmallerScreen={isSmallerScreen}
        infoTooltipLabel={
          toNumber(costBreakdown.data.proposalMinGrandTotal) > 0 &&
          toNumber(costBreakdown.data.proposalMaxGrandTotal) > 0 &&
          `Range: ${formatCurrency(
            costBreakdown.data.proposalMinGrandTotal,
            proposalCurrency,
          )} - ${formatCurrency(
            costBreakdown.data.proposalMaxGrandTotal,
            proposalCurrency,
          )}`
        }
        hours={roundTheseHours(costBreakdown.data.proposalGrandTotalHours || 0)}
        cost={formatNumber(costBreakdown.data.proposalCost || 0)}
        totalCost={formatNumber(costBreakdown.data.proposalGrandTotal || 0)}
        contingency={formatNumber(
          costBreakdown.data.finalContingencyValue || 0,
        )}
        onSubmit={onOpenSubmitConfirmation}
        isSubmitting={brief.isCreatingProposal}
        isDisabled={brief.isClosed || isPendingRequest}
        mainNameQA={mainNameQA}
        showButton={!isReadOnly && !disableForms}
        status={proposalData.status}
        isTeamAdmin={isTeamAdmin}
      />
      <DiscardBriefModal
        isOpen={isDiscardOpen}
        isProposal
        onClose={onDiscardClose}
        onDiscardBrief={() => {
          history.push(APP_ROUTES.briefs);
        }}
      />
      <SuccessModal
        isOpen={isSuccessOpen}
        onClose={() => history.push(APP_ROUTES.briefById(brief.id))}
      />
      <ProposalIncompleteModal />
      <RateConfirmationModal
        isOpen={isRateConfirmationOpen}
        onClose={onRateConfirmationClose}
        onConfirm={createProposal}
        isLoading={isSubmitting}
      />
    </>
  );
};

export default inject(
  'proposalsStore',
  'briefsStore',
  'usersStore',
  'toastsStore',
  'ratesStore',
)(observer(Proposal));
