import { Box, Flex } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';

const BriefDeclineReasonModal = ({ isOpen, onClose, reason }) => {
  const isSmallerScreen = useMediaQuery(breakpoint.lg);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styleProps={
        isSmallerScreen && { p: '24px 16px 48px', margin: 'auto 0px 0px' }
      }
    >
      <Flex flexDir="column" alignItems="center">
        <Typography variant="h2" mb="24px">
          Declining Reason
        </Typography>
        <Box
          borderRadius="8px"
          p="16px"
          border="1px solid"
          borderColor="dark.mid"
          width="100%"
          mb="40px"
        >
          {reason}
        </Box>
        <Button variant="outline" onClick={onClose} minWidth="236px">
          Close
        </Button>
      </Flex>
    </Modal>
  );
};

export default BriefDeclineReasonModal;
