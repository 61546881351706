import { Box } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { isEmpty, map, times } from 'lodash-es';
import React from 'react';
import { Facebook as UserLoader } from 'react-content-loader';
import UserCard from './UserCard';
import UserCardContent from './UserCardContent';

const UsersList = ({
  users,
  isLoading,
  setUserActivity,
  setUserAccessibility,
}) => {
  if (isLoading) {
    return map(times(8), (_, idx) => (
      <Box mb="16px" w="100%" key={`loader-${idx}`} maxW="720px">
        <UserLoader height="240px" width="100%" />
      </Box>
    ));
  }
  if (isEmpty(users)) {
    return (
      <Typography variant="h2" my="40px">
        User list is empty.
      </Typography>
    );
  }
  return map(users, user => (
    <UserCard userId={user.id} key={`user-card-${user.id}`}>
      <UserCardContent
        user={user}
        isStatic
        setUserActivity={setUserActivity}
        setUserAccessibility={setUserAccessibility}
      />
    </UserCard>
  ));
};

export default UsersList;
