import { IS_DEV, IS_PREPROD, IS_PROD, IS_STAGING } from '@app/constants';
import CreateButton from '@components/CreateButton';
import Popover, { PopoverList } from '@components/Popover';
import { Box, Flex, Image, useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled';
import useMediaQuery from '@hooks/useMediaQuery';
import { APP_ROUTES } from '@routes';
import { FONTS } from '@styles/theme';
import Typography from '@styles/Typography';
import { includes, some, startsWith } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Icon from '../Icon';

const getEnvTitle = () => {
  if (IS_DEV) {
    return 'LOCAL';
  }
  if (IS_PREPROD) {
    return 'PREPROD';
  }
  if (IS_STAGING) {
    return 'STAGING';
  }
  return 'ERROR';
};

const Wrapper = styled(Box)`
  position: fixed;
  width: 92px;
  height: 100vh;
  @media (max-width: 1024px) {
    bottom: 0;
    left: 0;
    height: 88px;
    width: 100%;
  }
`;

const navStyle = `
  color: white;
  width: 84px;
  height: 60px;
  font-size: 11px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 12px;
  padding-bottom: 8px;
  margin-left: 4px;
  margin-right: 4px;
  line-height: 16px;
  font-family: ${FONTS.WS};
  &.mt-auto {
    margin-top: auto;
  }
  &.active {
    background-color: #394569;
  }
  :hover {
    text-decoration: none;
    background: rgba(255, 255, 255, 0.05);
  }
  @media (max-width: 1024px) {
    margin: 0;
    width: 64px;
  }
`;

const NavRouterItem = styled(NavLink)`
  ${navStyle};
`;

const navItemsStyles = isSmallerScreen => ({
  p: isSmallerScreen ? '8px 16px' : '35px 0 0',
  h: isSmallerScreen ? '100%' : `calc(100vh - ${IS_PROD ? '92px' : '130px'})`,
  direction: isSmallerScreen ? 'row' : 'column',
  justify: isSmallerScreen ? 'space-between' : 'flex-start',
});

const isMyProfileRouteActive = (match, location) => {
  const isMyProfilePage = /^\/profile$/.test(location.pathname);
  return match?.isExact || isMyProfilePage;
};

const isClientsRouteActive = (match, location) => {
  if (includes(location.pathname, '/projects')) {
    return false;
  }
  const isClientPage = /\/client\/\d+/.test(location.pathname);
  const isClientUserEdit = /\/individual-client\/\d+/.test(location.pathname);
  if (
    isClientPage ||
    isClientUserEdit ||
    includes(['/individuals'], location.pathname)
  ) {
    return true;
  }
  if (includes(['/projects/', '/individuals'], location.pathname)) {
    return false;
  }
  return match;
};

const Sidebar = ({
  usersStore: { isTeamMember, isAdminOrDL, isTeamAdmin, isClient, profile },
  tasksStore: { tasksCount },
  appStore: {
    featureFlags: {
      'companies-tab-in-sidebar': companiesTabInSidebarFeatureFlag,
      'my-profile-in-sidebar': myProfileTabInSidebarFeatureFlag,
      'uo-42-view-partner-list': viewPartnerListFeatureFlag,
      'uo-48-update-sidebar': updateSidebarUserOrchestration48,
      'deazy-team-listing-and-profiles': deazyTeamListingAndProfiles,
    },
  },
  onImportProjectModalOpen,
  onCreateUserModalOpen,
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');
  const {
    onOpen: onMorePopupOpen,
    onClose: onMorePopupClose,
    isOpen: isMorePopupOpen,
  } = useDisclosure();
  const {
    onOpen: onCreatePopupOpen,
    onClose: onCreatePopupClose,
    isOpen: isCreatePopupOpen,
  } = useDisclosure();
  const iconProps = {
    w: '21px',
    h: '21px',
    mb: '5px',
  };

  const isDeazyTeamRouteActive = (match, location) => {
    const isDeazyTeamProfile = /\/deazy-team\/\d+/.test(location.pathname);
    const conditionOne =
      deazyTeamListingAndProfiles && (match?.isExact || isDeazyTeamProfile);
    const conditionTwo =
      !deazyTeamListingAndProfiles &&
      (match?.isExact ||
        includes(
          [
            '/admins',
            '/deazy-leads',
            '/team-members',
            '/team-admins',
            '/client-leads',
            '/users',
          ],
          location.pathname,
        ));

    return conditionOne || conditionTwo;
  };

  const popoverOptions = [
    {
      label: 'Resources',
      visible: isTeamAdmin || isAdminOrDL || isTeamMember,
      as: NavLink,
      to: APP_ROUTES.resources,
      icon: 'resources',
      iconProps,
    },
    {
      label: 'My Company',
      visible: isTeamAdmin || isTeamMember,
      as: NavLink,
      to: isTeamAdmin
        ? APP_ROUTES.teams.supplierById(profile?.supplier?.id)
        : APP_ROUTES.partnerById(profile?.supplier?.id),
      icon: 'briefcase',
      iconProps: {
        fontSize: '24px',
        ml: '-2.5px',
      },
    },
    {
      label: 'Team Members',
      visible: isTeamAdmin && updateSidebarUserOrchestration48,
      as: NavLink,
      to: APP_ROUTES.users.teamMembers,
      icon: 'teams',
      iconProps: {
        fontSize: '20px',
        mr: '10px',
      },
      isActive: (match, location) => {
        if (includes(location.pathname, '/users/')) {
          return true;
        }
        return match;
      },
    },
    {
      label: 'My Profile',
      visible: isTeamMember && myProfileTabInSidebarFeatureFlag,
      as: NavLink,
      to: APP_ROUTES.myProfile,
      icon: 'users',
      iconProps: {
        fontSize: '20px',
        mr: '10px',
      },
    },
    {
      label: 'My Profile',
      visible:
        isTeamAdmin &&
        myProfileTabInSidebarFeatureFlag &&
        updateSidebarUserOrchestration48,
      as: NavLink,
      to: APP_ROUTES.myProfile,
      icon: 'users',
      iconProps: {
        fontSize: '20px',
        mr: '10px',
      },
    },
    {
      label: 'Teams',
      visible: isAdminOrDL && !updateSidebarUserOrchestration48,
      as: NavLink,
      to: APP_ROUTES.teams.clients,
      icon: 'teams',
      iconProps,
      isActive: (match, location) => {
        if (includes(location.pathname, 'projects')) {
          return false;
        }
        if (
          startsWith(location.pathname, '/delivery-partners') ||
          startsWith(location.pathname, '/clients')
        ) {
          return true;
        }
        return match;
      },
    },
    {
      label: 'Users',
      visible: isAdminOrDL && !updateSidebarUserOrchestration48,
      as: NavLink,
      to: APP_ROUTES.users.all,
      icon: 'users',
      iconProps,
      isActive: (match, location) => {
        if (match) {
          return true;
        }
        if (
          includes(
            [
              '/admins',
              '/client-leads',
              '/team-members',
              '/team-admins',
              '/deazy-leads',
            ],
            location.pathname,
          )
        ) {
          return true;
        }
        return false;
      },
    },
    {
      label: 'Clients',
      visible: isAdminOrDL && updateSidebarUserOrchestration48,
      as: NavLink,
      to: APP_ROUTES.teams.clients,
      isActive: isClientsRouteActive,
      icon: 'building',
      iconProps: {
        fontSize: '24px',
      },
    },
    {
      label: 'Partners',
      visible: isAdminOrDL,
      as: NavLink,
      to: APP_ROUTES.partners,
      icon: 'companies',
      iconProps,
    },
    {
      label: 'Profiles',
      visible: isAdminOrDL && !viewPartnerListFeatureFlag,
      as: NavLink,
      to: APP_ROUTES.profiles,
      icon: 'profiles',
      iconProps,
    },
    {
      label: 'Deazy Team',
      visible: isAdminOrDL && updateSidebarUserOrchestration48,
      as: NavLink,
      to: deazyTeamListingAndProfiles
        ? APP_ROUTES.deazyTeam
        : APP_ROUTES.users.admins,
      isActive: isDeazyTeamRouteActive,
      icon: 'group',
      iconProps,
    },
    {
      label: 'My Profile',
      visible: isAdminOrDL && updateSidebarUserOrchestration48,
      as: NavLink,
      to: APP_ROUTES.editMyProfile,
      isActive: isMyProfileRouteActive,
      icon: 'users',
      iconProps,
    },
    {
      label: 'My Company',
      visible: isClient,
      as: NavLink,
      to: APP_ROUTES.teams.clientById(profile?.customer?.id),
      icon: 'building',
      iconProps: {
        fontSize: '24px',
      },
    },
    {
      label: 'My Profile',
      visible: isClient && updateSidebarUserOrchestration48,
      as: NavLink,
      to: APP_ROUTES.editMyProfile,
      isActive: isMyProfileRouteActive,
      icon: 'users',
      iconProps,
    },
    {
      label: 'Settings',
      visible: isAdminOrDL,
      as: NavLink,
      to: APP_ROUTES.settings,
      icon: 'settings',
      iconProps,
    },
  ];

  return (
    <>
      <Wrapper bg="primary.600" zIndex={300}>
        {!isSmallerScreen && (
          <>
            <Flex mt="32px" justify="center">
              <Image
                ignoreFallback
                w="32px"
                src="/images/deazy-logo.svg"
                alt="Deazy"
              />
            </Flex>
            {(IS_STAGING || IS_DEV || IS_PREPROD) && (
              <Box
                bg="purple.solid"
                borderRadius="8px"
                px="8px"
                py="4px"
                mt="16px"
                mx="12px"
                paddingInline="0"
              >
                <Typography
                  color="gray.100"
                  textAlign="center"
                  variant="caption"
                >
                  {getEnvTitle()}
                </Typography>
              </Box>
            )}
          </>
        )}
        <Flex {...navItemsStyles(isSmallerScreen)}>
          {isAdminOrDL && (
            <NavRouterItem to={APP_ROUTES.tasks}>
              {tasksCount > 0 && (
                <Flex
                  minW="16px"
                  h="16px"
                  position="absolute"
                  top="3px"
                  left="43px"
                  bg="red.solid"
                  color="#fff"
                  borderRadius="4px"
                  fontFamily="WB"
                  alignItems="center"
                  justifyContent="center"
                  fontSize={12}
                  px="3px"
                >
                  {tasksCount > 99 ? '99+' : tasksCount}
                </Flex>
              )}
              <Icon name="tasks" mb="7px" width="18px" height="20px" />
              Tasks
            </NavRouterItem>
          )}
          {!isTeamMember && (
            <NavRouterItem
              to={APP_ROUTES.briefs}
              isActive={(match, location) => {
                if (includes(location.pathname, 'briefs')) {
                  return true;
                }
                return match;
              }}
            >
              <Icon name="briefs" mb="7px" width="18px" height="20px" />
              Briefs
            </NavRouterItem>
          )}

          {isSmallerScreen && (
            <CreateButton
              onImport={onImportProjectModalOpen}
              onCreateUser={onCreateUserModalOpen}
              buttonProps={{
                isActive: isCreatePopupOpen,
              }}
              popoverProps={{
                withOverlay: true,
                offset: [0, 22],
                variant: 'responsive',
                onOpen: onCreatePopupOpen,
                onClose: onCreatePopupClose,
                isOpen: isCreatePopupOpen,
              }}
            />
          )}

          <NavRouterItem
            to={APP_ROUTES.projects}
            isActive={(match, location) => {
              if (match) {
                return true;
              }
              if (
                includes(location.pathname, '/clients/') &&
                includes(location.pathname, '/projects/')
              ) {
                return true;
              }
              return false;
            }}
          >
            <Icon name="projects" width="14px" height="20px" mb="6px" />
            Projects
          </NavRouterItem>

          {!isSmallerScreen && (
            <>
              {(isTeamAdmin || isAdminOrDL || isTeamMember) && (
                <NavRouterItem to={APP_ROUTES.resources}>
                  <Icon name="resources" width="20px" height="16px" mb="8px" />
                  Resources
                </NavRouterItem>
              )}

              {isClient && (
                <>
                  <NavRouterItem
                    to={APP_ROUTES.teams.clientById(profile?.customer?.id)}
                  >
                    <Icon name="building" fontSize={24} mb="4px" />
                    My Company
                  </NavRouterItem>
                  <NavRouterItem
                    to={APP_ROUTES.editMyProfile}
                    isActive={isMyProfileRouteActive}
                  >
                    <Icon name="users" fontSize={16} mb="8px" />
                    My Profile
                  </NavRouterItem>
                </>
              )}

              {(isTeamAdmin || isTeamMember) && (
                <NavRouterItem
                  to={
                    isTeamAdmin
                      ? APP_ROUTES.teams.supplierById(profile?.supplier?.id)
                      : APP_ROUTES.partnerById(profile?.supplier?.id)
                  }
                >
                  <Icon name="briefcase" width="25px" height="25px" mb="4px" />
                  My Company
                </NavRouterItem>
              )}

              {isTeamAdmin && updateSidebarUserOrchestration48 && (
                <NavRouterItem
                  to={APP_ROUTES.users.teamMembers}
                  isActive={(match, location) => {
                    if (includes(location.pathname, '/users/')) {
                      return true;
                    }
                    return match;
                  }}
                >
                  <Icon name="teams" fontSize={16} mb="8px" />
                  Team Members
                </NavRouterItem>
              )}

              {isTeamAdmin && updateSidebarUserOrchestration48 && (
                <NavRouterItem to={APP_ROUTES.myProfile}>
                  <Icon name="users" fontSize={16} mb="8px" />
                  My Profile
                </NavRouterItem>
              )}

              {isTeamMember && myProfileTabInSidebarFeatureFlag && (
                <NavRouterItem to={APP_ROUTES.myProfile}>
                  <Icon name="users" fontSize={16} mb="8px" />
                  My Profile
                </NavRouterItem>
              )}

              {isAdminOrDL && (
                <>
                  {!updateSidebarUserOrchestration48 && (
                    <>
                      <NavRouterItem
                        to={APP_ROUTES.teams.clients}
                        isActive={(match, location) => {
                          if (includes(location.pathname, 'projects')) {
                            return false;
                          }
                          if (
                            startsWith(
                              location.pathname,
                              '/delivery-partners',
                            ) ||
                            startsWith(location.pathname, '/clients')
                          ) {
                            return true;
                          }
                          return match;
                        }}
                      >
                        <Icon
                          name="teams"
                          width="20px"
                          height="14px"
                          mb="9px"
                        />
                        Teams
                      </NavRouterItem>
                      <NavRouterItem
                        to={APP_ROUTES.users.all}
                        isActive={(match, location) => {
                          if (match) {
                            return true;
                          }
                          if (
                            includes(
                              [
                                '/admins',
                                '/client-leads',
                                '/team-members',
                                '/team-admins',
                                '/deazy-leads',
                              ],
                              location.pathname,
                            )
                          ) {
                            return true;
                          }
                          return false;
                        }}
                      >
                        <Icon name="users" fontSize={16} mb="8px" />
                        Users
                      </NavRouterItem>
                    </>
                  )}
                  {updateSidebarUserOrchestration48 && (
                    <NavRouterItem
                      to={APP_ROUTES.teams.clients}
                      isActive={isClientsRouteActive}
                    >
                      <Icon name="building" fontSize={24} mb="4px" />
                      Clients
                    </NavRouterItem>
                  )}
                  {companiesTabInSidebarFeatureFlag && (
                    <NavRouterItem
                      to={APP_ROUTES.partners}
                      isActive={(match, location) => {
                        const isPartnerPage = /\/partner\/\d+/.test(
                          location.pathname,
                        );
                        const isProfilePage = /\/profile\/\d+/.test(
                          location.pathname,
                        );

                        if (match?.isExact) return true;
                        if (isPartnerPage || isProfilePage) return true;

                        if (
                          includes(
                            ['/profiles', '/partners'],
                            location.pathname,
                          ) &&
                          viewPartnerListFeatureFlag
                        ) {
                          return true;
                        }
                        return false;
                      }}
                    >
                      <Icon name="briefcase" fontSize={24} mb="4px" />
                      Partners
                    </NavRouterItem>
                  )}
                  {!viewPartnerListFeatureFlag && (
                    <NavRouterItem to={APP_ROUTES.profiles}>
                      <Icon name="profiles" fontSize={16} mb="8px" />
                      Profiles
                    </NavRouterItem>
                  )}
                  {updateSidebarUserOrchestration48 && (
                    <>
                      <NavRouterItem
                        to={
                          deazyTeamListingAndProfiles
                            ? APP_ROUTES.deazyTeam
                            : APP_ROUTES.users.admins
                        }
                        isActive={isDeazyTeamRouteActive}
                      >
                        <Icon name="group" fontSize={16} mb="8px" />
                        Deazy Team
                      </NavRouterItem>
                      <NavRouterItem
                        to={APP_ROUTES.editMyProfile}
                        isActive={isMyProfileRouteActive}
                      >
                        <Icon name="users" fontSize={16} mb="8px" />
                        My Profile
                      </NavRouterItem>
                    </>
                  )}
                </>
              )}
              {isAdminOrDL && (
                <NavRouterItem className="mt-auto" to={APP_ROUTES.settings}>
                  <Icon name="settings" fontSize={20} mb="6px" />
                  Settings
                </NavRouterItem>
              )}
            </>
          )}
          {isSmallerScreen && some(popoverOptions, 'visible') && (
            <Popover
              variant="responsive"
              withOverlay
              offset={[0, 22]}
              onOpen={onMorePopupOpen}
              onClose={onMorePopupClose}
              isOpen={isMorePopupOpen}
              trigger={
                <Box css={navStyle}>
                  <Icon
                    name="dotsVertical"
                    width="20px"
                    height="14px"
                    mb="9px"
                    transform="rotate(90deg)"
                  />
                  More
                </Box>
              }
            >
              <PopoverList options={popoverOptions} />
            </Popover>
          )}
        </Flex>
      </Wrapper>
    </>
  );
};

export default inject(
  'usersStore',
  'tasksStore',
  'appStore',
)(observer(Sidebar));
