import MockAdapter from 'axios-mock-adapter';
// import moment from 'moment';

// function route(path = '') {
//   return typeof path === 'string'
//     ? new RegExp(path.replace(/:\w+/g, '[^/]+'))
//     : path;
// }
const MOCKED_DP = {
  createdAt: '2021-09-15T13:53:38.856Z',
  updatedAt: '2022-10-06T14:20:54.000Z',
  uuidVersion: '',
  id: 44,
  name: 'Yojji',
  invoiceName: 'test_supplier2_deer',
  address: 'address\naddress',
  location: 'EU',
  country: 'Ukraine',
  size: 'Medium (26-75)',
  status: 'Trusted',
  mondayCurrency: 'USD',
  paymentInfo: 'bank account\n123 456',
  financeEmails: ['deazy-test-01@goodylabs.com'],
  vatNumber: '222',
  logo: '',
  logoLocal: 'e9cc2524-be43-44bd-b448-f13ea52c1e5e.jpeg',
  accountsFinalisationDay: 1,
  invoiceNumberingPattern: '[ST]/#NO#/MM/YYYY',
  technologies: [
    'Javascript',
    'PostgreSQL',
    'Typescript',
    'CSS',
    'HTML5',
    'MongoDB',
    'MySQL',
    'React',
    'Node.js',
    'ExpressJS',
    'Vue.js',
    'Tailwind CSS',
    'Angular',
    'Flutter',
    'React Native',
    'Google Cloud Platform',
    'Azure',
    'AWS',
    'Firebase',
    'Jenkins',
    'Docker',
    'Kubernetes',
  ],
  matchedTechnicalSkills: [
    'Typescript',
    'CSS',
    'HTML5',
    'MongoDB',
    'MySQL',
    'React',
    'Node.js',
  ],
  flags: [
    'No gambling projects',
    'Discuss PM & QA requirements clearly upfront',
  ],
};

export default api => {
  const apiMock = new MockAdapter(api, { delayResponse: 500 });

  apiMock
    .onPost('/matching-questionnaire')
    .reply(config => {
      const data = JSON.parse(config.data);
      return [
        200,
        {
          ...data,
        },
      ];
    })
    .onGet('/matched-delivery-partners')
    .reply(200, {
      best: [MOCKED_DP, { ...MOCKED_DP, id: 55, name: 'Cool DP' }],
      new: [{ ...MOCKED_DP, id: 33, name: 'Not cool DPPP' }],
      partial: [],
    })
    // apiMock
    //   .onGet('/tasks')
    //   .reply(200, [
    //     {
    //       id: 1,
    //       title: 'Lorem ipsum',
    //       description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
    //           Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    //       link: 'https://google.com',
    //       status: 'open',
    //     },
    //     {
    //       id: 2,
    //       title: 'Lorem ipsum 2',
    //       description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
    //           Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    //       link: 'https://google.com',
    //       status: 'open',
    //     },
    //     {
    //       id: 3,
    //       title: 'Lorem ipsum 3',
    //       description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
    //           Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
    //       link: 'https://google.com',
    //       status: 'open',
    //     },
    //   ])
    // .onPost(route('/briefs/:id/questions'))
    // .reply(config => {
    //   const data = JSON.parse(config.data);
    //   return [
    //     200,
    //     {
    //       id: Math.random(),
    //       ...data,
    //     },
    //   ];
    // })
    // .onGet(route('/briefs/:id/questions'))
    // .reply(200, [
    //   {
    //     id: 1,
    //     name: 'Lorem ipsum',
    //     description:
    //       'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Earum dignissimos temporibus nostrum impedit est ad,
    // voluptates et consequuntur accusamus molestiae molestias ? Aperiam quibusdam, tempora praesentium doloribus expedita earum quam temporibus ? ',
    //     relatedFeature: 'General',
    //     replies: [
    //       {
    //         id: 3,
    //         author: { name: 'deazy' },
    //         description:
    //           'BLABLABLA BLABLABLA BLABLABLABLABLABLABLABLABLA  BLABLABLA',
    //         createdAt: moment
    //           .utc()
    //           .add(-533, 'minutes')
    //           .format(),
    //       },
    //       {
    //         id: 5,
    //         author: { name: 'deazy' },
    //         description: 'helllo LA',
    //         createdAt: moment
    //           .utc()
    //           .add(-335, 'minutes')
    //           .format(),
    //       },
    //     ],
    //     createdAt: moment
    //       .utc()
    //       .add(-10, 'days')
    //       .format(),
    //     author: { name: 'GOOODYLABS' },
    //   },
    //   {
    //     id: 2,
    //     name: 'Test 222222222',
    //     relatedFeature: 'Messaging',
    //     description:
    //       'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Earum dignissimos temporibus nostrum impedit est ad,
    // voluptates et consequuntur accusamus molestiae molestias ? Aperiam quibusdam, tempora praesentium doloribus expedita earum quam temporibus ? ',
    //     createdAt: moment
    //       .utc()
    //       .add(-130, 'days')
    //       .format(),
    //     author: { name: 'GOOODYLABS 2' },
    //   },
    // ])
    // .onPost(route('/briefs/:id/questions/:questionId/reply'))
    // .reply(config => {
    //   const data = JSON.parse(config.data);
    //   return [
    //     200,
    //     {
    //       id: Math.random(),
    //       ...data,
    //       author: { name: 'goody' },
    //     },
    //   ];
    // })
    // .onPost(route('/briefs/:id/notes'))
    // .reply(config => {
    //   const data = JSON.parse(config.data);
    //   return [
    //     200,
    //     {
    //       id: Math.random(),
    //       author: { name: 'Lukas' },
    //       createdAt: moment.utc().toDate(),
    //       ...data,
    //     },
    //   ];
    // })
    .onAny()
    .passThrough();
};
