import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment';
import { formatCurrency } from '@utils';
import React from 'react';
import { Stat } from '@pages/Project/Payments/components';
import { HorizontalStat } from '@pages/Project/ChangeRequests/Single/CRCard';

const CRCard = ({ cr, currency, clientRate }) => (
  <Box w="100%" border="1px solid" borderColor="dark.mid" mb="32px">
    <Flex
      w="100%"
      borderBottom="1px solid"
      borderColor="dark.mid"
      alignItems="stretch"
    >
      <Stat
        bg="dark.solid"
        color="#fff"
        px="8px"
        py="16px"
        borderBottomRightRadius="8px"
        label="Status"
        value={cr.status}
      />
      <Stat
        px="8px"
        py="16px"
        borderRight="none"
        label="No."
        value={`#${cr.number}`}
      />
      <Stat
        px="8px"
        py="16px"
        borderRight="none"
        label="Name"
        value={cr.name}
      />
      <Stat
        px="8px"
        py="16px"
        borderRight="none"
        label="Hours"
        value={formatCurrency(cr.amount)}
      />
      <Stat
        px="8px"
        py="16px"
        borderRight="none"
        label="Client rate"
        value={
          cr.clientDisabled
            ? 'Disabled'
            : formatCurrency(clientRate || cr.clientRate, currency)
        }
      />
    </Flex>
    <Flex>
      <Flex px="24px" my="20px" flexDirection="column">
        <HorizontalStat label="Description" value={cr.description} />
        {cr.purchaseOrder && (
          <HorizontalStat label="Purchase order" value={cr.purchaseOrder} />
        )}
        {cr.dateProcessed && (
          <HorizontalStat
            label="Processed at"
            value={moment(cr.dateProcessed).format('LLLL')}
          />
        )}
      </Flex>
    </Flex>
  </Box>
);

export default CRCard;
