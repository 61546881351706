/* eslint-disable max-len */
export const DEFAULT_SURVEY_OPTIONS = [
  {
    id: 1,
    title: 'STRUCTURED',
    fieldName: 'isStructured',
    description:
      'Do the team demonstrate clear use of methodology (could be waterfall or agile)? Do they have robust processes to manage change, and routinely follow this? Do they have clear and defined roles and responsibilities, and use/deliver thorough documentation?',
  },
  {
    id: 2,
    title: 'FLEXIBLE',
    fieldName: 'isFlexible',
    description:
      'Are the team adaptable to change in a variety of forms including client ways of working, changing team sizes and changing priority order of deliverables? Are they able to keep moving during delivery to maintain speed, despite obstacles such as scope ambiguity, delivering multiple projects or dependencies not being ready?',
  },
  {
    id: 3,
    title: 'INQUISITIVE',
    fieldName: 'isInquisitive',
    description: `Have the team successfully researched the technical implementation of something new that was an unknown at the start of the project?
    
    If there was nothing unknown, put 'N/A'`,
  },
  {
    id: 4,
    title: 'ACCOUNTABLE',
    fieldName: 'isAccountable',
    description:
      'Did the team proactively manage the delivery of this project without needing to be prompted?',
  },
  {
    id: 5,
    title: 'GOOD COMMS',
    fieldName: 'isGoodCommunication',
    description: `Does the team proactively communicate with us without the need to chase or request information?
    
    When dealing with a client directly, are devs confident enough to manage expectations & comfortably describe technical information?
    
    Consider comms across Response to Bugs, Highlighting Risks, Client Responsiveness, Escalation Responses.
    `,
  },
];

export const TA_SURVEY_OPTIONS = [
  {
    id: 1,
    title: 'ACCOUNTABLE',
    fieldName: 'isAccountable',
    description:
      'Are all developers independent and able to problem solve? Do they ask for help when needed, take charge of assignments and avoid hiding behind blockers?',
  },
  {
    id: 2,
    title: 'GOOD COMMS',
    fieldName: 'isGoodCommunication',
    description: `
    Does the team proactively communicate with us without the need to chase or request information?
    When dealing with a client directly, are devs confident enough to manage expectations & comfortably describe technical information?
    Consider comms across Response to Bugs, Highlighting Risks, Client Responsiveness, Escalation Responses.
    `,
  },
];
