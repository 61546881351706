import React from 'react';
import { Box } from '@chakra-ui/react';
import Typography from '@styles/Typography';

const WhiteCard = ({ title, children, ...props }) => {
  return (
    <Box
      w="100%"
      bg="#fff"
      borderRadius="8px"
      mt="25px"
      px="24px"
      py="32px"
      position="relative"
      {...props}
    >
      {title && (
        <Typography variant="h4" mb="24px">
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default WhiteCard;
