import useMediaQuery from '@hooks/useMediaQuery';
import { Flex, Tooltip } from '@chakra-ui/react';
import React from 'react';

export const tabProps = (isActive, isSmallerScreen) => ({
  fontFamily: 'WS',
  fontWeight: isSmallerScreen ? 500 : 600,
  color: isActive ? '#fff' : 'dark.solid',
  bg: isActive ? 'blue.solid' : '#fff',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  minW: isSmallerScreen ? '108px' : '180px',
  cursor: 'pointer',
  p: isSmallerScreen && '10px 8px',
  fontSize: 12,
  letterSpacing: '0.5px',
  border: '2px solid',
  borderColor: 'blue.solid',
  textTransform: 'uppercase',
  _first: {
    borderBottomLeftRadius: '8px',
    borderTopLeftRadius: '8px',
  },
  _last: {
    borderBottomRightRadius: '8px',
    borderTopRightRadius: '8px',
  },
});

const ClientTab = ({
  isBlocked,
  onTabChange,
  activeTab,
  isSmallerScreen,
  ...props
}) => {
  const tab = (
    <Flex
      onClick={() => (isBlocked ? null : onTabChange('client'))}
      {...tabProps(activeTab === 'client', isSmallerScreen)}
      borderTopRightRadius="8px"
      opacity={isBlocked ? 0.4 : 1}
      cursor={isBlocked ? 'default' : 'pointer'}
      borderBottomRightRadius="8px"
      borderLeft="none"
      {...props}
    >
      Client view
    </Flex>
  );
  if (!isBlocked) {
    return tab;
  }
  return undefined;
};

const DeazyTab = ({
  isBlocked,
  onTabChange,
  activeTab,
  isClientTabActive,
  isSmallerScreen,
  ...props
}) => {
  const tab = (
    <Flex
      onClick={() => (isBlocked ? null : onTabChange('deazy'))}
      {...tabProps(activeTab === 'deazy', isSmallerScreen)}
      borderTopRightRadius={isClientTabActive ? '0' : '38px'}
      opacity={isBlocked ? 0.4 : 1}
      cursor={isBlocked ? 'default' : 'pointer'}
      {...props}
    >
      Deazy view
    </Flex>
  );
  if (!isBlocked) {
    return tab;
  }
  return (
    <Tooltip
      zIndex={33000}
      p="12px"
      borderRadius="8px"
      label="You cannot go to deazy tab if proposal status is `With Delivery Partner`"
      hasArrow
      textAlign="center"
      bg="dark.solid"
      px="24px"
      py="12px"
      placement="bottom"
      fontSize={16}
    >
      {tab}
    </Tooltip>
  );
};

const ProposalTabs = ({
  activeTab,
  onTabChange,
  isDeazyTabBlocked,
  isClientTabActive = true,
  showDpTab,
  mainNameQA,
  isPlugAndPlay = false,
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const cellWidth = React.useMemo(() => {
    if (isSmallerScreen && showDpTab) {
      return '33.3%';
    }
    if (isSmallerScreen && !showDpTab) {
      return '50%';
    }
    return 'auto';
  }, [isSmallerScreen, showDpTab]);

  return (
    <Flex
      h="40px"
      borderRadius="8px"
      maxW={isClientTabActive ? '540px' : '360px'}
      {...(isSmallerScreen && { maxW: '100%' })}
      mb={isSmallerScreen && !isPlugAndPlay ? '16px' : '32px'}
    >
      {showDpTab && (
        <Flex
          onClick={() => onTabChange('delivery-partner')}
          {...tabProps(activeTab === 'delivery-partner', isSmallerScreen)}
          borderRight={isClientTabActive ? 'none' : 'inherit'}
          data-test-id={mainNameQA && `${mainNameQA}-dpTab`}
          w={cellWidth}
        >
          Delivery Partner
        </Flex>
      )}
      <DeazyTab
        isBlocked={isDeazyTabBlocked}
        activeTab={activeTab}
        onTabChange={onTabChange}
        isSmallerScreen={isSmallerScreen}
        isClientTabActive={isClientTabActive}
        w={cellWidth}
        data-test-id={mainNameQA && `${mainNameQA}-deazyTab`}
      />
      <ClientTab
        activeTab={activeTab}
        onTabChange={onTabChange}
        isSmallerScreen={isSmallerScreen}
        w={cellWidth}
        data-test-id={mainNameQA && `${mainNameQA}-clientTab`}
      />
    </Flex>
  );
};

export default ProposalTabs;
