import React from 'react';
import { Flex } from '@chakra-ui/react';
import moment from 'moment';
import Icon from '@components/Icon';
import Button from '@styles/Button';
import { Field, Form } from 'react-final-form';
import WhiteCard from '@components/WhiteCard';
import DatePicker from '@components/forms/DatePicker';
import Typography from '@styles/Typography';

const CheckInvoiceNumbering = ({
  checkInvoiceNumberingForSuppliers,
  supplierId,
  onOpen,
}) => {
  const onSubmit = values => {
    checkInvoiceNumberingForSuppliers({
      supplierId,
      date: values.date,
      onSuccess: onOpen,
    });
  };
  return (
    <WhiteCard p="40px">
      <Typography mb="24px" variant="h3">
        Check Invoice Numbering
      </Typography>
      <Form
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        initialValues={{ date: moment() }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Flex
                justifyContent="space-between"
                mb="32px"
                alignItems="flex-end"
              >
                <Field
                  label="Check date"
                  name="date"
                  component={DatePicker}
                  calendarColor="dark.solid"
                  minW="10px"
                  isErrorAbsolute
                  containerProps={{ w: '45%' }}
                />
                <Flex>
                  <Button
                    rightIcon={
                      <Icon name="arrowLongTail" fontSize={13} ml="4px" />
                    }
                    isLoading={submitting}
                    type="submit"
                  >
                    Check numbering
                  </Button>
                </Flex>
              </Flex>
            </form>
          );
        }}
      />
    </WhiteCard>
  );
};

export default CheckInvoiceNumbering;
