import { Button, Flex, Image } from '@chakra-ui/react';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { inject, observer } from 'mobx-react';
import React from 'react';

const LoadingPage = ({ usersStore: { appLoadFailed, fetchAllData } }) => {
  return (
    <Flex minW="100vw" minH="100vh" justifyContent="center">
      <Flex
        maxW="880px"
        px="40px"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        mb="15%"
      >
        <Image
          w="342px"
          src="/images/deazy-wordmark-black.svg"
          alt="DEAZY"
          ignoreFallback
        />
        {!appLoadFailed && (
          <Spinner
            mt="42px"
            thickness="6px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#36F"
            variant="large"
          />
        )}
        {appLoadFailed && (
          <>
            <Typography
              mt="40px"
              variant="h2"
              borderLeft="3px solid"
              borderColor="red.solid"
              pl="20px"
            >
              An error occurred while starting the application
            </Typography>
            <Button
              bg="blue.solid"
              onClick={fetchAllData}
              fontWeight="400"
              color="#fff"
              borderRadius="30px"
              h="56px"
              minW="260px"
              alignSelf="center"
              _hover={{
                bg: '#000',
                transition: 'all 0.7s',
              }}
              fontSize={18}
              mt="40px"
            >
              Reload
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default inject('usersStore')(observer(LoadingPage));
