import React from 'react';
import WhiteCard from '@components/WhiteCard';
import Select from '@components/forms/Select';
import Checkbox from '@components/forms/_common/Checkbox';
import moment from 'moment';
import Button from '@styles/Button';
import { Box, Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { IS_PROD, MAIN_NAMES_QA, ASSIGNMENT_STATUSES } from '@app/constants';
import { Field } from 'react-final-form';
import { map } from 'lodash-es';
import { DpIcon } from './ShortlistedSupplierRow';
import AssignmentStatusBadge from './AssignmentStatusBadge';
import AssignmentActionButtons from './AssignmentActionButtons';

const Stat = ({ label, value, bottomTextProps, ...props }) => (
  <Box w="50%" {...props}>
    <Typography variant="text2" mb="4px" fontSize={9}>
      {label}
    </Typography>
    <Typography variant="text2" fontSize={13} {...bottomTextProps}>
      {value || '-'}
    </Typography>
  </Box>
);

const MobileAssignmentCard = ({
  formApi,
  assignment,
  isExclusivitySelected,
  onDelete,
  onAssign,
  onRemind,
  fieldPrefix,
  shouldShowExclusiveCheckbox,
  isAnyAssignmentExclusive,
}) => {
  const [isReminding, setIsReminding] = React.useState();
  const cardBg = React.useMemo(() => {
    if (assignment.status === ASSIGNMENT_STATUSES.AWAITING_RESPONSE) {
      return 'orange.light';
    }
    if (assignment.status === ASSIGNMENT_STATUSES.NOT_INTERESTED) {
      return 'red.light';
    }
    if (assignment.status === ASSIGNMENT_STATUSES.SHOWN_INTEREST) {
      return 'green.light';
    }
    if (
      assignment.status === ASSIGNMENT_STATUSES.PROPOSAL_SHARED ||
      assignment.status === ASSIGNMENT_STATUSES.CANDIDATES_SHARED
    ) {
      return 'aqua.light';
    }
    return '#F9F9F9';
  }, [assignment.status]);

  return (
    <WhiteCard bg={cardBg} p="12px" w="auto" mb="20px">
      <Flex mb="12px" alignItems="center">
        <AssignmentStatusBadge assignment={assignment} mr="auto" />
        {assignment.status !== ASSIGNMENT_STATUSES.SCHEDULED &&
          assignment.status !== ASSIGNMENT_STATUSES.PROPOSAL_SHARED &&
          assignment.status !== ASSIGNMENT_STATUSES.CANDIDATES_SHARED &&
          assignment.status !== ASSIGNMENT_STATUSES.CANCELLED &&
          assignment.status !== ASSIGNMENT_STATUSES.ASSIGNMENT_PENDING && (
            <Button
              isLoading={isReminding}
              iconButton={{
                name: 'clock3',
                color: 'blue.solid',
                fontSize: 22,
              }}
              mr="8px"
              p={0}
              variant="ghost"
              onClick={async () => {
                setIsReminding(true);
                await onRemind();
                setIsReminding(false);
              }}
            />
          )}
        <Button
          p={0}
          iconButton={{
            name: 'trash3',
            color: 'gray.mid',
            fontSize: 24,
          }}
          isDisabled={assignment.status === ASSIGNMENT_STATUSES.EXCLUSIVE}
          variant="ghost"
          onClick={onDelete}
        />
      </Flex>

      <Flex>
        <DpIcon status={assignment.status} />
        <Typography variant="h4">{assignment?.supplier?.name}</Typography>
      </Flex>

      {!shouldShowExclusiveCheckbox(assignment) ? (
        <Flex w="100%" my="16px">
          {assignment.assignedAt && (
            <Stat
              label="Assignment date"
              value={moment(assignment.assignedAt).format('DD MMM YYYY')}
            />
          )}
          {assignment.status === 'Cancelled' && (
            <Stat
              label="Assignment scheduled for"
              value="Brief no longer will be shared"
              bottomTextProps={{ color: 'dark.mid' }}
            />
          )}
          {assignment.dueDate && (
            <Stat
              label="Assignment scheduled for"
              w="100%"
              bottomTextProps={{ color: 'dark.mid' }}
              value={moment(assignment.dueDate).format(
                '[Due] DD MMM YYYY @ HH:mmA',
              )}
            />
          )}
          {assignment.lastReminderDate && !assignment.dueDate && (
            <Stat
              label="Last reminder date"
              value={moment(assignment.lastReminderDate).format('DD MMM YYYY')}
            />
          )}
        </Flex>
      ) : (
        <Flex my="16px">
          <Field
            name={`${fieldPrefix}.exclusivityEnabled`}
            variant="secondary"
            isDisabled={false}
            component={Checkbox}
            id={`exclusivityEnabled${fieldPrefix}`}
            label="Give exclusivity for"
            containerStyles={{
              mb: 0,
              mr: '20px',
              display: 'flex',
              alignItems: 'center',
            }}
            onAfterChange={e => {
              const { values } = formApi.getState();
              formApi.change(
                'assignments',
                map(values.assignments, a => ({
                  ...a,
                  exclusivityEnabled:
                    a.id === assignment.id ? e.target.checked : false,
                })),
              );
            }}
          />
          <Field
            name={`${fieldPrefix}.exclusivityDuration`}
            isDisabled={!assignment.exclusivityEnabled}
            options={[
              ...(!IS_PROD
                ? [
                    { label: '3 mins', value: 0.05 },
                    { label: '15 mins', value: 0.25 },
                    { label: '1 hours', value: 1 },
                  ]
                : []),
              { label: '2 hours', value: 2 },
              { label: '4 hours', value: 4 },
              { label: '8 hours', value: 8 },
              { label: '12 hours', value: 12 },
              { label: '24 hours', value: 24 },
            ]}
            component={Select}
            styleProps={{ maxW: '160px' }}
          />
        </Flex>
      )}

      <AssignmentActionButtons
        assignment={assignment}
        isExclusivitySelected={isExclusivitySelected}
        isAnyAssignmentExclusive={isAnyAssignmentExclusive}
        onAssign={onAssign}
        onRemind={onRemind}
        mainNameQA={MAIN_NAMES_QA.BRIEF_ASSIGNMENT}
        isMobile
      />
    </WhiteCard>
  );
};

export default MobileAssignmentCard;
