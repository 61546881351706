import { API } from '@app/api';
import { Flex, Stack } from '@chakra-ui/react';
import DescriptionWithIconBox from '@components/DescriptionWithIconBox';
import DatePicker from '@components/forms/DatePicker';
import ErrorSpy from '@components/forms/ErrorSpy';
import RichTextEditorInput from '@components/forms/RichTextEditorInput';
import AsyncAutocomplete from '@components/forms/_common/AsyncAutocomplete';
import FormError from '@components/forms/_common/FormError';
import Select from '@components/forms/_common/Select';
import { StickyFooter } from '@components/StickyFooterCostBox';
import useMediaQuery from '@hooks/useMediaQuery';
import useQuery from '@hooks/useQuery';
import Dropzone from '@pages/BriefsCreate/BasicsStep/Dropzone';
import { breakpoint } from '@styles/breakpoints';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { sleep } from '@utils';
import {
  composeValidators,
  mustBeAfter,
  mustBeShorterOrEqualForHTML,
  mustBeShorterOrEqual,
  validateBriefName,
  required,
} from '@utils/formValidators';
import { find, forEach, get, isEmpty, map, omit, reject } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Field, Form } from 'react-final-form';
import ProjectNameInput from '../BriefsCreate/BasicsStep/__LegacyProjectNameInput';
import ClientCreateModal from '../UserCreate/ClientCreateModal';
import ClientLeadCreateModal from '../UserCreate/ClientLeadCreateModal';
import ResourceTable from './ResourceTable';
import { Content } from '../BriefsCreate';

const disabledDaysFn = day =>
  moment.utc(day).isBefore(moment.utc().startOf('day'));

export const validate = ({ resources, files }) => {
  const errors = {};
  forEach(files, ({ file }, idx) => {
    if (file?.size / 1024 / 1024 > 10) {
      if (!errors?.files) {
        errors.files = [];
      }
      errors.files[idx] = 'The file size cannot exceed 10MB.';
    }
  });
  const filteredResources = reject(resources, r =>
    isEmpty(omit(r, ['id', 'numberOfMonths'])),
  );
  if (isEmpty(filteredResources)) {
    errors.resource = 'This section is required';
  }
  if (
    find(
      filteredResources,
      r => !r.technology || !r.seniority || !r.startDate || !r.numberOfMonths,
    )
  ) {
    errors.resource =
      'Some rows are partially finished. Please fulfill them or remove.';
  }
  return errors;
};

const titleStyles = (isSmallerScreen, withSubTitle) => {
  const mb = withSubTitle ? 4 : 6;

  return isSmallerScreen
    ? {
        mt: 6,
        mb: mb / 2,
      }
    : {
        mt: 14,
        mb,
      };
};

const cardStyles = isSmallerScreen => ({
  h: 'auto',
  maxW: '276px',
  flexDir: 'row',
  ...(isSmallerScreen && {
    maxW: '100%',
    align: 'center',
  }),
});

const CreateForm = ({
  teamsStore: { createClient, clearNewClient },
  usersStore: { createUserByRole },
  initialValues,
  onSubmit,
  customerId,
  isProfileLoaded,
  briefId,
  mainNameQA,
}) => {
  const [clientIntialValues, setClientInitialValues] = React.useState({});
  const [clientLeadIntialValues, setClientLeadInitialValues] = React.useState(
    {},
  );
  const [customer, setCustomer] = React.useState(initialValues.customer || {});
  const [clientLead, setClientLead] = React.useState(
    initialValues.clientLead || {},
  );
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  const {
    onCreateClientModalOpen,
    onCreateClientModalClose,
    isCreateClientModalOpen,
  } = React.useMemo(() => {
    return {
      isCreateClientModalOpen: !isEmpty(clientIntialValues),
      onCreateClientModalOpen: setClientInitialValues,
      onCreateClientModalClose: () => setClientInitialValues({}),
    };
  }, [JSON.stringify(clientIntialValues)]);

  const {
    onCreateClientLeadModalOpen,
    onCreateClientLeadModalClose,
    isCreateClientLeadModalOpen,
  } = React.useMemo(() => {
    return {
      isCreateClientLeadModalOpen: !isEmpty(clientLeadIntialValues),
      onCreateClientLeadModalOpen: setClientLeadInitialValues,
      onCreateClientLeadModalClose: () => setClientLeadInitialValues({}),
    };
  }, [JSON.stringify(clientLeadIntialValues)]);

  let formApi = {};

  React.useEffect(() => {
    if (!isEmpty(customer) && !isEmpty(formApi)) {
      formApi.change('customer', customer);
      formApi.change('clientLead', null);
    }
    if (isEmpty(customer) && !isEmpty(formApi)) {
      formApi.change('customer', null);
      formApi.change('clientLead', null);
    }
  }, [customer]);

  React.useEffect(() => {
    if (!isEmpty(clientLead) && !isEmpty(formApi)) {
      formApi.change('clientLead', clientLead);
    }
    if (isEmpty(clientLead) && !isEmpty(formApi)) {
      formApi.change('clientLead', null);
    }
  }, [clientLead]);

  const { data: industries } = useQuery(API.getIndustries, {});

  const industryOptions = map(industries, ind => ({ label: ind, value: ind }));
  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({
          handleSubmit,
          form,
          values,
          submitting,
          submitFailed,
          errors,
        }) => {
          formApi = form;
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <ErrorSpy
                title={
                  !isEmpty(omit(errors, 'files'))
                    ? 'Please complete all the required sections'
                    : 'Max file size is 10MB.'
                }
              />
              <Content>
                {!customerId && (
                  <>
                    <Typography variant="h2" mb={isSmallerScreen ? 3 : 6}>
                      Select a client for whom you would like to create a
                      project
                    </Typography>
                    <Stack
                      spacing={6}
                      direction={isSmallerScreen ? 'column' : 'row'}
                    >
                      <Field
                        styleProps={{ flex: 1 }}
                        name="customer"
                        label="Client name"
                        data-test-id={`${mainNameQA}--customer`}
                        validate={required}
                        component={AsyncAutocomplete}
                        isCreatable
                        getOptionsFn={API.searchClients}
                        isValidNewOption={() => true}
                        onCreateOption={v => {
                          onCreateClientModalOpen({ name: v || '' });
                        }}
                        placeholder="Select or type client name"
                        onAfterChange={async (c = {}) => {
                          setCustomer(c);
                          const cId = get(values, 'customer.id');
                          const { name } = values;
                          if (cId !== c?.id || customer.id !== cId) {
                            setClientLead(null);
                            form.change('clientLead', {});
                            await sleep(10);
                            form.change('clientLead', null);
                            if (name) {
                              form.change('name', `${name} `);
                              await sleep(50);
                              form.change('name', name);
                            }
                          }
                        }}
                      />
                      <Field
                        name="clientLead"
                        styleProps={{ flex: 1 }}
                        validate={required}
                        data-test-id={`${mainNameQA}--clientLead`}
                        component={AsyncAutocomplete}
                        isCreatable
                        isValidNewOption={() => true}
                        onCreateOption={v => {
                          onCreateClientLeadModalOpen({
                            fullname: v,
                            client: values.customer,
                          });
                        }}
                        reloadOnEmpty
                        getOptionsFn={API.searchClientContactUsers(
                          get(values, 'customer.id'),
                        )}
                        placeholder="Select or type client contact"
                        label="Main client contact"
                        isDisabled={!get(values, 'customer.id')}
                        onAfterChange={async (c = {}) => {
                          setClientLead(c);
                        }}
                        customOptionMap={option => ({
                          label: option.fullname || option.username,
                          value: option,
                        })}
                      />
                    </Stack>
                  </>
                )}
                <Typography
                  variant="h2"
                  {...titleStyles(isSmallerScreen, true)}
                >
                  Give the project a name
                </Typography>
                <Typography mb={isSmallerScreen ? '16px' : '32px'}>
                  This is the name we’ll use throughout the quoting process, so
                  make sure it’s something you can live with! (max 200 chars)
                </Typography>
                <Field
                  name="name"
                  data-test-id={`${mainNameQA}--name`}
                  label="Project Name"
                  component={ProjectNameInput}
                  backgroundColor="white"
                  onBlur={form.resumeValidation}
                  validate={composeValidators(
                    required,
                    mustBeShorterOrEqual(200),
                    validateBriefName,
                  )}
                  placeholder="Type new project name"
                />
                <Typography variant="h2" {...titleStyles(isSmallerScreen)}>
                  Is this a brand new product?
                </Typography>
                <Field
                  name="newOrExisting"
                  validate={required}
                  render={({ meta, input: { value, onChange } }) => (
                    <>
                      <Stack
                        spacing={isSmallerScreen ? 3 : 6}
                        direction={isSmallerScreen ? 'column' : 'row'}
                      >
                        <DescriptionWithIconBox
                          data-test-id={`${mainNameQA}--newProduct`}
                          isActive={value === 'New'}
                          onClick={() => onChange('New')}
                          label="Yes, I’m building a brand new product"
                          icon={{
                            name: 'check',
                            fontSize: 20,
                            color: 'green.solid',
                            mr: '11px',
                            mb: '0px',
                          }}
                          {...cardStyles(isSmallerScreen)}
                        />
                        <DescriptionWithIconBox
                          onClick={() => onChange('Existing')}
                          data-test-id={`${mainNameQA}--existingProduct`}
                          flexDir="row"
                          isActive={value === 'Existing'}
                          icon={{
                            name: 'cross',
                            fontSize: 20,
                            mr: '11px',
                            mb: '0px',
                          }}
                          label="No, I’m developing an existing product"
                          {...cardStyles(isSmallerScreen)}
                        />
                      </Stack>
                      <FormError
                        meta={meta}
                        data-test-id={`${mainNameQA}--newOrExistingProductError`}
                      />
                    </>
                  )}
                />
                <Typography variant="h2" {...titleStyles(isSmallerScreen)}>
                  Industry
                </Typography>
                <Field
                  name="industryVertical"
                  data-test-id={`${mainNameQA}--industryVertical`}
                  arrowColor="green.solid"
                  placeholder="Select an industry"
                  options={industryOptions}
                  validate={required}
                  component={Select}
                  styleProps={{ w: isSmallerScreen ? '100%' : '50%' }}
                />
                <Typography
                  variant="h2"
                  maxW="664px"
                  {...titleStyles(isSmallerScreen)}
                >
                  {`Give us an overview of the key aims of the project and the type of
              product you're working on.`}
                </Typography>
                <Field
                  name="description"
                  data-test-id={`${mainNameQA}--description`}
                  validate={composeValidators(
                    required,
                    mustBeShorterOrEqualForHTML(2500),
                  )}
                  render={props => (
                    <RichTextEditorInput
                      initialValue={initialValues.description}
                      height="400px"
                      width="100%"
                      errorStyle={{ marginLeft: 0 }}
                      isFormField
                      id={`${mainNameQA}--richText`}
                      {...props}
                    />
                  )}
                  mx="auto"
                  w="100%"
                />
                <Typography
                  variant="h2"
                  maxW="664px"
                  {...titleStyles(isSmallerScreen)}
                >
                  When do you need a proposal by?
                </Typography>
                <Field
                  component={DatePicker}
                  disabledDaysFn={disabledDaysFn}
                  name="proposalClosingDate"
                  data-test-id={`${mainNameQA}--proposalClosingDate`}
                  backgroundColor="white"
                  label="Select Date"
                  validate={composeValidators(
                    required,
                    mustBeAfter(moment.utc().subtract({ day: 1 })),
                  )}
                  containerProps={{ maxW: isSmallerScreen ? '100%' : '256px' }}
                />
                <Typography
                  variant="h2"
                  maxW="664px"
                  {...titleStyles(isSmallerScreen)}
                >
                  What resource do you need?
                </Typography>
                <ResourceTable
                  proposalClosingDate={values.proposalClosingDate}
                  formApi={formApi}
                  mainNameQA={mainNameQA}
                />
                <Field name="resource">
                  {({ meta }) =>
                    submitFailed ? (
                      <FormError
                        data-test-id={`${mainNameQA}--resourceError`}
                        mt="8px"
                        meta={meta}
                        ml={!isSmallerScreen && '39px'}
                      />
                    ) : null
                  }
                </Field>
                <Typography
                  variant="h2"
                  maxW="664px"
                  {...titleStyles(isSmallerScreen, true)}
                >
                  Do you have any useful files you’d like to share?
                </Typography>
                <Typography mb={isSmallerScreen ? '16px' : '32px'}>
                  If you have files such as spec documentaion, wireframes, brand
                  guidelines etc upload them here. - It helps makes our quote
                  much more accurate.
                </Typography>
                <Field
                  component={Dropzone}
                  name="files"
                  data-test-id={`${mainNameQA}--files`}
                  errors={errors.files}
                  mx="auto"
                  mt={isSmallerScreen ? '24px' : '32px'}
                  fileProps={{ mx: 'auto' }}
                  w="100%"
                  maxW="100%"
                />
              </Content>
              <StickyFooter
                h="auto"
                {...(isSmallerScreen && {
                  w: '100%',
                  left: 0,
                  bottom: '88px',
                  p: '16px',
                })}
              >
                <Flex maxW="1200px" mx="auto" justifyContent="flex-end">
                  <Button
                    type="submit"
                    isLoading={submitting}
                    isDisabled={!isProfileLoaded}
                    size="lg"
                    {...(isSmallerScreen && { minWidth: '167px' })}
                  >
                    {`${briefId ? 'Update' : 'Create'} brief`}
                  </Button>
                </Flex>
              </StickyFooter>
            </form>
          );
        }}
      />
      <ClientCreateModal
        isOpen={isCreateClientModalOpen}
        onClose={onCreateClientModalClose}
        initialValues={clientIntialValues}
        showIncompleteForm
        onSubmit={clientValues =>
          createClient({
            values: clientValues,
            onSuccess: clientResponse => {
              onCreateClientModalClose();
              clearNewClient();
              setCustomer(clientResponse);
            },
          })
        }
      />
      <ClientLeadCreateModal
        isOpen={isCreateClientLeadModalOpen}
        initialValues={clientLeadIntialValues}
        onClose={onCreateClientLeadModalClose}
        showIncompleteForm
        onSubmit={clientLeadValues =>
          createUserByRole({
            role: 'CL',
            values: { ...clientLeadValues, username: clientLeadValues.email },
            onSuccess: clientLeadResponse => {
              onCreateClientLeadModalClose();
              setClientLead(clientLeadResponse);
            },
          })
        }
      />
    </>
  );
};

export default inject('teamsStore', 'usersStore')(observer(CreateForm));
