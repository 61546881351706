import Modal from '@components/Modal';
import Typography from '@styles/Typography';
import React from 'react';
import SupplierCreateForm from './SupplierCreateForm';

const SupplierCreateModal = ({ isOpen, onClose, createSupplier }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} styleProps={{ minW: '720px' }}>
      <Typography variant="h2" mb="32px">
        Add new Delivery Partner
      </Typography>
      <SupplierCreateForm
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={values => {
          return createSupplier({
            values,
            onSuccess: onClose,
          });
        }}
      />
    </Modal>
  );
};

export default SupplierCreateModal;
