import Modal from '@components/Modal';
import Typography from '@styles/Typography';
import React from 'react';
import AddUserToTeamForm from './AddUserToTeamForm';

const AddUserToTeamModal = ({ isOpen, onClose, handleAddUser, teamId }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <Typography variant="h2" mb="32px">
      Add member to role
    </Typography>
    <AddUserToTeamForm
      onClose={onClose}
      onSubmit={values => {
        return handleAddUser(teamId, values.user.id);
      }}
    />
  </Modal>
);

export default AddUserToTeamModal;
