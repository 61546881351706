import { Box } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Icon from './Icon';

const BackButton = ({ children, as = RouterLink, ...props }) => (
  <Box
    alignItems="center"
    display="inline-flex"
    _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
    as={as}
    {...props}
  >
    <Icon name="arrowLeft" color="aqua.solid" fontSize={20} />
    <Typography>{children}</Typography>
  </Box>
);

export default BackButton;
