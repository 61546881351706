import Typography from '@styles/Typography';
import { Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import React from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import Button from '@styles/Button';
import JsxParser from 'react-jsx-parser';

const EXCLUSIVITY_END_MESSAGE =
  'Your exclusivity period has ended, this brief may be shared with other Delivery Partners';

let interval;

const ExclusivityDpBanner = ({ briefsStore: { brief } }) => {
  const { supplierProposal = {} } = brief;

  const localStorageKey = React.useMemo(() => {
    return `hide-dp-banner-${brief.id}`;
  }, [brief.id]);

  const hideBanner = React.useMemo(() => {
    try {
      const { assignedOn } = JSON.parse(localStorage.getItem(localStorageKey));
      return assignedOn === supplierProposal.assignedOn;
    } catch {
      return false;
    }
  }, [localStorageKey, supplierProposal.assignedOn]);

  const exclusivityDuration = React.useMemo(() => {
    return Math.ceil(
      moment
        .duration(
          moment
            .utc(supplierProposal.exclusiveTo)
            .diff(moment.utc(supplierProposal.assignedOn)),
        )
        .asMinutes() / 60,
    );
  }, [supplierProposal.exclusiveTo, supplierProposal.assignedOn]);

  const isDeclined = supplierProposal?.isDeclined;
  const isAccepted = supplierProposal?.isAccepted;

  const [isOpen, setIsOpen] = React.useState(true);
  const [label, setLabel] = React.useState();
  const [stop, setStop] = React.useState(false);

  const setStatusLabel = () => {
    const date = moment.utc(supplierProposal.exclusiveTo);

    const duration = moment.duration(date.diff(moment.utc()));
    const minutes = duration.asMinutes();
    const seconds = duration.asSeconds();
    if (Math.ceil(seconds) <= 0) {
      setStop(true);
      return setLabel(EXCLUSIVITY_END_MESSAGE);
    }
    return setLabel(
      `<Typography as="span" fontWeight="600" mr="4px">${Math.floor(
        minutes / 60,
      )}h${Math.ceil(seconds) === 60 ? 1 : Math.floor(minutes % 60)}m${
        Math.ceil(seconds) < 60 ? `${Math.ceil(seconds)}s` : ''
      } remaining:</Typography>You have been given ${exclusivityDuration} hours exclusivity on this brief. 
    It will not be shown to another Delivery partner during this period.`,
    );
  };

  React.useEffect(() => {
    if (exclusivityDuration > 0 && !stop) {
      setStatusLabel();
      interval = setInterval(() => {
        setStatusLabel();
      }, 1000);
    } else {
      clearInterval(interval);
      setStatusLabel();
    }
    return () => clearInterval(interval);
  }, [exclusivityDuration, stop]);

  if (
    !hideBanner &&
    isOpen &&
    ((exclusivityDuration > 0 && !isDeclined && !isAccepted) ||
      (isAccepted && label === EXCLUSIVITY_END_MESSAGE))
  ) {
    return (
      <Flex
        p="20px"
        border="1px solid"
        borderColor="yellow.solid"
        borderRadius="8px"
        bg="yellow.light"
        mb="24px"
        display="inline-flex"
      >
        <Icon
          mb="1px"
          name="bolt"
          color={
            label === EXCLUSIVITY_END_MESSAGE ? 'red.solid' : 'yellow.solid'
          }
          fontSize={18}
          mr="12px"
        />
        <Typography>
          <JsxParser
            jsx={label}
            renderInWrapper={false}
            components={{
              Typography,
            }}
          />
        </Typography>
        {label === EXCLUSIVITY_END_MESSAGE && (
          <Button
            iconButton={{
              name: 'close',
              color: 'black',
              fontSize: 20,
            }}
            ml="16px"
            h="auto"
            minW="auto"
            p="0px"
            alignItems="baseline"
            onClick={() => {
              localStorage.setItem(
                localStorageKey,
                JSON.stringify({
                  assignedOn: supplierProposal.assignedOn,
                }),
              );
              setIsOpen(false);
            }}
            variant="ghost"
          />
        )}
      </Flex>
    );
  }

  return null;
};

export default inject('briefsStore')(observer(ExclusivityDpBanner));
