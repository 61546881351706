import { Flex, useDisclosure } from '@chakra-ui/react';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';
import QuestionForm from './QuestionForm';

const AskQuestionSection = ({
  author,
  brief,
  createQuestion,
  isClient,
  mainNameQA,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isReadOnly = brief.isClosed;

  return (
    <>
      <Flex justify="space-between" flexDirection="column">
        <Flex flexDirection="row" w="100%" justify="space-between">
          <Typography variant="h2" mb={25}>
            Questions
          </Typography>
          {!isOpen && !isClient && (
            <Button
              leftIcon={{ name: 'add' }}
              onClick={onOpen}
              isDisabled={isOpen || isReadOnly}
              fontSize="15px"
              data-test-id={`${mainNameQA}--askQuestion`}
            >
              Ask a new question
            </Button>
          )}
        </Flex>
        <Typography mb={8} maxW="720px">
          {isClient
            ? `If we have any questions that we need you to answer in order to give you an accurate estimate,
          we’ll add them here. You’ll also get an email notification.`
            : `Is there some aditional information you’d like to know about this brief before creating a proposal?
              All questions and responses can be viewed by the Deazy and the client.`}
        </Typography>
      </Flex>

      {isOpen && (
        <QuestionForm
          brief={brief}
          onClose={onClose}
          author={author}
          onSubmit={values => createQuestion(values, onClose)}
          initialValues={{ briefId: brief.id }}
          mainNameQA={mainNameQA}
        />
      )}
    </>
  );
};

export default AskQuestionSection;
