import { formatCurrency, formatNumber, sleep } from '@utils';
import {
  orderBy,
  get,
  isEmpty,
  map,
  reject,
  reduce,
  partition,
  flatten,
  flatMap,
  filter,
  omit,
  find,
  some,
  toNumber,
  isObject,
  has,
  groupBy,
  forEach,
  first,
  pick,
  uniqBy,
  set,
  includes,
} from 'lodash-es';
import { action, computed, makeObservable, observable } from 'mobx';
import moment, { max } from 'moment';
import { getDueDaysTerms, getPaymentReference } from '@utils/paymentUtils';
import {
  PROJECT_PAYMENT_TYPES,
  PROJECT_TYPES,
  INVOICE_AMOUNT_TYPES,
  FP_PROJECT_PAYMENT_OPTIONS,
  TM_PROJECT_PAYMENT_OPTIONS,
  ENGAGEMENT_TYPES,
} from '@app/constants';
import { FORM_ERROR } from 'final-form';

export default class PaymentsStore {
  constructor({ API, projectsStore, toastsStore, invoicesStore, usersStore }) {
    makeObservable(this);
    this.API = API;
    this.projectsStore = projectsStore;
    this.toastsStore = toastsStore;
    this.invoicesStore = invoicesStore;
    this.usersStore = usersStore;
  }

  @observable isLoading = false;

  @observable forecast = { client: {}, supplier: {} };

  @observable forecastDeposit = { client: {}, supplier: {} };

  @observable _payments = [];

  @observable earliestStartDate = '';

  @observable paymentsFirstRequest = true;

  @observable overviewFirstRequest = true;

  @observable isCRAssignmentUnavailable = false;

  @observable isLoadingOverview = false;

  @observable _paymentOverview = {};

  @computed get payments() {
    return this.paymentsMapping(this._payments);
  }

  paymentsMapping = payments => {
    const filteredPayments = filter(payments, payment =>
      some(payment.invoices, invoice => !isEmpty(invoice)),
    );
    return map(filteredPayments, payment => {
      const invoices = filter(payment.invoices, i => i);
      const invoicesByDate = orderBy(
        invoices,
        invoice => moment.utc(invoice.invoiceDate).format('YYYYMMDD'),
        ['asc'],
      );
      return {
        ...payment,
        invoiceDate: get(invoicesByDate, '[0].invoiceDate', null),
        invoices: map(invoices, this.invoicesStore.decoratePaymentInvoice),
      };
    });
  };

  @computed get maxPaymentValues() {
    const { clientTotalValue, projectType } = this.projectsStore.project;

    const supplierTotalValue = reduce(
      this.projectsStore.project.projectToSuppliers,
      (sum, pts) => +sum + +pts.supplierTotalValue,
      0,
    );
    const isFixedPrice = projectType === PROJECT_TYPES.FIXED_PRICE;
    const nonCrPayments = this.payments.filter(
      pp => pp.paymentType !== 'Change Request Payment',
    );
    const allInvoices = flatten(map(nonCrPayments, p => p.invoices));
    const [clientInvoices, supplierInvoices] = partition(allInvoices, {
      contactType: 'Client',
    });

    const invKeyToCalculateTotals = isFixedPrice ? 'totalWithoutCRs' : 'total';

    const sumInvoicesTotal = invoices =>
      reduce(
        invoices,
        (sum, invoice) => sum + invoice[invKeyToCalculateTotals],
        0,
      );
    const clientInvoicesTotalValue = sumInvoicesTotal(clientInvoices);

    const supplierInvoicesTotalValue = sumInvoicesTotal(supplierInvoices);

    const groupedSupplierInvoices = groupBy(supplierInvoices, 'supplier.id');

    return {
      maxSupplierValue: supplierTotalValue - supplierInvoicesTotalValue,
      maxClientValue: clientTotalValue - clientInvoicesTotalValue,
      maxSuppliersValue: map(
        this.projectsStore.project.projectToSuppliers,
        pts => ({
          supplier: pts.supplier,
          maxSupplierValue:
            pts.supplierTotalValue -
            sumInvoicesTotal(groupedSupplierInvoices[pts?.supplier?.id]),
        }),
      ),
    };
  }

  to = null;

  @action fetchPayments = async (projectId, paymentId) => {
    try {
      this.isLoading = true;
      const { data } = await this.API.getPayments(projectId);
      this._payments = map(data, p => ({
        ...p,
        invoices: filter(p.invoices, i => i),
      }));
      this.paymentsFirstRequest = false;

      if (paymentId) {
        this.setPaymentProperty(toNumber(paymentId), { isOpened: true });
        this.isLoading = false;
        await sleep(500);
        const container = document.getElementById(
          `payment-container-${paymentId}`,
        );
        if (container) {
          const headerOffset = 85;
          const elementPosition = container.getBoundingClientRect().top;
          const offsetPosition = elementPosition - headerOffset;
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Could not find project invoices. Please try again later.',
      });
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchAvailablePayments = async (projectId, crId) => {
    try {
      this.isLoading = true;
      const { data } = await this.API.getCRAvailablePayments(projectId, crId);
      this._payments = map(data, p => ({
        ...p,
        invoices: filter(p.invoices, i => i),
      }));

      this.isCRAssignmentUnavailable = isEmpty(data);
      this.paymentsFirstRequest = false;
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Could not find project invoices. Please try again later.',
      });
    } finally {
      this.isLoading = false;
    }
  };

  @action updateInvoiceLineItem = async (
    { projectId, invoiceId, lineItemId, description: name, amount, rate },
    formApi,
  ) => {
    try {
      const { data } = await this.API.updateSchedulueInvoiceLineItem(
        { projectId, invoiceId, lineItemId },
        { name, amount, rate },
      );
      this._payments = map(this._payments, payment => ({
        ...payment,
        invoices: map(payment.invoices, invoice => ({
          ...invoice,
          lineItems: map(invoice.lineItems, lineItem => ({
            ...lineItem,
            ...(lineItem.id === lineItemId && { ...data }),
          })),
        })),
      }));
      this.toastsStore.showSuccess({
        title: 'Invoice updated successfully!',
      });
    } catch (e) {
      setTimeout(formApi.restart);
      this.toastsStore.showError({
        title:
          e.message ||
          'Could not update invoice line item. Please, try again later.',
      });
    }
  };

  validatePaymentSuppliers = (suppliers, paymentType) => {
    let error;
    forEach(suppliers, sup => {
      const { isDisabled, lineItems } = sup;
      if (isDisabled === false) {
        if (
          sup.invoiceAmountType === INVOICE_AMOUNT_TYPES.SET_AMOUNT ||
          paymentType === PROJECT_PAYMENT_TYPES.FIXED_PAYMENT
        ) {
          if (!lineItems) {
            error = true;
          }
          forEach(lineItems, item => {
            const { paymentAmount, paymentPercent } = item;
            if (!paymentAmount || !paymentPercent) {
              error = true;
            }
          });
        }
        if (
          !sup.invoiceAmountType &&
          paymentType !== PROJECT_PAYMENT_TYPES.FIXED_PAYMENT
        ) {
          error = true;
        }
      }
    });
    if (error) {
      // eslint-disable-next-line no-throw-literal
      throw {
        [FORM_ERROR]:
          'Some delivery partner invoices are partially finished. Please fulfill them or remove.',
      };
    }
  };

  nextPaymentNumbers = (isClient, currentSupplierId) => {
    const numberOfPayments = this.payments.length;

    const clientDisabledPayments = filter(this.payments, {
      clientDisabled: true,
    }).length;

    const allInvForSupplier = filter(
      flatMap(this.payments, mappedItem => {
        return mappedItem.invoices;
      }),
      filteredItem => filteredItem.contactType === 'Supplier',
    );

    const currentSupplierReferenceNumber = filter(
      allInvForSupplier,
      sup => sup.supplier?.id === currentSupplierId,
    ).length;

    if (isClient) {
      return numberOfPayments - clientDisabledPayments + 1;
    }

    return currentSupplierReferenceNumber + 1;
  };

  @action getPaymentTerms = async (
    projectId,
    projectToSupplierId,
    clientDays,
  ) => {
    if (
      !this.usersStore.isAdminOrDL ||
      this.projectsStore?.project?.projectType !== PROJECT_TYPES.FIXED_PRICE ||
      this.projectsStore?.project?.engagementType === ENGAGEMENT_TYPES.SUPPORT
    ) {
      return { days: 30, projectToSupplierId };
    }
    try {
      const isFixedPrice =
        this.projectsStore?.project?.projectType === PROJECT_TYPES.FIXED_PRICE;
      const projectShouldHasSOWs = includes(
        [
          ENGAGEMENT_TYPES.TEAM_AUGMENTATION,
          ENGAGEMENT_TYPES.PROJECT_BUILD,
          ENGAGEMENT_TYPES.RETAINED_TEAM,
        ],
        this.projectsStore?.project?.engagementType,
      );
      if (!(isFixedPrice && projectShouldHasSOWs)) {
        return;
      }

      if (projectToSupplierId) {
        const { data } = await this.API.getPaymentScheduleSettingsForSupplier(
          projectId,
          projectToSupplierId,
        );

        return {
          days: getDueDaysTerms(data, clientDays),
          projectToSupplierId,
        };
      }
      const { data } = await this.API.getPaymentScheduleSettingsForClient(
        projectId,
      );
      return { days: getDueDaysTerms(data) };
    } catch {
      return { days: 30, projectToSupplierId };
    }
  };

  @action preparePaymentInitialData = async paymentToDuplicate => {
    const { project } = this.projectsStore;

    const paymentTypesOptions =
      project.projectType === PROJECT_TYPES.FIXED_PRICE
        ? FP_PROJECT_PAYMENT_OPTIONS
        : TM_PROJECT_PAYMENT_OPTIONS;

    const initialInvoiceDate = max(moment.utc(), moment.utc(project.startDate));

    const createReference = (isClient, currentSupplierId) => {
      return getPaymentReference(
        project,
        this.nextPaymentNumbers(isClient, currentSupplierId),
        initialInvoiceDate,
      );
    };

    const invoiceInitialValues = {
      invoiceDate: initialInvoiceDate
        .clone()
        .startOf('day')
        .toDate(),
      purchaseOrder: project.purchaseOrder,
      periodStart: initialInvoiceDate
        .clone()
        .startOf('month')
        .toDate(),
      periodEnd: initialInvoiceDate
        .clone()
        .endOf('month')
        .toDate(),
    };

    let clientTerms;
    if (!project.deazyAsClient) {
      clientTerms = await this.getPaymentTerms(project.id);
    }
    const ptosTerms = await Promise.all(
      map(project.projectToSuppliers, pts =>
        this.getPaymentTerms(project.id, pts.id, clientTerms?.days),
      ),
    );

    const clientInitialValues = {
      ...invoiceInitialValues,
      isDisabled: project.deazyAsClient,
      reference: createReference(true),
      paymentTerms: clientTerms?.days,
      dueDate: initialInvoiceDate
        .clone()
        .startOf('day')
        .add(clientTerms?.days, 'days')
        .toDate(),
    };

    const supplierInitialValues = map(project.projectToSuppliers, pts => ({
      ...invoiceInitialValues,
      isDisabled: project.deazyAsSupplier,
      reference: createReference(false, pts.supplier.id),
      supplier: pts.supplier,
      pts,
      isPrimary: pts.isPrimary,
      paymentTerms: find(ptosTerms, { projectToSupplierId: pts.id })?.days,
      dueDate: initialInvoiceDate
        .clone()
        .startOf('day')
        .add(find(ptosTerms, { projectToSupplierId: pts.id })?.days, 'days')
        .toDate(),
    }));

    const clientInvoiceToDuplicate = find(
      paymentToDuplicate?.invoices,
      inv => inv.contactType === 'Client',
    );

    const invoiceAmountType = clientInvoiceToDuplicate?.amountForecasted
      ? INVOICE_AMOUNT_TYPES.FORECASTED
      : INVOICE_AMOUNT_TYPES.SET_AMOUNT;

    const clientDuplicateInvoiceData = {
      ...pick(clientInvoiceToDuplicate, ['invoiceDate', 'dueDate']),
      invoiceAmountType,
      reference: createReference(true),
      isDisabled: !clientInvoiceToDuplicate,
      lineItems: map(
        filter(
          clientInvoiceToDuplicate?.lineItems,
          filteredItem => !filteredItem.isFromCr,
        ),
        mappedItem => {
          return {
            paymentPercent:
              (toNumber(mappedItem.rate) / project.clientTotalValue) * 100,
            paymentAmount: mappedItem.rate,
            name:
              project.projectType === PROJECT_TYPES.FIXED_PRICE &&
              mappedItem.name,
            purchaseOrder: clientInvoiceToDuplicate.purchaseOrder,
          };
        },
      ),
    };

    const suppliersToDuplicate = map(
      uniqBy(
        [
          ...filter(
            paymentToDuplicate?.invoices,
            inv => inv.contactType === 'Supplier',
          ),
          ...(project.projectToSuppliers || []),
        ],
        'supplier.id',
      ),
      item => {
        const findMissingSupplier = find(
          project.projectToSuppliers,
          ptos => ptos.supplier.id === item.supplier.id,
        );
        return {
          ...item,
          supplierTotalValue: findMissingSupplier?.supplierTotalValue,
          isPrimary: findMissingSupplier?.isPrimary,
          ptosId: findMissingSupplier?.id,
        };
      },
    );

    const supplierDuplicateInvoiceData = map(suppliersToDuplicate, pts => {
      return {
        ...pick(pts, ['invoiceDate', 'dueDate', 'isPrimary', 'supplier']),
        isDisabled: !pts.lineItems,
        invoiceAmountType,
        reference: createReference(false, pts.supplier.id),
        lineItems: map(
          filter(pts?.lineItems, filteredItem => !filteredItem.isFromCr),
          mappedItem => {
            return {
              paymentPercent:
                (toNumber(mappedItem.rate) / pts.supplierTotalValue) * 100,
              paymentAmount: mappedItem.rate,
              name:
                project.projectType === PROJECT_TYPES.FIXED_PRICE &&
                mappedItem.name,
              purchaseOrder: pts.purchaseOrder,
            };
          },
        ),
        pts: { id: pts.ptosId },
      };
    });

    const calculatedFromActuals =
      project.projectType === PROJECT_TYPES.T_AND_M &&
      paymentToDuplicate?.calculatedFromActuals;

    return {
      projectId: project.id,
      calculatedFromActuals,
      isDuplicated: !!paymentToDuplicate,
      paymentType: paymentToDuplicate
        ? paymentToDuplicate.paymentType
        : get(first(paymentTypesOptions), 'value'),
      client: paymentToDuplicate
        ? clientDuplicateInvoiceData
        : clientInitialValues,
      suppliers: paymentToDuplicate
        ? supplierDuplicateInvoiceData
        : supplierInitialValues,
    };
  };

  @action createProjectPayment = async (
    { projectId, ...payload },
    successCb,
  ) => {
    try {
      if (payload.paymentType !== PROJECT_PAYMENT_TYPES.CR_PAYMENT) {
        this.validatePaymentSuppliers(
          this.paymentPayload(payload.suppliers),
          payload.paymentType,
        );
      }
      await this.API.createPayment(projectId, this.paymentPayload(payload));
      await this.refetchPaymentsSilently(projectId);
      await this.fetchPaymentOverview(projectId);
      this.toastsStore.showSuccess({
        title: 'Project invoices created successfully!',
      });
      if (successCb) {
        successCb();
      }
    } catch (e) {
      if (isObject(e) && has(e, FORM_ERROR)) {
        return e;
      }
      this.toastsStore.showError({
        title: e.message || 'Creating actuals failed. Please, try again later.',
      });
    }
    return undefined;
  };

  paymentPayload = payment => {
    let { client = {} } = payment;
    const { suppliers = [] } = payment;

    const isFixedPayment =
      payment.paymentType === PROJECT_PAYMENT_TYPES.FIXED_PAYMENT;
    client = omit(client, [
      client.autoAmount && 'paymentAmount',
      client.autoPercent && 'paymentPercent',
      'autoAmount',
      'autoPercent',
    ]);

    const { deazyAsSupplier } = this.projectsStore.project;

    const isCRPayment =
      payment.paymentType === PROJECT_PAYMENT_TYPES.CR_PAYMENT;

    const primarySupplier = find(suppliers, s => s.isPrimary);

    return {
      ...payment,
      client: {
        ...client,
        purchaseOrder: client.purchaseOrder || '',
        ...(!isFixedPayment
          ? first(client.lineItems)
          : { entriesPayload: client.lineItems }),
      },

      suppliers:
        !deazyAsSupplier &&
        map(isCRPayment ? [primarySupplier] : suppliers, pts => ({
          isDisabled: pts.isDisabled,
          supplierId: pts.supplier.id,
          invoiceDate: pts.invoiceDate,
          dueDate: pts.dueDate,
          reference: pts.reference,
          purchaseOrder: pts.purchaseOrder,
          invoiceAmountType: pts.invoiceAmountType,
          periodStart: pts.periodStart,
          periodEnd: pts.periodEnd,
          ...(!isFixedPayment
            ? first(pts.lineItems)
            : { entriesPayload: pts.lineItems }),
        })),
    };
  };

  @observable updatedInvoiceData = {};

  @action updateInvoice = async (
    { id, ...payload },
    projectId,
    successCb,
    refetchPayments = true,
  ) => {
    try {
      const { data } = await this.API.updateScheduleInvoice(
        projectId,
        id,
        this.invoiceUpdatePayload(payload),
      );
      this.updatedInvoiceData = data;
      if (refetchPayments) {
        await this.refetchPaymentsSilently(projectId);
      }
      this.toastsStore.showSuccess({ title: 'Invoice updated successfully!' });
      successCb();
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message || 'Could not update invoice. Please, try again later.',
      });
    }
  };

  invoiceUpdatePayload = invoice => {
    return {
      ...omit(invoice, [
        invoice.autoAmount && 'paymentAmount',
        invoice.autoPercent && 'paymentPercent',
        'autoAmount',
        'autoPercent',
        'supplier',
      ]),
      purchaseOrder: invoice.purchaseOrder || '',
      xeroId: invoice.xeroId || null,
    };
  };

  @action fetchForecastedValue = async payload => {
    const { isClient, projectToSupplierId } = payload;
    const {
      id: projectId,
      currency,
      projectToSuppliers,
    } = this.projectsStore.project;

    const currentPTOS = find(
      projectToSuppliers,
      ptos => ptos.id === projectToSupplierId,
    );

    const supplierCurrency =
      currentPTOS?.overriddenCurrency || currentPTOS?.supplier?.currency;

    this.forecast[isClient ? 'client' : 'supplier'].isLoading = true;
    try {
      const { data } = await this.API.getPaymentForecast(projectId, payload);
      this.forecast[isClient ? 'client' : 'supplier'] = {
        projectId,
        value: formatCurrency(data, isClient ? currency : supplierCurrency),
        isLoading: false,
      };
    } catch {
      this.forecast[isClient ? 'client' : 'supplier'] = {
        projectId,
        value: 'N/A',
        isLoading: false,
      };
    }
  };

  @action deletePayment = async (projectId, paymentId) => {
    try {
      this.setPaymentProperty(paymentId, { isDeleting: true });
      await this.API.deletePayment(projectId, paymentId);
      await this.fetchPaymentOverview(projectId);
      this._payments = reject(this._payments, { id: paymentId });
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Project invoices could not be deleted. Please try again later.',
      });
    } finally {
      this.setPaymentProperty(paymentId, { isDeleting: false });
    }
  };

  @action setPaymentProperty = (paymentId, obj) => {
    this._payments = map(this._payments, payment => {
      if (paymentId === payment.id) {
        return { ...payment, ...obj };
      }
      return payment;
    });
  };

  @action clearPayments = () => {
    this._payments = [];
    this.paymentsFirstRequest = true;
    this.overviewFirstRequest = true;
    this.isCRAssignmentUnavailable = false;
  };

  @action getTaxInfo = async (
    projectId,
    invoiceId,
    isClient,
    supplierId,
    isDeposit,
  ) => {
    const allInvoices = flatten(map(this.payments, p => p.invoices));
    const invoice = find(allInvoices, i => i.id === invoiceId) || {};
    if (!isEmpty(invoice.taxInfo) || isEmpty(invoice.lineItems)) {
      return;
    }
    try {
      this.setInvoiceProperty(invoiceId, { isLoadingTaxInfo: true });
      const { data } = isClient
        ? await this.API.getClientTaxInfo(projectId, isDeposit)
        : await this.API.getSupplierTaxInfo(projectId, supplierId, isDeposit);
      this.setInvoiceProperty(invoiceId, { taxInfo: data });
    } catch (e) {
      //
    } finally {
      this.setInvoiceProperty(invoiceId, { isLoadingTaxInfo: false });
    }
  };

  @action approveOrPreapproveInvoice = async (
    projectId,
    invoiceId,
    preapprove,
    successCb,
  ) => {
    try {
      this.setInvoiceProperty(invoiceId, { isApproving: true });
      const endpoint = preapprove
        ? 'preapproveScheduleInvoice'
        : 'approveScheduleInvoice';
      await this.API[endpoint](projectId, invoiceId);
      await this.refetchPaymentsSilently(projectId);
      this.toastsStore.showSuccess({
        title: 'Invoice has been approved successfully.',
      });
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title:
          e || 'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      this.setInvoiceProperty(invoiceId, { isApproving: false });
    }
  };

  @action markAsSentInvoice = async (projectId, invoiceId) => {
    try {
      this.setInvoiceProperty(invoiceId, { isMarking: true });
      await this.API.markAsSentScheduleInvoice(projectId, invoiceId);
      await this.refetchPaymentsSilently(projectId);
      this.toastsStore.showSuccess({
        title: 'Invoice has been marked as sent.',
      });
    } catch (e) {
      this.toastsStore.showError({
        title:
          e || 'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      this.setInvoiceProperty(invoiceId, { isMarking: false });
    }
  };

  @action setInvoiceProperty = (invoiceId, obj) => {
    this._payments = map(this._payments, p => ({
      ...p,
      invoices: map(p.invoices, invoice => {
        if (invoiceId === invoice.id) {
          return { ...invoice, ...obj };
        }
        return invoice;
      }),
    }));
  };

  @action refetchPaymentsSilently = async projectId => {
    try {
      const { data: payments } = await this.API.getPayments(projectId);
      this._payments = map(payments, p => {
        const oldPayment =
          find(this._payments, payment => payment.id === p.id) || {};
        return {
          ...oldPayment,
          ...p,
          invoices: filter(
            map(p.invoices, inv => ({
              ...(find(oldPayment.invoices, oldInv => oldInv.id === inv.id) ||
                {}),
              ...inv,
            })),
            i => i,
          ),
        };
      });
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Could not find project invoices. Please try again later.',
      });
    }
  };

  @action fetchPaymentOverview = async projectId => {
    try {
      this.isLoadingOverview = true;
      const { data } = await this.API.getPaymentOverview(projectId);
      this._paymentOverview = data;
      this.overviewFirstRequest = false;
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      this.isLoadingOverview = false;
    }
  };

  @action rejectInvoice = async (projectId, values, successCb) => {
    try {
      await this.API.rejectInvoice(projectId, values);
      await this.refetchPaymentsSilently(projectId);
      this.toastsStore.showSuccess({
        title: 'Invoice rejected successfully!',
      });
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    }
  };

  @action addNewPaymentNote = async (projectId, values, successCb) => {
    try {
      await this.API.addCommentToInvoice(projectId, values);
      this.toastsStore.showSuccess({
        title: 'Note created successfully!',
      });

      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    }
  };

  @action fetchearliestStartDate = async projectId => {
    try {
      this.isLoadingOverview = true;
      const { data: earliestInvoiceDate } = await this.API.getEarliestStartDate(
        projectId,
      );
      this.earliestStartDate = earliestInvoiceDate;
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      this.isLoadingOverview = false;
    }
  };

  @computed get paymentOverview() {
    const { currency } = this.projectsStore.projectCurrency;
    const supplierCurrency = 'GBP';
    const {
      supplierScheduledInvoicesTotal,
      clientScheduledInvoicesTotal,
      scheduledInvoicesMargin,
      supplierInvoicedInvoicesTotal,
      clientInvoicedInvoicesTotal,
      invoicedInvoicesMargin,
      supplierDepositInvoicesTotal,
      clientDepositInvoicesTotal,
      depositMargin,
      supplierAllocatedDepositTotal,
      clientAllocatedDepositTotal,
      depositAllocationMargin,
      supplierTotal,
      clientTotal,
      totalMargin,
    } = this._paymentOverview;
    const isFixedPrice =
      this.projectsStore.project.projectType !== PROJECT_TYPES.FIXED_PRICE;

    return {
      tableData: [
        [
          'Total',
          formatCurrency(clientTotal, currency),
          formatCurrency(supplierTotal, supplierCurrency),
          totalMargin && `${formatNumber(totalMargin)}%`,
        ],
        [
          'Invoiced',
          formatCurrency(clientInvoicedInvoicesTotal, currency),
          formatCurrency(supplierInvoicedInvoicesTotal, supplierCurrency),
          invoicedInvoicesMargin && `${formatNumber(invoicedInvoicesMargin)}%`,
        ],
        [
          'Scheduled',
          formatCurrency(clientScheduledInvoicesTotal, currency),
          formatCurrency(supplierScheduledInvoicesTotal, supplierCurrency),
          scheduledInvoicesMargin &&
            `${formatNumber(scheduledInvoicesMargin)}%`,
        ],
      ],
      ...(isFixedPrice && {
        depositTableData: [
          [
            'Deposit paid',
            formatCurrency(clientDepositInvoicesTotal, currency),
            formatCurrency(supplierDepositInvoicesTotal, supplierCurrency),
            depositMargin && `${formatNumber(depositMargin)}%`,
          ],
          [
            'Deposit allocated to',
            formatCurrency(clientAllocatedDepositTotal, currency),
            formatCurrency(supplierAllocatedDepositTotal, supplierCurrency),
            depositAllocationMargin &&
              `${formatNumber(depositAllocationMargin)}%`,
          ],
        ],
      }),
    };
  }

  @action createCreditNote = async (
    { projectId, invoiceId, ...values },
    successCb,
  ) => {
    if (toNumber(values.unitPrice) === 0 || toNumber(values.quantity) === 0) {
      return this.toastsStore.showError({
        title: 'Credit note amount cannot be 0!',
      });
    }
    if ((values.description || '').length > 255) {
      return this.toastsStore.showError({
        title:
          'Credit note description must be shorter or equal 255 characters.',
      });
    }
    try {
      await this.API.createCreditNote(projectId, invoiceId, {
        ...values,
        unitPrice: toNumber(values.unitPrice),
        quantity: toNumber(values.quantity),
      });
      await this.refetchPaymentsSilently(projectId);
      this.toastsStore.showSuccess({
        title: 'Credit note created successfully!',
      });
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  _getCreditNotePath = creditNoteId => {
    let creditNoteObservablePath = '';
    forEach(this._payments, (p, pIdx) => {
      forEach(p.invoices, (i, iIdx) => {
        forEach(i.creditAllocations, (ca, caIdx) => {
          if (ca?.creditNote?.id === creditNoteId) {
            creditNoteObservablePath = `[${pIdx}]invoices[${iIdx}].creditAllocations[${caIdx}].creditNote`;
          }
        });
      });
    });
    return creditNoteObservablePath;
  };

  @action deleteCreditNote = async (
    projectId,
    invoiceId,
    creditNoteId,
    successCb,
  ) => {
    const creditNoteObservablePath = this._getCreditNotePath(creditNoteId);
    try {
      if (!creditNoteObservablePath) {
        return this.toastsStore.showError({
          title: 'Credit note could not be removed. Please, try again later.',
        });
      }
      set(this._payments, `${creditNoteObservablePath}.isDeleting`, true);
      await this.API.deleteCreditNote(projectId, invoiceId, creditNoteId);
      await this.refetchPaymentsSilently(projectId);
      this.toastsStore.showSuccess({
        title: 'Credit note deleted successfully!',
      });
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
      set(this._payments, `${creditNoteObservablePath}.isDeleting`, false);
    }
  };

  @action approveOrPreapproveCreditNote = async (
    projectId,
    invoiceId,
    creditNoteId,
    preapprove = false,
  ) => {
    const creditNoteObservablePath = this._getCreditNotePath(creditNoteId);
    try {
      set(this._payments, `${creditNoteObservablePath}.isApproving`, true);
      const endpoint = preapprove
        ? 'preApproveCreditNote'
        : 'approveCreditNote';
      await this.API[endpoint](projectId, invoiceId, creditNoteId);
      await this.refetchPaymentsSilently(projectId);
      this.toastsStore.showSuccess({
        title: `Credit note has been ${
          preapprove ? 'pre-' : ''
        }approved successfully.`,
      });
    } catch (e) {
      this.toastsStore.showError({
        title:
          e || 'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      set(this._payments, `${creditNoteObservablePath}.isApproving`, false);
    }
  };

  @action rejectCreditNote = async (projectId, invoiceId, creditNoteId) => {
    const creditNoteObservablePath = this._getCreditNotePath(creditNoteId);
    try {
      set(this._payments, `${creditNoteObservablePath}.isRejecting`, true);
      await this.API.rejectCreditNote(projectId, invoiceId, creditNoteId);
      await this.refetchPaymentsSilently(projectId);
      this.toastsStore.showSuccess({
        title: `Credit note has been rejected successfully.`,
      });
    } catch (e) {
      this.toastsStore.showError({
        title:
          e || 'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      set(this._payments, `${creditNoteObservablePath}.isRejecting`, false);
    }
  };
}
