import { isFixedPrice } from '@utils/projectUtils';
import { filter, find, isEmpty, isEqual, omit, reject } from 'lodash-es';
import { ENGAGEMENT_TYPES, PROJECT_TYPES } from '@app/constants';
import { composeValidators, required } from '@utils/formValidators';

export const filterAllocationFn = a =>
  reject(a, r => isEmpty(omit(r, ['id', 'startDate', 'endDate'])));

export const PHASE_EMPTY_ERROR_MSG =
  'At least one allocation in one phase must be entered.';

const checkProjectType = (projectType, engagementType) => {
  if (
    projectType === PROJECT_TYPES.FIXED_PRICE &&
    engagementType === ENGAGEMENT_TYPES.PROJECT_BUILD
  ) {
    return PHASE_EMPTY_ERROR_MSG;
  }
  return 'This section is required';
};

const hasDeazyAsClientSelected = (value, values) =>
  values.deazyAsClient && value?.id === 'deazy'
    ? 'Deazy is already selected as a client!'
    : undefined;

const supplierIsAlreadySelected = (value, values) =>
  filter(values?.projectToSuppliers, s => isEqual(s?.supplier, value)).length >
  1
    ? 'That Delivery Partner is already selected.'
    : undefined;

export const valdiatePts = (pts, values) => ({
  supplier: composeValidators(
    required,
    hasDeazyAsClientSelected,
    supplierIsAlreadySelected,
  )(pts.supplier, values),
  supplierLead: required(pts.supplierLead),
  overriddenCurrency: required(pts.overriddenCurrency),
});

export const allocationValidation = (
  projectType,
  engagementType,
  allocations,
  allocationErrorField,
  isDeazyAllocation = false,
  deazyAsClient,
) => {
  const errors = {};

  const shouldCheckAllocationDates = isFixedPrice({
    projectType,
    engagementType,
  });

  const filteredAllocations = filterAllocationFn(allocations);

  if (isEmpty(filteredAllocations) && !isDeazyAllocation) {
    errors[allocationErrorField] = checkProjectType(
      projectType,
      engagementType,
    );
  }
  const findObjWithZero = find(filteredAllocations, a =>
    deazyAsClient
      ? a.rate <= 0 || a.amount <= 0
      : a.clientRate <= 0 || a.rate <= 0 || a.amount <= 0,
  );

  if (
    isDeazyAllocation &&
    find(
      filteredAllocations,
      a =>
        !a.amount ||
        !a.resourceName ||
        !a.rate ||
        (shouldCheckAllocationDates && (!a.startDate || !a.endDate)),
    )
  ) {
    errors[allocationErrorField] =
      'Some rows are partially finished. Please fulfill them or remove.';
  }

  if (
    !isDeazyAllocation &&
    find(filteredAllocations, a =>
      deazyAsClient
        ? !a.amount ||
          !a.resourceType ||
          !a.name ||
          !a.rate ||
          (shouldCheckAllocationDates && (!a.startDate || !a.endDate))
        : !a.amount ||
          !a.resourceType ||
          !a.name ||
          !a.rate ||
          !a.clientRate ||
          (shouldCheckAllocationDates && (!a.startDate || !a.endDate)),
    )
  ) {
    errors[allocationErrorField] =
      'Some rows are partially finished. Please fulfill them or remove.';
  }
  if (findObjWithZero) {
    const printValue = obj => {
      if (obj.amount <= 0) {
        return 'Hours';
      }
      return 'Delivery partner or client rate';
    };
    errors[allocationErrorField] = `${printValue(
      findObjWithZero,
    )} must be greater than 0`;
  }
  return errors;
};
