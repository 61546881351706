/* eslint-disable */
import { IS_DEV, IS_STAGING, PROJECT_TYPES } from '@app/constants';
import { Avatar, Box, Flex, Link, useDisclosure } from '@chakra-ui/react';
import Menu from '@components/Menu';
import TextEllipsisWithTooltip from '@components/TextEllipsisWithTooltip';
import useDownload from '@hooks/useDownload';
import { APP_ROUTES } from '@routes';
import Card from '@styles/Card';
import { COLORS } from '@styles/theme';
import ProjectTypeBadge from '@components/ProjectTypeBadge';
import Typography from '@styles/Typography';
import { createProjectSlug } from '@utils/projectUtils';
import { get, isEmpty, noop } from 'lodash-es';
import moment from 'moment';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import DeleteModal from '../Project/Overview/DeleteModal';
import ActionsButton from './ActionsButton';
import ProjectCardBadge from './ProjectCardBadge';
import ProjectStatusBadge from './ProjectStatusBadge';
import ConfirmMergeBriefModal from './ConfirmMergeBriefModal';

const { REACT_APP_API_URL: API_URL } = process.env;

const statsWrapperBorderProps = {
  borderLeft: `1px solid ${COLORS.dark.mid}`,
  pl: '8px',
  ml: '8px',
};
const StatsWrapper = ({ children, ...props }) => (
  <Flex _notFirst={statsWrapperBorderProps} h="fit-content" {...props}>
    {children}
  </Flex>
);

const Stat = ({ label, value, valueAs, qaTag, ...props }) => (
  <Box maxH="43px" {...props}>
    <Typography color="dark.solid" mb="8px" variant="text3">
      {label}
    </Typography>
    <Typography variant="text2" mb="8px" as={valueAs} data-test-id={qaTag}>
      {value}
    </Typography>
  </Box>
);

const ProjectCard = ({
  mainNameQA,
  project = {},
  deleteProject,
  isDeleting,
  canDeleteProject,
  isAdmin,
  isAdminOrDL,
  isTeamMember,
  isTeamAdmin,
  isClient,
  forceJobsByProject,
  archiveOrUnarchiveProject,
  fetchProjects,
  isProjectsListRoute,
  cloneProject,
  isMergingView,
  xeroSync,
  xero,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmMergeBriefOpen,
    onOpen: onConfirmMergeBriefOpen,
    onClose: onConfirmMergeBriefClose,
  } = useDisclosure();

  const [syncFailed, setSyncFailed] = useState(project.syncFailed);
  const [forcing, setForcing] = useState(false);

  const exportProjectLink = `${API_URL}/projects/${project.id}/export`;

  const { onDownload: exportProject } = useDownload({
    url: exportProjectLink,
  });

  const handleTryResync = () => {
    setForcing(!forcing);
    forceJobsByProject({
      syncProjectId: project.id,
      onSuccess: () => {
        setSyncFailed(!syncFailed);
        setForcing(false);
      },
    });
  };

  const projectLink = React.useMemo(() => {
    if (isTeamMember) {
      if (project.projectType === PROJECT_TYPES.FIXED_PRICE) {
        return createProjectSlug(project, 'allocations');
      }
      return createProjectSlug(project, 'allocations-and-actuals');
    }
    return createProjectSlug(project, 'payments');
  }, [isTeamMember, project.projectType, isTeamMember]);

  const menuItems = [
    {
      label: 'Try Resync',
      icon: 'refresh',
      onClick: handleTryResync,
      isVisible: syncFailed,
    },
    // {
    //   label: 'Sync with xero',
    //   icon: 'refresh',
    //   onClick: () => xeroSync(project.id),
    //   isVisible: xero.authorized,
    // },
    {
      label: 'Duplicate',
      icon: 'duplicateNew',
      isLink: true,
      to: `${APP_ROUTES.projectsCreate}?cloneId=${project.id}`,
    },
    {
      label: 'Clone',
      icon: 'copy',
      onClick: () => cloneProject(project.id, fetchProjects),
      isVisible: IS_DEV || IS_STAGING,
    },
    {
      label: 'Export',
      icon: 'download',
      onClick: exportProject,
      isVisible: (IS_DEV || IS_STAGING) && isAdmin,
    },
    {
      label: project.archived ? 'Unarchive' : 'Archive',
      icon: 'archivedNew',
      onClick: () => archiveOrUnarchiveProject(project, fetchProjects),
      isDisabled: !project.readyToArchive,
      isVisible: isAdminOrDL && !project.pending,
    },
    {
      label: 'Delete',
      icon: 'trashNew',
      onClick: onOpen,
      isVisible: canDeleteProject,
    },
  ];

  const isDPView = isTeamMember || isTeamAdmin;

  return (
    <Card
      onClick={isMergingView ? onConfirmMergeBriefOpen : noop}
      data-test-id={`${mainNameQA}--card`}
    >
      <Link
        _hover={{ cursor: 'pointer', textDecoration: 'none' }}
        to={projectLink}
        as={isMergingView ? Box : RouterLink}
      >
        <Box
          pb="12px"
          display="flex"
          alignItems="flex-start"
          w="100%"
          {...(!isMergingView && {
            borderBottom: '1px solid',
            borderColor: 'dark.mid',
          })}
        >
          <Flex pt="12px" pl="11px" align="center" mr="auto">
            <Avatar
              name={get(project, 'client.name', 'Deazy')}
              mr="7px"
              src={get(project, 'client.logo')}
              pt={get(project, 'client.logo') ? '0px' : '3px'}
            />

            <Box>
              <Typography
                variant="h4"
                mb={!isClient && '5px'}
                pr="12px"
                data-test-id={`${mainNameQA}--projectName`}
              >
                {project.name}
              </Typography>
              {!isClient && (
                <Typography data-test-id={`${mainNameQA}--clientName`}>
                  {get(project, 'client.name', 'Deazy')}
                </Typography>
              )}
            </Box>
          </Flex>
          <ProjectCardBadge
            data-test-id={`${mainNameQA}--statusBadge`}
            project={project}
          />
        </Box>
        {!isMergingView && (
          <>
            <Box
              as={Flex}
              p="16px"
              w="100%"
              flexWrap={['wrap', 'wrap', 'nowrap', 'nowrap']}
              {...(!isClient && {
                borderBottom: '1px solid',
                borderColor: 'dark.mid',
              })}
            >
              <StatsWrapper w={isDPView ? '33%' : ['50%', '50%', '20%', '20%']}>
                <Stat
                  label="Engagement type"
                  valueAs="div"
                  value={<ProjectTypeBadge type={project.engagementType} />}
                  qaTag={`${mainNameQA}--engagementType`}
                  mr="10px"
                  mb="5px"
                />
              </StatsWrapper>
              <StatsWrapper
                flexDir="column"
                w={isDPView ? '33%' : ['45%', '45%', '20%', '20%']}
              >
                <Stat
                  label="Duration"
                  qaTag={`${mainNameQA}--duration`}
                  value={`${moment
                    .utc(project.startDate)
                    .format('DD MMM YY')} - ${moment
                    .utc(project.endDate)
                    .format('DD MMM YY')}`}
                />
              </StatsWrapper>
              <StatsWrapper
                flexDir="column"
                mt={[isDPView ? 0 : '20px', isDPView ? 0 : '20px', 0, 0]}
                w={isDPView ? '33%' : ['50%', '50%', '20%', '20%']}
                _notFirst={[
                  isDPView ? statsWrapperBorderProps : {},
                  isDPView ? statsWrapperBorderProps : {},
                  statsWrapperBorderProps,
                  statsWrapperBorderProps,
                ]}
              >
                <Stat
                  label="Deazy Lead"
                  qaTag={`${mainNameQA}--deazyLead`}
                  value={
                    get(project, 'pm.fullname') || get(project, 'pm.email')
                  }
                />
              </StatsWrapper>
              {!isDPView && (
                <StatsWrapper
                  flexDir="column"
                  mt={[isDPView ? 0 : '20px', isDPView ? 0 : '20px', 0, 0]}
                  w={['45%', '45%', '30%', '30%']}
                >
                  {isAdminOrDL && (
                    <Stat
                      label={`Delivery Partner${
                        project?.projectToSuppliers?.length > 1 ? 's' : ''
                      }`}
                      valueAs="div"
                      qaTag={`${mainNameQA}--deliveryPartners`}
                      value={
                        <TextEllipsisWithTooltip variant="text2">
                          {get(project, 'supplierNamesLabel')}
                        </TextEllipsisWithTooltip>
                      }
                    />
                  )}
                  {isClient && (
                    <Box maxH="43px">
                      <Typography color="dark.solid" mb="8px" variant="text3">
                        RAG Status
                      </Typography>
                      <ProjectStatusBadge project={project} />
                    </Box>
                  )}
                </StatsWrapper>
              )}
            </Box>
            {!isClient && <Box pb="72px" />}
          </>
        )}
      </Link>
      {!isClient && !isMergingView && (
        <>
          <Flex
            px="16px"
            py="24px"
            alignItems="center"
            position="absolute"
            bottom="0"
            left="0"
          >
            <ProjectStatusBadge
              project={project}
              label="RAG Status"
              isProjectsListRoute={isProjectsListRoute}
            />
            {isAdminOrDL && (
              <ProjectStatusBadge
                project={project}
                label="Risk Profile"
                isRiskDropdown
                isProjectsListRoute={isProjectsListRoute}
              />
            )}
          </Flex>
          <Flex
            px="16px"
            pb="16px"
            alignItems="center"
            position="absolute"
            bottom="0"
            w="100%"
            right="0"
          >
            <Box ml="auto">
              {!isEmpty(project.actionsForMe) && (
                <ActionsButton
                  actions={project.actionsForMe}
                  project={project}
                  variant="secondary"
                  isTeamMember={isTeamMember}
                />
              )}
              {!isEmpty(project.actionsForOthers) && (
                <ActionsButton
                  actions={project.actionsForOthers}
                  project={project}
                  variant="ghost"
                  buttonLabel="Other actions"
                  isTeamMember={isTeamMember}
                  mr="-16px"
                />
              )}
              {isAdminOrDL && (
                <>
                  <Menu menuItems={menuItems} />
                  <DeleteModal
                    onClose={onClose}
                    isOpen={isOpen}
                    onDelete={() => deleteProject(project.id, onClose)}
                    isLoading={isDeleting}
                  />
                </>
              )}
            </Box>
          </Flex>
        </>
      )}
      <ConfirmMergeBriefModal
        onClose={onConfirmMergeBriefClose}
        isOpen={isConfirmMergeBriefOpen}
        project={project}
      />
    </Card>
  );
};

export default ProjectCard;
