import { Box } from '@chakra-ui/react';
import Button from '@styles/Button';
import React from 'react';

const CompleteButton = ({ completeSOW, isLoading, ...props }) => {
  return (
    <Box mt="20px">
      <Button {...props} onClick={completeSOW} ml="auto" isLoading={isLoading}>
        Finalise
      </Button>
    </Box>
  );
};
export default CompleteButton;
