import { Box, Flex } from '@chakra-ui/react';
import FormError from '@components/forms/FormError';
import Typography from '@styles/Typography';
import { map } from 'lodash-es';
import React from 'react';
import Radio from './RadioField';

const RadioGroupField = ({
  containerProps,
  label,
  choices,
  alignItems = 'flex-end',
  input = {},
  meta,
  isVertical = false,
  labelProps = {},
  inputLabelStyle = {},
  isDisabled = false,
  onAfterChange,
  fieldsWrapperStyles,
  mainNameQA,
}) => {
  const hasError =
    (meta.touched || meta.submitFailed) && (meta.error || meta.submitError);
  return (
    <Flex direction="column">
      <Box position="relative" {...containerProps}>
        {hasError && (
          <Box
            w="2px"
            height="100%"
            position="absolute"
            bg="red.solid"
            left="-12px"
          />
        )}
        {label && (
          <Typography variant="title" mb="18px" {...labelProps}>
            {label}
          </Typography>
        )}
        <Box display={isVertical ? 'block' : 'flex'} {...fieldsWrapperStyles}>
          {map(choices, ({ label: title, value, subLabel }) => {
            const isChecked = value === input.value;

            return (
              <Radio
                key={`radio-group-${value}`}
                label={title}
                checked={isChecked}
                value={value}
                subLabel={subLabel}
                align={alignItems}
                disabled={isDisabled}
                onChange={input.onChange}
                onAfterChange={onAfterChange}
                name={input.name}
                isVertical={isVertical}
                inputLabelStyle={inputLabelStyle}
                mainNameQA={mainNameQA}
              />
            );
          })}
        </Box>
      </Box>
      <FormError meta={meta} ml="0px" />
    </Flex>
  );
};

export default RadioGroupField;
