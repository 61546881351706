import { Box, Stack } from '@chakra-ui/react';
import { join } from 'lodash-es';
import React from 'react';
import { Stat } from './components';

const MobileResourceCard = ({ resource = {}, brief = {} }) => (
  <Box
    py="16px"
    px="12px"
    borderRadius="8px"
    bg="primary.100"
    _notLast={{ mb: '12px' }}
  >
    <Stack isInline spacing="16px">
      <Stat w="50%" label="Role" value={resource.technology} />
      <Stat w="50%" label="Seniority" value={brief.seniority} />
    </Stack>
    <Stack isInline spacing="16px">
      <Stat w="50%" label="Start date" value={brief.startDate} />
      <Stat w="50%" label="Months" value={brief.numberOfMonths} />
    </Stack>
    <Stack isInline spacing="8px">
      <Stat
        w="100%"
        label="Desired technologies"
        value={join(resource.technologies, ', ')}
      />
    </Stack>
    {resource.comment && (
      <Stack isInline spacing="8px">
        <Stat w="100%" label="Comment" value={resource.comment} />
      </Stack>
    )}
  </Box>
);

export default MobileResourceCard;
