/* eslint-disable no-nested-ternary */
import React from 'react';
import { FormControl, Input } from '@chakra-ui/react';
import { getInputVariantProps, INPUT_VARIANTS } from '@styles/theme';
import FormError from './FormError';
import FormLabel from './FormLabel';

const TextInput = ({
  placeholder = '',
  label,
  input = {},
  meta = {},
  onAfterChange,
  isBordered = false,
  onAfterBlur,
  onFocus,
  containerProps,
  isErrorAbsolute,
  errorStyle,
  variant = INPUT_VARIANTS.NORMAL,
  labelProps = {},
  hideError,
  onOnlyBlur,
  ...props
}) => {
  const hasError =
    !!(meta.touched || meta.submitFailed) && (meta.error || meta.submitError);

  const borderColor = hasError ? 'red.solid' : isBordered ? 'dark.mid' : '';
  const {
    labelProps: lProps,
    inputProps,
    containerProps: wrapperProps,
  } = getInputVariantProps(variant);
  return (
    <FormControl
      position={isErrorAbsolute && 'relative'}
      w="100%"
      {...containerProps}
      {...wrapperProps}
    >
      <FormLabel label={label} name={input.name} {...lProps} {...labelProps} />
      <Input
        {...input}
        id={input.name}
        placeholder={placeholder}
        _placeholder={{ color: 'dark.mid' }}
        boxShadow="0 0 8px 0 rgba(71, 181, 189, 0.1)"
        borderWidth={
          hasError && variant === INPUT_VARIANTS.ROUNDED ? '2px' : '1px'
        }
        borderColor={borderColor}
        borderStyle={isBordered || hasError ? 'solid' : 'none'}
        _focus={{
          borderColor:
            hasError && variant === INPUT_VARIANTS.ROUNDED && 'red.solid',
          boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.1)',
        }}
        _hover={{
          borderColor:
            hasError && variant === INPUT_VARIANTS.ROUNDED && 'red.solid',
          boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.1)',
        }}
        {...inputProps}
        onChange={e => {
          input.onChange(e.target.value);
          if (typeof onAfterChange === 'function') {
            onAfterChange(e.target.value);
          }
        }}
        {...props}
        onBlur={e => {
          input.onBlur(e.target.value);
          if (typeof onAfterBlur === 'function') {
            onAfterBlur(e.target.value);
          }
        }}
        {...(typeof onOnlyBlur === 'function' && { onBlur: onOnlyBlur })}
      />
      {!hideError && (
        <FormError
          isErrorAbsolute={isErrorAbsolute}
          meta={meta}
          {...errorStyle}
        />
      )}
    </FormControl>
  );
};

export default TextInput;
