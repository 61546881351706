import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import CostRow from '@pages/Brief/CostRow';

export const StickyFooter = ({ children, isSmallerScreen, ...props }) => (
  <Box
    position="fixed"
    bottom={0}
    marginBottom="0"
    zIndex={101}
    width="calc(100% - 92px)"
    bg="white"
    px="40px"
    left="92px"
    boxShadow="0px 0px 24px rgba(0, 0, 0, 0.15)"
    height="72px"
    py="16px"
    {...props}
  >
    {children}
  </Box>
);

const StickyFooterCostBox = ({
  infoTooltipLabel,
  currency,
  title,
  hours,
  cost,
  subText,
  mainNameQA,
  submitAction = null,
  isSmallerScreen,
  ...props
}) => {
  const row = (
    <CostRow
      currency={currency}
      mt="24px"
      title={title}
      hours={hours}
      subText={subText}
      my="0"
      py="0"
      cost={cost}
      border="none"
      px={0}
      height="40px"
      infoTooltipLabel={infoTooltipLabel}
      mainNameQA={mainNameQA}
      alignItems="inherit"
      w="100%"
      maxW="1336px"
      mx="auto"
      {...props}
    />
  );

  return (
    <StickyFooter pl="230px" isSmallerScreen={isSmallerScreen}>
      {submitAction ? (
        <Flex w="100%" maxW="1336px" justifyContent="space-between">
          {row}
          {submitAction}
        </Flex>
      ) : (
        row
      )}
    </StickyFooter>
  );
};

export default StickyFooterCostBox;
