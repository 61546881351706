import { Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import styled from '@emotion/styled';
import Typography from '@styles/Typography';
import React from 'react';

const getBadgeProps = userAccessible => {
  if (!userAccessible) {
    return {
      label: 'Has no access',
      icon: 'close',
      color: '#fff',
      bg: 'blue.solid',
    };
  }
  return {
    label: 'Has Access',
    icon: 'check',
    bg: 'yellow.solid',
    color: 'black',
  };
};

const Wrapper = styled(Flex)`
  top: -1px;
  right: -1px;
`;

const UserCardBadge = ({ userAccessible, styleProps }) => {
  const { label, icon, bg, color = 'white' } = getBadgeProps(userAccessible);
  return (
    <Wrapper
      borderTopRightRadius="8px"
      borderBottomLeftRadius="8px"
      bg={bg}
      pr="16px"
      pl="6px"
      py="10px"
      align="center"
      justify="center"
      {...styleProps}
    >
      <Icon name={icon} mr="6px" width="16px" color={color} />
      <Typography variant="text3" color={color}>
        {label}
      </Typography>
    </Wrapper>
  );
};

export default UserCardBadge;
