import { first, isFinite, split, toNumber } from 'lodash-es';
import moment from 'moment';

export const getPaymentReference = (project, paymentNumber, date) =>
  `${project.name} ${paymentNumber} ${moment.utc(date).format('YYYY/MM')}`;

export const getDueDaysTerms = (paymentSchedule = {}, clientDays) => {
  const isInvoiceScheduled = !!paymentSchedule.id;
  let terms;
  if (paymentSchedule.paymentTerms === 'Client + 7') {
    terms = clientDays + 7;
  } else {
    terms = toNumber(first(split(paymentSchedule.paymentTerms, ' days')));
  }
  if (isFinite(terms) && isInvoiceScheduled) {
    return terms;
  }
  return 30;
};
