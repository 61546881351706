/* eslint-disable */

export const DEFAULT_CLIENT_SOW_INSTRUCTIONS = {
  leftSideItems: [
    `For Startups and Scaleups only 1 developer option provided with minimum term of 3 months`,
    `For Agency minimum term of 2 weeks`,
    `Please complete all yellow highlighted sections in [] with specific project detail. 
    Once detail has been added please ensure brackets and highlight format are removed before sharing`,
    `All dates to be in written format eg. 1st January 2021`,
    `Project start date <BoldSpan>ALWAYS AT LEAST 10</BoldSpan> working days from sending deposit invoice 
    (and SOW signing) to allow adequate time for<BoldSpan> payment prior to starting for Startups, Scale ups and SMEs</BoldSpan>`,
  ],

  rightSideItems: [
    `For Agencies projects can commence prior to deposit payment being
    received`,
    `Payments details section to be updated to align with client type and
    payment terms agreed in Terms Startups & Scaleups in Orange, Agency
    in Pink Delete as appropriate. Do not change payment details unless
    explicitly agreed with the Leadership Team.`,
    `Follow SOW review process (see reference pack)`,
  ],
};

export const DEFAULT_SUPPLIER_SOW_INSTRUCTIONS = {
  referenceLink: '',
  leftSideItems: [
    `For Startups and Scaleups only 1 team member option provided with
    minimum term of 3 months`,
    `For Agency minimum term of 2 weeks`,
    `Please complete all yellow highlighted sections in [] with
    specific project detail, as per the{' '}
    <BoldSpan>CLIENT SOW</BoldSpan>. Once detail has been added please
    ensure brackets and highlight format are removed before sharing`,
  ],
  rightSideItems: [
    `All dates to be in written format eg. 1st January 2021`,
    `If Agency client, remove deposit clauses in payment schedule`,
    `Follow SOW review process (see reference pack)`,
  ],
};

export const FIXED_PRICE_CLIENT_SOW_INSTRUCTIONS = {
  leftSideItems: [
    `This template is for Scale ups, SMEs, Enterprise & Agency where the development effort is <BoldSpan>10 days OR MORE</BoldSpan> 
     of development work (1 full sprint (10 days) or more) estimated`,
    `This template is <BoldSpan>NOT APPLICABLE</BoldSpan> for Startups - refer to MVP Template for Fixed Price`,
    `Please complete all <YellowHighlight>yellow highlighted sections in []</YellowHighlight> with specific project detail.
    Once detail has been added please ensure brackets and highlight format are removed before sharing`,
  ],

  rightSideItems: [
    'Product & Design time to be included based on client type (see reference pack)',
    'Payments details section to be updated to align with client type and payment terms agreed in Terms',
    'Do not change % payments unless explicitly agreed with Leadership Team.',
    'Follow SOW review process (see reference pack)',
  ],
};

export const FIXED_PRICE_SUPPLIER_SOW_INSTRUCTIONS = {
  referenceLink: '',
  leftSideItems: [
    `This template is for Scale ups, SMEs, Enterprise & Agency where the development effort is <BoldSpan>10 days OR MORE</BoldSpan> of  development work (1 full sprint (=10 days) or more) estimated`,
    `This template is <BoldSpan>NOT APPLICABLE</BoldSpan> for Startups - refer to Start up fixed priced Template`,
    `Please complete all <YellowHighlight>yellow highlighted sections in []</YellowHighlight> with specific project detail, as per the <BoldSpan>CLIENT SOW</BoldSpan>. Once detail has been added please ensure brackets and highlight format are removed before sharing`,
  ],
  rightSideItems: [
    'All dates to be in written format eg. 1st January 2021',
    'Follow SOW review process (see reference pack)',
  ],
};

export const CONTINUOUS_IMPROVEMENT_CLIENT_SOW_INSTRUCTIONS = {
  leftSideItems: [
    `This template is for <BoldSpan>ALL</BoldSpan> client types, looking for a Continuous Improvement retainer to support on NEW feature development, bug fixing and ongoing improvements
      to existing functionality`,
    `<BoldSpan>Minimum developer allocation of 0.5 Full Time Dec (80 hours)
      plus PO/QA roles</BoldSpan>`,
    `Product Management should be included for all clients, <BoldSpan>EXCEPT</BoldSpan> Agency clients`,
  ],

  rightSideItems: [
    `Please complete <YellowHighlight>all yellow highlighted sections in []</YellowHighlight> with specific project detail. Once detail has been added please
      ensure brackets and highlight format are removed before sharing`,
    'All dates to be in written format eg. 1st January 2021',
    'Payments details section to be updated to align with client type and payment terms agreed in Terms',
    'Follow SOW review process (see reference pack)',
  ],
};

export const CONTINUOUS_IMPROVEMENT_SUPPLIER_SOW_INSTRUCTIONS = {
  referenceLink: '',
  leftSideItems: [
    `This template is for <BoldSpan>ALL</BoldSpan> client types, looking for a Continuous Improvement retainer to support on NEW feature development, bug fixing and ongoing improvements
      to existing functionality`,
    `<BoldSpan>Minimum developer allocation of 0.5 Full Time Dec plus QA roles (80hours)</BoldSpan>`,
    `Please complete all <YellowHighlight>yellow highlighted sections in []</YellowHighlight> with specific project detail, as per the <BoldSpan>CLIENT SOW</BoldSpan>. Once detail has been added please
      ensure brackets and highlight format are removed before sharing`,
  ],
  rightSideItems: [
    'All dates to be in written format eg. 1st January 2021',
    'Follow SOW review process (see reference pack)',
  ],
};
