import { Box, Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import styled from '@emotion/styled';
import Button from '@styles/Button';
import { COLORS, FONTS } from '@styles/theme';
import useMediaQuery from '@hooks/useMediaQuery';
import React from 'react';
import { css } from '@emotion/react';
import ReactPaginate from 'react-paginate';

const Styles = styled(({ isSmallerScreen, ...props }) => <Flex {...props} />)`
  display: inline-flex;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 13px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.15);

  ${({ isSmallerScreen }) =>
    isSmallerScreen &&
    css`
      box-shadow: none;
      max-width: 368px;
    `};
  ul {
    display: flex;
    align-items: center;
    font-family: ${FONTS.WS};
    li {
      display: flex;
      align-items: center;
      cursor: pointer;
      &.active {
        a {
          background: ${COLORS.primary[200]};
          cursor: default;
        }
      }
      :not(.active):hover {
        a {
          background: ${COLORS.primary[300]};
        }
      }
      transition: all 0.5s;
      a {
        ${({ isSmallerScreen }) =>
          isSmallerScreen
            ? css`
                padding: 0;
                width: 24px;
                margin: 0 2px;
              `
            : css`
                padding: 5px 6px;
                margin: 0 6px;
              `};
        outline: none;
        border-radius: 8px;
      }
      &.next,
      &.previous {
        a {
          padding: 0px;
        }
      }
    }
  }
`;

const ArrowButton = props => (
  <Button
    iconButton={{ name: 'arrowLeft', fontSize: 24 }}
    minW="auto"
    h="25px"
    bg="#fff"
    _active={{
      bg: '#fff',
    }}
    {...props}
  />
);

const DoubleArrowButton = ({ rotate = false, ...props }) => (
  <Button
    _hover={{ bg: '#fff' }}
    _active={{ bg: '#fff' }}
    variant="ghost"
    px="0px"
    {...props}
  >
    <Icon
      color="dark.solid"
      name="arrowLeft"
      fontSize={24}
      mr="-8px"
      {...(rotate && { transform: 'rotate(180deg)' })}
    />
    <Icon
      color="dark.solid"
      name="arrowLeft"
      fontSize={24}
      ml="-8px"
      {...(rotate && { transform: 'rotate(180deg)' })}
    />
  </Button>
);

const Pagination = ({ onPageChange, containerProps, ...props }) => {
  const { forcePage, pageCount } = props;

  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const prevDisabled = +forcePage + 1 === 1;
  const nextDisabled = +forcePage + 1 === pageCount;
  const isShown = +pageCount > 1;
  return (
    <Box mt="auto" textAlign="center" {...containerProps}>
      {isShown && (
        <Styles mb="10px" isSmallerScreen={isSmallerScreen}>
          <DoubleArrowButton
            onClick={() => onPageChange(1)}
            isDisabled={prevDisabled}
            width={isSmallerScreen && '20px'}
            data-test-id="doublePrev"
            _hover={{
              opacity: 1,
              color: prevDisabled ? 'dark.mid' : COLORS.primary[300],
              cursor: prevDisabled ? 'not-allowed' : 'pointer',
              bg: '#fff',
            }}
          />
          <ReactPaginate
            previousLabel={
              <ArrowButton
                width={isSmallerScreen && '8px'}
                color={prevDisabled ? 'dark.mid' : 'dark.solid'}
                data-test-id="singlePrev"
                _hover={{
                  opacity: 1,
                  cursor: prevDisabled ? 'not-allowed' : 'pointer',
                  bg: '#fff',
                }}
              />
            }
            nextLabel={
              <ArrowButton
                width={isSmallerScreen && '8px'}
                transform="rotate(180deg)"
                data-test-id="singleNext"
                color={nextDisabled ? 'dark.mid' : 'dark.solid'}
                _hover={{
                  opacity: 1,
                  cursor: nextDisabled ? 'not-allowed' : 'pointer',
                }}
              />
            }
            breakLabel="..."
            marginPagesDisplayed={!isSmallerScreen && 2}
            pageRangeDisplayed={5}
            onPageChange={({ selected }) => onPageChange(+selected + 1)}
            activeClassName="active"
            {...props}
          />
          <DoubleArrowButton
            data-test-id="doubleNext"
            rotate
            width={isSmallerScreen && '20px'}
            onClick={() => onPageChange(pageCount)}
            isDisabled={nextDisabled}
          />
        </Styles>
      )}
    </Box>
  );
};

export default Pagination;
