import React from 'react';
import { Box } from '@chakra-ui/react';
import { map, take } from 'lodash-es';
import InfiniteScroll from 'react-infinite-scroller';

const SyncInfiniteScroller = ({ items, pageSize = 5, renderItem }) => {
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    setPage(1);
  }, [items]);

  const hasMore = React.useMemo(() => {
    return page * pageSize < items.length;
  }, [items, page, pageSize]);

  const calculatedItems = React.useMemo(() => {
    return take(items, page * pageSize);
  }, [items, page]);

  const onLoadMore = () => {
    setPage(p => p + 1);
  };

  if (items?.length > 0) {
    return (
      <Box>
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          loadMore={onLoadMore}
          threshold={1500}
          hasMore={hasMore}
        >
          {map(calculatedItems, item => renderItem(item))}
        </InfiniteScroll>
      </Box>
    );
  }
  return null;
};

export default SyncInfiniteScroller;
