import { get } from 'lodash-es';
import React from 'react';
import { Field, useForm } from 'react-final-form';
import { isFixedPrice } from '@utils/projectUtils';
import moment from 'moment';
import useMediaQuery from '@hooks/useMediaQuery';
import { Box } from '@chakra-ui/react';
import { INPUT_VARIANTS } from '@styles/theme';
import {
  cellStyle,
  recalculateProjectEndDateOnAllocationChange,
} from './allocationsHelpers';
import FormSpyFieldValues from '../forms/FormSpyFieldValues';
import DatePicker from '../forms/DatePicker';

const AllocationEntryDatesFields = ({
  startDateFieldName,
  endDateFieldName,
  isStartDateDisabled,
  isEndDateDisabled,
  mainNameQA,
  isNotCloning,
  borderDateForAllocation,
  isOldRow,
}) => {
  const isDesktop = useMediaQuery('(min-width: 1620px)');

  const getEarliestStartDate = (isNewProject, startDate, borderDate) => {
    if (!isNewProject && borderDate) {
      return moment.max(moment.utc(borderDate), moment.utc(startDate));
    }
    return moment.utc(startDate);
  };
  const formApi = useForm();
  return (
    <>
      <FormSpyFieldValues
        fieldNames={[
          'startDate',
          'endDate',
          endDateFieldName,
          'engagementType',
          'projectType',
          'isNewProject',
        ]}
      >
        {({ startDate, endDate, isNewProject, ...values }) => {
          if (!isFixedPrice(values)) {
            return null;
          }
          const allocationEndDate = get(values, endDateFieldName);
          const earliestStartDate = getEarliestStartDate(
            isNewProject,
            startDate,
            borderDateForAllocation,
          );

          const startDateDisableFn = day =>
            (!isNewProject &&
              moment
                .utc(earliestStartDate)
                .startOf('day')
                .isAfter(moment.utc(day))) ||
            moment.utc(day).isAfter(moment.utc(endDate).endOf('day')) ||
            (allocationEndDate &&
              moment
                .utc(day)
                .isAfter(moment.utc(allocationEndDate).endOf('day')));

          return (
            <Box
              alignSelf="center"
              sx={cellStyle(150)}
              h="100%"
              position="relative"
              borderRight="1px solid"
              borderColor="dark.mid"
            >
              <Field
                name={startDateFieldName}
                variant={INPUT_VARIANTS.CONTAINER_FILLED}
                data-test-id={`${mainNameQA}--${startDateFieldName}`}
                disabledDaysFn={startDateDisableFn}
                dayPickerProps={{
                  ...(earliestStartDate &&
                    moment.utc(earliestStartDate).isValid() && {
                      month: moment.utc(earliestStartDate).toDate(),
                    }),
                }}
                disabled={isStartDateDisabled}
                placeholder={isDesktop ? 'Select date' : 'Select'}
                minW="auto"
                containerProps={{ h: '100%' }}
                h="100%"
                calendarProps={{ top: '13px' }}
                calendarColor="#3366ff"
                onAfterChange={() => {
                  if (isNewProject) {
                    recalculateProjectEndDateOnAllocationChange(formApi);
                  }
                }}
              >
                {props => {
                  const shouldBeDisabled =
                    borderDateForAllocation &&
                    props.input.value &&
                    isNotCloning &&
                    isOldRow
                      ? moment
                          .utc(props.input.value)
                          .isBefore(
                            moment.utc(borderDateForAllocation).startOf('day'),
                          )
                      : false;

                  return (
                    <DatePicker
                      {...props}
                      disabled={props.disabled || shouldBeDisabled}
                    />
                  );
                }}
              </Field>
            </Box>
          );
        }}
      </FormSpyFieldValues>
      <FormSpyFieldValues
        fieldNames={[
          'startDate',
          'endDate',
          startDateFieldName,
          'engagementType',
          'projectType',
          'isNewProject',
        ]}
      >
        {({ startDate, isNewProject, ...values }) => {
          if (!isFixedPrice(values)) {
            return null;
          }

          const allocationStartDate = get(values, startDateFieldName);

          const earliestStartDate = getEarliestStartDate(
            isNewProject,
            startDate,
            borderDateForAllocation,
          );

          const endDateDisableFn = day =>
            moment
              .utc(earliestStartDate)
              .startOf('day')
              .isAfter(moment.utc(day)) ||
            (allocationStartDate &&
              moment.utc(day).isBefore(moment.utc(allocationStartDate)));

          return (
            <Box
              alignSelf="center"
              sx={cellStyle(150)}
              h="100%"
              position="relative"
              borderRight="1px solid"
              borderColor="dark.mid"
            >
              <Field
                name={endDateFieldName}
                variant={INPUT_VARIANTS.CONTAINER_FILLED}
                data-test-id={`${mainNameQA}--${endDateDisableFn}`}
                component={DatePicker}
                disabledDaysFn={endDateDisableFn}
                disabled={isEndDateDisabled}
                placeholder={isDesktop ? 'Select date' : 'Select'}
                dayPickerProps={{
                  ...(allocationStartDate &&
                    moment.utc(allocationStartDate).isValid() && {
                      month: moment.utc(allocationStartDate).toDate(),
                    }),
                }}
                minW="auto"
                containerProps={{ h: '100%' }}
                h="100%"
                calendarProps={{ top: '13px' }}
                calendarColor="#3366ff"
                onAfterChange={() => {
                  if (isNewProject) {
                    recalculateProjectEndDateOnAllocationChange(formApi);
                  }
                }}
              />
            </Box>
          );
        }}
      </FormSpyFieldValues>
    </>
  );
};

export default AllocationEntryDatesFields;
