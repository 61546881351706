import React from 'react';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import {
  required,
  composeValidators,
  mustBeValidUrl,
} from '@utils/formValidators';
import Icon from '@components/Icon';
import { Form, Field } from 'react-final-form';
import { map, includes } from 'lodash-es';
import TextInput from '@components/forms/_common/TextInput';
import { Flex, Box, Link, Spinner } from '@chakra-ui/react';
import Button from '@styles/Button';

const LinkedDocumentRow = ({ document, deleteLinkedDocument }) => (
  <Flex
    alignItems="center"
    minH="56px"
    borderBottom="1px solid"
    borderColor="gray.200"
  >
    <Flex minW="380px" p="15px 12px" mr="24px">
      <Link
        href={
          includes(document.link, 'http') ? document.link : `//${document.link}`
        }
        color="black"
        target="_blank"
        maxW="344px"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        position="relative"
        pr="32px"
      >
        {document.link}
        <Icon name="arrowLongTail" ml="5px" position="absolute" right="7px" />
      </Link>
    </Flex>
    <Box p="15px 12px">
      <Typography>{document.description}</Typography>
    </Box>
    <Button
      ml="auto"
      background="primary.200"
      color="black"
      width="40px"
      iconButton={{
        name: 'trashNew',
      }}
      isLoading={document.isDeleting}
      onClick={deleteLinkedDocument}
    />
  </Flex>
);

const LinkedDocuments = ({
  onSubmit,
  linkedDocuments,
  deleteLinkedDocument,
  isLoadingLinkedDocuments,
  ptsId,
}) => {
  return (
    <WhiteCard p="24px">
      <Typography variant="h3" mb="12px">
        Linked documents
      </Typography>
      <Typography mb="32px">
        Add links to documents pertinent to this statement of work. E.g. Change
        Requests.
      </Typography>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Flex alignItems="flex-end" mb="24px">
              <Flex w="100%">
                <Box marginRight="24px" maxW="400px" w="100%">
                  <Field
                    name="link"
                    component={TextInput}
                    label="Link to external document"
                    rightIcon="arrowLongTail"
                    elementProps={{ pr: '16px' }}
                    pr="40px"
                    placeholder="Add link"
                    validate={composeValidators(required, mustBeValidUrl)}
                  />
                </Box>
                <Box marginRight="24px" maxW="400px" w="100%">
                  <Field
                    name="description"
                    component={TextInput}
                    label="Add a document description"
                    elementProps={{ pr: '16px' }}
                    placeholder="Document description"
                    validate={required}
                  />
                </Box>
              </Flex>
              <Button
                type="submit"
                h="48px"
                ml="auto"
                leftIcon={{ name: 'add' }}
                isLoading={submitting}
              >
                Add
              </Button>
            </Flex>
          </form>
        )}
      />
      <Flex flexDir="column">
        {isLoadingLinkedDocuments && (
          <Spinner alignSelf="center" size="lg" mx="auto" my="30px" />
        )}
        {map(linkedDocuments, ld => (
          <LinkedDocumentRow
            document={ld}
            deleteLinkedDocument={() => deleteLinkedDocument(ptsId, ld.id)}
          />
        ))}
      </Flex>
    </WhiteCard>
  );
};

export default LinkedDocuments;
