import { Tooltip } from '@chakra-ui/react';
import Button from '@styles/Button';
import React from 'react';

const DuplicatePayment = ({ modalIsOpen, ...props }) => {
  return (
    <Tooltip
      zIndex={10000}
      label="Duplicate this invoice"
      hasArrow
      p="12px"
      placement="top"
      hideDelay={500}
      display={modalIsOpen && 'none'}
    >
      <Button
        iconButton={{
          name: 'duplicateNew',
          fontSize: 22,
        }}
        {...props}
      />
    </Tooltip>
  );
};

export default DuplicatePayment;
