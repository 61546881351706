import { Box, Flex, Link as ChakraLink } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import React, { useEffect } from 'react';
import { MAIN_NAMES_QA } from '@app/constants';

const ExpandableBox = ({
  children,
  onAction,
  title,
  actionButtonLabel,
  isDisabled,
  isOpenByDefault = false,
  ...props
}) => {
  const [open, setOpen] = React.useState(isOpenByDefault);

  useEffect(() => {
    if (isDisabled) setOpen(false);
  }, [isDisabled]);

  return (
    <Flex
      flexDir="column"
      border="1px solid"
      borderColor="dark.mid"
      bg="#fff"
      w="284px"
      p="16px 24px 16px 21px"
      {...props}
    >
      <Flex alignItems="center">
        <Flex
          mr="auto"
          onClick={() => !isDisabled && setOpen(o => !o)}
          cursor="pointer"
          alignItems="center"
        >
          {open ? (
            <Box cursor="pointer" py="10px" px="2px">
              <Flex
                w="14px"
                h="2px"
                bg="blue.solid"
                data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--matchingExpander`}
              />
            </Box>
          ) : (
            <Icon
              name="cross"
              transform="rotate(45deg)"
              fontSize={20}
              data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--matchingExpander`}
              color={isDisabled ? 'dark.mid' : 'blue.solid'}
            />
          )}
          <Typography
            variant="h5"
            ml="12px"
            color={isDisabled ? 'dark.mid' : 'black'}
          >
            {title}
          </Typography>
        </Flex>
        {!isDisabled && actionButtonLabel && (
          <ChakraLink
            onClick={onAction}
            as={Typography}
            _hover={{ opacity: 0.7 }}
            color="blue.solid"
            data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--matchingActionButton`}
          >
            {actionButtonLabel}
          </ChakraLink>
        )}
      </Flex>
      {open && (
        <Flex flexDir="column" pt="12px">
          {children}
        </Flex>
      )}
    </Flex>
  );
};

export default ExpandableBox;
