import { Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import TextEllipsisWithTooltip from '@components/TextEllipsisWithTooltip';
import Typography from '@styles/Typography';
import { formatCurrency } from '@utils';
import { prepareCostRange } from '@utils/proposalUtils';
import { lowerCase } from 'lodash-es';
import React from 'react';

const FeatureButton = ({
  isCostPerFeature,
  children,
  isDisabled,
  isActive,
  onClick,
  hours,
  maxCost,
  minCost,
  cost,
  mainNameQA,
  ...props
}) => {
  const { formattedCost, formattedHours } = React.useMemo(() => {
    if (isCostPerFeature) {
      return {
        formattedCost:
          +cost >= 0
            ? prepareCostRange({ cost, maxCost, minCost })
            : formatCurrency(0),
        formattedHours: `${hours} Hours`,
      };
    }
    return {};
  }, [isCostPerFeature, hours, maxCost, minCost, cost]);

  return (
    <Flex
      onClick={isDisabled || isActive ? () => {} : onClick}
      borderRadius="8px"
      display="inline-flex"
      border={isActive ? '2px solid' : '1px solid'}
      borderColor={isActive ? 'blue.solid' : 'dark.mid'}
      cursor={isDisabled || isActive ? 'default' : 'pointer'}
      mb="24px"
      mr="12px"
      alignItems="center"
      w={isCostPerFeature && '100%'}
    >
      <Flex
        w={isCostPerFeature && 'calc(100% - 228px)'}
        mr="auto"
        px="10px"
        justifyContent="space-between"
        {...props}
      >
        {isCostPerFeature ? (
          <TextEllipsisWithTooltip alignSelf="center" py="16px">
            {children}
          </TextEllipsisWithTooltip>
        ) : (
          <Typography variant="h4" py="16px">
            {children}
          </Typography>
        )}
        {!isDisabled && (
          <Icon
            ml="10px"
            alignSelf="center"
            name="info"
            color="blue.solid"
            fontSize={20}
          />
        )}
      </Flex>
      {formattedCost && (
        <>
          <Flex
            w="114px"
            py="16px"
            px="10px"
            h="100%"
            fontSize={14}
            borderRight="1px solid"
            borderLeft="1px solid"
            borderColor="dark.mid"
          >
            <Icon
              color="blue.solid"
              alignSelf="center"
              name="clock"
              fontSize={22}
              mr="8px"
            />
            <TextEllipsisWithTooltip
              variant="text2"
              alignSelf="center"
              mt="4px"
              data-test-id={mainNameQA && `${mainNameQA}--festureHours`}
            >
              {formattedHours}
            </TextEllipsisWithTooltip>
          </Flex>
          <Flex w="114px" fontSize={14} py="16px" px="10px" h="100%">
            <Icon
              color="blue.solid"
              name={lowerCase('gbp')}
              fontSize={22}
              alignSelf="center"
              mr="4px"
            />
            <TextEllipsisWithTooltip
              variant="text2"
              alignSelf="center"
              mt="4px"
              data-test-id={mainNameQA && `${mainNameQA}--festureCost`}
            >
              {formattedCost}
            </TextEllipsisWithTooltip>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default FeatureButton;
