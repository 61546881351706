import { Box, Flex } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const BriefAcceptedModal = ({ isOpen, onClose, projectName, clientName }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Flex flexDir="column" mb="24px">
        <Typography variant="h2" mb="24px" textAlign="center">
          Brief accepted!
        </Typography>
        <Flex justify="space-between" bg="dark.light" p="16px">
          <Box minW="50%" textAlign="center">
            <Typography>
              Project:{' '}
              <Box as="span" fontWeight="bold">
                {projectName}
              </Box>
            </Typography>
          </Box>
          <Box minW="50%" textAlign="center">
            <Typography>
              Client:{' '}
              <Box as="span" fontWeight="bold">
                {clientName}
              </Box>
            </Typography>
          </Box>
        </Flex>
      </Flex>
      <Flex w="100%" mx="auto" flexDirection="column">
        <Typography mb="40px" textAlign="center" color="#000">
          We’ve let Deazy know you’ve accepted this brief.
        </Typography>
        <Typography mb="40px" textAlign="center" color="#000">
          Next, navigate to the ‘Your Proposal’ tab in order to create your
          estimate.
        </Typography>
        <Button variant="outline" onClick={onClose}>
          Close
        </Button>
      </Flex>
    </Modal>
  );
};

export default BriefAcceptedModal;
