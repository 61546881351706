import { Flex } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import { StickyFooter } from '@components/StickyFooterCostBox';
import Button from '@styles/Button';
import Textarea from '@components/forms/_common/Textarea';
import Typography from '@styles/Typography';
import React from 'react';
import { forEach, cloneDeep, isBoolean, map } from 'lodash-es';
import BlueRadioGroup from '@pages/Brief/FindDPs/PerfectDPMatchForm/BlueRadioGroup';
import { mustBeShorterOrEqual } from '@utils/formValidators';
import { inject, observer } from 'mobx-react';
import { Field, Form } from 'react-final-form';
import Badge from '@styles/Badge';
import moment from 'moment';
import { ENGAGEMENT_TYPES } from '@app/constants';
import { DEFAULT_SURVEY_OPTIONS, TA_SURVEY_OPTIONS } from './surveyOptions';

const ProjectSurvey = ({ projectsStore: { project, createProjectSurvey } }) => {
  const isTA = project.engagementType === ENGAGEMENT_TYPES.TEAM_AUGMENTATION;
  const OPTIONS = React.useMemo(
    () => (isTA ? TA_SURVEY_OPTIONS : DEFAULT_SURVEY_OPTIONS),
    [isTA],
  );
  const initialValues = () => {
    const values = {};
    values.projectToSuppliers = map(project.projectToSuppliers, pts => {
      const surveys = {};
      forEach(OPTIONS, opt => {
        surveys[opt.fieldName] = isBoolean(pts[opt.fieldName])
          ? pts[opt.fieldName]
          : 'unknown';
      });
      return {
        id: pts.id,
        supplierId: pts?.supplier?.id,
        surveys,
        projectSurveyComment: pts.projectSurveyComment || '',
      };
    });
    return values;
  };

  const completedAt = React.useMemo(() => {
    return project?.projectSurveySubmitDate
      ? moment(project?.projectSurveySubmitDate).format('D MMM YYYY')
      : null;
  }, [project]);

  const onSubmit = values => {
    const payload = map(cloneDeep(values.projectToSuppliers), pts => {
      const { surveys } = pts;
      Object.keys(surveys).forEach(key => {
        if (surveys[key] === 'unknown') {
          surveys[key] = null;
        }
      });
      if (isTA) {
        surveys.isAccountableTeamAug = surveys.isAccountable;
      } else {
        surveys.isAccountableProject = surveys.isAccountable;
      }
      return {
        ...surveys,
        comment: pts.projectSurveyComment,
        supplierId: pts?.supplierId,
      };
    });
    return createProjectSurvey(project.id, payload);
  };

  return (
    <Flex
      flexDir="column"
      px="24px"
      w="100%"
      mx="auto"
      maxW="1328px"
      pb={['120px', '120px', '120px', '60px']}
      mt="32px"
    >
      <Flex alignItems="center" mb="16px">
        <Typography variant="h2" mr="auto">
          Project Completion Survey
        </Typography>
        {completedAt && (
          <Badge variant="secondary" bg="green">
            {`COMPLETED ${completedAt}`}
          </Badge>
        )}
      </Flex>
      <Typography maxW="664px">
        Now this project has ended please answer the questions below so Deazy
        can improve the service in the future. The questions should only take a
        few moments to answer.
      </Typography>
      <WhiteCard p="40px 32px" mb="80px" border="1px solid #d9dada">
        <Typography variant="h2" mb="16px">
          Review Delivery Partners
        </Typography>
        <Typography mb="24px">
          Firstly, we want to make sure that the competency information we hold
          about the delivery partners on this project matches the experience
          working with them. Please review the answers below, and make any
          adjustments you see fit.
        </Typography>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues()}
          render={({ handleSubmit, submitting }) => {
            const isDisabled = project?.projectSurveySubmitDate || submitting;
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Flex w="100%" flexDir="column">
                  {map(project.projectToSuppliers, (pts, ptsIdx) => (
                    <React.Fragment key={`survey-pts-${pts.id}`}>
                      <Flex flexDir="column" _notFirst={{ mt: '40px' }}>
                        <Typography pb="12px" variant="h4">
                          {pts?.supplier?.name}
                        </Typography>
                        {map(
                          OPTIONS,
                          ({ id, title, fieldName, description }) => (
                            <Flex
                              alignItems="center"
                              key={`survey-${id}-pts-${pts.id}`}
                              borderTop="1px solid"
                              borderColor="dark.mid"
                              pt="30px"
                              pb="24px"
                            >
                              <Flex flexDir="column" mr="auto" pr="8px">
                                <Typography variant="h5" mb="8px">
                                  {title}
                                </Typography>
                                <Typography whiteSpace="pre-line">
                                  {description}
                                </Typography>
                              </Flex>
                              <BlueRadioGroup
                                name={`projectToSuppliers[${ptsIdx}].surveys[${fieldName}]`}
                                isDisabled={isDisabled}
                                choices={[
                                  { label: "Don't know", value: 'unknown' },
                                  { label: 'No', value: false },
                                  { label: 'Yes', value: true },
                                ]}
                              />
                            </Flex>
                          ),
                        )}
                      </Flex>
                      <Typography
                        variant="h5"
                        pt="24px"
                        borderTop="1px solid"
                        mb="8px"
                        borderColor="dark.mid"
                      >
                        Any additional feedback?
                      </Typography>
                      <Field
                        inputProps={{
                          _disabled: { bg: '#F9F9F9', color: '#878380' },
                        }}
                        isDisabled={isDisabled}
                        component={Textarea}
                        name={`projectToSuppliers[${ptsIdx}].projectSurveyComment`}
                        rows={5}
                        placeholder="Please provide any additional information you’d like to share"
                        validate={mustBeShorterOrEqual(2500)}
                      />
                    </React.Fragment>
                  ))}
                </Flex>
                {!isDisabled && (
                  <StickyFooter
                    h="auto"
                    bottom={['88px', '88px', '88px', 0]}
                    width={['100%', '100%', '100%', 'calc(100% - 92px)']}
                    left={[0, 0, 0, '92px']}
                  >
                    <Flex maxW="1328px" mx="auto" justifyContent="flex-end">
                      <Button isLoading={submitting} type="submit">
                        Submit survey
                      </Button>
                    </Flex>
                  </StickyFooter>
                )}
              </form>
            );
          }}
        />
      </WhiteCard>
    </Flex>
  );
};

export default inject('projectsStore')(observer(ProjectSurvey));
