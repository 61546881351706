/* eslint-disable no-plusplus */
import {
  forEach,
  isEmpty,
  isNaN,
  lowerCase,
  toNumber,
  map,
  compact,
} from 'lodash-es';
import { PROPOSAL_STATUSES } from '@app/constants';

export const decorateBrief = brief => {
  const isPPbrief = !brief.briefToSuppliers;
  const briefToSuppliers = brief.briefToSuppliers || brief.ppBriefToSuppliers;

  let proposalsCount = 0;
  let assignedCount = 0;
  let acceptedCount = 0;
  let declinedCount = 0;
  let pendingCount = 0;
  forEach(briefToSuppliers, bts => {
    if (bts.assignedOn) {
      assignedCount++;
    }
    if (bts.status === PROPOSAL_STATUSES.DECLINED) {
      declinedCount++;
    }
    if (bts.status === PROPOSAL_STATUSES.PENDING) {
      pendingCount++;
    }
    if (!isEmpty(bts?.proposal) && (isPPbrief || bts?.proposal.submitted)) {
      proposalsCount++;
    }
    if (isPPbrief && !isEmpty(bts.proposals)) {
      proposalsCount++;
    }
    if (bts.status === PROPOSAL_STATUSES.ACCEPTED) {
      acceptedCount++;
    }
  });
  return {
    proposalsCount,
    assignedCount,
    acceptedCount,
    declinedCount,
    pendingCount,
  };
};

export const decorateBriefForSendbridIds = brief => {
  const btsArray = brief?.briefToSuppliers || brief?.ppBriefToSuppliers;
  const sendbridChannels = compact(
    map(btsArray, item => item.sendbirdChannelId),
  );
  return { sendbridChannels };
};

export const getMarginborderColor = margin => {
  const marg = toNumber(margin);
  if (isNaN(marg)) {
    return 'dark.mid';
  }
  if (marg >= 30) {
    return 'green.solid';
  }
  if (marg <= 30 && marg >= 25) {
    return 'yellow.solid';
  }
  return 'red.solid';
};

export const numberColorFn = (isCompleted, isCurrent) => {
  if (isCurrent) {
    return 'primary.400';
  }
  if (isCompleted) {
    return '#FFF';
  }
  return 'gray.200';
};

export const bgColorFn = (isCompleted, isCurrent) => {
  if (isCurrent) {
    return '#fff';
  }
  if (isCompleted) {
    return 'primary.400';
  }
  return 'gray.100';
};

export const textColorFn = (isCompleted, isCurrent) => {
  if (isCurrent) {
    return 'inherit';
  }
  if (isCompleted) {
    return 'primary.400';
  }
  return 'gray.200';
};

export const getPnpResourceCurrencyRate = (
  resource,
  brief,
  currencyRateEur,
  currencyRateUsd,
) => {
  let currencyRate = 1;
  if (lowerCase(resource?.ppBriefProposal?.overriddenCurrency) === 'eur') {
    currencyRate =
      currencyRateEur || brief?.deazyProposalInfo?.currencyRateEur || 1;
  }
  if (lowerCase(resource?.ppBriefProposal?.overriddenCurrency) === 'usd') {
    currencyRate =
      currencyRateUsd || brief?.deazyProposalInfo?.currencyRateUsd || 1;
  }
  return currencyRate;
};
