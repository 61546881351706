import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { Field, Form } from 'react-final-form';
import Select from '@components/forms/_common/Select';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import Button from '@styles/Button';
import { required } from '@utils/formValidators';
import { inject, observer } from 'mobx-react';
import InfoAlert from '@components/InfoAlert';
import { ErrorText } from '@components/forms/_common/FormError';
import { ENGAGEMENT_TYPES, PROJECT_TYPES } from '@app/constants';
import Icon from '@components/Icon';
// import { RightIconInput } from '@components/forms/MaskedTextInput';
import Tooltip from '@styles/Tooltip';
import RadioGroupField from '@components/forms/RadioGroupField';
import { compact, map, cloneDeep } from 'lodash-es';
import moment from 'moment';
import PaymentSplitTable from './PaymentScheduleTable';

const PaymentScheduleForm = ({
  initialValues,
  isClientTab,
  project,
  projectToSupplier,
  currency,
  isSowFinalised,
  updateSowTemplate,
  sowPaymentScheduleStore: {
    isCoping,
    copiedPaymentData,
    updateManualTable,
    copyClientScheduleToSupplier,
    createOrUpdatePaymentSchedule,
  },
  sowsStore: { isLoading },
}) => {
  const [isManual, setIsManual] = React.useState(false);

  React.useEffect(() => {
    setIsManual(
      isClientTab
        ? project.manualClientSchedule
        : projectToSupplier?.manualSchedule,
    );
  }, [isClientTab, projectToSupplier]);

  if (project.projectType !== PROJECT_TYPES.FIXED_PRICE) {
    return null;
  }
  const isProjectEngagementType =
    project.engagementType === ENGAGEMENT_TYPES.PROJECT_BUILD;

  const isPnPType =
    project.engagementType === ENGAGEMENT_TYPES.TEAM_AUGMENTATION;

  const PaymentTermsLabel = (
    <Box>
      Invoice terms
      <Tooltip label="This timeframe is linked to client terms">
        <Box as="span" ml="10px">
          <Icon name="infoFilled" color="gray.mid" mt="-2px" />
        </Box>
      </Tooltip>
    </Box>
  );

  return (
    <WhiteCard mb="40px">
      <Flex w="100%" justifyContent="space-between">
        <Box>
          <Typography variant="h3" mb="12px">
            Invoice Schedule Tool
          </Typography>
          <Typography mb="24px">
            {isManual
              ? 'You have chosen to populate the table manually'
              : 'Use this tool to to generate invoices within the Statment of work'}
          </Typography>
        </Box>
        {!isClientTab &&
          isProjectEngagementType &&
          !isManual &&
          !project.deazyAsClient &&
          !project.manualClientSchedule && (
            <Button
              leftIcon={{ fontSize: 24, name: 'sync' }}
              variant="outline"
              size="lg"
              isLoading={isCoping}
              isDisabled={isLoading || isSowFinalised}
              onClick={() =>
                copyClientScheduleToSupplier(project.id, projectToSupplier.id)
              }
            >
              Sync figures from client side
            </Button>
          )}
      </Flex>

      <Form
        onSubmit={values =>
          createOrUpdatePaymentSchedule(values, updateSowTemplate)
        }
        initialValues={cloneDeep({ ...initialValues, ...copiedPaymentData })}
        render={({ handleSubmit, submitError, form, values, submitting }) => {
          const onInvoiceCreationTimeChange = value => {
            const isOnFirst = value === '1st of the month (in advance)';
            // omit last row as it's empty
            const items = (values.paymentScheduleItems || []).slice(0, -1);
            form.change(
              'paymentScheduleItems',
              map(items, p => ({
                ...p,
                invoiceDate: moment
                  .utc(isOnFirst ? p.periodStart : p.periodEnd)
                  .format(),
              })),
            );
          };

          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              {!isManual && (
                <>
                  <Flex mb="24px">
                    {isClientTab ? (
                      <Field
                        component={Select}
                        name="paymentTerms"
                        options={[
                          { label: '14 days', value: '14 days' },
                          { label: '30 days', value: '30 days' },
                          { label: '60 days', value: '60 days' },
                        ]}
                        validate={required}
                        isDisabled={isSowFinalised}
                        label={PaymentTermsLabel}
                        styleProps={{ maxW: '256px', mr: '40px' }}
                      />
                    ) : (
                      <Flex>
                        <Field
                          component={Select}
                          name="paymentTerms"
                          options={compact([
                            !project.deazyAsClient && {
                              label: 'Client + 7',
                              value: 'Client + 7',
                            },
                            { label: '14 days', value: '14 days' },
                            { label: '30 days', value: '30 days' },
                            { label: '60 days', value: '60 days' },
                          ])}
                          validate={required}
                          isDisabled={isSowFinalised}
                          label={PaymentTermsLabel}
                          styleProps={{ maxW: '256px', mr: '40px' }}
                        />
                        {/* HIDE TEMPORARLY */}
                        {/* <Box maxW="112px" w="100%" mx="40px">
                          <Field
                            label="Additional days"
                            name="additionalDays"
                            decimalScale={0}
                            fixedDecimalScale
                            isAllowed={({ floatValue }) =>
                              (floatValue < 1000 && floatValue >= 0) ||
                              !floatValue
                            }
                            component={RightIconInput}
                            iconName="add"
                            rightPlacement={false}
                            iconProps={{
                              top: '43px',
                              color: 'blue.solid',
                              fontSize: 14,
                            }}
                            pr="12px"
                            pl="32px"
                          />
                        </Box> */}
                      </Flex>
                    )}
                    {!isProjectEngagementType && (
                      <Flex flexDir="column">
                        <Typography variant="text2" mb="20px">
                          Create invoice on the...
                        </Typography>
                        <Field
                          name="invoiceCreationTime"
                          isDisabled={isSowFinalised}
                          component={RadioGroupField}
                          initialValue="1st of the month (in advance)"
                          validate={required}
                          onAfterChange={onInvoiceCreationTimeChange}
                          choices={[
                            {
                              label: '1st of the month (In advance)',
                              value: '1st of the month (in advance)',
                            },
                            {
                              label: 'Last day of the month (In arrears)',
                              value: 'Last day of the month (in arrears)',
                            },
                          ]}
                        />
                      </Flex>
                    )}
                  </Flex>
                  {isPnPType && (
                    <InfoAlert label="Amounts are calculated by day rate x Mon-Fri days in month" />
                  )}
                  <PaymentSplitTable
                    paymentScheduleItems={values.paymentScheduleItems}
                    currency={currency}
                    maxValue={values.maxValue}
                    formApi={form}
                    engagementType={project.engagementType}
                    isReadOnly={isSowFinalised}
                  />
                  {submitError && (
                    <ErrorText my="12px">{submitError}</ErrorText>
                  )}
                </>
              )}

              <Flex mt={isManual ? '24px' : '40px'}>
                {!isManual && (
                  <Button
                    leftIcon={{
                      name: values.id ? 'sync' : 'download2',
                      fontSize: 20,
                    }}
                    type="submit"
                    isLoading={submitting}
                    isDisabled={isSowFinalised || isLoading}
                  >
                    {values.id ? 'Update table' : 'Populate table'}
                  </Button>
                )}

                {isManual && (
                  <Button
                    onClick={() => {
                      setIsManual(false);
                      updateManualTable({
                        projectId: values.projectId,
                        projectToSupplierId: !isClientTab
                          ? projectToSupplier?.id
                          : null,
                        manualTable: false,
                      });
                    }}
                    leftIcon={{
                      name: 'arrowLongTail',
                      transform: 'rotate(225deg)',
                      fontSize: 14,
                    }}
                    isDisabled={isSowFinalised}
                  >
                    Back and use schedule tool
                  </Button>
                )}
                {/* {!isManual && (
                  <Button
                    variant="ghost"
                    onClick={() => {
                      updateManualTable({
                        projectId: values.projectId,
                        projectToSupplierId: !isClientTab
                          ? projectToSupplier?.id
                          : null,
                        manualTable: true,
                      });
                      setIsManual(true);
                    }}
                    isDisabled={isSowFinalised}
                  >
                    I want to create table manually instead
                  </Button>
                )} */}
              </Flex>
            </form>
          );
        }}
      />
    </WhiteCard>
  );
};

export default inject(
  'sowPaymentScheduleStore',
  'sowsStore',
)(observer(PaymentScheduleForm));
