import RadioGroupField from '@components/forms/RadioGroupField';
import React from 'react';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import { Field } from 'react-final-form';
import { Flex } from '@chakra-ui/react';

const EmployeeTypeRadio = ({
  isDisabled,
  fieldName,
  supplierName = 'DP',
  ...props
}) => {
  return (
    <Flex flexDir="column" maxW="360px" w="100%" {...props}>
      <Typography mb="8px" variant="text2">
        Candidate is
      </Typography>
      <WhiteCard
        bg={isDisabled ? 'dark.light' : 'white'}
        mt="0px"
        p="22px 14px 4px 14px"
        border="1px solid"
        borderColor="dark.mid"
      >
        <Field
          name={fieldName}
          component={RadioGroupField}
          isDisabled={isDisabled}
          isVertical
          defaultValue="Employed by"
          choices={[
            {
              label: `Employed by ${supplierName}`,
              value: 'Employed by',
            },
            {
              label: `Employee of sister/partner of ${supplierName}`,
              value: 'Employed by Sister/Partner of',
            },
            {
              label: `Freelance/sub-contractor for ${supplierName}`,
              value: 'Freelance/sub-contractor of',
            },
          ]}
        />
      </WhiteCard>
    </Flex>
  );
};

export default EmployeeTypeRadio;
