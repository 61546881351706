import { Box, Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import WhiteCard from '@components/WhiteCard';
import { ENGAGEMENT_TYPES, PROJECT_TYPES } from '@app/constants';
import FormSpyFieldValues from '@components/forms/FormSpyFieldValues';
import React from 'react';
import Button from '@styles/Button';
import {
  getAllocationMultiplier,
  getClientAllocationTotal,
  isProjectBuild,
  isFixedPriceProjectType,
} from '@utils/projectUtils';
import { formatNumber } from '@utils';
import FixedPriceDeazyAllocations from './FixedPriceDeazyAllocations';
import DeazyAllocations from './DeazyAllocations';
import SubmitFailedError from './SubmitFailedError';
import CostRow from '../Brief/CostRow';

const DeazyCard = ({ mainNameQA }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  return (
    <WhiteCard>
      <Flex alignItems="center">
        <FormSpyFieldValues fieldNames={['engagementType']}>
          {({ engagementType }) => (
            <Typography variant="h3" mr="auto">
              {`${
                isProjectBuild(engagementType) ? 'Total' : 'Weekly'
              } Deazy Allocation`}
              <Typography as="span" ml="8px" variant="h3" color="#878380">
                (optional)
              </Typography>
            </Typography>
          )}
        </FormSpyFieldValues>
        <Button
          borderRadius="8px"
          fontSize={30}
          bg="primary.100"
          iconButton={{ name: 'arrowLeft' }}
          transform={isOpen ? 'rotate(90deg)' : 'rotate(-90deg)'}
          onClick={() => setIsOpen(!isOpen)}
        />
      </Flex>
      {isOpen && (
        <Box bg="dark.light" p="32px" borderRadius="8px" mt="40px">
          <FormSpyFieldValues fieldNames={['projectType', 'engagementType']}>
            {({ projectType, engagementType }) => {
              if (
                projectType === PROJECT_TYPES.FIXED_PRICE &&
                engagementType === ENGAGEMENT_TYPES.PROJECT_BUILD
              ) {
                return (
                  <FixedPriceDeazyAllocations
                    resourceAsText
                    mainNameQA={mainNameQA}
                  />
                );
              }
              return (
                <>
                  <DeazyAllocations
                    fieldName="deazyAllocations"
                    mainNameQA={mainNameQA}
                    resourceAsText
                  />
                  <SubmitFailedError fieldName="deazyAllocation" mt="0px" />
                </>
              );
            }}
          </FormSpyFieldValues>
        </Box>
      )}
      <FormSpyFieldValues
        fieldNames={[
          'deazyAllocations',
          'deazyAllocations1',
          'deazyAllocations2',
          'deazyAllocations3',
          'startDate',
          'endDate',
          'totalBasis',
          'currency',
          'projectType',
          'engagementType',
        ]}
      >
        {values => {
          const allocationMultiplier = React.useMemo(() => {
            const multiplier = getAllocationMultiplier(values);
            return values.totalBasis ? multiplier : 1;
          }, [values.startDate, values.endDate, values.totalBasis]);
          const deazyAllocations = isFixedPriceProjectType(values)
            ? [
                ...(values.deazyAllocations1 || []),
                ...(values.deazyAllocations2 || []),
                ...(values.deazyAllocations3 || []),
              ]
            : values.deazyAllocations || [];
          const total = getClientAllocationTotal(
            { deazyAllocations },
            allocationMultiplier,
          );

          return (
            <CostRow
              mt="24px"
              bg="#fff"
              title="Total amount"
              currency={values?.currency}
              cost={formatNumber(total)}
              showHours={false}
              mainNameQA={mainNameQA}
              id="costForDeazy"
            />
          );
        }}
      </FormSpyFieldValues>
    </WhiteCard>
  );
};

export default DeazyCard;
