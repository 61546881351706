import { PROJECT_STATUSES } from '@app/constants';
import FiltersFormSidebar from '@components/FiltersFormSidebar';
import React from 'react';

const SORT_OPTIONS = [
  {
    label: 'Creation date (new to old)',
    value: 'orderDirection=DESC&orderBy=createdAt',
  },
  {
    label: 'Creation date (old to new)',
    value: 'orderDirection=ASC&orderBy=createdAt',
  },
];

const ProjectsFilters = ({
  onSubmit,
  onResetFilters,
  initialValues,
  isAdminOrDL,
  isLoading,
  isMergingView,
}) => {
  const fieldsToShow = React.useMemo(() => {
    if (isMergingView) {
      return ['suppliers'];
    }
    if (isAdminOrDL) {
      return ['actionType', 'suppliers', 'ragStatus'];
    }
    return ['ragStatus'];
  }, [isMergingView, isAdminOrDL]);

  return (
    <FiltersFormSidebar
      onSubmit={onSubmit}
      onReset={onResetFilters}
      initialValues={initialValues}
      showStatusFilters={!isMergingView}
      sortOptions={SORT_OPTIONS}
      statusOptions={PROJECT_STATUSES}
      fieldsToShow={fieldsToShow}
      loading={isLoading}
    />
  );
};

export default ProjectsFilters;
