import Typography from '@styles/Typography';
import { map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form } from 'react-final-form';
import AutoSave from '../Proposal/AutoSave';
import TableHeadings from './TableHeadings';
import TeamRoleRow from './TeamRoleRow';

const AdditionalTeamRolesTable = ({
  initialValues,
  onSubmit,
  proposalsStore: { proposalSupplierCurrency, proposalExchangeRate },
  isReadOnly,
  mainNameQA,
}) => {
  return (
    <>
      <Typography variant="h4" mb="30px" mt="24px">
        Additional team roles
      </Typography>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ handleSubmit, values, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <AutoSave
                formName="deazyLayerAdditionalTeam"
                formApi={form}
                onSave={onSubmit}
              />
              <TableHeadings />
              {map(values.additionalTeam, (r, idx) => (
                <TeamRoleRow
                  isReadOnly={isReadOnly}
                  idx={idx}
                  mainNameQA={mainNameQA}
                  key={`additional-role-team-${r.id}`}
                  resource={r}
                  formApi={form}
                  fieldName="additionalTeam"
                  proposalSupplierCurrency={proposalSupplierCurrency}
                  proposalExchangeRate={proposalExchangeRate}
                />
              ))}
            </form>
          );
        }}
      />
    </>
  );
};

export default inject('proposalsStore')(observer(AdditionalTeamRolesTable));
