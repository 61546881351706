import { Box, Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';

export const InvoiceStatus = ({ value, ...props }) => (
  <Box bg="gray.400" borderRadius="8px" p="6px 12px" {...props}>
    <Typography
      wordBreak="break-word"
      color="#fff"
      variant="caption"
      textAlign="center"
    >
      {value}
    </Typography>
  </Box>
);

export const Stat = ({
  withStyledStatus,
  label,
  labelColor,
  value,
  ...rest
}) => (
  <Flex
    flexDirection="column"
    borderRight="1px solid"
    borderColor="dark.mid"
    justifyContent="center"
    minW="140px"
    {...rest}
  >
    <Typography variant="text3" mb="4px" color={labelColor || 'gray.400'}>
      {label}
    </Typography>

    {withStyledStatus ? (
      <InvoiceStatus value={value} data-test-id={rest['data-test-id']} />
    ) : (
      <Typography
        variant="text2"
        wordBreak="break-word"
        data-test-id={rest['data-test-id']}
        as="div"
      >
        {value || '-'}
      </Typography>
    )}
  </Flex>
);
