import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const MakePrimaryCurrencyConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2" mb="24px" textAlign="center">
        Make primary currency
      </Typography>
      <Typography mb="40px" textAlign="center" fontSize="15px">
        Are you sure you’d like to change the primary currency for this Delivery
        Partner?
      </Typography>
      <Stack spacing={3}>
        <Button h="44px" onClick={onConfirm}>
          Yes, change primary currency
        </Button>
        <Button h="44px" onClick={onClose} variant="outline">
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};

export default MakePrimaryCurrencyConfirmationModal;
