import { Box, Flex } from '@chakra-ui/react';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import Typography from '@styles/Typography';
import { bgColorFn, numberColorFn, textColorFn } from '@utils/briefUtils';
import { includes, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router';

const circleStyles = isSmallerScreen => ({
  borderRadius: '100%',
  alignItems: 'center',
  lineHeight: '22px',
  justifyContent: 'center',
  ...(isSmallerScreen
    ? {
        mr: '8px',
        width: '32px',
        height: '32px',
      }
    : {
        width: '40px',
        height: '40px',
        mr: '12px',
      }),
});

const WizardStepIndicator = ({
  briefsStore: { stepProgress },
  toastsStore: { showInfo },
  steps,
  briefId,
  brief,
  activeStep,
  onSubmit,
  submit,
  ...props
}) => {
  const history = useHistory();
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  return (
    <Flex {...props}>
      {map(steps, ({ route, editRoute, title, id: number }, idx) => {
        const isCurrent = includes(route, activeStep);
        const isCompleted = brief[`isStep${number}Completed`];

        return (
          <Flex
            flexShrink="0"
            alignItems="center"
            mr={isSmallerScreen ? '4px' : '24px'}
            key={`wizardId=${number}`}
          >
            <Flex
              border={(!isSmallerScreen || isCurrent) && '1px solid'}
              borderColor={numberColorFn(isCompleted, isCurrent)}
              bg={bgColorFn(isCompleted, isCurrent)}
              _hover={{
                boxShadow:
                  stepProgress !== idx && 'inset 0 0 8px 0 rgba(0, 0, 0, 0.15)',
                cursor: !isCurrent && 'pointer',
              }}
              pointerEvents={isCurrent && 'none'}
              key={`wizard-route-${route || editRoute(briefId)}`}
              type="submit"
              onClick={event => {
                if (!briefId && stepProgress < idx) {
                  return showInfo({
                    title:
                      'You cannot go forward if previous steps are not finished!',
                  });
                }
                if (briefId) {
                  submit(event);
                  history.push(editRoute(briefId));
                } else {
                  history.push(route);
                }
                return undefined;
              }}
              {...circleStyles(isSmallerScreen)}
            >
              <Typography
                color={numberColorFn(isCompleted, isCurrent)}
                variant="title"
                fontSize="16px"
              >
                {number}
              </Typography>
            </Flex>

            {(!isSmallerScreen || isCurrent) && (
              <Typography
                variant="title"
                color={textColorFn(isCompleted, isCurrent)}
                lineHeight="22px"
                mr="12px"
              >
                {title}
              </Typography>
            )}
            {idx <= 1 && (
              <Box
                height="2px"
                background="#D9DADA"
                width={isSmallerScreen ? '32px' : '72px'}
                mt="2px"
              />
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default inject(
  'briefsStore',
  'toastsStore',
)(observer(WizardStepIndicator));
