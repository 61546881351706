import { Flex, Tooltip, useDisclosure, Text } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import styled from '@emotion/styled';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { formatNumber } from '@utils';
import { filter, find, get, isEmpty, map, reject, uniq } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';

export const TableHeadings = ({
  mainWidth = '90%',
  lastCellWidth = '10%',
  lastCellLabel = 'Rate',
}) => {
  return (
    <Flex w={mainWidth} mb="16px">
      <Typography w="40%" pl="20px" variant="caption">
        Candidate name
      </Typography>
      <Typography w="40%" variant="caption">
        Role
      </Typography>
      <Typography w={lastCellWidth} variant="caption">
        {lastCellLabel}
      </Typography>
    </Flex>
  );
};

export const CellText = styled(Text)`
  font-family: Work Sans;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const tooltipProps = {
  zIndex: 33000,
  p: '12px',
  borderRadius: '8px',
  lineHeight: 'normal',
  hasArrow: true,
  bg: 'dark.solid',
  px: '24px',
  py: '12px',
  placement: 'top',
};

const PnPCandidatesList = ({
  proposalsStore: {
    isUpdatingPnPResource,
    proposalData: { resources },
    updatePPResource,
    areCandidatesChosen,
  },
  toastsStore: { showError },
  briefsStore: {
    brief: { isPlugAndPlay },
  },
  isReadOnly,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedIds, setSelectedIds] = React.useState([]);

  React.useEffect(() => {
    if (areCandidatesChosen) {
      onClose();
    } else {
      onOpen();
    }
  }, [areCandidatesChosen]);

  const numberOfCandidates = selectedIds.length;

  React.useEffect(() => {
    setSelectedIds(map(filter(resources, { selected: true }), r => r.id));
  }, [resources]);

  const onCandidateSelect = candidateId => {
    const briefResourceId = get(
      find(resources, res => res.id === candidateId),
      'briefResource.id',
    );
    const resourceCandidates = filter(
      resources,
      res =>
        res?.briefResource?.id === briefResourceId &&
        !!find(selectedIds, slectedId => slectedId === res.id),
    );
    if (resourceCandidates.length > 0) {
      return showError({
        title: 'Only one candidate can be added per brief resource.',
      });
    }
    return setSelectedIds(uniq([...selectedIds, candidateId]));
  };
  const onCandidateDeselect = candidateId => {
    setSelectedIds(reject(selectedIds, id => id === candidateId));
  };

  const isSelected = cId => !!find(selectedIds, id => id === cId);

  const onCandidateProceed = async () => {
    const payload = map(resources, res => ({
      ...res,
      selected: !!find(selectedIds, id => id === res.id),
    }));
    await updatePPResource(
      { resources: payload },
      () => {},
      'Ooops! Something unexpected happened. Please try again later.',
    );
  };

  if (!isPlugAndPlay) {
    return null;
  }
  return (
    <WhiteCard border="1px solid" borderColor="dark.mid">
      <Flex alignItems="center" w="100%" justifyContent="space-between">
        <Typography variant="h2" mr="auto">
          Select Candidates
        </Typography>
        <Typography mr="24px">{`Added ${numberOfCandidates} Candidate${
          numberOfCandidates === 1 ? '' : 's'
        }`}</Typography>
        <Button
          color="#fff"
          bg="blue.solid"
          borderRadius="100%"
          width="40px"
          iconButton={{ name: 'arrowLeft', fontSize: 30 }}
          isDisabled={isUpdatingPnPResource}
          transform={isOpen ? 'rotate(90deg)' : 'rotate(-90deg)'}
          onClick={!isOpen ? onOpen : onClose}
        />
      </Flex>
      {isOpen && (
        <>
          <Typography mb="48px" mt="24px">
            {areCandidatesChosen
              ? `Select the candidates you’d like to add to the proposal. When you’ve
              finished, click ‘Update proposal’ to add the margin and cost per
              role.`
              : `Select the candidates you’d like to add to the proposal.
              When you’ve finished, click ‘Generate proposal’ to add the margin and cost per role.`}
          </Typography>
          <Flex w="100%" flexDir="column">
            <TableHeadings />
            {map(resources, res => (
              <Flex key={`candidate-data-row-${res.id}`}>
                <Flex
                  border="1px solid"
                  borderColor="dark.mid"
                  borderRadius="8px"
                  bg="#fff"
                  position="relative"
                  h="72px"
                  mb="12px"
                  w="90%"
                >
                  <Flex alignItems="center" px="12px" w="40%">
                    <CellText>{res.name}</CellText>
                  </Flex>
                  <Flex
                    alignItems="center"
                    px="12px"
                    borderLeft="1px solid"
                    borderRight="1px solid"
                    borderColor="dark.mid"
                    w="40%"
                  >
                    <CellText>{res?.briefResource?.technology}</CellText>
                  </Flex>
                  <Flex alignItems="center" px="12px" w="10%">
                    <CellText>{formatNumber(res.rate, 2)}</CellText>
                  </Flex>
                </Flex>
                <Flex alignItems="center" px="12px" w="10%">
                  {isSelected(res.id) && (
                    <Tooltip {...tooltipProps} label="Remove">
                      <Button
                        bg="red.solid"
                        borderRadius="50%"
                        iconButton={{ name: 'trash', fontSize: 22 }}
                        color="#fff"
                        isDisabled={isUpdatingPnPResource || isReadOnly}
                        mb="10px"
                        width="40px"
                        onClick={() => onCandidateDeselect(res.id)}
                        _hover={{ bg: 'red.mid' }}
                      />
                    </Tooltip>
                  )}
                  {!isSelected(res.id) && (
                    <Tooltip {...tooltipProps} label="Add">
                      <Button
                        bg="blue.solid"
                        borderRadius="50%"
                        isDisabled={isUpdatingPnPResource || isReadOnly}
                        iconButton={{ name: 'add' }}
                        color="#fff"
                        onClick={() => onCandidateSelect(res.id)}
                        mb="10px"
                        fontSize={15}
                        _hover={{ bg: 'blue.mid' }}
                      />
                    </Tooltip>
                  )}
                </Flex>
              </Flex>
            ))}
          </Flex>
          <Button
            bg="blue.solid"
            color="#fff"
            border="none"
            fontSize={18}
            borderRadius="32px"
            fontWeight="normal"
            minW="180px"
            h="56px"
            py="16px"
            pl="16px"
            pr="24px"
            onClick={onCandidateProceed}
            isDisabled={isEmpty(selectedIds) || isReadOnly}
            isLoading={isUpdatingPnPResource}
            mt="30px"
          >
            {areCandidatesChosen ? 'Update proposal' : 'Generate proposal'}
          </Button>
        </>
      )}
    </WhiteCard>
  );
};

export default inject(
  'proposalsStore',
  'briefsStore',
  'toastsStore',
)(observer(PnPCandidatesList));
