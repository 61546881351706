import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import { Field } from 'react-final-form';
import { INPUT_VARIANTS } from '@styles/theme';
import ActionButton from '@components/AllocationTable/ActionButton';
import { formatCurrency, formatNumber } from '@utils';
import TextInput from '@components/forms/TextInput';
import { isNil, last, split } from 'lodash-es';
import TextEllipsisWithTooltip from '@components/TextEllipsisWithTooltip';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import { Cell, CellWithLink } from './TeamRoleRow';

const DEFAULT_DEAZY_RATE = 30;

const DeazyRoleRow = ({
  idx,
  resource,
  canBeDeleted,
  deleteRow,
  addRow,
  formApi,
  isReadOnly,
  mainNameQA,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Box
      w="100%"
      position="relative"
      data-test-id={mainNameQA && `${mainNameQA}--deazyRow`}
    >
      <ActionButton
        disallowRowChange={isReadOnly}
        deleteRow={deleteRow}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        canBeDeleted={canBeDeleted}
        addRow={addRow}
        mainNameQA={mainNameQA}
      />
      <Flex
        borderRadius="8px"
        w="100%"
        h="72px"
        mb="12px"
        bg="#fff"
        opacity={isOpen ? 0.5 : 1}
        pl="40px"
        border="1px solid"
        borderColor="dark.mid"
        borderTopLeftRadius="8px"
        borderBottomLeftRadius="8px"
      >
        <Box
          alignSelf="center"
          flex={1}
          h="70px"
          borderRight="1px solid"
          borderColor="dark.mid"
        >
          <Field
            name={`deazyTeam[${idx}].deazyName`}
            data-test-id={
              mainNameQA && `${mainNameQA}--deazyTeam[${idx}].deazyName`
            }
            placeholder="Add a role here"
            variant={INPUT_VARIANTS.CONTAINER_FILLED}
            component={TextInput}
            isDisabled={isReadOnly}
          />
        </Box>
        <CellWithLink
          cellProps={{ w: '130px' }}
          name={`deazyTeam[${idx}].clientRate`}
          data-test-id={
            mainNameQA && `${mainNameQA}--deazyTeam[${idx}].clientRate`
          }
          currency="GBP"
          variant={INPUT_VARIANTS.CONTAINER_FILLED}
          component={MaskedTextInput}
          placeholder="0.00"
          decimalScale={2}
          fixedDecimalScale
          isReadOnly={isReadOnly}
          onAfterChange={clientRate => {
            const margin = 100 * (1 - DEFAULT_DEAZY_RATE / clientRate);
            formApi.change(`deazyTeam[${idx}].margin`, margin);
          }}
          iconProps={{ opacity: 0.5, name: 'unlink' }}
        />
        <Cell w="130px">
          <Flex
            w="100%"
            h="100%"
            alignItems="center"
            position="relative"
            opacity={isReadOnly ? '0.4' : 'inherit'}
            data-test-id={
              mainNameQA && `${mainNameQA}--deazyTeam[${idx}].margin`
            }
          >
            {resource.clientRate ? formatNumber(resource.margin, 0) : '-'}
            <Icon
              name="link"
              color="blue.solid"
              position="absolute"
              zIndex={1}
              right="1px"
              top="22px"
              p="2px"
              fontSize={24}
            />
          </Flex>
        </Cell>
        <CellWithLink
          isReadOnly={isReadOnly}
          cellProps={{ w: '130px' }}
          iconProps={{ opacity: 0.5, name: 'unlink' }}
          name={`deazyTeam[${idx}].timeHours`}
          isAllowed={({ floatValue, value }) => {
            const dp = last(split(value, '.'));
            return (
              (floatValue >= 0 &&
                (dp === '0' || dp === '5') &&
                floatValue <= 999) ||
              isNil(floatValue)
            );
          }}
          variant={INPUT_VARIANTS.CONTAINER_FILLED}
          component={MaskedTextInput}
          placeholder="0.00"
          decimalScale={1}
          fixedDecimalScale
          data-test-id={
            mainNameQA && `${mainNameQA}--deazyTeam[${idx}].timeHours`
          }
        />
        <Cell w="130px" borderRight="none" px="0px" pr="8px">
          <Flex
            pl="12px"
            w="100%"
            h="100%"
            bg="blue.light"
            alignItems="center"
            position="relative"
            data-test-id={mainNameQA && `${mainNameQA}--deazyTeam[${idx}].cost`}
          >
            <TextEllipsisWithTooltip pr="22px" variant="text1">
              {formatCurrency(resource.clientRate * resource.timeHours, 'gbp')}
            </TextEllipsisWithTooltip>
            <Icon
              name="link"
              color="blue.solid"
              position="absolute"
              zIndex={1}
              right="1px"
              top="22px"
              p="2px"
              fontSize={24}
            />
          </Flex>
        </Cell>
      </Flex>
    </Box>
  );
};
export default DeazyRoleRow;
