import React from 'react';
import ExpandableCard from '@components/ExpandableCard';
import WhiteCard from '@components/WhiteCard';
import AllocationTable from '@components/AllocationTable';
import { filter, isEmpty } from 'lodash-es';
import Typography from '@styles/Typography';
import FormSpyFieldValues from '@components/forms/FormSpyFieldValues';
import { inject, observer } from 'mobx-react';
import SubmitFailedError from './SubmitFailedError';

export const AllocationTableWrapper = ({ children, ...props }) => (
  <WhiteCard boxShadow="none" p="0px" {...props}>
    {children}
  </WhiteCard>
);

export const StageCounterHandler = ({ fieldNames, onSetCounter }) => (
  <FormSpyFieldValues fieldNames={fieldNames}>
    {values => {
      React.useEffect(() => {
        onSetCounter(
          filter(
            values[fieldNames[0]] || [],
            ({ id, startDate, endDate, ...entry }) => !isEmpty(entry),
          ).length,
        );
      }, [values[fieldNames[0]]]);
      return null;
    }}
  </FormSpyFieldValues>
);

const FixedPriceAllocations = ({
  mainNameQA,
  fieldName,
  projectsStore: { projectInitialValues },
}) => {
  const prepareFieldName = fName =>
    fieldName ? `${fieldName}.${fName}` : fName;

  return (
    <>
      <Typography variant="h3" mb="32px">
        Total allocation
      </Typography>
      <ExpandableCard
        title="Development Readiness"
        initialCount={
          (projectInitialValues[prepareFieldName('allocations1')] || []).length
        }
      >
        {({ onSetCounter }) => (
          <>
            <StageCounterHandler
              onSetCounter={onSetCounter}
              fieldNames={[prepareFieldName('allocations1')]}
              mainNameQA={mainNameQA}
            />
            <AllocationTableWrapper>
              <AllocationTable
                fieldName={prepareFieldName('allocations1')}
                mainNameQA={mainNameQA}
                initialAllocations={
                  projectInitialValues[prepareFieldName('allocations1')] || []
                }
              />
              <SubmitFailedError
                fieldName={prepareFieldName('allocation1')}
                data-test-id={`${mainNameQA}--fixedPriceProjectBuildAllocationError`}
              />
            </AllocationTableWrapper>
          </>
        )}
      </ExpandableCard>
      <ExpandableCard
        title="Delivery"
        initialCount={
          (projectInitialValues[prepareFieldName('allocations2')] || []).length
        }
      >
        {({ onSetCounter }) => (
          <>
            <StageCounterHandler
              onSetCounter={onSetCounter}
              fieldNames={[prepareFieldName('allocations2')]}
              mainNameQA={mainNameQA}
            />
            <AllocationTableWrapper>
              <AllocationTable
                fieldName={prepareFieldName('allocations2')}
                initialAllocations={
                  projectInitialValues[prepareFieldName('allocations2')] || []
                }
                mainNameQA={mainNameQA}
              />
              <SubmitFailedError
                fieldName={prepareFieldName('allocation2')}
                mainNameQA={mainNameQA}
              />
            </AllocationTableWrapper>
          </>
        )}
      </ExpandableCard>
      <ExpandableCard
        title="UAT & Deployment"
        initialCount={
          (projectInitialValues[prepareFieldName('allocations3')] || []).length
        }
      >
        {({ onSetCounter }) => (
          <>
            <StageCounterHandler
              onSetCounter={onSetCounter}
              fieldNames={[prepareFieldName('allocations3')]}
              mainNameQA={mainNameQA}
            />
            <AllocationTableWrapper>
              <AllocationTable
                fieldName={prepareFieldName('allocations3')}
                initialAllocations={
                  projectInitialValues[prepareFieldName('allocations3')] || []
                }
                mainNameQA={mainNameQA}
              />
              <SubmitFailedError
                fieldName={prepareFieldName('allocation3')}
                mainNameQA={mainNameQA}
              />
            </AllocationTableWrapper>
          </>
        )}
      </ExpandableCard>
    </>
  );
};

export default inject('projectsStore')(observer(FixedPriceAllocations));
