import ReadOnlyTable from '@components/ReadOnlyTable';
import NoAllocationsMessage from '@components/AllocationTable/NoAllocationsMessage';
import Spinner from '@styles/Spinner';
import { MAIN_NAMES_QA } from '@app/constants';
import Typography from '@styles/Typography';
import { isFixedPrice, isFixedPriceProjectType } from '@utils/projectUtils';
import { first, get, isEmpty, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { getDeazyAllocationsColumns } from '../constants';

const ReadOnlyDeazyAllocations = ({
  projectId,
  projectsStore: { project },
  allocationsStore: {
    fetchCurrentDeazyAllocations,
    deazyAllocations: {
      current: { isLoading },
    },
    currentDeazyAllocations,
  },
}) => {
  React.useEffect(() => {
    fetchCurrentDeazyAllocations(projectId);
  }, []);

  const isProjectFixedPriceModel = isFixedPriceProjectType(project);

  if (isLoading) {
    return <Spinner variant="large" />;
  }

  if (isEmpty(currentDeazyAllocations)) {
    return <NoAllocationsMessage />;
  }

  if (isProjectFixedPriceModel) {
    return map(currentDeazyAllocations, alloc => (
      <React.Fragment key={`deazy-allocation-stage-item-${alloc.id}`}>
        <Typography mt="32px" variant="h5">
          {alloc.stage}
        </Typography>
        {isEmpty(alloc.entries) ? (
          <NoAllocationsMessage />
        ) : (
          <ReadOnlyTable
            tableData={get(alloc, 'tableData', [])}
            columns={getDeazyAllocationsColumns({
              isFixedPrice: isFixedPrice(project),
            })}
            qaTag={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}-deazyAllocations`}
          />
        )}
      </React.Fragment>
    ));
  }

  return (
    <ReadOnlyTable
      tableData={get(first(currentDeazyAllocations), 'tableData', [])}
      columns={getDeazyAllocationsColumns({
        isFixedPrice: isFixedPrice(project),
      })}
      qaTag={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}-deazyAllocations`}
    />
  );
};

export default inject(
  'allocationsStore',
  'projectsStore',
)(observer(ReadOnlyDeazyAllocations));
