import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const ConvertBriefWarningModal = ({ onContinue, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography textAlign="center" mb="32px" variant="h2">
        Missing proposal data
      </Typography>
      <Typography textAlign="center" maxW="480px" mx="auto">
        You must set the status of one proposal as ‘Won’ for the allocation and
        rates to be pulled through to the project. If you choose to continue,
        you will need to enter this manually.
      </Typography>
      <Stack spacing={3} mt="40px">
        <Button onClick={onContinue} type="submit">
          Continue
        </Button>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};

export default ConvertBriefWarningModal;
