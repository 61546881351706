import { map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import Spinner from '@styles/Spinner';
import React from 'react';
import ResourceCard from './ResourceCard';

const ResourcesList = ({
  resourcesStore: {
    fetchResources,
    clearResources,
    resources: { data: resources, isLoading },
  },
}) => {
  React.useEffect(() => {
    fetchResources();
    return () => clearResources();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return map(resources, resource => (
    <ResourceCard key={`resource-key-${resource.id}`} resource={resource} />
  ));
};

export default inject('resourcesStore')(observer(ResourcesList));
