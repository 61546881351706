import React from 'react';
import Button from '@styles/Button';
import { NEW_INVOICE_STATUSES } from '@app/constants';
import { Box, Flex } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import moment from 'moment';

const InvoiceSpecificActions = ({
  isAdminOrDL,
  isAdmin,
  invoice,
  canCreateCreditNote,
  isHistoryOpened,
  setIsHistoryOpened,
  approveOrPreapproveInvoice,
  project,
  markAsSentInvoice,
  setIsNoteFormOpened,
  setIsRejectInvoiceOpened,
  isRejectInvoiceOpened,
  isApprovedButNotPaid,
  setIsCreditNoteOpened,
  isCreditNoteOpened,
  refetchInvoiceHistory,
  notesCount = 0,
  mainNameQA,
  updatedInvoiceData = {},
}) => {
  const leftIconProps = {
    name: 'check',
    fontSize: 22,
    mr: 0,
  };

  const canPreApproveInvoice = React.useMemo(() => {
    const { invoiceDate } = invoice;

    const checkPrevDate =
      moment.utc(invoiceDate).diff(moment.utc(), 'days') + 1 > 7;

    const checkUpdatedDate =
      !isEmpty(updatedInvoiceData) &&
      moment.utc(updatedInvoiceData.invoiceDate).diff(moment.utc(), 'days') +
        1 <=
        7;

    if (invoice.status === NEW_INVOICE_STATUSES.READY_FOR_PRE_APPROVE) {
      return true;
    }
    if (
      invoice.status === NEW_INVOICE_STATUSES.SCHEDULED &&
      checkPrevDate &&
      checkUpdatedDate
    ) {
      return true;
    }
    return false;
  }, [invoice, updatedInvoiceData]);

  return (
    <Flex>
      {isAdminOrDL && (
        <Box position="relative">
          {notesCount > 0 && (
            <Box
              borderRadius="4px"
              bg="aqua.solid"
              right="44px"
              px="4px"
              py="2px"
              top="-4px"
              zIndex={10}
              fontWeight={600}
              color="#fff"
              position="absolute"
              fontSize="12px"
              data-test-id={`${mainNameQA}--notesCounter`}
            >
              {notesCount}
            </Box>
          )}
          <Button
            iconButton={{
              name: 'chevronDown',
              fontSize: 12,
              color: 'primary.400',
            }}
            width="40px"
            bg="#fff"
            mr="12px"
            transform={isHistoryOpened ? 'rotate(-180deg)' : 'rotate(0deg)'}
            onClick={() => setIsHistoryOpened(!isHistoryOpened)}
            data-test-id={`${mainNameQA}--toggleHistory`}
          />
        </Box>
      )}
      {isAdmin && invoice.status === NEW_INVOICE_STATUSES.READY && (
        <>
          <Button
            leftIcon={leftIconProps}
            isLoading={invoice.isApproving}
            onClick={() => {
              approveOrPreapproveInvoice(
                project.id,
                invoice.id,
                false,
                refetchInvoiceHistory,
              );
            }}
            data-test-id={`${mainNameQA}--approveInvoice`}
          >
            Approve invoice
          </Button>
          <Button
            variant="outline"
            ml="12px"
            leftIcon={{ name: 'close', fontSize: 22, mr: 0 }}
            onClick={() => {
              setIsHistoryOpened(true);
              setIsNoteFormOpened(true);
              setIsRejectInvoiceOpened(true);
            }}
            isDisabled={isRejectInvoiceOpened}
            data-test-id={`${mainNameQA}--rejectInvoice`}
          >
            Reject Invoice
          </Button>
        </>
      )}

      {isAdminOrDL && (
        <>
          {isApprovedButNotPaid && canCreateCreditNote && (
            <Button
              leftIcon={{ name: 'creditNote', fontSize: 22, mr: 0 }}
              isLoading={invoice.isApproving}
              onClick={() => setIsCreditNoteOpened(true)}
              isDisabled={isCreditNoteOpened}
              data-test-id={`${mainNameQA}--createCreditNote`}
            >
              Create credit note
            </Button>
          )}
          {canPreApproveInvoice && (
            <Button
              leftIcon={leftIconProps}
              isLoading={invoice.isApproving}
              onClick={() =>
                approveOrPreapproveInvoice(
                  project.id,
                  invoice.id,
                  true,
                  refetchInvoiceHistory,
                )
              }
              data-test-id={`${mainNameQA}--preApproveInvoice`}
            >
              Pre-approve invoice
            </Button>
          )}

          {invoice.status === NEW_INVOICE_STATUSES.WAITING_TO_BE_SENT &&
            invoice.contactType !== 'Client' && (
              <Button
                leftIcon={leftIconProps}
                isLoading={invoice.isMarking}
                onClick={() => markAsSentInvoice(project.id, invoice.id)}
                data-test-id={`${mainNameQA}--markAsSent`}
              >
                Mark as sent
              </Button>
            )}
        </>
      )}
    </Flex>
  );
};

export default InvoiceSpecificActions;
