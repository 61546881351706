import React from 'react';
import moment from 'moment';
import { map, find } from 'lodash-es';

export default function(proposals) {
  const versionOptions = React.useMemo(
    () =>
      map(proposals, (p, idx) => ({
        label: `Version - ${proposals.length - idx} - ${
          idx === 0 ? 'Latest version' : 'Old version'
        }`,
        value: p.id,
        createdAt: moment.utc().format('[Created] DD MMM YYYY'),
      })),
    [proposals],
  );

  const [currentVersion, setCurrentVersion] = React.useState();

  React.useEffect(() => {
    if (!currentVersion && versionOptions?.[0]) {
      setCurrentVersion(versionOptions[0].value);
    }
  }, [versionOptions]);

  const currentProposal = React.useMemo(
    () => find(proposals, proposal => proposal.id === currentVersion) || {},
    [proposals, currentVersion],
  );

  return {
    versionOptions,
    currentVersion,
    currentProposal,
    setCurrentVersion,
  };
}
