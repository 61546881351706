import { action, makeObservable, observable } from 'mobx';
import { NEW_PROPOSAL_STATUSES } from '@app/constants';

export default class ProposalQueriesStore {
  constructor({ API, toastsStore, briefsStore, usersStore, proposalsStore }) {
    makeObservable(this);
    this.API = API;
    this.usersStore = usersStore;
    this.toastsStore = toastsStore;
    this.briefsStore = briefsStore;
    this.proposalsStore = proposalsStore;
  }

  @observable queries = {
    data: [],
    isLoading: false,
  };

  @action clearProposalQueries = () => {
    this.queries.data = [];
  };

  @action fetchProposalQueries = async proposalId => {
    const {
      brief: { isPlugAndPlay, id: briefId },
    } = this.briefsStore;
    if (!isPlugAndPlay) {
      try {
        this.queries.isLoading = true;
        const { data } = await this.API.getProposalQueries(briefId, proposalId);
        this.queries.data = data;
      } catch (e) {
        this.toastsStore.showError({
          title: e.message || 'Network error! Please try again later.',
        });
      } finally {
        this.queries.isLoading = false;
      }
    }
  };

  @action queryProposal = async (
    { briefId, proposalId, ...data },
    successCb,
  ) => {
    const {
      brief: { isPlugAndPlay, id },
    } = this.briefsStore;
    const bId = briefId || id;
    const pId =
      proposalId ||
      this.proposalsStore?.proposalData?.id ||
      this.proposalsStore?.clientProposalData?.id;
    try {
      if (!isPlugAndPlay) {
        const { data: query } = await this.API.queryProposal(bId, pId, data);
        this.queries.data = [query, ...this.queries.data];
      } else {
        const { data: query } = await this.API.queryPnpProposal(bId, data);
        this.queries.data = [query, ...this.queries.data];
      }
      if (this.proposalsStore?.proposalData?.status) {
        this.proposalsStore.proposalData.status = NEW_PROPOSAL_STATUSES.WITH_DP;
      }
      if (this.proposalsStore?.clientProposalData?.status) {
        this.proposalsStore.clientProposalData.status =
          NEW_PROPOSAL_STATUSES.WITH_DP;
      }
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Network error! Please try again later.',
      });
    }
  };
}
