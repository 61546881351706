import { Box, Stack } from '@chakra-ui/react';
import RadioGroupField from '@components/forms/RadioGroupField';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';
import { Field, Form } from 'react-final-form';

const ShareSettingsModal = ({
  isOpen,
  onClose,
  onSubmit,
  actualEstimateCost,
  hightCost,
  fullRangeCost,
  initialValues,
  mainNameQA,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography mb="24px" variant="h2" textAlign="center">
        Share optional details
      </Typography>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Box>
                <Field
                  label="Cost"
                  name="visibleCosts"
                  component={RadioGroupField}
                  alignItems="flex-start"
                  mainNameQA={mainNameQA}
                  choices={[
                    {
                      label: 'Actual estimate',
                      value: 'Actual estimate',
                      subLabel: actualEstimateCost,
                    },
                    {
                      label: 'High cost only',
                      value: 'High cost only',
                      subLabel: hightCost,
                    },
                    {
                      label: 'Full range',
                      value: 'Full range',
                      subLabel: fullRangeCost,
                    },
                  ]}
                />
                <Box pt="24px" />
                <Field
                  label="Feature Breakdown"
                  name="featuresBreakdown"
                  component={RadioGroupField}
                  mainNameQA={mainNameQA}
                  choices={[
                    {
                      label: 'Hidden costs',
                      value: 'Hidden costs',
                    },
                    {
                      label: 'Costs per feature',
                      value: 'Costs per feature',
                    },
                    {
                      label: 'Full breakdown',
                      value: 'Full breakdown',
                    },
                  ]}
                />
              </Box>

              <Stack spacing={3} mt="40px">
                <Button isLoading={submitting} type="submit">
                  Change
                </Button>
                <Button
                  variant="outline"
                  isDisabled={submitting}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Stack>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default ShareSettingsModal;
