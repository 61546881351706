import { formatCurrency } from '@utils';
import { lowerCase, find } from 'lodash-es';

export const prepareCostRange = ({ cost, minCost, maxCost, currency }) =>
  minCost && maxCost
    ? `${formatCurrency(minCost, currency)} - ${formatCurrency(
        maxCost,
        currency,
      )}`
    : formatCurrency(cost, currency);

export const isAdvancedFrameworkCheck = technologies =>
  !!find(
    technologies,
    technology =>
      lowerCase(technology) === 'episerver' ||
      lowerCase(technology) === 'sitecore',
  );

export const getFormattedRates = ({
  rate,
  clientLowRate,
  clientHighRate,
  clientTargetRate,
  currency,
  supplierCurrency,
  highRate,
}) => {
  const getClientRateRange = () => {
    if (+clientLowRate > 0 && +clientHighRate > 0) {
      return `${formatCurrency(clientLowRate, currency)} - ${formatCurrency(
        clientHighRate,
        currency,
      )}`;
    }
    if (+clientTargetRate > 0) {
      return formatCurrency(clientTargetRate, currency);
    }
    return null;
  };

  const getDPRate = () => {
    if (+highRate > 0 && +rate > 0) {
      return `${formatCurrency(rate, supplierCurrency)} - ${formatCurrency(
        highRate,
        supplierCurrency,
      )}`;
    }
    return +rate > 0 ? formatCurrency(rate, supplierCurrency) : null;
  };

  return {
    dpTargetRate: getDPRate(),
    clientRateRange: getClientRateRange(),
  };
};
