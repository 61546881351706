import { Box, Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import { last, split } from 'lodash-es';
import Button from '@styles/Button';
import DownloadButton from '@components/DownloadButton';
import React from 'react';
import Typography from '@styles/Typography';

export const SectionTitle = ({ children, ...props }) => (
  <Typography variant="h4" fontSize={18} mb="24px" mt="24px" {...props}>
    {children}
  </Typography>
);

export const Stat = ({ label, value, isHTML, ...props }) => (
  <Flex flexDir="column" mb="32px" {...props}>
    <Typography variant="text2" color="dark.solid" mb="8px">
      {label}
    </Typography>
    {!isHTML ? (
      <Typography color="black">{value || '-'}</Typography>
    ) : (
      <Box
        className="displayDescriptionAsHTML"
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    )}
  </Flex>
);

export const Document = ({
  name,
  link,
  onDeleteOpen,
  createdAt,
  updatedAt,
  uuidVersion,
  deleteFn,
  ...props
}) => {
  const iconName = last(split(name, '.'));
  return (
    <Flex
      border="1px solid"
      borderRadius="8px"
      borderColor="dark.mid"
      px="18px"
      py="12px"
      mb="12px"
      alignItems="center"
      {...props}
    >
      <Icon mr="12px" fontSize={24} name={iconName} />
      <Typography
        variant="h5"
        w="80%"
        mr="auto"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {name}
      </Typography>
      {link && <DownloadButton url={link} name={name} />}
      {onDeleteOpen && (
        <Button
          iconButton={{
            name: 'trash',
            fontSize: '20px',
            marginBottom: '2px',
          }}
          width="40px"
          color="gray.300"
          bg="transparent"
          onClick={() => {
            onDeleteOpen();
          }}
        />
      )}
    </Flex>
  );
};
