import {
  ENGAGEMENT_TYPES,
  MILESTONE_STAGE,
  MAIN_NAMES_QA,
} from '@app/constants';
import { Box, Flex } from '@chakra-ui/react';
import InfoAlert from '@components/InfoAlert';
import { APP_ROUTES } from '@routes';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { sleep } from '@utils';
import { isEmpty, map } from 'lodash-es';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ChangesSavedIndicator from './ChangesSavedIndicator';
import StatusDropdown from './MilestoneStatusDropdown';
import MilestoneTableForm from './MilestoneTableForm';
import ReportButton from './ReportButton';

const Milestone = ({
  usersStore: { isAdminOrDL, isTeamAdminSideUser, isClient },
  projectsStore: {
    project: { id: projectId, engagementType },
    isPrimaryDP,
    project,
  },
  milestonesStore: {
    clearLastModifiedDate,
    clearUpdatedMilestone,
    uatMilestones,
    upfrontMilestones,
    deliveryMilestones,
    saveMilestonesData,
    clearMilestones,
    submitMilestoneReport,
    approveMilestoneReport,
    reportStatus,
    fetchedMilestones,
    fetchReport,
    isLoadingMilestones,
  },
  nationalHolidaysStore: {
    getUpcomingHolidays,
    clearHolidays,
    upcomingHolidays,
  },
}) => {
  useEffect(() => {
    if (projectId) {
      fetchReport(projectId);
      getUpcomingHolidays(projectId, true);
    }
    return () => {
      clearMilestones();
      clearLastModifiedDate();
      clearUpdatedMilestone();
      clearHolidays();
    };
  }, [clearMilestones, fetchReport, projectId]);

  const onSubmit = ({ milestones: milestonesData }, stage) => {
    saveMilestonesData(projectId, milestonesData, stage);
  };

  const isPlugPlay = React.useMemo(
    () => engagementType === ENGAGEMENT_TYPES.TEAM_AUGMENTATION,
    [engagementType],
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  let upfrontSubmit;
  let deliverySubmit;
  let uatSubmit;

  const submit = async (isApprove = false) => {
    setIsSubmitting(true);
    if (upfrontSubmit) {
      await upfrontSubmit();
      await sleep(50);
    }
    if (deliverySubmit) {
      await deliverySubmit();
      await sleep(50);
    }
    if (uatSubmit) {
      await uatSubmit();
      await sleep(50);
    }
    const error = document.querySelector('.final-form-error');
    if (error) {
      setIsSubmitting(false);
      return error.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
    if (isApprove) {
      await approveMilestoneReport({
        projectId,
        successCb: () => fetchReport(projectId),
      });
    } else {
      await submitMilestoneReport({
        projectId,
        successCb: () => fetchReport(projectId),
      });
    }
    return setIsSubmitting(false);
  };

  const upcomingHolidaysString = () => {
    const upcoming = map(upcomingHolidays, h => {
      return `${moment(h.date).format('Do MMMM')} [${h.countryCode}]`;
    });
    return upcoming.length - 1 ? upcoming.join(' and ') : upcoming.join(' ');
  };

  const mainNameQA = MAIN_NAMES_QA.PROJECT_OVERVIEW;

  return (
    <Box maxW="1280px" w="100%" mx="auto" px="40px" pb="100px">
      <Flex justifyContent="space-between">
        <Box w="100%">
          <Flex pt="40px" alignItems="center">
            <Typography variant="h2" mr="auto">
              Overview
            </Typography>

            {!isClient && <ChangesSavedIndicator />}
            <ReportButton
              isPrimaryDP={isPrimaryDP}
              submit={submit}
              reportStatus={reportStatus}
              isTeamAdminSideUser={isTeamAdminSideUser}
              isAdminOrDL={isAdminOrDL}
              isSubmitting={isSubmitting}
              isLoadingMilestones={isLoadingMilestones}
              fetchedMilestones={fetchedMilestones}
            />
          </Flex>
          <Flex justifyContent="space-between" alignItems="flex-end" pt="16px">
            <Box maxW="720px">
              {reportStatus && (
                <Typography>
                  {reportStatus === 'Accepted'
                    ? `Update the status and leave a comment when you reach the planned date of each milestone.
              If anything needs to change, update the planned dates or add new milestones and agree this with the client.`
                    : `Create a plan to monitor the progress of a project against agreed
              key dates. Submit this report once you’ve added your milestones -
              you’ll still have a chance to make changes until it’s approved by
              the Deazy lead.`}
                </Typography>
              )}
            </Box>
            <Flex bg="#fff" py="12px" pl="16px" pr="4px" borderRadius="8px">
              {reportStatus === 'Accepted' && (
                <StatusDropdown withRevertLabels mainNameQA={mainNameQA} />
              )}
              {reportStatus === 'Accepted' && isAdminOrDL && (
                <StatusDropdown
                  isRiskDropdown
                  withRevertLabels
                  mainNameQA={mainNameQA}
                />
              )}
            </Flex>
          </Flex>
          {!isEmpty(upcomingHolidays) && (
            <InfoAlert
              mt="32px"
              mb="16px"
              bg="#fff"
              label={`The next three national holidays fall on the
             ${upcomingHolidaysString()}`}
              link={APP_ROUTES.projectTabWithSlugs(
                project?.client?.slug,
                project?.slug,
                'national-holidays',
              )}
              linkLabel="View national holidays"
            />
          )}
        </Box>
      </Flex>
      {isLoadingMilestones || !fetchedMilestones ? (
        <Spinner />
      ) : (
        <>
          <MilestoneTableForm
            isPrimaryDP={isPrimaryDP}
            isAdminOrDL={isAdminOrDL}
            isTeamAdminSideUser={isTeamAdminSideUser}
            isClient={isClient}
            ignoreErrors={!isSubmitting}
            onSubmit={onSubmit}
            initialValues={{ milestones: toJS(upfrontMilestones) }}
            title="Upfront Project Setup"
            subtitle="Add dates for the planning stages and make sure you add any dependencies agreed in the statement of work."
            stage={MILESTONE_STAGE.UPFRONT}
            reportStatus={reportStatus}
            setSubmit={handleSubmit => {
              upfrontSubmit = handleSubmit;
            }}
            isPlugPlay={isPlugPlay}
            mainNameQA={mainNameQA}
          />
          <MilestoneTableForm
            isPrimaryDP={isPrimaryDP}
            isAdminOrDL={isAdminOrDL}
            isTeamAdminSideUser={isTeamAdminSideUser}
            isClient={isClient}
            onSubmit={onSubmit}
            ignoreErrors={!isSubmitting}
            initialValues={{ milestones: toJS(deliveryMilestones) }}
            title="Delivery"
            subtitle="Add a milestone either per sprint or per high-level feature."
            stage={MILESTONE_STAGE.DELIVERY}
            reportStatus={reportStatus}
            setSubmit={handleSubmit => {
              deliverySubmit = handleSubmit;
            }}
            isPlugPlay={isPlugPlay}
            mainNameQA={mainNameQA}
          />
          <MilestoneTableForm
            isPrimaryDP={isPrimaryDP}
            isAdminOrDL={isAdminOrDL}
            isTeamAdminSideUser={isTeamAdminSideUser}
            isClient={isClient}
            ignoreErrors={!isSubmitting}
            onSubmit={onSubmit}
            initialValues={{ milestones: toJS(uatMilestones) }}
            title="UAT & Go Live"
            subtitle="Ensure enough time is allocated to finish the project well."
            stage={MILESTONE_STAGE.UAT}
            reportStatus={reportStatus}
            setSubmit={handleSubmit => {
              uatSubmit = handleSubmit;
            }}
            isPlugPlay={isPlugPlay}
            mainNameQA={mainNameQA}
          />
        </>
      )}
    </Box>
  );
};

export default inject(
  'projectsStore',
  'usersStore',
  'nationalHolidaysStore',
  'milestonesStore',
)(observer(Milestone));
