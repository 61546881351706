import { Box, Flex } from '@chakra-ui/react';
import { RightIconInput } from '@components/forms/MaskedTextInput';
import TextInput from '@components/forms/TextInput';
import Icon from '@components/Icon';
import Tooltip from '@styles/Tooltip';
import Typography from '@styles/Typography';
import { mustBeShorterOrEqual } from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';
import AutoSave from '../Proposal/AutoSave';

const DiscountSection = ({
  onSubmit,
  initialValues,
  isReadOnly,
  isSmallerScreen,
}) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    keepDirtyOnReinitialize
    render={({ handleSubmit, form }) => {
      return (
        <form onSubmit={handleSubmit} autoComplete="off">
          <AutoSave formApi={form} onSave={onSubmit} />
          <Flex
            border="1px solid"
            borderColor="dark.mid"
            bg="dark.light"
            borderRadius="8px"
            px="16px"
            py="20px"
            mb="12px"
            flexDir="column"
            {...(isSmallerScreen && {
              py: '12px',
              px: '8px',
              border: 'none',
              bg: 'dark.light',
            })}
          >
            <Typography
              variant="h4"
              mr="auto"
              mb={['12px', '12px', '12px', '26px']}
            >
              Discount
            </Typography>
            <Flex
              justifyContent="space-between"
              width="100%"
              {...(isSmallerScreen && { flexDir: 'column' })}
            >
              <Field
                component={TextInput}
                validate={mustBeShorterOrEqual(200)}
                label="Discount reason"
                name="discountReason"
                placeholder="Enter a discount reason"
                containerProps={{
                  pr: [0, 0, 0, '24px'],
                  mb: ['12px', '12px', '12px', '0px'],
                }}
                disabled={isReadOnly}
                backgroundColor="#fff"
              />
              <Field
                containerProps={{ minW: '292px', w: '100%' }}
                component={RightIconInput}
                iconName="percent"
                label={
                  <Box>
                    Discount percentage
                    <Tooltip
                      label="Type any discount up to 5%, to 2 decimal places"
                      maxW="208px"
                    >
                      <Box as="span" ml="10px">
                        <Icon name="infoFilled" color="gray.mid" mt="-4px" />
                      </Box>
                    </Tooltip>
                  </Box>
                }
                name="discountPercentage"
                isAllowed={({ floatValue }) =>
                  (floatValue <= 5 && floatValue > 0) || !floatValue
                }
                decimalScale={2}
                fixedDecimalScale
                changeIfActive
                placeholder="e.g. 1.00"
                disabled={isReadOnly}
              />
            </Flex>
          </Flex>
        </form>
      );
    }}
  />
);

export default DiscountSection;
