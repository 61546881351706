import { isArray, capitalize, isEmpty } from 'lodash-es';
import { action, makeObservable, observable } from 'mobx';

export default class UserOrchestrationStore {
  constructor({ API, toastsStore }) {
    makeObservable(this);
    this.toastsStore = toastsStore;
    this.API = API;
  }

  @observable user = {};

  @observable isLoading = false;

  @observable roles = [];

  @observable isLoadingRoles = false;

  @observable timezones = [];

  @observable isLoadingTimezones = false;

  @observable isOpenCreateUserModal = false;

  @observable editPasswordResponse = false;

  @observable isLoadingEditPassword = false;

  @action createUserFrontegg = async postData => {
    try {
      this.user = {};
      this.isLoading = true;
      const { data } = await this.API.createUserFrontegg(postData);
      this.user = data;
    } catch (e) {
      const statusCode = e?.response?.status;
      const statusText = e?.response?.statusText;
      const error = e?.response?.data?.error;
      const errorMessage = e?.response?.data?.message;
      const messageUppercased = isArray(errorMessage)
        ? capitalize(errorMessage?.[0])
        : capitalize(errorMessage);
      const message = !isEmpty(errorMessage)
        ? messageUppercased
        : error || statusText;

      this.toastsStore.showError({
        title: statusCode ? message : 'Network error',
      });
    } finally {
      this.isLoading = false;
    }
  };

  @action clearUserData = () => {
    this.user = {};
  };

  @action getUserRoles = async () => {
    try {
      this.roles = [];
      this.isLoadingRoles = true;
      const { data } = await this.API.getUserRoles();
      this.roles = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingRoles = false;
    }
  };

  @action getUserTimezones = async () => {
    try {
      this.timezones = [];
      this.isLoadingTimezones = true;
      const { data } = await this.API.getTimezones();
      this.timezones = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingTimezones = false;
    }
  };

  @action onCreateUserModalOpen = () => {
    this.isOpenCreateUserModal = true;
  };

  @action onCloseCreateUserModal = () => {
    this.isOpenCreateUserModal = false;
  };

  @action editUserPassword = async postData => {
    try {
      this.editPasswordResponse = false;
      this.isLoadingEditPassword = true;
      await this.API.editUserPassword(postData);
      this.editPasswordResponse = true;
    } catch (e) {
      this.editPasswordResponse = false;

      const statusCode = e?.response?.status;
      const statusText = e?.response?.statusText;
      const error = e?.response?.data?.error;
      const errorMessage = e?.response?.data?.message;
      const messageUppercased = isArray(errorMessage)
        ? capitalize(errorMessage?.[0])
        : capitalize(errorMessage);
      const message = !isEmpty(errorMessage)
        ? messageUppercased
        : error || statusText;

      this.toastsStore.showError({
        title: statusCode ? message : 'Network error',
      });
    } finally {
      this.isLoadingEditPassword = false;
    }
  };

  @action clearUserPasswordResponse = () => {
    this.editPasswordResponse = false;
  };
}
