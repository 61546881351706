import { Link } from 'react-router-dom';
import {
  Box,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
} from '@chakra-ui/react';
import React from 'react';
import Button from '@styles/Button';
import { PROJECT_TYPES } from '@app/constants';
import { inject, observer } from 'mobx-react';

const Drawer = ({
  projectsStore: { project },
  bg = 'dark.light',
  children,
  maxW = '1200px',
  backUrl,
}) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const backRoute = () => {
    if (backUrl) {
      return backUrl;
    }
    return project.projectType === PROJECT_TYPES.FIXED_PRICE
      ? 'allocations'
      : 'allocations-and-actuals';
  };
  return (
    <>
      <ChakraDrawer
        size="full"
        placement="top"
        isOpen={isOpen}
        isFullHeight
        onClose={() => {}}
      >
        <DrawerOverlay />
        <DrawerContent bg={bg}>
          <Flex position="fixed" top="20px" right="50px">
            <Button
              borderRadius="100%"
              bg="blue.solid"
              color="#fff"
              iconButton={{ name: 'close' }}
              cursor="pointer"
              onClick={() => setIsOpen(false)}
              as={Link}
              to={backRoute()}
            />
          </Flex>
          <Box w="100%" overflowY="scroll">
            <Box maxW={maxW} w="100%" p="40px" mx="auto">
              <DrawerBody>{children}</DrawerBody>
            </Box>
          </Box>
        </DrawerContent>
      </ChakraDrawer>
    </>
  );
};

export default inject('projectsStore')(observer(Drawer));
