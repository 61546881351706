/* eslint-disable */
import {
  action,
  makeObservable,
  when,
  observable,
  reaction,
  computed,
} from 'mobx';
// import * as LDClient from 'launchdarkly-js-client-sdk';
import { isEmpty } from 'lodash-es';
import { destroyIsAuthorized } from '@app/api';

export default class AppStore {
  constructor({
    API,
    history,
    toastsStore,
    usersStore,
    authStore,
    routerStore,
  }) {
    makeObservable(this);
    this.API = API;
    this.history = history;
    this.toastsStore = toastsStore;
    this.usersStore = usersStore;
    this.authStore = authStore;
    this.routerStore = routerStore;

    // this._initializeLaunchDarkly();
  }

  @observable is404 = false;

  @observable is500 = false;

  @observable enums = {};

  @observable isLoadingEnums = false;

  @observable isSubSidebarOpened = false;

  @observable isBriefChatOpened = false;

  @observable _ldClient = null;

  @observable featureFlags = {
    'uo-create-user': false,
    'uo-frontegg-auth': false,
    'companies-tab-in-sidebar': false,
    'my-profile-in-sidebar': false,
    'uo-48-update-sidebar': false,
    'deazy-team-listing-and-profiles': false,
    'show-brief-chat': false,
    'announce-kit': false,
    'dev-profiles-in-ta-proposal': false,
    'use-pandadoc': false,
    'bulk-upload-profile': false,
    'removal-of-billing-type': true,
    'brief-exclusive-assigment': true,
    'delivery-partner-matching': true,
    'uo-42-view-partner-list': true,
  };

  @action toggleSubSidebar = role => {
    if (role) {
      this.isSubSidebarOpened = !this.isSubSidebarOpened;
      return undefined;
    }
    this.isSubSidebarOpened = false;
    return undefined;
  };

  @action toggleBriefChat = () => {
    this.isBriefChatOpened = !this.isBriefChatOpened;
  };

  @action setIs404 = is404 => {
    this.is404 = is404;
  };

  @action setIs500 = is500 => {
    this.is500 = is500;
  };

  @action getEnums = async () => {
    this.enums = {};
    this.isLoadingEnums = true;
    try {
      const { data } = await this.API.getSetupEnums();
      this.enums = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoadingEnums = false;
    }
  };

  @action _initializeLaunchDarkly = () => {
    // this._mountLaunchDarkly({});
    // reaction(
    //   () => this.usersStore.profile,
    //   profile => {
    //     const newUser = this.prepareLDuserPayload(profile);
    //     this._ldClient.identify(newUser, newFlags => {
    //       this.featureFlags = newFlags;
    //     });
    //   },
    // );
  };

  prepareLDuserPayload = profile => {
    return profile?.id
      ? {
          key: profile.id,
          name: profile.fullname,
          email: profile.email,
        }
      : {
          key: 'anonymous',
          anonymous: true,
        };
  };

  @action _mountLaunchDarkly = async profile => {
    // if (!this._ldClient) {
    //   this._ldClient = await LDClient.initialize(
    //     process.env.REACT_APP_LAUNCH_DARKLY_SDK_KEY,
    //     this.prepareLDuserPayload(profile),
    //   );
    //   this._ldClient.on('ready', this._onLaunchDarklyReady);
    // }
  };

  @action _onLaunchDarklyReady = async () => {
    // if (!this._ldClient) {
    //   await when(() => !isEmpty(this._ldClient));
    // }
    // const flags = await this._ldClient.allFlags();
    // const isDevEnv = process.env.REACT_APP_ENV === 'development';
    // if (isDevEnv) {
    //   flags['uo-frontegg-auth'] = process.env.REACT_APP_USE_FRONTEGG === 'true';
    // }
    // this.featureFlags = flags;
    // this._fronteggFeatureFlagHandler();
    // this._ldClient.on('change', this._onLaunchDarklyChange);
  };

  @action _onLaunchDarklyChange = async updatedFeatureFlags => {
    // Object.keys(updatedFeatureFlags).forEach(key => {
    //   const isAuthFlag = key === 'uo-frontegg-auth';
    //   const isDevEnv = process.env.REACT_APP_ENV === 'development';
    //   if (isAuthFlag && isDevEnv) return;
    //   this.featureFlags[key] = updatedFeatureFlags[key]?.current;
    // });
  };

  @action _fronteggFeatureFlagHandler = () => {
    // reaction(
    //   () => this.featureFlags['uo-frontegg-auth'],
    //   (isFronteggEnabled, prevIsFronteggEnabled) => {
    //     if (isFronteggEnabled) {
    //       destroyIsAuthorized();
    //     }
    //     const shouldRedirectToLogin =
    //       prevIsFronteggEnabled === false && !!isFronteggEnabled;
    //     if (shouldRedirectToLogin) {
    //       this.history.replace('/login');
    //     }
    //     const shouldRedirectToOldLogin =
    //       prevIsFronteggEnabled === true && !isFronteggEnabled;
    //     if (shouldRedirectToOldLogin) {
    //       destroyIsAuthorized();
    //       this.history.replace('/sign-in');
    //     }
    //   },
    //   {},
    // );
  };

  /**
   * Can be used to determine if LaunchDarkly is still loading so that some loading indicator is displayed if necessary
   * @returns {boolean}
   */
  // @computed get isLaunchDarklyReady() {
  //   return !isEmpty(this.featureFlags);
  // }
}
