import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const ExpandableCard = ({
  initialCount = 0,
  title,
  getCounterLabel = num => `Added ${num} ${num !== 1 ? 'people' : 'person'}`,
  isExpandDisabled = false,
  children,
  defaultIsOpen = true,
}) => {
  const [counter, setCounter] = React.useState(initialCount);
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen });
  React.useEffect(() => {
    if (initialCount > 0) {
      setCounter(initialCount);
    }
  }, [initialCount]);

  const onIncrement = () => {
    setCounter(counter + 1);
  };

  const onDecrement = () => {
    setCounter(counter - 1);
  };

  const onSetCounter = c => {
    setCounter(c);
  };

  return (
    <WhiteCard border="1px solid" borderColor="dark.mid">
      <Flex alignItems="center" w="100%" justifyContent="space-between">
        <Typography variant="h4" mr="auto">
          {title}
        </Typography>
        <Typography mr="24px">{getCounterLabel(counter)}</Typography>
        <Button
          borderRadius="8px"
          width="40px"
          color="black"
          bg="primary.100"
          iconButton={{ name: 'arrowLeft', fontSize: 30 }}
          isDisabled={isExpandDisabled}
          transform={isOpen ? 'rotate(90deg)' : 'rotate(-90deg)'}
          onClick={!isOpen ? onOpen : onClose}
        />
      </Flex>
      <Box display={isOpen ? 'inherit' : 'none'}>
        {typeof children === 'function'
          ? children({ onIncrement, onDecrement, onSetCounter })
          : children}
      </Box>
    </WhiteCard>
  );
};

export default ExpandableCard;
