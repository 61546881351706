import { last, isEmpty, some, filter } from 'lodash-es';
import {
  PHASE_EMPTY_ERROR_MSG,
  allocationValidation as aValidation,
} from '@pages/ProjectsCreate/projectValidation';
import { object, string, array, mixed } from 'yup';

const validateAllocation = (errorName, key) => (_, testContext) => {
  const allocations = testContext.parent[key];
  const project = last(testContext.from)?.value;
  const error = aValidation(
    project.projectType,
    project.engagementType,
    allocations,
    errorName,
    false,
    project.deazyAsClient,
  );
  if (!error[errorName]) {
    return true;
  }
  const isPhased = !isEmpty(testContext.parent.allocations1);
  if (
    isPhased &&
    some(
      [
        testContext.parent.allocations1,
        testContext.parent.allocations2,
        testContext.parent.allocations3,
      ],
      allocs => allocs?.length > 1,
    ) &&
    error[errorName] === PHASE_EMPTY_ERROR_MSG
  ) {
    return true;
  }
  const showError =
    (!isPhased && errorName === 'allocation') ||
    (isPhased && errorName !== 'allocation');

  if (showError) {
    return testContext.createError({ message: error[errorName] });
  }
  return true;
};

const validateDeazyAllocation = (errorName, key) => (_, testContext) => {
  const deazyAllocations = testContext.parent[key];
  const project = last(testContext.from)?.value;
  const error = aValidation(
    project.projectType,
    project.engagementType,
    deazyAllocations,
    errorName,
    true,
    project.deazyAsClient,
  );
  if (!error[errorName] || project.deazyAsClient) {
    return true;
  }
  return testContext.createError({ message: error[errorName] });
};

const allocationValidation = {
  deazyAllocation: object().test(
    'pts allocation',
    'This section is required',
    validateDeazyAllocation('deazyAllocation', 'deazyAllocations'),
  ),
  deazyAllocation1: object().test(
    'pts allocation',
    'This section is required',
    validateDeazyAllocation('deazyAllocation1', 'deazyAllocations1'),
  ),
  deazyAllocation2: object().test(
    'pts allocation',
    'This section is required',
    validateDeazyAllocation('deazyAllocation2', 'deazyAllocations2'),
  ),
  deazyAllocation3: object().test(
    'pts allocation',
    'This section is required',
    validateDeazyAllocation('deazyAllocation3', 'deazyAllocations3'),
  ),

  projectToSuppliers: array().of(
    object({
      supplierLead: mixed().required('This field is required'),
      supplier: object()
        .required('This field is required')
        .test(
          'deazy as client',
          'Deazy is already selected as a client!',
          (supplier, testContext) => {
            const project = last(testContext.from)?.value;
            return !(project.deazyAsClient && supplier?.id === 'deazy');
          },
        )
        .test(
          'supplier duplicated',
          'That Delivery Partner is already selected.',
          (supplier, testContext) =>
            !(
              filter(
                last(testContext.from)?.value?.projectToSuppliers,
                pts => pts?.supplier?.id === supplier?.id,
              ).length > 1
            ),
        ),

      overriddenCurrency: string().required('This field is required'),
      allocation: object().test(
        'pts allocation',
        'This section is required',
        validateAllocation('allocation', 'allocations'),
      ),
      allocation1: object().test(
        'pts allocation',
        'This section is required',
        validateAllocation('allocation1', 'allocations1'),
      ),
      allocation2: object().test(
        'pts allocation',
        'This section is required',
        validateAllocation('allocation2', 'allocations2'),
      ),
      allocation3: object().test(
        'pts allocation',
        'This section is required',
        validateAllocation('allocation3', 'allocations3'),
      ),
    }),
  ),
};

export { allocationValidation };
