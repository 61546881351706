import { Accordion, AccordionItem, Box } from '@chakra-ui/react';
import StickyFooterCostBox from '@components/StickyFooterCostBox';
import { COLORS } from '@styles/theme';
import Typography from '@styles/Typography';
import { formatNumber } from '@utils';
import { prepareCostRange } from '@utils/proposalUtils';
import { isEmpty, omit } from 'lodash-es';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import ProposalsAccordionItemBody from '../Proposal/ProposalAccordionItemBody';
import ProposalOverview from '../Proposal/ProposalOverview';
import ProposalTimings from '../Proposal/ProposalTimings';
import ResourcesBreakdownSection from '../Proposal/ResourcesBreakdownSection';
import ClientProposalFeatures from './ClientProposalFeatures';
import DiscountDisclaimer from './DiscountDisclaimer';
import ProposalQueriesList from './ProposalQueriesList';
import ProposalStatus from './ProposalStatus';

const accordionItemProps = {
  bg: 'white',
  mb: '24px',
  borderRadius: '8px',
  border: `1px solid ${COLORS.dark.mid}`,
};

const ClientProposal = ({
  proposalId,
  proposalsStore: { clientProposalData, saveProposalCostData },
  usersStore: { isAdminOrDL },
  mainNameQA,
  isSmallerScreen,
}) => {
  const isFullBreakdownView =
    clientProposalData?.clientSettings?.featuresBreakdown === 'Full breakdown';

  return (
    <>
      <DiscountDisclaimer clientProposalData={clientProposalData} />
      <ProposalStatus
        isAdminOrDL={isAdminOrDL}
        clientProposalData={clientProposalData}
      />
      <ProposalQueriesList proposalId={clientProposalData.id || proposalId} />
      <Accordion
        mt="48px"
        defaultIndex={[0, 1, 2]}
        allowMultiple={false}
        allowToggle={false}
      >
        <AccordionItem {...accordionItemProps}>
          <ProposalsAccordionItemBody
            showArrow={false}
            stepTitle="1. Overview"
            isSmallerScreen={isSmallerScreen}
          >
            <ProposalOverview
              onSubmit={() => {}}
              initialValues={{
                ...omit(toJS(clientProposalData), [
                  'coreTeam',
                  'additionalTeam',
                ]),
              }}
              setSubmit={() => {}}
              isReadOnly
              mainNameQA={mainNameQA}
              isSmallerScreen={isSmallerScreen}
            />
          </ProposalsAccordionItemBody>
        </AccordionItem>
        <AccordionItem {...accordionItemProps}>
          <ProposalsAccordionItemBody
            showArrow={false}
            stepTitle="2. Resource"
            mainNameQA={mainNameQA}
            isSmallerScreen={isSmallerScreen}
          >
            <ResourcesBreakdownSection
              isClientView
              mainNameQA={mainNameQA}
              isSmallerScreen={isSmallerScreen}
            />
            <ProposalTimings
              initialValues={{
                ...omit(toJS(clientProposalData), [
                  'coreTeam',
                  'additionalTeam',
                ]),
              }}
              onSubmit={isAdminOrDL ? saveProposalCostData(false) : () => {}}
              isReadOnly
              mainNameQA={mainNameQA}
              isSmallerScreen={isSmallerScreen}
            />
          </ProposalsAccordionItemBody>
        </AccordionItem>
        {!isEmpty(clientProposalData.featureBreakdown) && (
          <AccordionItem {...accordionItemProps}>
            <ProposalsAccordionItemBody
              showArrow={false}
              stepTitle="3. Features"
              isSmallerScreen={isSmallerScreen}
            >
              {!isFullBreakdownView && (
                <Typography mb="32px">
                  Features included in this proposal. Features with the ‘i’ icon
                  next to them contain additional information. Select these to
                  see more details.
                </Typography>
              )}
              <ClientProposalFeatures
                mainNameQA={mainNameQA}
                isSmallerScreen={isSmallerScreen}
              />
            </ProposalsAccordionItemBody>
          </AccordionItem>
        )}
      </Accordion>
      <StickyFooterCostBox
        title="Proposal grand total"
        mainNameQA={mainNameQA}
        subText={
          +clientProposalData.discountPercentage > 0 ? (
            <Box
              display="inline-flex"
              bg="green.light"
              py="12px"
              px="18px"
              borderRadius="8px"
              alignItems="center"
            >
              <Typography variant="h3" color="green.solid" mr="12px">
                %
              </Typography>
              <Typography color="dark.solid">
                {`${formatNumber(
                  clientProposalData.discountPercentage,
                )}% discount applied`}
              </Typography>
            </Box>
          ) : null
        }
        currency="GBP"
        hours={clientProposalData?.costs?.hours}
        cost={prepareCostRange({
          cost: clientProposalData?.costs?.proposalCost,
          minCost: clientProposalData?.costs?.proposalMinCost,
          maxCost: clientProposalData?.costs?.proposalMaxCost,
        })}
      />
    </>
  );
};

export default inject('proposalsStore', 'usersStore')(observer(ClientProposal));
