import React from 'react';
import { Form, FormSpy } from 'react-final-form';
import { Box, Flex } from '@chakra-ui/react';
import { ErrorText } from '@components/forms/FormError';
import ErrorSpy from '@components/forms/ErrorSpy';
import Typography from '@styles/Typography';
import { MAIN_NAMES_QA } from '@app/constants';
import FormSpyFieldValues from '@components/forms/FormSpyFieldValues';
import yupFormValidation from '@utils/yupFormValidation';
import { projectValidation } from '@validationSchemas/projectValidation';
import ProjectSummary from './ProjectSummary';
import ProjectFormListener from './ProjectFormListener';
import ProjectFinances from './ProjectFinances';
import SubmitSection from './SubmitSection';
import ProjectBasicInfo from './ProjectBasicInfo';
import ProjectTimeFrame from './ProjectTimeFrame';
import ProjectPrimaryDPSection from './ProjectPrimaryDPSection';
import MultipleDPSection from './MultipleDPSection';
import DeazyCard from './DeazyCard';

const ProjectForm = ({ onSubmit, initialValues }) => {
  const mainNameQA = MAIN_NAMES_QA.PROJECT_CREATE;
  return (
    <Form
      initialValues={{
        ...initialValues,
      }}
      onSubmit={onSubmit}
      validate={yupFormValidation(projectValidation)}
      subscription={{ submitFailed: true, submitting: true }}
      render={({ handleSubmit, form, submitError, submitting }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <ErrorSpy />
            <Box w="100%">
              <Typography variant="h1" mb="16px">
                New project builder
              </Typography>
              <Typography>
                Fill in the details below to add a new project to the platform
              </Typography>
              <Flex mt="15px">
                <Box w="60%" pr="12px">
                  <ProjectBasicInfo formApi={form} mainNameQA={mainNameQA} />
                </Box>
                <Box w="40%" pl="12px">
                  <ProjectTimeFrame formApi={form} mainNameQA={mainNameQA} />
                </Box>
              </Flex>
              <ProjectFinances mainNameQA={mainNameQA} />
            </Box>

            <FormSpyFieldValues fieldNames={['deazyAsSupplier']}>
              {({ deazyAsSupplier }) => {
                if (deazyAsSupplier) {
                  return null;
                }
                return (
                  <MultipleDPSection formApi={form} mainNameQA={mainNameQA} />
                );
              }}
            </FormSpyFieldValues>
            <FormSpyFieldValues fieldNames={['deazyAsClient']}>
              {({ deazyAsClient }) => {
                if (deazyAsClient) {
                  return null;
                }
                return <DeazyCard mainNameQA={mainNameQA} />;
              }}
            </FormSpyFieldValues>

            <FormSpyFieldValues
              fieldNames={['deazyAsSupplier', 'projectToSuppliers']}
            >
              {({ deazyAsSupplier, projectToSuppliers }) => {
                if (deazyAsSupplier || projectToSuppliers.length === 1) {
                  return null;
                }
                return (
                  <ProjectPrimaryDPSection
                    formApi={form}
                    mainNameQA={mainNameQA}
                  />
                );
              }}
            </FormSpyFieldValues>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => (
                <ProjectSummary project={values} mainNameQA={mainNameQA} />
              )}
            </FormSpy>
            {submitError && <ErrorText>{submitError}</ErrorText>}
            <Flex mt="72px" mb="200px">
              <FormSpy subscription={{ values: true, errors: true }}>
                {({ values, errors }) => (
                  <>
                    <ProjectFormListener
                      isProjectCreate
                      formApi={form}
                      values={values}
                    />
                    <SubmitSection
                      errors={errors}
                      values={values}
                      submitting={submitting}
                      handleSubmit={handleSubmit}
                    />
                  </>
                )}
              </FormSpy>
            </Flex>
          </form>
        );
      }}
    />
  );
};
export default ProjectForm;
