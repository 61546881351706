import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const DeleteModal = ({ isOpen, onClose, isLoading, onDelete }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styleProps={{ p: '20px' }}
      closeOnOverlayClick={!isLoading}
      closeOnEsc={!isLoading}
    >
      <Typography variant="h1" color="red.solid" mb="20px">
        Warning
      </Typography>
      <Typography>This action is irreversible.</Typography>
      <Stack mt="40px" spacing={3}>
        <Button variant="warning" isLoading={isLoading} onClick={onDelete}>
          Delete permanently
        </Button>
        <Button variant="outline" onClick={onClose} isDisabled={isLoading}>
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};

export default DeleteModal;
