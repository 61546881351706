import React from 'react';
import { isEmpty, filter } from 'lodash-es';
import { inject, observer } from 'mobx-react';

import qs from 'query-string';
import { Flex } from '@chakra-ui/react';
import useListing from '@hooks/useListing';
import { API } from '@app/api';
import { APP_ROUTES } from '@routes';
import useSubSidebarToggle from '@hooks/useSubSidebarToggle';
import PageResults from '@components/PageResults';
import Pagination from '@components/Pagination';
import PageSize from '@components/PageSize';
import ListContainer from '@components/ListContainer';
import SubSidebarTabs from '@components/SubSidebarTabs';
import SortAndOrderSelect from '@components/SortAndOrderSelect';
import TeamsList from './TeamsList';

const DELIVERY_PARTNER_OPTIONS = [
  {
    label: 'Creation date (New to Old)',
    value: 'orderDirection=DESC&orderBy=id',
  },
  {
    label: 'Creation date (Old to New)',
    value: 'orderDirection=ASC&orderBy=id',
  },
  {
    label: 'Delivery Partner name (A-Z)',
    value: 'orderDirection=ASC&orderBy=name',
  },
  {
    label: 'Delivery Partner name (Z-A)',
    value: 'orderDirection=DESC&orderBy=name',
  },
];

const CLIENT_OPTIONS = [
  {
    label: 'Creation date (New to Old)',
    value: 'orderDirection=DESC&orderBy=id',
  },
  {
    label: 'Creation date (Old to New)',
    value: 'orderDirection=ASC&orderBy=id',
  },
  {
    label: 'Client name (A-Z)',
    value: 'orderDirection=ASC&orderBy=name',
  },
  {
    label: 'Client name (Z-A)',
    value: 'orderDirection=DESC&orderBy=name',
  },
];

const Teams = ({
  location: { search },
  history,
  isSupplierRoute,
  appStore: { toggleSubSidebar, isSubSidebarOpened },
  usersStore: { isAdminOrDL },
}) => {
  const {
    page = 1,
    orderDirection = 'DESC',
    orderBy = 'id',
    ...params
  } = qs.parse(search);

  const onOrderChange = order => {
    history.push(
      `${
        isSupplierRoute ? APP_ROUTES.teams.suppliers : APP_ROUTES.teams.clients
      }?${qs.stringify({
        ...params,
        page: 1,
      })}&${order}`,
    );
  };

  const fetchTeams = React.useMemo(() => {
    if (isSupplierRoute) {
      return API.getAllSuppliers;
    }
    return API.getAllClients;
  }, [isSupplierRoute]);

  const {
    items: teams,
    totalPages,
    isLoading,
    totals,
    onFilterChange,
    fetch: refetch,
  } = useListing(fetchTeams);

  const fetchTeamsFn = () => {
    refetch({
      page: (teams || []).length === 1 && page > 1 ? page - 1 : page,
    });
  };

  useSubSidebarToggle(() => toggleSubSidebar(isAdminOrDL));

  const isListEmpty = isEmpty(teams) && !isLoading;
  const isFiltered = !isEmpty(params.query);

  const tabs = filter(
    [
      {
        label: 'Clients',
        value: 'clients',
        show: isAdminOrDL,
        link: APP_ROUTES.teams.clients,
      },
      {
        label: 'Delivery Partners',
        value: 'delivery-partners',
        show: isAdminOrDL,
        link: APP_ROUTES.teams.suppliers,
      },
    ],
    { show: true },
  );

  const activeTab = isSupplierRoute ? 'delivery-partners' : 'clients';
  return (
    <>
      {isAdminOrDL && (
        <SubSidebarTabs
          value={activeTab}
          tabs={tabs}
          subsidebarProps={{
            width: isSubSidebarOpened ? '172px' : '0',
          }}
        />
      )}
      <ListContainer>
        {(!isListEmpty || isFiltered) && (
          <Flex
            w="100%"
            justify="space-between"
            mb="20px"
            display="-webkit-inline-box"
          >
            <PageResults
              totalPages={totalPages}
              currentPage={page}
              totals={totals}
            />
            <Flex key={`teams-page-filters-${search}`}>
              <SortAndOrderSelect
                options={
                  isSupplierRoute ? DELIVERY_PARTNER_OPTIONS : CLIENT_OPTIONS
                }
                onChange={({ value: order }) => onOrderChange(order)}
                defaultValue={`orderDirection=${orderDirection}&orderBy=${orderBy}`}
              />
            </Flex>
          </Flex>
        )}
        <TeamsList
          teams={teams}
          isLoading={isLoading}
          isSupplierRoute={isSupplierRoute}
          fetchTeamsFn={fetchTeamsFn}
        />

        <Flex
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          pt="24px"
          pb="72px"
        >
          {totals > 0 && <PageSize />}
          <Pagination
            forcePage={+page - 1}
            pageCount={totalPages}
            onPageChange={p => onFilterChange({ page: p })}
          />
        </Flex>
      </ListContainer>
    </>
  );
};

export default inject('appStore', 'usersStore')(observer(Teams));
