/* eslint-disable no-underscore-dangle */
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  useToast,
  Box,
  Flex,
} from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { BRIEF_STATUSES, PROPOSAL_STATUSES } from '@app/constants';
import useMediaQuery from '@hooks/useMediaQuery';
import { APP_ROUTES } from '@routes';
import { first, map, values, includes, isEmpty, filter } from 'lodash-es';
import { API } from '@app/api';
import Badge from '@styles/Badge';
import Notification from '@components/app/Notification';

export const DropdownOption = ({
  icon,
  isDisabled,
  children,
  isSelected,
  ...props
}) => (
  <Box
    _hover={{
      bg: !isSelected && 'aqua.light',
      textDecoration: 'none',
    }}
    _active={{
      pointerEvents: isDisabled ? 'none' : 'default',
    }}
    cursor={isSelected ? 'not-allowed' : 'pointer'}
    py="9px"
    px="12px"
    bg={isSelected && 'primary.200'}
    borderRadius="8px"
    zIndex="100"
    opacity={isDisabled ? '0.4' : '1'}
    {...props}
  >
    {children}
  </Box>
);

const BriefCardBdage = ({
  brief,
  isAdminOrDL,
  isTeamAdmin,
  refetch,
  mainNameQA,
  showChatIcon,
  unreadMessagesCount,
}) => {
  const location = useLocation();

  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const [status, setStatus] = React.useState();
  React.useEffect(() => {
    if (isTeamAdmin) {
      const { proposal, proposals, status: proposalStatus } =
        first(brief.briefToSuppliers || brief.ppBriefToSuppliers) || {};
      if (!isEmpty(proposal || proposals)) {
        setStatus('Proposal');
      } else {
        setStatus(
          proposalStatus === PROPOSAL_STATUSES.PENDING
            ? 'Assigned'
            : proposalStatus,
        );
      }
    } else {
      setStatus(brief.status);
    }
  }, [brief, isTeamAdmin]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  const showToast = useToast();

  const onStatusChange = async st => {
    setIsLoading(true);
    try {
      if (status === BRIEF_STATUSES.CLOSED) {
        await API.restoreBrief(!!brief.newOrExisting)(brief.id);
      } else {
        await API.updateBriefStatus(!!brief.newOrExisting)(brief.id, {
          status: st,
        });
      }
      if (
        st !== BRIEF_STATUSES.CLOSED &&
        includes(location.pathname, APP_ROUTES.briefsClosed)
      ) {
        refetch();
      }
      if (
        st === BRIEF_STATUSES.CLOSED &&
        !includes(location.pathname, APP_ROUTES.briefsClosed)
      ) {
        refetch();
      }
      setIsOpen(false);
      setStatus(st);
    } catch (e) {
      showToast({
        position: 'top',
        isClosable: true,
        status: 'error',
        title:
          e || 'Error. Something unexpected happened. Please, try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const badgeColor = () => {
    if (status === 'Declined') {
      return 'red';
    }
    if (status === 'Proposal') {
      return 'blue';
    }
    if (status === 'Closed') {
      return 'gray';
    }
    if (status === 'On Hold') {
      return 'aqua';
    }
    return 'green';
  };

  return (
    <Popover
      isOpen={isOpen}
      placement="bottom-end"
      usePortal
      onClose={() => setIsOpen(false)}
    >
      <PopoverTrigger>
        <Flex position="absolute" right="10px" top="10px" alignItems="center">
          {showChatIcon && (isTeamAdmin || isAdminOrDL) && (
            <Notification
              iconName="message"
              mr={isSmallerScreen ? '6px' : '16px'}
              messagesCount={unreadMessagesCount}
              buttonPoitnerEvent="none"
              isDisabled={unreadMessagesCount === 0}
              mb="-8px"
            />
          )}
          <Badge
            onClick={() => {
              if (isAdminOrDL) {
                setIsOpen(true);
              }
            }}
            variant="secondary"
            bg={badgeColor()}
            data-test-id={mainNameQA && `${mainNameQA}--status`}
          >
            {status}
          </Badge>
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        zIndex={4}
        w="200px"
        border="1px solid #d9dada"
        px="4px"
        py="8px"
        borderRadius="8px"
        boxShadow="0 8px 44px 0 rgba(0, 0, 0, 0.15)"
      >
        <PopoverBody p="0px" textAlign="center" zIndex={200}>
          {isLoading ? (
            <Spinner mx="auto" my="20px" />
          ) : (
            map(
              filter(
                values(BRIEF_STATUSES),
                s =>
                  !(
                    s === BRIEF_STATUSES.ON_HOLD &&
                    status === BRIEF_STATUSES.CLOSED
                  ),
              ),
              (st, index) => (
                <DropdownOption
                  key={`brief-status-${status}-${index}`}
                  onClick={() => {
                    if (st !== status) {
                      onStatusChange(st);
                    }
                  }}
                  isSelected={st === status}
                >
                  {isLoading ? <Spinner /> : st}
                </DropdownOption>
              ),
            )
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default BriefCardBdage;
