import Modal from '@components/Modal';
import Typography from '@styles/Typography';
import React from 'react';
import ChangeRequestForm from './ChangeRequestForm';

const ChangeRequestModal = ({
  initialValues,
  isOpen,
  isTeamAdmin,
  onClose,
  isTeamMember,
  onSubmit,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styleProps={{ py: '40px', maxW: '640px' }}
    >
      <Typography variant="h2" mb="32px">
        {`${initialValues.id ? 'Update' : 'Create'} change request `}
      </Typography>
      <ChangeRequestForm
        isTeamAdmin={isTeamAdmin}
        isTeamMember={isTeamMember}
        onClose={onClose}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default ChangeRequestModal;
