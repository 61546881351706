import { Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { isEmpty, last, map, omit } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import { Form } from 'react-final-form';
import AutoSave from '../Proposal/AutoSave';
import DeazyRoleRow from './DeazyRoleRow';

const TableHeadings = () => {
  return (
    <Flex w="100%" mb="16px">
      <Typography flex={1} pl="40px" variant="caption">
        Role name
      </Typography>
      <Typography w="130px" variant="caption">
        Client Rate
      </Typography>
      <Typography w="130px" variant="caption">
        Margin
      </Typography>
      <Typography w="130px" variant="caption">
        Hours
      </Typography>
      <Typography w="130px" variant="caption">
        Cost
      </Typography>
    </Flex>
  );
};

const DeazyRolesTable = ({ deazyTeam, onSubmit, isReadOnly, mainNameQA }) => {
  return (
    <Form
      initialValues={{ deazyTeam }}
      onSubmit={() => {}}
      render={({ handleSubmit, values, form }) => {
        const addRow = () => {
          form.change('deazyTeam', [
            ...values.deazyTeam,
            { rowId: nanoid(10) },
          ]);
        };
        const deleteRow = rowId => {
          form.change(
            'deazyTeam',
            map(values.deazyTeam, res => ({
              ...res,
              ...(res.rowId === rowId && { toBeDeleted: true }),
            })),
          );
        };
        if (!isReadOnly) {
          if (!isEmpty(omit(last(values.deazyTeam), 'rowId'))) {
            addRow();
          }
        }

        return (
          <form onSubmit={handleSubmit}>
            <AutoSave
              formName="deazyLayerCoreTeam"
              formApi={form}
              onSave={onSubmit}
            />
            <TableHeadings />
            {map(values.deazyTeam, (r, idx) =>
              r?.toBeDeleted ? null : (
                <DeazyRoleRow
                  isReadOnly={isReadOnly}
                  idx={idx}
                  key={`resource-core-team-${r.rowId}`}
                  resource={r}
                  formApi={form}
                  addRow={addRow}
                  deleteRow={() => deleteRow(r.rowId)}
                  canBeDeleted={idx !== values.deazyTeam.length - 1}
                  mainNameQA={mainNameQA}
                />
              ),
            )}
          </form>
        );
      }}
    />
  );
};

export default inject('proposalsStore')(observer(DeazyRolesTable));
