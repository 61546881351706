import { Avatar, Box, Flex, Link } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Card from '@styles/Card';
import Typography from '@styles/Typography';
import { formatNumber, roundTheseHours } from '@utils';
import { prepareCostRange } from '@utils/proposalUtils';
import { get, isEmpty, lowerCase, map, find } from 'lodash-es';
import moment from 'moment';
import useMediaQuery from '@hooks/useMediaQuery';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ProposalCardBadge from './ProposalCardBadge';

const ProposalsCards = ({
  brief,
  proposals,
  onStatusChange,
  fetchDeazyCostBreakdown,
  isClient,
  deazyCostBreakdown,
  mainNameQA,
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  if (isEmpty(proposals)) {
    return (
      <Typography variant="h2" my="40px">
        No proposals submitted yet.
      </Typography>
    );
  }
  return (
    <Box>
      {map(proposals, (proposal = {}) => {
        const { supplier = {} } =
          proposal.briefToSupplier || proposal.ppBriefToSupplier || {};
        const currency = React.useMemo(() => {
          if (isClient) {
            return 'gbp';
          }
          return proposal.overriddenCurrency
            ? lowerCase(proposal.overriddenCurrency)
            : lowerCase(
                find(supplier?.paymentDetails, c => c.isPrimary)?.currency,
              ) || 'gbp';
        }, [isClient, proposal]);
        const cost = React.useMemo(() => {
          if (!isClient) {
            return brief.isPlugAndPlay
              ? formatNumber(proposal.monthlyCost)
              : formatNumber(proposal.totalCosts);
          }
          return brief.isPlugAndPlay
            ? formatNumber(proposal?.monthlyCosts)
            : prepareCostRange({
                cost: proposal?.costs?.proposalCost,
                minCost: proposal?.costs?.proposalMinCost,
                maxCost: proposal?.costs?.proposalMaxCost,
              });
        }, [isClient, proposal, brief]);
        const hours = React.useMemo(() => {
          if (!isClient) {
            return proposal?.totalHours;
          }
          return proposal?.costs?.hours;
        }, [isClient, proposal]);

        return (
          <Card
            mb="32px"
            key={`proposal-${proposal.id}`}
            data-test-id={`${mainNameQA}--singleCard`}
          >
            <Link
              as={RouterLink}
              to={
                isClient
                  ? `proposals/${proposal.id}`
                  : `proposals/${proposal.id}/delivery-partner`
              }
              _hover={{ cursor: 'pointer', textDecoration: 'none' }}
            >
              <Box
                display="flex"
                alignItems="flex-start"
                w="100%"
                mb="24px"
                py="24px"
                px="32px"
                borderBottom="1px solid"
                borderColor="dark.mid"
              >
                <Flex
                  align="center"
                  mr="auto"
                  width={isSmallerScreen && '100%'}
                >
                  {isClient ? (
                    <Typography
                      variant="h2"
                      data-test-id={`${mainNameQA}--proposal-createdAt`}
                    >
                      {proposal.createdAt
                        ? `Created at: ${moment(proposal.createdAt).format(
                            'Do MMMM YYYY',
                          )}`
                        : '-'}
                    </Typography>
                  ) : (
                    <>
                      <Avatar
                        name={get(supplier, 'name', 'Deazy')}
                        mr="7px"
                        pt={get(supplier, 'logo') ? '0px' : '3px'}
                        src={get(supplier, 'logo')}
                      />
                      <Box>
                        <Typography
                          variant="h2"
                          data-test-id={`${mainNameQA}--proposal-supplierName`}
                        >
                          {supplier.name}
                        </Typography>
                      </Box>
                    </>
                  )}
                  {isSmallerScreen && (
                    <ProposalCardBadge
                      brief={brief}
                      proposal={proposal}
                      onStatusChange={onStatusChange}
                      fetchDeazyCostBreakdown={fetchDeazyCostBreakdown}
                      deazyCostBreakdown={deazyCostBreakdown}
                      mainNameQA={mainNameQA}
                      isSmallerScreen={isSmallerScreen}
                    />
                  )}
                </Flex>
              </Box>
              <Box mb="24px" px="32px">
                <Typography variant="title" mb="12px">
                  {brief.isPlugAndPlay
                    ? 'Proposal monthly cost'
                    : 'Proposal total time & cost'}
                </Typography>
                <Flex>
                  {!brief.isPlugAndPlay && (
                    <Flex alignItems="center" mr="20px" mt="3px">
                      <Icon
                        name="clock"
                        color="blue.solid"
                        fontSize={20}
                        mr="5px"
                      />
                      <Typography
                        alignSelf="center"
                        color="dark.solid"
                        data-test-id={`${mainNameQA}--proposal-hours`}
                      >
                        {`${roundTheseHours(hours)} Hours`}
                      </Typography>
                    </Flex>
                  )}
                  <Flex alignItems="center" mt="3px">
                    <Icon
                      name={currency}
                      fontSize={20}
                      mr="5px"
                      color="blue.solid"
                      data-test-id={`${mainNameQA}--proposal-currency`}
                    />
                    <Typography
                      alignSelf="center"
                      color="dark.solid"
                      data-test-id={`${mainNameQA}--proposal-cost`}
                    >
                      {cost}
                    </Typography>
                  </Flex>
                </Flex>
              </Box>
              {!brief.isPlugAndPlay && (
                <Box px="32px" pb="32px">
                  <Typography variant="title" mb="12px">
                    Proposal summary
                  </Typography>
                  <Typography
                    data-test-id={`${mainNameQA}--proposal-summary`}
                    style={{
                      width: '100%',
                      overflow: 'hidden',
                      display: '-webkit-box',
                      '-webkit-line-clamp': '2',
                      '-webkit-box-orient': 'vertical',
                    }}
                  >
                    {proposal.summary}
                  </Typography>
                </Box>
              )}
            </Link>
            {!isSmallerScreen && (
              <ProposalCardBadge
                brief={brief}
                proposal={proposal}
                onStatusChange={onStatusChange}
                fetchDeazyCostBreakdown={fetchDeazyCostBreakdown}
                deazyCostBreakdown={deazyCostBreakdown}
                mainNameQA={mainNameQA}
              />
            )}
          </Card>
        );
      })}
    </Box>
  );
};

export default ProposalsCards;
