import { API } from '@app/api';
import { ACTION_TYPES, BRIEF_TYPES, ragStatusesOptions } from '@app/constants';
import { Box, Flex } from '@chakra-ui/react';
import MultiSelectSimple from '@components/forms/MultiSelectSimple';
import RadioGroupField from '@components/forms/RadioGroupField';
import Checkbox from '@components/forms/_common/Checkbox';
import useQuery from '@hooks/useQuery';
import Typography from '@styles/Typography';
import { find, includes, map, values as _values } from 'lodash-es';
import React from 'react';
import { Field } from 'react-final-form';
import { MatchingFiltersFields } from '@pages/Brief/FindDPs/MatchingFiltersForm';

const actionTypes = _values(ACTION_TYPES);

const labelProps = {
  textTransform: 'uppercase',
  fontSize: '12px',
  color: 'dark.solid',
  mb: '14px',
  fontFamily: 'WM',
  fontWeight: '500',
};

const PageFilters = ({
  isLoadingList,
  fieldsToShow = ['suppliers', 'actionType'],
  formValues,
  mainNameQA = '',
}) => {
  const shouldShowField = fieldName =>
    find(fieldsToShow, field => field === fieldName);

  const { data: suppliers, isLoading: isLoadingSuppliers } = useQuery(
    API.searchSuppliers,
    shouldShowField('suppliers'),
  );

  const { data: deazyLeads, isLoading: isLoadingDeazyLeads } = useQuery(
    API.searchPMs,
    shouldShowField('deazyLeadIds'),
  );

  const { data: technologies, isLoading: isLoadingTechnologies } = useQuery(
    () => API.getBriefTechStack(1, ''),
    shouldShowField('techStack'),
  );

  const technologiesOptions = React.useMemo(() => {
    return map(technologies, tech => ({
      label: tech,
      value: tech,
    }));
  }, [technologies]);

  const actionOptions = React.useMemo(() => {
    return map(actionTypes, action => ({
      label: action,
      value: action,
      isDisabled: isLoadingList,
    }));
  }, [actionTypes, isLoadingList]);

  const supplierOptions = React.useMemo(() => {
    return map(suppliers, s => ({
      label: s.name,
      value: s.id,
      isDisabled: isLoadingList,
    }));
  }, [suppliers, isLoadingList]);

  const deazyLeadOptions = React.useMemo(() => {
    return map(deazyLeads, s => ({
      label: s.fullname,
      value: s.id,
      isDisabled: isLoadingList,
    }));
  }, [deazyLeads, isLoadingList]);

  return (
    <Flex justifyContent="space-between" direction="column">
      {shouldShowField('briefType') && (
        <Box mb="24px">
          <Field
            name="type"
            label="Type"
            labelProps={labelProps}
            component={RadioGroupField}
            isVertical
            containerProps={{ minW: 0 }}
            choices={BRIEF_TYPES}
          />
        </Box>
      )}

      {shouldShowField('ragStatus') && (
        <Box mb="25px">
          <Typography {...labelProps}>Rag Status</Typography>
          {ragStatusesOptions.map(({ label, value }) => (
            <Field
              value={value}
              key={`rag-status-${value}`}
              containerStyles={{ mb: '22px' }}
              name="ragStatus"
              component={Checkbox}
              label={label}
              checked={includes(formValues.ragStatus, value)}
              disabled={isLoadingList}
              type="checkbox"
              id={value}
            />
          ))}
        </Box>
      )}

      {shouldShowField('suppliers') && (
        <Field
          name="suppliers"
          label="Delivery Partner"
          component={MultiSelectSimple}
          menuPosition="relative"
          isLoading={isLoadingSuppliers}
          options={supplierOptions}
          isClearable={false}
          placeholder="All"
          notRemoveFromInput
          styleProps={{ w: '100%' }}
          labelProps={labelProps}
          data-test-id={`${mainNameQA}--suppliers`}
          showCounter
        />
      )}

      {shouldShowField('deazyLeadIds') && (
        <Field
          name="deazyLeadIds"
          label="Deazy Lead"
          component={MultiSelectSimple}
          menuPosition="relative"
          isLoading={isLoadingDeazyLeads}
          options={deazyLeadOptions}
          isClearable={false}
          placeholder="All"
          notRemoveFromInput
          data-test-id={`${mainNameQA}--deazyLeadIds`}
          styleProps={{ w: '100%', mt: '28px' }}
          labelProps={labelProps}
          showCounter
        />
      )}

      {shouldShowField('techStack') && (
        <Field
          name="techStack"
          label="Tech Stack"
          component={MultiSelectSimple}
          menuPosition="relative"
          isLoading={isLoadingTechnologies}
          options={technologiesOptions}
          isClearable={false}
          placeholder="All"
          data-test-id={`${mainNameQA}--techStack`}
          notRemoveFromInput
          styleProps={{ w: '100%', mt: '28px' }}
          labelProps={labelProps}
          showCounter
        />
      )}

      {shouldShowField('actionType') && (
        <Box mb="25px" mt="28px">
          <Typography {...labelProps}>Open actions</Typography>
          {actionOptions.map(({ label, value }) => (
            <Field
              value={value}
              key={`open-action-${value}`}
              containerStyles={{ mb: '22px' }}
              name="actionType"
              component={Checkbox}
              label={label}
              checked={includes(formValues.actionType, value)}
              disabled={isLoadingList}
              type="checkbox"
              id={value}
            />
          ))}
        </Box>
      )}

      {shouldShowField('matchingFilters') && (
        <MatchingFiltersFields values={formValues} />
      )}

      {shouldShowField('taskCategory') && (
        <Box mb="24px">
          <Typography {...labelProps}>Category</Typography>
          <Box>
            <Field
              containerStyles={{ mb: '22px' }}
              name="groupedBrief"
              label="Brief"
              component={Checkbox}
              id="grouped-brief"
            />
            <Field
              containerStyles={{ mb: '22px' }}
              name="groupedProposal"
              label="Proposal"
              component={Checkbox}
              id="grouped-proposal"
            />
            <Field
              containerStyles={{ mb: '22px' }}
              name="groupedInvoice"
              label="Invoice"
              component={Checkbox}
              id="grouped-invoice"
            />
            <Field
              containerStyles={{ mb: '22px' }}
              name="groupedProject"
              label="Project"
              component={Checkbox}
              id="grouped-project"
            />
          </Box>
        </Box>
      )}
    </Flex>
  );
};

export default PageFilters;
