/* eslint-disable max-len */
import React from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import { values, filter, find } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import NotMatchedModal from './NotMatchedModal';

const missingAttributesTypos = [
  {
    id: 1,
    fieldName: 'isAccountable',
    title: 'Accountable',
    desc:
      'The team do <span style="font-weight: 600;">not</span> have experienced project managers that take ownership of delivery',
  },
  {
    id: 2,
    fieldName: 'isStructured',
    title: 'Structured',
    desc:
      'The team may struggle to manage change effectively, roles and responsibilities are not always clear and they may not consistently user or deliver documentation',
  },
  {
    id: 3,
    fieldName: 'isGoodCommunication',
    title: 'Communicative',
    desc: `The team may need to be chased to provide updates. They may <span style="font-weight: 600;">not</span> be comfortable managing expectations and describing technical information to clients.`,
  },
  {
    id: 4,
    fieldName: 'isFlexible',
    title: 'Flexible',
    desc:
      'The team do <span style="font-weight: 600;">not</span> respond well to change such as client ways of working, changing team sizes and changing priority order of deliverables. They may be disrupted by scope ambiguity, delivering multiple projects or missing dependencies.',
  },
  {
    id: 5,
    fieldName: 'isInquisitive',
    title: 'Inquisitive',
    desc:
      'The team are most comfortable with solutions they have previous experience in',
  },
  {
    id: 6,
    fieldName: 'isAccountable',
    title: 'Accountable',
    desc:
      'Developers may struggle to solve problems independently or ask for help at the right times. They may hide behind blockers.',
    isPnP: true,
  },
  {
    id: 7,
    fieldName: 'isResponsiveOutsideDelivery',
    title: 'Responsive outside delivery',
    desc:
      'The Delivery Partner is slower than average to respond to new briefs and provide proposals',
    isPnP: true,
  },
];

const MatchedCounter = ({
  briefsStore: { brief },
  counter = 0,
  matchedQuestions = [],
  ...props
}) => {
  const total = filter(
    values(brief.briefQuestions || brief.ppBriefQuestions),
    v => v === true,
  ).length;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const options = React.useMemo(() => {
    const pnpFilter = opt => (brief.isPlugAndPlay ? opt.isPnP : !opt.isPnP);
    const optionFilter = opt => {
      const questions = brief.briefQuestions || brief.ppBriefQuestions;
      return (
        !find(matchedQuestions, o => o === opt.fieldName) &&
        questions[opt.fieldName]
      );
    };
    return filter(filter(missingAttributesTypos, pnpFilter), optionFilter);
  }, [brief]);

  if (total > 0) {
    return (
      <>
        <Box
          border="1px solid #D9DFFF"
          borderRadius="8px"
          bg="primary.100"
          p="11.5px 8px"
          mr="12px"
          onClick={counter < total && onOpen}
          _hover={{
            cursor: counter < total && 'pointer',
          }}
          {...props}
        >
          {`${counter}/${total} matched`}
        </Box>
        <NotMatchedModal options={options} isOpen={isOpen} onClose={onClose} />
      </>
    );
  }
  return null;
};

export default inject('briefsStore')(observer(MatchedCounter));
