import { Box } from '@chakra-ui/react';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { get } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { MAIN_NAMES_QA } from '@app/constants';
import { isInvoiceApprovedAtLeast } from '@utils/invoiceUtils';
import InvoiceFooter from './InvoiceFooter';
import InvoiceHeader from './InvoiceHeader';
import InvoiceItems from './InvoiceItems';
import InvoiceSides from './InvoiceSides';
import InvoiceTotals from './InvoiceTotals';

const { REACT_APP_API_URL: API_URL } = process.env;

const mainNameQA = MAIN_NAMES_QA.PROJECT_INVOICE;

const InvoiceView = ({
  projectId,
  match: {
    params: { invoiceId, type },
  },
  invoicesStore: { invoice: scheduleInvoice, fetchScheduleInvoice, isLoading },
}) => {
  React.useEffect(() => {
    fetchScheduleInvoice(projectId, invoiceId);
  }, []);

  const { downloadLink, invoice } = React.useMemo(() => {
    return {
      invoice: scheduleInvoice,
      downloadLink: `${API_URL}/projects/${get(
        scheduleInvoice,
        'projectPayment.project.id',
      )}/scheduleInvoices/${scheduleInvoice.id}/pdf`,
    };
  }, [scheduleInvoice, type]);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Box maxW="1280px" px="40px" w="100%" mx="auto" pb="100px" mt="32px">
      <Box
        maxW="1048px"
        px="40px"
        pt="40px"
        w="100%"
        pb="12px"
        mt="32px"
        border="1px solid"
        borderColor="dark.mid"
        bg="#fff"
      >
        <InvoiceHeader
          invoice={invoice}
          downloadLink={downloadLink}
          isDownloadable={isInvoiceApprovedAtLeast(invoice)}
          mainNameQA={mainNameQA}
        />
        <Typography variant="h1" my="32px">
          Tax invoice
        </Typography>
        <InvoiceSides invoice={invoice} mainNameQA={mainNameQA} />
        <InvoiceItems invoice={invoice} mainNameQA={mainNameQA} />
        <InvoiceTotals invoice={invoice} maxW="284px" mainNameQA={mainNameQA} />
        <InvoiceFooter invoice={invoice} mainNameQA={mainNameQA} />
      </Box>
    </Box>
  );
};

export default inject('invoicesStore')(observer(InvoiceView));
