import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import Spinner from '@styles/Spinner';
import { APP_ROUTES } from '@routes';
import UserCreateForm from './UserCreateForm';
import ClientCreateModal from './ClientCreateModal';
import SupplierCreateModal from './SupplierCreateModal';
import DeveloperCreateForm from '../Team/DeveloperCreateForm';

const UserCreate = ({
  usersStore: {
    createUserByRole,
    updateUser,
    fetchUser,
    user,
    isProfileLoaded,
    isLoading,
    isAdminOrDL,
    profile,
  },
  teamsStore: { createClient, createSupplier, newClient, newSupplier },
  history,
  isDeveloperCreateRoute,
  match: {
    params: { id },
  },
}) => {
  useEffect(() => {
    fetchUser(id);
  }, []);
  const {
    isOpen: isCreateClientModalOpen,
    onOpen: onCreateClientModalOpen,
    onClose: onCreateClientModalClose,
  } = useDisclosure();

  const {
    isOpen: isCreateSupplierModalOpen,
    onOpen: onCreateSupplierModalOpen,
    onClose: onCreateSupplierModalClose,
  } = useDisclosure();

  return (
    <>
      <Box maxW="1201px" h="100%" w="100%" mx="auto" minH="calc(100vh - 135px)">
        {isLoading || !isProfileLoaded ? (
          <Spinner />
        ) : (
          <Flex
            px="32px"
            pt="39px"
            pb="50px"
            direction="column"
            align="flex-start"
          >
            {!isDeveloperCreateRoute ? (
              <UserCreateForm
                user={user}
                isAdminOrDL={isAdminOrDL}
                onCreateClientModalOpen={onCreateClientModalOpen}
                onCreateSupplierModalOpen={onCreateSupplierModalOpen}
                newClient={newClient}
                newSupplier={newSupplier}
                history={history}
                onSubmit={values => {
                  if (!values.id) {
                    return createUserByRole({
                      role: values.role,
                      values,
                      onSuccess: u => {
                        history.push(APP_ROUTES.users.userById(u.id));
                      },
                    });
                  }
                  return updateUser({
                    userId: values.id,
                    values,
                    onSuccess: () => {
                      history.push(APP_ROUTES.users.userById(values.id));
                    },
                  });
                }}
              />
            ) : (
              <DeveloperCreateForm
                supplier={profile.supplier}
                history={history}
                isDeveloperCreateRoute={isDeveloperCreateRoute}
                onSubmit={values => {
                  return createUserByRole({
                    role: values.role,
                    values,
                    onSuccess: u => {
                      history.push(APP_ROUTES.users.userById(u.id));
                    },
                  });
                }}
              />
            )}
          </Flex>
        )}
      </Box>

      {isCreateClientModalOpen && (
        <ClientCreateModal
          isOpen={isCreateClientModalOpen}
          onClose={onCreateClientModalClose}
          onSubmit={values =>
            createClient({ values, onSuccess: onCreateClientModalClose })
          }
        />
      )}
      {isCreateSupplierModalOpen && (
        <SupplierCreateModal
          isOpen={isCreateSupplierModalOpen}
          onClose={onCreateSupplierModalClose}
          createSupplier={createSupplier}
        />
      )}
    </>
  );
};
export default inject('usersStore', 'teamsStore')(observer(UserCreate));
