import { API } from '@app/api';
import {
  CURRENCY_OPTIONS,
  ENGAGEMENT_TYPES_OPTIONS,
  PROJECT_TYPES,
  PROJECT_TYPE_OPTIONS,
  MAIN_NAMES_QA,
} from '@app/constants';
import { Box, Stack } from '@chakra-ui/react';
import DatePicker from '@components/forms/DatePicker';
import AsyncAutocomplete from '@components/forms/_common/AsyncAutocomplete';
import Select from '@components/forms/_common/Select';
import TextInput from '@components/forms/_common/TextInput';
import Switcher from '@components/Switcher';
import { ProjectGroupTagTooltip } from '@pages/ProjectsCreate/ProjectFinances';
import Typography from '@styles/Typography';
import { formatCurrency } from '@utils';
import { mustBeShorterOrEqual, required } from '@utils/formValidators';
import { omit, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Field, Form } from 'react-final-form';
import useQuery from '@hooks/useQuery';
import useMediaQuery from '@hooks/useMediaQuery';
import MultiDPSection, { cellProps, FieldSection } from './MultiDPSection';
import ProjectSettingsStickyFooter from './ProjectSettingsStickyFooter';

const getDatePickerProps = (startDate, blockPast = true) => ({
  disabledDays: blockPast
    ? undefined
    : day => moment.utc(day).isAfter(moment.utc(startDate).endOf('day')),
});

const OverviewForm = ({
  usersStore: { isAdminOrDL, isTeamAdmin, isTeamMember },
  sowsStore: {
    canSeeSowOverviewFields,
    cannotChangeFinalisedSupplierSOW,
    thereIsFinalisedDateSupplierSOW,
  },
  onSubmit,
  paymentsStore: { fetchearliestStartDate, earliestStartDate },
  projectsStore: {
    toggleProjectCRSendout,
    project: {
      sendClientCrEmails,
      id,
      syncEnabled,
      pending,
      clientSOWSignedOutsideOfPlatform,
      finalisedDateClientSOW,
    },
    project,
  },
}) => {
  const isFixedPrice = project.projectType === PROJECT_TYPES.FIXED_PRICE;

  React.useEffect(() => {
    if (id) {
      fetchearliestStartDate(id);
    }
  }, [id]);

  const datePickerProps = getDatePickerProps(earliestStartDate, false);

  const onProjectSync = (projectId, enabled) => {
    API.projectSync(projectId, { enabled });
    project.syncEnabled = enabled;
  };

  const onMilestonesEnabled = (projectObj, enabled) => {
    const projectWithoutXero = omit(projectObj, ['syncEnabled']);
    API.updateProject({ ...projectWithoutXero, milestonesEnabled: enabled });
  };

  const mainNameQA = MAIN_NAMES_QA.PROJECT_SETTINGS_FORM;

  const initialValues = {
    ...project,
    clientTotalValue: formatCurrency(
      project.clientTotalValue,
      project.currency,
    ),
    projectToSuppliers: map(project.projectToSuppliers, pts => {
      return {
        ...pts,
        supplierTotalValue: formatCurrency(
          pts.supplierTotalValue,
          pts.overriddenCurrency,
        ),
      };
    }),
  };

  const { data, isLoading } = useQuery(
    () => API.getLastAllocationEntryEndDate(project.id),
    isFixedPrice && isAdminOrDL,
  );

  const endDateDisableFn = day =>
    data?.lastAllocationEntryEndDate &&
    moment
      .utc(day)
      .isBefore(moment.utc(data?.lastAllocationEntryEndDate).startOf('day'));

  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, dirty: isDirty, values }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Box
              maxW="1280px"
              w="100%"
              mx="auto"
              px="40px"
              pb={isSmallerScreen ? '188px' : '100px'}
            >
              <FieldSection
                title="General Info"
                data-test-id={`${mainNameQA}--generalInfo`}
              >
                <Box {...cellProps}>
                  <Field
                    name="engagementType"
                    data-test-id={`${mainNameQA}--engagementType`}
                    isDisabled
                    component={Select}
                    options={ENGAGEMENT_TYPES_OPTIONS}
                    label="Engagement type"
                  />
                </Box>
                <Box {...cellProps}>
                  <Field
                    label="Deazy Lead"
                    component={AsyncAutocomplete}
                    name="pm"
                    data-test-id={`${mainNameQA}--pm`}
                    getOptionsFn={API.searchPMs}
                    customOptionMap={option => ({
                      value: option,
                      label: `${option.fullname} <${option.email}>`,
                    })}
                    placeholder="Add a deazy lead"
                    validate={required}
                    isDisabled={!isAdminOrDL}
                  />
                </Box>
                <Box {...cellProps}>
                  <Field
                    name="startDate"
                    data-test-id={`${mainNameQA}--startDate`}
                  >
                    {props => (
                      <DatePicker
                        {...props}
                        label="Start Date"
                        minW="120px"
                        dayPickerProps={datePickerProps}
                        isDisabled={
                          moment
                            .utc()
                            .endOf('day')
                            .isAfter(moment.utc(props.input.value)) ||
                          !isAdminOrDL
                        }
                      />
                    )}
                  </Field>
                </Box>
                <Box {...cellProps}>
                  <Field
                    component={DatePicker}
                    minW="120px"
                    name="endDate"
                    {...(isFixedPrice && { disabledDaysFn: endDateDisableFn })}
                    data-test-id={`${mainNameQA}--endDate`}
                    label="End Date"
                    isDisabled={!isAdminOrDL || isLoading}
                  />
                </Box>
              </FieldSection>
              <FieldSection
                title="Project Finances"
                data-test-id={`${mainNameQA}--projectFinances`}
              >
                <Box {...cellProps}>
                  <Field
                    name="projectType"
                    data-test-id={`${mainNameQA}--projectType`}
                    isDisabled
                    component={Select}
                    options={PROJECT_TYPE_OPTIONS}
                    label="Billing Model"
                  />
                </Box>
                <Box {...cellProps}>
                  <Field
                    name="trackingCategory"
                    data-test-id={`${mainNameQA}--trackingCategory`}
                    label={
                      <Typography>
                        Project group tag
                        <ProjectGroupTagTooltip />
                      </Typography>
                    }
                    component={AsyncAutocomplete}
                    getOptionsFn={API.trackingCategories}
                    customOptionMap={option => ({
                      value: option,
                      label: option,
                    })}
                    isDisabled
                  />
                </Box>
                <Box {...cellProps}>
                  <Field
                    component={TextInput}
                    name="purchaseOrder"
                    data-test-id={`${mainNameQA}--purchaseOrder`}
                    label="Purchase Order"
                    placeholder="Enter a PO"
                    isDisabled={!isAdminOrDL}
                  />
                </Box>
                <Box {...cellProps}>
                  <Field
                    name="currency"
                    data-test-id={`${mainNameQA}--currency`}
                    isDisabled
                    component={Select}
                    options={CURRENCY_OPTIONS}
                    label="Project Currency"
                  />
                </Box>
              </FieldSection>

              {!isTeamAdmin && !isTeamMember && !values.deazyAsClient && (
                <FieldSection
                  title="Client Info"
                  data-test-id={`${mainNameQA}--clientInfo`}
                >
                  <Box {...cellProps}>
                    <Field
                      name="client"
                      data-test-id={`${mainNameQA}--client`}
                      component={AsyncAutocomplete}
                      getOptionsFn={API.searchClients}
                      label="Client"
                      isDisabled
                    />
                  </Box>

                  <Box {...cellProps}>
                    <Field
                      name="client"
                      data-test-id={`${mainNameQA}--client`}
                      subscription={{ value: true }}
                    >
                      {({ input: { value: client = {} } }) => (
                        <Field
                          name="clientLead"
                          data-test-id={`${mainNameQA}--clientLead`}
                          label="Client lead"
                          isDisabled={!client.id || !isAdminOrDL}
                          reloadOnEmpty
                          component={AsyncAutocomplete}
                          getOptionsFn={API.searchClientContactUsers(client.id)}
                          customOptionMap={option => ({
                            value: option,
                            label: option.fullname,
                          })}
                          placeholder="Add a client lead"
                          validate={required}
                        />
                      )}
                    </Field>
                  </Box>

                  <Box {...cellProps}>
                    <Field
                      name="clientFinanceEmail"
                      data-test-id={`${mainNameQA}--clientFinanceEmail`}
                      component={TextInput}
                      isDisabled
                      placeholder="E-mail"
                      label="Client finance email"
                    />
                  </Box>

                  <Box {...cellProps}>
                    <Field
                      name="clientTotalValue"
                      data-test-id={`${mainNameQA}--clientTotalValue`}
                      isDisabled
                      label="Initial clients project amount"
                      component={TextInput}
                    />
                  </Box>

                  {canSeeSowOverviewFields && [
                    <Box {...cellProps} key="sow-client-reference">
                      <Field
                        component={TextInput}
                        name="clientSOWReference"
                        data-test-id={`${mainNameQA}--clientSOWReference`}
                        label="Client SOW reference"
                        placeholder="Enter client SOW reference"
                        isDisabled={!isAdminOrDL}
                        validate={mustBeShorterOrEqual(200)}
                      />
                    </Box>,
                    <Box {...cellProps} key="sow-client-signed-date">
                      <Field
                        component={DatePicker}
                        minW="120px"
                        name="finalisedDateClientSOW"
                        data-test-id={`${mainNameQA}--finalisedDateClientSOW`}
                        label="Client signed SOW date"
                        isDisabled={
                          !isAdminOrDL ||
                          (finalisedDateClientSOW &&
                            !clientSOWSignedOutsideOfPlatform)
                        }
                      />
                    </Box>,
                  ]}
                </FieldSection>
              )}
              <MultiDPSection
                values={values}
                isAdminOrDL={isAdminOrDL}
                canSeeSowOverviewFields={canSeeSowOverviewFields}
                cannotChangeFinalisedSupplierSOW={
                  cannotChangeFinalisedSupplierSOW
                }
                thereIsFinalisedDateSupplierSOW={
                  thereIsFinalisedDateSupplierSOW
                }
                mainNameQA={mainNameQA}
              />
              {isAdminOrDL && (
                <Box
                  bg="#fff"
                  borderRadius="8px"
                  padding="40px"
                  data-test-id={`${mainNameQA}--other`}
                >
                  <Typography variant="h3" mb="24px">
                    Other
                  </Typography>
                  {isFixedPrice && (
                    <Switcher
                      leftLabel="Send CR emails"
                      data-test-id={`${mainNameQA}--sendCrEmails`}
                      disallowLabelClick
                      onChange={toggleProjectCRSendout}
                      defaultChecked={sendClientCrEmails}
                      pr="26px"
                      fontSize="14px"
                    />
                  )}
                  <Stack mb="29px" />
                  <Field type="checkbox" name="milestonesEnabled">
                    {props => (
                      <Switcher
                        leftLabel="Show milestones"
                        data-test-id={`${mainNameQA}--showMilestones`}
                        disallowLabelClick
                        onChange={enabled => {
                          props.input.onChange(enabled);
                          onMilestonesEnabled(project, enabled);
                        }}
                        defaultChecked={props.input.checked}
                        pr="26px"
                        fontSize="14px"
                      />
                    )}
                  </Field>
                  <Box mt="24px">
                    <Switcher
                      isDisabled={pending}
                      leftLabel="Xero"
                      data-test-id={`${mainNameQA}--xero`}
                      disallowLabelClick
                      defaultChecked={syncEnabled}
                      onChange={checked => {
                        onProjectSync(id, checked);
                      }}
                      pr="26px"
                      fontSize="14px"
                    />
                  </Box>
                </Box>
              )}
            </Box>
            <ProjectSettingsStickyFooter
              submitting={submitting}
              isDirty={isDirty}
            />
          </form>
        );
      }}
    />
  );
};

export default inject(
  'usersStore',
  'projectsStore',
  'sowsStore',
  'paymentsStore',
)(observer(OverviewForm));
