import React, { useState } from 'react';
import { Box, Flex, Button } from '@chakra-ui/react';
import { Field, Form } from 'react-final-form';
import TextInput from '@components/forms/_common/TextInput';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import { INPUT_VARIANTS } from '@styles/theme';
import { ErrorText } from '@components/forms/FormError';
import { isEmpty, find, reject, cloneDeep } from 'lodash-es';
import {
  required,
  mustBeValidEmail,
  mustBeAtLeastCharLong,
  composeValidators,
} from '@utils/formValidators';
import Select from '@components/forms/Select';
import { NEW_ROLES } from '@app/constants';
import AsyncAutocomplete from '@components/forms/AsyncAutocomplete';
import { API } from '@app/api';
import { inject, observer } from 'mobx-react';

const ROLE_TYPE_OPTIONS = [
  { value: NEW_ROLES.ADMIN, label: 'Admin' },
  { value: NEW_ROLES.DEAZY_LEAD, label: 'Deazy Lead' },
  { value: NEW_ROLES.CLIENT_LEAD, label: 'Client Lead' },
  { value: NEW_ROLES.TEAM_MEMBER, label: 'Team Member' },
  { value: NEW_ROLES.TEAM_ADMIN, label: 'Team Admin' },
];

const UserCreateForm = ({
  onSubmit,
  onCreateClientModalOpen,
  onCreateSupplierModalOpen,
  newClient,
  newSupplier,
  usersStore: { isAdmin, isClient, profile },
  history,
  user,
}) => {
  const [userStateHelper, setUserStateHelper] = useState({});
  return (
    <Box maxW="1201px" h="100%" w="100%" mx="auto" minH="calc(100vh - 135px)">
      <Flex h="100%" px="32px" pt="39px" direction="column" align="flex-start">
        <Form
          initialValues={{
            ...user,
            ...userStateHelper,
            client: !isEmpty(newClient) ? { ...newClient } : null,
            supplier: !isEmpty(newSupplier) ? { ...newSupplier } : null,
            ...(isClient && {
              client: cloneDeep(profile.customer),
              role: NEW_ROLES.CLIENT_LEAD,
            }),
          }}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitError, submitting, values, form }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Flex flexDirection="column">
                  {!values.id && (
                    <Box mb="32px">
                      <Field
                        isDisabled={isClient}
                        label="User type"
                        name="role"
                        placeholder="Choose a user role"
                        component={Select}
                        variant={INPUT_VARIANTS.NORMAL}
                        validate={required}
                        options={
                          !isAdmin
                            ? reject(ROLE_TYPE_OPTIONS, {
                                value: NEW_ROLES.ADMIN,
                              })
                            : ROLE_TYPE_OPTIONS
                        }
                        isBordered
                        onAfterChange={role => {
                          form.change('client', null);
                          if (
                            !(
                              (values.role === NEW_ROLES.TEAM_ADMIN ||
                                values.role === NEW_ROLES.TEAM_MEMBER) &&
                              (role === NEW_ROLES.TEAM_ADMIN ||
                                role === NEW_ROLES.TEAM_MEMBER)
                            )
                          ) {
                            form.change('supplier', null);
                          }
                        }}
                      />
                    </Box>
                  )}
                  {values.role === NEW_ROLES.CLIENT_LEAD && (
                    <Field
                      name="client"
                      label="Client"
                      isDisabled={isClient}
                      placeholder="Choose a client"
                      component={AsyncAutocomplete}
                      styleProps={{ mb: '32px' }}
                      getOptionsFn={API.searchClients}
                      isBordered
                      validate={required}
                      isCreatable
                      onAfterChange={value => {
                        if (!value.id) {
                          setUserStateHelper({ ...values });
                          onCreateClientModalOpen();
                        }
                      }}
                    />
                  )}
                  {(values.role === NEW_ROLES.TEAM_MEMBER ||
                    values.role === NEW_ROLES.TEAM_ADMIN) && (
                    <Field
                      name="supplier"
                      label="Delivery Partner"
                      placeholder="Choose a Delivery Partner"
                      component={AsyncAutocomplete}
                      styleProps={{ mb: '32px' }}
                      getOptionsFn={API.searchSuppliers}
                      isBordered
                      validate={required}
                      isCreatable
                      onAfterChange={value => {
                        if (!value.id) {
                          setUserStateHelper({ ...values });
                          onCreateSupplierModalOpen();
                        }
                      }}
                    />
                  )}
                  <Field
                    label="Username"
                    name="username"
                    placeholder="Enter your username"
                    type="text"
                    component={TextInput}
                    variant={INPUT_VARIANTS.NORMAL}
                    validate={composeValidators(
                      required,
                      mustBeAtLeastCharLong(5),
                    )}
                    isBordered
                    containerProps={{ mb: '32px' }}
                  />
                  <Field
                    label="Email"
                    name="email"
                    placeholder="Email"
                    type="text"
                    component={TextInput}
                    variant={INPUT_VARIANTS.NORMAL}
                    validate={composeValidators(required, mustBeValidEmail)}
                    isBordered
                    containerProps={{ mb: '32px' }}
                  />
                  <Field
                    label="Full name"
                    name="fullname"
                    placeholder="Full name"
                    type="text"
                    component={TextInput}
                    variant={INPUT_VARIANTS.NORMAL}
                    validate={required}
                    isBordered
                    containerProps={{ mb: '32px' }}
                  />
                  <Field
                    label="Password"
                    name="password"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    component={TextInput}
                    variant={INPUT_VARIANTS.NORMAL}
                    validate={required}
                    isBordered
                    containerProps={{ mb: '32px' }}
                  />

                  {(values.role === NEW_ROLES.DEAZY_LEAD ||
                    find(
                      user?.roles,
                      r => r.name === NEW_ROLES.DEAZY_LEAD,
                    )) && (
                    <Field
                      name="rate"
                      label="Rate"
                      component={MaskedTextInput}
                      containerProps={{ mb: '32px' }}
                      isBordered
                      type="number"
                      allowNegative={false}
                      placeholder="0"
                      validate={required}
                    />
                  )}
                  {submitError && <ErrorText>{submitError}</ErrorText>}
                  <Flex mt="56px">
                    <Button
                      isLoading={submitting}
                      isDisabled={submitting}
                      type="submit"
                      bg="green.solid"
                      fontWeight="400"
                      fontSize="18px"
                      py="18px"
                      minW="200px"
                      color="#fff"
                      borderRadius="30px"
                      h="auto"
                      mr="16px"
                    >
                      {values.id ? 'Update' : 'Create'}
                    </Button>
                    <Button
                      fontWeight="400"
                      borderRadius="30px"
                      minW="180px"
                      borderWidth="2px"
                      fontSize={18}
                      variant="outline"
                      h="56px"
                      borderColor="blue.solid"
                      isDisabled={submitting}
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </Flex>
              </form>
            );
          }}
        />
      </Flex>
    </Box>
  );
};

export default inject('usersStore')(observer(UserCreateForm));
