import { Box } from '@chakra-ui/react';
import Icon from '@components/Icon';
import { isEmpty, map } from 'lodash-es';
import React from 'react';
import RoleRow from './RoleRow';

const RolesTable = ({
  onAddRole,
  onDeleteRole,
  onSaveComment,
  resources,
  isReadOnly,
  errors,
  shouldShowCandidateError,
  formApi,
  isSmallerScreen,
  mainNameQA,
  isEditingMode,
}) => {
  const { tableError, candidateErrors } = errors;

  return (
    <>
      {map(resources, (r, idx) => (
        <RoleRow
          isSmallerScreen={isSmallerScreen}
          isEditingMode={isEditingMode}
          {...r}
          formApi={formApi}
          isReadOnly={isReadOnly}
          onDeleteRole={onDeleteRole}
          onSaveComment={onSaveComment}
          idx={idx}
          onAddRole={onAddRole}
          shouldShowCandidateError={shouldShowCandidateError}
          candidateErrors={candidateErrors}
          key={`role-row-${r.id}`}
          mainNameQA={mainNameQA}
        />
      ))}
      {!isEmpty(tableError) && (
        <Box className="final-form-error" color="red.500" mt="24px">
          <Icon name="warning" color="red.500" mb="2px" mr="5px" />
          {tableError}
        </Box>
      )}
    </>
  );
};

export default RolesTable;
