import { NEW_ROLES } from '@app/constants';
import { Box, Flex, Grid, useDisclosure } from '@chakra-ui/react';
import Button from '@styles/Button';
import Modal from '@components/Modal';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import { addFullDescription } from '@utils/usersUtils';
import { filter, find, isEmpty, isUndefined, map } from 'lodash-es';
import React from 'react';
import AddUserToTeamModal from './AddUserToTeamModal';
import DeveloperCreateForm from './DeveloperCreateForm';
import { Stat } from '../Teams/TeamCardContent';

const UsersDetails = ({
  users,
  teamId,
  handleRemoveUser,
  handleAddUser,
  isAdminOrDL,
  isTeamAdmin,
  createUserByRole,
  supplier,
  fetchSupplier,
  isSupplierRoute,
  toastsStore,
  profile,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    isOpen: isCreateUserModalOpen,
    onOpen: onCreateUserModalOpen,
    onClose: onCreateUserModalClose,
  } = useDisclosure();

  const mapRolesToDescriptions = item => {
    const roles = map(item.roles, el => ({
      ...el,
      fullDescription: addFullDescription(el.name),
    }));
    return map(
      filter(
        roles,
        r =>
          r.name === NEW_ROLES.TEAM_MEMBER || r.name === NEW_ROLES.TEAM_ADMIN,
      ),
      'fullDescription',
    ).join(', ');
  };

  return (
    <>
      <WhiteCard p="40px">
        <Flex alignItems="center" justifyContent="space-between" mb="24px">
          <Typography variant="h3">Member list</Typography>
          <Flex>
            {(isAdminOrDL || isTeamAdmin) && isSupplierRoute && (
              <>
                <Button
                  variant="outline"
                  leftIcon={{ name: 'add' }}
                  onClick={onCreateUserModalOpen}
                >
                  Create user
                </Button>
              </>
            )}
            {isAdminOrDL && (
              <Button
                leftIcon={{ name: 'add' }}
                onClick={onOpen}
                variant="outline"
                ml="12px"
              >
                Add member
              </Button>
            )}
          </Flex>
        </Flex>
        <Box>
          {!isEmpty(users) ? (
            users.map(item => {
              const mappedRoles = mapRolesToDescriptions(item);

              const userHasSupplierAdminRole =
                find(item.roles, { name: 'SA' }) ||
                find(item.roles, { name: 'TA' });

              const deleteVisible =
                isUndefined(userHasSupplierAdminRole) &&
                profile.email !== item.email;

              return (
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  key={`role-${item.id}`}
                  borderBottom="1px solid"
                  borderColor="dark.mid"
                  mt="40px"
                  _notLast={{
                    mb: '12px',
                  }}
                >
                  <Grid templateColumns="repeat(3, 1fr)" width="100%">
                    <Stat
                      label="User name"
                      value={item.username}
                      ml="0"
                      mb="0"
                      pl="0px"
                      borderLeft="none"
                    />
                    <Stat
                      label="Email"
                      value={item.email}
                      ml="0"
                      pl="0px"
                      mb="0"
                      borderLeft="none"
                    />
                    {isSupplierRoute && (
                      <Stat
                        label="Role"
                        value={mappedRoles}
                        pl="0px"
                        mb="0"
                        ml="0"
                        borderLeft="none"
                      />
                    )}
                  </Grid>
                  {deleteVisible ? (
                    <Button
                      iconButton={{
                        name: 'trashNew',
                        color: 'black',
                        fontSize: 14,
                      }}
                      w="42px"
                      variant="ghost"
                      isDisabled={userHasSupplierAdminRole && !isAdminOrDL}
                      onClick={() => handleRemoveUser(item.id, teamId)}
                    />
                  ) : (
                    <Box w="42px" />
                  )}
                </Flex>
              );
            })
          ) : (
            <Typography>Client has no members</Typography>
          )}
        </Box>
      </WhiteCard>
      {isOpen && (
        <AddUserToTeamModal
          handleAddUser={handleAddUser}
          teamId={teamId}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      {isCreateUserModalOpen && (
        <Modal
          styleProps={{ minW: '720px' }}
          isOpen={isCreateUserModalOpen}
          onClose={onCreateUserModalClose}
        >
          <DeveloperCreateForm
            supplier={supplier}
            isAdminOrDL={isAdminOrDL}
            onClose={onCreateUserModalClose}
            onSubmit={values => {
              return createUserByRole({
                role: values.role,
                values,
                onSuccess: () => {
                  fetchSupplier(supplier.id);
                  onCreateUserModalClose();
                },
                onFailure: e => {
                  toastsStore.showError({
                    title:
                      e.message ||
                      'Something went wrong. Please, try again later.',
                  });
                },
              });
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default UsersDetails;
