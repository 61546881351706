import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';
import { Field, Form } from 'react-final-form';
import Textarea from '@components/forms/_common/Textarea';
import {
  composeValidators,
  required,
  mustBeShorterOrEqual,
} from '@utils/formValidators';

const ProposalUpdateReasonModal = ({
  isOpen,
  onClose,
  onSubmit = () => {},
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2" mb="16px" textAlign="center">
        One final thing...
      </Typography>
      <Typography mb="32px" textAlign="center">
        Please let us know the reason for these updates
      </Typography>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Field
                component={Textarea}
                name="archivingReason"
                rows={5}
                validate={composeValidators(
                  required,
                  mustBeShorterOrEqual(250),
                )}
                placeholder="Add your reason here"
                maxW="488px"
                mb="40px"
                mx="auto"
              />
              <Stack spacing={3} mt="40px">
                <Button isLoading={submitting} type="submit">
                  Submit proposal updates
                </Button>
                <Button
                  variant="outline"
                  onClick={onClose}
                  isDisabled={submitting}
                >
                  Cancel
                </Button>
              </Stack>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default ProposalUpdateReasonModal;
