import { Stack } from '@chakra-ui/react';
import { MAIN_NAMES_QA } from '@app/constants';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import { APP_ROUTES } from '@routes';
import Typography from '@styles/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const AddNewDeliveryPartnerModal = ({ isOpen, onClose, project }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2" mb="24px" textAlign="center">
        Please select an option
      </Typography>

      <Typography mb="24px" textAlign="center" fontSize="15px">
        If you’d like to recieve proposals for new roles, then you’ll need to
        create a new brief. This brief can be merged with this project once
        roles and rates have been agreed.
      </Typography>

      <Typography mb="40px" textAlign="center" fontSize="15px">
        If you have made an agreement with a delivery partner outside the
        platform then you’ll be able to add these roles directly.
      </Typography>

      <Stack spacing={3}>
        <Button
          h="44px"
          as={RouterLink}
          to={APP_ROUTES.briefsCreatePlugAndPlay}
          data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--createTABriefButton`}
        >
          Create a new team augmentation brief
        </Button>
        <Button
          h="44px"
          as={RouterLink}
          variant="outline"
          to={APP_ROUTES.projectTabWithSlugs(
            project?.client?.slug || 'deazy',
            project?.slug,
            'new-delivery-partner',
          )}
          data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--proceedDPButton`}
        >
          I have agreed roles to add
        </Button>
        <Button variant="ghost" onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};

export default AddNewDeliveryPartnerModal;
