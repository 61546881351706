import { Flex, Stack } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { required } from '@utils/formValidators';
import { upperCase } from 'lodash-es';
import React from 'react';
import { Field, Form } from 'react-final-form';
import MaskedTextInput from './forms/MaskedTextInput';

const ChangeRateModal = ({
  initialValues = {},
  initialValues: { sourceCurrency = 'GBP', destCurrency = 'USD' } = {},
  onSubmit,
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2" mb="32px" textAlign="center">
        Change Rate
      </Typography>
      <Typography textAlign="center" mb="32px">
        You can set new rate for Proposal
      </Typography>
      <Flex w="100%" mx="auto" flexDirection="column" alignItems="center">
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Flex justifyContent="center">
                  <Flex
                    mb="48px"
                    alignItems="center"
                    p="15px"
                    borderRadius="8px"
                    bg="dark.light"
                    mr="24px"
                  >
                    <Typography mt="5px" variant="text2">
                      {upperCase(destCurrency)}
                    </Typography>
                    <Icon
                      mx="20px"
                      name="arrowUp"
                      fontSize={22}
                      transform="rotate(90deg)"
                    />
                    <Typography mt="5px" fontSize={14}>
                      {upperCase(sourceCurrency)}
                    </Typography>
                  </Flex>
                  <Field
                    name="currencyRate"
                    validate={required}
                    isAllowed={({ floatValue }) =>
                      (floatValue <= 10 && floatValue > 0) || !floatValue
                    }
                    component={MaskedTextInput}
                    placeholder="0.00"
                    containerProps={{ maxW: '88px' }}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Flex>
                <Stack spacing={3}>
                  <Button isLoading={submitting} type="submit" width="full">
                    Submit
                  </Button>
                  <Button
                    variant="outline"
                    onClick={onClose}
                    isDisabled={submitting}
                    width="full"
                  >
                    Cancel
                  </Button>
                </Stack>
              </form>
            );
          }}
        />
      </Flex>
    </Modal>
  );
};

export default ChangeRateModal;
