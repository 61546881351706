import React from 'react';
import StatusDropdown from '@components/StatusDropdown';
import { inject, observer } from 'mobx-react';

const greenProps = {
  borderColor: 'green.500',
  bg: 'green.light',
  color: 'green.500',
};

const amberProps = {
  bg: 'yellow.lightSecondary',
  color: 'yellow.solid',
  borderColor: 'yellow.solid',
};

const redProps = {
  color: 'red.500',
  borderColor: 'red.500',
  bg: 'red.light',
};

const ProjectStatusBadge = ({
  project,
  isProjectsListRoute,
  projectsStore: { setProjectRiskProfile },
  milestonesStore: { updatedMilestone, overrideProjectStatus },
  isRiskDropdown = false,
  usersStore: { isAdminOrDL },
  label,
}) => {
  const [riskLevel, setRiskLevel] = React.useState(project.riskLevel);

  const currentStatus = React.useMemo(() => {
    if (isRiskDropdown) {
      return riskLevel;
    }
    return (
      updatedMilestone.report?.project.overriddenProjectStatus ||
      updatedMilestone.report?.project.calculatedProjectStatus ||
      project.overriddenProjectStatus ||
      project.calculatedProjectStatus
    );
  }, [updatedMilestone, project, isRiskDropdown]);

  const options = React.useMemo(() => {
    if (isRiskDropdown) {
      return [
        { label: 'Standard', value: 'Standard', ...greenProps },
        { label: 'High', value: 'High', ...redProps },
      ];
    }
    return [
      {
        label: 'Green',
        value: 'Green',
        ...greenProps,
      },
      {
        label: 'Amber',
        value: 'Amber',
        ...amberProps,
      },
      {
        label: 'Red',
        value: 'Red',
        ...redProps,
      },
    ];
  }, [isRiskDropdown]);

  const onChange = React.useMemo(() => {
    if (isRiskDropdown) {
      return risk => {
        const oldRiskLevel = riskLevel;
        setRiskLevel(risk);
        setProjectRiskProfile(project.id, risk, () => {
          setRiskLevel(oldRiskLevel);
        });
      };
    }
    return status => overrideProjectStatus(project.id, { status });
  }, []);

  if (!currentStatus && !isRiskDropdown) {
    return null;
  }

  return (
    <StatusDropdown
      options={options}
      label={label}
      buttonProps={{
        label: isRiskDropdown ? 'ADD RISK' : undefined,
        zIndex: isRiskDropdown && 1,
      }}
      onChange={onChange}
      currentValue={currentStatus}
      isReadOnly={!isAdminOrDL || (isProjectsListRoute && !isRiskDropdown)}
      withRevertLabels
    />
  );
};

export default inject(
  'usersStore',
  'projectsStore',
  'milestonesStore',
)(observer(ProjectStatusBadge));
