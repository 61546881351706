import { Flex, useDisclosure } from '@chakra-ui/react';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';
import StatusMessageBox from '@pages/Brief/StatusMessageBox';
import ProposalStatusDropdown from '@pages/Brief/Proposal/ProposalStatusDropdown';
import QueryProposalModal from './QueryProposalModal';

export const CheckButton = props => (
  <Button
    mr="16px"
    size="lg"
    leftIcon={{ name: 'check', fontSize: 20 }}
    {...props}
  />
);

const RightSideActions = ({
  brief,
  showBriefActions = false,
  showProposalActions = false,
  showClientProposalActions = false,
  disableClientProposalActions = false,
  proposal,
  acceptBrief,
  declineBrief,
  onAcceptClientProposal,
  onDeclineClientProposal,
  mainNameQA,
  isSmallerScreen,
}) => {
  const {
    isOpen: isQueryModalOpen,
    onOpen: onQueryModalOpen,
    onClose: onQueryModalClose,
  } = useDisclosure();

  return (
    <>
      <Flex
        justifyContent="flex-end"
        w={['100%', '100%', '100%', 'auto']}
        flexDir={['column', 'column', 'column', 'row']}
        display={isSmallerScreen && !showProposalActions && 'none'}
      >
        {showBriefActions && (
          <>
            <CheckButton
              onClick={acceptBrief}
              isLoading={brief.isAccepting}
              isDisabled={brief?.supplierProposal?.isAccepted || brief.isClosed}
              w={['100%', '100%', '100%', 'auto']}
              mb={['12px', '12px', '12px', 0]}
            >
              Interested
            </CheckButton>
            <Button
              variant="warning"
              size="lg"
              onClick={declineBrief}
              leftIcon={{ name: 'cross', fontSize: 20 }}
              isDisabled={brief?.supplierProposal?.isDeclined || brief.isClosed}
              mb={['12px', '12px', '12px', 0]}
              w={['100%', '100%', '100%', 'auto']}
            >
              Not interested
            </Button>
          </>
        )}
        {showClientProposalActions && (
          <>
            <CheckButton
              onClick={onAcceptClientProposal}
              isDisabled={
                proposal.clientProposalStatus === 'Accepted' ||
                disableClientProposalActions
              }
              mb={['12px', '12px', '12px', 0]}
              w={['100%', '100%', '100%', 'auto']}
              data-test-id={
                mainNameQA && `${mainNameQA}--proceedWithProposalButton`
              }
            >
              Proceed with proposal
            </CheckButton>
            {!brief.isPlugAndPlay && (
              <Button
                variant="outline"
                leftIcon={{ name: 'questionMark', fontSize: 20 }}
                onClick={onQueryModalOpen}
                mr="16px"
                size="lg"
                isDisabled={disableClientProposalActions}
                mb={['12px', '12px', '12px', 0]}
                w={['100%', '100%', '100%', 'auto']}
                data-test-id={
                  mainNameQA && `${mainNameQA}--queryProposalButton`
                }
              >
                Query proposal
              </Button>
            )}
            <Button
              variant="warning"
              leftIcon={{ name: 'cross', fontSize: 20 }}
              onClick={onDeclineClientProposal}
              isDisabled={
                proposal.clientProposalStatus === 'Declined' ||
                disableClientProposalActions
              }
              size="lg"
              data-test-id={
                mainNameQA && `${mainNameQA}--declineProposalButton`
              }
            >
              Decline
            </Button>
          </>
        )}
      </Flex>
      <QueryProposalModal
        isOpen={isQueryModalOpen}
        onClose={onQueryModalClose}
      />
    </>
  );
};

const BriefActions = ({
  brief,
  proposal,
  showBriefActions = false,
  showProposalActions = false,
  showClientProposalActions = false,
  disableClientProposalActions = false,
  createProposal,
  rejectProposal,
  acceptBrief,
  declineBrief,
  onAcceptClientProposal,
  onDeclineClientProposal,
  onDownload,
  isDisabledDownload = false,
  isDownloadLoading,
  canDownloadPDF = false,
  infoBoxMessage,
  isPendingRequest,
  mainNameQA,
  isSmallerScreen,
  isTeamAdmin,
}) => {
  return (
    <>
      {canDownloadPDF ? (
        <Flex
          bg="#fff"
          justifyContent="space-between"
          p={isSmallerScreen ? '12px 0' : '24px 29px'}
          flexDirection={isSmallerScreen && 'column'}
        >
          <Flex
            alignItems={isSmallerScreen && 'center'}
            mb={isSmallerScreen && '24px'}
          >
            <Typography variant="h2" mr="16px">
              Proposal
            </Typography>
            {!brief.isPlugAndPlay && (
              <Button
                isLoading={isDownloadLoading}
                onClick={onDownload}
                isDisabled={isDisabledDownload}
                width="40px"
                bg="primary.100"
                size="lg"
                iconButton={{
                  name: 'download',
                  fontSize: 22,
                  color: 'black',
                }}
                data-test-id={mainNameQA && `${mainNameQA}--downloadButton`}
              />
            )}
            <Flex alignItems="center" ml={isSmallerScreen ? 'auto' : '32px'}>
              <Typography fontSize="14px" mr="16px">
                Status
              </Typography>
              <ProposalStatusDropdown
                isDisabled
                buttonProps={{ mt: '3px' }}
                mainNameQA={mainNameQA}
              />
            </Flex>
          </Flex>
          <RightSideActions
            brief={brief}
            proposal={proposal}
            showBriefActions={showBriefActions}
            showProposalActions={showProposalActions}
            showClientProposalActions={showClientProposalActions}
            disableClientProposalActions={disableClientProposalActions}
            createProposal={createProposal}
            rejectProposal={rejectProposal}
            acceptBrief={acceptBrief}
            declineBrief={declineBrief}
            onAcceptClientProposal={onAcceptClientProposal}
            onDeclineClientProposal={onDeclineClientProposal}
            mainNameQA={mainNameQA}
          />
        </Flex>
      ) : (
        <Flex justifyContent="space-between" mb={[0, 0, 0, '24px']}>
          {infoBoxMessage && !isSmallerScreen && (
            <StatusMessageBox
              message={infoBoxMessage}
              mr="12px"
              mb={['12px', '12px', '12px', 0]}
              maxW="660px"
            />
          )}
          {!isTeamAdmin && (
            <RightSideActions
              brief={brief}
              isPendingRequest={isPendingRequest}
              proposal={proposal}
              showBriefActions={showBriefActions}
              showProposalActions={showProposalActions}
              showClientProposalActions={showClientProposalActions}
              disableClientProposalActions={disableClientProposalActions}
              createProposal={createProposal}
              rejectProposal={rejectProposal}
              acceptBrief={acceptBrief}
              declineBrief={declineBrief}
              onAcceptClientProposal={onAcceptClientProposal}
              onDeclineClientProposal={onDeclineClientProposal}
              mainNameQA={mainNameQA}
              isSmallerScreen={isSmallerScreen}
            />
          )}
        </Flex>
      )}
    </>
  );
};

export default BriefActions;
