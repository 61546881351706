/* eslint-disable max-len */
import { Flex, Image } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';

const Typo500 = (
  <svg
    width="282"
    height="121"
    viewBox="0 0 282 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M80.206 24.3763H34.122L33.63 42.2523C36.1447 41.3776 39.5887 40.9403 43.962 40.9403C49.866 40.9403 55.278 41.8149 60.198 43.5643C65.118 45.3136 69.3274 47.8829 72.826 51.2723C76.434 54.5522 79.222 58.5976 81.19 63.4083C83.158 68.1096 84.142 73.4123 84.142 79.3163C84.142 85.8763 82.994 91.7256 80.698 96.8643C78.5114 102.003 75.45 106.376 71.514 109.984C67.578 113.483 62.8767 116.162 57.41 118.02C52.0527 119.879 46.2034 120.808 39.862 120.808C29.8033 120.808 21.3847 118.348 14.606 113.428C7.82735 108.508 3.18068 101.074 0.666016 91.1243L26.414 85.2203C27.07 89.3749 28.8194 92.7643 31.662 95.3883C34.5047 97.9029 38.058 99.1602 42.322 99.1602C47.57 99.1602 51.7247 97.5202 54.786 94.2402C57.9567 90.9603 59.542 86.8056 59.542 81.7763C59.542 77.9496 58.722 74.8336 57.082 72.4283C55.5513 69.9136 53.474 67.9456 50.85 66.5243C48.3354 64.9936 45.4927 63.9549 42.322 63.4083C39.1513 62.8616 35.9807 62.5883 32.81 62.5883C28.7647 62.5883 24.6647 62.9709 20.51 63.7363C16.4647 64.5016 12.5287 65.5403 8.70202 66.8523L10.014 2.72826H80.206V24.3763Z"
      fill="#F7CDCD"
    />
    <path
      d="M97.8847 60.7843C97.8847 53.8963 98.486 46.8989 99.6887 39.7923C101.001 32.6856 103.242 26.2349 106.413 20.4403C109.693 14.6456 114.121 9.94425 119.697 6.33625C125.273 2.61892 132.379 0.760254 141.017 0.760254C149.654 0.760254 156.761 2.61892 162.337 6.33625C167.913 9.94425 172.286 14.6456 175.457 20.4403C178.737 26.2349 180.978 32.6856 182.181 39.7923C183.493 46.8989 184.149 53.8963 184.149 60.7843C184.149 67.6723 183.493 74.6696 182.181 81.7763C180.978 88.8829 178.737 95.3336 175.457 101.128C172.286 106.923 167.913 111.679 162.337 115.396C156.761 119.004 149.654 120.808 141.017 120.808C132.379 120.808 125.273 119.004 119.697 115.396C114.121 111.679 109.693 106.923 106.413 101.128C103.242 95.3336 101.001 88.8829 99.6887 81.7763C98.486 74.6696 97.8847 67.6723 97.8847 60.7843ZM122.485 60.7843C122.485 63.8456 122.594 67.5083 122.813 71.7723C123.141 76.0363 123.906 80.1363 125.109 84.0723C126.311 88.0082 128.115 91.3429 130.521 94.0762C133.035 96.8096 136.534 98.1763 141.017 98.1763C145.39 98.1763 148.834 96.8096 151.349 94.0762C153.863 91.3429 155.722 88.0082 156.925 84.0723C158.127 80.1363 158.838 76.0363 159.057 71.7723C159.385 67.5083 159.549 63.8456 159.549 60.7843C159.549 57.7229 159.385 54.0603 159.057 49.7963C158.838 45.5323 158.127 41.4323 156.925 37.4963C155.722 33.5603 153.863 30.2256 151.349 27.4923C148.834 24.7589 145.39 23.3923 141.017 23.3923C136.534 23.3923 133.035 24.7589 130.521 27.4923C128.115 30.2256 126.311 33.5603 125.109 37.4963C123.906 41.4323 123.141 45.5323 122.813 49.7963C122.594 54.0603 122.485 57.7229 122.485 60.7843Z"
      fill="#F7CDCD"
    />
    <path
      d="M194.939 60.7843C194.939 53.8963 195.541 46.8989 196.743 39.7923C198.055 32.6856 200.297 26.2349 203.467 20.4403C206.747 14.6456 211.175 9.94425 216.751 6.33625C222.327 2.61892 229.434 0.760254 238.071 0.760254C246.709 0.760254 253.815 2.61892 259.391 6.33625C264.967 9.94425 269.341 14.6456 272.511 20.4403C275.791 26.2349 278.033 32.6856 279.235 39.7923C280.547 46.8989 281.203 53.8963 281.203 60.7843C281.203 67.6723 280.547 74.6696 279.235 81.7763C278.033 88.8829 275.791 95.3336 272.511 101.128C269.341 106.923 264.967 111.679 259.391 115.396C253.815 119.004 246.709 120.808 238.071 120.808C229.434 120.808 222.327 119.004 216.751 115.396C211.175 111.679 206.747 106.923 203.467 101.128C200.297 95.3336 198.055 88.8829 196.743 81.7763C195.541 74.6696 194.939 67.6723 194.939 60.7843ZM219.539 60.7843C219.539 63.8456 219.649 67.5083 219.867 71.7723C220.195 76.0363 220.961 80.1363 222.163 84.0723C223.366 88.0082 225.17 91.3429 227.575 94.0762C230.09 96.8096 233.589 98.1763 238.071 98.1763C242.445 98.1763 245.889 96.8096 248.403 94.0762C250.918 91.3429 252.777 88.0082 253.979 84.0723C255.182 80.1363 255.893 76.0363 256.111 71.7723C256.439 67.5083 256.603 63.8456 256.603 60.7843C256.603 57.7229 256.439 54.0603 256.111 49.7963C255.893 45.5323 255.182 41.4323 253.979 37.4963C252.777 33.5603 250.918 30.2256 248.403 27.4923C245.889 24.7589 242.445 23.3923 238.071 23.3923C233.589 23.3923 230.09 24.7589 227.575 27.4923C225.17 30.2256 223.366 33.5603 222.163 37.4963C220.961 41.4323 220.195 45.5323 219.867 49.7963C219.649 54.0603 219.539 57.7229 219.539 60.7843Z"
      fill="#F7CDCD"
    />
  </svg>
);
const Squares = props => (
  <Image
    h="auto"
    src="/images/squares.svg"
    alt="..."
    position="fixed"
    ignoreFallback
    w={['120px', '250px', '320px', '450px', '512px']}
    {...props}
  />
);

const Page500 = () => {
  return (
    <Flex bg="#fff" w="100vw" minH="100vh">
      <Squares left="0px" bottom="0px" />
      <Squares transform="rotate(180deg)" top="0px" right="0px" />
      <Flex
        w="50%"
        minH="100vh"
        p={['30px', '40px', '50px', '60px', '117px 20px 48px 128px']}
        flexDir="column"
        zIndex={10}
      >
        <Image
          maxW="312px"
          ml="16px"
          w="100%"
          h="auto"
          src="/images/deazy-wordmark-black.svg"
          alt="DEAZY"
          mb="120px"
          ignoreFallback
        />
        <Flex
          flexDir="column"
          maxW="474px"
          w="100%"
          alignItems="center"
          alignSelf="center"
        >
          {Typo500}
          <Typography variant="h2" mb="24px" pt="32px">
            Internal server error!
          </Typography>
        </Flex>
      </Flex>
      <Flex w="50%" justifyContent="center" zIndex={10}>
        <Image
          maxW="820px"
          w="100%"
          h="auto"
          src="/images/500icon.svg"
          alt="Looking..."
          ignoreFallback
        />
      </Flex>
    </Flex>
  );
};

export default Page500;
