/* eslint-disable */
import React from 'react';
import { map, times, filter, some, reduce, isEmpty } from 'lodash-es';
import { Box } from '@chakra-ui/react';
// import {
//   ChannelListProvider,
//   useChannelListContext,
// } from '@sendbird/uikit-react/ChannelList/context';
import styled from '@emotion/styled';
import { Facebook as ProjectLoader } from 'react-content-loader';
import BriefCard from './BriefCard';

const EnhancedBriefList = ({
  briefs,
  isAdminOrDL,
  isTeamAdmin,
  refetch,
  mainNameQA,
  showBriefChat,
  toggleSubSidebar,
}) => {
  // const { allChannels } = useChannelListContext();

  return map(briefs, brief => {
    // const filteredChannels = filter(allChannels, ch => {
    //   return some(brief.sendbridChannels, item => {
    //     return ch.url === item;
    //   });
    // });

    const totalUnreadMessages = 0;
    // reduce(
    //   filteredChannels,
    //   (result, chanelItem) => result + chanelItem.unreadMessageCount,
    //   0,
    // );

    return (
      <BriefCard
        key={`brief-card-${brief.id}-${brief.name}`}
        brief={brief}
        unreadMessagesCount={totalUnreadMessages}
        showChatIcon={showBriefChat && !isEmpty(brief.sendbridChannels)}
        isAdminOrDL={isAdminOrDL}
        isTeamAdmin={isTeamAdmin}
        refetch={refetch}
        toggleSubSidebar={toggleSubSidebar}
        mainNameQA={mainNameQA}
      />
    );
  });
};

const StyledListWrapper = styled(Box)`
  .sendbird-channel-list {
    background-color: transparent;
  }
`;

const BriefsList = ({
  briefs,
  isLoading,
  isAdminOrDL,
  isTeamAdmin,
  refetch,
  mainNameQA,
  toggleSubSidebar,
  showBriefChat,
}) => {
  if (isLoading) {
    return map(times(8), (t, idx) => (
      <Box mb="16px" w="100%" key={`loader-${idx}`} maxW="720px">
        <ProjectLoader height="240px" width="100%" />
      </Box>
    ));
  }
  return (
    <StyledListWrapper>
      {/* <ChannelListProvider
        className="briefChat"
        queries={{
          channelListQuery: {
            includeEmpty: false,
            limit: 100,
            unreadChannelFilter: 'unread_message',
          },
        }}
      > */}
      <EnhancedBriefList
        briefs={briefs}
        isAdminOrDL={isAdminOrDL}
        isTeamAdmin={isTeamAdmin}
        refetch={refetch}
        mainNameQA={mainNameQA}
        toggleSubSidebar={toggleSubSidebar}
        showBriefChat={showBriefChat}
      />
      {/* </ChannelListProvider> */}
    </StyledListWrapper>
  );
};

export default BriefsList;
