import React from 'react';
import { Box } from '@chakra-ui/react';
import { inject, observer } from 'mobx-react';
import { APP_ROUTES } from '@routes';
import BackButton from '@components/BackButton';
import TeamCard from '@pages/Teams/TeamCard';
import TeamCardContent from '@pages/Teams/TeamCardContent';
import UsersDetails from './UsersDetails';
import SuppliersBlacklist from './SuppliersBlacklist';
import AddDeazyLeadToClient from './AddDeazyLeadToClient';

const ClientDetails = ({
  client,
  deleteUserFromClient,
  addUserToClient,
  fetchClient,
  isAdminOrDL,
  usersStore: { profile },
  appStore: {
    featureFlags: { 'uo-frontegg-auth': uoFronteggAuth },
  },
}) => {
  const handleRemoveUser = (userId, clientId) => {
    return deleteUserFromClient({
      userId,
      clientId,
      onSuccess: () => fetchClient(clientId),
    });
  };

  const handleAddUser = (clientId, userId) => {
    return addUserToClient({
      clientId,
      memberId: userId,
      onSuccess: () => fetchClient(clientId),
    });
  };

  const allClientsListButton = !uoFronteggAuth && isAdminOrDL;

  return (
    <Box w="100%" maxW="1200px" mx="auto">
      {allClientsListButton && (
        <BackButton to={APP_ROUTES.teams.clients}>
          Go to all client list
        </BackButton>
      )}
      <Box w="100%" maxW="1200px" mx="auto">
        <TeamCard isStatic>
          <TeamCardContent team={client} />
        </TeamCard>
      </Box>
      <UsersDetails
        handleRemoveUser={handleRemoveUser}
        handleAddUser={handleAddUser}
        users={client.users}
        teamId={client.id}
        isAdminOrDL={isAdminOrDL}
        profile={profile}
      />
      <SuppliersBlacklist />
      <AddDeazyLeadToClient />
    </Box>
  );
};

export default inject('appStore', 'usersStore')(observer(ClientDetails));
