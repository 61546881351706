import { API } from '@app/api';
import { Box, Stack } from '@chakra-ui/react';
import AsyncAutocomplete from '@components/forms/_common/AsyncAutocomplete';
import Button from '@styles/Button';
import { required } from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';

const AddUserToTeamForm = ({ onClose, onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting }) => {
      return (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Box>
            <Field
              name="user"
              label="Choose a user"
              placeholder="Choose a user"
              component={AsyncAutocomplete}
              styleProps={{ mb: '32px' }}
              getOptionsFn={API.searchUsers}
              customOptionMap={option => ({
                value: option,
                label: option.username,
              })}
              validate={required}
            />
          </Box>
          <Stack mt="40px" spacing={3}>
            <Button isLoading={submitting} type="submit">
              Add Member
            </Button>
            <Button variant="outline" isDisabled={submitting} onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </form>
      );
    }}
  />
);

export default AddUserToTeamForm;
