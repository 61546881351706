import { Box, Flex } from '@chakra-ui/react';
import Textarea from '@components/forms/_common/Textarea';
import TextInput from '@components/forms/_common/TextInput';
import Button from '@styles/Button';
import { required } from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';

const labelProps = {
  lineHeight: '15px',
  fontSize: '13px',
  mb: '8px',
};

const QuestionForm = ({ initialValues, onClose, onSubmit, mainNameQA }) => {
  return (
    <Box
      border="1px solid"
      borderColor="dark.mid"
      bg="#fff"
      borderRadius="8px"
      mb={8}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Box pt="32px" pb="24px" px="24px">
              <Flex>
                <Field
                  name="title"
                  data-test-id={`${mainNameQA}--title`}
                  placeholder="Give this question a name"
                  component={TextInput}
                  validate={required}
                  label="Question Summary"
                  labelProps={labelProps}
                  containerProps={{ flex: 1, pr: '20px' }}
                />
              </Flex>
              <Field
                name="question"
                data-test-id={`${mainNameQA}--question`}
                label="Your Question"
                placeholder="Write your question here"
                component={Textarea}
                validate={required}
                mt="24px"
                labelProps={labelProps}
              />
              <Flex mt="24px">
                <Button
                  mr="12px"
                  isLoading={submitting}
                  type="submit"
                  fontSize="15px"
                >
                  Submit question
                </Button>
                <Button
                  variant="outline"
                  onClick={onClose}
                  isDisabled={submitting}
                  fontSize="15px"
                >
                  Cancel
                </Button>
              </Flex>
            </Box>
          </form>
        )}
      />
    </Box>
  );
};

export default QuestionForm;
