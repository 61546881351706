import { Box, Flex } from '@chakra-ui/react';
import Textarea from '@components/forms/_common/Textarea';
import Button from '@styles/Button';
import { required } from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';

const QuestionReplyForm = ({
  initialValues = {},
  onClose,
  onSubmit,
  mainNameQA,
}) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Box pb="40px" px="32px">
            <Field
              name="text"
              placeholder="Write your response here"
              component={Textarea}
              validate={required}
              label="Your Answer"
              mt="8px"
              data-test-id={`${mainNameQA}--answer`}
            />
            <Flex mt="24px">
              <Button
                mr="12px"
                fontSize="15px"
                isLoading={submitting}
                type="submit"
                data-test-id={`${mainNameQA}--submitAnswer`}
              >
                Submit Answer
              </Button>
              <Button
                fontSize="15px"
                variant="outline"
                onClick={onClose}
                isDisabled={submitting}
                data-test-id={`${mainNameQA}--cancelAnswer`}
              >
                Cancel
              </Button>
            </Flex>
          </Box>
        </form>
      )}
    />
  );
};

export default QuestionReplyForm;
