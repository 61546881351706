import {
  Box,
  Button,
  Flex,
  Icon,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { round, upperCase } from 'lodash-es';
import React from 'react';
import ChangeRateModal from './ChangeRateModal';
import ResetRateModal from './ResetRateModal';

const ExchangeRateBox = ({
  currencyRate: currRate,
  globalRate,
  sourceCurrency = 'GBP',
  isSettingRate,
  differsFromGlobalRate = false,
  destCurrency,
  onExchangeRateChange,
  isReadOnly,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isResetRateModalOpen,
    onClose: onResetRateModalClose,
    onOpen: onResetRateModalOpen,
  } = useDisclosure();

  const currencyRate = (round(currRate || globalRate, 2) || 0).toFixed(2);

  if (!currencyRate && !destCurrency) {
    return null;
  }

  return (
    <>
      <Box py="15px" pl="15px" borderRadius="8px" bg="dark.light">
        {/* REVERT AFTER DEMO */}
        <Typography variant="text2">1 {upperCase(destCurrency)}</Typography>
        <Flex alignItems="center" mt="8px">
          <Typography
            variant="title"
            mr="50px"
            color={differsFromGlobalRate ? 'red.solid' : '#000'}
          >
            {`${currencyRate} ${upperCase(sourceCurrency)}`}
            {differsFromGlobalRate && (
              <Tooltip
                zIndex={33000}
                p="12px"
                borderRadius="8px"
                lineHeight="normal"
                label="The exchange rate differs from current platform default exchange rate."
                hasArrow
                fontFamily="WS"
                bg="dark.solid"
                px="24px"
                py="12px"
                placement="bottom"
              >
                <Box as="span" ml="10px">
                  <Icon name="question" color="#000" mt="-4px" />
                </Box>
              </Tooltip>
            )}
          </Typography>
          <Button
            variant="ghost"
            color="blue.solid"
            _hover={{ bg: 'transparent', color: 'blue.mid' }}
            _active={{ bg: 'transparent' }}
            h="auto"
            mr="32px"
            px="0px"
            onClick={onOpen}
            isDisabled={isReadOnly}
          >
            Change rate
          </Button>
          {differsFromGlobalRate && (
            <Button
              variant="ghost"
              color="red.solid"
              _hover={{ bg: 'transparent', color: 'red.mid' }}
              _active={{ bg: 'transparent' }}
              h="auto"
              mr="32px"
              px="0px"
              onClick={onResetRateModalOpen}
              isDisabled={isReadOnly}
            >
              Reset rate
            </Button>
          )}
        </Flex>
      </Box>
      <ResetRateModal
        isOpen={isResetRateModalOpen}
        onResetRate={async () => {
          await onExchangeRateChange({ currencyRate: round(globalRate, 2) });
          onResetRateModalClose();
        }}
        isReseting={isSettingRate}
        onClose={onResetRateModalClose}
      />
      <ChangeRateModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={async values => {
          await onExchangeRateChange(values);
          onClose();
        }}
        initialValues={{
          currencyRate,
          sourceCurrency,
          destCurrency,
        }}
      />
    </>
  );
};

export default ExchangeRateBox;
