import { Box, Button, Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { formatCurrency } from '@utils';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form } from 'react-final-form';
import CRCard from './CRCard';

const ChangeRequestActionForm = ({
  tokenAction,
  onSubmit,
  currency,
  data,
  calculatedClientRate,
}) => {
  const { amount, clientRate: clientRateFromCr, clientDisabled } = data;

  const clientRate = clientRateFromCr || calculatedClientRate;

  const clientTotal = formatCurrency(amount * clientRate || 0, currency);

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <CRCard cr={data} clientRate={clientRate} />
            <Box mt="24px">
              {!clientDisabled && clientRate && amount && (
                <Typography variant="h4">{`Total client value: ${clientTotal}`}</Typography>
              )}
            </Box>
            <Flex mt="56px">
              <Button
                bg="blue.solid"
                fontWeight="400"
                color="#fff"
                borderRadius="30px"
                h="56px"
                minW="260px"
                fontSize={18}
                mr="24px"
                isLoading={submitting}
                type="submit"
              >
                {tokenAction}
              </Button>
            </Flex>
          </form>
        );
      }}
    />
  );
};

export default inject('crsStore')(observer(ChangeRequestActionForm));
