import React from 'react';
import ReadOnlyTable from '@components/ReadOnlyTable';

const COLUMNS = [
  {
    name: 'Description',
    w: '40%',
    ellipsis: false,
  },
  { name: 'Quantity', w: '15%' },
  { name: 'Unit price', w: '15%' },
  {
    name: 'Vat',
    w: '15%',
    ellipsis: false,
  },
  { name: 'Amount', w: '15%' },
];

const InvoiceItems = ({ invoice, mainNameQA }) => {
  return (
    <>
      <ReadOnlyTable
        columns={COLUMNS}
        tableData={invoice.tableData}
        mt="40px"
        qaTag={mainNameQA}
      />
    </>
  );
};

export default InvoiceItems;
