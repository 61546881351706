import { Flex } from '@chakra-ui/react';
import React from 'react';

const ZoneContainer = ({ children, ...props }) => (
  <Flex
    align="center"
    direction="column"
    justify="center"
    w="100%"
    bg="#fff"
    borderColor="#797979"
    borderStyle="dashed"
    borderRadius="8px"
    borderWidth="2px"
    {...props}
  >
    {children}
  </Flex>
);

export default ZoneContainer;
