import { API } from '@app/api';
import { SENIORITY_OPTIONS } from '@app/constants';
import { Box, Flex, Stack } from '@chakra-ui/react';
import ActionButton from '@components/AllocationTable/ActionButton';
import DatePicker from '@components/forms/DatePicker';
import ExtendableRichText from '@components/forms/ExtendableRichText';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import MultiSelect from '@components/forms/MultiSelect';
import Select from '@components/forms/_common/Select';
import TextInput from '@components/forms/_common/TextInput';
import Popover from '@components/Popover';
import useMediaQuery from '@hooks/useMediaQuery';
import useQuery from '@hooks/useQuery';
import { breakpoint } from '@styles/breakpoints';
import Button from '@styles/Button';
import { INPUT_VARIANTS } from '@styles/theme';
import Typography from '@styles/Typography';
import { sleep } from '@utils';
import {
  forEach,
  isEmpty,
  isNil,
  last,
  isEqual,
  map,
  omit,
  reject,
  pick,
} from 'lodash-es';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useLayoutEffect } from 'react';
import { Field, FormSpy } from 'react-final-form';

const disabledDaysFn = proposalClosingDate => day =>
  moment.utc(day).isBefore(moment.utc().startOf('day')) ||
  moment.utc(day).isBefore(moment.utc(proposalClosingDate).startOf('day'));

const ResourceHead = () => (
  <Flex w="100%" mb="12px" mt="24px" pl="40px">
    <Typography variant="caption" w="40%">
      Name
    </Typography>
    <Typography variant="caption" w="25%">
      Seniority
    </Typography>
    <Typography variant="caption" w="20%">
      Start date
    </Typography>
    <Typography variant="caption" w="15%">
      Months
    </Typography>
  </Flex>
);

const rowStyles = {
  w: '100%',
  h: '72px',
  bg: '#fff',
  position: 'relative',
  borderTopRightRadius: '8px',
  borderRight: '1px solid #D9DADA',
};

const cellStyles = isSmallerScreen =>
  !isSmallerScreen
    ? {
        pl: '40px',
        w: '100%',
        borderBottom: '1px solid #D9DADA',
        borderTop: '1px solid #D9DADA',
        borderRight: 'none',
        borderRadius: '8px',
        borderBottomRightRadius: '0px',
      }
    : { flexDir: 'column', w: '100%' };

const fieldWrapperStyles = (isSmallerScreen, styles) =>
  !isSmallerScreen
    ? {
        alignSelf: 'center',
        h: '70px',
        borderRight: '1px solid',
        borderColor: 'dark.mid',
        ...styles,
      }
    : {
        mb: '12px',
      };

const ResourceRow = ({
  allocationIdx,
  allocationId,
  canBeDeleted,
  deleteRow,
  addRow,
  disallowRowChange,
  formApi,
  mainNameQA,
  proposalClosingDate,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    data: technologiesFromAPI,
    isLoading: isLoadingTechnologies,
  } = useQuery(value => API.getBriefTechStack(1, value), {});
  const isSmallerScreen = useMediaQuery(breakpoint.lg);
  const technologiesOptions = React.useMemo(() => {
    return map(technologiesFromAPI, t => ({ label: t, value: t }));
  }, [technologiesFromAPI]);
  const triggerRef = React.useRef(null);
  const bottom = -(
    document.body.offsetHeight -
    triggerRef.current?.getBoundingClientRect().bottom -
    30
  );

  useLayoutEffect(() => {
    if (isSmallerScreen && isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isSmallerScreen, isOpen]);

  return (
    <>
      {isSmallerScreen && (
        <Flex
          bg="primary.200"
          borderRadius="8px 8px 0px 0px"
          padding="8px 12px"
          justify="space-between"
          align="center"
          minH="56px"
        >
          <Typography variant="h4">Role</Typography>
          {canBeDeleted && (
            <Popover
              isOpen={isOpen}
              withOverlay
              variant="responsive"
              contentStyles={{
                m: 0,
                borderRadius: 0,
                position: 'fixed',
                bottom,
                width: '100%',
              }}
              trigger={
                <Button
                  bg="primary.100"
                  width="40px"
                  ref={triggerRef}
                  iconButton={{
                    name: 'trashNew',
                    fontSize: '18px',
                    color: 'black',
                  }}
                  onClick={() => setIsOpen(true)}
                  isDisabled={!canBeDeleted}
                />
              }
            >
              <FormSpy subscription={{ values: true }}>
                {({ values, form }) => {
                  return (
                    <Box p="16px 8px 48px">
                      <Typography variant="h1" mb="16px">
                        Are you sure?
                      </Typography>
                      <Typography>
                        Once you delete this role you will not be able to
                        retrive it.
                      </Typography>
                      <Stack spacing={4} mt="24px">
                        <Button
                          onClick={async () => {
                            await setIsOpen(false);
                            await deleteRow(allocationId)(values, form);
                          }}
                        >
                          Yes, delete this role
                        </Button>
                        <Button
                          variant="outline"
                          onClick={() => setIsOpen(false)}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </Box>
                  );
                }}
              </FormSpy>
            </Popover>
          )}
        </Flex>
      )}
      <Box
        {...(isSmallerScreen && {
          bg: 'primary.100',
          p: '12px',
          borderRadius: '0 0 8px 8px',
          mb: 6,
        })}
      >
        <Box w="100%" position="relative">
          {!isSmallerScreen && (
            <ActionButton
              deleteRow={deleteRow(allocationId)}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              canBeDeleted={canBeDeleted}
              addRow={addRow}
              disallowRowChange={disallowRowChange}
            />
          )}
          <Flex opacity={isOpen ? 0.5 : 1} {...(!isSmallerScreen && rowStyles)}>
            <Flex {...cellStyles(isSmallerScreen)}>
              <Box {...fieldWrapperStyles(isSmallerScreen, { w: '40%' })}>
                <Field
                  name={`resources[${allocationIdx}].technology`}
                  data-test-id={`${mainNameQA}--resources[${allocationIdx}].technology`}
                  variant={
                    isSmallerScreen
                      ? INPUT_VARIANTS.DEFAULT
                      : INPUT_VARIANTS.CONTAINER_FILLED
                  }
                  component={TextInput}
                  placeholder="Type name"
                />
              </Box>
              <Box {...fieldWrapperStyles(isSmallerScreen, { w: '25%' })}>
                <Field
                  name={`resources[${allocationIdx}].seniority`}
                  data-test-id={`${mainNameQA}--resources[${allocationIdx}].seniority`}
                  variant={
                    isSmallerScreen
                      ? INPUT_VARIANTS.NORMAL
                      : INPUT_VARIANTS.CONTAINER_FILLED
                  }
                  component={Select}
                  options={SENIORITY_OPTIONS}
                  placeholder={
                    isSmallerScreen ? 'Select seniority' : 'Seniority'
                  }
                  iconColor="blue.solid"
                />
              </Box>
              <Box {...fieldWrapperStyles(isSmallerScreen, { w: '20%' })}>
                <Field
                  name={`resources[${allocationIdx}].startDate`}
                  data-test-id={`${mainNameQA}--resources[${allocationIdx}].startDate`}
                  variant={
                    isSmallerScreen
                      ? INPUT_VARIANTS.NORMAL
                      : INPUT_VARIANTS.CONTAINER_FILLED
                  }
                  component={DatePicker}
                  disabledDaysFn={disabledDaysFn(proposalClosingDate)}
                  minW="auto"
                  bg="#fff"
                  containerProps={{ h: '100%' }}
                  placeholder={isSmallerScreen ? 'Select start date' : 'Select'}
                  calendarProps={{ top: isSmallerScreen ? '3px' : '13px' }}
                  calendarColor="#3366ff"
                />
              </Box>
              <Box
                {...fieldWrapperStyles(isSmallerScreen, {
                  w: '15%',
                  borderTopRightRadius: '12px',
                  borderRight: 0,
                })}
              >
                <Field
                  name={`resources[${allocationIdx}].numberOfMonths`}
                  data-test-id={`${mainNameQA}--resources[${allocationIdx}].numberOfMonths`}
                  variant={
                    isSmallerScreen
                      ? INPUT_VARIANTS.DEFAULT
                      : INPUT_VARIANTS.CONTAINER_FILLED
                  }
                  component={MaskedTextInput}
                  decimalScale={0}
                  isAllowed={({ floatValue }) =>
                    (floatValue >= 1 && floatValue <= 1000) || isNil(floatValue)
                  }
                  suffix={isSmallerScreen ? ' months' : ''}
                  borderTopRightRadius="8px"
                  placeholder="Months"
                />
              </Box>
            </Flex>
          </Flex>
        </Box>
        <Box
          {...(!isSmallerScreen && {
            position: 'relative',
            w: 'calc(100% - 39px)',
            left: '39px',
            mb: '40px',
          })}
        >
          <Box {...(isSmallerScreen && { mb: '12px' })}>
            <Field
              variant={
                isSmallerScreen
                  ? INPUT_VARIANTS.NORMAL
                  : INPUT_VARIANTS.CONTAINER_FILLED
              }
              component={MultiSelect}
              isRegular={!isSmallerScreen}
              name={`resources[${allocationIdx}].technologies`}
              data-test-id={`${mainNameQA}--resources[${allocationIdx}].technologies`}
              placeholder={
                isSmallerScreen
                  ? 'Add desired technologies'
                  : 'Add any technologies this candidate will need to know'
              }
              isLoading={isLoadingTechnologies}
              options={technologiesOptions}
              onAfterChange={technology => {
                formApi.change(
                  `resources[${allocationIdx}].technologies`,
                  map(technology, 'value'),
                );
              }}
              iconColor="black"
            />
          </Box>
          <Field
            name={`resources[${allocationIdx}].comment`}
            data-test-id={`${mainNameQA}--resources[${allocationIdx}].comment`}
            component={ExtendableRichText}
            placeholder={
              isSmallerScreen ? 'Add a comment' : 'Add a comment here'
            }
            inputStyle={{
              ...(isSmallerScreen && {
                borderTopRightRadius: '8px',
                borderTopLeftRadius: '8px',
                height: '136px',
                alignItems: 'flex-start',
                padding: '16px',
              }),
            }}
          />
        </Box>
      </Box>
    </>
  );
};

const MemoedResourceRole = React.memo(ResourceRow, (prevProps, nextProps) => {
  if (
    !isEqual(
      pick(prevProps, [
        'proposalClosingDate',
        'canBeDeleted',
        'allocationId',
        'rowsLength',
      ]),
      pick(nextProps, [
        'proposalClosingDate',
        'canBeDeleted',
        'allocationId',
        'rowsLength',
      ]),
    )
  ) {
    return false;
  }
  return true;
});

const ResourceTable = ({ formApi, mainNameQA, proposalClosingDate }) => {
  const [rows, setRows] = React.useState([]);
  React.useEffect(() => {
    setRows([{ id: nanoid(10), numberOfMonths: 3 }]);
  }, []);
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  const onRowDelete = id => setRows(reject(rows, { id }));

  const addRow = async (values, form) => {
    const id = nanoid(10);
    form.change('resources', [...values.resources, { id, numberOfMonths: 3 }]);
    setRows([...values.resources, { id }]);
  };
  const deleteRow = allocationId => (values, form) => {
    form.change('resources', reject(values.resources, { id: allocationId }));
    onRowDelete(allocationId);
  };

  return (
    <>
      <FormSpy subscription={{ values: true }}>
        {({ values, form }) => {
          React.useEffect(() => {
            forEach(values.resources, (r, idx) => {
              if (
                r.startDate &&
                moment
                  .utc(r.startDate)
                  .endOf('day')
                  .isBefore(moment.utc(values.proposalClosingDate))
              ) {
                formApi.change(`resources[${idx}].startDate`, null);
              }
            });
          }, [values.proposalClosingDate]);
          if (isEmpty(values.resources)) {
            form.change('resources', rows);
          }
          if (
            !isEmpty(omit(last(values.resources), ['id', 'numberOfMonths']))
          ) {
            // tricky
            sleep(0).then(() => {
              addRow(values, form);
            });
          }
          return null;
        }}
      </FormSpy>
      {!isSmallerScreen && <ResourceHead />}
      {map(rows, (allocation, idx) => {
        return (
          <MemoedResourceRole
            proposalClosingDate={proposalClosingDate}
            key={`resources-row-${allocation.id}`}
            allocationIdx={idx}
            formApi={formApi}
            mainNameQA={mainNameQA}
            allocationId={allocation.id}
            canBeDeleted={idx !== rows.length - 1}
            rowsLength={rows.length}
            addRow={addRow}
            deleteRow={deleteRow}
          />
        );
      })}
    </>
  );
};

export default React.memo(ResourceTable, (prev, next) => {
  return isEqual(prev.proposalClosingDate, next.proposalClosingDate);
});
