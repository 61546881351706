import { Box } from '@chakra-ui/react';
import React from 'react';
import ResourcesList from './ResourcesList';

const Resources = () => {
  return (
    <Box minH="calc(100vh - 80px)">
      <Box maxW="1201px" mx="auto" py="72px" px="56px">
        <ResourcesList />
      </Box>
    </Box>
  );
};

export default Resources;
