import { Image } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import React from 'react';

const EmptyMatches = ({ filter }) => {
  const label = React.useMemo(() => {
    if (filter === 'new') {
      return 'There are no new delivery partners for the selected criteria';
    }
    if (filter === 'partial') {
      return 'There are no partial matches for the selected criteria';
    }
    return 'There are no best matches for the selected criteria';
  }, [filter]);

  return (
    <WhiteCard
      p={['16px', '16px', '16px', '64px']}
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      <Image
        maxW="212px"
        mb="24px"
        src="/images/person-with-techs.png"
        ignoreFallback
      />
      <Typography variant="h4" textAlign="center">
        {label}
      </Typography>
    </WhiteCard>
  );
};

export default EmptyMatches;
