import moment from 'moment';

export const getNumberOfDaysForWeek = (
  year,
  month,
  week,
  projectStartDate,
  projectEndDate,
) => {
  const monthStart = moment.utc([year, month - 1, 1]).toDate();
  const monthEnd = moment
    .utc([year, month - 1, 1])
    .endOf('month')
    .toDate();

  let startDate = moment
    .utc(monthStart)
    .startOf('isoWeek')
    .add(week - 1, 'week')
    .toDate();
  let endDate = moment
    .utc(monthStart)
    .endOf('isoWeek')
    .add(week - 1, 'week')
    .toDate();

  if (projectEndDate < startDate || projectStartDate > endDate) return 0;

  if (startDate < projectStartDate) {
    startDate = projectStartDate;
  }

  if (endDate > projectEndDate) {
    endDate = projectEndDate;
  }

  if (startDate < monthStart) {
    startDate = monthStart;
  }

  if (endDate > monthEnd) {
    endDate = monthEnd;
  }

  return moment.utc(endDate).diff(moment.utc(startDate), 'days') + 1;
};

export const getWeekStartAndEnd = (
  year,
  month,
  week,
  projectStartDate,
  projectEndDate,
) => {
  const monthStart = moment.utc([year, month - 1, 1]).toDate();
  const monthEnd = moment
    .utc([year, month - 1, 1])
    .endOf('month')
    .toDate();

  let startDate = moment
    .utc(monthStart)
    .startOf('isoWeek')
    .add(week - 1, 'week')
    .toDate();
  let endDate = moment
    .utc(monthStart)
    .endOf('isoWeek')
    .add(week - 1, 'week')
    .toDate();

  if (projectEndDate < startDate || projectStartDate > endDate) {
    startDate = undefined;
    endDate = undefined;
  }

  if (startDate < projectStartDate) {
    startDate = projectStartDate;
  }

  if (endDate > projectEndDate) {
    endDate = projectEndDate;
  }

  if (startDate < monthStart) {
    startDate = monthStart;
  }

  if (endDate > monthEnd) {
    endDate = monthEnd;
  }

  const days = getNumberOfDaysForWeek(
    year,
    month,
    week,
    projectStartDate,
    projectEndDate,
  );

  return { startDate, endDate, days };
};

export const getDatePickerProps = (startDate, blockPast = true) => {
  if (moment.utc(startDate).isValid()) {
    return {
      disabledDays: blockPast
        ? day => moment.utc(day).isBefore(moment.utc(startDate))
        : undefined,
      fromMonth: blockPast ? moment.utc(startDate).toDate() : undefined,
      month: moment.utc(startDate).toDate(),
    };
  }
  return {};
};
