import React from 'react';

const useSubSidebarToggle = (toggleFn, role) => {
  React.useEffect(() => {
    toggleFn(role);
    return () => {
      toggleFn(role);
    };
  }, []);
};

export default useSubSidebarToggle;
