import { Box, Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';
import { FormSpy } from 'react-final-form';

const ActionButton = ({
  isOpen,
  setIsOpen,
  canBeDeleted,
  deleteRow,
  addRow,
  isManualAddingRow = false,
  disallowRowChange = false,
  actionColor,
  customIcon,
  alternateBg = false,
  isOnlyFirstRow = false,
  cannotRemoveExistingRow = false,
  mainNameQA,
}) => {
  let bg = isOpen ? 'red.light' : 'blue.solid';

  const prepareIconName = () => {
    if (customIcon) {
      return customIcon;
    }
    if ((isOnlyFirstRow && isManualAddingRow) || cannotRemoveExistingRow) {
      return 'check';
    }
    if (canBeDeleted || isManualAddingRow) {
      return 'close';
    }
    return 'add';
  };
  let iconName = prepareIconName();

  if (canBeDeleted && !isOpen) {
    bg = alternateBg ? 'dark.solid' : 'blue.light';
  }
  if (disallowRowChange) {
    bg = isManualAddingRow ? 'primary.200' : 'blue.solid';
    iconName = 'check';
  }
  bg = actionColor || bg;

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values, form }) => {
        return (
          <Box
            display="flex"
            alignSelf="center"
            justifyContent="center"
            alignItems="center"
            _hover={
              canBeDeleted &&
              !isOpen &&
              !disallowRowChange && {
                bg: 'red.solid',
                color: '#fff',
              }
            }
            w={isOpen ? '260px' : '40px'}
            bg={bg}
            border="1px solid #d9dada"
            h="100%"
            borderTopLeftRadius="8px"
            borderBottomLeftRadius="8px"
            cursor={
              disallowRowChange || isOnlyFirstRow || cannotRemoveExistingRow
                ? 'default'
                : 'pointer'
            }
            position="absolute"
            zIndex={10}
            left={0}
            onClick={() => {
              if (
                !isOpen &&
                !disallowRowChange &&
                !isOnlyFirstRow &&
                !cannotRemoveExistingRow
              ) {
                if (isManualAddingRow || canBeDeleted) {
                  setIsOpen(true);
                } else {
                  addRow(values, form);
                }
              }
            }}
          >
            {isOpen ? (
              <Flex pl="16px" pr="12px" w="100%" alignItems="center">
                <Typography mr="auto">Delete this row?</Typography>
                <Button
                  bg="green.solid"
                  borderRadius="100%"
                  iconButton={{ name: 'undo' }}
                  data-test-id={mainNameQA && `${mainNameQA}--undo`}
                  width="40px"
                  color="#fff"
                  _hover={{ bg: 'green.mid' }}
                  onClick={() => setIsOpen(false)}
                  mr="12px"
                />
                <Button
                  width="40px"
                  bg="red.solid"
                  borderRadius="100%"
                  iconButton={{ name: 'trash' }}
                  data-test-id={mainNameQA && `${mainNameQA}--remove`}
                  color="#fff"
                  _hover={{ bg: 'red.mid' }}
                  onClick={() => deleteRow(values, form)}
                />
              </Flex>
            ) : (
              <Box
                color={
                  canBeDeleted && !disallowRowChange ? 'blue.mid' : 'blue.mid'
                }
                _hover={{
                  color: '#fff',
                }}
                w="100%"
                h="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bg={isManualAddingRow && 'primary.200'}
                data-test-id={
                  mainNameQA &&
                  `${mainNameQA}--${canBeDeleted ? 'deleteRow' : 'addRow'}`
                }
              >
                <Icon
                  {...(alternateBg && { color: '#fff' })}
                  name={iconName}
                  fontSize={
                    canBeDeleted || disallowRowChange || isManualAddingRow
                      ? 20
                      : 14
                  }
                  _hover={{
                    color: '#fff',
                  }}
                  onClick={() => {
                    if (!disallowRowChange && !isManualAddingRow) {
                      if (canBeDeleted) {
                        setIsOpen(true);
                      } else {
                        addRow(values, form);
                      }
                    }
                  }}
                />
              </Box>
            )}
          </Box>
        );
      }}
    </FormSpy>
  );
};
export default ActionButton;
