/* eslint-disable no-underscore-dangle */
import { action, computed, makeObservable, observable } from 'mobx';
import { map, reject } from 'lodash-es';

export default class QuestionsStore {
  constructor({ API, toastsStore, briefsStore }) {
    makeObservable(this);
    this.API = API;
    this.toastsStore = toastsStore;
    this.briefsStore = briefsStore;
  }

  @computed get questions() {
    return map(this.briefsStore.brief.questions, q => ({
      ...q,
      repliesCount: (q.replies || []).length || 0,
    }));
  }

  @observable isLoading = false;

  @action setQuestionProperty = (questionId, data = {}) => {
    this.briefsStore._brief.questions = map(
      this.briefsStore._brief.questions,
      q => ({
        ...q,
        ...(q.id === questionId && data),
      }),
    );
  };

  @action deleteQuestion = async ({ briefId, questionId, replyId }, cb) => {
    try {
      this.setQuestionProperty(questionId, { isDeleting: true });
      if (replyId) {
        await this.API.deleteBriefQuestionReply(
          this.briefsStore.brief.isPlugAndPlay,
        )(briefId, questionId, replyId);
        this.briefsStore._brief.questions = map(
          this.briefsStore._brief.questions,
          q => ({
            ...q,
            replies: reject(q.replies, { id: replyId }),
          }),
        );
      } else {
        await this.API.deleteBriefQuestion(
          this.briefsStore.brief.isPlugAndPlay,
        )(briefId, questionId);
        this.briefsStore._brief.questions = reject(
          this.briefsStore._brief.questions,
          { id: questionId },
        );
      }
      if (cb) {
        cb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Network error. Please try again later.',
      });
    } finally {
      this.setQuestionProperty(questionId, { isDeleting: false });
    }
  };

  @action createQuestion = async ({ briefId, ...payload }, cb) => {
    try {
      const { data } = await this.API.createBriefQuestion(
        !!this.briefsStore.brief.newOrExisting,
      )(briefId, {
        ...payload,
        featureId: payload.featureId === 'general' ? null : payload.featureId,
      });
      this.briefsStore._brief.questions = [
        ...this.briefsStore._brief.questions,
        data,
      ];
      if (cb) {
        cb();
      }
    } catch (e) {
      this.toastsStore.showError({ title: e.message || 'Network error' });
    }
  };

  @action createQuestionReply = async (
    { briefId, questionId, ...payload },
    cb,
  ) => {
    try {
      const { data: reply } = await this.API.createBriefQuestionReply(
        !!this.briefsStore.brief.newOrExisting,
      )(briefId, questionId, payload);
      this.briefsStore._brief.questions = map(
        this.briefsStore._brief.questions,
        q => {
          if (q.id === questionId) {
            return { ...q, replies: [...(q.replies || []), reply] };
          }
          return q;
        },
      );
      if (cb) {
        cb();
      }
    } catch (e) {
      this.toastsStore.showError({ title: e.message || 'Network error' });
    }
  };
}
