import React from 'react';
import * as Pages from '@pages';
import Page404 from '@pages/404Page';
import { Redirect } from 'react-router';
import { Box } from '@chakra-ui/react';
import * as DeazyMarketplaceFront from '../utils/importMarketplaceUtil';
import { APP_ROUTES } from './routes';
import { NEW_ROLES } from './constants';
import injectMarketplaceComponent from '../utils/injectMarketplaceComponent';

export default [
  {
    path: APP_ROUTES.magicLink(),
    exact: true,
    component: Pages.MagicLink,
    redirectIfAuthenticated: false,
    isPrivate: false,
    layout: Box,
  },

  {
    path: APP_ROUTES.auth.signIn,
    exact: true,
    component: Pages.SignIn,
    redirectIfAuthenticated: true,
    isPrivate: false,
    layout: Box,
  },
  {
    path: APP_ROUTES.auth.resetPassword,
    exact: true,
    component: Pages.PasswordReset,
    isPrivate: true,
    layout: Box,
  },
  {
    path: APP_ROUTES.auth.forgottenPassword,
    exact: true,
    component: Pages.ForgottenPassword,
    redirectIfAuthenticated: true,
    isPrivate: false,
    layout: Box,
  },
  {
    path: APP_ROUTES.projects,
    exact: true,
    component: Pages.Projects,
    headTitle: 'Projects - Deazy Platform',
    title: 'Projects',
    searchBar: 'Search any project',
    pageProps: {
      isProjectsListRoute: true,
    },
  },
  {
    path: APP_ROUTES.briefsStartCreate,
    exact: true,
    component: Pages.BriefsStartCreate,
    headTitle: 'New brief',
    title: 'New brief',
    permittedRoles: [
      NEW_ROLES.ADMIN,
      NEW_ROLES.DEAZY_LEAD,
      NEW_ROLES.CLIENT_LEAD,
      NEW_ROLES.TEAM_ADMIN,
    ],
  },
  {
    path: APP_ROUTES.briefs,
    exact: true,
    component: Pages.Briefs,
    headTitle: 'Briefs - Deazy Platform',
    title: 'Briefs',
    searchBar: 'Search any brief',
    permittedRoles: [
      NEW_ROLES.ADMIN,
      NEW_ROLES.DEAZY_LEAD,
      NEW_ROLES.CLIENT_LEAD,
      NEW_ROLES.TEAM_ADMIN,
    ],
  },
  {
    path: APP_ROUTES.briefsEdit(),
    exact: false,
    component: Pages.BriefsCreate,
    headTitle: 'Edit Brief',
    title: 'Edit Brief',
    pageProps: {
      isEditBriefBasic: true,
    },
    permittedRoles: [
      NEW_ROLES.ADMIN,
      NEW_ROLES.DEAZY_LEAD,
      NEW_ROLES.CLIENT_LEAD,
    ],
  },
  {
    path: APP_ROUTES.briefsCreatePlugAndPlay,
    exact: true,
    component: Pages.BriefPlugAndPlayCreate,
    headTitle: 'New team augmentation brief',
    title: 'New team augmentation brief',
    permittedRoles: [
      NEW_ROLES.ADMIN,
      NEW_ROLES.DEAZY_LEAD,
      NEW_ROLES.CLIENT_LEAD,
    ],
  },
  {
    path: APP_ROUTES.briefsCreate,
    exact: false,
    component: Pages.BriefsCreate,
    headTitle: 'New project brief',
    title: 'New project brief',
    permittedRoles: [
      NEW_ROLES.ADMIN,
      NEW_ROLES.DEAZY_LEAD,
      NEW_ROLES.CLIENT_LEAD,
    ],
  },
  {
    path: APP_ROUTES.briefById(),
    component: Pages.Brief,
    headTitle: 'Brief review',
    title: 'Brief review',
    permittedRoles: [
      NEW_ROLES.ADMIN,
      NEW_ROLES.DEAZY_LEAD,
      NEW_ROLES.CLIENT_LEAD,
      NEW_ROLES.TEAM_ADMIN,
    ],
  },
  {
    path: APP_ROUTES.briefsEditPlugAndPlay(),
    exact: true,
    component: Pages.BriefPlugAndPlayCreate,
    headTitle: 'Edit team augmentation brief',
    title: 'Edit team augmentation brief',
    pageProps: {
      isEditPlugAndPlay: true,
    },
    permittedRoles: [
      NEW_ROLES.ADMIN,
      NEW_ROLES.DEAZY_LEAD,
      NEW_ROLES.CLIENT_LEAD,
    ],
  },
  {
    path: APP_ROUTES.briefPlugAndPlayById(),
    component: Pages.Brief,
    headTitle: 'Team Augmentation Brief review',
    title: 'Team Augmentation Brief review',
    pageProps: {
      isPlugAndPlay: true,
    },
    permittedRoles: [
      NEW_ROLES.ADMIN,
      NEW_ROLES.DEAZY_LEAD,
      NEW_ROLES.CLIENT_LEAD,
      NEW_ROLES.TEAM_ADMIN,
    ],
  },
  {
    path: APP_ROUTES.projectByClientAndSlug(),
    exact: false,
    component: Pages.Project,
    headTitle: 'Platform project',
    title: 'Platform project',
  },
  {
    path: APP_ROUTES.projectsCreate,
    exact: false,
    component: Pages.ProjectsCreate,
    headTitle: 'New project',
    title: 'Create New Project',
    permittedRoles: [NEW_ROLES.ADMIN, NEW_ROLES.DEAZY_LEAD],
  },
  {
    path: APP_ROUTES.settings,
    exact: true,
    component: Pages.Settings,
    title: 'Settings',
    permittedRoles: [NEW_ROLES.ADMIN, NEW_ROLES.DEAZY_LEAD],
  },
  {
    path: APP_ROUTES.profile,
    exact: true,
    component: Pages.Profile,
    headTitle: 'My profile',
    title: 'Profile',
  },
  {
    path: APP_ROUTES.users.all,
    exact: true,
    component: Pages.Users,
    headTitle: 'Users',
    title: 'Users',
    searchBar: 'Search any user',
    pageProps: {
      isAllUsersRoute: true,
    },
  },
  {
    path: APP_ROUTES.users.create,
    exact: false,
    component: Pages.UserCreate,
    headTitle: 'Create new user',
    title: 'Create new user',
    permittedRoles: [
      NEW_ROLES.ADMIN,
      // NEW_ROLES.DEAZY_LEAD,
      // NEW_ROLES.CLIENT_LEAD,
    ],
  },
  {
    path: APP_ROUTES.users.developerCreate,
    exact: false,
    component: Pages.UserCreate,
    headTitle: 'Team Member create',
    title: 'Team Member create',
    permittedRoles: [NEW_ROLES.ADMIN, NEW_ROLES.TEAM_ADMIN],
    pageProps: {
      isDeveloperCreateRoute: true,
    },
  },
  {
    path: APP_ROUTES.users.userByIdEdit(),
    exact: false,
    component: Pages.UserCreate,
    headTitle: 'User edit',
    title: 'User edit',
  },
  {
    path: APP_ROUTES.users.userByIdEditProfile(),
    exact: false,
    component: injectMarketplaceComponent(
      DeazyMarketplaceFront.EditStaffProfile,
    ),
    headTitle: 'Create profile',
    title: 'Create profile',
  },
  {
    path: APP_ROUTES.users.userById(),
    exact: true,
    component: Pages.User,
    headTitle: 'User',
    title: 'User',
  },
  {
    path: APP_ROUTES.users.admins,
    exact: true,
    component: Pages.Users,
    headTitle: 'Admins',
    title: 'Admins',
    searchBar: 'Search any user',
    pageProps: {
      isAdminRoute: true,
    },
    permittedRoles: [NEW_ROLES.ADMIN, NEW_ROLES.DEAZY_LEAD],
  },
  {
    path: APP_ROUTES.users.clientLeads,
    exact: true,
    component: Pages.Users,
    headTitle: 'Client Leads',
    searchBar: 'Search any user',
    title: 'Client Leads',
    pageProps: {
      isClientLeadRoute: true,
    },
  },
  {
    path: APP_ROUTES.users.projectManagers,
    exact: true,
    component: Pages.Users,
    headTitle: 'Deazy Leads',
    title: 'Deazy Leads',
    searchBar: 'Search any user',
    pageProps: {
      isProjectManagerRoute: true,
    },
  },
  {
    path: APP_ROUTES.users.teamMembers,
    exact: true,
    component: Pages.Users,
    headTitle: 'Team Members',
    title: 'Team Members',
    searchBar: 'Search any user',
    pageProps: {
      isTeamMemberRoute: true,
    },
  },
  {
    path: APP_ROUTES.users.teamAdmins,
    exact: true,
    component: Pages.Users,
    headTitle: 'Team Admins',
    title: 'Team Admins',
    searchBar: 'Search any user',
    pageProps: {
      isTeamAdminRoute: true,
    },
  },
  {
    path: APP_ROUTES.profiles,
    exact: true,
    component: injectMarketplaceComponent(DeazyMarketplaceFront.Profiles),
    headTitle: 'Profiles',
    title: 'Profiles',
    searchBar: 'Search any profile',
    permittedRoles: [NEW_ROLES.ADMIN, NEW_ROLES.DEAZY_LEAD],
  },
  {
    path: APP_ROUTES.profileById(),
    exact: true,
    component: injectMarketplaceComponent(DeazyMarketplaceFront.Profile),
    headTitle: 'Profile',
    title: 'Profile',
    hideTopbar: true,
    hideSidebar: true,
  },
  {
    path: APP_ROUTES.editProfileById(),
    exact: true,
    component: injectMarketplaceComponent(DeazyMarketplaceFront.EditProfile),
    headTitle: 'Profiles',
    title: 'Profiles',
    hideTopbar: true,
  },
  {
    path: APP_ROUTES.partners,
    exact: true,
    component: injectMarketplaceComponent(DeazyMarketplaceFront.Partners),
    headTitle: 'Partners',
    title: 'Partners',
  },
  {
    path: APP_ROUTES.partnerById(),
    exact: true,
    component: injectMarketplaceComponent(DeazyMarketplaceFront.Partner),
    headTitle: 'Partner',
    title: 'Partner',
    hideTopbar: true,
  },
  {
    path: APP_ROUTES.teams.suppliers,
    exact: true,
    component: Pages.Teams,
    headTitle: 'Delivery Partners',
    searchBar: 'Search any Delivery Partner',
    title: 'Delivery Partners',
    pageProps: {
      isSupplierRoute: true,
    },
  },
  {
    path: APP_ROUTES.teams.clients,
    exact: true,
    component: Pages.Teams,
    headTitle: 'Clients',
    searchBar: 'Search any client',
    title: 'Clients',
  },
  {
    path: APP_ROUTES.teams.supplierCreate,
    exact: false,
    component: Pages.TeamsCreate,
    headTitle: 'Delivery Partner create',
    title: 'Delivery Partner create',
    pageProps: {
      isSupplierRoute: true,
    },
    permittedRoles: [NEW_ROLES.ADMIN, NEW_ROLES.DEAZY_LEAD],
  },
  {
    path: APP_ROUTES.teams.clientCreate,
    exact: false,
    component: Pages.TeamsCreate,
    headTitle: 'Client create',
    title: 'Client create',
    pageProps: {
      isClientRoute: true,
    },
    permittedRoles: [NEW_ROLES.ADMIN, NEW_ROLES.DEAZY_LEAD],
  },
  {
    path: APP_ROUTES.teams.clientById(),
    exact: true,
    component: Pages.Team,
    headTitle: 'Client',
    title: 'Client',
    pageProps: {
      isClientRoute: true,
    },
  },
  {
    path: APP_ROUTES.teams.clientByIdEdit(),
    exact: false,
    component: Pages.TeamsCreate,
    headTitle: 'Client edit',
    title: 'Client edit',
    pageProps: {
      isClientRoute: true,
    },
  },
  {
    path: APP_ROUTES.teams.supplierById(),
    exact: true,
    component: Pages.Team,
    headTitle: 'Delivery Partner',
    title: 'Delivery Partner',
    pageProps: {
      isSupplierRoute: true,
    },
  },
  {
    path: APP_ROUTES.teams.supplierByIdEdit(),
    exact: false,
    component: Pages.TeamsCreate,
    headTitle: 'Delivery Partner edit',
    title: 'Delivery Partner edit',
    permittedRoles: [NEW_ROLES.ADMIN, NEW_ROLES.DEAZY_LEAD],
    pageProps: {
      isSupplierRoute: true,
    },
  },
  {
    path: APP_ROUTES.resources,
    exact: true,
    component: Pages.Resources,
    headTitle: 'Resources & Guides',
    title: 'Resources & Guides',
    permittedRoles: [
      NEW_ROLES.ADMIN,
      NEW_ROLES.DEAZY_LEAD,
      NEW_ROLES.TEAM_MEMBER,
      NEW_ROLES.TEAM_ADMIN,
    ],
  },
  {
    path: APP_ROUTES.changeRequestAction,
    exact: true,
    component: Pages.ChangeRequestAction,
    headTitle: 'Change Request Action',
    title: 'Change Request Action',
    isPrivate: false,
    layout: Box,
  },
  {
    path: APP_ROUTES.changeRequestGroupAction,
    exact: true,
    component: Pages.ChangeRequestAction,
    headTitle: 'Change Request Action',
    title: 'Change Request Action',
    isPrivate: false,
    layout: Box,
    pageProps: {
      isCRGroupRoute: true,
    },
  },
  {
    path: APP_ROUTES.tasks,
    exact: true,
    component: Pages.Tasks,
    headTitle: 'Tasks',
    title: 'Tasks',
    pageProps: {
      isTasksRoute: true,
    },
    permittedRoles: [NEW_ROLES.ADMIN, NEW_ROLES.DEAZY_LEAD],
  },
  {
    path: APP_ROUTES.mailing,
    exact: true,
    component: Pages.Mailing,
    headTitle: 'Mailing',
    title: 'Test some templates',
    permittedRoles: [NEW_ROLES.ADMIN],
  },
  {
    path: APP_ROUTES.myProfile,
    exact: true,
    component: injectMarketplaceComponent(DeazyMarketplaceFront.MyProfile),
    headTitle: 'MyProfile',
    title: 'MyProfile',
    hideTopbar: true,
  },
  {
    path: APP_ROUTES.editMyProfile,
    exact: true,
    component: injectMarketplaceComponent(
      DeazyMarketplaceFront.EditMyProfileComponent,
    ),
    headTitle: 'Edit Profile',
    title: 'Edit my profile',
    hideTopbar: true,
  },
  {
    path: APP_ROUTES.clientsIndividuals,
    exact: true,
    component: injectMarketplaceComponent(DeazyMarketplaceFront.ClientLeads),
    headTitle: 'Individuals',
    searchBar: 'Search any client',
    title: 'Clients',
  },
  {
    path: APP_ROUTES.clientById(),
    exact: true,
    component: injectMarketplaceComponent(DeazyMarketplaceFront.SingleClient),
    headTitle: 'Client',
    title: 'Client',
    hideTopbar: true,
    hideSidebar: true,
  },
  {
    path: APP_ROUTES.individualClientById(),
    exact: true,
    component: injectMarketplaceComponent(
      DeazyMarketplaceFront.IndividualClient,
    ),
    headTitle: 'Client',
    title: 'Client',
    hideTopbar: true,
    hideSidebar: true,
  },
  {
    path: APP_ROUTES.deazyTeam,
    exact: true,
    component: injectMarketplaceComponent(DeazyMarketplaceFront.DeazyUsers),
    headTitle: 'Deazy Team',
    searchBar: 'Search any Deazy Team member',
    title: 'Deazy Team',
  },
  {
    path: APP_ROUTES.deazyTeamById(),
    exact: true,
    component: injectMarketplaceComponent(
      DeazyMarketplaceFront.DeazyUserProfile,
    ),
    headTitle: 'Edit Profile',
    title: 'Deazy Team',
    hideTopbar: true,
    hideSidebar: true,
  },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={APP_ROUTES.projects} />,
  },
  {
    path: '*',
    component: () => <Page404 />,
  },
];
