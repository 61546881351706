import React from 'react';
import { Box } from '@chakra-ui/react';
import DeazyAllocationTable from '@components/DeazyAllocationTable';
import { AllocationTableWrapper } from './FixedPriceAllocations';

const DeazyAllocations = ({
  hide,
  fieldName,
  disabledFields,
  mainNameQA,
  resourceAsText,
}) => {
  if (hide) {
    return null;
  }
  return (
    <Box display={hide ? 'none' : 'block'}>
      <AllocationTableWrapper p="40px" mt="0px">
        <DeazyAllocationTable
          mainNameQA={mainNameQA}
          fieldName={fieldName}
          disabledFields={disabledFields}
          resourceAsText={resourceAsText}
        />
      </AllocationTableWrapper>
    </Box>
  );
};
export default DeazyAllocations;
