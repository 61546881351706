export const PB_MATCH_OPTIONS = [
  {
    id: 1,
    title: 'Accountable',
    description: `Is the client outsourcing a significant proportion of the project?`,
    fieldName: 'isAccountable',
  },
  {
    id: 2,
    title: 'Structured',
    description: `Is the client a "Large Sized Enterprise" (# employees > 500 or annual revenue > £1B)? If not,
    is the client known for sticking rigidly to delivery processes? e.g. Are they regimented in writing documentation?`,
    fieldName: 'isStructured',
  },
  {
    id: 3,
    title: 'Good Communications',
    description: `Is the client nervous about working with outsourced teams due to lack of experience or poor previous experience?`,
    fieldName: 'isGoodCommunication',
  },
  {
    id: 4,
    title: 'Flexible',
    description: `Are the deliverables likely to change from what is currently defined?`,
    fieldName: 'isFlexible',
  },
  {
    id: 5,
    title: 'Inquisitive',
    description: `Does the product require research to reach an implementation approach?`,
    fieldName: 'isInquisitive',
  },
];

export const TA_MATCH_OPTIONS = [
  {
    id: 6,
    title: 'Accountable',
    description: `Is the client outsourcing a significant proportion of the project?`,
    fieldName: 'isAccountable',
  },
  {
    id: 7,
    title: 'Responsive outside delivery',
    description: `Is the client in a highly competitive environment where ability to kick-off quickly is essential?`,
    fieldName: 'isResponsiveOutsideDelivery',
  },
];
