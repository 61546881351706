import React from 'react';
import TextInput from '@components/forms/TextInput';
import { Field, Form } from 'react-final-form';
import { Box } from '@chakra-ui/react';
import AutoSave from './AutoSave';

const CurrencyForm = ({
  label,
  initialValues,
  onSubmit,
  setSubmit,
  isReadOnly,
  mainNameQA,

  ...props
}) => (
  <Box position="relative" maxW="358px" w="100%" {...props}>
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, values, form }) => {
        setSubmit(handleSubmit);
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <AutoSave
              formApi={form}
              values={values}
              onSave={onSubmit}
              formName="currency"
            />
            <Field
              name="overriddenCurrency"
              label="Project currency"
              component={TextInput}
              isDisabled={isReadOnly}
              data-test-id={`${mainNameQA}--overriddenCurrency`}
            />
          </form>
        );
      }}
    />
  </Box>
);

export default CurrencyForm;
