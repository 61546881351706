import { Box, Flex, List, ListItem } from '@chakra-ui/react';
import Icon from '@components/Icon';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import { cloneDeep, forEach, isEmpty, keys, map, take } from 'lodash-es';
import { nanoid } from 'nanoid';
import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import AutoSave from '../../Brief/Proposal/AutoSave';
import MilestoneRow from './MilestoneRow';
import MilestoneUpdateRow from './MilestoneUpdateRow';

const TableHeader = ({ title, subtitle }) => (
  <Box maxW="720px" mb="32px">
    <Typography variant="h3" mb="16px" mt="8px">
      {title}
    </Typography>
    <Typography>{subtitle}</Typography>
  </Box>
);

const TableHead = () => (
  <Flex w="100%" mb="12px" mt="24px" color="dark.solid">
    <Flex w="100%">
      <Typography variant="caption" ml="40px" w="40%">
        Milestone
      </Typography>
    </Flex>
    <Flex minW="256px">
      <Typography variant="caption">Planned date</Typography>
    </Flex>
  </Flex>
);

const UpdateTableHead = () => (
  <Flex w="100%" mb="12px" mt="24px" color="dark.solid">
    <Typography variant="caption" pl="40px" w="30%">
      Milestone
    </Typography>
    <Typography variant="caption" w="14%">
      Status
    </Typography>
    <Typography variant="caption" w="14%">
      Baseline date
    </Typography>
    <Typography variant="caption" w="14%">
      Planned date
    </Typography>
    <Typography variant="caption" w="14%">
      Actual date
    </Typography>
    <Typography variant="caption" w="14%">
      Comments
    </Typography>
  </Flex>
);

const validate = ({ milestones }) => {
  const errors = { milestones: [] };
  forEach(milestones, (r, idx) => {
    errors.milestones[idx] = {};
    if (milestones.length - 1 !== idx) {
      if (!r.name) {
        errors.milestones[idx].name = 'Milestone name is required';
      } else if (r.name.length > 125) {
        errors.milestones[idx].name =
          'Milestone name must be shorter than or equal to 125 characters';
      }
      if (!r.baselineDate) {
        errors.milestones[idx].baselineDate = 'Planned date is required';
      }
    }
    if (isEmpty(errors.milestones[idx])) {
      delete errors.milestones[idx];
    }
  });

  return errors.milestones;
};

const MilestoneTableForm = ({
  title,
  subtitle,
  stage,
  onSubmit,
  initialValues,
  reportStatus,
  ignoreErrors,
  isAdminOrDL,
  isTeamAdminSideUser,
  isClient,
  isPrimaryDP,
  isPlugPlay,
  mainNameQA,

  setSubmit = () => {},
}) => {
  return (
    <Form
      onSubmit={values => onSubmit(values, stage)}
      initialValues={initialValues}
      subscription={{ values: true, errors: true, touched: true }}
      keepDirtyOnReinitialize
      validate={validate}
      render={({
        handleSubmit,
        errors,
        touched,
        values: { milestones = [] },
        form: formApi,
      }) => {
        setSubmit(handleSubmit);
        const emptyRowsNumber = milestones.filter(
          m =>
            !m.name &&
            !m.baselineDate &&
            m.stage === stage &&
            !m.ragStatus &&
            !m.percentComplete &&
            !m.plannedCompletionDate &&
            !m.actualDate,
        ).length;

        useEffect(() => {
          if (emptyRowsNumber === 0) {
            formApi.change('milestones', [
              ...milestones,
              { rowId: nanoid(10), stage },
            ]);
          }
        }, [emptyRowsNumber]);

        const onAddMilestone = () => {
          formApi.change('milestones', [
            ...milestones,
            { rowId: nanoid(10), stage },
          ]);
        };

        const onChangeMilestone = (val, inputName, milestoneRowId) => {
          const updatedMilestones = cloneDeep(milestones).map(m => {
            if (m.rowId === milestoneRowId) {
              m[inputName] = val;
              m.toBeUpdated = true;
            }
            return m;
          });

          formApi.change('milestones', updatedMilestones);
        };

        const onDeleteMilestone = milestoneRowId => {
          const updatedMilestones = cloneDeep(milestones).map(m => {
            if (m.rowId === milestoneRowId) {
              m.toBeDeleted = true;
            }
            return m;
          });

          formApi.change('milestones', updatedMilestones);
        };

        const filteredMilestones =
          (!isAdminOrDL && !isTeamAdminSideUser) ||
          (isTeamAdminSideUser && !isPrimaryDP)
            ? take(milestones, milestones.length - 1)
            : milestones;
        return (
          <>
            <AutoSave
              ignoreErrors={ignoreErrors}
              formApi={formApi}
              onSave={values => onSubmit(values, stage)}
            />
            <form onSubmit={handleSubmit} autoComplete="off">
              <WhiteCard>
                <TableHeader title={title} subtitle={subtitle} />
                {reportStatus !== 'Accepted' ? (
                  <TableHead />
                ) : (
                  <UpdateTableHead />
                )}

                {map(
                  filteredMilestones,
                  (m, idx) =>
                    !m?.toBeDeleted && (
                      <React.Fragment key={`milestone-row-${m.rowId}`}>
                        {reportStatus !== 'Accepted' ? (
                          <MilestoneRow
                            isPrimaryDP={isPrimaryDP}
                            isAdminOrDL={isAdminOrDL}
                            isTeamAdminSideUser={isTeamAdminSideUser}
                            isClient={isClient}
                            milestoneRowId={m.rowId}
                            idx={idx}
                            reportStatus={reportStatus}
                            stage={stage}
                            canBeDeleted={idx !== milestones.length - 1}
                            onAddRow={onAddMilestone}
                            onDeleteRow={onDeleteMilestone}
                            onChangeRow={onChangeMilestone}
                            mainNameQA={mainNameQA}
                          />
                        ) : (
                          <MilestoneUpdateRow
                            isPrimaryDP={isPrimaryDP}
                            isAdminOrDL={isAdminOrDL}
                            isTeamAdminSideUser={isTeamAdminSideUser}
                            isLast={idx === milestones.length - 1}
                            milestoneRowId={m.rowId}
                            idx={idx}
                            reportStatus={reportStatus}
                            stage={stage}
                            canBeDeleted={idx !== milestones.length - 1}
                            onAddRow={onAddMilestone}
                            milestone={m}
                            onDeleteRow={onDeleteMilestone}
                            onChangeRow={onChangeMilestone}
                            isPlugPlay={isPlugPlay}
                            mainNameQA={mainNameQA}
                          />
                        )}

                        {!isEmpty(errors && errors[idx]) && (
                          <List ml="24px">
                            {map(keys(errors[idx]), (key, i) =>
                              touched[`milestones[${idx}].${key}`] ? (
                                <ListItem
                                  className="final-form-error"
                                  color="red.500"
                                  mb="12px"
                                  key={`milestone-error-${idx}-${i}`}
                                >
                                  <Icon
                                    name="warning"
                                    color="red.500"
                                    mb="2px"
                                    mr="5px"
                                  />
                                  {errors[idx][key]}
                                </ListItem>
                              ) : null,
                            )}
                          </List>
                        )}
                      </React.Fragment>
                    ),
                )}
              </WhiteCard>
            </form>
          </>
        );
      }}
    />
  );
};

export default MilestoneTableForm;
