import { Box } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import { formatCurrency } from '@utils';
import { prepareCostRange } from '@utils/proposalUtils';
import { isEmpty, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import CostRow from '../CostRow';
import ResourcesBreakdownTable from './ResourcesBreakDownTable';

const ResourcesBreakdownSection = ({
  isClientView,
  proposalsStore: { proposalResources, clientProposalData, proposalData },
  mainNameQA,
  isSmallerScreen,
}) => {
  const currency =
    proposalData.overriddenCurrency || clientProposalData.currency;

  const resources = React.useMemo(() => {
    if (isClientView) {
      return map(clientProposalData.resourceBreakdown, res => ({
        ...res,
        cost: prepareCostRange({
          cost: res.cost,
          minCost: res.minCost,
          maxCost: res.maxCost,
          currency: 'GBP',
        }),
      }));
    }
    return map(proposalResources.data, res => ({
      ...res,
      cost: formatCurrency(res.cost, currency),
    }));
  }, [clientProposalData, proposalResources.data, currency]);

  const { costs = {} } = clientProposalData;
  return (
    <WhiteCard
      mt={0}
      mb="24px"
      py={isSmallerScreen ? '16px' : '40px'}
      px={isSmallerScreen ? '12px' : '32px'}
      boxShadow="0 0 0 0 rgba(0, 0, 0, 0)"
      border="solid 1px"
      borderColor="dark.mid"
    >
      <Typography variant="h2" mb={isSmallerScreen ? '16px' : '32px'}>
        Resource Breakdown
      </Typography>
      {isSmallerScreen && (
        <Typography mb="24px">
          A breakdown by resource gives clear visibility to where the complexity
          lies.
        </Typography>
      )}
      {proposalResources.error ? (
        <Typography
          my="20px"
          variant="h4"
          borderLeft="3px solid"
          borderColor="red.solid"
          pl="20px"
        >
          {proposalResources.error}
        </Typography>
      ) : (
        <>
          {isEmpty(resources) ? (
            <Typography fontSize={20} mb="20px">
              No data to show.
            </Typography>
          ) : (
            <Box>
              <ResourcesBreakdownTable
                resources={resources}
                mainNameQA={mainNameQA}
                isSmallerScreen={isSmallerScreen}
              />
            </Box>
          )}
        </>
      )}
      {isClientView && (
        <CostRow
          showHours={false}
          title="Total cost"
          cost={prepareCostRange({
            maxCost: costs.proposalMaxCost,
            minCost: costs.proposalMinCost,
            cost: costs?.proposalCost,
          })}
          border="2px solid"
          borderColor="blue.solid"
          currency="GBP"
          isSmallerScreen={isSmallerScreen}
          mainNameQA={mainNameQA}
          data-test-id={mainNameQA && `${mainNameQA}--totalCost`}
        />
      )}
    </WhiteCard>
  );
};

export default inject('proposalsStore')(observer(ResourcesBreakdownSection));
