import { Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { isEmpty, map } from 'lodash-es';
import moment from 'moment';
import React from 'react';

const CandidateQueriesList = ({ queries, isDpView, mainNameQA }) => {
  if (!isEmpty(queries)) {
    return (
      <Flex
        bg="dark.light"
        borderTop="1px solid"
        borderColor="dark.mid"
        p="16px"
        borderBottomRightRadius="8px"
        borderBottomLeftRadius="8px"
        flexDir="column"
      >
        {map(queries, query => (
          <React.Fragment key={`query-item-${query.id}`}>
            <Typography
              variant="text3"
              mb="8px"
              data-test-id={mainNameQA && `${mainNameQA}--queryCreatedAt`}
            >
              {`Query ${isDpView ? 'by Deazy' : ''} | ${moment(
                query.createdAt,
              ).format('MMM D, YYYY, h:mm A')}`}
            </Typography>
            <Typography
              _notLast={{ mb: '28px' }}
              data-test-id={mainNameQA && `${mainNameQA}--queryMessage`}
            >
              {query.message}
            </Typography>
          </React.Fragment>
        ))}
      </Flex>
    );
  }
  return null;
};

export default CandidateQueriesList;
