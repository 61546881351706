import { PROJECT_TYPES } from '@app/constants';
import moment from 'moment';
import SubSidebarTabs from '@components/SubSidebarTabs';
import useSubSidebarToggle from '@hooks/useSubSidebarToggle';
import { APP_ROUTES } from '@routes';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { createProjectSlug } from '@utils/projectUtils';
import { find, get, includes, isEmpty, reject } from 'lodash-es';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import AllChangeRequests from './AllChangeRequests';
import AllDeazyActuals from './AllDeazyActuals';
import AllDeazyAllocations from './AllDeazyAllocations';
import Allocations from './AllocationsAndActuals';
import AllTeamActuals from './AllTeamActuals';
import AllTeamAllocations from './AllTeamAllocations';
import ChangeRequests from './ChangeRequests';
import Documents from './Documents';
import Invoice from './Invoice';
import Milestone from './Milestone';
import NationalHolidays from './NationalHolidays';
import NewDeliveryPartnerToProject from './NewDeliveryPartnerToProject';
import Overview from './Overview';
import Payments from './Payments';
import ProjectSurvey from './ProjectSurvey';
import StatementOfWorks from './StatementOfWorks';
// import PandadocSow from './StatementOfWorks/PandadocSow';

const ADDITIONAL_PERMITTED_ROUTES = [
  'all-deazy-allocations',
  'all-team-allocations',
  'all-team-actuals',
  'all-deazy-actuals',
  'invoices',
  'new-delivery-partner',
];

const Project = ({
  match: {
    params: { clientSlug, projectSlug },
  },
  projectsStore: {
    isPrimaryDP,
    clearProject,
    isLoading,
    fetchProject,
    updateProject,
    project = {},
  },
  appStore: { toggleSubSidebar, isSubSidebarOpened, featureFlags },
  usersStore: {
    isClient,
    isTeamMember,
    isTeamAdmin,
    isAdminOrDL,
    isTeamAdminSideUser,
  },
  sowsStore: { canSeeSowTab },
  allocationsStore: { clearAllAllocations },
  history,
  location: { pathname },
}) => {
  const permissionsToSeeMilestoneTab = status => {
    if (status === 'Accepted' || status === 'Submitted') {
      return false;
    }
    if (isAdminOrDL || isTeamAdminSideUser) {
      return false;
    }
    return true;
  };

  const { report, id: projectId, milestonesEnabled } = project || {};
  const TABS = reject(
    [
      {
        label: 'Overview',
        value: 'milestone',
        isDisabled: true,
        hide:
          permissionsToSeeMilestoneTab(report?.status) || !milestonesEnabled,
        hideTitle: true,
      },
      {
        label: 'Statement of Work',
        value: 'statement-of-work',
        hideTitle: true,
        hide: !canSeeSowTab,
      },
      {
        label: 'Invoices',
        value: 'payments',
        hideTitle: true,
        hide: isTeamMember,
      },
      {
        label: 'Allocations',
        value: 'allocations',
        hideTitle: true,
        hide: project.projectType !== PROJECT_TYPES.FIXED_PRICE,
      },
      {
        label: 'Allocations, Actuals',
        value: 'allocations-and-actuals',
        hideTitle: true,
        hide: project.projectType === PROJECT_TYPES.FIXED_PRICE,
      },
      {
        label: 'Change Requests',
        hideTitle: true,
        value: 'change-requests',
        hide:
          project.projectType !== PROJECT_TYPES.FIXED_PRICE ||
          (!isPrimaryDP && (isTeamAdmin || isTeamMember)),
      },
      { label: 'Documents', value: 'documents', hide: false },
      {
        label: 'National Holidays',
        value: 'national-holidays',
        hide: false,
        hideTitle: true,
        isDisabled: true,
      },
      {
        label: 'Project Survey',
        hideTitle: true,
        value: 'survey',
        hide:
          !featureFlags['delivery-partner-matching'] ||
          !isAdminOrDL ||
          moment
            .utc(project.firstEndDate || project.endDate)
            .isAfter(moment.utc().startOf('day')),
      },
      { label: 'Settings', value: 'overview', hide: isClient || isTeamMember },
    ],
    { hide: true },
  );

  const { isInvoicePage } = React.useMemo(
    () => ({
      isInvoicePage: includes(pathname, 'payments/invoices'),
    }),
    [pathname],
  );
  useSubSidebarToggle(() =>
    toggleSubSidebar(isAdminOrDL || isTeamAdminSideUser || isClient),
  );

  React.useEffect(() => {
    fetchProject({ clientSlug, projectSlug });

    return () => {
      clearProject();
      clearAllAllocations();
    };
  }, []);

  React.useEffect(() => {
    if (
      !find(TABS, tab => includes(pathname, tab.value)) &&
      !find(ADDITIONAL_PERMITTED_ROUTES, route => includes(pathname, route)) &&
      project.id
    ) {
      history.push(APP_ROUTES.projects);
    }
  }, [pathname, project.id]);

  const onTabChange = projectTab => {
    return history.push(createProjectSlug(project, projectTab));
  };

  const activeTab = get(
    find(TABS, ({ value }) =>
      includes(
        pathname.replace(clientSlug, '').replace(projectSlug, ''),
        value,
      ),
    ),
    'value',
  );

  const sectionTitle = isInvoicePage
    ? { label: 'Invoice' }
    : find(TABS, { value: activeTab }) || {};

  return (
    <>
      {!isEmpty(project) && !isLoading && (
        <SubSidebarTabs
          value={activeTab}
          tabs={TABS}
          onAfterChange={onTabChange}
          subsidebarProps={{
            width: isSubSidebarOpened ? '172px' : '0',
          }}
        />
      )}
      {!sectionTitle.hideTitle && (
        <Typography
          variant="h2"
          mt="32px"
          mb="48px"
          maxW="1280px"
          px="40px"
          w="100%"
          mx="auto"
        >
          {sectionTitle.label}
        </Typography>
      )}

      {isLoading || isEmpty(project) ? (
        <Spinner />
      ) : (
        <Switch>
          <Route
            path={APP_ROUTES.projectTabWithSlugs(null, null, 'milestone')}
            exact
            render={props => <Milestone {...props} />}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(
              null,
              null,
              'statement-of-work',
            )}
            exact
            render={() => (
              <Redirect
                to={`statement-of-work/${
                  project.deazyAsClient ||
                  project.clientSOWSignedOutsideOfPlatform
                    ? 'delivery-partner'
                    : 'client'
                }`}
              />
            )}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(
              null,
              null,
              'statement-of-work/:type',
            )}
            exact
            render={props => {
              if (
                project.clientSOWSignedOutsideOfPlatform &&
                !find(
                  project.projectToSuppliers,
                  pts => !pts.supplierSOWSignedOutsideOfPlatform,
                )
              ) {
                return <Redirect to="/projects" />;
              }
              if (
                props.match.params.type === 'client' &&
                find(
                  project.projectToSuppliers,
                  pts => !pts.supplierSOWSignedOutsideOfPlatform,
                ) &&
                project.clientSOWSignedOutsideOfPlatform
              ) {
                return <Redirect to="delivery-partner" />;
              }
              if (
                props.match.params.type === 'delivery-partner' &&
                !find(
                  project.projectToSuppliers,
                  pts => !pts.supplierSOWSignedOutsideOfPlatform,
                )
              ) {
                return <Redirect to="client" />;
              }
              // if (
              //   (project.engagementType === 'Team Augmentation' ||
              //     project.engagementType === 'Retained Team') &&
              //   featureFlags['use-pandadoc']
              // ) {
              //   return <PandadocSow {...props} />;
              // }
              return <StatementOfWorks {...props} />;
            }}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(null, null, 'overview')}
            exact
            render={props => <Overview {...props} onSubmit={updateProject} />}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(null, null, 'allocations')}
            exact
            render={props => (
              <Allocations
                {...props}
                projectId={projectId}
                project={toJS(project)}
              />
            )}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(
              null,
              null,
              'allocations-and-actuals',
            )}
            exact
            render={props => (
              <Allocations
                {...props}
                projectId={projectId}
                project={toJS(project)}
              />
            )}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(
              null,
              null,
              'all-team-allocations/:supplierId',
            )}
            exact
            render={props => (
              <AllTeamAllocations {...props} projectId={projectId} />
            )}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(
              null,
              null,
              'all-team-actuals/:supplierId',
            )}
            exact
            render={props => (
              <AllTeamActuals {...props} projectId={projectId} />
            )}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(
              null,
              null,
              'all-deazy-actuals',
            )}
            exact
            render={props => (
              <AllDeazyActuals {...props} projectId={projectId} />
            )}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(
              null,
              null,
              'all-deazy-allocations',
            )}
            exact
            render={props => (
              <AllDeazyAllocations {...props} projectId={projectId} />
            )}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(null, null, 'payments')}
            exact
            render={props => <Payments {...props} projectId={projectId} />}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(
              null,
              null,
              'payments/invoices/:invoiceId',
            )}
            exact
            render={props => <Invoice {...props} projectId={projectId} />}
          />
          {/* <Route
            path={APP_ROUTES.projectTabWithSlugs(null, null, 'change-requests')}
            exact
            render={() => <Redirect to="change-requests" />}
          /> */}
          <Route
            path={APP_ROUTES.projectTabWithSlugs(
              null,
              null,
              'change-requests/',
            )}
            exact
            render={props => (
              <ChangeRequests {...props} projectId={projectId} />
            )}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(
              null,
              null,
              'change-requests/all',
            )}
            exact
            render={props => (
              <AllChangeRequests {...props} projectId={projectId} />
            )}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(
              null,
              null,
              'national-holidays',
            )}
            exact
            render={props => (
              <NationalHolidays {...props} projectId={projectId} />
            )}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(
              null,
              null,
              'new-delivery-partner',
            )}
            exact
            render={props => (
              <NewDeliveryPartnerToProject {...props} project={toJS(project)} />
            )}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(null, null, 'documents')}
            exact
            render={props => <Documents {...props} projectId={projectId} />}
          />
          <Route
            path={APP_ROUTES.projectTabWithSlugs(null, null, 'survey')}
            exact
            render={props => <ProjectSurvey {...props} projectId={projectId} />}
          />
        </Switch>
      )}
    </>
  );
};

export default inject(
  'projectsStore',
  'paymentsStore',
  'sowsStore',
  'usersStore',
  'appStore',
  'allocationsStore',
)(observer(Project));
