import React from 'react';
import { withRouter } from 'react-router-dom';

export default
@withRouter
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      location: { pathname, search },
    } = this.props;
    if (
      pathname !== prevProps.location.pathname ||
      search !== prevProps.location.search
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}
