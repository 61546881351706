import {
  PROJECT_AVAILABLE_CURRENCIES,
  PROJECT_TYPES,
  TOP_PROJECT_PRIORITY,
} from '@app/constants';
import { Box, Flex } from '@chakra-ui/react';
import Condition from '@components/forms/Condition';
import DatePicker from '@components/forms/DatePicker';
import ErrorSpy from '@components/forms/ErrorSpy';
import FormSpyFieldValues from '@components/forms/FormSpyFieldValues';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import Select from '@components/forms/_common/Select';
import InfoAlert from '@components/InfoAlert';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import Typography from '@styles/Typography';
import {
  composeValidators,
  isDefined,
  mustBeAfter,
  mustBeGreaterThan,
  mustBePositiveNumber,
  required,
} from '@utils/formValidators';
import { map } from 'lodash-es';
import moment from 'moment';
import React from 'react';
import { Field, Form } from 'react-final-form';
import WizardFooter from '../WizardFooter';
import WizardLeavingGuard from '../WizardLeavingGuard';
import FormRadio from './FormRadio';

const CURRENCY_OPTIONS = map(PROJECT_AVAILABLE_CURRENCIES, c => ({
  value: c,
  label: c,
}));

const disabledDaysFn = day =>
  moment.utc(day).isBefore(moment.utc().startOf('day'));

const targetDateDisableFn = proposalClosingDate => day =>
  moment.utc(day).isBefore(moment.utc().startOf('day')) ||
  moment.utc(day).isBefore(moment.utc(proposalClosingDate).startOf('day'));

export const ClosingDateSection = ({ mainNameQA }) => {
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  return (
    <>
      <Typography
        variant="h2"
        {...(isSmallerScreen ? { mt: 6, mb: 3 } : { mt: 14, mb: 8 })}
      >
        When do you need a proposal by?
      </Typography>
      <Field
        component={DatePicker}
        disabledDaysFn={disabledDaysFn}
        name="proposalClosingDate"
        label="Select date"
        placeholder="Select a date"
        backgroundColor="white"
        data-test-id={`${mainNameQA}--proposalClosingDate`}
        validate={composeValidators(
          required,
          mustBeAfter(moment.utc().subtract({ day: 1 })),
        )}
        containerProps={{ maxW: isSmallerScreen ? 'auto' : '260px' }}
        errorQaValue={`${mainNameQA}--closingDateError`}
      />
    </>
  );
};

const titleStyles = isSmallerScreen =>
  isSmallerScreen
    ? {
        mt: 6,
        mb: 3,
      }
    : {
        mt: 14,
        mb: 4,
      };

const subTitleStyles = isSmallerScreen =>
  isSmallerScreen
    ? {
        mb: 4,
      }
    : {
        mb: 8,
      };

const budgetInputStyle = isSmallerScreen =>
  isSmallerScreen
    ? {
        width: 'auto',
        position: 'relative',
        maxWidth: 'none',
        flex: 1,
      }
    : { maxW: '118px' };

const FinishStepForm = ({
  initialValues,
  onSubmit,
  submit,
  onPrevClick,
  mainNameQA,
}) => {
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        subscription={{ submitting: true, submitSucceeded: true }}
        render={({ handleSubmit, form, submitting, submitSucceeded }) => {
          submit(handleSubmit);
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <ErrorSpy />
              <Typography variant="h2" mb="16px">
                Target date
                <Box as="span" fontSize="18px" color="gray.300">
                  &nbsp;(if you have one)
                </Box>
              </Typography>
              <Typography {...subTitleStyles(isSmallerScreen)}>
                When would you like to see the product go live?
              </Typography>
              <FormSpyFieldValues
                fieldNames={['proposalClosingDate', 'startDate']}
              >
                {({ proposalClosingDate, startDate }) => {
                  React.useEffect(() => {
                    if (
                      moment
                        .utc(startDate)
                        .isBefore(
                          moment.utc(proposalClosingDate).startOf('day'),
                        )
                    ) {
                      form.change('startDate', null);
                    }
                  }, [startDate, proposalClosingDate]);
                  return (
                    <Field
                      component={DatePicker}
                      disabledDaysFn={targetDateDisableFn(proposalClosingDate)}
                      data-test-id={`${mainNameQA}--startDate`}
                      name="startDate"
                      label="Select date"
                      placeholder="Select a date"
                      backgroundColor="white"
                      validate={mustBeAfter(moment.utc().subtract({ day: 1 }))}
                      containerProps={{
                        maxW: isSmallerScreen ? 'auto' : '260px',
                      }}
                    />
                  );
                }}
              </FormSpyFieldValues>

              <ClosingDateSection mainNameQA={mainNameQA} />

              <Box mb="16px">
                <Typography variant="h2" {...titleStyles(isSmallerScreen)}>
                  How would you like to work with us?
                </Typography>
                <Typography {...subTitleStyles(isSmallerScreen)}>
                  Select one of the options below
                </Typography>
                <Field
                  component={FormRadio}
                  validate={isDefined}
                  name="preferredBillingModel"
                  maxW={isSmallerScreen ? 'auto' : '355px'}
                  height="auto"
                  keyPrefix="preferredBillingModel"
                  w={isSmallerScreen ? '100%' : '33%'}
                  responsive={isSmallerScreen}
                  choices={[
                    {
                      label: 'Not Sure',
                      description:
                        'If you’re not sure which engagment type is right for you then select this option.',
                      value: 'Not Sure',
                      leftIcon: 'questionMarkWithoutBorder',
                      dataTestForRadio: `${mainNameQA}--preferredBillingModel`,
                    },
                    {
                      label: 'On a time & materials basis',
                      description:
                        'If you’re happy to consider proposals that are estimated on a time and material basis select this option.',
                      value: PROJECT_TYPES.T_AND_M,
                      leftIcon: 'clock2',
                      dataTestForRadio: `${mainNameQA}--preferredBillingModel`,
                    },
                    {
                      label: 'On a fixed price basis',
                      description:
                        'If you have a fixed budget and you have a a very clear brief then select this option',
                      leftIcon: 'lock',
                      value: PROJECT_TYPES.FIXED_PRICE,
                      dataTestForRadio: `${mainNameQA}--preferredBillingModel`,
                    },
                  ]}
                />
              </Box>

              <FormSpyFieldValues fieldNames={['preferredBillingModel']}>
                {({ preferredBillingModel }) => {
                  return (
                    preferredBillingModel === PROJECT_TYPES.FIXED_PRICE && (
                      <InfoAlert
                        mb={isSmallerScreen && '24px'}
                        label="We can only provide a fixed price proposal when we have sufficient detail in the brief "
                      />
                    )
                  );
                }}
              </FormSpyFieldValues>

              <Box my={isSmallerScreen ? '24px' : '56px'}>
                <Typography variant="h2" {...titleStyles(isSmallerScreen)}>
                  What’s your top priority?
                </Typography>
                <Typography {...subTitleStyles(isSmallerScreen)}>
                  Select one of the options below
                </Typography>
                <Field
                  component={FormRadio}
                  validate={isDefined}
                  name="topProjectPriority"
                  keyPrefix="topProjectPriority"
                  maxW={isSmallerScreen ? 'auto' : '355px'}
                  w={isSmallerScreen ? '100%' : '33%'}
                  responsive={isSmallerScreen}
                  choices={[
                    {
                      label: 'Getting the project completed for the best price',
                      leftIcon: 'usd',
                      value: TOP_PROJECT_PRIORITY.PRICE,
                      dataTestForRadio: `${mainNameQA}--topProjectPriority`,
                    },
                    {
                      label: 'Getting the project completed quickly',
                      leftIcon: 'clock',
                      specificStyle: { mx: '24px' },
                      value: TOP_PROJECT_PRIORITY.TIME,
                      dataTestForRadio: `${mainNameQA}--topProjectPriority`,
                    },
                    {
                      label:
                        'Getting the project produced to the very best quality',
                      leftIcon: 'favouriteStar',
                      value: TOP_PROJECT_PRIORITY.QUALITY,
                      dataTestForRadio: `${mainNameQA}--topProjectPriority`,
                    },
                  ]}
                />
              </Box>

              <Box mb={isSmallerScreen && '56px'}>
                <Typography variant="h2" {...titleStyles(isSmallerScreen)}>
                  Your Budget
                </Typography>
                <Typography {...subTitleStyles(isSmallerScreen)}>
                  How much are you looking to spend on this product
                </Typography>
                <Field
                  component={FormRadio}
                  validate={isDefined}
                  name="hasBudget"
                  keyPrefix="hasBudget"
                  maxW={isSmallerScreen ? 'auto' : '355px'}
                  w={isSmallerScreen && '100%'}
                  minW="260px"
                  responsive={isSmallerScreen}
                  choices={[
                    {
                      label: 'I have a budget',
                      value: true,
                      leftIcon: 'check',
                      dataTestForRadio: `${mainNameQA}--hasBudget`,
                    },
                    {
                      label: "I haven't a budget",
                      value: false,
                      leftIcon: 'close',
                      specificStyle: { ml: '24px' },
                      dataTestForRadio: `${mainNameQA}--hasBudget`,
                    },
                  ]}
                />

                <Condition when="hasBudget" is>
                  <Flex
                    mt={isSmallerScreen ? '24px' : '32px'}
                    flexWrap="wrap"
                    gap="12px 24px"
                  >
                    <Field
                      component={Select}
                      validate={required}
                      name="currency"
                      data-test-id={`${mainNameQA}--currency`}
                      label="Currency"
                      options={CURRENCY_OPTIONS}
                      styleProps={{ maxW: isSmallerScreen ? '100%' : '260px' }}
                    />
                    <Field
                      component={MaskedTextInput}
                      validate={composeValidators(
                        required,
                        mustBePositiveNumber,
                      )}
                      isAllowed={({ floatValue }) => floatValue <= 2000000000}
                      name="budgetFrom"
                      containerProps={budgetInputStyle(isSmallerScreen)}
                      label="From"
                      inputBoxAutoHeight
                      data-test-id={`${mainNameQA}--budgetFrom`}
                    />
                    <Field
                      component={MaskedTextInput}
                      validate={composeValidators(
                        required,
                        mustBePositiveNumber,
                        mustBeGreaterThan('budgetFrom', 'budget from value'),
                      )}
                      containerProps={budgetInputStyle(isSmallerScreen)}
                      name="budgetTo"
                      inputBoxAutoHeight
                      isAllowed={({ floatValue }) => floatValue <= 2000000000}
                      label="To"
                      data-test-id={`${mainNameQA}--budgetTo`}
                    />
                  </Flex>
                </Condition>
              </Box>
              <WizardFooter
                disableSubmit={submitSucceeded}
                onPrevClick={onPrevClick}
                isLast
                mainNameQA={mainNameQA}
                submitting={submitting}
              />
            </form>
          );
        }}
      />
      <WizardLeavingGuard isFormDirty />
    </>
  );
};

export default FinishStepForm;
