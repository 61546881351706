import { APP_ROUTES } from '@routes';
import React from 'react';
import TechStackForm from './TechStackForm';

const TechStack = ({
  initialValues,
  saveBrief,
  onSubmit,
  history,
  submit,
  briefId,
  mainNameQA,
}) => {
  const onPrevClick = async (values, formApi) => {
    await saveBrief(values, formApi);
    if (briefId) {
      history.push(APP_ROUTES.briefsEditBasics(briefId));
    } else {
      history.push(APP_ROUTES.briefsCreateBasics);
    }
  };

  return (
    <TechStackForm
      onPrevClick={onPrevClick}
      onSubmit={(values, form) => onSubmit(values, form)}
      initialValues={{ ...initialValues, step: 2 }}
      submit={submit}
      briefId={briefId}
      mainNameQA={mainNameQA}
    />
  );
};

export default TechStack;
