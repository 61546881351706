import { Flex } from '@chakra-ui/react';
import useMediaQuery from '@hooks/useMediaQuery';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AssignmentDropdown from './AssignmentDropdown';

const topBarStyles = isSmallerScreen => ({
  position: 'fixed',
  zIndex: '10',
  w: isSmallerScreen ? '100%' : 'calc(100% - 376px)',
  h: '56px',
  left: isSmallerScreen ? 0 : '376px',
  top: isSmallerScreen ? '63px' : '80px',
  bg: '#fff',
  borderBottom: '1px solid',
  borderColor: 'dark.mid',
  alignItems: 'center',
});

const TasksBulkBar = ({
  tasksStore: { selectedTasksCount, unselectAllTasks, reassignTask },
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  return (
    <Flex {...topBarStyles(isSmallerScreen)}>
      <AssignmentDropdown
        onChooseOption={reassignTask}
        popoverStyleProps={{ offset: [0, 16] }}
      >
        {({ onOpen, ref }) => (
          <Button
            variant="ghost"
            fontSize={15}
            leftIcon={{
              name: 'taskAssignment',
              fontSize: 24,
              mr: '0px',
            }}
            isDisabled={selectedTasksCount < 1}
            mx="18px"
            p="10px 0px"
            onClick={onOpen}
            ref={ref}
            data-test-id="reassign-tasks-bulk-button"
          >
            Reassign
          </Button>
        )}
      </AssignmentDropdown>
      {selectedTasksCount > 0 && (
        <>
          <Typography
            data-test-id="selected-tasks-counter"
            ml="auto"
            fontSize={15}
          >{`${selectedTasksCount} ${
            selectedTasksCount === 1 ? 'task' : 'tasks'
          } selected`}</Typography>
          <Button
            mx="20px"
            variant="ghost"
            p="12px 0px"
            fontSize={15}
            onClick={unselectAllTasks}
            data-test-id="unselect-tasks-bulk-button"
          >
            Unselect all
          </Button>
        </>
      )}
    </Flex>
  );
};

export default inject('tasksStore')(observer(TasksBulkBar));
