import { Box } from '@chakra-ui/react';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { isEmpty, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import CRCard from '../ChangeRequests/Single/CRCard';

const AllChangeRequests = ({
  crsStore: {
    fetchAllCRs,
    crs: {
      all: { data: crs, isLoading },
    },
  },
  usersStore: { isClient, isTeamAdmin, isTeamMember },
  projectId,
  projectsStore: { projectCurrency, project },
}) => {
  React.useEffect(() => {
    fetchAllCRs(projectId);
  }, []);

  return (
    <>
      <Box maxW="1280px" px="40px" w="100%" mx="auto" pb="60px" mt="32px">
        <Typography variant="h1" my="40px">
          All change requests
        </Typography>
        {isLoading && <Spinner />}
        {map(crs, cr => (
          <CRCard
            projectCurrency={projectCurrency}
            primaryPTOS={project.primaryPTOS}
            cr={cr}
            key={`all-cr-card-${cr.id}`}
            isClient={isClient}
            isTeamMember={isTeamMember}
            isTeamAdmin={isTeamAdmin}
          />
        ))}
        {isEmpty(crs) && !isLoading && (
          <Typography variant="h2" my="40px">
            There are no change requests here yet.
          </Typography>
        )}
      </Box>
    </>
  );
};

export default inject(
  'paymentsStore',
  'crsStore',
  'projectsStore',
  'usersStore',
)(observer(AllChangeRequests));
