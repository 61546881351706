import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Box, Flex } from '@chakra-ui/react';
import Spinner from '@styles/Spinner';
import ClientCreateForm from '@pages/UserCreate/ClientCreateForm';
import SupplierCreateForm from '@pages/UserCreate/SupplierCreateForm';
import { APP_ROUTES } from '@routes';
import WhiteCard from '@components/WhiteCard';

const TeamsCreate = ({
  teamsStore: {
    createClient,
    createSupplier,
    client,
    supplier,
    fetchClient,
    fetchSupplier,
    isLoading,
    updateClient,
    updateSupplier,
    removeCurrencyFromSupplier,
    setCurrencyAsPrimaryForSupplier,
  },
  usersStore: { isProfileLoaded },
  history,
  isSupplierRoute,
  match: {
    params: { id },
  },
}) => {
  useEffect(() => {
    if (isSupplierRoute) {
      fetchSupplier(id);
    } else {
      fetchClient(id);
    }
  }, []);

  return (
    <Box maxW="1201px" h="100%" w="100%" mx="auto" minH="calc(100vh - 135px)">
      {isLoading || !isProfileLoaded ? (
        <Spinner />
      ) : (
        <Flex
          px="32px"
          pt="39px"
          pb="50px"
          direction="column"
          align="flex-start"
        >
          {isSupplierRoute ? (
            <SupplierCreateForm
              initialValues={{
                currency: 'GBP',
                location: 'UK',
                noVat: false,
                invoiceNumberingTimespan: 'Monthly',
              }}
              removeCurrencyFromSupplier={removeCurrencyFromSupplier}
              setCurrencyAsPrimaryForSupplier={setCurrencyAsPrimaryForSupplier}
              supplier={supplier}
              history={history}
              onSubmit={values => {
                if (!values.id) {
                  return createSupplier({
                    values,
                    onSuccess: s =>
                      history.push(APP_ROUTES.teams.supplierById(s.id)),
                  });
                }
                return updateSupplier({
                  supplierId: values.id,
                  values,
                  onSuccess: () =>
                    history.push(APP_ROUTES.teams.supplierById(values.id)),
                });
              }}
            />
          ) : (
            <WhiteCard mb="60px" mt="8px" p="40px">
              <ClientCreateForm
                initialValues={{
                  currency: 'GBP',
                  location: 'UK',
                  noVat: false,
                }}
                client={client}
                onSubmit={values => {
                  if (!values.id) {
                    return createClient({
                      values,
                      onSuccess: () => history.push(APP_ROUTES.teams.clients),
                    });
                  }
                  return updateClient({
                    clientId: values.id,
                    values,
                    onSuccess: () =>
                      history.push(APP_ROUTES.teams.clientById(values.id)),
                  });
                }}
              />
            </WhiteCard>
          )}
        </Flex>
      )}
    </Box>
  );
};
export default inject('teamsStore', 'usersStore')(observer(TeamsCreate));
