import Modal from '@components/Modal';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import Typography from '@styles/Typography';
import React from 'react';
import ClientLeadCreateForm from './ClientLeadForm';

const ClientCreateLeadModal = ({
  isOpen,
  onClose,
  onSubmit,
  initialValues,
  ...props
}) => {
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styleProps={{
        minW: isSmallerScreen ? 'auto' : '720px',
        p: isSmallerScreen && '20px 28px',
      }}
    >
      <Typography variant="h2" mb="32px">
        Add new client lead
      </Typography>
      <ClientLeadCreateForm
        onClose={onClose}
        onSubmit={onSubmit}
        initialValues={initialValues}
        {...props}
      />
    </Modal>
  );
};

export default ClientCreateLeadModal;
