import { NEW_PROPOSAL_STATUSES, SOW_STATUSES } from '@app/constants';
import Icon from '@components/Icon';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { find } from 'lodash-es';
import React from 'react';

const withDeliveryPartnerProps = {
  borderColor: 'orange.solid',
  bg: 'orange.light',
  color: 'orange.solid',
};

const withDeazyProps = {
  borderColor: 'aqua.solid',
  bg: 'aqua.light',
  color: 'aqua.solid',
};

const withClientProps = {
  borderColor: 'blue.solid',
  bg: 'primary.100',
  altMobileBg: 'primary.200',
  color: 'blue.solid',
};

const wonProps = {
  borderColor: 'green.500',
  bg: 'green.light',
  color: 'green.500',
};

const lostProps = {
  borderColor: 'red.500',
  bg: 'red.light',
  color: 'red.500',
};

const signedProps = {
  borderColor: 'green.500',
  bg: 'green.light',
  color: 'green.500',
};

const draftProps = {
  borderColor: 'gray.300',
  bg: 'gray.100',
  color: 'gray.300',
};

export const NEW_PROPOSAL_STATUSES_OPTIONS = [
  {
    label: NEW_PROPOSAL_STATUSES.WITH_DP,
    altLabel: 'With you',
    value: NEW_PROPOSAL_STATUSES.WITH_DP,
    ...withDeliveryPartnerProps,
  },
  {
    label: NEW_PROPOSAL_STATUSES.WITH_DEAZY,
    value: NEW_PROPOSAL_STATUSES.WITH_DEAZY,
    ...withDeazyProps,
  },
  {
    label: NEW_PROPOSAL_STATUSES.WITH_CLIENT,
    value: NEW_PROPOSAL_STATUSES.WITH_CLIENT,
    ...withClientProps,
  },
  {
    label: NEW_PROPOSAL_STATUSES.WON,
    value: NEW_PROPOSAL_STATUSES.WON,
    ...wonProps,
  },
  {
    label: NEW_PROPOSAL_STATUSES.LOST,
    value: NEW_PROPOSAL_STATUSES.LOST,
    ...lostProps,
  },
];

export const SOW_STATUSES_OPTIONS = [
  {
    label: SOW_STATUSES.DRAFT,
    value: SOW_STATUSES.DRAFT,
    ...draftProps,
  },
  {
    label: SOW_STATUSES.SIGNED,
    value: SOW_STATUSES.SIGNED,
    ...signedProps,
  },
];

const getCurrentOptionByStatus = (array, status) => {
  return find(array, opt => opt.value === status);
};

const StatusDropdownTrigger = React.forwardRef(
  (
    {
      mainNameQA,
      qaValue = '',
      onOpen,
      currentOption,
      isReadOnly = false,
      buttonProps,
      buttonDescriptionProps,
      status,
      isSOW,
      useAltLabel,
    },
    ref,
  ) => {
    const currentOptionObj =
      currentOption ||
      getCurrentOptionByStatus(
        isSOW ? SOW_STATUSES_OPTIONS : NEW_PROPOSAL_STATUSES_OPTIONS,
        status,
      );

    return (
      <Button
        ref={ref}
        display="flex"
        alignItems="flex-start"
        pointerEvents={isReadOnly ? 'none' : 'default'}
        mr="8px"
        padding={!isReadOnly ? '4px 12px 4px 16px' : '4px 16px'}
        borderColor={currentOptionObj.borderColor}
        bg={currentOptionObj.bg}
        color={currentOptionObj.color}
        variant="outline"
        borderWidth="1px"
        maxHeight="24px"
        onClick={onOpen}
        data-test-id={mainNameQA && `${mainNameQA}--${qaValue}`}
        rightIcon={
          !isReadOnly ? (
            <Icon
              name="chevronDown"
              fontSize={12}
              ml="8px"
              position="relative"
              top="-1px"
            />
          ) : null
        }
        {...buttonProps}
      >
        <Typography
          variant="caption"
          lineHeight="15px"
          {...buttonDescriptionProps}
        >
          {useAltLabel
            ? currentOptionObj.altLabel || currentOptionObj.label
            : currentOptionObj.label}
        </Typography>
      </Button>
    );
  },
);
export default StatusDropdownTrigger;
