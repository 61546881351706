import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import Textarea from '@components/forms/Textarea';
import { Field, Form } from 'react-final-form';
import FormError from '@components/forms/FormError';
import WhiteCard from '@components/WhiteCard';
import Button from '@styles/Button';
import Condition from '@components/forms/Condition';
import { SectionTitle } from './components';
import NotesList from './NotesList';

const CommentTextArea = ({
  isLoading,
  onAfterBlur,
  onCancel,
  isSmallerScreen,
  mainNameQA,
  ...props
}) => (
  <Box
    position="relative"
    border="2px solid"
    borderColor="blue.solid"
    borderRadius="8px"
    pb={isSmallerScreen ? '130px' : '80px'}
    w="100%"
  >
    <Textarea
      {...props}
      onBlur={() => {
        props.input.onBlur();
        if (onAfterBlur) {
          onAfterBlur();
        }
      }}
      inputProps={{ rows: 2 }}
      data-test-id={`${mainNameQA}--commentArea`}
    />
    <Flex
      position="absolute"
      bottom="22px"
      left="16px"
      {...(isSmallerScreen && {
        flexDir: 'column',
        w: 'calc(100% - 32px)',
      })}
    >
      <Button
        mr="12px"
        isLoading={isLoading}
        type="submit"
        data-test-id={`${mainNameQA}--commentButton`}
        {...(isSmallerScreen && { w: '100%', mb: '12px' })}
      >
        Comment
      </Button>
      <Button
        variant="outline"
        data-test-id={`${mainNameQA}--cancelCommentButton`}
        onClick={onCancel}
        {...(isSmallerScreen && { w: '100%' })}
      >
        Cancel
      </Button>
    </Flex>
  </Box>
);

const validate = ({ description = '' }) => {
  if (!description) {
    return {
      noteDescription: 'Note cannot be empty',
    };
  }
  if (description.length > 10000) {
    return {
      noteDescription: 'Must be shorter or equal 10 000 characters.',
    };
  }
  return {};
};

const NotesForm = ({
  isReadOnly,
  isAdminOrDL,
  initialValues,
  onSubmit,
  notes,
  isSmallerScreen,
  mainNameQA,
}) => {
  return (
    <WhiteCard {...(isSmallerScreen && { px: 0, mt: 0, pt: 0 })}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({
          handleSubmit,
          submitting,
          values,
          form: { change, focus, blur },
        }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <SectionTitle mt="0px" mb="0px">
                Notes
              </SectionTitle>
              <NotesList notes={notes} mainNameQA={mainNameQA} />
              {isAdminOrDL && (
                <Flex w="100%" alignItems="flex-start" mt="22px">
                  <Flex w="100%" flexDirection="column" alignItems="flex-start">
                    <Condition when="showCommentBox" is>
                      <Field
                        component={CommentTextArea}
                        mainNameQA={mainNameQA}
                        isSmallerScreen={isSmallerScreen}
                        name="description"
                        isLoading={submitting}
                        placeholder="Write comment here..."
                        onCancel={() => {
                          change('showCommentBox', false);
                          change('description', '');
                        }}
                        onAfterBlur={() => {
                          focus('noteDescription');
                          blur('noteDescription');
                        }}
                      />
                      <Field name="noteDescription">
                        {({ meta }) => <FormError mt="12px" meta={meta} />}
                      </Field>
                      <Box mt="24px" />
                    </Condition>
                    <Button
                      data-test-id={`${mainNameQA}--addNewNote`}
                      onClick={() => {
                        change('showCommentBox', true);
                        change('note', '');
                      }}
                      isDisabled={values.showCommentBox || isReadOnly}
                      variant="outline"
                      {...(isSmallerScreen && { w: '100%' })}
                    >
                      Add a new note
                    </Button>
                  </Flex>
                </Flex>
              )}
            </form>
          );
        }}
      />
    </WhiteCard>
  );
};

export default NotesForm;
