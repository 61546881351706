import { endsWith, get, pick } from 'lodash-es';
import { API } from '@app/api';
import { stripObj } from '@utils';
import { IS_DEV, IS_STAGING } from '@app/constants';
import AuthStore from './authStore';
import BriefsStore from './briefsStore';
import ProjectsStore from './projectsStore';
import ToastsStore from './toastsStore';
import UsersStore from './usersStore';
import UserOrchestrationStore from './userOrchestrationStore';
import TeamsStore from './teamsStore';
import XeroStore from './xeroStore';
import InvoicesStore from './invoicesStore';
import routerStore from './routerStore';
import AllocationsStore from './allocationsStore';
import PaymentsStore from './paymentsStore';
import CRsStore from './crsStore';
import QuestionsStore from './questionsStore';
import ResourcesStore from './resourcesStore';
import DocumentsStore from './documentsStore';
import ProposalsStore from './proposalsStore';
import SettingsStore from './settingsStore';
import SowsStore from './sowsStore';
import MilestonesStore from './milestonesStore';
import MailingStore from './mailingStore';
import AppStore from './appStore';
import RatesStore from './ratesStore';
import ProposalQueriesStore from './proposalQueriesStore';
import NationalHolidaysStore from './nationalHolidaysStore';
import TasksStore from './tasksStore';
import SowPaymentScheduleStore from './sowPaymentScheduleStore';
import StaffProfileStore from './staffProfileStore';
import PublicPagesStore from './publicPagesStore';
import ProposalCandidatesStore from './proposalCandidatesStore';
import BriefAssignmentsStore from './briefAssignmentsStore';
import ClientsStore from './clientsStore';

class RootStore {
  constructor() {
    this.routerStore = routerStore;
    this.toastsStore = new ToastsStore();
    this.authStore = new AuthStore({
      API,
      history: this.routerStore.history,
      ...this,
    });
    this.xeroStore = new XeroStore({ API, ...this });
    this.settingsStore = new SettingsStore({ API, ...this });
    this.usersStore = new UsersStore({
      API,
      history: this.routerStore.history,
      ...this,
    });
    this.userOrchestrationStore = new UserOrchestrationStore({
      API,
      history: this.routerStore.history,
      ...this,
    });
    this.ratesStore = new RatesStore({ API, ...this });
    this.briefsStore = new BriefsStore({ API, ...this });
    this.teamsStore = new TeamsStore({ API, ...this });
    this.projectsStore = new ProjectsStore({ API, ...this });
    this.invoicesStore = new InvoicesStore({ API, ...this });
    this.allocationsStore = new AllocationsStore({ API, ...this });
    this.paymentsStore = new PaymentsStore({ API, ...this });
    this.crsStore = new CRsStore({ API, ...this });
    this.questionsStore = new QuestionsStore({ API, ...this });
    this.resourcesStore = new ResourcesStore({ API, ...this });
    this.documentsStore = new DocumentsStore({ API, ...this });
    this.proposalsStore = new ProposalsStore({ API, ...this });
    this.proposalQueriesStore = new ProposalQueriesStore({ API, ...this });
    this.sowPaymentScheduleStore = new SowPaymentScheduleStore({
      API,
      ...this,
    });
    this.tasksStore = new TasksStore({ API, ...this });
    this.sowsStore = new SowsStore({ API, ...this });
    this.milestonesStore = new MilestonesStore({ API, ...this });
    this.mailingStore = new MailingStore({ API, ...this });
    this.appStore = new AppStore({
      API,
      history: this.routerStore.history,
      ...this,
    });
    this.nationalHolidaysStore = new NationalHolidaysStore({ API, ...this });
    this.briefAssignmentsStore = new BriefAssignmentsStore({ API, ...this });
    this.staffProfileStore = new StaffProfileStore({ API, ...this });
    this.publicPagesStore = new PublicPagesStore({ API, ...this });
    this.clientsStore = new ClientsStore({ API, ...this });
    this.proposalCandidatesStore = new ProposalCandidatesStore({
      API,
      ...this,
    });

    this.interceptRequest();
    this.routerStore.watchPrevLocation();
    this.routerStore.redirectFromOldPlugAndPlayRoute();
  }

  interceptRequest = () => {
    API.apiInstance.interceptors.response.use(
      response => {
        this.checkResponse(response);
        return {
          ...response,
          data: stripObj(response.data),
        };
      },
      error => {
        this.checkResponse(error.response);
        if (error.response && error.response.status === 401) {
          if (this.appStore.featureFlags['uo-frontegg-auth']) {
            this.authStore.fronteggSignOut();
          } else {
            this.authStore.signOut();
          }
          this.toastsStore.showError({
            title: 'An error occured.',
            description: 'Your session is probably expired',
          });
        }
        const isTasksCountEndpoint =
          error.config.url === '/actionNotifications/count';
        if (
          error.response &&
          error.response.status === 404 &&
          !isTasksCountEndpoint &&
          !endsWith(error.config.url, 'unexclusive')
        ) {
          this.appStore.setIs404(true);

          return Promise.reject((error.response && error.response.data) || {});
        }

        if (
          error.response &&
          error.response.status >= 500 &&
          !isTasksCountEndpoint
        ) {
          this.appStore.setIs500(true);

          return Promise.resolve({ data: {} });
        }

        if (
          error.response &&
          error.response.status === 403 &&
          !isTasksCountEndpoint
        ) {
          this.authStore.setIsForbidden(true);

          // return Promise.resolve({ data: {} });
        }
        return Promise.reject((error.response && error.response.data) || {});
      },
    );
  };

  checkResponse = response => {
    if (
      response &&
      response.config &&
      (response.config.method === 'post' || response.config.method === 'put') &&
      this.usersStore.isAdminOrDL &&
      this.usersStore.isErrorReportingEnabled
    ) {
      let payload = get(response, 'config.data', '{}');
      payload = payload instanceof FormData ? '{}' : payload;

      this.toastsStore.setRequestError({
        request: {
          ...pick(response.config, [
            'status',
            'baseURL',
            'method',
            'headers',
            'url',
          ]),
          data: JSON.parse(payload),
        },
        response: pick(response, ['data', 'status', 'statusText']),
      });
    }
  };
}

const rootStore = new RootStore();

if (IS_STAGING || IS_DEV) {
  window.rootStore = rootStore;
}

export default rootStore;
