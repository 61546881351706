import { NEW_PROPOSAL_STATUSES } from '@app/constants';
import { Box, Divider, Stack } from '@chakra-ui/react';
import RadioGroupField from '@components/forms/RadioGroupField';
import useMediaQuery from '@hooks/useMediaQuery';
import Textarea from '@components/forms/_common/Textarea';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { mustBeShorterOrEqual } from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';

const ChangeStatusCommentModal = ({
  isReadOnly,
  targetStatus,
  isOpen,
  onClose,
  onSubmit,
  actualEstimateCost,
  hightCost,
  fullRangeCost,
  initialValues,
  isPlugAndPlay,
  mainNameQA,
}) => {
  const dividerProps = {
    borderColor: 'rgba(217, 167, 235, 0.5)',
    my: '24px',
  };
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styleProps={
        isSmallerScreen
          ? { p: '24px 16px 48px', margin: 'auto 0px 0px' }
          : { maxW: '610px' }
      }
    >
      <Typography variant="h2" mb="32px" textAlign="center">
        Change Status to ‘{targetStatus}’
      </Typography>
      <Typography textAlign="center" mb="32px">
        Are you sure you want to change status to ‘{targetStatus}’? Supplier
        can’t make changes to the proposal.
      </Typography>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Field
                component={Textarea}
                name="statusMessage"
                rows={5}
                counter={10000}
                placeholder="Tell Delivery Partner what is happening next"
                validate={mustBeShorterOrEqual(10000)}
                inputProps={{ disabled: isReadOnly }}
                mt="32px"
                mb={
                  targetStatus === NEW_PROPOSAL_STATUSES.WITH_CLIENT &&
                  !isPlugAndPlay
                    ? '28px'
                    : '30px'
                }
              />
              <Typography variant="text2">
                Leave this blank if you want to continue assigning without
                sending a message
              </Typography>
              {targetStatus === NEW_PROPOSAL_STATUSES.WITH_CLIENT &&
                !isPlugAndPlay && (
                  <Box>
                    <Divider {...dividerProps} />
                    <Typography variant="h4" textAlign="center" mb="24px">
                      Share optional details
                    </Typography>
                    <Field
                      label="Cost"
                      name="visibleCosts"
                      component={RadioGroupField}
                      alignItems="flex-start"
                      mainNameQA={mainNameQA}
                      choices={[
                        {
                          label: 'Actual estimate',
                          value: 'Actual estimate',
                          subLabel: actualEstimateCost,
                        },
                        {
                          label: 'High cost only',
                          value: 'High cost only',
                          subLabel: hightCost,
                        },
                        {
                          label: 'Full range',
                          value: 'Full range',
                          subLabel: fullRangeCost,
                        },
                      ]}
                    />
                    <Box pt="24px" />
                    <Field
                      label="Feature Breakdown"
                      name="featuresBreakdown"
                      component={RadioGroupField}
                      alignItems="flex-start"
                      choices={[
                        {
                          label: 'Hidden costs',
                          value: 'Hidden costs',
                        },
                        {
                          label: 'Costs per feature',
                          value: 'Costs per feature',
                        },
                        {
                          label: 'Full breakdown',
                          value: 'Full breakdown',
                        },
                      ]}
                    />
                  </Box>
                )}
              <Stack spacing={3} mt="40px">
                <Button isLoading={submitting} type="submit">
                  Submit
                </Button>
                <Button
                  variant="outline"
                  isDisabled={submitting}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Stack>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default ChangeStatusCommentModal;
