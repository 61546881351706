import { Button as ChakraButton } from '@chakra-ui/react';
import Icon from '@components/Icon';
import { has } from 'lodash-es';
import React from 'react';

const BASE_STYLES = ({ isIconButton }) => ({
  h: '40px',
  fontFamily: 'WS',
  fontWeight: 400,
  lineHeight: 1,
  color: 'white',
  fontSize: '15px',
  p: isIconButton ? '14px' : '0px 24px 0px 24px',
  borderRadius: '8px',
  _disabled: {
    bg: 'gray.200',
    cursor: 'not-allowed',
  },
});

const VARIANTS = {
  primary: {
    bg: 'primary.400',
    _hover: {
      bg: 'primary.300',
    },
    _active: {
      bg: 'primary.500',
    },
  },
  secondary: {
    bg: 'orange.solid',
    _hover: {
      bg: 'orange.mid',
    },
    _active: {
      bg: '#DD7300',
    },
  },
  outline: {
    color: 'primary.400',
    border: '2px solid',
    borderColor: 'primary.400',
    bg: 'white',
    _hover: {
      bg: 'primary.100',
    },
    _active: {
      bg: 'primary.300',
    },
    _disabled: {
      borderColor: 'gray.200',
      color: 'gray.200',
      cursor: 'not-allowed',
      bg: 'white',
    },
  },
  ghost: {
    bg: 'transparent',
    color: 'primary.400',
    _hover: {
      color: 'primary.300',
    },
    _active: {
      color: 'primary.500',
    },
    _disabled: {
      color: 'gray.200',
      cursor: 'not-allowed',
    },
  },
  warning: {
    bg: 'red.solid',
    _hover: {
      bg: 'red.mid',
    },
    _active: {
      bg: 'red.mid',
    },
  },
  unstyled: {
    bg: 'transparent',
    _hover: {
      opacity: 0.7,
    },
    _active: {},
    _disabled: {
      cursor: 'not-allowed',
    },
  },
  green: {
    bg: 'green.light',
    color: 'green.solid',
    _hover: {
      bg: 'green.solid',
      color: '#fff',
    },
    _active: {
      bg: 'green.mid',
    },
    _disabled: {
      color: 'dark.mid',
      bg: 'dark.light',
      cursor: 'not-allowed',
    },
  },
};

const Button = React.forwardRef(
  (
    {
      variant = 'primary',
      leftIcon: lIcon,
      iconButton: iButton,
      children,
      size,
      ...props
    },
    ref,
  ) => {
    const styles = has(VARIANTS, variant)
      ? VARIANTS[variant]
      : VARIANTS.primary;

    const leftIcon = has(lIcon, 'name') ? (
      <Icon mr="9px" ml="-8px" mb="2px" fontSize={14} {...lIcon} />
    ) : null;

    const iconButton = has(iButton, 'name') ? (
      <Icon fontSize={14} {...iButton} />
    ) : null;

    return (
      <ChakraButton
        {...BASE_STYLES({ isIconButton: !!iconButton })}
        leftIcon={leftIcon}
        {...(props.isDisabled ? { ...styles, _hover: {} } : styles)}
        {...(size === 'lg' && { h: '48px' })}
        {...props}
        ref={ref}
      >
        {iconButton || children}
      </ChakraButton>
    );
  },
);

export default Button;
