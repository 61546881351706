import React, { useState } from 'react';
import { APP_ROUTES } from '@routes';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from '@chakra-ui/react';
import { useRouteMatch } from 'react-router';
import { MAIN_NAMES_QA } from '@app/constants';
import {
  TA_MATCH_OPTIONS,
  PB_MATCH_OPTIONS,
} from './PerfectDPMatchForm/matchOptions';
import MatchingCriteriaBox from './MatchingCriteriaBox';
import PerfectDPMatchForm from './PerfectDPMatchForm';
import DiscoverDPs from './DiscoverDPs';
import MatchingFilters from './MatchingFilters';

const FindDPs = ({ briefsStore: { brief } }) => {
  const mainNameQA = MAIN_NAMES_QA.BRIEF_FIND_DP;

  const [isInitialStep, setIsInitialStep] = useState(
    !(brief.ppBriefQuestions || brief.briefQuestions),
  );

  const hideInitialStep = () => setIsInitialStep(false);
  const showInitialStep = () => setIsInitialStep(true);

  const isProjectBuild = useRouteMatch(APP_ROUTES.briefById());
  const matchOptions = React.useMemo(() => {
    return isProjectBuild ? PB_MATCH_OPTIONS : TA_MATCH_OPTIONS;
  }, [isProjectBuild]);

  return (
    <Flex pl={[0, 0, 0, '136px']}>
      {isInitialStep ? (
        <PerfectDPMatchForm
          matchOptions={matchOptions}
          onAfterSubmit={hideInitialStep}
          mainNameQA={mainNameQA}
        />
      ) : (
        <DiscoverDPs mainNameQA={mainNameQA} />
      )}

      <Box
        position="fixed"
        bottom={['88px', '88px', '88px', '0px']}
        left={[0, 0, 0, '92px']}
        zIndex={1301}
      >
        <Box position="relative">
          <MatchingCriteriaBox
            isDisabled={isInitialStep}
            matchOptions={matchOptions}
            onEdit={showInitialStep}
          />
          <MatchingFilters isDisabled={isInitialStep} />
        </Box>
      </Box>
    </Flex>
  );
};

export default inject('briefsStore')(observer(FindDPs));
