import { Box, Flex, useBoolean } from '@chakra-ui/react';
import ErrorSpy from '@components/forms/ErrorSpy';
import TextInput from '@components/forms/TextInput';
import useMediaQuery from '@hooks/useMediaQuery';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import {
  find,
  flatten,
  forEach,
  get,
  isEmpty,
  map,
  reject,
  some,
  trim,
} from 'lodash-es';
import { inject } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import Button from '@styles/Button';
import { CheckButton } from '@components/BriefActions';
import { Field, Form } from 'react-final-form';
import { StickyFooter } from '@components/StickyFooterCostBox';
import { required } from '@utils/formValidators';
import RolesTable from './RolesTable';
import ProposalUpdateReasonModal from './ProposalUpdateReasonModal';

export const CardWithTitle = ({ title, children }) => (
  <WhiteCard
    px="32px"
    py="40px"
    boxShadow="none"
    border="1px solid"
    borderColor="dark.mid"
  >
    <Typography variant="h2" mb="26px">
      {title}
    </Typography>
    {children}
  </WhiteCard>
);

const validate = ({ resources }) => {
  const errors = { candidates: {} };

  const hasNonEmptyRows = some(resources, r =>
    find(
      r.candidates,
      c =>
        (c.file || c.plugPlayProposalCv || c?.devProfile?.id) &&
        c.rate &&
        c.name &&
        c.startDate &&
        c.location &&
        c.typeOfPlugPlayResource,
    ),
  );
  if (!hasNonEmptyRows) {
    errors.table = 'Please fill in at least one role.';
  }

  const allCandidates = reject(
    flatten(map(resources, r => r.candidates)),
    c =>
      !c ||
      (!c.file &&
        !c.comments &&
        !c.rate &&
        !c.location &&
        !c.startDate &&
        !c.name &&
        !c.typeOfPlugPlayResource),
  );

  forEach(allCandidates, (c, cIdx) => {
    errors.candidates[c.rowId] = {};

    if (!c.name) {
      errors.candidates[c.rowId].name = 'Candidate name is required.';
    }

    if (
      c.name &&
      find(allCandidates, (candidate, candidateIdx) => {
        return (
          candidate?.name &&
          candidateIdx < cIdx &&
          trim((c.name || '').toLowerCase()) ===
            trim((candidate.name || '').toLowerCase())
        );
      })
    ) {
      errors.candidates[c.rowId].name = 'Candidate name is duplicated.';
    }

    if (!c.rate) {
      errors.candidates[c.rowId].rate = 'Rate is required.';
    }

    if (!c.startDate) {
      errors.candidates[c.rowId].startDate = 'Start date is required.';
    }

    if (!c.location) {
      errors.candidates[c.rowId].locaiton = 'Location is required.';
    }

    if (!c.file && !c.devProfile?.id) {
      errors.candidates[c.rowId].file = 'CV is required.';
    }
    if (c.file && c.file.size / 1024 / 1024 > 3) {
      errors.candidates[c.rowId].file = 'CV file size cannot exceed 3MB.';
    }

    if (c.comments && c.comments.length > 500) {
      errors.candidates[c.rowId].comments =
        'Comment cannot exceed 500 characters.';
    }
    if (c.location && c.location.length > 100) {
      errors.candidates[c.rowId].location =
        'Location cannot exceed 100 characters.';
    }
    if (c.name && c.name.length > 100) {
      errors.candidates[c.rowId].name = 'Name cannot exceed 100 characters.';
    }
    if (c.rate && +c.rate > 999.99) {
      errors.candidates[c.rowId].rate = 'Rate cannot exceed 999.99.';
    }
    if (isEmpty(errors.candidates[c.rowId])) {
      delete errors.candidates[c.rowId];
    }
  });
  if (isEmpty(errors.candidates)) {
    delete errors.candidates;
  }
  return errors;
};

const PlugAndPlayProposalForm = ({
  isReadOnly,
  supplierId,
  setSubmit,
  initialValues,
  briefsStore: { brief },
  onDiscard,
  onSubmit,
  ratesStore: { fetchResourceRates },
  isEditingMode,
  mainNameQA,
}) => {
  React.useEffect(() => {
    fetchResourceRates(supplierId);
  }, [supplierId]);

  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const [isUpdateReasonModalOpen, setIsUpdateReasonModalOpen] = useBoolean();

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({
          handleSubmit,
          values,
          errors,
          form,
          touched,
          submitFailed,
        }) => {
          setSubmit(handleSubmit);

          const shouldShowCandidateError = (rIdx, cIdx, fieldName) => {
            if (
              !touched[`resources[${rIdx}].candidates[${cIdx}].${fieldName}`] &&
              !submitFailed &&
              !get(
                values,
                `resources[${rIdx}].candidates[${cIdx}].${fieldName}`,
              )
            ) {
              return false;
            }
            return true;
          };

          const onAddRole = resourceIdx => {
            form.change(
              'resources',
              map(values.resources, (res, idx) => ({
                ...res,
                ...(idx === resourceIdx && {
                  candidates: [
                    ...(res.candidates || []),
                    { rowId: nanoid(10), rate: null, briefResource: res },
                  ],
                }),
              })),
            );
          };

          const onSaveComment = (resourceIdx, candidateIdx, comment) => {
            form.change(
              `resources[${resourceIdx}].candidates[${candidateIdx}].comments`,
              comment,
            );
          };

          const onDeleteRole = (resourceIdx, candidateIdx) => {
            form.change(
              'resources',
              map(values.resources, (res, rIdx) => ({
                ...res,
                ...(resourceIdx === rIdx && {
                  candidates: reject(
                    res.candidates,
                    (_, cIdx) => cIdx === candidateIdx,
                  ),
                }),
              })),
            );
          };

          const displayErrors = {
            candidateErrors: errors.candidates || {},
            tableError: submitFailed ? errors.table : undefined,
          };

          const submitButtonLabel = React.useMemo(() => {
            if (isEditingMode) {
              return 'Confirm proposal updates';
            }
            if (!isEmpty(brief?.supplierProposal?.proposal)) {
              return 'Re-submit proposal';
            }
            return 'Submit proposal';
          }, [isEditingMode, isReadOnly, brief?.supplierProposal?.proposal]);

          return (
            <>
              <form onSubmit={handleSubmit} autoComplete="off">
                <ErrorSpy />
                <Box position="relative" maxW="358px" w="100%">
                  <Field
                    name="overriddenCurrency"
                    label="Project currency"
                    component={TextInput}
                    data-test-id={`${mainNameQA}--overriddenCurrency`}
                    isDisabled
                    validate={required}
                  />
                </Box>
                <Typography
                  variant="h2"
                  mb="12px"
                  mt={['15px', '15px', '15px', '32px']}
                >
                  Roles
                </Typography>
                <Typography mb="24px">
                  See requested roles below. Add suitable candidate(s) you’d
                  like to add to the proposal under each.
                </Typography>
                <RolesTable
                  isSmallerScreen={isSmallerScreen}
                  formApi={form}
                  isReadOnly={isReadOnly}
                  resources={values.resources}
                  isEditingMode={isEditingMode}
                  onAddRole={onAddRole}
                  onSaveComment={onSaveComment}
                  onDeleteRole={onDeleteRole}
                  errors={displayErrors}
                  shouldShowCandidateError={shouldShowCandidateError}
                  mainNameQA={mainNameQA}
                />

                {!isReadOnly && !isSmallerScreen && (
                  <StickyFooter display="flex" h="80px" justifyContent="center">
                    <Flex
                      w="100%"
                      justifyContent="flex-end"
                      ml="235px"
                      maxW="1336px"
                    >
                      {((brief.isPlugAndPlay &&
                        isEmpty(brief?.supplierProposal?.proposal)) ||
                        isEditingMode) && (
                        <Button
                          mr="16px"
                          size="lg"
                          onClick={onDiscard}
                          variant="outline"
                          isDisabled={brief.isClosed}
                          data-test-id={`${mainNameQA}--discard`}
                        >
                          Discard
                        </Button>
                      )}
                      <CheckButton
                        {...(isEditingMode && isEmpty(errors)
                          ? {
                              type: 'button',
                              onClick: setIsUpdateReasonModalOpen.on,
                            }
                          : { type: 'submit' })}
                        isLoading={brief.isCreatingProposal}
                        data-test-id={`${mainNameQA}--${
                          isEmpty(brief?.supplierProposal?.proposal)
                            ? 'submit'
                            : 'resubmit'
                        }`}
                      >
                        {submitButtonLabel}
                      </CheckButton>
                    </Flex>
                  </StickyFooter>
                )}
                {isSmallerScreen && !isReadOnly && (
                  <Flex flexDir="column">
                    <CheckButton
                      {...(isEditingMode && isEmpty(errors)
                        ? {
                            type: 'button',
                            onClick: setIsUpdateReasonModalOpen.on,
                          }
                        : { type: 'submit' })}
                      mt="40px"
                      w="100%"
                      isLoading={brief.isCreatingProposal}
                    >
                      {submitButtonLabel}
                    </CheckButton>
                    <Button
                      mt="12px"
                      w="100%"
                      size="lg"
                      onClick={onDiscard}
                      variant="outline"
                    >
                      Discard
                    </Button>
                  </Flex>
                )}
              </form>
              <ProposalUpdateReasonModal
                isOpen={isUpdateReasonModalOpen}
                onClose={setIsUpdateReasonModalOpen.off}
                onSubmit={async ({ archivingReason }) => {
                  form.change('archivingReason', archivingReason);
                  await onSubmit({ ...values, archivingReason });
                  setIsUpdateReasonModalOpen.off();
                }}
              />
            </>
          );
        }}
      />
    </>
  );
};

export default inject(
  'briefsStore',
  'ratesStore',
  'toastsStore',
)(PlugAndPlayProposalForm);
