import { Box, Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import styled from '@emotion/styled';
import Tooltip from '@styles/Tooltip';
import Typography from '@styles/Typography';
import React from 'react';

const GrayScaledBox = styled(({ isGrayScaled, ...props }) => (
  <Flex {...props} />
))`
  ${({ isGrayScaled }) =>
    isGrayScaled &&
    `
    filter: grayscale(100%);
    opacity: 0.5;`}
`;

const CountBadge = props => {
  const { label, count, iconName, iconProps, ...rest } = props;
  return (
    <Tooltip label={label}>
      <Box as="span" mr="5px">
        <GrayScaledBox
          isGrayScaled={count === 0}
          borderRadius="8px"
          h="32px"
          px={['4px', '4px', '8px', '8px']}
          alignItems="center"
          mr="8px"
          bg="dark.light"
          {...rest}
        >
          <Flex justifyContent="space-between" alignItems="center">
            {iconName && <Icon name={iconName} {...iconProps} />}
            <Typography variant="text2" ml="12px">
              {count}
            </Typography>
          </Flex>
        </GrayScaledBox>
      </Box>
    </Tooltip>
  );
};

export default CountBadge;
