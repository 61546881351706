import { Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import ConditionalWrapper from '@components/ConditionalWrapper';
import Typography from '@styles/Typography';
import React from 'react';

const CandidateDiffTile = ({
  isTooltip,
  fieldName,
  oldValue,
  newValue,
  resourceName,
  isAdded,
  isRemoved,
  candidateName,
  supplierName,
}) => {
  const formattedValue = value => {
    if (fieldName === 'typeOfPlugPlayResource') {
      return `${value} ${supplierName}`;
    }
    return value;
  };
  return (
    <ConditionalWrapper
      wrapper={children => (
        <Flex borderRadius="8px" p="12px" flexDir="column">
          {children}
        </Flex>
      )}
      condition={isTooltip}
    >
      <Flex
        border="1px solid"
        _notLast={{ mb: '8px' }}
        borderColor="dark.mid"
        p="8px"
        borderRadius="8px"
        flexDir="column"
      >
        {!isTooltip && (
          <Typography mb="16px" variant="h5">
            {resourceName}
          </Typography>
        )}
        {renderTileBody()}
      </Flex>
    </ConditionalWrapper>
  );

  function renderTileBody() {
    if (
      [
        'rate',
        'seniority',
        'startDate',
        'location',
        'role',
        'typeOfPlugPlayResource',
      ].includes(fieldName)
    ) {
      const tileTitle = getTileTitle();
      return (
        <>
          {!isTooltip && (
            <Typography variant="text2" fontWeight="bold" mb="8px">
              {candidateName}
            </Typography>
          )}
          <Typography variant="text2" mb="8px">
            {tileTitle}
          </Typography>
          <Flex
            bg="dark.light"
            alignSelf="flex-start"
            p="11px 8px"
            align="center"
            borderRadius="8px"
          >
            <Typography>{formattedValue(oldValue)}</Typography>
            <Icon name="mediumArrow" mx="16px" fontSize={12} />
            <Typography fontWeight="bold">
              {formattedValue(newValue)}
            </Typography>
          </Flex>
        </>
      );
    }
    if (isAdded || isRemoved) {
      return (
        <Flex
          bg={isAdded ? 'green.light' : 'red.light'}
          alignSelf="flex-start"
          p="11px 8px"
        >
          {!isTooltip && <Typography mr="12px">{candidateName}</Typography>}
          <Typography
            color={isAdded ? 'green.solid' : 'red.solid'}
            fontWeight="bold"
          >
            {isAdded ? 'Added' : 'Removed'}
          </Typography>
        </Flex>
      );
    }
    return null;
  }

  function getTileTitle() {
    if (fieldName === 'seniority') {
      return 'Seniority';
    }
    if (fieldName === 'rate') {
      return 'Delivery partner rate';
    }
    if (fieldName === 'startDate') {
      return 'Start date';
    }
    if (fieldName === 'location') {
      return 'Location';
    }
    if (fieldName === 'typeOfPlugPlayResource') {
      return 'Type of resource';
    }
    return '';
  }
};

export default CandidateDiffTile;
