import { API } from '@app/api';
import { NEW_ROLES } from '@app/constants';
import { Box, Stack } from '@chakra-ui/react';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import AsyncAutocomplete from '@components/forms/_common/AsyncAutocomplete';
import Select from '@components/forms/_common/Select';
import Button from '@styles/Button';
import { required } from '@utils/formValidators';
import { reject } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form } from 'react-final-form';

const PROJECT_TYPE_OPTIONS = [
  { value: NEW_ROLES.ADMIN, label: 'Admin' },
  { value: NEW_ROLES.DEAZY_LEAD, label: 'Deazy Lead' },
  { value: NEW_ROLES.CLIENT_LEAD, label: 'Client Lead' },
  { value: NEW_ROLES.TEAM_MEMBER, label: 'Team Member' },
  { value: NEW_ROLES.TEAM_ADMIN, label: 'Team Admin' },
];

const AddToRoleForm = ({ usersStore: { isAdmin }, onClose, onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting, values, form }) => {
      return (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Box>
            <Field
              label="User type"
              name="role"
              placeholder="Choose a user role"
              component={Select}
              validate={required}
              options={
                !isAdmin
                  ? reject(PROJECT_TYPE_OPTIONS, { value: NEW_ROLES.ADMIN })
                  : PROJECT_TYPE_OPTIONS
              }
              styleProps={{ mb: '32px' }}
              onAfterChange={role => {
                form.change('client', null);
                if (
                  !(
                    (values.role === NEW_ROLES.TEAM_ADMIN ||
                      values.role === NEW_ROLES.TEAM_MEMBER) &&
                    (role === NEW_ROLES.TEAM_ADMIN ||
                      role === NEW_ROLES.TEAM_MEMBER)
                  )
                ) {
                  form.change('supplier', null);
                }
              }}
            />
            {values.role === NEW_ROLES.CLIENT_LEAD && (
              <Field
                name="client"
                label="Client"
                placeholder="Choose a client"
                component={AsyncAutocomplete}
                styleProps={{ mb: '32px' }}
                getOptionsFn={API.searchClients}
                validate={required}
              />
            )}
            {(values.role === NEW_ROLES.TEAM_MEMBER ||
              values.role === NEW_ROLES.TEAM_ADMIN) && (
              <Field
                name="supplier"
                label="Delivery Partner"
                placeholder="Choose a Delivery Partner"
                component={AsyncAutocomplete}
                styleProps={{ mb: '32px' }}
                getOptionsFn={API.searchSuppliers}
                validate={required}
              />
            )}
            {values.role === NEW_ROLES.DEAZY_LEAD && (
              <Field
                name="rate"
                label="Rate"
                component={MaskedTextInput}
                containerProps={{ mb: '32px' }}
                isBordered
                type="number"
                validate={required}
                allowNegative={false}
                placeholder="0"
              />
            )}
          </Box>
          <Stack mt="40px" spacing={3}>
            <Button isLoading={submitting} type="submit">
              Add role
            </Button>
            <Button variant="outline" isDisabled={submitting} onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </form>
      );
    }}
  />
);

export default inject('usersStore')(observer(AddToRoleForm));
