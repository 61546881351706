import {
  MAIN_NAMES_QA,
  ACTUALS_STATUS,
  ALLOCATION_STATUS,
} from '@app/constants';
import { Badge, Box, Flex } from '@chakra-ui/react';
import ReadOnlyTable from '@components/ReadOnlyTable';
import Typography from '@styles/Typography';
import { map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { useMemo } from 'react';

const badgeProps = {
  variant: 'solid',
  fontSize: 14,
  p: '10px',
  borderRadius: '8px',
  mr: '10px',
};

const getWeekOrdinal = week => {
  switch (week) {
    case 1:
      return `${week}st`;
    case 2:
      return `${week}nd`;
    case 3:
      return `${week}rd`;
    default:
      return `${week}th`;
  }
};

const getMoreDetailedDate = (allocation, isGoody) => {
  const { year, month, week } = allocation;
  if (!!year && !!month && !!week && isGoody) {
    return `${moment
      .utc([year, month - 1, 1])
      .format('YYYY/MM')}/${getWeekOrdinal(week)} week`;
  }
  return undefined;
};

const AllocationCard = ({
  children,
  usersStore: { isGoodyUser },
  allocation,
  columns,
  mainNameQA,
  isProjectFixedPriceModal,
  ...props
}) => {
  const badges = useMemo(() => {
    const { year, month, week } = allocation;
    const isActuals = !!year && !!month && !!week;

    const items = [];

    if (allocation.status) {
      items.push({
        label: allocation.status,
        variantColor:
          allocation.status !== ALLOCATION_STATUS.REJECTED ? 'blue' : 'red',
      });
      if (
        allocation.status === ACTUALS_STATUS.PENDING ||
        allocation.status === ALLOCATION_STATUS.NEW
      ) {
        return items;
      }
    }
    if (!isActuals) {
      if (
        moment.utc().isAfter(allocation.applicableFrom) &&
        !moment
          .utc()
          .isBetween(allocation.applicableFrom, allocation.applicableUntil)
      ) {
        items.push({
          label: 'Past',
          variantColor: 'gray',
        });
      }
      if (
        moment
          .utc()
          .isBetween(allocation.applicableFrom, allocation.applicableUntil) &&
        (allocation.status
          ? allocation.status === ALLOCATION_STATUS.APPROVED
          : true)
      ) {
        items.push({
          label: 'Current',
          variantColor: 'gray',
        });
      }
    }
    return items;
  }, [allocation]);

  const moreDetailedDate = getMoreDetailedDate(allocation, isGoodyUser);

  return (
    <Box
      mb="60px"
      border="1px solid"
      borderColor="dark.mid"
      borderRadius="8px"
      p="40px"
      bg="#fff"
      boxShadow="0 0 24px 0 rgba(0, 0, 0, 0.15)"
      {...props}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          {map(badges, ({ label, ...restProps }, i) => (
            <Badge key={`badge-label-${i}`} {...badgeProps} {...restProps}>
              {label}
            </Badge>
          ))}
        </Flex>
        <Flex flexDirection="column">
          {moreDetailedDate && (
            <Typography
              textAlign="right"
              variant="h4"
              mb="8px"
              data-test-id={mainNameQA && `${mainNameQA}--moreDetailedDate`}
            >
              {moreDetailedDate}
            </Typography>
          )}
          <Typography
            data-test-id={mainNameQA && `${mainNameQA}--duration`}
            variant="h4"
            textAlign="right"
          >{`${moment
            .utc(allocation.applicableFrom)
            .format('dddd, Do MMM')} - ${moment
            .utc(allocation.applicableUntil)
            .format('dddd, Do MMM')}`}</Typography>
        </Flex>
      </Flex>
      {isProjectFixedPriceModal && (
        <Typography mt="32px" variant="h5">
          {allocation.stage}
        </Typography>
      )}
      <ReadOnlyTable
        qaTag={MAIN_NAMES_QA.PROJECT_ALLOCATIONS}
        mainNameQA={mainNameQA}
        columns={columns}
        tableData={allocation.tableData}
      />
      {children}
    </Box>
  );
};

export default inject('usersStore')(observer(AllocationCard));
