/* eslint-disable no-throw-literal */
import { isNil, set, get, map, reject, noop } from 'lodash-es';
import { action, computed, makeObservable, observable } from 'mobx';

export default class ChangeRequestsStore {
  constructor({
    API,
    routerStore,
    projectsStore,
    paymentsStore,
    toastsStore,
    usersStore,
  }) {
    makeObservable(this);
    this.routerStore = routerStore;
    this.paymentsStore = paymentsStore;
    this.toastsStore = toastsStore;
    this.usersStore = usersStore;
    this.projectsStore = projectsStore;
    this.API = API;
  }

  @action calculateCosts = cr => {
    const { isTeamMember, isTeamAdmin, isClient } = this.usersStore;
    return {
      ...cr,
      totalClientCost:
        !isTeamMember && !isTeamAdmin && +cr.clientRate > 0
          ? cr.clientRate * cr.amount
          : null,
      totalSupplierCost:
        !isClient && !isTeamMember && +cr.rate > 0 ? cr.rate * cr.amount : null,
    };
  };

  @computed get isAllowedToApproveCRs() {
    const { profile, isAdminOrDL } = this.usersStore;
    const { project } = this.projectsStore;
    return profile?.id === project?.clientLead?.id || isAdminOrDL;
  }

  @observable isLoading = false;

  @observable assignedPaymentId = null;

  @observable _cr = {};

  @observable _token = {};

  @computed get cr() {
    return this.calculateCosts(this._cr);
  }

  @observable _crs = {
    all: {
      data: [],
      isLoading: false,
    },
    pending: {
      firstRequest: true,
    },
  };

  @computed get crs() {
    return {
      all: {
        ...this._crs.all,
        data: map(this._crs.all.data, cr => this.calculateCosts(cr)),
      },
      pending: {
        ...this._crs.pending,
        data: map(this._crs.pending.data, cr => this.calculateCosts(cr)),
      },
    };
  }

  @action clearPendingCRs = () => {
    this._crs.pending = { firstRequest: true };
  };

  @action clearCRs = () => {
    this._cr = {};
  };

  @observable readyId = null;

  @action fetchCR = async (projectId, crId) => {
    try {
      this.isLoading = true;
      const { data } = await this.API.getSingleChangeRequest(projectId, crId);
      this._cr = data;
    } catch {
      //
    } finally {
      this.isLoading = false;
    }
  };

  @action assignCRtoPayment = async (projectId, crId, paymentId) => {
    try {
      this.assignedPaymentId = paymentId;
      await this.API.assignChangeRequestToPayment(projectId, paymentId, crId);
      await this.paymentsStore.refetchPaymentsSilently(projectId);
      const {
        push,
        location: { pathname },
      } = this.routerStore;
      push(pathname);
      this.toastsStore.showSuccess({
        title: 'Change request has been successfully assigned.',
      });
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      this.assignedPaymentId = null;
    }
  };

  @action _fetch = async (
    projectId,
    observablePath,
    apiFnKey,
    fallbackErrorMessage = 'Network error. Please try again later.',
  ) => {
    try {
      set(this, `${observablePath}.isLoading`, true);
      const { data } = await this.API[apiFnKey](projectId);
      set(this, `${observablePath}.firstRequest`, false);
      set(this, `${observablePath}.data`, data);
    } catch (e) {
      this.toastsStore.showError({ title: fallbackErrorMessage });
    } finally {
      set(this, `${observablePath}.isLoading`, false);
    }
  };

  @action fetchAllCRs = async projectId => {
    this._crs.all.data = [];
    return this._fetch(projectId, '_crs.all', 'getAllChangeRequests');
  };

  @action fetchPendingCRs = async projectId =>
    this._fetch(projectId, '_crs.pending', 'getPendingChangeRequests');

  validateCR = cr => {
    const { clientDisabled, supplierDisabled, clientRate, rate } = cr;
    if (!clientDisabled && isNil(clientRate)) {
      throw {
        message:
          'In order to ready CR, client rate must be filled or disabled.',
      };
    }
    if (!supplierDisabled && isNil(rate)) {
      throw {
        message: 'In order to ready CR, rate must be filled or disabled.',
      };
    }
  };

  @action readyChangeRequests = async (projectId, cr) => {
    const { id: crId } = cr;
    try {
      this.validateCR(cr);
      this.setCRProperty('_crs.pending.data', crId, { isSettingReady: true });
      const { data } = await this.API.readyChangeRequests(projectId, crId);
      this.setCRProperty('_crs.pending.data', crId, data);
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please try again later.',
      });
    } finally {
      this.setCRProperty('_crs.pending.data', crId, { isSettingReady: false });
    }
  };

  @action fetchTokenForSingleCR = async ({ token }) => {
    try {
      this.isLoading = true;
      const { data } = await this.API.checkToken(token);
      this._token = data;
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please try again later.',
      });
    } finally {
      this.isLoading = false;
    }
  };

  @action useTokenAction = async ({ token, onSuccess = noop }) => {
    try {
      this.isLoading = true;
      await this.API.useTokenAction(token);
      this.toastsStore.showSuccess({ title: 'Successfully updated!' });
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    } finally {
      this.isLoading = false;
    }
  };

  @computed get token() {
    return {
      ...this._token,
    };
  }

  @action approveCR = async (projectId, crId) => {
    try {
      this.setCRProperty('_crs.pending.data', crId, { isApproving: true });
      const { data } = await this.API.approveCR(projectId, crId);
      this.setCRProperty('_crs.pending.data', crId, data);
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please try again later.',
      });
    } finally {
      this.setCRProperty('_crs.pending.data', crId, { isApproving: false });
    }
  };

  @action rejectCR = async (projectId, crId) => {
    try {
      this.setCRProperty('_crs.pending.data', crId, { isRejecting: true });
      const { data } = await this.API.rejectCR(projectId, crId);
      this.setCRProperty('_crs.pending.data', crId, data);
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please try again later.',
      });
    } finally {
      this.setCRProperty('_crs.pending.data', crId, { isRejecting: false });
    }
  };

  @action deleteCR = async (projectId, crId) => {
    try {
      this.setCRProperty('_crs.pending.data', crId, { isDeleting: true });
      await this.API.deleteCR(projectId, crId);
      this._crs.pending.data = reject(this._crs.pending.data, { id: crId });
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please try again later.',
      });
    } finally {
      this.setCRProperty('_crs.pending.data', crId, { isDeleting: false });
    }
  };

  @action createOrUpdateCR = async ({ projectId, ...payload }, successCB) => {
    try {
      await this.API[
        payload.id ? 'updateChangeRequest' : 'createChangeRequest'
      ](projectId, this.crPayload(payload));
      await this.fetchPendingCRs(projectId);
      if (successCB) {
        successCB();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please try again later.',
      });
    }
  };

  crPayload = cr => {
    const { isTeamMember } = this.usersStore;
    return {
      ...cr,
      ...(isTeamMember && { rate: null }),
    };
  };

  @action setCRProperty = (path, id, obj) => {
    set(
      this,
      path,
      map(get(this, path), item => ({
        ...item,
        ...(id === item.id && obj),
      })),
    );
  };
}
