import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import Typography from '@styles/Typography';

const Wrapper = ({ children, ...props }) => (
  <Box bg="#fff" p="64px 20px" borderRadius="8px" width="100%" {...props}>
    {children}
  </Box>
);

const EmptyList = ({ label, description, imagePath }) => (
  <Wrapper>
    <Box textAlign="center">
      <Image
        mx="auto"
        maxW="153px"
        w="100%"
        h="auto"
        src={imagePath}
        alt="DEAZY"
        mb="24px"
      />
      <Typography variant="h4" mb="12px">
        {label}
      </Typography>
      <Typography>{description}</Typography>
    </Box>
  </Wrapper>
);

export default EmptyList;
