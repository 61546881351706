/* eslint-disable no-nested-ternary */
import React from 'react';
import ExchangeRateBox from '@components/ExchangeRateBox';
import PnPExchangeRateBox from '@components/PnPExchangeRateBox';
import { inject, observer } from 'mobx-react';
import { get, find, round, isEmpty } from 'lodash-es';

const ProposalExchangeRateBox = ({
  settingsStore: { exchangeRates },
  proposalsStore: { proposalData, updateProposalCurrencyRate },
  briefsStore: { brief },
  isReadOnly,
}) => {
  const globalUsd = get(
    find(
      exchangeRates.data,
      rate => rate.sourceCurrency === 'GBP' && rate.destCurrency === 'USD',
    ),
    'rate',
  );
  const globalEur = get(
    find(
      exchangeRates.data,
      rate => rate.sourceCurrency === 'GBP' && rate.destCurrency === 'EUR',
    ),
    'rate',
  );
  const projectProposalGlobalCurrency =
    proposalData.overriddenCurrency === 'EUR'
      ? globalEur
      : proposalData.overriddenCurrency === 'USD'
      ? globalUsd
      : 1;

  React.useEffect(() => {
    if (
      !isEmpty(proposalData) &&
      !proposalData.currencyRate &&
      !brief.isPlugAndPlay
    ) {
      updateProposalCurrencyRate({
        currencyRate: round(projectProposalGlobalCurrency, 2),
      });
    }
  }, [brief.isPlugAndPlay, projectProposalGlobalCurrency, proposalData]);

  if (!projectProposalGlobalCurrency && !brief.isPlugAndPlay) {
    return null;
  }

  if (brief.isPlugAndPlay) {
    return (
      <PnPExchangeRateBox
        isReadOnly={isReadOnly}
        initialValues={{
          currencyRateEur: proposalData.currencyRateEur,
          currencyRateUsd: proposalData.currencyRateUsd,
          currency: proposalData.overriddenCurrency || proposalData.currency,
          currencyRate: proposalData.currencyRate,
        }}
        globalEur={round(globalEur, 2)}
        globalUsd={round(globalUsd, 2)}
        updateProposalCurrencyRate={updateProposalCurrencyRate}
      />
    );
  }

  return (
    <ExchangeRateBox
      isReadOnly={isReadOnly}
      currencyRate={proposalData.currencyRate}
      onExchangeRateChange={updateProposalCurrencyRate}
      isSettingRate={proposalData.isSettingRate}
      sourceCurrency="GBP"
      globalRate={round(projectProposalGlobalCurrency, 2)}
      differsFromGlobalRate={
        round(+projectProposalGlobalCurrency, 2) !==
          round(+proposalData.currencyRate, 2) && !!proposalData.currencyRate
      }
      destCurrency={proposalData.currency || proposalData.overriddenCurrency}
    />
  );
};

export default inject(
  'proposalsStore',
  'settingsStore',
  'briefsStore',
)(observer(ProposalExchangeRateBox));
