import { inject, observer } from 'mobx-react';
import React from 'react';
import { omit } from 'lodash-es';
import { Box } from '@chakra-ui/react';
import Spinner from '@styles/Spinner';
import { toJS } from 'mobx';
import ProfileForm from './ProfileForm';

const Profile = ({
  usersStore: { profile, updateProfileAndPassword, isLoading },
}) => {
  return (
    <Box bg="blue.light" pt="40px" pb="150px" px="40px">
      {isLoading ? (
        <Spinner />
      ) : (
        <ProfileForm
          initialValues={{
            ...toJS(omit(profile, 'profilePictureURL')),
            profilePicture: profile.profilePictureURL,
            updateBoth: true,
          }}
          onSubmit={updateProfileAndPassword}
        />
      )}
    </Box>
  );
};

export default inject('usersStore')(observer(Profile));
