import { Box } from '@chakra-ui/react';
import InfoAlert from '@components/InfoAlert';
import WhiteCard from '@components/WhiteCard';
import ZoneContainer from '@components/ZoneContainer';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { forEach, isEmpty, isEqual, last, map, omit } from 'lodash-es';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import { Form } from 'react-final-form';
import AutoSave from './AutoSave';
import CoreTeamRolesTable from './CoreTeamRoleTable';

const validate = ({ roles }) => {
  const errors = { coreRoles: [] };
  forEach(roles, (r, idx) => {
    errors.coreRoles[idx] = {};
    if (roles.length - 1 !== idx) {
      if (!r.name) {
        errors.coreRoles[idx].name = 'Role name is required.';
      } else if (r.name.length > 255) {
        errors.coreRoles[idx].name =
          'Role name must be shorter or equal 255 characters.';
      }
      if (!r.rate) {
        errors.coreRoles[idx].rate = 'Rate is required.';
      }
    }
    if (isEmpty(errors.coreRoles[idx])) {
      delete errors.coreRoles[idx];
    }
  });
  return errors.coreRoles;
};

const CoreTeamRolesForm = ({
  setSubmit,
  initialValues,
  onSubmit,
  deleteRole,
  isReadOnly,
  ratesStore: {
    resourceRates: { isLoading: isLoadingRates },
  },
  mainNameQA,
  isSmallerScreen,
}) => {
  const [showForm, setShowForm] = React.useState(false);

  React.useEffect(() => {
    if (!isEmpty(initialValues.coreTeam)) {
      setShowForm(true);
    }
  }, [initialValues.coreTeam]);

  const coreTeam = React.useMemo(() => {
    const cTeam = map(toJS(initialValues.coreTeam), m => {
      return {
        ...m,
        rowId: m.rowId || nanoid(10),
      };
    });
    if (
      !isSmallerScreen &&
      !isEmpty(omit(last(cTeam), 'rowId')) &&
      !isReadOnly
    ) {
      cTeam.push({ rowId: nanoid(10) });
    }
    return cTeam;
  }, [initialValues.coreTeam, isSmallerScreen]);

  return (
    <WhiteCard
      mt={0}
      mb={isSmallerScreen ? '32px' : '24px'}
      pb={isSmallerScreen && !isReadOnly ? '16px' : 'inherit'}
      boxShadow="0 0 0 0 rgba(0, 0, 0, 0)"
      border="solid 1px"
      borderColor="dark.mid"
      pt={isSmallerScreen ? '16px' : 'inherit'}
      px={isSmallerScreen ? '12px' : '32px'}
    >
      <Typography variant="h2" mb="16px">
        Core Team Roles
      </Typography>
      <Typography
        mb={isSmallerScreen ? '24px' : '40px'}
        maxW={isSmallerScreen ? '100%' : '632px'}
        lineHeight="22px"
      >
        These are your ‘sprint team’, they are the roles you’d like to add to
        specific tasks. For the core team, you’ll use time estimations assigned
        to specific tasks to calculate a cost for each feature.
      </Typography>
      {!isSmallerScreen && (
        <InfoAlert label="Rates are prefilled with your agreed target rate" />
      )}
      {!showForm && !isReadOnly && (
        <ZoneContainer py="24px">
          <Button
            leftIcon={{ name: 'add' }}
            onClick={() => setShowForm(true)}
            data-test-id={`${mainNameQA}--add-core-team`}
          >
            Add a core team role
          </Button>
        </ZoneContainer>
      )}
      <Box display={!showForm || isLoadingRates ? 'none' : 'block'}>
        <Form
          onSubmit={onSubmit}
          keepDirtyOnReinitialize
          initialValues={{ roles: coreTeam }}
          validate={validate}
          render={({ handleSubmit, form, values }) => {
            setSubmit(handleSubmit);
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                {showForm && (
                  <>
                    <AutoSave
                      formName="coreTeam"
                      formApi={form}
                      onSave={onSubmit}
                    />
                    <CoreTeamRolesTable
                      deleteRole={deleteRole}
                      isReadOnly={isReadOnly}
                      mainNameQA={mainNameQA}
                    />
                    {isSmallerScreen && !isReadOnly && (
                      <Button
                        onClick={() => {
                          form.change('roles', [
                            ...values.roles,
                            { rowId: nanoid(10) },
                          ]);
                        }}
                        variant="outline"
                        leftIcon={{ name: 'add' }}
                        size="lg"
                        width="100%"
                      >
                        Add another core role
                      </Button>
                    )}
                  </>
                )}
              </form>
            );
          }}
        />
      </Box>
    </WhiteCard>
  );
};

export default React.memo(
  inject('ratesStore')(observer(CoreTeamRolesForm)),
  (prevProps, nextProps) => {
    if (
      isEqual(
        omit(prevProps, ['onSubmit', 'deleteRole', 'setSubmit']),
        omit(nextProps, ['onSubmit', 'deleteRole', 'setSubmit']),
      )
    ) {
      return true;
    }
    return false;
  },
);
