import Button from '@styles/Button';
import React from 'react';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import { Box } from '@chakra-ui/react';

const ChangeRateReasonExpander = ({ reason, textProps = {} }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const textRef = React.useRef();

  const height = React.useMemo(() => {
    if (
      textProps.position === 'absolute' &&
      textRef &&
      textRef.current &&
      isOpen
    ) {
      return `${textRef.current.offsetHeight}px`;
    }
    return '0px';
  }, [textProps.position, isOpen, textRef]);

  const toggle = () => setIsOpen(!isOpen);

  if (!reason) {
    return null;
  }

  return (
    <>
      <Button
        variant="ghost"
        p="0px"
        onClick={toggle}
        mb="8px"
        mt="-8px"
        minW="228px"
        fontWeight="400"
      >
        <Icon name="commentOutline" mr="6px" />
        {!isOpen ? 'Show change rate reason' : 'Hide change rate reason'}
        <Icon
          name="arrowLeft"
          ml="auto"
          transform={!isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'}
        />
      </Button>
      <Typography
        ref={textRef}
        mb="25px"
        {...(textProps.position === 'absolute'
          ? {
              visibility: !isOpen && 'hidden',
            }
          : { display: !isOpen && 'none' })}
        {...textProps}
      >
        {reason}
      </Typography>
      <Box h={height} />
    </>
  );
};

export default ChangeRateReasonExpander;
