/* eslint-disable */
/* retainer */
export default `
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Schedule 1 Statement of Work&nbsp;</strong></p>
<p><strong>Dated:&nbsp;</strong></p>
<p><strong>Between:</strong></p>
<p><strong>(1) {{DEAZY_NAME}}</strong><span style="font-weight: 400;"> (trading as Deazy) of {{DEAZY_DETAILS}} (Company No. 10305788) (&ldquo;</span><strong>Deazy</strong><span style="font-weight: 400;">&rdquo;); and</span></p>
<p><span style="background-color: #feff00;"><strong>(2) {{SUPPLIER_NAME}}<span style="font-weight: 400;"> {{SUPPLIER_ADDRESS}} {{COUNTRY}}<span style="background-color: #ffffff;">&nbsp;</span></span><span style="background-color: #ffffff;"><span style="font-weight: 400;">(the "</span>Supplier<span style="font-weight: 400;">").</span></span></strong></span></p>
<p style="padding-left: 40px;">&nbsp;</p>
<p><strong>Background</strong></p>
<p><span style="font-weight: 400;">(A) Pursuant to the Deazy Supplier Agreement agreed between the parties <span style="background-color: #feff00;">{{DATE}}</span>&nbsp;(the &ldquo;<strong>Agreement</strong>&rdquo;) Deazy has appointed the Supplier to provide software development services when required, during the Term.</span></p>
<p><span style="font-weight: 400;">(B) Pursuant to the Agreement, the Deazy is able to request Services (as defined in the Agreement) and the Supplier has agreed to provide such services in accordance with this statement of work (&ldquo;<strong>SOW</strong>&rdquo;).</span></p>
<p>&nbsp;</p>
<p><strong>Term</strong></p>
<ol class="start">
<li><span style="font-weight: 400;">This SOW shall come into force on the date it is executed by its parties OR on </span><span style="font-weight: 400; background-color: #feff00;">{{TERM_DATE}}</span><span style="font-weight: 400;"> (the &ldquo;</span><strong>Commencement Date</strong><span style="font-weight: 400;">&rdquo;</span><strong>)</strong></li>
<li><span style="font-weight: 400;">This SOW shall take effect from the Commencement date and will continue in effect for a initial term of [<span style="background-color: #feff00;">{{DURATION}}</span>], (“<strong>Initial Term</strong>”) then continue thereafter, unless terminated earlier, on a [<span style="background-color: #feff00;">monthly/quarterly</span>] rolling basis.
<ol class="nested-1">
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Deazy may terminate this SOW on giving the Supplier written notice of not less than [</span><span style="background-color: #feff00;">thirty (30) days]</span><span style="font-weight: 400;"> to be served at any time.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The Supplier may terminate this SOW on giving Deazy written notice of not less than [<span style="background-color: #feff00;">sixty (60) days</span>] to be served at any time</span></li>
</ol>
</li>
</ol>
<p><span style="font-weight: 400;"><strong>Client</strong></span></p>
<ol class="continue">
<li><span style="font-weight: 400;">The underlying Client receiving the benefit of the Services is </span><span style="font-weight: 400; background-color: #feff00;">{{CLIENT_NAME}}</span><span style="font-weight: 400;">&nbsp;(the &ldquo;</span><strong>Client</strong><span style="font-weight: 400;">&rdquo;) </span></li>
</ol>
<p>&nbsp;</p>
<p><strong>Conflict</strong></p>
<ol class="continue">
<li><span style="font-weight: 400;">If there is an inconsistency between any of the provisions of this SOW and the provisions of the Agreement, the provisions of this SOW shall prevail.</span></li>
<li><span style="font-weight: 400;">If a term is capitalised in this SOW but not defined therein the defined terms of the Agreement shall be applicable to said term.</span></li>
</ol>



<p>&nbsp;</p>
<p><strong>Services</strong></p>
<p><strong>Scope of work and approach</strong></p>


<ol class="continue">
<li><strong>Context: </strong><span style="font-weight: 400;">The Client wishes to have  <span style="background-color: #feff00;">[new or ongoing]</span> product development including, new feature development, bug fixing and minor updates on the</span><span style="font-weight: 400;"> [<span style="background-color: #feff00;">PLATFORM DETAILS</span></span><span style="font-weight: 400;">] (the </span><strong>&lsquo;engagement&rsquo;</strong><span style="font-weight: 400;">).</span><span style="font-weight: 400;"> </span></li>
<li><strong>Approach: <span style="font-weight: 400;">The Services in this SOW are provided on a retainer basis, with a fixed resource allocation detailed in clause 8. Any additional hours outside of the retainer will be delivered on a Time &amp; Material basis. [</span><span style="font-weight: 400; background-color: #feff00;">Hours can be ramped up or down with 1 months notice,&nbsp; but cannot be reduced below 100 hours total per month</span><span style="font-weight: 400;">].</span></strong></li>

<li aria-level="1"><strong>Scope:</strong><span style="font-weight: 400;"> The scope of services provided under this SoW is as follows;</span>
<ol class="nested-1">
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The Supplier shall provide a cross functional development team to work o to work on;</span>
<ol class="nested-1">
<li style="font-weight: 400;" aria-level="4"><span style="font-weight: 400;">New feature, as prioritized by the Deazy Product Manager and the Client.</span></li>
<li style="font-weight: 400;" aria-level="4"><span style="font-weight: 400;">Minor updates to existing features, as prioritized by the Deazy Product Manager and the Client.</span></li>
<li style="font-weight: 400;" aria-level="4"><span style="font-weight: 400;">General maintenance and Support</span></li>
<li style="font-weight: 400;" aria-level="4"><span style="font-weight: 400;">Bug fixes, as prioritized by the Deazy Product Manager and the Client.</span></li>
</ol>
</li>
<li><span style="font-weight: 400;">Monthly retainer team breakdown;</span></li>
</ol>
</ol>



<table style="border-collapse: collapse; width: 91.9273%; height: 72px;" border="1">
<tbody>
<tr style="height: 18px;">
<td style="width: 49.6158%; height: 18px;">
<p><strong>Role</strong></p>
</td>
<td style="width: 49.6158%; height: 18px; font-weight: bold">[<span style="background-color: #feff00;">Days</span>] per month (4 week period)</td>
</tr>

{{RESOURCES}}

<tr style="height: 18px;">
<td style="width: 49.6158%; height: 18px;">
<p><span style="font-weight: 400;"><strong>Total Monthly [<span style="background-color: #feff00;">Days</span>]</strong></p>
</td>
<td style="width: 49.6158%; height: 18px;">{{TOTAL_RESOURCE_HOURS}}</td>
</tbody>
</table>


<ol class="continue">
<li><strong>&nbsp;Service Level Agreements</strong><br />
<ol class="nested-1">
<li><span style="font-weight: 400;">The Supplier will respond to new support tickets (bugs and ad-hoc technical support) according to the following Service Level Agreements (SLAs). </span>
<br />
<br />


<table style="border-collapse: collapse; width: 105%; height: 72px; margin-left: -30px" border="1" cellpadding="10">
  <tbody>
    <tr style="height: 18px;">
      <td style="height: 18px;">Severity</td>
      <td style="height: 18px;">Description</td>
      <td style="height: 18px;">Response time</td>
      <td style="height: 18px;">Target Resolution time</td>
    </tr>
    <tr style="height: 18px;">
      <td style="height: 18px;">Critical</td>
      <td style="height: 18px;">
        <p>Any error that either;</p>
        <ul class="resetDisplayList">
          <li>makes the system completely unusable</li>
          <li>impedes the business at large</li>
          <li>stops the client from working during business as usual</li>
        </ul>
        <p>Example: fatal application crash where the OS aborts the application</p>
      </td>
      <td style="height: 18px;">4 Business Hours</td>
      <td style="height: 18px;">8 Business Hours from reporting time</td>
    </tr>
    <tr style="height: 18px;">
      <td style="height: 18px;"><p>Normal</p></td>
      <td style="height: 18px;">
        <p>Any error related to critical user flows in the products.</p>
        <p>Example: login, registration, scanning, payment etc</p>
      </td>
      <td style="height: 18px;"><p>4 Business Hours</p></td>
      <td style="height: 18px;"><p>16 Business Hours from reporting time</p></td>
    </tr>
    <tr style="height: 18px;">
      <td style="height: 18px;"><p>Low</p></td>
      <td style="height: 18px;">
        <p>User interface glitches, typos, low impact errors etc.</p>
      </td>
      <td style="height: 18px;"><p>4 Business Hours</p></td>
      <td style="height: 18px;"><p>40 Business Hours from reporting time</p></td>
    </tr>
    <tr style="height: 18px;">
      <td style="height: 18px;"><p>Client Feedback</p></td>
      <td style="height: 18px;" colspan="3">
        <p>The Client shall confirm that the issue has been solved within 4 Business Days of the time the issue is marked as resolved. The Supplier will need formal approval from the Client or Deazy, on behalf of the Client, before marking issues as resolved. </p>
      </td>
    </tr>
  </tbody>
</table>
<p>&nbsp;</p>
</li>

<li><strong>Business Hours: </strong><span style="font-weight: 400;">Business Hours are defined as Monday to Friday 9am to 5pm UK time, excluding bank holidays</span></li>
<li><span style="font-weight: 400;"><strong>Response Time: </strong>Response Time is defined as the time elapsed between the initial submission of the issue as a Support ticket and the first response acknowledging the issue, confirming the severity and communicating that a resource is investigating it.</span></li>
<li style="font-weight: 400;" aria-level="2"><strong>Resolution Time: </strong><span style="font-weight: 400;">Resolution Time is defined as the time elapsed between the initial submission of the issue as a Support ticket and confirmation of the resolution of the issue, which will be either:</span>
<ol class="nested-2">
<li><span style="font-weight: 400;">An explanation that the issue is not caused by the [<span style="background-color: #feff00;">Product details</span>] (e.g. the issue is caused by an external API)</span></li>
<li><span style="font-weight: 400;">A new version of the [<span style="background-color: #feff00;">Product details</span>] that contains the fixed codebase.&nbsp; The issue will be QA tested internally by the the Supplier.&nbsp; The [<span style="background-color: #feff00;">Product details</span>] will be sanity checked to ensure that the application is still functional by following the major user flows.&nbsp; The [<span style="background-color: #feff00;">Product details</span>] will be distributed as outlined in clause 10.6.</span></li>
</ol>
</li>
<li><strong>Target Resolution Time: </strong><span style="font-weight: 400;">Target Resolution Time is defined as the target elapsed time between the initial submission of the issue as a Support ticket and confirmation of the resolution of the issue.</span>
<ol class="nested-2">
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">The Supplier shall use all reasonable endeavours to meet the Target Resolution Time as set out in the table above, however there may be scenarios where the resolution is not only dependent on the modification of the [</span><span style="font-weight: 400; background-color: #feff00;">Product details</span><span style="font-weight: 400;">] codebase, for example, if there are delays due to changes in implementation required from the Client or the Client&rsquo;s third party suppliers.&nbsp;</span></li>
</ol>
</li>
<li><span style="font-weight: 400;"><strong>Application Deployment:</strong></span>
<ol class="nested-2">
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">The [</span><span style="font-weight: 400; background-color: #feff00;">Product details</span><span style="font-weight: 400;">] is distributed [</span><span style="font-weight: 400; background-color: #feff00;">INCLUDE details of distribution to app store / production environments, including the role Deazy and the client play</span><span style="font-weight: 400;">].</span></li>
</ol>
</li>
</ol>
<p>clause 8-9, the "<strong>Services</strong>".</p>
<li><span style="font-weight: 400;"><strong>Production / Client Data</strong></span>
<ol class="nested-1">
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">During the triage and issue resolution process, the Supplier will never view, transfer or copy data which is related to customers of the Client, internal staff or other production aspects.&nbsp; In the case where the issue at hand is related to customers of the Client or internal staff, Deazy will;</span>
<ol class="nested-2">
<li><span style="font-weight: 400;">provide the Supplier with a UAT (test) environment where it is possible to replicate the issue and test the solution</span></li>
</ol>
</li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">If the Supplier is provided any data which is related to customers of the Client, internal staff or other production aspects they will notify Deazy immediately to ensure data protection processes are followed</span></li>
</ol>
</li>
<li aria-level="1"><strong>Location of services</strong><span style="font-weight: 400;"> </span><strong>provided: </strong><span style="font-weight: 400;">The Supplier shall work remotely. Any travel required to deliver the Services will be chargeable to the Deazy but all expenses must be approved in advance by Deazy, in both cases in writing.</span></li>

</ol>


<ol class="continue">
  <li>
    <strong>Charges</strong>
    <p>This SOW will be delivered on a retainer basis with a fixed monthly resource allocation. Any additional hours outside of the retainer will be delivered on a Time & Material basis. The charges are outlined in the table below:</p>
    <table style="border-collapse: collapse; width: 91.9273%; height: 72px;" border="1" cellpadding="10">
    <tbody>
      <tr style="height: 18px;">
        <td style="height: 18px; font-weight:bold;">Period</td>
        <td style="height: 18px; font-weight:bold;">Activity</td>
        <td style="height: 18px; font-weight:bold;">Total (Ex VAT)</td>
      </tr>

      {{CHARGES_PER_PERIODS_ROWS}}
      {{CHARGES_PER_PERIODS_ROWS_TOTAL}}
  
    </tbody>
  </table>
  <p>(the <strong>"Charges"</strong>)</p>
  </li>
</ol>

<p>&nbsp;</p>
<ol class="continue">
<li><span style="background-color: #feff00;"><strong>[Add if supplier is going to subcontract Use of sub-contractors</strong></span></li>
</ol>
<p style="padding-left: 40px;"><span style="font-weight: 400; background-color: #feff00;">Further to clause 18 of the Agreement, the Supplier may not sub-contract any of its obligations to any third party without Deazy's prior written consent.</span></p>
<p style="padding-left: 40px;"><span style="font-weight: 400; background-color: #feff00;">For the purpose of this SOW, Deazy agrees that the Supplier is permitted to use the following sub-contractors, which for the avoidance of doubt, includes both companies and individual consultants/freelancers engaged through the individual&rsquo;s own companies:[insert names of contractors]</span></p>
<p style="padding-left: 40px;"><span style="font-weight: 400; background-color: #feff00;">Any sub-contracting by the Supplier of any of its obligations will not relieve the Supplier from its obligations under the Agreement or this SOW, including confidentially obligations in clause 11 and securing the necessary Intellectual Property Rights in clause 14&nbsp;</span></p>
<p style="padding-left: 40px;"><span style="background-color: #feff00;"><span style="font-weight: 400;">All individual sub-contractors to complete </span><a style="background-color: #feff00;" href=""><span style="font-weight: 400;">this form</span></a><span style="font-weight: 400;"> prior to project commencement date.</span><span style="font-weight: 400;">]</span></span></p>
<p style="padding-left: 40px;">&nbsp;</p>
<p><strong><span style="background-color: #ffffff;">Payment schedule</span></strong></p>
<ol class="continue">
<li><span style="font-weight: 400;">Deazy will generate a self-billing invoice monthly <span style="background-color: #feff00;">[advance/arrears]</span> for all invoices.</span></li>
<li><span style="font-weight: 400;">Invoices due during the initial term of the SOW are set out in the table below:</span></li>
</ol>

<p>&nbsp;</p>

{{PREPOPULATED_TABLE}}

<ol class="continue">
<li><span style="font-weight: 400;">Deazy will pay the Supplier for all self-billing invoices in accordance with the agreement.</span></li>
</ol>


<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<table style="border-collapse: collapse; width: 93.2318%; height: 136px;" border="0">
<tbody>
<tr style="height: 118px;">
<td style="width: 49.6824%; height: 118px;">
<p><strong>Signed</strong><span style="font-weight: 400;"> by </span><span style="background-color: #feff00;"><strong>[A MEMBER OF THE DEAZY LEADERSHIP TEAM]</strong></span></p>
<p><span style="font-weight: 400;">duly authorised for and on behalf of </span><strong>Deazy Limited</strong></p>
</td>
<td style="width: 49.6824%; height: 118px;">
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-weight: 400;">..................................................................................................</span></p>
<p style="text-align: center;"><span style="font-weight: 400;">[Director]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; [Secretary]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;[Authorised Signatory]</span></p>
</td>
</tr>
<tr style="height: 18px;">
<td style="width: 49.6824%; height: 18px;">
<p style="text-align: left;"><strong>Signed<span style="font-weight: 400;"> by </span><span style="background-color: #feff00;">[AUTHORISED SIGNATORY]</span></strong></p>
<p><strong><span style="font-weight: 400;">duly authorised for and on behalf of </span><span style="background-color: #feff00;">{{SUPPLIER_NAME}}</span></strong></p>
</td>
<td style="width: 49.6824%; height: 18px;">
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-weight: 400;">..................................................................................................</span></p>
<p style="text-align: center;"><span style="font-weight: 400;">[Director]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; [Secretary]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;[Authorised Signatory]</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p>&nbsp;</p>
`;
