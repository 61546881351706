import { Tooltip, useDisclosure } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';

const TextEllipsisWithTooltip = ({ children, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Tooltip
      isOpen={isOpen}
      zIndex={33000}
      p="12px"
      borderRadius="8px"
      lineHeight="normal"
      label={children}
      hasArrow
      bg="dark.solid"
      px="24px"
      py="12px"
      placement="bottom"
    >
      <Typography
        onMouseEnter={e => {
          if (e.target.offsetWidth < e.target.scrollWidth) {
            onOpen();
          }
        }}
        onMouseLeave={onClose}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        variant="h4"
        as="div"
        {...props}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};

export default TextEllipsisWithTooltip;
