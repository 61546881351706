import { Box } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import { formatNumber, roundTheseHours } from '@utils';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form } from 'react-final-form';
import CostRow from '../CostRow';
import AutoSave from './AutoSave';
import ContigencySection from './ContingencySection';

const CostBreakdownForm = ({
  isReadOnly,
  proposalsStore: {
    costBreakdown: { isLoading, data: costBreakdown, error },
  },
  initialValues,
  setSubmit,
  onSubmit,
  currency,
  isSmallerScreen,
  mainNameQA,
}) => {
  return (
    <WhiteCard
      pb="40px"
      mt={isSmallerScreen ? '4px' : 0}
      boxShadow="0 0 0 0 rgba(0, 0, 0, 0)"
      border="solid 1px"
      borderColor="dark.mid"
      mb="24px"
      pt={isSmallerScreen ? '16px' : 'inherit'}
      px={isSmallerScreen ? '12px' : 'inherit'}
    >
      <Box mb={isSmallerScreen ? '16px' : '32px'}>
        <Typography variant="h2" mb="16px">
          Cost Breakdown
        </Typography>
      </Box>
      {isSmallerScreen && (
        <Typography mb="24px">
          A breakdown by feature gives clear visibility to where the complexity
          lies. Add contingency if necessary.
        </Typography>
      )}

      {error ? (
        <Typography
          my="20px"
          variant="h4"
          borderLeft="3px solid"
          borderColor="red.solid"
          pl="20px"
        >
          {error}
        </Typography>
      ) : (
        <Form
          onSubmit={onSubmit}
          keepDirtyOnReinitialize
          initialValues={initialValues}
          render={({ handleSubmit, form, values }) => {
            setSubmit(handleSubmit);
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <CostRow
                  hours={!isLoading && roundTheseHours(costBreakdown.coreHours)}
                  cost={!isLoading && formatNumber(costBreakdown.coreCost)}
                  currency={currency}
                  title="Features total (including core team)"
                  mainNameQA={mainNameQA}
                  data-test-id={mainNameQA && `${mainNameQA}--core`}
                  qaValue="core"
                  isSmallerScreen={isSmallerScreen}
                />
                <CostRow
                  currency={currency}
                  title="Additional team total"
                  hours={
                    !isLoading && roundTheseHours(costBreakdown.additionalHours)
                  }
                  cost={
                    !isLoading && formatNumber(costBreakdown.additionalCost)
                  }
                  mainNameQA={mainNameQA}
                  data-test-id={mainNameQA && `${mainNameQA}--additional`}
                  qaValue="additional"
                  isSmallerScreen={isSmallerScreen}
                />
                <CostRow
                  currency={currency}
                  title="Sub total"
                  borderColor="blue.solid"
                  borderWidth="2px"
                  mb="24px"
                  mainNameQA={mainNameQA}
                  data-test-id={mainNameQA && `${mainNameQA}--subtotal`}
                  qaValue="subtotal"
                  hours={
                    !isLoading && roundTheseHours(costBreakdown.proposalHours)
                  }
                  cost={!isLoading && formatNumber(costBreakdown.proposalCost)}
                  isSmallerScreen={isSmallerScreen}
                />

                <ContigencySection
                  isReadOnly={isReadOnly}
                  form={form}
                  currency={currency}
                  values={values}
                  mainNameQA={mainNameQA}
                  finalContingencyValue={
                    !isLoading &&
                    formatNumber(costBreakdown.finalContingencyValue)
                  }
                  isSmallerScreen={isSmallerScreen}
                />
                <CostRow
                  currency={currency}
                  mt="24px"
                  title="Proposal grand total"
                  borderColor="blue.solid"
                  borderWidth="2px"
                  data-test-id={mainNameQA && `${mainNameQA}--grandtotal`}
                  qaValue="grandtotal"
                  hours={roundTheseHours(costBreakdown.proposalGrandTotalHours)}
                  cost={formatNumber(costBreakdown.proposalGrandTotal)}
                  mainNameQA={mainNameQA}
                  isSmallerScreen={isSmallerScreen}
                />
                <AutoSave
                  currency={currency}
                  formApi={form}
                  onSave={onSubmit}
                  formName="costBreakdown"
                />
              </form>
            );
          }}
        />
      )}
    </WhiteCard>
  );
};

export default inject('proposalsStore')(observer(CostBreakdownForm));
