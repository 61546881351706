import {
  find,
  filter,
  map,
  noop,
  orderBy,
  lowerCase,
  isNil,
  isEmpty,
  take,
} from 'lodash-es';
import useMediaQuery from '@hooks/useMediaQuery';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form } from 'react-final-form';
import { ASSIGNMENT_STATUSES } from '@app/constants';
import DiscardBriefModal from '@pages/BriefsCreate/DiscardBriefModal';
import Button from '@styles/Button';
import Icon from '@components/Icon';
import SupplierNoteModal from './SupplierNoteModal';
import ShortlistedSupplierRow from './ShortlistedSupplierRow';
import MobileAssignmentCard from './MobileAssignmentCard';

const renderAssignmentItem = (isSmallerScreen, mainNameQA, props) => {
  const { assignment } = props;
  if (isSmallerScreen) {
    return (
      <MobileAssignmentCard
        key={`assignment-mobile-item-${assignment?.id}`}
        {...props}
      />
    );
  }
  return (
    <ShortlistedSupplierRow
      key={`assignment-item-${assignment?.id}`}
      mainNameQA={mainNameQA}
      {...props}
    />
  );
};

const ShortlistedSuppliersForm = ({
  briefAssignmentsStore: {
    decoratedAssignments: assignments,
    unassignSupplier,
    remindSuppliers,
    assignSupplier,
  },
  briefsStore: { brief },
  mainNameQA,
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const [showAll, setShowAll] = React.useState(false);

  const initialValues = React.useMemo(() => {
    const orderedAssignments = map(
      orderBy(assignments, item => lowerCase(item?.supplier?.name), ['asc']),
      a => ({ exclusivityDuration: 4, ...a }),
    );
    return { assignments: orderedAssignments };
  }, [assignments]);

  const [idToDelete, setIdToDelete] = React.useState();
  const [currentAssignment, setCurrentAssignment] = React.useState();

  const onAssign = (values, formApi) =>
    assignSupplier(
      {
        briefId: brief.id,
        btsId: currentAssignment?.id,
        exclusivityEnabled: currentAssignment?.exclusivityEnabled,
        exclusivityDuration: currentAssignment?.exclusivityDuration,
        message: values.message,
        closeModalCb: () => setCurrentAssignment({}),
      },
      formApi,
    );

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={noop}
        render={({ handleSubmit, values, form: formApi }) => {
          const isExclusivitySelected = React.useMemo(
            () => !!find(values.assignments, a => a.exclusivityEnabled),
            [values.assignments],
          );

          const isAnyAssignmentExclusive = React.useMemo(
            () => !!find(values.assignments, a => a.exclusivityDuration),
            [values.assignments],
          );
          const shouldShowExclusiveCheckbox = React.useCallback(
            assignment => {
              if (
                assignment.status === ASSIGNMENT_STATUSES.NOT_INTERESTED ||
                assignment.status === ASSIGNMENT_STATUSES.CANCELLED
              ) {
                return false;
              }
              return !find(
                filter(
                  values.assignments,
                  a =>
                    a.status !== ASSIGNMENT_STATUSES.NOT_INTERESTED &&
                    a.status !== ASSIGNMENT_STATUSES.CANCELLED,
                ),
                a => a.status !== ASSIGNMENT_STATUSES.ASSIGNMENT_PENDING,
              );
            },
            [values.assignments],
          );
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              {map(
                showAll ? values.assignments : take(values.assignments, 5),
                (assignment, idx) =>
                  renderAssignmentItem(isSmallerScreen, mainNameQA, {
                    isExclusivitySelected,
                    isAnyAssignmentExclusive,
                    shouldShowExclusiveCheckbox,
                    assignment,
                    fieldPrefix: `assignments[${idx}]`,
                    formApi,
                    onDelete: () => setIdToDelete(assignment?.supplier?.id),
                    onRemind: () => remindSuppliers(assignment?.supplier),
                    onAssign: () => setCurrentAssignment(assignment),
                  }),
              )}
              {setShowAll && assignments.length > 5 && (
                <Button
                  mt="24px"
                  display="flex"
                  onClick={() => setShowAll(!showAll)}
                  variant="ghost"
                  mx="auto"
                  color="primary.400"
                  data-test-id={`${mainNameQA}--showAll`}
                  rightIcon={
                    <Icon
                      name="chevronDown"
                      fontSize={12}
                      transform={showAll ? 'rotate(-180deg)' : 'rotate(0deg)'}
                      position="relative"
                    />
                  }
                >
                  {showAll
                    ? `Hide Delivery Partners`
                    : `Show more Delivery Partners`}
                </Button>
              )}
            </form>
          );
        }}
      />

      <SupplierNoteModal
        onSubmit={onAssign}
        initialValues={{ briefId: brief.id }}
        isSingleSupplier
        isOpen={!isEmpty(currentAssignment)}
        onClose={() => setCurrentAssignment({})}
        mainNameQA={mainNameQA}
      />
      <DiscardBriefModal
        isOpen={!isNil(idToDelete)}
        discardQuote="Remove this delivery partner?"
        modalMainText="If you remove this delivery partner, they will no longer have access to this brief"
        cancelCTA="Go back to brief"
        discardCTA="Remove delivery partner"
        onDiscardBrief={async () => {
          await unassignSupplier(idToDelete);
          setIdToDelete(null);
        }}
        onClose={() => setIdToDelete(null)}
        maxWidth="820px"
      />
    </>
  );
};

export default inject(
  'briefsStore',
  'briefAssignmentsStore',
)(observer(ShortlistedSuppliersForm));
