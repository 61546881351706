import { Box, Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { isEmpty, isObject, isString, last, map } from 'lodash-es';
import React from 'react';

const ReadOnlyTable = ({ columns, tableData, qaTag, mainNameQA, ...props }) => {
  if (isEmpty(tableData)) {
    return (
      <Typography variant="h3" mt="42px" mb="10px" textAlign="center">
        No records to display.
      </Typography>
    );
  }
  return (
    <>
      <Flex w="100%" mb="12px" mt="24px" color="dark.solid" {...props}>
        {map(columns, ({ name, w, component, ellipsis, ...colProps }) => (
          <Typography
            key={`column-${name}`}
            variant="caption"
            w={w || 'auto'}
            {...colProps}
          >
            {name}
          </Typography>
        ))}
      </Flex>
      {map(tableData, (row, i) => (
        <Flex
          border="1px solid"
          borderColor="dark.mid"
          borderRadius="8px"
          bg="#fff"
          data-test-id={mainNameQA && `${mainNameQA}--row`}
          key={`table-data-row-${i}`}
          position="relative"
          mb={
            tableData[i].length > columns.length && last(tableData[i])
              ? '60px'
              : '12px'
          }
        >
          {tableData[i].length > columns.length && last(tableData[i]) && (
            <Box
              position="absolute"
              w="100%"
              border="1px solid"
              borderColor="dark.mid"
              px="20px"
              display="flex"
              alignItems="center"
              borderBottomRightRadius="8px"
              borderBottomLeftRadius="8px"
              top="68px"
              h="50px"
              bg="dark.light"
              left="0px"
            >
              {last(tableData[i])}
            </Box>
          )}
          {map(
            columns,
            (
              { w, component: Component, ellipsis = true, ...colProps },
              idx,
            ) => (
              <Box
                as={Flex}
                px="10px"
                minH="72px"
                alignItems="center"
                w={w}
                borderRight="1px solid"
                borderColor="dark.mid"
                _last={{ borderRight: 'none' }}
                {...colProps}
                key={`table-data-item-${i}-${idx}`}
              >
                {Component ? (
                  <Component
                    value={isString(row[idx]) ? row[idx] : row[idx].value}
                    {...(qaTag && {
                      'data-test-id': `${qaTag}--${colProps.name}`,
                    })}
                    {...(isObject(row[idx]) && row[idx])}
                  />
                ) : (
                  <Typography
                    py="10px"
                    wordBreak="break-word"
                    {...(qaTag && {
                      'data-test-id': `${qaTag}--${colProps.name}`,
                    })}
                    {...(ellipsis && {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    })}
                  >
                    {isObject(row[idx]) ? row[idx].value : row[idx]}
                  </Typography>
                )}
              </Box>
            ),
          )}
        </Flex>
      ))}
    </>
  );
};
export default ReadOnlyTable;
