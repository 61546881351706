import { find, forEach, filter, map, reject, uniqBy } from 'lodash-es';
import qs from 'query-string';
import { action, computed, makeObservable, observable, reaction } from 'mobx';

export default class TasksStore {
  constructor({ API, toastsStore, usersStore, routerStore }) {
    makeObservable(this);
    this.toastsStore = toastsStore;
    this.API = API;
    this.routerStore = routerStore;
    this.usersStore = usersStore;

    this._fetchTasksCount();
  }

  @observable tasksCount = 0;

  @observable tasks = {
    data: [],
    paging: {},
    isLoading: false,
  };

  @action fetchTasks = async params => {
    if (
      this.tasks.isLoading ||
      (params.page > 1 && !this.tasks.paging.hasMore)
    ) {
      return;
    }
    let { page } = params;
    const filteredTasks = reject(this.tasks.data, { isCompleted: true });
    if ((params.page - 1) * params.pageSize > filteredTasks.length) {
      page = Math.ceil(filteredTasks.length / params.pageSize);
    }
    this.tasks.isLoading = true;
    if (params.page === 1) {
      this.tasks.paging.totalObjects = '...';
      this.tasks.data = [];
    }
    try {
      const {
        data: { items, paging },
      } = await this.API.getTasks({ params: { ...params, page } });
      this.tasks.data = uniqBy([...this.tasks.data, ...items], 'id');
      this.tasks.paging = paging;
      this.tasks.paging.hasMore = paging.totalPages > paging.page;
    } catch (e) {
      this.toastsStore.showError({ title: e?.message || 'Network error' });
    } finally {
      this.tasks.isLoading = false;
    }
  };

  @action toggleTaskSelection = taskId => {
    const isSelected = find(this.tasks.data, t => t.id === taskId)?.isSelected;
    this.setTaskData(taskId, { isSelected: !isSelected });
  };

  @action unselectAllTasks = () => {
    this.tasks.data = map(this.tasks.data, task => ({
      ...task,
      isSelected: false,
    }));
  };

  @computed get selectedTasksCount() {
    return filter(this.tasks.data, { isSelected: true }).length;
  }

  @action triggerScrollOnShortenedList = async () => {
    // infinitescroller workaround
    if (
      this.tasks.data.length <
      this.tasks.paging.pageSize * this.tasks.paging.page - 5
    ) {
      window.scrollTo(window.scrollX, window.scrollY + 1200);
      window.scrollTo(window.scrollX, window.scrollY - 1200);
    }
  };

  @action completeTask = async taskId => {
    try {
      this.setTaskData(taskId, { isLoading: true });
      await this.API.taskCompleteAction(taskId);
      this.tasksCount -= 1;
      this.tasks.data = reject(this.tasks.data, { id: taskId });
      this.triggerScrollOnShortenedList();
      this.tasks.paging.totalObjects -= 1;
      this.toastsStore.showSuccess({
        title: 'Action has been completed',
      });
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
      this.setTaskData(taskId, { isLoading: false });
    }
  };

  @action setTaskData = (taskId, obj) => {
    this.tasks.data = map(this.tasks.data, t => ({
      ...t,
      ...(t.id === taskId && obj),
    }));
  };

  @action reassignTask = async (user, taskId) => {
    const taskIds = taskId
      ? [taskId]
      : map(
          filter(this.tasks.data, task => task.isSelected),
          t => t.id,
        );

    forEach(taskIds, tId => {
      const prevAssignee =
        find(this.tasks.data, t => t.id === tId)?.assignee ||
        find(this.tasks.data, t => t.id === tId)?.owner;
      this.setTaskData(tId, { assignee: user, prevAssignee });
    });
    try {
      await this.API.reassignTask(user?.id, taskIds);
      const {
        location: { search },
      } = this.routerStore;
      const { status = 'Open' } = qs.parse(search);
      const isMe = user?.id === this.usersStore.profile?.id;
      if ((status === 'Open' && !isMe) || (status === 'Reassigned' && isMe)) {
        this.tasks.data = reject(this.tasks.data, task =>
          find(taskIds, tId => tId === task.id),
        );
        this.tasksCount =
          status === 'Open'
            ? this.tasksCount - taskIds.length
            : this.tasksCount + taskIds.length;
        this.tasks.paging.totalObjects -= taskIds.length;
      } else {
        this.unselectAllTasks();
      }
      this.triggerScrollOnShortenedList();
      this.toastsStore.showSuccess({
        title: `${taskIds.length} ${
          taskIds.length > 1 ? 'tasks have' : 'task has'
        } been reassigned successfully!`,
      });
    } catch {
      this.toastsStore.showError({
        title:
          'Ooops! Something unexpected happened. Assignee could not be changed. Please try again later.',
      });
      forEach(taskIds, tId => {
        const prevAssignee = find(this.tasks.data, task => tId === task.id)
          ?.prevAssignee;
        this.setTaskData(tId, { assignee: prevAssignee, prevAssignee: null });
      });
    }
  };

  taskInterval = null;

  fetchTasksCount = async () => {
    try {
      const {
        data: { count = 0 },
      } = await this.API.getTasksCount();
      this.tasksCount = count;
    } catch {
      //
    }
  };

  @action _fetchTasksCount = async () => {
    reaction(
      () => this.usersStore.isAdminOrDL,
      async isAdminOrDL => {
        if (isAdminOrDL) {
          this.fetchTasksCount();
          this.taskInterval = setInterval(this.fetchTasksCount, 1000 * 60);
        } else {
          clearInterval(this.taskInterval);
        }
      },
    );
  };
}
