import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { MAIN_NAMES_QA } from '@app/constants';
import { isEmpty, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AllocationCard from '../AllocationCard';
import { getDeazyAllocationsColumns } from '../constants';
import Drawer from '../Drawer';

const AllDeazyActuals = ({
  projectsStore: { project },
  allocationsStore: {
    fetchAllDeazyActuals,
    allDeazyActuals,
    deazyActuals: {
      all: { isLoading },
    },
  },
}) => {
  React.useEffect(() => {
    if (project.id) {
      fetchAllDeazyActuals(project.id);
    }
  }, [project.id]);

  return (
    <Drawer>
      <Typography variant="h3" mb="80px">
        All deazy actuals
      </Typography>
      {map(allDeazyActuals, allocation => (
        <AllocationCard
          mainNameQA={MAIN_NAMES_QA.PROJECT_ACTUALS}
          key={`allocation-entry-card-${allocation.id}`}
          allocation={allocation}
          data-test-id={`${MAIN_NAMES_QA.PROJECT_ACTUALS}--actualCard`}
          columns={getDeazyAllocationsColumns({
            isFixedPrice: false,
          })}
        />
      ))}
      {isLoading && <Spinner />}
      {isEmpty(allDeazyActuals) && !isLoading && (
        <Typography variant="h3" textAlign="center" my="40px">
          No actuals found.
        </Typography>
      )}
    </Drawer>
  );
};

export default inject(
  'allocationsStore',
  'projectsStore',
)(observer(AllDeazyActuals));
