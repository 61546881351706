import { Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';
import BlueRadioGroup from './BlueRadioGroup';

const PerfectMatchQuestion = ({
  title,
  description,
  fieldName,
  mainNameQA,
}) => {
  return (
    <Flex
      w="100%"
      borderTop="1px solid #D9DADA"
      alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
      justifyContent="space-between"
      data-test-id={`${mainNameQA}--matchQuestionSingle`}
      flexDir={['column', 'column', 'column', 'row']}
      pb={['24px', '24px', '24px', 0]}
    >
      <Flex flexDir="column" mt={['24px', '24px', '24px', '30px']} mr="12px">
        <Typography variant="h5" mb="8px">
          {title}
        </Typography>
        <Typography
          mb={['24px', '24px', '24px', '32px']}
          data-test-id={`${mainNameQA}--matchQuestion--${fieldName}`}
        >
          {description}
        </Typography>
      </Flex>
      <BlueRadioGroup name={fieldName} />
    </Flex>
  );
};

export default PerfectMatchQuestion;
