import { API } from '@app/api';
import { ACTION_TYPES, MAIN_NAMES_QA } from '@app/constants';
import { Flex } from '@chakra-ui/react';
import useMediaQuery from '@hooks/useMediaQuery';
import EmptyResults from '@components/EmptyResults';
import PageChips from '@components/PageChips';
import PageResults from '@components/PageResults';
import PageSize from '@components/PageSize';
import Pagination from '@components/Pagination';
import useListing from '@hooks/useListing';
import { createSupplierNamesLabel } from '@utils/projectUtils';
import {
  forEach,
  isEmpty,
  isString,
  join,
  map,
  find,
  toNumber,
  values as _values,
} from 'lodash-es';
import { inject, observer } from 'mobx-react';
import Button from '@styles/Button';
import moment from 'moment';
import { nanoid } from 'nanoid';
import useDownload from '@hooks/useDownload';
import qs from 'query-string';
import React from 'react';
import ProjectsEmpty from './ProjectsEmpty';
import ProjectsFilters from './ProjectsFilters';
import ProjectsList from './ProjectsList';

let actionTypes = _values(ACTION_TYPES);

const projectsMapping = projects =>
  map(projects, project => ({
    ...project,
    actionsForMe: map(project.actionsForMe, a => ({
      ...a,
      id: nanoid(10),
    })),
    actionsForOthers: map(project.actionsForOthers, a => ({
      ...a,
      id: nanoid(10),
    })),
    supplierNamesLabel: createSupplierNamesLabel(project),
  }));

const Projects = ({
  usersStore: {
    isClient,
    isTeamAdmin,
    isAdminOrDL,
    isAdmin,
    forceJobsByProject,
    isTeamMember,
  },
  projectsStore: {
    deleteProject,
    isDeleting,
    archiveOrUnarchiveProject,
    getProjectsArchiveSource,
    projectsReadyToArchive,
  },
  settingsStore: { settings },
  history,
  location: { search },
  isProjectsListRoute,
}) => {
  const mainNameQA = MAIN_NAMES_QA.PROJECTS_LIST;
  const {
    onDownload: onCsvDownload,
    isLoading: isDownloadingCsv,
  } = useDownload({
    url: '/mailing/delivery-partner-outstanding-invoices',
  });

  const params = qs.parse(search);
  const {
    page = 1,
    orderDirection = 'DESC',
    orderBy = 'createdAt',
    onlyActionRequired = 'false',
    onlyWithActions = 'false',
    supplierIds,
    supplierNames,
    ragStatus: rags,
    actionType: actions,
    status = 'active',
    query,
    ppBriefId,
    briefId,
  } = params;

  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const filters = { onlyActionRequired, onlyWithActions, status };

  const canDeleteProject = p =>
    (settings.canDeleteStartedProjects ||
      moment.utc(p.startDate).isAfter(moment.utc().endOf('day'))) &&
    isAdmin;

  const projectTypeDV = () => {
    if (status === 'active') {
      return 'active';
    }
    if (status === 'pending') {
      return 'pending';
    }
    if (status === 'archived') {
      return 'archived';
    }
    return 'all-projects';
  };

  const transformToInitialValue = el => map(isString(el) ? [el] : el, v => v);

  const initialValues = () => ({
    status: projectTypeDV(),
    sort: `orderDirection=${orderDirection}&orderBy=${orderBy}`,
    query,
    ppBriefId,
    briefId,
    actionType: transformToInitialValue(actions),
    ragStatus: transformToInitialValue(rags),
    suppliers: map(
      isString(supplierIds) ? [supplierIds] : supplierIds,
      (id, idx) => ({
        value: toNumber(id),
        label: isString(supplierIds) ? supplierNames : supplierNames[idx],
      }),
    ),
  });

  const onSubmit = values => {
    const valueMapping = ({ value }) => value;

    return history.push(
      `${history.location.pathname}?${qs.stringify({
        status: values.status,
        query: values.query,
        ragStatus: values.ragStatus,
        actionType: values.actionType,
        supplierIds: map(values.suppliers, valueMapping),
        supplierNames: map(values.suppliers, ({ label }) => label),
        ppBriefId: values.ppBriefId,
        briefId: values.briefId,
      })}&${values.sort}`,
    );
  };

  const isMergingView = React.useMemo(() => {
    return !!(ppBriefId || briefId);
  }, [ppBriefId, briefId]);

  const {
    items: projects,
    totalPages,
    isLoading,
    totals,
    onFilterChange,
    fetch: refetch,
  } = useListing(
    ({ params: { actionType, ragStatus, ...paramsToListing } }) => {
      actionTypes = {};
      forEach(isString(actionType) ? [actionType] : actionType, type => {
        actionTypes[type] = true;
      });

      const ragStatuses = {};

      forEach(isString(ragStatus) ? [ragStatus] : ragStatus, type => {
        ragStatuses[type] = true;
      });

      const apiEndpoint = isMergingView
        ? API.getProjectsForBriefMerge(ppBriefId || briefId)
        : API.getProjects;

      return apiEndpoint({
        params: {
          ...paramsToListing,
          ...(!isMergingView && actionTypes),
          ...ragStatuses,
          status,
          supplierIds: isString(supplierIds)
            ? supplierIds
            : join(supplierIds, ','),
        },
      });
    },
    filters,
    projectsMapping,
  );

  React.useEffect(() => {
    if (isAdminOrDL && !isEmpty(projects)) {
      getProjectsArchiveSource({
        params: { projects: `${map(projects, ({ id }) => id)}` },
      });
    }
  }, [projects]);

  const fetchProjects = () => {
    refetch({
      page: (projects || []).length === 1 && page > 1 ? page - 1 : page,
    });
  };

  const isListEmpty = !isLoading && isEmpty(projects);
  const isFiltered = !isEmpty(params);

  const mappedProjects = map(projects, project => ({
    ...project,
    readyToArchive: find(
      projectsReadyToArchive,
      projectToArchive => projectToArchive.id === project.id,
    )?.readyToArchive,
  }));
  return (
    <>
      <Flex>
        <ProjectsFilters
          onSubmit={onSubmit}
          initialValues={initialValues()}
          isAdminOrDL={isAdminOrDL}
          isLoading={isLoading}
          isMergingView={isMergingView}
        />
        <Flex direction="column" px="24px" pt="16px" flex={1}>
          {(!isListEmpty || isFiltered) && (
            <Flex
              w="100%"
              justify="space-between"
              mb="12px"
              display="-webkit-inline-box"
            >
              <PageResults
                mainNameQA={mainNameQA}
                totalPages={totalPages}
                currentPage={page}
                totals={totals}
              />
              {isAdmin && (
                <Button
                  data-test-id={`${mainNameQA}--paymentsButton`}
                  size="lg"
                  leftIcon={{ name: 'download', fontSize: 20, mr: 0 }}
                  onClick={onCsvDownload}
                  isLoading={isDownloadingCsv}
                >
                  DP payments due
                </Button>
              )}
            </Flex>
          )}
          <PageChips />
          <ProjectsList
            mainNameQA={mainNameQA}
            canDeleteProject={canDeleteProject}
            isDeleting={isDeleting}
            deleteProject={(id, cb) =>
              deleteProject(id, async () => {
                fetchProjects({
                  page:
                    (projects || []).length === 1 && page > 1 ? page - 1 : page,
                });
                cb();
              })
            }
            projects={mappedProjects}
            fetchProjects={fetchProjects}
            isLoading={isLoading}
            isAdmin={isAdmin}
            isAdminOrDL={isAdminOrDL}
            isTeamMember={isTeamMember}
            isTeamAdmin={isTeamAdmin}
            isClient={isClient}
            forceJobsByProject={forceJobsByProject}
            archiveOrUnarchiveProject={archiveOrUnarchiveProject}
            isProjectsListRoute={isProjectsListRoute}
            isMergingView={isMergingView}
          />
          {isFiltered && isListEmpty && <EmptyResults />}
          {isListEmpty && !isFiltered && (
            <ProjectsEmpty
              status={status}
              isClient={isClient}
              isTeamAdmin={isTeamAdmin}
              isAdminOrDL={isAdminOrDL}
            />
          )}
          <Flex
            flexDirection={isSmallerScreen && 'column'}
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            pt="calc(24px - 16px)"
            pb="72px"
          >
            {totals > 0 && (
              <PageSize
                containerProps={isSmallerScreen && { mb: '12px', minW: 'auto' }}
              />
            )}
            <Pagination
              forcePage={+page - 1}
              pageCount={totalPages}
              onPageChange={p => onFilterChange({ page: p })}
              containerProps={isSmallerScreen && { mb: '85px' }}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default inject(
  'usersStore',
  'projectsStore',
  'settingsStore',
)(observer(Projects));
