import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import { isEmpty, map, find } from 'lodash-es';
import { DropdownOption } from '@pages/Briefs/BriefCardBadge';
import { inject, observer } from 'mobx-react';
import { VISIBLE_COSTS, FEATURES_BREAKDOWN } from '@app/constants';
import React from 'react';
import { formatCurrency } from '@utils';
import StatusDropdownTrigger, {
  NEW_PROPOSAL_STATUSES_OPTIONS,
} from '@components/StatusDropdownTrigger';
import ChangeStatusCommentModal from '../Proposal/ChangeStatusCommentModal';

const wrapperDesktopProps = {
  top: '28px',
  right: '16px',
};

const ProposalCardBadge = ({
  brief,
  onStatusChange,
  proposal,
  fetchDeazyCostBreakdown,
  deazyCostBreakdown,
  usersStore: { isClient },
  mainNameQA,
  isSmallerScreen,
}) => {
  const isPlugAndPlay = !isEmpty(proposal.ppBriefToSupplier);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenCommentModal,
    onOpen: onCommentModalOpen,
    onClose: onCommentModalClose,
  } = useDisclosure();

  const [targetStatus, setTargetStatus] = React.useState('');
  const [value, setValue] = React.useState('');

  const decoratedProposal = { ...proposal, value };

  React.useEffect(() => {
    if (proposal.status) {
      setValue(proposal.status);
    }
  }, [proposal.status]);

  const clientCurrency = 'GBP';

  const fullRangeCost = `${formatCurrency(
    deazyCostBreakdown.data.proposalMinCost,
    clientCurrency,
  )} - ${formatCurrency(
    deazyCostBreakdown.data.proposalMaxCost,
    clientCurrency,
  )}`;

  const optionsWithDisabled = map(NEW_PROPOSAL_STATUSES_OPTIONS, opt => {
    return {
      ...opt,
      isDisabled: opt.label === decoratedProposal.value,
    };
  });

  const currentOption = find(optionsWithDisabled, { value }) || {};

  const shareSettingsForClientInnitialValues = proposal.clientSettings || {
    featuresBreakdown: FEATURES_BREAKDOWN.HIDDEN_COSTS,
    visibleCosts: VISIBLE_COSTS.ACTUAL_ESTIMATE,
  };

  const onSubmit = values =>
    onStatusChange({
      v: values,
      proposalId: proposal.id,
      targetStatus,
      successCb: () => {
        setValue(targetStatus);
        onCommentModalClose();
      },
    });

  if (!value) {
    return null;
  }

  return (
    <>
      <Popover isOpen={isOpen} usePortal onClose={onClose}>
        <PopoverTrigger>
          <Box
            background="transparent"
            position={isSmallerScreen ? 'relative' : 'absolute'}
            {...(!isSmallerScreen && { ...wrapperDesktopProps })}
            marginLeft="auto"
          >
            <StatusDropdownTrigger
              onOpen={e => {
                e.preventDefault();
                e.stopPropagation();
                onOpen();
              }}
              currentOption={currentOption}
              isReadOnly={brief.isClosed || isClient}
              mainNameQA={mainNameQA}
              qaValue="proposalStatus"
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent
          zIndex={4}
          w="200px"
          border="1px solid"
          borderColor="#d9dada"
          px="4px"
          py="8px"
          borderRadius="8px"
          boxShadow="0 8px 44px 0 rgba(0, 0, 0, 0.15)"
        >
          <PopoverBody p="0px" textAlign="center" zIndex={200}>
            {map(optionsWithDisabled, (st, index) => (
              <DropdownOption
                isDisabled={st.isDisabled}
                key={`proposal-status-${st.value}-${index}`}
                isSelected={st.value === value}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setTargetStatus(st.value);
                  onClose();
                  fetchDeazyCostBreakdown(brief.id, proposal.id);
                  onCommentModalOpen();
                }}
              >
                {st.value}
              </DropdownOption>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <ChangeStatusCommentModal
        onSubmit={onSubmit}
        targetStatus={targetStatus}
        isOpen={isOpenCommentModal}
        onClose={onCommentModalClose}
        actualEstimateCost={formatCurrency(
          deazyCostBreakdown.data.proposalCost,
          clientCurrency,
        )}
        hightCost={formatCurrency(
          deazyCostBreakdown.data.proposalMaxCost,
          clientCurrency,
        )}
        fullRangeCost={fullRangeCost}
        initialValues={shareSettingsForClientInnitialValues}
        isPlugAndPlay={isPlugAndPlay}
        mainNameQA={mainNameQA}
      />
    </>
  );
};

export default inject('usersStore')(observer(ProposalCardBadge));
