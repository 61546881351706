import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react';
import Icon from '@components/Icon';
import SectionTitle from '@components/SectionTitle';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import { isEmpty } from 'lodash-es';
import { inject } from 'mobx-react';
import React from 'react';
import AddDeazyLeadToClientModal from './AddDeazyLeadToClientModal';

function AddDeazyLeadToClient({
  usersStore: { isAdminOrDL },
  teamsStore: {
    _client: { id: clientId, deazyLead },
    addDeazyLeadToClient,
    removeDeazyLeadToClient,
  },
}) {
  const {
    onOpen: onAddDeazyLeadToClientModalOpen,
    onClose: onAddDeazyLeadToClientModalClose,
    isOpen: isAddDeazyLeadToClientModalOpen,
  } = useDisclosure();

  const [localDeazyLead, setLocalDeazyLead] = React.useState(null);

  React.useEffect(() => {
    setLocalDeazyLead(deazyLead);
  }, [deazyLead]);

  const handleAddDeazyLeadToClient = async values => {
    await addDeazyLeadToClient({
      clientId,
      userId: values.deazyLead.id,
      onSuccess: () => setLocalDeazyLead(values.deazyLead),
    });
    onAddDeazyLeadToClientModalClose();
  };

  const handleDeleteDeazyLeadToClient = () => {
    removeDeazyLeadToClient({
      clientId,
      onSuccess: () => setLocalDeazyLead(null),
    });
  };

  return (
    <>
      <WhiteCard>
        <SectionTitle mt="0px">Deazy Lead</SectionTitle>
        <Flex right="24px" position="absolute" top="18px">
          {isAdminOrDL && !localDeazyLead && (
            <Button
              pt="3px"
              leftIcon={<Icon name="add" mr="10px" w="10px" mb="5px" />}
              fontWeight="400"
              bg="aqua.solid"
              color="#fff"
              minW="200px"
              maxH="40px"
              borderRadius="30px"
              _hover={{ bg: '#3E898C' }}
              _active={{ bg: '#3E898C' }}
              onClick={onAddDeazyLeadToClientModalOpen}
            >
              Add Deazy Lead
            </Button>
          )}
        </Flex>
        {!isEmpty(localDeazyLead) ? (
          <Flex alignItems="center" justifyContent="space-between" mb="24px">
            <Box>{localDeazyLead && localDeazyLead.fullname}</Box>
            {isAdminOrDL && (
              <Button
                pt="2px"
                bg="red.solid"
                color="#fff"
                variant="solid"
                borderRadius="32px"
                fontWeight="initial"
                size="sm"
                mr="8px"
                px="14px"
                onClick={() => handleDeleteDeazyLeadToClient(clientId)}
                leftIcon={<Icon fontSize={16} mr="5px" name="trash" />}
              >
                Delete
              </Button>
            )}
          </Flex>
        ) : (
          <Typography>Nothing to show here</Typography>
        )}
      </WhiteCard>
      {isAddDeazyLeadToClientModalOpen && (
        <AddDeazyLeadToClientModal
          onSubmit={handleAddDeazyLeadToClient}
          isOpen={isAddDeazyLeadToClientModalOpen}
          onClose={onAddDeazyLeadToClientModalClose}
        />
      )}
    </>
  );
}

export default inject('teamsStore', 'usersStore')(AddDeazyLeadToClient);
