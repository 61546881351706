import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Field } from 'react-final-form';
import DatePicker from '@components/forms/DatePicker';
import TextInput from '@components/forms/TextInput';
import { required } from '@utils/formValidators';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';

const ProjectTimeFrame = ({ mainNameQA }) => {
  return (
    <WhiteCard p="40px" boxShadow="none">
      <Typography variant="h3" mb="24px">
        Duration
      </Typography>
      <Field
        component={DatePicker}
        name="startDate"
        data-test-id={`${mainNameQA}--startDate`}
        label="Start date"
        dateFormat="DD MMMM YYYY"
        containerProps={{ mb: '24px' }}
        validate={required}
      />
      <Field
        component={DatePicker}
        name="endDate"
        data-test-id={`${mainNameQA}--endDate`}
        label="End date"
        dateFormat="DD MMMM YYYY"
        containerProps={{ mb: '24px' }}
        validate={required}
      />
      <Flex w="100%" alignItems="center" justifyContent="center">
        <Field
          component={TextInput}
          type="number"
          name="numberOfWeeks"
          data-test-id={`${mainNameQA}--numberOfWeeks`}
          step={1}
          min={0}
          label="Number of Weeks"
          onClick={e => {
            if (e && e.target && e.target.select) {
              e.target.select();
            }
          }}
          containerProps={{ mr: '20px' }}
        />
        <Field
          type="number"
          component={TextInput}
          name="numberOfDays"
          data-test-id={`${mainNameQA}--numberOfDays`}
          min={0}
          step={1}
          label="Number of Days"
          onClick={e => {
            if (e && e.target && e.target.select) {
              e.target.select();
            }
          }}
          containerProps={{ ml: '20px' }}
        />
      </Flex>
    </WhiteCard>
  );
};

export default ProjectTimeFrame;
