import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useDisclosure,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import Icon from '@components/Icon';
import { isEmpty } from 'lodash-es';
import React from 'react';

const StyledInput = styled(Input)`
  width: 320px;
  height: 40px;
  border-radius: 8px;
  border: solid 1px #9fc5ff;
  font-size: 14px;
  &:focus {
    box-shadow: 0 0 0 1px #9fc5ff;
  }
`;

const SearchBar = ({
  initialValue = '',
  onChange = () => {},
  placeholder = 'Search any project or something',
  ...props
}) => {
  const [mounted, setMounted] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: !!initialValue,
  });

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (mounted && isOpen && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (isOpen && isEmpty(initialValue)) {
      onClose();
    }
  }, [initialValue]);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  if (!isOpen) {
    return (
      <Icon
        onClick={onOpen}
        cursor="pointer"
        name="search"
        color="gray.500"
        fontSize={18}
        width={6}
      />
    );
  }

  return (
    <InputGroup {...props}>
      <InputLeftElement>
        <Icon onClick={onOpen} name="search" color="gray.500" fontSize={18} />
      </InputLeftElement>
      {isOpen && (
        <InputRightElement right="6px">
          <Icon
            onClick={() => {
              onClose();
              onChange('');
            }}
            name="close"
            cursor="pointer"
            color="gray.500"
            fontSize={22}
          />
        </InputRightElement>
      )}
      {isOpen && (
        <StyledInput
          ref={inputRef}
          defaultValue={initialValue}
          onChange={e => onChange(e.target.value)}
          _focus={{ borderColor: 'dark.light', boxShadow: 'none' }}
          pl="35px"
          pr="35px"
          type="text"
          borderColor="dark.solid"
          placeholder={placeholder}
        />
      )}
    </InputGroup>
  );
};
export default SearchBar;
