import React from 'react';
import { inject, observer } from 'mobx-react';
import { addFullDescription } from '@utils/usersUtils';
import { get } from 'lodash-es';
import { MAIN_NAMES_QA } from '@app/constants';
import AskQuestionSection from './AskQuestionSection';
import QuestionsList from './QuestionsList';
import QuestionsFooter from './QuestionsFooter';

const Questions = ({
  questionsStore: { createQuestion },
  briefsStore: { brief, acceptBrief, declineBrief },
  usersStore: { isClient, profile },
}) => {
  const { isAccepting, isDeclining } = brief;
  const author = addFullDescription(get(profile, 'roles[0].name'));
  const mainNameQA = MAIN_NAMES_QA.BRIEF_QUESTIONS;

  return (
    <>
      <AskQuestionSection
        brief={brief}
        isClient={isClient}
        createQuestion={createQuestion}
        author={author}
        mainNameQA={mainNameQA}
      />
      <QuestionsList briefId={brief.id} mainNameQA={mainNameQA} />
      {false && (
        <QuestionsFooter
          isAccepting={isAccepting}
          isDeclining={isDeclining}
          acceptBrief={() => acceptBrief(brief.id)}
          declineBrief={() => declineBrief(brief.id)}
          mainNameQA={mainNameQA}
        />
      )}
    </>
  );
};

export default inject(
  'briefsStore',
  'usersStore',
  'questionsStore',
)(observer(Questions));
