import React from 'react';
import { Link } from 'react-router-dom';
import { MAIN_NAMES_QA } from '@app/constants';
import { inject, observer } from 'mobx-react';
import ZoneContainer from '@components/ZoneContainer';
import Button from '@styles/Button';
import { Box, useDisclosure } from '@chakra-ui/react';
import Single from './Single';
import ChangeRequestModal from './ChangeRequestModal';

const ChangeRequests = ({
  projectId,
  crsStore: { createOrUpdateCR },
  usersStore: { isAdminOrDL, isTeamAdmin, isTeamMember },
  location: { pathname },
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [initialValues, setInitialValues] = React.useState({});

  const onModalOpen = initials => {
    setInitialValues({
      ...initials,
    });
    onOpen();
  };

  return (
    <>
      <Box maxW="1280px" px="40px" w="100%" mx="auto" pb="60px" mt="32px">
        {(isAdminOrDL || isTeamAdmin) && (
          <ZoneContainer bg="#fff" borderColor="dark.mid" py="24px" mb="32px">
            <Button
              onClick={() => {
                setInitialValues({});
                onOpen();
              }}
              variant="outline"
              leftIcon={{ name: 'add' }}
              data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--createCRButton`}
              size="lg"
            >
              Create change request
            </Button>
          </ZoneContainer>
        )}
        <Single projectId={projectId} onEdit={onModalOpen} />
        <Button
          fontWeight="400"
          fontSize={18}
          variant="outline"
          border="2px solid"
          borderColor="blue.solid"
          borderRadius="30px"
          h="56px"
          as={Link}
          to={`${pathname}/all`}
          my="12px"
          data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--seeAllCRsButton`}
        >
          See all change requests
        </Button>
        <ChangeRequestModal
          isOpen={isOpen}
          isTeamMember={isTeamMember}
          isTeamAdmin={isTeamAdmin}
          onClose={onClose}
          initialValues={initialValues}
          onSubmit={values => createOrUpdateCR(values, onClose)}
        />
      </Box>
    </>
  );
};

export default inject(
  'paymentsStore',
  'crsStore',
  'projectsStore',
  'usersStore',
)(observer(ChangeRequests));
