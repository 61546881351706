import { Box } from '@chakra-ui/react';
import Button from '@styles/Button';
import React from 'react';

const ExportToPDFButton = ({
  label = 'Export as PDF',
  onDownload,
  isLoadingDownload,
}) => (
  <Box mt="20px">
    <Button onClick={onDownload} ml="auto" isLoading={isLoadingDownload}>
      {label}
    </Button>
  </Box>
);
export default ExportToPDFButton;
