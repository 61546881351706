import { Box, Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import { Field, Form } from 'react-final-form';
import TextInput from '@components/forms/TextInput';
import WhiteCard from '@components/WhiteCard';
import { ErrorText } from '@components/forms/_common/FormError';
import { StickyFooter } from '@components/StickyFooterCostBox';
import {
  composeValidators,
  mustBeValidEmail,
  passwordsMustMatch,
  required,
} from '@utils/formValidators';
import Button from '@styles/Button';
import FileUpload from '../UserCreate/FileUpload';

const validate = ({ newPassword, currentPassword, repeatPassword }) => {
  if (newPassword || currentPassword || repeatPassword) {
    return {
      newPassword: required(newPassword),
      currentPassword: required(currentPassword),
      repeatPassword: composeValidators(
        required,
        passwordsMustMatch,
      )(repeatPassword, { newPassword }),
    };
  }
};

const ProfileForm = ({ initialValues, onSubmit }) => {
  return (
    <WhiteCard p="40px">
      <Form
        onSubmit={onSubmit}
        validate={validate}
        subscription={{
          submitError: true,
          dirty: true,
          submitting: true,
        }}
        initialValues={initialValues}
        render={({ submitError, handleSubmit, submitting, dirty, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box mb="40px">
                <Field
                  name="profilePicture"
                  component={({ input, meta }) => {
                    return (
                      <>
                        <FileUpload
                          value={input.value}
                          onUploaded={input.onChange}
                          label="Upload image"
                          labelToChangeFile="Change profile photo"
                          allowedExtensions={['.png', '.jpg', '.jpeg']}
                        />
                        {meta.submitError && (
                          <ErrorText textAlign="center">
                            {meta.submitError}
                          </ErrorText>
                        )}
                      </>
                    );
                  }}
                />
              </Box>
              <Stack w="100%" isInline spacing="40px" mb="48px">
                <Box w="50%">
                  <Field
                    name="fullname"
                    component={TextInput}
                    placeholder="Enter your full name"
                    label="Full name"
                    validate={required}
                  />
                </Box>
                <Box w="50%">
                  <Field
                    name="email"
                    component={TextInput}
                    placeholder="Enter your email"
                    label="Email"
                    validate={composeValidators(required, mustBeValidEmail)}
                  />
                </Box>
              </Stack>
              <Stack w="100%" isInline spacing="40px" mb="24px">
                <Box w="50%">
                  <Field
                    name="currentPassword"
                    component={TextInput}
                    placeholder="Current password"
                    autoComplete="new-password"
                    type="password"
                    label="Current Password"
                  />
                </Box>
                <Box w="50%" />
              </Stack>
              <Stack w="100%" isInline spacing="40px" alignItems="end">
                <Box w="50%">
                  <Field
                    name="newPassword"
                    type="password"
                    component={TextInput}
                    placeholder="Enter new password"
                    label="New Password"
                  />
                </Box>
                <Box w="50%">
                  <Field
                    name="repeatPassword"
                    component={TextInput}
                    type="password"
                    placeholder="Repeat new password"
                    label="Repeat New Password"
                  />
                </Box>
              </Stack>
              {submitError && <ErrorText my="12px">{submitError}</ErrorText>}

              <StickyFooter as={Flex}>
                {dirty && (
                  <Button
                    fontWeight="400"
                    variant="outline"
                    isDisabled={submitting}
                    onClick={() => form.restart()}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  ml="auto"
                  type="submit"
                  fontWeight="400"
                  isDisabled={!dirty}
                  isLoading={submitting}
                >
                  Save changes
                </Button>
              </StickyFooter>
            </form>
          );
        }}
      />
    </WhiteCard>
  );
};

export default ProfileForm;
