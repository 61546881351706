import { Flex, Link } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';
import Button from '@styles/Button';

const Page403 = ({ goBack }) => {
  return (
    <Flex minW="100vw" minH="100vh" bg="dark.light" justifyContent="center">
      <Flex maxW="880px" px="40px" flexDir="column" pt="10%">
        <Typography fontSize={120} mb="70px" fontFamily="PP" textAlign="center">
          403!
        </Typography>
        <Typography fontSize={32} textAlign="center">
          You do not have access to this page. If you believe this is incorrect,
          please contact
          <Link
            href="mailto:support@deazy.co.uk"
            ml="12px"
            textDecoration="underline"
            _hover={{ color: 'blue.solid' }}
          >
            support@deazy.co.uk
          </Link>
        </Typography>
        <Button
          bg="blue.solid"
          fontWeight="400"
          color="#fff"
          borderRadius="30px"
          h="56px"
          minW="260px"
          alignSelf="center"
          _hover={{
            bg: '#000',
            transition: 'all 0.7s',
          }}
          fontSize={18}
          mt="70px"
          onClick={goBack}
        >
          Back to projects
        </Button>
      </Flex>
    </Flex>
  );
};

export default Page403;
