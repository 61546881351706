import { Box, Flex } from '@chakra-ui/react';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import TextInput from '@components/forms/TextInput';
import Icon from '@components/Icon';
import { INPUT_VARIANTS } from '@styles/theme';
import Typography from '@styles/Typography';
import { formatCurrency } from '@utils';
import Button from '@styles/Button';
import {
  composeValidators,
  mustBeShorterOrEqual,
  required,
} from '@utils/formValidators';
import {
  filter,
  find,
  isEmpty,
  isNil,
  isNumber,
  last,
  map,
  split,
} from 'lodash-es';
import React from 'react';
import { Field } from 'react-final-form';
import TaskTotals from './TaskTotals';
import DeleteFeatureOrTaskModal from './DeleteFeatureOrTaskModal';

const TableHead = () => (
  <Flex w="100%" mb="12px" mt="24px" color="dark.solid">
    <Typography ml="12px" w="100%" variant="caption">
      Role
    </Typography>
    <Typography variant="caption" maxW="110px" w="100%">
      Hours
    </Typography>
    <Typography variant="caption" maxW="110px" w="100%">
      Cost
    </Typography>
  </Flex>
);

const onAfterChangeFn = (h, rate, formApi, taskIndex, idx) => {
  if (!h) {
    h = 0;
    formApi.change(`tasks[${taskIndex}].resources[${idx}].hours`, 0);
    const el = document.querySelector(
      `input[name="tasks[${taskIndex}].resources[${idx}].hours"]`,
    );
    if (el) {
      setTimeout(() => {
        el.select();
      }, 100);
    }
  }
  formApi.change(`tasks[${taskIndex}].resources[${idx}].cost`, rate * h);
};

const TaskRowDesktop = ({
  idx,
  isReadOnly,
  taskIndex,
  name,
  hours,
  cost,
  rate,
  formApi,
  mainNameQA,
  currency,
  hasError,
}) => {
  return (
    <Flex
      borderRadius="8px"
      w="100%"
      h="72px"
      mb="12px"
      bg="#fff"
      border="1px solid"
      borderColor="dark.mid"
      borderTopLeftRadius="8px"
      borderBottomLeftRadius="8px"
    >
      <Flex
        alignItems="center"
        w="100%"
        h="70px"
        borderRight="1px solid"
        borderColor="dark.mid"
        px="16px"
        py="10px"
      >
        <Typography
          opacity={isReadOnly ? '0.4' : 'inherit'}
          cursor={isReadOnly ? 'not-allowed' : 'default'}
        >
          {name}
        </Typography>
      </Flex>
      <Box
        alignSelf="center"
        maxW="110px"
        w="100%"
        h="70px"
        borderRight="1px solid"
        borderColor="dark.mid"
      >
        <Field
          name={`tasks[${taskIndex}].resources[${idx}].hours`}
          data-test-id={`${mainNameQA}--tasks[${taskIndex}].resources[${idx}].hours`}
          isDisabled={isReadOnly || hasError}
          variant={INPUT_VARIANTS.CONTAINER_FILLED}
          component={MaskedTextInput}
          decimalScale={1}
          isAllowed={({ floatValue, value }) => {
            const dp = last(split(value, '.'));
            return (
              (floatValue >= 0 &&
                (dp === '0' || dp === '5') &&
                floatValue <= 999) ||
              isNil(floatValue)
            );
          }}
          fixedDecimalScale
          onAfterChange={h => onAfterChangeFn(h, rate, formApi, taskIndex, idx)}
          placeholder="-"
        />
      </Box>
      <Flex
        alignItems="center"
        h="70px"
        w="100%"
        maxW="110px"
        px="12px"
        py="10px"
        mr="12px"
        borderColor="dark.mid"
        bg="blue.light"
      >
        <Typography
          mr="auto"
          mt="4px"
          data-test-id={`${mainNameQA}--tasks[${taskIndex}].resources[${idx}].cost`}
        >
          {isNumber(cost) && hours >= 0 ? formatCurrency(cost, currency) : '-'}
        </Typography>
        <Icon name="link" color="blue.solid" ml="3px" fontSize={22} />
      </Flex>
    </Flex>
  );
};

const TaskRowMobile = ({
  idx,
  isReadOnly,
  taskIndex,
  name = '',
  hours,
  cost,
  rate,
  formApi,
  mainNameQA,
  currency,
  hasError,
}) => {
  const showCost = isNumber(cost) && hours >= 0;
  return (
    <Box
      borderRadius="8px"
      w="100%"
      h="104px"
      mb="12px"
      bg="#fff"
      border="1px solid"
      borderColor="dark.mid"
      p="12px"
      pb="16px"
    >
      <Typography
        opacity={isReadOnly ? '0.4' : 'inherit'}
        cursor={isReadOnly ? 'not-allowed' : 'default'}
        mb="12px"
      >
        {name}
      </Typography>
      <Flex>
        <Box
          alignSelf="center"
          w="100%"
          h="48px"
          border="1px solid"
          borderColor="dark.mid"
          borderRadius="8px"
          mr="8px"
        >
          <Field
            name={`tasks[${taskIndex}].resources[${idx}].hours`}
            data-test-id={`${mainNameQA}--tasks[${taskIndex}].resources[${idx}].hours`}
            isDisabled={isReadOnly || hasError}
            placeholder="Hours"
            variant={INPUT_VARIANTS.CONTAINER_FILLED}
            component={MaskedTextInput}
            borderRadius="8px"
            decimalScale={1}
            fontSize="15px"
            isAllowed={({ floatValue, value }) => {
              const dp = last(split(value, '.'));
              return (
                (floatValue >= 0 &&
                  (dp === '0' || dp === '5') &&
                  floatValue <= 999) ||
                isNil(floatValue)
              );
            }}
            fixedDecimalScale
            onAfterChange={h =>
              onAfterChangeFn(h, rate, formApi, taskIndex, idx)
            }
          />
        </Box>
        <Flex
          alignItems="center"
          h="48px"
          w="100%"
          px="12px"
          py="10px"
          border="1px solid"
          borderColor="dark.mid"
          borderRadius="8px"
        >
          <Typography
            mr="auto"
            mt="4px"
            fontSize="15px"
            data-test-id={`${mainNameQA}--tasks[${taskIndex}].resources[${idx}].cost`}
            {...(!showCost && {
              color: '#D9DADA',
            })}
          >
            {showCost ? formatCurrency(cost, currency) : 'Cost'}
          </Typography>
          <Icon name="link" color="blue.solid" ml="3px" fontSize={22} />
        </Flex>
      </Flex>
    </Box>
  );
};

const validateTaskName = idx => (value, { tasks }) => {
  const taskNames = map(
    filter(tasks, (t, i) => i !== idx),
    task => task.name,
  );

  if (find(taskNames, name => name === value)) {
    return 'Task name must be uniqe across feature';
  }
  return undefined;
};

const TasksTable = ({
  taskRows,
  isReadOnly,
  currency,
  tasks,
  formApi,
  deleteTask,
  errors,
  isPendingRequest,
  mainNameQA,
  isSmallerScreen,
}) => {
  const [deleteTaskId, setDeleteTaskId] = React.useState(null);

  const [deleteTaskRowId, setDeleteTaskRowId] = React.useState(null);

  return (
    <>
      {map(taskRows, (row, idx) => {
        const thereIsErrorInName = !!(errors && errors.tasks);
        const currentError = !!(errors && errors.tasks && errors.tasks[idx]);
        return (
          <React.Fragment key={`task-key-${row.rowId}`}>
            {idx > 0 && (
              <Box
                borderTop="1px solid"
                borderColor="dark.mid"
                mt="24px"
                mb="32px"
              />
            )}
            <Flex
              {...(isSmallerScreen && {
                justifyContent: 'space-between',
                mb: '16px',
                alignItems: 'center',
              })}
            >
              <Field
                name={`tasks[${idx}].name`}
                data-test-id={`${mainNameQA}--tasks[${idx}].name`}
                component={TextInput}
                placeholder="Give this task a name"
                borderRadius={isSmallerScreen ? '8px' : '30px'}
                h={isSmallerScreen ? '38px' : '64px'}
                label={isSmallerScreen && 'Task name'}
                backgroundColor="white"
                validate={composeValidators(
                  mustBeShorterOrEqual(200),
                  validateTaskName(idx),
                  required,
                )}
                isDisabled={isReadOnly || (thereIsErrorInName && !currentError)}
                px={isSmallerScreen ? '12px' : '24px'}
                pb={isSmallerScreen && '15px'}
                pt={isSmallerScreen && '15px'}
              />
              {!isReadOnly && (
                <Button
                  iconButton={{ name: 'trash3', fontSize: 24 }}
                  data-test-id={`${mainNameQA}--removeTask`}
                  color="#878380"
                  bg="dark.light"
                  w="32px"
                  h="32px"
                  pr="0"
                  pl="0"
                  ml="12px"
                  mt="26px"
                  isDisabled={tasks.length === 1}
                  onClick={() => {
                    setDeleteTaskId(tasks[idx].id);
                    setDeleteTaskRowId(row.rowId);
                  }}
                />
              )}
            </Flex>
            {!isSmallerScreen && <TableHead />}
            {!isEmpty(tasks) && (
              <>
                {map(tasks[idx]?.resources, (resource, i) => (
                  <React.Fragment
                    key={`task-row-${row.rowId}-${resource.resourceId}`}
                  >
                    {isSmallerScreen ? (
                      <TaskRowMobile
                        isReadOnly={isReadOnly}
                        hasError={thereIsErrorInName && !currentError}
                        {...resource}
                        taskIndex={idx}
                        idx={i}
                        formApi={formApi}
                        currency={currency}
                        mainNameQA={mainNameQA}
                      />
                    ) : (
                      <TaskRowDesktop
                        isReadOnly={isReadOnly}
                        hasError={thereIsErrorInName && !currentError}
                        {...resource}
                        taskIndex={idx}
                        idx={i}
                        formApi={formApi}
                        currency={currency}
                        mainNameQA={mainNameQA}
                      />
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
            <TaskTotals
              label="Task totals"
              currency={currency}
              isSmallerScreen={isSmallerScreen}
              resources={tasks[idx]?.resources}
              mainNameQA={mainNameQA}
            />
          </React.Fragment>
        );
      })}
      {!isReadOnly && (
        <DeleteFeatureOrTaskModal
          isForTask
          isOpen={deleteTaskId || deleteTaskRowId}
          onClose={() => {
            setDeleteTaskId(null);
            setDeleteTaskRowId(null);
          }}
          isLoading={isPendingRequest}
          onDelete={() =>
            deleteTask(
              tasks,
              formApi,
              deleteTaskId,
              deleteTaskRowId,
              () => setDeleteTaskId(null),
              setDeleteTaskRowId(null),
            )
          }
        />
      )}
    </>
  );
};

export default TasksTable;
