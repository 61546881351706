import { useToast } from '@chakra-ui/react';
import React from 'react';
import Button from '@styles/Button';
import { API } from '@app/api';

const DownloadButton = ({ url, name }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const showToast = useToast();

  const download = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const { data } = await API.apiInstance.get(url, {
        withCredentials: false,
        responseType: 'blob',
      });
      const fileURL = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      showToast({
        position: 'top',
        isClosable: true,
        status: 'error',
        title: e.message || 'Network error. Please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Button
      iconButton={{ name: 'download', fontSize: 20 }}
      onClick={download}
      variant="ghost"
      borderRadius="50%"
      width="32px"
      height="32px"
      minW="32px"
      isLoading={isLoading}
    />
  );
};

export default DownloadButton;
