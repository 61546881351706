import { PROPOSAL_CLIENT_STATUS } from '@app/constants';
import { Flex, Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { inject, observer } from 'mobx-react';
import React from 'react';

const ClientAcceptProposalModal = ({
  proposalId,
  proposalsStore: { briefId, proceedOrDeclineClientProposal, proposalData },
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Flex flexDir="column">
        <Typography variant="h2" mb="32px" textAlign="center">
          Would you like to proceed with this proposal?
        </Typography>
      </Flex>
      <Flex w="100%" mx="auto" flexDirection="column">
        <Typography mb="40px" textAlign="center" color="#000">
          This is not official confirmation until you have signed our Statement
          of Work.
        </Typography>
        <Stack spacing={3}>
          <Button
            type="submit"
            onClick={() =>
              proceedOrDeclineClientProposal({
                clientStatus: PROPOSAL_CLIENT_STATUS.ACCEPTED,
                briefId,
                proposalId,
                successCb: onClose,
              })
            }
            isLoading={proposalData.isUpdatingClientProposalStatus}
          >
            Submit
          </Button>
          <Button
            variant="outline"
            onClick={onClose}
            isDisabled={proposalData.isUpdatingClientProposalStatus}
          >
            Cancel
          </Button>
        </Stack>
      </Flex>
    </Modal>
  );
};

export default inject('proposalsStore')(observer(ClientAcceptProposalModal));
