/* eslint-disable */
const { REACT_APP_GTAG_KEY } = process.env;

/**
 * EVENT SENDING
 * ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);
 */

const googleAnalytics = {
  onProposalExpandEstimateSectionDpView(proposalId) {
    // const { ga } = window;
    // if (ga) {
    //   ga('create', REACT_APP_GTAG_KEY, 'auto', 'deazy');
    //   ga(
    //     'deazy.send',
    //     'event',
    //     'Proposal_project',
    //     'Open estimate',
    //     proposalId,
    //   );
    // }
  },
  onProposalExpandCostSectionDpView(proposalId) {
    // const { ga } = window;
    // if (ga) {
    //   ga('create', REACT_APP_GTAG_KEY, 'auto', 'deazy');
    //   ga('deazy.send', 'event', 'Proposal_project', 'Open cost', proposalId);
    // }
  },
  onProposalExpandOverivewSectionDpView(proposalId) {
    // const { ga } = window;
    // if (ga) {
    //   ga('create', REACT_APP_GTAG_KEY, 'auto', 'deazy');
    //   ga(
    //     'deazy.send',
    //     'event',
    //     'Proposal_project',
    //     'Open overview',
    //     proposalId,
    //   );
    // }
  },
  onProposalClickSubmit(proposalId) {
    // const { ga } = window;
    // if (ga) {
    //   ga('create', REACT_APP_GTAG_KEY, 'auto', 'deazy');
    //   ga('deazy.send', 'event', 'Proposal_project', 'Submit', proposalId);
    // }
  },
};

export default googleAnalytics;
