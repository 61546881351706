/* eslint-disable no-underscore-dangle */
import {
  filter,
  set,
  find,
  flatten,
  forEach,
  get,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  isString,
  map,
  partition,
  reduce,
  reject,
  toNumber,
  pick,
  findIndex,
  isArray,
  round,
  isNull,
  some,
  compact,
  omit,
  omitBy,
  last,
} from 'lodash-es';
import { action, computed, makeObservable, observable, when } from 'mobx';
import { nanoid } from 'nanoid';
import googleAnalytics from '@app/ga';
import { CONFIDENCE_LEVEL, NEW_PROPOSAL_STATUSES } from '@app/constants';
import { APP_ROUTES } from '@routes';
import { isAdvancedFrameworkCheck } from '@utils/proposalUtils';
import { getDeazyMarginFactor, sleep } from '@utils';

export default class ProposalsStore {
  constructor({
    API,
    toastsStore,
    routerStore,
    briefsStore,
    usersStore,
    ratesStore,
    settingsStore,
  }) {
    makeObservable(this);
    this.API = API;
    this.usersStore = usersStore;
    this.toastsStore = toastsStore;
    this.routerStore = routerStore;
    this.briefsStore = briefsStore;
    this.usersStore = usersStore;
    this.settingsStore = settingsStore;
    this.ratesStore = ratesStore;
  }

  @observable isLoading = false;

  @observable isLoadingProposal = false;

  @observable proposalData = {};

  @observable clientProposalData = {};

  @observable isUpdatingPnPResource = false;

  // validation from backend
  @observable incompleteFeatures = [];

  @observable isEditingRolesSection = false;

  @observable isEditingFeaturesSection = false;

  @observable isEditingCostSection = false;

  @observable isEditingOverviewSection = false;

  @observable costSectionAsCompletedOnSubmit = false;

  @observable isPendingRequest = false;

  @computed get briefId() {
    return this.briefsStore.brief.id;
  }

  @computed get proposalId() {
    const proposals = get(this.briefsStore.brief, 'supplierProposal.proposals');
    const singleProjectProposalId = get(
      this.briefsStore.brief,
      'supplierProposal.proposal.id',
      null,
    );
    if (!isEmpty(proposals)) {
      return last(proposals)?.id;
    }
    return singleProjectProposalId;
  }

  @computed get pnpSupplierProposals() {
    return (this.briefsStore.brief?.ppBriefToSuppliers?.[0]?.proposals || [])
      .slice()
      .reverse();
  }

  @computed get proposalWithClientStatusId() {
    return get(
      find(
        this.briefsStore.brief.briefToSuppliers,
        p => p.proposal.status === 'With Client',
      ),
      'id',
      null,
    );
  }

  @computed get coreTeamError() {
    const isCoreTeamMember = filter(this.proposalData.coreTeam, t => t.id);
    if (isCoreTeamMember.length > 0) {
      return false;
    }
    return true;
  }

  @computed get timingsError() {
    return (
      !this.proposalData.earliestStartDate || !this.proposalData.deliveryTime
    );
  }

  @computed get featuresError() {
    const allTaskArray = [];

    forEach(this.proposalData.features, feature => {
      const taskToResources = flatten(
        map(feature.tasks, t => t.taskToResources),
      );
      allTaskArray.push(taskToResources);
    });

    if (
      !isEmpty(this.proposalData.features) &&
      !some(allTaskArray, arr => isEmpty(arr)) &&
      isEmpty(this.incompleteFeatures)
    ) {
      return false;
    }
    return true;
  }

  @computed get overviewError() {
    if (!isNull(this.proposalData.summary)) {
      return false;
    }
    return true;
  }

  @action clearCostSectionAsCompletedOnSubmit = () => {
    this.costSectionAsCompletedOnSubmit = false;
  };

  @action initializeProposal = async briefId => {
    if (briefId && this.proposalId) {
      await this.fetchProposalData(briefId, this.proposalId);
    } else {
      const { supplierProfilePrimaryCurrency } = this.usersStore;
      this.isLoadingProposal = true;
      try {
        const { data } = await this.API.createProposal(false)(
          this.briefsStore.brief.id,
          {
            briefToSupplierId: this.briefsStore.brief.supplierProposal.id,
            overriddenCurrency: supplierProfilePrimaryCurrency,
          },
        );
        set(this.briefsStore.brief, 'briefToSuppliers[0].proposal', data);

        this.proposalData = { ...data };
      } catch (e) {
        this.toastsStore.showError({
          title: 'Network error! Proposal is not available.',
        });
        this.routerStore.push(APP_ROUTES.briefTab(briefId, 'overview'));
      } finally {
        this.isLoadingProposal = false;
      }
    }
  };

  @observable proposalFirstRequest = true;

  @action fetchProposalData = async (
    briefId,
    proposalId,
    goToProposalsOnFail = false,
  ) => {
    this.isLoadingProposal = true;
    try {
      if (!proposalId) {
        const { data } = await this.API.getPnpDeazyProposal(briefId);
        this.proposalData = {
          ...data,
          status: data.calculatedStatus,
        };
      } else {
        const { data } = await this.API.getBriefProposal(
          this.briefsStore.brief.isPlugAndPlay,
        )(briefId, proposalId);
        this.proposalData = { ...data };
      }
    } catch (e) {
      if (goToProposalsOnFail) {
        if (!this.briefsStore.brief.isPlugAndPlay) {
          this.routerStore.push(APP_ROUTES.briefTab(briefId, 'proposals'));
        }
      } else {
        this.toastsStore.showError({
          title: 'Network error! Could not retrieve the proposal data',
        });
      }
    } finally {
      this.isLoadingProposal = false;
      this.proposalFirstRequest = false;
    }
  };

  @action fetchClientProposalData = async (briefId, proposalId) => {
    const { isAdminOrDL } = this.usersStore;
    this.isLoadingProposal = true;
    try {
      let endpoint = this.briefsStore.brief.isPlugAndPlay
        ? 'getPnpClientProposal'
        : 'getClientProposal';
      if (!this.briefsStore.brief.isPlugAndPlay && isAdminOrDL) {
        endpoint = 'getProposalClientView';
      }
      const { data } = await this.API[endpoint](briefId, proposalId);
      this.clientProposalData = {
        ...this.proposalData,
        ...data,
      };
    } catch (e) {
      if (!this.usersStore.isClient) {
        this.toastsStore.showError({
          title:
            e?.message || 'Network error! Could not retrieve the proposal data',
        });
      }
    } finally {
      this.isLoadingProposal = false;
    }
  };

  @action clearProposalData = () => {
    this.proposalData = {};
    this.clientProposalData = {};
    this.proposalFirstRequest = true;
  };

  @action clearEditingSections = () => {
    this.isEditingCostSection = false;
    this.isEditingRolesSection = false;
    this.isEditingOverviewSection = false;
    this.isEditingFeaturesSection = false;
  };

  @computed get briefToSupplierId() {
    if (this.usersStore.isTeamAdmin) {
      return get(this.briefsStore, 'brief.briefToSuppliers[0].id', null);
    }
    return get(this.proposalData, 'briefToSupplier.id', null);
  }

  @action saveProposalCostData = isSubmitting => async values => {
    if (isSubmitting) {
      this.costSectionAsCompletedOnSubmit = true;
      this.proposalData = { ...this.proposalData, ...omit(values, 'features') };
      return;
    }
    if (!isSubmitting) {
      this.isEditingCostSection = true;
    }

    try {
      this.isPendingRequest = true;

      const { data } = await this.API.updateProposal(
        this.briefsStore.brief.id,
        this.proposalData.id,
        {
          ...values,
          briefToSupplierId: this.briefToSupplierId,
          contingencyPercent: values.contingencyPercent || null,
          contingencyValue: values.contingencyValue || null,
        },
      );
      this.proposalData = {
        ...this.proposalData,
        ...omit(values, 'features'),
        ...omit(data, 'features'),
        contingencyPercent: values.contingencyPercent || null,
        contingencyValue: values.contingencyValue || null,
      };
    } catch (e) {
      this.toastsStore.showError({
        title: 'Network error! Could not auto save proposal data.',
      });
    } finally {
      this.isPendingRequest = false;
    }
  };

  @action saveProposalTimings = isSubmitting => async values => {
    if (isSubmitting) {
      this.costSectionAsCompletedOnSubmit = true;
      this.proposalData = { ...this.proposalData, ...omit(values, 'features') };
      return;
    }
    if (!isSubmitting) {
      this.isEditingCostSection = true;
    }

    try {
      this.isPendingRequest = true;

      const { data } = await this.API.updateProposal(
        this.briefsStore.brief.id,
        this.proposalData.id,
        {
          ...omit(this.proposalData, ['coreTeam', 'additionalTeam']),
          ...values,
          briefToSupplierId: this.briefToSupplierId,

          deliveryTime: toNumber(values.deliveryTime),
        },
      );
      this.proposalData = {
        ...this.proposalData,
        ...omit(values, 'features'),
        ...omit(data, 'features'),

        deliveryTime: toNumber(values.deliveryTime),
      };
    } catch (e) {
      this.toastsStore.showError({
        title: 'Network error! Could not auto save proposal data.',
      });
    } finally {
      this.isPendingRequest = false;
    }
  };

  @action saveProposalCurrency = isSubmitting => async values => {
    if (values.overriddenCurrency === this.proposalData.overriddenCurrency) {
      return;
    }
    if (!isSubmitting) {
      this.isEditingRolesSection = true;
    }
    try {
      this.isPendingRequest = true;

      const { data } = await this.API.updateProposal(
        this.briefsStore.brief.id,
        this.proposalData.id,
        {
          ...this.proposalData,
          ...values,
          briefToSupplierId: this.briefToSupplierId,
        },
      );
      this.proposalData = {
        ...this.proposalData,
        ...omit(values, 'features'),
        ...omit(data, 'features'),
      };
    } catch (e) {
      this.toastsStore.showError({
        title: 'Network error! Could not auto save proposal data.',
      });
    } finally {
      this.isPendingRequest = false;
    }
  };

  @action saveProposalOverviewData = isSubmitting => async values => {
    if (isSubmitting) {
      this.proposalData = { ...this.proposalData, ...omit(values, 'features') };
      return;
    }
    if (!isSubmitting) {
      this.isEditingOverviewSection = true;
    }
    try {
      this.isPendingRequest = true;
      const { data } = await this.API.updateProposal(
        this.briefsStore.brief.id,
        this.proposalData.id,
        { ...values, briefToSupplierId: this.briefToSupplierId },
      );
      this.proposalData = {
        ...this.proposalData,
        ...omit(values, 'features'),
        ...omit(data, 'features'),
      };
    } catch (e) {
      this.toastsStore.showError({
        title: 'Network error! Could not auto save proposal data.',
      });
    } finally {
      this.isPendingRequest = false;
    }
  };

  @action deleteProposalTask = async (featureId, taskId, successCb) => {
    try {
      this.isPendingRequest = true;
      await this.API.deleteFeatureTask({
        briefId: this.briefId,
        proposalId: this.proposalId,
        featureId,
        taskId,
      });
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: 'Network error! Could not auto save proposal data.',
      });
    } finally {
      this.isPendingRequest = false;
    }
  };

  @action saveProposalTasks = isSubmitting => async ({ featureId, tasks }) => {
    this.incompleteFeatures = [];
    const feature =
      find(
        this.decoratedProposalData.features,
        f => f.id === featureId || f.tempId === featureId,
      ) || {};
    if (!isSubmitting) {
      this.isEditingFeaturesSection = true;
    }

    try {
      this.isPendingRequest = true;

      await Promise.all(
        map(tasks, async task => {
          const prevTask = find(feature.tasks, t => t.id === task.id) || {};
          if (
            task.name &&
            (task.name !== prevTask.name ||
              !isEqual(prevTask.resources, task.resources))
          ) {
            const apiFn = isNumber(task.id)
              ? this.API.updateFeatureTask
              : this.API.createFeatureTask;
            const { data } = await apiFn(
              {
                briefId: this.briefsStore.brief.id,
                proposalId: this.proposalData.id,
                featureId,
                taskId: task.id,
              },
              {
                ...task,
                resources: map(task.resources, r => ({
                  ...r,
                  hours: r.hours || null,
                })),
              },
            );
            this.proposalData = {
              ...data,
              features: map(this.proposalData.features, f => ({
                ...f,
                ...(find(data.features, _f => f.id === _f.id) || {}),
              })),
            };
            await sleep(1000);
          }
        }),
      );
    } catch (e) {
      if (!isSubmitting) {
        this.toastsStore.showError({
          title: 'Network error! Could not auto save proposal data.',
        });
      }
    } finally {
      this.isPendingRequest = false;
    }
  };

  @action saveAdditionalTeam = isSubmitting => async ({ additionalTeam }) => {
    const { isAdminOrDL } = this.usersStore;
    const apiFnName = isAdminOrDL
      ? 'updateProposalAllRolesAsDeazy'
      : 'updateProposalTeamResources';
    const { additionalTeam: proposalTeam } = this.proposalData;
    const [resourcesToDel, restResources] = partition(
      additionalTeam,
      r => r.toBeDeleted,
    );
    const completedResources = filter(
      restResources,
      res => +res.rate >= 0 && !isNil(res.rate) && res.name,
    );

    const [newResources, oldResources] = partition(
      completedResources,
      res => !res.id,
    );

    const prevOldResources = filter(proposalTeam, res =>
      find(oldResources, oldRes => oldRes.id === res.id),
    );

    if (
      !isSubmitting &&
      !isAdminOrDL &&
      !isEmpty(filter(additionalTeam, ({ rowId, ...r }) => !isEmpty(r)))
    ) {
      this.isEditingRolesSection = true;
    }
    try {
      this.isPendingRequest = true;

      if (!isEmpty(resourcesToDel)) {
        await Promise.all(
          map(resourcesToDel, async res => {
            if (isNumber(res.id)) {
              await this.API.deleteProposalTeamResource({
                resourceId: res.id,
                briefId: this.briefId,
                proposalId: this.proposalId,
              });
            }
          }),
        );
        this.proposalData.additionalTeam = reject(
          additionalTeam,
          res => !!find(resourcesToDel, r => res.rowId === r.rowId),
        );
      }

      const preparePayload = r => {
        return {
          ...r,
          timeHours: r.timePercent ? null : r.timeHours,
          timePercent: r.timePercent || null,
          clientRate: r.autoClientRate ? null : r.clientRate,
          margin: r.autoMargin ? null : r.margin,
        };
      };
      if (
        // do not compare IDs
        !isEqual(
          map(prevOldResources, ({ id, ...r }) => ({
            ...this.compareMapWithParameters(r),
            rate: toNumber(+r.rate).toFixed(2),
            timeHours: r.timePercent ? null : r.timeHours,
          })),
          map(oldResources, ({ id, ...r }) => ({
            ...this.compareMapWithParameters(r),
            rate: toNumber(+r.rate).toFixed(2),
            timeHours: r.timePercent ? null : r.timeHours,
          })),
        ) &&
        this.proposalData.id
      ) {
        const { data } = await this.API[apiFnName](
          this.briefsStore.brief.id,
          this.proposalData.id,
          map(oldResources, preparePayload),
        );
        const updatedAdditionalResources = isArray(data)
          ? data
          : data?.additionalTeam;
        this.proposalData.additionalTeam = map(additionalTeam, t => ({
          ...t,
          ...(updatedAdditionalResources[
            findIndex(updatedAdditionalResources, res => res.id === t.id)
          ] || {}),
        }));
      }
      if (!isEmpty(newResources)) {
        const resourcesResp = await Promise.all(
          map(newResources, async res => {
            const { data } = await this.API.createProposalSingleResource(
              {
                briefId: this.briefId,
                proposalId: this.proposalId,
              },
              preparePayload(res),
            );
            return { ...data, rowId: res.rowId };
          }),
        );
        this.proposalData = {
          ...this.proposalData,
          additionalTeam: map(additionalTeam, t => ({
            ...t,
            ...(resourcesResp[
              findIndex(resourcesResp, newR => newR.rowId === t.rowId)
            ] || {}),
          })),
        };
      }
    } catch (e) {
      if (!isSubmitting) {
        this.toastsStore.showError({
          title:
            e.message ||
            'Network error! Could not auto save additional team data.',
        });
      }
    } finally {
      this.isPendingRequest = false;
    }
  };

  compareMapWithParameters = r => {
    const transformedResource = {
      ...r,
      margin: !isNil(r.margin) ? toNumber(r.margin) : r.margin,
    };

    return omitBy(
      pick(
        transformedResource,
        compact([
          'name',
          'seniority',
          'rate',
          'comment',
          !transformedResource.autoClientRate && 'clientRate',
          !transformedResource.autoMargin && 'margin',
          'timeHours',
          'timePercent',
        ]),
      ),
      isNil,
    );
  };

  @action saveProposalCoreTeamData = isSubmitting => async values => {
    const { isAdminOrDL } = this.usersStore;
    const apiFnName = isAdminOrDL
      ? 'updateProposalAllRolesAsDeazy'
      : 'createProposalCoreTeamRoles';
    if (!isSubmitting && !isAdminOrDL) {
      this.isEditingRolesSection = true;
    }
    try {
      this.isPendingRequest = true;

      const actualRoles = map(
        filter(values.roles, r => r.name && !isNil(r.rate)),
        r => {
          return {
            ...r,
            rate: toNumber(r.rate).toFixed(2),
          };
        },
      );
      const nonRoles = reject(values.roles, r => r.name);
      const filteredRoles = filter(
        this.proposalData.coreTeam,
        r => r.name && !isNil(r.rate),
      );

      if (
        !isEqual(
          map(actualRoles, ({ ...r }) => this.compareMapWithParameters(r)),
          map(filteredRoles, ({ ...r }) => this.compareMapWithParameters(r)),
        ) &&
        // this is to prevenet firing when role is deleted manually
        actualRoles.length >= filteredRoles.length &&
        this.proposalData.id
      ) {
        const { data } = await this.API[apiFnName](
          this.briefsStore.brief.id,
          this.proposalData.id,
          map(actualRoles, ({ id, ...r }) => ({
            ...r,
            id: isNumber(id) ? id : undefined,
            clientRate: r.autoClientRate ? null : r.clientRate,
            margin: r.autoMargin ? null : r.margin,
          })),
        );
        this.proposalData.coreTeam = [
          ...map(data?.coreTeam || data, (m, idx) => ({
            ...m,
            rowId: get(actualRoles[idx], 'rowId'),
            isOpen: get(actualRoles[idx], 'isOpen'),
            isRateOk: get(actualRoles[idx], 'isRateOk'),
          })),
          ...nonRoles,
        ];
      }
    } catch (e) {
      if (!isSubmitting) {
        this.toastsStore.showError({
          title:
            e.message || 'Network error! Could not auto save proposal data.',
        });
      }
    } finally {
      this.isPendingRequest = false;
    }
  };

  @action deleteCoreTeamRole = async (roleId, prevRoles, failCb) => {
    try {
      await this.API.deleteProposalCoreTeamRole(
        this.briefsStore.brief.id,
        this.proposalData.id,
        roleId,
      );
      this.proposalData = {
        ...this.proposalData,
        // in order to work with delete row
        coreTeam: reject(prevRoles, { id: roleId }),
      };
    } catch (e) {
      this.toastsStore.showError({
        title: 'Network error! Could not delete core team resource',
      });
      failCb();
    }
  };

  @observable isPendingFeatureRequest = false;

  @action saveProposalFeature = isSubmitting => async (
    { briefId, proposalId, featureId },
    payload,
  ) => {
    if (!isSubmitting) {
      this.isEditingFeaturesSection = true;
    }
    if (this.isPendingFeatureRequest) {
      await when(() => !this.isPendingFeatureRequest);
    }
    const matchedFeature =
      find(this.proposalData.features, f => f.tempId === featureId) || {};
    const isCustom = isString(featureId) && isEmpty(matchedFeature);

    try {
      this.isPendingFeatureRequest = true;
      this.isPendingRequest = true;

      const { data } = await this.API[
        isCustom ? 'createProposalFeature' : 'updateProposalFeature'
      ](
        { briefId, proposalId, featureId: matchedFeature.id || featureId },
        {
          ...(isString(featureId) &&
            isEmpty(matchedFeature) && {
              isCustom: true,
              confidenceLevel: CONFIDENCE_LEVEL.HIGH,
            }),
          ...matchedFeature,
          ...payload,
        },
      );
      this.isPendingFeatureRequest = false;
      this.proposalData = {
        ...data,
        features: map(data.features, (f, idx) => ({
          ...(find(this.proposalData.features, _f => f.id === _f.id) || {}),
          ...f,
          ...(data.features.length - 1 === idx &&
            isString(featureId) && { tempId: featureId }),
        })),
      };
    } catch (e) {
      if (!isSubmitting) {
        this.toastsStore.showError({
          title: 'Network error! Could not auto save proposal data.',
        });
      }
    } finally {
      this.isPendingRequest = false;
    }
  };

  @observable selectedFeatureId = null;

  @action setSelectedFeatureId = featureId => {
    this.selectedFeatureId = featureId;
  };

  @action prepareProposalPayload = (values, isPlugAndPlay) => {
    const resources = map(
      flatten(
        map(
          reject(values.resources, r => isEmpty(r.candidates)),
          res => res.candidates,
        ),
      ),
      candidate => ({
        ...candidate,
        ...(!candidate?.file?.id &&
          !candidate?.devProfile?.id && {
            plugPlayProposalCv: { uid: nanoid(10) },
          }),
        ...(candidate?.devProfile?.id && {
          staffProfileId: candidate.devProfile?.id,
        }),
        briefResourceId: candidate?.briefResource?.id,
      }),
    );
    if (find(resources, c => !c.rate)) {
      // eslint-disable-next-line no-throw-literal
      throw { message: 'You cannot submit proposal with empty role!' };
    }

    return {
      ...values,
      ...(isPlugAndPlay && {
        resources,
      }),
      getPpBriefSyncDate: this.briefsStore.ppBriefFetchDate,
    };
  };

  @action createOrResubmitProposal = async (
    { briefId, isPlugAndPlay, ...values },
    successCb,
  ) => {
    try {
      this.briefsStore._brief.isCreatingProposal = true;
      const payload = this.prepareProposalPayload(values, isPlugAndPlay);
      await this.uploadProposalFiles({ briefId, isPlugAndPlay }, payload);

      if (values.id) {
        if (isPlugAndPlay) {
          if (values.archivingReason) {
            await this.API.submitNewPnPProposal(briefId, values.id, payload);
          } else {
            await this.API.resubmitPnPProposal(briefId, values.id, payload);
          }
        } else {
          const { data } = await this.API.updateProposal(
            briefId,
            values.id,
            payload,
          );
          this.briefsStore._brief.isCreatingProposal = data;
        }
      } else {
        await this.API.createProposal(isPlugAndPlay)(briefId, payload);
      }
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      this.briefsStore._brief.isCreatingProposal = false;
    }
  };

  @action submitProposal = async (values, successCb) => {
    try {
      const { submitted } = this.proposalData;
      await this.API.submitProposal(
        { briefId: this.briefId, proposalId: this.proposalId },
        values,
      );
      if (!submitted) {
        googleAnalytics.onProposalClickSubmit(this.proposalId);
      }
      if (successCb) {
        successCb();
      }
    } catch (e) {
      if (!isEmpty(e.errors)) {
        const incompleteFeatures = [];
        forEach(e.errors, error => {
          if (error.field === 'tasks') {
            incompleteFeatures.push({
              id: nanoid(10),
              name: get(
                find(this.proposalData.features, f => f.id === error.id),
                'name',
                error.message,
              ),
            });
          } else if (error.field === 'taskToResources') {
            let featureIndex;
            forEach(this.proposalData.features, (feature, idx) => {
              const taskToResources = flatten(
                map(feature.tasks, t => t.taskToResources),
              );
              if (find(taskToResources, r => r?.task?.id === error.id)) {
                featureIndex = idx;
              }
            });
            incompleteFeatures.push({
              id: nanoid(10),
              name: get(
                this.proposalData.features[featureIndex],
                'name',
                error.message,
              ),
            });
          } else {
            this.toastsStore.showError({
              title: error.message,
            });
          }
        });
        this.incompleteFeatures = incompleteFeatures;
      } else {
        this.toastsStore.showError({
          title:
            e.message ||
            'Ooops! Something unexpected happened. Please try again later.',
        });
      }
    }
  };

  @action clearIncompleteFeatures = () => {
    this.incompleteFeatures = [];
  };

  @action createProjectProposal = async ({ briefId, ...values }, successCb) => {
    try {
      this.briefsStore._brief.isCreatingProposal = true;
      await this.API.createProposal(false)(briefId, values);
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      this.briefsStore._brief.isCreatingProposal = false;
    }
  };

  uploadProposalFiles = async ({ briefId, isPlugAndPlay }, proposal) => {
    if (!isEmpty(proposal.resources)) {
      const promises = [];
      proposal.resources.forEach(
        ({ staffProfileId, devProfile, file, plugPlayProposalCv }) => {
          const f = file || {};
          const { uid = {} } = plugPlayProposalCv || {};
          if (!f.id && !devProfile?.id && !staffProfileId) {
            const fd = new FormData();
            fd.append(uid, f, f.name);
            promises.push(
              this.API.uploadProposals({ briefId, isPlugAndPlay }, fd),
            );
          }
        },
      );
      await Promise.all(promises);
    }
  };

  @computed get initialCoreTeamRoles() {
    return map(
      filter(this.proposalData.coreTeam, role => role.name && role.rate),
      ({ id, ...role }) => ({ resourceId: id, ...role }),
    );
  }

  @computed get coreTeamRolesForClientProposal() {
    return map(this.clientProposalData?.coreTeam, ({ id, ...role }) => ({
      resourceId: id,
      ...role,
    }));
  }

  decorateProposalData = decorateForClientFullBreakdown => {
    const proposalData = decorateForClientFullBreakdown
      ? this.clientProposalData
      : this.proposalData;
    const proposalFeatures = decorateForClientFullBreakdown
      ? this.clientProposalData?.featureBreakdown
      : this.proposalData?.features;
    const teamRoles = decorateForClientFullBreakdown
      ? this.coreTeamRolesForClientProposal
      : this.initialCoreTeamRoles;
    let proposalVisibleCosts = null;
    if (decorateForClientFullBreakdown) {
      proposalVisibleCosts = this.clientProposalData?.clientSettings
        ?.visibleCosts;
    }

    return {
      ...proposalData,
      isFetched: decorateForClientFullBreakdown ? true : !!this.proposalData.id,
      features: map(proposalFeatures, f => {
        let tasks = [{ rowId: nanoid(10), resources: teamRoles }];
        if (!isEmpty(f.tasks)) {
          tasks = map(f.tasks, task => ({
            ...task,
            resources: map(teamRoles, role => {
              const taskToResource =
                find(
                  task.taskToResources,
                  taskRes => taskRes.resource.id === role.resourceId,
                ) || {};

              let resourceRowCost = null;
              if (
                !isNil(taskToResource.hours) &&
                proposalVisibleCosts === 'High cost only'
              ) {
                resourceRowCost = taskToResource.maxCost;
              } else if (!isNil(taskToResource.hours)) {
                resourceRowCost = +taskToResource.hours * +role.rate;
              }

              return {
                ...taskToResource.resource,
                ...role,
                ...task,
                resourceId: role.resourceId || role.id,
                id: taskToResource.id,
                name: role.name,
                // toFixed(1) is in order to work properly with task form so it doesnt loop on auto save
                hours: !isNil(taskToResource.hours)
                  ? Number(taskToResource.hours).toFixed(1)
                  : null,
                cost: resourceRowCost,
              };
            }),
          }));
        }
        const completedResources = filter(
          flatten(map(tasks, task => task.resources)),
          r => !!r.cost && !!r.hours,
        );

        return {
          ...f,
          tasks,
          isAdditional: !!find(
            this.briefsStore._brief?.additionalFeatures,
            feature => feature.name === f.name,
          ),
          tasksCount: filter(tasks, task => isNumber(task.id)).length,
          totalHours: reduce(
            completedResources,
            (sum, task) => sum + +task.hours,
            0,
          ),
          totalCost: reduce(
            completedResources,
            (sum, task) => sum + +task.cost,
            0,
          ),
        };
      }),
    };
  };

  @computed get decoratedProposalData() {
    return this.decorateProposalData();
  }

  @computed get decoratedClientProposalData() {
    return this.decorateProposalData(true);
  }

  @computed get totalHours() {
    const { features } = this.decoratedProposalData;
    const totalHours = reduce(features, (a, b) => +a + +b.totalHours, 0);
    return totalHours;
  }

  @computed get totalCost() {
    const { features } = this.decoratedProposalData;
    const totalCost = reduce(features, (a, b) => +a + +b.totalCost, 0);
    return totalCost;
  }

  @computed
  get featuresCount() {
    return (this.proposalData?.features || []).length;
  }

  @computed get totalHoursForClient() {
    const { features } = this.decoratedClientProposalData;
    const totalHours = reduce(features, (a, b) => +a + +b.totalHours, 0);
    return totalHours;
  }

  @computed get totalCostForClient() {
    const { features } = this.decoratedClientProposalData;
    const totalCost = reduce(features, (a, b) => +a + +b.totalCost, 0);
    return totalCost;
  }

  @computed
  get featuresCountForClient() {
    return (this.clientProposalData?.featureBreakdown || []).length;
  }

  @observable proposalResources = {
    isLoading: false,
    data: [],
    error: null,
  };

  @action clearBreakdowns = () => {
    this.proposalResources = {
      isLoading: false,
      data: [],
      error: null,
    };
    this.costBreakdown = {
      isLoading: false,
      data: {},
      error: null,
    };
    this.deazyCostBreakdown = {
      isLoading: false,
      data: {},
      error: null,
    };
  };

  @action fetchProposalResources = async (briefId, proposalId) => {
    this.proposalResources = {
      isLoading: true,
      data: [],
    };
    try {
      const { data } = await this.API.getBriefProposalResourcesBreakDown(
        briefId,
        proposalId,
      );
      this.proposalResources.data = data;
    } catch (e) {
      this.toastsStore.showError({
        title: 'Network error! Could not retrieve proposal resource breakdown',
      });
      this.proposalResources.error =
        'Error occured while loading resource breakdown. Please try again later.';
    } finally {
      this.proposalResources.isLoading = false;
    }
  };

  @observable costBreakdown = {
    isLoading: false,
    data: {},
    error: null,
  };

  @action fetchCostBreakdown = async (briefId, proposalId) => {
    this.costBreakdown = {
      isLoading: true,
      data: {},
    };
    try {
      const { data } = await this.API.getCostBreakdown(briefId, proposalId);
      this.costBreakdown.data = data;
    } catch (e) {
      this.toastsStore.showError({
        title: 'Network error! Could not retrieve proposal cost breakdown',
      });
      this.costBreakdown.error =
        'Error occured while loading cost breakdown. Please try again later.';
    } finally {
      this.costBreakdown.isLoading = false;
    }
  };

  @observable deazyCostBreakdown = {
    isLoading: false,
    data: {},
    error: null,
  };

  @action fetchDeazyCostBreakdown = async (briefId, proposalId) => {
    this.deazyCostBreakdown.isLoading = true;
    try {
      const { data } = await this.API[
        this.briefsStore.brief.isPlugAndPlay
          ? 'getPnpDeazyCostBreakdown'
          : 'getDeazyCostBreakdown'
      ](briefId, proposalId);
      this.deazyCostBreakdown.data = data;
    } catch (e) {
      this.toastsStore.showError({
        title: 'Network error! Could not retrieve proposal cost breakdown',
      });
      this.deazyCostBreakdown.error =
        e.message ||
        'Error occured while loading cost breakdown. Please try again later.';
    } finally {
      this.deazyCostBreakdown.isLoading = false;
    }
  };

  @action deleteCustomFeature = async (featureId, successCb) => {
    try {
      this.proposalData.features = map(this.proposalData.features, f => ({
        ...f,
        isDeleting: f.id === featureId ? true : f.isDeleting,
      }));
      await this.API.deleteProposalFeature({
        briefId: this.briefId,
        proposalId: this.proposalId,
        featureId,
      });
      this.proposalData.features = reject(this.proposalData.features, {
        id: featureId,
      });
      if (typeof successCb === 'function') {
        successCb();
      }
    } catch (e) {
      this.proposalData.features = map(this.proposalData.features, f => ({
        ...f,
        isDeleting: f.id === featureId ? false : f.isDeleting,
      }));
      this.toastsStore.showError({
        title: 'Network error! Could not delete proposal feature',
      });
    }
  };

  @action saveProposalDeazyTeam = async ({ deazyTeam }) => {
    const { deazyTeam: proposalDeazyTeam } = this.proposalData;
    const [resourcesToDel, restResources] = partition(
      deazyTeam,
      r => r.toBeDeleted,
    );
    const completedResources = filter(
      restResources,
      res => res.deazyName && +res.clientRate >= 0,
    );
    const [newResources, oldResources] = partition(
      completedResources,
      res => !res.id,
    );
    const compareMap = r => pick(r, ['deazyName', 'clientRate', 'timeHours']);
    try {
      if (!isEmpty(resourcesToDel)) {
        await Promise.all(
          map(resourcesToDel, async res => {
            if (isNumber(res.id)) {
              await this.API.deleteProposalDeazyTeamResource({
                resourceId: res.id,
                briefId: this.briefId,
                proposalId: this.proposalData.id,
              });
            }
          }),
        );
        this.proposalData.deazyTeam = reject(
          deazyTeam,
          res => !!find(resourcesToDel, r => res.rowId === r.rowId),
        );
      }

      if (!isEmpty(newResources)) {
        const resources = await Promise.all(
          map(newResources, async res => {
            const { data } = await this.API.createProposalDeazySingleResource(
              { briefId: this.briefId, proposalId: this.proposalData.id },
              { ...res, rate: 30 },
            );
            return { ...res, rate: data.rate, id: data.id };
          }),
        );
        this.proposalData.deazyTeam = map(deazyTeam, res => ({
          ...res,
          ...(find(resources, newRes => newRes.rowId === res.rowId) || {}),
        }));
      }
      const oldResourcesToUpdate = filter(oldResources, res => {
        const resToCompare = find(proposalDeazyTeam, r => r.id === res.id);
        return !isEqual(compareMap(res), compareMap(resToCompare));
      });
      if (!isEmpty(oldResourcesToUpdate)) {
        const { data } = await this.API.updateProposalDeazyTeamResources(
          { briefId: this.briefId, proposalId: this.proposalData.id },
          oldResourcesToUpdate,
        );
        this.proposalData.deazyTeam = map(deazyTeam, res => ({
          ...res,
          ...(find(data.deazyTeam, oldRes => oldRes.rowId === res.rowId) || {}),
        }));
      }
    } catch (e) {
      this.toastsStore.showError({
        title: 'Network error! Could not auto save deazy team.',
      });
    }
  };

  @computed get proposalSupplier() {
    return (
      this.proposalData?.briefToSupplier?.supplier ||
      this.proposalData?.ppBriefToSupplier?.supplier ||
      {}
    );
  }

  @computed get proposalSupplierCurrency() {
    const proposalSupplierCurrency = find(
      this.proposalSupplier?.paymentDetails,
      c => c.isPrimary,
    )?.currency;
    return this.proposalData?.overriddenCurrency || proposalSupplierCurrency;
  }

  @computed get proposalExchangeRate() {
    if (this.proposalData.currencyRate) {
      return this.proposalData.currencyRate;
    }
    if (this.proposalSupplierCurrency === 'GBP') {
      return 1;
    }
    const exRate =
      find(
        this.settingsStore.exchangeRates.data,
        rate =>
          rate.sourceCurrency === 'GBP' &&
          rate.destCurrency === this.proposalSupplierCurrency,
      ) || {};
    return +round(1 / (exRate.rate || 1), 2);
  }

  @action updateDeazyContingency = async values => {
    try {
      await this.API.updateDeazyContingency(
        this.briefsStore.brief.id,
        this.proposalData.id,
        values,
      );
      this.proposalData = { ...this.proposalData, ...values };
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    }
  };

  @action updateDiscount = async ({
    discountPercentage = '0.00',
    discountReason = '',
  }) => {
    try {
      const { id: briefId, isPlugAndPlay } = this.briefsStore.brief;
      const apiFn = !isPlugAndPlay
        ? () =>
            this.API.updateProposal(briefId, this.proposalData.id, {
              ...this.proposalData,
              discountPercentage,
              discountReason,
            })
        : () =>
            this.API.updatePnpProposal(briefId, {
              ...this.proposalData,
              discountPercentage,
              discountReason,
            });
      await apiFn();
      this.proposalData = {
        ...this.proposalData,
        discountPercentage,
        discountReason,
      };
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    }
  };

  @action updateSupplierContingency = async values => {
    try {
      await this.API.updateSupplierContingency(
        this.briefsStore.brief.id,
        this.proposalData.id,
        values,
      );
      this.proposalData = { ...this.proposalData, ...values };
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    }
  };

  @action updatePPResource = async (
    { resources },
    successCb,
    custtomMessage,
  ) => {
    const pullToCompare = r => {
      const clientRate = round(
        r.clientRate ||
          getDeazyMarginFactor(+r.margin) * r.rate * this.proposalExchangeRate,
        2,
      );
      const margin =
        r.margin ||
        100 * (1 - (r.rate * this.proposalExchangeRate) / clientRate);
      return {
        clientRate,
        margin,
        selected: r.selected,
      };
    };

    const resourcesToUpdate = reject(
      map(resources, res => {
        const oldRes =
          find(this.proposalData.resources, r => r.id === res.id) || {};
        if (isEqual(pullToCompare(oldRes), pullToCompare(res))) {
          return null;
        }
        return res;
      }),
      isNil,
    );

    try {
      this.isUpdatingPnPResource = true;
      const responses = await Promise.all(
        map(resourcesToUpdate, async resource => {
          const { data } = await this.API.updatePPResource(
            {
              briefId: this.briefId,
              proposalId: resource?.ppBriefProposal?.id,
              resourceId: resource.id,
            },
            {
              ...resource,
              clientRate: resource.autoClientRate ? null : resource.clientRate,
              margin: resource.autoMargin ? null : resource.margin,
            },
          );
          return data;
        }),
      );
      this.proposalData.resources = map(this.proposalData.resources, res => ({
        ...res,
        costPerMonth:
          find(resourcesToUpdate, r => r.id === res.id)?.clientRate * 160,
        ...(find(responses, r => r.id === res.id) || {}),
      }));
      if (typeof successCb === 'function') {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title:
          custtomMessage ||
          e.message ||
          'Network error! Could not auto save proposal data.',
      });
    } finally {
      this.isUpdatingPnPResource = false;
    }
  };

  @action updateProposalStatus = async (
    { status, statusMessage, visibleCosts, featuresBreakdown, isPlugAndPlay },
    successCb,
  ) => {
    const payload = {
      status,
      statusMessage,
      visibleCosts,
      featuresBreakdown,
    };
    const preparePayload = s => {
      if (s !== NEW_PROPOSAL_STATUSES.WITH_CLIENT) {
        return omit(payload, { visibleCosts, featuresBreakdown });
      }
      return payload;
    };
    try {
      const { id: briefId } = this.briefsStore.brief;
      const apiFn = isPlugAndPlay
        ? () =>
            this.API.updatePnpProposalStatus(briefId, preparePayload(status))
        : () =>
            this.API.updateProposalStatus(
              briefId,
              this.proposalData.id,
              preparePayload(status),
            );
      const { data } = await apiFn();
      this.proposalData = {
        ...this.proposalData,
        ...data,
        status,
        statusMessage,
      };

      if (successCb) {
        successCb();
      }

      const key = this.briefsStore.brief.isPlugAndPlay
        ? 'ppBriefToSuppliers'
        : 'briefToSuppliers';
      this.briefsStore._brief[key] = map(this.briefsStore._brief[key], bts =>
        bts.proposal?.id === this.proposalData.id
          ? { ...bts, proposal: { ...bts.proposal, status } }
          : bts,
      );
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Network error! Please try again later.',
      });
    }
  };

  @action updateProposalCurrencyRate = async (
    { currencyRate, currencyRateEur, currencyRateUsd },
    successCb,
  ) => {
    const { brief } = this.briefsStore;
    try {
      this.proposalData.isSettingRate = true;
      if (brief.isPlugAndPlay) {
        await this.API.updatePnpProposal(brief.id, {
          ...this.proposalData,
          currencyRateEur,
          currencyRateUsd,
        });
        this.proposalData.currencyRateEur = currencyRateEur;
        this.proposalData.currencyRateUsd = currencyRateUsd;
      } else {
        await this.API.updateProposal(brief.id, this.proposalData.id, {
          ...this.proposalData,
          currencyRate,
        });
        this.proposalData.currencyRate = currencyRate;
      }
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Network error! Could not update proposal currency rate.',
      });
    } finally {
      this.proposalData.isSettingRate = false;
    }
  };

  @computed get decoratedPnPResources() {
    return map(this.briefsStore.brief.resources, res => ({
      ...res,
      candidates: map(
        filter(this.proposalData.resources, candidate => {
          if (candidate?.briefResource?.id) {
            return candidate?.briefResource?.id === res.id;
          }
          return candidate?.role === res.technology;
        }),
        c => ({
          ...c,
          rowId: nanoid(10),
          file: c.plugPlayProposalCv,
          devProfile: c.staffProfile,
        }),
      ),
    }));
  }

  @action updateFeatureConfidenceLevel = async (feature, failCb) => {
    try {
      this.isPendingRequest = true;
      await this.API.updateProposalFeature(
        {
          briefId: this.briefsStore.brief.id,
          proposalId: this.proposalData.id,
          featureId: feature.id,
        },
        { ...feature },
      );
      this.proposalData.features = map(this.proposalData.features, f => ({
        ...f,
        ...(f.id === feature.id && { ...feature }),
      }));
    } catch {
      if (failCb) {
        failCb();
      }
    } finally {
      this.isPendingRequest = false;
    }
  };

  @action proceedOrDeclineClientProposal = async ({
    clientStatus,
    clientProposalStatusMessage,
    briefId,
    proposalId,
    successCb,
  }) => {
    try {
      this.proposalData.isUpdatingClientProposalStatus = true;
      let responseData;
      if (this.briefsStore.brief.isPlugAndPlay) {
        const { data } = await this.API.updatePnpClientProposalStatus(briefId, {
          status: clientStatus,
          statusMessage: clientProposalStatusMessage,
        });
        responseData = data;
      } else {
        const { data } = await this.API.updateClientProposalStatus(
          briefId,
          proposalId,
          {
            status: clientStatus,
            statusMessage: clientProposalStatusMessage,
          },
        );
        responseData = data;
      }
      const d = {
        status: responseData.status,
        clientStatus,
        clientProposalStatusMessage,
      };
      this.proposalData = {
        ...this.proposalData,
        ...d,
      };
      if (!isEmpty(this.clientProposalData)) {
        this.clientProposalData = {
          ...this.proposalData,
          ...this.clientProposalData,
          ...d,
        };
      }
      if (typeof successCb === 'function') {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Network error! Please try again later.',
      });
    } finally {
      delete this.proposalData.isUpdatingClientProposalStatus;
    }
  };

  @observable allProposals = {
    data: [],
    isLoading: false,
    shouldSeeAllProposalsTab: false,
    firstRequest: true,
  };

  @action clearAllProposals = () => {
    this.allProposals = {
      data: [],
      isLoading: false,
      shouldSeeAllProposalsTab: false,
      firstRequest: true,
    };
  };

  @observable pnpPersistedProposalFormData = {};

  @action initializePnpPersistedProposalFormData = data => {
    this.pnpPersistedProposalFormData = data;
  };

  @action clearPnpPersistedProposalFormData = () => {
    this.pnpPersistedProposalFormData = {};
  };

  @action addDevProfileToProposal = devProfile => {
    const {
      history: { push },
    } = this.routerStore;
    const {
      currentResourceId,
      ...formData
    } = this.pnpPersistedProposalFormData;

    if (!currentResourceId) {
      this.toastsStore.showError({
        title:
          'Something went wrong while adding a dev profile. Please try again later.',
      });
      return push('proposal');
    }
    this.pnpPersistedProposalFormData = {
      ...formData,
      resources: map(formData.resources, resource => ({
        ...resource,
        ...(currentResourceId === resource.id && {
          candidates: [
            ...(resource.candidates || []),
            {
              rowId: nanoid(10),
              name: devProfile.user.fullname,
              seniority: devProfile.seniority,
              briefResource: resource,
              location: devProfile.country,
              devProfile,
            },
          ],
        }),
      })),
    };
    push('proposal');
  };

  @action fetchAllProposals = async briefId => {
    const { isClient, isTeamMember, isTeamAdmin } = this.usersStore;
    if (!briefId || isTeamMember || isTeamAdmin) {
      return;
    }
    const {
      brief: { isPlugAndPlay },
    } = this.briefsStore;
    this.allProposals.isLoading = true;
    try {
      const { data } = await this.API[
        isClient ? 'getProposalsForClient' : 'getBriefProposals'
      ](isPlugAndPlay)(briefId);
      this.allProposals.data = data;
      this.allProposals.shouldSeeAllProposalsTab =
        isPlugAndPlay || !isEmpty(data);
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Network error! Please try again later.',
      });
    } finally {
      this.allProposals.isLoading = false;
      this.allProposals.firstRequest = false;
    }
  };

  @computed get proposalCurrency() {
    const supplierPrimeCurrency = find(
      this.usersStore.profile?.supplier?.paymentDetails,
      c => c.isPrimary,
    );

    const currency =
      this.proposalData.overriddenCurrency || supplierPrimeCurrency?.currency;
    return currency;
  }

  validateProposalRates = values => {
    let error;
    if (this.briefsStore.brief.isPlugAndPlay) {
      const candidates = compact(
        flatten(map(values.resources, r => r.candidates)),
      );
      forEach(candidates, c => {
        const {
          rate: targetRate,
          highRate: targetHighRate,
        } = this.ratesStore.getResourceRate(
          c.role,
          c.seniority,
          true,
          isAdvancedFrameworkCheck(c?.briefResource?.technologies),
        );
        const rate = targetHighRate || targetRate;
        if (rate && toNumber(c.rate) > toNumber(rate)) {
          error = true;
        }
      });
    } else {
      const resources = compact([...values.coreTeam, ...values.additionalTeam]);
      forEach(resources, r => {
        const {
          rate: targetRate,
          highRate: targetHighRate,
        } = this.ratesStore.getResourceRate(
          r.name,
          r.seniority,
          false,
          isAdvancedFrameworkCheck(this.briefsStore?.brief?.technologies),
        );
        const rate = targetHighRate || targetRate;
        if (rate && toNumber(r.rate) > toNumber(rate)) {
          error = true;
        }
      });
    }
    return error;
  };
}
