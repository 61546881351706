import { NotAllowedIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';
import FormError from '@components/forms/FormError';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import { INPUT_VARIANTS } from '@styles/theme';
import Typography from '@styles/Typography';
import { formatCurrency } from '@utils';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field } from 'react-final-form';
import { MAIN_NAMES_QA } from '@app/constants';

const cellProps = hide => ({
  position: 'relative',
  opacity: hide && 0.2,
  pointerEvents: hide && 'none',
});

const ChangeRequestRates = ({
  projectsStore: {
    project: { primaryPTOS, deazyAsSupplier, deazyAsClient },
    projectCurrency: { currency },
  },
  usersStore: { isTeamAdmin, isTeamMember },
  formValues = {},
}) => {
  const {
    rate,
    amount,
    clientRate,
    clientDisabled,
    supplierDisabled,
  } = formValues;

  const { supplierTotal, clientTotal } = React.useMemo(() => {
    return {
      supplierTotal:
        !deazyAsSupplier &&
        formatCurrency(
          amount * rate || 0,
          primaryPTOS.overriddenCurrency || primaryPTOS?.supplier?.currency,
        ),
      clientTotal:
        !deazyAsClient && formatCurrency(amount * clientRate || 0, currency),
    };
  }, [rate, clientRate, amount]);

  const cellWidth = React.useMemo(() => {
    if (isTeamAdmin) {
      return { wrapper: '66.66%', cell: '50%' };
    }
    if (isTeamMember) {
      return { wrapper: '33.33%', cell: '100%' };
    }
    return { wrapper: '100%', cell: '33.33%' };
  }, [isTeamAdmin, isTeamMember]);

  return (
    <>
      <Flex fontSize="14px" mb="4px" w={cellWidth.wrapper}>
        <Box w={cellWidth.cell}>HOURS</Box>
        {!isTeamMember && <Box w={cellWidth.cell}>DELIVERY PARTNER RATE</Box>}
        {!isTeamAdmin && !isTeamMember && (
          <Box w={cellWidth.cell}>CLIENT RATE</Box>
        )}
      </Flex>
      <Flex
        w={cellWidth.wrapper}
        borderRadius="8px"
        border="1px solid"
        borderColor="blue.solid"
        h="56px"
      >
        <Box
          w={cellWidth.cell}
          {...(!isTeamMember && { borderRight: '1px solid' })}
          borderColor="blue.solid"
        >
          <Field
            name="amount"
            variant={INPUT_VARIANTS.CONTAINER_FILLED}
            component={MaskedTextInput}
            placeholder="0.00"
            data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--crAmountInput`}
            decimalScale={2}
            fixedDecimalScale
            borderRadius="10px"
          />
        </Box>
        {!isTeamMember && (
          <Box
            w={cellWidth.cell}
            {...(!isTeamAdmin && { borderRight: '1px solid' })}
            borderColor="blue.solid"
            {...cellProps(supplierDisabled)}
          >
            {supplierDisabled ? (
              <NotAllowedIcon
                mx="auto"
                display="flex"
                mt="11px"
                fontSize={30}
              />
            ) : (
              <>
                <Field
                  name="rate"
                  variant={INPUT_VARIANTS.CONTAINER_FILLED}
                  component={MaskedTextInput}
                  placeholder="0.00"
                  decimalScale={2}
                  data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--crRateInput`}
                  fixedDecimalScale
                  changeIfActive
                  borderRadius="10px"
                  pr="30px"
                />
              </>
            )}
          </Box>
        )}

        {!isTeamAdmin && !isTeamMember && (
          <Box w={cellWidth.cell} {...cellProps(clientDisabled)}>
            {clientDisabled || isTeamAdmin ? (
              <NotAllowedIcon
                mx="auto"
                display="flex"
                mt="11px"
                fontSize={30}
              />
            ) : (
              <>
                <Field
                  name="clientRate"
                  data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--crClientRateInput`}
                  variant={INPUT_VARIANTS.CONTAINER_FILLED}
                  component={MaskedTextInput}
                  placeholder="0.00"
                  decimalScale={2}
                  fixedDecimalScale
                  borderRadius="10px"
                  changeIfActive
                  pr="30px"
                />
              </>
            )}
          </Box>
        )}
      </Flex>
      <Box mt="24px">
        <Field name="rates">
          {({ meta }) => <FormError meta={meta} mt="-14px" />}
        </Field>
        {!isTeamMember && (
          <>
            {!!(!deazyAsSupplier && !supplierDisabled && rate && amount) && (
              <Typography
                variant="h4"
                mb="12px"
                data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--totalDPValue`}
              >
                {`Total delivery partner value: ${supplierTotal}`}
              </Typography>
            )}
            {!!(
              !deazyAsClient &&
              !clientDisabled &&
              clientRate &&
              amount &&
              !isTeamAdmin
            ) && (
              <Typography
                variant="h4"
                data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--totalClientValue`}
              >{`Total client value: ${clientTotal}`}</Typography>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default inject(
  'usersStore',
  'projectsStore',
)(observer(ChangeRequestRates));
