import { Box, useDisclosure } from '@chakra-ui/react';
import ZoneContainer from '@components/ZoneContainer';
import Button from '@styles/Button';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { isEmpty, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import qs from 'query-string';
import React, { useState } from 'react';
import { MAIN_NAMES_QA } from '@app/constants';
import EditInvoiceModal from './EditInvoiceModal';
import NoPaymentsModal from './NoPaymentsModal';
import PaymentContainer from './PaymentContainer';
import PaymentOverview from './PaymentOverview';
import ProjectPaymentModal from './ProjectPaymentModal';

const Payments = ({
  projectId,
  projectsStore: { project },
  usersStore: { isAdminOrDL },
  paymentsStore: {
    isLoading,
    paymentsFirstRequest,
    payments,
    fetchPayments,
    fetchAvailablePayments,
    setPaymentProperty,
    deletePayment,
    updateInvoice,
    createProjectPayment,
    clearPayments,
    isCRAssignmentUnavailable,
    fetchPaymentOverview,
    isLoadingOverview,
    overviewFirstRequest,
    paymentOverview,
    preparePaymentInitialData,
  },
  crsStore: {
    assignCRtoPayment,
    assignCRGrouptoPayment,
    assignedPaymentId,
    fetchCR,
    fetchCRGroup,
    isLoading: isLoadingCR,
    clearCRs,
    cr,
  },
  history,
  location: { search, pathname },
}) => {
  const mainNameQA = MAIN_NAMES_QA.PROJECT_INVOICES;
  const { crId, crGroupId, paymentId } = qs.parse(search);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const [editInvoice, setEditInvoice] = useState();

  const [paymentToDuplicate, setPaymentToDuplicate] = useState();

  const onOpenInvoice = invoice => {
    setEditInvoice(invoice);
  };
  const onCloseInvoice = () => {
    setEditInvoice(null);
  };

  const onDuplicatePayment = payment => {
    setPaymentToDuplicate(payment);
  };
  const onClosePaymentToDuplicate = () => {
    setPaymentToDuplicate(null);
  };

  const [initialPaymentData, setInitialPaymentData] = useState({});

  const asyncFn = async () => {
    setInitialPaymentData({});
    const data = await preparePaymentInitialData(paymentToDuplicate);
    setInitialPaymentData(data);
  };

  React.useEffect(() => {
    if (!paymentsFirstRequest) {
      asyncFn();
    }
  }, [paymentToDuplicate, paymentsFirstRequest, payments]);

  React.useEffect(() => {
    if (!crId && !crGroupId) {
      fetchPayments(projectId, paymentId);
    } else {
      fetchAvailablePayments(projectId, crId || crGroupId, !!crGroupId);
    }
  }, [crGroupId, crId]);

  const {
    isOpen: isNoPaymentsOpen,
    onClose: onNoPaymentsClose,
    onOpen: onNoPaymentsOpen,
  } = useDisclosure();

  React.useEffect(() => {
    if (isCRAssignmentUnavailable && crId) {
      onNoPaymentsOpen();
    }
  }, [crId, isCRAssignmentUnavailable]);

  React.useEffect(() => {
    if (crId) {
      fetchCR(project.id, crId);
    }
    if (crGroupId) {
      fetchCRGroup(project.id, crGroupId);
    }
    fetchPaymentOverview(project.id);
    return () => {
      clearCRs();
      clearPayments();
    };
  }, []);

  const { origin } = window.location;

  if (isLoading || paymentsFirstRequest || isLoadingCR) {
    return <Spinner />;
  }

  return (
    <Box maxW="1280px" px="40px" w="100%" mx="auto" pb="60px" mt="32px">
      {isAdminOrDL && (
        <PaymentOverview
          mainNameQA={mainNameQA}
          isLoading={isLoadingOverview || overviewFirstRequest}
          tableData={paymentOverview?.tableData}
          depositTableData={paymentOverview?.depositTableData}
        />
      )}
      <Typography variant="h2" mt="52px" mb="32px" maxW="1280px">
        Invoices
      </Typography>
      {isAdminOrDL && (
        <ZoneContainer
          py="24px"
          mb="32px"
          borderColor="gray.200"
          height="112px"
        >
          <Button
            variant="outline"
            fontWeight="400"
            leftIcon={{ name: 'add' }}
            onClick={onOpen}
            isDisabled={project.archived}
            data-test-id={`${mainNameQA}--createInvoiceButton`}
          >
            Create project invoice
          </Button>
        </ZoneContainer>
      )}
      {isEmpty(payments) && !isLoading && (
        <Typography
          variant="h2"
          my="40px"
          data-test-id={`${mainNameQA}--noInvoiceMessage`}
        >
          There are no invoices here yet.
        </Typography>
      )}
      {map(payments, payment => {
        const checkCR = () => {
          if (!cr.supplierDisabled && payment.noPrimarySupplierInvoice) {
            return false;
          }
          return true;
        };
        return (
          <PaymentContainer
            key={`payment-container-${payment.id}`}
            paymentLink={`${origin}${pathname}?paymentId=${payment.id}`}
            project={project}
            payment={payment}
            modalIsOpen={!isEmpty(paymentToDuplicate)}
            toggleInvoices={() =>
              setPaymentProperty(payment.id, { isOpened: !payment.isOpened })
            }
            deletePayment={() => deletePayment(projectId, payment.id)}
            onEditInvoice={inv =>
              onOpenInvoice({ ...inv, paymentType: payment.paymentType })
            }
            onDuplicatePayment={paymentObj => onDuplicatePayment(paymentObj)}
            isAdminOrDL={isAdminOrDL}
            assignCRtoPayment={() => {
              if (crId) {
                assignCRtoPayment(projectId, crId, payment.id);
              } else if (crGroupId) {
                assignCRGrouptoPayment(projectId, crGroupId, payment.id);
              }
            }}
            isAssigning={assignedPaymentId === payment.id}
            canAssignCR={isAdminOrDL && (crId || crGroupId) && checkCR()}
            mainNameQA={mainNameQA}
          />
        );
      })}
      <EditInvoiceModal
        isOpen={!isEmpty(editInvoice)}
        onClose={onCloseInvoice}
        invoice={editInvoice}
        onSubmit={updateInvoice}
        projectId={projectId}
        syncEnabled={project.syncEnabled}
        project={project}
        mainNameQA={mainNameQA}
      />
      <ProjectPaymentModal
        isOpen={isOpen || !isEmpty(paymentToDuplicate)}
        onClose={
          isEmpty(paymentToDuplicate) ? onClose : onClosePaymentToDuplicate
        }
        project={project}
        initialPaymentData={initialPaymentData}
        createProjectPayment={createProjectPayment}
        mainNameQA={mainNameQA}
      />
      <NoPaymentsModal
        isOpen={isNoPaymentsOpen}
        onClose={() => {
          onNoPaymentsClose();
          history.push(pathname);
        }}
        mainNameQA={mainNameQA}
      />
    </Box>
  );
};

export default inject(
  'paymentsStore',
  'crsStore',
  'projectsStore',
  'usersStore',
)(observer(Payments));
