import { NEW_ROLES } from '@app/constants';
import { Avatar, Box, Button, Flex, Grid, Stack } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Switcher from '@components/Switcher';
import styled from '@emotion/styled';
import { APP_ROUTES } from '@routes';
import { COLORS } from '@styles/theme';
import Typography from '@styles/Typography';
import { find, get, isBoolean, isEmpty } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserCardBadge from './UserCardBadge';

export const StatsWrapper = styled(Flex)`
  :not(:first-of-type) {
    padding-left: 8px;
    margin-left: 8px;
  }
  height: fit-content;
`;

export const Stat = ({
  label,
  value,
  isSwitcher,
  onChange,
  isUserProfile = false,
  isDisabled = false,
  marginBottom,
  ...props
}) => (
  <Box
    borderLeft={!isUserProfile ? `1px solid ${COLORS.dark.mid}` : 'none'}
    pl={!isUserProfile ? '13px' : 'none'}
    mb={!isUserProfile ? '18px' : 'none'}
    _last={{ marginBottom: 0 || marginBottom }}
    {...props}
  >
    <Typography color="dark.solid" mb="8px" variant="text3">
      {label}
    </Typography>
    {isSwitcher ? (
      <Switcher
        defaultChecked={value}
        onChange={onChange}
        isDisabled={isDisabled}
        preventDefault
      />
    ) : (
      <Typography variant="text2" mb="8px" wordBreak="break-word">
        {value}
      </Typography>
    )}
  </Box>
);

const UserCardContent = ({
  setUserActivity,
  setUserAccessibility,
  user,
  usersStore: { isDL, isAdmin, isAdminOrDL, isTeamAdmin },
  isStatic,
}) => {
  const [userAccessible, setUserAccessible] = useState(true);
  React.useEffect(() => {
    if (isBoolean(user.accessible)) setUserAccessible(user.accessible);
  }, [user]);

  const handleUserActivity = checked => {
    setUserActivity(user.id, checked);
  };

  const handleUserAccessibility = e => {
    e.preventDefault();
    setUserAccessibility(user.id, () => {
      setUserAccessible(!userAccessible);
    });
  };

  const handleUserEdit = e => {
    e.preventDefault();
    history.push(APP_ROUTES.users.userByIdEdit(user.id));
  };

  const history = useHistory();

  const isUserAdmin = !!find(user.roles, role => role.name === NEW_ROLES.ADMIN);
  const isEditActive = isAdmin || (!isUserAdmin && isDL);

  return (
    <>
      <Box
        pb="12px"
        display="flex"
        alignItems="flex-start"
        borderBottom="1px solid"
        borderColor="dark.mid"
        w="100%"
      >
        <Flex pt="12px" pl="11px" align="center" mr="auto">
          <Avatar
            name={get(user, 'username', 'Deazy')}
            mr="7px"
            src={user?.profilePictureURL}
          />
          <Box>
            <Typography variant="h4" mb="5px">
              {!isStatic ? 'Basic details' : user.fullname}
            </Typography>
          </Box>
        </Flex>
        <UserCardBadge userAccessible={userAccessible} />
      </Box>
      <Grid
        position="relative"
        w="100%"
        p="32px 40px 90px"
        borderRadius="8px"
        gap="16px"
        templateColumns={[
          'repeat(1, 1fr)',
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
          'repeat(3, 1fr)',
          'repeat(3, 1fr)',
        ]}
      >
        <Stack gridColumn={['span 3', 'span 3', 'span 1', 'span 1', 'span 1']}>
          <Stat isUserProfile label="Username" value={user.username} />
        </Stack>
        <Stack gridColumn={['span 3', 'span 3', 'span 1', 'span 1', 'span 1']}>
          <Stat isUserProfile label="User no." value={user.id} />
        </Stack>
        <Stack gridColumn={['span 3', 'span 3', 'span 1', 'span 1', 'span 1']}>
          <Stat isUserProfile label="Email" value={user.email} />
        </Stack>
      </Grid>
      {(isAdminOrDL || isTeamAdmin) && (
        <Flex
          px="16px"
          pb="16px"
          alignItems="center"
          position="absolute"
          bottom="0"
          right="0"
        >
          {!userAccessible && (
            <Button
              leftIcon={<Icon name="send" fontSize={16} mr="5px" />}
              bg="primary.400"
              color="white"
              fontSize={14}
              variant="solid"
              borderRadius="8px"
              fontWeight="initial"
              size="sm"
              px="14px"
              mr="8px"
              onClick={handleUserAccessibility}
              _hover={{ bg: 'primary.300' }}
              _active={{ bg: 'primary.500' }}
            >
              Convert
            </Button>
          )}
          {!isStatic && (
            <Button
              leftIcon={<Icon fontSize={16} mr="5px" name="edit" />}
              bg="gray.400"
              color="white"
              fontSize={14}
              variant="solid"
              borderRadius="8px"
              onClick={handleUserEdit}
              fontWeight="initial"
              size="sm"
              mr="8px"
              isDisabled={!isEditActive}
              px="14px"
              _hover={{ bg: 'gray.300' }}
              _active={{ bg: 'gray.500' }}
            >
              Edit
            </Button>
          )}
          {!isEmpty(user) && (
            <StatsWrapper flexDir="column">
              <Stat
                label="Is active?"
                value={user.active}
                isSwitcher
                isDisabled={!isEditActive}
                onChange={handleUserActivity}
              />
            </StatsWrapper>
          )}
        </Flex>
      )}
    </>
  );
};

export default inject('usersStore')(observer(UserCardContent));
