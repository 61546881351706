import { Box, Input } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';

const RadioField = ({
  label,
  subLabel,
  align,
  onChange = {},
  isVertical = false,
  disabled = false,
  onAfterChange,
  value,
  checked,
  name,
  inputLabelStyle,
  wrapperProps,
  mainNameQA,
}) => (
  <Box
    display="flex"
    alignItems={align}
    justifyContent="flex-start"
    _focus={{ outline: 'none' }}
    cursor="pointer"
    as="label"
    htmlFor={`${name}-${value}`}
    mb={isVertical ? '20px' : '6px'}
    mr="26px"
    {...wrapperProps}
  >
    <Input
      name={name}
      type="radio"
      value={value}
      onChange={event => {
        if (!disabled) {
          onChange(event.target.value);
          if (onAfterChange) {
            onAfterChange(event.target.value);
          }
        }
      }}
      checked={checked}
      opacity={0}
      position="absolute"
      id={`${name}-${value}`}
      w="0px"
      p="0px"
    />
    <Box
      width="20px"
      height="20px"
      minW="20px"
      border="2px solid"
      borderColor={checked ? 'blue.solid' : 'gray.300'}
      borderRadius="50%"
      display="flex"
      bg="#fff"
      alignItems="center"
      justifyContent="center"
    >
      {checked && (
        <Box
          borderRadius="50%"
          width="10px"
          height="10px"
          bg="blue.solid"
          position="absolute"
          data-test-id={mainNameQA && `${mainNameQA}--radioIsChecked`}
        />
      )}
    </Box>
    <Box ml="10px">
      <Typography fontSize="15px" {...inputLabelStyle}>
        {label}
      </Typography>
      {subLabel && (
        <Typography
          variant="text3"
          color="dark.solid"
          mt="10px"
          data-test-id={mainNameQA && `${mainNameQA}--radioFieldsubLabel`}
        >
          {subLabel}
        </Typography>
      )}
    </Box>
  </Box>
);

export default RadioField;
