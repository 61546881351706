import React from 'react';
import useDownload from '@hooks/useDownload';
import { Stack } from '@chakra-ui/react';
import Button from '@styles/Button';
import { Link } from 'react-router-dom';

const iconButtonProps = {
  width: '40px',
  color: 'gray.500',
  bg: '#fff',
};

const InvoiceBasicActions = ({
  showEdit,
  onEdit,
  invoiceLink,
  downloadLink,
  isDownloadable,
  mainNameQA,
}) => {
  const { onDownload, isLoading } = useDownload({
    url: downloadLink,
  });

  return (
    <Stack spacing="12px" isInline alignItems="center" ml="auto">
      <Button
        {...iconButtonProps}
        iconButton={{ name: 'invoice', fontSize: 22 }}
        as={Link}
        to={invoiceLink}
        data-test-id={`${mainNameQA}--showInvoice`}
      />
      {isDownloadable && (
        <Button
          {...iconButtonProps}
          iconButton={{ name: 'download', fontSize: 22 }}
          onClick={onDownload}
          isLoading={isLoading}
          cursor="pointer"
          data-test-id={`${mainNameQA}--download`}
        />
      )}
      {showEdit && (
        <Button
          {...iconButtonProps}
          iconButton={{ name: 'edit', fontSize: 18 }}
          onClick={onEdit}
          data-test-id={`${mainNameQA}--edit`}
        />
      )}
    </Stack>
  );
};

export default InvoiceBasicActions;
