import { reject } from 'lodash-es';

export const DEAZY_COLUMNS = [
  { name: 'Manager', w: '30%' },
  { name: 'Start date', w: '12.5%' },
  { name: 'End date', w: '12.5%' },
  { name: 'Hours', w: '15%' },
  { name: 'Rate', w: '15%' },
  { name: 'Cost', w: '15%' },
];

export const TEAM_COLUMNS = [
  { name: 'Name', w: '30%', flex: 1 },
  { name: 'Role', w: '15%' },
  { name: 'Hours', w: '7%' },
  { name: 'Start date', w: '9%' },
  { name: 'End date', w: '9%' },
  { name: 'DP rate', w: '9.5%' },
  { name: 'DP cost', w: '9.5%' },
  { name: 'Client rate', w: '9%' },
  { name: 'Client cost', w: '12%' },
];

const TEAM_COLUMNS_FOR_DEV = [
  { name: 'Name', w: '35%', flex: 1 },
  { name: 'Role', w: '22.5%' },
  { name: 'Hours', w: '22.5%' },
  { name: 'Start date', w: '15%' },
  { name: 'End date', w: '15%' },
];

const TEAM_COLUMNS_FOR_SA = [
  { name: 'Name', w: '25%', flex: 1 },
  { name: 'Role', w: '15%' },
  { name: 'Hours', w: '10%' },
  { name: 'Start date', w: '10%' },
  { name: 'End date', w: '10%' },
  { name: 'Delivery Partner rate', w: '15%' },
  { name: 'Delivery Partner cost', w: '15%' },
];

export const getAllocationsColumns = ({
  isTeamAdmin,
  isClient,
  isTeamMember,
  isFixedPrice,
}) => {
  let cols = TEAM_COLUMNS;

  if (isTeamMember) {
    cols = TEAM_COLUMNS_FOR_DEV;
  }
  if (isTeamAdmin) {
    cols = TEAM_COLUMNS_FOR_SA;
  }
  if (isClient) {
    cols = reject(
      cols,
      col => col.name === 'DP rate' || col.name === 'DP cost',
    );
  }

  return isFixedPrice
    ? cols
    : reject(cols, col => col.name === 'Start date' || col.name === 'End date');
};

export const getDeazyAllocationsColumns = ({ isFixedPrice }) => {
  return isFixedPrice
    ? DEAZY_COLUMNS
    : reject(
        DEAZY_COLUMNS,
        col => col.name === 'Start date' || col.name === 'End date',
      );
};
