import { FORM_ERROR } from 'final-form';
import {
  filter,
  isObject,
  reduce,
  has,
  isEmpty,
  map,
  first,
  find,
  omit,
} from 'lodash-es';
import { action, makeObservable, observable } from 'mobx';
import { nanoid } from 'nanoid';
import { ENGAGEMENT_TYPES } from '@app/constants';

export default class SowPaymentScheduleStore {
  constructor({ API, projectsStore, toastsStore }) {
    makeObservable(this);
    this.toastsStore = toastsStore;
    this.projectsStore = projectsStore;
    this.API = API;
  }

  @observable isLoading = false;

  @observable isCoping = false;

  @observable paymentInitialData = {};

  @action clearPaymentInitialData = () => {
    this.paymentInitialData = {};
    this.copiedPaymentData = {};
  };

  @action prepareInitialData = paymentSchedule => {
    return {
      projectId: this.projectsStore.project.id,
      ...paymentSchedule,
      maxValue:
        (100 * first(paymentSchedule.paymentScheduleItems)?.value) /
        first(paymentSchedule.paymentScheduleItems)?.percentage,
      paymentScheduleItems: map(paymentSchedule.paymentScheduleItems, item => ({
        rowId: nanoid(10),
        ...item,
      })),
    };
  };

  @action getPaymentInitialData = async (projectId, projectToSupplierId) => {
    try {
      this.paymentInitialData = {};
      this.copiedPaymentData = {};
      this.isLoading = true;
      const exisitingDataEndpoint = this.API[
        projectToSupplierId
          ? 'getPaymentScheduleSettingsForSupplier'
          : 'getPaymentScheduleSettingsForClient'
      ];
      const { data: exisitngData } = await exisitingDataEndpoint(
        projectId,
        projectToSupplierId,
      );
      this.paymentInitialData = {
        ...this.prepareInitialData(exisitngData),
        isManual: !projectToSupplierId
          ? this.projectsStore.project.manualClientSchedule
          : find(
              this.projectsStore.project.projectToSuppliers,
              pts => pts.id === projectToSupplierId,
            )?.manualSchedule,
      };
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Could not fetch payment schedule. Please try again later.',
      });
    } finally {
      this.isLoading = false;
    }
  };

  @action updateManualTable = async ({
    projectId,
    projectToSupplierId,
    manualTable,
  }) => {
    try {
      await this.API[
        projectToSupplierId
          ? 'updateSupplierManulaTable'
          : 'updateClientManulaTable'
      ]({ projectId, projectToSupplierId, manualTable });
      this.paymentInitialData.isManual = manualTable;
      if (!projectToSupplierId) {
        this.projectsStore.project.manualClientSchedule = manualTable;
      } else {
        this.projectsStore.project.projectToSuppliers = map(
          this.projectsStore.project.projectToSuppliers,
          pts => ({
            ...pts,
            manualSchedule:
              pts.id === projectToSupplierId ? manualTable : pts.manualSchedule,
          }),
        );
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Network error! Please try again later.',
      });
    }
  };

  @action createOrUpdatePaymentSchedule = async (values, cb) => {
    try {
      const filteredPayments = filter(values.paymentScheduleItems, p => p.name);
      this.validatePaymentSchedule(filteredPayments);
      const { data } = await this.API[
        values.id
          ? 'updatePaymentScheduleSettings'
          : 'postPaymentScheduleSettings'
      ](values.projectId, {
        ...values,
        ...(!isEmpty(filteredPayments) && {
          paymentScheduleItems: filteredPayments,
        }),
      });
      this.paymentInitialData = this.prepareInitialData({
        ...this.paymentInitialData,
        ...data,
      });
      this.copiedPaymentData = {};
      if (cb) {
        cb();
      }
    } catch (e) {
      if (isObject(e) && has(e, FORM_ERROR)) {
        return e;
      }
      this.toastsStore.showError({
        title: e.message || 'Network error! Please try again later.',
      });
    }
  };

  validatePaymentSchedule = paymentScheduleItems => {
    const isProjectType =
      this.projectsStore.project.engagementType ===
      ENGAGEMENT_TYPES.PROJECT_BUILD;
    let errorMsg = '';
    const is100PercentTotal = p => {
      return reduce(p, (sum, payment) => sum + +payment.percentage, 0) !== 100;
    };

    const areAllFieldsFilled = p => {
      const filteredPayments = isProjectType
        ? filter(
            p,
            payment =>
              !payment.invoiceDate ||
              !payment.value ||
              !payment.name ||
              !payment.percentage,
          )
        : filter(p, payment => !payment.name);

      return isEmpty(filteredPayments);
    };

    if (is100PercentTotal(paymentScheduleItems) && isProjectType) {
      errorMsg = 'Invoice percentages do not add up to 100%';
    }
    if (!areAllFieldsFilled(paymentScheduleItems)) {
      errorMsg = 'Please fill in the missing fields in the table.';
    }
    if (errorMsg) {
      // eslint-disable-next-line no-throw-literal
      throw { [FORM_ERROR]: errorMsg };
    }
  };

  @observable copiedPaymentData = {};

  @action copyClientScheduleToSupplier = async (
    projectId,
    projectToSupplierId,
  ) => {
    try {
      this.isCoping = true;
      const {
        data: { id, ...clientData },
      } = await this.API.getPaymentScheduleSettingsForClient(projectId);
      this.copiedPaymentData = {
        ...this.paymentInitialData,
        ...omit(
          {
            ...omit(clientData, ['createdAt', 'clientProject']),
            id: this.paymentInitialData?.id,
            projectToSupplierId,
            paymentScheduleItems: map(
              clientData.paymentScheduleItems,
              item => ({
                ...omit(item, ['id', 'createdAt', 'updatedAt']),
                rowId: nanoid(10),
                value:
                  (this.paymentInitialData?.maxValue * item.percentage) / 100,
              }),
            ),
          },
          this.paymentInitialData?.id ? [] : ['id'],
        ),
      };
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Could not copy payment schedule. Please try again later.',
      });
    } finally {
      this.isCoping = false;
    }
  };
}
