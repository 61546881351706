import {
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
} from '@chakra-ui/react';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import React from 'react';

const ProposalsAccordionItemBody = ({
  children,
  stepTitle,
  stepNumber,
  iconWhenTrySubmitting,
  isError,
  isCompleted,
  isEditing,
  showArrow = true,
  mainNameQA,
  isSmallerScreen,
}) => {
  return (
    <>
      <AccordionButton
        borderRadius="8px"
        borderBottom="1px solid"
        borderColor="dark.mid"
        className={isError && 'errorInSection'}
        {...(isError && { boxShadow: '0 0 0 1px #ff6666', color: 'red.500' })}
        _focus={{}}
        _expanded={{
          borderBottomRightRadius: '0',
          borderBottomLeftRadius: '0',
        }}
        height={isSmallerScreen ? '56px' : '73px'}
        padding={isSmallerScreen ? '16px 12px' : '24px 32px'}
        _hover={{
          cursor: 'default',
          ...(showArrow && { bg: 'dark.light', cursor: 'pointer' }),
        }}
        data-test-id={mainNameQA && `${mainNameQA}--button-${stepNumber}`}
      >
        <Flex flex="1" alignItems="baseline" id={`stepNumber-${stepNumber}`}>
          {iconWhenTrySubmitting && (
            <Icon
              name={iconWhenTrySubmitting}
              color={isError ? 'red.500' : 'green.500'}
              mr="11px"
            />
          )}
          {isCompleted && <Icon name="check" color="green.500" mr="11px" />}
          {isEditing && <Icon name="pending" mr="11px" />}
          <Typography variant="h4">{stepTitle}</Typography>
        </Flex>
        {showArrow && <AccordionIcon />}
      </AccordionButton>
      <AccordionPanel padding={isSmallerScreen ? '12px' : '32px'}>
        {children}
      </AccordionPanel>
    </>
  );
};

export default ProposalsAccordionItemBody;
