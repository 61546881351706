import { API } from '@app/api';
import { Box, Button } from '@chakra-ui/react';
import AsyncAutocomplete from '@components/forms/AsyncAutocomplete';
import TextInput from '@components/forms/TextInput';
import Icon from '@components/Icon';
import SectionTitle from '@components/SectionTitle';
import WhiteCard from '@components/WhiteCard';
import { INPUT_VARIANTS } from '@styles/theme';
import {
  composeValidators,
  mustBeValidEmail,
  required,
} from '@utils/formValidators';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form } from 'react-final-form';

const Mailing = ({ mailingStore: { sendMailingTemplate } }) => (
  <Box p="32px" pb="155px" minH="calc(100vh - 80px)">
    <WhiteCard>
      <SectionTitle mt="0px">Mailing test list</SectionTitle>
      <Form
        onSubmit={sendMailingTemplate}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Box my="40px">
                <Box mb="25px" maxW="480px">
                  <Field
                    label="Email"
                    name="email"
                    placeholder="Email"
                    type="text"
                    component={TextInput}
                    variant={INPUT_VARIANTS.NORMAL}
                    validate={composeValidators(required, mustBeValidEmail)}
                    isBordered
                    containerProps={{ mb: '32px' }}
                  />
                  <Field
                    name="template"
                    label="Select a template to test"
                    placeholder="Choose a template"
                    component={AsyncAutocomplete}
                    customOptionMap={option => ({
                      value: option,
                      label: option,
                    })}
                    styleProps={{ mb: '32px' }}
                    getOptionsFn={API.getMailing}
                    isBordered
                  />
                </Box>
                <Button
                  bg="aqua.solid"
                  color="#fff"
                  fontSize={22}
                  borderRadius="32px"
                  fontWeight="normal"
                  h="72px"
                  type="submit"
                  mt="20px"
                  maxW="300px"
                  w="100%"
                  leftIcon={
                    <Icon name="send" mr="20px" color="#fff" fontSize={22} />
                  }
                  isLoading={submitting}
                  mx="auto"
                  textAlign="center"
                >
                  Submit test email
                </Button>
              </Box>
            </form>
          );
        }}
      />
    </WhiteCard>
  </Box>
);

export default inject('mailingStore')(observer(Mailing));
