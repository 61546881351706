import React from 'react';
import { Box } from '@chakra-ui/react';
import { map, times } from 'lodash-es';
import { Facebook as ProjectLoader } from 'react-content-loader';
import InfiniteScroll from 'react-infinite-scroller';

import TaskCard from './TaskCard';

const TaskList = ({
  initialLoad,
  hasMore,
  status,
  tasks,
  onLoadMore,
  mainNameQA,
}) => {
  const Loader = map(times(6), (_, idx) => (
    <Box mb="16px" w="100%" key={`loader-${idx}`} maxW="720px">
      <ProjectLoader height="240px" width="100%" />
    </Box>
  ));

  if (initialLoad) {
    return Loader;
  }

  return (
    <Box>
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={onLoadMore}
        threshold={1300}
        hasMore={hasMore}
        loader={Loader}
      >
        {map(tasks, task => (
          <TaskCard
            key={`task-card-${task.id}`}
            paramsStatus={status}
            task={task}
            data-test-id={`${mainNameQA}-card-${task.id}`}
          />
        ))}
      </InfiniteScroll>
    </Box>
  );
};

export default TaskList;
