import { Text } from '@chakra-ui/react';
import { has } from 'lodash-es';
import React from 'react';

const VARIANTS = {
  h1: { fontFamily: 'PP', fontSize: [28, null, null, 32], fontWeight: 600 },
  h2: {
    fontFamily: 'PP',
    fontSize: [20, null, null, 26],
    fontWeight: 600,
  },
  h3: { fontFamily: 'PP', fontSize: [18, null, null, 22], fontWeight: 600 },
  h4: { fontFamily: 'PP', fontSize: [15, null, null, 18], fontWeight: 600 },
  h5: { fontFamily: 'PP', fontSize: 16, fontWeight: 600 },
  title: {
    fontFamily: 'WB',
    fontSize: [13, null, null, 16],
    fontWeight: 600,
    lineHeight: 1.17,
  },
  text1: {
    fontFamily: 'WS',
    fontSize: [12, null, null, 15],
    fontWeight: 400,
    lineHeight: [1.33, null, null, 1.2],
  },
  text2: {
    fontFamily: 'WS',
    fontSize: [11, null, null, 13],
    fontWeight: 400,
  },
  text3: { fontFamily: 'WS', fontSize: 11, fontWeight: 400 },
  caption: { textTransform: 'uppercase', fontFamily: 'WM', fontSize: 12 },
};

const Typography = React.forwardRef(
  ({ variant = 'text1', children, ...props }, ref) => {
    const styles = has(VARIANTS, variant) ? VARIANTS[variant] : VARIANTS.text1;

    return (
      <Text {...styles} {...props} ref={ref}>
        {children}
      </Text>
    );
  },
);

export default Typography;
