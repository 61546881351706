import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import React from 'react';

const TaxCell = ({ isLoading, value, ...props }) => {
  if (isLoading) {
    return <Spinner variant="small" />;
  }
  return (
    <Typography
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      {...props}
    >
      {value}
    </Typography>
  );
};

export default TaxCell;
