import React from 'react';
import { API } from '../app/api';

const injectMarketplaceComponent = Component => {
  return () => {
    return <Component API={API} />;
  };
};

export default injectMarketplaceComponent;
