import { Tab, TabList, Tabs as ChakraTabs } from '@chakra-ui/react';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import { findIndex, map } from 'lodash-es';
import React from 'react';
import { Link } from 'react-router-dom';

const tabListProps = isSmallerScreen =>
  isSmallerScreen
    ? {
        orientation: 'horizontal',
        width: 'auto',
        background: 'primary.100',
        padding: '12px 8px',
        overflow: 'auto',
      }
    : {
        position: 'fixed',
        top: 0,
        zIndex: '10',
        height: '100vh',
        left: '92px',
        py: '120px',
        orientation: 'vertical',
      };

const tabProps = isSmallerScreen => ({
  borderRadius: '8px',
  border: 'none',
  ...(isSmallerScreen
    ? {
        minWidth: 'auto',
        padding: '8px 12px',
        fontSize: '12px',
        lineHeight: '16px',
        margin: 0,
        whiteSpace: 'nowrap',
      }
    : {
        minWidth: '140px',
        py: '8px',
        px: '16px',
        mx: '16px',
        mb: '8px',
        fontSize: '15px',
        lineHeight: '22px',
        justifyContent: 'flex-start',
        textAlign: 'left',
      }),
});

const SubSidebarTabs = ({
  onAfterChange,
  value,
  tabs,
  tabStyles,
  subsidebarProps,
}) => {
  const [tabIndex, setTabIndex] = React.useState(
    value ? findIndex(tabs, { value }) : 0,
  );
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  React.useEffect(() => {
    setTabIndex(findIndex(tabs, { value }));
  }, [value, tabs]);

  return (
    <ChakraTabs
      onChange={index => {
        if (!tabs[index]?.link) {
          setTabIndex(index);
        }
        if (onAfterChange) {
          onAfterChange(tabs[index].value, tabs[index]);
        }
      }}
      index={tabIndex}
      size="sm"
      {...subsidebarProps}
      {...tabListProps(isSmallerScreen)}
    >
      <TabList color="black" border="none">
        {map(tabs, ({ isDisabled = false, label, link, value: v }, index) => {
          return (
            <Tab
              data={`number=${index}`}
              isDisabled={isDisabled}
              key={`key-tab-value-${v}`}
              {...tabProps(isSmallerScreen)}
              _selected={{ bg: '#E7EBFF' }}
              {...(link && { as: Link, to: link })}
              {...tabStyles}
            >
              {label}
            </Tab>
          );
        })}
      </TabList>
    </ChakraTabs>
  );
};

export default SubSidebarTabs;
