import { Flex, Tooltip } from '@chakra-ui/react';
import Icon from '@components/Icon';
import useMediaQuery from '@hooks/useMediaQuery';
import Badge from '@styles/Badge';
import { breakpoint } from '@styles/breakpoints';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { isEmpty, join, map } from 'lodash-es';
import React from 'react';
import SupplierStat from './SupplierStat';

const listItemStyles = isSmallerScreen =>
  isSmallerScreen
    ? {
        mb: '16px',
        borderRadius: '8px',
        p: '12px',
        bg: '#fff',
      }
    : {
        mb: '24px',
        border: '1px solid',
        borderColor: 'dark.mid',
        borderRadius: '8px',
        minH: '74px',
      };

const numberStyles = {
  alignItems: 'center',
  justifyContent: 'center',
  bg: 'dark.solid',
  color: '#fff',
  w: '40px',
  minW: '40px',
  fontFamily: 'WM',
};

const RecommendedSupplierListItem = ({
  ordinalNumber,
  shortlistSuppliers,
  supplier,
  mainNameQA,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isAssigning, setIsAssigning] = React.useState(false);
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  const assignedIcon = isSmallerScreen ? (
    <Flex w="40px" h="40px" bg="green.light" align="center" justify="center">
      <Icon name="check" color="green.solid" fontSize="21px" />
    </Flex>
  ) : (
    <Typography color="green.solid" variant="text2">
      Shortlisted
    </Typography>
  );

  return (
    <Flex {...listItemStyles(isSmallerScreen)}>
      {!isSmallerScreen && (
        <Flex
          {...numberStyles}
          fontSize={16}
          borderTopLeftRadius="8px"
          borderBottomLeftRadius="8px"
        >
          {ordinalNumber}
        </Flex>
      )}
      <Flex w="100%" flexDir={isSmallerScreen ? 'column' : 'row'}>
        {!isSmallerScreen && (
          <Flex
            p="14px 16px"
            align="center"
            {...(isSmallerScreen
              ? { w: '80%', order: 1 }
              : { minW: '150px', maxW: '200px' })}
          >
            <Typography variant="title">{supplier.name}</Typography>
          </Flex>
        )}
        <Flex
          {...(isSmallerScreen
            ? { wrap: 'wrap' }
            : { pt: '15px', w: '100%', wrap: 'wrap', align: 'flex-start' })}
        >
          {isSmallerScreen && (
            <Flex
              align="center"
              {...(isSmallerScreen
                ? { w: '80%', order: 1 }
                : { flex: 1, minW: '150px' })}
            >
              {isSmallerScreen && (
                <Flex
                  {...numberStyles}
                  h="40px"
                  borderRadius="50px"
                  fontSize={13}
                >
                  {ordinalNumber}
                </Flex>
              )}
              <Typography variant="title" px="16px">
                {supplier.name}
              </Typography>
            </Flex>
          )}
          <SupplierStat
            w="16%"
            label="Status"
            value={supplier.status}
            mb="15px"
            py="2px"
            {...(isSmallerScreen && { w: '50%', order: 3 })}
          />
          <SupplierStat
            w="16%"
            label="Flags"
            value={join(supplier.flags, ', ')}
            mb="15px"
            py="2px"
            {...(isSmallerScreen && { w: '50%', order: 4 })}
          />
          <SupplierStat
            w="16%"
            label="Industry vertical"
            value={join(supplier.industryVerticals, ', ')}
            mb="15px"
            py="2px"
            {...(isSmallerScreen && { w: '50%', order: 5 })}
          />
          <SupplierStat
            w="16%"
            label="Active projects"
            showZero="true"
            value={supplier.activeProjects}
            mb="15px"
            py="2px"
            {...(isSmallerScreen && { w: '50%', order: 6 })}
          />
          <SupplierStat
            w="16%"
            label="White label"
            value={supplier.whiteLabel ? 'Yes' : 'No'}
            mb="15px"
            py="2px"
            {...(isSmallerScreen && { w: '50%', order: 6 })}
          />
          <Flex
            alignItems="center"
            justify="flex-end"
            mb="15px"
            py="2px"
            ml="auto"
            minH="40px"
            {...(isSmallerScreen && { w: '20%', order: 2 })}
          >
            {supplier.isAssigned ? (
              assignedIcon
            ) : (
              <Tooltip
                zIndex={10000}
                label="Shortlist Delivery Partner"
                hasArrow
                bg="dark.solid"
                px="24px"
                py="12px"
                placement="top"
                lineHeight="normal"
                borderRadius="8px"
              >
                <Button
                  width="40px"
                  data-test-id={`${mainNameQA}--setAssign`}
                  color="#fff"
                  isLoading={isAssigning}
                  iconButton={{ name: 'add', fontSize: 16 }}
                  onClick={async () => {
                    setIsAssigning(true);
                    await shortlistSuppliers(
                      {
                        suppliers: [{ supplier }],
                      },
                      {},
                    );
                    setIsAssigning(false);
                  }}
                />
              </Tooltip>
            )}
          </Flex>

          {!isSmallerScreen && (
            <Flex
              justify="flex-end"
              mb="15px"
              p="2px 16px 0 12px"
              {...(isSmallerScreen && { w: '100%', order: 7 })}
            >
              <Button
                color="black"
                variant="ghost"
                width="40px"
                iconButton={{ name: 'arrowLeft', fontSize: 30 }}
                transform={isOpen ? 'rotate(90deg)' : 'rotate(-90deg)'}
                onClick={() => setIsOpen(!isOpen)}
              />
            </Flex>
          )}

          {isOpen && (
            <>
              <SupplierStat
                w="16%"
                label="Size"
                value={supplier.size}
                my="15px"
                py="2px"
                {...(isSmallerScreen && { w: '50%', order: 7 })}
              />
              <SupplierStat
                w="16%"
                label="Country"
                value={supplier.country}
                my="15px"
                py="2px"
                {...(isSmallerScreen && { w: '50%', order: 8 })}
              />
              <SupplierStat
                w="16%"
                label="Number of strikes"
                value={supplier.numberOfStrikes}
                my="15px"
                py="2px"
                {...(isSmallerScreen && { w: '50%', order: 9 })}
              />
              <SupplierStat
                w="16%"
                label="Rate"
                value={supplier.rate}
                my="15px"
                py="2px"
                {...(isSmallerScreen && { w: '50%', order: 10 })}
              />
              <Flex m="15px 0" w="100%" {...(isSmallerScreen && { order: 11 })}>
                <SupplierStat
                  w="calc(48% + 220px)"
                  label={!isSmallerScreen && 'Technologies'}
                  mt={isSmallerScreen && '-15px'}
                  p="2px 16px 8px"
                  {...(isSmallerScreen && { p: 0, w: '100%' })}
                  value={
                    <>
                      <Flex display="inline">
                        {!isEmpty(supplier.matchingTechnologies) && (
                          <>
                            <Typography
                              variant="text2"
                              as={isSmallerScreen ? 'p' : 'span'}
                              mr="8px"
                            >
                              {`${
                                isSmallerScreen
                                  ? 'Technologies matched'
                                  : 'Matched'
                              }  with brief:`}
                            </Typography>
                            {map(supplier.matchingTechnologies, tech => (
                              <Badge
                                mt={isSmallerScreen && '8px'}
                                as="span"
                                bg="dark.mid"
                                color="#000"
                                variant="tertiary"
                                borderRadius="8px"
                                mb="8px"
                                mr="8px"
                                p="6px 12px"
                                lineHeight={1}
                              >
                                {tech}
                              </Badge>
                            ))}
                          </>
                        )}
                      </Flex>
                      {!isEmpty(supplier.missmatchingTechnologies) && (
                        <>
                          {!isSmallerScreen ? (
                            <Typography variant="text2">
                              {`Others: ${join(
                                supplier.missmatchingTechnologies,
                                ', ',
                              )}`}
                            </Typography>
                          ) : (
                            <>
                              <Typography variant="text2" mb="2px">
                                Other technologies:
                              </Typography>
                              <Typography>
                                {`${join(
                                  supplier.missmatchingTechnologies,
                                  ', ',
                                )}
                                `}
                              </Typography>
                            </>
                          )}
                        </>
                      )}
                    </>
                  }
                />
              </Flex>
            </>
          )}
        </Flex>
        {isSmallerScreen && (
          <Flex
            w="60px"
            align="center"
            justify="center"
            {...(isSmallerScreen && { w: '100%', order: 7 })}
          >
            <Button
              onClick={() => setIsOpen(!isOpen)}
              variant="ghost"
              mx="auto"
              color="primary.400"
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
              rightIcon={
                <Icon
                  name="chevronDown"
                  fontSize={12}
                  transform={isOpen ? 'rotate(-180deg)' : 'rotate(0deg)'}
                  position="relative"
                />
              }
            >
              {isOpen ? 'Less' : 'Show more'} detail
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default RecommendedSupplierListItem;
