import { Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';
import { cellStyle } from './allocationsHelpers';

const AllocationHead = ({
  showAllocationDates = false,
  isTeamAdmin = false,
  isTeamMember,
}) => {
  return (
    <Flex w="100%" mb="12px" mt="24px" color="dark.solid">
      <Flex flex={1}>
        <Typography
          variant="caption"
          ml="40px"
          flex={1}
          minW="100px"
          sx={cellStyle(150)}
        >
          Name
        </Typography>
        <Typography variant="caption" w="142px" sx={cellStyle(160)}>
          Role
        </Typography>
        <Typography variant="caption" w="140px" sx={cellStyle(140)}>
          Hours (decimal)
        </Typography>
        {showAllocationDates && (
          <>
            <Typography variant="caption" w="150px" sx={cellStyle(150)}>
              Start date
            </Typography>
            <Typography variant="caption" w="150px" sx={cellStyle(150)}>
              End date
            </Typography>
          </>
        )}
      </Flex>
      {!isTeamMember && (
        <Flex sx={cellStyle(600)} maxW="600px" w="100%">
          <Typography variant="caption" pr="5%" w={isTeamAdmin ? '50%' : '25%'}>
            DP rate
          </Typography>
          <Typography variant="caption" pr="5%" w={isTeamAdmin ? '50%' : '25%'}>
            DP cost
          </Typography>
          {!isTeamAdmin && (
            <>
              <Typography variant="caption" w="25%">
                Client rate
              </Typography>
              <Typography variant="caption" w="25%">
                Client cost
              </Typography>
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};
export default AllocationHead;
