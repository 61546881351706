import { PROJECT_PAYMENT_TYPES } from '@app/constants';
import { Box, Flex, Stack, Tooltip } from '@chakra-ui/react';
import DatePicker from '@components/forms/DatePicker';
import TextInput from '@components/forms/_common/TextInput';
import Icon from '@components/Icon';
import Modal from '@components/Modal';
import { getDatePickerProps } from '@utils/datesUtils';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { required } from '@utils/formValidators';
import { find, join, split, get } from 'lodash-es';
import { API } from '@app/api';
import moment from 'moment';
import React from 'react';
import { Field, Form } from 'react-final-form';
import useQuery from '@hooks/useQuery';
import Spinner from '@styles/Spinner';
import { getDueDaysTerms } from '@utils/paymentUtils';

const ReferenceLabel = ({ referenceChanged = false }) => {
  return (
    <Typography variant="text2">
      Reference
      {referenceChanged && (
        <Tooltip
          zIndex={10000}
          label="Reference was auto modified due to invoice date change. Make sure this field is filled in correctly."
          hasArrow
          p="12px"
          placement="top"
        >
          <Box as="span" ml="8px">
            <Icon mb="3px" name="warning" color="red.solid" />
          </Box>
        </Tooltip>
      )}
    </Typography>
  );
};

const XeroIdLabel = ({ syncEnabled }) => {
  return (
    <Typography variant="text2">
      Xero ID
      {syncEnabled && (
        <Tooltip
          zIndex={10000}
          label="Xero sync needs to be disabled for xeroID binding changes"
          hasArrow
          p="12px"
          placement="top"
        >
          <Box as="span" ml="8px">
            <Icon mb="3px" name="warning" color="red.solid" />
          </Box>
        </Tooltip>
      )}
    </Typography>
  );
};

const EditInvoiceModal = ({
  invoice = {},
  onSubmit = () => {},
  isOpen,
  syncEnabled,
  onClose,
  projectId,
  project,
  mainNameQA,
}) => {
  const isClient = (invoice || {}).contactType === 'Client';
  const fieldPrefix = isClient ? 'client' : 'supplier';
  const initialValues = {
    [fieldPrefix]: { ...invoice },
  };
  const isFixedPayment =
    (invoice || {}).paymentType === PROJECT_PAYMENT_TYPES.FIXED_PAYMENT;

  const date = moment.utc(project.startDate);
  const datePickerProps = getDatePickerProps(date, false);

  const supplierApiFn = React.useMemo(() => {
    const projectToSupplierId = find(
      project?.projectToSuppliers,
      pts => pts?.supplier?.id === invoice?.supplier?.id,
    )?.id;
    return () =>
      API.getPaymentScheduleSettingsForSupplier(projectId, projectToSupplierId);
  }, [project.projectToSuppliers, isOpen]);

  const clientApiFn = () => API.getPaymentScheduleSettingsForClient(projectId);

  const {
    data: paymentSchedule,
    isLoading: isLoadingSupplierPaymentTerms,
  } = useQuery(supplierApiFn, isOpen && !isClient);
  const {
    data: clientPaymentSchedule,
    isLoading: isLoadingClientPaymentTerms,
  } = useQuery(
    clientApiFn,
    (isOpen && isClient) || paymentSchedule.paymentTerms === 'Client + 7',
  );

  const dueDateDaysToAdd = React.useMemo(() => {
    return getDueDaysTerms(
      isClient ? clientPaymentSchedule : paymentSchedule,
      paymentSchedule.paymentTerms === 'Client + 7' &&
        getDueDaysTerms(clientPaymentSchedule),
    );
  }, [paymentSchedule, clientPaymentSchedule, isClient]);

  const startOfCurrentDay = moment
    .utc()
    .startOf('day')
    .toDate();

  const startOfProjectStartDate = moment
    .utc(project.startDate)
    .startOf('day')
    .toDate();

  const dueDatePickerProps = getDatePickerProps(
    date.toDate() < moment.utc().toDate()
      ? startOfCurrentDay
      : startOfProjectStartDate,
  );
  const periodEndPickerProps = periodStart => getDatePickerProps(periodStart);

  return (
    <Modal isOpen={isOpen} onClose={onClose} styleProps={{ minW: '720px' }}>
      <Typography variant="h2" mb="32px">
        Edit invoice
      </Typography>
      {isLoadingClientPaymentTerms || isLoadingSupplierPaymentTerms ? (
        <Spinner />
      ) : (
        <Form
          onSubmit={values => onSubmit(values[fieldPrefix], projectId, onClose)}
          initialValues={initialValues}
          render={({ handleSubmit, values, submitting, form }) => {
            const { referenceChanged } = values[fieldPrefix];

            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <>
                  <Flex justifyContent="space-between" mb="32px">
                    <Field
                      label="Invoice date"
                      name={`${fieldPrefix}.invoiceDate`}
                      data-test-id={`${mainNameQA}--${fieldPrefix}.invoiceDate`}
                      component={DatePicker}
                      dayPickerProps={datePickerProps}
                      calendarColor="dark.solid"
                      validate={required}
                      minW="10px"
                      isErrorAbsolute
                      containerProps={{ w: '45%' }}
                      onAfterChange={d => {
                        const invoiceDate = moment.utc(d);

                        form.change(
                          `${fieldPrefix}.dueDate`,
                          moment.utc(d).add({ days: dueDateDaysToAdd }),
                        );
                        let { invoiceDate: prevInvoiceDate } = values[
                          fieldPrefix
                        ];
                        const { reference } = values[fieldPrefix];
                        prevInvoiceDate = moment.utc(prevInvoiceDate);
                        if (
                          !invoiceDate.isSame(prevInvoiceDate, 'month') ||
                          !invoiceDate.isSame(prevInvoiceDate, 'year')
                        ) {
                          const referenceParts = split(reference, ' ');
                          const referenceDate = referenceParts.pop();
                          if (
                            referenceDate &&
                            moment(referenceDate, 'YYYY/MM', true).isValid()
                          ) {
                            form.change(
                              `${fieldPrefix}.reference`,
                              join(
                                [
                                  ...referenceParts,
                                  invoiceDate.format('YYYY/MM'),
                                ],
                                ' ',
                              ),
                            );
                            form.change('referenceChanged', true);
                          }
                        }
                      }}
                    />
                    <Field
                      label="Due date"
                      name={`${fieldPrefix}.dueDate`}
                      data-test-id={`${mainNameQA}--${fieldPrefix}.dueDate`}
                      component={DatePicker}
                      dayPickerProps={dueDatePickerProps}
                      calendarColor="dark.solid"
                      minW="10px"
                      isErrorAbsolute
                      validate={required}
                      containerProps={{ w: '45%' }}
                    />
                  </Flex>
                  {isFixedPayment && (
                    <Flex justifyContent="space-between" mb="24px">
                      <Field
                        label="Period start"
                        name={`${fieldPrefix}.periodStart`}
                        data-test-id={`${mainNameQA}--${fieldPrefix}.periodStart`}
                        component={DatePicker}
                        calendarColor="dark.solid"
                        minW="10px"
                        isErrorAbsolute
                        containerProps={{ w: '45%' }}
                        validate={required}
                        dayPickerProps={datePickerProps}
                        onAfterChange={d => {
                          const periodStart = moment.utc(d);
                          if (
                            periodStart.isAfter(
                              moment
                                .utc(get(values, `${fieldPrefix}.periodEnd`))
                                .endOf('day'),
                            )
                          ) {
                            form.change(`${fieldPrefix}.periodEnd`, null);
                          }
                        }}
                      />
                      <Box pr="24px" />
                      <Field
                        label="Period end"
                        name={`${fieldPrefix}.periodEnd`}
                        data-test-id={`${mainNameQA}--${fieldPrefix}.periodEnd`}
                        component={DatePicker}
                        calendarColor="dark.solid"
                        minW="10px"
                        isErrorAbsolute
                        validate={required}
                        containerProps={{ w: '45%' }}
                        dayPickerProps={periodEndPickerProps(
                          get(values, `${fieldPrefix}.periodStart`),
                        )}
                      />
                    </Flex>
                  )}
                  <Flex
                    justifyContent="space-between"
                    mt="24px"
                    mb="40px"
                    flexWrap="wrap"
                  >
                    <Field
                      name={`${fieldPrefix}.reference`}
                      data-test-id={`${mainNameQA}--${fieldPrefix}.reference`}
                      component={TextInput}
                      label={
                        <ReferenceLabel referenceChanged={referenceChanged} />
                      }
                      containerProps={{ w: '45%' }}
                      onAfterChange={() => {
                        form.change(`${fieldPrefix}.referenceChanged`, false);
                      }}
                    />
                    {!isFixedPayment && (
                      <Field
                        name={`${fieldPrefix}.purchaseOrder`}
                        data-test-id={`${mainNameQA}--${fieldPrefix}.purchaseOrder`}
                        component={TextInput}
                        label="Purchase order"
                        containerProps={{ w: '45%' }}
                      />
                    )}
                    <Field
                      name={`${fieldPrefix}.xeroId`}
                      data-test-id={`${mainNameQA}--${fieldPrefix}.xeroId`}
                      component={TextInput}
                      label={<XeroIdLabel syncEnabled={syncEnabled} />}
                      isDisabled={syncEnabled}
                      onAfterChange={xeroId => {
                        form.change(
                          `${fieldPrefix}.hasXeroIdChanged`,
                          xeroId !== initialValues.xeroId,
                        );
                      }}
                      containerProps={{
                        w: '45%',
                        mt: isFixedPayment ? '0px' : '24px',
                      }}
                    />
                  </Flex>
                </>
                <Stack mt="56px" spacing={3}>
                  <Button
                    isLoading={submitting}
                    type="submit"
                    data-test-id={`${mainNameQA}--updateButton`}
                  >
                    Update invoice
                  </Button>
                  <Button
                    variant="outline"
                    data-test-id={`${mainNameQA}--cancelButton`}
                    isDisabled={submitting}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Stack>
              </form>
            );
          }}
        />
      )}
    </Modal>
  );
};

export default EditInvoiceModal;
