/* eslint-disable max-len */
import { action, makeObservable, observable } from 'mobx';

export default class DocumentsStore {
  constructor({ API, toastsStore }) {
    makeObservable(this);
    this.API = API;
    this.toastsStore = toastsStore;
  }

  @observable documents = {
    isLoading: false,
    firstRequest: true,
    data: [],
  };

  @action fetchProjectDocuments = async projectId => {
    this.documents.isLoading = true;
    try {
      const { data } = await this.API.getProjectDocuments(projectId);
      this.documents.firstRequest = false;
      this.documents.data = data;
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      this.documents.isLoading = false;
    }
  };

  @action createProjectDocument = async (
    { projectId, ...values },
    successCB,
  ) => {
    try {
      const { data } = await this.API.createProjectDocument(projectId, values);
      this.documents.data = [data, ...this.documents.data];
      if (successCB) {
        successCB();
      }
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    }
  };

  @action clearDocuments = () => {
    this.documents = { data: [], isLoading: false, firstRequest: true };
  };
}
