import React from 'react';
import ExpandableCard from '@components/ExpandableCard';
import DeazyAllocationTable from '@components/DeazyAllocationTable';
import FormSpyFieldValues from '@components/forms/FormSpyFieldValues';
import { inject, observer } from 'mobx-react';
import { get, isEmpty, filter } from 'lodash-es';
import SubmitFailedError from './SubmitFailedError';
import {
  AllocationTableWrapper,
  StageCounterHandler,
} from './FixedPriceAllocations';

const FixedPriceDeazyAllocations = ({
  fieldName,
  projectsStore: { projectInitialValues },
  resourceAsText,
  mainNameQA,
}) => {
  const prepareFieldName = fName =>
    fieldName ? `${fieldName}.${fName}` : fName;

  const initialCountFilterFn = allocs =>
    filter(allocs, ({ id, startDate, endDate, ...a }) => !isEmpty(a));

  return (
    <FormSpyFieldValues fieldNames={['deazyAsClient']}>
      {({ deazyAsClient }) => {
        if (deazyAsClient) {
          return null;
        }
        return (
          <>
            <FormSpyFieldValues
              fieldNames={[prepareFieldName('deazyAllocations1')]}
            >
              {values => {
                const deazyAllocations = get(
                  values,
                  prepareFieldName('deazyAllocations1'),
                );
                const initialCount = initialCountFilterFn(deazyAllocations)
                  .length;
                return (
                  <ExpandableCard
                    title="Development Readiness"
                    initialCount={initialCount}
                  >
                    {({ onSetCounter }) => (
                      <>
                        <StageCounterHandler
                          onSetCounter={onSetCounter}
                          fieldNames={[prepareFieldName('deazyAllocations1')]}
                          mainNameQA={mainNameQA}
                        />
                        <AllocationTableWrapper>
                          <DeazyAllocationTable
                            resourceAsText={resourceAsText}
                            fieldName={prepareFieldName('deazyAllocations1')}
                            initialAllocations={
                              projectInitialValues[
                                prepareFieldName('deazyAllocations1')
                              ] || []
                            }
                            mainNameQA={mainNameQA}
                          />
                          <SubmitFailedError
                            fieldName={prepareFieldName('deazyAllocation1')}
                            mainNameQA={mainNameQA}
                          />
                        </AllocationTableWrapper>
                      </>
                    )}
                  </ExpandableCard>
                );
              }}
            </FormSpyFieldValues>
            <FormSpyFieldValues
              fieldNames={[prepareFieldName('deazyAllocations2')]}
            >
              {values => {
                const deazyAllocations = get(
                  values,
                  prepareFieldName('deazyAllocations2'),
                );
                const initialCount = initialCountFilterFn(deazyAllocations)
                  .length;
                return (
                  <ExpandableCard title="Delivery" initialCount={initialCount}>
                    {({ onSetCounter }) => (
                      <>
                        <StageCounterHandler
                          onSetCounter={onSetCounter}
                          fieldNames={[prepareFieldName('deazyAllocations2')]}
                          mainNameQA={mainNameQA}
                        />

                        <AllocationTableWrapper>
                          <DeazyAllocationTable
                            resourceAsText={resourceAsText}
                            fieldName={prepareFieldName('deazyAllocations2')}
                            initialAllocations={
                              projectInitialValues[
                                prepareFieldName('deazyAllocations2')
                              ] || []
                            }
                            mainNameQA={mainNameQA}
                          />
                          <SubmitFailedError
                            fieldName={prepareFieldName('deazyAllocation2')}
                            mainNameQA={mainNameQA}
                          />
                        </AllocationTableWrapper>
                      </>
                    )}
                  </ExpandableCard>
                );
              }}
            </FormSpyFieldValues>
            <FormSpyFieldValues
              fieldNames={[prepareFieldName('deazyAllocations3')]}
            >
              {values => {
                const deazyAllocations = get(
                  values,
                  prepareFieldName('deazyAllocations3'),
                );
                const initialCount = initialCountFilterFn(deazyAllocations)
                  .length;
                return (
                  <ExpandableCard
                    title="UAT & Deployment"
                    initialCount={initialCount}
                  >
                    {({ onSetCounter }) => (
                      <>
                        <StageCounterHandler
                          onSetCounter={onSetCounter}
                          fieldNames={[prepareFieldName('deazyAllocations3')]}
                          mainNameQA={mainNameQA}
                        />
                        <AllocationTableWrapper>
                          <DeazyAllocationTable
                            resourceAsText={resourceAsText}
                            fieldName={prepareFieldName('deazyAllocations3')}
                            initialAllocations={
                              projectInitialValues[
                                prepareFieldName('deazyAllocations3')
                              ] || []
                            }
                            mainNameQA={mainNameQA}
                          />
                          <SubmitFailedError
                            fieldName={prepareFieldName('deazyAllocation3')}
                            mainNameQA={mainNameQA}
                          />
                        </AllocationTableWrapper>
                      </>
                    )}
                  </ExpandableCard>
                );
              }}
            </FormSpyFieldValues>
          </>
        );
      }}
    </FormSpyFieldValues>
  );
};

export default inject('projectsStore')(observer(FixedPriceDeazyAllocations));
