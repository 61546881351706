/* eslint-disable no-underscore-dangle */
import { action, makeObservable, observable, computed } from 'mobx';
import moment from 'moment';
import {
  map,
  values as objValues,
  reject,
  find,
  forEach,
  isEmpty,
} from 'lodash-es';
import { ASSIGNMENT_STATUSES } from '@app/constants';
import { APP_ROUTES } from '@routes';

export default class BriefAssignmentsStore {
  constructor({ API, toastsStore, briefsStore, appStore }) {
    makeObservable(this);
    this.API = API;
    this.toastsStore = toastsStore;
    this.briefsStore = briefsStore;
    this.appStore = appStore;
  }

  @observable matchedDPs = {};

  @observable isLoadingMatchedDPs = false;

  @action updateMatchingQuestionnaire = async (payload, successCb) => {
    const { isPlugAndPlay, id: briefId } = this.briefsStore.brief;
    const key = isPlugAndPlay ? 'ppBriefQuestions' : 'briefQuestions';
    const briefQuestions = this.briefsStore.brief[key];
    try {
      const { data } = await this.API[
        !briefQuestions
          ? 'createMatchingQuestionnaire'
          : 'updateMatchingQuestionnaire'
      ](isPlugAndPlay)(briefId, { id: briefId, ...payload });
      this.briefsStore._brief[key] = data;
      if (successCb) successCb();
    } catch (e) {
      this.toastsStore.showError({ title: e?.message || 'Network error!' });
    }
  };

  @action fetchMatchedDPs = async params => {
    const {
      brief: { id: briefId, isPlugAndPlay },
    } = this.briefsStore;
    this.matchedDPs = {};
    try {
      this.isLoadingMatchedDPs = true;
      const { data } = await this.API.getMatchedDPs(isPlugAndPlay)(
        briefId,
        params,
      );
      this.matchedDPs = {
        best: data.bestMatches,
        new: data.newDPs,
        partial: data.partialMatches,
      };
    } catch (e) {
      this.toastsStore.showError({ title: e?.message || 'Network error!' });
    } finally {
      this.isLoadingMatchedDPs = false;
    }
  };

  @action assignSupplier = async ({
    btsId,
    exclusivityEnabled,
    message: note,
    exclusivityDuration: hours,
    closeModalCb,
  }) => {
    const { isPlugAndPlay, id: briefId } = this.briefsStore.brief;

    try {
      if (exclusivityEnabled) {
        const { data } = await this.API.exclusiveSupplier(isPlugAndPlay)(
          {
            briefId,
            btsId,
          },
          { hours, note },
        );

        this.overrideAssignments(
          data.briefToSuppliers || data.ppBriefToSuppliers,
        );
        this.setAssignmentProperty(btsId, { reminderAllowed: true });

        this.briefsStore._brief = {
          ...this.briefsStore._brief,
          ppBriefToSuppliers: data.ppBriefToSuppliers,
          briefToSuppliers: data.briefToSuppliers,
        };
      } else if (find(this.assignments.data, a => a.isExclusive)) {
        const { data } = await this.API.scheduleSupplier(isPlugAndPlay)(
          {
            briefId,
            btsId,
          },
          { note },
        );
        const supplier =
          find(this.assignments.data, a => a.id === btsId)?.supplier || {};
        this.toastsStore.showInfo({
          title: `${supplier.name ||
            'DP'} will be assigned when the first refusal expires.`,
        });
        this.overrideAssignments(
          data.briefToSuppliers || data.ppBriefToSuppliers,
        );
      } else {
        const { data } = await this.API.assignSupplier(isPlugAndPlay)(
          {
            briefId,
            btsId,
          },
          { note },
        );
        this.overrideAssignments(
          data.briefToSuppliers || data.ppBriefToSuppliers,
        );
        this.setAssignmentProperty(btsId, { reminderAllowed: true });

        this.briefsStore._brief = {
          ...this.briefsStore._brief,
          ppBriefToSuppliers: data.ppBriefToSuppliers,
          briefToSuppliers: data.briefToSuppliers,
        };
      }
    } catch (e) {
      this.toastsStore.showError({ title: e?.message || 'Network error!' });
    } finally {
      if (closeModalCb) {
        closeModalCb();
      }
    }
  };

  @action assignAllSuppliers = async ({ briefId, values, successCb }) => {
    try {
      this.briefsStore._brief.isAssigningAllSuppliers = true;
      const { data } = await this.API.assignAllSuppliersToBrief(
        !!this.briefsStore.brief.newOrExisting,
      )(briefId, { note: values });

      this.briefsStore._brief = {
        ...this.briefsStore._brief,
        ppBriefToSuppliers: data.ppBriefToSuppliers,
        briefToSuppliers: data.briefToSuppliers,
      };
      this.overrideAssignments(
        data.briefToSuppliers || data.ppBriefToSuppliers,
      );
      this.assignments.data = map(this.assignments.data, bToS => ({
        ...bToS,
        reminderAllowed: true,
      }));

      const {
        featureFlags: { 'brief-exclusive-assigment': briefExclusiveAssigment },
      } = this.appStore;
      if (
        briefExclusiveAssigment &&
        !!find(this.assignments.data, { isExclusive: true })
      ) {
        this.toastsStore.showInfo({
          title:
            'Delivery partners will be assigned when the first refusal expires.',
        });
      }

      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      this.briefsStore._brief.isAssigningAllSuppliers = false;
    }
  };

  @action unassignSupplier = async (supplierId, failureCb) => {
    const key = this.briefsStore.brief.isPlugAndPlay
      ? 'ppBriefToSuppliers'
      : 'briefToSuppliers';
    try {
      this.assignments.data = map(this.assignments.data, assignment => ({
        ...assignment,
        isDeleting:
          assignment?.supplier?.id === supplierId
            ? true
            : assignment?.isDeleting,
      }));

      await this.API.unassignSupplierFromBrief(
        !!this.briefsStore.brief.newOrExisting,
      )(this.briefsStore.brief.id, supplierId);
      this.briefsStore._brief[key] = reject(
        this.briefsStore._brief[key],
        p => p?.supplier.id === supplierId,
      );
      this.assignments.data = reject(
        this.assignments.data,
        p => p?.supplier.id === supplierId,
      );
    } catch (e) {
      this.toastsStore.showError({
        title:
          e?.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
      if (failureCb) {
        failureCb();
      }
    } finally {
      this.assignments.data = map(this.assignments.data, assignment => ({
        ...assignment,
        isDeleting:
          assignment?.supplier?.id === supplierId
            ? false
            : assignment.isDeleting,
      }));
    }
  };

  @observable recommendedSuppliers = {
    data: [],
    firstRequest: true,
    isLoading: false,
  };

  @action clearRecommendedSuppliers = () => {
    this.recommendedSuppliers = {
      data: [],
      firstRequest: true,
      isLoading: false,
    };
  };

  @action getRecommendedSuppliers = async (briefId, filters) => {
    this.recommendedSuppliers = {
      ...this.recommendedSuppliers,
      data: [],
      isLoading: true,
    };
    try {
      const { data } = await this.API.getRecomendedSuppliersForBrief(
        !!this.briefsStore.brief.newOrExisting,
      )(briefId, {
        ...filters,
        countries: map(filters.countries, c => c.value),
      });
      this.briefsStore._brief = {
        ...this.briefsStore._brief,
        ...filters,
        countries: map(filters.countries, c => c.value),
      };
      this.recommendedSuppliers.data = data;
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      this.recommendedSuppliers = {
        ...this.recommendedSuppliers,
        firstRequest: false,
        isLoading: false,
      };
    }
  };

  @computed get decoratedRecommendedSuppliers() {
    const key = this.briefsStore.brief.isPlugAndPlay
      ? 'ppBriefToSuppliers'
      : 'briefToSuppliers';
    return map(this.recommendedSuppliers.data, supplier => ({
      ...supplier,
      ...(find(
        this.briefsStore._brief[key],
        proposal => proposal?.supplier?.id === supplier.id,
      ) && { isAssigned: true }),
    }));
  }

  @observable reminders = {};

  @action remindSuppliers = async (supplier = {}, successCb) => {
    this.briefsStore._brief.remindingSupplier = supplier;
    try {
      await this.API.remindSupplierAssignment(
        this.briefsStore.brief.isPlugAndPlay,
      )(this.briefsStore.brief.id, supplier.id);
      const forWhom = supplier.id
        ? `the ‘${supplier.name}’.`
        : 'all suppliers.';
      this.toastsStore.showSuccess({
        title: `The reminder was successfully sent for ${forWhom}`,
      });

      const setReminderDate = supplierId => {
        const lastReminderDate = moment.utc().format();
        if (supplierId) {
          this.assignments.data = map(this.assignments.data, assignment => ({
            ...assignment,
            ...(assignment?.supplier?.id === supplierId && {
              lastReminderDate,
            }),
          }));
        } else {
          this.assignments.data = map(this.assignments.data, assignment => ({
            ...assignment,
            ...(assignment?.reminderAllowed && {
              lastReminderDate,
            }),
          }));
        }
      };
      if (supplier.id) {
        this.reminders[supplier.id] = true;
        setReminderDate(supplier.id);
        if (
          objValues(this.reminders).length ===
          (
            this.briefsStore._brief.briefToSuppliers ||
            this.briefsStore.brief.ppBriefToSuppliers ||
            []
          ).length
        ) {
          this.reminders.all = true;
          setReminderDate();
        }
      } else {
        forEach(this.assignments.data, a => {
          this.reminders[a.supplier.id] = true;
        });
        setReminderDate();
      }
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e?.message || 'Network error. Please, try again later.',
      });
    } finally {
      delete this.briefsStore._brief.remindingSupplier;
    }
  };

  @observable assignments = {
    data: [],
    isLoading: false,
    isFetched: false,
  };

  @action clearAssignmentList = () => {
    this.assignments = {
      data: [],
      isLoading: false,
      isFetched: false,
    };
  };

  @action setAssignmentProperty = (assignmentId, obj) => {
    this.assignments.data = map(this.assignments.data, a => ({
      ...a,
      ...(assignmentId === a.id && obj),
    }));
  };

  @computed get decoratedAssignments() {
    const exclusiveAssignment = find(this.assignments.data, a => a.isExclusive);
    const { isPlugAndPlay, id: briefId } = this.briefsStore.brief;
    return map(this.assignments.data, a => ({
      ...a,
      assignedAt: a.assignedOn,
      ...(a.status === ASSIGNMENT_STATUSES.SCHEDULED &&
        exclusiveAssignment && {
          dueDate: exclusiveAssignment.exclusiveTo,
        }),
      ...(a.status === 'Pending' &&
        a.isExclusive && { status: ASSIGNMENT_STATUSES.EXCLUSIVE }),
      ...(a.status === 'Pending' &&
        a.isExclusive &&
        moment.utc(a.exclusiveTo).isBefore(moment.utc()) && {
          status: ASSIGNMENT_STATUSES.AWAITING_RESPONSE,
        }),
      ...(a.status === 'Pending' &&
        !a.isExclusive && { status: ASSIGNMENT_STATUSES.AWAITING_RESPONSE }),
      ...(a.status === 'Shortlisted' && {
        status: ASSIGNMENT_STATUSES.ASSIGNMENT_PENDING,
      }),
      ...(a.status === 'Accepted' && {
        status: ASSIGNMENT_STATUSES.SHOWN_INTEREST,
      }),
      ...(a.status === 'Declined' && {
        status: ASSIGNMENT_STATUSES.NOT_INTERESTED,
      }),
      ...((a?.proposal?.id || !isEmpty(a?.proposals)) &&
        isPlugAndPlay && {
          status: ASSIGNMENT_STATUSES.CANDIDATES_SHARED,
          proposalLink: APP_ROUTES.briefPlugAndPlayTab(briefId, 'candidates'),
        }),
      ...(a?.proposal?.submitted &&
        !isPlugAndPlay && {
          status: ASSIGNMENT_STATUSES.PROPOSAL_SHARED,
          proposalLink: APP_ROUTES.briefTab(
            briefId,
            `proposals/${a?.proposal?.id}/delivery-partner`,
          ),
        }),
    }));
  }

  @action fetchAssignmentList = async () => {
    this.assignments.isLoading = true;
    try {
      const { isPlugAndPlay, id: briefId } = this.briefsStore.brief;
      const { data } = await this.API.getAssignmentList(isPlugAndPlay)(briefId);
      this.assignments.data = data;
      if (
        find(
          data,
          a =>
            a.isExclusive &&
            a.exclusiveTo &&
            moment.utc(a.exclusiveTo).isBefore(moment.utc()),
        )
      ) {
        await this.handleExclusiveAssignmentEnd();
      }
    } catch (e) {
      this.assignments.error = true;
      this.toastsStore.showError({
        title:
          e?.message ||
          'Ooops! Something unexpected happened. Could not fetch DP assignments. Please try again later.',
      });
    } finally {
      this.assignments = {
        ...this.assignments,
        isLoading: false,
        isFetched: true,
      };
    }
  };

  @action overrideAssignments = briefToSuppliers => {
    // just in case response doesn't include proposal object
    const filteredBts = reject(briefToSuppliers, bts =>
      find(this.assignments.data, a => a.proposal?.id && bts.id === a.id),
    );
    this.assignments.data = [
      ...filteredBts,
      ...reject(this.assignments.data, a => find(filteredBts, { id: a.id })),
    ];
  };

  shortlistSingleSupplier = async (supplierId, failureCb) => {
    const { isPlugAndPlay, id: briefId } = this.briefsStore.brief;
    try {
      const { data } = await this.API.shortlistSuppliers(isPlugAndPlay)(
        briefId,
        {
          supplierIds: [supplierId],
        },
      );
      this.briefsStore._brief = {
        ...this.briefsStore._brief,
        ppBriefToSuppliers: data.ppBriefToSuppliers,
        briefToSuppliers: data.briefToSuppliers,
      };
      this.overrideAssignments(
        data.briefToSuppliers || data.ppBriefToSuppliers,
      );
    } catch (e) {
      this.toastsStore.showError({
        title:
          e?.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
      if (failureCb) {
        failureCb();
      }
    }
  };

  shortlistSuppliers = async ({ suppliers }, formApi) => {
    const { isPlugAndPlay, id: briefId } = this.briefsStore.brief;
    try {
      const supplierIds = map(suppliers, s => s?.supplier?.id);
      const { data } = await this.API.shortlistSuppliers(isPlugAndPlay)(
        briefId,
        {
          supplierIds,
        },
      );
      this.briefsStore._brief = {
        ...this.briefsStore._brief,
        ppBriefToSuppliers: data.ppBriefToSuppliers,
        briefToSuppliers: data.briefToSuppliers,
      };
      this.overrideAssignments(
        data.briefToSuppliers || data.ppBriefToSuppliers,
      );
      setTimeout(formApi.restart);
    } catch (e) {
      this.toastsStore.showError({
        title:
          e?.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
      setTimeout(formApi.restart);
    }
  };

  @action handleExclusiveAssignmentEnd = async id => {
    this.assignments.data = map(this.assignments.data, a => ({
      ...a,
      ...(a.id === id && { isExclusive: false }),
      ...(a.status === ASSIGNMENT_STATUSES.SCHEDULED && {
        status: 'Pending',
        assignedOn: moment.utc().format(),
      }),
    }));
    const { isPlugAndPlay, id: briefId } = this.briefsStore.brief;
    try {
      await this.API.assignmentJobTrigger(isPlugAndPlay)(briefId);
    } catch {
      //
    }
  };
}
