import React from 'react';
import Icon from '@components/Icon';
import Tooltip from '@styles/Tooltip';
import FormSpyFieldValues from '@components/forms/FormSpyFieldValues';
import { isAdvancedFrameworkCheck } from '@utils/proposalUtils';
import { Box } from '@chakra-ui/react';
import { get, isNil, toNumber } from 'lodash-es';
import { inject, observer } from 'mobx-react';

const ResourceRateIndicator = ({
  ratesStore: {
    getResourceRate,
    resourceRates: { isFetched },
  },
  formApi,
  prefix,
  isPnP,
  isDeazyView,
  briefsStore: { brief },
  ...props
}) => {
  const roleNameField = isPnP ? `${prefix}.role` : `${prefix}.name`;
  if (!isFetched) {
    return null;
  }
  return (
    <FormSpyFieldValues
      fieldNames={[
        `${prefix}.rate`,
        `${prefix}.clientRate`,
        `${prefix}.seniority`,
        roleNameField,
        `${prefix}.briefResource.technologies`,
      ]}
    >
      {values => {
        const technologies = get(
          values,
          `${prefix}.briefResource.technologies`,
        );
        const rate = get(values, `${prefix}.rate`);
        const clientRate = get(values, `${prefix}.clientRate`);
        const seniority = get(values, `${prefix}.seniority`);
        const name = get(values, roleNameField);

        const isAdvancedFramework = React.useMemo(() => {
          if (isPnP) {
            return isAdvancedFrameworkCheck(technologies);
          }
          return isAdvancedFrameworkCheck(brief.technologies);
        }, [brief.id, technologies]);

        const {
          rate: targetRate,
          highRate: targetHighRate,
          clientHighRate,
          clientLowRate,
        } = React.useMemo(
          () =>
            getResourceRate(
              name,
              seniority,
              isPnP,
              isAdvancedFramework,
              brief?.customer?.type,
            ),
          [brief?.customer?.type, name, seniority, isPnP, isAdvancedFramework],
        );

        const [isRateOk, setIsRateOk] = React.useState(true);

        const prevSeniority = React.useRef(seniority);
        const prevName = React.useRef(name);
        const prevPrefix = React.useRef(prefix);

        React.useEffect(() => {
          setTimeout(() => {
            prevPrefix.current = prefix;
          }, 250);
        }, [prefix]);

        React.useEffect(() => {
          if (
            seniority &&
            prevPrefix.current === prefix &&
            (prevSeniority.current !== seniority ||
              prevName.current !== name) &&
            name &&
            targetRate >= 0
          ) {
            formApi.change(`${prefix}.rate`, targetRate);
            setIsRateOk(true);
            prevSeniority.current = seniority;
            prevName.current = name;
          }
        }, [seniority, name, targetRate]);

        React.useEffect(() => {
          const isOk =
            toNumber(rate) <= toNumber(targetRate) ||
            toNumber(rate) <= toNumber(targetHighRate) ||
            isNil(targetRate);
          setIsRateOk(isOk);
        }, [rate, targetRate]);

        React.useEffect(() => {
          if (
            isRateOk !== values[`${prefix}.isRateOk`] &&
            (values[`${prefix}.rate`] >= 0 ||
              isNil(values[`${prefix}.rate`])) &&
            values[`${prefix}.seniority`]
          ) {
            formApi.batch(() => {
              formApi.change(`${prefix}.isRateOk`, isRateOk);
              if (isRateOk) {
                formApi.change(`${prefix}.comment`, null);
              }
            });
          }
        }, [isRateOk]);

        const warningMessage = React.useMemo(() => {
          if (isDeazyView) {
            if (clientRate > clientHighRate || clientRate < clientLowRate) {
              return 'This rate causes the client rate to fall outside the expected range';
            }
            if (rate > targetRate && rate < targetHighRate) {
              return 'This has been changed from the DP’s target rate';
            }
            if (rate > targetRate) {
              return 'This rate is outside the range agreed with the DP';
            }
          }
          if (isDeazyView && isNil(targetRate)) {
            return 'This rate could not be validated due to unavailable data';
          }
          if (!isRateOk && !isDeazyView) {
            return 'This is above your agreed maximum rate';
          }
          return null;
        }, [
          isRateOk,
          isDeazyView,
          targetRate,
          clientRate,
          clientHighRate,
          clientLowRate,
        ]);

        const iconProps = {
          w: '20px',
          h: '20px',
          color: isRateOk && !warningMessage ? 'green.solid' : 'orange.solid',
          name: isRateOk && !warningMessage ? 'tick' : 'warning',
        };

        if (!rate || !seniority || !name) {
          return null;
        }

        if (warningMessage) {
          return (
            <Tooltip label={warningMessage} maxW="172px" textAlign="center">
              <Box as="span" _hover={{ opacity: 0.5 }} {...props}>
                <Icon {...iconProps} />
              </Box>
            </Tooltip>
          );
        }
        return <Icon {...iconProps} {...props} />;
      }}
    </FormSpyFieldValues>
  );
};

export default inject(
  'ratesStore',
  'briefsStore',
)(observer(ResourceRateIndicator));
