import { inject, observer } from 'mobx-react';
import React from 'react';
import { isEmpty, toNumber } from 'lodash-es';
import { StickyFooter } from '@components/StickyFooterCostBox';
import { PROJECT_TYPES } from '@app/constants';
import { Flex } from '@chakra-ui/react';
import Button from '@styles/Button';
import ProjectUnviableModal from './ProjectUnviableModal';

const SubmitSection = inject('projectsStore')(
  observer(
    ({
      handleSubmit,
      submitting,
      values,
      errors,
      projectsStore: {
        projectTotals: {
          isLoading: isLoadingTotals,
          data: { supplierTotal, clientTotal },
        },
      },
    }) => {
      const [isOpen, setIsOpen] = React.useState(false);
      const onCreateProject = React.useCallback(() => {
        const isFixedPrice = values.projectType === PROJECT_TYPES.FIXED_PRICE;
        if (
          !!supplierTotal &&
          !!clientTotal &&
          clientTotal <=
            supplierTotal * toNumber(values.supplierCurrencyRate || 1) &&
          isFixedPrice &&
          isEmpty(errors)
        ) {
          setIsOpen(true);
          return;
        }
        handleSubmit();
      }, [
        values.projectType,
        clientTotal,
        supplierTotal,
        JSON.stringify(errors),
      ]);

      return (
        <>
          <StickyFooter display="flex" h="80px" justifyContent="center">
            <Flex maxW="1460px" w="100%" justifyContent="flex-end">
              <Button
                size="lg"
                isLoading={submitting}
                isDisabled={isLoadingTotals}
                onClick={onCreateProject}
              >
                Create project
              </Button>
            </Flex>
          </StickyFooter>
          <ProjectUnviableModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onCreate={handleSubmit}
            isLoading={submitting}
          />
        </>
      );
    },
  ),
);

export default SubmitSection;
