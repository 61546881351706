import { API } from '@app/api';
import { CURRENCY_OPTIONS, PROJECT_TYPES } from '@app/constants';
import { Box, Stack, Tooltip } from '@chakra-ui/react';
import AsyncAutocomplete from '@components/forms/_common/AsyncAutocomplete';
import Select from '@components/forms/_common/Select';
import TextInput from '@components/forms/_common/TextInput';
import Icon from '@components/Icon';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field } from 'react-final-form';

const PROJECT_TYPE_OPTIONS = [
  { value: PROJECT_TYPES.FIXED_PRICE, label: PROJECT_TYPES.FIXED_PRICE },
  { value: PROJECT_TYPES.T_AND_M, label: PROJECT_TYPES.T_AND_M },
];

export const ProjectGroupTagTooltip = () => (
  <Tooltip
    zIndex={33000}
    p="12px"
    borderRadius="8px"
    label="This is a tag that links together different phases of projects for reporting.
  If this is a brand new project, create a new tag. Otherwise, start typing to find an existing tag"
    hasArrow
    bg="dark.solid"
    px="24px"
    py="12px"
    placement="top"
  >
    <Box as="span" ml="10px">
      <Icon name="info" mt="-4px" color="#878380" />
    </Box>
  </Tooltip>
);

const ProjectFinances = ({
  mainNameQA,
  appStore: {
    featureFlags: { 'removal-of-billing-type': removalOfBillingType },
  },
}) => {
  return (
    <WhiteCard p="40px" boxShadow="none">
      <Typography variant="h3" mb="24px">
        Project finances
      </Typography>
      <Stack isInline spacing="40px">
        {!removalOfBillingType && (
          <Box w="25%">
            <Field
              component={Select}
              name="projectType"
              data-test-id={`${mainNameQA}--projectType`}
              label="Billing model"
              options={PROJECT_TYPE_OPTIONS}
            />
          </Box>
        )}
        <Box w="25%">
          <Field
            name="xeroProjectCategory"
            data-test-id={`${mainNameQA}--xeroProjectCategory`}
            label={
              <>
                Project group tag
                <ProjectGroupTagTooltip />
              </>
            }
            placeholder="Enter project group tag"
            component={AsyncAutocomplete}
            getOptionsFn={API.trackingCategories}
            isCreatable
            isClearable
            triggerWhenMoreThan={2}
            customOptionMap={option => ({
              value: option,
              label: option,
            })}
          />
        </Box>
        <Box w="25%" marginRight="40px">
          <Field
            component={TextInput}
            data-test-id={`${mainNameQA}--purchaseOrder`}
            name="purchaseOrder"
            label="Purchase Order"
            placeholder="Enter a PO"
          />
        </Box>
        <Box w="25%">
          <Field
            component={Select}
            options={CURRENCY_OPTIONS}
            name="currency"
            data-test-id={`${mainNameQA}--currency`}
            label="Project currency"
            placeholder="Select currency"
          />
        </Box>
      </Stack>
    </WhiteCard>
  );
};

export default inject('appStore')(observer(ProjectFinances));
