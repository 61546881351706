/* eslint-disable */
import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Field } from 'react-final-form';
import { API } from '@app/api';
import TextInput from '@components/forms/_common/TextInput';
import WhiteCard from '@components/WhiteCard';
import { includes, isEmpty, lowerCase } from 'lodash-es';
import Typography from '@styles/Typography';
import FormSpyFieldValues from '@components/forms/FormSpyFieldValues';
import Select from '@components/forms/_common/Select';
import ClientLeadCreateModal from '@pages/UserCreate/ClientLeadCreateModal';
import AsyncAutocomplete from '@components/forms/_common/AsyncAutocomplete';
import Checkbox from '@components/forms/_common/Checkbox';
import { sleep } from '@utils';
import {
  ENGAGEMENT_TYPES,
  NEW_ROLES,
  DEAZY_HELPER_OPTION,
} from '@app/constants';
import { nanoid } from 'nanoid';
import { isFixedPriceProjectType } from '@utils/projectUtils';
import { inject, observer } from 'mobx-react';
import {
  composeValidators,
  mustBeShorterOrEqual,
  required,
  validateProjectName,
} from '@utils/formValidators';
import ProjectNameInput from '../BriefsCreate/BasicsStep/__LegacyProjectNameInput';

const ENGAGEMENT_TYPE_OPTIONS = [
  {
    value: ENGAGEMENT_TYPES.PROJECT_BUILD,
    label: ENGAGEMENT_TYPES.PROJECT_BUILD,
  },
  {
    value: ENGAGEMENT_TYPES.TEAM_AUGMENTATION,
    label: ENGAGEMENT_TYPES.TEAM_AUGMENTATION,
  },
  { value: ENGAGEMENT_TYPES.SUPPORT, label: ENGAGEMENT_TYPES.SUPPORT },
  {
    value: ENGAGEMENT_TYPES.RETAINED_TEAM,
    label: ENGAGEMENT_TYPES.RETAINED_TEAM,
  },
];

const ProjectBasicInfo = ({
  formApi,
  mainNameQA,
  briefsStore: { isCreatingProjectFromBrief },
  usersStore: { isAdminOrDL, createUserByRole },
  settingsStore: { settings },
  toastsStore,
}) => {
  const [clientLeadIntialValues, setClientLeadInitialValues] = React.useState(
    {},
  );
  const [createdClientLead, setCreatedClientLead] = React.useState();

  React.useEffect(() => {
    if (createdClientLead) {
      formApi.change('clientLead', createdClientLead);
    }
  }, [createdClientLead]);

  const {
    onCreateClientLeadModalOpen,
    onCreateClientLeadModalClose,
    isCreateClientLeadModalOpen,
  } = React.useMemo(() => {
    return {
      isCreateClientLeadModalOpen: !isEmpty(clientLeadIntialValues),
      onCreateClientLeadModalOpen: setClientLeadInitialValues,
      onCreateClientLeadModalClose: () => setClientLeadInitialValues({}),
    };
  }, [JSON.stringify(clientLeadIntialValues)]);

  return (
    <WhiteCard p="40px" boxShadow="none">
      <Typography variant="h3" mb="24px">
        General info
      </Typography>
      <Flex mb="24px">
        <FormSpyFieldValues fieldNames={['deazyAsSupplier']}>
          {({ deazyAsSupplier }) => {
            return (
              <Field
                name="client"
                component={AsyncAutocomplete}
                getOptionsFn={API.searchClients}
                label="Choose a client"
                placeholder="Choose a client"
                data-test-id={`${mainNameQA}--client`}
                key={`key=deazyAsSupplier-${deazyAsSupplier}`}
                // defaultOptions={fetchedOptions => {
                //   // if (deazyAsSupplier) {
                //     return fetchedOptions;
                //   // }
                //   // return [
                //   //   ...(includes('deazy', lowerCase(phrase))
                //   //     ? [DEAZY_HELPER_OPTION]
                //   //     : []),
                //   //   ...(fetchedOptions || []),
                //   // ];
                // }}
                isDisabled={isCreatingProjectFromBrief}
                onAfterChange={async opt => {
                  const { value: projectName } = formApi.getFieldState('name');
                  formApi.change('deazyAsClient', opt?.id === 'deazy');
                  if (projectName) {
                    formApi.change('name', `${projectName} `);
                    await sleep(50);
                    formApi.change('name', projectName);
                  }
                }}
                styleProps={{ pr: '24px', maxW: '33.3%' }}
              />
            );
          }}
        </FormSpyFieldValues>
        <FormSpyFieldValues fieldNames={['client']}>
          {({ client }) => {
            const clientId = client?.id;
            const isDeazy = clientId === 'deazy';
            if (isDeazy) {
              return null;
            }
            return (
              <>
                <Field
                  name="clientLead"
                  label="Client lead"
                  data-test-id={`${mainNameQA}--clientLead`}
                  placeholder="Client lead"
                  component={AsyncAutocomplete}
                  isCreatable={isAdminOrDL}
                  isValidNewOption={() => true}
                  onCreateOption={v => {
                    onCreateClientLeadModalOpen({
                      fullname: v,
                      client: client?.id,
                    });
                  }}
                  reloadOnEmpty
                  getOptionsFn={API.searchClientContactUsers(client?.id)}
                  customOptionMap={option => ({
                    label: option.fullname || option.username,
                    value: option,
                  })}
                  styleProps={{ pr: '24px', maxW: '33.3%' }}
                  isDisabled={isEmpty(client)}
                />
                <Field
                  component={TextInput}
                  isDisabled={isEmpty(client)}
                  name="clientFinanceEmail"
                  data-test-id={`${mainNameQA}--clientFinanceEmail`}
                  label="Client finance email"
                  placeholder="Email"
                />
              </>
            );
          }}
        </FormSpyFieldValues>
      </Flex>
      <FormSpyFieldValues fieldNames={['client', 'isZeroMargin']}>
        {({ client }) => (
          <Flex>
            <Field
              name="name"
              data-test-id={`${mainNameQA}--name`}
              placeholder="Add a project name here"
              label="What’s the project name?"
              component={ProjectNameInput}
              validate={composeValidators(
                required,
                mustBeShorterOrEqual(200),
                validateProjectName,
              )}
              isDisabled={isEmpty(client)}
              fullWidth
            />
            <Field
              component={Checkbox}
              name="isZeroMargin"
              data-test-id={`${mainNameQA}--isZeroMargin`}
              label="Deazy for good?"
              id="isZeroMargin"
              containerStyles={{
                mb: '0px',
                ml: '20px',
                minWidth: '150px',
                display: 'flex',
                alignItems: 'center',
              }}
            />
          </Flex>
        )}
      </FormSpyFieldValues>
      <Flex>
        <Field
          styleProps={{ mr: '12px' }}
          component={Select}
          name="engagementType"
          data-test-id={`${mainNameQA}--engagementType`}
          label="Engagement type"
          placeholder="Select engagement type"
          options={ENGAGEMENT_TYPE_OPTIONS}
          onAfterChange={engagementType => {
            formApi.change(
              'totalBasis',
              engagementType === ENGAGEMENT_TYPES.PROJECT_BUILD,
            );
          }}
        />
        <FormSpyFieldValues
          fieldNames={[
            'deazyAsClient',
            'projectType',
            'engagementType',
            'startDate',
            'endDate',
          ]}
        >
          {({ deazyAsClient, ...p }) => {
            const onPmChange = pm => {
              if (!deazyAsClient && !isFixedPriceProjectType(p)) {
                formApi.change('deazyAllocations', [
                  {
                    resourceName: pm.fullname,
                    amount: settings.defaultPmAmount,
                    rate: pm.rate,
                    startDate: p.startDate,
                    endDate: p.endDate,
                    id: nanoid(10),
                  },
                ]);
              }
            };
            return (
              <>
                <Field
                  component={AsyncAutocomplete}
                  name="pm"
                  data-test-id={`${mainNameQA}--pm`}
                  label="Deazy Lead"
                  getOptionsFn={API.searchPMs}
                  customOptionMap={option => ({
                    value: option,
                    label: `${option.fullname} <${option.email}>`,
                  })}
                  placeholder="Add a deazy lead"
                  onAfterChange={onPmChange}
                  styleProps={{ ml: '12px' }}
                />
                {/* {!deazyAsClient && (
                  <Field
                    component={Checkbox}
                    name="deazyAsSupplier"
                    data-test-id={`${mainNameQA}--deazyAsSupplier`}
                    label="Deazy as DP"
                    id="deazyAsSupplier"
                    containerStyles={{
                      mb: '0px',
                      mt: '30px',
                      ml: '20px',
                      minWidth: '150px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  />
                )} */}
              </>
            );
          }}
        </FormSpyFieldValues>
      </Flex>
      <ClientLeadCreateModal
        isOpen={isCreateClientLeadModalOpen}
        initialValues={clientLeadIntialValues}
        onClose={onCreateClientLeadModalClose}
        onSubmit={clientLeadValues =>
          createUserByRole({
            role: NEW_ROLES.CLIENT_LEAD,
            values: { ...clientLeadValues, username: clientLeadValues.email },
            onSuccess: resp => {
              setCreatedClientLead(resp);
              onCreateClientLeadModalClose();
            },
            onFailure: e => {
              toastsStore.showError({
                title:
                  e.message || 'Something went wrong. Please, try again later.',
              });
            },
          })
        }
      />
    </WhiteCard>
  );
};

export default inject(
  'briefsStore',
  'usersStore',
  'settingsStore',
  'toastsStore',
)(observer(ProjectBasicInfo));
