import { Alert, CloseButton, Link, useDisclosure } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Icon from './Icon';

const InfoAlert = ({ link, linkLabel, label, withCloseButton, ...props }) => {
  const { isOpen: isVisible, onClose } = useDisclosure({
    defaultIsOpen: true,
  });
  return isVisible ? (
    <Alert
      status="info"
      fontFamily="WB"
      borderRadius="8px"
      w="fit-content"
      p="18px"
      bg="primary.100"
      mb="40px"
      {...props}
    >
      <Icon name="infoFilled" color="blue.solid" mr="14px" fontSize={22} />
      <Typography>{label}</Typography>
      {link && (
        <Link
          ml="32px"
          fontFamily="WS"
          fontWeight="400"
          color="primary.400"
          as={RouterLink}
          to={link}
        >
          {linkLabel}
        </Link>
      )}
      {withCloseButton && (
        <CloseButton
          alignSelf="flex-start"
          fontSize={16}
          onClick={onClose}
          ml="8px"
        />
      )}
    </Alert>
  ) : null;
};

export default InfoAlert;
