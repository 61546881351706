import React from 'react';
import { Field } from 'react-final-form';
import { Box, Flex } from '@chakra-ui/react';
import TextInput from '@components/forms/TextInput';
import ActionButton from '@components/AllocationTable/ActionButton';
import { INPUT_VARIANTS } from '@styles/theme';
import DatePicker from '../../../components/forms/DatePicker';
import { MILESTONE_STAGE } from '../../../app/constants';

const MilestoneRow = ({
  idx,
  onAddRow,
  isAdminOrDL,
  isTeamAdminSideUser,
  isPrimaryDP,
  isClient,
  onDeleteRow,
  canBeDeleted,
  onChangeRow,
  milestoneRowId,
  stage,
  reportStatus,
  mainNameQA,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  let stageIdentifier = '';
  if (stage === MILESTONE_STAGE.UAT) {
    stageIdentifier = 'uat';
  } else if (stage === MILESTONE_STAGE.DELIVERY) {
    stageIdentifier = 'delivery';
  } else if (stage === MILESTONE_STAGE.UPFRONT) {
    stageIdentifier = 'upfront';
  }

  const permissionsToEdit = status => {
    if (isTeamAdminSideUser && !isPrimaryDP) {
      return true;
    }
    if (status === 'Accepted' && (isAdminOrDL || isTeamAdminSideUser)) {
      return true;
    }
    if (status === 'Submitted' && isClient) {
      return true;
    }
    return false;
  };

  return (
    <Box w="100%" position="relative">
      <ActionButton
        deleteRow={() => onDeleteRow(milestoneRowId)}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        canBeDeleted={canBeDeleted}
        addRow={onAddRow}
        disallowRowChange={permissionsToEdit(reportStatus)}
      />
      <Flex
        borderRadius="8px"
        w="100%"
        h="72px"
        mb="12px"
        bg="#fff"
        opacity={isOpen ? 0.5 : 1}
      >
        <Flex
          pl="40px"
          w="100%"
          border="1px solid"
          borderColor="dark.mid"
          borderRight="none"
          borderTopLeftRadius="8px"
          borderBottomLeftRadius="8px"
        >
          <Box
            alignSelf="center"
            w="100%"
            h="70px"
            borderRight="1px solid"
            borderColor="dark.mid"
          >
            <Field
              id={`${stageIdentifier}Milestones[${idx}].name`}
              name={`milestones[${idx}].name`}
              data-test-id={`${mainNameQA}--milestones[${idx}].name`}
              disabled={permissionsToEdit(reportStatus)}
              variant={INPUT_VARIANTS.CONTAINER_FILLED}
              placeholder="Give this milestone a name"
              render={props => (
                <TextInput
                  {...props}
                  input={{
                    ...props.input,
                    onChange: val => {
                      props.input.onChange(val);
                      const inputName = props.input.name?.split('.')[1];
                      onChangeRow(val, inputName, milestoneRowId);
                    },
                  }}
                />
              )}
            />
          </Box>
        </Flex>
        <Flex
          border="1px solid"
          borderColor="dark.mid"
          borderLeft="none"
          borderTopRightRadius="8px"
          borderBottomRightRadius="8px"
        >
          <Field
            id={`${stageIdentifier}Milestones[${idx}].baselineDate`}
            name={`milestones[${idx}].baselineDate`}
            data-test-id={`${mainNameQA}--milestones[${idx}].baselineDate`}
            calendarColor="dark.solid"
            borderTopRightRadius="8px"
            borderBottomRightRadius="8px"
            h="70px"
            variant={INPUT_VARIANTS.CONTAINER_FILLED}
            calendarProps={{ top: '13px' }}
            placeholder="Select a date"
            render={props => (
              <DatePicker
                {...props}
                input={{
                  ...props.input,
                  onChange: val => {
                    props.input.onChange(val);
                    const inputName = props.input.name?.split('.')[1];
                    onChangeRow(val, inputName, milestoneRowId);
                  },
                }}
                isDisabled={isOpen || permissionsToEdit(reportStatus)}
              />
            )}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default MilestoneRow;
