/* eslint-disable */
import React from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Button from '@styles/Button';
import useDownload from '@hooks/useDownload';
import { APP_ROUTES } from '@routes';
import useMediaQuery from '@hooks/useMediaQuery';
import { IS_DEV, IS_STAGING } from '@app/constants';
import { StickyFooter } from '@components/StickyFooterCostBox';
import Menu from '@components/Menu';
import DeleteModal from './DeleteModal';

const { REACT_APP_API_URL: API_URL } = process.env;

const ProjectSettingsStickyFooter = ({
  isDirty,
  submitting,
  routerStore,
  projectsStore: {
    archiveOrUnarchiveProject,
    project,
    deleteProject,
    isDeleting,
    cloneProject,
  },
  usersStore: { isAdminOrDL, forceJobsByProject, isAdmin },
  settingsStore: { settings },
  xeroStore: { xeroSync, xero },
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [syncFailed, setSyncFailed] = React.useState(project.syncFailed);
  const [forcing, setForcing] = React.useState(false);

  const exportProjectLink = `${API_URL}/projects/${project.id}/export`;

  const { onDownload: exportProject } = useDownload({
    url: exportProjectLink,
  });

  const handleTryResync = () => {
    setForcing(!forcing);
    forceJobsByProject({
      syncProjectId: project.id,
      onSuccess: () => {
        setSyncFailed(!syncFailed);
        setForcing(false);
      },
    });
  };

  const canDelete =
    (settings.canDeleteStartedProjects ||
      moment.utc(project.startDate).isAfter(moment.utc().endOf('day'))) &&
    isAdmin;

  const menuItems = [
    {
      label: 'Try Resync',
      icon: 'refresh',
      onClick: handleTryResync,
      isVisible: syncFailed,
    },
    // {
    //   label: 'Sync with xero',
    //   icon: 'refresh',
    //   onClick: () => xeroSync(project.id),
    //   isVisible: xero.authorized,
    // },
    {
      label: 'Duplicate',
      icon: 'duplicateNew',
      isLink: true,
      to: `${APP_ROUTES.projectsCreate}?cloneId=${project.id}`,
    },
    {
      label: 'Clone',
      icon: 'copy',
      onClick: () => cloneProject(project.id),
      isVisible: IS_DEV || IS_STAGING,
    },
    {
      label: 'Export',
      icon: 'download',
      onClick: exportProject,
      isVisible: (IS_DEV || IS_STAGING) && isAdmin,
    },
    {
      label: project.archived ? 'Unarchive' : 'Archive',
      icon: 'archivedNew',
      onClick: () => archiveOrUnarchiveProject(project),
      isDisabled: !project.readyToArchive,
      isVisible: isAdminOrDL && !project.pending,
    },
    {
      label: 'Delete',
      icon: 'trashNew',
      onClick: onOpen,
      isVisible: canDelete,
    },
  ];
  return (
    <>
      <StickyFooter as={Flex}>
        <Button
          ml="auto"
          type="submit"
          isDisabled={!isDirty}
          isLoading={submitting}
          leftIcon={{ name: 'check', fontSize: '18px' }}
          fontWeight="400"
          width={isSmallerScreen && '100%'}
        >
          Save
        </Button>
        {isAdminOrDL && <Menu menuItems={menuItems} />}
      </StickyFooter>
      <DeleteModal
        onClose={onClose}
        isOpen={isOpen}
        onDelete={() =>
          deleteProject(project.id, () => {
            onClose();
            routerStore.push(APP_ROUTES.projects);
          })
        }
        isLoading={isDeleting}
      />
    </>
  );
};

export default inject(
  'projectsStore',
  'usersStore',
  'routerStore',
  'settingsStore',
  'xeroStore',
)(observer(ProjectSettingsStickyFooter));
