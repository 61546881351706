/* eslint-disable no-restricted-syntax */
import { find, filter, compact, isEmpty, flatten, map } from 'lodash-es';
import moment from 'moment';
import { isProjectBuild } from './projectUtils';

export const getEarliestStartDateForAllocationRateOrHourChange = (
  oldAllocations,
  currentAllocations,
) => {
  const filteredOldAllocations = filter(oldAllocations, a => !!a.id);
  const filteredCurrentAllocations = filter(currentAllocations, a => !!a.id);

  let changedAllocationDates = [];

  for (const oldAllocation of filteredOldAllocations) {
    const current = find(filteredCurrentAllocations, { id: oldAllocation.id });

    if (
      current &&
      ((+oldAllocation?.rate && +current?.rate !== +oldAllocation?.rate) ||
        (oldAllocation?.clientRate &&
          +current?.clientRate !== +oldAllocation?.clientRate) ||
        +current?.amount !== +oldAllocation?.amount)
    ) {
      changedAllocationDates.push(moment.utc(current?.startDate));
    }
  }
  changedAllocationDates = compact(changedAllocationDates);
  if (isEmpty(changedAllocationDates)) {
    return null;
  }
  return moment.max(changedAllocationDates).format();
};

export const getFlattenedAllProjectAllocations = project => {
  return isProjectBuild(project.engagementType)
    ? [
        ...(project.deazyAllocations1 || []),
        ...(project.deazyAllocations2 || []),
        ...(project.deazyAllocations3 || []),
        ...flatten(
          map(project.projectToSuppliers, pts => [
            ...(pts.allocations1 || []),
            ...(pts.allocations2 || []),
            ...(pts.allocations3 || []),
          ]),
        ),
      ]
    : [
        ...(project.deazyAllocations || []),
        ...flatten(
          map(project.projectToSuppliers, pts => pts.allocations || []),
        ),
      ];
};
