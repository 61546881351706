import React from 'react';
import Card from '@styles/Card';
import { Avatar, Box, Flex, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { APP_ROUTES } from '@routes';
import { get, map, isEmpty, toLower } from 'lodash-es';
import Typography from '@styles/Typography';
import {
  MAIN_NAMES_QA,
  SUPPLIER_ESTIMATE_STATUSES,
  SUPPLIER_STATUSES,
} from '@app/constants';
import Icon from '@components/Icon';
import { formatCurrency, objectValueToLower } from '@utils';
import Button from '@styles/Button';
import MatchedCounter from './MatchedCounter';

const trustedProps = {
  color: 'green.500',
  name: 'checkCircle',
};

const foundingTeamsProps = {
  color: 'green.basil',
  name: 'checkCircle',
};

const validatedProps = {
  color: 'yellow.solid',
  name: 'checkCircle',
};

const untestedProps = {
  color: 'orange.solid',
  name: 'questionCircle',
};

const disqualifiedProps = {
  color: 'red.500',
  name: 'warningTriangle',
};

const SkillPill = ({ skill, matched, ...props }) => {
  return (
    <Box
      padding="4px 8px"
      bg={matched ? 'yellow.lightSecondary' : 'gray.100'}
      borderRadius="8px"
      border="1px solid "
      borderColor={matched ? 'yellow.solid' : 'gray.200'}
      mr="8px"
      mb="8px"
      {...props}
    >
      <Typography variant="caption">{skill}</Typography>
    </Box>
  );
};

const ESTIMATE_STATUS_TO_LOWER = objectValueToLower(SUPPLIER_ESTIMATE_STATUSES);

const SUPPLIER_STATUS_TO_LOWER = objectValueToLower(SUPPLIER_STATUSES);

const MatchedCompanyCard = ({
  mainNameQA,
  shortlistSingleSupplier,
  unassignSupplier,
  deliveryPartner,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const statusIconProps = React.useMemo(() => {
    const { status } = deliveryPartner;

    const statusToLower = toLower(status);

    if (statusToLower === SUPPLIER_STATUS_TO_LOWER.TRUSTED) {
      return { ...trustedProps };
    }
    if (statusToLower === SUPPLIER_STATUS_TO_LOWER.FOUNDING_TEAMS) {
      return { ...foundingTeamsProps };
    }
    if (statusToLower === SUPPLIER_STATUS_TO_LOWER.VALIDATED) {
      return { ...validatedProps };
    }
    if (statusToLower === SUPPLIER_STATUS_TO_LOWER.UNTESTED) {
      return { ...untestedProps };
    }
    if (statusToLower === SUPPLIER_STATUS_TO_LOWER.DISQUALIFIED) {
      return { ...disqualifiedProps };
    }
    if (statusToLower === SUPPLIER_STATUS_TO_LOWER.NO_CONTRACT) {
      return {
        color: 'gray.paleSlate',
        name: 'crossCircle',
      };
    }
  }, [deliveryPartner]);

  const fpEstimateProps = React.useMemo(() => {
    const { fpEstimate } = deliveryPartner;
    const toLowerEstimateStatus = toLower(fpEstimate);
    if (toLowerEstimateStatus === ESTIMATE_STATUS_TO_LOWER.LEGENDS) {
      return { bg: 'green.basil', color: 'white' };
    }
    if (toLowerEstimateStatus === ESTIMATE_STATUS_TO_LOWER.TRIED_AND_TESTED) {
      return { bg: 'green.500', color: 'white' };
    }
    if (toLowerEstimateStatus === ESTIMATE_STATUS_TO_LOWER.UNKNOWN) {
      return { bg: 'yellow.solid', color: 'black' };
    }
    if (toLowerEstimateStatus === ESTIMATE_STATUS_TO_LOWER.MIXED) {
      return { bg: 'orange.solid', color: 'white' };
    }
    if (toLowerEstimateStatus === ESTIMATE_STATUS_TO_LOWER.TM_ONLY) {
      return { bg: 'red.500', color: 'white' };
    }
    if (toLowerEstimateStatus === ESTIMATE_STATUS_TO_LOWER.NEW) {
      return { bg: 'gray.paleSlate', color: 'black' };
    }
    return { bg: 'orange.mid', color: 'black' };
  }, [deliveryPartner]);

  const handleShortlistSupplier = React.useCallback(() => {
    setIsLoading(true);
    if (deliveryPartner.isChosen) {
      unassignSupplier(deliveryPartner.id);
    } else {
      shortlistSingleSupplier(deliveryPartner.id);
    }
    setIsLoading(false);
  }, [deliveryPartner]);

  return (
    <>
      <Card
        data-test-id={`${mainNameQA}--card`}
        padding="16px"
        bg={deliveryPartner.isChosen && '#F5FBEF'}
        borderColor={deliveryPartner.isChosen && '#7CCC33'}
        _hover={{
          cursor: 'normal',
        }}
      >
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          w="100%"
          mb="21.5px"
          data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpBasicInfoSection`}
          flexDir={[
            'column-reverse',
            'column-reverse',
            'column-reverse',
            'row',
          ]}
          rever
        >
          <Flex>
            <Avatar
              h="75px"
              mr="12px"
              w="75px"
              borderRadius="12px"
              name={get(deliveryPartner, 'name', 'Deazy')}
              src={deliveryPartner.logo}
              ignoreFallback
              bgColor={deliveryPartner.logo && 'transparent'}
            />
            <Box>
              <Flex alignItems="center">
                {deliveryPartner.status && (
                  <Icon {...statusIconProps} height="12.5px" mr="5px" />
                )}
                <Typography
                  variant="text2"
                  textTransform="capitalize"
                  lineHeight="17px"
                  data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpStatus`}
                >
                  {deliveryPartner.status}
                </Typography>
              </Flex>
              <Flex mb="8px">
                <Typography
                  variant="h3"
                  mr="5px"
                  wordBreak="break-all"
                  lineHeight="27px"
                  data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpName`}
                >
                  {deliveryPartner.name}
                </Typography>
                <Link
                  as={RouterLink}
                  to={APP_ROUTES.teams.supplierById(deliveryPartner.id)}
                >
                  <Icon
                    mt="1px"
                    name="mediumArrow"
                    fontSize="16px"
                    color="blue.500"
                    transform="rotate(-45deg)"
                  />
                </Link>
              </Flex>
              <Flex>
                <Flex alignItems="center" mr="19px">
                  <Icon name="location" mr="6px" />
                  <Typography
                    variant="text3"
                    data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpLocation`}
                  >
                    {deliveryPartner.country}
                  </Typography>
                </Flex>
                <Flex alignItems="center" mr="19px">
                  <Icon name="size" mr="6px" />
                  <Typography
                    variant="text3"
                    data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpSize`}
                  >
                    {deliveryPartner.size}
                  </Typography>
                </Flex>
                <Flex alignItems="center" mr="19px">
                  <Icon mr="6px" name="rate" />
                  {deliveryPartner.lowestRate >= 0 &&
                  deliveryPartner.highestRate > 0 ? (
                    <Typography
                      variant="text3"
                      data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpRates`}
                    >
                      {`${
                        deliveryPartner.lowestRate > 0
                          ? `${formatCurrency(
                              deliveryPartner.lowestRate,
                              deliveryPartner.hubspotCurrency,
                            )} - `
                          : ''
                      }${formatCurrency(
                        deliveryPartner.highestRate,
                        deliveryPartner.hubspotCurrency,
                      )}`}
                    </Typography>
                  ) : (
                    <Typography
                      variant="text3"
                      data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpRates`}
                    >
                      No rates available
                    </Typography>
                  )}
                </Flex>
              </Flex>
            </Box>
          </Flex>
          <Flex
            ml={['auto', 'auto', 'auto', '8px']}
            mb={['12px', '12px', '12px', 0]}
          >
            <MatchedCounter
              data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--matchedCounter`}
              matchedQuestions={deliveryPartner.matchedQuestions}
              counter={deliveryPartner.countOfMatchingAnswers}
            />
            <Button
              iconButton={{
                name: deliveryPartner.isChosen ? 'close' : 'add',
                fontSize: deliveryPartner.isChosen ? 23 : 14,
                color: deliveryPartner.isChosen && 'black',
              }}
              bg={deliveryPartner.isChosen ? 'white' : 'primary.400'}
              width="40px"
              h="40px"
              data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--addDpButton`}
              onClick={handleShortlistSupplier}
              isDisabled={isLoading}
            />
          </Flex>
        </Flex>
        <Flex
          mb="16px"
          flexWrap="wrap"
          data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpBasicProjectInfoSection`}
        >
          <Box mr="24px">
            <Typography mb="3px" variant="text2">
              Active Projects
            </Typography>
            <Typography
              data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpActiveProjects`}
            >
              {deliveryPartner.countOfActiveProjects || '-'}
            </Typography>
          </Box>
          <Box
            mr="24px"
            data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpEstimateSkillSection`}
          >
            <Typography mb="3px" variant="text2">
              FP Estimate Skill
            </Typography>
            <Box
              borderRadius="8px"
              padding="6px 12px"
              bg={fpEstimateProps.bg}
              width="fit-content"
            >
              <Typography
                fontFamily="WS"
                fontSize="12px"
                fontWeight="500"
                textTransform="uppercase"
                color={fpEstimateProps.color}
                data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpEstimateSkill`}
              >
                {deliveryPartner.fpEstimate || 'N/A'}
              </Typography>
            </Box>
          </Box>
        </Flex>
        <Box
          mb="8px"
          data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpStrengthsSection`}
        >
          <Typography color="gray.400" variant="text2" mb="5px">
            Technology Strengths
          </Typography>
          {!isEmpty(deliveryPartner.techStrengths) ? (
            <Flex wrap="wrap">
              {map(deliveryPartner.techStrengths, skill => {
                return (
                  <SkillPill
                    key={`skill-pill-${skill.name}-${deliveryPartner.id}`}
                    data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpStrengths`}
                    skill={skill.name}
                    matched={skill.isBriefMatched}
                  />
                );
              })}
            </Flex>
          ) : (
            <Typography variant="caption">-</Typography>
          )}
        </Box>
        <Box
          mb="8px"
          data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpMatchedTechincalSkillsSection`}
        >
          <Typography color="gray.400" variant="text2" mb="5px">
            Technical Skills
          </Typography>
          {!isEmpty(deliveryPartner.technologies) ? (
            <Flex wrap="wrap">
              {map(deliveryPartner.technologies, technology => {
                return (
                  <SkillPill
                    skill={technology.name}
                    matched={technology.isBriefMatched}
                    key={`matched-tech-skils-${technology}-${deliveryPartner.id}`}
                    data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpMatchedTechincalSkills`}
                  />
                );
              })}
            </Flex>
          ) : (
            <Typography variant="caption">-</Typography>
          )}
        </Box>
        <Box data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpFlagSection`}>
          <Typography variant="text2" mb="3px">
            Flags
          </Typography>
          {!isEmpty(deliveryPartner.flags) ? (
            <Flex wrap="wrap">
              {map(deliveryPartner.flags, flag => {
                return (
                  <Typography
                    w="100%"
                    mb="5px"
                    data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpFlag`}
                  >
                    {flag}
                  </Typography>
                );
              })}
            </Flex>
          ) : (
            <Typography>-</Typography>
          )}
        </Box>
      </Card>
    </>
  );
};

export default MatchedCompanyCard;
