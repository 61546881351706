import { API } from '@app/api';
import { Box, Flex } from '@chakra-ui/react';
import DatePicker from '@components/forms/DatePicker';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import AsyncAutocomplete from '@components/forms/_common/AsyncAutocomplete';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import { mustBePositiveNumber } from '@utils/formValidators';
import { get } from 'lodash-es';
import moment from 'moment';
import React from 'react';
import { Field, Form } from 'react-final-form';

const DeadlineStat = ({ date, ...props }) => {
  if (!date) {
    return null;
  }
  return (
    <Flex
      p="15px"
      alignSelf="flex-start"
      borderRadius="8px"
      bg="#fff"
      flexDir="column"
      lineHeight="21px"
      {...props}
    >
      <Typography variant="title" color="#000" mb="10px">
        {moment.utc(date).format('DD MMM YYYY')}
      </Typography>
      <Typography variant="text2" color="dark.solid">
        Deadline
      </Typography>
    </Flex>
  );
};

const TargetValueForm = ({
  brief,
  initialValues,
  onSubmit,
  isAdminOrDL,
  isReadOnly,
  isTeamAdmin,
  isSmallerScreen,
  mainNameQA,
}) => {
  const isPlugAndPlay = !!brief.newOrExisting;
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <WhiteCard
              mt="32px"
              flexWrap="wrap"
              display="flex"
              {...(isSmallerScreen && { mt: '24px', p: 0 })}
            >
              {(isTeamAdmin || isAdminOrDL) && (
                <>
                  {!isPlugAndPlay && (
                    <Box
                      w="28%"
                      pr="24px"
                      mb="20px"
                      {...(isSmallerScreen && { w: '100%', mb: '24px', pr: 0 })}
                    >
                      <Field
                        component={MaskedTextInput}
                        type="number"
                        decimalScale={2}
                        name="targetValue"
                        label="Target value (£)"
                        isDisabled={!isAdminOrDL || isReadOnly}
                        validate={mustBePositiveNumber}
                        placeholder="Enter the value you’d like the DP to aim for"
                        onAfterBlur={() => {
                          handleSubmit();
                        }}
                        data-test-id={`${mainNameQA}-targetValue`}
                      />
                    </Box>
                  )}
                  <Box
                    w="28%"
                    pr="24px"
                    mb="20px"
                    {...(isSmallerScreen && { w: '100%', mb: '24px', pr: 0 })}
                  >
                    <Field
                      component={AsyncAutocomplete}
                      name="pm"
                      isDisabled={!isAdminOrDL || isReadOnly}
                      label="Deazy lead"
                      getOptionsFn={API.searchPMs}
                      customOptionMap={option => ({
                        value: option,
                        label: `${option.fullname} (${option.email})`,
                      })}
                      placeholder="Add a deazy lead"
                      data-test-id={`${mainNameQA}-deazyLead`}
                      onAfterChange={() => handleSubmit()}
                    />
                  </Box>
                </>
              )}
              <Box
                w="28%"
                mb="20px"
                pr="24px"
                {...(isSmallerScreen && { w: '100%', mb: '24px', pr: 0 })}
              >
                <Field
                  component={AsyncAutocomplete}
                  name="clientLead"
                  isDisabled={!isAdminOrDL || isReadOnly}
                  label="Client contact"
                  data-test-id={`${mainNameQA}-clientLead`}
                  getOptionsFn={API.searchClientContactUsers(
                    get(brief, 'customer.id'),
                  )}
                  customOptionMap={option => ({
                    value: option,
                    label: `${option.fullname} (${option.email})`,
                  })}
                  placeholder="Add client contact"
                  onAfterChange={() => handleSubmit()}
                />
              </Box>
              {isAdminOrDL && (
                <Box
                  w="16%"
                  mb="20px"
                  {...(isSmallerScreen && { w: '100%', mb: '24px', pr: 0 })}
                >
                  <Field
                    component={DatePicker}
                    minW="100px"
                    name="proposalClosingDate"
                    data-test-id={`${mainNameQA}-proposalClosingDate`}
                    backgroundColor="white"
                    label="Closing date"
                    placeholder="Pick closing date..."
                    isDisabled={isReadOnly}
                    onAfterChange={() => handleSubmit()}
                  />
                </Box>
              )}
              {!isAdminOrDL && (
                <DeadlineStat
                  date={brief.proposalClosingDate}
                  ml="auto"
                  {...(isSmallerScreen && { ml: 'inherit', p: 0, py: '24px' })}
                />
              )}
            </WhiteCard>
          </form>
        );
      }}
    />
  );
};

export default TargetValueForm;
