import { useHistory, useRouteMatch } from 'react-router';
import React from 'react';
import { find, forEach, isEmpty, last, reject, split, get } from 'lodash-es';

export default ({
  usersStore,
  briefsStore,
  proposalsStore,
  tabs,
  appStore,
} = {}) => {
  const { isAdminOrDL, isTeamAdmin, isClient } = usersStore;
  const { featureFlags } = appStore;
  const deliveryPartnerMatchingEnabled =
    featureFlags['delivery-partner-matching'];
  const { brief } = briefsStore;
  const {
    allProposals: { firstRequest, data: allProposals },
  } = proposalsStore;

  const history = useHistory();

  const [firstLocation, setFirstLocation] = React.useState();
  const [strippedTabs, setStrippedTabs] = React.useState(tabs);

  React.useEffect(() => {
    setFirstLocation(history.location);
  }, []);

  const basePath = useRouteMatch('/:briefType/:briefId');

  const { params: { tab: currentTab, briefType, briefId } = {} } =
    useRouteMatch('/:briefType/:briefId/:tab') || {};

  React.useEffect(() => {
    if (basePath && !currentTab) {
      history.push(
        `/${basePath.params.briefType}/${basePath.params.briefId}/overview`,
      );
    }
  }, [basePath]);

  const { params: { type } = {}, url: briefUrl } =
    useRouteMatch('/:briefType/:briefId/:tab/:type') || {};

  const isDeazyLayerBriefPage = useRouteMatch(
    '/briefs/:briefId/proposals/:proposalId/:type',
  );

  const isDeazyLayerPPBriefPage =
    currentTab === 'proposal' && type === 'deazy-proposal';

  const isDeazyLayerTABriefPage = currentTab === 'proposal' && type === 'deazy';

  const isClientSingleProposalPage = currentTab === 'proposals' && type;

  const isSinglePlugAndPlayProposalPageForTeamAdmin =
    currentTab === 'proposal' && !type;

  const isClientSinglePnPProposalPage =
    currentTab === 'proposals' && type === 'client';
  const isDeazyLayerPage = isDeazyLayerBriefPage || isDeazyLayerPPBriefPage;

  const isPlugAndPlay = briefType !== 'briefs';

  const isMobileSingleFeaturePage =
    currentTab === 'proposal' && type === 'features';

  const redirectToBriefOverview = React.useCallback(() => {
    history.push(`/${briefType}/${briefId}/overview`);
  }, [briefType, briefId]);

  const redirectToTeamAdminProposal = React.useCallback(() => {
    history.push(`/${briefType}/${briefId}/proposal`);
  }, [briefType, briefId]);

  const redirectToDeazyTab = React.useCallback(() => {
    history.push(`/${briefType}/${briefId}/proposal/deazy`);
  }, [briefType, briefId]);

  const proposals = get(brief, 'supplierProposal.proposals');

  const stripTabs = React.useCallback(() => {
    let newTabs = [...(tabs || [])];
    const stripTab = t => reject(newTabs, { value: t });

    forEach(tabs, ({ value: tab, label }) => {
      if (
        label === 'Your proposal' &&
        (!isTeamAdmin ||
          (brief.supplierProposal?.status !== 'Accepted' && brief.id))
      ) {
        newTabs = stripTab(tab);
      }

      if (isClient && label === 'Proposal' && isEmpty(proposals)) {
        newTabs = stripTab(tab);
      }

      if (tab === 'proposals' && isTeamAdmin) {
        newTabs = stripTab(tab);
      }

      if (
        tab === 'proposal' &&
        currentTab !== 'proposal' &&
        label === 'Your proposal' &&
        isTeamAdmin &&
        !brief.id
      ) {
        newTabs = stripTab(tab);
      }

      if (tab === 'candidates' && (!isAdminOrDL || !isPlugAndPlay)) {
        newTabs = stripTab(tab);
      }

      if (tab === 'assignment' && !isAdminOrDL) {
        newTabs = stripTab(tab);
      }

      if (tab === 'client' && (!isPlugAndPlay || !isClient)) {
        newTabs = stripTab(tab);
      }

      if (
        tab === 'find-delivery-partners' &&
        (!isAdminOrDL || !deliveryPartnerMatchingEnabled)
      ) {
        newTabs = stripTab(tab);
      }

      if (tab === 'deazy-proposal' && (!isPlugAndPlay || !isAdminOrDL)) {
        newTabs = stripTab(tab);
      }

      if (
        (tab === 'proposals' && isPlugAndPlay) ||
        (tab === 'proposals' &&
          ((!firstRequest && isEmpty(allProposals)) ||
            (firstRequest &&
              firstLocation &&
              !firstLocation?.pathname?.includes(tab) &&
              isEmpty(allProposals))))
      ) {
        newTabs = stripTab(tab);
      }
    });

    setStrippedTabs(newTabs);
  }, [tabs, brief, briefType, briefId, type, allProposals, firstRequest]);

  React.useEffect(() => {
    if (isAdminOrDL) {
      if (isClientSingleProposalPage) {
        history.push(`${briefUrl}/delivery-partner`);
      }
      if (isClientSinglePnPProposalPage) {
        history.push(`${briefUrl}/delivery-partner`);
      }
      if (isSinglePlugAndPlayProposalPageForTeamAdmin) {
        redirectToDeazyTab();
      }
    }
    if (isTeamAdmin) {
      if (currentTab === 'proposals') {
        redirectToTeamAdminProposal();
      }
    }
  }, []);

  const activeTab = React.useMemo(() => {
    if (isDeazyLayerPPBriefPage || isDeazyLayerTABriefPage) {
      return 'deazy-proposal';
    }
    if (isClientSinglePnPProposalPage) {
      return 'client';
    }
    if (isDeazyLayerPage || isClientSingleProposalPage) {
      return 'proposals';
    }
    if (isMobileSingleFeaturePage) {
      return 'proposal';
    }

    return last(split(history.location.pathname, '/'));
  }, [history.location.pathname]);

  React.useEffect(() => {
    stripTabs();
  }, [currentTab, type, firstLocation, allProposals, firstRequest, brief]);

  React.useEffect(() => {
    const overriddenTab = () => {
      if (isDeazyLayerPPBriefPage) {
        return 'deazy-proposal';
      }
      if (isClientSinglePnPProposalPage) {
        return 'client';
      }
      return currentTab;
    };

    if (!find(strippedTabs, st => st.value === overriddenTab()) && !basePath) {
      redirectToBriefOverview();
    }
  }, [strippedTabs, currentTab]);

  return { tabs: strippedTabs, activeTab };
};
