import { isString, map, noop, omit } from 'lodash-es';
import { action, makeObservable, observable, computed } from 'mobx';

export default class TeamsStore {
  constructor({ API, toastsStore }) {
    makeObservable(this);
    this.toastsStore = toastsStore;
    this.API = API;
  }

  @observable newClient = {};

  @observable newSupplier = {};

  @observable isLoading = false;

  @observable _client = {};

  @observable _supplier = {};

  @observable checkInvoiceNumberingPayload = {};

  @observable supplierInvoices = {};

  @action createClient = async ({ values, onSuccess = noop }) => {
    try {
      const { data } = await this.API.createClient(values);
      this.newClient = data;
      onSuccess(data);
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action clearNewClient = () => {
    this.newClient = {};
  };

  @action updateClient = async ({ clientId, values, onSuccess = noop }) => {
    try {
      await this.API.updateClientById(clientId, { ...values });
      this.toastsStore.showSuccess({
        title: 'Client has been updated successfully.',
      });
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action deleteClient = async ({ clientId, onSuccess = noop, onFailure }) => {
    try {
      await this.API.deleteClientById(clientId);
      this.toastsStore.showSuccess({
        title: 'Client has been deleted successfully.',
      });
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
      onFailure();
    }
  };

  prepareSupplierPayload = data => ({
    ...data,
    paymentDetails: map(data.paymentDetails, pd => ({
      ...omit(pd, isString(pd.id) ? 'id' : []),
    })),
  });

  @action updateSupplier = async ({ supplierId, values, onSuccess = noop }) => {
    try {
      await this.API.updateSupplierById(
        supplierId,
        this.prepareSupplierPayload(values),
      );
      this.toastsStore.showSuccess({
        title: 'Delivery partner has been updated successfully.',
      });
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action createSupplier = async ({ values, onSuccess = noop }) => {
    try {
      const { data } = await this.API.createSupplier(
        this.prepareSupplierPayload(values),
      );
      this.newSupplier = data;
      onSuccess(data);
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action deleteSupplier = async ({
    supplierId,
    onSuccess = noop,
    onFailure,
  }) => {
    try {
      await this.API.deleteSupplierById(supplierId);
      this.toastsStore.showSuccess({
        title: 'Delivery partner has been deleted successfully.',
      });
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
      onFailure();
    }
  };

  @action fetchClient = async clientId => {
    this._client = {};
    if (!clientId) {
      return;
    }
    this.isLoading = true;
    try {
      const { data } = await this.API.getClients(clientId);
      this._client = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoading = false;
    }
  };

  @action clear = () => {
    this._client = {};
    this._supplier = {};
  };

  @action fetchSuppliersBlacklist = async clientId => {
    if (!clientId) {
      return;
    }
    this.isLoading = true;
    try {
      const { data } = await this.API.getSuppliersBlacklist(clientId);
      this._client = {
        ...this._client,
        blacklistedSuppliers: data,
      };
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoading = false;
    }
  };

  @computed get client() {
    return {
      ...this._client,
    };
  }

  @action fetchSupplier = async supplierId => {
    this._supplier = {};
    if (!supplierId) {
      return;
    }
    this.isLoading = true;
    try {
      const { data } = await this.API.getSupplierById(supplierId);
      this._supplier = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoading = false;
    }
  };

  @computed get supplier() {
    return {
      ...this._supplier,
    };
  }

  @action deleteUserFromClient = async ({
    clientId,
    userId,
    onSuccess = noop,
  }) => {
    try {
      await this.API.deleteUserFromClient(clientId, userId);
      this.toastsStore.showSuccess({
        title: 'User has been removed successfully.',
      });
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action removeCurrencyFromSupplier = async (
    supplierId,
    paymentDetailsId,
    successCb,
  ) => {
    try {
      await this.API.removeCurrencyFromSupplier(supplierId, paymentDetailsId);
      this.toastsStore.showSuccess({
        title: 'Currency has been removed successfully.',
      });
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action setCurrencyAsPrimaryForSupplier = async (
    supplierId,
    paymentDetailsId,
    successCb,
  ) => {
    try {
      await this.API.setCurrencyAsPrimaryForSupplier(
        supplierId,
        paymentDetailsId,
      );
      this.toastsStore.showSuccess({
        title: 'Currency has been updated successfully.',
      });
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action deleteUserFromSupplier = async ({
    supplierId,
    userId,
    onSuccess = noop,
  }) => {
    try {
      await this.API.deleteUserFromSupplier(supplierId, userId);
      this.toastsStore.showSuccess({
        title: 'User has been removed successfully.',
      });
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action addUserToClient = async ({
    clientId,
    memberId,
    onSuccess = noop,
  }) => {
    try {
      await this.API.addUserToClient(clientId, { memberId });
      this.toastsStore.showSuccess({
        title: 'User has been added successfully.',
      });
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action addSupplierToBlacklist = async ({
    clientId,
    supplierId,
    blacklistData,
    onSuccess,
  }) => {
    try {
      await this.API.addSupplierToBlacklist(
        clientId,
        supplierId,
        blacklistData,
      );
      await onSuccess();
      this.toastsStore.showSuccess({
        title: 'Delivery partner has been successfully blacklisted',
      });
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action removeSupplierFromBlacklist = async ({
    clientId,
    supplierId,
    onSuccess,
  }) => {
    try {
      await this.API.removeSupplierFromBlacklist(clientId, supplierId);
      await onSuccess();
      this.toastsStore.showSuccess({
        title: 'Delivery partner has been removed from the blacklist',
      });
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action addDeazyLeadToClient = async ({ clientId, userId, onSuccess }) => {
    try {
      await this.API.addDeazyLeadToClient(clientId, { userId });
      await onSuccess();
      this.toastsStore.showSuccess({
        title: 'Deazy Lead has been successfully added',
      });
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action removeDeazyLeadToClient = async ({ clientId, onSuccess }) => {
    try {
      await this.API.removeDeazyLeadToClient(clientId);
      await onSuccess();
      this.toastsStore.showSuccess({
        title: 'Deazy Lead has been successfully removed',
      });
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action addUserToSupplier = async ({
    supplierId,
    memberId,
    onSuccess = noop,
  }) => {
    try {
      await this.API.addUserToSupplier(supplierId, { memberId });
      this.toastsStore.showSuccess({
        title: 'User has been added successfully.',
      });
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action checkInvoiceNumberingForSuppliers = async ({
    supplierId,
    date,
    onSuccess = noop,
  }) => {
    try {
      const { data } = await this.API.checkInvoiceNumberingForSuppliers(
        supplierId,
        (this.checkInvoiceNumberingPayload = {
          supplierId,
          date,
        }),
      );
      this.supplierInvoices = data;
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action reCheckInvoiceNumberingForSuppliers = async ({
    supplierId,
    onSuccess = noop,
  }) => {
    try {
      const { data } = await this.API.checkInvoiceNumberingForSuppliers(
        supplierId,
        this.checkInvoiceNumberingPayload,
      );
      this.supplierInvoices = data;
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };
}
