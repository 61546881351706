import { Box, Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import ZoneContainer from '@components/ZoneContainer';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { formatCurrency } from '@utils';
import { isString, lowerCase, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import InfoAlert from '@components/InfoAlert';

const featuresCounter = (featuresCount = 0, isSmallerScreen) => {
  if (isSmallerScreen) {
    return `(${featuresCount} ${featuresCount > 1 ? 'features' : 'feature'})`;
  }
  return `${featuresCount} ${featuresCount > 1 ? 'Features' : 'Feature'}`;
};

const OverviewCard = ({
  featuresCount,
  currency,
  totalHours,
  totalCost,
  mainNameQA,
  isSmallerScreen,
}) => (
  <Box
    bg={isSmallerScreen ? 'dark.light' : 'blue.solid'}
    color={!isSmallerScreen && '#fff'}
    borderRadius="8px"
    mb={isSmallerScreen ? '16px' : '31px'}
  >
    <Box
      mb={isSmallerScreen ? '4px' : '8px'}
      p="16px"
      {...(isSmallerScreen && {
        display: 'flex',
        alignItems: 'center',
        p: '12px',
        pb: 0,
      })}
    >
      <Typography
        variant="h2"
        mb={!isSmallerScreen && '8px'}
        mr={isSmallerScreen && '4px'}
      >
        Overview
      </Typography>
      <Typography
        color={isSmallerScreen && '#4E4E4E'}
        data-test-id={`${mainNameQA}--featuresCounter`}
        variant={isSmallerScreen ? 'h2' : 'text2'}
      >
        {featuresCounter(featuresCount, isSmallerScreen)}
      </Typography>
    </Box>
    <Flex borderTop={!isSmallerScreen && '1px solid #fff'}>
      <Flex
        w="50%"
        fontSize={14}
        borderRight="1px solid #fff"
        px="10px"
        py="14px"
      >
        <Icon name="clock" fontSize={22} mr="8px" />
        <Typography
          data-test-id={`${mainNameQA}--totalHours`}
          alignSelf="center"
        >{`${totalHours} Hours`}</Typography>
      </Flex>
      <Flex w="50%" fontSize={14} px="10px" py="13px">
        <Icon
          name={currency}
          fontSize={22}
          mr="8px"
          data-test-id={`${mainNameQA}--currency`}
        />
        <Typography
          data-test-id={`${mainNameQA}--totalCost`}
          alignSelf="center"
        >
          {formatCurrency(totalCost)}
        </Typography>
      </Flex>
    </Flex>
  </Box>
);

const FeatureCard = ({
  name,
  isSelected,
  onSelect,
  currency,
  tasksCount = 0,
  totalHours = 0,
  totalCost = 0,
  mainNameQA,
}) => {
  return (
    <Box
      borderRadius="8px"
      mb="31px"
      bg="#fff"
      onClick={onSelect}
      data-tedt-id={`${mainNameQA}--feature`}
      cursor="pointer"
      boxShadow={
        !isSelected
          ? '0 0 0 1px #d9dada'
          : 'inset 0 0 0 2px #3366ff, 0 0 24px 0 rgba(0, 0, 0, 0.15)'
      }
    >
      <Box mb="12px">
        <Flex justifyContent="space-between" px="8px" pt="12px">
          <Typography
            color="#fff"
            variant="caption"
            p="10px"
            borderRadius="32px"
            px="14px"
            py="3px"
            bg={tasksCount > 0 ? 'blue.solid' : 'blue.mid'}
            data-test-id={`${mainNameQA}--feature-taskCount`}
          >
            {`${tasksCount} ${tasksCount > 1 ? 'TASKS' : 'TASK'}`}
          </Typography>
        </Flex>
        <Typography
          variant="h3"
          mt="16px"
          px="12px"
          data-test-id={`${mainNameQA}--feature-featureName`}
        >
          {name}
        </Typography>
      </Box>
      <Flex borderTop="1px solid" borderColor="dark.mid" position="relative">
        <Box
          position="absolute"
          h="8px"
          zIndex={10}
          w="calc(100% - 4px)"
          left="2px"
          backgroundImage="linear-gradient(to bottom, #f9f9f9,rgba(0,0,0,0))"
        />
        <Flex
          w="50%"
          zIndex={20}
          fontSize={14}
          borderRight="1px solid"
          borderColor="dark.mid"
          px="10px"
          py="14px"
        >
          <Icon
            name="clock"
            fontSize={22}
            mr="8px"
            color={totalHours > 0 ? 'green.solid' : 'dark.mid'}
          />
          <Typography
            alignSelf="center"
            color={totalHours > 0 ? 'dark.solid' : 'dark.mid'}
            data-test-id={`${mainNameQA}--feature-taskHours`}
          >
            {`${totalHours} hours`}
          </Typography>
        </Flex>
        <Flex w="50%" fontSize={14} px="10px" py="13px">
          <Icon
            name={currency}
            data-test-id={`${mainNameQA}--currency`}
            fontSize={22}
            mr="8px"
            color={totalCost > 0 ? 'green.solid' : 'dark.mid'}
          />
          <Typography
            data-test-id={`${mainNameQA}--feature-totalCost`}
            alignSelf="center"
            color={totalCost > 0 ? 'dark.solid' : 'dark.mid'}
          >
            {formatCurrency(totalCost)}
          </Typography>
        </Flex>
      </Flex>
    </Box>
  );
};

const ProposalFeatures = ({
  proposalsStore: {
    featuresCount,
    featuresCountForClient,
    decoratedProposalData,
    decoratedClientProposalData,
    totalHours,
    totalHoursForClient,
    totalCost,
    totalCostForClient,
  },
  usersStore: { isTeamAdmin, isClient },
  onSelect,
  selectedId,
  currency,
  onAddNewFeature,
  isReadOnly,
  showFullBreakdownForClient,
  mainNameQA,
  isSmallerScreen,
}) => {
  const useClientProposalData = isClient || showFullBreakdownForClient;
  const featuresCountToShow = useClientProposalData
    ? featuresCountForClient
    : featuresCount;
  const totalHoursToShow = useClientProposalData
    ? totalHoursForClient
    : totalHours;
  const totalCostToShow = useClientProposalData
    ? totalCostForClient
    : totalCost;
  const featuresOverviewData = useClientProposalData
    ? map(decoratedClientProposalData.features, f => ({
        ...f,
        totalCost: f.cost,
        totalHours: f.hours,
      }))
    : decoratedProposalData.features;

  return (
    <>
      <OverviewCard
        featuresCount={featuresCountToShow}
        currency={lowerCase(currency)}
        totalHours={totalHoursToShow}
        totalCost={totalCostToShow}
        mainNameQA={mainNameQA}
        isSmallerScreen={isSmallerScreen}
      />
      {isSmallerScreen && (
        <InfoAlert
          w="100%"
          mb="16px"
          label="Click into a feature to see more"
        />
      )}
      {map(featuresOverviewData, feature => (
        <FeatureCard
          currency={lowerCase(currency)}
          key={`feature-card-${feature.id}`}
          {...feature}
          isSelected={selectedId === feature.id}
          onSelect={() => onSelect(feature.id)}
          mainNameQA={mainNameQA}
        />
      ))}
      {isTeamAdmin && !isReadOnly && (
        <ZoneContainer
          h={isSmallerScreen ? '112px' : '145px'}
          alignItems="center"
          justifyContent="center"
          borderColor={isSmallerScreen && '#D9DADA'}
        >
          <Button
            variant="outline"
            leftIcon={{ name: 'add' }}
            isDisabled={isString(selectedId)}
            onClick={onAddNewFeature}
            data-test-id={`${mainNameQA}--add-new-feature`}
          >
            Add new feature
          </Button>
        </ZoneContainer>
      )}
    </>
  );
};

export default inject(
  'usersStore',
  'proposalsStore',
)(observer(ProposalFeatures));
