import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import Icon from '@components/Icon';
import StatusDropdownTrigger from '@components/StatusDropdownTrigger';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { find, isEmpty, map, isBoolean } from 'lodash-es';
import useMediaQuery from '@hooks/useMediaQuery';
import React from 'react';

const tooltipProps = {
  zIndex: 40001,
  p: '12px',
  borderRadius: '8px',
  lineHeight: 'normal',
  hasArrow: true,
  fontFamily: 'WS',
  bg: 'dark.solid',
  px: '24px',
  py: '12px',
  placement: 'top',
  fontSize: 16,
};

const DropdownOption = ({ icon, isDisabled, children, ...props }) => (
  <Box
    _hover={{ bg: 'blue.light', textDecoration: 'none' }}
    _active={{
      pointerEvents: isDisabled ? 'none' : 'default',
    }}
    py="9px"
    px="12px"
    borderRadius="8px"
    cursor="pointer"
    zIndex="100"
    opacity={isDisabled ? '0.4' : '1'}
    {...props}
  >
    {children}
  </Box>
);

const AddButton = ({ label, ...props }) => (
  <Button
    rightIcon={<Icon name="add" mb="2px" fontSize={10} />}
    mr="8px"
    variant="outline"
    borderRadius="8px"
    borderColor="#797979"
    borderWidth="1px"
    bg="#fff"
    borderStyle="dashed"
    h="20px"
    p="0px 12px"
    color="inherit"
    {...props}
  >
    <Typography variant="caption">{label || 'ADD'}</Typography>
  </Button>
);

const StatusDropdown = ({
  onChange,
  isReadOnly,
  label,
  options,
  currentValue,
  closeOnClick = true,
  setOnClick = true,
  buttonProps,
  buttonDescriptionProps,
  popoverContentProps,
  isDisabled,
  tooltipLabel,
  withRevertLabels,
  mainNameQA,
  qaValue,
  buttonWrapperProps,
  labelProps,
  useAltLabel,
  useOutsideBg = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [value, setValue] = React.useState(currentValue);
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  React.useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  const currentOption = React.useMemo(() => {
    const option = find(options, { value }) || {};
    if (isSmallerScreen) {
      return { ...option, bg: option?.altMobileBg || option?.bg };
    }
    return option;
  }, [options, isSmallerScreen, value]);

  const TriggerButton = (
    <Flex
      pb={withRevertLabels ? '0' : '6px'}
      alignItems="center"
      mr={withRevertLabels ? '0' : '12px'}
      {...buttonWrapperProps}
      {...(isSmallerScreen &&
        useOutsideBg && {
          borderRadius: '8px',
          justifyContent: 'center',
          p: '12px',
          mr: 0,
          w: '100%',
          bg: currentOption.bg,
        })}
    >
      {label && !withRevertLabels && (
        <Typography variant="caption" mt="3px" mr="12px" {...labelProps}>
          {label}
        </Typography>
      )}
      {isEmpty(currentOption) ? (
        <AddButton {...buttonProps} />
      ) : (
        <StatusDropdownTrigger
          isReadOnly={isReadOnly}
          currentOption={currentOption}
          buttonProps={buttonProps}
          buttonDescriptionProps={buttonDescriptionProps}
          mainNameQA={mainNameQA}
          qaValue={qaValue}
          useAltLabel={useAltLabel}
        />
      )}
    </Flex>
  );

  if (!isBoolean(isSmallerScreen)) {
    return null;
  }

  if (isReadOnly && !withRevertLabels) {
    return TriggerButton;
  }

  if (isReadOnly && withRevertLabels) {
    return (
      <Flex alignItems="center" mr="16px">
        {TriggerButton}
        <Typography mt="3px" variant="text3">
          {label}
        </Typography>
      </Flex>
    );
  }

  return (
    <Popover
      isOpen={!isDisabled && isOpen}
      placement={
        // eslint-disable-next-line no-nested-ternary
        isSmallerScreen
          ? 'bottom'
          : withRevertLabels
          ? 'bottom-start'
          : 'bottom-end'
      }
      onOpen={onOpen}
      onClose={onClose}
    >
      {label && withRevertLabels ? (
        <Flex alignItems="center" mr={isSmallerScreen ? '0px' : '16px'}>
          <PopoverTrigger>{TriggerButton}</PopoverTrigger>
          <Typography mt="3px" variant="text3">
            {label}
          </Typography>
        </Flex>
      ) : (
        <PopoverTrigger>{TriggerButton}</PopoverTrigger>
      )}
      <PopoverContent
        zIndex={40000}
        w="124px"
        border="1px solid"
        borderColor="#d9dada"
        px="4px"
        py="8px"
        borderRadius="8px"
        boxShadow="0 8px 44px 0 rgba(0, 0, 0, 0.15)"
        {...popoverContentProps}
      >
        <PopoverBody p="0px">
          <Stack spacing="0px">
            {map(options, opt => (
              <DropdownOption
                isDisabled={opt.isDisabled}
                key={`dropdown-status-key-${opt.value}`}
                data-test-id={`${mainNameQA}--${opt.value}`}
                onClick={() => {
                  if (isReadOnly) {
                    return;
                  }
                  if (setOnClick) {
                    setValue(opt.value);
                  }
                  if (closeOnClick) {
                    onClose();
                  }
                  if (onChange) {
                    onChange(opt.value);
                  }
                }}
              >
                {opt.label}
                {opt.isDisabled && tooltipLabel && (
                  <Tooltip label={tooltipLabel} {...tooltipProps}>
                    <Box as="span" ml="5px">
                      <Icon name="warning" />
                    </Box>
                  </Tooltip>
                )}
              </DropdownOption>
            ))}
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default StatusDropdown;
