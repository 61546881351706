import { Flex, Stack } from '@chakra-ui/react';
import FormLabel from '@components/forms/FormLabel';
import MultiSelect from '@components/forms/MultiSelect';
import Select from '@components/forms/_common/Select';
import Switcher from '@components/Switcher';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import Button from '@styles/Button';
import { map } from 'lodash-es';
import WhiteCard from '@components/WhiteCard';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form } from 'react-final-form';
import Typography from '@styles/Typography';
import useCountries from '@hooks/useCountries';
import useEngagementTypes from '@hooks/useEngagementTypes';
import RecommendedSupplierList from './RecommendedSupplierList';

const MONDAY_P_N_P_ENGAGEMENT_TYPE = 'Plug & Play resourcing';

const AdditionalRequirementsForm = ({
  briefsStore: { brief },
  briefAssignmentsStore: { getRecommendedSuppliers },
  mainNameQA,
}) => {
  const { countryOptions, isLoading: isLoadingCountries } = useCountries();
  const {
    engagementOptions,
    isLoading: isLoadingEngagements,
  } = useEngagementTypes();

  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  const initialValues = {
    whiteLabel: brief.whiteLabel || false,
    partialMatching: brief.partialMatching || false,
    countries: map(brief.countries, c => ({ label: c, value: c })),
    engagementType: brief.isPlugAndPlay
      ? MONDAY_P_N_P_ENGAGEMENT_TYPE
      : brief.engagementType,
  };

  return (
    <>
      <WhiteCard
        title="Advanced matching"
        {...(isSmallerScreen && { px: 0, pt: 0, mt: '24px' })}
      >
        <Typography mt="-16px" mb="32px">
          Find an intelligent match for this brief with advanced filtering and
          sort options
        </Typography>
        <Form
          keepDirtyOnReinitialize
          initialValues={initialValues}
          onSubmit={values => getRecommendedSuppliers(brief.id, values)}
          render={({ handleSubmit, submitting, values, form }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Stack
                  mb="24px"
                  direction={isSmallerScreen ? 'column' : 'row'}
                  spacing={isSmallerScreen ? 6 : 10}
                >
                  <Field
                    name="engagementType"
                    data-test-id={`${mainNameQA}--engagementType`}
                    label="What kind of engagement model will this be?"
                    component={Select}
                    placeholder="Engagement model type"
                    isLoading={isLoadingEngagements}
                    options={engagementOptions}
                    isDisabled={brief.isPlugAndPlay || brief.isClosed}
                  />
                  <Field
                    name="countries"
                    data-test-id={`${mainNameQA}--countries`}
                    label="Which countries do you want to exclude?"
                    component={MultiSelect}
                    placeholder="Select regions"
                    isLoading={isLoadingCountries}
                    isDisabled={brief.isClosed}
                    options={countryOptions}
                    iconColor="black"
                  />
                </Stack>
                <Flex mb="24px" alignItems="center">
                  <FormLabel
                    htmlFor="white-label-form-input"
                    label="Do we need to white label?"
                    pr="24px"
                    pb={0}
                    mb={0}
                  />
                  <Switcher
                    id="white-label-form-input"
                    data-test-id={`${mainNameQA}--whiteLabel`}
                    isDisabled={brief.isClosed}
                    defaultChecked={values.whiteLabel}
                    onChange={checked => form.change('whiteLabel', checked)}
                  />
                </Flex>
                <Flex mb="32px" alignItems="center">
                  <FormLabel
                    htmlFor="white-label-form-input"
                    label="Allow partial match on technologies?"
                    pr="24px"
                    pb={0}
                    mb={0}
                  />
                  <Switcher
                    id="partial-match-form-input"
                    data-test-id={`${mainNameQA}--partialMatch`}
                    isDisabled={brief.isClosed}
                    defaultChecked={values.partialMatching}
                    onChange={checked =>
                      form.change('partialMatching', checked)
                    }
                  />
                </Flex>
                <Button
                  type="submit"
                  isLoading={submitting}
                  isDisabled={brief.isClosed}
                  size="lg"
                  {...(isSmallerScreen && {
                    width: '100%',
                  })}
                >
                  Suggest delivery partners
                </Button>
              </form>
            );
          }}
        />
        <RecommendedSupplierList mainNameQA={mainNameQA} />
      </WhiteCard>
    </>
  );
};

export default inject(
  'briefsStore',
  'briefAssignmentsStore',
)(observer(AdditionalRequirementsForm));
