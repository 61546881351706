import { Box } from '@chakra-ui/react';
import SectionTitle from '@components/SectionTitle';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { isEmpty, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import RecommendedSupplierListItem from './RecommendedSupplierListItem';

const RecommendedSupplierList = ({
  briefAssignmentsStore: {
    shortlistSuppliers,
    decoratedRecommendedSuppliers,
    recommendedSuppliers: { isLoading, firstRequest },
  },
  briefsStore: { brief },
  mainNameQA,
}) => {
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  if (firstRequest) {
    return null;
  }
  return (
    <>
      {!isSmallerScreen && <Box w="100%" mt="32px" h="1px" bg="dark.mid" />}
      <SectionTitle
        mt={isSmallerScreen ? '16px' : '40px'}
        p={isSmallerScreen ? '24px 0 0 ' : 0}
        mb="24px"
        border="none"
      >
        {isSmallerScreen
          ? 'Recommended delivery partners'
          : 'Recommended supplier list'}
      </SectionTitle>
      {isLoading && <Spinner variant="medium" />}
      {map(decoratedRecommendedSuppliers, (supplier, idx) => (
        <RecommendedSupplierListItem
          key={`supplier-list-item-${supplier.id}`}
          ordinalNumber={idx + 1}
          supplier={supplier}
          shortlistSuppliers={shortlistSuppliers}
          briefId={brief.id}
          mainNameQA={mainNameQA}
        />
      ))}
      {isEmpty(decoratedRecommendedSuppliers) && !isLoading && (
        <Typography my="20px">No Delivery Partners found.</Typography>
      )}
    </>
  );
};

export default inject(
  'briefsStore',
  'briefAssignmentsStore',
)(observer(RecommendedSupplierList));
