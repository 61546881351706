import { Stack } from '@chakra-ui/react';
import Textarea from '@components/forms/_common/Textarea';
import useMediaQuery from '@hooks/useMediaQuery';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { mustBeShorterOrEqual } from '@utils/formValidators';
import React from 'react';
import { Field } from 'react-final-form';

const CommentModal = ({
  isReadOnly,
  isOpen,
  onClose,
  fieldName = 'comment',
  defaultValue,
  mainNameQA,
  saveComment,
  limit = 300,
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styleProps={
        isSmallerScreen && { p: '24px 16px 48px', margin: 'auto 0px 0px' }
      }
    >
      <Typography mb="32px" variant="h2" textAlign="center">
        Comment View
      </Typography>

      <Field
        defaultValue={defaultValue}
        name={fieldName}
        validate={mustBeShorterOrEqual(limit)}
        placeholder="Write note here"
        mb="16px"
        inputProps={{
          borderColor: 'dark.mid',
          borderWidth: '1px',
          disabled: isReadOnly,
        }}
        mt="24px"
        mx="auto"
        maxW="480px"
        data-test-id={`${mainNameQA}--commentModal`}
        render={props => (
          <>
            <Textarea {...props} />
            <Stack spacing={3} mt="40px">
              {!isReadOnly && (
                <Button
                  onClick={() => {
                    if (props.input.value.length <= limit) {
                      saveComment(props.input.value);
                      props.input.onChange('');
                      return onClose();
                    }
                    return props.meta.error;
                  }}
                >
                  Save
                </Button>
              )}
              <Button
                variant="outline"
                onClick={() => {
                  onClose();
                  props.input.onChange('');
                }}
              >
                Cancel
              </Button>
            </Stack>
          </>
        )}
      />
    </Modal>
  );
};

export default CommentModal;
