import { Box, Tooltip, useDisclosure } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';

const ChangesSavedIndicator = ({ milestonesStore: { lastModifiedDate } }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const label = React.useMemo(() => {
    let str = 'Every change you make is automatically saved.';
    if (lastModifiedDate) {
      str = `${str} Last edit was ${moment(lastModifiedDate).fromNow()}.`;
    }
    return str;
  }, [isOpen, lastModifiedDate]);

  return (
    <Tooltip
      isOpen={isOpen}
      zIndex={33000}
      px="16px"
      pb="16px"
      pt="24px"
      textAlign="center"
      borderRadius="8px"
      lineHeight="normal"
      hasArrow
      bg="dark.solid"
      label={label}
      placement="bottom"
      fontSize={16}
    >
      <Box
        ml="20px"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        as={Typography}
        cursor="default"
        textDecoration="underline"
        color="dark.mid"
        mt="11px"
        _hover={{
          color: 'dark.solid',
        }}
        alignSelf="center"
      >
        All changes are saved
      </Box>
    </Tooltip>
  );
};

export default inject('milestonesStore')(observer(ChangesSavedIndicator));
