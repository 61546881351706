import { Box } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';
import FormError from './FormError';
import FormLabel from './FormLabel';

const Textarea = ({
  label,
  placeholder,
  input,
  meta,
  labelColor,
  inputProps = {},
  labelProps = {},
  rows = 6,
  counter,
  ...props
}) => {
  const hasError =
    (meta.touched || meta.submitFailed) && (meta.error || meta.submitError);

  return (
    <Box position="relative" {...props}>
      <FormLabel
        label={label}
        name={input.name}
        fontSize={14}
        {...labelProps}
      />
      <Box
        as="textarea"
        w="100%"
        borderRadius="8px"
        boxShadow="0 0 0 0 rgba(0, 0, 0, 0)"
        borderWidth="2px"
        borderColor={hasError ? 'red.solid' : '#fff'}
        rows={rows}
        p="16px"
        resize="none"
        _focus={{
          outline: 'none',
        }}
        placeholder={placeholder}
        _placeholder={{ color: 'dark.mid' }}
        _disabled={{ bg: '#fff' }}
        {...inputProps}
        {...input}
      />
      {counter > 0 && (
        <Typography
          variant="text3"
          color="dark.solid"
          textAlign="right"
          position="absolute"
          right={0}
          bottom="-20px"
        >{`${input.value.length}/${counter}`}</Typography>
      )}
      <FormError isErrorAbsolute={counter > 0} meta={meta} />
    </Box>
  );
};

export default Textarea;
