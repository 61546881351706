import React from 'react';
import { Field, Form } from 'react-final-form';
import { INPUT_VARIANTS } from '@styles/theme';
import TextInput from '@components/forms/TextInput';
import { Flex } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import Icon from '@components/Icon';

export const LineItemInput = ({
  fieldName,
  handleSubmit,
  isMaskedInput,
  inputProps,
  dirty,
  showEditIcon = true,
  ...props
}) => (
  <Flex alignItems="center" w="100%" h="100%" {...props}>
    {showEditIcon && <Icon name="edit" color="dark.mid" fontSize={18} />}
    <Field
      name={fieldName}
      component={isMaskedInput ? MaskedTextInput : TextInput}
      variant={INPUT_VARIANTS.CONTAINER_FILLED}
      pt="0px"
      px="0px"
      pl="6px"
      {...inputProps}
    />
  </Flex>
);

const InvoiceLineItemForm = ({
  initialValues,
  isMaskedInput,
  inputProps,
  fieldName = 'description',
  onSubmit,
  ...rest
}) => {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, form, dirty }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <LineItemInput
            isMaskedInput={isMaskedInput}
            inputProps={{
              ...inputProps,
              onAfterBlur: v => {
                if (dirty && !isEmpty(v)) {
                  handleSubmit();
                } else {
                  form.restart();
                }
              },
            }}
            fieldName={fieldName}
            data-test-id={rest['data-test-id']}
          />
        </form>
      )}
    />
  );
};

export default InvoiceLineItemForm;
