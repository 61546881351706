import { API } from '@app/api';
import { Box, Stack } from '@chakra-ui/react';
import AsyncAutocomplete from '@components/forms/_common/AsyncAutocomplete';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import Button from '@styles/Button';
import { required } from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';

const AddToGroupForm = ({
  onClose,
  onSubmit,
  isClientLeadRoute,
  isTeamMemberRoute,
  isProjectManagerRoute,
  isTeamAdminRoute,
}) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting }) => {
      return (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Box>
            <Field
              name="user"
              label="Choose an user"
              placeholder="Choose an user"
              component={AsyncAutocomplete}
              styleProps={{ mb: '32px' }}
              getOptionsFn={API.searchUsers}
              customOptionMap={option => ({
                value: option,
                label: option.username,
              })}
              validate={required}
            />
            {isClientLeadRoute && (
              <Field
                name="client"
                label="Client"
                placeholder="Choose a client"
                component={AsyncAutocomplete}
                styleProps={{ mb: '32px' }}
                getOptionsFn={API.searchClients}
                isBordered
                validate={required}
              />
            )}
            {(isTeamMemberRoute || isTeamAdminRoute) && (
              <Field
                name="supplier"
                label="Delivery Partner"
                placeholder="Choose a Delivery Partner"
                component={AsyncAutocomplete}
                styleProps={{ mb: '32px' }}
                getOptionsFn={API.searchSuppliers}
                isBordered
                validate={required}
              />
            )}
            {isProjectManagerRoute && (
              <Field
                name="rate"
                label="Rate"
                component={MaskedTextInput}
                containerProps={{ mb: '32px' }}
                isBordered
                type="number"
                validate={required}
                allowNegative={false}
                placeholder="0"
              />
            )}
          </Box>
          <Stack mt="40px" spacing={3}>
            <Button isLoading={submitting} type="submit">
              Add User
            </Button>
            <Button variant="outline" isDisabled={submitting} onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </form>
      );
    }}
  />
);

export default AddToGroupForm;
