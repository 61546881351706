import { inject, observer } from 'mobx-react';
import React from 'react';
import { isEmpty } from 'lodash-es';
import { isFixedPrice } from '@utils/projectUtils';
import ReadOnlyTeamAllocations from '@pages/Project/AllocationsAndActuals/ReadOnlyTeamAllocations';
import ReadOnlyDeazyAllocations from '@pages/Project/AllocationsAndActuals/ReadOnlyDeazyAllocations';
import AllocationHead from './AllocationHead';
import AllocationRow from './AllocationRow';
import FormSpyFieldValues from '../forms/FormSpyFieldValues';
import ATable from './AllocationTable';

const AllocationTable = ({
  initialAllocations,
  projectsStore: { allocationRolesOptions, fetchAllocationRoles, project },
  disabledFields,
  usersStore: { isTeamAdmin, isTeamMember },
  allocationsStore: { allocationBorderDates },
  disallowRowChange = false,
  isManualAddingRow,
  currentPts,
  isEditing,
  projectToSupplier = {},
  ...props
}) => {
  React.useEffect(() => {
    fetchAllocationRoles();
  }, []);

  const borderDateForAllocation = allocationBorderDates[project.id];

  return (
    <>
      {isEditing || isEmpty(projectToSupplier) ? (
        <>
          <FormSpyFieldValues fieldNames={['engagementType', 'projectType']}>
            {p => (
              <AllocationHead
                isTeamAdmin={isTeamAdmin}
                isTeamMember={isTeamMember}
                showAllocationDates={isFixedPrice(p)}
              />
            )}
          </FormSpyFieldValues>
          <ATable
            AllocationRow={AllocationRow}
            initialAllocations={
              initialAllocations || project.allocationsToClone
            }
            allocationRolesOptions={allocationRolesOptions}
            disabledFields={disabledFields}
            disallowRowChange={disallowRowChange}
            isTeamMember={isTeamMember}
            isManualAddingRow={isManualAddingRow}
            isEditing={isEditing}
            isFixedPrice={isFixedPrice(project)}
            borderDateForAllocation={borderDateForAllocation}
            {...props}
          />
        </>
      ) : (
        <>
          {currentPts ? (
            <ReadOnlyTeamAllocations
              allocations={currentPts.allocations}
              projectId={project.id}
            />
          ) : (
            <ReadOnlyDeazyAllocations projectId={project.id} />
          )}
        </>
      )}
    </>
  );
};

export default inject(
  'projectsStore',
  'usersStore',
  'allocationsStore',
)(observer(AllocationTable));
