import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const EditProposalConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2" mb="20px" textAlign="center">
        Are you sure?
      </Typography>
      <Typography textAlign="center" mb="16px">
        This is proposal is currently with Deazy
      </Typography>
      <Typography textAlign="center">
        Are you sure you’d like to edit?
      </Typography>
      <Stack spacing={3} mt="40px">
        <Button onClick={onConfirm}>Yes, continue to make updates</Button>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};

export default EditProposalConfirmationModal;
