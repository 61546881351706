import {
  filter,
  forEach,
  isArray,
  isEmpty,
  isObject,
  isUndefined,
  map,
  omit,
  reject,
  has,
  every,
  round,
  set,
  isNumber,
  flatten,
  includes,
  first,
  compact,
  orderBy,
} from 'lodash-es';
import { action, computed, makeObservable, observable } from 'mobx';
import { formatCurrency, formatNumber } from '@utils';
import { getWeekStartAndEnd } from '@utils/datesUtils';
import { FORM_ERROR } from 'final-form';
import moment from 'moment';
import { isFixedPrice, isRetainerOrTAForFixedPrice } from '@utils/projectUtils';
import { PROJECT_TYPES } from '@app/constants';

export default class AllocationsStore {
  constructor({ API, toastsStore, projectsStore, usersStore }) {
    makeObservable(this);
    this.API = API;
    this.toastsStore = toastsStore;
    this.projectsStore = projectsStore;
    this.usersStore = usersStore;
  }

  @observable allocations = {
    all: {
      data: {},
      isLoading: false,
    },
    current: {},
    pending: {},
  };

  @observable actuals = {
    status: {},
    last: {},
    all: {},
    pending: {},
  };

  @observable deazyAllocations = {
    all: {},
    current: {},
    pending: {},
  };

  @observable deazyActuals = {
    status: {},
    last: {},
    all: {},
    pending: {},
  };

  @observable allocationBorderDates = {};

  @computed get canAddNewDeliveryPartner() {
    const { project } = this.projectsStore;
    const { isAdminOrDL } = this.usersStore;
    const allDeliveryPartnerSOWsShouldBeFinalised = every(
      project.projectToSuppliers,
      ptos => ptos.finalisedDateSupplierSOW,
    );

    const clientSowFinalised =
      project.deazyAsClient || !!project.finalisedDateClientSOW;

    const supplierSowFinalised =
      project.deazyAsSupplier || allDeliveryPartnerSOWsShouldBeFinalised;

    if (
      isRetainerOrTAForFixedPrice(project) &&
      isAdminOrDL &&
      clientSowFinalised &&
      supplierSowFinalised
    ) {
      return true;
    }
    return false;
  }

  @computed get canEditAllocations() {
    const { project } = this.projectsStore;
    const { isAdminOrDL } = this.usersStore;

    if (project.projectType === PROJECT_TYPES.T_AND_M && isAdminOrDL) {
      return true;
    }
    if (this.canAddNewDeliveryPartner) {
      return true;
    }
    return false;
  }

  @action fetchAllocationBorderDate = async (projectId, failureCb) => {
    this.allocationBorderDates[projectId] = null;
    try {
      const {
        data: { date: borderDate },
      } = await this.API.getBorderDateForAllocation(projectId);
      this.allocationBorderDates[projectId] =
        moment
          .utc(borderDate)
          .add({ days: 1 })
          .format() || this.projectsStore.project.startDate;
    } catch (e) {
      this.toastsStore.showError({
        title:
          'Allocation date range could not be retrieved. Please try again later.',
      });
      if (failureCb) {
        failureCb();
      }
    }
  };

  @action clearAllAllocations = () => {
    this.allocations.all.data = {};
    this.allocations.current.data = {};
    this.deazyAllocations.all.data = {};
  };

  @action _fetch = async (
    params,
    observablePath,
    apiFnKey,
    fallbackErrorMessage = 'Network error. Please try again later.',
  ) => {
    const isActuals = includes(observablePath, 'actuals');
    try {
      set(this, `${observablePath}.isLoading`, true);
      if (has(params, 'pToS') && isArray(params.pToS)) {
        const responses = await Promise.all(
          map(params.pToS, async pts => {
            const { data } = await this.API[apiFnKey]({
              projectId: params.projectId,
              supplierId: pts?.supplier?.id,
            });
            return {
              ...pts,
              [isActuals ? 'actuals' : 'allocations']: this.convertToArray(
                data,
              ),
            };
          }),
        );
        set(this, `${observablePath}.data`, flatten(responses));
      } else {
        const { data } = await this.API[apiFnKey](params);
        set(this, `${observablePath}.data`, data);
      }
    } catch (e) {
      this.toastsStore.showError({ title: fallbackErrorMessage });
    } finally {
      set(this, `${observablePath}.isLoading`, false);
    }
  };

  @action fetchAllActuals = async params =>
    this._fetch(
      params,
      'actuals.all',
      'getAllActuals',
      'Fetching all team actuals failed. Please try again later.',
    );

  @action fetchAllDeazyActuals = async projectId =>
    this._fetch(
      projectId,
      'deazyActuals.all',
      'getAllDeazyActuals',
      'Fetching all deazy actuals failed. Please try again later.',
    );

  @action fetchAllAllocations = async params =>
    this._fetch(
      params,
      'allocations.all',
      'getAllAllocations',
      'Fetching all team allocations failed. Please try again later.',
    );

  @action fetchAllDeazyAllocations = async params =>
    this._fetch(
      params,
      'deazyAllocations.all',
      'getAllDeazyAllocations',
      'Fetching all deazy allocations failed. Please try again later.',
    );

  @action fetchPendingAllocations = async params =>
    this._fetch(
      params,
      'allocations.pending',
      'getPendingAllocations',
      'Fetching pending team allocations failed. Please try again later.',
    );

  @action fetchCurrentAllocations = async params =>
    this._fetch(
      params,
      'allocations.current',
      'getCurrentAllocations',
      'Fetching current team allocations failed. Please try again later.',
    );

  @action fetchCurrentDeazyAllocations = async params =>
    this._fetch(
      params,
      'deazyAllocations.current',
      'getCurrentDeazyAllocations',
      'Fetching current deazy allocations failed. Please try again later.',
    );

  @action fetchLastActuals = async params =>
    this._fetch(
      params,
      'actuals.last',
      'getLastActuals',
      'Fetching last team actuals failed. Please try again later.',
    );

  @action fetchLastDeazyActuals = async params =>
    this._fetch(
      params,
      'deazyActuals.last',
      'getLastDeazyActuals',
      'Fetching last deazy actuals failed. Please try again later.',
    );

  @action fetchPendingActuals = async params =>
    this._fetch(
      params,
      'actuals.pending',
      'getPendingActuals',
      'Fetching pending team actuals failed. Please try again later.',
    );

  @action fetchActualsStatus = async params =>
    this._fetch(
      params,
      'actuals.status',
      'getActualsStatus',
      'Fetching team actuals status failed. Please try again later.',
    );

  @action fetchDeazyActualsStatus = async params =>
    this._fetch(
      params,
      'deazyActuals.status',
      'getDeazyActualsStatus',
      'Fetching deazy actuals status failed. Please try again later.',
    );

  @action approveActuals = async (projectId, supplierId, actualId) => {
    try {
      this.actuals.pending.data = map(this.actuals.pending.data, pts => ({
        ...pts,
        actuals: map(pts.actuals, actual => ({
          ...actual,
          isApproving: actualId === actual.id ? true : actual.isRejecting,
        })),
      }));
      await this.API.approveActuals(projectId, supplierId, actualId);
      this.actuals.pending.data = map(this.actuals.pending.data, pts => ({
        ...pts,
        actuals: reject(
          map(pts.actuals, a => ({ ...a })),
          {
            id: actualId,
          },
        ),
      }));

      this.fetchActualsStatus({ projectId, pToS: this.actuals.pending.data });
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message || 'Could not approve actuals. Please try again later.',
      });
    } finally {
      this.actuals.pending.data = map(this.actuals.pending.data, pts => ({
        ...pts,
        actuals: map(pts.actuals, actual => ({
          ...actual,
          isApproving: actualId === actual.id ? false : actual.isRejecting,
        })),
      }));
    }
  };

  @action approveAllocations = async (projectId, supplierId, allocationId) => {
    try {
      this.allocations.pending.data = map(
        this.allocations.pending.data,
        pts => ({
          ...pts,
          isApproving:
            pts?.supplier?.id === supplierId ? true : pts.isRejecting,
        }),
      );

      await this.API.approveAllocations(projectId, supplierId, allocationId);
      await this.fetchPendingAllocations({
        projectId,
        pToS: this.allocations.current.data,
      });
      await this.fetchCurrentAllocations({
        projectId,
        pToS: this.allocations.current.data,
      });
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message || 'Could not approve allocations. Please try again later.',
      });
    } finally {
      this.allocations.pending.data = map(
        this.allocations.pending.data,
        pts => ({
          ...pts,
          isApproving:
            pts?.supplier?.id === supplierId ? false : pts.isRejecting,
        }),
      );
    }
  };

  @action rejectAllocations = async (projectId, supplierId, allocationId) => {
    try {
      this.allocations.pending.data = map(
        this.allocations.pending.data,
        pts => ({
          ...pts,
          isRejecting:
            pts?.supplier?.id === supplierId ? true : pts.isRejecting,
        }),
      );
      await this.API.rejectAllocations(projectId, supplierId, allocationId);
      await this.fetchPendingAllocations({
        projectId,
        pToS: this.allocations.current.data,
      });
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message || 'Could not reject allocations. Please try again later.',
      });
    } finally {
      this.allocations.pending.data = map(
        this.allocations.pending.data,
        pts => ({
          ...pts,
          isRejecting:
            pts?.supplier?.id === supplierId ? false : pts.isRejecting,
        }),
      );
    }
  };

  @action rejectActuals = async (projectId, supplierId, actualId) => {
    try {
      this.actuals.pending.data = map(this.actuals.pending.data, pts => ({
        ...pts,
        actuals: map(pts.actuals, actual => ({
          ...actual,
          isRejecting: actualId === actual.id ? true : actual.isRejecting,
        })),
      }));
      await this.API.rejectActuals(projectId, supplierId, actualId);
      this.actuals.pending.data = map(this.actuals.pending.data, pts => ({
        ...pts,
        actuals: reject(
          /* 
          this map is necessary for app not to crash
           */
          map(pts.actuals, a => ({ ...a })),
          {
            id: actualId,
          },
        ),
      }));
      this.fetchActualsStatus({ projectId, pToS: this.actuals.pending.data });
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Could not reject actuals. Please try again later.',
      });
    } finally {
      this.actuals.pending.data = map(this.actuals.pending.data, pts => ({
        ...pts,
        actuals: map(pts.actuals, actual => ({
          ...actual,
          isRejecting: actualId === actual.id ? false : actual.isRejecting,
        })),
      }));
    }
  };

  prepareActuals = entries =>
    map(entries, ({ id, amount }) => ({ id, amount }));

  prepareEntries = (entries, isDeazyAllocation = false, isTeamAdmin) =>
    map(
      filter(entries, ({ id, ...rest }) => !isEmpty(rest)),
      ({ id, ...entry }) => {
        const idToSend = isNumber(id) ? id : undefined;
        return isDeazyAllocation
          ? { id: idToSend, ...entry }
          : this.projectsStore.decorateTeamEntry(
              { id: idToSend, ...entry },
              isTeamAdmin,
            );
      },
    );

  // eslint-disable-next-line no-nested-ternary
  convertToArray = obj => (isArray(obj) ? obj : isObject(obj) ? [obj] : obj);

  @action updateAllocations = async (
    {
      projectId,
      supplierId,
      applicableFrom,
      allocations,
      deazyAllocations,
      updateStartsFrom,
    },
    isDeazy,
    successCb,
  ) => {
    const { isTeamAdmin } = this.usersStore;
    const { project } = this.projectsStore;
    try {
      this.validateAllocations(
        isDeazy ? deazyAllocations : allocations,
        isDeazy,
      );

      const endpoint = () => {
        if (isDeazy && !isRetainerOrTAForFixedPrice(project)) {
          return 'updateDeazyAllocations';
        }
        if (isDeazy && isRetainerOrTAForFixedPrice(project)) {
          return 'updateFixedPriceForTAandRetainerDeazyAllocations';
        }
        if (isFixedPrice(this.projectsStore.project)) {
          return 'updateFixedPriceAllocations';
        }
        return 'updateAllocations';
      };
      const entries = this.prepareEntries(
        allocations || deazyAllocations,
        isDeazy,
        isTeamAdmin,
      );
      let { data } = await this.API[endpoint()](
        { projectId, supplierId },
        {
          updateStartsFrom,
          applicableFrom,
          entries,
        },
      );

      const messageForTAorRTforFixedPrice = data.message;

      data = this.convertToArray(
        isRetainerOrTAForFixedPrice(this.projectsStore.project) && !isDeazy
          ? data.savedAllocations
          : data,
      );

      const replaceAllocationsMap = allocs =>
        map(allocs, alloc => ({
          ...alloc,
          ...(alloc?.supplier?.id === supplierId && { allocations: data }),
        }));

      if (isDeazy) {
        await this.fetchDeazyActualsStatus(projectId);
        this.deazyAllocations.current.data = omit(data, ['project']);
      } else if (!this.usersStore.isAdminOrDL) {
        this.allocations.pending.data = replaceAllocationsMap(
          this.allocations.pending.data,
        );
        const currentData = [...(this.allocations.current.data || [])];
        this.allocations.current.data = currentData;
      } else {
        this.allocations.current.data = replaceAllocationsMap(
          this.allocations.current.data,
        );
      }
      if (messageForTAorRTforFixedPrice) {
        this.toastsStore.showInfo({ title: messageForTAorRTforFixedPrice });
      } else {
        this.toastsStore.showSuccess({
          title: isRetainerOrTAForFixedPrice(project)
            ? 'Allocation change was successful. Invoices have been updated accordingly.'
            : 'Allocations updated successfully!',
        });
      }
      if (isFixedPrice(project)) {
        const allEntries = [
          ...this.deazyAllocations?.current.data[0]?.entries,
          ...flatten(
            map(
              this.currentAllocations,
              ({ allocations: allocs }) => allocs[0]?.entries,
            ),
          ),
          ...entries,
        ];
        const latestEndDate = first(orderBy(allEntries, 'endDate', 'desc'))
          ?.endDate;
        this.projectsStore.project.endDate = latestEndDate;
      }
      if (successCb) {
        successCb();
      }
    } catch (e) {
      if (isObject(e) && has(e, FORM_ERROR)) {
        return e;
      }
      this.toastsStore.showError({
        title:
          e.message || 'Changing allocations failed. Please, try again later.',
      });
    }
    return undefined;
  };

  @action createActuals = async (
    { projectId, supplierId, allocations, deazyAllocations, year, week, month },
    successCb,
    isDeazy = false,
  ) => {
    try {
      this.validateAllocations(
        isDeazy ? deazyAllocations : allocations,
        isDeazy,
      );
      const { data } = await this.API[
        isDeazy ? 'createDeazyActuals' : 'createActuals'
      ](
        { projectId, supplierId },
        {
          entries: this.prepareActuals(allocations || deazyAllocations),
          year,
          week,
          month,
        },
      );

      if (isDeazy) {
        await this.fetchLastDeazyActuals(projectId);
        this.fetchDeazyActualsStatus(projectId);
      } else {
        this.actuals.pending.data = map(this.actuals.pending.data, pts => ({
          ...pts,
          actuals: compact([
            pts?.supplier?.id === supplierId && {
              ...data,
              canApprove: this.usersStore.isAdminOrDL,
            },
            ...map(pts.actuals, a => ({ ...a })),
          ]),
        }));

        await this.fetchActualsStatus({
          projectId,
          pToS: this.actuals.pending.data,
        });
      }
      this.toastsStore.showSuccess({
        title: 'Actuals has been sent successfully!',
      });
      if (successCb) {
        successCb();
      }
    } catch (e) {
      if (isObject(e) && has(e, FORM_ERROR)) {
        return e;
      }
      this.toastsStore.showError({
        title: e.message || 'Creating actuals failed. Please, try again later.',
      });
    }
    return undefined;
  };

  @action decorateAllocationForTable = (
    projectToSupplier = {},
    formatTableEntryFn,
  ) => {
    const {
      allocations,
      overriddenCurrency: supplierCurrency,
      supplierCurrencyRate,
    } = projectToSupplier;
    if (!isArray(allocations) && isEmpty(allocations?.entries)) {
      return [];
    }
    const { currency, startDate, endDate } = this.projectsStore.project;

    return map(
      isArray(allocations) ? allocations : reject([allocations], isUndefined),
      allocation => {
        const { year, month, week } = allocation;
        if (!!month && !!year && !!week) {
          const weekInfo = getWeekStartAndEnd(
            year,
            month,
            week,
            startDate,
            endDate,
          );
          allocation.applicableFrom = weekInfo.startDate;
          allocation.applicableUntil = weekInfo.endDate;
        }

        return {
          ...projectToSupplier,
          ...allocation,
          formPreparedEntries: this.projectsStore.prepareAllocation(
            allocation.entries,
            1,
            projectToSupplier.supplierCurrencyRate || 1,
          ),
          tableData: map(allocation.entries, entry =>
            formatTableEntryFn({
              entry,
              currency,
              supplierCurrency,
              supplierCurrencyRate,
            }),
          ),
        };
      },
    );
  };

  @action decorateDeazyAllocationForTable = (
    allocations,
    formatTableEntryFn,
  ) => {
    if (!isArray(allocations) && isEmpty(allocations?.entries)) {
      return [];
    }
    const { currency, startDate, endDate } = this.projectsStore.project;

    return map(
      isArray(allocations) ? allocations : reject([allocations], isUndefined),
      allocation => {
        const { year, month, week } = allocation;
        if (!!month && !!year && !!week) {
          const weekInfo = getWeekStartAndEnd(
            year,
            month,
            week,
            startDate,
            endDate,
          );
          allocation.applicableFrom = weekInfo.startDate;
          allocation.applicableUntil = weekInfo.endDate;
        }

        return {
          ...allocation,
          tableData: map(allocation.entries, entry =>
            formatTableEntryFn({
              entry,
              currency,
            }),
          ),
        };
      },
    );
  };

  calcAutoRates = (entry, clientRateFactor) => {
    let { clientRate, rate } = entry;
    if (!clientRate) {
      clientRate = rate * clientRateFactor;
      clientRate = entry.clientRate === 0 ? '0.00' : round(clientRate, 2);
    }
    if (!rate) {
      rate = clientRate / clientRateFactor;
      rate = entry.rate === 0 ? '0.00' : round(rate, 2);
    }
    return { clientRate, rate };
  };

  formatTableEntry = ({
    entry,
    supplierCurrencyRate,
    supplierCurrency,
    currency,
  }) => {
    const { isTeamAdmin, isClient, isTeamMember } = this.usersStore;

    const { clientRate, rate } = this.calcAutoRates(
      entry,
      supplierCurrencyRate,
    );

    let base = [entry.name, entry.resourceType, formatNumber(entry.amount)];

    if (isFixedPrice(this.projectsStore.project)) {
      base = [
        ...base,
        entry.startDate
          ? moment.utc(entry.startDate).format('DD MMM, YYYY')
          : '-',
        entry.endDate ? moment.utc(entry.endDate).format('DD MMM, YYYY') : '-',
      ];
    }

    if (isTeamMember) {
      return base;
    }
    return [
      ...base,
      ...(!isClient
        ? [
            formatCurrency(rate, supplierCurrency),
            formatCurrency(rate * entry.amount, supplierCurrency),
          ]
        : []),
      ...(!isTeamAdmin
        ? [
            formatCurrency(clientRate, currency),
            formatCurrency(clientRate * entry.amount, currency),
          ]
        : []),
    ];
  };

  formatTableDeazyEntry = ({ entry, currency }) => {
    const tableData = [
      entry?.resourceName,
      entry.startDate
        ? moment.utc(entry.startDate).format('DD MMM, YYYY')
        : '-',
      entry.endDate ? moment.utc(entry.endDate).format('DD MMM, YYYY') : '-',
      formatNumber(entry.amount),
      formatCurrency(entry.rate, currency),
      formatCurrency(entry.rate * entry.amount, currency),
    ];
    if (!isFixedPrice(this.projectsStore.project)) {
      tableData.splice(1, 2);
    }
    return tableData;
  };

  @computed get allActuals() {
    return map(
      this.actuals.all.data,
      ({ projectToSupplier, ...allocation }) => ({
        ...projectToSupplier,
        ...allocation,
        ...first(
          this.decorateAllocationForTable(
            { ...projectToSupplier, allocations: allocation },
            this.formatTableEntry,
          ),
        ),
      }),
    );
  }

  @computed get allDeazyActuals() {
    return this.decorateDeazyAllocationForTable(
      this.deazyActuals.all.data,
      this.formatTableDeazyEntry,
    );
  }

  @computed get pendingActuals() {
    return map(this.actuals.pending.data, projectToSupplier => ({
      ...projectToSupplier,
      actuals: map(projectToSupplier.actuals, actual => ({
        ...actual,
        ...first(
          this.decorateAllocationForTable(
            { ...actual.projectToSupplier, ...actual, allocations: actual },
            this.formatTableEntry,
          ),
        ),
      })),
    }));
  }

  @computed get pendingAllocations() {
    return map(this.allocations.pending.data, projectToSupplier => ({
      ...projectToSupplier,
      allocations: this.decorateAllocationForTable(
        projectToSupplier,
        this.formatTableEntry,
      ),
    }));
  }

  @computed get currentAllocations() {
    return map(this.allocations.current.data, projectToSupplier => ({
      ...projectToSupplier,
      allocations: this.decorateAllocationForTable(
        projectToSupplier,
        this.formatTableEntry,
      ),
    }));
  }

  @computed get currentDeazyAllocations() {
    return this.decorateDeazyAllocationForTable(
      this.deazyAllocations.current.data,
      this.formatTableDeazyEntry,
    );
  }

  @computed get lastDeazyActuals() {
    return this.decorateDeazyAllocationForTable(
      this.deazyActuals.last.data,
      this.formatTableDeazyEntry,
    );
  }

  @computed get allAllocations() {
    return map(
      this.allocations.all.data,
      ({ projectToSupplier, ...allocation }) => ({
        ...projectToSupplier,
        ...allocation,
        ...first(
          this.decorateAllocationForTable(
            { ...projectToSupplier, allocations: allocation },
            this.formatTableEntry,
          ),
        ),
      }),
    );
  }

  @computed get allDeazyAllocations() {
    return this.decorateDeazyAllocationForTable(
      this.deazyAllocations.all.data,
      this.formatTableDeazyEntry,
    );
  }

  validateAllocations = (allocations, isDeazy) => {
    let error;
    forEach(allocations, a => {
      const { id, startDate, endDate, ...rest } = a;
      if (!isEmpty(rest)) {
        if (!isDeazy) {
          const { amount, resourceType, name, rate, clientRate } = a;
          if (
            !resourceType ||
            !amount ||
            !rate ||
            !name ||
            !rate ||
            (!clientRate && this.usersStore.isAdminOrDL)
          ) {
            error = true;
          }
        } else {
          const { resourceName, amount, rate } = a;
          if (isEmpty(resourceName) || !amount || !rate) {
            error = true;
          }
        }
      }
    });
    if (error) {
      // eslint-disable-next-line no-throw-literal
      throw {
        [FORM_ERROR]:
          'Some rows are partially finished. Please fulfill them or remove.',
      };
    }
  };
}
