import { Box, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import qs from 'query-string';
import RadioGroupField from '@components/forms/RadioGroupField';
import Icon from '@components/Icon';
import PageFilters from '@components/PageFilters';
import useMediaQuery from '@hooks/useMediaQuery';
import { APP_ROUTES } from '@routes';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React, { useLayoutEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import AutoSave from '../pages/Brief/Proposal/AutoSave';

const labelProps = {
  color: 'dark.solid',
  fontSize: 12,
  mb: '14px',
  fontWeight: 500,
  fontFamily: 'WM',
  textTransform: 'uppercase',
};

const inputLabelStyle = {
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '18px',
  color: 'black',
};

const filterWrapperStyles = isSmallerScreen => ({
  position: 'fixed',
  top: 0,
  left: '92px',
  bg: '#fff',
  height: '100%',
  borderRight: '1px solid',
  borderColor: 'dark.mid',
  ...(isSmallerScreen
    ? {
        width: '100%',
        left: 0,
        pt: '8px',
        pb: '14px',
        zIndex: 300,
      }
    : { width: '284px', left: '92px', pt: '82px', zIndex: 10 }),
});

const FiltersFormSidebar = ({
  onSubmit,
  sortOptions = [],
  statusOptions = [],
  loading = false,
  fieldsToShow,
  initialValues = {},
  showStatusFilters = true,
  mainNameQA,
  filterButtonLabel = 'Sort & filter',
  filterButtonProps,
}) => {
  const history = useHistory();
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleResetFilters = () => {
    const { sort, ppBriefId, briefId } = initialValues;
    if (
      history.location.pathname === APP_ROUTES.projects &&
      (ppBriefId || briefId)
    ) {
      return history.push(
        `${history.location.pathname}?${sort}&status=active&${qs.stringify({
          ppBriefId,
          briefId,
        })}`,
      );
    }
    return history.push(`${history.location.pathname}?${sort}`);
  };

  useLayoutEffect(() => {
    if (!isSmallerScreen) {
      onOpen();
    } else {
      onClose();
    }
  }, [isSmallerScreen]);

  useLayoutEffect(() => {
    if (isSmallerScreen && isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isSmallerScreen, isOpen]);

  return (
    <>
      {isSmallerScreen && (
        <Button
          position="fixed"
          right="12px"
          bottom="104px"
          size="lg"
          zIndex={100}
          leftIcon={{ name: 'filterNew', fontSize: '18px' }}
          onClick={onOpen}
          {...filterButtonProps}
        >
          {filterButtonLabel}
        </Button>
      )}
      {isOpen && (
        <Box width="284px">
          <Box {...filterWrapperStyles(isSmallerScreen)}>
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              render={({ handleSubmit, form }) => {
                return (
                  <Flex
                    direction="column"
                    justify="space-between"
                    as="form"
                    height="100%"
                    overflowY="auto"
                    p="16px"
                    css={{
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: '#d9dada',
                      },
                    }}
                    onSubmit={values => {
                      handleSubmit(values);

                      if (isOpen) {
                        onClose();
                      }
                    }}
                    autoComplete="off"
                  >
                    {!isSmallerScreen && (
                      <AutoSave formApi={form} onSave={onSubmit} />
                    )}
                    <Box>
                      {isSmallerScreen && (
                        <Flex justify="space-between" align="center" mb="22px">
                          <Typography fontSize="18px" variant="h4">
                            Sort & filter
                          </Typography>
                          <Button
                            iconButton={{
                              name: 'close',
                              width: '24px',
                              height: '24px',
                              color: 'black',
                            }}
                            onClick={onClose}
                            variant="ghost"
                            position="absolute"
                            zIndex={1000}
                            right={0}
                          />
                        </Flex>
                      )}
                      {sortOptions?.length > 0 && (
                        <>
                          <Flex alignItems="baseline" mb="25px">
                            <Flex alignItems="center">
                              <Icon
                                name="sortAndSelect"
                                mr="11px"
                                fontSize="18px"
                              />
                              <Typography marginTop="1px" variant="h5">
                                Sort by
                              </Typography>
                            </Flex>
                          </Flex>
                          <Box
                            mb="18px"
                            borderBottom={isSmallerScreen ? 0 : '1px solid'}
                            borderColor="dark.mid"
                          >
                            <Field
                              name="sort"
                              isVertical
                              component={RadioGroupField}
                              containerProps={{ minW: 0 }}
                              choices={sortOptions}
                              inputLabelStyle={inputLabelStyle}
                            />
                          </Box>
                        </>
                      )}
                      <Flex alignItems="baseline" mb="25px">
                        <Flex alignItems="center">
                          <Icon name="filterNew" mr="11px" fontSize="18px" />
                          <Typography marginTop="1px" variant="h5">
                            Filter by
                          </Typography>
                        </Flex>
                        {!isSmallerScreen && (
                          <Button
                            variant="ghost"
                            color="blue.solid"
                            fontSize="16px"
                            height="auto"
                            fontWeight="400"
                            ml="auto"
                            pr="8px"
                            onClick={handleResetFilters}
                          >
                            Reset filters
                          </Button>
                        )}
                      </Flex>

                      <Box mb="18px">
                        {showStatusFilters && (
                          <Field
                            name="status"
                            label="Status"
                            labelProps={labelProps}
                            isVertical
                            component={RadioGroupField}
                            containerProps={{ minW: 0 }}
                            choices={statusOptions}
                            inputLabelStyle={inputLabelStyle}
                          />
                        )}
                      </Box>
                      <PageFilters
                        fieldsToShow={fieldsToShow}
                        ml="16px"
                        isLoadingList={loading}
                        formValues={initialValues}
                        mainNameQA={mainNameQA}
                      />
                    </Box>
                    {isSmallerScreen && (
                      <Stack spacing={3} mt="24px">
                        <Button type="submit" width="full">
                          Apply
                        </Button>
                        <Button
                          width="full"
                          variant="outline"
                          onClick={() => {
                            handleResetFilters();
                            if (isOpen) {
                              onClose();
                            }
                          }}
                        >
                          Reset filters
                        </Button>
                      </Stack>
                    )}
                  </Flex>
                );
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default FiltersFormSidebar;
