import React, { useState } from 'react';
import moment from 'moment';
import { find, get, replace, isEmpty } from 'lodash-es';
import { Flex, useDisclosure } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Button from '@styles/Button';
import { APP_ROUTES } from '@routes';
import EditInvoiceIntegrationModal from './EditInvoiceIntegrationModal';
import CustomTable from './CustomTable';

const CustomCell = ({ children, ...props }) => (
  <Flex
    borderRight="1px solid"
    borderColor="dark.mid"
    px="10px"
    minH="72px"
    alignItems="center"
    overflow="hidden"
    textOverflow="ellipsis"
    {...props}
  >
    {children}
  </Flex>
);

const invoiceNumberingPatternUtil = (date, object, path, ordinal) => {
  return moment
    .utc(date)
    .format(replace(get(object, path, ''), '#NO#', `[${ordinal}]`));
};

const InvoicesListTable = ({
  tableData,
  isTeamAdminSideUser,
  isTeamAdmin,
  isAdminOrDL,
}) => {
  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const selectedInvoice = find(
    tableData,
    invoice => invoice.id === selectedInvoiceId,
  );
  const columns = React.useMemo(
    () => [
      {
        Header: 'Project Name',
        accessor: 'projectName',
        width: '20%',
        getCellProps: {
          id: 1,
        },
        Cell: ({
          row: {
            original: { contactType, projectPayment, project },
          },
        }) => (
          <CustomCell width="20%">
            {contactType === 'Supplier'
              ? projectPayment.project.name
              : project.name}
          </CustomCell>
        ),
      },
      {
        Header: 'Invoice breadcrumb',
        accessor: 'invoiceBreadcrumb',
        width: '20%',
        getCellProps: {
          id: 2,
        },
        Cell: ({
          row: {
            original: { contactType, reference, breadcrumb },
          },
        }) => (
          <CustomCell width="20%">
            {contactType === 'Supplier' ? reference : breadcrumb || '-'}
          </CustomCell>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '10%',
        getCellProps: {
          id: 3,
        },
        Cell: ({
          row: {
            original: { status },
          },
        }) => <CustomCell width="10%">{status}</CustomCell>,
      },
      {
        Header: 'Date',
        accessor: 'invoiceDate',
        width: '10%',
        getCellProps: {
          id: 4,
        },
        Cell: ({
          row: {
            original: { invoiceDate },
          },
        }) => (
          <CustomCell width="10%">
            {moment.utc(invoiceDate).format('DD MMM YYYY') || '-'}
          </CustomCell>
        ),
      },
      {
        Header: 'Monthly Ordinal',
        accessor: 'monthOriginal',
        width: '10%',
        getCellProps: {
          id: 5,
        },
        Cell: ({
          row: {
            original: { monthOrdinal, periodOrdinal },
          },
        }) => (
          <CustomCell width="10%">
            {monthOrdinal || periodOrdinal || '-'}
          </CustomCell>
        ),
      },
      {
        Header: 'Delivery Partner Acc No',
        accessor: 'accNo',
        width: '15%',
        getCellProps: {
          id: 6,
        },
        Cell: ({ row: { original } }) => {
          const {
            monthOrdinal,
            periodOrdinal,
            invoiceDate,
            supplierAccountingNo,
            projectPayment,
            project,
          } = original;
          const isNewPayment = !isEmpty(projectPayment?.project);
          const {
            invoiceNumberingPattern: invoiceNumberingPatternForOldPayment,
          } = project?.supplier || {};
          const {
            invoiceNumberingPattern: invoiceNumberingPatternForNewPayment,
          } = projectPayment?.project?.supplier || {};
          const accNumberOldPaymentPre =
            invoiceNumberingPatternForOldPayment && monthOrdinal
              ? invoiceNumberingPatternUtil(
                  invoiceDate,
                  original,
                  'project.supplier.invoiceNumberingPattern',
                  monthOrdinal,
                )
              : '-';

          const accNumberOldPayment =
            supplierAccountingNo || accNumberOldPaymentPre;
          const accNumberNewPaymentPre =
            invoiceNumberingPatternForNewPayment && periodOrdinal
              ? invoiceNumberingPatternUtil(
                  invoiceDate,
                  original,
                  'projectPayment.project.supplier.invoiceNumberingPattern',
                  periodOrdinal,
                )
              : '-';
          const accNumberNewPayment =
            supplierAccountingNo || accNumberNewPaymentPre;
          return (
            <CustomCell width="15%">
              {isNewPayment ? accNumberNewPayment : accNumberOldPayment}
            </CustomCell>
          );
        },
      },
      {
        Header: '',
        accessor: 'id',
        width: '20%',
        getCellProps: {
          id: 7,
        },
        Cell: ({
          row: {
            original: { id, projectPayment, project },
          },
        }) => {
          const { slug: clientSlug } =
            projectPayment?.project.client || project?.client || {};
          const projectSlug = projectPayment?.project?.slug || project?.slug;
          const linkToNewInvoice = APP_ROUTES.invoiceById(
            clientSlug || 'deazy',
            projectSlug,
            id,
          );
          const linktoOldInvoice = APP_ROUTES.supplierOldInvoiceById(
            clientSlug || 'deazy',
            projectSlug,
            id,
          );
          const isNewPayment = !isEmpty(projectPayment?.project);
          return (
            <CustomCell width="20%" justifyContent="center" borderRight="none">
              {(isAdminOrDL || isTeamAdmin) && (
                <Button
                  leftIcon={{ m: 0, name: 'edit', fontSize: 14 }}
                  mr="8px"
                  onClick={() => {
                    setSelectedInvoiceId(id);
                    onEditModalOpen();
                  }}
                >
                  Edit
                </Button>
              )}
              {/* is admin/pm or SA */}
              {(!isTeamAdminSideUser || isTeamAdmin) && (
                <Button
                  variant="outline"
                  as="a"
                  target="_blank"
                  rightIcon={
                    <Icon m={0} name="arrowLongTail" fontSize={12} ml="4px" />
                  }
                  mr="8px"
                  href={isNewPayment ? linkToNewInvoice : linktoOldInvoice}
                >
                  View
                </Button>
              )}
            </CustomCell>
          );
        },
      },
    ],
    [],
  );
  return (
    <>
      <CustomTable columns={columns} data={tableData} />
      {isEditModalOpen && (
        <EditInvoiceIntegrationModal
          isOpen={isEditModalOpen}
          onClose={onEditModalClose}
          selectedInvoice={selectedInvoice}
        />
      )}
    </>
  );
};
export default InvoicesListTable;
