import { FormLabel as ChakraLabel } from '@chakra-ui/react';
import React from 'react';

const FormLabel = ({ label, name, ...props }) => {
  if (label) {
    return (
      <ChakraLabel
        mb="8px"
        htmlFor={name}
        p="0px"
        fontFamily="WS"
        fontSize={13}
        color="gray.solid"
        {...props}
      >
        {label}
      </ChakraLabel>
    );
  }
  return null;
};

export default FormLabel;
