import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
} from '@chakra-ui/react';
import { map, orderBy } from 'lodash-es';
import Typography from '@styles/Typography';
import moment from 'moment';
import React from 'react';
import CandidateDiffTile from './CandidateDiffTile';

const CandidatesHistory = ({ isOpen, onClose, proposalChanges }) => {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} top="80px">
      <DrawerContent
        w="480px"
        maxW="480px"
        h="calc(100vh - 80px)"
        top="80px !important"
      >
        <DrawerCloseButton color="blue.solid" />
        <DrawerHeader mt="20px">
          <Typography variant="h4" mb="8px">
            Candidate history
          </Typography>
          <Typography mb="24px">
            Review the updates made to candidates by delivery partners.
          </Typography>
        </DrawerHeader>
        <DrawerBody>
          {map(orderBy(proposalChanges, ['changeDate'], ['desc']), diff => (
            <Flex
              pt="16px"
              borderTop="1px solid"
              borderColor="dark.mid"
              flexDir="column"
            >
              <Flex justify="space-between">
                <Typography variant="h5" mb="8px">
                  {diff.supplierName}
                </Typography>
                <Typography variant="text2" color="gray.mid">
                  {moment(diff.changeDate).format('DD MMM YYYY')}
                </Typography>
              </Flex>
              <Typography mb="8px">{diff.comment}</Typography>
              <Flex flexDir="column" mb="24px">
                {diff.changedCandidates.map(candidate => (
                  <CandidateDiffTile
                    supplierName={diff.supplierName}
                    {...candidate}
                  />
                ))}
              </Flex>
            </Flex>
          ))}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default CandidatesHistory;
