import { Spinner as ChakraSpinner } from '@chakra-ui/react';
import React from 'react';
import { has, isEmpty } from 'lodash-es';

const VARIANTS = {
  big: {
    height: '140px',
    width: '140px',
    my: '90px',
  },
  large: {
    height: '80px',
    width: '80px',
    my: '90px',
  },
  medium: {
    height: '50px',
    width: '50px',
  },
  small: {
    height: '36px',
    width: '36px',
  },
};

const Spinner = ({ variant, ...props }) => {
  const checkIfSize = () => {
    if (!isEmpty(props.size)) {
      return null;
    }
    return VARIANTS.big;
  };
  const styles = has(VARIANTS, variant) ? VARIANTS[variant] : checkIfSize();
  return (
    <ChakraSpinner
      variant={variant}
      {...styles}
      mx="auto"
      display="block"
      {...props}
    />
  );
};
export default Spinner;
