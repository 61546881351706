import React from 'react';
import { Button } from '@chakra-ui/react';
import Icon from '@components/Icon';

const buttonProps = {
  bg: 'blue.solid',
  color: '#fff',
  borderRadius: '32px',
  pl: '8px',
  pr: '32px',
  py: '15px',
  fontFamily: 'WS',
  fontWeight: '400',
  fontSize: '18px',
  height: '56px',
  ml: '32px',
  minW: 'auto',
};

const ReportButton = ({
  reportStatus,
  isTeamAdminSideUser,
  isAdminOrDL,
  submit,
  isSubmitting,
  isPrimaryDP,
  isLoadingMilestones,
  fetchedMilestones,
}) => {
  return (
    <>
      {reportStatus &&
        reportStatus === 'Pending' &&
        ((isTeamAdminSideUser && isPrimaryDP) || isAdminOrDL) && (
          <Button
            {...buttonProps}
            leftIcon={<Icon name="check" mr="12px" fontSize={22} />}
            onClick={() => submit(false)}
            isLoading={isSubmitting}
            minW="186px"
          >
            Submit report
          </Button>
        )}
      {isTeamAdminSideUser &&
        (reportStatus === 'Submitted' || reportStatus === 'Accepted') && (
          <Button
            {...buttonProps}
            leftIcon={
              <Icon
                name={reportStatus === 'Submitted' ? 'clock' : 'check'}
                mr="12px"
                fontSize={22}
              />
            }
            isDisabled
          >
            {reportStatus === 'Submitted' ? 'Awaiting approval' : 'Approved'}
          </Button>
        )}
      {isAdminOrDL && reportStatus !== 'Pending' && (
        <Button
          {...buttonProps}
          isLoading={isSubmitting || isLoadingMilestones || !fetchedMilestones}
          leftIcon={<Icon name="check" mr="12px" fontSize={22} />}
          isDisabled={reportStatus === 'Accepted'}
          onClick={() => submit(true)}
        >
          {reportStatus === 'Accepted' ? 'Approved' : 'Approve report'}
        </Button>
      )}
    </>
  );
};

export default ReportButton;
