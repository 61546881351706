import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { APP_ROUTES } from '@routes';
import { breakpoint } from '@styles/breakpoints';
import Spinner from '@styles/Spinner';
import { sleep } from '@utils';
import { get, includes, isEmpty, last, omit } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Redirect, Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { MAIN_NAMES_QA } from '@app/constants';
import BasicsStep from './BasicsStep';
import FinishStep from './FinishStep';
import TechStackStep from './TechStackStep';
import WizardHeader from './WizardHeader';

const Container = styled(Flex)`
  padding: 39px 64px 0;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  max-width: 1460px;
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 80px);
  @media ${breakpoint.lg} {
    padding: 16px;
    background: #fff;
  }
`;

export const Content = styled(Box)`
  background-color: #fff;
  padding: 40px 32px;
  width: 100%;
  margin-bottom: 160px;
  @media ${breakpoint.lg} {
    padding: 0;
  }
`;

const STEPS = [
  {
    id: 1,
    route: APP_ROUTES.briefsCreateBasics,
    editRoute: briefId => APP_ROUTES.briefsEditBasics(briefId),
    title: 'Basic info',
  },
  {
    id: 2,
    route: APP_ROUTES.briefsCreateTechStack,
    editRoute: briefId => APP_ROUTES.briefsEditTechStack(briefId),
    title: 'Tech requirements',
  },
  {
    id: 3,
    route: APP_ROUTES.briefsCreateFinish,
    editRoute: briefId => APP_ROUTES.briefsEditFinish(briefId),
    title: 'Project details',
  },
];

@inject('briefsStore', 'usersStore', 'authStore')
@observer
class ProjectCreate extends React.Component {
  componentDidMount() {
    const {
      briefsStore: { fetchBrief, assignBriefToDraft },
      isPlugAndPlay,
      match: {
        params: { id },
      },
    } = this.props;
    const waitForDraft = async () => {
      await fetchBrief(id, isPlugAndPlay);
      assignBriefToDraft();
    };
    if (id) {
      waitForDraft();
    }
    this.briefGuard();
  }

  componentDidUpdate() {
    this.briefGuard();
  }

  componentWillUnmount() {
    const {
      briefsStore: { clearDraft },
    } = this.props;
    clearDraft();
  }

  briefGuard = () => {
    const {
      location: { pathname },
      history: { push },
      isEditBriefBasic,
      briefsStore: { draft, brief },
      authStore: { setIsForbidden },
    } = this.props;

    if (brief.id && !brief.isEditable && isEditBriefBasic) {
      setIsForbidden(true);
    }

    if (
      pathname !== APP_ROUTES.briefsCreateBasics &&
      isEmpty(omit(draft, 'step')) &&
      !isEditBriefBasic
    ) {
      push(APP_ROUTES.briefsCreateBasics);
    }
  };

  render() {
    const {
      location,
      briefsStore,
      history,
      usersStore: { profile, isClient, isAdminOrDL },
    } = this.props;
    const { draft, saveBrief, flag, isLoading } = briefsStore;
    const customerId = isClient ? get(profile, 'customer.id', null) : null;
    const isProfileLoaded = !isEmpty(profile);
    const activeStepByKeyword = location.pathname.split('/');

    const flagCondition = !isClient ? flag : true;

    const onSubmit = async (values, formApi, callback) => {
      await sleep(100);
      if (includes(location.pathname, 'basics')) {
        await saveBrief(values, formApi, callback);
        if (flagCondition) {
          if (!draft.id) {
            history.push(APP_ROUTES.briefsCreateTechStack);
          }
          if (draft.id) {
            history.push(APP_ROUTES.briefsEditTechStack(draft.id));
          }
        }
      }
      if (includes(location.pathname, 'tech-stack')) {
        await saveBrief(values, formApi);
        if (flagCondition) {
          if (draft.id) {
            history.push(APP_ROUTES.briefsEditFinish(draft.id));
          } else {
            history.push(APP_ROUTES.briefsCreateFinish);
          }
        }
      }

      if (includes(location.pathname, 'finish')) {
        saveBrief(values, formApi, callback);
      }
    };

    let submitStep;
    const submit = handleSubmit => {
      submitStep = handleSubmit;
    };

    const mainNameQA = MAIN_NAMES_QA.BRIEF_PROJECT_CREATE;

    return (
      <Box>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Container>
              <WizardHeader
                steps={STEPS}
                briefId={draft.id}
                activeStep={last(activeStepByKeyword)}
                brief={{ isStep1Completed: true, ...draft }}
                submit={event => {
                  submitStep(event);
                }}
              />
              <Content>
                <Switch>
                  <Route
                    path={[
                      APP_ROUTES.briefsCreateBasics,
                      APP_ROUTES.briefsEditBasics(draft.id),
                    ]}
                    exact
                    render={props => (
                      <BasicsStep
                        {...props}
                        onSubmit={onSubmit}
                        submit={submit}
                        initialValues={{
                          ...draft,
                          originalName: draft.name,
                          ...(isClient && { clientLead: profile }),
                        }}
                        isAdminOrDL={isAdminOrDL}
                        customerId={customerId}
                        isProfileLoaded={isProfileLoaded}
                        briefId={draft.id}
                        mainNameQA={mainNameQA}
                      />
                    )}
                  />

                  <Route
                    path={[
                      APP_ROUTES.briefsCreateTechStack,
                      APP_ROUTES.briefsEditTechStack(draft.id),
                    ]}
                    exact
                    render={props => (
                      <TechStackStep
                        {...props}
                        initialValues={{ ...draft }}
                        saveBrief={saveBrief}
                        briefId={draft.id}
                        onSubmit={onSubmit}
                        submit={submit}
                        mainNameQA={mainNameQA}
                      />
                    )}
                  />

                  <Route
                    path={[
                      APP_ROUTES.briefsCreateFinish,
                      APP_ROUTES.briefsEditFinish(draft.id),
                    ]}
                    exact
                    render={props => (
                      <FinishStep
                        {...props}
                        initialValues={{ ...draft }}
                        saveBrief={saveBrief}
                        briefId={draft.id}
                        onSubmit={onSubmit}
                        submit={submit}
                        mainNameQA={mainNameQA}
                      />
                    )}
                  />
                  {!includes(location.pathname, 'edit') && (
                    <Redirect to={APP_ROUTES.briefsCreateBasics} />
                  )}
                  {includes(location.pathname, 'edit') && (
                    <Redirect to={APP_ROUTES.briefsEditBasics(draft.id)} />
                  )}
                </Switch>
              </Content>
            </Container>
          </>
        )}
      </Box>
    );
  }
}
export default ProjectCreate;
