import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import React from 'react';
import Typography from '@styles/Typography';

const ResetRateModal = ({ onResetRate, isReseting, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2" mb="32px" textAlign="center">
        Reset rate
      </Typography>
      <Typography mb="40px" textAlign="center" maxW="488px" mx="auto">
        Are you sure you want to reset rate to current platform default exchange
        rate?
      </Typography>
      <Stack spacing={3}>
        <Button variant="warning" onClick={onResetRate} isLoading={isReseting}>
          Reset rate
        </Button>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};

export default ResetRateModal;
