import { Box } from '@chakra-ui/react';
import Textarea from '@components/forms/_common/Textarea';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import { stripOptionalLabel } from '@utils/appUtils';
import { mustBeShorterOrEqual } from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';
import AutoSave from './AutoSave';

const textAreaProps = (isReadOnly = false) => ({
  maxW: '727px',
  mb: '24px',
  inputProps: {
    disabled: isReadOnly,
    color: isReadOnly ? 'rgba(0,0,0,0.4)' : 'inherit',
  },
});

const FeatureAssumptionsForm = ({
  isReadOnly,
  currentFeature,
  mainNameQA,
  featureId,
  onSubmit = () => {},
  isSmallerScreen,
}) => {
  return (
    <WhiteCard
      mt="0"
      pb="0"
      boxShadow="0 0 0 0 rgba(0, 0, 0, 0)"
      border="solid 1px"
      borderColor="dark.mid"
      mb="24px"
      p={isSmallerScreen && '12px'}
    >
      <Typography variant="h2" mb="32px">
        Assumptions & Risks
      </Typography>
      <Form
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        initialValues={{
          assumptions: currentFeature.assumptions,
          risks: currentFeature.risks,
        }}
        render={({ handleSubmit, form }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <AutoSave
                formApi={form}
                onSave={onSubmit}
                formName="featureAssumptions"
              />
              <Box w="100%" maxW="800px" pb="20px">
                <Field
                  component={Textarea}
                  label={stripOptionalLabel(
                    'Assumptions (optional)',
                    isReadOnly,
                  )}
                  {...textAreaProps(isReadOnly)}
                  rows={isSmallerScreen ? 4 : 6}
                  name="assumptions"
                  data-test-id={`${mainNameQA}--featureAssumptions[${featureId}]`}
                  parse={value => (value === null ? '' : value)}
                  validate={mustBeShorterOrEqual(10000)}
                  placeholder="List any assumptions here"
                  isDisabled={isReadOnly}
                />
                <Field
                  component={Textarea}
                  validate={mustBeShorterOrEqual(10000)}
                  label={stripOptionalLabel('Risks (optional)', isReadOnly)}
                  {...textAreaProps(isReadOnly)}
                  rows={isSmallerScreen ? 4 : 6}
                  name="risks"
                  data-test-id={`${mainNameQA}--featureRisks[${featureId}]`}
                  parse={value => (value === null ? '' : value)}
                  placeholder="What risks have you identified that could affect the success of the project"
                  isDisabled={isReadOnly}
                />
              </Box>
            </form>
          );
        }}
      />
    </WhiteCard>
  );
};

export default FeatureAssumptionsForm;
