/**
 * REMOVE THIS FILE ONCE BRIEF VALIDATION IS DONE WITH YUP
 */

import React from 'react';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import TextInput from '@components/forms/_common/TextInput';
import useMediaQuery from '@hooks/useMediaQuery';
import Icon from '@components/Icon';
import { ErrorText } from '@components/forms/_common/FormError';
import { INPUT_VARIANTS } from '@styles/theme';

const indicatorColor = (isValidating, showError) => {
  if (showError) {
    return 'red.solid';
  }
  if (isValidating) {
    return '#fff';
  }
  return 'transparent';
};

const ProjectNameInput = ({
  placeholder = '',
  variant,
  label,
  meta = {},
  fullWidth,
  ...props
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const [wasVisited, setWasVisitedTouched] = React.useState(false);

  const showError = meta.error && (wasVisited || meta.touched || meta.dirty);

  const isSyncError =
    showError && meta.error !== 'Project name must be unique.';

  const indicatorBg = indicatorColor(meta.validating, showError);

  const iconProps = React.useMemo(() => {
    if (variant === 'rounded') {
      return {
        w: '48px',
        h: '48px',
        right: '9px',
        top: '8px',
      };
    }
    return {
      w: '24px',
      right: '9px',
      top: '39px',
      h: '24px',
    };
  }, [variant]);

  return (
    <Box position="relative" mb="24px" w={fullWidth ? '100%' : null}>
      <TextInput
        label={label}
        variant={variant}
        placeholder={placeholder}
        {...props}
        meta={meta}
        hideError
        pr={variant === INPUT_VARIANTS.CONTAINER_FILLED ? '70px' : '45px'}
        onOnlyBlur={() => {
          if (!wasVisited) {
            setWasVisitedTouched(true);
          }
        }}
      />

      {(showError || meta.validating || meta.valid) && (
        <Flex
          position="absolute"
          bg={!showError && isSmallerScreen ? '#fff' : indicatorBg}
          borderRadius="50%"
          {...iconProps}
          alignItems="center"
          justifyContent="center"
          data-test-id="projectNameInputError"
          zIndex={10}
        >
          {meta.validating && meta.dirty && !isSyncError ? (
            <Spinner color="dark.solid" size="md" />
          ) : (
            <Icon
              name={showError ? 'close' : 'simpleTick'}
              color={!showError ? 'green.solid' : '#fff'}
              fontSize={18}
            />
          )}
        </Flex>
      )}
      {showError && <ErrorText>{meta.error}</ErrorText>}
    </Box>
  );
};

export default ProjectNameInput;
