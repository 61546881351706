import React from 'react';
import {
  Drawer,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  Box,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
// import { ChannelProvider } from '@sendbird/uikit-react/Channel/context';
// import '@sendbird/uikit-react/dist/index.css';
import Typography from '@styles/Typography';
// import ChannelTabs from './ChannelTabs';
// import CustomChannel from './CustomChannel';

const StyledDrawerWrapper = styled(Box)`
  .sendbird-channel-list {
    width: 100%;
  }
`;

const BriefChat = ({
  isBriefChatOpened,
  toggleBriefChat,
  briefName,
  // brief,
  // isAdminOrDL,
  // currentChannelUrl,
  setCurrentChannelUrl,
}) => {
  const onClose = () => {
    toggleBriefChat();
    setCurrentChannelUrl('');
  };

  return (
    <Drawer
      isOpen={isBriefChatOpened}
      placement="right"
      onClose={onClose}
      trapFocus={false}
    >
      <StyledDrawerWrapper>
        <DrawerContent
          maxW="480px"
          marginTop="80px"
          borderLeft="1px solid #D9DADA"
        >
          <DrawerCloseButton fontSize="14px" color="primary.400" top="14px" />
          <DrawerHeader px="16px" pt="21px">
            <Typography pb="20px" maxWidth="420px">
              {briefName} chat
            </Typography>
            {/* <ChannelTabs
              brief={brief}
              setCurrentChannelUrl={setCurrentChannelUrl}
              currentChannelUrl={currentChannelUrl}
              isAdminOrDL={isAdminOrDL}
            /> */}
          </DrawerHeader>
          {/* <ChannelProvider channelUrl={currentChannelUrl}>
            <CustomChannel
              channelUrl={currentChannelUrl}
              isAdminOrDL={isAdminOrDL}
            />
          </ChannelProvider> */}
        </DrawerContent>
      </StyledDrawerWrapper>
    </Drawer>
  );
};

export default BriefChat;
