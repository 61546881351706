import useMediaQuery from '@hooks/useMediaQuery';
import { Box, Flex } from '@chakra-ui/react';
import { filter, includes, isEmpty, isString, join, split } from 'lodash-es';
import EmptyList from '@components/EmptyList';
import { inject, observer } from 'mobx-react';
import { MAIN_NAMES_QA } from '@app/constants';
import qs from 'query-string';
import React from 'react';
import TaskList from './TaskList';
import TasksBulkBar from './TasksBulkBar';
import TasksFilters from './TasksFilters';

const Tasks = ({
  tasksStore: {
    fetchTasks,
    tasks: { data: tasks, paging, isLoading },
  },
  location: { search },
  history,
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const params = qs.parse(search);
  const {
    status = 'Open',
    orderBy = 'createdAt',
    orderDirection = 'desc',
    categories,
  } = params;

  React.useEffect(() => {
    fetchTasks({
      status,
      ...params,
      pageSize: 50,
      page: 1,
    });
  }, [search]);

  const isListEmpty = !isLoading && isEmpty(tasks);

  const onSubmit = values => {
    const cats = filter(
      [
        values.groupedProject && 'Project',
        values.groupedProposal && 'Proposal',
        values.groupedBrief && 'Brief',
        values.groupedInvoice && 'Invoice',
      ],
      isString,
    );
    return history.push(
      `${history.location.pathname}?${qs.stringify({
        status: values.status,
        categories: join(cats, ','),
      })}&${values.sort}`,
    );
  };

  const mainNameQA = MAIN_NAMES_QA.TASKS;

  const initialValues = () => {
    const cats = split(categories, ',');
    return {
      status,
      orderDirection,
      orderBy,
      groupedBrief: includes(cats, 'Brief'),
      groupedProject: includes(cats, 'Project'),
      groupedInvoice: includes(cats, 'Invoice'),
      groupedProposal: includes(cats, 'Proposal'),
      sort: `orderDirection=${orderDirection}&orderBy=${orderBy}`,
    };
  };

  return (
    <Flex width="100%">
      <TasksFilters onSubmit={onSubmit} initialValues={initialValues()} />
      <Flex
        direction="column"
        px={isSmallerScreen ? '12px' : '24px'}
        pt={isSmallerScreen ? '73px' : '88px'}
        flex={1}
      >
        <TasksBulkBar />
        <Box
          h="100%"
          w="100%"
          mx="auto"
          minH="calc(100vh - 188px)"
          pb={isSmallerScreen ? '160px' : '60px'}
        >
          <TaskList
            initialLoad={isLoading && (+paging.page || 1) <= 1}
            onLoadMore={() =>
              fetchTasks({
                status,
                ...params,
                pageSize: 50,
                page: +paging.page + 1,
              })
            }
            status={status}
            tasks={tasks}
            hasMore={paging.hasMore}
            mainNameQA={mainNameQA}
          />
          {isListEmpty && (
            <EmptyList
              label="No tasks here!"
              description="They will appear here soon"
              imagePath="/images/notaskshere.svg"
            />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default inject('tasksStore')(observer(Tasks));
