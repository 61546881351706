import React from 'react';

const useCopy = () => {
  const [isCopied, setCopied] = React.useState(false);

  const onCopy = link => {
    const textField = document.createElement('textarea');
    textField.innerText = link;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setCopied(true);
  };

  const clearCopy = () => {
    setCopied(false);
  };
  return {
    clearCopy,
    onCopy,
    isCopied,
  };
};

export default useCopy;
