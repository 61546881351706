import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Typography from '@styles/Typography';
import { isBoolean } from 'lodash-es';
import React from 'react';

export const StyledCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: flex;
    padding-left: ${props => (props.size === 'sm' ? '12px;' : '25px;')};
    align-items: center;
  }
  & + label:before {
    content: '';
    background-image: url('/images/icons/checkbox-unselected.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -1px;
    left: 0;
    ${props =>
      props.size === 'sm'
        ? 'width: 12px; height: 12px;'
        : 'width: 18px; height: 18px;'}
  }
  &:disabled + label {
    :hover {
      :before {
        border: none;
      }
    }
    color: #d9dada;
    cursor: auto;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    background: url('/images/icons/checkbox-selected.svg') no-repeat center
      center;
    background-size: cover;
    top: -1px;
    left: 0;
    ${props =>
      props.size === 'sm'
        ? 'width: 12px; height: 12px;'
        : 'width: 18px; height: 18px;'}
  }
`;

const Checkbox = ({
  onAfterChange,
  variant,
  input = {},
  label,
  id = 'id',
  containerStyles = {},
  labelProps = {},
  bg,
  checked,
  ...props
}) => (
  <Box mb="12px" {...containerStyles}>
    <StyledCheckbox
      type="checkbox"
      id={id}
      {...input}
      {...props}
      checked={isBoolean(input.value) ? input.value : checked || ''}
      onChange={e => {
        input.onChange(e);
        if (typeof onAfterChange === 'function') {
          onAfterChange(e);
        }
      }}
    />
    <Typography as="label" color="gray.400" htmlFor={id} {...labelProps}>
      {label}
    </Typography>
  </Box>
);

export default Checkbox;
