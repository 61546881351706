import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import useMediaQuery from '@hooks/useMediaQuery';
// import Button from '@styles/Button';

const SignInLayout = ({ children }) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');
  return (
    <Flex justifyContent={isSmallerScreen ? 'center' : 'flex-start'}>
      <Flex
        w={isSmallerScreen ? '100%' : '45%'}
        bg="primary.100"
        minH="100vh"
        p={['20px', '30px', '40px', '50px', '60px', '117px 40px 40px 128px']}
        {...(isSmallerScreen && { maxW: '600px', p: '80px 20px 20px 20px' })}
        flexDir="column"
      >
        <Image
          maxW={isSmallerScreen ? '174px' : '312px'}
          w="100%"
          h="auto"
          src="/images/deazy-wordmark-black.svg"
          alt="DEAZY"
          ignoreFallback
        />
        <WhiteCard
          maxW="540px"
          boxShadow="none"
          p="40px 32px"
          mt="116px"
          mb="24px"
          {...(isSmallerScreen && { bg: 'primary.100', p: 0, mt: '40px' })}
        >
          {children}
        </WhiteCard>
        {/* <Flex
          mt="auto"
          justifyContent={isSmallerScreen ? 'center' : 'flex-start'}
        >
          <Button
            variant="ghost"
            as="a"
            href=""
            target="_blank"
            h="19px"
            p="0px 12px 0px 0px"
            fontSize={14}
          >
            Privacy Policy
          </Button>
          <Box borderRight="1px solid" borderColor="dark.mid" />
          <Button
            variant="ghost"
            as="a"
            href=""
            target="_blank"
            h="19px"
            fontSize={14}
            p="0px 12px"
          >
            Cookie policy
          </Button>
        </Flex> */}
      </Flex>

      {!isSmallerScreen && (
        <Flex w="55%" bg="#fff" minH="100vh" alignItems="center">
          <Box
            backgroundImage="url('/images/auth-bg.png')"
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            backgroundPosition="center"
            w="80%"
            h="80%"
            m="auto"
          />
        </Flex>
      )}
    </Flex>
  );
};

export default SignInLayout;
