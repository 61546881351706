import { PROJECT_TYPES, MAIN_NAMES_QA } from '@app/constants';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { isFixedPrice, isFixedPriceProjectType } from '@utils/projectUtils';
import { isEmpty, join, map, split } from 'lodash-es';

import { inject, observer } from 'mobx-react';
import React from 'react';
import { useRouteMatch } from 'react-router';
import AllocationCard from '../AllocationCard';

import { getAllocationsColumns } from '../constants';
import Drawer from '../Drawer';

const AllTeamAllocations = ({
  projectsStore: { project },
  allocationsStore: {
    fetchAllAllocations,
    allAllocations,
    clearAllAllocations,
    allocations: {
      all: { isLoading },
    },
  },
  usersStore: { isTeamAdmin, isClient, isTeamMember },
}) => {
  const routeMatch = useRouteMatch(
    '/clients/:clientSlug/projects/:projectSlug/all-team-allocations/:supplierId',
  );

  React.useEffect(() => {
    if (project.id) {
      fetchAllAllocations({
        projectId: project.id,
        supplierId: routeMatch?.params?.supplierId,
      });
    }
    return () => {
      clearAllAllocations();
    };
  }, [project.id]);

  const isProjectFixedPriceModal = isFixedPriceProjectType(project);

  return (
    <Drawer
      maxW="1400px"
      backUrl={join(
        [
          ...split(routeMatch?.url, '/').slice(0, -2),
          project.projectType === PROJECT_TYPES.FIXED_PRICE
            ? 'allocations'
            : 'allocations-and-actuals',
        ],
        '/',
      )}
    >
      <Typography variant="h3" mb="80px">
        All team allocation
      </Typography>
      {map(allAllocations, allocation => (
        <AllocationCard
          columns={getAllocationsColumns({
            isTeamAdmin,
            isClient,
            isTeamMember,
            isFixedPrice: isFixedPrice(project),
          })}
          key={`allocation-card-${allocation.id}`}
          mainNameQA={MAIN_NAMES_QA.PROJECT_ALLOCATIONS}
          data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--allocationCard`}
          allocation={allocation}
          isProjectFixedPriceModal={isProjectFixedPriceModal}
        />
      ))}
      {isLoading && <Spinner />}
      {isEmpty(allAllocations) && !isLoading && (
        <Typography variant="h3" textAlign="center" my="40px">
          No allocations found.
        </Typography>
      )}
    </Drawer>
  );
};

export default inject(
  'allocationsStore',
  'usersStore',
  'projectsStore',
)(observer(AllTeamAllocations));
