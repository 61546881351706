import { Box } from '@chakra-ui/react';
import Spinner from '@styles/Spinner';
import { find } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { IS_STAGING, IS_DEV } from '@app/constants';
import React from 'react';
import SettingsForm from './SettingsForm';
import UploadCSVForm from './UploadCSVform';

const Settings = ({
  usersStore: { isLoading, isAdmin, isAdminOrDL, isDL },
  xeroStore: { isAuthorized: isXeroAuthorized },
  settingsStore: {
    uploadCSVTemplate,
    deleteCSVTemplate,
    updateSettings,
    settings,
    nukePayments,
    isNuking,
    forceJobs,
    isForcing,
    disconnectXero,
    isDisconnecting,
    fetchExchangeRates,
    updateExchangeRates,
    exchangeRates: { data: exchangeRates, isLoading: isLoadingexchangeRates },
  },
}) => {
  React.useEffect(() => {
    fetchExchangeRates();
  }, []);
  const onCSVTemplateSubmit = (values, formApi) =>
    uploadCSVTemplate({ values }, formApi);

  const initialValues = React.useMemo(() => {
    const gbpToUsd = find(
      exchangeRates,
      rate => rate.sourceCurrency === 'GBP' && rate.destCurrency === 'USD',
    );
    const gbpToEur = find(
      exchangeRates,
      rate => rate.sourceCurrency === 'GBP' && rate.destCurrency === 'EUR',
    );
    return {
      ...settings,
      rates: [
        { sourceCurrency: 'GBP', destCurrency: 'USD', ...gbpToUsd },
        { sourceCurrency: 'GBP', destCurrency: 'EUR', ...gbpToEur },
      ],
    };
  }, [exchangeRates, settings]);

  return (
    <Box pt="24px" pb="150px" px="40px" minH="calc(100vh - 80px)">
      <Box background="#fff" borderRadius="8px">
        {isAdminOrDL && (
          <>
            {isLoading || isLoadingexchangeRates ? (
              <Spinner />
            ) : (
              <>
                <SettingsForm
                  initialValues={initialValues}
                  isAdmin={isAdmin}
                  isDL={isDL}
                  forceJobs={forceJobs}
                  isForcing={isForcing}
                  updateExchangeRates={updateExchangeRates}
                  updateSettings={updateSettings}
                  nukePayments={nukePayments}
                  isNuking={isNuking}
                  disconnectXero={disconnectXero}
                  isDisconnecting={isDisconnecting}
                  isXeroAuthorized={isXeroAuthorized}
                />
                {(IS_DEV || IS_STAGING) && isAdmin && (
                  <UploadCSVForm
                    onSubmit={onCSVTemplateSubmit}
                    deleteCSVTemplate={deleteCSVTemplate}
                    files={settings.csvTemplates}
                  />
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default inject(
  'usersStore',
  'xeroStore',
  'settingsStore',
)(observer(Settings));
