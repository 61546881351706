import React from 'react';
import { inject, observer } from 'mobx-react';
import { get, map } from 'lodash-es';
import Spinner from '@styles/Spinner';
import { addFullDescription } from '@utils/usersUtils';
import QuestionListItem from './QuestionListItem';

const QuestionsList = ({
  briefId,
  briefsStore: { isLoading, brief },
  questionsStore: {
    questions,
    setQuestionProperty,
    createQuestionReply,
    deleteQuestion,
  },
  usersStore: { profile, isAdminOrDL },
  mainNameQA,
}) => {
  const isReadOnly = brief.isClosed;

  if (isLoading) {
    return <Spinner variant="large" />;
  }

  return map(questions, question => (
    <QuestionListItem
      deleteQuestion={deleteQuestion}
      key={`question-list-item-${question.id}`}
      toggleQuestion={() =>
        setQuestionProperty(question.id, { isOpened: !question.isOpened })
      }
      isReadOnly={isReadOnly}
      question={question}
      isAdminOrDL={isAdminOrDL}
      createQuestionReply={createQuestionReply}
      briefId={briefId}
      author={addFullDescription(get(profile, 'roles[0].name'))}
      mainNameQA={mainNameQA}
    />
  ));
};

export default inject(
  'questionsStore',
  'briefsStore',
  'usersStore',
)(observer(QuestionsList));
