import { Box, Flex } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import ZoneContainer from '@components/ZoneContainer';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { flatten, compact, isEmpty, map, takeRight, reject } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import { Form } from 'react-final-form';
import AutoSave from './AutoSave';
import TasksTable from './TasksTable';
import TaskTotals from './TaskTotals';

const TasksForm = ({
  isReadOnly,
  currency,
  proposalsStore: {
    initialCoreTeamRoles,
    decoratedProposalData,
    coreTeamRolesForClientProposal,
    deleteProposalTask,
    isPendingRequest,
  },
  onSubmit,
  mainNameQA,
  showFullBreakdownForClient,
  isSmallerScreen,
  currentFeature,
}) => {
  const [taskRows, setTasksRows] = React.useState([]);

  const initialValues = React.useMemo(() => {
    const lastTask =
      currentFeature?.tasks?.length + 1 === taskRows?.length
        ? takeRight(taskRows, 1)
        : [];
    setTasksRows([
      ...map(currentFeature?.tasks, (t, idx) => {
        if (taskRows[idx]) {
          return { rowId: nanoid(10), ...taskRows[idx] };
        }
        return { rowId: nanoid(10) };
      }),
      ...lastTask,
    ]);
    return {
      tasks: [
        ...(currentFeature?.tasks || []),
        ...(currentFeature?.tasks?.length + 1 === taskRows?.length
          ? [
              {
                resources: initialCoreTeamRoles,
                ...lastTask[0],
              },
            ]
          : []),
      ],
      currency,
    };
  }, [decoratedProposalData, currency, showFullBreakdownForClient]);

  const deleteTask = (tasks, form, taskId, taskRowId, cb) => {
    const newTasks = reject(
      tasks,
      taskId ? { id: taskId } : { rowId: taskRowId },
    );
    form.change('tasks', newTasks);
    setTasksRows(newTasks);
    if (taskId) {
      deleteProposalTask(currentFeature.id, taskId, cb);
    }
  };

  const coreTeamData = showFullBreakdownForClient
    ? coreTeamRolesForClientProposal
    : initialCoreTeamRoles;

  return (
    <WhiteCard
      mt={0}
      mb="24px"
      py="40px"
      px={isSmallerScreen ? '12px' : '32px'}
      boxShadow="0 0 0 0 rgba(0, 0, 0, 0)"
      border="solid 1px"
      borderColor="dark.mid"
    >
      <Typography variant="h2" mb="16px">
        Tasks
      </Typography>
      <Typography mb="16px">
        Tell us about the taks needed to complete this feature.
      </Typography>
      {isEmpty(coreTeamData) ? (
        <Typography
          fontSize={18}
          mb="12px"
          borderLeft="2px solid"
          borderColor="red.solid"
          mt="32px"
          py="16px"
          pl="16px"
        >
          You have to add at least one role in the `Configure your team` tab
        </Typography>
      ) : (
        <Flex
          border="1px solid"
          borderColor="dark.mid"
          bg="dark.light"
          borderRadius="8px"
          px="16px"
          pt="40px"
          pb="24px"
          mb="12px"
          flexDir="column"
        >
          <Form
            onSubmit={onSubmit}
            keepDirtyOnReinitialize
            initialValues={initialValues}
            render={({ handleSubmit, values, form, errors }) => {
              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <AutoSave
                    formName="tasksForm"
                    formApi={form}
                    onSave={onSubmit}
                  />
                  <TasksTable
                    tasks={values.tasks}
                    mainNameQA={mainNameQA}
                    taskRows={taskRows}
                    formApi={form}
                    isPendingRequest={isPendingRequest}
                    currency={currency}
                    isReadOnly={isReadOnly}
                    isSmallerScreen={isSmallerScreen}
                    errors={errors}
                    deleteTask={deleteTask}
                  />
                  <Box
                    borderTop="1px solid"
                    borderColor="dark.mid"
                    mt="24px"
                    mb="32px"
                  />
                  <TaskTotals
                    label="Feature totals"
                    currency={currency}
                    resources={compact(
                      flatten(map(values.tasks, t => t?.resources)),
                    )}
                    isSmallerScreen={isSmallerScreen}
                    mainNameQA={mainNameQA}
                  />
                  {!isReadOnly && (
                    <ZoneContainer
                      py="24px"
                      mt="24px"
                      height={isSmallerScreen && '112px'}
                      borderColor={isSmallerScreen && '#D9DADA'}
                    >
                      <Button
                        leftIcon={{ name: 'add' }}
                        data-test-id={`${mainNameQA}--addTask`}
                        variant={isSmallerScreen && 'outline'}
                        onClick={() => {
                          const rowId = nanoid(10);
                          const tasks = [
                            ...(values.tasks || []),
                            {
                              resources: initialCoreTeamRoles,
                              rowId,
                            },
                          ];
                          if (isEmpty(errors)) {
                            setTasksRows([...taskRows, { rowId }]);
                            form.reset();
                          } else {
                            form.submit();
                          }
                          onSubmit({ tasks });
                          form.change('tasks', tasks);
                        }}
                      >
                        Add another task
                      </Button>
                    </ZoneContainer>
                  )}
                </form>
              );
            }}
          />
        </Flex>
      )}
    </WhiteCard>
  );
};

export default inject('proposalsStore', 'briefsStore')(observer(TasksForm));
