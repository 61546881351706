import React from 'react';
import Typography from '@styles/Typography';
import { Grid, Stack } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import { Stat } from '@pages/Users/UserCardContent';

const AdditionalDetails = ({ obj, label }) => (
  <WhiteCard p="40px 40px 8px">
    <Typography variant="h4" pb="10px" mb="16px">
      {label}
    </Typography>
    <Grid
      position="relative"
      w="100%"
      p="0"
      borderRadius="8px"
      gap="16px"
      templateColumns={[
        'repeat(1, 1fr)',
        'repeat(2, 1fr)',
        'repeat(4, 1fr)',
        'repeat(4, 1fr)',
        'repeat(4, 1fr)',
      ]}
    >
      <Stack gridColumn={['span 4', 'span 2', 'span 2', 'span 2', 'span 1']}>
        <Stat isUserProfile label="Name" value={obj.name} />
      </Stack>
      <Stack gridColumn={['span 4', 'span 2', 'span 2', 'span 2', 'span 1']}>
        <Stat
          isUserProfile
          label="Finance email"
          value={obj.financeEmail || '-'}
        />
      </Stack>
      <Stack gridColumn={['span 4', 'span 2', 'span 2', 'span 2', 'span 1']}>
        <Stat isUserProfile label="Vat number" value={obj.vatNumber || '-'} />
      </Stack>
      {obj.isTeamAdmin && (
        <Stack gridColumn={['span 4', 'span 2', 'span 2', 'span 2', 'span 1']}>
          <Stat
            isUserProfile
            label="Invoice Numbering Pattern"
            value={obj.invoiceNumberingPattern || '-'}
          />
        </Stack>
      )}
      <Stack gridColumn={['span 4', 'span 2', 'span 2', 'span 2', 'span 1']}>
        <Stat isUserProfile label="Location" value={obj.location} />
      </Stack>
      <Stack gridColumn={['span 4', 'span 2', 'span 2', 'span 2', 'span 1']}>
        <Stat isUserProfile label="Currency" value={obj.currency} />
      </Stack>
      {obj.isTeamAdmin && (
        <Stack gridColumn={['span 4', 'span 2', 'span 2', 'span 2', 'span 1']}>
          <Stat
            isUserProfile
            label="Invoice Numbering Timespan"
            value={obj.invoiceNumberingTimespan || '-'}
          />
        </Stack>
      )}
      {obj.isTeamAdmin && (
        <Stack gridColumn={['span 4', 'span 2', 'span 2', 'span 2', 'span 1']}>
          <Stat
            isUserProfile
            label="invoice Name"
            value={obj.invoiceName || '-'}
          />
        </Stack>
      )}
      <Stack gridColumn={['span 4', 'span 2', 'span 2', 'span 2', 'span 2']}>
        <Stat
          isUserProfile
          marginBottom="18px"
          label="Address"
          value={obj.address || '-'}
        />
      </Stack>
      {obj.isTeamAdmin && (
        <Stack gridColumn={['span 4', 'span 2', 'span 2', 'span 2', 'span 2']}>
          <Stat
            isUserProfile
            marginBottom="18px"
            label="Invoice Info"
            value={obj.paymentInfo || '-'}
          />
        </Stack>
      )}
    </Grid>
  </WhiteCard>
);

export default AdditionalDetails;
