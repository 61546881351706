import { APP_ROUTES } from '@routes';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { isEmpty, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import CRCard from './CRCard';

const SingleChangeRequests = ({
  usersStore: { isTeamMember, isAdminOrDL, isClient, isTeamAdmin },
  projectId,
  projectsStore: { project, projectCurrency },
  crsStore: {
    rejectCR,
    isAllowedToApproveCRs,
    deleteCR,
    approveCR,
    fetchPendingCRs,
    crs,
    readyChangeRequests,
    clearPendingCRs,
  },
  onEdit,
}) => {
  React.useEffect(() => {
    fetchPendingCRs(projectId);
    return () => {
      clearPendingCRs();
    };
  }, []);

  const { pending: { isLoading, firstRequest, data } = {} } = crs;

  const addToPaymentLink = crId =>
    `${APP_ROUTES.projectTabWithSlugs(
      project?.client?.slug,
      project?.slug,
      'payments',
    )}?crId=${crId}`;

  if (isLoading || firstRequest) {
    return <Spinner />;
  }

  return (
    <>
      {map(data, cr => (
        <CRCard
          key={`single-change-request-${cr.id}`}
          cr={cr}
          projectId={projectId}
          readyChangeRequests={readyChangeRequests}
          projectCurrency={projectCurrency}
          primaryPTOS={project.primaryPTOS}
          approveCR={approveCR}
          rejectCR={rejectCR}
          deleteCR={deleteCR}
          addToPaymentLink={addToPaymentLink(cr.id)}
          onEdit={() => onEdit(cr)}
          isAdminOrDL={isAdminOrDL}
          isTeamAdmin={isTeamAdmin}
          isTeamMember={isTeamMember}
          isClient={isClient}
          isAllowedToApproveCRs={isAllowedToApproveCRs}
          deazyAsClient={project.deazyAsClient}
          deazyAsSupplier={project.deazyAsSupplier}
        />
      ))}
      {isEmpty(data) && !isLoading && (
        <Typography variant="title" my="40px" textAlign="center">
          There are no change requests here yet.
        </Typography>
      )}
    </>
  );
};

export default inject(
  'crsStore',
  'projectsStore',
  'usersStore',
)(observer(SingleChangeRequests));
