import React from 'react';
import { Flex } from '@chakra-ui/react';
import Button from '@styles/Button';
import { StickyFooter } from '@components/StickyFooterCostBox';

const SettingsStickyFooter = ({ isDirty, submitting }) => {
  return (
    <>
      <StickyFooter as={Flex}>
        <Button
          ml="auto"
          type="submit"
          isDisabled={!isDirty}
          isLoading={submitting}
          leftIcon={{ name: 'check', fontSize: '18px' }}
          fontWeight="400"
        >
          Save
        </Button>
      </StickyFooter>
    </>
  );
};

export default SettingsStickyFooter;
