import { inject, observer } from 'mobx-react';
import qs from 'query-string';
import React from 'react';
import LoadingPage from '../LoadingPage';

const MagicLinkPage = ({
  authStore: { signOut, magicLinkSignIn },
  location,
  match,
}) => {
  const asyncFn = async () => {
    await signOut(false);
    const { token } = match.params;
    const { actionType } = qs.parse(location.search);
    await magicLinkSignIn({ token, actionType });
  };
  React.useEffect(() => {
    asyncFn();
  }, []);

  return <LoadingPage />;
};

export default inject('usersStore', 'authStore')(observer(MagicLinkPage));
