import { Box, Flex, Stack, Tooltip } from '@chakra-ui/react';
import Checkbox from '@components/forms/_common/Checkbox';
import { ErrorText } from '@components/forms/_common/FormError';
import TextInput from '@components/forms/_common/TextInput';
import Icon from '@components/Icon';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { mustBeNumber } from '@utils/formValidators';
import { supplierInvoiceCanBeExcluded } from '@utils/invoiceUtils';
import { get, replace } from 'lodash-es';
import moment from 'moment';
import React from 'react';
import { Field, Form } from 'react-final-form';

const EditNewInvoiceForm = ({
  invoice,
  onClose,
  onSubmit,
  initialValues,
  isEditable,
}) => (
  <Form
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={({
      handleSubmit,
      submitError,
      form: { change },
      submitting,
      values,
    }) => {
      const isPeriodOrdinalDisabled =
        values.isNumberFrozen ||
        values.excludeAutomaticNumbering ||
        !!values.supplierAccountingNo;

      const showAutomaticInvoiceNumber =
        (values.periodOrdinal || values.periodOrdinal === 0) &&
        !values.excludeAutomaticNumbering &&
        !values.supplierAccountingNo;

      return (
        <form onSubmit={handleSubmit}>
          <Flex flexDirection="column">
            <Flex w="100%">
              <Field
                label={
                  <Typography>
                    {`${
                      invoice?.project?.supplier?.invoiceNumberingPattern
                        ? 'Custom '
                        : ''
                    }Delivery Partner Accounting Number`}
                    <Tooltip
                      zIndex={33000}
                      p="12px"
                      borderRadius="8px"
                      lineHeight="normal"
                      label="If you want to use a custom accounting invoice number, please use this field.
                        Please note that the ordinal must be assinged in order for the automatic numbering sequence to work"
                    >
                      <Box as="span" ml="10px">
                        <Icon name="info" mt="-4px" />
                      </Box>
                    </Tooltip>
                  </Typography>
                }
                name="supplierAccountingNo"
                disabled={!isEditable}
                component={TextInput}
                parse={value => (value === '' ? '' : value)}
                isBordered
                containerProps={{ mb: '16px' }}
              />
            </Flex>
            <Flex w="100%">
              <Field
                label="Period Ordinal"
                name="periodOrdinal"
                validate={mustBeNumber}
                disabled={isPeriodOrdinalDisabled}
                component={TextInput}
                isBordered
                containerProps={{ mb: '16px' }}
                maxW="200px"
              />
              {showAutomaticInvoiceNumber && (
                <Flex
                  minW="200px"
                  flexDir="column"
                  mt="24px"
                  ml="20px"
                  alignItems="flex-end"
                >
                  <Typography fontFamily="WB" mb="12px">
                    {moment
                      .utc(invoice.invoiceDate)
                      .format(
                        replace(
                          get(invoice, 'supplier.invoiceNumberingPattern', ''),
                          '#NO#',
                          `[${values.periodOrdinal}]`,
                        ),
                      )}
                  </Typography>
                  <Typography variant="text3">
                    Automatic invoice number
                  </Typography>
                </Flex>
              )}
            </Flex>
            <Field
              label="Exclude from automatic numbering"
              name="excludeAutomaticNumbering"
              component={Checkbox}
              disabled={supplierInvoiceCanBeExcluded(invoice?.status)}
              type="checkbox"
              onAfterChange={e => {
                if (e.target.checked) {
                  return change('periodOrdinal', -1);
                }
                return change('periodOrdinal', '');
              }}
            />
            {submitError && <ErrorText>{submitError}</ErrorText>}
            <Stack mt="40px" spacing={3}>
              <Button
                isLoading={submitting}
                isDisabled={submitting}
                type="submit"
              >
                Edit
              </Button>
              <Button type="button" variant="outline" onClick={onClose}>
                Cancel
              </Button>
            </Stack>
          </Flex>
        </form>
      );
    }}
  />
);

export default EditNewInvoiceForm;
