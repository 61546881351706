import React from 'react';
import useSearchParams from '@hooks/useSearchParams';
import FiltersFormSidebar from '@components/FiltersFormSidebar';
import useMediaQuery from '@hooks/useMediaQuery';
import { join, map, split } from 'lodash-es';
import BottomFixedExpandableBox from './ExpandableBox';
import MatchingFiltersForm from './MatchingFiltersForm';

const MatchingFilters = ({ isDisabled }) => {
  const { searchObj, onSearch } = useSearchParams();

  const onSubmit = ({ countries: selectedCountries, ...values }) =>
    onSearch({
      ...searchObj,
      ...values,
      countries: join(
        map(selectedCountries, c => c.value),
        ',',
      ),
    });

  const onReset = () =>
    onSearch({
      filter: searchObj.filter,
    });

  const initialValues = React.useMemo(
    () => ({
      filter: searchObj.filter,
      engagementType: searchObj.engagementType,
      countries:
        searchObj.countries &&
        map(split(searchObj.countries, ','), c => ({
          label: c,
          value: c,
        })),
    }),
    [searchObj.filter, searchObj.engagementType, searchObj.countries],
  );

  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  if (isSmallerScreen) {
    if (isDisabled) return null;
    return (
      <FiltersFormSidebar
        onSubmit={onSubmit}
        onReset={onReset}
        initialValues={initialValues}
        showStatusFilters={false}
        fieldsToShow={['matchingFilters']}
        filterButtonLabel="Filter by"
        filterButtonProps={{
          bottom: '157px',
          top: '-60px',
          position: 'absolute',
        }}
      />
    );
  }

  return (
    <BottomFixedExpandableBox
      isOpenByDefault={searchObj.countries || searchObj.engagementType}
      onAction={() => onSearch({ filter: searchObj.filter })}
      isDisabled={isDisabled}
      title="Filter by"
      actionButtonLabel="Reset filters"
    >
      <MatchingFiltersForm initialValues={initialValues} onSubmit={onSubmit} />
    </BottomFixedExpandableBox>
  );
};

export default MatchingFilters;
