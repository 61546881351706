import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { every, find, filter } from 'lodash-es';
import Button from '@styles/Button';
import { Field, Form } from 'react-final-form';
import Select from '@components/forms/Select';
import { ErrorText } from '@components/forms/FormError';
import {
  PROJECT_PAYMENT_TYPES,
  PROJECT_TYPES,
  FP_PROJECT_PAYMENT_OPTIONS,
  TM_PROJECT_PAYMENT_OPTIONS,
} from '@app/constants';
import { required } from '@utils/formValidators';
import Checkbox from '@components/forms/_common/Checkbox';
import InvoiceFields from './InvoiceFields';

const ProjectPaymentForm = ({
  project,
  onClose,
  onSubmit,
  initialPaymentData,
  mainNameQA,
}) => {
  const paymentTypesOptions =
    project.projectType === PROJECT_TYPES.FIXED_PRICE
      ? FP_PROJECT_PAYMENT_OPTIONS
      : TM_PROJECT_PAYMENT_OPTIONS;

  const { deazyAsClient, deazyAsSupplier } = project;

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      initialValues={initialPaymentData}
      render={({ handleSubmit, values, submitting, form, submitError }) => {
        const [index, setIndex] = useState(0);
        const { client = {}, paymentType } = values;
        const isTMPayment =
          paymentType === PROJECT_PAYMENT_TYPES.T_AND_M_PAYMENT;
        const isCRPayment = paymentType === PROJECT_PAYMENT_TYPES.CR_PAYMENT;
        const supplier = isCRPayment
          ? find(values.suppliers, s => s.isPrimary)
          : (values.suppliers || [])[index] || {};

        const isDeposit = paymentType === PROJECT_PAYMENT_TYPES.DEPOSIT;
        const isFixedPayment =
          paymentType === PROJECT_PAYMENT_TYPES.FIXED_PAYMENT;

        const {
          invoiceDate: clientInvoiceDate,
          dueDate: clientDueDate,
        } = client;
        const disabledForClient = () => {
          if (deazyAsClient || deazyAsSupplier) {
            return true;
          }

          if (every(values.suppliers, s => s.isDisabled)) {
            return true;
          }
          return false;
        };

        const disabledForSupplier = isDisabled => {
          const allGuys = [...values.suppliers, client];

          if (isDisabled) {
            return false;
          }
          if (deazyAsSupplier) {
            return true;
          }
          if (project.projectToSuppliers.length === 1 && !!client.isDisabled) {
            return true;
          }
          if (
            filter(allGuys, { isDisabled: true }).length ===
            allGuys.length - 1
          ) {
            return true;
          }
          return false;
        };

        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Flex alignItems="center">
              {!isTMPayment && (
                <Field
                  component={Select}
                  name="paymentType"
                  label="Invoice type"
                  data-test-id={
                    mainNameQA && `${mainNameQA}--paymentForm-invoiceType`
                  }
                  placeholder="Select invoice type..."
                  options={paymentTypesOptions}
                  styleProps={{ maxW: '48%' }}
                  validate={required}
                />
              )}
              {isTMPayment && (
                <Field
                  name="calculatedFromActuals"
                  variant="secondary"
                  component={Checkbox}
                  id="calculatedFromActuals"
                  data-test-id={
                    mainNameQA &&
                    `${mainNameQA}--paymentForm-calculatedFromActuals`
                  }
                  bg="black"
                  label="Calculated from actuals"
                  labelProps={{ fontSize: '14px' }}
                  containerStyles={{ mb: '0px' }}
                />
              )}
            </Flex>
            <Flex mt="32px" key={paymentType}>
              {!deazyAsClient && (
                <>
                  <InvoiceFields
                    isClient
                    fieldPrefix="client"
                    project={project}
                    isTMPayment={isTMPayment}
                    isFixedPayment={isFixedPayment}
                    isCRPayment={isCRPayment}
                    isDeposit={isDeposit}
                    isDisabled={!isCRPayment && !!client.isDisabled}
                    invoiceCheckboxDisabled={disabledForClient()}
                    invoiceDate={clientInvoiceDate}
                    reference={client.reference}
                    dueDate={clientDueDate}
                    form={form}
                    deazyAsClient={deazyAsClient}
                    deazyAsSupplier={deazyAsSupplier}
                    periodStart={client.periodStart}
                    periodEnd={client.periodEnd}
                    paymentTerms={client.paymentTerms}
                    mainNameQA={mainNameQA}
                  />
                  <Box pr="40px" />
                </>
              )}

              {!deazyAsSupplier && (
                <InvoiceFields
                  form={form}
                  project={project}
                  isTMPayment={isTMPayment}
                  isFixedPayment={isFixedPayment}
                  isCRPayment={isCRPayment}
                  isDeposit={isDeposit}
                  paymentTerms={supplier?.paymentTerms}
                  projectToSuppliers={
                    isCRPayment
                      ? [find(values.suppliers, s => s.isPrimary)]
                      : project.projectToSuppliers
                  }
                  selectedSupplier={supplier}
                  index={index}
                  setIndex={setIndex}
                  fieldPrefix={`suppliers[${index}]`}
                  reference={supplier.reference}
                  isDisabled={!!supplier.isDisabled}
                  invoiceCheckboxDisabled={disabledForSupplier(
                    !!supplier.isDisabled,
                  )}
                  invoiceDate={supplier.invoiceDate}
                  dueDate={supplier.supplierDueDate}
                  deazyAsClient={deazyAsClient}
                  deazyAsSupplier={deazyAsSupplier}
                  periodStart={supplier.periodStart}
                  periodEnd={supplier.periodEnd}
                  mainNameQA={mainNameQA}
                />
              )}
            </Flex>
            {submitError && <ErrorText>{submitError}</ErrorText>}

            <Flex mt="32px">
              <Button
                fontWeight="400"
                mr="16px"
                isLoading={submitting}
                type="submit"
                data-test-id={`${mainNameQA}--createInvoiceButton`}
              >
                Create project invoice
              </Button>
              <Button
                variant="outline"
                isDisabled={submitting}
                onClick={onClose}
                data-test-id={`${mainNameQA}--cancelCreateInvoiceButton`}
              >
                Cancel
              </Button>
            </Flex>
          </form>
        );
      }}
    />
  );
};

export default ProjectPaymentForm;
