import WhiteCard from '@components/WhiteCard';
import useDownload from '@hooks/useDownload';
import Button from '@styles/Button';
import React from 'react';

const ExportProposalCsvSection = React.memo(({ briefId, proposalId }) => {
  const {
    onDownload: downloadProposalCsv,
    isLoading: isProposalCsvLoading,
  } = useDownload({
    url: `/briefs/${briefId}/proposals/${proposalId}/report`,
  });

  const isProposalSaved = briefId && proposalId;

  return isProposalSaved ? (
    <WhiteCard
      mt="0"
      boxShadow="0 0 0 0 rgba(0, 0, 0, 0)"
      border="solid 1px"
      borderColor="dark.mid"
      align="center"
    >
      <Button onClick={downloadProposalCsv} isLoading={isProposalCsvLoading}>
        Export data
      </Button>
    </WhiteCard>
  ) : null;
});

export default ExportProposalCsvSection;
