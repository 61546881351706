import { PROJECT_TYPES, MAIN_NAMES_QA } from '@app/constants';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { isFixedPrice } from '@utils/projectUtils';
import { isEmpty, join, map, split } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useRouteMatch } from 'react-router';
import AllocationCard from '../AllocationCard';
import { getAllocationsColumns } from '../constants';
import Drawer from '../Drawer';

const AllTeamActuals = ({
  projectsStore: { project },
  allocationsStore: {
    fetchAllActuals,
    allActuals,
    actuals: {
      all: { isLoading },
    },
  },
  usersStore: { isTeamAdmin, isClient, isTeamMember },
}) => {
  const routeMatch = useRouteMatch(
    '/clients/:clientSlug/projects/:projectSlug/all-team-actuals/:supplierId',
  );

  React.useEffect(() => {
    if (project.id) {
      fetchAllActuals({
        projectId: project.id,
        supplierId: routeMatch?.params?.supplierId,
      });
    }
  }, [project.id]);

  return (
    <Drawer
      backUrl={join(
        [
          ...split(routeMatch?.url, '/').slice(0, -2),
          project.projectType === PROJECT_TYPES.FIXED_PRICE
            ? 'allocations'
            : 'allocations-and-actuals',
        ],
        '/',
      )}
    >
      <Typography variant="h3" mb="80px">
        All teams actuals
      </Typography>
      {map(allActuals, allocation => (
        <AllocationCard
          mainNameQA={MAIN_NAMES_QA.PROJECT_ACTUALS}
          key={`allocation-entry-card-${allocation.id}`}
          data-test-id={`${MAIN_NAMES_QA.PROJECT_ACTUALS}--actualCard`}
          allocation={allocation}
          columns={getAllocationsColumns({
            isTeamAdmin,
            isClient,
            isTeamMember,
            isFixedPrice: isFixedPrice(project),
          })}
        />
      ))}
      {isLoading && <Spinner />}
      {isEmpty(allActuals) && !isLoading && (
        <Typography variant="h3" textAlign="center" my="40px">
          No actuals found.
        </Typography>
      )}
    </Drawer>
  );
};

export default inject(
  'allocationsStore',
  'projectsStore',
  'usersStore',
)(observer(AllTeamActuals));
