import { Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { toJS } from 'mobx';
import React from 'react';

const RequestErrorToast = ({ onClose, errorObj }) => {
  const data = `data:text/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify(toJS(errorObj)),
  )}`;

  return (
    <Flex
      mt="16px"
      bg="red.solid"
      px="18px"
      py="10px"
      align="center"
      borderRadius="8px"
      boxShadow="0 0 24px 0 rgba(0, 0, 0, 0.15)"
      flexDirection="column"
    >
      <Icon
        color="#fff"
        name="close"
        cursor="pointer"
        onClick={onClose}
        ml="auto"
      />
      <Flex align="center">
        <Icon name="warning" size="20px" color="#fff" mr="16px" />
        <Typography variant="h3" color="#fff">
          Oops! Something went wrong!
        </Typography>
      </Flex>
      <Typography color="#fff" mt="10px">
        Help us improve your experience by sending an error report
      </Typography>
      <Typography mt="20px" color="#fff">
        API URL: {errorObj.request.url}
      </Typography>
      <Button
        mt="24px"
        mb="12px"
        as="a"
        href={data}
        download={`${Date.now()}-error.json`}
        color="#fff"
        fontSize={20}
        variant="ghost"
      >
        Download error report
      </Button>
    </Flex>
  );
};
export default RequestErrorToast;
