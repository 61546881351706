/* eslint-disable */

export default `
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Schedule 1 Statement of Work&nbsp;</strong></p>
<p><strong>Dated:&nbsp;</strong></p>
<p><strong>Between:</strong></p>
<p><strong>(1) {{DEAZY_NAME}}</strong><span style="font-weight: 400;"> (trading as Deazy) of {{DEAZY_DETAILS}} (Company No. 10305788) (&ldquo;</span><strong>Deazy</strong><span style="font-weight: 400;">&rdquo;); and</span></p>
<p><span style="background-color: #feff00;"><strong>(2) {{CLIENT_NAME}} </strong><span style="font-weight: 400;"> of {{ADDRESS}} (the "</span><strong>Client</strong><span style="font-weight: 400;">")&nbsp;</span></span></p>
<p style="padding-left: 40px;">&nbsp;</p>
<p><strong>Background</strong></p>
<p><span style="font-weight: 400;">(A) Pursuant to Deazy&rsquo;s standard terms and conditions (the &ldquo;</span><strong>Terms</strong><span style="font-weight: 400;">&rdquo;) accepted by the Client on <span style="background-color: #feff00;">{{DATE}},</span> the Client has appointed Deazy to provide <span style="background-color: #feff00;"> </span></span><span style="font-weight: 400; background-color: #feff00;">[INCLUDE AS APPROPRIATE product management, project management, design and software development services]</span><span style="font-weight: 400;">, when required, during the Term.&nbsp;</span></p>
<p>(B) Pursuant to the Terms, the Client is able to request these services (as defined in the Terms) and Deazy has agreed to provide such Services in accordance with this statement of work (“<strong>SOW</strong>”).</p>
<p>&nbsp;</p>
<p><strong>Term</strong></p>
<ol class="start">
<li><span style="font-weight: 400;">This SOW shall come into force on the date it is executed by its parties <strong>OR</strong> on </span><span style="font-weight: 400; background-color: #feff00;">{{TERM_DATE}}</span><span style="font-weight: 400;"> (the &ldquo;</span><strong>Commencement Date&rdquo;</strong><span style="font-weight: 400;">).</span></li>
<li><span style="font-weight: 400;">This SOW shall take effect from the Commencement Date and will continue in effect for a fixed term of <span style="background-color: #feff00;">{{DURATION}}</span>, until <span style="background-color: #feff00;">{{PROJECT_END_DATE}}</span>.
<li>Either party may terminate this SOW on giving the other party written notice of not less than <span style="background-color: #feff00;">[thirty (30) days]</span> to be served at any time.</li>
</ol>
<p>&nbsp;</p>
<p><strong>Conflict</strong></p>
<ol class="continue">
<li><span style="font-weight: 400;">If there is an inconsistency between any of the provisions of this SOW and the provisions of the Terms, the provisions of this SOW shall prevail.&nbsp;</span></li>
<li><span style="font-weight: 400;">If a term is capitalised in this SOW but not defined therein the defined terms of the Terms shall be applicable to said term.</span></li>
</ol>
<p>&nbsp;</p>
<p><strong>Services</strong></p>
<ol class="continue">
<li><strong>&nbsp;Context:</strong><span style="font-weight: 400;"> The Client wishes to have <span style="background-color: #feff00;">[PROJECT DETAILS] [INCLUDE AS APPROPRIATE scoped, designed, developed, tested and ready for go-live]</span> the ("<strong>engagement</strong>")</li>
</ol>
<ol class="continue">
<li><span style="font-weight: 400;"><strong>Project Approach:</strong></span> the services provided in this SOW are on a fixed-price basis to deliver the fixed scope, as outlined in clause 8 below</li>
</li>
</ol>
<ol class="continue">
<li><strong>Scope:</strong> the project should be <span style="background-color: #feff00;">[INCLUDE AS APPROPRIATE scoped, designed, developed, tested and ready for go-live]</span> as outlined in the following Specifications and Designs:
<ol class="nested-1">
<li>Specifications
<ol class="nested-2">
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">[insert specifications]</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">[insert specifications]</span></li>
</ol>
</li>
<li>Designs
<ol class="nested-2">
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">[insert designs]</span></li>
</ol>
</li>
</ol>
</li>
</ol>
<ol class="continue">
<li><strong>Delivery schedule:</strong> Deazy will adequately resource the Project so it is ready for go-live no later than <span style="font-weight: 400; background-color: #feff00;">{{PROJECT_END_DATE}}</span>, unless the Client changes the scope or causes unnecessary delays, including (without limitation), as a result of the Client not:
<ol class="nested-1">
<li>sharing the final designs by <span style="font-weight: 400; background-color: #feff00;">[Date]</span>;</li>
<li>providing key data and technical dependencies for the Project on time; or</li>
<li>completing UAT during agreed period of [<span style="font-weight: 400; background-color: #feff00;">dates</span>]; or</li>
<li>taking decisions during the development process at the required time.</li>
</ol>
</li>
</ol>
<ol class="continue">
<li><span style="background-color: #feff00;"><strong>[IF INCLUDED IN SCOPE Product Management Services</strong>: Deazy shall provide the following services relating to product management under this SOW:</span>
<ol class="nested-1">
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400; background-color: #feff00;">Facilitate a development readiness phase working with the Client to;&nbsp;</span>
<ol class="nested-2">
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">developing an understanding of the Client&rsquo;s business, vision and the aims of the product;</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">understand product / Project requirements;</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">develop a programme of work, product roadmap and indicative schedule for Project;&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">map technical dependencies required to be delivered by the client;</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">prioritise features;&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">define the scope of each phase; and</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">plan the high level roadmap and delivery schedule;</span></li>
</ol>
</li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400; background-color: #feff00;">collaborate with the Scrum Master and the Client during development sprints to:</span>
<ol class="nested-2">
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">plan upcoming development Sprints</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">communicate progress with the Client;</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">identify any blockers or key decisions required for quick resolution or escalation;&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">support decision-making during development sprints; and</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">schedule and deliver regular product demos for the Client.</span>]</li>
</ol>
</li>
</ol>
</li>
</ol>
<ol class="continue">
<li><span style="background-color: #feff00;"><strong>[IF INCLUDED IN TEAM ALLOCATION Design Services</strong>: Deazy shall provide the following services relating to design under this SOW:</span>
<ol class="nested-1">
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400; background-color: #feff00;">work with the Product Manager and Client during development readiness phase to:&nbsp;</span>
<ol class="nested-2">
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">create [NUMBER IF APPLICABLE] low-fidelity screen wireframes for mobile and desktop;</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">create [NUMBER IF APPLICABLE] user interface screen designs for mobile and desktop;</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">supply design files of the UI to the client and development team;</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">[IF APPLICABLE a clickable prototype of the user interface designs]</span></li>
</ol>
</li>
<li><span style="font-weight: 400; background-color: #feff00;">work with the Product Manager and Client during development sprints to create:</span><br />
<ol class="nested-2">
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">support development team with supply or design assets and queries&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">ongoing design work and amends if applicable</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">design testing of developed features</span></li>
</ol>
</li>
</ol>
</li>
</ol>
<ol class="continue">
<li><strong>Software Development Services:</strong> <span style="font-weight: 400;">Deazy shall provide (either directly or with the assistance of a sub-contractor) the following services relating to software development under this SOW:</span>
<ol class="nested-1">
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">participating in the development readiness phase as required to support estimation, refinement and planning activity.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">developing the Project as outlined in clauses 7 (Project approach), 8 (Scope) and 9 (Delivery schedule);&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">project managing the development and quality assurance (&ldquo;</span><strong>QA</strong><span style="font-weight: 400;">&rdquo;) team to deliver the agreed scope according to the agreed timeline, as outlined in clauses 7 (Project approach), 8 (Scope) and 9 (Delivery schedule);</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">providing QA services for the implemented deliverables;</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">providing go-live support, including triage for production issues and prompt support for hot-fixing bugs;&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">assistance to the Client for the deployment of solutions to relevant environments;</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">submission of the latest code to the Client's code repository once the final payment is received by Deazy ("</span><strong>Code Repository</strong><span style="font-weight: 400;">"); and</span></li>
</ol>
</li>
</ol>
<p><span style="font-weight: 400;"><span class="mce-nbsp-wrap" contenteditable="false">&nbsp;&nbsp;&nbsp;</span><span class="mce-nbsp-wrap" contenteditable="false">&nbsp;&nbsp;&nbsp;</span><span class="mce-nbsp-wrap" contenteditable="false">&nbsp;&nbsp;&nbsp;</span>clauses [<span style="background-color: #feff00;">8-12</span>] are, together, the "<strong>Services</strong>".</span></p>
<ol class="continue">
<li><strong>Location of services</strong><span style="font-weight: 400;"> </span><strong>provided</strong></li>
</ol>
<p style="padding-left: 40px;"><span style="font-weight: 400;">Deazy shall work remotely. Any travel required to deliver the Services will be chargeable to the Client but all expenses must be approved in advance by the Client, in both cases in writing.</span></p>
<ol class="continue">
<li><strong>Use of subcontractors </strong></li>
</ol>
<p style="padding-left: 40px;"><span style="font-weight: 400;">The parties acknowledge and agree that Deazy may retain the services of third parties to provide the Services.&nbsp; </span></p>
<ol class="continue">
<li><strong>Non-solicitation</strong></li>
</ol>
<p style="padding-left: 40px;"><span style="font-weight: 400;">During the term of this SOW and for a period of twelve (12) months after any termination or expiry of this SOW, Client will not solicit, induce, recruit or encourage any of Deazy&rsquo;s or its subcontractors&rsquo; officers, employees, agents, advisers and other representatives to terminate their relationship with Deazy or the subcontractor (as applicable).&nbsp;</span></p>
<p style="padding-left: 40px;"><span style="font-weight: 400;">Client shall provide Deazy with notice as soon as reasonably possible if any of the events outlined in clause 15 above occurs.</span></p>
<p style="padding-left: 40px;">&nbsp;</p>
<ol class="continue">
<li><strong>Ways of working</strong></li>
</ol>
<p style="padding-left: 40px;"><span style="font-weight: 400;">The parties acknowledge and agree that the following ways of working apply to this statement of work:</span></p>
<table style="border-collapse: collapse; width: 91.0714%; height: 160px;" border="1">
<tbody>
<tr style="height: 22px;">
<td style="width: 30%; height: 22px;"><span style="background-color: #feff00;"><strong>Development Readiness (Sprint 0)</strong></span></td>
<td style="width: 49.5711%; height: 22px;">
<p><span style="font-weight: 400; background-color: #feff00;">Product refinement, design and planning will be completed by the Deazy team in collaboration with the Client.</span></p>
<br />
<p><span style="font-weight: 400; background-color: #feff00;">The Deazy Product Manager will facilitate remote product workshops and meetings to deliver the services in clause 9.1, making use of web-based collaboration tools to manage the Roadmap and Delivery Schedule.</span></p>
<p><span style="font-weight: 400; background-color: #feff00;">The Client agrees to:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #feff00;">approve the roadmap and delivery schedule within the timescales agreed during the planning process;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #feff00;">approve the scope for each phase within the timescales agreed during the planning process;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #feff00;">adhere to the decision timelines agreed.</span></li>
</ul>
<br />
<p><span style="font-weight: 400; background-color: #feff00;">Deazy will facilitate [product design] and [branding] via a number of collaborative, remote workshops and feedback sessions. Deazy will deliver the services agreed in clause 10 via various online collaboration tools.</span></p>
<p><span style="font-weight: 400; background-color: #feff00;">The Client agrees to:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #feff00;">provide relevant design assets, including photography and illustrations (if applicable);</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #feff00;">provide pre-existing branding and style guides (if applicable);</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #feff00;">provide all site copy (if applicable);&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #feff00;">provide all site data or suitable dummy data to support design (if applicable);</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #feff00;">provide feedback on designs;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #feff00;">approve the wireframes;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #feff00;">approve User Interface (UI) designs;&nbsp;</span></li>
</ul>
<br />
<p><span style="font-weight: 400; background-color: #feff00;">in a timely fashion, as per the timescales agreed.</span></p>
<p><br /><span style="background-color: #feff00;"><span style="font-weight: 400;">(together "</span><strong>Development Readiness</strong><span style="font-weight: 400;">")</span></span></p>
</td>
</tr>
<tr style="height: 22px;">
<td style="width: 30%; height: 22px;">
<p><span style="background-color: #deedd9;"><strong>Development Sprints</strong></span></p>
</td>
<td style="width: 49.5711%; height: 22px;">
<p><span style="background-color: #deedd9;"><span style="font-weight: 400;">Items of work will be collected into functional delivery groups of </span><span style="font-weight: 400; background-color: #feff00;">ten (10)</span><span style="font-weight: 400;"> business days ("Sprints").&nbsp;</span></span></p>
<p><span style="font-weight: 400; background-color: #deedd9;">The content of each Sprint will be approved by the Authorised Person from the Client or the Deazy product manager during Sprint planning.</span></p>
<br />
<p><span style="font-weight: 400; background-color: #deedd9;">The client agrees to;</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #deedd9;">review and approve ongoing design work</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #deedd9;">attend sprint refinement, standups and demo ceremonies&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #deedd9;">provide any data, technical dependencies and system access</span></li>
</ul>
<p><span style="font-weight: 400; background-color: #deedd9;">as required and in line with sprint planning and delivery schedule.</span></p>
<br />
<p><span style="font-weight: 400; background-color: #deedd9;">Deazy will carry out Quality Assurance Tests - testing items of work according to standard QA procedures and may include the following:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #deedd9;">functional testing &ndash; a feature (or ticket) delivers the functionality that was outlined in the original description; and</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #deedd9;">regression testing &ndash; testing to ensure that changes or new functionality introduced through recent development have not negatively impacted the existing functionality and stability;</span></li>
</ul>
<p><br /><span style="background-color: #deedd9;"><span style="font-weight: 400;">(together "</span><strong>Development Sprints</strong><span style="font-weight: 400;">")</span></span></p>
</td>
</tr>
<tr style="height: 22px;">
<td style="width: 30%; height: 22px;"><span style="background-color: #deedd9;"><strong>Development Phase</strong></span></td>
<td style="width: 49.5711%; height: 22px;">
<p><span style="font-weight: 400; background-color: #deedd9;">Deazy will commence development only once all specifications and designs have been approved by the Client.&nbsp;&nbsp;</span></p>
<p><span style="font-weight: 400; background-color: #deedd9;">Deazy will carry out Quality Assurance Tests - testing items of work according to standard QA procedures and may include the following:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #deedd9;">functional testing &ndash; a feature (or ticket) delivers the functionality that was outlined in the original description; and</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400; background-color: #deedd9;">regression testing &ndash; testing to ensure that changes or new functionality introduced through recent development have not negatively impacted the existing functionality and stability;</span></li>
</ul>
<p><br /><span style="background-color: #deedd9;"><span style="font-weight: 400;">(together "</span><strong>Development Phase</strong><span style="font-weight: 400;">")</span></span></p>
</td>
</tr>
<tr style="height: 50px;">
<td style="width: 30%; height: 50px;">
<p><strong>UAT and Deployment</strong></p>
</td>
<td style="width: 49.5711%; height: 50px;">
<p><span style="font-weight: 400;">The Client is responsible for User Acceptance Testing (UAT).&nbsp; The Client&rsquo;s Authorised Person accepts the functionality works in the way he/she wanted; ("</span><strong>Acceptance Tests</strong><span style="font-weight: 400;">").</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">An item of work will be considered delivered on the earlier of (i) a week from completion of the Acceptance Tests or (ii) when the Client uses the items in a production environment</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">The Client agrees to complete all acceptance tests within [</span><span style="background-color: #feff00;"><span style="font-weight: 400;">one</span><span style="font-weight: 400;"> </span></span><span style="font-weight: 400;"><span style="background-color: #feff00;">(1) week</span>]</span><span style="font-weight: 400;"> of the completion of a project. Failure to complete UAT within [</span><span style="background-color: #feff00;"><span style="font-weight: 400;">one</span><span style="font-weight: 400;"> </span></span><span style="font-weight: 400;"><span style="background-color: #feff00;">(1) week</span>]</span><span style="font-weight: 400;"> of the completion of a project assumes the client's passing of the acceptance tests.</span></li>
</ul>
<p><br /><span style="font-weight: 400;">The process for deployment to UAT and Production environments (</span><strong>&ldquo;Deployment&rdquo;</strong><span style="font-weight: 400;">) will be agreed during project kick off.</span></p>
</td>
</tr>
<tr style="height: 22px;">
<td style="width: 30%; height: 22px;"><strong>Authorised Person (s) </strong></td>
<td style="width: 49.5711%; height: 22px;">
<p><strong>Client:</strong><span style="font-weight: 400;"> </span><span style="font-weight: 400; background-color: #feff00;">[{{CLIENT_NAME}}]</span></p>
<p><strong>Deazy:</strong><span style="font-weight: 400;"> </span><span style="font-weight: 400; background-color: #feff00;">[{{DEAZY_LEAD}}]</span></p>
</td>
</tr>
<tr style="height: 22px;">
<td style="width: 30%; height: 22px;"><strong>Change Request procedure</strong></td>
<td style="width: 49.5711%; height: 22px;">
<p><span style="font-weight: 400;">Any changes to the scope of this SOW shall be approved via mutual agreement of the Authorised Persons (on behalf of Deazy and the Client) and confirmed via a signed </span><a href=""><span style="font-weight: 400;">Change Request Form</span></a><span style="font-weight: 400;">, which includes the associated impact to the Charges and delivery schedule of the engagement.</span><span style="font-weight: 400;">&nbsp;</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<ol class="continue">
<li><strong>Charges</strong></li>
</ol>
<p style="padding-left: 40px;"><span style="font-weight: 400;">This SOW will be delivered on a fixed-price basis and the following Charges shall apply:</span></p>
<table style="border-collapse: collapse; width: 90.8257%; height: 322px;" border="1">
<tbody>
<tr style="height: 50px;">
<td style="width: 12.2948%; height: 50px;">
<p><strong>&nbsp;</strong></p>
</td>
<td style="width: 41.5796%; height: 50px;">
<p><strong>Item</strong></p>
</td>
<td style="width: 15.2778%; height: 50px;"><span style="background-color: #ffffff;"><strong>Sprint cost</strong></span></td>
<td style="width: 15.4128%; height: 50px;"><span style="background-color: #ffffff;"><strong>Number of sprints</strong></span></td>
<td style="width: 13.7934%; height: 50px;"><strong>Total </strong><strong><br /></strong><strong>(Ex VAT)</strong></td>
</tr>
<tr style="height: 50px;">
<td style="width: 12.2948%; height: 50px;">
<p>1</p>
</td>
<td style="width: 41.5796%; height: 50px;">
<p><span style="background-color: #feff00;"><strong><span style="font-weight: 400;">Development Readiness (Sprint 0)</span></strong></span></p>
</td>
<td style="width: 15.2778%; height: 50px;">{{CURRENCY}}</td>
<td style="width: 15.4128%; height: 50px;">1</td>
<td style="width: 13.7934%; height: 50px;">{{STAGE_1_COST}}</td>
</tr>
<tr style="height: 122px;">
<td style="width: 12.2948%; height: 122px;">
<p>2</p>
</td>
<td style="width: 41.5796%; height: 122px;">
<p><strong><span style="font-weight: 400;">Development Sprints - including<span style="background-color: #ffffff;"> </span></span><span style="font-weight: 400; background-color: #feff00;">[INCLUDE AS APPROPRIATE Product Management, Design, Development, Tech Lead, QA and Scrum Master]</span></strong></p>
</td>
<td style="width: 15.2778%; height: 122px;">{{CURRENCY}}</td>
<td style="width: 15.4128%; height: 122px;"><span style="background-color: #feff00;">x</span></td>
<td style="width: 13.7934%; height: 122px;">{{STAGE_2_COST}}</td>
</tr>
<tr style="height: 50px;">
<td style="width: 12.2948%; height: 50px;">
<p>3</p>
</td>
<td style="width: 41.5796%; height: 50px;">
<p><span style="font-weight: 400;">UAT &amp; Deployment Sprint </span></p>
</td>
<td style="width: 15.2778%; height: 50px;">{{CURRENCY}}</td>
<td style="width: 15.4128%; height: 50px;">1</td>
<td style="width: 13.7934%; height: 50px;">{{STAGE_3_COST}}</td>
</tr>
<tr style="height: 50px;">
<td style="width: 12.2948%; height: 50px;">
<p>&nbsp;</p>
</td>
<td style="width: 41.5796%; height: 50px;">
<p><strong>Total</strong></p>
</td>
<td style="width: 15.2778%; height: 50px;">&nbsp;</td>
<td style="width: 15.4128%; height: 50px;">&nbsp;</td>
<td style="width: 13.7934%; height: 50px;"><strong>{{TOTAL_STAGES_COST}}</strong></td>
</tr>
</tbody>
</table>
<p><span style="background-color: #ffffff;"><span style="font-weight: 400;">(the "<strong>Charges</strong>")</span></span></p>
<p>&nbsp;</p>
<p><strong><span style="background-color: #ffffff;">Payment schedule</span></strong></p>
<ol class="continue">
<li><span style="font-weight: 400;">Deazy will invoice as follows:</span></li>
</ol>
<p>&nbsp;</p>

{{PREPOPULATED_TABLE}}

<p>&nbsp;</p>
<ol class="continue">
<li aria-level="1"><span style="font-weight: 400;">All remaining Charges will be payable by the Client within </span><span style="font-weight: 400; background-color: #feff00;">[DELETE AS APPROPRIATE 14 or 30 or 60 days]</span><span style="font-weight: 400;"> of the invoice date, in accordance with the Terms.</span></li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<table style="border-collapse: collapse; width: 93.2318%; height: 136px;" border="0">
<tbody>
<tr style="height: 118px;">
<td style="width: 49.6824%; height: 118px;">
<p><strong>Signed</strong><span style="font-weight: 400;"> by </span><span style="background-color: #feff00;"><strong>[DEAZY LEADERSHIP TEAM MEMBER]</strong></span> <span style="font-weight: 400;">duly authorised for and on behalf of </span><strong>Deazy Limited</strong></p>
</td>
<td style="width: 49.6824%; height: 118px;">
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-weight: 400;">..................................................................................................</span></p>
<p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<span style="font-weight: 400;">Director</span></p>
</td>
</tr>
<tr style="height: 18px;">
<td style="width: 49.6824%; height: 18px;">
<p style="text-align: left;"><strong>Signed<span style="font-weight: 400;"> by </span><span style="background-color: #feff00;">[NAME OF AUTHORISED SIGNATORY]</span></strong> <strong><span style="font-weight: 400;">duly authorised for and on behalf of </span><span style="background-color: #feff00;">{{CLIENT}}</span></strong></p>
</td>
<td style="width: 49.6824%; height: 18px;">
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-weight: 400;">..................................................................................................</span></p>
<p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<span style="font-weight: 400;">Authorised Signatory</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p>&nbsp;</p>
`;
