import { CURRENCY_OPTIONS } from '@app/constants';
import { Box, Flex, SimpleGrid, useDisclosure } from '@chakra-ui/react';
import Select from '@components/forms/_common/Select';
import TextInput from '@components/forms/_common/TextInput';
import WhiteCard from '@components/WhiteCard';
import Badge from '@styles/Badge';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import {
  composeValidators,
  mustBeShorterOrEqual,
  required,
} from '@utils/formValidators';
import {
  isNumber,
  isString,
  map,
  isEmpty,
  includes,
  reject,
  filter,
} from 'lodash-es';
import { nanoid } from 'nanoid';
import FormError from '@components/forms/_common/FormError';
import countries from '@app/countries';
import React from 'react';
import { Field, Form } from 'react-final-form';
import ZoneContainer from '@components/ZoneContainer';
import MakePrimaryCurrencyConfirmationModal from './MakePrimaryCurrencyConfirmationModal';
import RemoveCurrencyConfirmationModal from './RemoveCurrencyConfirmationModal';

export const CurrencyFormFields = ({
  values,
  form,
  removeCurrencyFromSupplier,
  setCurrencyAsPrimaryForSupplier,
}) => {
  const {
    isOpen: isOpenToRemove,
    onOpen: onOpenToRemove,
    onClose: onCloseToRemove,
  } = useDisclosure();
  const onAddNewCurrency = () => {
    form.change('paymentDetails', [
      ...(values.paymentDetails || []),
      { id: nanoid(10), isPrimary: isEmpty(values.paymentDetails) },
    ]);
  };
  const onRemoveNewCurrency = id => {
    form.change('paymentDetails', reject(values.paymentDetails, { id }));
  };
  const [currencyToRemoveId, setCurrencyToRemoveId] = React.useState(null);
  return (
    <>
      {map(values.paymentDetails, (currency, idx) => {
        const onToggle = () =>
          form.change(`paymentDetails[${idx}].isOpen`, !currency.isOpen);

        const changePrimaryCurrencyInForm = () =>
          form.change(
            `paymentDetails`,
            map(values.paymentDetails, (c, i) => ({
              ...c,
              isPrimary: idx === i,
            })),
          );

        const currencyOptions = map(CURRENCY_OPTIONS, ({ value, label }) => ({
          isDisabled: includes(
            map(values.paymentDetails, c => c.currency),
            value,
          ),
          value,
          label,
        }));
        const isRemoveable = currency?.id && !currency?.isPrimary;

        const prepareToRemoveCurrency = currencyId => {
          onOpenToRemove();
          if (isString(currencyId)) {
            return onRemoveNewCurrency(currencyId);
          }
          return setCurrencyToRemoveId(currencyId);
        };

        return (
          <WhiteCard
            key={`currency-card-${currency.id}`}
            _notLast={{ mb: '40px' }}
            mt="0px"
          >
            <Flex alignItems="center" justifyContent="space-between" mb="24px">
              <Typography variant="h3">Currency</Typography>
              <Flex alignItems="center">
                {currency?.isPrimary ? (
                  <Badge variant="tertiary" bg="primary.500">
                    Primary currency
                  </Badge>
                ) : (
                  <>
                    <Button
                      leftIcon={{ fontSize: 24, name: 'sync', mr: '0px' }}
                      variant="ghost"
                      size="lg"
                      p="8px 0px"
                      onClick={onToggle}
                    >
                      Make primary currency
                    </Button>
                    {isRemoveable && (
                      <Button
                        iconButton={{
                          name: 'trashNew',
                          fontSize: 16,
                          color: 'black',
                        }}
                        bg="transparent"
                        ml="16px"
                        onClick={() => prepareToRemoveCurrency(currency.id)}
                      />
                    )}
                  </>
                )}
              </Flex>
              <MakePrimaryCurrencyConfirmationModal
                onConfirm={async () => {
                  if (isString(currency.id)) {
                    changePrimaryCurrencyInForm();
                  } else {
                    await setCurrencyAsPrimaryForSupplier(
                      values.id,
                      currency.id,
                      () => changePrimaryCurrencyInForm(),
                    );
                  }
                  return onToggle();
                }}
                isOpen={!currencyToRemoveId && currency.isOpen}
                onClose={onToggle}
              />
            </Flex>
            <SimpleGrid columns={2} spacing="24px" mb="24px">
              <Field
                name={`paymentDetails[${idx}].currency`}
                component={Select}
                isDisabled={isNumber(currency.id)}
                options={currencyOptions}
                label="Currency"
                validate={required}
                placeholder="Please select..."
              />
            </SimpleGrid>
            <Typography variant="h5" mb="24px">
              Account information associated with this currency:
            </Typography>
            <SimpleGrid columns={2} spacing="24px">
              <Box>
                <Field
                  name={`paymentDetails[${idx}].accountNumber`}
                  component={TextInput}
                  label="Account number"
                  placeholder="Enter account number"
                />
              </Box>
              <Box>
                <Field
                  name={`paymentDetails[${idx}].addressLine1`}
                  component={TextInput}
                  validate={composeValidators(
                    required,
                    mustBeShorterOrEqual(250),
                  )}
                  label="Address line 1"
                  placeholder="First line of the address"
                />
              </Box>
              <Box>
                <Field
                  name={`paymentDetails[${idx}].sortCode`}
                  component={TextInput}
                  label="Sort code"
                  placeholder="Enter sort code"
                />
              </Box>
              <Box>
                <Field
                  name={`paymentDetails[${idx}].addressLine2`}
                  component={TextInput}
                  label="Address line 2"
                  placeholder="Second line of the address"
                />
              </Box>
              <Box>
                <Field
                  name={`paymentDetails[${idx}].IBAN`}
                  component={TextInput}
                  label="IBAN"
                  placeholder="Add IBAN number"
                />
              </Box>
              <Box>
                <Field
                  name={`paymentDetails[${idx}].city`}
                  component={TextInput}
                  label="City"
                  validate={composeValidators(
                    required,
                    mustBeShorterOrEqual(250),
                  )}
                  placeholder="Enter name of city"
                />
              </Box>
              <Box>
                <Field
                  name={`paymentDetails[${idx}].BIC`}
                  component={TextInput}
                  label="BIC"
                  placeholder="Add a BIC number"
                />
              </Box>
              <Box>
                <Field
                  name={`paymentDetails[${idx}].postalCode`}
                  component={TextInput}
                  label="Postal code"
                  placeholder="Enter postal code"
                />
              </Box>
              <Box>
                <Field
                  name={`paymentDetails[${idx}].bankCountry`}
                  component={Select}
                  options={countries}
                  label="Recipient bank country"
                  validate={required}
                  isSearchable
                  placeholder="Select recipient bank country"
                />
              </Box>
            </SimpleGrid>
            <Field name={`currencyError${idx}`}>
              {({ meta }) => <FormError meta={meta} mt="40px" fontSize={22} />}
            </Field>
          </WhiteCard>
        );
      })}
      <RemoveCurrencyConfirmationModal
        onConfirm={async () => {
          await removeCurrencyFromSupplier(
            values.id,
            currencyToRemoveId,
            () => {
              form.change(
                `paymentDetails`,
                filter(values.paymentDetails, c => c.id !== currencyToRemoveId),
              );
            },
          );
          setCurrencyToRemoveId(null);
        }}
        isOpen={currencyToRemoveId && isOpenToRemove}
        onClose={() => {
          onCloseToRemove();
          setCurrencyToRemoveId(null);
        }}
      />
      {(values?.paymentDetails || []).length < 3 && (
        <ZoneContainer bg="#fff" py="32px" borderColor="dark.mid" my="40px">
          <Button
            onClick={onAddNewCurrency}
            variant="outline"
            leftIcon={{ name: 'add' }}
            size="lg"
          >
            {isEmpty(values.paymentDetails)
              ? 'Add currency'
              : 'Add another currency'}
          </Button>
        </ZoneContainer>
      )}
    </>
  );
};

const CurrencyForm = ({ initialValues, onSubmit = () => {} }) => {
  return (
    <Form
      initialValues={{ ...initialValues }}
      onSubmit={onSubmit}
      render={({ handleSubmit, values, form }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <CurrencyFormFields values={values} form={form} />
          </form>
        );
      }}
    />
  );
};

export default CurrencyForm;
