import React from 'react';
import { Box, Grid, Flex } from '@chakra-ui/react';
import moment from 'moment';
import Typography from '@styles/Typography';
import { isEmpty, map } from 'lodash-es';
import ReactCountryFlag from 'react-country-flag';

const HolidaysTable = ({ holidays, label }) => {
  const isWindows = navigator.userAgent.indexOf('Win') !== -1;
  return (
    <Box mb="24px">
      {!isEmpty(holidays) && (
        <Box
          bg="#fff"
          borderRadius="8px"
          p="40px 32px"
          border="1px solid #D9DADA"
        >
          {label && (
            <Typography mb="24px" variant="h2">
              {label}
            </Typography>
          )}
          {map(holidays, holiday => (
            <Box mb="24px" key={`holiday-month=${holiday.month}`}>
              <Typography variant="h4" mb="8px">
                {holiday.month}
              </Typography>
              {map(holiday.items, item => (
                <Grid
                  borderBottom="1px solid black"
                  height="39px"
                  borderColor="#D9DADA"
                  alignItems="center"
                  justifyContent="space-between"
                  gridTemplateColumns="1fr 1fr 1fr 348px 1fr"
                  gridColumnGap="8px"
                  key={`holiday=${item.id}`}
                >
                  <Box textAlign="left" pl="12px">
                    <Typography lineHeight="22px">
                      {moment(item.date).format('DD/MM/YYYY')}
                    </Typography>
                  </Box>
                  <Box textAlign="left">
                    <Typography lineHeight="22px">
                      {moment(item.date).format('dddd')}
                    </Typography>
                  </Box>
                  <Flex alignItems="center">
                    <ReactCountryFlag
                      className="emojiFlag"
                      countryCode={item.countryCode}
                      style={{
                        height: isWindows && 'auto',
                        marginTop: isWindows && '-1px',
                        filter: isWindows && 'drop-shadow(0px 0px 0.4px #000)',
                      }}
                      svg={isWindows}
                      aria-label={item.country}
                    />
                    <Typography lineHeight="22px" ml="12px">
                      {item.country}
                    </Typography>
                  </Flex>
                  <Box textAlign="left" ml="40px">
                    <Typography lineHeight="22px">{item.name}</Typography>
                  </Box>
                </Grid>
              ))}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default HolidaysTable;
