import { Box, Flex, Icon, Textarea as ChakraTextArea } from '@chakra-ui/react';
import Tooltip from '@styles/Tooltip';
import Typography from '@styles/Typography';
import React from 'react';
import FormLabel from '../FormLabel';
import FormError from './FormError';

const Textarea = ({
  label,
  placeholder,
  input,
  meta,
  labelColor,
  inputProps = {},
  labelProps = {},
  rows = 4,
  counter,
  tooltip,
  isDisabled,
  ...props
}) => {
  const hasError =
    (meta.touched || meta.submitFailed) && (meta.error || meta.submitError);
  return (
    <Box position="relative" {...props}>
      <Flex alignItems="flex-start">
        <FormLabel label={label} name={input.name} {...labelProps} />
        {tooltip && (
          <Tooltip label={tooltip} placement="bottom">
            <Box as="span">
              <Icon name="infoFilled" color="gray.400" mb="8px" />
            </Box>
          </Tooltip>
        )}
      </Flex>
      <ChakraTextArea
        minH="136px"
        w="100%"
        isDisabled={isDisabled}
        borderRadius="8px"
        borderWidth="1px"
        borderColor={hasError ? 'red.solid' : 'dark.mid'}
        rows={rows}
        p="16px"
        lineHeight="22px"
        resize="none"
        cursor={isDisabled ? 'not-allowed' : 'default'}
        _focus={{
          outline: 'none',
        }}
        _hover={{
          outline: 'none',
          cursor: 'text',
        }}
        placeholder={placeholder}
        _placeholder={{ color: 'dark.mid' }}
        _disabled={{ bg: '#fff' }}
        {...inputProps}
        {...input}
      />
      {counter > 0 && (
        <Typography
          variant="text3"
          color="dark.solid"
          textAlign="right"
          position="absolute"
          right={0}
          bottom="-20px"
        >{`${input.value.length}/${counter}`}</Typography>
      )}
      <FormError isErrorAbsolute={counter > 0} meta={meta} />
    </Box>
  );
};

export default Textarea;
