import React from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { Field } from 'react-final-form';
import { map, find, isEmpty } from 'lodash-es';
import { required } from '@utils/formValidators';
import AsyncAutocomplete from '@components/forms/_common/AsyncAutocomplete';
import ProjectAllocations from '@pages/ProjectsCreate/ProjectAllocations';
import { API } from '@app/api';
import { formatNumber } from '@utils';
import Select from '@components/forms/_common/Select';
import CostRow from '@pages/Brief/CostRow';
import {
  getAllocationMultiplier,
  getClientAllocationTotal,
} from '@utils/projectUtils';
import SubmitFailedError from '@pages/ProjectsCreate/SubmitFailedError';
import Typography from '@styles/Typography';

const cellProps = {
  width: 'calc(33% - 32px)',
  pb: '40px',
  maxWidth: '401px',
};

const Wrapper = ({ children, ...props }) => (
  <Box p="40px" bg="#fff" borderRadius="8px" {...props}>
    {children}
  </Box>
);

const FieldsSection = ({ title, children, ...props }) => (
  <Box {...props}>
    <Typography variant="h2" mb="24px">
      {title}
    </Typography>
    <Stack isInline spacing="40px" flexWrap="wrap">
      {children}
    </Stack>
  </Box>
);

const AddSingleDPSection = ({
  formApi,
  mainNameQA = 'new-delivery-partner-to-project',
  fieldPrefix,
  project,
  pts,
}) => {
  const { startDate, endDate, totalBasis } = project;
  const { allocations } = pts;
  const allocationMultiplier = React.useMemo(() => {
    const multiplier = getAllocationMultiplier({
      startDate,
      endDate,
      totalBasis,
    });
    return totalBasis ? multiplier : 1;
  }, [startDate, endDate, totalBasis]);
  const getTotal = () => {
    return getClientAllocationTotal(
      {
        allocations,
      },
      allocationMultiplier,
    );
  };
  const currencies = pts?.supplier?.paymentDetails;
  return (
    <Wrapper mb="40px">
      <FieldsSection>
        <Box {...cellProps}>
          <Field
            label="Delivery Partner name"
            name={`${fieldPrefix}.supplier`}
            validate={required}
            data-test-id={`${mainNameQA}--supplier`}
            component={AsyncAutocomplete}
            getOptionsFn={API.searchSuppliers}
            onAfterChange={opt => {
              const primaryCurrency = find(
                opt?.paymentDetails,
                c => c.isPrimary,
              );
              formApi.change(`${fieldPrefix}.supplierLead`, null);
              formApi.change(
                `${fieldPrefix}.overriddenCurrency`,
                primaryCurrency?.currency,
              );
            }}
            placeholder="Please select"
          />
        </Box>
        <Box {...cellProps}>
          <Field
            label="Delivery Partner lead"
            component={AsyncAutocomplete}
            getOptionsFn={API.searchSupplierAdmins(pts?.supplier?.id)}
            customOptionMap={option => ({
              value: option,
              label: `${option.fullname} <${option.email}>`,
            })}
            name={`${fieldPrefix}.supplierLead`}
            key={`deliveryPartner=${pts?.supplier?.id}`}
            data-test-id={`${mainNameQA}--supplierLead`}
            placeholder="Please select"
            validate={required}
            isDisabled={isEmpty(pts.supplier)}
          />
        </Box>
        <Box {...cellProps}>
          <Field
            label="Delivery Partner currency"
            component={Select}
            name={`${fieldPrefix}.overriddenCurrency`}
            validate={required}
            options={map(currencies, c => ({
              value: c.currency,
              label: c.currency,
            }))}
            isDisabled={isEmpty(pts.supplier)}
            placeholder="Please select"
          />
        </Box>
      </FieldsSection>
      <Box bg="gray.100" p="32px">
        <ProjectAllocations
          label="Weekly allocation"
          p="32px"
          fieldName={`${fieldPrefix}.allocations`}
        />
        <SubmitFailedError fieldName={`${fieldPrefix}.allocation`} />

        <CostRow
          mt="24px"
          bg="#fff"
          title="Total amount"
          currency={project.currency}
          cost={formatNumber(getTotal())}
          showHours={false}
          mainNameQA={mainNameQA}
          id="costForDP"
        />
      </Box>
    </Wrapper>
  );
};

export default AddSingleDPSection;
