import { Link } from 'react-router-dom';
import React from 'react';
import Button from '@styles/Button';

const SeeAllButton = ({ to, children, ...props }) => {
  return (
    <Button size="lg" as={Link} to={to} {...props}>
      {children}
    </Button>
  );
};

export default SeeAllButton;
