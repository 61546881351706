import { API } from '@app/api';
import { Box, Spinner, Stack } from '@chakra-ui/react';
import ErrorSpy from '@components/forms/ErrorSpy';
import RichTextEditorInput from '@components/forms/RichTextEditorInput';
import AsyncAutocomplete from '@components/forms/_common/AsyncAutocomplete';
import InfoAlert from '@components/InfoAlert';
import useMediaQuery from '@hooks/useMediaQuery';
import ClientCreateModal from '@pages/UserCreate/ClientCreateModal';
import ClientLeadCreateModal from '@pages/UserCreate/ClientLeadCreateModal';
import { breakpoint } from '@styles/breakpoints';
import Typography from '@styles/Typography';
import { fileSizeValidation, sleep } from '@utils';
import {
  composeValidators,
  mustBeShorterOrEqual,
  mustBeShorterOrEqualForHTML,
  required,
  validateBriefName,
} from '@utils/formValidators';
import { get, isEmpty, omit } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form } from 'react-final-form';
import WizardFooter from '../WizardFooter';
import WizardLeavingGuard from '../WizardLeavingGuard';
import Dropzone from './Dropzone';
import ProjectNameInput from './__LegacyProjectNameInput';

const titleStyles = (isSmallerScreen, withSubTitle) => {
  const mb = withSubTitle ? 4 : 6;

  return isSmallerScreen
    ? {
        mt: 6,
        mb: mb / 2,
      }
    : {
        mt: 14,
        mb,
      };
};

const subTitleStyles = isSmallerScreen =>
  isSmallerScreen
    ? {
        mb: 4,
      }
    : {
        mb: 8,
      };

const ProjectBasicsForm = ({
  initialValues,
  customerId,
  isProfileLoaded,
  teamsStore: { createClient, clearNewClient },
  usersStore: { createUserByRole, isClient, isAdminOrDL },
  submit,
  onSubmit,
  mainNameQA,
  toastsStore,
}) => {
  const [clientIntialValues, setClientInitialValues] = React.useState({});
  const [clientLeadIntialValues, setClientLeadInitialValues] = React.useState(
    {},
  );
  const [customer, setCustomer] = React.useState(initialValues.customer || {});
  const [clientLead, setClientLead] = React.useState(
    initialValues.clientLead || {},
  );
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  const {
    onCreateClientModalOpen,
    onCreateClientModalClose,
    isCreateClientModalOpen,
  } = React.useMemo(() => {
    return {
      isCreateClientModalOpen: !isEmpty(clientIntialValues),
      onCreateClientModalOpen: setClientInitialValues,
      onCreateClientModalClose: () => setClientInitialValues({}),
    };
  }, [JSON.stringify(clientIntialValues)]);

  const {
    onCreateClientLeadModalOpen,
    onCreateClientLeadModalClose,
    isCreateClientLeadModalOpen,
  } = React.useMemo(() => {
    return {
      isCreateClientLeadModalOpen: !isEmpty(clientLeadIntialValues),
      onCreateClientLeadModalOpen: setClientLeadInitialValues,
      onCreateClientLeadModalClose: () => setClientLeadInitialValues({}),
    };
  }, [JSON.stringify(clientLeadIntialValues)]);

  let formApi = {};

  React.useEffect(() => {
    if (!isEmpty(customer) && !isEmpty(formApi)) {
      formApi.change('customer', customer);
      formApi.change('clientLead', null);
    }
    if (isEmpty(customer) && !isEmpty(formApi)) {
      formApi.change('customer', null);
      formApi.change('clientLead', null);
    }
  }, [customer]);

  React.useEffect(() => {
    if (!isEmpty(clientLead) && !isEmpty(formApi)) {
      formApi.change('clientLead', clientLead);
    }
    if (isEmpty(clientLead) && !isEmpty(formApi)) {
      formApi.change('clientLead', null);
    }
  }, [clientLead]);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={fileSizeValidation}
        render={({ handleSubmit, form, submitting, errors, values, dirty }) => {
          submit(handleSubmit);
          formApi = form;
          const isRoughIdeas = values.projectStage === 'rough';
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <ErrorSpy
                title={
                  !isEmpty(omit(errors, 'files'))
                    ? 'Please complete all the required sections'
                    : 'Max file size is 10MB.'
                }
              />
              {!isProfileLoaded ? (
                <Spinner my="60px" mx="auto" size="xl" display="block" />
              ) : (
                <Box>
                  {!customerId && (
                    <>
                      <Typography variant="h2" mb={isSmallerScreen ? 3 : 6}>
                        Select a client for whom you would like to create a
                        project
                      </Typography>

                      <Stack
                        spacing={6}
                        {...(isSmallerScreen
                          ? { direction: 'column', mb: '24px' }
                          : { direction: 'row', mb: '56px' })}
                      >
                        <Field
                          name="customer"
                          label="Client name"
                          data-test-id={`${mainNameQA}--customer`}
                          validate={required}
                          component={AsyncAutocomplete}
                          isCreatable={isAdminOrDL}
                          getOptionsFn={API.searchClients}
                          isValidNewOption={() => true}
                          onCreateOption={v => {
                            onCreateClientModalOpen({ name: v || '' });
                          }}
                          placeholder="Select or type client name"
                          onAfterChange={async (c = {}) => {
                            setCustomer(c);
                            const cId = get(values, 'customer.id');
                            const { name } = values;
                            if (cId !== c?.id || customer.id !== cId) {
                              setClientLead(null);
                              form.change('clientLead', {});
                              await sleep(10);
                              form.change('clientLead', null);
                              if (name) {
                                form.change('name', `${name} `);
                                await sleep(50);
                                form.change('name', name);
                              }
                            }
                          }}
                        />
                        {!isClient && (
                          <Field
                            label="Main client contact"
                            name="clientLead"
                            data-test-id={`${mainNameQA}--clientLead`}
                            validate={required}
                            isDisabled={!get(values, 'customer.id')}
                            component={AsyncAutocomplete}
                            isCreatable={isAdminOrDL}
                            isValidNewOption={() => true}
                            onCreateOption={v => {
                              onCreateClientLeadModalOpen({
                                fullname: v,
                                client: values.customer,
                              });
                            }}
                            reloadOnEmpty
                            getOptionsFn={API.searchClientContactUsers(
                              get(values, 'customer.id'),
                            )}
                            placeholder="Select or type client contact"
                            onAfterChange={async (c = {}) => {
                              setClientLead(c);
                            }}
                            customOptionMap={option => ({
                              label: option.fullname || option.username,
                              value: option,
                            })}
                          />
                        )}
                      </Stack>
                    </>
                  )}

                  <>
                    <Typography
                      variant="h2"
                      {...titleStyles(isSmallerScreen, true)}
                    >
                      Give the project a name
                    </Typography>
                    <Typography {...subTitleStyles(isSmallerScreen)}>
                      Give us an overview of the key aims of the project and the
                      type of product you&apos;re imagining.
                    </Typography>
                    <Field
                      name="name"
                      data-test-id={`${mainNameQA}--name`}
                      placeholder="Type new project name"
                      label="Project Name"
                      component={ProjectNameInput}
                      validate={composeValidators(
                        required,
                        mustBeShorterOrEqual(200),
                        validateBriefName,
                      )}
                    />
                  </>
                </Box>
              )}

              <Typography variant="h2" {...titleStyles(isSmallerScreen, true)}>
                What&apos;s the elevator pitch?
              </Typography>
              <Typography {...subTitleStyles(isSmallerScreen)}>
                Give us an overview of the key aims of the project and the type
                of product you&apos;re imagining.
              </Typography>
              <Field
                name="description"
                data-test-id={`${mainNameQA}--description`}
                validate={composeValidators(
                  required,
                  mustBeShorterOrEqualForHTML(2500),
                )}
                render={props => (
                  <RichTextEditorInput
                    initialValue={initialValues.description}
                    height="400px"
                    isResponsive={!isSmallerScreen}
                    width="100vw"
                    isErrorAbsolute={!isSmallerScreen}
                    isFormField
                    isEditorForUser
                    id={`${mainNameQA}--richText`}
                    {...props}
                  />
                )}
                {...(isSmallerScreen
                  ? { w: '100%', m: 0 }
                  : { m: '24px auto 45px' })}
              />
              <Typography variant="h2" {...titleStyles(isSmallerScreen, true)}>
                Upload your brief{' '}
              </Typography>
              <InfoAlert
                mb={isSmallerScreen && '16px'}
                label="Uploading a brief document allows us to be much quicker at providing an accurate proposal."
              />

              <Typography {...subTitleStyles(isSmallerScreen)}>
                If you have another additional files please add them here too.
                <br />
                E.g. spec documentaion, wireframes, brand guidelines will help
                us make our quote more accurate.
              </Typography>
              <Box m={isSmallerScreen ? '24px 0 56px' : '32px 0 0'}>
                <Field
                  component={Dropzone}
                  name="files"
                  data-test-id={`${mainNameQA}--files`}
                  errors={errors.files}
                  file={initialValues.files}
                  width="100%"
                />
              </Box>
              <WizardFooter
                submitting={submitting || !isProfileLoaded}
                isRoughIdeas={isRoughIdeas}
                mainNameQA={mainNameQA}
              />
              <WizardLeavingGuard isFormDirty={dirty} />
            </form>
          );
        }}
      />
      <ClientCreateModal
        isOpen={isCreateClientModalOpen}
        onClose={onCreateClientModalClose}
        initialValues={clientIntialValues}
        showIncompleteForm
        onSubmit={clientValues =>
          createClient({
            values: clientValues,
            onSuccess: clientResponse => {
              onCreateClientModalClose();
              clearNewClient();
              setCustomer(clientResponse);
            },
          })
        }
      />
      <ClientLeadCreateModal
        isOpen={isCreateClientLeadModalOpen}
        initialValues={clientLeadIntialValues}
        onClose={onCreateClientLeadModalClose}
        showIncompleteForm
        onSubmit={clientLeadValues =>
          createUserByRole({
            role: 'CL',
            values: { ...clientLeadValues, username: clientLeadValues.email },
            onSuccess: clientLeadResponse => {
              onCreateClientLeadModalClose();
              setClientLead(clientLeadResponse);
            },
            onFailure: e => {
              toastsStore.showError({
                title:
                  e.message || 'Something went wrong. Please, try again later.',
              });
            },
          })
        }
      />
    </>
  );
};

export default inject(
  'teamsStore',
  'usersStore',
  'toastsStore',
)(observer(ProjectBasicsForm));
