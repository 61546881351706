import { Box, Flex } from '@chakra-ui/react';
import Button from '@styles/Button';
import theme from '@styles/theme';
import Typography from '@styles/Typography';
import React from 'react';

const buttonProps = {
  bg: theme.colors.gray[200],
  color: '#fff',
  width: '40px',
  borderRadius: '100%',
};

const BulletRide = ({ bulletIndex, index }) => {
  return (
    <Box mx="5px">
      <Box
        height="8px"
        width="8px"
        borderRadius="100%"
        bg={
          index === bulletIndex
            ? theme.colors.primary[400]
            : theme.colors.gray[200]
        }
      />
    </Box>
  );
};

const CarouselHeader = ({
  length,
  index,
  setIndex,
  supplierName,
  isSupplierPart,
  mainNameQA,
}) => {
  const onPrev = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };
  const onNext = () => {
    if (index < length - 1) {
      setIndex(index + 1);
    }
  };

  return (
    <>
      {isSupplierPart ? (
        <Flex
          direction="column"
          alignItems="center"
          width="100%"
          p="32px"
          pb="12px"
        >
          {' '}
          {length > 1 ? (
            <Flex
              alignItems="center"
              width="100%"
              justifyContent="space-between"
            >
              <Button
                iconButton={{ name: 'arrowLeft', fontSize: 24 }}
                mr="8px"
                {...buttonProps}
                cursor={index <= 0 ? 'not-allowed' : 'pointer'}
                opacity={index <= 0 ? 0.5 : 1}
                onClick={onPrev}
                data-test-id={`${mainNameQA}--prevSupplier`}
              />
              <Typography variant="h4" data-test-id={`${mainNameQA}--supplier`}>
                {supplierName}
              </Typography>
              <Button
                iconButton={{ name: 'arrowLeft', fontSize: 24 }}
                {...buttonProps}
                transform="rotate(180deg)"
                cursor={index >= length - 1 ? 'not-allowed' : 'pointer'}
                opacity={index >= length - 1 ? 0.5 : 1}
                onClick={onNext}
                data-test-id={`${mainNameQA}--nextSupplier`}
              />
            </Flex>
          ) : (
            <Typography variant="h4" data-test-id={`${mainNameQA}--supplier`}>
              {supplierName}
            </Typography>
          )}
          {length > 1 ? (
            <Flex width="100%" justifyContent="center" px="40px">
              {Array(length)
                .fill()
                .map((_, i) => (
                  <BulletRide bulletIndex={i} index={index} />
                ))}
            </Flex>
          ) : (
            <Box height="8px" />
          )}
        </Flex>
      ) : (
        <Flex
          direction="column"
          alignItems="center"
          width="100%"
          p="32px"
          pb="12px"
        >
          <Typography variant="h4" data-test-id={`${mainNameQA}--client`}>
            Client
          </Typography>
          <Box height="8px" />
        </Flex>
      )}
    </>
  );
};

export default CarouselHeader;
