import { Box } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { map } from 'lodash-es';
import styled from '@emotion/styled';
import React from 'react';

const getActiveListReasons = () => {
  return [
    'Configure your team',
    'Estimate against features',
    'Review your cost',
    'Finally, supply a summary and any risks',
  ];
};

const StyledWrapper = styled(({ isSmallerScreen, ...props }) => (
  <Box {...props} />
))`
  overflow: hidden;
  position: relative;
  max-width: 671px;
  border-radius: 8px;
  padding: 32px 40px 40px 40px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${({ theme }) => theme.colors.dark.mid};
  ${({ isSmallerScreen }) =>
    isSmallerScreen &&
    `
    border: none;
    background-color: #fff;
    padding: 20px 16px;
  &::before {
    content: '';
    position: absolute;
    border-width: 9px;
    border-color: ${({ theme }) => theme.colors.gray.light};
    border-style: dashed;
    top: -8px;
    bottom: -8px;
    left: -8px;
    right: -8px;
  }`})}
`;

const ProposalEmpty = ({
  setStartProposal,
  briefId,
  initializeProposal,
  closedBrief,
  mainNameQA,
  isSmallerScreen,
}) => {
  return (
    <Box py={isSmallerScreen ? '20px' : '44px'} maxW="671px" w="100%">
      <Typography
        variant="h3"
        mb={isSmallerScreen ? '16px' : '40px'}
        maxW="480px"
      >
        Proposal Creation
      </Typography>
      <StyledWrapper isSmallerScreen={isSmallerScreen}>
        <Box maxW="520px">
          <Typography variant="h3" mb="16px">
            Create proposal to set up a project
          </Typography>

          <Typography mb="24px">
            We’ve broken the proposal into 4 sections. We’ll ask about to:
          </Typography>

          <Box as="ul">
            {map(getActiveListReasons(), item => (
              <Typography
                as="li"
                fontFamily="WM"
                mb={isSmallerScreen ? '17px' : '8px'}
                key={item}
                fontSize={isSmallerScreen && '13px'}
              >
                <Icon
                  name="arrowLeft"
                  color="blue.solid"
                  fontSize={24}
                  transform="rotate(180deg)"
                  mr="15px"
                  {...(isSmallerScreen && {
                    mr: '12px',
                    name: 'mediumArrow',
                    transform: 'none',
                    fontSize: 16,
                  })}
                />
                {item}
              </Typography>
            ))}
          </Box>
          <Typography mt={isSmallerScreen ? '32px' : '30px'}>
            You can also create briefs on behalf of a client.
          </Typography>

          <Button
            mt={isSmallerScreen ? '32px' : '30px'}
            leftIcon={{ name: 'mediumArrow' }}
            color="#fff"
            isDisabled={closedBrief}
            onClick={() => {
              setStartProposal(true);
              initializeProposal(briefId);
            }}
            data-test-id={`${mainNameQA}--start-proposal`}
          >
            Start Proposal
          </Button>
        </Box>
      </StyledWrapper>
    </Box>
  );
};

export default ProposalEmpty;
