import React from 'react';
import { inject, observer } from 'mobx-react';
import Typography from '@styles/Typography';
import SignInForm from './SignInForm';
import SignInLayout from './SignInLayout';

const SignInPage = ({
  routerStore: { history },
  authStore: { signIn },
  appStore: { featureFlags },
}) => {
  React.useEffect(() => {
    if (featureFlags['uo-frontegg-auth']) {
      history.push('/login');
    }
  }, [featureFlags]);

  return (
    <SignInLayout>
      <Typography variant="h2" mb={['24px', '24px', '32px']}>
        Login
      </Typography>
      <SignInForm onSubmit={signIn} />
    </SignInLayout>
  );
};

export default inject(
  'authStore',
  'appStore',
  'routerStore',
)(observer(SignInPage));
