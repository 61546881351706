import { Flex } from '@chakra-ui/react';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import React from 'react';
import WizardStepIndicator from './WizardStepIndicator';

const styles = isSmallerScreen => ({
  p: '32px',
  bg: '#fff',
  borderRadius: '8px',
  mb: '40px',
  w: '100%',
  ...(isSmallerScreen && { bg: 'transparent', p: 0 }),
});

const WizardHeader = ({
  steps,
  activeStep,
  submit,
  brief,
  briefId,
  ...props
}) => {
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  return (
    <Flex {...props} {...styles(isSmallerScreen)}>
      <WizardStepIndicator
        steps={steps}
        briefId={briefId}
        brief={brief}
        activeStep={activeStep}
        submit={submit}
      />
    </Flex>
  );
};

export default WizardHeader;
