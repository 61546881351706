import { Box, Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import useFileNameTurncate from '@hooks/useFileNameTurncate';
import Typography from '@styles/Typography';
import { endsWith, find, isEmpty } from 'lodash-es';
import React from 'react';

const NativeUploadFile = ({
  input,
  nativeInputProps = {},
  label,
  allowedExtensions,
  title,
  name,
  ...props
}) => {
  const inputRef = React.useRef();
  const containerRef = React.useRef();
  const textRef = React.useRef();

  const turncatedText = useFileNameTurncate({
    containerRef,
    textRef,
    fileName: input?.value?.name || '',
    additionalOffset: 45,
  });

  return (
    <Flex w="100%" flexDir="column">
      {title && input?.value && <Typography pl="10px">{title}</Typography>}
      <Flex
        ref={containerRef}
        overflow="hidden"
        w="100%"
        pt={!input?.value ? '18px' : '0px'}
        alignItems="center"
        h="100%"
        {...props}
      >
        {input?.value?.name && (
          <Box
            as={Icon}
            fontSize={45}
            cursor="pointer"
            name="cross"
            p="10px"
            mt="4px"
            transition="all 0.5s"
            _hover={{ transform: 'rotate(270deg)' }}
            onClick={() => {
              if (inputRef && inputRef.current) {
                inputRef.current.value = null;
              }
              input.onChange(null);
            }}
          />
        )}
        <Box
          as="label"
          onClick={() => {
            input.onFocus();
            input.onBlur();
          }}
          alignItems="center"
          display="inline-flex"
          mr="auto"
          _hover={{
            cursor: 'pointer',
          }}
        >
          {!input?.value && (
            <Icon
              mr="12px"
              fontSize={24}
              transition="all 0.5s"
              color="blue.solid"
              name="download"
              transform="rotate(180deg)"
            />
          )}
          <Typography
            ref={textRef}
            whiteSpace="nowrap"
            color={!input?.value ? 'blue.solid' : 'dark.solid'}
            transition="all 0.5s"
            mt="5px"
          >
            {turncatedText || label}
          </Typography>
          <input
            type="file"
            ref={inputRef}
            {...nativeInputProps}
            onChange={({ target }) => {
              const file = target.files[0];
              if (
                (allowedExtensions &&
                  !!find(allowedExtensions, ext =>
                    endsWith(file?.name, ext),
                  )) ||
                isEmpty(allowedExtensions)
              ) {
                input.onChange(file);
              }
            }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default NativeUploadFile;
