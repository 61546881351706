import React from 'react';
import { inject, observer } from 'mobx-react';
import Typography from '@styles/Typography';
import ForgottenPasswordForm from './ForgottenPasswordForm';
import SignInLayout from '../SignIn/SignInLayout';

const ForgottenPassword = ({ authStore: { forgotPassword } }) => (
  <SignInLayout>
    <Typography variant="h2" mb={['24px', '24px', '32px']}>
      Forgotten password
    </Typography>
    <ForgottenPasswordForm onSubmit={forgotPassword} />
  </SignInLayout>
);

export default inject('authStore')(observer(ForgottenPassword));
