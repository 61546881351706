import { find, get, map, flatten, uniqBy, filter } from 'lodash-es';
import { action, computed, makeObservable, observable } from 'mobx';

export default class SettingsStore {
  constructor({ API, toastsStore, xeroStore }) {
    makeObservable(this);
    this.toastsStore = toastsStore;
    this.xeroStore = xeroStore;
    this.API = API;
  }

  @observable exchangeRates = {
    isLoading: false,
    data: [],
    error: null,
  };

  @observable settings = {};

  @observable isNuking = false;

  @observable isForcing = false;

  @observable isDisconnecting = false;

  @action disconnectXero = async () => {
    try {
      this.isDisconnecting = true;
      await this.API.disconnectXero();
      await this.xeroStore.getXeroAuth();
      this.xeroStore.xero = { authorized: false };
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    } finally {
      this.isDisconnecting = false;
    }
  };

  @action fetchExchangeRates = async () => {
    if (this.exchangeRates.isLoading) {
      return;
    }
    this.exchangeRates = {
      ...this.exchangeRates,
      error: null,
      isLoading: true,
    };
    try {
      const { data } = await this.API.getExchangeRates();
      this.exchangeRates.data = data;
    } catch (e) {
      this.exchangeRates.error =
        e.messsage ||
        'Ooops! Something unexpected happened. Please try again later.';
    } finally {
      this.exchangeRates.isLoading = false;
    }
  };

  @action updateExchangeRates = async ({ rates }) => {
    try {
      const ratesResponse = await Promise.all(
        map(rates, rate => this.API.updateExchangeRate(rate)),
      );
      this.exchangeRates.data = map(ratesResponse, resp => resp.data);
    } catch (e) {
      this.toastsStore.showError({
        title:
          e || 'Ooops! Something unexpected happened. Please try again later.',
      });
    }
  };

  @action getSettings = async () => {
    try {
      this.isLoading = true;
      const { data } = await this.API.getSettings();
      this.settings = { ...data };
    } catch (e) {
      this.settings = {};
    } finally {
      this.isLoading = false;
    }
  };

  @action updateSettings = async values => {
    try {
      const { data } = await this.API.updateSettings(values);
      this.settings = data;
      this.toastsStore.showSuccess({
        title: 'Your settings have been successfully updated.',
      });
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action deleteCSVTemplate = async (fileId, successCb) => {
    try {
      await this.API.deleteCSVTemplate(fileId);
      this.toastsStore.showSuccess({
        title: 'File has been removed successfully.',
      });
      this.settings.csvTemplates = filter(
        this.settings.csvTemplates,
        csv => csv.id !== fileId,
      );
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action uploadCSVTemplate = async ({ values, successCb }, formApi) => {
    try {
      const responses = await Promise.all(
        map(values.files, async file => {
          const formData = new FormData();
          formData.append('file', file?.file);
          try {
            const { data } = await this.API.uploadCSVTemplate(formData);
            return data.csvTemplates;
          } catch {
            this.toastsStore.showError({
              title: `Something went wrong while uploading file: ${file?.file?.name}`,
            });
          }
        }),
      );
      this.settings.csvTemplates = uniqBy(flatten(responses), 'id');

      if (successCb) {
        successCb();
      }
      setTimeout(formApi.restart);
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    }
  };

  @action nukePayments = async () => {
    try {
      this.isNuking = true;
      await this.API.nukePayments();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    } finally {
      this.isNuking = false;
    }
  };

  @action forceJobs = async () => {
    try {
      this.isForcing = true;
      await this.API.forceJobs();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    } finally {
      this.isForcing = false;
    }
  };

  @computed get gbpToUsd() {
    return get(
      find(
        this.exchangeRates.data,
        rate => rate.sourceCurrency === 'GBP' && rate.destCurrency === 'USD',
      ),
      'rate',
    );
  }

  @computed get gbpToEur() {
    return get(
      find(
        this.exchangeRates.data,
        rate => rate.sourceCurrency === 'GBP' && rate.destCurrency === 'EUR',
      ),
      'rate',
    );
  }

  calculateCurrencyRate = (source, dest) => {
    if (source === dest) {
      return 1;
    }
    if (source === 'gbp' && dest === 'usd') {
      return 1 / this.gbpToUsd;
    }
    if (dest === 'gbp' && source === 'usd') {
      return this.gbpToUsd;
    }
    if (source === 'gbp' && dest === 'eur') {
      return 1 / this.gbpToEur;
    }
    if (dest === 'gbp' && source === 'eur') {
      return this.gbpToEur;
    }
    if (source === 'usd' && dest === 'eur') {
      return this.gbpToUsd / this.gbpToEur;
    }
    if (source === 'eur' && dest === 'usd') {
      return this.gbpToEur / this.gbpToUsd;
    }
    return 1;
  };
}
