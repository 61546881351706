import { Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';

const PageDescription = ({
  title,
  description,
  descriptionProps,
  titleProps,
  ...props
}) => {
  if (!title && !description) {
    return null;
  }

  return (
    <Flex
      flexDir="column"
      mt="40px"
      borderBottom="1px solid"
      borderColor="dark.mid"
      mb="40px"
      {...props}
    >
      {title && (
        <Typography variant="h4" mb="8px" maxW="451px" {...titleProps}>
          {title}
        </Typography>
      )}
      {description && (
        <Typography maxW="451px" mb="16px" {...descriptionProps}>
          {description}
        </Typography>
      )}
    </Flex>
  );
};
export default PageDescription;
