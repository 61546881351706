import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';

const ReminderConfirmationModal = ({
  isOpen,
  isLoading,
  onRemind,
  onClose,
}) => {
  const isSmallerScreen = useMediaQuery(breakpoint.lg);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styleProps={
        isSmallerScreen && { p: '24px 16px 48px', margin: 'auto 0px 0px' }
      }
    >
      <Typography variant="h2" mb="24px" textAlign="center">
        Send reminder
      </Typography>
      <Typography mb="40px" textAlign="center" maxW="488px" mx="auto">
        Are you sure you want to send reminder to all assigned delivery
        partners?
      </Typography>
      <Stack spacing={3}>
        <Button onClick={onRemind} isLoading={isLoading}>
          Send reminder
        </Button>
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};

export default ReminderConfirmationModal;
