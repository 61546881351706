import { Box, Flex, useBoolean } from '@chakra-ui/react';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { map, filter, flatMap, isEmpty } from 'lodash-es';
import qs from 'query-string';
import useMediaQuery from '@hooks/useMediaQuery';
import SortAndOrderSelect from '@components/SortAndOrderSelect';
import Pagination from '@components/Pagination';
import Button from '@styles/Button';
import PageSize from '@components/PageSize';
import { MAIN_NAMES_QA } from '@app/constants';
import CandidatesList from './CandidatesList';
import CandidatesHistory from './CandidatesHistory';
import SectionTitle from './SectionTitle';

const GROUP_OPTONS = [
  {
    label: 'Role name',
    value: 'role',
  },
  {
    label: 'Proposal',
    value: 'proposal',
  },
];

const mainNameQA = MAIN_NAMES_QA.PLUG_AND_PLAY_PROPOSAL_PROPOSAL_CANDIDATES;

const AllCandidates = ({
  briefsStore: { brief },
  proposalCandidatesStore: {
    fetchBriefCandidates,
    fetchProposalChanges,
    clearCandidates,
    candidates: { data: candidates, isLoading, paging },
    proposalChanges: { data: proposalChanges },
    clearProposalChanges,
  },
  location: { search },
}) => {
  React.useEffect(() => {
    fetchBriefCandidates(brief.id);
    fetchProposalChanges(brief.id);
    return () => {
      clearCandidates();
      clearProposalChanges();
    };
  }, []);

  const { validCandidates, removedCandidates } = React.useMemo(() => {
    const flattenedChanges = flatMap(proposalChanges, p => p.changedCandidates);

    const validCands = map(
      filter(candidates, c => !c.isRemoved),
      c => {
        return {
          ...c,
          diffs: filter(
            flattenedChanges,
            changedCand =>
              changedCand.resourceId === c?.briefResource?.id &&
              changedCand.candidateName === c?.name,
          ),
        };
      },
    );
    return {
      validCandidates: validCands,
      removedCandidates: filter(candidates, c => c.isRemoved),
    };
  }, [candidates, proposalChanges]);

  const isListEmpty = isEmpty(candidates) && !isLoading;

  const printCandidates = !isEmpty(candidates) ? validCandidates.length : '';

  const { page = 1 } = qs.parse(search);

  const onOrderChange = phrase => {
    fetchBriefCandidates(brief.id, phrase);
  };

  const [isCandidateHistoryOpen, setIsCandidateHistoryOpen] = useBoolean();

  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const showCandidateHistory = !isEmpty(proposalChanges);

  return (
    <>
      <Flex
        w="100%"
        flexDirection={isSmallerScreen && 'column'}
        justify="space-between"
        mb="20px"
        display={!isSmallerScreen && '-webkit-inline-box'}
      >
        <SectionTitle
          data-test-id={`${mainNameQA}--counter`}
          label={`Review ${printCandidates} ${
            printCandidates === 1 ? 'candidate' : 'candidates'
          }`}
          description="These are all the candidates that have been put forward for the roles in
      this brief."
        />
        {!isListEmpty && (
          <Box as={!isSmallerScreen && Flex}>
            {showCandidateHistory && (
              <Button
                variant="ghost"
                leftIcon={{
                  name: 'version',
                  color: 'blue.solid',
                  fontSize: 20,
                }}
                color="dark.solid"
                onClick={setIsCandidateHistoryOpen.on}
              >
                Candidate history
              </Button>
            )}
            <SortAndOrderSelect
              options={GROUP_OPTONS}
              onChange={({ value: phrase }) => onOrderChange(phrase)}
              defaultValue="role"
              maxW={isSmallerScreen ? '100%' : '200px'}
              marginLeft={isSmallerScreen && '0'}
              marginTop={isSmallerScreen && '12px'}
              data-test-id={`${mainNameQA}--order`}
            />
          </Box>
        )}
      </Flex>
      <CandidatesHistory
        isOpen={isCandidateHistoryOpen}
        onClose={setIsCandidateHistoryOpen.off}
        proposalChanges={proposalChanges}
      />
      <CandidatesList
        validCandidates={validCandidates}
        removedCandidates={removedCandidates}
        isLoading={isLoading}
        mainNameQA={mainNameQA}
      />

      <Flex
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        pt="24px"
        pb="72px"
      >
        {paging.totals > 0 && <PageSize />}
        <Pagination forcePage={+page - 1} pageCount={paging.totalPages} />
      </Flex>
    </>
  );
};

export default inject(
  'proposalCandidatesStore',
  'briefsStore',
)(observer(AllCandidates));
