/* eslint-disable */
/* ta */
export default `
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Schedule 1 Statement of Work&nbsp;</strong></p>
<p><strong>Dated:&nbsp;</strong></p>
<p><strong>Between:</strong></p>
<p><strong>(1) {{DEAZY_NAME}}</strong><span style="font-weight: 400;"> (trading as Deazy) of {{DEAZY_DETAILS}} (Company No. 10305788) (&ldquo;</span><strong>Deazy</strong><span style="font-weight: 400;">&rdquo;); and</span></p>
<p><span style="background-color: #feff00;"><strong>(2) {{CLIENT_NAME}} </strong><span style="font-weight: 400;"> of {{ADDRESS}} (the "</span><strong>Client</strong><span style="font-weight: 400;">")&nbsp;</span></span></p>
<p style="padding-left: 40px;">&nbsp;</p>
<p><strong>Background</strong></p>
<p><span style="font-weight: 400;">(A) Pursuant to Deazy&rsquo;s standard terms and conditions (the &ldquo;</span><strong>Terms</strong><span style="font-weight: 400;">&rdquo;) accepted by the Client on <span style="background-color: #feff00;">{{DATE}},</span> the Client has appointed Deazy to provide <span style="background-color: #feff00;"> </span></span><span style="font-weight: 400; background-color: #feff00;">INCLUDE AS APPROPRIATE product management, project management, design and software development services</span><span style="font-weight: 400;">, when required, during the Term.&nbsp;</span></p>
<p><span style="font-weight: 400;">(B) Pursuant to the Terms, the Client is able to request Services (as defined in the Terms) and Deazy has agreed to provide such services in accordance with this statement of work (&ldquo;</span><strong>SOW</strong><span style="font-weight: 400;">&rdquo;).</span></p>
<p>&nbsp;</p>
<p><strong>Term</strong></p>
<ol class="start">
<li><span style="font-weight: 400;">This SOW shall come into force on the date it is executed by its parties OR on </span><span style="font-weight: 400; background-color: #feff00;">{{TERM_DATE}}</span><span style="font-weight: 400;"> (the &ldquo;</span><strong>Commencement Date&rdquo;</strong><span style="font-weight: 400;">).</span></li>
<li><span style="font-weight: 400;">This SOW shall take effect from the Commencement Date and will continue in effect for the initial term of <span style="background-color: #feff00;">{{DURATION}}</span>,(&ldquo;<strong>Initial Term</strong>&rdquo;)&nbsp;then continue thereafter, unless terminated earlier, on a rolling basis.</span></li>
<li><span style="font-weight: 400;">Following the Initial Term, either party may terminate this SOW on giving no less than <span style="background-color: #feff00;">[thirty (30) days]</span> notice. In both cases, such notice must expire on or after the&nbsp;Initial Term.</span></li>
</ol>
<p>&nbsp;</p>
<p><strong>Conflict</strong></p>
<ol class="continue">
<li><span style="font-weight: 400;">If there is an inconsistency between any of the provisions of this SOW and the provisions of the Terms, the provisions of this SOW shall prevail.&nbsp;</span></li>
<li><span style="font-weight: 400;">If a term is capitalised in this SOW but not defined therein the defined terms of the Terms shall be applicable to said term.</span></li>
</ol>
<p>&nbsp;</p>
<p><strong>Services</strong></p>
<p><strong>Scope of work and approach</strong></p>
<ol class="continue">
<li><strong>&nbsp;Context:</strong><span style="font-weight: 400;"> The Client wishes to <span>{{SHORT_DESCRIPTION}}</span></li>
</ol>
{{LONG_DESCRIPTION}}
<ol class="continue">
<li><span style="font-weight: 400;"><strong>Approach:</strong></span></li>
<ol class="nested-1">
<li><span style="font-weight: 400;">The Services in this SOW are provided on a retainer basis, with an initial resource allocation detailed in clause 8.</span></li>
<li><span style="font-weight: 400;">Resources can be ramped up with a minimum of thirty (30) days notice in writing. However, Deazy aims to deliver on ramp up requests as quickly as possible and will use all reasonable endeavours to do so.</span></li>
<li><span style="font-weight: 400;">Resources can be ramped down with a minimum of thirty (30) days notice in writing.</span></li>
</ol>
</li>
</ol>
<p style="padding-left: 40px;">&nbsp;</p>
<ol class="continue">
<li><strong>Scope of Services:</strong>
<ol class="nested-1">
<li><span style="font-weight: 400;">Deazy shall provide (either directly or with the assistance of a sub-contractor) technical expertise to augment the Client team:</span>
<ol class="nested-2">
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">Technical roles required;</span>
<ol class="nested-3">{{ALLOCATIONS}}</ol>
</li>
</ol>
</li>
<li style="font-weight: 400;" aria-level="4"><span style="font-weight: 400;">Deazy will fulfill the agreed monthly resource allocation by assigning personnel who meet the defined specification for each technical role and are best suited to the team.</span>
<ol class="nested-2">
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">The Client reserves the right to review the profiles and interview all nominated personnel, prior to Deazy allocating them to the team</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">Each new Deazy resource will be required to complete a probation period of </span><span style="font-weight: 400; background-color: #feff00;">complete eg. four (4) weeks</span><span style="font-weight: 400;"> (&ldquo;</span><strong>Probation Period</strong><span style="font-weight: 400;">&rdquo;).&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">The Client can request a change in the Deazy personnel allocated by giving Deazy no less than </span><span style="font-weight: 400; background-color: #feff00;">ten (10) days</span><span style="font-weight: 400;"> notice in writing, if the personnel is still within the Probation Period.</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">For all other personnel, the Client can request a change in the Deazy personnel allocated by giving Deazy no less than thirty (30) days notice in writing.&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">Deazy can change the Deazy personnel allocated by giving the Client no less than thirty (30) days notice in writing. In this case Deazy will be responsible for ensuring knowledge transfer takes place successfully, where required. This will not incur additional Charges to the Client.&nbsp;</span></li>
</ol>
</li>
</ol>
</li>
</ol>
<table style="border-collapse: collapse; width: 91.9273%; height: 72px;" border="1">
<tbody>
<tr style="height: 18px;">
<td style="width: 49.6158%; height: 18px;">
<p><strong>Resource</strong></p>
</td>
<td style="width: 49.6158%; height: 18px;"><strong>Total days for Initial Term</strong></td>
</tr>
{{RESOURCES}}
<tr style="height: 18px;">
<td style="width: 49.6158%; height: 18px;">
<p><span style="font-weight: 400;"><strong>Total days for Initial Term <span style="background-color: #feff00;">(X weeks)</span></strong></span></p>
</td>
<td style="width: 49.6158%; height: 18px;">{{TOTAL_RESOURCE_HOURS}}</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<ol class="continue">
<li><strong>Ways of working</strong>
<ol class="nested-1">
<li><strong><span style="font-weight: 400;">The parties acknowledge and agree that the following change procedure applies to this statement of work:</span></strong></li>
</ol>
</li>
</ol>
<table style="border-collapse: collapse; width: 93.2318%; height: 150px;" border="1">
<tbody>
<tr style="height: 50px;">
<td style="width: 33.0309%; height: 50px;">
<p><strong>Onboarding Phase</strong></p>
</td>
<td style="width: 33.0309%; height: 50px;">
<p><span style="font-weight: 400;">Onboarding of any new resources into the Client team will be completed as below.&nbsp;</span></p>
<br />
<p><span style="font-weight: 400;">One week onboarding&nbsp;</span></p>
<br />
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Team introductions&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Product intros/demos</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Code and architecture walkthrough</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Development process walkthrough&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">System access and IT set up&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Complete simple task to test workflow/access etc&nbsp;</span></li>
</ul>
<br />
<p><span style="font-weight: 400;">The Client is responsible for the system access and IT set up and commits to making the relevant team members available for necessary intros, demos and process walkthrough remote sessions. </span></p>
</td>
</tr>
<tr style="height: 50px;">
<td style="width: 33.0309%; height: 50px;">
<p><strong>Authorised Person (s) </strong></p>
</td>
<td style="width: 33.0309%; height: 50px;">
<p><strong>Client:</strong><span style="font-weight: 400;"> </span><span style="font-weight: 400; background-color: #feff00;">{{CLIENT_LEAD}}</span></p>
<p><strong>Deazy:</strong><span style="font-weight: 400;"> </span><span style="font-weight: 400; background-color: #feff00;">{{DEAZY_LEAD}}</span></p>
</td>
</tr>
<tr style="height: 50px;">
<td style="width: 33.0309%; height: 50px;">
<p><strong>Change Request procedure</strong></p>
</td>
<td style="width: 33.0309%; height: 50px;">
<p><span style="font-weight: 400;">Any changes to the scope of this SOW shall be approved via mutual agreement of the Authorised Persons (on behalf of Deazy and the Client) and confirmed via a signed </span><a href=""><span style="font-weight: 400;">Change Request Form</span></a><span style="font-weight: 400;">, which includes the associated impact to the Charges and delivery schedule of the engagement</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<ol class="continue">
<li><strong>Location of services</strong><span style="font-weight: 400;"> </span><strong>provided</strong></li>
</ol>
<p style="padding-left: 40px;">Deazy shall work remotely. Any travel required to deliver the Services will be chargeable to the Client but all expenses must be approved in advance by the Client, in both cases in writing.</p>
<p>&nbsp;</p>
<ol class="continue">
<li><strong>Use of subcontractors&nbsp;</strong></li>
</ol>
<p style="padding-left: 40px;"><span style="font-weight: 400;">The parties acknowledge and agree that Deazy may retain the services of third parties to provide the Services.</span></p>
<p>&nbsp;</p>
<ol class="continue">
<li aria-level="1"><strong>Non-solicitation</strong></li>
</ol>
<p style="padding-left: 40px;"><span style="font-weight: 400;">During the term of this SOW and for a period of twelve (12) months after any termination or expiry of this SOW, Client will not solicit, induce, recruit or encourage any of Deazy&rsquo;s or its subcontractors&rsquo; officers, employees, agents, advisers and other representatives to terminate their relationship with Deazy or the subcontractor (as applicable).&nbsp;</span></p>
<p style="padding-left: 40px;"><span style="font-weight: 400;">Client shall provide Deazy with notice as soon as reasonably possible if any of the events outlined in clause 12 above occurs.</span></p>
<p>&nbsp;</p>
<ol class="continue">
<li><strong>Charges</strong></li>
</ol>
<p style="padding-left: 40px;"><span style="font-weight: 400;">This SOW will be delivered on a retainer basis with a fixed resource allocation and the estimated Charges are outlined in the table that follows:</span></p>
<p style="padding-left: 40px;">[<span style="background-color: #feff00;">INSERT TEAM DETAIL AS PER CLAUSE 8.3</span>]</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<table style="border-collapse: collapse; width: 90.8257%;" border="1">
<tbody>

{{CHARGES_ROWS}}
{{CHARGES_TOTALS_ROW}}
</tbody>
</table>
<p><span style="background-color: #ffffff;"><span style="font-weight: 400;">(the "<strong>Charges</strong>")</span></span></p>
<p>&nbsp;</p>
<p><strong><span style="background-color: #ffffff;">Payment schedule</span></strong></p>
<ol class="continue">
<li><span style="font-weight: 400;">Deazy will invoice monthly in <span style="background-color: #feff00;">[advance/arrears]</span>&nbsp;for all invoices</span></li>
<li>Invoices due during the initial term of the SOW are set out in the table below:</li>
</ol>

<p>&nbsp;</p>

{{PREPOPULATED_TABLE}}

<ol class="continue">
<li><span style="font-weight: 400;">All payments shall be due within </span><span style="font-weight: 400;"><span style="background-color: #feff00;">DELETE AS APPROPRIATE 14 or 30 or 60 days<span style="background-color: #ffffff;"></span></span></span><span style="font-weight: 400;"> of the invoice date, in accordance with the Terms.</span></li>
</ol>

<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<table style="border-collapse: collapse; width: 93.2318%; height: 136px;" border="0">
<tbody>
<tr style="height: 118px;">
<td style="width: 49.6824%; height: 118px;">
<p><strong>Signed</strong><span style="font-weight: 400;"> by </span><span style="background-color: #feff00;"><strong>A MEMBER OF THE DEAZY LEADERSHIP TEAM</strong></span></p>
<p><span style="font-weight: 400;">duly authorised for and on behalf of </span><strong>Deazy Limited</strong></p>
</td>
<td style="width: 49.6824%; height: 118px;">
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-weight: 400;">..................................................................................................</span></p>
<p style="text-align: center;"><span style="font-weight: 400;">[Director]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; [Secretary]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;[Authorised Signatory]</span></p>
</td>
</tr>
<tr style="height: 18px;">
<td style="width: 49.6824%; height: 18px;">
<p style="text-align: left;"><strong>Signed<span style="font-weight: 400;"> by </span><span style="background-color: #feff00;">AUTHORISED SIGNATORY</span></strong></p>
<p><strong><span style="font-weight: 400;">duly authorised for and on behalf of </span><span style="background-color: #feff00;">{{CLIENT}}</span></strong></p>
</td>
<td style="width: 49.6824%; height: 18px;">
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-weight: 400;">..................................................................................................</span></p>
<p style="text-align: center;"><span style="font-weight: 400;">[Director]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; [Secretary]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;[Authorised Signatory]</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p>&nbsp;</p>`;
