import { map, values, flatten, find } from 'lodash-es';
import { action, makeObservable, observable } from 'mobx';
import { nanoid } from 'nanoid';
import { NEW_PROPOSAL_STATUSES, CANDIDATE_STATUSES } from '@app/constants';
import moment from 'moment';

export default class ProposalCandidatesStore {
  constructor({ API, toastsStore }) {
    makeObservable(this);
    this.API = API;
    this.toastsStore = toastsStore;
  }

  @observable candidates = { isLoading: false, data: [], paging: {} };

  @observable proposalChanges = { isLoading: false, data: [] };

  @action clearCandidates = () => {
    this.candidates = { isLoading: false, data: [], paging: {} };
  };

  @action fetchBriefCandidates = async (briefId, phrase = 'role') => {
    try {
      this.candidates.isLoading = true;
      const { data } = await this.API.getBriefCandidates(briefId, phrase);

      const mappedData = {
        ...data,
        removedResources: map(data.removedResources, r => ({
          ...r,
          isRemoved: true,
        })),
      };

      const plainData = flatten(values(mappedData));

      this.candidates.data = plainData;
      this.candidates.paging = {
        totalPages: 1,
        totalObjects: plainData.length,
      };
    } catch (e) {
      this.toastsStore.showError({
        title: e?.message || 'Ooops! Something unexpected happened.',
      });
    } finally {
      this.candidates.isLoading = false;
    }
  };

  @action clearProposalChanges = () => {
    this.proposalChanges = { data: [], isLoading: false };
  };

  @action fetchProposalChanges = async briefId => {
    try {
      this.proposalChanges.isLoading = true;
      const { data } = await this.API.getBriefProposalChanges(briefId);
      this.proposalChanges.data = map(
        data,
        ({ addedCandidates, removedCandidates, ...proposal }) => ({
          ...proposal,
          changedCandidates: [
            ...(proposal.changedCandidates || []),
            ...map(removedCandidates, c => ({ ...c, isRemoved: true })),
            ...map(addedCandidates, c => ({ ...c, isAdded: true })),
          ],
        }),
      );
    } catch (e) {
      this.toastsStore.showError({
        title: e?.message || 'Ooops! Something unexpected happened.',
      });
    } finally {
      this.proposalChanges.isLoading = false;
    }
  };

  @action shortlistCandidate = async (briefId, candidate) => {
    this.setCandidateProperty(candidate.id, { isAdding: true });
    try {
      await this.API.toggleCandidateShortlist(
        briefId,
        candidate?.ppBriefProposal?.id,
        candidate?.id,
      );
      this.setCandidateProperty(candidate.id, {
        isAdding: false,
        status: CANDIDATE_STATUSES.SHORTLISTED,
      });
      this.toastsStore.showSuccess({
        title: 'Candidate shortlisted and added to your proposal',
      });
    } catch (e) {
      this.toastsStore.showError({ title: e.message || 'Network error!' });
      this.setCandidateProperty(candidate.id, {
        isAdding: false,
      });
    }
  };

  @action unshortlistCandidate = async (briefId, candidate) => {
    this.setCandidateProperty(candidate.id, { isRemoving: true });
    try {
      await this.API.toggleCandidateShortlist(
        briefId,
        candidate?.ppBriefProposal?.id,
        candidate?.id,
      );
      this.setCandidateProperty(candidate.id, {
        isRemoving: false,
        status: CANDIDATE_STATUSES.PROPOSED,
      });
    } catch (e) {
      this.toastsStore.showError({ title: e.message || 'Network error!' });
      this.setCandidateProperty(candidate.id, {
        isRemoving: false,
      });
    }
  };

  @action queryCandidate = async ({ briefId, candidate, query }, successCb) => {
    this.setCandidateProperty(candidate.id, { isQuering: true });
    try {
      const { data } = await this.API.queryCandidate(
        briefId,
        candidate?.ppBriefProposal?.id,
        candidate?.id,
        { message: query },
      );
      this.candidates.data = map(this.candidates.data, c => {
        const shouldGoToWithDP = find(data.resources, r => r.id === c.id);
        return {
          ...c,
          ...(c.id === candidate.id && {
            isQuering: false,
            status: CANDIDATE_STATUSES.QUERIED,
            queries: [
              ...(c?.queries || []),
              {
                id: nanoid(10),
                createdAt: moment.utc().format(),
                message: query,
              },
            ],
          }),
          ppBriefProposal: {
            ...c.ppBriefProposal,
            status: shouldGoToWithDP
              ? NEW_PROPOSAL_STATUSES.WITH_DP
              : c?.ppBriefProposal?.status,
          },
        };
      });
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({ title: e.message || 'Network error!' });
      this.setCandidateProperty(candidate.id, {
        isQuering: false,
      });
    }
  };

  getCandidate = candidateId =>
    find(this.candidates.data, c => c.id === candidateId);

  setCandidateProperty = (candidateId, obj) => {
    this.candidates.data = map(this.candidates.data, c => ({
      ...c,
      ...(c.id === candidateId && obj),
    }));
  };
}
