import { css, Global as EmotionGlobal } from '@emotion/react';
import reset from 'emotion-reset';
import React from 'react';
import { COLORS, FONTS } from './theme';

export const RICH_TEXT_STYLES = `
  #prepopulated table tr, #prepopulated table th {
    height: 36px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 10px;
    line-height: 1.55;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${FONTS.WB};
  }
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 15px;
  }
  h6 {
    font-size: 12px;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  ul,
  ol {
    li {
      margin-bottom: 5px;
    }
  }
  ol {
    counter-reset: item;
  }
  ol {
    padding-left: 20px;
    li:before {
      content: counters(item, '.') '. ';
      counter-increment: item;
    }
  }
  ul {
    list-style: disc;
    padding-left: 37px;
  }
`;

const GlobalStyles = () => {
  return (
    <EmotionGlobal
      styles={css`
        ${reset}
        @font-face {
          font-family: 'Work Sans';
          src: url('/fonts/WorkSans/WorkSans-Thin.ttf') format('truetype');
          font-weight: 100;
        }
        @font-face {
          font-family: 'Work Sans';
          src: url('/fonts/WorkSans/WorkSans-ExtraLight.ttf') format('truetype');
          font-weight: 200;
        }
        @font-face {
          font-family: 'Work Sans';
          src: url('/fonts/WorkSans/WorkSans-Light.ttf') format('truetype');
          font-weight: 300;
        }
        @font-face {
          font-family: 'Work Sans';
          src: url('/fonts/WorkSans/WorkSans-Regular.ttf') format('truetype');
        }
        @font-face {
          font-family: 'Work Sans';
          src: url('/fonts/WorkSans/WorkSans-Medium.ttf') format('truetype');
          font-weight: 500;
        }
        @font-face {
          font-family: 'Work Sans';
          src: url('/fonts/WorkSans/WorkSans-SemiBold.ttf') format('truetype');
          font-weight: 600;
        }
        @font-face {
          font-family: 'Work Sans';
          src: url('/fonts/WorkSans/WorkSans-Bold.ttf') format('truetype');
          font-weight: 700;
        }
        @font-face {
          font-family: 'Work Sans';
          src: url('/fonts/WorkSans/WorkSans-ExtraBold.ttf') format('truetype');
          font-weight: 800;
        }
        @font-face {
          font-family: 'Work Sans';
          src: url('/fonts/WorkSans/WorkSans-Black.ttf') format('truetype');
          font-weight: 900;
        }
        @font-face {
          font-family: 'Work Sans Medium';
          src: url('/fonts/WorkSans/WorkSans-Medium.ttf') format('truetype');
          font-weight: 500;
        }
        @font-face {
          font-family: 'Work Sans SemiBold';
          src: url('/fonts/WorkSans/WorkSans-SemiBold.ttf') format('truetype');
          font-weight: 600;
        }
        @font-face {
          font-family: 'Poppins';
          src: url('/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
          font-weight: 100;
        }
        @font-face {
          font-family: 'Poppins';
          src: url('/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
          font-weight: 200;
        }
        @font-face {
          font-family: 'Poppins';
          src: url('/fonts/Poppins/Poppins-Light.ttf') format('truetype');
          font-weight: 300;
        }
        @font-face {
          font-family: 'Poppins';
          src: url('/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
        }
        @font-face {
          font-family: 'Poppins';
          src: url('/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
          font-weight: 500;
        }
        @font-face {
          font-family: 'Poppins';
          src: url('/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
          font-weight: 600;
        }
        @font-face {
          font-family: 'Poppins';
          src: url('/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
          font-weight: 700;
        }
        @font-face {
          font-family: 'Poppins';
          src: url('/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
          font-weight: 800;
        }
        @font-face {
          font-family: 'Poppins';
          src: url('/fonts/Poppins/Poppins-Black.ttf') format('truetype');
          font-weight: 900;
        }
        body {
          font-family: 'Work Sans', sans-serif;
          line-height: normal;
          font-size: 15px;
          background-color: ${COLORS.primary[100]};
        }
        form {
          width: 100%;
        }
        input[type='file'] {
          display: none;
        }
        .displayDescriptionAsHTML {
          ${RICH_TEXT_STYLES}
        }
        .tox-tinymce {
          border: none;
        }
        :focus-visible {
          outline: none;
          box-shadow: none;
        }
        .sendbird-channel-list {
          width: auto;
        }
        .sendbird-dropdown__reaction-bar {
          z-index: 2000;
          animation: 0.5s fadeIn;
          animation-fill-mode: forwards;
        }
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        #sendbird-modal-root {
          .sendbird-modal {
            z-index: 2000;
          }
        }
        #userflow-ui
          > .userflowjs-checklist-launcher.userflowjs-checklist-launcher--position-fixed {
          right: 32px !important;
          bottom: 87px !important;
          @media (max-width: 1024px) {
            bottom: 170px !important;
          }
        }
      `}
    />
  );
};
export default GlobalStyles;
