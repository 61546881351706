import React from 'react';
import WhiteCard from '@components/WhiteCard';
import SectionTitle from '@components/SectionTitle';
import { inject } from 'mobx-react';
import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import SupplierBlacklistItem from './SupplierBlacklistItem';
import AddSupplierToBlacklistModal from './AddSupplierToBlacklistModal';

function SuppliersBlacklist({
  usersStore: { isAdminOrDL },
  teamsStore: {
    _client: { blacklistedSuppliers = [], id: clientId },
    addSupplierToBlacklist,
    removeSupplierFromBlacklist,
    fetchSuppliersBlacklist,
  },
}) {
  const {
    onOpen: onAddSupplierToBlacklistModalOpen,
    onClose: onAddSupplierToBlacklistModalClose,
    isOpen: isAddSupplierToBlacklistModalOpen,
  } = useDisclosure();

  const handleAddSupplierToBlacklist = async (supplierId, blacklistData) => {
    await addSupplierToBlacklist({
      clientId,
      supplierId,
      blacklistData,
      onSuccess: () => fetchSuppliersBlacklist(clientId),
    });
    onAddSupplierToBlacklistModalClose();
  };

  const handleDeleteSupplierFromBlacklist = supplierId => {
    removeSupplierFromBlacklist({
      clientId,
      supplierId,
      onSuccess: () => fetchSuppliersBlacklist(clientId),
    });
  };

  return (
    <>
      <WhiteCard>
        <SectionTitle mt="0px">Blacklist</SectionTitle>
        <Flex right="24px" position="absolute" top="18px">
          {isAdminOrDL && (
            <Button
              pt="3px"
              leftIcon={<Icon name="add" mr="10px" w="10px" mb="5px" />}
              fontWeight="400"
              fontSize={16}
              bg="aqua.solid"
              color="#fff"
              minW="200px"
              maxH="40px"
              borderRadius="30px"
              _hover={{ bg: '#3E898C' }}
              _active={{ bg: '#3E898C' }}
              onClick={onAddSupplierToBlacklistModalOpen}
            >
              Add Delivery Partner
            </Button>
          )}
        </Flex>
        <ul style={{ marginTop: '24px' }}>
          {blacklistedSuppliers?.length > 0 ? (
            blacklistedSuppliers.map(bs => (
              <SupplierBlacklistItem
                supplierId={bs.supplier?.id || -1}
                key={`blacklisted-supplier-${bs.id}`}
                canDelete={isAdminOrDL}
                name={bs.supplier?.name || '--'}
                reason={bs.reason || '--'}
                onDelete={handleDeleteSupplierFromBlacklist}
              />
            ))
          ) : (
            <Typography>Nothing to show here</Typography>
          )}
        </ul>
      </WhiteCard>
      {isAddSupplierToBlacklistModalOpen && (
        <AddSupplierToBlacklistModal
          handleAddSupplierToBlacklist={handleAddSupplierToBlacklist}
          isOpen={isAddSupplierToBlacklistModalOpen}
          onClose={onAddSupplierToBlacklistModalClose}
        />
      )}
    </>
  );
}

export default inject('teamsStore', 'usersStore')(SuppliersBlacklist);
