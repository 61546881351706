/* eslint-disable no-unused-vars */
import {
  uniqBy,
  find,
  first,
  includes,
  isEmpty,
  map,
  noop,
  reject,
} from 'lodash-es';
import { NEW_ROLES, IS_PREPROD, IS_STAGING, IS_PROD } from '@app/constants';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  when,
} from 'mobx';
import { FORM_ERROR } from 'final-form';
import { APP_ROUTES } from '@routes';
// import userflow from 'userflow.js';
import moment from 'moment';

const REPORTS_STORAGE_KEY = 'reportsEnabled';

export default class UsersStore {
  constructor({
    history,
    API,
    authStore,
    xeroStore,
    toastsStore,
    routerStore,
    settingsStore,
  }) {
    makeObservable(this);
    this.authStore = authStore;
    this.xeroStore = xeroStore;
    this.toastsStore = toastsStore;
    this.routerStore = routerStore;
    this.settingsStore = settingsStore;
    this.API = API;
    this.history = history;

    this._fetchAllData();
    this._watchHotJarUserType();
    this._watchUserFlow();
  }

  @observable appLoadFailed = false;

  @observable profile = {};

  @observable _user = {};

  @observable isLoading = false;

  @observable isErrorReportingEnabled =
    localStorage.getItem(REPORTS_STORAGE_KEY) === 'true';

  @action toggleErrorReporting = () => {
    localStorage.setItem(REPORTS_STORAGE_KEY, !this.isErrorReportingEnabled);
    this.isErrorReportingEnabled = !this.isErrorReportingEnabled;
  };

  @action clearUserData = () => {
    this.profile = {};
    this.settingsStore.settings = {};
  };

  @action fetchAllData = async () => {
    await this.getProfile();
    if (this.isAdminOrDL) {
      this.settingsStore.getSettings();
      await this.xeroStore.getXeroStatusLimits();
      if (!this.xeroStore.xero.authorized) {
        this.xeroStore.getXeroAuth();
      }
    }
  };

  @action
  _fetchAllData = async () => {
    await when(
      () => this.authStore.isAuthenticated,
      async () => {
        this.fetchAllData();
        reaction(
          () => this.authStore.isAuthenticated,
          async isAuthenticated => {
            if (isAuthenticated) {
              this.fetchAllData();
            } else {
              this.clearUserData();
            }
          },
        );
      },
      { delay: 200 },
    );
  };

  getSendbirdToken = async () => {
    try {
      // const {
      //   data: { sendbirdToken },
      // } = await this.API.getSendbirdToken();
      // this.profile.sendbirdToken = sendbirdToken;
    } catch {
      //
    }
  };

  @action getProfile = async () => {
    try {
      this.appLoadFailed = false;
      this.isLoading = true;
      const { data } = await this.API.getProfile();
      if (data.needsSendbirdToken) {
        this.getSendbirdToken();
      }
      if (data.passwordResetRequired) {
        this.routerStore.push(APP_ROUTES.auth.resetPassword);
      }
      this.profile = data;
    } catch (e) {
      this.profile = {};
      this.appLoadFailed = true;
    } finally {
      this.isLoading = false;
    }
  };

  @computed get supplierProfilePrimaryCurrency() {
    return find(this.profile?.supplier?.paymentDetails, c => c.isPrimary)
      ?.currency;
  }

  @action setUserActivity = async (userId, value) => {
    try {
      await this.API.setUserActivity(userId, { active: value });
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action fetchUser = async userId => {
    this._user = {};
    if (!userId) {
      return;
    }
    this.isLoading = true;
    try {
      const { data } = await this.API.getUserById(userId);
      this._user = data;
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
    } finally {
      this.isLoading = false;
    }
  };

  @computed get user() {
    return {
      ...this._user,
    };
  }

  @action updateUser = async ({ userId, values, onSuccess = noop }) => {
    try {
      await this.API.updateUserById(userId, { ...values });
      this.toastsStore.showSuccess({ title: 'User has updated successfully.' });
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action createUserByRole = async ({
    values,
    role,
    onSuccess = noop,
    onFailure,
  }) => {
    try {
      const { data } = await this.API.createUserByRole(role, values);
      this.toastsStore.showSuccess({ title: 'User created successfully.' });
      return onSuccess(data);
    } catch (e) {
      if (typeof onFailure === 'function') {
        onFailure(e);
      } else {
        this.toastsStore.showError({
          title: e.message || 'Something went wrong. Please, try again later.',
        });
      }
    }
  };

  @action addUserToGroupByRole = async ({
    memberId,
    role,
    supplier,
    client,
    rate,
    onSuccess = noop,
  }) => {
    try {
      await this.API.updateUserByRole(role, {
        memberId,
        supplier,
        client,
        rate,
      });
      this.toastsStore.showSuccess({
        title: 'User has added to group successfully.',
      });
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action deleteUserByRole = async ({ userId, role, onSuccess = noop }) => {
    try {
      await this.API.deleteUserByRole(role, userId);
      this.toastsStore.showSuccess({
        title: 'User has been removed successfully.',
      });
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action setUserAccessibility = async (userId, onSuccess) => {
    try {
      await this.API.setUserAccessibility({ id: userId, accessible: true });
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action updateProfileAndPassword = async (
    { updateBoth = false, ...values },
    formApi,
  ) => {
    const isPasswordChange = !!values.currentPassword && !!values.newPassword;

    const updateProfile = async () => {
      const { data } = await this.API.updateProfile(values);
      this.profile = data;
      this.toastsStore.showSuccess({
        title: `Profile updated successfully.`,
      });
    };

    try {
      if (isPasswordChange) {
        await this.API.updatePassword(values);
        this.toastsStore.showSuccess({
          title: `Password updated successfully.`,
        });
        if (updateBoth) {
          await updateProfile();
        } else {
          const { data: profile } = await this.API.getProfile();
          this.profile = profile;
        }
      } else {
        await updateProfile();
      }

      setTimeout(formApi.restart);
    } catch (e) {
      if (includes(e.message, 'file size exceeded')) {
        return {
          profilePicture: e.message,
        };
      }
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
      if (e.message) {
        return { [FORM_ERROR]: e.message };
      }
    }
  };

  @action forceJobsByProject = async ({ syncProjectId, onSuccess = noop }) => {
    try {
      await this.API.forceJobsByProject({ syncProjectId });
      this.toastsStore.showSuccess({
        title: 'Your project has been successfully updated.',
      });
      onSuccess();
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @computed get isProfileLoaded() {
    return !isEmpty(this.profile);
  }

  @computed get isGoodyUser() {
    return includes(this.profile.email, 'goodylabs');
  }

  @computed get isAdmin() {
    return !!find(this.profile.roles, role => role.name === NEW_ROLES.ADMIN);
  }

  @computed get isAdminOrDL() {
    return !!find(
      this.profile.roles,
      role =>
        role.name === NEW_ROLES.ADMIN || role.name === NEW_ROLES.DEAZY_LEAD,
    );
  }

  @computed get isDL() {
    return (
      !this.isAdmin &&
      !!find(this.profile.roles, role => role.name === NEW_ROLES.DEAZY_LEAD)
    );
  }

  @computed get isTeamAdmin() {
    return (
      !this.isAdminOrDL &&
      !this.isAdmin &&
      !!find(this.profile.roles, role => role.name === NEW_ROLES.TEAM_ADMIN)
    );
  }

  @computed get isTeamAdminSideUser() {
    return (
      !this.isAdminOrDL &&
      !this.isAdmin &&
      !!find(
        this.profile.roles,
        role =>
          role.name === NEW_ROLES.TEAM_ADMIN ||
          role.name === NEW_ROLES.TEAM_MEMBER,
      )
    );
  }

  @computed get isTeamMember() {
    return (
      !this.isAdminOrDL &&
      !this.isAdmin &&
      !this.isTeamAdmin &&
      !!find(this.profile.roles, role => role.name === NEW_ROLES.TEAM_MEMBER)
    );
  }

  @computed get isClient() {
    return (
      !this.isTeamAdmin &&
      !this.isAdminOrDL &&
      !this.isAdmin &&
      !!find(this.profile.roles, role => role.name === NEW_ROLES.CLIENT_LEAD)
    );
  }

  @computed get hasClientLeadRole() {
    return !!find(
      this.profile.roles,
      role => role.name === NEW_ROLES.CLIENT_LEAD,
    );
  }

  // eslint-disable-next-line no-unused-vars
  @action triggerHotJarIdentify = profile => {
    // if (!isEmpty(profile)) {
    //   const { id: userId, roles } = profile;
    //   const { description, name } = first(roles) || {};
    //   const role = description || name;
    //   if (role) {
    //     window.hj('identify', userId, { 'User Type': role });
    //   }
    // }
  };

  @action _watchHotJarUserType = () => {
    // let unlisten;
    // const onListen = () =>
    //   this.history.listen(() => {
    //     this.triggerHotJarIdentify(this.profile);
    //   });
    // reaction(
    //   () => this.profile,
    //   async profile => {
    //     this.triggerHotJarIdentify(profile);
    //     if (unlisten) {
    //       unlisten();
    //     }
    //     unlisten = onListen();
    //   },
    // );
  };

  @action triggerUserFlowAndHeap = profile => {
    // if (!isEmpty(profile)) {
    //   const { id: userId, username, email, createdAt } = profile;
    //   const { REACT_APP_USERFLOW_TOKEN } = process.env;
    //   const role = first(profile?.roles)?.name;
    //   if (REACT_APP_USERFLOW_TOKEN) {
    //     userflow.init(REACT_APP_USERFLOW_TOKEN);
    //     userflow.identify(userId, {
    //       name: username,
    //       email,
    //       signed_up_at: moment(createdAt).toISOString(),
    //       ...(role && { user_type: this.getFullRoleName(role) }),
    //     });
    //   }
    //   if (window.heap) {
    //     window.heap.addUserProperties({
    //       UserType: this.getFullRoleName(role),
    //       email,
    //     });
    //   }
    // }
  };

  getFullRoleName = role => {
    if (role === NEW_ROLES.DEAZY_LEAD) {
      return 'Deazy Lead';
    }
    if (role === NEW_ROLES.CLIENT_LEAD) {
      return 'Client Lead';
    }
    if (role === NEW_ROLES.TEAM_MEMBER) {
      return 'Team Member';
    }
    if (role === NEW_ROLES.TEAM_ADMIN) {
      return 'Team Admin';
    }
    return role;
  };

  @action _watchUserFlow = () => {
    if (IS_STAGING || IS_PROD || IS_PREPROD) {
      return reaction(
        () => this.profile,
        async profile => {
          this.triggerUserFlowAndHeap(profile);
        },
      );
    }
    return undefined;
  };

  @observable userGroups = {
    data: [],
    isLoading: false,
  };

  @action clearUserGroups = () => {
    this.userGroups = { data: [], isLoading: false };
  };

  @action fetchUserGroups = async userId => {
    try {
      this.userGroups.data = [];
      this.userGroups.isLoading = true;
      const { data } = await this.API.getUserGroups(userId);
      this.userGroups.data = data;
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    } finally {
      this.userGroups.isLoading = false;
    }
  };

  @action deleteUserFromGroup = async (userId, groupId) => {
    try {
      this._user.groups = map(this.user.groups, g => ({
        ...g,
        isDeleting: g.id === groupId ? true : g.isDeleting,
      }));
      await this.API.removeUserFromGroup(userId, groupId);
      this._user.groups = reject(this.user.groups, g => g.id === groupId);
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    } finally {
      this._user.groups = map(this.user.groups, g => ({
        ...g,
        isDeleting: g.id === groupId ? false : g.isDeleting,
      }));
    }
  };

  @action addUserToGroup = async (userId, groupId, successCb) => {
    try {
      const { data } = await this.API.addUserToGroup(userId, groupId);
      this._user.groups = uniqBy([...this.user.groups, data], 'id');
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };
}
