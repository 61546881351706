import { DEAZY_DETAILS } from '@app/constants';
import { Box, Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { get, join } from 'lodash-es';
import React from 'react';
import { Stat } from './InvoiceHeader';

const Details = ({ side, mainNameQA }) => (
  <>
    <Typography
      whiteSpace="pre-line"
      data-test-id={`${mainNameQA}--${side?.invoiceName || side?.name}`}
    >
      {side?.invoiceName || side?.name}
    </Typography>
    <Typography whiteSpace="pre-line" data-test-id={`${mainNameQA}--address`}>
      {side?.address}
    </Typography>
    <Typography data-test-id={`${mainNameQA}--location`}>
      {side?.location}
    </Typography>
    {side?.vatNumber && (
      <Stat
        label="VAT number"
        value={side?.vatNumber}
        mt="16px"
        pr="0px"
        data-test-id={`${mainNameQA}--vatNumber`}
      />
    )}
  </>
);

const InvoiceSides = ({ invoice, mainNameQA }) => {
  const isClientInvoice = invoice.contactType === 'Client';
  const deazyDetails = {
    name: join([DEAZY_DETAILS.fullName, DEAZY_DETAILS.noteToTheName], '\n'),
    address: join(
      [
        DEAZY_DETAILS.addressLine1,
        DEAZY_DETAILS.addressLine2,
        DEAZY_DETAILS.cityAndRegion,
        DEAZY_DETAILS.postalCode,
      ],
      '\n',
    ),
    location: DEAZY_DETAILS.countryCode,
    vatNumber: DEAZY_DETAILS.vatNumber,
  };

  const buyer = isClientInvoice
    ? get(invoice, 'invoiceDetailsInfo', {})
    : deazyDetails;
  const seller = isClientInvoice
    ? deazyDetails
    : get(invoice, 'invoiceDetailsInfo', {});

  return (
    <Flex w="100%" justifyContent="space-between">
      <Box>
        <Typography variant="h4" mb="8px">
          Buyer
        </Typography>
        <Details side={buyer} mainNameQA={mainNameQA} />
      </Box>
      <Box textAlign="right">
        <Typography variant="h4" mb="8px">
          Seller
        </Typography>
        <Details side={seller} mainNameQA={mainNameQA} />
      </Box>
    </Flex>
  );
};

export default InvoiceSides;
