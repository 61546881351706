import { Avatar, Box, Button, Flex, useDisclosure } from '@chakra-ui/react';
import Icon from '@components/Icon';
import styled from '@emotion/styled';
import DeleteModal from '@pages/Project/Overview/DeleteModal';
import { APP_ROUTES } from '@routes';
import { COLORS } from '@styles/theme';
import Typography from '@styles/Typography';
import { get, isEmpty, join, map, partition } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import TeamsCardBadge from './TeamCardBadge';

const StatsWrapper = styled(Flex)`
  :not(:first-of-type) {
    padding-left: 8px;
    margin-left: 8px;
  }
  height: fit-content;
`;

export const Stat = ({ label, value, onChange, ...props }) => (
  <Box
    borderLeft={`1px solid ${COLORS.dark.mid}`}
    pl="13px"
    mb="18px"
    _last={{ marginBottom: 0 }}
    {...props}
  >
    <Typography color="dark.solid" mb="8px" variant="text3">
      {label}
    </Typography>
    <Typography variant="text2" mb="8px">
      {value || '-'}
    </Typography>
  </Box>
);

const TeamCardContent = ({
  team,
  isSupplierRoute,
  isStatic,
  fetchTeamsFn,
  usersStore: { isAdminOrDL },
  teamsStore: { deleteSupplier, deleteClient },
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const route = useRouteMatch();
  const history = useHistory();

  const handleTeamEdit = e => {
    e.preventDefault();
    if (isSupplierRoute) {
      history.push(APP_ROUTES.teams.supplierByIdEdit(team.id));
    } else {
      history.push(APP_ROUTES.teams.clientByIdEdit(team.id));
    }
  };

  const handleTeamDelete = () => {
    if (isSupplierRoute) {
      return deleteSupplier({
        supplierId: team.id,
        onSuccess: () => {
          onClose();
          if (isEmpty(route.params.id)) {
            return fetchTeamsFn();
          }
          return history.push(APP_ROUTES.teams.suppliers);
        },
        onFailure: onClose,
      });
    }
    return deleteClient({
      clientId: team.id,
      onSuccess: () => {
        onClose();
        if (isEmpty(route.params.id)) {
          return fetchTeamsFn();
        }
        return history.push(APP_ROUTES.teams.clients);
      },
      onFailure: onClose,
    });
  };

  const currency = React.useMemo(() => {
    if (isSupplierRoute) {
      const [primaryPd, rest] = partition(
        team.paymentDetails,
        pd => pd.isPrimary,
      );
      if (!primaryPd?.[0]?.currency) {
        return '-';
      }
      return `${primaryPd?.[0]?.currency}${
        !isEmpty(rest)
          ? ` + ${join(
              map(rest, pd => pd.currency),
              ', ',
            )}`
          : ''
      }`;
    }
    return team.currency;
  }, [team]);

  return (
    <>
      <Box
        pb="12px"
        display="flex"
        alignItems="flex-start"
        borderBottom="1px solid"
        borderColor="dark.mid"
        w="100%"
      >
        <Flex pt="12px" pl="11px" align="center" mr="auto">
          <Avatar
            name={get(team, 'name', 'Deazy')}
            mr="7px"
            src={get(team, 'logo')}
            pt={get(team, 'logo') ? '0px' : '3px'}
            {...(get(team, 'logo') && { bg: 'transparent' })}
          />
          <Box>
            <Typography variant="h4" mb="5px">
              {team.name}
            </Typography>
          </Box>
        </Flex>
        <TeamsCardBadge team={team} />
      </Box>
      <Flex pt="16px" px="16px" w="100%" pb="26px">
        <StatsWrapper w="25%" direction="column">
          <Stat
            label="Finance Email"
            value={team.financeEmail || '-'}
            mr="10px"
            borderLeft="none"
            width="100%"
            overflowWrap="break-word"
          />
        </StatsWrapper>
        <StatsWrapper flexDir="column" w="25%">
          <Stat label="Team no." value={team.id} />
        </StatsWrapper>
        <StatsWrapper flexDir="column" w="20%">
          <Stat label="Vat Number" value={team.vatNumber || '-'} />
        </StatsWrapper>
        <StatsWrapper flexDir="column" w="15%">
          {isSupplierRoute && (
            <Stat
              label="Invoice Numbering Pattern"
              value={team.invoiceNumberingPattern || '-'}
            />
          )}
        </StatsWrapper>
      </Flex>
      <Flex pt="16px" px="16px" w="100%" pb={!isStatic ? '26px' : '66px'}>
        <StatsWrapper flexDir="column" w="25%">
          <Stat label="Currency" value={currency} borderLeft="none" />
        </StatsWrapper>
        <StatsWrapper flexDir="column" w="25%">
          <Stat label="Location" value={team.location} />
        </StatsWrapper>
        {!isSupplierRoute && (
          <StatsWrapper flexDir="column" w="25%">
            <Stat label="Type" value={team.type} />
          </StatsWrapper>
        )}
        {isSupplierRoute && (
          <StatsWrapper flexDir="column" w="20%">
            <Stat
              label="Invoice Numbering Timespan"
              value={team.invoiceNumberingTimespan}
            />
          </StatsWrapper>
        )}
        <StatsWrapper flexDir="column" w="15%">
          {isSupplierRoute && (
            <Stat
              label="Invoice name"
              value={team.invoiceName || '-'}
              mr="10px"
            />
          )}
        </StatsWrapper>
      </Flex>
      {!isStatic && (
        <Flex pt="16px" px="16px" w="100%" pb="66px">
          <StatsWrapper flexDir="column" w="25%">
            <Stat
              label="Address"
              value={team.address || '-'}
              borderLeft="none"
            />
          </StatsWrapper>
          <StatsWrapper flexDir="column" w="25%">
            {isSupplierRoute && (
              <Stat label="Invoice Info" value={team.paymentInfo || '-'} />
            )}
          </StatsWrapper>
        </Flex>
      )}
      {isAdminOrDL && (
        <Flex
          px="16px"
          pb="16px"
          alignItems="center"
          position="absolute"
          bottom="0"
          right="0"
        >
          {!isStatic && (
            <Button
              leftIcon={<Icon fontSize={16} mr="5px" name="edit" />}
              bg="dark.solid"
              color="#fff"
              fontSize={14}
              variant="solid"
              borderRadius="32px"
              onClick={handleTeamEdit}
              fontWeight="initial"
              size="sm"
              mr="8px"
              px="14px"
            >
              Edit
            </Button>
          )}
          <Button
            onClick={e => {
              e.preventDefault();
              onOpen();
            }}
            leftIcon={<Icon fontSize={16} mr="5px" name="trash" />}
            bg="red.solid"
            color="#fff"
            fontSize={14}
            variant="solid"
            borderRadius="32px"
            fontWeight="initial"
            size="sm"
            mr="8px"
            px="14px"
          >
            Delete
          </Button>
        </Flex>
      )}
      {isOpen && (
        <DeleteModal
          onClose={e => {
            if (e) {
              e.preventDefault();
            }
            onClose();
          }}
          isOpen={isOpen}
          onDelete={handleTeamDelete}
        />
      )}
    </>
  );
};

export default inject('usersStore', 'teamsStore')(observer(TeamCardContent));
