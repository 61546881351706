import { Box } from '@chakra-ui/react';
import Spinner from '@styles/Spinner';
import { isEmpty } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import ShortlistedSuppliersForm from './ShortlistedSuppliersForm';

const SupplierList = ({
  briefAssignmentsStore: {
    assignments: { isLoading },
    decoratedAssignments: assignments,
  },
  mainNameQA,
  isMobile,
}) => {
  if (isLoading) {
    return <Spinner my="24px" variant="small" />;
  }

  if (isEmpty(assignments)) {
    return null;
  }

  return (
    <Box
      borderTop={isMobile ? 'none' : '1px solid'}
      borderColor="dark.mid"
      mt={isMobile ? '0px' : '40px'}
      pt="24px"
    >
      <ShortlistedSuppliersForm mainNameQA={mainNameQA} />
    </Box>
  );
};

export default inject('briefAssignmentsStore')(observer(SupplierList));
