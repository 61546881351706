import Spinner from '@styles/Spinner';
import { MAIN_NAMES_QA } from '@app/constants';
import Typography from '@styles/Typography';
import { isFixedPrice, isFixedPriceProjectType } from '@utils/projectUtils';
import { isEmpty, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AllocationCard from '../AllocationCard';
import { getDeazyAllocationsColumns } from '../constants';
import Drawer from '../Drawer';

const AllDeazyAllocations = ({
  projectsStore: { project },
  allocationsStore: {
    fetchAllDeazyAllocations,
    allDeazyAllocations,
    clearAllAllocations,
    deazyAllocations: {
      all: { isLoading },
    },
  },
}) => {
  React.useEffect(() => {
    if (project.id) {
      fetchAllDeazyAllocations(project.id);
    }
    return () => {
      clearAllAllocations();
    };
  }, [project.id]);

  const isProjectFixedPriceModal = isFixedPriceProjectType(project);

  return (
    <Drawer>
      <Typography variant="h3" mb="80px">
        All deazy allocations
      </Typography>
      {map(allDeazyAllocations, allocation => (
        <AllocationCard
          columns={getDeazyAllocationsColumns({
            isFixedPrice: isFixedPrice(project),
          })}
          mainNameQA={MAIN_NAMES_QA.PROJECT_ALLOCATIONS}
          key={`allocation-card-${allocation.id}`}
          allocation={allocation}
          isProjectFixedPriceModal={isProjectFixedPriceModal}
          data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--alocationCard`}
        />
      ))}
      {isLoading && <Spinner />}
      {isEmpty(allDeazyAllocations) && !isLoading && (
        <Typography variant="h3" textAlign="center" my="40px">
          No allocations found.
        </Typography>
      )}
    </Drawer>
  );
};

export default inject(
  'allocationsStore',
  'projectsStore',
)(observer(AllDeazyAllocations));
