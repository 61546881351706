import React from 'react';
import { Flex } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import useSearchParams from '@hooks/useSearchParams';
import Badge from '@styles/Badge';
import { MAIN_NAMES_QA } from '@app/constants';
import { map } from 'lodash-es';
import { Link } from 'react-router-dom';
import { tabProps } from '../../ProposalTabs';

const ShortlistedDPsHeader = ({
  counts,
  clearAssignmentList,
  fetchAssignmentList,
  assignments,
  unassignSupplier,
}) => {
  const {
    searchObj: { filter: activeTab = 'best', ...searchObj },
    onSearch,
  } = useSearchParams();

  React.useEffect(() => {
    fetchAssignmentList();
    return () => {
      clearAssignmentList();
    };
  }, []);

  const containerRef = React.useRef();

  const tabs = React.useMemo(
    () => [
      { label: `BEST MATCHES (${counts.best})`, value: 'best' },
      { label: `NEW DELIVERY PARTNERS (${counts.new})`, value: 'new' },
      { label: `PARTIAL MATCHES (${counts.partial})`, value: 'partial' },
    ],
    [counts],
  );

  const prevAssignments = React.useRef(assignments);

  React.useEffect(() => {
    if (
      containerRef &&
      containerRef.current &&
      prevAssignments?.current?.length > 0 &&
      prevAssignments?.current?.length < assignments.length
    ) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
    }
    if (assignments.length !== prevAssignments?.current?.length) {
      prevAssignments.current = assignments;
    }
  }, [containerRef, prevAssignments, assignments]);

  return (
    <Flex flexDir="column" mb="32px" w="100%">
      <WhiteCard
        mt={[0, 0, 0, '24px']}
        py="14px"
        px="12px"
        mb="24px"
        border="1px solid"
        borderColor="dark.mid"
        display="flex"
        w={[
          'calc(100% + 32px)',
          'calc(100% + 32px)',
          'calc(100% + 32px)',
          '100%',
        ]}
        ml={['-16px', '-16px', '-16px', 0]}
        borderRadius={[0, 0, 0, '8px']}
      >
        <Flex alignItems="center" mr="auto" gap="8px" w="100%">
          {assignments?.length ? (
            <>
              <Typography
                alignSelf={['center', 'center', 'center', 'flex-start']}
              >
                Shortlisted:
              </Typography>
              <Flex
                overflowX={['scroll', 'scroll', 'scroll', 'auto']}
                ref={containerRef}
                gap="8px"
                alignItems="center"
                flexWrap={['nowrap', 'nowrap', 'nowrap', 'wrap']}
              >
                {map(assignments, dp => {
                  return (
                    <Badge
                      whiteSpace="nowrap"
                      key={`assignment-badge-${dp.id}`}
                      variant="tertiary"
                      data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--dpShortlisted`}
                      bg="dark.light"
                      isClosable
                      color="black"
                      onClose={() => {
                        unassignSupplier(dp.supplier.id);
                      }}
                    >
                      {dp.supplier.name}
                    </Badge>
                  );
                })}
              </Flex>
            </>
          ) : (
            <Typography color="gray.mid">
              No delivery partners shotlisted yet
            </Typography>
          )}
        </Flex>
        <Typography
          as={Link}
          to="assignment"
          _hover={{ opacity: 0.7 }}
          color="blue.solid"
          minW="210px"
          display={['none', 'none', 'none', 'block']}
        >
          Manage Delivery Partners
        </Typography>
      </WhiteCard>

      <Flex
        h={['auto', 'auto', 'auto', '40px']}
        borderRadius="8px"
        mb={[0, 0, 0, '34px']}
      >
        {map(tabs, tab => (
          <Flex
            key={`matching-tab-${tab.value}`}
            textAlign="center"
            {...tabProps(activeTab === tab.value)}
            onClick={() => {
              onSearch({ ...searchObj, filter: tab.value });
            }}
            px="16px"
            p={['10px', '10px', '10px', 0]}
            minW={['100px', '100px', '100px', '232px']}
          >
            {tab.label}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default ShortlistedDPsHeader;
