import { Box, Flex, Spinner, Tooltip } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import { formatNumber } from '@utils';
import { isNumber } from 'lodash-es';
import React from 'react';

const CostRow = ({
  margin,
  title,
  currency,
  hours,
  cost,
  showHours = true,
  infoTooltipLabel,
  subText,
  mainNameQA,
  qaValue = '',
  titleContainerProps,
  isSmallerScreen,
  ...props
}) => (
  <Flex
    border="1px solid"
    borderColor="dark.mid"
    borderRadius="8px"
    px="16px"
    py="20px"
    mb="12px"
    flexDir="row"
    alignItems="flex-start"
    flexFlow={isSmallerScreen && 'wrap'}
    {...props}
  >
    <Flex
      mr="auto"
      mb={['16px', '16px', '16px', 0]}
      {...titleContainerProps}
      flexDir="row"
      width={isSmallerScreen && '100%'}
    >
      <Typography variant="h4" alignSelf="center" mr="40px">
        {title}
      </Typography>
      {subText && (
        <Typography mt="5px" color="green.solid">
          {subText}
        </Typography>
      )}
    </Flex>
    {showHours && (
      <Flex
        alignItems="center"
        mr="20px"
        mt="3px"
        width={isSmallerScreen && '50%'}
      >
        <Icon name="clock" color="blue.solid" fontSize={20} mr="5px" />
        {hours ? (
          <Typography
            data-test-id={mainNameQA && `${mainNameQA}-${qaValue}-hours`}
            alignSelf="center"
          >{`${hours} Hours`}</Typography>
        ) : (
          <Spinner />
        )}
      </Flex>
    )}
    <Flex>
      {isNumber(margin) && (
        <Flex
          alignItems="center"
          mr={['40px', '40px', '40px', '20px']}
          mt="3px"
        >
          <Typography fontSize={20} fontFamily="WM" color="blue.solid" mr="5px">
            %
          </Typography>
          <Typography
            data-test-id={mainNameQA && `${mainNameQA}-${qaValue}-margin`}
            alignSelf="center"
          >
            {formatNumber(margin, 0)}
          </Typography>
        </Flex>
      )}
      <Flex alignItems="center" mt="3px">
        <Icon
          name={currency?.toLowerCase() || 'gbp'}
          data-test-id={mainNameQA && `${mainNameQA}--currency`}
          fontSize={20}
          mr="5px"
          color="blue.solid"
        />
        {cost ? (
          <Typography
            alignSelf="center"
            data-test-id={mainNameQA && `${mainNameQA}-${qaValue}-cost`}
          >
            {`${cost}`}
          </Typography>
        ) : (
          <Spinner />
        )}
      </Flex>

      {infoTooltipLabel && (
        <Tooltip
          zIndex={33000}
          p="12px"
          borderRadius="8px"
          lineHeight="normal"
          hasArrow
          bg="dark.solid"
          px="24px"
          py="12px"
          placement="top"
          fontSize={16}
          label={infoTooltipLabel}
        >
          <Box as="span" mr="5px" mb="4px">
            <Icon name="infoOutline" ml="10px" />
          </Box>
        </Tooltip>
      )}
    </Flex>
  </Flex>
);

export default CostRow;
