import { Flex } from '@chakra-ui/react';
import qs from 'query-string';
import React from 'react';
import Typography from '@styles/Typography';
import { useHistory } from 'react-router';
import SortAndOrderSelect from './SortAndOrderSelect';

const DEFAULT_OPTIONS = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '100',
    value: '100',
  },
];

const PageSize = ({ containerProps, ...props }) => {
  const history = useHistory();
  const { pageSize = '10', ...params } = qs.parse(history.location.search);
  return (
    <Flex
      alignItems="center"
      minW="250px"
      key={`page-size-${history.location.search}`}
      {...containerProps}
    >
      <Typography mr="8px">View</Typography>
      <SortAndOrderSelect
        options={DEFAULT_OPTIONS}
        data-test-id="pageSize"
        isRegular
        minW="80px"
        defaultValue={pageSize}
        onChange={({ value }) =>
          history.push(
            `?${qs.stringify({ ...params, page: 1, pageSize: value })}`,
          )
        }
        {...props}
      />
      <Typography ml="8px">per page</Typography>
    </Flex>
  );
};
export default PageSize;
