import moment from 'moment';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { inject, observer } from 'mobx-react';
import Button from '@styles/Button';
import React from 'react';
import ReadOnlyTable from '@components/ReadOnlyTable';
import { XERO_CREDIT_NOTE_URL, NEW_INVOICE_STATUSES } from '@app/constants';
import { determineStatusBadgeBg } from '@utils/invoiceUtils';
import { Stat, InvoiceStatus } from '../components';
import CreditNoteForm from './CreditNoteForm';
import DeleteCreditNoteModal from './DeleteCreditNoteModal';

const statStyles = {
  borderRight: 'none',
  mr: '8px',
};

const COLUMNS = [
  {
    name: 'Description',
    w: '40%',
    ellipsis: false,
  },
  {
    name: 'Quantity',
    w: '15%',
  },
  {
    name: 'Unit price',
    w: '15%',
  },
  {
    name: 'Vat',
    w: '15%',
  },
  { name: 'Amount', w: '15%' },
];

const CreditNote = ({
  invoice = {},
  isAdmin,
  onCancelCreditNoteForm,
  projectsStore: {
    projectCurrency: { currency },
    project,
  },
  creditAllocation = {},
  paymentsStore: {
    approveOrPreapproveCreditNote,
    createCreditNote,
    deleteCreditNote,
    rejectCreditNote,
  },
  refetchInvoiceHistory,
  mainNameQA,
}) => {
  const { creditNote } = creditAllocation;
  const isPreApprovalReady =
    creditNote?.status === NEW_INVOICE_STATUSES.READY_FOR_PRE_APPROVE;
  const isApprovalReady = creditNote?.status === NEW_INVOICE_STATUSES.READY;

  const xeroLink = () => {
    if (!creditNote?.xeroCreditNoteId) {
      return null;
    }
    return invoice.contactType === 'Client'
      ? `${XERO_CREDIT_NOTE_URL.CLIENT}${creditNote.xeroCreditNoteId}`
      : `${XERO_CREDIT_NOTE_URL.SUPPLIER}${creditNote.xeroCreditNoteId}`;
  };

  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Flex flexDir="column" position="relative" mt="48px">
      <Box
        borderTop="2px dotted"
        borderColor="dark.mid"
        position="absolute"
        width="calc(100% + 48px)"
        top="-24px"
        left="-24px"
      />
      <Flex justifyContent="space-between" mb="24px">
        <Typography variant="h4">Credit note</Typography>
        <InvoiceStatus
          data-test-id={`${mainNameQA}--cnStatus`}
          value={creditNote?.status || 'Draft'}
          bg={determineStatusBadgeBg(creditNote || {})}
        />
      </Flex>
      <Flex mb="36px">
        <Stat
          {...statStyles}
          label="Xero number"
          value={creditNote?.xeroCreditNoteNumber}
          data-test-id={`${mainNameQA}--xeroCreditNoteNumber`}
        />
        <Stat
          {...statStyles}
          label="Xero invoice link"
          value={xeroLink() ? 'xero.com' : '-'}
          data-test-id={`${mainNameQA}--xeroCreditNoteLink`}
          {...(xeroLink() && {
            as: 'a',
            href: xeroLink(),
            target: '_blank',
          })}
        />
        {creditNote?.id && (
          <>
            <Stat
              {...statStyles}
              label="Date"
              value={moment.utc(creditNote.timestamp).format('DD MMM YYYY')}
              data-test-id={`${mainNameQA}--xeroCreditNoteDate`}
            />
            <Stat
              {...statStyles}
              label="Reference"
              value={creditNote.reference}
              data-test-id={`${mainNameQA}--xeroCreditNoteReference`}
            />
          </>
        )}
        {(isPreApprovalReady || isApprovalReady) && (
          <Button
            bg="#fff"
            iconButton={{ name: 'trashNew', fontSize: 20, color: 'black' }}
            onClick={onOpen}
            w="40px"
            h="40px"
            ml="auto"
            data-test-id={`${mainNameQA}--xeroCreditNoteModalOpen`}
          />
        )}
      </Flex>
      {creditNote?.id ? (
        <ReadOnlyTable
          columns={COLUMNS}
          tableData={creditAllocation.creditNoteTableData}
          qaTag={mainNameQA}
        />
      ) : (
        <CreditNoteForm
          invoice={invoice}
          onSubmit={values =>
            createCreditNote(values, () => {
              onCancelCreditNoteForm();
              refetchInvoiceHistory();
            })
          }
          onCancel={onCancelCreditNoteForm}
          project={project}
          currency={currency}
          mainNameQA={mainNameQA}
        />
      )}
      <Flex mt="16px">
        {isPreApprovalReady && (
          <Button
            mr="16px"
            leftIcon={{ name: 'check', fontSize: 22 }}
            isLoading={creditNote.isApproving}
            data-test-id={`${mainNameQA}--preApprove`}
            onClick={() => {
              approveOrPreapproveCreditNote(
                project.id,
                invoice.id,
                creditNote.id,
                true,
              );
            }}
          >
            Pre-approve credit note
          </Button>
        )}
        {isApprovalReady && isAdmin && (
          <>
            <Button
              mr="16px"
              leftIcon={{ name: 'check', fontSize: 22 }}
              isLoading={creditNote.isApproving}
              data-test-id={`${mainNameQA}--approve`}
              onClick={() => {
                approveOrPreapproveCreditNote(
                  project.id,
                  invoice.id,
                  creditNote.id,
                );
              }}
            >
              Approve credit note
            </Button>
            <Button
              mr="auto"
              variant="outline"
              onCancel={onCancelCreditNoteForm}
              leftIcon={{ name: 'cross', fontSize: 24 }}
              isLoading={creditNote.isRejecting}
              onClick={() =>
                rejectCreditNote(project.id, invoice.id, creditNote.id)
              }
              data-test-id={`${mainNameQA}--reject`}
            >
              Reject
            </Button>
          </>
        )}
      </Flex>
      <DeleteCreditNoteModal
        onClose={onClose}
        isOpen={isOpen}
        isDeleting={creditNote?.isDeleting}
        onConfirm={() =>
          deleteCreditNote(project.id, invoice.id, creditNote?.id, onClose)
        }
        mainNameQA={mainNameQA}
      />
    </Flex>
  );
};

export default inject('projectsStore', 'paymentsStore')(observer(CreditNote));
