import Dropzone from '@pages/BriefsCreate/BasicsStep/Dropzone';
import { Field, Form } from 'react-final-form';
import { isEmpty, map } from 'lodash-es';
import { fileSizeValidation } from '@utils';
import { Flex, useDisclosure, Box } from '@chakra-ui/react';
import React from 'react';
import Typography from '@styles/Typography';
import Button from '@styles/Button';
import DeleteModal from '@pages/Project/Overview/DeleteModal';
import { Document } from '../Brief/Overview/components';

const UploadCSVForm = ({ files, onSubmit, deleteCSVTemplate }) => {
  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();

  const [targetFile, setTargetFile] = React.useState(null);
  return (
    <>
      <Box p="40px">
        <Typography variant="h3" mb="24px">
          Upload CSV template
        </Typography>
        <Form
          onSubmit={onSubmit}
          validate={fileSizeValidation}
          render={({ handleSubmit, submitting, values, errors }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Field
                  component={Dropzone}
                  name="files"
                  errors={errors.files}
                  width="100%"
                  supportedExtensions={['.csv']}
                />
                <Button
                  type="submit"
                  mt="40px"
                  isDisabled={isEmpty(values.files) || submitting}
                >
                  Upload
                </Button>
              </form>
            );
          }}
        />
      </Box>
      {!isEmpty(files) && (
        <Box p="40px">
          <Flex w="100%" flexWrap="wrap" justifyContent="space-between">
            {map(files, file => (
              <Document
                w="calc(50% - 12px)"
                key={`file-${file.id}`}
                {...file}
                deleteFn={() => deleteCSVTemplate(file.id)}
                onDeleteOpen={onDeleteOpen}
                onClick={() => setTargetFile(file)}
              />
            ))}
          </Flex>
        </Box>
      )}
      {isDeleteOpen && (
        <DeleteModal
          onClose={onDeleteClose}
          isOpen={isDeleteOpen}
          onDelete={() =>
            deleteCSVTemplate(targetFile.id, () => {
              onDeleteClose();
              setTargetFile(null);
            })
          }
        />
      )}
    </>
  );
};

export default UploadCSVForm;
