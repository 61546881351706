import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const DeletePaymentModal = ({
  isOpen,
  onClose,
  isLoading,
  onDelete,
  mainNameQA,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styleProps={{ p: '20px', maxW: '440px' }}
      closeOnOverlayClick={!isLoading}
      closeOnEsc={!isLoading}
    >
      <Typography variant="h1" mb="20px">
        Are you sure?
      </Typography>
      <Typography>
        You will need to update the reference of any later invoices to preserve
        the ordering.
      </Typography>
      <Stack spacing={3} mt="40px">
        <Button
          isLoading={isLoading}
          onClick={onDelete}
          data-test-id={`${mainNameQA}--confirmDeletePayment`}
        >
          Yes
        </Button>
        <Button
          variant="outline"
          onClick={onClose}
          isDisabled={isLoading}
          data-test-id={`${mainNameQA}--cancelDeletePayment`}
        >
          No
        </Button>
      </Stack>
    </Modal>
  );
};

export default DeletePaymentModal;
