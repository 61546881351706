import { API } from '@app/api';
import { PROJECT_TECHNOLOGY } from '@app/constants';
import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Stack,
  Tag,
  TagLabel,
  TagRightIcon,
} from '@chakra-ui/react';
import Condition from '@components/forms/Condition';
import ErrorSpy from '@components/forms/ErrorSpy';
import MultiSelect from '@components/forms/MultiSelect';
import RadioGroupField from '@components/forms/RadioGroupField';
import Select from '@components/forms/_common/Select';
import Textarea from '@components/forms/_common/Textarea';
import useMediaQuery from '@hooks/useMediaQuery';
import useQuery from '@hooks/useQuery';
import { breakpoint } from '@styles/breakpoints';
import Typography from '@styles/Typography';
import { mustBeShorterOrEqual, required } from '@utils/formValidators';
import {
  filter,
  flatten,
  isEmpty,
  map,
  some,
  uniq,
  values as _values,
} from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form } from 'react-final-form';
import WizardFooter from '../WizardFooter';
import WizardLeavingGuard from '../WizardLeavingGuard';

const PROJECT_TECHNOLOGY_OPTIONS = map(_values(PROJECT_TECHNOLOGY), v => ({
  label: v,
  value: v,
}));

const titleStyles = isSmallerScreen =>
  isSmallerScreen
    ? {
        mt: 6,
        mb: 3,
        fontSize: '20px',
      }
    : {
        mt: 14,
        mb: 6,
      };

const SuggestedInlineTag = ({ label, onClick }) => (
  <Tag
    p="6px 8px"
    background="#fff"
    mr="12px"
    mt="12px"
    border="1px dashed"
    borderColor="#D9DADA"
    borderRadius="8px"
    onClick={onClick}
    textTransform="uppercase"
    fontSize={12}
    fontFamily="WM"
    minW="auto"
    _hover={{
      cursor: 'pointer',
    }}
  >
    <TagLabel lineHeight="normal">{label}</TagLabel>
    <TagRightIcon as={AddIcon} fontSize={10} />
  </Tag>
);

const TechStackForm = ({
  initialValues,
  onSubmit,
  submit,
  onPrevClick,
  briefId,
  mainNameQA,
}) => {
  const { data: industries, isLoading } = useQuery(API.getIndustries, {});
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  const industryOptions = map(industries, ind => ({ label: ind, value: ind }));

  const filteredTechnology = (technologies = []) => {
    return filter(PROJECT_TECHNOLOGY_OPTIONS, option => {
      return !some(technologies, t => t.value === option.value);
    });
  };

  const {
    data: technologiesFromAPI,
    isLoading: isLoadingTechnologies,
  } = useQuery(value => API.getBriefTechStack(briefId, value), {});

  const technologiesOptions = React.useMemo(() => {
    const arr = uniq(
      flatten([...Object.values(PROJECT_TECHNOLOGY), technologiesFromAPI]),
    );
    return map(arr, mappedItem => ({
      label: mappedItem,
      value: mappedItem,
    }));
  }, [technologiesFromAPI]);

  const [inlineTagFlag, setInlineTagFlag] = React.useState(false);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ values, handleSubmit, submitting, form: { change } }) => {
          submit(handleSubmit);
          const { technologies = [] } = values;

          const onChooseTechnology = t => {
            const techs = technologies ? [...technologies, t] : [t];
            return change('technologies', techs);
          };

          return (
            <form onSubmit={handleSubmit}>
              <ErrorSpy />
              <Stack
                spacing={6}
                {...(isSmallerScreen
                  ? { direction: 'column', mb: '24px' }
                  : { direction: 'row', mb: '56px' })}
              >
                <Box flex={1}>
                  <Typography variant="h2" mb={6}>
                    Mobile app or website?
                  </Typography>
                  <Field
                    choices={[
                      { label: 'Mobile app', value: 'Mobile app' },
                      { label: 'Website', value: 'Website' },
                      { label: 'Both', value: 'Both' },
                      { label: 'Help me decide', value: 'Undecided' },
                    ]}
                    name="appOrWebsite"
                    data-test-id={`${mainNameQA}--appOrWebsite`}
                    validate={required}
                    component={RadioGroupField}
                    fieldsWrapperStyles={
                      isSmallerScreen && { flexWrap: 'wrap', gap: '10px 34px' }
                    }
                  />
                </Box>
                <Box flex={1}>
                  <Typography
                    variant="h2"
                    {...titleStyles(isSmallerScreen)}
                    mt={0}
                  >
                    Industry
                  </Typography>

                  <Field
                    name="industryVertical"
                    data-test-id={`${mainNameQA}--industryVertical`}
                    arrowColor="green.solid"
                    placeholder="Select a industry"
                    options={industryOptions}
                    validate={required}
                    component={Select}
                  />
                </Box>
              </Stack>

              <Condition when="appOrWebsite" isOneOf={['Mobile app', 'Both']}>
                <Stack
                  spacing={6}
                  {...(isSmallerScreen
                    ? { direction: 'column', mb: '24px' }
                    : { direction: 'row', mb: '56px' })}
                >
                  <Box flex={1}>
                    <Typography variant="h2" mb="24px">
                      Technical approach
                    </Typography>

                    <Field
                      choices={[
                        { label: 'Native', value: 'Native' },
                        { label: 'Cross-platform', value: 'CrossPlatform' },
                        { label: 'Help me decide', value: 'Undecided' },
                      ]}
                      name="techApproach"
                      data-test-id={`${mainNameQA}--techApproach`}
                      validate={required}
                      component={RadioGroupField}
                      fieldsWrapperStyles={
                        isSmallerScreen && {
                          flexWrap: 'wrap',
                          gap: '10px 34px',
                        }
                      }
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography variant="h2" mb="24px">
                      Which platforms?
                    </Typography>

                    <Field
                      choices={[
                        { label: 'iOS', value: 'Ios' },
                        { label: 'Android', value: 'Android' },
                        { label: 'Both', value: 'Both' },
                        { label: 'Help me decide', value: 'Undecided' },
                      ]}
                      name="platforms"
                      validate={required}
                      data-test-id={`${mainNameQA}--platforms`}
                      component={RadioGroupField}
                      fieldsWrapperStyles={
                        isSmallerScreen && {
                          flexWrap: 'wrap',
                          gap: '10px 34px',
                        }
                      }
                    />
                  </Box>
                </Stack>
              </Condition>

              <Box w="100%">
                <Typography
                  variant="h2"
                  {...titleStyles(isSmallerScreen)}
                  mb={4}
                >
                  Required tech stack?
                </Typography>
                <Typography mb={isSmallerScreen ? 4 : 8}>
                  Add any languages, technologies and frameworks you’d like to
                  use
                </Typography>
              </Box>
              <Box mb={isSmallerScreen ? '24px' : '56px'}>
                <Box key={`technologies-${JSON.stringify(inlineTagFlag)}`}>
                  <Field
                    component={MultiSelect}
                    name="technologies"
                    data-test-id={`${mainNameQA}--technologies`}
                    placeholder="Select or type tags..."
                    isLoading={isLoadingTechnologies}
                    options={technologiesOptions}
                    onAfterChange={tech => {
                      change(`technologies`, tech);
                    }}
                    iconColor="black"
                  />
                </Box>

                <Flex alignItems="baseline" wrap={isSmallerScreen && 'wrap'}>
                  {!isEmpty(filteredTechnology(technologies)) && (
                    <Typography marginRight="12px" fontSize="12px">
                      Suggested:
                    </Typography>
                  )}
                  {map(filteredTechnology(technologies), tech => {
                    return (
                      <SuggestedInlineTag
                        onClick={() => {
                          setInlineTagFlag(!inlineTagFlag);
                          onChooseTechnology(tech);
                        }}
                        key={`technology-suggested-${tech.label}`}
                        label={tech.label}
                      />
                    );
                  })}
                </Flex>
              </Box>

              <Typography variant="h4" {...titleStyles(isSmallerScreen)} mb={6}>
                Can’t find what you need?
              </Typography>
              <Field
                name="techRequirements"
                data-test-id={`${mainNameQA}--techRequirements`}
                component={Textarea}
                placeholder="Tell us about your tech requirements here"
                mb="45px"
                validate={mustBeShorterOrEqual(300)}
              />
              <WizardFooter
                onPrevClick={onPrevClick}
                mainNameQA={mainNameQA}
                submitting={submitting || isLoading}
              />
            </form>
          );
        }}
      />
      <WizardLeavingGuard isFormDirty />
    </>
  );
};

export default inject('toastsStore')(observer(TechStackForm));
