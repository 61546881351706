import { action, computed, makeObservable, observable } from 'mobx';
import { map, groupBy } from 'lodash-es';
import moment from 'moment';
import { nanoid } from 'nanoid';

export default class NationalHolidaysStore {
  constructor({ API, toastsStore }) {
    makeObservable(this);
    this.toastsStore = toastsStore;
    this.API = API;
  }

  @observable isLoading = false;

  @observable firstRequest = true;

  @observable upcomingHolidays = [];

  @observable pastHolidays = [];

  @action clearHolidays = () => {
    this.upcomingHolidays = [];
    this.firstRequest = true;
    this.pastHolidays = [];
  };

  @action getUpcomingHolidays = async (projectId, limited = false) => {
    this.isLoading = true;
    try {
      const { data } = await this.API.getUpcomingHolidays(limited)(projectId);
      this.upcomingHolidays = data;
      this.firstRequest = false;
    } catch (e) {
      this.toastsStore.showError({ title: e.message || 'Network error' });
    } finally {
      this.isLoading = false;
    }
  };

  @computed get docoratedUpcomingHolidays() {
    const mappedHolidays = map(this.upcomingHolidays, h => {
      return {
        ...h,
        month: moment(h.date).format('MMMM YYYY'),
        id: nanoid(10),
      };
    });

    return map(groupBy(mappedHolidays, 'month'), (_, holidayMonth) => ({
      items: _,
      month: holidayMonth,
    }));
  }

  @computed get docoratedPastHolidays() {
    const mappedHolidays = map(this.pastHolidays, h => {
      return {
        ...h,
        month: moment(h.date).format('MMMM YYYY'),
        id: nanoid(10),
      };
    });

    return map(groupBy(mappedHolidays, 'month'), (_, holidayMonth) => ({
      items: _,
      month: holidayMonth,
    }));
  }

  @action getPastHolidays = async projectId => {
    this.isLoading = true;
    try {
      const { data } = await this.API.getPastHolidays(projectId);
      this.pastHolidays = data;
    } catch (e) {
      this.toastsStore.showError({ title: e.message || 'Network error' });
    } finally {
      this.isLoading = false;
    }
  };
}
