import React from 'react';
import { inject, observer } from 'mobx-react';
import { FormSpy } from 'react-final-form';

const scrollToError = () => {
  setTimeout(() => {
    const error = document.querySelector('.final-form-error');
    if (error) {
      error.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, 100);
};

const ErrorSpy = ({
  toastsStore: { showError },
  title = 'Please complete all the required sections',
}) => {
  return (
    <>
      <FormSpy
        subscription={{ submitFailed: true }}
        onChange={({ submitFailed }) => {
          if (submitFailed) {
            showError({ title });
            scrollToError();
          }
        }}
      />
      <FormSpy
        subscription={{
          dirtySinceLastSubmit: true,
          modifiedSinceLastSubmit: true,
        }}
        onChange={({ dirtySinceLastSubmit, modifiedSinceLastSubmit }) => {
          if (!dirtySinceLastSubmit && modifiedSinceLastSubmit) {
            showError({ title });
            scrollToError();
          }
        }}
      />
    </>
  );
};

export default inject('toastsStore')(observer(ErrorSpy));
