import {
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import React from 'react';
import CandidateDiffTile from './CandidateDiffTile';

const CandidateDiffTooltip = ({ children, newDiff }) => {
  return (
    <Popover>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent border="none">
          <PopoverCloseButton mt="8px" />
          <PopoverBody p="32px 0px 0px 0px">
            <CandidateDiffTile {...newDiff} isTooltip />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default CandidateDiffTooltip;
