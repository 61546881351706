import Badge from '@styles/Badge';
import React from 'react';
import { CANDIDATE_STATUSES } from '@app/constants';

const getCandidateStatusBadgeProps = status => {
  if (status === CANDIDATE_STATUSES.SHORTLISTED) {
    return 'aqua.solid';
  }
  if (status === CANDIDATE_STATUSES.QUERIED) {
    return 'orange.solid';
  }
  if (status === CANDIDATE_STATUSES.WON) {
    return 'green.solid';
  }
  if (status === CANDIDATE_STATUSES.LOST) {
    return 'red.solid';
  }
  return 'primary.500';
};

const CandidateBadge = ({ status, mainNameQA, ...props }) => (
  <Badge
    variant="tertiary"
    bg={getCandidateStatusBadgeProps(status)}
    data-test-id={mainNameQA && `${mainNameQA}--statusBadge`}
    {...props}
  >
    {status}
  </Badge>
);

export default CandidateBadge;
