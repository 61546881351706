import 'element.scrollintoviewifneeded-polyfill';
import ReactDOMClient from 'react-dom/client';
import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { Provider } from 'mobx-react';
import { configure } from 'mobx';

import App from './App';
import * as serviceWorker from './serviceWorker';
import rootStore from './stores/rootStore';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

configure({
  enforceActions: 'never',
});

root.render(
  <Provider {...rootStore}>
    <App />
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
