import Modal from '@components/Modal';
import React from 'react';
import { Stack } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import Button from '@styles/Button';

const DeleteCreditNoteModal = ({
  isOpen,
  onClose,
  isDeleting,
  onConfirm,
  mainNameQA,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <Typography variant="h2" mb="24px" textAlign="center">
      Delete credit note
    </Typography>
    <Typography fontSize={15} mb="40px" textAlign="center">
      You are about to delete this credit note. This action cannot be undone.
      Please confirm.
    </Typography>
    <Stack spacing={3} mt="40px">
      <Button
        isLoading={isDeleting}
        type="submit"
        onClick={onConfirm}
        data-test-id={`${mainNameQA}--deleteCreditNoteConfirm`}
      >
        Confirm
      </Button>
      <Button
        variant="outline"
        onClick={onClose}
        data-test-id={`${mainNameQA}--deleteCreditNoteCancel`}
      >
        Cancel
      </Button>
    </Stack>
  </Modal>
);

export default DeleteCreditNoteModal;
