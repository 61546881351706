import Typography from '@styles/Typography';
import { isEmpty, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AllocationCard from '../AllocationCard';
import { getDeazyAllocationsColumns } from '../constants';

const LastDeazyActuals = ({ allocationsStore: { lastDeazyActuals } }) => {
  if (isEmpty(lastDeazyActuals)) {
    return (
      <Typography textAlign="center" variant="h4" my="20px">
        No last deazy actuals!
      </Typography>
    );
  }
  return (
    <>
      <Typography variant="h4" my="20px">
        Last deazy actual:
      </Typography>
      {map(lastDeazyActuals, allocation => (
        <AllocationCard
          key={`allocation-entry-card-${allocation.id}`}
          allocation={allocation}
          columns={getDeazyAllocationsColumns({
            isFixedPrice: false,
          })}
          mb="20px"
        />
      ))}
    </>
  );
};

export default inject('allocationsStore')(observer(LastDeazyActuals));
