import { Box, Flex, Skeleton } from '@chakra-ui/react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import qs from 'query-string';
import { PROJECT_TYPES } from '@app/constants';
import { map, times, cloneDeep } from 'lodash-es';
import { APP_ROUTES } from '@routes';
import React from 'react';
import ProjectForm from './ProjectForm';
import LinkedBriefBanner from './LinkedBriefBanner';
import ConvertBriefWarningModal from './ConvertBriefWarningModal';

const Loader = () =>
  map(times(4), i => (
    <React.Fragment key={`skeleton-${i}`}>
      <Skeleton w="100%" h="35px" my="15px" borderRadius="8px" />
      <Skeleton ml="60px" w="90%" h="35px" my="15px" borderRadius="8px" />
      <Skeleton ml="60px" w="70%" h="35px" my="15px" borderRadius="8px" />
    </React.Fragment>
  ));

const ProjectCreate = ({
  projectsStore: {
    isLoading,
    fetchProject,
    clearProject,
    project,
    projectInitialValues,
    createProject,
  },
  settingsStore: { fetchExchangeRates },
  briefsStore: {
    initializeProjectDataFromBrief,
    clearBrief,
    brief,
    isLoading: isLoadingBrief,
    projectInitialValuesFromBrief,
  },
  appStore: {
    featureFlags: { 'removal-of-billing-type': removalOfBillingType },
  },
  history,
  location: { search },
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { cloneId: projectId, briefId, ppBriefId } = qs.parse(search);

  const asyncFn = async () => {
    fetchExchangeRates();
    clearProject();
    clearBrief();

    if (projectId) {
      fetchProject({ projectId });
    } else if (briefId || ppBriefId) {
      initializeProjectDataFromBrief(briefId || ppBriefId, !!ppBriefId);
    }
  };

  React.useEffect(() => {
    asyncFn();
  }, []);

  React.useEffect(() => {
    return () => {
      clearProject();
    };
  }, []);

  React.useEffect(() => {
    if (brief.warningMessage && !isOpen) {
      setIsOpen(true);
    }
  }, [brief.warningMessage]);

  return (
    <Box key={`project-form-wrapper-${project.id}`}>
      <Flex
        pt="64px"
        px="24px"
        direction="column"
        align="flex-start"
        h="100%"
        w="100%"
        mx="auto"
        minH="calc(100vh - 80px)"
      >
        <LinkedBriefBanner />
        <ConvertBriefWarningModal
          isOpen={isOpen}
          onContinue={() => setIsOpen(false)}
          onClose={() =>
            ppBriefId
              ? history.push(APP_ROUTES.briefPlugAndPlayById(ppBriefId))
              : history.push(APP_ROUTES.briefById(briefId))
          }
        />
        {isLoading || isLoadingBrief ? (
          <Loader />
        ) : (
          <ProjectForm
            key={`project-form-key-${project.id}-${brief.id}`}
            initialValues={{
              totalBasis: false,
              startDate: moment.utc().format('DD MMM YYYY'),
              endDate: moment
                .utc()
                .add({ days: 6 })
                .format('DD MMM YYYY'),
              numberOfWeeks: 1,
              numberOfDays: 0,
              multiplier: 1,
              isNewProject: true,
              ...(project.id && {
                ...cloneDeep(projectInitialValues),
                clientSOWId: undefined,
              }),
              ...(brief.id && {
                ...cloneDeep(projectInitialValuesFromBrief),
              }),
              ...(removalOfBillingType && {
                projectType: PROJECT_TYPES.FIXED_PRICE,
              }),
            }}
            onSubmit={createProject}
          />
        )}
      </Flex>
    </Box>
  );
};

export default inject(
  'projectsStore',
  'briefsStore',
  'settingsStore',
  'appStore',
)(observer(ProjectCreate));
