import Typography from '@styles/Typography';
import { get, isArray } from 'lodash-es';
import React from 'react';

export const ErrorText = ({ children, ...props }) => (
  <Typography
    className="final-form-error"
    variant="text2"
    color="red.500"
    mt="8px"
    mb="10px"
    lineHeight="19px"
    ml="0"
    {...props}
  >
    {children}
  </Typography>
);

const FormError = ({ isErrorAbsolute, meta, ...props }) => {
  if ((meta.touched || meta.submitFailed) && (meta.error || meta.submitError)) {
    return (
      <ErrorText
        {...(isErrorAbsolute && {
          position: 'absolute',
          mb: 0,
          bottom: '-22px',
        })}
        {...props}
      >
        {meta.error ||
          (isArray(meta.submitError)
            ? get(meta, 'submitError[0]')
            : meta.submitError)}
      </ErrorText>
    );
  }
  return null;
};

export default FormError;
