import { CURRENCY_OPTIONS, LOCATION_OPTIONS } from '@app/constants';
import { Box, Flex, Stack } from '@chakra-ui/react';
import Checkbox from '@components/forms/_common/Checkbox';
import Select from '@components/forms/_common/Select';
import Textarea from '@components/forms/_common/Textarea';
import TextInput from '@components/forms/_common/TextInput';
import { StickyFooter } from '@components/StickyFooterCostBox';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import Button from '@styles/Button';
import {
  composeValidators,
  mustBeAtLeastCharLong,
  mustBeValidEmail,
  required,
} from '@utils/formValidators';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router';
import FileUpload from './FileUpload';

const ClientCreateForm = ({
  onClose,
  onSubmit,
  client,
  initialValues,
  showIncompleteForm = false,
}) => {
  const history = useHistory();
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...initialValues, ...client }}
      render={({ handleSubmit, submitting, values, form }) => {
        const Buttons = (
          <>
            <Button isLoading={submitting} type="submit" size="lg">
              {`${values.id ? 'Update' : 'Add'} client`}
            </Button>
            <Button
              variant="outline"
              isDisabled={submitting}
              onClick={() => (onClose ? onClose() : history.goBack())}
              size="lg"
            >
              Cancel
            </Button>
          </>
        );

        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Box pb={!showIncompleteForm && isSmallerScreen && '50px'}>
              {!showIncompleteForm && (
                <Box mt="32px" mb="45px">
                  <Field
                    name="logo"
                    component={({ input }) => (
                      <FileUpload
                        value={input.value}
                        onUploaded={val => input.onChange(val)}
                        label="Upload image"
                        labelToChangeFile="Change profile photo"
                        allowedExtensions={['.png', '.jpg', '.jpeg']}
                      />
                    )}
                  />
                </Box>
              )}
              <Stack
                mb={isSmallerScreen ? '16px' : '24px'}
                {...(isSmallerScreen
                  ? { direction: 'column', spacing: 4 }
                  : { direction: 'row', spacing: 6 })}
              >
                <Field
                  name="name"
                  component={TextInput}
                  inputBoxAutoHeight
                  placeholder="Enter name"
                  label="Name"
                  validate={composeValidators(
                    required,
                    mustBeAtLeastCharLong(3),
                  )}
                />
                <Field
                  name="type"
                  component={Select}
                  options={[
                    { label: 'Agency', value: 'Agency' },
                    { label: 'Direct', value: 'Direct' },
                  ]}
                  placeholder="Select type"
                  label="Type"
                  validate={required}
                />
              </Stack>
              {!showIncompleteForm && (
                <>
                  <Stack
                    mb="24px"
                    {...(isSmallerScreen
                      ? { direction: 'column', spacing: 4 }
                      : { direction: 'row', spacing: 6 })}
                  >
                    <Field
                      name="currency"
                      component={Select}
                      options={CURRENCY_OPTIONS}
                      label="Currency"
                    />
                    <Field
                      name="location"
                      component={Select}
                      options={LOCATION_OPTIONS}
                      label="Location"
                    />
                  </Stack>
                  <Field
                    name="noVat"
                    variant="secondary"
                    component={Checkbox}
                    type="checkbox"
                    id="noVat"
                    bg="black"
                    checked={values.noVat}
                    label="Non VAT registered company"
                    labelProps={{ fontSize: '14px' }}
                    containerStyles={{ mr: '16px' }}
                    onAfterChange={() => {
                      form.change('vatNumber', '');
                    }}
                  />
                  <Flex my="24px" justifyContent="space-between">
                    <Field
                      name="address"
                      label="Address"
                      placeholder="Address"
                      component={Textarea}
                      inputProps={{ minH: '149px' }}
                      w="48%"
                    />
                    <Flex w="48%" flexDir="column">
                      {!values.noVat && (
                        <Field
                          name="vatNumber"
                          label="Vat Number"
                          placeholder="Vat Number"
                          component={TextInput}
                          containerProps={{ mb: '24px' }}
                          parse={value => (value === '' ? '' : value)}
                        />
                      )}
                      <Field
                        name="financeEmail"
                        label="Finance Email"
                        placeholder="Finance Email"
                        component={TextInput}
                        inputBoxAutoHeight
                        validate={
                          showIncompleteForm
                            ? mustBeValidEmail
                            : composeValidators(required, mustBeValidEmail)
                        }
                      />
                    </Flex>
                  </Flex>
                </>
              )}
            </Box>
            {showIncompleteForm ? (
              <Stack mt="40px" spacing={3}>
                {Buttons}
              </Stack>
            ) : (
              <StickyFooter
                h="auto"
                display="flex"
                justifyContent="space-between"
                {...(isSmallerScreen && {
                  width: '100%',
                  left: 0,
                  padding: '12px',
                })}
              >
                {Buttons}
              </StickyFooter>
            )}
          </form>
        );
      }}
    />
  );
};

export default ClientCreateForm;
