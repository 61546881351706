import { Box, Flex, Spinner } from '@chakra-ui/react';
import AllocationTable from '@components/AllocationTable';
import ActualsWeekPicker from '@components/forms/ActualsWeekPicker';
import { ErrorText } from '@components/forms/FormError';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { find, first, get, isEmpty, pick } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form, FormSpy } from 'react-final-form';
import { MAIN_NAMES_QA } from '@app/constants';
import ProjectFormListener from '../../ProjectsCreate/ProjectFormListener';
import SeeAllButton from '../SeeAllButton';

const TeamActuals = ({
  projectId,
  supplierId,
  allocations,
  usersStore: { isAdmin, isTeamAdmin },
  projectsStore: { project },
  allocationsStore: {
    createActuals,
    actuals: {
      status: { data: actualsStatus = {}, isLoading: isLoadingStatus },
    },
  },
}) => {
  const [showForm, setShowForm] = React.useState(false);

  const status = get(
    find(actualsStatus, ({ supplier = {} }) => supplier.id === supplierId),
    'actuals[0]',
    {},
  );

  const { missingActualsData } = status;

  const initialValues = {
    projectId,
    ...pick(project, [
      'currency',
      'projectType',
      'engagementType',
      'overriddenCurrency',
      'deazyMarginPercent',
      'supplierCurrencyRate',
    ]),
    supplierId,
    allocations,
    year: get(first(missingActualsData), 'year'),
    month: get(first(missingActualsData), 'month'),
    week: get(first(missingActualsData), 'week'),
  };

  return (
    <>
      {showForm && (
        <Form
          initialValues={initialValues}
          onSubmit={values => createActuals(values, () => setShowForm(false))}
          subscription={{
            submitting: true,
            submitError: true,
            submitErrors: true,
          }}
          render={({ handleSubmit, submitting, form, submitError }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => (
                    <ProjectFormListener formApi={form} values={values} />
                  )}
                </FormSpy>
                <Box px="24px" w="100%" mx="auto" pb="60px" pt="40px">
                  <Box mb="40px">
                    <ActualsWeekPicker
                      missingActualsData={missingActualsData}
                      project={project}
                    />
                  </Box>
                  <AllocationTable
                    initialAllocations={initialValues.allocations}
                    disabledFields={['name', 'role', 'rate', 'clientRate']}
                    disallowRowChange
                    qaTag={MAIN_NAMES_QA.PROJECT_ALLOCATIONS}
                  />
                  {submitError && <ErrorText>{submitError}</ErrorText>}

                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mt="40px"
                  >
                    <SeeAllButton to={`all-team-actuals/${supplierId}`}>
                      See all team actuals
                    </SeeAllButton>
                    <Flex alignItems="center">
                      <Button
                        size="lg"
                        isLoading={submitting}
                        type="submit"
                        mr="20px"
                        data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--sendActualsButton`}
                      >
                        Send actuals
                      </Button>
                      <Button
                        variant="outline"
                        size="lg"
                        isDisabled={submitting}
                        onClick={() => setShowForm(false)}
                        data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--cancelActualsButton`}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              </form>
            );
          }}
        />
      )}
      {!showForm && (
        <Flex justifyContent="space-between" alignItems="center" mt="40px">
          <SeeAllButton
            to={`all-team-actuals/${supplierId}`}
            data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--seeAllActualsButton`}
          >
            See all team actuals
          </SeeAllButton>
          {isLoadingStatus ? (
            <Spinner mr="12px" />
          ) : (
            <>
              {!isEmpty(missingActualsData) &&
                isEmpty(initialValues.allocations) &&
                isTeamAdmin && (
                  <Typography variant="h4">
                    To post actuals fulfill allocations first
                  </Typography>
                )}
              {!isEmpty(missingActualsData) &&
                !isEmpty(initialValues.allocations) &&
                (isTeamAdmin || isAdmin) && (
                  <Button
                    ml="auto"
                    size="lg"
                    data-test-id={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}--postNewActualsButton`}
                    onClick={() => setShowForm(true)}
                  >
                    Post new actuals
                  </Button>
                )}
            </>
          )}
        </Flex>
      )}
    </>
  );
};

export default inject(
  'usersStore',
  'projectsStore',
  'allocationsStore',
)(observer(TeamActuals));
