/* eslint-disable react/no-did-update-set-state */
import Icon from '@components/Icon';
import { getInputVariantProps, INPUT_VARIANTS } from '@styles/theme';
import Typography from '@styles/Typography';
import { get, isEmpty, isEqual, map } from 'lodash-es';
import React from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import FormError from './FormError';
import FormLabel from './FormLabel';
import { Styles } from './Select';

const CreateNewLabel = ({ value }) => {
  return (
    <Typography pl="30px" position="relative" variant="text2">
      <Icon name="add" position="absolute" top="1px" left="5px" />
      {`Create ${value ? `"${value}"` : 'new'}`}
    </Typography>
  );
};
class AsyncAutocomplete extends React.Component {
  state = { options: [], isLoaded: false, isLoading: true };

  componentDidMount() {
    this.loadInitialOption();
  }

  componentDidUpdate(prevProps) {
    const {
      reloadOnEmpty,
      input: { value },
    } = this.props;
    const {
      input: { value: prevValue },
    } = prevProps;

    if (reloadOnEmpty && !isEqual(value, prevValue) && isEmpty(value)) {
      this.setState({ options: [] });
    }
  }

  optionsMapping = (data = {}) => {
    const { customOptionMap } = this.props;
    if (typeof customOptionMap === 'function') {
      return map(data, customOptionMap);
    }
    return map(data, option => ({
      value: option,
      label: option.name,
    }));
  };

  loadOptions = (value = '') =>
    new Promise(resolve => {
      const {
        getOptionsFn,
        triggerWhenMoreThan = -1,
        defaultOptions,
      } = this.props;
      const shouldTrigger = value.length > triggerWhenMoreThan;
      if (!shouldTrigger) {
        this.setState({
          options: [],
          isLoading: false,
        });
        return resolve([]);
      }
      this.setState({ isLoading: true });
      clearTimeout(this.to);
      this.to = setTimeout(async () => {
        try {
          const { data } = await getOptionsFn(value);
          const options =
            typeof defaultOptions === 'function'
              ? defaultOptions(this.optionsMapping(data), value)
              : this.optionsMapping(data);
          this.setState({
            options,
            isLoading: false,
            isLoaded: true,
            value,
          });
          resolve(options);
        } catch (e) {
          this.setState({
            options: [],
            isLoading: false,
            isLoaded: false,
            value,
          });
          resolve([]);
        }
      }, 700);
      return undefined;
    });

  loadInitialOption = async () => {
    const {
      getDefaultFn,
      input: { value },
      defaultOptionLabelKey = 'name',
    } = this.props;
    if (value && typeof getDefaultFn === 'function') {
      try {
        const { data } = await getDefaultFn(value);
        this.setState({
          defaultValue: { label: data[defaultOptionLabelKey], value },
        });
      } catch (e) {
        // console.log('Error loading initial option', e);
      }
    }
  };

  defaultValue = () => {
    const { defaultValue } = this.state;
    const {
      customOptionMap = option => ({ label: option.name, value: option.id }),
      input: { value },
    } = this.props;
    if (value) {
      return customOptionMap(value);
    }
    return defaultValue;
  };

  clearOptions = () =>
    this.setState({ options: [], isLoaded: false, value: '' });

  render() {
    const {
      placeholder = 'Select...',
      styleProps = {},
      input: { value: _v, ...input },
      meta = {},
      label,
      onAfterChange,
      isCreatable = false,
      isClearable = false,
      isDisabled = false,
      variant = INPUT_VARIANTS.NORMAL,
      ...props
    } = this.props;
    const { options, isLoaded, isLoading, value } = this.state;
    const hasError =
      (meta.touched || meta.submitFailed) && (meta.error || meta.submitError);

    const { labelProps } = getInputVariantProps(variant);

    const defaultValue = this.defaultValue();

    const selectProps = {
      isLoading,
      placeholder,
      className: 'form-select',
      classNamePrefix: 'select',
      components: {
        IndicatorSeparator: () => null,
        IndicatorsContainer: () => (
          <Icon
            className="arrow-down"
            name="chevronDown"
            fontSize={12}
            color="black"
          />
        ),
      },
      isClearable,
      isDisabled,
      loadOptions: this.loadOptions,
      ...input,
      onChange: option => {
        const v = get(option, 'value');
        if (!isEmpty(value)) {
          this.clearOptions();
        }
        input.onBlur();
        input.onChange(v);
        if (typeof onAfterChange === 'function') {
          onAfterChange(v);
        }
      },
      ...props,
      onInputChange: inputValue => {
        if (!inputValue) {
          this.loadOptions();
        }
      },
      onMenuClose: () => {
        clearTimeout(this.to);
        this.setState({ isLoading: false });
      },
      onMenuOpen: () => {
        if (!isLoaded || isEmpty(options)) {
          this.loadOptions();
        }
      },
      onBlur: e => {
        input.onBlur(e);
        if (!isEmpty(e.target.value)) {
          this.clearOptions();
        }
      },
      defaultOptions: options,
      key: JSON.stringify(defaultValue),
      defaultValue,
    };

    return (
      <Styles
        w="100%"
        variant={variant}
        {...styleProps}
        hasError={hasError}
        {...props}
      >
        <FormLabel name={input.name} label={label} {...labelProps} />
        {isCreatable ? (
          <AsyncCreatableSelect
            isValidNewOption={v => v.length > 0}
            formatCreateLabel={v => <CreateNewLabel value={v} />}
            getNewOptionData={(inputValue, optionLabel) => ({
              value: inputValue,
              label: optionLabel,
              __isNew__: true,
              isEqual: () => false,
            })}
            {...selectProps}
          />
        ) : (
          <AsyncSelect isDisabled={isDisabled} {...selectProps} />
        )}
        <FormError meta={meta} />
      </Styles>
    );
  }
}

export default AsyncAutocomplete;
