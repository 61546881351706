import {
  ROLE_TYPES,
  ROLE_TYPES_OPTIONS,
  SENIORITY_OPTIONS,
  SENIORITY_TYPES,
} from '@app/constants';
import { Box, Flex, List, ListItem } from '@chakra-ui/react';
import Button from '@styles/Button';
import ActionButton from '@components/AllocationTable/ActionButton';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import Select from '@components/forms/Select';
import TextInput from '@components/forms/_common/TextInput';
import Icon from '@components/Icon';
import { INPUT_VARIANTS } from '@styles/theme';
import Typography from '@styles/Typography';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import {
  filter,
  first,
  get,
  isEmpty,
  isNil,
  keys,
  last,
  map,
  omit,
  isBoolean,
  split,
  toNumber,
} from 'lodash-es';
import { nanoid } from 'nanoid';
import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import AutoCalcCell from './AutoCalcCell';
import ResourceRateIndicator from './ResourceRateIndicator';

const TableHead = () => (
  <Flex w="100%" mb="12px" mt="24px" color="dark.solid">
    <Flex w="40%">
      <Typography variant="caption" ml="40px" w="50%">
        Role name
      </Typography>
      <Typography variant="caption" ml="40px" w="50%">
        Seniority
      </Typography>
    </Flex>
    <Flex w="60%">
      <Typography variant="caption" w="25%">
        Rate
      </Typography>
      <Typography variant="caption" w="20%">
        % of time
      </Typography>
      <Typography variant="caption" w="25%">
        no of hours
      </Typography>
      <Typography variant="caption" w="30%">
        Cost
      </Typography>
    </Flex>
  </Flex>
);

const linkIconProps = {
  color: 'blue.mid',
  position: 'absolute',
  zIndex: 10,
  right: '3px',
  top: '22px',
  bg: '#fff',
  p: '2px',
  fontSize: 24,
  name: 'unlink',
};

const onAfterChangeFn = (value, idx, formApi) => {
  const afterDot = last(split(value, '.'));
  const beforeDot = first(split(value, '.'));
  const numberedDot = toNumber(beforeDot);
  const numberedAfter = toNumber(afterDot);

  if (numberedAfter > 50) {
    const numberedDotPlusOne = toNumber(beforeDot) + 1;
    formApi.change(
      `additionalTeam[${idx}].timeHours`,
      `${numberedDotPlusOne}.00`,
    );
  }
  if (numberedAfter <= 50 && numberedAfter > 0) {
    formApi.change(`additionalTeam[${idx}].timeHours`, `${numberedDot}.50`);
  }
  if (numberedAfter === 0) {
    formApi.change(`additionalTeam[${idx}].timeHours`, `${numberedDot}.00`);
  }
  formApi.change(`additionalTeam[${idx}].timePercent`, null);
};

const RoleRowDesktop = ({
  idx,
  onDeleteRow,
  onAddRow,
  canBeDeleted,
  isOpen,
  setIsOpen,
  currency,
  isReadOnly,
  formApi,
  values,
  mainNameQA,
  ...rest
}) => {
  const { rate, amount, timePercent, timeHours, name, isRateOk } = get(
    values,
    `additionalTeam[${idx}]`,
    {},
  );

  const showCommentBox = isRateOk === false;

  return (
    <>
      <Box w="100%" position="relative" {...rest}>
        <ActionButton
          deleteRow={onDeleteRow}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          canBeDeleted={canBeDeleted}
          addRow={onAddRow}
          disallowRowChange={isReadOnly}
          mainNameQA={mainNameQA}
        />
        <Flex
          borderRadius="8px"
          w="100%"
          h="72px"
          mb="12px"
          bg="#fff"
          opacity={isOpen ? 0.5 : 1}
        >
          <Flex
            pl="40px"
            w="40%"
            border="1px solid"
            borderColor="dark.mid"
            borderRight="none"
            borderTopLeftRadius="8px"
            borderBottomLeftRadius="8px"
            borderBottomRightRadius={showCommentBox ? '0px' : '8px'}
          >
            <Box
              alignSelf="center"
              w="50%"
              h="70px"
              borderRight="1px solid"
              borderColor="dark.mid"
            >
              <Field
                name={`additionalTeam[${idx}].name`}
                data-test-id={`${mainNameQA}--additionalTeam[${idx}].name`}
                variant={INPUT_VARIANTS.CONTAINER_FILLED}
                placeholder="Select Role"
                isDisabled={isReadOnly}
                options={ROLE_TYPES_OPTIONS}
                component={Select}
              />
            </Box>
            <Box
              alignSelf="center"
              w="50%"
              h="70px"
              borderRight="1px solid"
              borderColor="dark.mid"
            >
              <Field
                name={`additionalTeam[${idx}].seniority`}
                data-test-id={`${mainNameQA}--additionalTeam[${idx}].seniority`}
                variant={INPUT_VARIANTS.CONTAINER_FILLED}
                placeholder="Select Seniority"
                isDisabled={isReadOnly}
                key={`key=by-${name}`}
                options={
                  name !== ROLE_TYPES.DEV
                    ? filter(
                        SENIORITY_OPTIONS,
                        opt =>
                          opt.value !== SENIORITY_TYPES.TechLead &&
                          opt.value !== SENIORITY_TYPES.Architect,
                      )
                    : SENIORITY_OPTIONS
                }
                component={Select}
              />
            </Box>
          </Flex>
          <Flex
            w="60%"
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor="dark.mid"
            borderTopRightRadius="8px"
            borderBottomRightRadius={showCommentBox ? '0px' : '8px'}
            h="100%"
          >
            <Box
              alignSelf="center"
              w="25%"
              h="100%"
              position="relative"
              borderRight="1px solid"
              borderColor="dark.mid"
            >
              <Field
                name={`additionalTeam[${idx}].rate`}
                data-test-id={`${mainNameQA}--additionalTeam[${idx}].rate`}
                variant={INPUT_VARIANTS.CONTAINER_FILLED}
                component={MaskedTextInput}
                isDisabled={isReadOnly}
                decimalScale={0}
                fixedDecimalScale
                placeholder="0"
              />
              <ResourceRateIndicator
                formApi={formApi}
                position="absolute"
                top="34%"
                right="6px"
                zIndex={30}
                prefix={`additionalTeam[${idx}]`}
              />
            </Box>
            <Box
              alignSelf="center"
              w="20%"
              h="100%"
              position="relative"
              borderRight="1px solid"
              borderColor="dark.mid"
            >
              <Field
                name={`additionalTeam[${idx}].timePercent`}
                data-test-id={`${mainNameQA}--additionalTeam[${idx}].timePercent`}
                render={props => (
                  <>
                    {!!props.input.value && (
                      <Icon {...linkIconProps} name="unlink" />
                    )}
                    <MaskedTextInput
                      variant={INPUT_VARIANTS.CONTAINER_FILLED}
                      decimalScale={2}
                      isDisabled={isReadOnly}
                      fixedDecimalScale
                      placeholder="-"
                      {...props}
                      isAllowed={({ floatValue }) => {
                        return (
                          (floatValue >= 0 && floatValue <= 99) ||
                          isNil(floatValue)
                        );
                      }}
                      changeIfActive
                      onAfterChange={() => {
                        formApi.change(
                          `additionalTeam[${idx}].timeHours`,
                          null,
                        );
                      }}
                    />
                  </>
                )}
              />
            </Box>
            <Box alignSelf="center" w="25%" h="100%" position="relative">
              <Field
                name={`additionalTeam[${idx}].timeHours`}
                data-test-id={`${mainNameQA}--additionalTeam[${idx}].timeHours`}
                render={props => (
                  <>
                    {!!props.input.value && (
                      <Icon {...linkIconProps} name="unlink" />
                    )}
                    <MaskedTextInput
                      variant={INPUT_VARIANTS.CONTAINER_FILLED}
                      decimalScale={2}
                      fixedDecimalScale
                      placeholder="-"
                      isDisabled={isReadOnly}
                      {...props}
                      changeIfActive
                      onAfterChange={value =>
                        onAfterChangeFn(value, idx, formApi)
                      }
                    />
                  </>
                )}
              />
            </Box>

            <AutoCalcCell
              width="30%"
              minW="108px"
              marginLeft="auto"
              currency={currency}
              borderBottomRightRadius={showCommentBox ? '0px' : '8px'}
              rate={rate}
              amount={amount}
              timePercent={timePercent}
              timeHours={timeHours}
              data-test-id={`${mainNameQA}--additionalTeam[${idx}].cost`}
            />
          </Flex>
        </Flex>
      </Box>
      {showCommentBox && (
        <Box
          w="calc(100% - 39px)"
          mt="-12px"
          mb="12px"
          h="48px"
          border="1px solid #d9dada"
          borderTop="none"
          borderBottomLeftRadius="8px"
          position="relative"
          left="39px"
          borderBottomRightRadius="8px"
        >
          <Field
            name={`additionalTeam[${idx}].comment`}
            data-test-id={`${mainNameQA}--additionalTeam[${idx}].comment`}
            variant={INPUT_VARIANTS.CONTAINER_FILLED}
            component={TextInput}
            pt="12px"
            isDisabled={isReadOnly}
            pb="12px"
            placeholder="Add a comment if required. Include why you have exceeded your agreed high rate."
            borderBottomRightRadius="8px"
            borderBottomLeftRadius="8px"
          />
        </Box>
      )}
    </>
  );
};

const RoleRowMobile = ({
  mainNameQA,
  idx,
  isReadOnly,
  onDeleteRow,
  values,
  formApi,
  currency,
  ...rest
}) => {
  const { name, rate, amount, timePercent, timeHours } = get(
    values,
    `additionalTeam[${idx}]`,
    {},
  );

  return (
    <Box w="100%" position="relative" mb="24px" {...rest}>
      <Flex
        backgroundColor="primary.200"
        justifyContent="space-between"
        px="12px"
        py="8px"
        borderTopLeftRadius="8px"
        borderTopRightRadius="8px"
      >
        <Typography variant="h5" mt="8px">
          Role
        </Typography>
        {!isReadOnly && (
          <Button
            onClick={onDeleteRow}
            background="#fff"
            color="black"
            width="40px"
            iconButton={{
              name: 'trashNew',
            }}
          />
        )}
      </Flex>
      <Flex
        bgColor="primary.100"
        flexDirection="column"
        p="12px"
        borderBottomLeftRadius="8px"
        borderBottomRightRadius="8px"
      >
        <Box mb="12px">
          <Field
            name={`additionalTeam[${idx}].name`}
            data-test-id={`${mainNameQA}--additionalTeam[${idx}].name`}
            variant={INPUT_VARIANTS.NORMAL}
            placeholder="Select Role"
            isDisabled={isReadOnly}
            options={ROLE_TYPES_OPTIONS}
            component={Select}
            style
          />
        </Box>
        <Box mb="12px">
          <Field
            name={`additionalTeam[${idx}].seniority`}
            data-test-id={`${mainNameQA}--additionalTeam[${idx}].seniority`}
            variant={INPUT_VARIANTS.NORMAL}
            placeholder="Select Seniority"
            isDisabled={isReadOnly}
            key={`key=by-${name}`}
            options={
              name !== ROLE_TYPES.DEV
                ? filter(
                    SENIORITY_OPTIONS,
                    opt =>
                      opt.value !== SENIORITY_TYPES.TechLead &&
                      opt.value !== SENIORITY_TYPES.Architect,
                  )
                : SENIORITY_OPTIONS
            }
            component={Select}
          />
        </Box>
        <Box
          w="100%"
          h="48px"
          position="relative"
          border="1px solid"
          borderRadius="8px"
          borderColor="dark.mid"
          mb="12px"
        >
          <Field
            name={`additionalTeam[${idx}].rate`}
            data-test-id={`${mainNameQA}--additionalTeam[${idx}].rate`}
            variant={INPUT_VARIANTS.CONTAINER_FILLED}
            component={MaskedTextInput}
            isDisabled={isReadOnly}
            decimalScale={0}
            fixedDecimalScale
            borderRadius="8px"
            placeholder="Rate"
          />
          <ResourceRateIndicator
            formApi={formApi}
            position="absolute"
            top="34%"
            right="6px"
            prefix={`additionalTeam[${idx}]`}
          />
        </Box>
        <Box
          w="100%"
          h="48px"
          position="relative"
          border="1px solid"
          borderColor="dark.mid"
          mb="12px"
          borderRadius="8px"
        >
          <Field
            name={`additionalTeam[${idx}].timePercent`}
            data-test-id={`${mainNameQA}--additionalTeam[${idx}].timePercent`}
            render={props => (
              <>
                {!!props.input.value && (
                  <Icon {...linkIconProps} name="unlink" />
                )}
                <MaskedTextInput
                  variant={INPUT_VARIANTS.CONTAINER_FILLED}
                  decimalScale={2}
                  isDisabled={isReadOnly}
                  fixedDecimalScale
                  borderRadius="8px"
                  placeholder="% of time"
                  {...props}
                  isAllowed={({ floatValue }) => {
                    return (
                      (floatValue >= 0 && floatValue <= 99) || isNil(floatValue)
                    );
                  }}
                  changeIfActive
                  onAfterChange={() => {
                    formApi.change(`additionalTeam[${idx}].timeHours`, null);
                  }}
                />
              </>
            )}
          />
        </Box>
        <Box
          w="100%"
          h="48px"
          position="relative"
          border="1px solid"
          borderColor="dark.mid"
          mb="12px"
          borderRadius="8px"
        >
          <Field
            name={`additionalTeam[${idx}].timeHours`}
            data-test-id={`${mainNameQA}--additionalTeam[${idx}].timeHours`}
            render={props => (
              <>
                {!!props.input.value && (
                  <Icon {...linkIconProps} name="unlink" />
                )}
                <MaskedTextInput
                  variant={INPUT_VARIANTS.CONTAINER_FILLED}
                  decimalScale={2}
                  fixedDecimalScale
                  placeholder="No of hours"
                  borderRadius="8px"
                  isDisabled={isReadOnly}
                  {...props}
                  changeIfActive
                  onAfterChange={value => onAfterChangeFn(value, idx, formApi)}
                />
              </>
            )}
          />
        </Box>
        <AutoCalcCell
          w="100%"
          h="48px"
          position="relative"
          border="1px solid dark.mid"
          borderRadius="8px"
          placeholder="Cost"
          bgColor="white"
          currency={currency}
          rate={rate}
          amount={amount}
          isMobile
          timePercent={timePercent}
          timeHours={timeHours}
          data-test-id={`${mainNameQA}--additionalTeam[${idx}].cost`}
        />
      </Flex>
    </Box>
  );
};

const AdditionalTeamRolesTable = ({
  isReadOnly,
  currency,
  totalHours,
  mainNameQA,
}) => {
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  return (
    <FormSpy subscription={{ values: true, errors: true, touched: true }}>
      {({
        values: { additionalTeam = [] },
        errors = {},
        touched,
        form,
        values,
      }) => {
        if (!isReadOnly && isBoolean(isSmallerScreen)) {
          if (isEmpty(additionalTeam)) {
            form.change('additionalTeam', [{ rowId: nanoid(10) }]);
          } else if (
            !isSmallerScreen &&
            !isEmpty(omit(last(additionalTeam), 'rowId'))
          ) {
            form.change('additionalTeam', [
              ...additionalTeam,
              { rowId: nanoid(10) },
            ]);
          }
        }
        const onDeleteRow = async (id, rowId) => {
          form.change(
            'additionalTeam',
            map(additionalTeam, res => ({
              ...res,
              ...(res.rowId === rowId && { toBeDeleted: true }),
            })),
          );
        };
        const onAddRow = () => {
          form.change('additionalTeam', [
            ...additionalTeam,
            { rowId: nanoid(10) },
          ]);
        };

        return (
          <>
            {isEmpty(additionalTeam) && isReadOnly && (
              <Typography fontSize={20} mt="32px">
                No records to display.
              </Typography>
            )}
            {!isEmpty(additionalTeam) && !isSmallerScreen && <TableHead />}
            {map(additionalTeam, (role, idx) =>
              role?.toBeDeleted || role?.isDeleted ? null : (
                <React.Fragment key={`additional-table-teamrole-${role.rowId}`}>
                  {isSmallerScreen ? (
                    <RoleRowMobile
                      mainNameQA={mainNameQA}
                      data-test-id={`${mainNameQA}--additionalRoleRow`}
                      idx={idx}
                      values={values}
                      currency={currency}
                      formApi={form}
                      totalHours={totalHours}
                      isReadOnly={isReadOnly}
                      onDeleteRow={() =>
                        !isReadOnly && onDeleteRow(role.id, role.rowId)
                      }
                    />
                  ) : (
                    <RoleRowDesktop
                      formApi={form}
                      values={values}
                      isReadOnly={isReadOnly}
                      isOpen={role.isOpen}
                      setIsOpen={isOpen =>
                        form.change(`additionalTeam[${idx}].isOpen`, isOpen)
                      }
                      idx={idx}
                      onDeleteRow={() =>
                        !isReadOnly && onDeleteRow(role.id, role.rowId)
                      }
                      onAddRow={!isReadOnly && onAddRow}
                      totalHours={totalHours}
                      currency={currency}
                      canBeDeleted={
                        !isReadOnly && idx !== additionalTeam.length - 1
                      }
                      mainNameQA={mainNameQA}
                      data-test-id={`${mainNameQA}--additionalRoleRow`}
                    />
                  )}
                  {!isEmpty(
                    errors.additionalTeam && errors.additionalTeam[idx],
                  ) && (
                    <List ml="24px">
                      {map(keys(errors.additionalTeam[idx]), (key, i) =>
                        touched[`additionalTeam[${idx}].${key}`] ? (
                          <ListItem
                            className="final-form-error"
                            color="red.500"
                            mb="12px"
                            key={`additional-role-error-${idx}-${i}`}
                          >
                            <Icon
                              name="warning"
                              color="red.500"
                              mb="2px"
                              mr="5px"
                            />
                            {errors.additionalTeam[idx][key]}
                          </ListItem>
                        ) : null,
                      )}
                    </List>
                  )}
                </React.Fragment>
              ),
            )}
          </>
        );
      }}
    </FormSpy>
  );
};

export default AdditionalTeamRolesTable;
