import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { Field } from 'react-final-form';
import DatePicker from '@components/forms/DatePicker';
import TextInput from '@components/forms/_common/TextInput';
import Checkbox from '@components/forms/_common/Checkbox';
import { required } from '@utils/formValidators';
import { getDatePickerProps } from '@utils/datesUtils';
import { join, split } from 'lodash-es';
import moment from 'moment';
import PercentageAndAbsoluteFields from './PercentageAndAbsoluteFields';
import InvoiceAmountTypeFields from './InvoiceAmountTypeFields';
import CarouselHeader from './CarouselHeader';

// eslint-disable-next-line no-underscore-dangle
const _requiredValidation = passThrough => value => {
  return passThrough ? undefined : required(value);
};

const InvoiceFields = ({
  isCRPayment,
  isTMPayment,
  isDeposit,
  isFixedPayment,
  isClient,
  setIndex,
  index,
  isDisabled,
  invoiceCheckboxDisabled,
  reference,
  fieldPrefix = 'client',
  project = {},
  invoiceDate,
  form,
  dueDate,
  periodStart,
  periodEnd,
  projectToSuppliers,
  selectedSupplier,
  deazyAsSupplier,
  deazyAsClient,
  paymentTerms,
  mainNameQA,
  ...props
}) => {
  const date = moment.utc(project.startDate);
  const periodStartDate = moment.utc(periodStart);
  const datePickerProps = getDatePickerProps(date, false);

  const startOfCurrentDay = moment
    .utc()
    .startOf('day')
    .toDate();
  const startOfProjectStartDate = moment
    .utc(project.startDate)
    .startOf('day')
    .toDate();

  const dueDatePickerProps = getDatePickerProps(
    date.toDate() < moment.utc().toDate()
      ? startOfCurrentDay
      : startOfProjectStartDate,
  );

  const periodEndPickerProps = getDatePickerProps(periodStartDate);

  const requiredValidation = _requiredValidation(isDisabled);
  return (
    <Box
      w={deazyAsSupplier || deazyAsClient ? '100%' : '50%'}
      key={`invoice-fields-wrapp-${isDisabled}`}
      borderRadius="8px"
      border="1px solid"
      borderColor="dark.mid"
      {...props}
    >
      <Flex
        borderBottom="1px solid"
        borderColor="dark.mid"
        alignItems="center"
        justifyContent="center"
        bg="dark.light"
        minH="100px"
        borderTopLeftRadius="8px"
        borderTopRightRadius="8px"
      >
        <CarouselHeader
          length={(projectToSuppliers || []).length}
          supplierName={selectedSupplier?.supplier?.name}
          isSupplierPart={!isClient}
          setIndex={setIndex}
          index={index}
          isCRPayment={isCRPayment}
          title={isClient ? 'Client' : selectedSupplier?.name}
          mainNameQA={mainNameQA}
        />
      </Flex>
      <Box
        w="100%"
        pt="34px"
        px="32px"
        pb="42px"
        h={!isCRPayment ? '466px' : '320px'}
        overflowY="scroll"
      >
        {(isFixedPayment ||
          ((projectToSuppliers || []).length > 1 && !isTMPayment)) &&
          !isCRPayment && (
            <Field
              name={`${fieldPrefix}.isDisabled`}
              data-test-id={`${mainNameQA}--${fieldPrefix}.isDisabled`}
              component={Checkbox}
              id={`${fieldPrefix}.isDisabled`}
              label={`Disable ${
                fieldPrefix === 'client' ? 'Client' : 'Delivery Partner'
              } invoice`}
              containerStyles={{
                mb: '35px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              disabled={invoiceCheckboxDisabled}
            />
          )}
        <Box
          opacity={isDisabled ? 0.4 : 1}
          pointerEvents={isDisabled ? 'none' : 'all'}
        >
          <Flex justifyContent="space-between" mb="24px">
            <Field
              label="Invoice date"
              name={`${fieldPrefix}.invoiceDate`}
              data-test-id={`${mainNameQA}--${fieldPrefix}.invoiceDate`}
              component={DatePicker}
              calendarColor="dark.solid"
              validate={requiredValidation}
              minW="10px"
              isErrorAbsolute
              containerProps={{ w: 'calc(50% - 12px)' }}
              dayPickerProps={datePickerProps}
              onAfterChange={d => {
                const invDate = moment.utc(d);
                const prevInvoiceDate = moment.utc(invoiceDate);

                const defaultDueDate = moment
                  .utc(d)
                  .add({ days: paymentTerms });

                form.change(
                  `${fieldPrefix}.dueDate`,
                  defaultDueDate < startOfCurrentDay
                    ? startOfCurrentDay
                    : defaultDueDate,
                );

                if (
                  !invDate.isSame(prevInvoiceDate, 'month') ||
                  !invDate.isSame(prevInvoiceDate, 'year')
                ) {
                  const referenceParts = split(reference, ' ');
                  const referenceDate = referenceParts.pop();

                  form.change(
                    `${fieldPrefix}.periodStart`,
                    invDate
                      .clone()
                      .startOf('month')
                      .toDate(),
                  );
                  form.change(
                    `${fieldPrefix}.periodEnd`,
                    invDate
                      .clone()
                      .endOf('month')
                      .toDate(),
                  );

                  if (
                    referenceDate &&
                    moment(referenceDate, 'YYYY/MM', true).isValid()
                  ) {
                    form.change(
                      `${fieldPrefix}.reference`,
                      join([...referenceParts, invDate.format('YYYY/MM')], ' '),
                    );
                    form.change('referenceChanged', true);
                  }
                }
              }}
            />
            <Box pr="24px" />
            <Field
              label="Due date"
              name={`${fieldPrefix}.dueDate`}
              data-test-id={`${mainNameQA}--${fieldPrefix}.dueDate`}
              component={DatePicker}
              calendarColor="dark.solid"
              minW="10px"
              isErrorAbsolute
              validate={requiredValidation}
              containerProps={{ w: 'calc(50% - 12px)' }}
              dayPickerProps={dueDatePickerProps}
            />
          </Flex>
          {isFixedPayment && (
            <Flex justifyContent="space-between" mb="24px">
              <Field
                label="Period start"
                name={`${fieldPrefix}.periodStart`}
                data-test-id={`${mainNameQA}--${fieldPrefix}.periodStart`}
                component={DatePicker}
                calendarColor="dark.solid"
                validate={requiredValidation}
                minW="10px"
                isErrorAbsolute
                containerProps={{ w: 'calc(50% - 12px)' }}
                onAfterChange={d => {
                  const newPeriodStart = moment.utc(d);
                  if (
                    newPeriodStart.isAfter(moment.utc(periodEnd).endOf('day'))
                  ) {
                    form.change(`${fieldPrefix}.periodEnd`, null);
                  }
                }}
              />
              <Box pr="24px" />
              <Field
                label="Period end"
                name={`${fieldPrefix}.periodEnd`}
                data-test-id={`${mainNameQA}--${fieldPrefix}.periodEnd`}
                component={DatePicker}
                calendarColor="dark.solid"
                minW="10px"
                isErrorAbsolute
                validate={requiredValidation}
                containerProps={{ w: 'calc(50% - 12px)' }}
                dayPickerProps={periodEndPickerProps}
              />
            </Flex>
          )}
          <Field
            name={`${fieldPrefix}.reference`}
            data-test-id={`${mainNameQA}--${fieldPrefix}.reference`}
            component={TextInput}
            label="Reference"
            validate={requiredValidation}
            containerProps={{ w: '100%', mb: '24px' }}
          />
          {(isTMPayment || isDeposit) && (
            <InvoiceAmountTypeFields
              key={`key-${selectedSupplier?.supplier?.id}`}
              fieldPrefix={fieldPrefix}
              selectedSupplier={selectedSupplier}
              isDeposit={isDeposit}
              requiredValidation={requiredValidation}
              mainNameQA={mainNameQA}
            />
          )}
          {!isTMPayment && !isCRPayment && !isDeposit && (
            <PercentageAndAbsoluteFields
              key={`percentage-absolute-fields-${selectedSupplier?.supplier?.id}`}
              fieldPrefix={fieldPrefix}
              selectedSupplier={selectedSupplier}
              requiredValidation={requiredValidation}
              mainNameQA={mainNameQA}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceFields;
