import { Box, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import Button from '@styles/Button';
import { isInvoiceUnderApproved } from '@utils/invoiceUtils';
import { every, map } from 'lodash-es';
import moment from 'moment';
import React from 'react';
import { Stat } from './components';
import CopyPaymentLink from './CopyPaymentLink';
import DeletePaymentModal from './DeletePaymentModal';
import DuplicatePayment from './DuplicatePayment';
import PaymentInvoice from './PaymentInvoice/index';
import PaymentProcessing from './PaymentProcessing';

const iconButtonProps = {
  width: '40px',
  color: 'gray.500',
  bg: 'primary.100',
};

const Actions = ({
  toggleInvoices,
  isOpened,
  onDeleteOpen,
  showProcessingIcon,
  assignCRtoPayment,
  isAssigning,
  canAssignCR,
  onDuplicatePayment,
  paymentLink,
  isAdminOrDL,
  modalIsOpen,
  mainNameQA,
}) => {
  return (
    <Stack spacing="12px" isInline alignItems="center" ml="auto" mr="16px">
      {isAdminOrDL && (
        <DuplicatePayment
          {...iconButtonProps}
          modalIsOpen={modalIsOpen}
          data-test-id={`${mainNameQA}--duplicatePayment`}
          onClick={e => {
            e.stopPropagation();
            onDuplicatePayment();
          }}
        />
      )}

      <CopyPaymentLink
        link={paymentLink}
        {...iconButtonProps}
        data-test-id={`${mainNameQA}--copyPyamnetLink`}
      />

      {canAssignCR && (
        <Button
          bg="aqua.solid"
          color="#fff"
          borderRadius="30px"
          px="20px"
          minW="140px"
          data-test-id={`${mainNameQA}--assignCr`}
          h="40px"
          leftIcon={{ name: 'add', fontSize: 12, mr: '12px' }}
          onClick={e => {
            e.stopPropagation();
            assignCRtoPayment();
          }}
          isLoading={isAssigning}
        >
          Add CR to this invoice
        </Button>
      )}
      {showProcessingIcon && (
        <PaymentProcessing
          mr={0}
          data-test-id={`${mainNameQA}--paymentProcessing`}
        />
      )}
      {typeof onDeleteOpen === 'function' && (
        <Button
          data-test-id={`${mainNameQA}--deletePayment`}
          iconButton={{
            name: 'trash',
            fontSize: 22,
          }}
          {...iconButtonProps}
          onClick={e => {
            e.stopPropagation();
            onDeleteOpen();
          }}
        />
      )}
      <Button
        iconButton={{
          name: 'chevronDown',
          fontSize: 12,
        }}
        transform={isOpened ? 'rotate(-180deg)' : 'rotate(0deg)'}
        {...iconButtonProps}
        onClick={toggleInvoices}
        data-test-id={`${mainNameQA}--togglePayment`}
      />
    </Stack>
  );
};

const PaymentContainer = ({
  toggleInvoices,
  deletePayment,
  onEditInvoice,
  payment = {},
  project,
  isAssigning,
  assignCRtoPayment,
  canAssignCR,
  isAdminOrDL,
  paymentLink,
  onDuplicatePayment,
  modalIsOpen,
  mainNameQA,
}) => {
  const { invoices, isOpened, isDeleting } = payment;

  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();

  return (
    <Box
      w="100%"
      border="1px solid"
      borderColor="dark.mid"
      mb="32px"
      id={`payment-container-${payment.id}`}
      bg="#fff"
      borderRadius="8px"
      data-test-id={`${mainNameQA}--paymentContainer`}
    >
      <Flex w="100%" onClick={toggleInvoices} cursor="pointer">
        <Stat
          pl="16px"
          pr="24px"
          my="16px"
          minW="230px"
          alignItems="flex-start"
          label="Invoice status"
          value={payment.calculatedStatus}
          withStyledStatus
          data-test-id={`${mainNameQA}--calculatedStatus`}
        />
        {payment.invoiceDate && (
          <Stat
            px="16px"
            py="16px"
            label="Invoice date"
            value={moment.utc(payment.invoiceDate).format('DD MMM YYYY')}
            borderRight="none"
            data-test-id={`${mainNameQA}--invoiceDate`}
          />
        )}
        {payment.calculatedFromActuals && payment.actualsPeriodStart && (
          <Stat
            px="16px"
            py="16px"
            label="Actuals period start"
            value={moment.utc(payment.actualsPeriodStart).format('DD MMM YYYY')}
            borderRight="none"
            data-test-id={`${mainNameQA}--actualsPeriodStart`}
          />
        )}
        {payment.calculatedFromActuals && payment.actualsPeriodEnd && (
          <Stat
            px="16px"
            py="16px"
            label="Actuals period end"
            value={moment.utc(payment.actualsPeriodEnd).format('DD MMM YYYY')}
            borderRight="none"
            data-test-id={`${mainNameQA}--actualsPeriodEnd`}
          />
        )}
        <Actions
          paymentLink={paymentLink}
          showProcessingIcon={payment.processing}
          toggleInvoices={toggleInvoices}
          isOpened={isOpened}
          onDuplicatePayment={() => onDuplicatePayment(payment)}
          onDeleteOpen={
            isAdminOrDL &&
            every(payment.invoices, invoice => isInvoiceUnderApproved(invoice))
              ? onDeleteOpen
              : null
          }
          isAdminOrDL={isAdminOrDL}
          canAssignCR={canAssignCR}
          isAssigning={isAssigning}
          assignCRtoPayment={assignCRtoPayment}
          modalIsOpen={modalIsOpen}
          mainNameQA={mainNameQA}
        />
      </Flex>
      <DeletePaymentModal
        isOpen={isDeleteOpen}
        isLoading={isDeleting}
        onClose={onDeleteClose}
        onDelete={deletePayment}
        mainNameQA={mainNameQA}
      />

      {isOpened && (
        <Box p="24px" borderTop="1px solid" borderColor="dark.mid">
          {map(invoices, invoice => (
            <PaymentInvoice
              key={`payment-invoice-${invoice.id}`}
              mb="24px"
              invoice={invoice}
              payment={payment}
              project={project}
              onEditInvoice={() => onEditInvoice(invoice)}
              mainNameQA={mainNameQA}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PaymentContainer;
