import { Box, Flex } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const NoPaymentsModal = ({ isOpen, mainNameQA, onClose = () => {} }) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    styleProps={{ minW: '620px', py: '40px' }}
    closeOnEsc={false}
  >
    <Flex>
      <Box alignSelf="center">
        <Typography variant="h1" mb="32px">
          No available invoices
        </Typography>
        <Typography mb="40px">
          There are no available invoices. Please create a new invoice.
        </Typography>
        <Button
          minW="158px"
          onClick={onClose}
          data-test-id={`${mainNameQA}--closeButton`}
        >
          Close
        </Button>
      </Box>
    </Flex>
  </Modal>
);

export default NoPaymentsModal;
