import React from 'react';
import useMediaQuery from '@hooks/useMediaQuery';
import { Link } from 'react-router-dom';
import Button from '@styles/Button';
import { isEmpty } from 'lodash-es';
import { NEW_PROPOSAL_STATUSES, CANDIDATE_STATUSES } from '@app/constants';
import { Flex, Box } from '@chakra-ui/react';

const DownloadCvButton = ({
  onDownload,
  plugPlayProposalCv,
  isLoading,
  ...props
}) => (
  <Button
    bg="primary.100"
    color="gray.500"
    leftIcon={{
      name: 'download',
      fontSize: 22,
      mr: '0',
      color: 'primary.400',
    }}
    onClick={onDownload}
    isDisabled={isEmpty(plugPlayProposalCv)}
    isLoading={isLoading}
    {...props}
  >
    Download CV
  </Button>
);

const ViewProfileButton = ({ link, ...props }) => (
  <Button
    bg="primary.100"
    color="gray.500"
    as={Link}
    to={link}
    leftIcon={{
      name: 'candidate',
      fontSize: 18,
      mr: '0',
      color: 'primary.400',
    }}
    {...props}
  >
    View profile
  </Button>
);

const ShortlistedButton = ({
  unshortlistCandidate,
  brief,
  candidate,
  ...props
}) => (
  <Button
    variant="outline"
    borderColor="red.solid"
    onClick={() => unshortlistCandidate(brief.id, candidate)}
    _hover={{
      bg: 'red.mid',
    }}
    _active={{
      bg: 'red.mid',
    }}
    leftIcon={{
      color: 'red.solid',
      name: 'cross',
      mr: '0px',
      fontSize: 24,
    }}
    color="dark.solid"
    isLoading={candidate.isRemoving}
    {...props}
  >
    Remove from proposal
  </Button>
);

const QueryButton = ({ onOpen, candidate, ...props }) => (
  <Button
    variant="outline"
    mr="12px"
    onClick={onOpen}
    leftIcon={{
      name: 'questionMark',
      mr: '0',
      color: 'primary.400',
      fontSize: '20px',
    }}
    isLoading={candidate.isQuering}
    isDisabled={candidate.isAdding}
    {...props}
  >
    Query
  </Button>
);

const AddToProposalButton = ({
  brief,
  candidate,
  status,
  shortlistCandidate,
  ...props
}) => (
  <Button
    onClick={() => shortlistCandidate(brief.id, candidate)}
    isLoading={candidate.isAdding}
    leftIcon={{
      name: 'add',
      mr: '0',
    }}
    isDisabled={status === CANDIDATE_STATUSES.QUERIED || candidate.isQuering}
    {...props}
  >
    Add to your proposal
  </Button>
);

const DesktopView = ({
  children,
  onDownload,
  plugPlayProposalCv,
  devProfile,
  isLoading,
  mainNameQA,
}) => (
  <Flex p="16px" pt="0" justifyContent="space-between">
    {devProfile?.id ? (
      <ViewProfileButton
        link={`/profile/${devProfile.id}`}
        data-test-id={`${mainNameQA}--viewDevProfile`}
      />
    ) : (
      <DownloadCvButton
        onDownload={onDownload}
        plugPlayProposalCv={plugPlayProposalCv}
        isLoading={isLoading}
        data-test-id={`${mainNameQA}--downloadCV`}
      />
    )}
    <Flex>{children}</Flex>
  </Flex>
);

const MobileView = ({ children }) => (
  <Box p="16px" pt="0">
    {children}
  </Box>
);

const CandidateCardButtons = ({
  onOpen,
  onDownload,
  isLoading,
  shortlistCandidate,
  unshortlistCandidate,
  candidate,
  status,
  plugPlayProposalCv,
  proposalStatus,
  brief,
  mainNameQA,
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const showShortlistedButton = status === CANDIDATE_STATUSES.SHORTLISTED;
  const showQueryButton =
    status === CANDIDATE_STATUSES.PROPOSED ||
    (status === CANDIDATE_STATUSES.LOST &&
      proposalStatus !== NEW_PROPOSAL_STATUSES.WON) ||
    status === CANDIDATE_STATUSES.QUERIED;
  const showAddToProposalButton =
    status === CANDIDATE_STATUSES.PROPOSED ||
    status === CANDIDATE_STATUSES.QUERIED;

  if (isSmallerScreen) {
    return (
      <MobileView>
        <Flex justifyContent="space-between" direction="column">
          {candidate?.staffProfile?.id ? (
            <ViewProfileButton
              link={`/profile/${candidate?.staffProfile.id}`}
              w="100%"
              mb="12px"
              data-test-id={`${mainNameQA}--viewDevProfile`}
            />
          ) : (
            <DownloadCvButton
              onDownload={onDownload}
              plugPlayProposalCv={plugPlayProposalCv}
              isLoading={isLoading}
              ml={0}
              width="100%"
              data-test-id={`${mainNameQA}--downloadCV`}
              mb="12px"
            />
          )}
          {showShortlistedButton && (
            <ShortlistedButton
              unshortlistCandidate={unshortlistCandidate}
              brief={brief}
              candidate={candidate}
              mr={0}
              mb="12px"
              width="100%"
              data-test-id={`${mainNameQA}--shortlist`}
            />
          )}
          {showQueryButton && (
            <QueryButton
              onOpen={onOpen}
              candidate={candidate}
              mb="12px"
              mr={0}
              width="100%"
              data-test-id={`${mainNameQA}--showQuery`}
            />
          )}
        </Flex>
        {showAddToProposalButton && (
          <AddToProposalButton
            brief={brief}
            candidate={candidate}
            status={status}
            shortlistCandidate={shortlistCandidate}
            width="100%"
            mb="6px"
            data-test-id={`${mainNameQA}--addToProposal`}
          />
        )}
      </MobileView>
    );
  }

  return (
    <DesktopView
      onDownload={onDownload}
      plugPlayProposalCv={plugPlayProposalCv}
      isLoading={isLoading}
      mainNameQA={mainNameQA}
      devProfile={candidate.staffProfile}
    >
      {showShortlistedButton && (
        <ShortlistedButton
          unshortlistCandidate={unshortlistCandidate}
          brief={brief}
          candidate={candidate}
          data-test-id={`${mainNameQA}--shortlist`}
        />
      )}
      {showQueryButton && <QueryButton onOpen={onOpen} candidate={candidate} />}
      {showAddToProposalButton && (
        <AddToProposalButton
          brief={brief}
          candidate={candidate}
          status={status}
          shortlistCandidate={shortlistCandidate}
          data-test-id={`${mainNameQA}--addToProposal`}
        />
      )}
    </DesktopView>
  );
};

export default CandidateCardButtons;
