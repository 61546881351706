import { Input, theme } from '@chakra-ui/react';
import { customIcons } from './customIcons';
import { size } from './breakpoints';

Input.defaultProps = { ...Input.defaultProps, pt: '3px' };

export const COLORS = {
  black: '#2b231d',
  dark: {
    solid: '#4e4e4e',
    mid: '#d9dada',
    light: '#f9f9f9',
  },
  green: {
    ...theme.colors.green,
    500: '#7ccc33',
    solid: '#7ccc33',
    mid: '#d5e8b3',
    light: '#f5fbef',
    basil: '#209874',
  },
  aqua: {
    500: '#47b5bd',
    solid: '#47b5bd',
    mid: '#cdf3f6',
    light: '#e9f6f7',
  },
  red: {
    ...theme.colors.red,
    500: '#ff6666',
    solid: '#ff6666',
    mid: '#f7cdcd',
    light: '#ffefef',
  },
  purple: {
    solid: '#7B61FF',
  },
  yellow: {
    ...theme.colors.yellow,
    solid: '#ffd900',
    mid: '#fded9e',
    light: '#fff9dc',
    lightSecondary: '#FFFCEC',
  },
  blue: {
    ...theme.colors.blue,
    solid: '#3366ff',
    mid: '#9fc5ff',
    light: '#f5f7ff',
    500: '#3366ff',
  },
  orange: {
    ...theme.colors.orange,
    solid: '#ff8500',
    mid: '#FFB973',
    light: '#FFF3E5',
  },
  primary: {
    100: '#F9FAFF',
    200: '#E7EBFF',
    300: '#9FC5FF',
    400: '#3366FF',
    500: '#394569',
    600: '#091A37',
  },
  gray: {
    ...theme.colors.gray,
    dark: '#4A4A4A',
    solid: '#4E4E4E',
    mid: '#878380',
    light: '#D9DADA',
    paleSlate: '#C2BFBC',
    100: '#F9F9F9',
    200: '#D9DADA',
    300: '#878380',
    400: '#4E4E4E',
    500: '#2B231D',
  },
};

export const FONTS = {
  WS: 'Work Sans',
  WB: 'Work Sans SemiBold',
  WM: 'Work Sans Medium',
  PP: 'Poppins',
};

export const INPUT_VARIANTS = {
  NORMAL: 'normal',
  ROUNDED: 'rounded',
  CONTAINER_FILLED: 'containerFilled',
  DEFAULT: 'default',
  NONE: 'none',
};

// TODO - remove variants as soon as possible

export const getInputVariantProps = variant => {
  if (variant === INPUT_VARIANTS.DEFAULT) {
    return {
      inputProps: {
        pt: '13px',
        pb: '13px',
        pr: '12px',
        borderRadius: '8px',
        h: '48px',
        borderStyle: 'solid',
        border: `1px solid ${COLORS.gray[200]}`,
        _focus: {
          borderColor: 'primary.300',
          boxShadow: 'none',
        },
        _disabled: {
          opacity: 1,
          bg: '#f9f9f9',
          cursor: 'not-allowed',
          _hover: {
            borderColor: '#d9dada',
          },
        },
        _hover: {
          borderColor: '#d9dada',
        },
      },
      labelProps: {
        color: 'gray.400',
        fontSize: 13,
        fontFamily: 'WS',
        lineHeight: '19px',
        mb: '8px',
        mr: '9px',
      },
    };
  }

  if (variant === INPUT_VARIANTS.ROUNDED) {
    return {
      inputProps: {
        px: '24px',
        h: '64px',
        borderRadius: '32px',
        border: 'none',
      },
      labelProps: { fontSize: 18, fontFamily: 'WB' },
    };
  }
  if (variant === INPUT_VARIANTS.CONTAINER_FILLED) {
    return {
      containerProps: { h: '100%' },
      inputProps: {
        h: '100%',
        borderRadius: 0,
        border: 'none',
        boxShadow: 'none',
        _hover: {
          boxShadow: 'none',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
    };
  }
  return {
    inputProps: {
      px: '12px',
      h: '48px',
      borderRadius: '8px',
      borderColor: 'gray.200',
      borderStyle: 'solid',
      _disabled: {
        opacity: 1,
        bg: '#f9f9f9',
        cursor: 'not-allowed',
        _hover: {
          borderColor: '#d9dada',
        },
      },
      _hover: {
        borderColor: '#d9dada',
      },
    },
    labelProps: {
      fontSize: 13,
      color: 'gray.400',
      fontFamily: 'WS',
      lineHeight: '19px',
      mb: '8px',
      mr: '9px',
    },
  };
};

export default {
  ...theme,
  colors: {
    ...theme.colors,
    ...COLORS,
  },
  fonts: {
    ...theme.fonts,
    mono: 'Work Sans',
    body: 'Work Sans',
    heading: 'Poppins',
    ...FONTS,
  },
  shadows: {
    ...theme.shadows,
    outline: 'none',
  },
  breakpoints: Object.values(size),
  icons: {
    ...theme.icons,
    ...customIcons,
  },
  components: {
    ...theme.components,
    Popover: {
      variants: {
        default: {
          content: {
            minWidth: '200px',
            width: 'auto',
          },
        },
        responsive: {
          popper: {
            width: '100%',
          },
          content: {
            width: 'auto',
          },
        },
      },
      ...theme.components.Popover,
    },
    Checkbox: {
      baseStyle: {
        control: {
          width: '18px',
          height: '18px',
          borderWidth: '2px',
          borderColor: 'gray.mid',
          borderStyle: 'solid',
          borderRadius: '2px',
        },
        _checked: {
          border: 0,
        },
      },
    },
  },
};
