import { Box, Flex } from '@chakra-ui/react';
import ReadOnlyTable from '@components/ReadOnlyTable';
import useMediaQuery from '@hooks/useMediaQuery';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { isEmpty } from 'lodash-es';
import React from 'react';

const COLUMNS = [
  {
    name: '',
    w: '40%',
  },
  { name: 'Client', w: '20%' },
  { name: 'Delivery Partner', w: '20%' },
  {
    name: 'Margin %',
    w: '20%',
  },
];

const PaymentOverview = ({
  isLoading,
  tableData,
  depositTableData,
  mainNameQA,
}) => {
  const isPageWide = useMediaQuery('(min-width: 1200px)');

  const hasDepositData = !isEmpty(depositTableData);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box bg="#fff" borderRadius="8px" mb="56px" p="24px">
      <Typography variant="h2" mb="16px">
        Invoice Overview
      </Typography>
      <Flex flexWrap="wrap">
        <Box
          w={
            hasDepositData
              ? ['100%', '100%', '100%', '100%', '100%', '50%']
              : '100%'
          }
          pr={[0, 0, 0, 0, 0, '15px']}
        >
          <ReadOnlyTable
            columns={COLUMNS}
            qaTag={mainNameQA}
            tableData={
              isPageWide
                ? tableData
                : [...(tableData || []), ...(depositTableData || [])]
            }
          />
        </Box>
        {hasDepositData && isPageWide && (
          <Box w="50%" pl="15px">
            <ReadOnlyTable
              qaTag={mainNameQA}
              columns={COLUMNS}
              tableData={depositTableData}
            />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default PaymentOverview;
