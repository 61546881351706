import { formatCurrency, formatNumber } from '@utils';
import { first, get, isEmpty, map, reduce, round } from 'lodash-es';
import { action, computed, makeObservable, observable } from 'mobx';
import { PROJECT_TYPES, PROJECT_PAYMENT_TYPES } from '@app/constants';

export default class InvoicesStore {
  constructor({ API, toastsStore, projectsStore }) {
    makeObservable(this);
    this.API = API;
    this.toastsStore = toastsStore;
    this.projectsStore = projectsStore;
  }

  @observable _invoice = {};

  @observable isLoading = false;

  @computed get invoice() {
    return this.decoratePaymentInvoice(this._invoice);
  }

  @action updateInvoice = async (invoice, successCB) => {
    try {
      await this.API.updateInvoice(invoice.id, invoice);
      if (typeof successCB === 'function') successCB();
    } catch (e) {
      this.toastsStore.showError({ title: 'Operation failed.' });
    }
  };

  @action fetchScheduleInvoice = async (projectId, invoiceId) => {
    this.isLoading = true;
    try {
      const { data: invoice } = await this.API.getScheduleInvoice(
        projectId,
        invoiceId,
      );

      const { data: taxInfo } =
        invoice.contactType === 'Client'
          ? await this.API.getClientTaxInfo(
              projectId,
              invoice.projectPayment.paymentType ===
                PROJECT_PAYMENT_TYPES.DEPOSIT,
            )
          : await this.API.getSupplierTaxInfo(
              projectId,
              invoice.supplier.id,
              invoice.projectPayment.paymentType ===
                PROJECT_PAYMENT_TYPES.DEPOSIT,
            );

      this._invoice = { ...invoice, taxInfo };
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      this.isLoading = false;
    }
  };

  decoratePaymentInvoice = invoice => {
    if (isEmpty(invoice)) {
      return {};
    }
    const {
      project: { currency, projectType },
    } = this.projectsStore;

    const getSupplierCurrency = () => {
      if (invoice.contactType === 'Supplier') {
        return invoice.overriddenCurrency;
      }
      return undefined;
    };

    const total = reduce(
      invoice.lineItems,
      (sum, entry) => sum + entry.amount * entry.rate,
      0,
    );
    const taxRate = get(invoice, 'taxInfo.rate');
    const taxName = get(invoice, 'taxInfo.name', '');
    const taxRateFactor = taxRate > 0 ? (taxRate + 100) / 100 : 1;
    const invoiceCurrency =
      invoice.contactType === 'Client' ? currency : getSupplierCurrency();

    const creditAllocationsTotal = reduce(
      invoice.creditAllocations,
      (sum, alloc) => sum + +alloc.amount,
      0,
    );
    const totalWithCredits =
      total * taxRateFactor - creditAllocationsTotal * taxRateFactor;

    const isFixedPrice = projectType === PROJECT_TYPES.FIXED_PRICE;
    const firstInvoice = first(invoice.lineItems) || {};
    const totalWithoutCRs = firstInvoice.amount * firstInvoice.rate;
    const {
      approvedAt,
      supplier,
      address,
      invoiceName,
      location,
      vatNumber,
      paymentInfo,
      projectPayment,
    } = invoice || {};
    const invoiceDetailsInfo = contactType => {
      const preApprovedData =
        contactType === 'Client' ? projectPayment?.project?.client : supplier;
      return {
        address: approvedAt ? address : preApprovedData?.address || address,
        invoiceName: approvedAt
          ? invoiceName
          : preApprovedData?.invoiceName || invoiceName,
        location: approvedAt ? location : preApprovedData?.location || location,
        vatNumber: approvedAt
          ? vatNumber
          : preApprovedData?.vatNumber || vatNumber,
        paymentInfo: approvedAt
          ? paymentInfo
          : preApprovedData?.paymentInfo || paymentInfo,
      };
    };

    return {
      ...invoice,
      tableData: map(invoice.lineItems, entry => [
        {
          value: `${entry.name}${
            entry.resourceType ? ` (${entry.resourceType})` : ''
          }`,
          id: entry.id,
        },
        {
          value: formatNumber(entry.amount),
          amount: entry.amount,
          id: entry.id,
        },
        {
          value: formatCurrency(entry.rate, invoiceCurrency),
          rate: entry.rate,
          id: entry.id,
        },
        taxName,
        formatCurrency(entry.amount * entry.rate, invoiceCurrency),
      ]),
      creditAllocations: map(invoice.creditAllocations, alloc => {
        return {
          ...alloc,
          creditNoteTableData: [
            [
              alloc?.creditNote?.description,
              formatNumber(alloc?.creditNote?.quantity || 1),
              formatCurrency(alloc?.creditNote?.unitPrice, invoiceCurrency),
              taxName,
              formatCurrency(alloc?.creditNote?.total, invoiceCurrency),
            ],
          ],
        };
      }),
      creditAllocationsTableData: map(invoice.creditAllocations, alloc => [
        alloc.status,
        formatCurrency(alloc.amount, invoiceCurrency),
        alloc?.creditNote?.status,
        formatCurrency(alloc?.creditNote?.amount, invoiceCurrency),
      ]),
      invoiceDetailsInfo: invoiceDetailsInfo(invoice.contactType),
      total,
      totalVat: total * (taxRateFactor > 1 ? taxRateFactor - 1 : 0),
      formatedTotalVat: formatCurrency(
        total * (taxRateFactor > 1 ? taxRateFactor - 1 : 0),
        invoiceCurrency,
      ),
      formatedTotal: formatCurrency(total, invoiceCurrency),
      formatedAmountDue: formatCurrency(total * taxRateFactor, invoiceCurrency),
      ...(isEmpty(invoice.creditAllocationsTotal) && {
        creditAllocationsTotal,
        formatedCreditAllocationsTotal: formatCurrency(
          creditAllocationsTotal,
          invoiceCurrency,
        ),
        formatedCreditAllocationsTotalWithVat: formatCurrency(
          creditAllocationsTotal * taxRateFactor,
          invoiceCurrency,
        ),
        totalWithCredits,
        formatedTotalWithCredits: formatCurrency(
          round(totalWithCredits, 2) === 0 ? 0 : totalWithCredits,
          invoiceCurrency,
        ),
      }),
      ...(isFixedPrice && { totalWithoutCRs }),
    };
  };

  @action setInvoiceProperty = (invoiceId, obj) => {
    this._invoices.data = map(this._invoices.data, invoice => {
      if (invoiceId === invoice.id) {
        return { ...invoice, ...obj };
      }
      return invoice;
    });
  };
}
