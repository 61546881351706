import React from 'react';
import { find, isEmpty } from 'lodash-es';
import { customIcons } from '@styles/customIcons';
import { createIcon, Icon as ChakraIcon } from '@chakra-ui/react';

const CustomIconCauseImLazy = name => {
  const arrayOfObjects = Object.keys(customIcons).map(key => {
    const array = customIcons[key];
    array.name = key;
    return array;
  });
  const findIconByName = find(arrayOfObjects, obj => obj.name === name);
  return findIconByName;
};

const Icon = ({ name, ...props }) => {
  const obj = CustomIconCauseImLazy(name) || {};
  const IconCreator = createIcon({
    displayName: obj.name,
    path: obj.path,
    viewBox: obj.viewBox,
  });
  if (!isEmpty(obj)) {
    return <IconCreator name={name} {...props} />;
  }
  return <ChakraIcon />;
};

export default Icon;
