import Typography from '@styles/Typography';
import React from 'react';
import WhiteCard from '@components/WhiteCard';
import { Flex } from '@chakra-ui/react';
import Button from '@styles/Button';
import { Form } from 'react-final-form';
import { map, forEach } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import PerfectMatchQuestion from './PerfectMatchQuestion';

const PerfectDPMatchForm = ({
  onAfterSubmit,
  matchOptions,
  briefAssignmentsStore: { updateMatchingQuestionnaire },
  briefsStore: { brief },
  mainNameQA,
}) => {
  const initialValues = React.useMemo(() => {
    const key = brief.isPlugAndPlay ? 'ppBriefQuestions' : 'briefQuestions';
    const values = {};
    forEach(matchOptions, opt => {
      values[opt.fieldName] = brief[key]
        ? brief[key][opt.fieldName] || false
        : false;
    });
    return values;
  }, [matchOptions, brief.briefQuestions, brief.ppBriefQuestions]);

  return (
    <WhiteCard
      m={0}
      p={[0, 0, 0, '24px']}
      border={['none', 'none', 'none', '1px solid #D9DADA']}
    >
      <Flex flexDir="column">
        <Typography variant="h4" mb="8px">
          Find the perfect match
        </Typography>
        <Typography mb="24px">
          To discover the best delivery partners for this engagment answer the
          questions about the client and engagment below.
        </Typography>
      </Flex>
      <Form
        onSubmit={values => updateMatchingQuestionnaire(values, onAfterSubmit)}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => {
          return (
            <Flex as="form" onSubmit={handleSubmit} flexDir="column">
              {map(matchOptions, option => (
                <PerfectMatchQuestion
                  key={`perfect-match-question-${option.id}`}
                  mainNameQA={mainNameQA}
                  {...option}
                />
              ))}
              <Button
                alignSelf="flex-start"
                mt="28px"
                mb={['40px', '40px', '40px', 0]}
                type="submit"
                isLoading={submitting}
                w={['100%', '100%', '100%', 'auto']}
              >
                See matches
              </Button>
            </Flex>
          );
        }}
      />
    </WhiteCard>
  );
};

export default inject(
  'briefAssignmentsStore',
  'briefsStore',
)(observer(PerfectDPMatchForm));
