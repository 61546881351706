import { Box, Flex, Image, Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const DiscardBriefModal = ({
  isOpen,
  onClose = () => {},
  onDiscardBrief = () => {},
  isProposal = false,
  discardQuote = 'Discard this quote?',
  modalMainText = 'If you leave the quote creation process all progress will be lost.',
  cancelCTA = 'Go back to quote',
  discardCTA = 'Discard quote',
  maxWidth = '640px',
}) => {
  const isSmallerScreen = useMediaQuery(breakpoint.lg);
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styleProps={{
        minW: '340px',
        p: isSmallerScreen ? '24px 16px' : '34px 28px',
        maxW: maxWidth,
      }}
      closeOnEsc={false}
    >
      <Flex>
        <Box alignSelf="center" w="100%">
          <Typography variant="h1" mb="16px">
            {isProposal ? 'Discard this Proposal?' : discardQuote}
          </Typography>
          <Typography mb="34px">{modalMainText}</Typography>
          <Stack direction={isSmallerScreen ? 'column' : 'row'} spacing={4}>
            <Button onClick={onClose} isDisabled={isLoading}>
              {isProposal ? 'Go back to proposal' : cancelCTA}
            </Button>
            <Button
              variant="warning"
              onClick={async () => {
                setIsLoading(true);
                await onDiscardBrief();
                setIsLoading(false);
              }}
              isLoading={isLoading}
            >
              {isProposal ? 'Discard' : discardCTA}
            </Button>
          </Stack>
        </Box>
        {!isSmallerScreen && (
          <Image
            ml="10px"
            src="/images/illustration-frustrated-man.svg"
            alt="Are you sure?"
            w="197px"
            ignoreFallback
          />
        )}
      </Flex>
    </Modal>
  );
};

export default DiscardBriefModal;
