import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import {
  isArray,
  isEmpty,
  isObject,
  isString,
  last,
  map,
  toLower,
} from 'lodash-es';
import React from 'react';

const ResourceDescription = ({ description, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!description) {
    return null;
  }

  return (
    <Box
      w="100%"
      position="relative"
      pl="20px"
      pr="45px"
      border="1px solid"
      borderColor="dark.mid"
      bg="#fff"
      borderTop="none"
      borderBottomRightRadius="8px"
      borderBottomLeftRadius="8px"
      {...props}
    >
      <Box
        w="100%"
        pt="16px"
        pb="8px"
        h={isOpen ? 'auto' : '35px'}
        mb="16px"
        className="displayDescriptionAsHTML"
        display="block"
        style={{
          ...(!isOpen && {
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': '1',
            '-webkit-box-orient': 'vertical',
          }),
        }}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      <Button
        iconButton={{ name: 'arrowLeft', fontSize: 24 }}
        transform={isOpen ? 'rotate(90deg)' : 'rotate(270deg)'}
        variant="ghost"
        borderRadius="50%"
        width="32px"
        height="32px"
        minW="32px"
        position="absolute"
        right="8px"
        top="9px"
        onClick={isOpen ? onClose : onOpen}
      />
    </Box>
  );
};

const ReadOnlyResourcesTable = ({
  columns,
  tableData,
  mainNameQA,
  ...props
}) => {
  if (isEmpty(tableData)) {
    return (
      <Typography variant="h4" mt="42px" mb="10px" textAlign="center">
        No records to display.
      </Typography>
    );
  }
  const displayValueInTable = value => {
    if (isArray(value)) {
      return value.join(', ');
    }
    if (isObject(value)) {
      return value.value;
    }
    return value;
  };
  return (
    <>
      <Flex w="100%" mb="12px" mt="24px" color="dark.solid" {...props}>
        {map(columns, col => (
          <Typography
            variant="caption"
            key={`column-${col.name}`}
            w={col.w || 'auto'}
          >
            {col.name}
          </Typography>
        ))}
      </Flex>
      {map(tableData, (row, i) => (
        <Box key={`table-data-row-${i}`} position="relative" mb="12px">
          <Flex
            border="1px solid"
            borderColor="dark.mid"
            borderTopLeftRadius="8px"
            borderTopRightRadius="8px"
            bg="#fff"
          >
            {map(
              columns,
              ({ w, component: Component, ellipsis = true, name }, idx) => {
                return (
                  <Box
                    as={Flex}
                    px="10px"
                    minH="72px"
                    alignItems="center"
                    w={w}
                    borderRight="1px solid"
                    borderColor="dark.mid"
                    _last={{ borderRight: 'none' }}
                    key={`table-data-item-${i}-${idx}`}
                  >
                    {Component ? (
                      <Component
                        value={isString(row[idx]) ? row[idx] : row[idx].value}
                        {...(isObject(row[idx]) && row[idx])}
                      />
                    ) : (
                      <Typography
                        py="10px"
                        {...(ellipsis && {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        })}
                        data-test-id={`${mainNameQA}--desiredResources-${toLower(
                          name,
                        )}`}
                      >
                        {displayValueInTable(row[idx])}
                      </Typography>
                    )}
                  </Box>
                );
              },
            )}
          </Flex>
          {tableData[i][4] && (
            <Box
              border="1px solid"
              borderColor="dark.mid"
              borderTop="none"
              borderBottomRightRadius={!last(tableData[i]) ? '8px' : ' 0'}
              borderBottomLeftRadius={!last(tableData[i]) ? '8px' : ' 0'}
              px="20px"
              py="10px"
              display="flex"
              alignItems="center"
              minH="50px"
              lineHeight="1.5"
              data-test-id={`${mainNameQA}--desiredResources-technologies`}
            >
              Technologies: {tableData[i][4].join(', ')}
            </Box>
          )}
          <ResourceDescription
            description={last(tableData[i])}
            data-test-id={`${mainNameQA}--desiredResources-resourceDescription`}
          />
        </Box>
      ))}
    </>
  );
};
export default ReadOnlyResourcesTable;
