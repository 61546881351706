import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import Textarea from '@components/forms/_common/Textarea';
import TextInput from '@components/forms/TextInput';
import WhiteCard from '@components/WhiteCard';
import Button from '@styles/Button';
import { INPUT_VARIANTS } from '@styles/theme';
import Typography from '@styles/Typography';
import { stripOptionalLabel } from '@utils/appUtils';
import {
  composeValidators,
  mustBeShorterOrEqual,
  required,
} from '@utils/formValidators';
import { find, isString, lowerCase, map, reject } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form } from 'react-final-form';
import AutoSave from './AutoSave';
import DeleteFeatureOrTaskModal from './DeleteFeatureOrTaskModal';

const textAreaProps = (isReadOnly = false) => ({
  maxW: '727px',
  mb: '24px',
  inputProps: {
    disabled: isReadOnly,
    color: isReadOnly ? 'rgba(0,0,0,0.4)' : 'inherit',
  },
});

const nameMustBeUniqe = features => value => {
  const names = map(features, f => lowerCase(f.name));
  return find(names, name => name === lowerCase(value))
    ? 'Feature name must be uniqe.'
    : undefined;
};

const FeatureDescriptionForm = ({
  selectedId,
  onDeleteFeature,
  isReadOnly,
  mainNameQA,
  isClientView = false,
  isSmallerScreen,
  currentFeature = {},
  features,
  usersStore: { isTeamAdmin },
  proposalsStore: { deleteCustomFeature },
  onSubmit = () => {},
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isCustom = isString(selectedId) || currentFeature.isCustom;

  return (
    <>
      <WhiteCard
        mt="0"
        pb="0"
        boxShadow="0 0 0 0 rgba(0, 0, 0, 0)"
        border="solid 1px"
        borderColor="dark.mid"
        mb="24px"
        p={isSmallerScreen && '12px'}
      >
        {(!isCustom || isClientView) && (
          <Typography varaint="h2" mb="32px">
            {currentFeature?.name}
          </Typography>
        )}
        <Form
          onSubmit={onSubmit}
          keepDirtyOnReinitialize
          initialValues={{
            description: currentFeature?.description,
            name: currentFeature?.name,
          }}
          render={({ handleSubmit, form }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <AutoSave
                  formApi={form}
                  onSave={onSubmit}
                  formName="featureDescription"
                />
                <Box w="100%" maxW="800px" pb={!isSmallerScreen && '20px'}>
                  {isCustom && (
                    <Flex justifyContent="space-between" alignItems="center">
                      {!isClientView && (
                        <Field
                          isDisabled={!isTeamAdmin || isReadOnly}
                          validate={composeValidators(
                            required,
                            nameMustBeUniqe(
                              reject(features, {
                                id: currentFeature.id,
                              }),
                            ),
                            mustBeShorterOrEqual(200),
                          )}
                          component={TextInput}
                          label="Feature name"
                          isBordered
                          name="name"
                          data-test-id={`${mainNameQA}--featureName`}
                          placeholder="Enter a feature name here"
                          variant={
                            isSmallerScreen
                              ? INPUT_VARIANTS.DEFAULT
                              : INPUT_VARIANTS.ROUNDED
                          }
                          containerProps={{
                            maxW: isSmallerScreen ? '100%' : '480px',
                            mt: '16px',
                            mb: '32px',
                          }}
                        />
                      )}
                      {isTeamAdmin && !isReadOnly && (
                        <Button
                          iconButton={{ name: 'trash3', fontSize: 30 }}
                          data-test-id={`${mainNameQA}--removeFeature`}
                          onClick={onOpen}
                          color="#878380"
                          bgColor="transparent"
                          w="48px"
                          h="48px"
                          pr="0"
                          pl="0"
                          ml="12px"
                          mt="12px"
                        />
                      )}
                    </Flex>
                  )}
                  <Field
                    validate={mustBeShorterOrEqual(10000)}
                    component={Textarea}
                    label={stripOptionalLabel(
                      'Feature description (optional)',
                      isClientView,
                    )}
                    {...textAreaProps(isReadOnly)}
                    rows={6}
                    name="description"
                    data-test-id={`${mainNameQA}--featureDescription`}
                    parse={value => (value === null ? '' : value)}
                    placeholder="Describe the feature in as much detail as possible"
                    isDisabled={isReadOnly}
                  />
                </Box>
              </form>
            );
          }}
        />
      </WhiteCard>
      {isTeamAdmin && !isReadOnly && (
        <DeleteFeatureOrTaskModal
          isOpen={isOpen}
          onClose={onClose}
          isLoading={currentFeature.isDeleting}
          onDelete={() =>
            currentFeature.id
              ? deleteCustomFeature(currentFeature.id, onDeleteFeature)
              : onDeleteFeature()
          }
        />
      )}
    </>
  );
};

export default inject(
  'proposalsStore',
  'usersStore',
)(observer(FeatureDescriptionForm));
