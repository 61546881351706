import {
  isRetainerOrTAForFixedPrice,
  isFixedPrice,
  isProjectCloning,
} from '@utils/projectUtils';
import { APP_ROUTES } from '@routes';
import { inject, observer } from 'mobx-react';
import { map, isEmpty } from 'lodash-es';
import ReadOnlyDeazyAllocations from '@pages/Project/AllocationsAndActuals/ReadOnlyDeazyAllocations';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { getDeazyAllocationsColumns } from '@pages/Project/constants';
import DeazyAllocationHead from './DeazyAllocationHead';
import DeazyAllocationRow from './DeazyAllocationRow';
import ATable from '../AllocationTable/AllocationTable';
import FormSpyFieldValues from '../forms/FormSpyFieldValues';

const AllocationTable = ({
  projectsStore: { project },
  allocationsStore: { formatTableDeazyEntry, allocationBorderDates },
  initialAllocations,
  disabledFields,
  disallowRowChange = false,
  resourceAsText,
  mainNameQA,
  isManualAddingRow,
  isActualsCreating,
  isEditing,
  ...props
}) => {
  const columns = getDeazyAllocationsColumns({
    isFixedPrice: isFixedPrice(project),
  });

  const isCreatingProject = useRouteMatch(APP_ROUTES.projectsCreate);

  const borderDateForAllocation = allocationBorderDates[project.id];
  const location = useLocation();

  const tableData = React.useMemo(() => {
    const { currency } = project;

    return map(initialAllocations, entry =>
      formatTableDeazyEntry({
        entry,
        currency,
      }),
    );
  }, [project, initialAllocations]);

  return (
    <>
      {isProjectCloning(location) ||
      isEditing ||
      (isEmpty(initialAllocations) && isCreatingProject) ||
      isActualsCreating ? (
        <>
          <FormSpyFieldValues fieldNames={['engagementType', 'projectType']}>
            {p => <DeazyAllocationHead showAllocationDates={isFixedPrice(p)} />}
          </FormSpyFieldValues>
          <ATable
            fieldName="deazyAllocations"
            mainNameQA={mainNameQA}
            AllocationRow={DeazyAllocationRow}
            initialAllocations={initialAllocations}
            resourceAsText={resourceAsText}
            disabledFields={disabledFields}
            disallowRowChange={disallowRowChange}
            isManualAddingRow={isManualAddingRow}
            isEditing={isEditing}
            isFixedPrice={isFixedPrice(project)}
            isRetainerOrTAForFixedPrice={isRetainerOrTAForFixedPrice(project)}
            borderDateForAllocation={borderDateForAllocation}
            {...props}
          />
        </>
      ) : (
        <ReadOnlyDeazyAllocations
          projectId={project.id}
          columns={columns}
          tableData={tableData}
        />
      )}
    </>
  );
};
export default inject(
  'projectsStore',
  'allocationsStore',
)(observer(AllocationTable));
