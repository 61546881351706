import { Box, Button, Flex, Spinner } from '@chakra-ui/react';
import Icon from '@components/Icon';
import { inject, observer } from 'mobx-react';
import { scrollToElementStart } from '@utils/appUtils';
import { NEW_PROPOSAL_STATUSES, MAIN_NAMES_QA } from '@app/constants';
import {
  find,
  isString,
  toNumber,
  forEach,
  isEmpty,
  get,
  isBoolean,
} from 'lodash-es';
import { nanoid } from 'nanoid';
import { APP_ROUTES } from '@routes';
import { useHistory, useParams } from 'react-router-dom';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import React from 'react';
import ConfidenceLevel from './ConfidenceLevel';
import FeatureAssumptionsForm from './FeatureAssumptionsForm';
import FeatureDescriptionForm from './FeatureDescriptionForm';
import ProposalFeatures from './ProposalFeatures';
import TasksForm from './TasksForm';

const mainNameQA = MAIN_NAMES_QA.PROJECT_PROPOSAL;

const DPFeatureSection = ({
  isReadOnly,
  isSubmitting,
  proposalsStore: {
    decoratedProposalData,
    proposalData,
    saveProposalFeature,
    saveProposalTasks,
    proposalId,
    fetchProposalData,
    clearProposalData,
    selectedFeatureId,
    setSelectedFeatureId,
    fetchClientProposalData,
    clientProposalData,
    proposalCurrency,
    decoratedClientProposalData,
  },
  usersStore: { isClient, isAdminOrDL, isTeamAdmin },
  toastsStore,
  briefsStore: { brief },
}) => {
  const disableForms =
    proposalData.status !== NEW_PROPOSAL_STATUSES.WITH_DP || brief.isClosed;
  const isSmallerScreen = useMediaQuery(breakpoint.lg);
  const history = useHistory();
  const {
    featureId: featureIdFromParams,
    proposalId: proposalIdFromURL,
    type,
  } = useParams();

  const [prevSelectedId, setPrevSelectedId] = React.useState(null);
  const isFullBreakdown =
    clientProposalData?.clientSettings?.featuresBreakdown === 'Full breakdown';

  const showFullBreakdownForClient =
    (isClient && isFullBreakdown) || (type === 'client' && isFullBreakdown);

  const currency = isClient || type === 'client' ? 'GBP' : proposalCurrency;

  const showConfidenceLevel = React.useMemo(() => {
    if (type === 'delivery-partner') {
      return true;
    }
    if (isTeamAdmin) {
      return true;
    }
    return false;
  }, [type, isTeamAdmin]);

  const initFeatureId = React.useMemo(() => {
    if (featureIdFromParams && featureIdFromParams !== 'add-new-feature') {
      return toNumber(featureIdFromParams);
    }
    return get(proposalData || clientProposalData, 'features[0].id', null);
  }, [featureIdFromParams, proposalData, clientProposalData]);

  React.useEffect(() => {
    if (!selectedFeatureId) {
      setSelectedFeatureId(initFeatureId);
    }
    if (isSmallerScreen && featureIdFromParams !== 'add-new-feature') {
      return () => setSelectedFeatureId(null);
    }
  }, [initFeatureId, featureIdFromParams, isSmallerScreen]);

  const nextFeatureId = React.useMemo(() => {
    const data = isClient ? decoratedClientProposalData : decoratedProposalData;

    const findClosestId = x =>
      (data.features.find(({ id }) => x < id) || {}).id;

    return findClosestId(selectedFeatureId || initFeatureId);
  }, [
    isClient,
    decoratedClientProposalData,
    decoratedProposalData,
    selectedFeatureId,
    initFeatureId,
  ]);

  const [isNewFeautureInMobile, setIsNewFeautureInMobile] = React.useState(
    false,
  );

  const temporaryFeature =
    find(proposalData?.features, f => f.tempId === selectedFeatureId) || {};

  const fId = React.useMemo(() => {
    return !featureIdFromParams || isNewFeautureInMobile
      ? temporaryFeature.id || selectedFeatureId
      : toNumber(featureIdFromParams);
  }, [featureIdFromParams, selectedFeatureId, temporaryFeature, isTeamAdmin]);

  const historyPushRoute = (featureId, isForSingleFeatureView = false) => {
    if (isForSingleFeatureView) {
      if (isClient) {
        return `proposals/${proposalIdFromURL}/features/${featureId}`;
      }
      if (isAdminOrDL) {
        return `proposals/${proposalIdFromURL}/${type}/features/${featureId}`;
      }
      return `proposal/features/${featureId}`;
    }
    if (isClient) {
      return `proposals/${proposalIdFromURL}`;
    }
    if (isAdminOrDL) {
      return `proposals/${proposalIdFromURL}/${type}`;
    }
    return `proposal`;
  };

  const nextFeatureClick = () => {
    const setIDsAndRedirect = () => {
      setPrevSelectedId(selectedFeatureId);
      setSelectedFeatureId(nextFeatureId);
      if (isSmallerScreen) {
        history.push(
          APP_ROUTES.briefTab(brief.id, historyPushRoute(nextFeatureId, true)),
        );
      }
    };

    if (isClient) {
      return setIDsAndRedirect();
    }

    const { tasks } =
      find(
        proposalData.features,
        f => f.id === (selectedFeatureId || initFeatureId),
      ) || {};
    if (isEmpty(tasks)) {
      return toastsStore.showError({
        title: 'At least one task must be added.',
      });
    }
    let hasEmptyTask = false;

    forEach(tasks, task => {
      if (!task.name) {
        hasEmptyTask = true;
      }
      forEach(task.resources, resource => {
        if (!resource.hours) {
          hasEmptyTask = true;
        }
      });
    });

    if (hasEmptyTask) {
      toastsStore.showError({
        title: 'Please complete all tasks!',
      });
    } else {
      scrollToElementStart('#stepNumber-2');
      setIDsAndRedirect();
    }
    return undefined;
  };

  React.useEffect(() => {
    if (featureIdFromParams && (proposalId || proposalIdFromURL)) {
      if (!isClient && brief.id && !isReadOnly) {
        fetchProposalData(brief.id, proposalId || proposalIdFromURL);
      }
      if (brief.id && isClient) {
        fetchClientProposalData(brief.id, proposalIdFromURL || proposalId);
      }
      return () => {
        clearProposalData();
      };
    }
  }, [brief.id, proposalId, featureIdFromParams]);

  React.useEffect(() => {
    if (isBoolean(isSmallerScreen)) {
      if (featureIdFromParams && !isSmallerScreen) {
        history.push(APP_ROUTES.briefTab(brief.id, historyPushRoute()));
      }
      if (!isTeamAdmin && featureIdFromParams === 'add-new-feature') {
        history.push(APP_ROUTES.briefs);
      }
    }
  }, [isSmallerScreen, isTeamAdmin, featureIdFromParams]);

  React.useEffect(() => {
    if (featureIdFromParams === 'add-new-feature') {
      setSelectedFeatureId(nanoid(10));
      return setIsNewFeautureInMobile(true);
    }
    return setIsNewFeautureInMobile(false);
  }, [featureIdFromParams]);

  const memoView = React.useMemo(() => {
    if (isSmallerScreen && featureIdFromParams) {
      return true;
    }
    if (!isSmallerScreen && selectedFeatureId) {
      return true;
    }
    return false;
  }, [isSmallerScreen, featureIdFromParams, selectedFeatureId]);

  const features = React.useMemo(() => {
    return showFullBreakdownForClient
      ? decoratedClientProposalData.features
      : decoratedProposalData.features;
  }, [
    showFullBreakdownForClient,
    decoratedClientProposalData.features,
    decoratedProposalData.features,
  ]);

  const currentFeature = React.useMemo(() => {
    return find(features, f => f.id === fId) || {};
  }, [features, fId]);

  return (
    <Flex
      mt={(isReadOnly || disableForms) && !featureIdFromParams && '30px'}
      pt={isSmallerScreen && '12px'}
    >
      {!featureIdFromParams && (
        <Box w="100%" maxW={isSmallerScreen ? '100%' : '380px'}>
          <ProposalFeatures
            isReadOnly={isReadOnly || disableForms}
            selectedId={fId}
            onSelect={featureId => {
              if (isSmallerScreen) {
                history.push(
                  APP_ROUTES.briefTab(
                    brief.id,
                    historyPushRoute(featureId, true),
                  ),
                );
              } else {
                setPrevSelectedId(fId);
                setSelectedFeatureId(featureId);
              }
            }}
            showFullBreakdownForClient={showFullBreakdownForClient}
            currency={currency}
            onAddNewFeature={() => {
              setPrevSelectedId(fId);
              setSelectedFeatureId(nanoid(10));
              scrollToElementStart('#stepNumber-2');
              if (isSmallerScreen) {
                history.push(
                  APP_ROUTES.briefTab(
                    brief.id,
                    `proposal/features/add-new-feature`,
                  ),
                );
              }
            }}
            mainNameQA={mainNameQA}
            isSmallerScreen={isSmallerScreen}
          />
        </Box>
      )}

      <Box
        w={!featureIdFromParams && isSmallerScreen ? 0 : '100%'}
        pl={isSmallerScreen ? 0 : '24px'}
      >
        {memoView && (
          <>
            {isEmpty(currentFeature) &&
            !selectedFeatureId &&
            !isNewFeautureInMobile ? (
              <Spinner mx="auto" my="50px" variant="medium" />
            ) : (
              <>
                <FeatureDescriptionForm
                  key={`feature-description-${selectedFeatureId ||
                    featureIdFromParams}`}
                  selectedId={fId}
                  currentFeature={currentFeature}
                  features={features}
                  isReadOnly={isReadOnly || disableForms}
                  isClientView={isClient}
                  mainNameQA={mainNameQA}
                  isSmallerScreen={isSmallerScreen}
                  onDeleteFeature={() =>
                    setSelectedFeatureId(
                      find(proposalData.features, f => f.id === prevSelectedId)
                        ? prevSelectedId
                        : null,
                    )
                  }
                  onSubmit={
                    !isReadOnly
                      ? values =>
                          saveProposalFeature(isSubmitting)(
                            {
                              briefId: brief.id,
                              proposalId,
                              featureId: fId,
                            },
                            values,
                          )
                      : () => {}
                  }
                />
                {(!isString(selectedFeatureId) || !isString(fId)) && (
                  <>
                    <TasksForm
                      currency={currency}
                      isSmallerScreen={isSmallerScreen}
                      currentFeature={currentFeature}
                      brief={brief}
                      mainNameQA={mainNameQA}
                      isReadOnly={isReadOnly || disableForms}
                      showFullBreakdownForClient={showFullBreakdownForClient}
                      onSubmit={
                        !isReadOnly
                          ? values =>
                              saveProposalTasks(isSubmitting)({
                                featureId: fId,
                                ...values,
                              })
                          : () => {}
                      }
                      key={`task-${selectedFeatureId}`}
                    />
                    <FeatureAssumptionsForm
                      isReadOnly={isReadOnly || disableForms}
                      mainNameQA={mainNameQA}
                      featureId={fId}
                      isSmallerScreen={isSmallerScreen}
                      onSubmit={
                        !isReadOnly
                          ? values => {
                              saveProposalFeature(isSubmitting)(
                                {
                                  briefId: brief.id,
                                  proposalId,
                                  featureId: fId,
                                },
                                values,
                              );
                            }
                          : () => {}
                      }
                      key={`feature-assumptions-${selectedFeatureId}`}
                      currentFeature={currentFeature}
                    />
                    {showConfidenceLevel && (
                      <ConfidenceLevel
                        isReadOnly={isReadOnly || disableForms}
                        mainNameQA={mainNameQA}
                        currentFeature={currentFeature}
                        isSmallerScreen={isSmallerScreen}
                      />
                    )}
                  </>
                )}
                {!!nextFeatureId && (
                  <Box textAlign={!isSmallerScreen && 'right'}>
                    <Button
                      color="blue.solid"
                      bg="white"
                      marginTop="16px"
                      width={isSmallerScreen && '100%'}
                      minW="180px"
                      border="2px solid"
                      height="auto"
                      fontSize="18px"
                      borderRadius={isSmallerScreen ? '8px' : '30px'}
                      fontWeight="normal"
                      borderColor="blue.solid"
                      py="15px"
                      px="24px"
                      rightIcon={
                        isSmallerScreen ? null : (
                          <Icon
                            ml="auto"
                            name="arrowLeft"
                            color="blue.solid"
                            fontSize={22}
                            transform="rotate(180deg)"
                          />
                        )
                      }
                      leftIcon={
                        isSmallerScreen ? (
                          <Icon
                            name="mediumArrow"
                            color="blue.solid"
                            fontSize={16}
                          />
                        ) : null
                      }
                      onClick={nextFeatureClick}
                      data-test-id={`${mainNameQA}--nextFeature`}
                    >
                      Next feature
                    </Button>
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Flex>
  );
};
export default inject(
  'proposalsStore',
  'briefsStore',
  'toastsStore',
  'usersStore',
)(observer(DPFeatureSection));
