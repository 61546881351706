import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const DeleteQuestionModal = ({ isOpen, onClose, isLoading, onDelete }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styleProps={{ p: '20px', maxW: '440px' }}
      closeOnOverlayClick={!isLoading}
      closeOnEsc={!isLoading}
    >
      <Typography variant="h1" mb="20px">
        Are you sure?
      </Typography>
      <Typography>This action is irreversible.</Typography>
      <Stack spacing={3} mt="40px">
        <Button isLoading={isLoading} onClick={onDelete}>
          Yes
        </Button>
        <Button variant="outline" onClick={onClose} isDisabled={isLoading}>
          No
        </Button>
      </Stack>
    </Modal>
  );
};

export default DeleteQuestionModal;
