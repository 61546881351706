import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { MAIN_NAMES_QA } from '@app/constants';
import ProposalCards from './ProposalCards';

const mainNameQA = MAIN_NAMES_QA.PROJECT_PROPOSALS_LIST;

const AllProposals = ({
  isLoading,
  proposals,
  usersStore: { isClient },
  briefsStore: { brief, onStatusChange },
  proposalsStore: { fetchDeazyCostBreakdown, deazyCostBreakdown },
}) => {
  return (
    <>
      <Typography variant="h1" mb="24px" mt="54px">
        All Proposals
      </Typography>
      {isLoading ? (
        <Spinner variant="large" />
      ) : (
        <ProposalCards
          isClient={isClient}
          brief={brief}
          proposals={proposals}
          onStatusChange={onStatusChange}
          fetchDeazyCostBreakdown={fetchDeazyCostBreakdown}
          deazyCostBreakdown={deazyCostBreakdown}
          mainNameQA={mainNameQA}
        />
      )}
    </>
  );
};

export default inject(
  'usersStore',
  'briefsStore',
  'proposalsStore',
)(observer(AllProposals));
