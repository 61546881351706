import { sleep } from '@utils';
import { useRef, useEffect, useReducer } from 'react';

const initialState = {
  error: undefined,
  data: undefined,
  isLoading: false,
};

const fetchReducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return { ...initialState, ...state, isLoading: true };
    case 'success':
      return { ...initialState, data: action.payload, isLoading: false };
    case 'error':
      return { ...initialState, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

const cache = {};

function useFetch(apiFn, options = {}) {
  const {
    triggerManually = false,
    cacheKey,
    cacheTime = 5, // in seconds
    polling, // in seconds
    successCb,
    failureCb,
    ignoreCache,
    ...restOptions
  } = options;

  const intervalRef = useRef(null);

  let cancel = false;

  const setCache = obj => {
    if (cacheKey) {
      cache[cacheKey] = { ...cache[cacheKey], ...obj };
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const fetchData = async opt => {
    if (cacheKey && cache[cacheKey]?.isLoading) {
      await sleep(300);
      return fetchData();
    }

    if (!ignoreCache && cacheKey && cache[cacheKey]?.data) {
      dispatch({ type: 'success', payload: cache[cacheKey].data });
      return cache[cacheKey].data;
    }

    setCache({ isLoading: true });
    dispatch({ type: 'loading' });

    try {
      const { data } = await apiFn({ ...restOptions, ...opt });
      if (cacheKey) {
        setCache({ data, isLoading: false });
        setTimeout(() => {
          cache[cacheKey] = { data: null };
        }, cacheTime * 1000);
      }

      if (!cancel) {
        dispatch({ type: 'success', payload: data });
      }

      if (successCb) {
        successCb();
      }
      return data;
    } catch (error) {
      if (!cancel) {
        dispatch({ type: 'error', payload: error });
      }
      if (failureCb) {
        failureCb();
      }
    } finally {
      setCache({ isLoading: false });
    }
  };

  useEffect(() => {
    if (!apiFn) return;

    if (!triggerManually) {
      fetchData();
    }
    if (polling) {
      intervalRef.current = setInterval(() => {
        fetchData();
      }, polling * 1000);
    }

    return () => {
      clearInterval(intervalRef.current);
      cancel = true;
    };
  }, [triggerManually]);

  return { ...state, fetch: fetchData };
}

export default useFetch;
