import {
  Box,
  Link as ChakraLink,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { linkForAction } from '@utils/projectUtils';
import { isEmpty, map } from 'lodash-es';
import React from 'react';
import { Link } from 'react-router-dom';

const BulletItem = styled(Typography)`
  display: flex;
  position: relative;
  padding-bottom: 16px;
  padding-left: 34px;
  :hover {
    text-decoration: underline;
  }
  :before {
    content: ' ';
    position: absolute;
    left: 6px;
    width: 18px;
    height: 18px;
    border-radius: 18px;
    background-color: ${({ theme: { colors } }) => colors.orange.solid};
  }
`;

const ActionsWrapper = ({ isTeamMember, actionsArray, project }) => {
  return (
    <Box>
      {map(actionsArray, action => {
        const link = linkForAction(project || {}, action);
        return (
          <BulletItem
            key={`action-${action.id}`}
            className="external-link"
            pointerEvents={isTeamMember && 'none'}
            as={link.to ? Link : ChakraLink}
            {...link}
          >
            {action.type}
          </BulletItem>
        );
      })}
    </Box>
  );
};

const ActionsButton = ({
  buttonLabel = 'My actions',
  actions = [],
  project,
  variant,
  isTeamMember,
  ...props
}) => {
  const actionsCounter = actions.length || 0;
  return (
    <Popover usePortal>
      <PopoverTrigger>
        <Button variant={variant} {...props}>
          <Typography>{`${buttonLabel} (${actionsCounter})`}</Typography>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        zIndex={3004}
        border="1px solid"
        borderColor="#d9dada"
        maxW="235px"
        boxShadow="0px 0px 24px rgba(0, 0, 0, 0.15);"
      >
        <PopoverBody px="16px" pt="16px" pb="0" boxShadow="none">
          {!isEmpty(actions) && (
            <ActionsWrapper
              isTeamMember={isTeamMember}
              actionsArray={actions}
              project={project}
            />
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ActionsButton;
