import { Box, Image, Stack } from '@chakra-ui/react';
import DescriptionWithIconBox from '@components/DescriptionWithIconBox';
import Icon from '@components/Icon';
import InfoAlert from '@components/InfoAlert';
import styled from '@emotion/styled';
import useMediaQuery from '@hooks/useMediaQuery';
import { APP_ROUTES } from '@routes';
import { breakpoint } from '@styles/breakpoints';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { map } from 'lodash-es';
import React from 'react';

const PLUG_AND_PLAY_BRIEF_OBJ = {
  alertLabel: 'Creating a team augmentation brief takes less than 10 minutes',
  buttonLink: APP_ROUTES.briefsCreatePlugAndPlay,
  buttonLabel: 'Create a team augmentation brief',
  list: [
    'If the brief is for an existing product',
    'The resources you require with start dates',
    'Any files you’d like to share. You can upload a brief document here if you already have one.',
  ],
};

const PROJECT_BRIEF_OBJ = {
  alertLabel: 'Creating a project typically takes around 10-15 minutes',
  buttonLink: APP_ROUTES.briefsCreate,
  buttonLabel: 'Create a project brief',
  list: [
    'A project name',
    'A brief description',
    'Preferred tech stack(s)',
    'Any design files you may have',
    'Any files you’d like to share. You can upload a brief document here if you already have one.',
  ],
};

const DetailsBox = styled(Box)`
  margin-top: 40px;
  @media ${breakpoint.lg} {
    margin-top: 12px;
    background-color: #fff;
    padding: 24px 12px;
    border-radius: 8px;
  }
`;

const ListItem = styled.li`
  padding: 11px;
  display: flex;
  align-items: center;
  @media ${breakpoint.lg} {
    font-size: 12px;
  }
`;

const BriefCreateDetails = ({ isPlugAndPlay, history }) => {
  const { list, buttonLabel, buttonLink, alertLabel } = React.useMemo(() => {
    if (isPlugAndPlay) {
      return PLUG_AND_PLAY_BRIEF_OBJ;
    }
    return PROJECT_BRIEF_OBJ;
  }, [isPlugAndPlay]);
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  return (
    <DetailsBox>
      <Typography variant="h3" mb="8px">
        What we will ask you for
      </Typography>
      <Typography mb={isSmallerScreen ? '16px' : '24px'}>
        In order to complete the brief, we’ll be asking for various bits of
        information, these include:
      </Typography>
      <InfoAlert label={alertLabel} mb={isSmallerScreen ? '20px' : '12px'} />
      <Box as="ul" mb="32px">
        {map(list, listItem => (
          <ListItem mb="12px" key={listItem}>
            <Icon
              name="mediumArrow"
              color="blue.solid"
              mr="12px"
              fontSize="16px"
            />
            <Box>{listItem}</Box>
          </ListItem>
        ))}
      </Box>
      <Button fontWeight="400" onClick={() => history.push(buttonLink)}>
        {buttonLabel}
      </Button>
    </DetailsBox>
  );
};

const imageStyles = isSmallerScreen => ({
  position: 'absolute',
  zIndex: '-1',
  left: '-100px',
  bottom: 0,
  ...(isSmallerScreen && {
    left: '-50px',
    bottom: '55px',
  }),
});

const cardStyles = isSmallerScreen => ({
  maxW: 'none',
  flex: 1,
  height: 'auto',
  ...(isSmallerScreen && {
    p: '12px',
  }),
});

const BriefsStartCreate = ({ history }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isPlugAndPlay, setIsPlugAndPlay] = React.useState(false);
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  const onClick = isPnP => {
    setIsOpen(true);
    setIsPlugAndPlay(isPnP);
  };

  return (
    <Box position="relative" minHeight="100%" pb="160px">
      <Image
        src="/images/with_binoculars.svg"
        {...imageStyles(isSmallerScreen)}
      />
      <Box
        maxW="1201px"
        h="100%"
        w="100%"
        mt={isSmallerScreen ? '32px' : '80px'}
        mx="auto"
      >
        <Box
          p={isSmallerScreen ? '0 16px' : '40px 32px'}
          bg={!isSmallerScreen ? '#fff' : 'transparent'}
          borderRadius="8px"
          maxWidth="1178px"
          mx="auto"
        >
          <Typography variant="h2" mb={isSmallerScreen ? '12px' : '24px'}>
            How can Deazy help you deliver?
          </Typography>
          <Stack
            spacing={isSmallerScreen ? 4 : 6}
            direction={isSmallerScreen ? 'column' : 'row'}
          >
            <DescriptionWithIconBox
              icon={{ name: 'teams', fontSize: 21 }}
              label="Team augmentation"
              description="I’m looking for flexible development resources to add to my team"
              isActive={isOpen && isPlugAndPlay}
              onClick={() => onClick(true)}
              {...cardStyles(isSmallerScreen)}
            />
            <DescriptionWithIconBox
              icon={{ name: 'projects', fontSize: 21 }}
              label="Project brief"
              description="I’m looking to find a partner to help deliver a project"
              isActive={isOpen && !isPlugAndPlay}
              onClick={() => onClick(false)}
              {...cardStyles(isSmallerScreen)}
            />
          </Stack>
          {isOpen && (
            <BriefCreateDetails
              history={history}
              isPlugAndPlay={isPlugAndPlay}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BriefsStartCreate;
