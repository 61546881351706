import { MAIN_NAMES_QA, ENGAGEMENT_TYPES, PROJECT_TYPES } from '@app/constants';
import { Box, Flex } from '@chakra-ui/react';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { formatCurrency, formatNumber } from '@utils';
import {
  getAllocationMultiplier,
  getClientAllocationTotal,
  isRetainerOrTAForFixedPrice,
  getSupplierAllocationTotal,
} from '@utils/projectUtils';
import {
  filter,
  find,
  flatten,
  isEmpty,
  lowerCase,
  map,
  reduce,
} from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import ProjectSummaryRow, { ProjectSummaryHeadings } from './ProjectSummaryRow';

const ProjectSummary = ({
  project,
  mainNameQA,
  projectsStore: { projectTotals, clearCorrectionsTotals },
  settingsStore: {
    exchangeRates: { data: exchangeRates },
  },
}) => {
  const isFixedPrice = project.projectType === PROJECT_TYPES.FIXED_PRICE;
  const isFixedPriceAndProjectType =
    isFixedPrice && project.engagementType === ENGAGEMENT_TYPES.PROJECT_BUILD;
  const {
    isLoading: isLoadingTotals,
    data: {
      updatedProjectToSuppliers: supplierTotals,
      clientTotal,
      clientRaw: clientSubtotal,
    },
  } = projectTotals;
  const {
    projectToSuppliers,
    totalBasis,
    startDate,
    projectType,
    endDate,
    currency,
  } = project;

  const allocationMultiplier = React.useMemo(
    () => getAllocationMultiplier({ startDate, endDate, totalBasis }),
    [startDate, endDate, totalBasis],
  );

  const client = React.useMemo(() => {
    if (isFixedPriceAndProjectType) {
      return getClientAllocationTotal(
        {
          totalBasis,
          projectType,
          allocations: flatten(
            flatten(
              map(projectToSuppliers, s => [
                ...(s.allocations1 || []),
                ...(s.allocations2 || []),
                ...(s.allocations3 || []),
              ]),
            ),
          ),
          deazyAllocations: [
            ...(project.deazyAllocations1 || []),
            ...(project.deazyAllocations2 || []),
            ...(project.deazyAllocations3 || []),
          ],
        },
        allocationMultiplier,
      );
    }
    return getClientAllocationTotal(
      {
        projectType,
        totalBasis,
        allocations: flatten(map(projectToSuppliers, s => s.allocations || [])),
        deazyAllocations: project.deazyAllocations,
      },
      allocationMultiplier,
    );
  }, [
    allocationMultiplier,
    projectToSuppliers,
    project.deazyAllocations,
    project.deazyAllocations1,
    project.deazyAllocations2,
    project.deazyAllocations3,
  ]);

  const { supplierCosts } = React.useMemo(() => {
    if (isFixedPriceAndProjectType) {
      return {
        supplier: getSupplierAllocationTotal(
          {
            projectType,
            totalBasis,
            allocations: flatten(
              flatten(
                map(projectToSuppliers, s => [
                  ...(s.allocations || []),
                  ...(s.allocations1 || []),
                  ...(s.allocations2 || []),
                  ...(s.allocations3 || []),
                ]),
              ),
            ),
          },
          allocationMultiplier,
        ),
        supplierCosts: map(projectToSuppliers, (s, idx) => ({
          ...s,
          showCorrectionCost: +supplierTotals?.[idx]?.supplierTotalValue > 0,
          costAfterCorrection: formatCurrency(
            supplierTotals?.[idx]?.supplierTotalValue,
            s.overriddenCurrency,
          ),
          cost: formatCurrency(
            getSupplierAllocationTotal(
              {
                projectType,
                totalBasis,
                allocations: [
                  ...(s.allocations || []),
                  ...(s.allocations1 || []),
                  ...(s.allocations2 || []),
                  ...(s.allocations3 || []),
                ],
              },
              allocationMultiplier,
            ),
            s.overriddenCurrency,
          ),
        })),
      };
    }
    return {
      supplier: getSupplierAllocationTotal(
        {
          projectType,
          totalBasis,
          allocations: flatten(
            map(projectToSuppliers, s => s.allocations || []),
          ),
        },
        allocationMultiplier,
      ),
      supplierCosts: map(projectToSuppliers, (s, sIdx) => ({
        ...s,
        cost: formatCurrency(
          getSupplierAllocationTotal(
            {
              projectType,
              totalBasis,
              allocations: s.allocations,
            },
            allocationMultiplier,
          ),
          s.overriddenCurrency,
        ),
        showCorrectionCost: +supplierTotals?.[sIdx]?.supplierTotalValue > 0,
        costAfterCorrection: formatCurrency(
          supplierTotals?.[sIdx]?.supplierTotalValue,
          s.overriddenCurrency,
        ),
      })),
    };
  }, [projectToSuppliers, allocationMultiplier, supplierTotals]);

  const { updatedSupplierCost } = React.useMemo(() => {
    return {
      updatedSupplierCost: map(supplierTotals, (st, sIdx) => ({
        ...st,
        cost: formatCurrency(st.rawSupplierCost, st.overriddenCurrency),
        showCorrectionCost: +supplierTotals?.[sIdx]?.supplierTotalValue > 0,
        costAfterCorrection: formatCurrency(
          supplierTotals?.[sIdx]?.supplierTotalValue,
          st.overriddenCurrency,
        ),
      })),
    };
  }, [supplierTotals]);

  React.useEffect(() => {
    return () => clearCorrectionsTotals();
  }, []);

  const filteredSuppliers = filter(
    isRetainerOrTAForFixedPrice(project) ? updatedSupplierCost : supplierCosts,
    s => !isEmpty(s?.supplier),
  );

  const currencyRates = React.useMemo(() => {
    const usdToGbp = find(
      exchangeRates,
      rate => rate.sourceCurrency === 'GBP' && rate.destCurrency === 'USD',
    );
    const eurToGbp = find(
      exchangeRates,
      rate => rate.sourceCurrency === 'GBP' && rate.destCurrency === 'EUR',
    );
    return {
      eurToGbp: +eurToGbp?.rate,
      usdToGbp: +usdToGbp?.rate,
      usdToEur: +eurToGbp?.rate / usdToGbp?.rate,
    };
  }, [exchangeRates]);

  const caclulateCurrencyRateFactor = React.useCallback(
    (projectCurrency, dpCurrency) => {
      if (lowerCase(dpCurrency) === lowerCase(projectCurrency)) {
        return 1;
      }
      if (lowerCase(projectCurrency) === 'usd') {
        if (lowerCase(dpCurrency) === 'eur') {
          return currencyRates.usdToEur;
        }
        if (lowerCase(dpCurrency) === 'gbp') {
          return 1 / currencyRates.usdToGbp;
        }
      }
      if (lowerCase(projectCurrency) === 'eur') {
        if (lowerCase(dpCurrency) === 'usd') {
          return 1 / currencyRates.usdToEur;
        }
        if (lowerCase(dpCurrency) === 'gbp') {
          return 1 / currencyRates.eurToGbp;
        }
      }
      if (lowerCase(projectCurrency) === 'gbp') {
        if (lowerCase(dpCurrency) === 'usd') {
          return currencyRates.usdToGbp;
        }
        if (lowerCase(dpCurrency) === 'eur') {
          return currencyRates.eurToGbp;
        }
      }
      return 1;
    },
    [currencyRates],
  );

  const { marginInGbp, marginInProjectCurrency } = React.useMemo(() => {
    const totalInProjectCurrency =
      clientTotal -
      reduce(
        filteredSuppliers,
        (acc, s, sIdx) => {
          const costValue = s.showCorrectionCost
            ? supplierTotals?.[sIdx]?.supplierTotalValue
            : s.cost;
          const currencyRateFactor = caclulateCurrencyRateFactor(
            project.currency,
            supplierTotals?.[sIdx]?.overriddenCurrency,
          );
          const costValueWithCurrencyRate =
            project.currency === supplierTotals?.[sIdx]?.overriddenCurrency
              ? costValue
              : costValue * currencyRateFactor;
          return acc + costValueWithCurrencyRate;
        },
        0,
      );
    if (project.deazyAsClient) {
      return { marginInGbp: 0 };
    }
    if (project.deazyAsSupplier) {
      return { marginInGbp: clientTotal };
    }
    return {
      marginInGbp:
        caclulateCurrencyRateFactor('gbp', project.currency) *
        totalInProjectCurrency,
      marginInProjectCurrency: totalInProjectCurrency,
    };
  }, [
    caclulateCurrencyRateFactor,
    filteredSuppliers,
    clientTotal,
    project.currency,
    project.deazyAsClient,
    project.deazyAsSupplier,
  ]);

  const marginPercent = React.useMemo(() => {
    if (project.deazyAsClient) {
      return 0;
    }
    if (project.deazyAsSupplier) {
      return 100;
    }
    return 100 * (1 - (clientTotal - marginInProjectCurrency) / clientTotal);
  }, [
    clientTotal,
    marginInProjectCurrency,
    project.deazyAsClient,
    project.deazyAsSupplier,
  ]);

  return (
    <Box w="100%" p="24px" mt="40px" mx="auto" borderRadius="8px" bg="#fff">
      <Typography variant="h3" mb="24px">
        Total interim project value
      </Typography>
      <Flex w="100%" alignItems="flex-start" flexDir="column">
        {!isEmpty(filteredSuppliers) && (
          <Flex
            w="100%"
            flexWrap="wrap"
            bg="blue.light"
            borderRadius="8px"
            border="1px solid"
            borderColor="dark.mid"
            flexDir="column"
            p="12px"
            mb="16px"
          >
            <Typography variant="h4" mb="16px">
              Delivery Partners
            </Typography>
            <ProjectSummaryHeadings />
            {map(filteredSuppliers, (s, idx) => (
              <ProjectSummaryRow
                key={`project-summary-${s.id}`}
                name={s?.supplier?.name}
                isLoadingTotals={isLoadingTotals}
                subtotal={s.cost}
                correctionPercentFieldName={`projectToSuppliers[${idx}].supplierCorrectionPercent`}
                correctionTotalFieldName={`projectToSuppliers[${idx}].supplierCorrectionAbsolute`}
                total={s.showCorrectionCost ? s?.costAfterCorrection : s?.cost}
                mainNameQA={mainNameQA}
              />
            ))}
          </Flex>
        )}
        {!project.deazyAsClient && (
          <Flex
            w="100%"
            flexWrap="wrap"
            bg="blue.light"
            borderRadius="8px"
            border="1px solid"
            borderColor="dark.mid"
            flexDir="column"
            p="12px"
          >
            <Typography variant="h4" mb="16px">
              Client
            </Typography>
            <ProjectSummaryHeadings />
            <ProjectSummaryRow
              isClient
              name={project?.client?.name || '-'}
              total={formatCurrency(
                isFixedPrice && clientTotal ? clientTotal : client,
                currency,
              )}
              isLoadingTotals={isLoadingTotals}
              subtotal={formatCurrency(
                isFixedPrice && clientSubtotal ? clientSubtotal : client,
                currency,
              )}
              mainNameQA={mainNameQA}
              correctionPercentFieldName="clientCorrectionPercent"
              correctionTotalFieldName="clientCorrectionAbsolute"
            />
          </Flex>
        )}
      </Flex>
      <Flex justifyContent="flex-end" w="100%" mt="24px">
        <Flex mr="24px" py="16px" px="12px" bg="blue.light" borderRadius="8px">
          <Typography as="span" mr="8px">
            Margin Amount:
          </Typography>
          <Typography
            as="span"
            fontWeight={600}
            data-test-id={`${MAIN_NAMES_QA.PROJECT_CREATE}--marginAmount`}
          >
            {isLoadingTotals ? (
              <Spinner variant="small" w="14px" h="14px" />
            ) : (
              formatCurrency(marginInGbp, 'GBP')
            )}
          </Typography>
        </Flex>
        <Flex
          bg="green.light"
          borderRadius="8px"
          border="1px solid"
          py="16px"
          px="12px"
          borderColor="green.solid"
          minW="310px"
        >
          <Typography as="span" mr="8px">
            Margin %:
          </Typography>
          <Typography
            as="span"
            fontWeight={600}
            data-test-id={`${MAIN_NAMES_QA.PROJECT_CREATE}--marginPercent`}
          >
            {isLoadingTotals ? (
              <Spinner variant="small" w="14px" h="14px" />
            ) : (
              formatNumber(marginPercent)
            )}
          </Typography>
        </Flex>
      </Flex>
    </Box>
  );
};

export default inject(
  'projectsStore',
  'settingsStore',
)(observer(ProjectSummary));
