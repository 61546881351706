import Modal from '@components/Modal';
import Spinner from '@styles/Spinner';
import { isEmpty } from 'lodash-es';
import Typography from '@styles/Typography';
import React from 'react';
import ProjectPaymentForm from './ProjectPaymentForm';

const ProjectPaymentModal = ({
  createProjectPayment,
  project,
  isOpen,
  onClose,
  initialPaymentData,
  mainNameQA,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} styleProps={{ maxW: '1556px' }}>
      <Typography variant="h2" mb="32px">
        Create project invoice
      </Typography>
      {isEmpty(initialPaymentData) ? (
        <Spinner variant="large" />
      ) : (
        <ProjectPaymentForm
          mainNameQA={mainNameQA}
          onClose={onClose}
          project={project}
          onSubmit={values => createProjectPayment(values, onClose)}
          initialPaymentData={initialPaymentData}
        />
      )}
    </Modal>
  );
};

export default ProjectPaymentModal;
