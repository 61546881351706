import { TOAST_STYLE_TYPES } from '@app/constants';
import { Flex, useToast } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { has, isEmpty } from 'lodash-es';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import RequestErrorToast from './toasts/RequestErrorToast';

const VARIANTS = {
  success: {
    bg: 'green.light',
    borderColor: 'green.solid',
  },
  warning: {
    bg: 'orange.light',
    borderColor: 'orange.solid',
  },
  info: {
    bg: 'primary.100',
    borderColor: 'primary.500',
  },
  error: {
    bg: 'red.light',
    borderColor: 'red.500',
  },
};

const BASIC_STYLES = {
  minHeight: '56px',
  borderRadius: '8px',
  padding: '18px',
};

const DEFAULT_TOAST_PROPERTIES = {
  position: 'top',
  duration: 5000,
  isClosable: true,
};

const getIconName = status => {
  if (status === 'error' || status === 'warning') {
    return 'error';
  }
  return status;
};

const ToastsContainer = ({ toastsStore }) => {
  const showToast = useToast();
  const { toast, requestError } = toastsStore;

  useEffect(() => {
    if (!isEmpty(toast)) {
      showToast({
        ...DEFAULT_TOAST_PROPERTIES,
        ...toast,
        render: ({ onClose }) => {
          const styles = has(VARIANTS, toast.status)
            ? VARIANTS[toast.status]
            : VARIANTS.error;
          return (
            <Flex
              alignItems="flex-start"
              mt="12px"
              border={
                toast.toastStyleType === TOAST_STYLE_TYPES.DEFAULT
                  ? '1px solid'
                  : 'none'
              }
              {...BASIC_STYLES}
              {...styles}
            >
              {toast.toastStyleType !== TOAST_STYLE_TYPES.BASIC && (
                <Icon
                  fontSize={20}
                  name={getIconName(toast.status)}
                  color={VARIANTS[toast.status].borderColor}
                  marginRight="14px"
                />
              )}
              <Typography>{toast.title}</Typography>
              {DEFAULT_TOAST_PROPERTIES.isClosable &&
                toast.toastStyleType === TOAST_STYLE_TYPES.DEFAULT && (
                  <Button
                    iconButton={{ name: 'close' }}
                    height="16px"
                    marginLeft="auto"
                    p="0"
                    width="16px"
                    background="transparent"
                    color="black"
                    mt="2px"
                    fontSize="24px"
                    aria-label="close"
                    onClick={onClose}
                    _hover={{ bg: 'transparent' }}
                    _active={{ bg: 'transparent' }}
                  />
                )}
            </Flex>
          );
        },
      });
    }
  }, [JSON.stringify(toJS(toast))]);

  useEffect(() => {
    if (!isEmpty(requestError)) {
      showToast({
        position: 'top',
        duration: 10000,
        render: props => (
          <RequestErrorToast {...props} errorObj={requestError} />
        ),
      });
    }
  }, [JSON.stringify(toJS(requestError))]);

  return null;
};

export default inject('toastsStore')(observer(ToastsContainer));
