import { API } from '@app/api';
import { Avatar, Box, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import TextInput from '@components/forms/_common/TextInput';
import Icon from '@components/Icon';
import Popover from '@components/Popover';
import useMediaQuery from '@hooks/useMediaQuery';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { find, findIndex, isEmpty, map, uniqBy } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';

const DropdownOption = ({
  icon,
  isMe,
  isFocused,
  isDisabled,
  isSelected,
  children,
  photo,
  ...props
}) => {
  const ref = React.useRef();

  React.useEffect(() => {
    if (isFocused && ref && ref.current) {
      ref.current.scrollIntoViewIfNeeded();
    }
  }, [isFocused]);

  return (
    <Flex
      ref={ref}
      h="40px"
      {...(isFocused && { bg: 'primary.200', textDecoration: 'none' })}
      _active={{
        pointerEvents: isDisabled || isSelected ? 'none' : 'default',
      }}
      {...(isSelected && { bg: 'primary.300' })}
      py="9px"
      px="12px"
      borderRadius="8px"
      cursor="pointer"
      zIndex="100"
      opacity={isDisabled ? '0.4' : '1'}
      alignItems="center"
      {...props}
    >
      <Avatar name={children} src={photo} size="xs" mr="8px" />
      <Typography mt="2px" as="span">
        {children}
      </Typography>
      {isMe && (
        <Typography mt="2px" as="span" color="gray.300" ml="8px">
          (You)
        </Typography>
      )}
    </Flex>
  );
};

let to = null;

const AssignmentDropdown = ({
  children,
  onChooseOption,
  value,
  toastsStore,
  usersStore: { profile },
  placement = 'bottom',
  popoverStyleProps,
}) => {
  const inputRef = React.useRef(null);
  const triggerRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [options, setOptions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState();
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const fetchOptions = async (phrase = '') => {
    try {
      setIsLoading(true);
      const { data } = await API.searchDLsAndAdmins(phrase);
      if (!isEmpty(data)) {
        setOptions(uniqBy([{ ...profile, isMe: true }, ...data], 'id'));
      } else {
        setOptions([]);
      }
    } catch {
      toastsStore.showError({
        title: 'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };
  const checkKey = e => {
    e = e || window.event;
    if (isEmpty(options)) {
      return;
    }
    const positionedIndex = findIndex(options, opt => opt.isFocused);
    const opts = [...options];
    if (e.keyCode === 38) {
      // up arrow
      e.preventDefault();
      if (positionedIndex < 0) {
        opts[0].isFocused = true;
        setOptions(opts);
      } else if (positionedIndex !== 0) {
        setOptions(
          map(opts, (opt, idx) => ({
            ...opt,
            isFocused: idx === positionedIndex - 1,
          })),
        );
      }
    } else if (e.keyCode === 40) {
      // down arrow
      e.preventDefault();
      if (positionedIndex < 0) {
        opts[0].isFocused = true;
        setOptions(opts);
      } else if (positionedIndex < options.length - 1) {
        setOptions(
          map(opts, (opt, idx) => ({
            ...opt,
            isFocused: idx === positionedIndex + 1,
          })),
        );
      }
    } else if (e.keyCode === 13) {
      // enter
      const focusedValue = find(options, opt => opt.isFocused);
      if (focusedValue) {
        onChooseOption(focusedValue);
        onClose();
      }
    }
  };

  React.useEffect(() => {
    document.onkeydown = null;
    if (!isEmpty(options)) {
      document.onkeydown = checkKey;
    }
    return () => {
      document.onkeydown = null;
    };
  }, [options]);

  const setIsFocused = optId => {
    setOptions(map(options, opt => ({ ...opt, isFocused: optId === opt.id })));
  };

  const popoverProps = {
    placement,
    isOpen,
    onOpen: () => fetchOptions(),
    onClose: () => setInputValue(''),
    initialFocusRef: inputRef,
    trigger: children({
      isOpen,
      currentValue: value?.isMe
        ? 'You'
        : value?.name || value?.fullname || 'Unassigned',
      onOpen,
      ref: triggerRef,
    }),
    ...(isSmallerScreen && {
      withOverlay: true,
      variant: 'responsive',
      overlayOffsetTop: 119,
      offset: popoverStyleProps.offset,
    }),
    contentStyles: { minW: '350px' },
  };

  return (
    <Popover {...popoverProps}>
      <Box position="relative" mb="8px">
        <Icon
          name="search"
          color="gray.500"
          fontSize={18}
          position="absolute"
          left="10px"
          zIndex={3000}
          top="15px"
        />
        <TextInput
          ref={inputRef}
          input={{
            value: inputValue,
            onChange: v => {
              setInputValue(v);
              clearTimeout(to);
              to = setTimeout(() => {
                fetchOptions(v);
              }, 500);
            },
            onBlur: () => {
              // without timeout option click sometimes doesnt trigger
              setTimeout(() => {
                onClose();
              }, 150);
            },
          }}
          placeholder="Search"
          pl="40px"
        />
      </Box>
      {isLoading ? (
        <Spinner variant="small" my="16px" />
      ) : (
        <Stack spacing="0px" maxH="240px" overflowY="scroll">
          {isEmpty(options) ? (
            <Typography my="20px" mx="auto" variant="h5">
              No users found
            </Typography>
          ) : (
            <>
              {map(options, opt => (
                <DropdownOption
                  isMe={opt.isMe}
                  data-test-id={opt.id}
                  isSelected={opt.id === value?.id}
                  onMouseEnter={() => {
                    setIsFocused(opt.id);
                  }}
                  isDisabled={opt.isDisabled}
                  isFocused={opt.isFocused}
                  key={`dropdown-status-key-${opt.id}`}
                  onClick={() => {
                    onChooseOption(opt);
                    setInputValue('');
                    onClose();
                  }}
                >
                  {opt.name || opt.fullname}
                </DropdownOption>
              ))}
            </>
          )}
        </Stack>
      )}
    </Popover>
  );
};

export default inject('usersStore')(observer(AssignmentDropdown));
