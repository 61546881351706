import { NEW_ROLES } from '@app/constants';
import Modal from '@components/Modal';
import { APP_ROUTES } from '@routes';
import Typography from '@styles/Typography';
import React from 'react';
import { useLocation } from 'react-router-dom';
import AddToGroupForm from './AddToGroupForm';

const AddToGroupModal = ({
  isOpen,
  onClose,
  fetch,
  addUserToGroupByRole,
  isClientLeadRoute,
  isTeamAdminRoute,
  isTeamMemberRoute,
  isProjectManagerRoute,
  groupTitle,
}) => {
  const location = useLocation();

  const setRole = value => {
    if (value.includes(APP_ROUTES.users.admins)) {
      return 'Admin';
    }
    if (value.includes(APP_ROUTES.users.clientLeads)) {
      return 'CL';
    }
    if (value.includes(APP_ROUTES.users.teamMembers)) {
      return NEW_ROLES.TEAM_MEMBER;
    }
    if (value.includes(APP_ROUTES.users.teamAdmins)) {
      return NEW_ROLES.TEAM_ADMIN;
    }
    if (value.includes(APP_ROUTES.users.projectManagers)) {
      return NEW_ROLES.DEAZY_LEAD;
    }
    return undefined;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2" mb="32px">
        Add user to {groupTitle}
      </Typography>
      <AddToGroupForm
        onClose={onClose}
        isClientLeadRoute={isClientLeadRoute}
        isTeamMemberRoute={isTeamMemberRoute}
        isTeamAdminRoute={isTeamAdminRoute}
        isProjectManagerRoute={isProjectManagerRoute}
        onSubmit={values => {
          if (values.supplier) {
            return addUserToGroupByRole({
              memberId: values.user.id,
              role: setRole(location.pathname),
              supplier: values.supplier,
              onSuccess: () => {
                fetch();
                onClose();
              },
            });
          }
          if (values.client) {
            return addUserToGroupByRole({
              memberId: values.user.id,
              role: setRole(location.pathname),
              client: values.client,
              onSuccess: () => {
                fetch();
                onClose();
              },
            });
          }
          if (values.rate) {
            return addUserToGroupByRole({
              memberId: values.user.id,
              role: setRole(location.pathname),
              rate: values.rate,
              onSuccess: () => {
                fetch();
                onClose();
              },
            });
          }
          return addUserToGroupByRole({
            memberId: values.user.id,
            role: setRole(location.pathname),
            onSuccess: () => {
              fetch();
              onClose();
            },
          });
        }}
      />
    </Modal>
  );
};

export default AddToGroupModal;
