import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { Form, Field } from 'react-final-form';
import Textarea from '@components/forms/Textarea';
import {
  composeValidators,
  mustBeShorterOrEqualTrick,
  required,
} from '@utils/formValidators';
import Button from '@styles/Button';

const PaymentNoteForm = ({
  onSubmit,
  onClose,
  isRejectInvoiceOpened,
  mainNameQA,
}) => {
  return (
    <Box mb="24px">
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Box>
              <Box>
                <Field
                  name={isRejectInvoiceOpened ? 'reason' : 'note'}
                  data-test-id={`${mainNameQA}--${
                    isRejectInvoiceOpened ? 'reason' : 'note'
                  }`}
                  placeholder={
                    isRejectInvoiceOpened
                      ? 'Add reason for rejection here'
                      : 'Add a new note here'
                  }
                  component={Textarea}
                  validate={composeValidators(
                    required,
                    mustBeShorterOrEqualTrick(9950),
                  )}
                  mt="24px"
                  inputProps={{ borderColor: 'dark.mid', borderWidth: '1px' }}
                />
              </Box>
              <Flex mt="24px">
                <Button
                  mr="12px"
                  isLoading={submitting}
                  type="submit"
                  data-test-id={`${mainNameQA}--submit`}
                >
                  {isRejectInvoiceOpened ? 'Submit reason' : 'Submit note'}
                </Button>
                <Button
                  variant="outline"
                  onClick={onClose}
                  isDisabled={submitting}
                  data-test-id={`${mainNameQA}--cancel`}
                >
                  Cancel
                </Button>
              </Flex>
            </Box>
          </form>
        )}
      />
    </Box>
  );
};

export default PaymentNoteForm;
