import { Box, Button, Image } from '@chakra-ui/react';
import Icon from '@components/Icon';
import { APP_ROUTES } from '@routes';
import Typography from '@styles/Typography';
import { map } from 'lodash-es';
import React from 'react';
import { Link } from 'react-router-dom';

const Arrow = () => (
  <Icon
    name="arrowLeft"
    color="aqua.solid"
    fontSize={24}
    transform="rotate(180deg)"
    mr="15px"
  />
);

const getArchivedListReasons = ({ isTeamAdmin, isClient }) => {
  if (isTeamAdmin || isClient) {
    return [
      'They have been completed',
      'The project has been manually archived by Deazy',
    ];
  }
  return [
    'They have been completed',
    'The project has been manually archived by you',
  ];
};
const getActiveListReasons = ({ isClient }) => {
  if (isClient) {
    return [
      'Current Project Status',
      'Project Timelines',
      'Any Actions for you.',
    ];
  }
  return [
    'Currently in progress',
    'Projects you are sole or joint delivery partner on',
    'They may or may not require actions from you',
  ];
};

const ProjectsEmpty = ({ status, isClient, isTeamAdmin, isAdminOrDL }) => {
  if (status === 'pending') {
    return (
      <Box mx="auto">
        <Image w="auto" src="/images/typing-bg.png" />
        <Typography variant="h4" mt="32px" textAlign="center">
          Any new projects that have not yet been signed off will appear here
        </Typography>
      </Box>
    );
  }
  if (status === 'archived') {
    return (
      <Box py="44px" maxW="671px" w="100%">
        <Typography variant="h1" mb="40px">
          There are no projects here yet.
        </Typography>
        <Box
          maxW="671px"
          w="100%"
          padding="32px 40px 40px 40px"
          borderRadius="8px"
          borderColor="dark.mid"
          borderStyle="dashed"
          borderWidth="2px"
        >
          <Box maxW="480px">
            <Typography variant="h3" mb="16px">
              Why archived?
            </Typography>
            <Typography mb="24px">Projects are achived when:</Typography>
            <Box as="ul">
              {map(getArchivedListReasons({ isTeamAdmin, isClient }), item => (
                <Typography as="li" fontFamily="WB" mb="8px" key={item}>
                  <Arrow />
                  {item}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Box py="44px" maxW="671px" w="100%">
      <Typography variant="h1" mb="40px" maxW={isClient ? '480px' : '671px'}>
        {isClient
          ? 'You don’t have any active projects at the moment.'
          : 'There are no projects here yet.'}
      </Typography>
      <Box
        maxW="671px"
        w="100%"
        padding="32px 40px 40px 40px"
        borderRadius="8px"
        borderColor="dark.mid"
        borderStyle="dashed"
        borderWidth="2px"
      >
        <Box maxW="520px">
          <Typography variant="h3" mb="16px">
            {isClient
              ? 'Once you’ve created a brief and accepted our proposal you new project will appear here.'
              : 'Active projects'}
          </Typography>
          {isClient && (
            <Typography mb="24px">
              The projects tab allows you to keep track of all of your projects
              in once place.
            </Typography>
          )}
          <Typography mb="24px">
            {isClient ? 'It will display:' : 'Active projects are:'}
          </Typography>

          <Box as="ul">
            {map(getActiveListReasons({ isClient }), item => (
              <Typography as="li" fontFamily="WM" mb="8px" key={item}>
                <Arrow />
                {item}
              </Typography>
            ))}
          </Box>
          {isAdminOrDL && (
            <Typography lineHeight={1.4} mt="30px">
              You can also create briefs on behalf of a client.
            </Typography>
          )}
          {isAdminOrDL && (
            <Button
              mt="30px"
              leftIcon={<Icon name="add" mb="2px" mr="13px" fontSize={14} />}
              bg="aqua.solid"
              fontWeight="400"
              color="#fff"
              py="18px"
              px="20px"
              variant="solid"
              fontSize={18}
              h="auto"
              borderRadius="30px"
              as={Link}
              to={APP_ROUTES.projectsCreate}
            >
              Create a new project
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectsEmpty;
