import { ENGAGEMENT_TYPES, PROJECT_TYPES } from '@app/constants';
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import JsxParser from 'react-jsx-parser';
import {
  CONTINUOUS_IMPROVEMENT_CLIENT_SOW_INSTRUCTIONS,
  CONTINUOUS_IMPROVEMENT_SUPPLIER_SOW_INSTRUCTIONS,
  DEFAULT_CLIENT_SOW_INSTRUCTIONS,
  DEFAULT_SUPPLIER_SOW_INSTRUCTIONS,
  FIXED_PRICE_CLIENT_SOW_INSTRUCTIONS,
  FIXED_PRICE_SUPPLIER_SOW_INSTRUCTIONS,
} from './constants';
// import ReferenceButton from './ReferenceButton';

export const BoldSpan = ({ children }) => (
  <Box as="span" fontFamily="WB">
    {children}
  </Box>
);

export const YellowHighlight = ({ children }) => (
  <Box as="span" bg="#feff00">
    {children}
  </Box>
);

export const StyledList = styled('ol')`
  display: flex;
  justify-content: space-between;
  list-style: none;
  counter-reset: my-counter;
  list-style-position: outside;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  margin-bottom: 32px;

  li {
    counter-increment: my-counter;
    font-size: 14px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &::before {
      content: counter(my-counter) '. ';
      color: ${({ theme }) => theme.colors.blue.solid};
      font-family: ${({ theme }) => theme.fonts.WM};
      font-size: 14px;
      min-width: 24px;
    }
  }
`;

export const StyledListItem = styled('li')`
  display: flex;
  align-items: baseline;
  font-family: ${({ theme }) => theme.fonts.WS};
`;

const SowInstructions = ({ isClientTab, projectsStore: { project } }) => {
  const {
    leftSideItems,
    rightSideItems,
    // referenceLink = '',
  } = React.useMemo(() => {
    if (!project?.id) {
      return {};
    }
    const isFixedPriceProjectType =
      project.projectType === PROJECT_TYPES.FIXED_PRICE &&
      project.engagementType === ENGAGEMENT_TYPES.PROJECT_BUILD;

    const isContinuousImprovementProjectType =
      project.engagementType === ENGAGEMENT_TYPES.RETAINED_TEAM;

    if (isFixedPriceProjectType) {
      if (isClientTab) {
        return FIXED_PRICE_CLIENT_SOW_INSTRUCTIONS;
      }
      return FIXED_PRICE_SUPPLIER_SOW_INSTRUCTIONS;
    }
    if (isContinuousImprovementProjectType) {
      if (isClientTab) {
        return CONTINUOUS_IMPROVEMENT_CLIENT_SOW_INSTRUCTIONS;
      }
      return CONTINUOUS_IMPROVEMENT_SUPPLIER_SOW_INSTRUCTIONS;
    }
    return isClientTab
      ? DEFAULT_CLIENT_SOW_INSTRUCTIONS
      : DEFAULT_SUPPLIER_SOW_INSTRUCTIONS;
  }, [project, isClientTab]);

  return (
    <>
      <StyledList>
        <Box mr="80px" width="50%">
          {map(leftSideItems, (item, idx) => (
            <StyledListItem key={`left-side-key-${idx}`}>
              <JsxParser
                jsx={item}
                components={{ BoldSpan, YellowHighlight }}
              />
            </StyledListItem>
          ))}
        </Box>
        <Box mr="80px" width="50%">
          {map(rightSideItems, (item, idx) => (
            <StyledListItem key={`right-side-key-${idx}`}>
              <JsxParser
                jsx={item}
                components={{ BoldSpan, YellowHighlight }}
              />
            </StyledListItem>
          ))}
        </Box>
      </StyledList>
      {/* <ReferenceButton referenceLink={referenceLink} /> */}
    </>
  );
};

export default inject('projectsStore')(observer(SowInstructions));
