/* eslint-disable no-underscore-dangle */
import { API } from '@app/api';
import { Alert, AlertIcon, Flex, Spinner } from '@chakra-ui/react';
import useQuery from '@hooks/useQuery';
import Typography from '@styles/Typography';
import { filter, map, uniqBy } from 'lodash-es';
import moment from 'moment';
import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import Select from './Select';

const ActualsWeekPicker = ({ project, missingActualsData }) => {
  return (
    <FormSpy subsription={{ values: true }}>
      {({ values, form }) => {
        const yearsOptions = map(
          uniqBy(missingActualsData, date => date.year),
          date => ({ label: date.year, value: date.year }),
        );
        const monthsOptions = map(
          uniqBy(
            filter(missingActualsData, { year: values.year }),
            date => date.month,
          ),
          date => ({ label: date.month, value: date.month }),
        );
        const weeksOptions = map(
          uniqBy(
            filter(missingActualsData, {
              year: values.year,
              month: values.month,
            }),
            date => date.week,
          ),
          date => ({ label: date.week, value: date.week }),
        );

        const actualsDetails = {
          year: values.year,
          month: values.month,
          week: values.week,
        };

        const { data, isLoading } = useQuery(
          () =>
            API.getActualsWeekInfo({
              projectId: project.id,
              ...actualsDetails,
            }),
          actualsDetails,
        );
        const { endDate, numberOfDays, startDate } = data;

        const allFieldsSet = !!values.year && !!values.month && !!values.week;

        return (
          <>
            {!allFieldsSet && (
              <Typography variant="h3" mb="20px">
                {isLoading ? (
                  <Spinner />
                ) : (
                  `${moment
                    .utc(startDate)
                    .format('dddd, Do MMMM')} - ${moment
                    .utc(endDate)
                    .format('dddd, Do MMMM')}`
                )}
              </Typography>
            )}
            <Typography variant="h4" mb="20px">
              Choose period:
            </Typography>
            <Flex alignItems="flex-end">
              <Field
                label="Year"
                styleProps={{ maxW: '100px', mr: '16px' }}
                name="year"
                component={Select}
                onAfterChange={() => {
                  form.change('month', null);
                  form.change('week', null);
                }}
                options={yearsOptions}
              />
              <Field
                label="Month"
                styleProps={{ maxW: '100px', mr: '16px' }}
                name="month"
                key={`month-period-key-${values.year}`}
                onAfterChange={() => {
                  form.change('week', null);
                }}
                component={Select}
                options={monthsOptions}
              />
              <Field
                label="Week"
                key={`week-period-key-${values.month}`}
                name="week"
                styleProps={{ maxW: '100px', mr: '24px' }}
                component={Select}
                options={weeksOptions}
              />
              {allFieldsSet && !isLoading && (
                <>
                  {numberOfDays < 5 ? (
                    <Alert
                      status="error"
                      fontFamily="WB"
                      borderRadius="8px"
                      h="48px"
                    >
                      <AlertIcon />
                      {`NOTE: You are entering actuals for ${numberOfDays} / 5 bussines days`}
                    </Alert>
                  ) : (
                    <Alert
                      status="info"
                      fontFamily="WB"
                      borderRadius="8px"
                      h="48px"
                    >
                      <AlertIcon />
                      You are entering actuals for a full week
                    </Alert>
                  )}
                </>
              )}
            </Flex>
          </>
        );
      }}
    </FormSpy>
  );
};

export default ActualsWeekPicker;
