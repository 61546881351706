import React from 'react';
import StatusDropdown from '@components/StatusDropdown';
import { inject, observer } from 'mobx-react';

const greenProps = {
  borderColor: 'green.500',
  bg: 'green.light',
  color: 'green.500',
};

const amberProps = {
  bg: 'yellow.lightSecondary',
  color: 'yellow.solid',
  borderColor: 'yellow.solid',
};

const redProps = {
  color: 'red.500',
  borderColor: 'red.500',
  bg: 'red.light',
};

const MilestoneStatusDropdown = ({
  milestonesStore: {
    updatedMilestone,
    overrideProjectStatus,
    projectStatusFromReport,
  },
  isRiskDropdown = false,
  projectsStore: {
    fetchProject,
    project: { id: projectId, riskLevel: projectRiskLevel },
    setProjectRiskProfile,
    project,
  },
  usersStore: { isAdminOrDL },
  mainNameQA,
}) => {
  const [riskLevel, setRiskLevel] = React.useState(projectRiskLevel);

  const currentStatus = React.useMemo(() => {
    if (isRiskDropdown) {
      return riskLevel;
    }
    return (
      updatedMilestone.report?.project.overriddenProjectStatus ||
      updatedMilestone.report?.project.calculatedProjectStatus ||
      projectStatusFromReport
    );
  }, [
    projectStatusFromReport,
    updatedMilestone,
    project,
    isRiskDropdown,
    projectRiskLevel,
    riskLevel,
  ]);

  const options = React.useMemo(() => {
    if (isRiskDropdown) {
      return [
        { label: 'Standard', value: 'Standard', ...greenProps },
        { label: 'High', value: 'High', ...redProps },
      ];
    }
    return [
      {
        label: 'Green',
        value: 'Green',
        ...greenProps,
      },
      {
        label: 'Amber',
        value: 'Amber',
        ...amberProps,
      },
      {
        label: 'Red',
        value: 'Red',
        ...redProps,
      },
    ];
  }, [isRiskDropdown]);

  const onChange = React.useMemo(() => {
    if (isRiskDropdown) {
      return risk => {
        const oldRiskLevel = riskLevel;
        setRiskLevel(risk);
        setProjectRiskProfile(project.id, risk, () => {
          setRiskLevel(oldRiskLevel);
        });
      };
    }
    return status =>
      overrideProjectStatus(projectId, { status }, () => {
        fetchProject({ projectId });
      });
  }, []);

  return (
    <StatusDropdown
      options={options}
      label={isRiskDropdown ? 'Risk profile' : 'Project status'}
      onChange={onChange}
      currentValue={currentStatus}
      isReadOnly={!isAdminOrDL}
      withRevertLabels
      mainNameQA={mainNameQA}
      qaValue={`${isRiskDropdown ? 'riskProfile' : 'projectStatus'}`}
    />
  );
};

export default inject(
  'projectsStore',
  'usersStore',
  'milestonesStore',
)(observer(MilestoneStatusDropdown));
