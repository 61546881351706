import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Textarea from '@components/forms/_common/Textarea';
import { Field, Form } from 'react-final-form';
import {
  composeValidators,
  mustBeShorterOrEqual,
  required,
} from '@utils/formValidators';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';

const QueryCandidateModal = ({ isOpen, onClose, onSubmit }) => {
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styleProps={
        isSmallerScreen && {
          minW: 'auto',
          margin: 'auto 0px 0px',
          p: '24px 16px 48px',
        }
      }
    >
      <Form
        onSubmit={onSubmit}
        subscription={{
          submitError: true,
          submitting: true,
        }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Typography variant="h2" mb="24px" textAlign="center">
                Query proposal
              </Typography>
              <Typography mb="24px" textAlign="center" fontSize="15px">
                Let us know what concerns you have with this candidate and we’ll
                inform the delivery partner.
              </Typography>

              <Typography textAlign="center" fontSize="15px" mb="24px">
                Once queried, all roles from this delivery partner will be
                marked as ‘With Delivery Partner’ and will become inactive until
                the role is re-sumbitted by Delivery Partner
              </Typography>
              <Field
                component={Textarea}
                name="query"
                rows={5}
                counter={500}
                validate={composeValidators(
                  required,
                  mustBeShorterOrEqual(500),
                )}
                placeholder="Add a note here"
                maxW="488px"
                mb="56px"
                mx="auto"
              />
              <Stack spacing={3}>
                <Button h="44px" type="submit" isLoading={submitting}>
                  Send query to delivery partner
                </Button>
                <Button
                  h="44px"
                  onClick={onClose}
                  variant="outline"
                  isDisabled={submitting}
                >
                  Cancel
                </Button>
              </Stack>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default QueryCandidateModal;
