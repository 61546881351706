import { Box } from '@chakra-ui/react';
import Icon from '@components/Icon';
import styled from '@emotion/styled';
import Spinner from '@styles/Spinner';
import {
  COLORS,
  FONTS,
  getInputVariantProps,
  INPUT_VARIANTS,
} from '@styles/theme';
import Typography from '@styles/Typography';
import { find } from 'lodash-es';
import React from 'react';
import ReactSelect, { components } from 'react-select';
import FormLabel from '../FormLabel';
import FormError from './FormError';

const CreatedAtLabel = ({ children, ...props }) => (
  <Typography as="span" color="gray.paleSlate" {...props}>
    {children}
  </Typography>
);

const Option = ({ children, ...props }) => {
  const {
    data,
    selectProps: { addCreatedAtLabel },
  } = props;
  return (
    <components.Option {...props}>
      <span data-test-id={data.value} />
      {children}
      {addCreatedAtLabel && (
        <CreatedAtLabel ml="auto">{data.createdAt}</CreatedAtLabel>
      )}
    </components.Option>
  );
};

const Control = ({ children, ...props }) => {
  const {
    options,
    selectProps: { leftIcon, addCreatedAtLabel },
    getValue,
  } = props;
  const value = getValue();
  const option = find(options, opt => opt?.value === value[0]?.value) || {};
  return (
    <components.Control {...props}>
      {leftIcon || null}
      {children?.[0] || null}
      {addCreatedAtLabel && (
        <CreatedAtLabel mr="12px">{option.createdAt}</CreatedAtLabel>
      )}
      {children?.[1] || null}
    </components.Control>
  );
};

export const Styles = styled(({ hasError, ...props }) => <Box {...props} />, {
  shouldForwardProp: prop =>
    ![
      'getOptionsFn',
      'isValidNewOption',
      'onCreateOption',
      'customOptionMap',
      'reloadOnEmpty',
      'isDisabled',
    ].includes(prop),
})`
  .select__control {
    position: relative;
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid
      ${({ hasError, theme }) =>
        hasError ? theme.colors.red.solid : theme.colors.dark.mid} !important;
    cursor: ${({ cursor = 'text' }) => cursor};
    :hover {
      border: 2px solid
        ${({ hasError, theme }) =>
          hasError ? theme.colors.red.solid : 'transparent'};
    }
  }
  .select__value-container,
  .select__single-value {
    padding: 0px;
    margin: 0px;
    color: ${({ theme }) => theme.colors.black};
  }
  .select__placeholder {
    font-size: 16px;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.dark.mid};
  }
  .select__control--menu-is-open {
    .arrow-down {
      transform: rotate(180deg);
    }
  }

  .select__option {
    font-size: 14px;
    font-family: ${FONTS.WM};
    padding: 12px 10px;
    border-radius: 8px;
    :not(:last-of-type) {
      margin-bottom: 7px;
    }
    display: flex;
    :active {
      background: ${COLORS.aqua.mid};
    }
  }
  .select__option--is-focused {
    background: ${COLORS.aqua.light};
    cursor: pointer;
  }
  .select__option--is-selected {
    background: ${COLORS.aqua.mid};
    color: #000;
  }

  .select__menu {
    ${({ hasError, theme }) =>
      hasError ? theme.colors.red.solid : 'transparent'};
    border-top: 2px solid transparent;
  }
  .select__control--is-disabled {
    background-color: transparent;
    opacity: 1;
    cursor: not-allowed;
    pointer-events: all;
  }

  ${({ variant, theme }) => {
    if (variant === INPUT_VARIANTS.NORMAL) {
      return `
        .select__control {
          border: 1px solid ${theme.colors.dark.mid};
          padding: 0px 18px 0 12px;
          height: 48px;
          box-shadow: none;
          :hover {
            border: 1px solid ${theme.colors.dark.mid};
          }

        }
        .select__menu {
            border: none;
            border-radius: 8px;
            top: 100%;
            margin-top: 4px;
            box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
            z-index: 100;
        }
        .select__option {
          font-size: 16px;
          font-family: ${FONTS.WS};
          padding: 9px 16px;
          border-radius: 8px;
          :not(:last-of-type) {
            margin-bottom: 0px;
          }
          :active {
            background: ${COLORS.blue.mid};
          }
        }
        .select__option--is-focused {
          background: ${COLORS.blue.light};
        }
        .select__option--is-selected {
          background: ${COLORS.blue.mid};
        }
        .select__menu-list {
          margin: 4px;
        }
        .select__control--menu-is-open {
          border-radius: 8px;
        }
      `;
    }
    if (variant === INPUT_VARIANTS.CONTAINER_FILLED) {
      return `
        height: 100%;
        .form-select {
          height: 100%;
        }
        .select__control {
          height: 100%;
          border-radius: 0px;
          border: none !important;
          padding: 18px 20px 18px 24px;
          :hover {
            border: none;
          }
        }
        .select__menu {
          background-color: #fff;
          border: 1px solid ${theme.colors.dark.mid};
          border-radius: 0;
          top: 100%;
          width: calc(100% + 2px);
          left: -1px;
        }
        .select__option {
          font-size: 15px;
          font-family: ${FONTS.WS};
          padding: 9px 8px;
          border-radius: 0px;
          :not(:last-of-type) {
            margin-bottom: 0px;
          }
          :active {
            background: ${COLORS.blue.mid};
          }
        }
        .select__option--is-focused {
          background: ${COLORS.blue.light};
          border-radius: 0;
        }
        .select__option--is-selected {
          background: ${COLORS.blue.mid};
          border-radius: 0;
        }
        .select__menu-list {
          border-top: none;
          margin: 4px;
        }
        .select__control--menu-is-open {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      `;
    }
    return '';
  }}
`;

const getArrowColor = variant => {
  if (variant === INPUT_VARIANTS.CONTAINER_FILLED) return 'blue.solid';
  if (variant === INPUT_VARIANTS.NORMAL) return 'black';
  return 'aqua.solid';
};

const Select = props => {
  const {
    leftIcon,
    placeholder = 'Select...',
    options = [],
    defaultValue,
    styleProps = {},
    input = {},
    meta = {},
    label,
    labelProps = {},
    customLabelStyles,
    isLoading,
    onAfterChange,
    variant = INPUT_VARIANTS.NORMAL,
    iconColor,
    addCreatedAtLabel,
    ...rest
  } = props;

  const lProps = customLabelStyles
    ? labelProps
    : getInputVariantProps(variant).labelProps;

  const arrowColor = iconColor || getArrowColor(variant);
  const value = find(options, { value: defaultValue || input.value });
  const hasError =
    (meta.touched || meta.submitFailed) && (meta.error || meta.submitError);

  return (
    <Styles
      variant={variant}
      w="100%"
      cursor="pointer"
      {...styleProps}
      hasError={hasError}
      data-test-id={rest['data-test-id']}
    >
      <FormLabel name={input.name} label={label} {...lProps} />
      {isLoading ? (
        <Spinner variant="small" />
      ) : (
        <ReactSelect
          placeholder={placeholder}
          className="form-select"
          classNamePrefix="select"
          leftIcon={leftIcon}
          components={{
            IndicatorSeparator: () => null,
            Option,
            Control,
            IndicatorsContainer: () => (
              <Icon
                className="arrow-down"
                name="chevronDown"
                fontSize={12}
                color={
                  hasError && variant !== INPUT_VARIANTS.NORMAL
                    ? 'red.solid'
                    : arrowColor
                }
              />
            ),
          }}
          isClearable={false}
          isSearchable={false}
          options={options}
          {...input}
          onChange={({ value: v }) => {
            input.onChange(v);
            if (onAfterChange) {
              onAfterChange(v);
            }
          }}
          value={value}
          {...props}
        />
      )}
      <FormError meta={meta} />
    </Styles>
  );
};

export default Select;
