import { Box, Flex, Image } from '@chakra-ui/react';
import Modal from '@components/Modal';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const SuccessModal = ({ isOpen, onClose = () => {} }) => {
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styleProps={{
        minW: '340px',
        p: isSmallerScreen ? '24px 16px' : '48px 32px',
      }}
      closeOnEsc={false}
    >
      <Flex>
        <Box alignSelf="center">
          <Typography variant="h1" mb="16px">
            Successfully submitted
          </Typography>
          <Typography mb="34px">
            Sit back and relax, you’ll hear from us shortly.
          </Typography>
          <Button onClick={onClose}>Close, go to brief overview</Button>
        </Box>
        {!isSmallerScreen && (
          <Image
            src="/images/illustration-relaxed-women.svg"
            alt="Sit back and enjoy"
            w="197px"
            ignoreFallback
          />
        )}
      </Flex>
    </Modal>
  );
};

export default SuccessModal;
