import React from 'react';
import { isEmpty, map, times } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { Box } from '@chakra-ui/react';
import { Facebook as ProjectLoader } from 'react-content-loader';
import ProjectCard from './ProjectCard';

const ProjectsList = ({
  mainNameQA,
  isAdminOrDL,
  isAdmin,
  isTeamMember,
  isTeamAdmin,
  isClient,
  projects,
  isLoading,
  canDeleteProject,
  deleteProject,
  isDeleting,
  forceJobsByProject,
  archiveOrUnarchiveProject,
  fetchProjects,
  isProjectsListRoute,
  isMergingView,
  routerStore: { prevProjectsListUrl },
  projectsStore: { setProjectRiskProfile, cloneProject },
  xeroStore: { xeroSync, xero },
}) => {
  React.useEffect(() => {
    if (!isEmpty(projects) && prevProjectsListUrl.pageYOffset > 0) {
      window.scrollTo(0, prevProjectsListUrl.pageYOffset);
    }
  }, [prevProjectsListUrl.pageYOffset, projects]);

  if (isLoading) {
    return map(times(8), (t, idx) => (
      <Box mb="16px" w="100%" key={`loader-${idx}`} maxW="720px">
        <ProjectLoader height="240px" width="100%" />
      </Box>
    ));
  }

  return map(projects, project => (
    <ProjectCard
      mainNameQA={mainNameQA}
      key={`project-card-${project.id}`}
      project={project}
      isTeamMember={isTeamMember}
      isTeamAdmin={isTeamAdmin}
      isAdminOrDL={isAdminOrDL}
      isAdmin={isAdmin}
      cloneProject={cloneProject}
      isClient={isClient}
      canDeleteProject={canDeleteProject(project)}
      deleteProject={deleteProject}
      archiveOrUnarchiveProject={archiveOrUnarchiveProject}
      isDeleting={isDeleting}
      forceJobsByProject={forceJobsByProject}
      fetchProjects={fetchProjects}
      isProjectsListRoute={isProjectsListRoute}
      setProjectRiskProfile={setProjectRiskProfile}
      isMergingView={isMergingView}
      xeroSync={xeroSync}
      xero={xero}
    />
  ));
};

export default inject(
  'routerStore',
  'projectsStore',
  'xeroStore',
)(observer(ProjectsList));
