import Typography from '@styles/Typography';
import WhiteCard from '@components/WhiteCard';
import React from 'react';
import { Field } from 'react-final-form';
import { Box, Flex, Stack } from '@chakra-ui/react';
import Button from '@styles/Button';
import { map, find } from 'lodash-es';
import AsyncAutocomplete from '@components/forms/AsyncAutocomplete';
import { API } from '@app/api';
import FormSpyFieldValues from '@components/forms/FormSpyFieldValues';
import Select from '@components/forms/Select';
import { formatNumber } from '@utils';
import { ENGAGEMENT_TYPES, PROJECT_TYPES } from '@app/constants';
import {
  getAllocationMultiplier,
  getClientAllocationTotal,
  isFixedPriceProjectType,
  isProjectBuild,
} from '@utils/projectUtils';
import ProjectAllocations from './ProjectAllocations';
import FixedPriceAllocations from './FixedPriceAllocations';
import SubmitFailedError from './SubmitFailedError';
import CostRow from '../Brief/CostRow';

const DPCard = ({ formApi, fieldName, mainNameQA, onRemoveDP }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 200);
  }, []);

  return (
    <WhiteCard>
      <Flex alignItems="center">
        <FormSpyFieldValues fieldNames={[`${fieldName}.supplier.name`]}>
          {values => (
            <Typography variant="h3" mr="auto">{`Delivery partner ${values[
              `${fieldName}.supplier.name`
            ] || ''}`}</Typography>
          )}
        </FormSpyFieldValues>
        {onRemoveDP && (
          <Button
            mr="12px"
            color="black"
            bg="primary.100"
            width="40px"
            iconButton={{
              name: 'trashNew',
              fontSize: 16,
            }}
            onClick={onRemoveDP}
          />
        )}
        <Button
          bg="primary.100"
          color="black"
          width="40px"
          iconButton={{
            name: 'arrowLeft',
            fontSize: 32,
          }}
          transform={isOpen ? 'rotate(90deg)' : 'rotate(-90deg)'}
          onClick={() => setIsOpen(!isOpen)}
        />
      </Flex>
      {isOpen && (
        <>
          <Stack isInline spacing="40px" mt="36px">
            <Box w="30%">
              <Field
                name={`${fieldName}.supplier`}
                data-test-id={`${mainNameQA}--${fieldName}.supplier`}
                label="Delivery Partner"
                component={AsyncAutocomplete}
                getOptionsFn={API.searchSuppliers}
                customMapping={data =>
                  map(data, item => ({
                    value: item.id,
                    label: item.name,
                  }))
                }
                onAfterChange={opt => {
                  const primaryCurrency = find(
                    opt?.paymentDetails,
                    c => c.isPrimary,
                  );
                  formApi.change(`${fieldName}.supplierLead`, null);
                  formApi.change(
                    `${fieldName}.overriddenCurrency`,
                    primaryCurrency?.currency,
                  );
                }}
                placeholder="Select Delivery Partner"
              />
            </Box>
            <FormSpyFieldValues fieldNames={[`${fieldName}.supplier`]}>
              {values => {
                const supplier = values[`${fieldName}.supplier`];
                const supplierId = supplier?.id;
                const currencies = supplier.paymentDetails;
                return (
                  <>
                    <Box w="30%" mr="40px" key={`supplier-${supplierId}`}>
                      <Field
                        name={`${fieldName}.supplierLead`}
                        data-test-id={`${mainNameQA}--${fieldName}.supplierLead`}
                        label="Delivery Partner lead"
                        isDisabled={!supplierId}
                        reloadOnEmpty
                        component={AsyncAutocomplete}
                        getOptionsFn={API.searchSupplierUsers(supplierId)}
                        customOptionMap={option => ({
                          value: option,
                          label: option.fullname || option.email,
                        })}
                        placeholder="Add a Delivery Partner lead"
                      />
                    </Box>

                    <Box w="20%" mr="40px">
                      <Field
                        name={`${fieldName}.overriddenCurrency`}
                        data-test-id={`${mainNameQA}--${fieldName}.overriddenCurrency`}
                        label="Delivery Partner currency"
                        component={Select}
                        options={map(currencies, c => ({
                          value: c.currency,
                          label: c.currency,
                        }))}
                        isDisabled={!supplier}
                      />
                    </Box>
                  </>
                );
              }}
            </FormSpyFieldValues>
          </Stack>
          <Box bg="dark.light" p="32px" borderRadius="8px" mt="40px">
            <FormSpyFieldValues fieldNames={['projectType', 'engagementType']}>
              {({ projectType, engagementType }) => {
                if (
                  projectType === PROJECT_TYPES.FIXED_PRICE &&
                  engagementType === ENGAGEMENT_TYPES.PROJECT_BUILD
                ) {
                  return (
                    <FixedPriceAllocations
                      fieldName={fieldName}
                      mainNameQA={mainNameQA}
                    />
                  );
                }
                return (
                  <>
                    <ProjectAllocations
                      fieldName={`${fieldName}.allocations`}
                      mainNameQA={mainNameQA}
                      label={
                        isProjectBuild(engagementType)
                          ? 'Total allocation'
                          : 'Weekly allocation'
                      }
                    />
                    <SubmitFailedError
                      fieldName={`${fieldName}.allocation`}
                      data-test-id={`${mainNameQA}--allocationError`}
                    />
                  </>
                );
              }}
            </FormSpyFieldValues>
            <FormSpyFieldValues
              fieldNames={[
                'currency',
                'projectType',
                'engagementType',
                'startDate',
                'endDate',
                'totalBasis',
                fieldName,
              ]}
            >
              {({ startDate, endDate, totalBasis, ...values }) => {
                const dp = values[fieldName];
                const hasAllocationPhases = isFixedPriceProjectType(values);
                const allocationMultiplier = React.useMemo(() => {
                  const multiplier = getAllocationMultiplier({
                    startDate,
                    endDate,
                    totalBasis,
                  });
                  return totalBasis ? multiplier : 1;
                }, [startDate, endDate, totalBasis]);
                const getTotal = () => {
                  if (hasAllocationPhases) {
                    return getClientAllocationTotal(
                      {
                        allocations: [
                          ...(dp.allocations1 || []),
                          ...(dp.allocations2 || []),
                          ...(dp.allocations3 || []),
                        ],
                      },
                      allocationMultiplier,
                    );
                  }
                  return getClientAllocationTotal(
                    {
                      allocations: dp.allocations,
                    },
                    allocationMultiplier,
                  );
                };

                return (
                  <CostRow
                    mt="24px"
                    bg="#fff"
                    title="Total amount"
                    currency={values.currency}
                    cost={formatNumber(getTotal())}
                    showHours={false}
                    mainNameQA={mainNameQA}
                    id="costForDP"
                  />
                );
              }}
            </FormSpyFieldValues>
          </Box>
        </>
      )}
    </WhiteCard>
  );
};

export default DPCard;
