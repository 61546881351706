import { API } from '@app/api';
import {
  CURRENCIES_SYMBOLS,
  NEW_INVOICE_STATUSES,
  PROJECT_PAYMENT_TYPES,
  XERO_INVOICE_URL,
} from '@app/constants';
import { Box, Flex, Stack } from '@chakra-ui/react';
import Icon from '@components/Icon';
import ReadOnlyTable from '@components/ReadOnlyTable';
import useQuery from '@hooks/useQuery';
import Typography from '@styles/Typography';
import {
  determineStatusBadgeBg,
  isInvoiceUnderApproved,
  isInvoiceApprovedAtLeast,
} from '@utils/invoiceUtils';
import {
  get,
  isEmpty,
  isNull,
  map,
  startsWith,
  lowerCase,
  filter,
} from 'lodash-es';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import InvoiceTotals from '../../Invoice/InvoiceTotals';
import { InvoiceStatus, Stat } from '../components';
import InvoiceLineItemForm from '../InvoiceLineItemForm';
import PaymentProcessing from '../PaymentProcessing';
import PurchaseOrderStat from '../PurchaseOrderStat';
import TaxCell from '../TaxCell';
import CreditNote from './CreditNote';
import InvoiceBasicActions from './InvoiceActions';
import InvoiceHistory from './InvoiceHistory';
import InvoiceSpecificActions from './InvoiceSpecificActions';

const { REACT_APP_API_URL: API_URL } = process.env;

const PaymentInvoice = ({
  onEditInvoice,
  onDuplicatePayment,
  project,
  payment,
  invoice = {},
  paymentsStore: {
    getTaxInfo,
    markAsSentInvoice,
    updateInvoiceLineItem,
    approveOrPreapproveInvoice,
    addNewPaymentNote,
    rejectInvoice,
    updatedInvoiceData,
  },
  usersStore: { isAdminOrDL, isAdmin },
  projectsStore: {
    projectCurrency: { currency },
  },
  mainNameQA,
  ...props
}) => {
  const isClient = invoice.contactType === 'Client';
  const isDeposit = payment.paymentType === PROJECT_PAYMENT_TYPES.DEPOSIT;
  const isFixedPayment =
    payment.paymentType === PROJECT_PAYMENT_TYPES.FIXED_PAYMENT;
  // eslint-disable-next-line no-nested-ternary
  const xeroLink = invoice.xeroId
    ? isClient
      ? `${XERO_INVOICE_URL.XERO_BASE_URL_CLIENT}${invoice.xeroId}`
      : `${XERO_INVOICE_URL.XERO_BASE_URL_SUPPLIER}${invoice.xeroId}`
    : null;
  React.useEffect(() => {
    getTaxInfo(
      project.id,
      invoice.id,
      isClient,
      invoice?.supplier?.id,
      isDeposit,
    );
  }, []);

  const [isHistoryOpened, setIsHistoryOpened] = React.useState(false);
  const [isCreditNoteOpened, setIsCreditNoteOpened] = React.useState(false);
  const [isNoteFormOpened, setIsNoteFormOpened] = React.useState(false);
  const [isRejectInvoiceOpened, setIsRejectInvoiceOpened] = React.useState(
    false,
  );

  const taxName = get(invoice, 'taxInfo.name', '');

  const COLUMNS = () => {
    const isUnderApproved = isInvoiceUnderApproved(invoice);
    return [
      {
        name: 'Description',
        w: '40%',
        ellipsis: false,
        component:
          isAdminOrDL && isUnderApproved
            ? ({ value, id }) => (
                <InvoiceLineItemForm
                  fieldName="description"
                  initialValues={{
                    projectId: project.id,
                    invoiceId: invoice.id,
                    lineItemId: id,
                    description: value,
                  }}
                  onSubmit={updateInvoiceLineItem}
                  data-test-id={`${mainNameQA}--paymentDescription`}
                />
              )
            : null,
      },
      {
        name: 'Quantity',
        w: '15%',
        component:
          isAdminOrDL && isUnderApproved
            ? ({ amount, id }) => (
                <InvoiceLineItemForm
                  fieldName="amount"
                  initialValues={{
                    projectId: project.id,
                    invoiceId: invoice.id,
                    lineItemId: id,
                    amount,
                  }}
                  isMaskedInput
                  inputProps={{
                    decimalScale: 2,
                    fixedDecimalScale: true,
                  }}
                  onSubmit={updateInvoiceLineItem}
                  data-test-id={`${mainNameQA}--paymentQuantity`}
                />
              )
            : null,
      },
      {
        name: 'Unit price',
        w: '15%',
        component:
          isAdminOrDL && isUnderApproved
            ? ({ rate, id }) => (
                <InvoiceLineItemForm
                  fieldName="rate"
                  initialValues={{
                    projectId: project.id,
                    invoiceId: invoice.id,
                    lineItemId: id,
                    rate,
                  }}
                  isMaskedInput
                  inputProps={{
                    prefix:
                      CURRENCIES_SYMBOLS[
                        isClient ? currency : invoice.overriddenCurrency
                      ],
                    decimalScale: 2,
                    fixedDecimalScale: true,
                  }}
                  onSubmit={updateInvoiceLineItem}
                  data-test-id={`${mainNameQA}--paymentUnitPrice`}
                />
              )
            : null,
      },
      {
        name: 'Vat',
        w: '15%',
        component: () => (
          <TaxCell
            value={taxName}
            isLoading={invoice.isLoadingTaxInfo}
            data-test-id={`${mainNameQA}--paymentVat`}
          />
        ),
      },
      { name: 'Amount', w: '15%' },
    ];
  };

  const invoiceTitle = () => {
    if (!isClient) {
      if (!isEmpty(invoice.supplier)) {
        return `${invoice?.supplier?.name} invoice`;
      }
      return 'Delivery Partner invoice';
    }
    return 'Client invoice';
  };

  const isApproved = isInvoiceApprovedAtLeast(invoice || {});

  const isApprovedButNotPaid = [
    NEW_INVOICE_STATUSES.SENT,
    NEW_INVOICE_STATUSES.WAITING_TO_BE_SENT,
    NEW_INVOICE_STATUSES.APPROVED,
  ].includes(get(invoice, 'status'));

  const { data: auditNotes, isLoading: auditNotesLoading, refetch } = useQuery(
    () => API.getAuditNotes(project.id, invoice.id),
    {},
    true,
  );

  const notesCount = React.useMemo(() => {
    return filter(auditNotes, note =>
      startsWith(lowerCase(note.comment), 'note'),
    ).length;
  }, [auditNotes]);

  return (
    <Box
      w="100%"
      border="1px solid"
      borderColor="dark.mid"
      bg="gray.100"
      borderRadius="8px"
      position="relative"
      {...props}
    >
      <Flex
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="dark.mid"
        height="72px"
        p="24px"
      >
        <Flex alignItems="center">
          <Typography
            variant="h4"
            minW="154px"
            mr="12px"
            data-test-id={`${mainNameQA}--${invoiceTitle()}`}
          >
            {invoiceTitle()}
          </Typography>
          {invoice.needsUpdate && (
            <PaymentProcessing
              mr="16px"
              tooltipLabel="Not yet synced with xero."
              data-test-id={`${mainNameQA}--paymentProcessing`}
            />
          )}
          {payment.calculatedFromActuals && (
            <Typography
              mt="6px"
              data-test-id={`${mainNameQA}--paymentCalculatedFromActuals`}
            >
              <Icon
                name="calculator"
                fontSize={22}
                color="black"
                mr="8px"
                mb="3px"
              />
              Calculated from actuals
            </Typography>
          )}
          {invoice.periodStart && (
            <Stat
              px="16px"
              py="16px"
              label="Period start"
              value={moment.utc(invoice.periodStart).format('DD MMM YYYY')}
              borderRight="none"
              data-test-id={`${mainNameQA}--paymentPeriodStart`}
            />
          )}
          {invoice.periodEnd && (
            <Stat
              px="16px"
              py="16px"
              label="Period end"
              value={moment.utc(invoice.periodEnd).format('DD MMM YYYY')}
              borderRight="none"
              data-test-id={`${mainNameQA}--paymentPeriodEnd`}
            />
          )}
        </Flex>
        <Flex flexDir="column" justifyContent="center">
          <InvoiceStatus
            value={
              invoice.status === 'Paid' && invoice.fullyPaidOnDate
                ? `Paid - ${moment(invoice.fullyPaidOnDate).format(
                    'DD MMM YYYY',
                  )}`
                : invoice.status
            }
            bg={determineStatusBadgeBg(invoice)}
            alignSelf="flex-end"
            data-test-id={`${mainNameQA}--paymentInvoiceStatus`}
          />
        </Flex>
      </Flex>
      <Box px="24px" py="16px">
        <Flex>
          <Stack isInline flexWrap="wrap">
            <Stat
              borderRight="none"
              label="Xero number"
              value={invoice.xeroNumber}
              pb="12px"
              data-test-id={`${mainNameQA}--paymentXeroNumber`}
            />
            {isAdminOrDL && (
              <Stat
                borderRight="none"
                label="Xero invoice link"
                data-test-id={`${mainNameQA}--paymentXeroLink`}
                value={
                  xeroLink && (
                    <Typography
                      as="a"
                      href={xeroLink}
                      target="_blank"
                      fontFamily="WM"
                    >
                      xero.com
                    </Typography>
                  )
                }
                pb="12px"
              />
            )}
            <Stat
              borderRight="none"
              label="Invoice date"
              value={moment.utc(invoice.invoiceDate).format('DD MMM YYYY')}
              data-test-id={`${mainNameQA}--paymentInvoiceDate`}
              pb="12px"
            />
            <Stat
              borderRight="none"
              label="Due date"
              value={moment.utc(invoice.dueDate).format('DD MMM YYYY')}
              pb="12px"
              data-test-id={`${mainNameQA}--paymentDueDate`}
            />
            {!isFixedPayment && (
              <Stat
                borderRight="none"
                label="PO number"
                value={
                  <PurchaseOrderStat
                    isInheritedFromProject={isNull(invoice.purchaseOrder)}
                    purchaseOrder={
                      isNull(invoice.purchaseOrder)
                        ? project.purchaseOrder
                        : invoice.purchaseOrder
                    }
                  />
                }
                pb="12px"
                data-test-id={`${mainNameQA}--paymentPoNumber`}
              />
            )}
            <Stat
              borderRight="none"
              label="Reference"
              value={invoice.reference}
              pb="12px"
              marginStart="0 !important"
              data-test-id={`${mainNameQA}--paymentPoNumber`}
            />
          </Stack>
          <InvoiceBasicActions
            showEdit={isAdminOrDL && !isApproved}
            isDownloadable={isApproved}
            invoiceLink={`payments/invoices/${invoice.id}`}
            downloadLink={`${API_URL}/projects/${project.id}/scheduleInvoices/${invoice.id}/pdf`}
            onEdit={onEditInvoice}
            onDuplicatePayment={onDuplicatePayment}
            mainNameQA={mainNameQA}
          />
        </Flex>
        {!isEmpty(invoice.tableData) && (
          <ReadOnlyTable
            columns={COLUMNS()}
            tableData={invoice.tableData}
            qaTag={mainNameQA}
          />
        )}
        <Flex justifyContent="space-between" alignItems="flex-end" mt="12px">
          <InvoiceSpecificActions
            notesCount={notesCount}
            isAdminOrDL={isAdminOrDL}
            isAdmin={isAdmin}
            invoice={invoice}
            project={project}
            markAsSentInvoice={markAsSentInvoice}
            approveOrPreapproveInvoice={approveOrPreapproveInvoice}
            setIsHistoryOpened={setIsHistoryOpened}
            isHistoryOpened={isHistoryOpened}
            setIsNoteFormOpened={setIsNoteFormOpened}
            setIsRejectInvoiceOpened={setIsRejectInvoiceOpened}
            isRejectInvoiceOpened={isRejectInvoiceOpened}
            isApprovedButNotPaid={isApprovedButNotPaid}
            canCreateCreditNote={isEmpty(invoice.creditAllocations)}
            setIsCreditNoteOpened={setIsCreditNoteOpened}
            isCreditNoteOpened={isCreditNoteOpened}
            refetchInvoiceHistory={refetch}
            mainNameQA={mainNameQA}
            updatedInvoiceData={updatedInvoiceData}
          />
          {!isEmpty(invoice.tableData) && (
            <InvoiceTotals
              isPreview={false}
              ml="auto"
              mb="12px"
              invoice={invoice}
              mainNameQA={mainNameQA}
            />
          )}
        </Flex>
        {isHistoryOpened && (
          <InvoiceHistory
            invoice={invoice}
            addNewPaymentNote={addNewPaymentNote}
            projectId={project.id}
            isNoteFormOpened={isNoteFormOpened}
            setIsNoteFormOpened={setIsNoteFormOpened}
            setIsRejectInvoiceOpened={setIsRejectInvoiceOpened}
            isRejectInvoiceOpened={isRejectInvoiceOpened}
            rejectInvoice={rejectInvoice}
            auditNotes={auditNotes}
            auditNotesLoading={auditNotesLoading}
            refetch={refetch}
            mainNameQA={mainNameQA}
          />
        )}
        {isCreditNoteOpened && isEmpty(invoice.creditAllocations) && (
          <CreditNote
            invoice={invoice}
            creditAllocation={{}}
            onCancelCreditNoteForm={() => setIsCreditNoteOpened(false)}
            refetchInvoiceHistory={refetch}
            isAdmin={isAdmin}
            mainNameQA={mainNameQA}
          />
        )}
        {map(invoice.creditAllocations, creditAllocation => (
          <CreditNote
            invoice={invoice}
            creditAllocation={creditAllocation}
            onCancelCreditNoteForm={() => setIsCreditNoteOpened(false)}
            refetchInvoiceHistory={refetch}
            isAdmin={isAdmin}
            mainNameQA={mainNameQA}
          />
        ))}
      </Box>
    </Box>
  );
};

export default inject(
  'paymentsStore',
  'usersStore',
  'projectsStore',
)(observer(PaymentInvoice));
