import ReadOnlyTable from '@components/ReadOnlyTable';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { MAIN_NAMES_QA } from '@app/constants';
import { isFixedPrice, isFixedPriceProjectType } from '@utils/projectUtils';
import { first, get, isEmpty, map } from 'lodash-es';
import NoAllocationsMessage from '@components/AllocationTable/NoAllocationsMessage';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { getAllocationsColumns } from '../constants';

const ReadOnlyTeamAllocations = ({
  allocations,
  allocationsStore: {
    allocations: {
      current: { isLoading },
    },
  },
  projectsStore: { project },
  usersStore: { isAdminOrDL, isTeamAdmin, isClient, isTeamMember },
}) => {
  const isProjectFixedPriceModel = isFixedPriceProjectType(project);

  if (isLoading) {
    return <Spinner variant="large" />;
  }

  if (isEmpty(allocations)) {
    return <NoAllocationsMessage />;
  }
  if (isProjectFixedPriceModel) {
    return map(allocations, alloc => (
      <React.Fragment key={`allocation-stage-item-${alloc.id}`}>
        <Typography variant="h5" mt="32px">
          {alloc.stage}
        </Typography>
        {isEmpty(alloc.entries) ? (
          <NoAllocationsMessage />
        ) : (
          <ReadOnlyTable
            columns={getAllocationsColumns({
              isAdminOrDL,
              isTeamAdmin,
              isClient,
              isTeamMember,
              isFixedPrice: isFixedPrice(project),
            })}
            qaTag={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}-teamAllocations`}
            tableData={get(alloc, 'tableData', [])}
          />
        )}
      </React.Fragment>
    ));
  }

  return (
    <ReadOnlyTable
      columns={getAllocationsColumns({
        isAdminOrDL,
        isTeamAdmin,
        isClient,
        isTeamMember,
        isFixedPrice: isFixedPrice(project),
      })}
      tableData={get(first(allocations), 'tableData', [])}
      qaTag={`${MAIN_NAMES_QA.PROJECT_ALLOCATIONS}-teamAllocations`}
    />
  );
};

export default inject(
  'allocationsStore',
  'usersStore',
  'projectsStore',
)(observer(ReadOnlyTeamAllocations));
