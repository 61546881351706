import { Box, useDisclosure } from '@chakra-ui/react';
import CommentModal from '@components/CommentModal';
import React from 'react';
import { Field } from 'react-final-form';
import TextInput from '@components/forms/TextInput';
import Icon from '@components/Icon';

const CommentSection = ({
  idx,
  candidateIdx,
  isReadOnly,
  onSaveComment,
  c,
  mainNameQA,
}) => {
  const {
    isOpen: isCommentModalOpen,
    onClose: onCommentModalClose,
    onOpen: onCommentModalOpen,
  } = useDisclosure();
  return (
    <>
      <Box
        pr={[0, 0, 0, '24px']}
        mb={['12px', '12px', '12px', 0]}
        position="relative"
        width={['100%', '100%', '100%', 'calc(66% + 24px)']}
      >
        <Field
          name={`resources[${idx}].candidates[${candidateIdx}].comments`}
          component={TextInput}
          label="Add a comment"
          placeholder={
            c.isRateOk === false
              ? 'Add a comment if required. Include why you have exceeded your agreed high rate.'
              : 'Add a comment if required'
          }
          isDisabled={isReadOnly || isCommentModalOpen}
          data-test-id={`${mainNameQA}--resources[${idx}].candidates[${candidateIdx}].comments`}
          pr="34px"
          onClick={isReadOnly && !c.comments ? () => {} : onCommentModalOpen}
          readOnly
          _readOnly={{
            bg: '#fff',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          _disabled={{ bg: 'transparent' }}
        />
        <Icon
          name="commentOutline"
          position="absolute"
          right={['12px', '12px', '12px', '31px']}
          fontSize={18}
          top="44px"
          color={!c.comments ? 'blue.solid' : 'dark.mid'}
          onClick={isReadOnly && !c.comments ? () => {} : onCommentModalOpen}
          cursor="pointer"
        />
      </Box>
      <CommentModal
        isReadOnly={isReadOnly}
        isOpen={isCommentModalOpen}
        onClose={onCommentModalClose}
        defaultValue={c.comments}
        limit={500}
        saveComment={comment => onSaveComment(idx, candidateIdx, comment || '')}
        mainNameQA={mainNameQA}
      />
    </>
  );
};

export default CommentSection;
