import WhiteCard from '@components/WhiteCard';
import { COLORS } from '@styles/theme';
import Typography from '@styles/Typography';
import { formatNumber } from '@utils';
import React from 'react';
import CostRow from '../CostRow';

const ClientPandPProposalSummary = ({
  isLoadingProposal,
  proposalData,
  mainNameQA,
}) => (
  <WhiteCard
    border={['none', 'none', 'none', `1px solid ${COLORS.dark.mid}`]}
    p={[0, 0, 0, '32px 24px']}
    bg={['primary.100', 'primary.100', 'primary.100', '#fff']}
  >
    <Typography variant="h2" mb="24px">
      Total Monthly Cost
    </Typography>
    <CostRow
      data-test-id={mainNameQA && `${mainNameQA}--deazyProposalGrandTotal`}
      mt={['16px', '16px', '16px', '24px']}
      bg="#fff"
      title="Deazy proposal grand total"
      currency="gbp"
      showHours={false}
      mainNameQA={mainNameQA}
      cost={
        !isLoadingProposal &&
        formatNumber(
          proposalData.monthlyClientCost || proposalData.monthlyCosts,
        )
      }
      border="2px solid"
    />
  </WhiteCard>
);

export default ClientPandPProposalSummary;
