import React from 'react';
import ReadOnlyTable from '@components/ReadOnlyTable';
import { Icon, Tooltip, Box } from '@chakra-ui/react';
import Typography from '@styles/Typography';

const COLUMNS = [
  {
    name: 'Status (allocation)',
    w: '30%',
  },
  { name: 'Amount (allocation)', w: '20%' },
  { name: 'Credit note status', w: '30%' },
  {
    name: 'Credit note total value',
    w: '20%',
  },
];

const CreditAllocations = ({ data }) => (
  <>
    <Typography variant="h4" mb="16px">
      Credit allocations:
    </Typography>
    <ReadOnlyTable columns={COLUMNS} tableData={data} />
  </>
);

const CreditAllocationsTableTooltip = ({ data }) => {
  return (
    <Tooltip
      zIndex={3000}
      lineHeight="normal"
      label={<CreditAllocations data={data} />}
      minW="900px"
      borderRadius="8px"
      border="1px solid #d9dada"
      px="24px"
      py="40px"
      bg="#fff"
      color="black"
    >
      <Box as="span" mr="8px">
        <Icon name="info-outline" mb="3px" />
      </Box>
    </Tooltip>
  );
};

export default CreditAllocationsTableTooltip;
