import { Box, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import Icon from '@components/Icon';
import styled from '@emotion/styled';
import { getInputVariantProps, INPUT_VARIANTS } from '@styles/theme';
import moment from 'moment';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import FormLabel from './FormLabel';
import FormError from './_common/FormError';

const Styles = styled(({ selectedBgColor, ...props }) => <Box {...props} />, {
  shouldForwardProp: prop => prop !== 'isOverlayFixed',
})`
  display: flex;
  flex-direction: column;
  .DayPicker-wrapper {
    outline: none;
  }
  .DayPickerInput-Overlay {
    border-radius: 20px;
    z-index: 200;
    ${({ placement }) => placement === 'top' && 'bottom: 60px;'}
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
    ${({ isOverlayFixed }) =>
      isOverlayFixed &&
      `position: fixed;
  left: auto;`}
  }
  .DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--outside) {
    background-color: ${({ selectedBgColor }) => selectedBgColor};
  }
  .DayPicker-Day {
    outline: none;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    :hover {
      background-color: ${({ selectedBgColor }) => selectedBgColor};
    }
  }
  .DayPickerInput {
    height: 100%;
  }
`;

const DatePicker = ({
  dateFormat = 'DD MMM YYYY',
  placeholder = 'Select date',
  label,
  meta,
  input = {},
  variant = INPUT_VARIANTS.NORMAL,
  onAfterChange,
  containerProps,
  dayPickerProps = {},
  disabledDaysFn = () => false,
  calendarProps = {},
  calendarColor = input.value && 'gray.200',
  isErrorAbsolute,
  hideCalendarIcon = false,
  isOverlayFixed = false,
  errorQaValue,
  placement,
  ...restProps
}) => {
  const { labelProps, inputProps } = getInputVariantProps(variant);

  const mainColor =
    calendarColor ||
    (variant === INPUT_VARIANTS.NORMAL ? '#3366ff' : '#47b5bd');
  return (
    <Styles
      position={isErrorAbsolute && 'relative'}
      selectedBgColor={mainColor}
      placement={placement}
      {...containerProps}
      isOverlayFixed={isOverlayFixed}
    >
      <FormLabel name={input.name} label={label} {...labelProps} />
      <DayPickerInput
        onDayChange={date => {
          input.onChange(
            date
              ? moment
                  .utc(date)
                  .startOf('day')
                  .toISOString()
              : null,
          );
          if (onAfterChange) {
            onAfterChange(date);
          }
        }}
        value={input.value && moment.utc(input.value).toISOString()}
        dayPickerProps={{
          disabledDays: disabledDaysFn,
          firstDayOfWeek: 1,
          // enableOutsideDaysClick: false, <- this will works only from version 8.0
          modifiersStyles: {
            outside: {
              pointerEvents: 'none',
            },
          },
          ...dayPickerProps,
        }}
        component={React.forwardRef((props, ref) => {
          const inputRef = React.useRef(ref);

          return (
            <InputGroup h="100%">
              <Input
                {...input}
                {...props}
                placeholder={placeholder}
                _placeholder={{ color: 'dark.mid' }}
                autoComplete="off"
                backgroundColor={inputRef.current?.disabled && 'gray.100'}
                _focus={{
                  boxShadow: 'none',
                  borderColor: 'primary.300',
                }}
                minW="256px"
                {...inputProps}
                onChange={() => {
                  input.onChange(null);
                  props.onChange({ target: { value: '' } });
                  props.onBlur();
                }}
                onBlur={e => {
                  props.onBlur();
                  input.onBlur(e);
                }}
                ref={inputRef}
                value={
                  input.value && moment.utc(input.value).format(dateFormat)
                }
                {...restProps}
              />
              {!hideCalendarIcon && (
                <InputRightElement
                  cursor="pointer"
                  width="auto"
                  top={variant !== INPUT_VARIANTS.NORMAL ? '10px' : '3px'}
                  right="14px"
                  {...calendarProps}
                  onClick={() => {
                    inputRef.current.focus();
                  }}
                >
                  <Icon name="calendar" color={mainColor} fontSize={22} />
                </InputRightElement>
              )}
            </InputGroup>
          );
        })}
      />
      <FormError
        isErrorAbsolute={isErrorAbsolute}
        meta={meta}
        data-test-id={errorQaValue}
      />
    </Styles>
  );
};

export default DatePicker;
