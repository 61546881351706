import Typography from '@styles/Typography';
import Icon from '@components/Icon';
import React from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { ASSIGNMENT_STATUSES } from '@app/constants';

const interval = {};

const AssignmentStatusBadge = ({
  assignment,
  briefAssignmentsStore: { handleExclusiveAssignmentEnd },
  mainNameQA,
}) => {
  const { status, exclusivityEnabled, exclusiveTo } = assignment;
  const isExclusive =
    exclusivityEnabled || assignment.isExclusive || assignment.exclusiveTo;

  const { color, iconColor, bg } = React.useMemo(() => {
    if (
      status === ASSIGNMENT_STATUSES.EXCLUSIVE ||
      status === ASSIGNMENT_STATUSES.SCHEDULED
    ) {
      return { color: 'yellow.solid', bg: 'yellow.light' };
    }
    if (status === ASSIGNMENT_STATUSES.AWAITING_RESPONSE) {
      return { color: 'orange.solid', bg: 'orange.light' };
    }
    if (status === ASSIGNMENT_STATUSES.NOT_INTERESTED) {
      return { color: 'red.solid', bg: 'red.light' };
    }
    if (status === ASSIGNMENT_STATUSES.SHOWN_INTEREST) {
      return { color: 'green.solid', bg: 'green.light' };
    }
    if (
      status === ASSIGNMENT_STATUSES.PROPOSAL_SHARED ||
      assignment.status === ASSIGNMENT_STATUSES.CANDIDATES_SHARED
    ) {
      return { color: 'aqua.solid', bg: 'aqua.light' };
    }
    return {
      color: 'dark.mid',
      iconColor: 'yellow.solid',
      bg: 'dark.light',
    };
  }, [status]);

  const [label, setLabel] = React.useState();

  const setStatusLabel = () => {
    if (status === ASSIGNMENT_STATUSES.EXCLUSIVE) {
      const date = moment.utc(exclusiveTo);
      const duration = moment.duration(date.diff(moment.utc()));
      const minutes = duration.asMinutes();
      const seconds = duration.asSeconds();
      if (Math.ceil(seconds) <= 0) {
        handleExclusiveAssignmentEnd(assignment.id);
      }
      return setLabel(
        `${Math.floor(minutes / 60)}H${
          Math.ceil(seconds) === 60 ? 1 : Math.floor(minutes % 60)
        }M${Math.ceil(seconds) < 60 ? `${Math.ceil(seconds)}S` : ''} REMAINING`,
      );
    }
    return setLabel(status);
  };

  React.useEffect(() => {
    if (status === ASSIGNMENT_STATUSES.EXCLUSIVE) {
      setStatusLabel();
      interval[assignment.id] = setInterval(() => {
        setStatusLabel();
      }, 1000);
    } else {
      clearInterval(interval[assignment.id]);
      interval[assignment.id] = null;
      setStatusLabel();
    }
    return () => {
      clearInterval(interval[assignment.id]);
      interval[assignment.id] = null;
    };
  }, [status]);

  return (
    <Typography
      as="div"
      p="4px 8px"
      border="1px solid"
      borderColor={color}
      textTransform="uppercase"
      borderRadius="8px"
      variant="text3"
      fontWeight={500}
      bg={bg}
      mr="auto"
      textAlign="center"
      data-test-id={`${mainNameQA}--status`}
    >
      {isExclusive && (
        <Icon
          mb="1px"
          name="bolt"
          color={iconColor || color}
          fontSize={12}
          mr="6px"
        />
      )}
      {label}
    </Typography>
  );
};

export default inject('briefAssignmentsStore')(observer(AssignmentStatusBadge));
