import { API } from '@app/api';
import { Box, Stack } from '@chakra-ui/react';
import DatePicker from '@components/forms/DatePicker';
import MaskedTextInput from '@components/forms/MaskedTextInput';
import AsyncAutocomplete from '@components/forms/_common/AsyncAutocomplete';
import Select from '@components/forms/_common/Select';
import TextInput from '@components/forms/_common/TextInput';
import styled from '@emotion/styled';
import Typography from '@styles/Typography';
import { mustBeShorterOrEqual, required } from '@utils/formValidators';
import { map } from 'lodash-es';
import moment from 'moment';
import React from 'react';
import { Field } from 'react-final-form';

const StyledStack = styled(Stack)`
  > div:nth-of-type(5) {
    margin-left: 0 !important;
  }
`;

export const cellProps = {
  width: 'calc(25% - 40px)',
  pb: '24px',
};

export const FieldSection = ({ title, children, ...props }) => (
  <Box
    p="40px"
    pb="16px"
    pr="0"
    mb="48px"
    bg="#fff"
    borderRadius="8px"
    {...props}
  >
    <Typography variant="h3" mb="24px">
      {title}
    </Typography>
    <StyledStack isInline spacing="40px" flexWrap="wrap">
      {children}
    </StyledStack>
  </Box>
);

const MultiDPSection = ({
  values,
  isAdminOrDL,
  canSeeSowOverviewFields,
  cannotChangeFinalisedSupplierSOW,
  thereIsFinalisedDateSupplierSOW,
  mainNameQA,
}) => (
  <>
    {map(values.projectToSuppliers, (pts, i) => {
      const currencies = pts?.supplier?.paymentDetails;
      return (
        <FieldSection
          title={`Delivery Partner - ${pts.supplier.name}`}
          key={`supplier-${pts.supplier.id}`}
          data-test-id={`${mainNameQA}--supplierInfo`}
        >
          <Box {...cellProps}>
            <Field
              name={`projectToSuppliers[${i}].supplier`}
              data-test-id={`${mainNameQA}--projectToSuppliers[${i}].supplier`}
              label="Name"
              component={AsyncAutocomplete}
              getOptionsFn={API.searchSuppliers}
              isDisabled={
                moment
                  .utc()
                  .endOf('day')
                  .isAfter(moment.utc(values.startDate)) || !isAdminOrDL
              }
              validate={required}
            />
          </Box>
          <Box {...cellProps}>
            <Field
              label="Lead"
              component={AsyncAutocomplete}
              getOptionsFn={API.searchSupplierAdmins(pts.supplier.id)}
              customOptionMap={option => ({
                value: option,
                label: `${option.fullname} <${option.email}>`,
              })}
              name={`projectToSuppliers[${i}].supplierLead`}
              data-test-id={`${mainNameQA}--projectToSuppliers[${i}].supplierLead`}
              placeholder="Add DP lead"
              validate={required}
              isDisabled={!isAdminOrDL}
            />
          </Box>

          {canSeeSowOverviewFields && [
            <Box {...cellProps} key={`${pts.id}-sow-dp-reference`}>
              <Field
                component={TextInput}
                name={`projectToSuppliers[${i}].supplierSOWReference`}
                data-test-id={`${mainNameQA}--projectToSuppliers[${i}].supplierSOWReference`}
                label="SOW reference"
                placeholder="SOW reference"
                isDisabled={!isAdminOrDL}
                validate={mustBeShorterOrEqual(200)}
              />
            </Box>,
            <Box {...cellProps} key={`${pts.id}-sow-dp-finalised-date`}>
              <Field
                component={DatePicker}
                minW="120px"
                name={`projectToSuppliers[${i}].finalisedDateSupplierSOW`}
                data-test-id={`${mainNameQA}--projectToSuppliers[${i}].finalisedDateSupplierSOW`}
                label="Signed SOW date"
                isDisabled={cannotChangeFinalisedSupplierSOW(pts.id)}
                validate={thereIsFinalisedDateSupplierSOW(pts.id) && required}
              />
            </Box>,
          ]}
          {!values.deazyAsSupplier && (
            <Box {...cellProps}>
              <Field
                isDisabled
                component={Select}
                options={map(currencies, c => ({
                  value: c.currency,
                  label: c.currency,
                }))}
                name={`projectToSuppliers[${i}].overriddenCurrency`}
                data-test-id={`${mainNameQA}--projectToSuppliers[${i}].overriddenCurrency`}
                label="Currency"
              />
            </Box>
          )}
          {isAdminOrDL && (
            <Box {...cellProps}>
              <Field
                name={`projectToSuppliers[${i}].supplierCurrencyRate`}
                data-test-id={`${mainNameQA}--projectToSuppliers[${i}].supplierCurrencyRate`}
                component={MaskedTextInput}
                decimalScale={2}
                isDisabled
                fixedDecimalScale
                label="Currency rate"
              />
            </Box>
          )}
          <Box {...cellProps}>
            <Field
              name={`projectToSuppliers[${i}].supplierTotalValue`}
              data-test-id={`${mainNameQA}--projectToSuppliers[${i}].supplierTotalValue`}
              isDisabled
              label="Initial project amount"
              component={TextInput}
            />
          </Box>
        </FieldSection>
      );
    })}
  </>
);

export default MultiDPSection;
