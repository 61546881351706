import { useMemo } from 'react';
import { API } from '@app/api';
import { map } from 'lodash-es';
import useFetch from './useFetch';

const PLUG_N_PLAY_ENGAGEMENT_TYPE = 'Team Augmentation';

const enagagementTypesMap = enagagementTypes =>
  map(enagagementTypes, e => {
    if (e === 'Plug & Play resourcing') {
      return { label: PLUG_N_PLAY_ENGAGEMENT_TYPE, value: e };
    }
    return { label: e, value: e };
  });

export default function useEngagementTypes() {
  const { data: enagagementTypes, isLoading } = useFetch(
    API.getEngagementTypes,
    {
      cacheKey: 'engagementTypes',
      cacheTime: 5 * 60,
    },
  );
  const engagementOptions = useMemo(
    () => enagagementTypesMap(enagagementTypes),
    [enagagementTypes],
  );

  return {
    engagementOptions,
    isLoading,
  };
}
