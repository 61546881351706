import { APP_ROUTES } from '@routes';
import {
  partition,
  join,
  flatten,
  get,
  map,
  reduce,
  round,
  toNumber,
  isEmpty,
} from 'lodash-es';
import moment from 'moment';
import qs from 'query-string';
import { ENGAGEMENT_TYPES, PROJECT_TYPES, ACTION_TYPES } from '@app/constants';
import { getNumberOfWeekdays } from '@utils';

export const getDeazyMarginFactor = deazyMargin => {
  return 100 / (100 - toNumber(deazyMargin));
};

export const getClientAutoCalcFactor = project => {
  const { deazyMarginPercent = 0, supplierCurrencyRate = 1 } = project;
  return (
    getDeazyMarginFactor(deazyMarginPercent) * toNumber(supplierCurrencyRate)
  );
};

export const getAllocationMultiplier = project => {
  const { totalBasis, endDate, startDate } = project;
  if (totalBasis) {
    return 1;
  }
  return getNumberOfWeekdays(startDate, endDate) / 5;
};

export const getClientAllocationTotal = (project, multiplier) => {
  const {
    allocations = [],
    deazyAllocations = [],
    totalBasis,
    projectType,
  } = project;
  multiplier = multiplier || getAllocationMultiplier(project);
  const isFixedPrice = projectType === PROJECT_TYPES.FIXED_PRICE;

  return (
    reduce(
      allocations,
      (sum, alloc) => {
        const { startDate, endDate } = alloc;
        const allocationMultiplier =
          isFixedPrice && totalBasis === false && startDate && endDate
            ? getNumberOfWeekdays(startDate, endDate) / 5
            : multiplier;
        return (
          sum +
          toNumber(alloc.amount || 0) *
            toNumber(alloc.clientRate || 0) *
            allocationMultiplier
        );
      },
      0,
    ) +
    reduce(
      deazyAllocations,
      (sum, alloc) => {
        const { startDate, endDate } = alloc;
        const allocationMultiplier =
          isFixedPrice && totalBasis === false && startDate && endDate
            ? getNumberOfWeekdays(startDate, endDate) / 5
            : multiplier;
        return (
          sum +
          toNumber(alloc.amount || 0) *
            toNumber(alloc.rate || 0) *
            allocationMultiplier
        );
      },
      0,
    )
  );
};

export const getSupplierAllocationTotal = (project, multiplier) => {
  const { allocations = [], projectType, totalBasis } = project;
  const isFixedPrice = projectType === PROJECT_TYPES.FIXED_PRICE;
  multiplier = multiplier || getAllocationMultiplier(project);
  return reduce(
    allocations,
    (sum, alloc) => {
      const { startDate, endDate } = alloc;
      const allocationMultiplier =
        isFixedPrice && totalBasis === false && startDate && endDate
          ? getNumberOfWeekdays(startDate, endDate) / 5
          : multiplier;
      return (
        sum +
        toNumber(alloc.amount || 0) *
          toNumber(alloc.rate || 0) *
          allocationMultiplier
      );
    },
    0,
  );
};

export const getCorrections = (project, clientTotal = 0, supplierTotal = 0) => {
  const {
    supplierCorrectionPercent = 0,
    supplierCorrectionAbsolute = 0,
    clientCorrectionPercent = 0,
    clientCorrectionAbsolute = 0,
  } = project;
  const autoCalcFactor = getClientAutoCalcFactor(project);

  const multiplier = getAllocationMultiplier(project);

  const supplierPercent = toNumber(
    supplierTotal * ((100 + (toNumber(supplierCorrectionPercent) || 0)) / 100),
  );
  const supplierAbsolute =
    (toNumber(supplierCorrectionAbsolute) || 0) + supplierPercent;

  const rawClientCost = getClientAllocationTotal(
    {
      ...project,
      allocations: project.allocations,
      deazyAllocations: [],
    },
    multiplier,
  );

  const rawDeazyCost = clientTotal - rawClientCost;

  const percentageBufferSupplierCostForClient =
    rawClientCost * ((100 + (toNumber(supplierCorrectionPercent) || 0)) / 100);

  const absoluteBufferSupplierCostForClient =
    percentageBufferSupplierCostForClient +
    (toNumber(supplierCorrectionAbsolute) || 0) * autoCalcFactor;

  const supplierTotalForClient = absoluteBufferSupplierCostForClient;

  const interimClientCost = supplierTotalForClient + rawDeazyCost;

  const clientPercent =
    (toNumber(interimClientCost) || 0) *
    ((100 + (toNumber(clientCorrectionPercent) || 0)) / 100);
  return {
    supplierPercent,
    supplierAbsolute,
    clientPercent,
    clientAbsolute: (toNumber(clientCorrectionAbsolute) || 0) + clientPercent,
  };
};

export const createProjectSlug = (project, tab = 'overview') =>
  APP_ROUTES.projectTabWithSlugs(
    get(project, 'client.slug', 'deazy'),
    get(project, 'slug', 'unknown-project'),
    tab,
  );

export const getActualsWeekDays = (year, month, week) => {
  if (!year || !month || !week) {
    return {};
  }
  let startDate = moment.utc([year, month - 1, 1]);
  let endDate = moment.utc([year, month - 1, 1]);
  let currentWeek = 0;
  let daysNumber = 0;

  let shouldBreak = false;
  while (currentWeek < week) {
    endDate = endDate.endOf('week').add({ day: 1 });
    if (endDate.format('MM') !== startDate.format('MM')) {
      shouldBreak = true;
      endDate = moment.utc(startDate).endOf('month');
    }

    const daysDiff = Math.abs(startDate.diff(moment.utc(endDate), 'days'));

    daysNumber = daysDiff + 1;

    currentWeek += 1;

    if (shouldBreak) {
      break;
    }

    if (currentWeek < week) {
      startDate = startDate.endOf('week').add({ day: 2 });
    }
  }
  return { days: daysNumber, startDate, endDate };
};

export const linkForAction = (project, action) => {
  switch (action.type) {
    case ACTION_TYPES.ActualsInput:
    case ACTION_TYPES.TeamActualsApproval:
    case ACTION_TYPES.Unsynced:
    case ACTION_TYPES.DeazyActualsInput:
      return {
        to: APP_ROUTES.projectTabWithSlugs(
          get(project, 'client.slug'),
          project.slug,
          'allocations-and-actuals',
        ),
      };
    case ACTION_TYPES.ClientInvoiceApproval:
    case ACTION_TYPES.DeliveryPartnerInvoiceApproval:
    case ACTION_TYPES.Payment:
      return {
        to: APP_ROUTES.projectTabWithSlugs(
          get(project, 'client.slug'),
          project.slug,
          'payments',
        ),
      };
    case ACTION_TYPES.FixInvoiceNumbering:
      return {
        to: APP_ROUTES.projectTabWithSlugs(
          get(project, 'client.slug'),
          project.slug,
          'payments',
        ),
      };
    default:
      return { to: '/projects' };
  }
};

export const floorEntriesValuesTo2Values = entries => {
  return entries.map(entry => {
    const newEntry = {
      ...entry,
    };
    if (entry.amount) {
      newEntry.amount = Math.floor(entry.amount * 100) / 100;
    }
    if (entry.rate) {
      newEntry.rate = Math.floor(entry.rate * 100) / 100;
    }
    if (entry.clientRate) {
      newEntry.clientRate = Math.floor(entry.clientRate * 100) / 100;
    }
    return newEntry;
  });
};

export const calculateMarginAndClientRate = (resource, exchangeRate) => {
  const clientRate = toNumber(
    round(
      resource.clientRate ||
        getDeazyMarginFactor(+resource.margin) * resource.rate * exchangeRate,
      2,
    ),
  );
  const margin = round(
    resource.margin || 100 * (1 - (resource.rate * exchangeRate) / clientRate),
    2,
  );
  return {
    clientRate,
    margin,
  };
};

export const decorateCoreTeamWithHours = (coreTeam, features, exchangeRate) => {
  const allTasks = flatten(
    flatten(
      map(features, feature =>
        map(feature.tasks, task => task.taskToResources),
      ),
    ),
  );
  const groupedHoursByResoruceId = reduce(
    allTasks,
    (resHours, task) => {
      return {
        ...resHours,
        [task.resource.id]: +task.hours + +(resHours[task.resource.id] || 0),
      };
    },
    {},
  );
  return map(coreTeam, resource => ({
    ...resource,
    amount: groupedHoursByResoruceId[resource.id],
    ...calculateMarginAndClientRate(resource, exchangeRate),
  }));
};

export const isFixedPrice = project =>
  project.projectType === PROJECT_TYPES.FIXED_PRICE;

export const isFixedPriceProjectType = project =>
  project.projectType === PROJECT_TYPES.FIXED_PRICE &&
  project.engagementType === ENGAGEMENT_TYPES.PROJECT_BUILD;

export const isRetainerOrTAForFixedPrice = project =>
  project.projectType === PROJECT_TYPES.FIXED_PRICE &&
  (project.engagementType === ENGAGEMENT_TYPES.RETAINED_TEAM ||
    project.engagementType === ENGAGEMENT_TYPES.TEAM_AUGMENTATION);

export const isTAForFixedPrice = project =>
  project.projectType === PROJECT_TYPES.FIXED_PRICE &&
  project.engagementType === ENGAGEMENT_TYPES.TEAM_AUGMENTATION;

export const createSupplierNamesLabel = project => {
  if (project.deazyAsSupplier) {
    return 'Deazy';
  }
  const [primary, ...restSuppliers] = map(
    flatten(partition(project.projectToSuppliers, pts => pts.isPrimary)),
    pts => pts?.supplier?.name || '',
  );
  if (isEmpty(restSuppliers)) {
    return primary;
  }
  return `${primary} + ${join(restSuppliers, ', ')}`;
};

export const isProjectBuild = engagementType =>
  engagementType === ENGAGEMENT_TYPES.PROJECT_BUILD;

export const isProjectCloning = location => {
  if (!isEmpty(qs.parse(location.search))) {
    return true;
  }
  return false;
};
