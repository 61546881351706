import React from 'react';
import qs from 'query-string';
import { Form } from 'react-final-form';
import { StickyFooter } from '@components/StickyFooterCostBox';
import { pick, isEmpty, map, cloneDeep } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from '@chakra-ui/react';
import Spinner from '@styles/Spinner';
import Button from '@styles/Button';
import {
  allocationValidation,
  valdiatePts,
} from '@pages/ProjectsCreate/projectValidation';
import { getPnpResourceCurrencyRate } from '@utils/briefUtils';
import AddSingleDPSection from './AddSingleDPSection';

const validate = values => {
  let errors = {};
  errors = {
    projectToSuppliers: !values.deazyAsSupplier
      ? map(values.projectToSuppliers, pts => ({
          ...valdiatePts(pts, values),
          ...allocationValidation(
            values.projectType,
            values.engagementType,
            pts.allocations,
            'allocation',
            false,
            values.deazyAsClient,
          ),
        }))
      : undefined,
  };
  return errors;
};

const NewDeliveryPartnerToProject = ({
  project,
  projectsStore: {
    addNewDeliveryPartnerToProjectWithAllocations,
    fetchBriefResourcesToMergeToExistingProject,
    isLoadingBriefResourcesToMerge,
    clearBriefResources,
    briefResourcesToMerge,
  },
  proposalsStore: { proposalData, clearProposalData, fetchProposalData },
  briefsStore: { decoratePnPResource },
  routerStore: { history },
}) => {
  const { ppBriefId } = qs.parse(history.location.search);

  React.useEffect(() => {
    if (ppBriefId) {
      fetchBriefResourcesToMergeToExistingProject({ ppBriefId });
      fetchProposalData(ppBriefId);
    }
    return () => {
      clearBriefResources();
      clearProposalData();
    };
  }, [ppBriefId]);

  const initialValues = React.useMemo(() => {
    const base = {
      ...pick(project, [
        'projectType',
        'engagementType',
        'startDate',
        'endDate',
        'deazyAsClient',
        'deazyAsSupplier',
        'totalBasis',
        'currency',
      ]),
      isNewProject: false,
      projectToSuppliers: [{}],
      ppBriefId,
    };

    if (!isEmpty(briefResourcesToMerge)) {
      return {
        ...base,
        projectToSuppliers: map(cloneDeep(briefResourcesToMerge), btos => ({
          supplier: btos.supplier,
          overriddenCurrency: btos.currency,
          allocations: map(btos.resources, resource => {
            const exchangeRate = getPnpResourceCurrencyRate(
              {
                ...resource,
                ppBriefProposal: { overriddenCurrency: resource?.currency },
              },
              {
                deazyProposalInfo: {
                  currencyRateEur: proposalData.currencyRateEur,
                  currencyRateUsd: proposalData.currencyRateUsd,
                },
              },
            );
            return decoratePnPResource(resource, exchangeRate);
          }),
        })),
      };
    }
    return base;
  }, [briefResourcesToMerge, proposalData]);

  if (isLoadingBriefResourcesToMerge) {
    return <Spinner />;
  }

  return (
    <Box maxW="calc(1780px + 80px)" w="100%" mx="auto" px="40px" pb="100px">
      <Form
        initialValues={initialValues}
        validate={validate}
        onSubmit={addNewDeliveryPartnerToProjectWithAllocations}
        render={({ handleSubmit, form, values, submitting }) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              {map(values.projectToSuppliers, (pts, idx) => (
                <AddSingleDPSection
                  key={`add-new-single-dp-${pts?.supplier?.id}`}
                  formApi={form}
                  project={values}
                  pts={pts}
                  fieldPrefix={`projectToSuppliers[${idx}]`}
                />
              ))}
              <StickyFooter display="flex" h="80px" justifyContent="center">
                <Flex maxW="1460px" w="100%" justifyContent="flex-end">
                  <Button
                    size="lg"
                    type="submit"
                    isLoading={submitting}
                    leftIcon={{ name: 'check' }}
                  >
                    Confirm new Allocation
                  </Button>
                </Flex>
              </StickyFooter>
            </form>
          );
        }}
      />
    </Box>
  );
};

export default inject(
  'projectsStore',
  'briefsStore',
  'allocationsStore',
  'routerStore',
  'proposalsStore',
)(observer(NewDeliveryPartnerToProject));
