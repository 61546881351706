import { Box, Flex, FormLabel, Spinner } from '@chakra-ui/react';
import React, { useState } from 'react';
import { noop } from 'lodash-es';

const getWrapperProps = checked => ({
  boxShadow: checked ? 'none' : 'inset 0 0 8px 0 rgba(0, 0, 0, 0.15)',
  bg: checked ? 'blue.500' : 'gray.300',
});

const labelProps = {
  fontFamily: 'WS',
  pb: '0',
  mb: '2px',
  cursor: 'pointer',
};

const Switcher = ({
  isDisabled = false,
  defaultChecked = false,
  onChange,
  leftLabel,
  rightLabel,
  id,
  containerProps,
  preventDefault = false,
  disallowLabelClick = false,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(defaultChecked);
  const wrapperProps = getWrapperProps(checked);

  React.useEffect(() => {
    if (defaultChecked) {
      setChecked(defaultChecked);
    }
  }, [defaultChecked]);

  const toggleAction = async action => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await onChange(action);
      setChecked(action);
    } catch (e) {
      // Display error
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex {...containerProps}>
      {leftLabel && (
        <FormLabel
          pr="12px"
          {...labelProps}
          {...props}
          onClick={() => {
            if (checked && !disallowLabelClick) {
              toggleAction(false);
            }
          }}
        >
          {leftLabel}
        </FormLabel>
      )}
      <Box
        onClick={
          isDisabled
            ? noop
            : e => {
                if (preventDefault) {
                  e.preventDefault();
                }
                toggleAction(!checked);
              }
        }
        id={id}
        w="44px"
        h="20px"
        opacity={isDisabled && '0.4'}
        borderRadius="32px"
        position="relative"
        transition="background-color 500ms linear"
        {...wrapperProps}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        {...props}
      >
        <Box
          bg="#fff"
          w="16px"
          h="16px"
          boxShadow="0 0 4px 0 rgba(71, 181, 189, 0.5)"
          borderRadius="16px"
          position="absolute"
          right={checked ? '2px' : '26px'}
          top="2px"
          transition="right 250ms ease 0s"
          zIndex={1}
          color="white"
          fontSize={8}
        >
          {loading && <Spinner size="sm" color="primary.500" />}
        </Box>
      </Box>
      {rightLabel && (
        <FormLabel
          pl="12px"
          {...labelProps}
          {...props}
          onClick={() => {
            if (!checked && !disallowLabelClick) {
              toggleAction(true);
            }
          }}
        >
          {rightLabel}
        </FormLabel>
      )}
    </Flex>
  );
};

export default Switcher;
