import { Flex } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import Typography from '@styles/Typography';
import InfoAlert from '@components/InfoAlert';
import useMediaQuery from '@hooks/useMediaQuery';
import { getDeazyMarginFactor } from '@utils';
import {
  filter,
  flatten,
  isEmpty,
  isNull,
  map,
  reduce,
  round,
  toNumber,
} from 'lodash-es';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { NEW_PROPOSAL_STATUSES } from '@app/constants';
import { getPnpResourceCurrencyRate } from '@utils/briefUtils';
import React from 'react';
import AdditionalTeamRolesTable from './AdditionalTeamRolesTable';
import ContingencySection from './ContingencySection';
import CoreTeamRolesTable from './CoreTeamRolesTable';
import PlugPlayResourceTable from './PlugPlayResourceTable';
import ProposalExchangeRateBox from './ProposalExchangeRateBox';

const SupplierTeamRolesCard = ({
  proposalsStore: {
    proposalData,
    saveAdditionalTeam,
    saveProposalCoreTeamData,
    deazyCostBreakdown,
  },
  briefsStore: {
    brief: { isPlugAndPlay, ...brief },
  },
  isReadOnly,
  isSubmitting = true,
  mainNameQA,
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const calculateMarginAndClientRate = resource => {
    const currencyRate = isPlugAndPlay
      ? getPnpResourceCurrencyRate(
          resource,
          brief,
          proposalData.currencyRateEur,
          proposalData.currencyRateUsd,
        )
      : proposalData.currencyRate || 1;
    const clientRate = toNumber(
      round(
        resource.clientRate ||
          getDeazyMarginFactor(+resource.margin) * resource.rate * currencyRate,
        2,
      ),
    );
    const margin = round(
      resource.margin ||
        100 * (1 - (resource.rate * currencyRate) / clientRate),
      2,
    );
    return {
      clientRate,
      margin,
    };
  };

  const additionalTeamRoles = React.useMemo(() => {
    return map(toJS(proposalData.additionalTeam), resource => ({
      ...resource,
      amount: +resource.timeHours,
      ...calculateMarginAndClientRate(resource),
      autoMargin: isNull(resource.margin),
      autoClientRate: isNull(resource.clientRate),
    }));
  }, [proposalData, brief]);

  const coreTeamRoles = React.useMemo(() => {
    const { coreTeam, features } = proposalData;
    const allTasks = flatten(
      flatten(
        map(features, feature =>
          map(feature.tasks, task => toJS(task.taskToResources)),
        ),
      ),
    );
    const groupedHoursByResoruceId = reduce(
      allTasks,
      (resHours, task) => {
        return {
          ...resHours,
          [task.resource.id]: +task.hours + +(resHours[task.resource.id] || 0),
        };
      },
      {},
    );
    return map(coreTeam, resource => ({
      ...resource,
      amount: groupedHoursByResoruceId[resource.id],
      autoMargin: isNull(resource.margin),
      autoClientRate: isNull(resource.clientRate),
      ...calculateMarginAndClientRate(resource),
    }));
  }, [proposalData, brief]);

  return (
    <WhiteCard
      border={isSmallerScreen ? 'none' : '1px solid'}
      borderColor="dark.mid"
      p={[0, 0, 0, '32px 24px']}
      bg={isSmallerScreen ? 'primary.100' : '#fff'}
    >
      <Flex justifyContent="space-between">
        <Typography variant="h2" mb="24px">
          Selected candidates
        </Typography>
        <ProposalExchangeRateBox isReadOnly={isReadOnly} />
      </Flex>
      <InfoAlert
        label="Rates are dependent of role and seniority"
        {...(isSmallerScreen && {
          bg: '#fff',
          mb: '12px',
        })}
      />
      {isPlugAndPlay && (
        <PlugPlayResourceTable
          isReadOnly={isReadOnly}
          mainNameQA={mainNameQA}
          initialValues={{
            resources: filter(
              map(proposalData.resources, resource => ({
                ...resource,
                autoMargin: isNull(resource.margin),
                autoClientRate: isNull(resource.clientRate),
                ...(resource.ppBriefProposal.status ===
                  NEW_PROPOSAL_STATUSES.WITH_DP && { isReadOnly: true }),
                ...calculateMarginAndClientRate(resource),
              })),
              r => (isPlugAndPlay ? true : r.selected),
            ),
          }}
        />
      )}
      {!isPlugAndPlay && (
        <>
          {!isEmpty(coreTeamRoles) && (
            <CoreTeamRolesTable
              isReadOnly={isReadOnly}
              initialValues={{ roles: coreTeamRoles }}
              onSubmit={saveProposalCoreTeamData(!isSubmitting)}
              mainNameQA={mainNameQA}
            />
          )}
          {!isEmpty(additionalTeamRoles) && (
            <AdditionalTeamRolesTable
              isReadOnly={isReadOnly}
              initialValues={{ additionalTeam: additionalTeamRoles }}
              onSubmit={saveAdditionalTeam(!isSubmitting)}
              mainNameQA={mainNameQA}
            />
          )}
          <ContingencySection
            isReadOnly={isReadOnly}
            initialValues={{
              supplierCost: deazyCostBreakdown.data.supplierContingencyValue,
              supplierContingencyMargin:
                deazyCostBreakdown.data.supplierContingencyMargin || 0,
            }}
            mainNameQA={mainNameQA}
          />
        </>
      )}
    </WhiteCard>
  );
};

export default inject(
  'proposalsStore',
  'briefsStore',
)(observer(SupplierTeamRolesCard));
