import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import {
  COLORS,
  FONTS,
  getInputVariantProps,
  INPUT_VARIANTS,
} from '@styles/theme';
import { find } from 'lodash-es';
import ReactSelect, { components } from 'react-select';
import React from 'react';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import Icon from '@components/Icon';
import Spinner from '@styles/Spinner';
import FormError from './FormError';
import FormLabel from './FormLabel';

export const Styles = styled(({ hasError, isSmallerScreen, ...props }) => (
  <Box {...props} />
))`
  .select__control {
    padding: 18px 20px 18px 24px;
    border-radius: 32px;
    position: relative;
    box-shadow: none;
    border: 2px solid
      ${({ hasError, theme }) =>
        hasError ? theme.colors.red.solid : 'transparent'};
    cursor: ${({ cursor = 'text' }) => cursor};
    :hover {
      border: 2px solid
        ${({ hasError, theme }) =>
          hasError ? theme.colors.red.solid : 'transparent'};
    }
  }
  .select__value-container,
  .select__single-value {
    padding: 0px;
    margin: 0px;
  }
  .select__placeholder {
    font-size: ${({ isSmallerScreen }) => (isSmallerScreen ? '15px' : '16px')};
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.dark.mid};
  }
  .select__control--menu-is-open {
    border-radius: 0px;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    .arrow-down {
      transform: rotate(180deg);
    }
  }
  .select__single-value {
    font-size: ${({ isSmallerScreen }) => (isSmallerScreen ? '15px' : '16px')};
    font-family: ${FONTS.WS};
  }
  .select__option {
    font-size: 14px;
    font-family: ${FONTS.WM};
    padding: 12px 10px;
    border-radius: 8px;
    :not(:last-of-type) {
      margin-bottom: 7px;
    }
    :active {
      background: ${COLORS.aqua.mid};
    }
  }
  .select__option--is-focused {
    background: ${COLORS.aqua.light};
    cursor: pointer;
  }
  .select__option--is-selected {
    background: ${COLORS.aqua.mid};
    color: #000;
  }
  .select__menu-list {
    margin: 16px;
    border-top: 1px solid ${COLORS.dark.mid};
  }
  .select__menu {
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    box-shadow: none;
    margin-top: 0px;
    top: 65%;
    z-index: 100;
    border: 2px solid
      ${({ hasError, theme }) =>
        hasError ? theme.colors.red.solid : 'transparent'};
    border-top: 2px solid transparent;
  }
  .select__control--is-disabled {
    background-color: transparent;
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: all;
  }
  ${({ variant, theme }) => {
    if (variant === INPUT_VARIANTS.NORMAL) {
      return `
        .select__control {
          padding: 0 12px;
          height: 48px;
          border-radius: 8px;
          border-color: ${theme.colors.dark.mid};
          border: 1px solid ${theme.colors.dark.mid};
          border-style: solid;
          :hover {
            border: 1px solid ${theme.colors.dark.mid};
          }
        }
        .select__menu {
          border: 1px solid ${theme.colors.dark.mid};
          border-top: 1px solid transparent;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          top: 70%;
        }
        .select__option {
          font-size: 15px;
          font-family: ${FONTS.WS};
          padding: 9px 8px;
          border-radius: 8px;
          :not(:last-of-type) {
            margin-bottom: 0px;
          }
          :active {
            background: ${COLORS.blue.mid};
          }
        }
        .select__option--is-focused {
          background: ${COLORS.blue.light};
        }
        .select__option--is-selected {
          background: ${COLORS.blue.mid};
        }
        .select__menu-list {
          margin: 4px;
        }
        .select__control--menu-is-open {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
      `;
    }
    if (variant === INPUT_VARIANTS.CONTAINER_FILLED) {
      return `
        height: 100%;
        .form-select {
          height: 100%;
        }
        .select__control {
          height: 100%;
          border-radius: 0px;
          border: none;
          :hover {
            border: none;
          }
        }
        .select__menu {
          background-color: #fff;
          border: 1px solid ${theme.colors.dark.mid};
          border-radius: 0;
          top: 100%;
          width: calc(100% + 2px);
          left: -1px;
        }
        .select__option {
          font-size: 15px;
          font-family: ${FONTS.WS};
          padding: 9px 8px;
          border-radius: 0px;
          :not(:last-of-type) {
            margin-bottom: 0px;
          }
          :active {
            background: ${COLORS.blue.mid};
          }
        }
        .select__option--is-focused {
          background: ${COLORS.blue.light};
          border-radius: 0;
        }
        .select__option--is-selected {
          background: ${COLORS.blue.mid};
          border-radius: 0;
        }
        .select__menu-list {
          border-top: none;
          margin: 4px;
        }
        .select__control--menu-is-open {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      `;
    }
    return '';
  }}
`;

const getArrowColor = (variant, isSmallerScreen) => {
  if (
    variant === INPUT_VARIANTS.CONTAINER_FILLED ||
    (variant === INPUT_VARIANTS.NORMAL && isSmallerScreen)
  )
    return 'blue.solid';
  if (variant === INPUT_VARIANTS.NORMAL && !isSmallerScreen) return 'black';
  return 'aqua.solid';
};

const Option = ({ children, ...props }) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <span data-test-id={data.value} />
      {children}
    </components.Option>
  );
};

const Select = props => {
  const {
    placeholder = 'Select...',
    options = [],
    defaultValue,
    styleProps = {},
    input = {},
    meta = {},
    label,
    isLoading,
    onAfterChange,
    variant = INPUT_VARIANTS.NORMAL,
    ...rest
  } = props;

  const { labelProps } = getInputVariantProps(variant);
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  const arrowColor = getArrowColor(variant, isSmallerScreen);
  const value = find(options, { value: defaultValue || input.value });
  const hasError =
    (meta.touched || meta.submitFailed) && (meta.error || meta.submitError);

  return (
    <Styles
      variant={variant}
      w="100%"
      cursor="pointer"
      {...styleProps}
      hasError={hasError}
      isSmallerScreen={isSmallerScreen}
      data-test-id={rest['data-test-id']}
    >
      <FormLabel name={input.name} label={label} {...labelProps} />
      {isLoading ? (
        <Spinner variant="small" />
      ) : (
        <ReactSelect
          placeholder={placeholder}
          className="form-select"
          classNamePrefix="select"
          components={{
            IndicatorSeparator: () => null,
            Option,
            IndicatorsContainer: () => (
              <Icon
                className="arrow-down"
                name="chevronDown"
                fontSize={12}
                color={
                  hasError && variant !== INPUT_VARIANTS.NORMAL
                    ? 'red.solid'
                    : arrowColor
                }
              />
            ),
          }}
          isClearable={false}
          isSearchable={false}
          options={options}
          {...input}
          onChange={({ value: v }) => {
            input.onChange(v);
            if (onAfterChange) {
              onAfterChange(v);
            }
          }}
          value={value}
          {...rest}
        />
      )}
      <FormError meta={meta} />
    </Styles>
  );
};

export default Select;
