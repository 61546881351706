import { Flex } from '@chakra-ui/react';
import WhiteCard from '@components/WhiteCard';
import useMediaQuery from '@hooks/useMediaQuery';
import { breakpoint } from '@styles/breakpoints';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { filter, find, get, map } from 'lodash-es';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import CreateForm from './CreateForm';

const BriefPlugAndPlayCreate = ({
  briefsStore: {
    createPlugAndPlayBrief,
    brief,
    updatePlugAndPlayBrief,
    clearBrief,
    fetchBrief,
    isLoading,
  },
  usersStore: { profile, isClient, isProfileLoaded },
  authStore: { setIsForbidden },
  isEditPlugAndPlay,
  match: {
    params: { id: briefId },
  },
}) => {
  const customerId = isClient ? get(profile, 'customer.id', null) : null;
  const isSmallerScreen = useMediaQuery(breakpoint.lg);

  const getInitialValues = () => {
    if (customerId) {
      return {
        customer: { id: customerId },
        ...(isClient && { clientLead: toJS(profile) }),
      };
    }
    return {};
  };

  React.useEffect(() => {
    if (!isEditPlugAndPlay) {
      clearBrief();
    }
    if (isEditPlugAndPlay) {
      fetchBrief(briefId, true);
    }
    return () => {
      clearBrief();
    };
  }, [briefId, isEditPlugAndPlay]);

  React.useEffect(() => {
    if (brief.id) {
      if (!brief.isEditable && isEditPlugAndPlay) {
        setIsForbidden(true);
      }
    }
  }, [brief.id, isEditPlugAndPlay, brief.isEditable]);

  if (isLoading) {
    return <Spinner variant="large" />;
  }

  const mappedResources = map(toJS(brief.resources), item => {
    const technologiesAsObject = map(item.technologies, t => ({
      label: t,
      value: t,
    }));
    return {
      ...item,
      technologies: technologiesAsObject,
    };
  });

  return (
    <Flex
      flexDir="column"
      m="0 auto"
      p={isSmallerScreen ? '24px 16px' : '40px'}
      maxW="1280px"
      {...(isSmallerScreen && { bg: '#fff', pb: '168px' })}
    >
      <WhiteCard
        mt="0px"
        p={isSmallerScreen ? 0 : '32px'}
        mb="40px"
        {...(isSmallerScreen && { bg: 'transparent', mb: '24px' })}
      >
        <Typography variant="h2" mb={isSmallerScreen ? 3 : 4}>
          Team augmentation brief
        </Typography>
        <Typography>
          This is the basic information we require to give a quote. As such, all
          fields in this section are required.
        </Typography>
      </WhiteCard>
      <CreateForm
        initialValues={
          briefId
            ? {
                ...toJS(brief),
                originalName: brief.name,
                resources: mappedResources,
              } // to omit weird mobx warnings
            : getInitialValues()
        }
        briefId={briefId}
        onSubmit={values => {
          const resourcesToSend = map(values.resources, r => {
            const formatArray = value => {
              if (find(value, item => item.value)) {
                return map(r.technologies, 'value');
              }
              return value;
            };

            return {
              ...r,
              technologies: formatArray(r.technologies),
            };
          });
          if (!briefId) {
            return createPlugAndPlayBrief(values);
          }
          return updatePlugAndPlayBrief(briefId, {
            ...values,
            resources: filter(resourcesToSend, item => item.technology),
          });
        }}
        customerId={customerId}
        isClient={isClient}
        isProfileLoaded={isProfileLoaded}
        mainNameQA="brief-team-augmentation-create"
      />
    </Flex>
  );
};

export default inject(
  'usersStore',
  'authStore',
  'briefsStore',
)(observer(BriefPlugAndPlayCreate));
