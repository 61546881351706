import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Field, FormSpy } from 'react-final-form';
import { INPUT_VARIANTS } from '@styles/theme';
import { useLocation } from 'react-router';
import { isProjectCloning } from '@utils/projectUtils';
import { find, get, isNumber } from 'lodash-es';
import { CURRENCIES_SYMBOLS } from '@app/constants';
import { API } from '@app/api';
import AutoCalcCell from '../AllocationTable/AutoCalcCell';
import ActionButton from '../AllocationTable/ActionButton';
import MaskedTextInput from '../forms/MaskedTextInput';
import AsyncAutocomplete from '../forms/AsyncAutocomplete';
import TextInput from '../forms/TextInput';
import AllocationEntryDatesFields from '../AllocationTable/AllocationEntryDatesFields';

const DeazyAllocationRow = ({
  allocationRolesOptions,
  allocationIdx,
  allocationId,
  canBeDeleted,
  deleteRow,
  addRow,
  mainNameQA,
  disabledFields,
  fieldName,
  borderDateForAllocation,
  disallowRowChange,
  resourceAsText = false,
  isManualAddingRow = false,
  isFixedPrice = false,
  isRetainerOrTAForFixedPrice,
  isEditing,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const location = useLocation();

  const disableEditing = (!isEditing && isManualAddingRow) || isOpen;

  const cannotRemoveExistingRow =
    isNumber(allocationId) && isFixedPrice && !isProjectCloning(location);

  const isOldRow = isNumber(allocationId);

  return (
    <Box
      w="100%"
      position="relative"
      data-test-id={`${mainNameQA}--${fieldName}-row`}
    >
      <ActionButton
        deleteRow={deleteRow(allocationId)}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        canBeDeleted={canBeDeleted}
        addRow={addRow}
        disallowRowChange={disallowRowChange}
        isManualAddingRow={isManualAddingRow}
        cannotRemoveExistingRow={cannotRemoveExistingRow}
        mainNameQA={mainNameQA}
      />
      <Flex
        borderRadius="8px"
        w="100%"
        h="72px"
        mb="12px"
        bg="#fff"
        opacity={isOpen ? 0.5 : 1}
        pl="40px"
        border="1px solid"
        borderColor="dark.mid"
        borderRight="none"
        borderTopLeftRadius="8px"
        borderBottomLeftRadius="8px"
      >
        <Box
          alignSelf="center"
          flex={1}
          minW="100px"
          h="70px"
          borderRight="1px solid"
          borderColor="dark.mid"
        >
          {resourceAsText ? (
            <Field
              name={`${fieldName}[${allocationIdx}].resourceName`}
              data-test-id={`${mainNameQA}--${fieldName}[${allocationIdx}].resourceName`}
              placeholder="Type manager"
              component={TextInput}
              variant={INPUT_VARIANTS.CONTAINER_FILLED}
              disabled={
                find(disabledFields, field => field === 'resourceName') ||
                disableEditing
              }
            />
          ) : (
            <Field
              name={`${fieldName}[${allocationIdx}].manager`}
              data-test-id={`${mainNameQA}--${fieldName}[${allocationIdx}].manager`}
              placeholder="Select manager"
              variant={INPUT_VARIANTS.CONTAINER_FILLED}
              {...(find(disabledFields, field => field === 'manager')
                ? { disabled: true, component: TextInput }
                : {
                    component: AsyncAutocomplete,
                    getOptionsFn: API.searchPMs,
                    customOptionMap: option => ({
                      value: option,
                      label: `${option.fullname} <${option.email}>`,
                    }),
                  })}
            />
          )}
        </Box>
        <AllocationEntryDatesFields
          isStartDateDisabled={find(
            disabledFields,
            field => field === 'startDate',
          )}
          isEndDateDisabled={find(disabledFields, field => field === 'endDate')}
          endDateFieldName={`${fieldName}[${allocationIdx}].endDate`}
          startDateFieldName={`${fieldName}[${allocationIdx}].startDate`}
          mainNameQA={mainNameQA}
          borderDateForAllocation={borderDateForAllocation}
          isNotCloning={!isProjectCloning(location)}
          isOldRow={isOldRow}
        />
        <Box
          alignSelf="center"
          w="15%"
          borderRight="1px solid"
          borderColor="dark.mid"
          h="70px"
        >
          <Field
            name={`${fieldName}[${allocationIdx}].amount`}
            data-test-id={`${mainNameQA}--${fieldName}[${allocationIdx}].amount`}
            variant={INPUT_VARIANTS.CONTAINER_FILLED}
            component={MaskedTextInput}
            options={allocationRolesOptions}
            placeholder="0.00"
            disabled={
              !isRetainerOrTAForFixedPrice &&
              (disableEditing || cannotRemoveExistingRow)
            }
            decimalScale={2}
            fixedDecimalScale
          />
        </Box>
        <Box
          alignSelf="center"
          w="15%"
          borderRight="1px solid"
          borderColor="dark.mid"
          h="70px"
        >
          <Field name="currency" subscription={{ value: true }}>
            {({ input: { value: currency } }) => (
              <Field
                name={`${fieldName}[${allocationIdx}].rate`}
                data-test-id={`${mainNameQA}--${fieldName}[${allocationIdx}].rate`}
                variant={INPUT_VARIANTS.CONTAINER_FILLED}
                component={MaskedTextInput}
                prefix={CURRENCIES_SYMBOLS[currency]}
                decimalScale={2}
                fixedDecimalScale
                placeholder="Input rate"
                disabled={
                  !isRetainerOrTAForFixedPrice &&
                  (find(disabledFields, field => field === 'rate') ||
                    disableEditing ||
                    cannotRemoveExistingRow)
                }
              />
            )}
          </Field>
        </Box>
        <Box alignSelf="center" w="20%" h="70px">
          <FormSpy subscription={{ values: true }}>
            {({ values }) => {
              const { rate, amount } = get(
                values,
                `${fieldName}[${allocationIdx}]`,
                {},
              );
              return (
                <AutoCalcCell
                  borderTopRightRadius="8px"
                  borderBottomRightRadius="8px"
                  w="100%"
                  borderLeft="none"
                  data-test-id={`${mainNameQA}--${fieldName}[${allocationIdx}].cost`}
                  rate={rate}
                  currency={values.currency}
                  amount={amount}
                />
              );
            }}
          </FormSpy>
        </Box>
      </Flex>
    </Box>
  );
};
export default DeazyAllocationRow;
