import {
  DEFAULT_NEW_PROPOSAL_STATUS_MESSAGE,
  NEW_PROPOSAL_STATUSES,
  MAIN_NAMES_QA,
} from '@app/constants';
import { Flex, useBoolean, useDisclosure } from '@chakra-ui/react';
import BriefActions from '@components/BriefActions';
import DiscardBriefModal from '@pages/BriefsCreate/DiscardBriefModal';
import SuccessModal from '@pages/BriefsCreate/SuccessModal';
import { APP_ROUTES } from '@routes';
import Typography from '@styles/Typography';
import { isEmpty, find } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import Button from '@styles/Button';
import { useHistory } from 'react-router';
import EmptyList from '@components/EmptyList';
import Icon from '@components/Icon';
import useProposalVersions from '@hooks/useProposalVersions';
import Spinner from '@styles/Spinner';
import ConditionalWrapper from '@components/ConditionalWrapper';
import WhiteCard from '@components/WhiteCard';
import useMediaQuery from '@hooks/useMediaQuery';
import Select from '@components/forms/_common/Select';
import ClientAcceptProposalModal from '../ClientProposal/ClientAcceptProposalModal';
import ClientDeclineProposalModal from '../ClientProposal/ClientDeclineProposalModal';
import DiscountDisclaimer from '../ClientProposal/DiscountDisclaimer';
import ProposalQueriesList from '../ClientProposal/ProposalQueriesList';
import ProposalStatusDropdown from '../Proposal/ProposalStatusDropdown';
import RateConfirmationModal from '../Proposal/RateConfirmationModal';
import ClientPandPProposalSummary from './ClientPandPProposalSummary';
import ClientPandPProposalTable from './ClientPandPProposalTable';
import PlugAndPlayProposalForm from './PlugAndPlayProposalForm';
import EditProposalConfirmationModal from './EditProposalConfirmationModal';

const Proposal = ({
  briefsStore: { brief, fetchBrief },
  usersStore: {
    supplierProfilePrimaryCurrency,
    profile,
    isTeamAdmin,
    isAdminOrDL,
    isClient,
  },
  proposalsStore: {
    clearPnpPersistedProposalFormData,
    clientProposalData,
    createOrResubmitProposal,
    fetchProposalData,
    proposalData,
    fetchClientProposalData,
    clearProposalData,
    validateProposalRates,
    decoratedPnPResources,
    proposalId: pId,
    isLoadingProposal,
    pnpPersistedProposalFormData,
    initializePnpPersistedProposalFormData,
    pnpSupplierProposals,
  },
  match: { params },
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');
  const [isEditProposalModalOpen, setIsEditProposalModalOpen] = useBoolean();
  const [isEditingMode, setIsEditingMode] = useBoolean(false);

  const proposalId = params.proposalId || pId;
  const { isPlugAndPlay } = brief;
  const statusMessage = React.useMemo(() => {
    if (
      (isEmpty(proposalData) ||
        proposalData.status === NEW_PROPOSAL_STATUSES.WITH_DP) &&
      !isLoadingProposal &&
      isTeamAdmin
    ) {
      return DEFAULT_NEW_PROPOSAL_STATUS_MESSAGE;
    }
    if (proposalData.statusMessage && proposalData.id) {
      return proposalData.statusMessage;
    }
    return null;
  }, [proposalData, isLoadingProposal, isTeamAdmin]);

  const {
    isOpen: isRateConfirmationOpen,
    onOpen: onRateConfirmationOpen,
    onClose: onRateConfirmationClose,
  } = useDisclosure();

  const { isOpen: isSuccessOpen, onOpen: onSuccessOpen } = useDisclosure();

  const onSubmit = values => {
    if (!isRateConfirmationOpen) {
      const hasError = validateProposalRates(values);
      if (hasError) {
        return onRateConfirmationOpen();
      }
    }

    return createOrResubmitProposal(
      {
        briefId: brief.id,
        isPlugAndPlay,
        ...values,
      },
      () => {
        onSuccessOpen();
        onRateConfirmationClose();
      },
    );
  };

  const history = useHistory();
  const {
    isOpen: isDiscardOpen,
    onOpen: onDiscardOpen,
    onClose: onDiscardClose,
  } = useDisclosure();

  const {
    currentVersion,
    versionOptions,
    setCurrentVersion,
    currentProposal: proposal,
  } = useProposalVersions(pnpSupplierProposals);

  const isReadOnly =
    !isEditingMode &&
    ((proposalData.status &&
      proposalData.status !== NEW_PROPOSAL_STATUSES.WITH_DP &&
      isTeamAdmin) ||
      brief.isClosed ||
      (!isEmpty(brief?.supplierProposal?.proposal) && isAdminOrDL));

  const resources = React.useMemo(() => {
    if (isTeamAdmin) {
      if (proposalId) {
        return decoratedPnPResources;
      }
      return brief.resources;
    }
    return proposal.resources;
  }, [proposalData, proposal, brief, decoratedPnPResources]);

  React.useEffect(() => {
    if (
      pnpPersistedProposalFormData.briefId &&
      brief.id &&
      pnpPersistedProposalFormData.briefId !== brief.id
    ) {
      clearPnpPersistedProposalFormData();
    }
  }, [brief.id, pnpPersistedProposalFormData.briefId]);

  React.useEffect(() => {
    const isProposalEmptyAndReady = !!(
      (isEmpty(pnpPersistedProposalFormData) &&
        currentVersion &&
        proposalData.id) ||
      (isEmpty(pnpSupplierProposals) && isEmpty(pnpPersistedProposalFormData))
    );

    const shouldBeReinitialized =
      !isEmpty(pnpPersistedProposalFormData) &&
      pnpPersistedProposalFormData.createdAt !== proposalData.createdAt &&
      proposalData.id;

    if (isProposalEmptyAndReady || shouldBeReinitialized) {
      initializePnpPersistedProposalFormData({
        id: proposalData.id,
        briefId: brief.id,
        resources,
        overriddenCurrency:
          proposal.overriddenCurrency ||
          supplierProfilePrimaryCurrency ||
          brief?.supplierProposal?.primarySupplierCurrency,
        currencies: profile?.supplier?.paymentDetails,
        startDate: proposal.startDate
          ? moment.utc(proposal.startDate).format('DD MMM YYYY')
          : null,
        ppBriefToSupplierId: brief?.supplierProposal?.id,
        createdAt: proposalData.createdAt,
      });
    }
  }, [
    brief,
    proposalData,
    proposal,
    currentVersion,
    pnpPersistedProposalFormData,
  ]);

  let submit;

  const {
    isOpen: isClientAcceptModalOpen,
    onClose: onClientAcceptModalClose,
    onOpen: onClientAcceptModalOpen,
  } = useDisclosure();
  const {
    isOpen: isClientDeclineModalOpen,
    onClose: onClientDeclineModalClose,
    onOpen: onClientDeclineModalOpen,
  } = useDisclosure();

  React.useEffect(() => {
    // This means it's in the middle of editing
    if (
      pnpPersistedProposalFormData.id &&
      find(pnpSupplierProposals, p => p.id === pnpPersistedProposalFormData.id)
    ) {
      setCurrentVersion(pnpPersistedProposalFormData.id);
      setIsEditingMode.on();
    }
  }, []);

  React.useEffect(() => {
    if ((isClient || isAdminOrDL) && proposalId) {
      fetchClientProposalData(brief.id, proposalId);
    }
    if ((isTeamAdmin || isAdminOrDL) && currentVersion) {
      fetchProposalData(brief.id, currentVersion, true);
    }
  }, [brief.id, currentVersion, proposalId]);

  React.useEffect(() => {
    return () => {
      clearProposalData();
    };
  }, []);

  if (isLoadingProposal) {
    return <Spinner />;
  }

  if (
    isPlugAndPlay &&
    isEmpty(clientProposalData) &&
    !isLoadingProposal &&
    isClient
  ) {
    return (
      <EmptyList
        label="Proposal is not created yet"
        imagePath="/images/candidates.svg"
      />
    );
  }

  const mainNameQA = MAIN_NAMES_QA.PLUG_AND_PLAY_PROPOSAL_FORM;

  return (
    <>
      {!isClient ? (
        <>
          <EditProposalConfirmationModal
            isOpen={isEditProposalModalOpen}
            onClose={setIsEditProposalModalOpen.off}
            onConfirm={() => {
              setIsEditingMode.on();
              setIsEditProposalModalOpen.off();
            }}
          />
          <BriefActions
            brief={brief}
            infoBoxMessage={statusMessage}
            proposal={proposal}
            showAssignedBadge={false}
            showProposalActions={
              (isEmpty(brief?.supplierProposal?.proposal) ||
                proposal?.status === NEW_PROPOSAL_STATUSES.WITH_DP) &&
              !isSmallerScreen
            }
            createProposal={() => {
              submit();
            }}
            rejectProposal={onDiscardOpen}
            mainNameQA={mainNameQA}
            isSmallerScreen={isSmallerScreen}
          />
          <ProposalQueriesList proposalId={proposalId} />
          {!isEditingMode &&
            proposalData.status === NEW_PROPOSAL_STATUSES.WITH_DEAZY && (
              <Button
                variant="outline"
                display="flex"
                ml="auto"
                mb={['12px', '12px', '12px', 0]}
                leftIcon={{ mr: 0, mt: '2px', name: 'refresh' }}
                onClick={() => {
                  if (
                    proposalData.status === NEW_PROPOSAL_STATUSES.WITH_DEAZY
                  ) {
                    return setIsEditProposalModalOpen.on();
                  }
                  return setIsEditingMode.on();
                }}
              >
                Make an update
              </Button>
            )}
          <Flex
            justifyContent="space-between"
            flexDir={[
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'row',
            ]}
            alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
          >
            <Flex
              mb={['8px', '8px', '8px', '24px']}
              mt={['16px', '16px', '16px']}
              flexDir="column"
            >
              <Typography variant="h2">
                Your team augmentation proposal
              </Typography>
              {isEditingMode && (
                <Select
                  styleProps={{ mt: '12px' }}
                  leftIcon={<Icon name="version" fontSize={20} mr="6px" />}
                  addCreatedAtLabel
                  input={{
                    value: currentVersion,
                    onChange: setCurrentVersion,
                  }}
                  options={versionOptions}
                  meta={{}}
                />
              )}
            </Flex>
            <ConditionalWrapper
              condition={!isSmallerScreen}
              wrapper={children => (
                <WhiteCard p="12px" mt="0px" w="auto" display="flex">
                  {children}
                </WhiteCard>
              )}
            >
              <ProposalStatusDropdown
                useOutsideBg
                useAltLabel={!isAdminOrDL}
                isPlugAndPlay={isPlugAndPlay}
                mainNameQA={mainNameQA}
                buttonProps={{
                  mt: '0px',
                  ml: '0px',
                }}
              />
            </ConditionalWrapper>
          </Flex>

          <PlugAndPlayProposalForm
            onSubmit={onSubmit}
            isReadOnly={isReadOnly}
            isEditingMode={isEditingMode}
            mainNameQA={mainNameQA}
            initialValues={pnpPersistedProposalFormData}
            setSubmit={handleSubmit => {
              submit = handleSubmit;
            }}
            onDiscard={onDiscardOpen}
          />
          <DiscardBriefModal
            isOpen={isDiscardOpen}
            isProposal
            onClose={onDiscardClose}
            onDiscardBrief={() => {
              history.push(APP_ROUTES.briefs);
            }}
          />
          <SuccessModal
            isOpen={isSuccessOpen}
            onClose={async () => {
              history.push(
                APP_ROUTES.briefPlugAndPlayTab(brief.id, 'overview'),
              );
              clearPnpPersistedProposalFormData();
              await fetchBrief(brief.id, true);
            }}
          />
          <RateConfirmationModal
            isOpen={isRateConfirmationOpen}
            onClose={onRateConfirmationClose}
            onConfirm={() => {
              submit();
            }}
            isLoading={brief.isCreatingProposal}
          />
        </>
      ) : (
        <>
          <BriefActions
            brief={{ ...brief }}
            showAssignedBadge={false}
            proposal={proposalData}
            showProposalActions={false}
            showClientProposalActions
            disableClientProposalActions={
              clientProposalData.status !== NEW_PROPOSAL_STATUSES.WITH_CLIENT
            }
            onAcceptClientProposal={onClientAcceptModalOpen}
            onDeclineClientProposal={onClientDeclineModalOpen}
            mainNameQA={mainNameQA}
          />
          <ProposalQueriesList proposalId={params.proposalId} />
          <DiscountDisclaimer clientProposalData={clientProposalData} />
          <ClientPandPProposalTable
            proposalData={clientProposalData}
            mainNameQA={mainNameQA}
          />
          <ClientPandPProposalSummary
            isLoadingProposal={isLoadingProposal}
            proposalData={clientProposalData}
            mainNameQA={mainNameQA}
          />
          <ClientAcceptProposalModal
            isOpen={isClientAcceptModalOpen}
            onClose={onClientAcceptModalClose}
            proposalId={proposalId}
          />
          <ClientDeclineProposalModal
            isOpen={isClientDeclineModalOpen}
            onClose={onClientDeclineModalClose}
            proposalId={proposalId}
          />
        </>
      )}
    </>
  );
};

export default inject(
  'briefsStore',
  'usersStore',
  'proposalsStore',
)(observer(Proposal));
