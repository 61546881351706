import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const ProjectUnviableModal = ({ isOpen, onClose, isLoading, onCreate }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styleProps={{ p: '20px', maxW: '440px' }}
      closeOnOverlayClick={!isLoading}
      closeOnEsc={!isLoading}
    >
      <Typography variant="h1" color="red.solid" mb="20px">
        Warning
      </Typography>
      <Typography>
        Corrections applied cause the project to be commercially unviable.
      </Typography>
      <Typography mt="12px">Do you want to proceed?</Typography>
      <Stack spacing={3} mt="40px">
        <Button variant="warning" isLoading={isLoading} onClick={onCreate}>
          Create project
        </Button>
        <Button variant="outline" onClick={onClose} isDisabled={isLoading}>
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};

export default ProjectUnviableModal;
