import { Flex, Spinner } from '@chakra-ui/react';
import { RightIconInput } from '@components/forms/MaskedTextInput';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { Field } from 'react-final-form';

const FinalContingencyValue = ({
  currency,
  mainNameQA,
  finalContingencyValue,
  isSmallerScreen,
}) => {
  return (
    <Flex
      alignItems="center"
      mt={isSmallerScreen ? '19px' : '62px'}
      alignSelf="flex-end"
    >
      <Icon
        name={currency?.toLowerCase() || 'gpb'}
        fontSize={20}
        iconTagQA={mainNameQA && `${mainNameQA}--currency`}
        mr="5px"
        color="blue.solid"
      />
      {finalContingencyValue ? (
        <Typography
          alignSelf="center"
          fontSize={18}
          data-test-id={`${mainNameQA}--finalContingencyValue`}
        >
          {finalContingencyValue}
        </Typography>
      ) : (
        <Spinner />
      )}
    </Flex>
  );
};

const ContigencySection = ({
  form,
  finalContingencyValue,
  currency,
  isReadOnly,
  isDeazy = false,
  mainNameQA,
  isSmallerScreen,
}) => (
  <Flex
    border="1px solid"
    borderColor="dark.mid"
    bg="dark.light"
    borderRadius="8px"
    px="16px"
    py="20px"
    mb="12px"
    flexDir="column"
  >
    <Typography variant="h4" mr="auto" mb="26px">
      Add a contingency
    </Typography>
    <Flex justifyContent="space-between">
      <Flex
        flexDirection={isSmallerScreen && 'column'}
        width={isSmallerScreen && '100%'}
      >
        <Field
          containerProps={{
            maxW: isSmallerScreen ? 'auto' : '178px',
            w: '100%',
          }}
          isErrorAbsolute
          component={RightIconInput}
          iconName="percent"
          label="As a percentage"
          name={isDeazy ? 'deazyContingencyPercent' : 'contingencyPercent'}
          data-test-id={`${mainNameQA}--${
            isDeazy ? 'deazyContingencyPercent' : 'contingencyPercent'
          }`}
          isAllowed={({ floatValue }) =>
            (floatValue <= 100 && floatValue > 0) || !floatValue
          }
          decimalScale={2}
          isDisabled={isReadOnly}
          fixedDecimalScale
          changeIfActive
          onAfterChange={v => {
            if (isEmpty(v)) {
              form.change(
                isDeazy ? 'deazyContingencyPercent' : 'contingencyPercent',
                null,
              );
            }
            form.change(
              isDeazy ? 'deazyContingencyValue' : 'contingencyValue',
              null,
            );
          }}
          placeholder="e.g. 10"
        />
        <Typography
          variant={isSmallerScreen ? 'text1' : 'text2'}
          mt={isSmallerScreen ? '16px' : '42px'}
          mx="8px"
          mb={isSmallerScreen && '16px'}
          textAlign={isSmallerScreen && 'center'}
        >
          OR
        </Typography>
        <Field
          containerProps={{
            maxW: isSmallerScreen ? 'auto' : '178px',
            w: '100%',
          }}
          isErrorAbsolute
          label="As an absolute value"
          component={RightIconInput}
          name={isDeazy ? 'deazyContingencyValue' : 'contingencyValue'}
          data-test-id={`${mainNameQA}--${
            isDeazy ? 'deazyContingencyValue' : 'contingencyValue'
          }`}
          isDisabled={isReadOnly}
          changeIfActive
          onAfterChange={v => {
            form.change(
              isDeazy ? 'deazyContingencyPercent' : 'contingencyPercent',
              null,
            );
            if (isEmpty(v)) {
              form.change(
                isDeazy ? 'deazyContingencyValue' : 'contingencyValue',
                null,
              );
            }
          }}
          isAllowed={({ floatValue }) =>
            (floatValue <= 1000000 && floatValue > 0) || !floatValue
          }
          iconName={currency?.toLowerCase() || 'gpb'}
          iconTagQA={mainNameQA && `${mainNameQA}--currency`}
          decimalScale={2}
          fixedDecimalScale
          placeholder="e.g. 2000"
        />
      </Flex>
      {!isSmallerScreen && (
        <FinalContingencyValue
          currency={currency}
          mainNameQA={mainNameQA}
          finalContingencyValue={finalContingencyValue}
          isSmallerScreen={isSmallerScreen}
        />
      )}
    </Flex>
    {isSmallerScreen && (
      <FinalContingencyValue
        currency={currency}
        mainNameQA={mainNameQA}
        finalContingencyValue={finalContingencyValue}
        isSmallerScreen={isSmallerScreen}
      />
    )}
  </Flex>
);

export default ContigencySection;
