import { Flex, Stack } from '@chakra-ui/react';
import Checkbox from '@components/forms/_common/Checkbox';
import TextInput from '@components/forms/_common/TextInput';
import Button from '@styles/Button';
import { required } from '@utils/formValidators';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { MAIN_NAMES_QA } from '@app/constants';
import ChangeRequestRates from './ChangeRequestRates';

const validate = (isTeamAdmin, isTeamMember) => values => {
  const error = {
    rates: 'This is required. Please, fill in the missing fields.',
  };
  const hasNoAmount = !values.amount;
  if (hasNoAmount) {
    return error;
  }
  if (hasNoAmount || (isTeamAdmin && !values.rate)) {
    return error;
  }
  const isNonSupplier = !isTeamAdmin && !isTeamMember;
  if (hasNoAmount || isNonSupplier) {
    if (!values.clientDisabled && !values.clientRate) {
      return error;
    }
    if (!values.supplierDisabled && !values.rate) {
      return error;
    }
  }
  return undefined;
};

const ChangeRequestForm = ({
  isTeamAdmin,
  initialValues,
  isTeamMember,
  onClose,
  onSubmit,
  projectsStore: { project },
}) => {
  return (
    <Form
      validate={validate(isTeamAdmin, isTeamMember)}
      onSubmit={onSubmit}
      initialValues={{
        projectId: project.id,
        clientDisabled: project.deazyAsClient,
        supplierDisabled: project.deazyAsSupplier,
        ...initialValues,
      }}
      render={({ handleSubmit, submitting, form, values }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Field
              name="name"
              component={TextInput}
              placeholder="Enter name"
              label="Name"
              validate={required}
              data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--crNameInput`}
              containerProps={{ mb: '24px' }}
            />
            <Field
              name="description"
              component={TextInput}
              placeholder="Enter description"
              label="Description"
              validate={required}
              data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--crDescriptionInput`}
              containerProps={{ mb: '24px' }}
            />
            <Field
              name="purchaseOrder"
              component={TextInput}
              defaultValue={project.purchaseOrder}
              parse={value => (value === null ? '' : value)}
              placeholder="Enter purchase order"
              data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--crPurchaseOrderInput`}
              label="Purchase order"
              containerProps={{ mb: '24px' }}
            />

            {!isTeamMember && !isTeamAdmin && (
              <Flex my="24px">
                <Field
                  name="supplierDisabled"
                  variant="secondary"
                  component={Checkbox}
                  id="supplier-disabled"
                  bg="black"
                  label="Delivery Partner Disabled"
                  data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--crSupplierDisabled`}
                  labelProps={{ fontSize: '14px' }}
                  containerStyles={{ mr: '16px' }}
                  disabled={values.clientDisabled || project.deazyAsSupplier}
                />
                <Field
                  name="clientDisabled"
                  variant="secondary"
                  component={Checkbox}
                  data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--crClientDisabled`}
                  id="client-disabled"
                  bg="black"
                  label="Client Disabled"
                  disabled={values.supplierDisabled || project.deazyAsClient}
                  labelProps={{ fontSize: '14px' }}
                />
              </Flex>
            )}
            <ChangeRequestRates
              formValues={values}
              formApi={form}
              isTeamAdmin={isTeamAdmin}
            />

            <Stack mt="56px" spacing={3}>
              <Button
                isLoading={submitting}
                type="submit"
                data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--submitButton`}
              >
                {`${values.id ? 'Update' : 'Create'} change request`}
              </Button>
              <Button
                variant="outline"
                isDisabled={submitting}
                onClick={onClose}
                data-test-id={`${MAIN_NAMES_QA.CHANGE_REQUEST}--cancelButton`}
              >
                Cancel
              </Button>
            </Stack>
          </form>
        );
      }}
    />
  );
};

export default inject('crsStore', 'projectsStore')(observer(ChangeRequestForm));
