import { Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import useMediaQuery from '@hooks/useMediaQuery';
import { COLORS } from '@styles/theme';
import Typography from '@styles/Typography';
import React from 'react';

const descriptionStyles = {
  mt: '8px',
  lineHeight: '22px',
};

const DescriptionWithIconBox = ({
  label,
  description,
  icon,
  isActive,
  ...props
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  return (
    <Flex
      p="24px"
      borderRadius="8px"
      maxW="408px"
      bg="#fff"
      h="155px"
      transition="all 0.3s"
      boxShadow={isActive ? '0 0 0 2px #3366ff' : '0 0 0 1px #d9dada'}
      _hover={
        !isActive && {
          boxShadow: `0 0 0 2px ${COLORS.primary[400]}`,
          cursor: 'pointer',
        }
      }
      alignItems={isSmallerScreen && !description && 'center'}
      flexDir="column"
      {...props}
    >
      <Icon
        mb={isSmallerScreen ? '12px' : '20px'}
        color={isActive && COLORS.blue.solid}
        {...icon}
      />
      <Flex flexDir="column">
        <Typography variant="title">{label}</Typography>
        {description && (
          <Typography {...descriptionStyles}>{description}</Typography>
        )}
      </Flex>
    </Flex>
  );
};

export default DescriptionWithIconBox;
