import React from 'react';
import { API } from '@app/api';
import { isEmpty } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import * as DeazyMarketplaceFront from '../../../utils/importMarketplaceUtil';

const DevProfiles = ({
  proposalsStore: { addDevProfileToProposal, pnpPersistedProposalFormData },
  routerStore: { history },
}) => {
  React.useEffect(() => {
    if (isEmpty(pnpPersistedProposalFormData)) {
      history.push('proposal');
    }
  }, []);
  return (
    <DeazyMarketplaceFront.Profiles
      API={API}
      isDevProfilePageView
      addDevProfileToProposal={addDevProfileToProposal}
    />
  );
};

export default inject('proposalsStore', 'routerStore')(observer(DevProfiles));
