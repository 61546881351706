import { Box, Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { find, first, isEmpty, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import DPFeatureSection from '../Proposal/DPFeatureSection';
import FeatureButton from './FeatureButton';

const Stat = ({ label, value, ...props }) => (
  <Flex flexDir="column" {...props}>
    <Typography mb="8px" variant="text2">
      {label}
    </Typography>
    <Typography variant="text1">{value || '-'}</Typography>
  </Flex>
);

const ClientProposalFeatures = ({
  proposalsStore: {
    clientProposalData,
    selectedFeatureId,
    setSelectedFeatureId,
  },
  mainNameQA,

  isSmallerScreen,
}) => {
  const isFullBreakdown =
    clientProposalData?.clientSettings?.featuresBreakdown === 'Full breakdown';
  const isCostPerFeature =
    clientProposalData?.clientSettings?.featuresBreakdown ===
    'Costs per feature';

  const features = React.useMemo(() => {
    return map(clientProposalData.featureBreakdown, f => ({
      ...f,
      isDisabled: !(f.description || f.assumptions || f.risks),
    }));
  }, [clientProposalData.featureBreakdown]);

  React.useEffect(() => {
    if (!isEmpty(features)) {
      const firstF = first(features) || {};

      if (isFullBreakdown) {
        setSelectedFeatureId(firstF.id);
      } else {
        setSelectedFeatureId(firstF.isDisabled ? null : firstF.id);
      }
    }
  }, [features, clientProposalData?.clientSettings?.featuresBreakdown]);

  const shouldShowFeatureDetails = React.useMemo(() => {
    const f = find(features, feature => feature.id === selectedFeatureId);
    if (!f) {
      return false;
    }
    return !f.isDisabled && selectedFeatureId;
  }, [features, selectedFeatureId]);

  const feature = React.useMemo(() => {
    return find(features, f => selectedFeatureId === f.id) || {};
  }, [features, selectedFeatureId]);

  if (isFullBreakdown) {
    return <DPFeatureSection isReadOnly />;
  }

  const mainWrapperDirection = React.useMemo(() => {
    if (!isCostPerFeature) {
      return 'column';
    }
    if (isSmallerScreen && isCostPerFeature) {
      return 'column-reverse';
    }
    return 'row';
  }, [isCostPerFeature]);

  return (
    <Flex justifyContent="space-between" flexDir={mainWrapperDirection}>
      <Flex
        flexWrap="wrap"
        {...(isCostPerFeature && {
          flexDir: 'column',
          w: isSmallerScreen ? '100%' : '44%',
        })}
      >
        {map(features, f => (
          <FeatureButton
            isActive={f.id === selectedFeatureId}
            key={`featuere-client-key-${f.id}`}
            isCostPerFeature={isCostPerFeature}
            onClick={() => setSelectedFeatureId(f.id)}
            currency="GBP"
            w={isCostPerFeature && !isSmallerScreen ? '50%' : '100%'}
            data-test-id={mainNameQA && `${mainNameQA}--featureButton`}
            mainNameQA={mainNameQA}
            {...f}
          >
            {f.name}
          </FeatureButton>
        ))}
      </Flex>
      {shouldShowFeatureDetails && (
        <Flex
          flexDir="column"
          mb={isSmallerScreen && '24px'}
          w={isCostPerFeature && !isSmallerScreen ? '50%' : '100%'}
        >
          <Stat
            mb="40px"
            mt={!isCostPerFeature && '24px'}
            label="Feature description"
            value={feature.description}
            data-test-id={mainNameQA && `${mainNameQA}--featureDescription`}
          />
          <Flex flexDir={isCostPerFeature && 'column'}>
            <Stat
              label="Assumptions"
              value={feature.assumptions}
              w={isCostPerFeature ? '100%' : '49%'}
              mb={isCostPerFeature && '40px'}
              data-test-id={mainNameQA && `${mainNameQA}--featureAssumptions`}
            />
            <Box w="2%" />
            <Stat
              label="Risks"
              value={feature.risks}
              data-test-id={mainNameQA && `${mainNameQA}--featureRisks`}
              w={isCostPerFeature ? '100%' : '49%'}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default inject('proposalsStore')(observer(ClientProposalFeatures));
