/* eslint-disable */

export default `
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Schedule 1 Statement of Work&nbsp;</strong></p>
<p><strong>Dated:&nbsp;</strong></p>
<p><strong>Between:</strong></p>
<p><strong>(1) {{DEAZY_NAME}}</strong><span style="font-weight: 400;"> (trading as Deazy) of {{DEAZY_DETAILS}} (Company No. 10305788) (&ldquo;</span><strong>Deazy</strong><span style="font-weight: 400;">&rdquo;); and</span></p>
<p><span style="background-color: #feff00;"><strong>(2) {{SUPPLIER_NAME}}<span style="font-weight: 400;"> {{SUPPLIER_ADDRESS}} {{COUNTRY}}<span style="background-color: #ffffff;">&nbsp;</span></span><span style="background-color: #ffffff;"><span style="font-weight: 400;">(the "</span>Supplier<span style="font-weight: 400;">").</span></span></strong></span></p>
<p style="padding-left: 40px;">&nbsp;</p>
<p><strong>Background</strong></p>
<p><span style="font-weight: 400;">(A) Pursuant to the Deazy Supplier Agreement agreed between the parties <span style="background-color: #feff00;">{{DATE}}</span>&nbsp;(the &ldquo;<strong>Agreement</strong>&rdquo;) Deazy has appointed the Supplier to provide software development services when required, during the Term.</span></p>
<p><span style="font-weight: 400;">(B) Pursuant to the Agreement, the Deazy is able to request Services (as defined in the Agreement) and the Supplier has agreed to provide such services in accordance with this statement of work (&ldquo;<strong>SOW</strong>&rdquo;).</span></p>
<p>&nbsp;</p>
<p><strong>Term</strong></p>
<ol class="start">
<li><span style="font-weight: 400;">This SOW shall come into force on the date it is executed by its parties <strong>OR</strong> on </span><span style="font-weight: 400; background-color: #feff00;">{{TERM_DATE}}</span><span style="font-weight: 400;"> (the &ldquo;</span><strong>Commencement Date</strong><span style="font-weight: 400;">&rdquo;</span><strong>)</strong></li>
<li><span style="font-weight: 400;">
This SOW shall take effect from the Commencement Date and will continue in effect for a fixed term of <span style="font-weight: 400;"><span style="background-color: #feff00;">{{DURATION}}</span>, until <span style="font-weight: 400;"><span style="background-color: #feff00;">{{PROJECT_END_DATE}}</span>. 
<ol class="nested-1">
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Deazy may terminate this SOW on giving the Supplier written notice of not less than [</span><span style="background-color: #feff00;">thirty (30) days]</span><span style="font-weight: 400;"> to be served at any time.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The Supplier may terminate this SOW on giving Deazy written notice of not less than [<span style="background-color: #feff00;">sixty (60) days</span>] to be served at any time</span></li>
</ol>
</li>
</ol>
<p><span style="font-weight: 400;"><strong>Client</strong></span></p>
<ol class="continue">
<li><span style="font-weight: 400;">The underlying Client receiving the benefit of the Services is </span><span style="font-weight: 400; background-color: #feff00;">{{CLIENT_NAME}}</span><span style="font-weight: 400;">&nbsp;(the &ldquo;</span><strong>Client</strong><span style="font-weight: 400;">&rdquo;) </span></li>
</ol>
<p>&nbsp;</p>
<p><strong>Conflict</strong></p>
<ol class="continue">
<li><span style="font-weight: 400;">If there is an inconsistency between any of the provisions of this SOW and the provisions of the Agreement, the provisions of this SOW shall prevail.</span></li>
<li><span style="font-weight: 400;">If a term is capitalised in this SOW but not defined therein the defined terms of the Agreement shall be applicable to said term.</span></li>
</ol>
<p>&nbsp;</p>
<p><strong>Services</strong></p>
<ol class="continue">
<li><strong>&nbsp;Context:</strong><span style="font-weight: 400;"> The Client wishes to have <span style="background-color: #feff00;">[PROJECT DETAILS] [INCLUDE AS APPROPRIATE developed, tested and ready for go-live]</span> the ("<strong>engagement</strong>")</li>
</ol>
<ol class="continue">
<li><span style="font-weight: 400;"><strong>Project Approach:</strong></span> the services provided in this SOW are on a fixed-price basis to deliver the fixed scope, as outlined in clause 8 below</li>
</li>
</ol>
<ol class="continue">
<li><strong>Scope:</strong> the project should be <span style="background-color: #feff00;">[INCLUDE AS APPROPRIATE scoped, designed, developed, tested and ready for go-live]</span> as outlined in the following Specifications and Designs:
<ol class="nested-1">
<li>Specifications
<ol class="nested-2">
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">[insert specifications]</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">[insert specifications]</span></li>
</ol>
</li>
<li>Designs
<ol class="nested-2">
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #feff00;">[insert designs]</span></li>
</ol>
</li>
</ol>
</li>
</ol>
<ol class="continue">
<li><strong>Delivery schedule:</strong> The Supplier will adequately resource the project so it is ready for go-live no later than <span style="font-weight: 400; background-color: #feff00;">{{PROJECT_END_DATE}}</span>, unless Deazy changes the scope or causes unnecessary delays as a result of Deazy:
<ol class="nested-1">
<li>sharing the final designs by [<span style="font-weight: 400; background-color: #feff00;">Date</span>];</li>
<li>not providing key data for the Project on time;</li>
<li>completing UAT during agreed period of [<span style="font-weight: 400; background-color: #feff00;">dates</span>]; or</li>
<li>not taking decisions during the development process at the required time.</li>
</ol>
</li>
</ol>
<ol class="continue">
<li><strong>Software Development Services:</strong> The Supplier shall provide the following services relating to software development under this SOW:
<ol class="nested-1">
<li>developing the project as outlined in clauses 7 (Project approach), 8 (Scope) and 9 (Delivery schedule); </li>
<li>project managing the development and QA team to deliver the agreed scope according to the agreed timeline, as outlined in clauses 7 (Project approach), 8 (Scope) and 9 (Delivery schedule); </li>
<li>providing quality assurance (QA) services for the implemented deliverables;</li>
<li>providing Go-live support, including triage for production issues and prompt support for hot-fixing bugs; </li>
<li>transfer of data to relevant environments;</li>
<li>assistance to Deazy or the end-client for the deployment of solutions to relevant environments;</li>
<li>submission of the latest code to Deazy’s or the end-client's code repository once the final payment is received by Deazy ("<strong>Code Repository</strong>");</li>
</ol>
</li>
</ol>
<p>together, the "<strong>Services</strong>".</p>
<ol class="continue">
<li><strong>Location of services</strong><span style="font-weight: 400;"> </span><strong>provided:</strong> The Supplier shall work remotely. Any travel required to deliver the Services will be chargeable to the Deazy but all expenses must be approved in advance by Deazy, in both cases in writing.</li>
</ol>
<ol class="continue">
<li><strong>Ways of working:</strong> The parties acknowledge and agree that the following ways of working apply to this statement of work:
</ol>
<table style="border-collapse: collapse; width: 91.0714%; height: 160px;" border="1">
<tbody>
<tr style="height: 22px;">
<td style="width: 24.7704%; height: 22px;"><strong>Development Sprints</strong></td>
<td style="width: 54.8007%; height: 22px;">
<p><span style="font-weight: 400;">Items of work will be collected into functional delivery groups of ten (10) business days ("Sprints").&nbsp;</span></p>
<p><span style="font-weight: 400;">The content of each Sprint will be approved by the Authorised Person from the Client or the Deazy product manager during Sprint planning.</span></p>
<p><span style="font-weight: 400;">The Client agrees to;</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">review and approve ongoing design work</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">attend sprint refinement, standups and demo ceremonies&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">provide any data, technical dependencies and system access</span></li>
</ul>
<p><span style="font-weight: 400;">as required and in line with sprint planning and delivery schedule.</span></p>
<p><span style="font-weight: 400;">Deazy will carry out Quality Assurance Tests - testing items of work according to standard QA procedures and may include the following:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">functional testing &ndash; a feature (or ticket) delivers the functionality that was outlined in the original description; and</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">regression testing &ndash; testing to ensure that changes or new functionality introduced through recent development have not negatively impacted the existing functionality and stability;</span></li>
</ul>
<p><br /><span style="font-weight: 400;">(together "</span><strong>Development Sprints</strong><span style="font-weight: 400;">")</span></p>
</td>
</tr>
<tr style="height: 50px;">
<td style="width: 24.7704%; height: 50px;">
<p><strong>UAT and Deployment</strong></p>
</td>
<td style="width: 54.8007%; height: 50px;">
<p><span style="font-weight: 400;">User acceptance testing (&ldquo;</span><strong>UAT</strong><span style="font-weight: 400;">&rdquo;) &ndash; Deazy and or the Client&rsquo;s Authorised Person accepts the functionality works in the way he/she wanted; ("</span><strong>Acceptance Tests</strong><span style="font-weight: 400;">").</span></p>
<p><span style="font-weight: 400;">An item of work will be considered delivered when Deazy has confirmed that Acceptance Tests have been passed in writing</span><span style="font-weight: 400;">.</span><span style="font-weight: 400;">&nbsp;</span></p>
<p><span style="font-weight: 400;">The process for deployment to UAT and Production environments (</span><strong>&ldquo;Deployment&rdquo;</strong><span style="font-weight: 400;">) will be agreed during project kick off. </span></p>
</td>
</tr>
<tr style="height: 22px;">
<td style="width: 24.7704%; height: 22px;"><strong>Authorised Person (s) </strong></td>
<td style="width: 54.8007%; height: 22px;">
<p><strong>Client:</strong><span style="font-weight: 400;"> </span><span style="font-weight: 400; background-color: #feff00;">[{{CLIENT_NAME}}]</span></p>
<p><strong>Deazy:</strong><span style="font-weight: 400;"> </span><span style="font-weight: 400; background-color: #feff00;">[{{DEAZY_LEAD}}]</span></p>
<p><span style="font-weight: 400; background-color: #feff00;"><span style="background-color: #ffffff;"><strong>Supplier:</strong><span style="font-weight: 400;"> </span></span><span style="font-weight: 400; background-color: #feff00;">[{{SUPPLIER_NAME}}]</span></span></p>
</td>
</tr>
<tr style="height: 22px;">
<td style="width: 24.7704%; height: 22px;"><strong>Change Request procedure</strong></td>
<td style="width: 54.8007%; height: 22px;">
<p><span style="font-weight: 400;">Any changes to the scope of this SOW shall be approved via mutual agreement of the Authorised Persons of Deazy and Supplier (for and on behalf of the parties) and confirmed via a signed </span><a href=""><span style="font-weight: 400;">Change Request Form</span></a><span style="font-weight: 400;">, which includes the associated impact to the Charges and delivery schedule of the engagement</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<ol class="continue">
<li><span style="background-color: #feff00;"><strong>[Add if supplier is going to subcontract Use of sub-contractors</strong></span></li>
</ol>
<p style="padding-left: 40px;"><span style="font-weight: 400; background-color: #feff00;">Further to clause 18 of the Agreement, the Supplier may not sub-contract any of its obligations to any third party without Deazy's prior written consent.</span></p>
<p style="padding-left: 40px;"><span style="font-weight: 400; background-color: #feff00;">For the purpose of this SOW, Deazy agrees that the Supplier is permitted to use the following sub-contractors, which for the avoidance of doubt, includes both companies and individual consultants/freelancers engaged through the individual&rsquo;s own companies:[insert names of contractors]</span></p>
<p style="padding-left: 40px;"><span style="font-weight: 400; background-color: #feff00;">Any sub-contracting by the Supplier of any of its obligations will not relieve the Supplier from its obligations under the Agreement or this SOW, including confidentially obligations in clause 11 and securing the necessary Intellectual Property Rights in clause 14&nbsp;</span></p>
<p style="padding-left: 40px;"><span style="background-color: #feff00;"><strong><span style="font-weight: 400;">All individual sub-contractors to complete </span><a style="background-color: #feff00;" href=""><span style="font-weight: 400;">this form</span></a><span style="font-weight: 400;"> prior to project commencement date.]</span></strong></span></p>
<p>&nbsp;</p>
<ol class="continue">
<li><strong>Charges</strong></li>
</ol>
<p style="padding-left: 40px;">This SOW will be delivered on a fixed-price basis and the following Charges shall apply:</p>
<table style="border-collapse: collapse; width: 90.8257%; height: 322px;" border="1">
<tbody>
<tr style="height: 50px;">
<td style="width: 12.2948%; height: 50px;">
<p><strong>&nbsp;</strong></p>
</td>
<td style="width: 41.5796%; height: 50px;">
<p><strong>Item</strong></p>
</td>
<td style="width: 15.2778%; height: 50px;"><span style="background-color: #ffffff;"><strong>Sprint cost</strong></span></td>
<td style="width: 15.4128%; height: 50px;"><span style="background-color: #ffffff;"><strong>Number of sprints</strong></span></td>
<td style="width: 13.7934%; height: 50px;"><strong>Total </strong><strong><br /></strong><strong>(Ex VAT)</strong></td>
</tr>
<tr style="height: 50px;">
<td style="width: 12.2948%; height: 50px;">
<p>1</p>
</td>
<td style="width: 41.5796%; height: 50px;">
<p><strong><span style="font-weight: 400;">Development Readiness (Sprint 0)</span></strong></p>
</td>
<td style="width: 15.2778%; height: 50px;"><span style="font-weight: 400;">[</span><span style="font-weight: 400; background-color: #feff00;">&pound;/&euro;/$</span><span style="font-weight: 400;">]</span></td>
<td style="width: 15.4128%; height: 50px;">1</td>
<td style="width: 13.7934%; height: 50px;"><span style="font-weight: 400;"></span><span style="font-weight: 400; background-color: #feff00;">{{STAGE_1_COST}}</span><span style="font-weight: 400;"></span></td>
</tr>
<tr style="height: 122px;">
<td style="width: 12.2948%; height: 122px;">
<p>2</p>
</td>
<td style="width: 41.5796%; height: 122px;">
<p><strong><span style="font-weight: 400;">Development Sprints - including<span style="background-color: #ffffff;"> </span></span><span style="font-weight: 400; background-color: #feff00;">[INCLUDE AS APPROPRIATE Development, Tech Lead, QA and Scrum Master]</span></strong></p>
</td>
<td style="width: 15.2778%; height: 122px;"><span style="font-weight: 400;">[</span><span style="font-weight: 400; background-color: #feff00;">&pound;/&euro;/$</span><span style="font-weight: 400;">]</span></td>
<td style="width: 12.2948%; height: 50px;">
<p>[<span style="background-color: #feff00;">x</span>]</p>
</td>
<td style="width: 13.7934%; height: 122px;"><span style="font-weight: 400;"></span><span style="font-weight: 400; background-color: #feff00;">{{STAGE_2_COST}}</span><span style="font-weight: 400;"></span></td>
</tr>
<tr style="height: 50px;">
<td style="width: 12.2948%; height: 50px;">
<p>3</p>
</td>
<td style="width: 41.5796%; height: 50px;">
<p><span style="font-weight: 400;">UAT &amp; Deployment Sprint </span></p>
</td>
<td style="width: 15.2778%; height: 50px;"><span style="font-weight: 400;">[</span><span style="font-weight: 400; background-color: #feff00;">&pound;/&euro;/$</span><span style="font-weight: 400;">]</span></td>
<td style="width: 15.4128%; height: 50px;">1</td>
<td style="width: 13.7934%; height: 50px;"><span style="font-weight: 400;"></span><span style="font-weight: 400; background-color: #feff00;">{{STAGE_3_COST}}</span><span style="font-weight: 400;"></span></td>
</tr>
<tr style="height: 50px;">
<td style="width: 12.2948%; height: 50px;">
<p>&nbsp;</p>
</td>
<td style="width: 41.5796%; height: 50px;">
<p><strong>Total</strong></p>
</td>
<td style="width: 15.2778%; height: 50px;">&nbsp;</td>
<td style="width: 15.4128%; height: 50px;">&nbsp;</td>
<td style="width: 13.7934%; height: 50px;"><strong><span style="font-weight: 400;"></span><span style="font-weight: 400; background-color: #feff00;">{{TOTAL_STAGES_COST}}</span><span style="font-weight: 400;"></span></strong></td>
</tr>
</tbody>
</table>
<p><span style="background-color: #ffffff;"><span style="font-weight: 400;">(the "<strong>Charges</strong>")</span></span></p>
<p>&nbsp;</p>
<p><strong><span style="background-color: #ffffff;">Payment schedule</span></strong></p>
<ol class="continue">
<li><span style="font-weight: 400;">Deazy will generate self-billing invoices on behalf of the Supplier, as outlined in the Agreement.</span></li>
<li><span style="font-weight: 400;">Deazy will invoice as follows:</span></li>
</ol>


{{PREPOPULATED_TABLE}}


<ol class="continue">
<li><span style="font-weight: 400;">Deazy will pay the Supplier for all self-billing invoices in accordance with the Agreement.</span></li>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<table style="border-collapse: collapse; width: 93.2318%; height: 136px;" border="0">
<tbody>
<tr style="height: 118px;">
<td style="width: 49.6824%; height: 118px;">
<p><strong>Signed</strong><span style="font-weight: 400;"> by </span><span style="background-color: #feff00;"><strong>[A MEMBER OF THE DEAZY LEADERSHIP TEAM]</strong></span></p>
<p><span style="font-weight: 400;">duly authorised for and on behalf of </span><strong>Deazy Limited</strong></p>
</td>
<td style="width: 49.6824%; height: 118px;">
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-weight: 400;">..................................................................................................</span></p>
<p style="text-align: center;"><span style="font-weight: 400;">[Director]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; [Secretary]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;[Authorised Signatory]</span></p>
</td>
</tr>
<tr style="height: 18px;">
<td style="width: 49.6824%; height: 18px;">
<p style="text-align: left;"><strong>Signed<span style="font-weight: 400;"> by </span><span style="background-color: #feff00;">[AUTHORISED SIGNATORY]</span></strong></p>
<p><strong><span style="font-weight: 400;">duly authorised for and on behalf of </span><span style="background-color: #feff00;">{{SUPPLIER_NAME}}</span></strong></p>
</td>
<td style="width: 49.6824%; height: 18px;">
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-weight: 400;">..................................................................................................</span></p>
<p style="text-align: center;"><span style="font-weight: 400;">[Director]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; [Secretary]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;[Authorised Signatory]</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p>&nbsp;</p>
`;
