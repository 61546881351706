import { object, string } from 'yup';

const emailValidation = object({
  email: string()
    .required('This field is required')
    .email('Invalid email address'),
});

const signInValidation = object({
  username: string().required('This field is required'),
  password: string().required('This field is required'),
});

export { signInValidation, emailValidation };
