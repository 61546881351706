import { action, makeObservable, observable } from 'mobx';

export default class PublicPagesStore {
  constructor({ API, toastsStore }) {
    makeObservable(this);
    this.toastsStore = toastsStore;
    this.API = API;
  }

  @observable isLoading = false;

  @observable publicStaffProfile = {};

  @action createPublicLink = async (profileData, callback) => {
    try {
      this.isLoading = true;
      const { data } = await this.API.createPublicLink(profileData);
      this.publicStaffProfile = data;
      this.isLoading = false;
      if (callback) callback();
    } catch (e) {
      this.toastsStore.showError({ title: 'Network error' });
      this.publicStaffProfile = {};
    } finally {
      this.isLoading = false;
    }
  };
}
