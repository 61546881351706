import { Box, Flex } from '@chakra-ui/react';
import { RightIconInput } from '@components/forms/MaskedTextInput';
import Typography from '@styles/Typography';
import { getDeazyMarginFactor } from '@utils';
import { lowerCase } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, Form } from 'react-final-form';

let to = null;

const ContingencySection = ({
  initialValues,
  proposalsStore: {
    proposalSupplierCurrency,
    proposalExchangeRate,
    updateSupplierContingency,
  },
  isReadOnly,
  mainNameQA,
}) => {
  return (
    <Flex
      flexDirection="column"
      border="1px solid"
      borderColor="dark.mid"
      bg="#F9F9F9"
      borderRadius="8px"
      px="16px"
      py="24px"
      mt="40px"
    >
      <Typography variant="h4" mb="30px">
        Delivery Partner contingency
      </Typography>
      <Form
        initialValues={{
          ...initialValues,
        }}
        onSubmit={updateSupplierContingency}
        render={({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Flex>
                <Box maxW="176px" mr="24px">
                  <Field
                    label="Delivery partner cost"
                    name="supplierCost"
                    decimalScale={2}
                    fixedDecimalScale
                    component={RightIconInput}
                    iconTagQA={mainNameQA && `${mainNameQA}--currency`}
                    iconName={lowerCase(proposalSupplierCurrency)}
                    disabled
                    data-test-id={mainNameQA && `${mainNameQA}--supplierCost`}
                  />
                </Box>
                <Box maxW="176px" mr="24px">
                  <Field
                    isDisabled={isReadOnly}
                    label="% Margin"
                    name="supplierContingencyMargin"
                    decimalScale={0}
                    fixedDecimalScale
                    data-test-id={
                      mainNameQA && `${mainNameQA}--supplierContingencyMargin`
                    }
                    onAfterChange={() => {
                      clearTimeout(to);
                      to = setTimeout(() => {
                        handleSubmit();
                      }, 700);
                    }}
                    isAllowed={({ floatValue }) =>
                      (floatValue < 100 && floatValue >= 0) || !floatValue
                    }
                    changeIfActive
                    component={RightIconInput}
                    iconName="percent"
                  />
                </Box>
                <Box maxW="176px" mr="24px">
                  <RightIconInput
                    label="Client cost"
                    decimalScale={2}
                    fixedDecimalScale
                    name="clientCost"
                    component={RightIconInput}
                    data-test-id={mainNameQA && `${mainNameQA}--clientCost`}
                    iconTagQA={mainNameQA && `${mainNameQA}--currency`}
                    iconName="gbp"
                    disabled
                    input={{
                      value:
                        (+values.supplierContingencyMargin > 0
                          ? getDeazyMarginFactor(
                              values.supplierContingencyMargin,
                            )
                          : 1) *
                        values.supplierCost *
                        proposalExchangeRate,
                      onChange: () => {},
                    }}
                    meta={{}}
                  />
                </Box>
              </Flex>
            </form>
          );
        }}
      />
    </Flex>
  );
};

export default inject('proposalsStore')(observer(ContingencySection));
