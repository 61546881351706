import React from 'react';
import { inject, observer } from 'mobx-react';
import { Flex, useDisclosure, Box } from '@chakra-ui/react';
import { find, isEmpty, toNumber } from 'lodash-es';
import Spinner from '@styles/Spinner';
import useDownload from '@hooks/useDownload';
import {
  NEW_PROPOSAL_STATUSES,
  VISIBLE_COSTS,
  FEATURES_BREAKDOWN,
  MAIN_NAMES_QA,
} from '@app/constants';
import { formatCurrency, formatNumber, roundTheseHours } from '@utils';
import InfoAlert from '@components/InfoAlert';
import StickyFooterCostBox from '@components/StickyFooterCostBox';
import Button from '@styles/Button';
import EmptyList from '@components/EmptyList';
import useMediaQuery from '@hooks/useMediaQuery';
import Typography from '@styles/Typography';
import WhiteCard from '@components/WhiteCard';
import PlugAndPlayProposalForm from '../PlugAndPlayProposal/PlugAndPlayProposalForm';
import Proposal from '../Proposal';
import ProposalTabs from '../ProposalTabs';
import ProposalTitle from './ProposalTitle';
import SupplierTeamRolesCard from './SupplierTeamRolesCard';
import CostBreakdownCard from './CostBreakdownCard';
import DeazyTeamRolesCard from './DeazyTeamRolesCard';
import ProposalStatusDropdown from '../Proposal/ProposalStatusDropdown';
import ClientPandPProposalSummary from '../PlugAndPlayProposal/ClientPandPProposalSummary';
import ClientPandPProposalTable from '../PlugAndPlayProposal/ClientPandPProposalTable';
import ClientProposal from '../ClientProposal';
import DiscountDisclaimer from '../ClientProposal/DiscountDisclaimer';
import ProposalStatus from '../ClientProposal/ProposalStatus';
import ProposalQueriesList from '../ClientProposal/ProposalQueriesList';
import ShareSettingsModal from '../Proposal/ShareSettingsModal';
import StatusMessageBox from '../StatusMessageBox';
import SectionTitle from '../AllCandidates/SectionTitle';

const { REACT_APP_API_URL: API_URL } = process.env;

const ShareSettingsButton = ({
  isAdminOrDL,
  isPlugAndPlay,
  proposalData,
  mainNameQA,
  onShareSettingsOpen,
  ...props
}) => {
  return (
    <>
      {isAdminOrDL &&
        !isPlugAndPlay &&
        (proposalData.status === NEW_PROPOSAL_STATUSES.WITH_CLIENT ||
          proposalData.status === NEW_PROPOSAL_STATUSES.WITH_DEAZY) && (
          <Button
            onClick={() => onShareSettingsOpen()}
            variant="ghost"
            height="auto"
            pr="8px"
            pl="8px"
            data-test-id={`${mainNameQA}-shareSettingsButton`}
            {...props}
          >
            Share Settings
          </Button>
        )}
    </>
  );
};

const ProposalDeazyLayer = props => {
  const {
    briefsStore: {
      brief: { isPlugAndPlay },
      brief,
    },
    usersStore: { isClient, isAdminOrDL },
    settingsStore: { fetchExchangeRates },
    ratesStore: { fetchClientResourceRates },
    proposalsStore: {
      proposalFirstRequest,
      updateProposalStatus,
      fetchProposalData,
      clearProposalData,
      fetchProposalResources,
      fetchCostBreakdown,
      proposalData,
      clientProposalData: clientProposalD,
      isLoadingProposal,
      deazyCostBreakdown,
      decoratedPnPResources,
      fetchClientProposalData,
    },
    history,
    match: {
      params: { type, proposalId, id: briefId },
    },
  } = props;

  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');

  const mainNameQA = isPlugAndPlay
    ? MAIN_NAMES_QA.TEAM_AUGMENTATION_PROPOSAL
    : MAIN_NAMES_QA.PROJECT_PROPOSAL;

  const clientProposalData = isPlugAndPlay ? proposalData : clientProposalD;
  const { proposalHours, proposalCost } = deazyCostBreakdown?.data || {};
  const [
    shouldRefetchClientProposal,
    setShouldRefetchClientProposal,
  ] = React.useState(true);

  const onTabChange = tab => {
    history.push(tab);
  };
  React.useEffect(() => {
    fetchExchangeRates();
    return () => {
      clearProposalData();
    };
  }, []);

  React.useEffect(() => {
    if (deazyCostBreakdown.data || proposalData.clientSettings) {
      setShouldRefetchClientProposal(true);
    }
  }, [
    JSON.stringify(deazyCostBreakdown.data),
    JSON.stringify(proposalData.clientSettings),
  ]);

  React.useEffect(() => {
    fetchClientResourceRates(brief?.customer?.id);
  }, []);

  const isDPTab = type === 'delivery-partner';
  const isDeazyTab = type === 'deazy';
  const isClientTab = type === 'client';

  const downloadLink = `${API_URL}/briefs/${brief.id}/clientProposals/${clientProposalData.id}/pdf`;

  const { onDownload, isLoading: isDownloadLoading } = useDownload({
    url: downloadLink,
  });

  React.useEffect(() => {
    if (isClientTab && shouldRefetchClientProposal && !isPlugAndPlay) {
      fetchClientProposalData(briefId, proposalId);
      setShouldRefetchClientProposal(false);
    }
    if (isEmpty(proposalData)) {
      fetchProposalData(briefId, proposalId, true);
      if (!isPlugAndPlay) {
        fetchProposalResources(briefId, proposalId);
        fetchCostBreakdown(briefId, proposalId);
      }
    }
  }, [isClientTab, shouldRefetchClientProposal, isPlugAndPlay, proposalData]);

  React.useEffect(() => {
    if (isClientTab && isAdminOrDL && isPlugAndPlay) {
      fetchProposalData(briefId, proposalId, true);
    }
  }, [isClientTab, isPlugAndPlay, isAdminOrDL]);

  React.useEffect(() => {
    if (
      !(isDPTab || isDeazyTab || isClientTab) ||
      (proposalData.status === NEW_PROPOSAL_STATUSES.WITH_DP &&
        !isClientTab &&
        !isDeazyTab)
    ) {
      history.push('delivery-partner');
    }
  }, [proposalData.status, isDPTab, isDeazyTab]);

  const clientCurrency = 'GBP';
  const {
    isOpen: isShareSettingsOpen,
    onOpen: onShareSettingsOpen,
    onClose: onShareSettingsClose,
  } = useDisclosure();

  const shareSettingsForClientInnitialValues = proposalData.clientSettings || {
    featuresBreakdown: FEATURES_BREAKDOWN.HIDDEN_COSTS,
    visibleCosts: VISIBLE_COSTS.ACTUAL_ESTIMATE,
  };

  const fullRangeCost = `${formatCurrency(
    deazyCostBreakdown.data.proposalMinCost,
    clientCurrency,
  )} - ${formatCurrency(
    deazyCostBreakdown.data.proposalMaxCost,
    clientCurrency,
  )}`;

  const showWithDPAlert = React.useMemo(() => {
    return (
      !!find(
        proposalData.resources,
        r => r?.ppBriefProposal?.status === NEW_PROPOSAL_STATUSES.WITH_DP,
      ) && isPlugAndPlay
    );
  }, [isPlugAndPlay, proposalData.resources]);

  if (
    isPlugAndPlay &&
    isEmpty(proposalData.resources) &&
    !isLoadingProposal &&
    isDeazyTab &&
    isAdminOrDL
  ) {
    return (
      <>
        <SectionTitle
          label="Team Augmentation Proposal"
          description="Manage costs of your proposal here before sharing with client."
          mb="20px"
        />
        <EmptyList
          label="You haven’t created a proposal for this brief yet"
          description="Once you have shortlisted candidates they will appear in this tab.
           From here you’ll be able to add margin and adjust costs to form a proposal you can share with the client."
          imagePath="/images/taproposals.svg"
        />
      </>
    );
  }

  return (
    <>
      {showWithDPAlert && (
        <InfoAlert
          bg="#fff"
          ml="auto"
          label="Some candidates on this proposal are currently ‘With Delivery Partner’ and the proposal cannot proceed."
          withCloseButton
        />
      )}
      {isShareSettingsOpen && (
        <ShareSettingsModal
          isOpen={isShareSettingsOpen}
          onClose={onShareSettingsClose}
          mainNameQA={mainNameQA}
          onSubmit={values =>
            updateProposalStatus(
              { ...values, status: proposalData.status },
              onShareSettingsClose,
            )
          }
          actualEstimateCost={formatCurrency(
            deazyCostBreakdown.data.proposalCost,
            clientCurrency,
          )}
          hightCost={formatCurrency(
            deazyCostBreakdown.data.proposalMaxCost,
            clientCurrency,
          )}
          fullRangeCost={fullRangeCost}
          initialValues={shareSettingsForClientInnitialValues}
        />
      )}
      {isSmallerScreen && !isPlugAndPlay && (
        <Box bg="#fff" p="16px 12px" borderRadius="8px" mb="32px">
          <ProposalStatusDropdown
            useOutsideBg
            isDisabled={brief.isClosed}
            isPlugAndPlay={isPlugAndPlay}
            availableOptions={proposalData.availableStatuses}
            clientCurrency={clientCurrency}
            isSmallerScreen={isSmallerScreen}
            mainNameQA={mainNameQA}
            buttonProps={{
              mt: '0px',
              ml: '0px',
            }}
          />
          <ShareSettingsButton
            isAdminOrDL={isAdminOrDL}
            proposalData={proposalData}
            mainNameQA={mainNameQA}
            onShareSettingsOpen={onShareSettingsOpen}
            my="21px"
            w="100%"
            mx="auto"
          />
          {isAdminOrDL &&
            proposalData.status !== NEW_PROPOSAL_STATUSES.WITH_DEAZY &&
            proposalData.status !== NEW_PROPOSAL_STATUSES.WITH_CLIENT && (
              <Box py="16px" />
            )}

          {!proposalFirstRequest && (
            <ProposalTabs
              isDeazyTabBlocked={
                proposalData.status &&
                proposalData.status === NEW_PROPOSAL_STATUSES.WITH_DP &&
                !brief.isPlugAndPlay
              }
              activeTab={type}
              onTabChange={onTabChange}
              showDpTab={!brief.isPlugAndPlay}
              mainNameQA={mainNameQA}
            />
          )}
          <Typography variant="h2">Project Proposal</Typography>
        </Box>
      )}
      {isSmallerScreen && isPlugAndPlay && (
        <>
          <Flex
            justifyContent="space-between"
            flexDir="column-reverse"
            width="100%"
            mb="16px"
            alignItems="flex-start"
          >
            <Typography variant="h2">Team Augmentation Proposal</Typography>
            <WhiteCard p={0} mt="0px" w="100%" display="flex" mb="16px">
              <ProposalStatusDropdown
                useOutsideBg
                isDisabled={brief.isClosed}
                isPlugAndPlay={isPlugAndPlay}
                availableOptions={proposalData.availableStatuses}
                clientCurrency={clientCurrency}
                mainNameQA={mainNameQA}
                isSmallerScreen={isSmallerScreen}
                buttonProps={{
                  mt: '0px',
                  ml: '0px',
                }}
              />
            </WhiteCard>
          </Flex>
          {!proposalFirstRequest && (
            <ProposalTabs
              isDeazyTabBlocked={
                proposalData.status &&
                proposalData.status === NEW_PROPOSAL_STATUSES.WITH_DP &&
                !brief.isPlugAndPlay
              }
              activeTab={type}
              onTabChange={onTabChange}
              showDpTab={!brief.isPlugAndPlay}
              mainNameQA={mainNameQA}
            />
          )}
        </>
      )}
      {!isSmallerScreen && (
        <>
          <Flex mb="35px" alignItems="center" justifyContent="space-between">
            <Typography variant="h2">
              {isPlugAndPlay
                ? 'Team Augmentation Proposal'
                : 'Project Proposal'}
            </Typography>
            <WhiteCard p="12px" mt="0px" w="auto" display="flex">
              <ProposalStatusDropdown
                useOutsideBg
                isDisabled={brief.isClosed}
                isPlugAndPlay={isPlugAndPlay}
                availableOptions={proposalData.availableStatuses}
                clientCurrency={clientCurrency}
                mainNameQA={mainNameQA}
                isSmallerScreen={isSmallerScreen}
                buttonProps={{
                  mt: '0px',
                  ml: '0px',
                }}
              />
              <ShareSettingsButton
                isAdminOrDL={isAdminOrDL}
                isPlugAndPlay={isPlugAndPlay}
                proposalData={proposalData}
                mainNameQA={mainNameQA}
                onShareSettingsOpen={onShareSettingsOpen}
              />
            </WhiteCard>
          </Flex>
          {!proposalFirstRequest && (
            <ProposalTabs
              isDeazyTabBlocked={
                proposalData.status &&
                proposalData.status === NEW_PROPOSAL_STATUSES.WITH_DP &&
                !brief.isPlugAndPlay
              }
              activeTab={type}
              onTabChange={onTabChange}
              showDpTab={!brief.isPlugAndPlay}
              mainNameQA={mainNameQA}
            />
          )}
        </>
      )}
      {isLoadingProposal && <Spinner variant="large" />}
      {isClientTab && !isPlugAndPlay && !isLoadingProposal && (
        <>
          <Flex justifyContent="space-between">
            <Typography variant="h1">Proposal</Typography>
            <Button
              isLoading={isDownloadLoading}
              onClick={onDownload}
              isDisabled={
                clientProposalData.clientSettings?.visibleCosts ===
                VISIBLE_COSTS.FULL_RANGE
              }
              leftIcon={{ name: 'download', fontSize: 22, mr: '0' }}
              data-test-id={`${mainNameQA}--downloadButton`}
            >
              Download
            </Button>
          </Flex>
          <ClientProposal
            proposalId={proposalId}
            mainNameQA={mainNameQA}
            isSmallerScreen={isSmallerScreen}
          />
        </>
      )}
      {isDPTab &&
        brief.isPlugAndPlay &&
        !isEmpty(proposalData) &&
        !isLoadingProposal && (
          <>
            <ProposalQueriesList proposalId={proposalId} />
            <Flex alignItems="center">
              <Typography variant="h1" mt="54px" mb="24px">
                {`${proposalData?.ppBriefToSupplier?.supplier?.name} Proposal`}
              </Typography>
            </Flex>
            <StatusMessageBox message={proposalData.statusMessage} />
            <PlugAndPlayProposalForm
              isReadOnly
              supplierId={proposalData?.ppBriefToSupplier?.supplier?.id}
              onSubmit={() => {}}
              setSubmit={() => {}}
              initialValues={{
                resources: decoratedPnPResources,
                currency: proposalData?.currency,
              }}
            />
          </>
        )}
      {isDPTab && !brief.isPlugAndPlay && !isLoadingProposal && (
        <Proposal isDeazyLayer isReadOnly {...props} />
      )}
      {isPlugAndPlay && isClientTab && (
        <>
          <DiscountDisclaimer clientProposalData={clientProposalData} />
          <ProposalStatus
            useOutsideBg
            clientProposalData={clientProposalData}
            isAdminOrDL
            mt="16px"
          />
          <ClientPandPProposalTable
            proposalData={clientProposalData}
            isClient={isClient}
            mainNameQA={mainNameQA}
          />
          <ClientPandPProposalSummary
            isClient={isClient}
            isLoadingProposal={isLoadingProposal}
            proposalData={clientProposalData}
            mainNameQA={mainNameQA}
          />
        </>
      )}

      {isDeazyTab && !isLoadingProposal && (
        <>
          <ProposalTitle mainNameQA={mainNameQA} />
          <SupplierTeamRolesCard
            isReadOnly={
              brief.isClosed ||
              proposalData.status !== NEW_PROPOSAL_STATUSES.WITH_DEAZY
            }
            mainNameQA={mainNameQA}
          />
          {!isPlugAndPlay && (
            <>
              <DeazyTeamRolesCard
                isReadOnly={
                  brief.isClosed ||
                  proposalData.status !== NEW_PROPOSAL_STATUSES.WITH_DEAZY
                }
                mainNameQA={mainNameQA}
              />
              <StickyFooterCostBox
                title="Total for client"
                hours={
                  !deazyCostBreakdown.isLoading &&
                  roundTheseHours(proposalHours)
                }
                currency="gbp"
                cost={
                  !deazyCostBreakdown.isLoading && formatNumber(proposalCost)
                }
                infoTooltipLabel={
                  toNumber(deazyCostBreakdown.data.proposalMinCost) > 0 &&
                  toNumber(deazyCostBreakdown.data.proposalMaxCost) > 0 &&
                  `Range: ${formatCurrency(
                    deazyCostBreakdown.data.proposalMinCost,
                    'GBP',
                  )} - ${formatCurrency(
                    deazyCostBreakdown.data.proposalMaxCost,
                    'GBP',
                  )}`
                }
                mainNameQA={mainNameQA}
              />
            </>
          )}
          <CostBreakdownCard
            isReadOnly={
              brief.isClosed ||
              proposalData.status !== NEW_PROPOSAL_STATUSES.WITH_DEAZY
            }
            mainNameQA={mainNameQA}
          />
        </>
      )}
    </>
  );
};

export default inject(
  'proposalsStore',
  'briefsStore',
  'ratesStore',
  'settingsStore',
  'usersStore',
)(observer(ProposalDeazyLayer));
