import { useToast } from '@chakra-ui/react';
import React from 'react';
import { API } from '@app/api';
import moment from 'moment';
import { get } from 'lodash-es';

const useDownload = ({ url, fileName }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const showToast = useToast();

  const onDownload = async successCb => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const { data, headers } = await API.apiInstance.get(url, {
        responseType: 'blob',
      });
      const contentDisposition = headers['content-disposition'] || '';
      let name = fileName;
      if (!fileName) {
        name = get(contentDisposition.match(/filename="(.+)"/), '[1]'); // image.jpg
      }
      const fileURL = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', name || `${moment().format()}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      if (typeof successCb === 'function') {
        successCb();
      }
    } catch (e) {
      showToast({
        position: 'top',
        isClosable: true,
        status: 'error',
        title: e.message || 'Network error. Please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    onDownload,
    isLoading,
  };
};

export default useDownload;
