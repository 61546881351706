import { Box, Flex, Link } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { get, isEmpty, map } from 'lodash-es';
import moment from 'moment';
import React from 'react';
import Linkify from 'react-linkify';

const Note = ({
  author,
  description,
  createdAt,
  isSupplierView,
  mainNameQA,
  id,
}) => {
  return (
    <Box
      _first={{ mt: ['8px', '8px', '8px', '24px'] }}
      p={isSupplierView ? '24px 0' : '16px'}
      borderRadius={isSupplierView ? '0' : '8px'}
      border={isSupplierView ? 'none' : '1px solid'}
      borderColor="dark.mid"
      mb="12px"
      data-test-id={`${mainNameQA}--note-${id}`}
    >
      <Flex color="dark.solid" fontSize={12} mb="8px">
        <Typography data-test-id={`${mainNameQA}--note-author`}>
          {author ? get(author, 'fullname', '-') : 'Deazy Admin'}
        </Typography>
        <Typography mx="6px">|</Typography>
        <Typography data-test-id={`${mainNameQA}--note-createdAt`}>
          {createdAt ? moment.utc(createdAt).format('DD MMM YYYY') : '-'}
        </Typography>
      </Flex>
      <Typography
        variant="text2"
        whiteSpace="pre-wrap"
        wordBreak="break-word"
        data-test-id={`${mainNameQA}--note-description`}
      >
        <Linkify
          componentDecorator={link => (
            <Link target="_blank" href={link} wordBreak="break-all">
              {link}
            </Link>
          )}
        >
          {description}
        </Linkify>
      </Typography>
    </Box>
  );
};
const NotesList = ({ notes, isSupplierView, mainNameQA }) => {
  if (isEmpty(notes) && !isSupplierView) {
    return (
      <Typography variant="text2" mt="16px">
        No notes currently added to this brief
      </Typography>
    );
  }
  return map(notes, note => (
    <Note
      isSupplierView={isSupplierView}
      key={`note-${note.id}`}
      mainNameQA={mainNameQA}
      {...note}
    />
  ));
};

export default NotesList;
