import { Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';

const DeazyAllocationHead = ({ showAllocationDates = false }) => (
  <Flex>
    <Flex w="100%" mb="12px" mt="24px" color="dark.solid" pl="40px">
      <Typography variant="caption" flex={1} minW="100px">
        Manager
      </Typography>
      {showAllocationDates && (
        <>
          <Typography variant="caption" w="150px">
            Start date
          </Typography>
          <Typography variant="caption" w="150px">
            End date
          </Typography>
        </>
      )}
      <Typography variant="caption" w="15%">
        Hours
      </Typography>
      <Typography variant="caption" w="15%">
        Rate
      </Typography>
      <Typography variant="caption" w="20%">
        Cost
      </Typography>
    </Flex>
  </Flex>
);

export default DeazyAllocationHead;
