import { Box, Flex, Heading, Image, useDisclosure } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Spinner from '@styles/Spinner';
import Typography from '@styles/Typography';
import { noop } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import ChangeRequestActionForm from './ChangeRequestActionForm';

const ChangeRequestAction = ({
  location: { search },
  crsStore: { useTokenAction, token, fetchTokenForSingleCR, isLoading },
}) => {
  const [thanksInfo, setThanksInfo] = useState(false);

  const sliceToken = search.slice(1);

  useEffect(() => {
    fetchTokenForSingleCR(qs.parse(sliceToken));
    onOpen();
  }, []);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const tokenAction = token.crTokenActionType;

  const currency = token.changeRequest?.project.currency;

  const data = token.changeRequest;

  const calculatedClientRate = token.calculatedClientRate || {};
  return (
    <>
      <Flex
        backgroundImage="url('/images/person-typing.jpg')"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        flexDir="column"
        w="100vw"
        minH="100vh"
        h="100%"
        justify="center"
      >
        <Flex flexDir="column" justify="center" minH="100vh" h="100%" py="20px">
          <Image
            mt="auto"
            ml="56px"
            mb="48px"
            w="242px"
            src="/images/deazy-wordmark.svg"
            alt="DEAZY"
            ignoreFallback
          />
        </Flex>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClose={noop}
        styleProps={{ p: '32px', minW: '720px', minH: '300px' }}
      >
        <Box>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {!thanksInfo ? (
                <>
                  <Typography variant="h1" mb="32px">
                    {`${tokenAction || 'Approve or reject'} Change Request`}
                  </Typography>
                  {data ? (
                    <ChangeRequestActionForm
                      data={data}
                      calculatedClientRate={calculatedClientRate}
                      tokenAction={tokenAction}
                      currency={currency}
                      onClose={onClose}
                      onSubmit={() => {
                        return useTokenAction({
                          token: qs.parse(sliceToken),
                          onSuccess: () => {
                            setThanksInfo(!thanksInfo);
                          },
                        });
                      }}
                    />
                  ) : (
                    <Typography fontSize="24px">
                      Change request is unavailable
                    </Typography>
                  )}
                </>
              ) : (
                <Box>
                  <Heading mb="18px">Thank you</Heading>
                  <Typography fontSize="24px">
                    Change request has been {`${tokenAction.toLowerCase()}ed`}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default inject('crsStore')(observer(ChangeRequestAction));
