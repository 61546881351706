import { Box } from '@chakra-ui/react';
import Icon from '@components/Icon';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Spinner from '@styles/Spinner';
import theme, {
  COLORS,
  FONTS,
  getInputVariantProps,
  INPUT_VARIANTS,
} from '@styles/theme';
import { find, findIndex, isEqual } from 'lodash-es';
import React from 'react';
import ReactSelect, { components } from 'react-select';
import FormError from './FormError';
import FormLabel from './FormLabel';

export const Styles = styled(
  React.forwardRef(
    ({ regularStyles, additionalOptionsSelected, hasError, ...props }, ref) => (
      <Box ref={ref} {...props} />
    ),
  ),
)`
  .select__control--is-disabled {
    background-color: transparent;
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: all;
  }
  .select__control {
    padding: 0px 12px;
    min-height: 48px;
    box-shadow: none;
    :hover {
      border: 1px solid ${theme.colors.dark.mid};
    }
    ${({ regularStyles }) =>
      regularStyles
        ? css`
            border-color: ${theme.colors.dark.mid};
            border: 1px solid ${theme.colors.dark.mid};
            border-radius: 0;
            border-top: none;
            border-bottom: none;
          `
        : css`
            border-radius: 8px;
            border-color: ${theme.colors.dark.mid};
            border: 1px solid ${theme.colors.dark.mid};
            border-style: solid;
          `};
  }
  .select__value-container {
    padding: 0px;
    position: relative;
    padding-right: ${({ justSingleItem }) =>
      justSingleItem ? '15px' : '20px'};
    flex-wrap: ${({ additionalOptionsSelected, justSingleItem }) =>
      additionalOptionsSelected > 0 || justSingleItem ? 'nowrap' : 'wrap'};
    ${({ additionalOptionsSelected }) =>
      additionalOptionsSelected > 0 &&
      `
        :after {
          content: '+${additionalOptionsSelected}';
          min-width: 28px;
          height: 24px;
          border-radius: 8px;
          color: #fff;
          font-size: 12px;
          font-family: ${FONTS.WM};
          background-color: ${COLORS.blue.solid};
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 12px;
        }
    `}
  }
  .select__multi-value {
    font-size: 12px;
    background-color: ${theme.colors.gray[100]};
    height: 24px;
    margin: 0;
    margin-right: 8px;
    margin-left: 0;
    margin-bottom: 12px;
    margin-top: 12px;
    border-radius: 8px;
    font-family: ${theme.fonts.WS};
    color: #000;
  }
  .select__multi-value__label {
    font-weight: 800;
    text-transform: uppercase;
    line-height: 20px;
    align-self: center;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 12px;
    padding-right: 0;
  }
  .select__multi-value__remove {
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
    cursor: pointer;
    &:hover {
      background-color: inherit;
      color: inherit;
    }
  }
  .select__clear-indicator {
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .select__indicator-separator {
    align-self: center;
    height: 24px;
  }
  .select__dropdown-indicator {
    padding: 8px 6px 8px 14px;
    cursor: pointer;
  }

  .select__menu-list {
    ${({ regularStyles }) =>
      regularStyles
        ? null
        : css`
            margin-top: 16px;
            // border-top: 1px solid ${COLORS.dark.mid};
          `};
  }

  .select__menu {
    ${({ regularStyles }) =>
      regularStyles
        ? css`
            margin-top: 0px;
            border: 1px solid ${theme.colors.dark.mid};
            border-radius: 0;
            box-shadow: none;
          `
        : css`
            border: 1px solid ${theme.colors.dark.mid};
            border-radius: 8px;
            top: 84%;
            margin-top: 10px;
            box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
          `};
    z-index: 100;
    ${({ menuRelative }) => menuRelative && 'position: relative;'};
  }
  .select__option {
    font-size: 15px;
    font-family: ${FONTS.WS};
    padding: 9px 8px;
    ${({ regularStyles }) =>
      regularStyles
        ? null
        : css`
            padding-left: calc(11px + 18px + 11px);
          `};
    position: relative;
    cursor: pointer;
    border-radius: 8px;
    :not(:last-of-type) {
      margin-bottom: 0px;
    }
    :active {
      background: transparent;
    }
    :before {
      ${({ regularStyles }) =>
        regularStyles
          ? css`
              display: none;
            `
          : null};
      content: '';
      background-image: url('/images/icons/checkbox-unselected.svg');
      width: 18px;
      height: 18px;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: 11px;
      top: 8px;
    }
    &--is-selected:before {
      ${({ regularStyles }) =>
        regularStyles
          ? css`
              display: none;
            `
          : null};
      content: '';
      background-image: url('/images/icons/checkbox-selected.svg');
      width: 18px;
      height: 18px;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: 11px;
      top: 8px;
    }
  }
  .select__option--is-focused {
    background: ${COLORS.primary[100]};
  }
  .select__option--is-selected {
    background: ${COLORS.primary[100]};
    color: #000;
  }
  .select__menu-list {
    margin: 8px 14px;
  }
  .select__control--menu-is-open {
    ${({ regularStyles }) =>
      regularStyles
        ? css`
            border-radius: 0;
          `
        : css`
            // box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
          `};
  }
  .select__placeholder {
    color: hsl(0, 0%, 70%);
    font-size: 16px;
    opacity: 0.6;
  }
`;

export const MultiValueContainer = ({
  itemsToDisplayCount,
  setItemsToDisplayCount,
  selectProps,
  inputWidth,
  ...props
}) => {
  const index = findIndex(selectProps.value, option =>
    isEqual(option, props.data),
  );
  React.useLayoutEffect(() => {
    let itemsWidth = 0;
    let itemsLength = 0;
    selectProps.value.forEach(option => {
      itemsWidth += option?.label?.length * 8 + 56;
      if (itemsWidth < inputWidth) {
        itemsLength += 1;
      }
    });
    setItemsToDisplayCount(itemsLength);
  }, [inputWidth, selectProps.value]);

  if (index < itemsToDisplayCount || itemsToDisplayCount === 0 || !inputWidth) {
    return (
      <components.MultiValueContainer {...props} selectProps={selectProps} />
    );
  }
  return null;
};

const ClearIndicator = props => {
  const { selectProps } = props;

  return (
    <components.ClearIndicator {...props}>
      <Icon name="close" fontSize={22} color={selectProps.iconColor} />
    </components.ClearIndicator>
  );
};

const DropdownIndicator = props => {
  const { selectProps } = props;

  return (
    <components.DropdownIndicator {...props}>
      <Icon name="chevronDown" fontSize={12} color={selectProps.iconColor} />
    </components.DropdownIndicator>
  );
};

const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <Icon name="close" fontSize={14} />
  </components.MultiValueRemove>
);

const MultiSelect = ({
  placeholder = 'Select multiple options...',
  options = [],
  defaultValue,
  styleProps = {},
  isLoading,
  input = {},
  isRegular,
  meta = {},
  label,
  notRemoveFromInput,
  onAfterChange,
  variant = INPUT_VARIANTS.NORMAL,
  iconColor,
  isDisabled,
  showAllSelectedOptions = false,
  menuRelative,
  ...props
}) => {
  const { labelProps } = getInputVariantProps(variant);
  const value = find(options, { value: defaultValue || input.value });
  const hasError =
    (meta.touched || meta.submitFailed) && (meta.error || meta.submitError);

  const [itemsToDisplayCount, setItemsToDisplayCount] = React.useState();

  const additionalOptionsSelected =
    (input.value || []).length - itemsToDisplayCount || 0;

  const justSingleItem = (input.value || []).length === 1;

  const wrapperRef = React.useRef();

  const [inputWidth, setInputWidth] = React.useState();

  React.useEffect(() => {
    if (wrapperRef?.current && !showAllSelectedOptions) {
      setInputWidth(
        wrapperRef.current.querySelector('.select__value-container')
          .clientWidth,
      );
    }
  }, [wrapperRef, showAllSelectedOptions]);

  return (
    <Styles
      variant={variant}
      w="100%"
      ref={wrapperRef}
      cursor="pointer"
      {...styleProps}
      regularStyles={isRegular ? 1 : 0}
      hasError={hasError}
      additionalOptionsSelected={
        showAllSelectedOptions ? 0 : additionalOptionsSelected
      }
      justSingleItem={justSingleItem}
      menuRelative={menuRelative}
      {...props}
    >
      <FormLabel name={input.name} label={label} {...labelProps} />
      {isLoading ? (
        <Spinner variant="small" />
      ) : (
        <ReactSelect
          isMulti
          itemsToDisplayCount={
            showAllSelectedOptions ? Infinity : itemsToDisplayCount
          }
          components={{
            MultiValueContainer: containerProps => (
              <MultiValueContainer
                inputWidth={inputWidth}
                itemsToDisplayCount={itemsToDisplayCount}
                setItemsToDisplayCount={setItemsToDisplayCount}
                {...containerProps}
              />
            ),
            MultiValueRemove,
            ClearIndicator,
            DropdownIndicator,
          }}
          closeMenuOnSelect={false}
          placeholder={placeholder}
          className="form-select"
          classNamePrefix="select"
          hideSelectedOptions={false}
          defaultValue={input.value || []}
          options={options}
          isDisabled={isDisabled}
          {...input}
          onChange={v => {
            input.onChange(v);
            if (onAfterChange) {
              onAfterChange(v);
            }
          }}
          value={value}
          iconColor={iconColor}
          {...props}
        />
      )}
      <FormError meta={meta} />
    </Styles>
  );
};

export default MultiSelect;
