/* eslint-disable */
/* ta */
export default `
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Schedule 1 Statement of Work&nbsp;</strong></p>
<p><strong>Dated:&nbsp;</strong></p>
<p><strong>Between:</strong></p>
<p><strong>(1) {{DEAZY_NAME}}</strong><span style="font-weight: 400;"> (trading as Deazy) of {{DEAZY_DETAILS}} (Company No. 10305788) (&ldquo;</span><strong>Deazy</strong><span style="font-weight: 400;">&rdquo;); and</span></p>
<p><span style="background-color: #feff00;"><strong>(2) {{SUPPLIER_NAME}}<span style="font-weight: 400;"> {{SUPPLIER_ADDRESS}}<span style="background-color: #ffffff;">&nbsp;</span></span><span style="background-color: #ffffff;"><span style="font-weight: 400;">(the "</span>Supplier<span style="font-weight: 400;">").</span></span></strong></span></p>
<p style="padding-left: 40px;">&nbsp;</p>
<p><strong>Background</strong></p>
<p><span style="font-weight: 400;">(A) Pursuant to the Deazy Supplier Agreement agreed between the parties <span style="background-color: #feff00;">{{DATE}}</span>&nbsp;(the &ldquo;<strong>Agreement</strong>&rdquo;) Deazy has appointed the Supplier to provide software development services when required, during the Term.</span></p>
<p><span style="font-weight: 400;">(B) Pursuant to the Agreement, the Deazy is able to request Services (as defined in the Agreement) and the Supplier has agreed to provide such services in accordance with this statement of work (&ldquo;<strong>SOW</strong>&rdquo;).</span></p>
<p>&nbsp;</p>
<p><strong>Term</strong></p>
<ol class="start">
<li><span style="font-weight: 400;">This SOW shall come into force on the date it is executed by its parties OR on </span><span style="font-weight: 400; background-color: #feff00;">{{TERM_DATE}}</span><span style="font-weight: 400;"> (the &ldquo;</span><strong>Commencement Date</strong><span style="font-weight: 400;">&rdquo;</span><strong>)</strong></li>
<li><span style="font-weight: 400;">This SOW shall take effect from the Commencement Date and will continue in effect for a fixed term of </span><span style="font-weight: 400;"><span style="background-color: #feff00;">{{DURATION}}</span>, </span><span style="font-weight: 400;">and, unless terminated earlier, continue thereafter on a </span> rolling basis. </span>
<ol class="nested-1">
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Deazy may terminate this SOW on giving the Supplier written notice of not less than [<span style="background-color:#feff00;">thirty (30) days</span>] to be served at any time.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The Supplier may terminate this SOW on giving Deazy written notice of not less than [<span style="background-color:#feff00;">sixty (60) days</span>] to be served at any time</span></li>
</ol>
</li>
</ol>
<p><span style="font-weight: 400;"><strong>Client</strong></span></p>
<ol class="continue">
<li><span style="font-weight: 400;">The underlying Client receiving the benefit of the Services is </span><span style="font-weight: 400; background-color: #feff00;">{{CLIENT_NAME}}</span><span style="font-weight: 400;">&nbsp;(the &ldquo;</span><strong>Client</strong><span style="font-weight: 400;">&rdquo;) </span></li>
</ol>
<p>&nbsp;</p>
<p><strong>Conflict</strong></p>
<ol class="continue">
<li><span style="font-weight: 400;">If there is an inconsistency between any of the provisions of this SOW and the provisions of the Agreement, the provisions of this SOW shall prevail.</span></li>
<li><span style="font-weight: 400;">If a term is capitalised in this SOW but not defined therein the defined terms of the Agreement shall be applicable to said term.</span></li>
</ol>
<p>&nbsp;</p>
<p><strong>Services</strong></p>
<p><strong>Scope of work and approach</strong></p>
<ol class="continue">
<li><strong>&nbsp;Context:</strong><span style="font-weight: 400;"> The Client wishes to <span>{{SHORT_DESCRIPTION}}</span></li>
</ol>
{{LONG_DESCRIPTION}}
<ol class="continue">
<li><span style="font-weight: 400;"><strong>Approach:</strong></span></li>
<ol class="nested-1">
<li><span style="font-weight: 400;">The Services in this SOW are provided on a retainer basis, with an initial fixed monthly resource allocation detailed in clause 8.</span></li>
</ol>
</ol>
<ol class="continue">
<li><strong>Scope of Services:</strong>
<ol class="nested-1">
<li><span style="font-weight: 400;">The Supplier shall provide technical expertise to augment the Client internal team: </span><br />
<ol class="nested-2">
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">Technical roles required;</span>
<ol class="nested-3">{{ALLOCATIONS}}</ol>
</li>
</ol>
</li>
<li style="font-weight: 400;" aria-level="4"><span style="font-weight: 400;">The supplier personnel will be required to complete a probation period of </span><span style="font-weight: 400; background-color: #feff00;">add agreed period eg. four (4) weeks</span><span style="font-weight: 400;"> (&ldquo;</span><strong>Probation Period</strong><span style="font-weight: 400;">&rdquo;). </span>
<ol class="nested-2">
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">The Client and Deazy can request a change in the personnel allocated by giving the Supplier no less than </span><span style="font-weight: 400; background-color: #feff00;">five (5) days</span><span style="font-weight: 400;"> notice in writing, if the personnel is still within the Probation Period.</span></li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">If the personnel is no longer in the Probation Period, the Client or Deazy can request a change in the personnel allocated by the Supplier by giving the Supplier no less than thirty (30) days notice in writing. </span></li>
</ol>
</li>
<li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400; background-color: #ffffff;"><span style="font-weight: 400;">Resource allocation breakdown:</span></span></li>
</ol>
</li>
</ol>
<table style="border-collapse: collapse; width: 91.9273%; height: 72px;" border="1">
<tbody>
<tr style="height: 18px;">
<td style="width: 49.6158%; height: 18px;">
<p><strong>Resource</strong></p>
</td>
<td style="width: 49.6158%; height: 18px;"><strong>Total days for Initial Term</strong></td>
</tr>
{{RESOURCES}}
<tr style="height: 18px;">
<td style="width: 49.6158%; height: 18px;">
<p><span style="font-weight: 400;"><strong>Total days for Initial Term<span style="background-color: #feff00;">(X weeks)</span></strong></span></p>
</td>
<td style="width: 49.6158%; height: 18px;">{{TOTAL_RESOURCE_HOURS}}</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<ol class="continue">
<li><strong>Ways of working</strong>
<ol class="nested-1">
<li><span style="font-weight: 400;">The parties acknowledge and agree that the following change procedure applies to this statement of work:</span></li>
</ol>
</li>
</ol>
<table style="border-collapse: collapse; width: 93.2318%; height: 150px;" border="1">
<tbody>
<tr style="height: 50px;">
<td style="width: 33.0309%; height: 50px;">
<p><strong>Onboarding Phase</strong></p>
</td>
<td style="width: 33.0309%; height: 50px;">
<p><span style="font-weight: 400;">Onboarding of any new resources into the Client team will be completed as below.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">One week onboarding&nbsp;</span></p>
<p>&nbsp;</p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Team introductions&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Product intros/demos</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Code and architecture walkthrough</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Development process walkthrough&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">System access and IT set up&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Complete simple task to test workflow/access etc&nbsp;</span></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">The Client is responsible for the system access and IT set up and commits to making the relevant team members available for necessary intros, demos and process walkthrough remote sessions. </span></p>
</td>
</tr>
<tr style="height: 50px;">
<td style="width: 33.0309%; height: 50px;">
<p><strong>Authorised Person (s) </strong></p>
</td>
<td style="width: 33.0309%; height: 50px;">
<p><strong>Client:</strong><span style="font-weight: 400;"> </span><span style="font-weight: 400; background-color: #feff00;">{{CLIENT_LEAD}}</span></p>
<p><strong>Deazy:</strong><span style="font-weight: 400;"> </span><span style="font-weight: 400; background-color: #feff00;">{{DEAZY_LEAD}}</span></p>
<p><strong>Supplier: </strong><span style="font-weight: 400; background-color: #feff00;">{{SUPPLIER_LEAD}}</span></p>
</td>
</tr>
<tr style="height: 50px;">
<td style="width: 33.0309%; height: 50px;">
<p><strong>Change Request procedure</strong></p>
</td>
<td style="width: 33.0309%; height: 50px;">
<p><span style="font-weight: 400;">Any changes to the scope of this SOW shall be approved via mutual agreement of the Authorised Persons (on behalf of Deazy and the Client) and confirmed via a signed </span><a href=""><span style="font-weight: 400;">Change Request Form</span></a><span style="font-weight: 400;">, which includes the associated impact to the Charges and delivery schedule of the engagement</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<ol class="continue">
<li><strong>Location of services</strong><span style="font-weight: 400;"> </span><strong>provided</strong></li>
</ol>
<p style="padding-left: 40px;"><span style="font-weight: 400;">The Supplier shall work remotely. Any travel required to deliver the Services will be chargeable to the Deazy but all expenses must be approved in advance by Deazy, in both cases in writing.</span></p>
<p style="padding-left: 40px;">&nbsp;</p>
<ol class="continue">
<li><strong>Charges</strong></li>
</ol>
<p style="padding-left: 40px;"><span style="font-weight: 400;">This SOW will be delivered on a Time &amp; Material basis with a fixed resource allocation and the estimated Charges are outlined in the table that follows:</span></p>
<table style="border-collapse: collapse; width: 90.8257%;" border="1">
<tbody>

{{CHARGES_ROWS}}
{{CHARGES_TOTALS_ROW}}
</tbody>
</table>
<p><span style="background-color: #ffffff;"><span style="font-weight: 400;">(the "<strong>Charges</strong>")</span></span></p>
<p>&nbsp;</p>
<ol class="continue">
<li><span style="background-color: #feff00;"><strong></strong><strong>Add if supplier is going to subcontract Use of sub-contractors</strong></span></li>
</ol>
<p style="padding-left: 40px;"><span style="font-weight: 400; background-color: #feff00;">Further to clause 18 of the Agreement, the Supplier may not sub-contract any of its obligations to any third party without Deazy's prior written consent.</span></p>
<p style="padding-left: 40px;"><span style="font-weight: 400; background-color: #feff00;">For the purpose of this SOW, Deazy agrees that the Supplier is permitted to use the following sub-contractors, which for the avoidance of doubt, includes both companies and individual consultants/freelancers engaged through the individual&rsquo;s own companies:[insert names of contractors]</span></p>
<p style="padding-left: 40px;"><span style="font-weight: 400; background-color: #feff00;">Any sub-contracting by the Supplier of any of its obligations will not relieve the Supplier from its obligations under the Agreement or this SOW, including confidentially obligations in clause 11 and securing the necessary Intellectual Property Rights in clause 14&nbsp;</span></p>
<p style="padding-left: 40px;"><span style="background-color: #feff00;"><strong><span style="font-weight: 400;">All individual sub-contractors to complete </span><a style="background-color: #feff00;" href=""><span style="font-weight: 400;">this form</span></a><span style="font-weight: 400;"> prior to project commencement date.</span><span style="font-weight: 400;"></span></strong></span></p>
<p>&nbsp;</p>
<p><strong><span style="background-color: #ffffff;">Payment schedule</span></strong></p>
<ol class="continue">
<li><span style="font-weight: 400;">Deazy will generate self-billing invoices on behalf of the Supplier, as outlined in the Agreement.</span></li>
<li><span style="font-weight: 400;">Deazy will generate self-billing invoices monthly in <span style="background-color: #feff00;">[advance/arrears]</span></span></li>
<li><span style="font-weight: 400;">Invoices due during the initial term of the SOW are set out in the table below:</span>
</li>
</ol>

<p>&nbsp;</p>

{{PREPOPULATED_TABLE}}


<ol class="continue">

<li><span style="font-weight: 400;">Deazy will pay the Supplier for all self-billing invoices in accordance with the agreement.</span></li>

</ol>
<p><span style="font-weight: 400;"><strong>Intellectual Property Rights</strong></span></p>
<ol class="continue">
<li>&nbsp;
<ol class="nested-1">
<li><span style="font-weight: 400;">The Supplier shall procure that the Supplier Personnel (and those of its sub-contractors) shall waive absolutely and irrevocably any moral rights granted under the Copyright Designs and Patents Act 1988 or equivalent or analogous rights under laws of other jurisdictions in relation to the Intellectual Property Rights assigned pursuant to this Statement of Work and the Agreement. </span></li>
<li><span style="font-weight: 400;">At Deazy's request, the Supplier shall at its own cost and expense do all such acts and things and execute or procure the execution of any document which Deazy deems necessary or desirable to give effect to the Supplier's obligations and/or Deazy's rights under this clause and clause 14 of the Agreement, including without limitation executing or procuring the execution of an assignment or assignments.</span></li>
</ol>
</li>
</ol>
<p><strong>Consultants</strong></p>
<ol class="continue">
<li><strong>Use of Consultants</strong>
<ol class="nested-1">
<li><span style="font-weight: 400;">For the performance of the Services, the Supplier shall use the Supplier Personnel listed in Annex 1 of this Statement of Work only (&ldquo;</span><strong>Consultants</strong><span style="font-weight: 400;">&rdquo;). The Supplier shall not subcontract any part of the Services without Deazy's prior written consent.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">At all times the Supplier will ensure that the Consultants are adequately trained (including in respect of the Client's operational requirements and quality methodologies), have suitable skills and experience to enable them to perform the tasks assigned to them and are capable of providing the Services.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Whether the Consultants are the Supplier's employees or subcontractors, the Supplier will remain at all times liable to Deazy and the Client, and shall assume full responsibility for the proper performance of the Services, including the full compliance with the confidentiality obligations as set forth in the Agreement. The Supplier shall impose on any subcontractor materially the same obligations as those of the Agreement.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Pursuant to clause 13.6 of this Statement of Work, Consultants shall be instructed to comply with the health and safety regulations applicable on the Client&rsquo;s premises where the Services shall be effectively performed. The Supplier's Consultants may be required to travel for the performance of the Services.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The Supplier shall ensure that the Consultant maintains complete and accurate records and information to demonstrate performance of the Services.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The Supplier shall procure that the Consultant shall undertake to comply with all works rules and safety and other regulations communicated to the Consultant by Deazy or the Client and which Deazy or the Client may reasonably prescribe and shall otherwise comply with all Applicable Laws.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The Supplier acknowledges and accepts that the Consultants provided by the Supplier are of key importance to the delivery of the Services.&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The Supplier shall inform Deazy and the Client of the absence of any of the Consultants at least thirty (30) days before such absence (where such absence is foreseeable), and if so required by Deazy or the Client, provide a suitably qualified replacement for such Consultant as approved by Deazy and the Client in writing for the duration of such period of absence.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The Supplier shall use its best endeavours not to make any changes to the Consultants throughout the term of this Statement of Work and shall obtain the prior written approval of Deazy to any replacement for such individual.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">In the event that a Consultant carrying out the Services is, for any reason whatsoever, unable to pursue the Services , the Supplier shall:</span>
<ol class="nested-2">
<li><span style="font-weight: 400;">notify Deazy at its earliest convenience;</span></li>
<li style="font-weight: 400;" aria-level="4"><span style="font-weight: 400;">provide, within five (5) Business Days, a suitably qualified and experienced replacement for such Consultant to provide the relevant Services for the remainder of the duration of this Statement of Work, on the prior written approval of Deazy; and</span></li>
<li style="font-weight: 400;" aria-level="4"><span style="font-weight: 400;">if so required by Deazy, grant Deazy a free transition period devoted to the transfer of knowledge and such transition period will be determined by Deazy at its discretion, taking into account the duration of this Statement of Work and the length of time that the outgoing Consultant has been providing the Services.</span></li>
</ol>
</li>
<li><span style="font-weight: 400;">If, during the performance of Services under this Agreement, in the sole reasonable opinion of Deazy or the Client, one or more Consultant(s) of the Supplier performing the Services, is or are not competent or suitable, upon Deazy&rsquo;s request, the Supplier shall replace this (those) Consultant(s) with a Consultant who is suitably qualified and experienced to provide the Services, on the prior written approval of Deazy, within five (5) working days.</span></li>
</ol>
</li>
<li><span style="font-weight: 400;"><strong>Supplier Personnel</strong></span>
<ol class="continue">
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The Supplier Personnel shall be regarded at all times as employees or agents of the Supplier and no relationship of employer and employee shall arise between Deazy or the Client and any Supplier Personnel under any circumstances regardless of the degree of supervision that may be exercised over the Supplier Personnel by Deazy or the Client.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The Supplier shall ensure that the Supplier Personnel abide by all of the provisions of this Agreement and this Statement of Work.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The Supplier shall ensure that any written or oral representations and all facts relating to the Supplier Personnel that are provided to Deazy and the Client are materially correct and that all qualifications and experience that the Supplier Personnel are stated to possess have been checked thoroughly by the Supplier and verified as accurate. Deazy may, upon demand at any time during the Term, request proof of the qualifications of any of the Supplier Personnel and that the qualifications have been verified by the Supplier.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Deazy and the Client is entitled to request the Supplier not to use any employee who has been involved in the provision of the Services who it does not consider to be competent, or whose behaviour is, at the sole discretion of Deazy or the Client, improper, inappropriate or impolite and the Supplier will act accordingly.&nbsp; Nothing in this clause 14.4 shall permit the Supplier to recover any costs, damages or expenses arising directly or indirectly as a consequence with a request made pursuant to this clause 14.4.</span></li>
</ol>
</li>
<li><span style="font-weight: 400;"><strong>Site Requirements and Access to Premises</strong></span>
<ol class="nested-1">
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Where the Supplier or the Supplier Personnel have to enter Deazy&rsquo;s or the Client&rsquo;s premises (&ldquo;</span><strong>Premises</strong><span style="font-weight: 400;">&rdquo;) to provide the Services (or any part of them), the Supplier will ensure that the Supplier Personnel carry suitable identification with them, which shall be produced to Deazy&rsquo;s or the Client&rsquo;s staff (as applicable) upon request.&nbsp; Otherwise than as agreed between the parties in writing, the Supplier must not enlist the services of any of Deazy&rsquo;s or the Client&rsquo;s employees to assist with any work carried out in the provision of the Services at the Premises.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Deazy or the Client (as applicable) shall afford to the Supplier such access to the Premises as is necessary for the performance of the Services, subject to prior agreement as to date and time. Deazy and the Client may request and obtain from the Supplier a list of the Supplier Personnel attending the Premises.&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Deazy or the Client (as applicable) may refuse admission to the Premises to any Supplier Personnel, or require any such person to leave such Premises, at any time and for any reason.&nbsp; If Deazy or the Client requires any such person to leave the Premises, the Supplier shall ensure that he or she promptly does so.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">While the Supplier Personnel attend the Premises, the Supplier shall ensure that they conform to Deazy&rsquo;s and the Client's (as applicable) codes and regulations, including the Client Policies, adopt proper standards of behaviour, and co-operate with Deazy's or the Client&rsquo;s employees or agents having security responsibilities.&nbsp;&nbsp;</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The Supplier shall ensure that in the provision of the Services the Supplier does not interfere with the operations of Deazy, the Client, or their employees or agents or any other providers engaged at the Premises whether or not the Supplier is provided with sole access to the Premises.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The Supplier shall notify Deazy immediately of any damage, loss or injury to property or persons at the Premises of which the Supplier and/or Supplier Personnel ought reasonably to be aware.</span></li>
<li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Each party shall ensure that, in respect of its activities, it complies with any statutory requirements in respect of health and safety related issues including accident reporting and notification to the Health and Safety Executive or other appropriate statutory body and shall cooperate with the other party so far as is necessary to ensure the other party is able to comply its obligations set out in this clause 15.</span></li>
</ol>
</li>
</ol>
<p>&nbsp;</p>
<p>
  ANNEX 1
  <br>
  <span style="background-color: #feff00;">NAMED PERSONNEL</span>
</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<table style="border-collapse: collapse; width: 93.2318%; height: 136px;" border="0">
<tbody>
<tr style="height: 118px;">
<td style="width: 49.6824%; height: 118px;">
<p><strong>Signed</strong><span style="font-weight: 400;"> by </span><span style="background-color: #feff00;"><strong>A MEMBER OF THE DEAZY LEADERSHIP TEAM</strong></span></p>
<p><span style="font-weight: 400;">duly authorised for and on behalf of </span><strong>Deazy Limited</strong></p>
</td>
<td style="width: 49.6824%; height: 118px;">
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-weight: 400;">..................................................................................................</span></p>
<p style="text-align: center;"><span style="font-weight: 400;">[Director]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; [Secretary]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;[Authorised Signatory]</span></p>
</td>
</tr>
<tr style="height: 18px;">
<td style="width: 49.6824%; height: 18px;">
<p style="text-align: left;"><strong>Signed<span style="font-weight: 400;"> by </span><span style="background-color: #feff00;">AUTHORISED SIGNATORY</span></strong></p>
<p><strong><span style="font-weight: 400;">duly authorised for and on behalf of </span><span style="background-color: #feff00;">{{SUPPLIER_NAME}}</span></strong></p>
</td>
<td style="width: 49.6824%; height: 18px;">
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-weight: 400;">..................................................................................................</span></p>
<p style="text-align: center;"><span style="font-weight: 400;">[Director]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; [Secretary]&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;[Authorised Signatory]</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p>&nbsp;</p>`;
