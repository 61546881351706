import {
  find,
  first,
  includes,
  isEmpty,
  join,
  map,
  reduce,
  toNumber,
  filter,
  get,
  reject,
  omit,
  orderBy,
  flatten,
  startCase,
  toLower,
} from 'lodash-es';
import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import SOW_CLIENT_FIXED_PRICE_PLACEHOLDER from '@utils/sowTemplates/sowClientFixedPriceTemplate';
import SOW_CLIENT_CONTINUOUS_IMPROVEMENT_PLACEHOLDER from '@utils/sowTemplates/sowClientContinuousImprovementTemplate';
import SOW_CLIENT_DEFAULT_PLACEHOLDER from '@utils/sowTemplates/sowClientBasicTemplate';
import SOW_SUPPLIER_FIXED_PRICE_PLACEHOLDER from '@utils/sowTemplates/sowSupplierFixedPriceTemplate';
import SOW_SUPPLIER_CONTINUOUS_IMPROVEMENT_PLACEHOLDER from '@utils/sowTemplates/sowSupplierContinuousImprovementTemplate';
import SOW_SUPPLIER_DEFAULT_PLACEHOLDER from '@utils/sowTemplates/sowSupplierBasicTemplate';

import {
  safeFormatCurrencyForSOWs,
  getNumberOfWeekdays,
  formatCurrency,
  formatNumber,
  roundTheseHours,
  roundToFullValue,
} from '@utils';
import {
  getClientAllocationTotal,
  getSupplierAllocationTotal,
  isTAForFixedPrice,
} from '@utils/projectUtils';
import {
  PROJECT_TYPES,
  FIXED_PRICE_ALLOCATION_STAGES,
  ENGAGEMENT_TYPES,
  CURRENCIES_SYMBOLS,
  DEAZY_DETAILS,
} from '@app/constants';

export default class SowsStore {
  constructor({
    projectsStore,
    API,
    toastsStore,
    usersStore,
    sowPaymentScheduleStore,
  }) {
    makeObservable(this);
    this.usersStore = usersStore;
    this.projectsStore = projectsStore;
    this.API = API;
    this.toastsStore = toastsStore;
    this.sowPaymentScheduleStore = sowPaymentScheduleStore;
  }

  @observable sowHistory = [];

  @observable isLoadingHistory = false;

  @observable isLoading = false;

  @observable historyFirstRequest = true;

  @action clearSowHistory = () => {
    this.sowHistory = [];
    this.historyFirstRequest = true;
  };

  @observable currentProjectToSupplier = {};

  @computed get primaryProjectToSupplier() {
    return find(this.decoratedProjectToSuppliers, dpts => dpts.isPrimary);
  }

  @observable pandadocId = null;

  @observable isLoadingPandadocTemplate = null;

  @action fetchPandadocTemplateId = async (projectId, supplierId) => {
    if (this.isLoadingPandadocTemplate) return;
    try {
      this.pandadocId = null;
      this.isLoadingPandadocTemplate = true;
      if (!supplierId) {
        const { data } = await this.API.getPandadocSowClientTemplate(projectId);
        this.pandadocId = data;
      } else {
        const { data } = await this.API.getPandadocSowSupplierTemplate(
          projectId,
          supplierId,
        );
        this.pandadocId = data;
      }
    } catch {
      //
    } finally {
      this.isLoadingPandadocTemplate = false;
    }
  };

  @action setCurrentProjectToSupplier = obj => {
    const { isAdminOrDL } = this.usersStore;
    const { project } = this.projectsStore;

    const firstPToSWithoutSignedOutide = find(
      project.projectToSuppliers,
      pts => !pts.supplierSOWSignedOutsideOfPlatform,
    );
    if (
      obj &&
      obj.supplier.id !== this.primaryProjectToSupplier.supplier.id &&
      !obj.supplierSOWSignedOutsideOfPlatform
    ) {
      this.currentProjectToSupplier = obj;
    } else if (
      isAdminOrDL &&
      !this.primaryProjectToSupplier?.supplierSOWSignedOutsideOfPlatform
    ) {
      this.currentProjectToSupplier = this.primaryProjectToSupplier;
    } else {
      this.currentProjectToSupplier = firstPToSWithoutSignedOutide;
    }
  };

  @action clearCurrentProjectToSupplier = () => {
    this.currentProjectToSupplier = {};
  };

  @computed get decoratedProjectToSuppliers() {
    const { project } = this.projectsStore;
    const decoratedProjectToSuppliers = map(project.projectToSuppliers, pts => {
      return {
        ...pts,
        pureAllocations: pts.allocations,
        allocations: get(first(pts.allocations), 'entries'),
      };
    });
    return decoratedProjectToSuppliers;
  }

  @computed get currentPTOSFromProjectsStore() {
    return find(
      this.decoratedProjectToSuppliers,
      dpts => dpts.id === this.currentProjectToSupplier?.id,
    );
  }

  @computed get allocationsForClientSOW() {
    const { deazyAsSupplier, deazyAllocations } = this.projectsStore.project;
    const allocations = flatten(
      map(this.decoratedProjectToSuppliers, dptos => dptos.allocations),
    );
    const pureAllocations = flatten(
      map(this.decoratedProjectToSuppliers, dptos => dptos.pureAllocations),
    );

    return {
      allocations: deazyAsSupplier
        ? first(deazyAllocations)?.entries || []
        : allocations,
      pureAllocations: deazyAsSupplier ? deazyAllocations : pureAllocations,
    };
  }

  @action fetchSowHistory = async isClient => {
    this.sowHistory = [];
    this.isLoadingHistory = true;
    const { id: projectId } = this.projectsStore.project;
    try {
      const { data } = await this.API[
        isClient ? 'getClientSowHistory' : 'getSupplierSowHistory'
      ](
        projectId,
        !isClient
          ? this.currentProjectToSupplier &&
              this.currentProjectToSupplier.supplier.id
          : null,
      );

      this.sowHistory = map(data, sow => ({
        ...sow,
        link: `/projects/${projectId}/${
          isClient ? 'clientSow' : 'supplierSow'
        }/${sow.id}/pdf`,
      }));
    } catch {
      //
    } finally {
      this.historyFirstRequest = false;
      this.isLoadingHistory = false;
    }
  };

  getStageTotal = (stage, allocations, isClient, forDP = false) => {
    const { prepareAllocation, project } = this.projectsStore;

    const allocation =
      find(allocations, a => a.stage === stage && a.number === -1) || {};

    const clientAllocation =
      filter(allocations, a => a.stage === stage && a.number === -1) || [];

    const allClientEntries = flatten(
      flatten(
        map(clientAllocation, calloc => prepareAllocation(calloc.entries)),
      ),
    );

    let total = reduce(
      isClient ? allClientEntries : prepareAllocation(allocation.entries),
      (sum, e) => (forDP ? e.rate : e.clientRate) * e.amount + sum,
      0,
    );

    if (!forDP) {
      const deazyAllocation =
        find(
          orderBy(project.deazyAllocations, ['number'], ['desc']),
          a => a.stage === stage && a.number === -1,
        ) || {};
      total += reduce(
        deazyAllocation.entries,
        (sum, e) => e.rate * e.amount + sum,
        0,
      );
    }
    return total;
  };

  getAllStagesTotals = (isClient, forDP) => {
    const stage1Cost = this.getStageTotal(
      FIXED_PRICE_ALLOCATION_STAGES.DEVELOPMENT_READINESS,
      isClient
        ? this.allocationsForClientSOW.pureAllocations
        : this.currentPTOSFromProjectsStore?.pureAllocations,
      isClient,
      forDP,
    );
    const stage2Cost = this.getStageTotal(
      FIXED_PRICE_ALLOCATION_STAGES.DELIVERY,
      isClient
        ? this.allocationsForClientSOW.pureAllocations
        : this.currentPTOSFromProjectsStore?.pureAllocations,
      isClient,
      forDP,
    );
    const stage3Cost = this.getStageTotal(
      FIXED_PRICE_ALLOCATION_STAGES.UAT_AND_DEPLOYMENT,
      isClient
        ? this.allocationsForClientSOW.pureAllocations
        : this.currentPTOSFromProjectsStore?.pureAllocations,
      isClient,
      forDP,
    );
    const totalStagesCost = +stage1Cost + +stage2Cost + +stage3Cost;
    return { stage1Cost, stage2Cost, stage3Cost, totalStagesCost };
  };

  getSowPlaceholder = (project = {}, isClient = true) => {
    const { engagementType: eType, projectType } = project;
    if (eType === ENGAGEMENT_TYPES.RETAINED_TEAM) {
      if (isClient) {
        return SOW_CLIENT_CONTINUOUS_IMPROVEMENT_PLACEHOLDER;
      }
      return SOW_SUPPLIER_CONTINUOUS_IMPROVEMENT_PLACEHOLDER;
    }
    if (
      projectType === PROJECT_TYPES.FIXED_PRICE &&
      eType === ENGAGEMENT_TYPES.PROJECT_BUILD
    ) {
      if (isClient) {
        return SOW_CLIENT_FIXED_PRICE_PLACEHOLDER;
      }
      return SOW_SUPPLIER_FIXED_PRICE_PLACEHOLDER;
    }
    return isClient
      ? SOW_CLIENT_DEFAULT_PLACEHOLDER
      : SOW_SUPPLIER_DEFAULT_PLACEHOLDER;
  };

  returnTable = (isClient = true) => {
    const { project } = this.projectsStore;

    const isPlugAndPlay =
      project.engagementType === ENGAGEMENT_TYPES.TEAM_AUGMENTATION;

    const isRetainer =
      project.engagementType === ENGAGEMENT_TYPES.RETAINED_TEAM;

    if (isPlugAndPlay) {
      return this.prepopulatedPandPTable(isClient);
    }
    if (isRetainer) {
      return this.prepopulatedRetainerTable(isClient);
    }
    return this.prepopulatedProjectTable(isClient);
  };

  @action prepareClientSow = () => {
    const { project, projectDurationInWeeks } = this.projectsStore;

    const description =
      project?.brief?.description || project?.ppBrief?.description;

    const descriptionHasList = includes(description, 'li');

    const { currency } = this.projectsStore.projectCurrency;

    const omitedDeazyDetails = omit(DEAZY_DETAILS, 'fullName', 'vatNumber');

    const {
      stage1Cost,
      stage2Cost,
      stage3Cost,
      totalStagesCost,
    } = this.getAllStagesTotals(true, project.deazyAsSupplier);

    const placeholder = this.getSowPlaceholder(project)
      .replace('{{STAGE_1_COST}}', formatCurrency(stage1Cost, currency))
      .replace('{{STAGE_2_COST}}', formatCurrency(stage2Cost, currency))
      .replace('{{STAGE_3_COST}}', formatCurrency(stage3Cost, currency))
      .replace(
        '{{TOTAL_STAGES_COST}}',
        formatCurrency(totalStagesCost, currency),
      )
      .replace('{{CURRENCY}}', CURRENCIES_SYMBOLS[currency || 'GBP'])
      .replace('{{CURRENCY}}', CURRENCIES_SYMBOLS[currency || 'GBP'])
      .replace('{{CURRENCY}}', CURRENCIES_SYMBOLS[currency || 'GBP'])
      .replace('{{CLIENT_NAME}}', project.client.name)
      .replace('{{CLIENT_NAME}}', project.client.name)
      .replace(
        '{{SUPPLIER_NAME}}',
        this.currentProjectToSupplier?.supplier?.name,
      )
      .replace('{{CLIENT}}', project.client.name)
      .replace('{{ADDRESS}}', project.client.address)
      .replace('{{DATE}}', moment.utc().format('Do MMMM YYYY'))
      .replace(
        '{{TERM_DATE}}',
        moment.utc(project.startDate).format('Do MMMM YYYY'),
      )
      .replace(
        '{{DURATION}}',
        `${projectDurationInWeeks} week${
          projectDurationInWeeks > 1 ? 's' : ''
        }`,
      )
      .replace(
        '{{SHORT_DESCRIPTION}}',
        !descriptionHasList
          ? `${description ||
              '<span style="background-color: #feff00;">DESCRIPTION</span>'} 
              (the &ldquo;</span><strong>engagement</strong><span style="font-weight: 400;">&rdquo;)</span>`
          : '',
      )
      .replace(
        '{{LONG_DESCRIPTION}}',
        descriptionHasList
          ? `${description} <p>(the &ldquo;</span><strong>engagement</strong><span style="font-weight: 400;">&rdquo;)</span></p>`
          : '',
      )
      .replace('{{CLIENT_LEAD}}', project?.clientLead?.fullname || '[CLIENT]')
      .replace('{{DEAZY_LEAD}}', project?.pm?.fullname || '[PM]')
      .replace('{{ALLOCATIONS}}', this.clientAllocationsListMarkup)
      .replace(`{{RESOURCES}}`, this.clientResourceTableMarkup)
      .replace('{{TOTAL_RESOURCE_HOURS}}', this.clientResourceTableTotal)
      .replace('{{CHARGES_ROWS}}', this.clientChargesTableMarkup)
      .replace(
        '{{CHARGES_PER_PERIODS_ROWS}}',
        this.clientChargesPerPeriodTableMarkup,
      )
      .replace(
        '{{CHARGES_PER_PERIODS_ROWS_TOTAL}}',
        this.chargesTotalTableMarkup,
      )
      .replace(
        '{{CHARGES_PER_PERIODS_WITH_INVOICES_ROWS}}',
        this.clientChargesPerPeriodWithInvoicesTableMarkup,
      )
      .replace('{{CHARGES_TOTALS_ROW}}', this.clientChargesTableTotalsMarkup)
      .replace(
        '{{PROJECT_END_DATE}}',
        moment.utc(project.endDate).format('Do MMMM YYYY'),
      )
      .replace(
        '{{PROJECT_END_DATE}}',
        moment.utc(project.endDate).format('Do MMMM YYYY'),
      )
      .replace('{{PREPOPULATED_TABLE}}', this.returnTable())
      .replace('{{DEAZY_NAME}}', startCase(toLower(DEAZY_DETAILS.fullName)))
      .replace(
        '{{DEAZY_DETAILS}}',
        join(Object.values(omitedDeazyDetails), ', '),
      );
    return placeholder;
  };

  prepareSupplierSow = () => {
    const { project, projectDurationInWeeks } = this.projectsStore;
    const description =
      project?.brief?.description || project?.ppBrief?.description;

    const descriptionHasList = includes(description, 'li');

    const {
      currency: supplierCurrency,
    } = this.currentProjectToSupplier.supplier;

    const {
      overriddenCurrency: overriddenSupplierCurrency,
    } = this.currentProjectToSupplier;

    const finalSupplierCurrency =
      overriddenSupplierCurrency || supplierCurrency;

    const {
      stage1Cost,
      stage2Cost,
      stage3Cost,
      totalStagesCost,
    } = this.getAllStagesTotals(false, true);

    const omitedDeazyDetails = omit(DEAZY_DETAILS, 'fullName', 'vatNumber');

    const placeholder = this.getSowPlaceholder(project, false)
      .replace(
        '{{STAGE_1_COST}}',
        formatCurrency(stage1Cost, finalSupplierCurrency),
      )
      .replace(
        '{{STAGE_2_COST}}',
        formatCurrency(stage2Cost, finalSupplierCurrency),
      )
      .replace(
        '{{STAGE_3_COST}}',
        formatCurrency(stage3Cost, finalSupplierCurrency),
      )
      .replace(
        '{{TOTAL_STAGES_COST}}',
        formatCurrency(totalStagesCost, finalSupplierCurrency),
      )
      .replace(
        '{{SUPPLIER_NAME}}',
        this.currentProjectToSupplier?.supplier?.name,
      )
      .replace(
        '{{SUPPLIER_NAME}}',
        this.currentProjectToSupplier?.supplier?.name,
      )
      .replace('{{COUNTRY}}', project?.supplier?.country || '')
      .replace('{{DATE}}', moment.utc().format('Do MMMM YYYY'))
      .replace(
        '{{TERM_DATE}}',
        moment.utc(project.startDate).format('Do MMMM YYYY'),
      )
      .replace(
        '{{TERM_DATE}}',
        moment.utc(project.startDate).format('Do MMMM YYYY'),
      )
      .replace(
        '{{PROJECT_END_DATE}}',
        moment.utc(project.endDate).format('Do MMMM YYYY'),
      )
      .replace(
        '{{PROJECT_END_DATE}}',
        moment.utc(project.endDate).format('Do MMMM YYYY'),
      )
      .replace(
        '{{SUPPLIER_ADDRESS}}',
        this.currentProjectToSupplier?.supplier?.address,
      )
      .replace(
        '{{DURATION}}',
        project.duration
          ? `${projectDurationInWeeks} week${
              projectDurationInWeeks > 1 ? 's' : ''
            }`
          : '[TERM E.G. twelve (12) weeks]',
      )
      .replace('{{CLIENT_NAME}}', project?.client?.name)
      .replace('{{CLIENT_NAME}}', project?.client?.name)
      .replace('{{ALLOCATIONS}}', this.supplierAllocationsListMarkup)
      .replace('{{TOTAL_RESOURCE_HOURS}}', this.supplierResourceTableTotal)
      .replace(
        '{{SHORT_DESCRIPTION}}',
        !descriptionHasList
          ? `${description ||
              '<span style="background-color: #feff00;">DESCRIPTION</span>'}
            (the &ldquo;</span><strong>engagement</strong><span style="font-weight: 400;">&rdquo;)</span>`
          : '',
      )
      .replace(
        '{{LONG_DESCRIPTION}}',
        descriptionHasList
          ? `${description} <p>(the &ldquo;</span><strong>engagement</strong><span style="font-weight: 400;">&rdquo;)</span></p>`
          : '',
      )
      .replace(`{{RESOURCES}}`, this.supplierResourceTableMarkup)
      .replace(
        '{{SUPPLIER_NAME}}',
        this.currentProjectToSupplier?.supplier?.name,
      )
      .replace(
        '{{SUPPLIER_LEAD}}',
        this.currentProjectToSupplier?.supplierLead?.fullname,
      )
      .replace('{{DEAZY_LEAD}}', project?.pm?.fullname)
      .replace('{{CLIENT_LEAD}}', project?.clientLead?.fullname || '[CLIENT]')
      .replace('{{CHARGES_ROWS}}', this.supplierChargesTableMarkup)
      .replace('{{CHARGES_TOTALS_ROW}}', this.supplierChargesTableTotalsMarkup)
      .replace(
        '{{CHARGES_PER_PERIODS_ROWS}}',
        this.supplierChargesPerPeriodTableMarkup,
      )
      .replace(
        '{{CHARGES_PER_PERIODS_ROWS_TOTAL}}',
        this.chargesTotalTableMarkup(false),
      )
      .replace('{{PREPOPULATED_TABLE}}', this.returnTable(false))
      .replace('{{DEAZY_NAME}}', startCase(toLower(DEAZY_DETAILS.fullName)))
      .replace(
        '{{DEAZY_DETAILS}}',
        join(Object.values(omitedDeazyDetails), ', '),
      );
    return placeholder;
  };

  allocationsListMarkup = allocations => {
    const allocs = map(
      allocations,
      entry =>
        `<li style="font-weight: 400;" aria-level="4"><span style="font-weight: 400; background-color: #feff00;">${this.getDecoratedEntryName(
          entry,
        )}</span></li>`,
    );
    return join(allocs, '');
  };

  @computed get supplierAllocationsListMarkup() {
    return this.allocationsListMarkup(
      this.currentPTOSFromProjectsStore?.allocations,
    );
  }

  @computed get supplierResourceTableMarkup() {
    return this.resourceTableMarkup(
      this.currentPTOSFromProjectsStore?.allocations,
    );
  }

  @computed get clientAllocationsListMarkup() {
    return this.allocationsListMarkup([
      ...this.allocationsForClientSOW.allocations,
    ]);
  }

  @computed get clientResourceTableMarkup() {
    const {
      project: { deazyAllocations, engagementType, deazyAsSupplier },
    } = this.projectsStore;
    return this.resourceTableMarkup(
      engagementType === ENGAGEMENT_TYPES.RETAINED_TEAM
        ? [
            ...this.allocationsForClientSOW.allocations,
            ...(!deazyAsSupplier ? first(deazyAllocations)?.entries : []),
          ]
        : [...this.allocationsForClientSOW.allocations],
    );
  }

  getDecoratedEntryName = entry => {
    if (entry.resourceName) {
      return entry.resourceName;
    }
    return `${
      entry.resourceType ? `${entry.resourceType}, ` : ''
    } ${entry.name || '[RESOURCE]'}`;
  };

  @action resourceTableMarkup = allocations => {
    const {
      project: { engagementType },
    } = this.projectsStore;

    const resources = map(allocations, entry => {
      const {
        totalDays,
        totalDaysPerWeek,
      } = this.getAllocationDayRateAndTotalDays(entry);
      return `<tr style="height: 18px;">
        <td style="width: 49.6158%; height: 18px;">
        <p><span style="font-weight: 400; background-color: #feff00;">${this.getDecoratedEntryName(
          entry,
        )}</span></p>
        </td>
        <td style="width: 49.6158%; height: 18px;">
        ${formatNumber(
          engagementType === ENGAGEMENT_TYPES.RETAINED_TEAM
            ? totalDaysPerWeek
            : totalDays,
        )}
          </td>
        </tr>`;
    });
    return join(resources, '');
  };

  @computed get supplierResourceTableTotal() {
    const { project } = this.projectsStore;
    return formatNumber(
      reduce(
        this.currentPTOSFromProjectsStore?.allocations,
        (sum, alloc) => {
          const {
            totalDays,
            totalDaysPerWeek,
          } = this.getAllocationDayRateAndTotalDays(alloc);

          return (
            sum +
            toNumber(
              project.engagementType === ENGAGEMENT_TYPES.RETAINED_TEAM
                ? totalDaysPerWeek
                : totalDays,
            )
          );
        },
        0,
      ),
    );
  }

  @computed get clientResourceTableTotal() {
    const { project } = this.projectsStore;
    return formatNumber(
      reduce(
        project.engagementType === ENGAGEMENT_TYPES.RETAINED_TEAM
          ? [
              ...this.allocationsForClientSOW.allocations,
              ...project.deazyAllocations,
            ]
          : [...this.allocationsForClientSOW.allocations],
        (sum, alloc) => {
          const {
            totalDays,
            totalDaysPerWeek,
          } = this.getAllocationDayRateAndTotalDays(alloc);
          return (
            sum +
            toNumber(
              project.engagementType === ENGAGEMENT_TYPES.RETAINED_TEAM
                ? totalDaysPerWeek
                : totalDays,
            )
          );
        },
        0,
      ),
    );
  }

  @action chargesTableMarkup = (allocations, isClient) => {
    const allocs = map(allocations, entry => {
      const { dayRate, totalDays } = this.getAllocationDayRateAndTotalDays(
        entry,
        isClient,
      );

      const { currency: clientCurrency } = this.projectsStore.projectCurrency;

      const currency = isClient
        ? clientCurrency
        : this.currentProjectToSupplier.overriddenCurrency;

      return `
        <tr>
          <td style="width: 24.5896%;">
          <span style="background-color: #feff00;">${this.getDecoratedEntryName(
            entry,
          )}
          </span>
          </td>
          <td style="width: 24.5896%;">${formatNumber(totalDays)}</td>
          <td style="width: 24.5896%;">${dayRate}</td>
          <td style="width: 24.5896%;">
            <span style="background-color: #feff00;">
              ${safeFormatCurrencyForSOWs(dayRate * totalDays, currency)}
            </span>
          </td>
        </tr>
    `;
    });
    return join(allocs, '').replace(/##USD##/g, '&#36;');
  };

  @action chargesPerPeriodTableMarkup = (isClient = true) => {
    const { paymentInitialData } = this.sowPaymentScheduleStore;
    const { currency: clientCurrency } = this.projectsStore.projectCurrency;
    const currency = isClient
      ? clientCurrency
      : this.currentProjectToSupplier.overriddenCurrency;
    const charges = map(paymentInitialData.paymentScheduleItems, payment => {
      return `
      <tr style="height: 18px;">
        <td style="height: 18px;"><span style="background-color: #feff00;">[${moment
          .utc(payment.periodStart)
          .format('DD MMMM YYYY')} - ${moment
        .utc(payment.periodEnd)
        .format('DD MMMM YYYY')}]</span></td>
        <td style="height: 18px;">
          <p>Monthly Team Retainer;</p>
        </td>
        <td style="height: 18px;"><span style="background-color: #feff00;">${formatCurrency(
          payment.value,
          currency,
        )}</span></td>
      </tr>
    `;
    });
    return join(charges, '');
  };

  @action chargesTotalTableMarkup = (isClient = true) => {
    const { currency: clientCurrency } = this.projectsStore.projectCurrency;
    return `
      <tr style="height: 18px;">
        <td colspan="2" style="height: 18px; font-weight: bold"><p>Total</p></td>
        <td  style="height: 18px;"><span style="background-color: #feff00;">${formatCurrency(
          this.chargesTableTotal,
          isClient
            ? clientCurrency
            : this.currentProjectToSupplier.overriddenCurrency,
        )}</span></td>
      </tr>
    `;
  };

  @computed get chargesTableTotal() {
    const { paymentInitialData } = this.sowPaymentScheduleStore;
    return reduce(
      map(paymentInitialData.paymentScheduleItems, ({ value }) =>
        toNumber(value),
      ),
      (sum, charge) => sum + charge,
      0,
    );
  }

  @action chargesPerPeriodWithInvoicesTableMarkup = () => {
    const { invoicesArr } = this.projectPeriods;
    const { currency: clientCurrency } = this.projectsStore.projectCurrency;
    const invoicesWithPeriods = map(invoicesArr, inv => {
      return `
      <tr style="height: 18px;">
      <td style="height: 18px;"><span style="background-color: #feff00;">[${
        inv.date
      }]</span></td>
        <td style="height: 18px;"><span style="background-color: #feff00;">[${
          inv.periodStart
        } - ${inv.periodEnd}]</span></td>
        <td style="height: 18px;"><span style="background-color: #feff00;">${formatCurrency(
          inv.total,
          clientCurrency,
        )}</span></td>
      </tr>
    `;
    });
    return join(invoicesWithPeriods, '');
  };

  chargesTableHeaders = (isClient = true) => {
    const { currency: clientCurrency } = this.projectsStore.projectCurrency;

    const currency = isClient
      ? clientCurrency
      : this.currentProjectToSupplier.overriddenCurrency;

    return `
    <tr>
      <td style="width: 24.5896%;">
        <p><strong>Resource</strong></p>
      </td>
      <td style="width: 24.5896%;"><strong>Total days</strong></td>
      <td style="width: 24.5896%;"><strong>Day rate (${CURRENCIES_SYMBOLS[currency]})</strong></td>
      <td style="width: 24.5896%;"><strong>Total </strong><strong><br /></strong><strong>(Ex VAT)</strong></td>
    </tr>
    `;
  };

  @computed get clientChargesTableMarkup() {
    return `
    ${this.chargesTableHeaders()}
    ${this.chargesTableMarkup(
      [...this.allocationsForClientSOW.allocations],
      true,
    )}
    `;
  }

  @computed get supplierChargesTableMarkup() {
    return `
    ${this.chargesTableHeaders(false)}
    ${this.chargesTableMarkup(
      this.currentPTOSFromProjectsStore?.allocations,
      false,
    )}`;
  }

  @computed get clientChargesPerPeriodTableMarkup() {
    return this.chargesPerPeriodTableMarkup();
  }

  @computed get supplierChargesPerPeriodTableMarkup() {
    return this.chargesPerPeriodTableMarkup(false);
  }

  @computed get clientChargesPerPeriodWithInvoicesTableMarkup() {
    return this.chargesPerPeriodWithInvoicesTableMarkup();
  }

  @action getAllocationDayRateAndTotalDays = (entry, isClient = false) => {
    const {
      project = {},
      projectDaysDurationWithoutWeekends,
    } = this.projectsStore;
    const { startDate, endDate } = project;

    const isDeazyAllocation = !!entry?.manager || !!entry?.resourceName;

    const multiplier = getNumberOfWeekdays(startDate, endDate) / 5;

    const multiplierForTAandRetainer =
      getNumberOfWeekdays(entry.startDate, entry.endDate) / 5;

    const totalDays = () => {
      if (isTAForFixedPrice(project)) {
        return roundTheseHours((entry.amount / 8) * multiplierForTAandRetainer);
      }
      return roundTheseHours(
        project.totalBasis
          ? (entry.amount / 8) * multiplier
          : ((entry.amount / 5) * projectDaysDurationWithoutWeekends) / 8,
      );
    };

    return {
      dayRate:
        (!isClient || isDeazyAllocation ? entry.rate : entry.clientRate) * 8,
      totalDays: totalDays(),
      totalDaysPerWeek: roundToFullValue((entry.amount * 4) / 8),
    };
  };

  @computed get clientChargesTableTotalsMarkup() {
    return this.chargesTableTotalsMarkup(
      [...this.allocationsForClientSOW.allocations],
      true,
    );
  }

  @computed get supplierChargesTableTotalsMarkup() {
    return this.chargesTableTotalsMarkup(
      this.currentPTOSFromProjectsStore?.allocations,
      false,
    );
  }

  prepopulatedProjectTable = (isClient = true) => {
    return `
    <div id="prepopulated">
    <table style="border-collapse: collapse; width: 89.9749%; height: 90px;" border="1">
      <tbody>
      <tr style="height: 18px;">
        <td style="width: 25%; height: 18px;" contenteditable='false'>Invoice date</td>
        <td style="width: 25%; height: 18px;" contenteditable='false'>Percentage (%)</td>
        <td style="width: 25%; height: 18px;" contenteditable='false'>Milestone</td>
        <td style="width: 25%; height: 18px;" contenteditable='false'>Invoice Total (Ex VAT)</td>
      </tr>
      ${this.prepopulatedProjectTableMarkup(isClient)}
      </tbody>
    </table>
    <p><span style="font-weight: 400; background-color: #ff9900;">
      Any changes made directly in this table will need to be manually updated in the invoices
    </span></p>
    <p>&nbsp;</p>
    </div>
  `.replace(/##USD##/g, '&#36;');
  };

  @action prepopulatedProjectTableMarkup = (isClient = true) => {
    const { paymentInitialData } = this.sowPaymentScheduleStore;

    const { currency: clientCurrency } = this.projectsStore.projectCurrency;
    const currency = isClient
      ? clientCurrency
      : this.currentProjectToSupplier.overriddenCurrency;

    const usePlaceholder =
      paymentInitialData.isManual || !paymentInitialData?.id;
    const paymentsToShow = usePlaceholder
      ? [
          { percentage: '40', name: 'SOW sign off' },
          {
            percentage: '40',
            name: this.yellowHighlightWrapper(
              '[delivery milestone appropriate for the project]',
            ),
          },
          { percentage: '20', name: 'UAT sign off' },
        ]
      : paymentInitialData.paymentScheduleItems;

    const payments = map(paymentsToShow, payment => {
      const value = safeFormatCurrencyForSOWs(payment.value, currency);
      return `
        <tr>
        ${this.preparePaymentTableDataTag(
          true,
          true,
          usePlaceholder
            ? `[${this.yellowHighlightWrapper('DATE')}]`
            : moment.utc(payment.invoiceDate).format('DD/MM/YYYY'),
        )}
        ${this.preparePaymentTableDataTag(
          true,
          true,
          `${toNumber(payment.percentage)}%`,
        )}
        ${this.preparePaymentTableDataTag(true, true, payment.name)}
        ${this.preparePaymentTableDataTag(
          true,
          true,
          usePlaceholder ? this.yellowHighlightWrapper('[$€£X]') : value,
        )}
        </tr>
        `;
    });

    return join(payments, '');
  };

  prepopulatedPandPTable = (isClient = true) => {
    const { paymentInitialData } = this.sowPaymentScheduleStore;

    const isManual = paymentInitialData.isManual || !paymentInitialData?.id;

    const tdStyle = `width: ${isManual ? '33%' : '25%'}; height: 18px;`;

    return `
    <div id="prepopulated">
      <table style="border-collapse: collapse; width: 89.9749%; height: 90px;" border="1">
        <tbody>
        <tr style="height: 18px;">
          <td style="${tdStyle}" contenteditable='false'>${
      isManual ? 'Invoice Date' : `Name of monthly invoice`
    }</td>
      <td style="${tdStyle}" contenteditable='false'>${
      isManual ? 'Monthly Period' : 'Invoice Date'
    }
          </td>
          <td style="${tdStyle}" contenteditable='false'>Invoice Amount (Ex VAT)</td>
        </tr>
          ${this.prepopulatedPandPTableMarkup(isClient)}
        </tbody>
      </table>
      <p><span style="font-weight: 400; background-color: #ff9900;">
        Any changes made directly in this table will need to be manually updated in the invoices
      </span></p>
      <p>&nbsp;</p>
    </div>
  `.replace(/##USD##/g, '&#36;');
  };

  @action prepopulatedPandPTableMarkup = (isClient = true) => {
    const { paymentInitialData } = this.sowPaymentScheduleStore;
    const { currency: clientCurrency } = this.projectsStore.projectCurrency;
    const currency = isClient
      ? clientCurrency
      : this.currentProjectToSupplier.overriddenCurrency;

    const usePlaceholder =
      paymentInitialData.isManual || !paymentInitialData?.id;

    const paymentsToShow = usePlaceholder
      ? [
          {
            name: '[e.g 1st Jan 2021e]',
            period: '[eg. 1st January 2021 - 28th January 2021]',
            value: `${CURRENCIES_SYMBOLS.GBP}4000`,
          },
          {
            period: '[eg. 1st February 2021 - 28th February 2021]',
            value: CURRENCIES_SYMBOLS.GBP,
          },
          {
            value: CURRENCIES_SYMBOLS.GBP,
          },
        ]
      : paymentInitialData.paymentScheduleItems;

    const payments = map(paymentsToShow, payment => {
      const safeValue = safeFormatCurrencyForSOWs(payment.value, currency);

      if (usePlaceholder) {
        return `<tr>
        ${this.preparePaymentTableDataTag(
          true,
          false,
          this.yellowHighlightWrapper(payment.name),
        )}
        ${this.preparePaymentTableDataTag(
          true,
          false,
          this.yellowHighlightWrapper(payment.period),
        )}
        ${this.preparePaymentTableDataTag(
          true,
          false,
          this.yellowHighlightWrapper(payment.value),
        )}
        `;
      }
      return `<tr>
        ${this.preparePaymentTableDataTag(true, true, payment.name)}
        ${this.preparePaymentTableDataTag(
          true,
          true,
          moment.utc(payment.invoiceDate).format('DD/MM/YYYY'),
        )}
        ${this.preparePaymentTableDataTag(true, true, safeValue)}
      </tr>`;
    });

    return join(payments, '');
  };

  prepopulatedRetainerTable = (isClient = true) => {
    const { paymentInitialData } = this.sowPaymentScheduleStore;

    const isManual = paymentInitialData.isManual || !paymentInitialData?.id;

    return `
    <div id="prepopulated">
      <table style="border-collapse: collapse; width: 89.9749%; height: 90px;" border="1">
        <tbody>
        <tr style="height: 18px;">
          <td style="width: 33%; height: 18px;" contenteditable='false'> ${
            isManual ? 'Invoice Date' : `Name of monthly invoice`
          }</td>
          <td style="width: 33%; height: 18px;" contenteditable='false'>${
            isManual ? `Monthly Period` : `Invoice Date`
          }</td>
          <td style="width: 33%; height: 18px;" contenteditable='false'>Invoice Amount (Ex VAT)</td>
        </tr>
          ${this.prepopulatedRetainerTableMarkup(isClient)}
        </tbody>
      </table>
      <p><span style="font-weight: 400; background-color: #ff9900;">
        Any changes made directly in this table will need to be manually updated in the invoices
      </span></p>
      <p>&nbsp;</p>
    </div>
  `.replace(/##USD##/g, '&#36;');
  };

  @action prepopulatedRetainerTableMarkup = (isClient = true) => {
    const { paymentInitialData } = this.sowPaymentScheduleStore;
    const { currency: clientCurrency } = this.projectsStore.projectCurrency;
    const currency = isClient
      ? clientCurrency
      : this.currentProjectToSupplier.overriddenCurrency;

    const usePlaceholder =
      paymentInitialData.isManual || !paymentInitialData?.id;

    const paymentsToShow = usePlaceholder
      ? [
          {
            name: '[e.g 1st Jan 2021e]',
            period: '[eg. 1st January 2021 - 28th January 2021]',
            value: `${CURRENCIES_SYMBOLS.GBP}4000`,
          },
          {
            period: '[eg. 1st February 2021 - 28th February 2021]',
            value: CURRENCIES_SYMBOLS.GBP,
          },
          {
            value: CURRENCIES_SYMBOLS.GBP,
          },
        ]
      : paymentInitialData.paymentScheduleItems;

    const payments = map(paymentsToShow, payment => {
      const safeValue = safeFormatCurrencyForSOWs(payment.value, currency);

      if (usePlaceholder) {
        return `<tr>
        ${this.preparePaymentTableDataTag(
          true,
          false,
          this.yellowHighlightWrapper(payment.name),
        )}
        ${this.preparePaymentTableDataTag(
          true,
          false,
          this.yellowHighlightWrapper(payment.period),
        )}
        ${this.preparePaymentTableDataTag(
          true,
          false,
          this.yellowHighlightWrapper(payment.value),
        )}
        `;
      }

      return `
        <tr>
        ${this.preparePaymentTableDataTag(true, false, payment.name)}
        ${this.preparePaymentTableDataTag(
          true,
          false,
          moment.utc(payment.invoiceDate).format('DD/MM/YYYY'),
        )}
        ${this.preparePaymentTableDataTag(true, false, safeValue)}
        </tr>
        `;
    });

    return join(payments, '');
  };

  @action chargesTableTotalsMarkup = (allocations, isClient) => {
    const { currency: clientCurrency } = this.projectsStore.projectCurrency;
    const currency = isClient
      ? clientCurrency
      : this.currentProjectToSupplier.overriddenCurrency;
    const { totalHours, totalRate, total } = reduce(
      allocations,
      (result, alloc) => {
        const { dayRate, totalDays } = this.getAllocationDayRateAndTotalDays(
          alloc,
          isClient,
        );
        return {
          totalHours: (result.totalHours || 0) + toNumber(totalDays),
          totalRate: (result.totalRate || 0) + dayRate,
          total: (result.total || 0) + toNumber(totalDays) * dayRate,
        };
      },
      {},
    );
    return `
      <tr>
        <td style="width: 24.5896%;">
        <p><strong>Total</strong></p>
        </td>
        <td style="width: 24.5896%; font-weight: bold">${formatNumber(
          totalHours,
        )}</td>
        <td style="width: 24.5896%; font-weight: bold">${totalRate}</td>
        <td style="width: 24.5896%;"><span style="font-weight: 400; background-color: #feff00; font-weight: bold">${formatCurrency(
          total,
          currency,
        )}</span>
        </td>
      </tr>
    `;
  };

  to = null;

  @action fetchSOW = async (isClient, projectId, supplierId, value) => {
    clearTimeout(this.to);
    this.to = setTimeout(async () => {
      await this._fetchSow(isClient, projectId, supplierId, value);
    }, 700);
  };

  @action _fetchSow = async (isClient, projectId, supplierId, value) => {
    this.isLoading = true;

    try {
      if (isClient) {
        await this.API.postClientSOW(projectId, {
          message: value,
          sowVersion: this.sowHistory[0]?.version || null,
        });
        await this.fetchSowHistory(true);
      } else {
        await this.API.postSupplierSOW(
          projectId,
          this.currentProjectToSupplier.supplier.id,
          {
            message: value,
            sowVersion: this.sowHistory[0]?.version || null,
          },
        );
        if (supplierId) {
          await this.fetchSowHistory(false, supplierId);
        }
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    } finally {
      this.isLoading = false;
    }
  };

  @action completeSOWaction = async (projectId, isClient, date, successCb) => {
    try {
      await this.fetchSowHistory(isClient);
      if (isEmpty(first(this.sowHistory)) && isClient) {
        await this._fetchSow(
          isClient,
          projectId,
          undefined,
          this.prepareClientSow(),
        );
      }
      if (isEmpty(first(this.sowHistory)) && !isClient) {
        await this._fetchSow(
          isClient,
          projectId,
          undefined,
          this.prepareSupplierSow(),
        );
      }
      if (isClient) {
        await this.API.postApproveClientSOW(projectId, date);
      } else {
        await this.API.postApproveSupplierSOW(
          projectId,
          this.currentProjectToSupplier.supplier.id,
          date,
        );
      }
      await this.fetchSowHistory(isClient);
      const { data } = await this.API.getProjectById(
        this.projectsStore.project.id,
      );
      this.projectsStore.project.pending = data.pending;
      if (successCb) {
        successCb();
      }
      if (isClient) {
        this.projectsStore.project.finalisedDateClientSOW = date;
      } else {
        const currentSupplierFromProjectStore = find(
          this.projectsStore.project.projectToSuppliers,
          pts => pts.supplier.id === this.currentProjectToSupplier.supplier.id,
        );
        currentSupplierFromProjectStore.finalisedDateSupplierSOW = date;
        this.currentProjectToSupplier.finalisedDateSupplierSOW = date;
      }
    } catch (e) {
      this.toastsStore.showError({
        title: e.message || 'Something went wrong. Please, try again later.',
      });
    }
  };

  @action cannotChangeFinalisedSupplierSOW = ptsId => {
    const { isAdminOrDL } = this.usersStore;
    const { project } = this.projectsStore;
    const checkPtos = find(project.projectToSuppliers, pts => pts.id === ptsId);
    if (
      !isAdminOrDL ||
      (checkPtos.finalisedDateSupplierSOW &&
        !checkPtos.supplierSOWSignedOutsideOfPlatform)
    ) {
      return true;
    }
    return false;
  };

  @action thereIsFinalisedDateSupplierSOW = ptsId => {
    const { project } = this.projectsStore;
    const checkPtos = find(project.projectToSuppliers, pts => pts.id === ptsId);
    if (checkPtos.finalisedDateSupplierSOW) {
      return true;
    }
    return false;
  };

  @computed get canSeeByProjectTypes() {
    const { project } = this.projectsStore;
    const {
      PROJECT_BUILD,
      TEAM_AUGMENTATION,
      RETAINED_TEAM,
    } = ENGAGEMENT_TYPES;
    if (
      project.projectType === PROJECT_TYPES.FIXED_PRICE &&
      includes(
        [TEAM_AUGMENTATION, PROJECT_BUILD, RETAINED_TEAM],
        project.engagementType,
      )
    ) {
      return true;
    }
    return false;
  }

  @computed get canSeeSowOverviewFields() {
    const { isAdminOrDL, isClient, isTeamAdmin } = this.usersStore;
    const { project } = this.projectsStore;

    const showFieldsForAdmin = isAdminOrDL;

    const showFieldsForClient = isClient && project.finalisedDateClientSOW;

    const showFieldsForTA = isTeamAdmin;

    const canSee = showFieldsForAdmin || showFieldsForClient || showFieldsForTA;

    if (this.canSeeByProjectTypes && canSee) {
      return true;
    }

    return false;
  }

  @computed get canSeeSowTab() {
    const { project } = this.projectsStore;
    const { isTeamAdmin, isAdminOrDL, isClient } = this.usersStore;
    const showTabForTA =
      isTeamAdmin &&
      first(project.projectToSuppliers)?.finalisedDateSupplierSOW &&
      !first(project.projectToSuppliers)?.supplierSOWSignedOutsideOfPlatform;

    const showTabForClient =
      isClient &&
      project.finalisedDateClientSOW &&
      !project.clientSOWSignedOutsideOfPlatform;

    const canSee = isAdminOrDL || showTabForTA || showTabForClient;

    return (
      this.canSeeByProjectTypes &&
      canSee &&
      !(
        project.clientSOWSignedOutsideOfPlatform &&
        !find(
          project.projectToSuppliers,
          pts => !pts.supplierSOWSignedOutsideOfPlatform,
        )
      )
    );
  }

  @computed get projectPeriods() {
    const { project } = this.projectsStore;

    const startDate = moment.utc(project.startDate);
    const endDate = moment.utc(project.endDate);

    let chargesArr = [];
    const invoicesArr = [];

    const currentDate = moment.utc(project.startDate);

    while (endDate.isAfter(currentDate)) {
      const end = endDate.isBefore(
        moment
          .utc(currentDate)
          .add({ months: 1 })
          .startOf('month'),
      )
        ? endDate
        : moment.utc(currentDate).endOf('month');

      const totalDays = getNumberOfWeekdays(
        moment.utc(currentDate),
        moment.utc(end),
      );

      chargesArr.push({
        start: currentDate.format('DD MMMM YYYY'),
        end: end.format('DD MMMM YYYY'),
        totalDays,
        totalForClient: getClientAllocationTotal(
          {
            allocations: [
              ...this.allocationsForClientSOW.allocations,
              ...this.projectsStore.project.deazyAllocations,
            ],
          },
          totalDays / 5,
        ),
        totalForSupplier: getSupplierAllocationTotal(
          {
            allocations: this.currentPTOSFromProjectsStore?.allocations,
          },
          totalDays / 5,
        ),
      });

      currentDate.add({ months: 1 }).startOf('month');
    }

    const tDays = getNumberOfWeekdays(
      moment.utc(startDate),
      moment.utc(startDate).endOf('month'),
    );
    if (tDays < 5 && chargesArr.length > 1) {
      const [firstPeriod, ...restPeriods] = chargesArr;
      restPeriods[0] = {
        ...restPeriods[0],
        start: firstPeriod.start,
        totalDays: getNumberOfWeekdays(
          moment.utc(firstPeriod.start),
          moment.utc(restPeriods[0].end),
        ),
        totalForClient: getClientAllocationTotal(
          {
            allocations: this.allocationsForClientSOW.allocations,
          },
          getNumberOfWeekdays(
            moment.utc(firstPeriod.start),
            moment.utc(restPeriods[0].end),
          ) / 5,
        ),
        totalForSupplier: getSupplierAllocationTotal(
          project,
          getNumberOfWeekdays(
            moment.utc(firstPeriod.start),
            moment.utc(restPeriods[0].end),
          ) / 5,
        ),
      };
      chargesArr = restPeriods;
    }

    for (let i = 0; i < chargesArr.length; i += 1) {
      // if first period has less than 5 total days
      // merge first two invoices and bill start of second period month
      if (i === 0 && chargesArr[0].totalDays < 5 && chargesArr.length > 1) {
        i += 1;
        invoicesArr.push({
          date: chargesArr[i].start,
          periodStart: chargesArr[0].start,
          periodEnd: chargesArr[i].end,
          total: chargesArr[0].totalForClient + chargesArr[i].totalForClient,
        });
        // else mirror charge periods
      } else {
        invoicesArr.push({
          date: chargesArr[i].start,
          periodStart: chargesArr[i].start,
          periodEnd: chargesArr[i].end,
          total: chargesArr[i].totalForClient,
        });
      }
    }

    return { chargesArr, invoicesArr };
  }

  yellowHighlightWrapper = (body = '') =>
    `<span style="font-weight: 400; background-color: #feff00;">${body}</span>`;

  preparePaymentTableDataTag = (isEditable, fourColumns, body = '') =>
    `<td style="width: ${
      fourColumns ? `25%` : '33%'
    };" contenteditable='${isEditable}'>${body}</td>`;

  @observable linkedDocuments = [];

  @observable isLoadingLinkedDocuments = false;

  @action setDocumentProperty = (docId, obj = {}) => {
    this.linkedDocuments = map(this.linkedDocuments, d => ({
      ...d,
      ...(docId === d.id && obj),
    }));
  };

  @action fetchLinkedDocuments = async ptsId => {
    const { id: projectId } = this.projectsStore.project;
    this.isLoadingLinkedDocuments = true;
    try {
      const { data } = await this.API[
        !ptsId ? 'getClientLinkedDocuments' : 'getSupplierLinkedDocuments'
      ](projectId, ptsId);
      this.linkedDocuments = data;
    } catch {
      this.toastsStore.showError({
        title: 'Fetching linked documents failed. Please, try again later.',
      });
    } finally {
      this.isLoadingLinkedDocuments = false;
    }
  };

  @action addLinkedDocument = async ({ ptsId, ...values }, successCb) => {
    const { id: projectId } = this.projectsStore.project;
    try {
      const { data } = await this.API[
        !ptsId ? 'createClientLinkedDocuments' : 'createSupplierLinkedDocuments'
      ]({ projectId, ptsId }, values);
      this.toastsStore.showSuccess({
        title: 'New document link added successfully.',
      });
      this.linkedDocuments = [...(this.linkedDocuments || []), data];
      if (successCb) {
        successCb();
      }
    } catch (e) {
      this.toastsStore.showError({
        title: 'Posting new link failed. Please, try again later.',
      });
    }
  };

  @action deleteLinkedDocument = async (ptsId, documentId) => {
    const { id: projectId } = this.projectsStore.project;
    this.setDocumentProperty(documentId, { isDeleting: true });
    try {
      await this.API[
        !ptsId ? 'deleteClientLinkedDocuments' : 'deleteSupplierLinkedDocuments'
      ](projectId, documentId);
      this.linkedDocuments = reject(this.linkedDocuments, { id: documentId });
    } catch {
      this.toastsStore.showError({
        title: 'Delete failed. Please, try again later.',
      });
    } finally {
      this.setDocumentProperty(documentId, { isDeleting: false });
    }
  };
}
