import { Flex, Box } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import { formatCurrency, formatNumber } from '@utils';
import { forEach } from 'lodash-es';
import React from 'react';
import Icon from '@components/Icon';

const getHoursAndCost = resources => {
  let hours = 0;
  let cost = 0;
  forEach(resources, r => {
    if (r?.cost) {
      cost += +r.cost;
    }
    if (r?.hours) {
      hours += +r.hours;
    }
  });

  return { hours, cost };
};

const TaskTotals = ({
  label,
  currency,
  resources,
  mainNameQA,
  isSmallerScreen,
}) => {
  const { hours, cost } = getHoursAndCost(resources);
  if (isSmallerScreen) {
    return (
      <Box
        w="100%"
        mt="24px"
        border="2px solid"
        borderRadius="8px"
        borderColor="blue.solid"
        p="12px"
        bg="#fff"
      >
        <Typography variant="h4" data-test-id={`${mainNameQA}--label`} mb="4px">
          {label}
        </Typography>
        <Flex w="100%" alignItems="center">
          <Box
            alignSelf="center"
            w="100%"
            h="48px"
            border="1px solid"
            borderColor="dark.mid"
            borderRadius="8px"
            mr="8px"
            data-test-id={`${mainNameQA}--hours`}
            px="12px"
            py="15px"
            fontSize="15px"
          >
            {formatNumber(hours, 1)}
          </Box>
          <Flex
            alignItems="center"
            h="48px"
            w="100%"
            px="12px"
            py="10px"
            border="1px solid"
            borderColor="dark.mid"
            borderRadius="8px"
            data-test-id={`${mainNameQA}--cost`}
          >
            <Typography mr="auto" fontSize="15px">
              {formatCurrency(cost, currency)}
            </Typography>
            <Icon name="link" color="blue.solid" ml="3px" fontSize={22} />
          </Flex>
        </Flex>
      </Box>
    );
  }
  return (
    <Flex
      w="100%"
      mt="24px"
      border="2px solid"
      borderRadius="8px"
      borderColor="blue.solid"
      h="70px"
      bg="#fff"
      boxShadow="0 0 24px 0 rgb(0 0 0 / 15%)"
    >
      <Flex
        alignItems="center"
        w="100%"
        px="16px"
        borderRight="1px solid"
        borderColor="dark.mid"
      >
        <Typography variant="title" data-test-id={`${mainNameQA}--label`}>
          {label}
        </Typography>
      </Flex>
      <Flex
        alignItems="center"
        maxW="110px"
        w="100%"
        px="16px"
        borderRight="1px solid"
        borderColor="dark.mid"
        data-test-id={`${mainNameQA}--hours`}
      >
        {formatNumber(hours, 1)}
      </Flex>
      <Flex
        alignItems="center"
        maxW="122px"
        w="100%"
        px="16px"
        data-test-id={`${mainNameQA}--cost`}
      >
        {formatCurrency(cost, currency)}
      </Flex>
    </Flex>
  );
};

export default TaskTotals;
