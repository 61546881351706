/* eslint-disable */
import { Avatar, Box, Flex, Stack } from '@chakra-ui/react';
import Popover, { PopoverList } from '@components/Popover';
import styled from '@emotion/styled';
import useMediaQuery from '@hooks/useMediaQuery';
import { APP_ROUTES } from '@routes';
import { get, find } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import qs from 'query-string';
import { breakpoint } from '@styles/breakpoints';
import React from 'react';
import { NavLink, withRouter, useRouteMatch } from 'react-router-dom';
import CreateButton from '../CreateButton';
import SearchBar from '../forms/SearchBar';
// import BriefChatBubble from './BriefChatBubble';
// import NotificationBell from './NotificationBell';
import TopbarHeader from './TopbarHeader';

const Wrapper = styled(Flex)`
  position: fixed;
  height: 80px;
  background: #fff;
  border-bottom: 1px solid #d9dada;
  @media ${breakpoint.lg} {
    height: 63px;
    width: 100%;
    left: 0;
    padding: 0 12px;
  }
`;

const UserAvatar = styled(Avatar)`
  cursor: pointer;
  @media ${breakpoint.lg} {
    width: 40px;
    height: 40px;
  }
`;

let to = null;

const Topbar = ({
  history,
  location: { search },
  title,
  isBrief,
  isDp,
  isProject,
  searchBar,
  isLoadingTitle,
  onImportProjectModalOpen,
  onCreateUserModalOpen,
  usersStore: {
    profile: { fullname, profilePictureURL, sendbirdId, username },
    isAdminOrDL,
    isTeamMember,
    isTeamAdmin,
    isProfileLoaded,
  },
  authStore: { signOut, fronteggSignOut },
  appStore: {
    toggleBriefChat,
    featureFlags: {
      'show-brief-chat': showBriefChat,
      'uo-frontegg-auth': uoFronteggAuth,
    },
    isBriefChatOpened,
  },
  briefsStore: { addToChannels, brief, sendMessageToSlack, currentChannelUrl },
}) => {
  const params = qs.parse(search);
  const isProfilesRoute = useRouteMatch('/profiles');
  const onSearchChange = query => {
    clearTimeout(to);

    to = setTimeout(() => {
      history.push(
        `?${qs.stringify({
          ...params,
          ...(isProfilesRoute ? { name: query } : { query }),
          page: 1,
        })}`,
      );
    }, 500);
  };

  const isBriefPage = useRouteMatch([
    APP_ROUTES.briefById(),
    APP_ROUTES.briefPlugAndPlayById(),
  ]);
  const userProfileOptions = [
    {
      label: 'Edit profile',
      visible: true,
      as: NavLink,
      to: APP_ROUTES.profile,
    },
    {
      label: 'Sign out',
      visible: true,
      onClick: uoFronteggAuth ? fronteggSignOut : signOut,
    },
  ];

  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');
  const isShowingCreateButton =
    !isTeamMember && isProfileLoaded && !isSmallerScreen;

  const shoudlSeeChatIcon = React.useMemo(() => {
    const key = brief.isPlugAndPlay ? 'ppBriefToSuppliers' : 'briefToSuppliers';

    const SupplierIsAssigned = !!find(
      brief[key],
      b => b.status !== 'Shortlisted',
    );

    if (isBriefPage && (isAdminOrDL || isTeamAdmin) && SupplierIsAssigned) {
      return true;
    }
    return false;
  }, [isBriefPage, isAdminOrDL, isTeamAdmin, brief]);

  return (
    <Wrapper
      align="center"
      px="24px"
      justify="space-between"
      left="92px"
      width="calc(100% - 92px)"
      zIndex={100}
    >
      <TopbarHeader
        title={title}
        isBrief={isBrief}
        isLoadingTitle={isLoadingTitle}
        isProject={isProject}
        isDp={isDp}
      />

      <Stack isInline spacing={isSmallerScreen ? 4 : 6} align="center">
        {searchBar && (
          <SearchBar
            placeholder={searchBar}
            onChange={onSearchChange}
            initialValue={get(params, '')}
          />
        )}

        {/* {showBriefChat && (isTeamAdmin || isAdminOrDL) && (
          <Flex alignItems="center">
            {shoudlSeeChatIcon && (
              <>
                <BriefChatBubble
                  brief={brief}
                  sendbirdId={sendbirdId}
                  toggleBriefChat={toggleBriefChat}
                  isBriefChatOpened={isBriefChatOpened}
                  addToChannels={addToChannels}
                  isAdminOrDL={isAdminOrDL}
                  isTeamAdmin={isTeamAdmin}
                  sendMessageToSlack={sendMessageToSlack}
                  username={username}
                  currentChannelUrl={currentChannelUrl}
                />
                <Box h="32px" w="1px" background="#D9DADA" />
              </>
            )}
            <NotificationBell />
          </Flex>
        )} */}

        {isShowingCreateButton && (
          <CreateButton
            onImport={onImportProjectModalOpen}
            onCreateUser={onCreateUserModalOpen}
          />
        )}
        <Popover
          trigger={
            <UserAvatar
              ignoreFallback
              name={fullname}
              src={profilePictureURL}
              data-test-id="userAvatar"
            />
          }
        >
          <PopoverList options={userProfileOptions} />
        </Popover>
      </Stack>
    </Wrapper>
  );
};

export default withRouter(
  inject(
    'usersStore',
    'authStore',
    'appStore',
    'briefsStore',
  )(observer(Topbar)),
);
