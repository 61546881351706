import { Flex } from '@chakra-ui/react';
import useDownload from '@hooks/useDownload';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import { inject, observer } from 'mobx-react';
import React from 'react';

const ProposalTitle = ({
  briefsStore: {
    brief: { isPlugAndPlay, id: briefId },
  },
  proposalsStore: {
    proposalData: { id: proposalId },
  },
  mainNameQA,
}) => {
  const url = `/${
    isPlugAndPlay ? 'plug-and-play-briefs' : 'briefs'
  }/${briefId}/proposals/${proposalId}/deazyReport`;

  const { onDownload, isLoading } = useDownload({
    url,
  });
  if (isPlugAndPlay) {
    return null;
  }

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Typography variant="h1">Deazy Proposal</Typography>
      <Button
        size="lg"
        ml="auto"
        isLoading={isLoading}
        onClick={onDownload}
        data-test-id={`${mainNameQA}--exportToCSVButton`}
      >
        Export to CSV
      </Button>
    </Flex>
  );
};

export default inject('briefsStore', 'proposalsStore')(observer(ProposalTitle));
