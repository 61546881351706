import Button from '@styles/Button';
import Tooltip from '@styles/Tooltip';
import React from 'react';

const CompleteTaskButton = props => {
  return (
    <Tooltip label="Mark task as complete">
      <Button
        iconButton={{ name: 'check' }}
        variant="green"
        minW="24px"
        w="24px"
        h="24px"
        {...props}
      />
    </Tooltip>
  );
};

export default CompleteTaskButton;
