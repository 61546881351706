import { setIn } from 'final-form';

// async creates weird spread errors object in final form!!! will never use final form again!!!
const yupFormValidation = schema => values => {
  if (typeof schema === 'function') {
    schema = schema();
  }
  try {
    schema.validateSync(values, { abortEarly: false });
  } catch (e) {
    return e.inner.reduce((errors, error) => {
      return setIn(errors, error.path, error.message);
    }, {});
  }
};

export default yupFormValidation;
