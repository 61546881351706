import { inject, observer } from 'mobx-react';
import React from 'react';
import { nanoid } from 'nanoid';
import {
  findIndex,
  groupBy,
  isEmpty,
  last,
  map,
  values as _values,
  omit,
  reject,
  find,
  includes,
  get,
} from 'lodash-es';
import { FormSpy } from 'react-final-form';
import { sleep } from '@utils';
import { isFixedPrice, isRetainerOrTAForFixedPrice } from '@utils/projectUtils';
import Spinner from '@styles/Spinner';
import { toJS } from 'mobx';
import Button from '@styles/Button';
import ZoneContainer from '../ZoneContainer';
import { recalculateProjectEndDateOnAllocationChange } from './allocationsHelpers';

const defaultProjectMarginPercent = 1.2;

const AllocationTable = ({
  fieldName = 'allocations',
  disabledFields,
  mainNameQA,
  initialAllocations,
  AllocationRow,
  isTeamMember,
  toastsStore,
  isEditing = false,
  disallowRowChange = false,
  isManualAddingRow = false,
  usersStore: { isTeamAdmin },
  borderDateForAllocation,
  projectsStore: { project },
  ...props
}) => {
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    if (!isEmpty(initialAllocations)) {
      setRows([...toJS(initialAllocations)]);
    } else {
      setRows([{ id: nanoid(10) }]);
    }
  }, [JSON.stringify(initialAllocations)]);

  const onRowDelete = id => setRows(reject(rows, { id }));

  const addRow = async (values, form) => {
    if (values.isNewProject) {
      recalculateProjectEndDateOnAllocationChange(form);
      await sleep(100);
    }
    const id = nanoid(10);
    form.change(fieldName, [
      ...get(values, fieldName),
      {
        id,
        startDate: isEditing ? borderDateForAllocation : values.startDate,
        endDate: values.endDate,
      },
    ]);

    setRows([
      ...get(values, fieldName),
      { id, startDate: values.startDate, endDate: values.endDate },
    ]);
  };
  const deleteRow = allocationId => (values, form) => {
    const newRows = reject(get(values, fieldName), { id: allocationId });
    form.change(fieldName, newRows);
    recalculateProjectEndDateOnAllocationChange(form);
    onRowDelete(allocationId);
  };

  if (isEditing && isFixedPrice(project) && !borderDateForAllocation) {
    return <Spinner my="24px" mx="auto" variant="medium" />;
  }

  return (
    <>
      {!isManualAddingRow && (
        <FormSpy subscription={{ values: true }}>
          {({ values, form }) => {
            if ((get(values, fieldName) || []).length > rows.length) {
              setRows([...(get(values, fieldName) || [])]);
            }
            if (isEmpty(get(values, fieldName))) {
              form.change(
                fieldName,
                map(rows, row => ({
                  ...row,
                  startDate: values.startDate,
                  endDate: values.endDate,
                })),
              );
            }

            if (includes(fieldName, 'deazyAllocations') && !disallowRowChange) {
              const groupedDeazyAllocations = groupBy(
                get(values, fieldName),
                'manager.id',
              );
              const duplicate = last(
                find(_values(groupedDeazyAllocations), coll => coll.length > 1),
              );
              if (
                !isEmpty(omit(duplicate, 'id')) &&
                !isEmpty(duplicate?.manager)
              ) {
                const duplicatedIdx = findIndex(
                  get(values, fieldName),
                  a => a.id === duplicate.id,
                );
                const newId = nanoid(10);
                form.change(`${fieldName}[${duplicatedIdx}]`, {
                  ...omit(duplicate, 'manager'),
                  id: newId,
                });
                toastsStore.showInfo({
                  title: 'You cannot add the same PM twice!',
                });
                setRows(
                  map(rows, row => ({
                    id: row.id === duplicate.id ? newId : row.id,
                  })),
                );
              } else if (
                !isEmpty(
                  omit(last(get(values, fieldName)), [
                    'id',
                    'startDate',
                    'endDate',
                  ]),
                ) &&
                !disallowRowChange
              ) {
                sleep(0).then(() => {
                  addRow(values, form);
                });
              }
            }

            if (
              !isEmpty(
                omit(last(get(values, fieldName)), [
                  'id',
                  'startDate',
                  'endDate',
                ]),
              ) &&
              !includes(fieldName, 'deazyAllocations') &&
              !disallowRowChange
            ) {
              // tricky
              sleep(0).then(() => {
                addRow(values, form);
              });
            }
            return null;
          }}
        </FormSpy>
      )}
      {map(rows, (allocation, idx) => (
        <AllocationRow
          isTeamAdmin={isTeamAdmin}
          mainNameQA={mainNameQA}
          key={`${fieldName}-row-${allocation.id}`}
          projectMarginPercent={defaultProjectMarginPercent}
          allocationIdx={idx}
          isTeamMember={isTeamMember}
          disabledFields={disabledFields}
          allocationId={allocation.id}
          isOnlyFirstRow={rows.length === 1}
          canBeDeleted={idx !== rows.length - 1}
          addRow={addRow}
          disallowRowChange={disallowRowChange}
          deleteRow={deleteRow}
          fieldName={fieldName}
          isManualAddingRow={isManualAddingRow}
          isEditing={isEditing}
          isFixedPrice={isFixedPrice(project)}
          isRetainerOrTAForFixedPrice={isRetainerOrTAForFixedPrice(project)}
          borderDateForAllocation={borderDateForAllocation}
          {...props}
        />
      ))}
      {isManualAddingRow && isEditing && (
        <FormSpy subscription={{ values: true }}>
          {({ values, form }) => {
            return (
              <ZoneContainer
                bg="#fff"
                py="32px"
                borderColor="dark.mid"
                mt="40px"
              >
                <Button
                  onClick={() => addRow(values, form)}
                  variant="outline"
                  leftIcon={{ name: 'add' }}
                  size="lg"
                >
                  Add another role
                </Button>
              </ZoneContainer>
            );
          }}
        </FormSpy>
      )}
    </>
  );
};

export default inject(
  'usersStore',
  'projectsStore',
  'toastsStore',
)(observer(AllocationTable));
