import FiltersFormSidebar from '@components/FiltersFormSidebar';
import React from 'react';

const SORT_OPTIONS = [
  {
    label: 'Creation date (new to old)',
    value: 'orderDirection=DESC&orderBy=createdAt',
  },
  {
    label: 'Creation date (old to new)',
    value: 'orderDirection=ASC&orderBy=createdAt',
  },
  {
    label: 'Brief title (a-z)',
    value: 'orderDirection=ASC&orderBy=name',
  },
  {
    label: 'Brief title (z-a)',
    value: 'orderDirection=DESC&orderBy=name',
  },
];

const STATUSES = [
  {
    label: 'All',
    value: 'all-statuses',
  },
  {
    label: 'Open',
    value: 'Open',
  },
  {
    label: 'On hold',
    value: 'On Hold',
  },
  {
    label: 'Closed',
    value: 'Closed',
  },
];

const BriefFilters = ({
  onSubmit,
  onResetFilters,
  initialValues,
  isAdminOrDL,
  isLoading,
  mainNameQA,
}) => {
  const fieldsToShow = [
    'briefType',
    ...(isAdminOrDL ? ['suppliers', 'deazyLeadIds', 'techStack'] : []),
  ];

  return (
    <FiltersFormSidebar
      onSubmit={onSubmit}
      onReset={onResetFilters}
      initialValues={initialValues}
      sortOptions={SORT_OPTIONS}
      statusOptions={STATUSES}
      fieldsToShow={fieldsToShow}
      loading={isLoading}
      mainNameQA={mainNameQA}
    />
  );
};

export default BriefFilters;
