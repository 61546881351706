import React from 'react';
import { Field, Form } from 'react-final-form';
import { Flex } from '@chakra-ui/react';
import { ErrorText } from '@components/forms/FormError';
import TextInput from '@components/forms/TextInput';
import {
  composeValidators,
  passwordsMustMatch,
  required,
} from '@utils/formValidators';
import Button from '@styles/Button';

const PasswordResetForm = ({ onSubmit, onCancel }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitError, submitting }) => {
      return (
        <form onSubmit={handleSubmit}>
          <Flex alignItems="center" flexDirection="column">
            <Field
              label="Current password"
              name="currentPassword"
              placeholder="Enter your current password"
              type="password"
              containerProps={{ mb: '16px' }}
              validate={required}
              component={TextInput}
            />

            <Field
              label="New password"
              containerProps={{ mb: '16px' }}
              name="newPassword"
              placeholder="Enter your current password"
              type="password"
              component={TextInput}
              validate={required}
            />
            <Field
              label="Password confirmation"
              name="repeatPassword"
              placeholder="Enter your current password"
              type="password"
              component={TextInput}
              validate={composeValidators(required, passwordsMustMatch)}
            />
            {submitError && (
              <ErrorText alignSelf="start">{submitError}</ErrorText>
            )}
          </Flex>
          <Flex justifyContent="flex-end">
            <Button
              variant="ghost"
              onClick={onCancel}
              mt="12px"
              mb="32px"
              h="22px"
              p="0px 12px"
            >
              Back to login page
            </Button>
          </Flex>
          <Button
            w="100%"
            size="lg"
            isLoading={submitting}
            isDisabled={submitting}
            type="submit"
          >
            Save
          </Button>
        </form>
      );
    }}
  />
);

export default PasswordResetForm;
