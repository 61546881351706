import { sleep, trimObj } from '@utils';
import { debounce, isEmpty, isEqual } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';

let isSaving = false;

const waitForSaveToEnd = async isBeingSaved =>
  new Promise(resolve => {
    const check = () => {
      setTimeout(() => {
        if (!isBeingSaved) {
          check();
        } else {
          resolve();
        }
      }, 50);
    };
    check();
  });

const AutoSave = ({ formApi, ignoreErrors, onSave }) => {
  const save = async ({ values }) => {
    // trimObj - to prevent infinity loop
    const { initialValues, errors } = formApi.getState();
    if (
      !isEqual(trimObj(values), trimObj(initialValues)) &&
      (ignoreErrors || isEmpty(errors))
    ) {
      if (isSaving) {
        await sleep(100);
        await waitForSaveToEnd(isSaving);
        save({ values });
      } else {
        isSaving = true;
        await onSave(values);
        isSaving = false;
      }
    }
  };

  const debouncedSave = React.useCallback(debounce(save, 1000), []);

  React.useEffect(() => {
    const unsubscribe = formApi.subscribe(
      debouncedSave,
      {
        values: true,
      },
      [],
    );

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, []);

  return null;
};

export default inject('proposalsStore')(observer(AutoSave));
