import { isEmpty, omitBy } from 'lodash-es';
import qs from 'query-string';
import { useHistory } from 'react-router';

export default function useSearchParams() {
  const history = useHistory();
  return {
    searchObj: qs.parse(history.location.search),
    onSearch: params =>
      history.push(`?${qs.stringify(omitBy(params, isEmpty))}`),
  };
}
