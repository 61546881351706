import {
  Box,
  Popover as ChakraPopover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
} from '@chakra-ui/react';
import Icon from '@components/Icon';
import { filter, map } from 'lodash-es';
import React from 'react';

const DropdownOption = ({ icon, children, iconProps, ...props }) => (
  <Box
    _hover={{ bg: 'primary.100', textDecoration: 'none' }}
    py="9px"
    px="12px"
    borderRadius="8px"
    cursor="pointer"
    zIndex="100"
    {...props}
  >
    {icon && <Icon mr="8px" mb="3px" name={icon} {...iconProps} />}
    {children}
  </Box>
);

export const PopoverList = ({ options }) => {
  return (
    <Stack spacing="0px">
      {map(
        filter(options, 'visible'),
        ({ label, dataTestId, visible, ...props }) => (
          <DropdownOption data-test-id={dataTestId} key={label} {...props}>
            {label}
          </DropdownOption>
        ),
      )}
    </Stack>
  );
};

const Popover = ({
  trigger,
  withOverlay,
  variant = 'default',
  children,
  overlayOffsetTop,
  contentStyles,
  ...popoverProps
}) => {
  const ref = React.useRef();

  const popoverContent = (
    <PopoverContent
      zIndex={40000}
      margin="0 16px"
      borderColor="#d9dada"
      borderRadius="8px"
      boxShadow="0px 0px 24px rgba(0, 0, 0, 0.15)"
      {...contentStyles}
    >
      <PopoverBody padding="8px">{children}</PopoverBody>
    </PopoverContent>
  );

  return (
    <>
      {withOverlay && (
        <Box
          ref={ref}
          position="fixed"
          width="100%"
          height="100%"
          backgroundColor="rgba(43, 35, 29, 0.3)"
          visibility={popoverProps.isOpen ? 'visible' : 'hidden'}
          bottom={overlayOffsetTop ? 0 : '88px'}
          top={overlayOffsetTop}
          zIndex="200"
          left="0"
          right="0"
        />
      )}
      <ChakraPopover variant={variant} {...popoverProps}>
        <PopoverTrigger>{trigger}</PopoverTrigger>
        {withOverlay ? (
          <Portal containerRef={ref}>{popoverContent}</Portal>
        ) : (
          popoverContent
        )}
      </ChakraPopover>
    </>
  );
};

export default Popover;
