/* eslint-disable max-len */
import { groupBy, get } from 'lodash-es';
import { NOTIFICATION_TYPES } from '@app/constants';
import { COLORS } from '@styles/theme';
import moment from 'moment';

const MODEL_NAMES = {
  CUSTOMER: 'Customer',
  CLIENT: 'Client',
  SUPPLIER: 'Supplier',
  BRIEF: 'Brief',
  PNP: 'PlugPlayBrief',
  PROJECT: 'Project',
  INVOICE: 'ScheduleInvoice',
  PROJECT_PAYMENT: 'ProjectPayment',
  PROJECT_TO_SUPPLIER: 'ProjectToSupplier',
  STAFF_PROFILE: 'StaffProfile',
};

const BRIEF_CATEGORY = {
  label: 'brief',
  bg: 'aqua.light',
  color: 'dark.solid',
};

const PROPOSAL_CATEGORY = {
  label: 'proposal',
  bg: 'green.light',
  color: 'dark.solid',
};

const INVOICE_CATEGORY = {
  label: 'invoice',
  bg: 'primary.100',
  color: 'dark.solid',
};

const STAFF_PROFILE_CATEGORY = {
  label: 'profile',
  bg: 'primary.500',
  color: '#fff',
};

const PROJECT_CATEGORY = {
  label: 'project',
  bg: 'yellow.lightSecondary',
  color: 'dark.solid',
};

const routerLinkWrapper = (link, body) =>
  `<Link style="color: ${COLORS.blue[500]}" to="${link}">${body}</Link>`;

export const prepareTaskDescription = task => {
  const { action } = task;
  const clientData = action?.client || {};

  const groupedVariables = groupBy(action?.metadata?.variables, 'class');

  const supplierData = get(groupedVariables, `${MODEL_NAMES.SUPPLIER}[0]`, {});
  const projectToSupplierData = get(
    groupedVariables,
    `${MODEL_NAMES.PROJECT_TO_SUPPLIER}[0]`,
    {},
  );
  const briefData =
    get(groupedVariables, `${MODEL_NAMES.BRIEF}[0]`) ||
    get(groupedVariables, `${MODEL_NAMES.PNP}[0]`, {});
  const projectData = get(groupedVariables, `${MODEL_NAMES.PROJECT}[0]`, {});
  const invoiceData = get(groupedVariables, `${MODEL_NAMES.INVOICE}[0]`, {});
  const staffProfileData = get(
    groupedVariables,
    `${MODEL_NAMES.STAFF_PROFILE}[0]`,
    {},
  );

  const projectPaymentData = get(
    groupedVariables,
    `${MODEL_NAMES.PROJECT_PAYMENT}[0]`,
    {},
  );

  const isPnP = briefData.class === MODEL_NAMES.PNP;

  const baseUrl = isPnP ? '/team-augmentation-briefs' : '/briefs';

  const briefLink = `${baseUrl}/${briefData.id}/overview`;

  const candidatesLink = `${baseUrl}/${briefData.id}/candidates`;

  const questionLink = `${baseUrl}/${briefData.id}/questions`;

  const invoiceLink = `/clients/${clientData.slug || 'deazy'}/projects/${
    projectData.slug
  }/payments?paymentId=${projectPaymentData?.id}`;

  const sowLink = `/clients/${clientData.slug || 'deazy'}/projects/${
    projectData.slug
  }/statement-of-work/delivery-partner?projectToSupplierId=${
    projectToSupplierData?.id
  }`;

  const projectLink = `/clients/${clientData.slug || 'deazy'}/projects/${
    projectData.slug
  }/allocations`;

  const staffProfileLink = `/profile/${staffProfileData.id}`;

  if (action.type === NOTIFICATION_TYPES.NEW_PROPOSAL_SUBMITED) {
    return {
      description: `${
        supplierData.name
      } has submitted a proposal for ${routerLinkWrapper(
        briefLink,
        briefData.name,
      )}. Please review this.`,
      category: PROPOSAL_CATEGORY,
      link: briefLink,
    };
  }

  if (action.type === NOTIFICATION_TYPES.TA_PROPOSAL_HAS_BEEN_UPDATED) {
    return {
      description: `${
        supplierData.name
      }  has submitted an update to ${routerLinkWrapper(
        candidatesLink,
        briefData.name,
      )}. Please review the changes.`,
      category: BRIEF_CATEGORY,
      link: candidatesLink,
    };
  }

  if (action.type === NOTIFICATION_TYPES.APPROVE_INVOICE) {
    return {
      description: `An invoice for ${routerLinkWrapper(
        invoiceLink,
        projectData.name,
      )} has been pre-approved and is due to be sent on ${moment(
        invoiceData.invoiceDate,
      ).format('DD MMM')} . Please review and approve this.`,
      category: INVOICE_CATEGORY,
      link: invoiceLink,
    };
  }

  if (action.type === NOTIFICATION_TYPES.FINALISE_SOW_FOR_NEW_DP) {
    return {
      description: `${supplierData.name} has been added to ${routerLinkWrapper(
        sowLink,
        projectData.name,
      )}. Please finalise their new Statement of Work.`,
      category: PROJECT_CATEGORY,
      link: sowLink,
    };
  }

  if (action.type === NOTIFICATION_TYPES.PRE_APPROVE_INVOICE) {
    return {
      description: `An invoice for ${routerLinkWrapper(
        invoiceLink,
        projectData.name,
      )} is due to be sent on ${moment(invoiceData.invoiceDate).format(
        'DD MMM',
      )}. Please review this and pre-approve it if you are happy for it to be sent out.`,
      category: INVOICE_CATEGORY,
      link: invoiceLink,
    };
  }

  if (action.type === NOTIFICATION_TYPES.APPROVE_CREDIT_NOTE) {
    return {
      description: `A credit note ${routerLinkWrapper(
        invoiceLink,
        projectData.name,
      )} has been pre-approved and is due to be sent on ${moment(
        invoiceData.creditNoteDate,
      ).format('DD MMM')}. Please review this and approve this.`,
      category: INVOICE_CATEGORY,
      link: invoiceLink,
    };
  }

  if (action.type === NOTIFICATION_TYPES.NEW_BRIEF_CREATED) {
    return {
      description: `${
        clientData.name
      } has submitted a new brief: ${routerLinkWrapper(
        briefLink,
        briefData.name,
      )}. Please review this and assign a Delivery Partner.`,
      category: BRIEF_CATEGORY,
      link: briefLink,
    };
  }

  if (action.type === NOTIFICATION_TYPES.ALL_DPS_DECLINED_BRIEF) {
    return {
      description: `All Delivery Partners assigned to ${routerLinkWrapper(
        briefLink,
        briefData.name,
      )} have declined the brief. Please assign an alternative Delivery Partner.`,
      category: BRIEF_CATEGORY,
      link: briefLink,
    };
  }

  if (action.type === NOTIFICATION_TYPES.NEW_QUESTION) {
    return {
      description: `Someone has asked a question on your brief: ${routerLinkWrapper(
        questionLink,
        briefData.name,
      )}. Please respond.`,
      link: questionLink,
      category: BRIEF_CATEGORY,
    };
  }

  if (action.type === NOTIFICATION_TYPES.BRIEF_IS_DUE_TO_CLOSE_TODAY) {
    return {
      link: briefLink,
      description: `Your brief ${routerLinkWrapper(
        briefLink,
        briefData.name,
      )} is due to close today. Please update the closing date if this brief is still active.`,
      category: BRIEF_CATEGORY,
    };
  }

  if (action.type === NOTIFICATION_TYPES.NEW_PUBLIC_PROFILE_CREATED) {
    return {
      link: staffProfileLink,
      description: `You shared the profile of ${routerLinkWrapper(
        staffProfileLink,
        staffProfileData.name,
      )} for review.`,
      category: STAFF_PROFILE_CATEGORY,
    };
  }

  if (action.type === NOTIFICATION_TYPES.PUBLIC_PROFILE_ACCEPTED) {
    return {
      link: staffProfileLink,
      description: `${routerLinkWrapper(
        staffProfileLink,
        staffProfileData.name,
      )} has been accepted.`,
      category: STAFF_PROFILE_CATEGORY,
    };
  }

  if (action.type === NOTIFICATION_TYPES.PUBLIC_PROFILE_REJECTED) {
    return {
      link: staffProfileLink,
      description: `${routerLinkWrapper(
        staffProfileLink,
        staffProfileData.name,
      )} has been rejected.`,
      category: STAFF_PROFILE_CATEGORY,
    };
  }

  if (action.type === NOTIFICATION_TYPES.PROJECT_SURVEY_TO_COMPLETE) {
    const projectSurveyLink = `/clients/${clientData.slug ||
      'deazy'}/projects/${projectData.slug}/survey`;
    return {
      link: projectSurveyLink,
      description: `You have a survey to complete for ${routerLinkWrapper(
        projectSurveyLink,
        projectData.name,
      )} as it has reached the end of its initial term. Please rate the Delivery Partner on key attributes to enable us to better match Delivery Partners to engagements in future.`,
      category: PROJECT_CATEGORY,
    };
  }

  if (action.type === NOTIFICATION_TYPES.PROJECT_IS_DUE_TO_END_IN_30_DAYS) {
    return {
      link: projectLink,
      description: `Your project  ${routerLinkWrapper(
        projectLink,
        projectData.name,
      )}  is due to end in 30 days. Consider extension options.`,
      category: PROJECT_CATEGORY,
    };
  }

  return undefined;
};
