import {
  PROJECT_AVAILABLE_CURRENCIES,
  ENGAGEMENT_TYPES,
  PROJECT_TYPES,
} from '@app/constants';
import { isEmpty } from 'lodash-es';
import { date, object, string } from 'yup';
import { allocationValidation } from './allocationValidation';

const projectValidation = object({
  client: object()
    .required('This field is required')
    .test('client', 'Client error', (value, testContext) => {
      if (isEmpty(value)) {
        return testContext.createError({
          message: 'This field is required',
        });
      }
      if (value?.id === 'deazy') {
        return true;
      }
      if (!value?.setupCompleted) {
        return testContext.createError({
          message:
            'This client is missing some details. Please complete these details before creating a project.',
        });
      }
      if (
        !value.location ||
        !value.currency ||
        !value.financeEmail ||
        !value.address
      ) {
        return testContext.createError({
          message:
            'Invoice details must be added to the client profile before the project can be created.',
        });
      }
      return true;
    }),

  nameError: string()
    .test('nameError', 'Must be empty', value => !value)
    .nullable(),

  clientLead: object().when('client', {
    is: client => client?.id !== 'deazy',
    then: object()
      .required('This field is required')
      .nullable(),
    otherwise: object().nullable(),
  }),

  xeroProjectCategory: string().required('This field is required'),

  engagementType: string()
    .oneOf(Object.values(ENGAGEMENT_TYPES))
    .required('This field is required'),

  pm: object().required('This field is required'),

  startDate: date().required('This field is required'),

  endDate: date().required('This field is required'),

  projectType: string()
    .oneOf(Object.values(PROJECT_TYPES))
    .required('This field is required'),

  currency: string()
    .oneOf(PROJECT_AVAILABLE_CURRENCIES)
    .required('This field is required'),

  ...allocationValidation,
});

export { projectValidation };
