import Badge from '@styles/Badge';
import React from 'react';

const getBadgeProps = project => {
  if (project.archived) {
    return {
      status: 'Archived',
      color: '#fff',
      bg: 'gray.mid',
    };
  }
  if (project.pending) {
    return {
      status: 'Pending',
      bg: 'aqua.solid',
    };
  }
  if (project.status === 'deleted') {
    return {
      status: 'Deleted',
      color: '#fff',
      bg: 'red.solid',
    };
  }
  return {
    status: 'Active',
    bg: 'green.solid',
    color: 'white',
  };
};

const ProjectCardBadge = ({ project, ...props }) => {
  const { status, bg, color = 'white' } = getBadgeProps(project);
  return (
    <Badge
      bg={bg}
      variant="tertiary"
      position="absolute"
      right="10px"
      top="10px"
      color={color}
      {...props}
    >
      {status}
    </Badge>
  );
};

export default ProjectCardBadge;
