/* eslint-disable max-len */
import { action, makeObservable, observable } from 'mobx';

const RESOURCES = [
  {
    id: 1,
    title: 'Project Plan',
    description:
      'The project plan contains a timeline for individual elements of work that will need to be completed. This needs to be maintained regularly and shared with your client.',
    link: '',
  },
  {
    id: 2,
    title: 'Milestone Plan (light Project Plan)',
    description:
      'The milestone plan tracks key points in the project that need to be completed. It should be shared with your client at the beginning of the project.',
    link: '',
  },
  {
    id: 3,
    title: 'RAID Log',
    description:
      'The RAID log tracks risks, actions, issues and dependencies. It should be reviewed on a weekly basis at minimum.',
    link: '',
  },
  {
    id: 4,
    title: 'Change Log',
    description:
      'The change log should be completed when your client requests a change to a fixed price project.',
    link: '',
  },
  {
    id: 5,
    title: 'Deazy Plug&Play Developer CV Template',
    description:
      'The Developer CV should be submitted alongside proposals for plug & play roles.',
    link: '',
  },
];

export default class ResourcesStore {
  constructor({ API, toastsStore }) {
    makeObservable(this);
    this.API = API;
    this.toastsStore = toastsStore;
  }

  @observable resources = {
    isLoading: false,
    data: [],
  };

  @action fetchResources = async () => {
    this.resources.isLoading = true;
    try {
      // const { data } = await this.API.getResources();
      this.resources.data = RESOURCES;
    } catch (e) {
      this.toastsStore.showError({
        title:
          e.message ||
          'Ooops! Something unexpected happened. Please try again later.',
      });
    } finally {
      this.resources.isLoading = false;
    }
  };

  @action clearResources = () => {
    this.resources = { data: [], isLoading: false };
  };
}
