import { Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';

const SupplierStat = ({ label, value, showZero, ...props }) => {
  return (
    <Flex flexDirection="column" justifyContent="center" {...props}>
      <Typography variant="text3" mb="8px" color="dark.solid">
        {label}
      </Typography>
      <Typography variant="text2" wordBreak="break-word" color="black">
        {value || (showZero ? 0 : '-')}
      </Typography>
    </Flex>
  );
};

export default SupplierStat;
