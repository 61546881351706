import { Stack } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Button from '@styles/Button';
import Typography from '@styles/Typography';
import React from 'react';

const RateConfirmationModal = ({ isOpen, isLoading, onConfirm, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2" mb="24px" textAlign="center">
        Are you sure you want to continue with these rates?
      </Typography>
      <Typography mb="40px" textAlign="center" maxW="488px" mx="auto">
        You have entered rates that are outside of your agreed rates for these
        roles. This may lead to your proposal being rejected.
      </Typography>
      <Stack spacing={3}>
        <Button onClick={onConfirm} isLoading={isLoading}>
          Continue
        </Button>
        <Button onClick={onClose} variant="outline" isDisabled={isLoading}>
          Go back
        </Button>
      </Stack>
    </Modal>
  );
};

export default RateConfirmationModal;
