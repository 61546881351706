import { INVOICE_STATUSES, NEW_INVOICE_STATUSES } from '@app/constants';
import { includes } from 'lodash-es';

export const isInvoiceEditable = status => {
  const editableStatuses = [
    INVOICE_STATUSES.ACTUALS_MISSING,
    INVOICE_STATUSES.PENDING_APPROVAL,
    INVOICE_STATUSES.READY,
    INVOICE_STATUSES.SCHEDULED,
    INVOICE_STATUSES.CLIENT_PAID,
    INVOICE_STATUSES.CLIENT_SENT,
  ];
  return includes(editableStatuses, status);
};

export const supplierInvoiceCanBeExcluded = status => {
  const editableStatuses = [
    NEW_INVOICE_STATUSES.SCHEDULED,
    NEW_INVOICE_STATUSES.PENDING,
    NEW_INVOICE_STATUSES.READY,
    INVOICE_STATUSES.SCHEDULED,
    INVOICE_STATUSES.ACTUALS_MISSING,
    INVOICE_STATUSES.PENDING_APPROVAL,
  ];
  return !includes(editableStatuses, status);
};

export const isInvoiceUnderApproved = (invoice = {}) =>
  includes(
    [
      NEW_INVOICE_STATUSES.SCHEDULED,
      NEW_INVOICE_STATUSES.PENDING,
      NEW_INVOICE_STATUSES.READY,
      NEW_INVOICE_STATUSES.READY_FOR_PRE_APPROVE,
    ],
    invoice.status,
  );

export const isInvoiceApprovedAtLeast = (invoice = {}) =>
  [
    NEW_INVOICE_STATUSES.SENT,
    NEW_INVOICE_STATUSES.PAID,
    NEW_INVOICE_STATUSES.WAITING_TO_BE_SENT,
    NEW_INVOICE_STATUSES.APPROVED,
  ].includes(invoice.status);

export const determineStatusBadgeBg = inv => {
  if (inv.status === NEW_INVOICE_STATUSES.APPROVED) {
    return 'green.solid';
  }
  if (inv.status === NEW_INVOICE_STATUSES.READY_FOR_PRE_APPROVE) {
    return 'purple.solid';
  }
  return 'gray.400';
};
