import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { inject, observer } from 'mobx-react';
import { isEmpty, map } from 'lodash-es';
import Icon from '@components/Icon';
import Spinner from '@styles/Spinner';
import React from 'react';
import ResourceCard from '@pages/Resources/ResourceCard';
import ResourceForm from '@pages/Resources/ResourceForm';
import ZoneContainer from '@components/ZoneContainer';
import Typography from '@styles/Typography';

const Documents = ({
  projectId,
  documentsStore: {
    fetchProjectDocuments,
    createProjectDocument,
    clearDocuments,
    documents: { data: documents, isLoading, firstRequest },
  },
}) => {
  React.useEffect(() => {
    fetchProjectDocuments(projectId);

    return () => clearDocuments();
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (isLoading || firstRequest) {
    return <Spinner />;
  }

  return (
    <Box maxW="1280px" px="40px" w="100%" mx="auto" pb="60px" mt="32px">
      {!isOpen && (
        <ZoneContainer py="24px" mb="32px">
          <Button
            leftIcon={<Icon name="add" mr="17px" w="14px" />}
            fontWeight="400"
            fontSize={18}
            bg="aqua.solid"
            color="#fff"
            borderRadius="30px"
            _hover={{ bg: '#3E898C' }}
            _active={{ bg: '#3E898C' }}
            h="56px"
            onClick={onOpen}
          >
            Add a new document
          </Button>
        </ZoneContainer>
      )}
      {isOpen && (
        <ResourceForm
          initialValues={{ projectId }}
          onSubmit={values => createProjectDocument(values, onClose)}
          onClose={onClose}
        />
      )}
      {map(documents, doc => (
        <ResourceCard key={`document-key-${doc.id}`} resource={doc} />
      ))}

      {isEmpty(documents) && !isLoading && (
        <Typography variant="h2" my="40px">
          There are no documents here yet.
        </Typography>
      )}
    </Box>
  );
};

export default inject('documentsStore')(observer(Documents));
