import { Flex } from '@chakra-ui/react';
import Typography from '@styles/Typography';
import React from 'react';
import Icon from '../Icon';

const NoAllocationsMessage = props => (
  <Flex
    mt="8px"
    px="18px"
    py="15px"
    border="1px dashed #D9DADA"
    borderRadius="8px"
    {...props}
  >
    <Icon name="teams" color="#D9DFFF" mr="10px" mt="3px" />
    <Typography color="#C2BFBC">
      There aren’t currently any allocations for this phase
    </Typography>
  </Flex>
);

export default NoAllocationsMessage;
