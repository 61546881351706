import { Flex } from '@chakra-ui/react';
import Icon from '@components/Icon';
import Typography from '@styles/Typography';
import useMediaQuery from '@hooks/useMediaQuery';
import { map, isBoolean } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import useSearchParams from '@hooks/useSearchParams';
import { MAIN_NAMES_QA } from '@app/constants';
import BottomFixedExpandableBox from './ExpandableBox';

const MatchingCriteriaBox = ({
  briefsStore: { brief },
  isDisabled,
  matchOptions,
  onEdit,
}) => {
  const isSmallerScreen = useMediaQuery('(max-width: 1024px)');
  const { onSearch } = useSearchParams();

  const decoratedOptions = React.useMemo(() => {
    const key = brief.isPlugAndPlay ? 'ppBriefQuestions' : 'briefQuestions';

    return map(matchOptions, opt => ({
      ...opt,
      isSelected: brief[key] ? brief[key][opt.fieldName] === true : false,
    }));
  }, [matchOptions, brief.briefQuestions, brief.ppBriefQuestions]);

  if (!isBoolean(isSmallerScreen) || (isSmallerScreen && isDisabled)) {
    return null;
  }

  return (
    <BottomFixedExpandableBox
      onAction={() => {
        onEdit();
        onSearch({});
      }}
      isOpenByDefault={!isSmallerScreen}
      isDisabled={isDisabled}
      actionButtonLabel="Edit"
      title="Matching criteria"
      borderBottom="none"
      w={['100vw', '100vw', '100vw', '284px']}
    >
      <Flex flexDir="column" pt="12px">
        {map(decoratedOptions, ({ isSelected, ...opt }) => {
          return (
            <Flex
              px="4px"
              py="11px"
              align="center"
              key={`match-option-${opt.id}`}
            >
              <Icon
                mr={isSelected ? '12px' : '8px'}
                name={isSelected ? 'simpleTick' : 'cross'}
                fontSize={isSelected ? 16 : 22}
                color={isSelected ? 'green.solid' : 'red.solid'}
              />
              <Typography
                data-test-id={`${MAIN_NAMES_QA.DP_MATCHING}--questionnaireOption`}
              >
                {opt.title}
              </Typography>
            </Flex>
          );
        })}
      </Flex>
    </BottomFixedExpandableBox>
  );
};

export default inject('briefsStore')(observer(MatchingCriteriaBox));
